export const miRNAs = [
  "mmu-let-7a-1-3p",
  "mmu-let-7a-5p",
  "mmu-let-7b-5p",
  "mmu-let-7c-2-3p",
  "mmu-let-7c-5p",
  "mmu-let-7d-3p",
  "mmu-let-7d-5p",
  "mmu-let-7e-5p",
  "mmu-let-7f-1-3p",
  "mmu-let-7f-5p",
  "mmu-let-7g-3p",
  "mmu-let-7g-5p",
  "mmu-let-7i-3p",
  "mmu-let-7i-5p",
  "mmu-miR-100-5p",
  "mmu-miR-101a-3p",
  "mmu-miR-101b-3p",
  "mmu-miR-101c",
  "mmu-miR-122-5p",
  "mmu-miR-125b-5p",
  "mmu-miR-126-3p",
  "mmu-miR-126-5p",
  "mmu-miR-127-3p",
  "mmu-miR-130a-3p",
  "mmu-miR-133a-3p",
  "mmu-miR-133a-5p",
  "mmu-miR-133b-3p",
  "mmu-miR-136-3p",
  "mmu-miR-136-5p",
  "mmu-miR-140-3p",
  "mmu-miR-140-5p",
  "mmu-miR-142-3p",
  "mmu-miR-143-3p",
  "mmu-miR-149-5p",
  "mmu-miR-150-5p",
  "mmu-miR-151-3p",
  "mmu-miR-154-5p",
  "mmu-miR-185-5p",
  "mmu-miR-186-5p",
  "mmu-miR-1895",
  "mmu-miR-18a-5p",
  "mmu-miR-190a-5p",
  "mmu-miR-191-5p",
  "mmu-miR-194-5p",
  "mmu-miR-195a-5p",
  "mmu-miR-196a-5p",
  "mmu-miR-196b-5p",
  "mmu-miR-199a-3p",
  "mmu-miR-199b-3p",
  "mmu-miR-19b-3p",
  "mmu-miR-1a-1-5p",
  "mmu-miR-1a-2-5p",
  "mmu-miR-1a-3p",
  "mmu-miR-200b-3p",
  "mmu-miR-200c-3p",
  "mmu-miR-206-3p",
  "mmu-miR-208b-3p",
  "mmu-miR-2137",
  "mmu-miR-214-3p",
  "mmu-miR-22-3p",
  "mmu-miR-22-5p",
  "mmu-miR-23a-3p",
  "mmu-miR-23b-3p",
  "mmu-miR-24-1-5p",
  "mmu-miR-24-2-5p",
  "mmu-miR-24-3p",
  "mmu-miR-25-3p",
  "mmu-miR-26a-5p",
  "mmu-miR-26b-3p",
  "mmu-miR-27a-3p",
  "mmu-miR-27b-3p",
  "mmu-miR-28a-3p",
  "mmu-miR-299a-3p",
  "mmu-miR-29a-3p",
  "mmu-miR-29b-2-5p",
  "mmu-miR-29b-3p",
  "mmu-miR-29c-3p",
  "mmu-miR-300-5p",
  "mmu-miR-301a-3p",
  "mmu-miR-3096a-3p",
  "mmu-miR-30a-3p",
  "mmu-miR-30b-5p",
  "mmu-miR-30c-5p",
  "mmu-miR-30e-3p",
  "mmu-miR-30e-5p",
  "mmu-miR-3102-3p",
  "mmu-miR-3107-3p",
  "mmu-miR-320-3p",
  "mmu-miR-322-3p",
  "mmu-miR-322-5p",
  "mmu-miR-328-3p",
  "mmu-miR-329-3p",
  "mmu-miR-337-3p",
  "mmu-miR-338-3p",
  "mmu-miR-369-5p",
  "mmu-miR-376c-3p",
  "mmu-miR-378a-3p",
  "mmu-miR-378a-5p",
  "mmu-miR-378c",
  "mmu-miR-378d",
  "mmu-miR-379-5p",
  "mmu-miR-380-5p",
  "mmu-miR-382-5p",
  "mmu-miR-3960",
  "mmu-miR-410-3p",
  "mmu-miR-411-5p",
  "mmu-miR-423-3p",
  "mmu-miR-425-5p",
  "mmu-miR-466f-3p",
  "mmu-miR-484",
  "mmu-miR-486-3p",
  "mmu-miR-486-5p",
  "mmu-miR-489-3p",
  "mmu-miR-500-5p",
  "mmu-miR-503-5p",
  "mmu-miR-505-5p",
  "mmu-miR-5106",
  "mmu-miR-5108",
  "mmu-miR-5109",
  "mmu-miR-5112",
  "mmu-miR-5119",
  "mmu-miR-532-3p",
  "mmu-miR-532-5p",
  "mmu-miR-539-5p",
  "mmu-miR-544-3p",
  "mmu-miR-6240",
  "mmu-miR-6243",
  "mmu-miR-6416-3p",
  "mmu-miR-669c-3p",
  "mmu-miR-674-3p",
  "mmu-miR-708-5p",
  "mmu-miR-744-5p",
  "mmu-miR-7a-1-3p",
  "mmu-miR-872-5p",
  "mmu-miR-877-5p",
  "mmu-miR-93-5p",
  "mmu-miR-98-5p",
  "mmu-miR-99a-5p",
  "mmu-miR-99b-5p"
];
