import React from "react";
import ReactTable from "react-table";
import { filterMethod } from "../Viewer";

const PathwayTable = ({ flag, results, onClick }) => {
  const columns = [
    {
      Header: "Pathway title",
      accessor: "title",
      filterable: true,
      Cell: ({ row }) =>
        row.viewable ? (
          <div className={"linkTitle"} onClick={() => onClick(row.title)}>
            {row.title}
          </div>
        ) : (
          <div>{row.title}</div>
        )
    },
    {
      Header: "genes in common",
      accessor: "genes_in_common",
      filterable: true
    },
    {
      Header: "dim. pathway",
      accessor: "dim_pathway",
      filterable: true
    },
    {
      Header: "pvalue",
      accessor: "pvalue",
      filterable: true
    },
    {
      Header: "adjusted pvalue (BH)",
      accessor: "adj_pvalue",
      filterable: true
    },
    {
      Header: "Available Network",
      accessor: "viewable",
      filterable: false,
      show: false
    }
  ];

  return flag ? (
    <div className="pathwayTableContainer">
      <ReactTable
        style={{ width: "1000px" }}
        data={results}
        columns={columns}
        pageSizeOptions={[5, 10, 20, 25, 50]}
        defaultPageSize={5}
        defaultFilterMethod={filterMethod}
        defaultSorted={[
          {
            id: "adj_pvalue",
            desc: false
          }
        ]}
      />
    </div>
  ) : (
    <div />
  );
};

export default PathwayTable;
