import f1 from "./jpg_optimized/Figure-1.jpg";
import f2 from "./jpg_optimized/Figure-2.jpg";
import f3 from "./jpg_optimized/Figure-3.jpg";
import f4 from "./jpg_optimized/Figure-4.jpg";
import f5 from "./jpg_optimized/Figure-5.jpg";
import f6 from "./jpg_optimized/Figure-6.jpg";
import f7 from "./jpg_optimized/Figure-7.jpg";
import f8 from "./jpg_optimized/Figure-8.jpg";
import f9 from "./jpg_optimized/Figure-9.jpg";
import f10 from "./jpg_optimized/Figure-10.jpg";
import f11 from "./jpg_optimized/Figure-11.jpg";
import f12 from "./jpg_optimized/Figure-12.jpg";
import f13 from "./jpg_optimized/Figure-13.jpg";
import f14 from "./jpg_optimized/Figure-14.jpg";

export const faqs = [
  {
    question: "Are you interested in one particular gene?",
    answers: [
      {
        answer:
          "You can insert the name of the gene or Ensembl gene ID in the box indicated with the red arrow in the figure below.",
        image: f1,
        imageAlt: "Single search entry"
      },
      {
        answer:
          "MyoData can suggest the name of the gene during user typing. Once the user writes the gene name or Ensembl ID (clicking on the 'SEARCH' button) results will appear.",
        image: null,
        imageAlt: null
      },
      { answer: null, image: null, imageAlt: null },
      { answer: null, image: null, imageAlt: null }
    ]
  },
  {
    question: "Are you interested in a list of genes?",
    answers: [
      {
        answer:
          "Click on the 'Custom network from user selection' link (red arrow in the figure below).",
        image: f2,
        imageAlt: "Custom network"
      },
      {
        answer:
          "User can insert a list of gene symbols or Ensembl IDs in the box as shown below.",
        image: f3,
        imageAlt: "Box for list of genes in custom network"
      },
      {
        answer:
          "After clicking on 'Compute Network' button, the number of accepted genes are used to construct the network. Genes that are not identified are listed as 'Rejected' and can be visualized by clicking on the 'Toggle Rejected Nodes' button.",
        image: null,
        imageAlt: null
      },
      { answer: null, image: null, imageAlt: null }
    ]
  },
  {
    question: "Are you interested in the pathway analysis?",
    answers: [
      {
        answer:
          "Click on the 'Pathway Enrichment Analysis' link (red arrow in the figure below).",
        image: f4,
        imageAlt: "Pathway Enrichment Analysis link"
      },
      {
        answer:
          "User can insert a list of gene symbols or Ensembl IDs in the box as shown below.",
        image: f5,
        imageAlt: "Box for list of genes in Pathways Enrichment Analysis"
      },
      {
        answer:
          "By clicking on the 'Compute Network' button, the list of KEGG enriched pathways will appear in association with accepted genes.   Rejected genes can be visualized by clicking on the 'Toggle Rejected Nodes'  button. The resulting table shows KEGG pathways that are enriched with miRNAs and lncRNAs that may influence the expression of genes associated with pathways.",
        image: f6,
        imageAlt: "list of KEGG enriched pathways"
      },
      {
        answer:
          "Pathways that are not made up of too many nodes are displayed as  link. The network associated to each pathway can be visualized by clicking on the corresponding pathway title. The table can be filtered by writing in the field of interest (red box in the previous figure): the pathway title, number of genes in common between used request and pathway genes (genes in common), the number of genes composing the pathway (dim. pathway), and statistics (pvalue or adjusted pvalue).",
        image: null,
        imageAlt: null
      }
    ]
  },
  {
    question: "Description of results page for the single gene searching",
    answers: [
      {
        answer:
          "After clicking on the 'SEARCH' button on the main searching page, it will be showed calculated results for the gene searched.\nFirst it will be showed its expression in all myofiber tested (see figure below).",
        image: f7,
        imageAlt: "Expression plot"
      },
      {
        answer:
          "Clicking on each column it is possible to show the expression of that specific myofiber while the button indicated with the arrow allows to download the image or data used to create the image.\nThe network associated with the searched gene will be showed below the expression profile (see image below).",
        image: f8,
        imageAlt: "Searched gene in network"
      },
      {
        answer:
          "The node corresponding to searched gene is represented in black (arrow). It is possible to interact dynamically with the network by moving nodes or using buttons below the network. It can be downloaded as image or as JSON file using the icons below the network. Alternatively, the network can be downloaded as txt file using the 'Download Network' button. Clicking on the drop-down menu (arrow in the figure below) it is possible to change the network visualization according to the single nucleus RNA-seq data the user chooses.",
        image: f9,
        imageAlt: "Dropdown menu"
      },
      { answer: null, image: null, imageAlt: null }
    ]
  },
  {
    question: "Description of results for the multiple gene searching",
    answers: [
      {
        answer:
          "In the resulting network accepted user genes are labeled in black (see the figure below). As described in 'Description of results page for the single gene searching' question, nodes and edges can be organized interactively, and the network can be downloaded as image or JSON and txt files. Information about nodes and edges can be retrieved by clicking on them.",
        image: f12,
        imageAlt: "Resulted network"
      },
      { answer: null, image: null, imageAlt: null },
      { answer: null, image: null, imageAlt: null },
      { answer: null, image: null, imageAlt: null }
    ]
  },
  {
    question: "Description of results in the pathway enrichment analysis",
    answers: [
      {
        answer:
          "Once the user clicks on \"Compute Network\" button, the first results is a table showing a list of enriched pathways  (see 'Are you interested in the pathway analysis?' section). Clicking on the pathway title, the corresponding network enriched with miRNAs and lncRNAs that can regulate genes of the pathway can be visualized. Nodes and edges can be organized interactively, and the network can be downloaded as image or JSON and txt files. Information about nodes and edges can be retrieved by clicking on them.",
        image: f14,
        imageAlt: "Pathway network"
      },
      { answer: null, image: null, imageAlt: null },
      { answer: null, image: null, imageAlt: null },
      { answer: null, image: null, imageAlt: null }
    ]
  },
  {
    question: "What information the user can retrieve from the network?",
    answers: [
      {
        answer:
          "Information related to each node can be visualized by clicking on it. Selected node will be displayed in pink (see figure below where arrow indicates interrogated node and the red box information retrieved for the gene represented by the node).",
        image: f10,
        imageAlt: "Node information"
      },
      {
        answer:
          "As for nodes, edges information can be retrieved by clicking on them (see figure below where arrow indicates selected edge and red box retrieved information).",
        image: f11,
        imageAlt: "Edge information"
      },
      { answer: null, image: null, imageAlt: null },
      { answer: null, image: null, imageAlt: null }
    ]
  },
  {
    question:
      "What information the user can retrieve about gene expression correlation?",
    answers: [
      {
        answer:
          "For each searched entry, user can retrieve gene expression correlations with the other biotypes. For example, as shown in the image below, once the user searches for a protein coding (e.g. Myh4), correlations with lncRNA and miRNA can be visualized. For each table row a plot  of the expressions comparisons will be displayed by clicking on the corresponding arrow. The image can be downloaded as SVG or PNG and data as CSV file.",
        image: f13,
        imageAlt: "Correlation"
      },
      { answer: null, image: null, imageAlt: null },
      { answer: null, image: null, imageAlt: null },
      { answer: null, image: null, imageAlt: null }
    ]
  }
];
