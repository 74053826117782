// Copyright 2019 Davide Corso

import React from "react";
import "../style.css";
import "react-table/react-table.css";
import Spinner from "../Spinner";
import ReactTable from "react-table";
import RemoteExpression from "../RemoteExpression";
import PropTypes from "prop-types";
import { filterMethod } from "../Viewer";

const Correlation = ({ flag, source, correlations, expToFetch, series }) => {
  return !!flag ? (
    <div className="correlation">
      <ReactTable
        style={{ width: "1000px" }}
        data={correlations}
        columns={[
          { Header: "Source Name", accessor: "source_name" },
          { Header: "Source", accessor: "source" },
          {
            Header: "Target Name / ID",
            accessor: "target_name",
            filterable: true
          },
          { Header: "Target", accessor: "target", filterable: true },
          { Header: "Correlations", accessor: "corr", filterable: true }
        ]}
        pageSizeOptions={[5, 10, 20, 25, 50]}
        defaultPageSize={10}
        defaultFilterMethod={filterMethod}
        SubComponent={row => {
          return (
            <RemoteExpression
              source={source}
              geneTarget={row.original.target}
              expToFetch={expToFetch}
              series={series}
              plotName={makeDoublePlotName(
                row.original.source,
                row.original.target,
                expToFetch
              )}
            />
          );
        }}
      />
    </div>
  ) : (
    <div className="correlation">
      <Spinner size="2x" />
    </div>
  );
};

Correlation.propTypes = {
  correlations: PropTypes.arrayOf(PropTypes.object),
  expToFetch: PropTypes.string
};

export default Correlation;

const makeDoublePlotName = (source_entry, target_entry, expToFetch) => {
  if (expToFetch === "compressed") {
    return `${source_entry} ${target_entry} comparison compressed expression`;
  } else {
    return `${source_entry} ${target_entry} comparison normal expression`;
  }
};
