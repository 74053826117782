// Copyright 2019 Davide Corso

import React from "react";
import { useTarget } from "../hooks/target";
import Expression from "./Expression/Expression";

const RemoteExpression = ({
  source,
  geneTarget,
  expToFetch,
  series,
  plotName
}) => {
  const expressionTarget = useTarget(geneTarget, expToFetch);

  function combineSeries(series, datasetTarget) {
    let s = [...series];
    s.push(datasetTarget);
    return s;
  }
  return (
    <Expression
      flag={expressionTarget.flag}
      series={combineSeries(series, expressionTarget.series)}
      source={source}
      target={expressionTarget.entry_type}
      height={400}
      width={950}
      plotName={plotName}
    />
  );
};

export default RemoteExpression;
