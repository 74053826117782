import React, { useState } from "react";
import NetworkViewer from "network-viewer";
import "network-viewer/dist/index.css";
import Dropdown from "../Network/Dropdown/Dropdown";
import cssDropdown from "../Network/Dropdown/Dropdown.module.css";
import NetworkTable from "../Network/NetworkTables";
import "./Pathway.css";
import PathwayLegend from "./PathwayLegend";
import { downloadNetwork } from "../Network/downloadNetwork";

const PathwayGraphSection = ({
  flag,
  nodes,
  edges,
  selectedElement,
  selectElement
}) => {
  const [layoutTimeout, setLayoutTimeout] = useState(0);

  const onLayoutChange = timeout => {
    setLayoutTimeout(findLayoutChoice(timeout, layoutChoices));
  };

  return flag ? (
    <div className="pathwayGraphSection">
      <PathwayLegend />
      <div className="pathwayGraph">
        <NetworkViewer
          nodes={nodes}
          edges={edges}
          style={style}
          layoutTimeout={layoutChoices[layoutTimeout].value}
          selectElements={selectElement}
        >
          <div className={cssDropdown.container}>
            <div className={`${cssDropdown.layout} ${cssDropdown.header}`}>
              Layout Duration:
            </div>
            <div className={`${cssDropdown.layout} ${cssDropdown.dropdown}`}>
              <Dropdown
                choices={layoutChoices}
                selection={layoutChoices[layoutTimeout].value}
                onChange={onLayoutChange}
              />
            </div>
            <div
              className={cssDropdown.download}
              onClick={() => downloadNetwork(nodes, edges)}
            >
              <button>Download Network</button>
            </div>
          </div>
        </NetworkViewer>
      </div>
      <NetworkTable
        flag={selectedElement.flag}
        element={selectedElement.element}
        information={selectedElement.infos}
      />
    </div>
  ) : (
    <div /> //style={{ height: "15rem" }} />
  );
};

export default PathwayGraphSection;

const layoutChoices = [
  { label: "5 seconds", value: 5 },
  { label: "10 seconds", value: 10 },
  { label: "20 seconds", value: 20 },
  { label: "30 seconds", value: 30 }
];

function findLayoutChoice(expected, choices) {
  let index = 0;
  for (const choice of choices) {
    // eslint-disable-next-line no-eval
    if (choice.value === eval(expected)) {
      return index;
    }
    index += 1;
  }
}

const style = [
  {
    selector: "node",
    css: {
      content: "data(label)",
      shape: "data(shape)",
      color: "data(col)",
      backgroundColor: "data(col)"
    }
  },
  {
    selector: "edge",
    css: {
      "line-color": "data(col)"
    }
  },
  {
    selector: ":selected",
    css: {
      "background-color": "#e933ff",
      "line-color": "#e933ff",
      "target-arrow-color": "#e933ff",
      "source-arrow-color": "#e933ff"
    }
  }
];
