import React from "react";
import css from "./Homepage.module.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HPAutocompleteSearch from "../HPAutocompleteSearch/HPAutocompleteSearch";
import { autocomplete_entries } from "../HPAutocompleteSearch/autocomplete_entries";

const StartingOptions = () => {
  return (
    <div className={css.startingOptions}>
      <div className={css.homepageSearchContainer}>
        <div className={css.hpSearchTitle}>
          <FontAwesomeIcon
            className={css.bullet}
            icon="greater-than"
            size="1x"
          />
          Search gene or miRNA
        </div>
        <HPAutocompleteSearch options={autocomplete_entries} />
      </div>
      <hr
        className={css.horizontalLine}
        style={{
          width: "80%",
          color: "lightgray",
          height: "1px",
          backgroundColor: "lightgray",
          border: "none"
        }}
      />
      <Link className={css.homepageNetwork} to="/network">
        <FontAwesomeIcon className={css.bullet} icon="greater-than" size="1x" />
        <div className={css.hpNetworkTitle}>
          Custom network from user selection
        </div>
      </Link>
      <hr
        className={css.horizontalLine}
        style={{
          width: "80%",
          color: "lightgray",
          height: "1px",
          backgroundColor: "lightgray",
          border: "none"
        }}
      />
      <Link className={css.homepagePathways} to="/pathways">
        <FontAwesomeIcon className={css.bullet} icon="greater-than" size="1x" />
        <div className={css.hpPathwaysTitle}>Pathway Enrichment Analysis</div>
      </Link>
      <hr
        className={css.horizontalLine}
        style={{
          width: "80%",
          color: "lightgray",
          height: "1px",
          backgroundColor: "lightgray",
          border: "none"
        }}
      />
      <Link className={css.homepageHelp} to="/help">
        <FontAwesomeIcon className={css.bullet} icon="greater-than" size="1x" />
        <div className={css.hpHelpTitle}>Help</div>
      </Link>
    </div>
  );
};

export default StartingOptions;
