import React from "react";
import "../style.css";
import logo800 from "./logo_800anni.png";

const Footer = () => {
  return (
    <div className={"footer"}>
      <div className={"citeUs"}>
        <br />
        <em>If you find this database useful, please cite:</em>
        <br />
        <br />
        <div style={{ color: "#2385e0" }}>
          Corso D, Chemello F, Alessio E, Urso I, Ferrarese G, Bazzega M,
          Romualdi C, Lanfranchi G, Sales G, Cagnin S.
          <br />
          <b>
            <a href="https://www.sciencedirect.com/science/article/pii/S200103702100310X">
              MyoData: single myofiber and myonucleus expression database to
              identify lncRNA-miRNA-coding RNA functional interactions.
            </a>
          </b>
        </div>
      </div>
      <a
        className={"unipd"}
        href="https://www.biologia.unipd.it"
        title="University of Padova"
      >
        <img
          src={logo800}
          height={114}
          width={400}
          alt={"University of Padova"}
        />
      </a>
    </div>
  );
};

export default Footer;
