import React from "react";
import css from "./Homepage.module.css";
import homepageIMG from "./compressed_Image-home-page.jpg";

const Description = () => {
  return (
    <div className={css.description}>
      <div>
        <p style={{ textAlign: "justify" }}>
          Skeletal muscle is the most abundant organ in mammals and is
          responsible not only for their movement but also for metabolic
          functions. The smallest complete contractile system of skeletal muscle
          influencing its contraction velocity and metabolism are myofibers.
          Muscles are composed of different myofiber types and each myofiber
          type is affected differently by atrophy. This supports myofiber
          functional differences. In recent years, high throughput studies on
          single myofibers revealed that myofiber functional differences may
          also be mediated by the interplay between miRNAs, lncRNAs, and mRNAs
          inducing coregulatory mechanisms.
        </p>
        <p style={{ textAlign: "justify" }}>
          MyoData DB is a comprehensive and integrated resource for single
          myofiber and nucleus miRNA:lncRNA:mRNA coregulatory networks also
          evaluating their impact on the regulation of KEGG pathways. The
          database provides an interactive visualization of the networks and
          interactive searching for a list of mRNAs, miRNAs or/and lncRNAs.
          MyoData DB can be used as a comprehensive ready reference of
          regulatory networks and pathways for decrypting the activity of miRNAs
          and lncRNAs in myofibers.
        </p>
      </div>
      <div style={{ placeSelf: "center" }}>
        <img src={homepageIMG} width={"100%"} alt={"Description"} />
      </div>
    </div>
  );
};

export default Description;
