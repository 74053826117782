import React from "react";
import "./style.css";

const Title = ({ flag, probe, geneName, entryType }) => {
  return Boolean(entryType) === false && flag ? (
    <h1 className="title">No such ID.</h1>
  ) : (
    <h1 className="title">
      <span style={{ fontWeight: "bold" }}>Entry:</span> {probe}
      <br />
      <span style={{ fontWeight: "bold" }}>Entry type:</span> {entryType}
      <br />
      <span style={{ fontWeight: "bold" }}>Symbol:</span> {geneName}
    </h1>
  );
};

export default Title;
