export const autocomplete_entries = [
  "0610005C13Rik",
  "0610006L08Rik",
  "0610009B22Rik",
  "0610009E02Rik",
  "0610009L18Rik",
  "0610010F05Rik",
  "0610012G03Rik",
  "0610025J13Rik",
  "0610030E20Rik",
  "0610033M10Rik",
  "0610039K10Rik",
  "0610040B10Rik",
  "0610040F04Rik",
  "0610040J01Rik",
  "0610043K17Rik",
  "0710001A04Rik",
  "1110002E22Rik",
  "1110002J07Rik",
  "1110002L01Rik",
  "1110004F10Rik",
  "1110008E08Rik",
  "1110008P14Rik",
  "1110012L19Rik",
  "1110015O18Rik",
  "1110017D15Rik",
  "1110018N20Rik",
  "1110019D14Rik",
  "1110020A21Rik",
  "1110025L11Rik",
  "1110028F11Rik",
  "1110028F18Rik",
  "1110032A03Rik",
  "1110032F04Rik",
  "1110035H17Rik",
  "1110038B12Rik",
  "1110038F14Rik",
  "1110046J04Rik",
  "1110051M20Rik",
  "1110057P08Rik",
  "1110059E24Rik",
  "1110059G10Rik",
  "1110065P20Rik",
  "1190001M18Rik",
  "1190005I06Rik",
  "1190028D05Rik",
  "1200007C13Rik",
  "1300002E11Rik",
  "1300017J02Rik",
  "1500002C15Rik",
  "1500002F19Rik",
  "1500004A13Rik",
  "1500009C09Rik",
  "1500009L16Rik",
  "1500011B03Rik",
  "1500012K07Rik",
  "1500015A07Rik",
  "1500015L24Rik",
  "1500026H17Rik",
  "1500032F14Rik",
  "1500035N22Rik",
  "1520401A03Rik",
  "1600002D24Rik",
  "1600010M07Rik",
  "1600012H06Rik",
  "1600012P17Rik",
  "1600014C10Rik",
  "1600014C23Rik",
  "1600020E01Rik",
  "1600022D10Rik",
  "1600025M17Rik",
  "1600027J07Rik",
  "1700001C19Rik",
  "1700001G01Rik",
  "1700001J03Rik",
  "1700001J04Rik",
  "1700001K19Rik",
  "1700001K23Rik",
  "1700001L05Rik",
  "1700001L19Rik",
  "1700001N15Rik",
  "1700001O22Rik",
  "1700001P01Rik",
  "1700003C15Rik",
  "1700003D09Rik",
  "1700003E16Rik",
  "1700003F12Rik",
  "1700003G18Rik",
  "1700003H04Rik",
  "1700003I16Rik",
  "1700003M07Rik",
  "1700003O11Rik",
  "1700003P14Rik",
  "1700006A11Rik",
  "1700006F04Rik",
  "1700006J14Rik",
  "1700007F19Rik",
  "1700007H22Rik",
  "1700007J10Rik",
  "1700007K13Rik",
  "1700008J07Rik",
  "1700008K24Rik",
  "1700008O03Rik",
  "1700008P02Rik",
  "1700009C05Rik",
  "1700009J07Rik",
  "1700009N14Rik",
  "1700010B08Rik",
  "1700010B13Rik",
  "1700010D01Rik",
  "1700010H22Rik",
  "1700010I14Rik",
  "1700010L04Rik",
  "1700011B04Rik",
  "1700011L22Rik",
  "1700011M02Rik",
  "1700012A03Rik",
  "1700012B07Rik",
  "1700012B09Rik",
  "1700012D14Rik",
  "1700012E03Rik",
  "1700012I11Rik",
  "1700012P22Rik",
  "1700013D24Rik",
  "1700013F07Rik",
  "1700013G24Rik",
  "1700013H16Rik",
  "1700013M08Rik",
  "1700014D04Rik",
  "1700015C15Rik",
  "1700015F17Rik",
  "1700016C15Rik",
  "1700016D06Rik",
  "1700016F12Rik",
  "1700016H13Rik",
  "1700016J18Rik",
  "1700016K19Rik",
  "1700016L21Rik",
  "1700016P04Rik",
  "1700017B05Rik",
  "1700017G19Rik",
  "1700017I07Rik",
  "1700017N19Rik",
  "1700018A04Rik",
  "1700018B08Rik",
  "1700018B24Rik",
  "1700018F24Rik",
  "1700018G05Rik",
  "1700019A02Rik",
  "1700019D03Rik",
  "1700019E08Rik",
  "1700019J19Rik",
  "1700019N19Rik",
  "1700019P21Rik",
  "1700020A23Rik",
  "1700020D05Rik",
  "1700020D12Rik",
  "1700020L24Rik",
  "1700020N01Rik",
  "1700020N18Rik",
  "1700021A07Rik",
  "1700021F02Rik",
  "1700021F07Rik",
  "1700021P04Rik",
  "1700022A22Rik",
  "1700022H16Rik",
  "1700022I11Rik",
  "1700022N22Rik",
  "1700023C21Rik",
  "1700023F02Rik",
  "1700023G09Rik",
  "1700024G13Rik",
  "1700025C18Rik",
  "1700025D23Rik",
  "1700025G04Rik",
  "1700025H01Rik",
  "1700025K04Rik",
  "1700025K24Rik",
  "1700025L06Rik",
  "1700025N23Rik",
  "1700025O08Rik",
  "1700026D11Rik",
  "1700026J12Rik",
  "1700027A07Rik",
  "1700027A15Rik",
  "1700027J07Rik",
  "1700028E10Rik",
  "1700028I16Rik",
  "1700028J19Rik",
  "1700028K03Rik",
  "1700028M03Rik",
  "1700028N14Rik",
  "1700028P14Rik",
  "1700029E06Rik",
  "1700029F12Rik",
  "1700029H14Rik",
  "1700029I15Rik",
  "1700029J07Rik",
  "1700029M03Rik",
  "1700029M20Rik",
  "1700029P11Rik",
  "1700030C10Rik",
  "1700030J22Rik",
  "1700030K09Rik",
  "1700030L20Rik",
  "1700030N03Rik",
  "1700031A10Rik",
  "1700031F05Rik",
  "1700031F10Rik",
  "1700031P21Rik",
  "1700034E13Rik",
  "1700034G24Rik",
  "1700034H15Rik",
  "1700034I23Rik",
  "1700034J05Rik",
  "1700034K08Rik",
  "1700034P13Rik",
  "1700036A12Rik",
  "1700037C18Rik",
  "1700037H04Rik",
  "1700039M10Rik",
  "1700040D17Rik",
  "1700040F17Rik",
  "1700041B01Rik",
  "1700041C23Rik",
  "1700041G16Rik",
  "1700041M19Rik",
  "1700042G07Rik",
  "1700042O10Rik",
  "1700045H11Rik",
  "1700046C09Rik",
  "1700047A11Rik",
  "1700047K16Rik",
  "1700047M11Rik",
  "1700048F04Rik",
  "1700048O14Rik",
  "1700048O20Rik",
  "1700049E22Rik",
  "1700051A21Rik",
  "1700052H01Rik",
  "1700052K11Rik",
  "1700054K19Rik",
  "1700054M17Rik",
  "1700055D18Rik",
  "1700056E22Rik",
  "1700057G04Rik",
  "1700057H15Rik",
  "1700060C20Rik",
  "1700061E17Rik",
  "1700061I17Rik",
  "1700062C10Rik",
  "1700063D05Rik",
  "1700063H04Rik",
  "1700064H15Rik",
  "1700065D16Rik",
  "1700065J11Rik",
  "1700066B19Rik",
  "1700066M21Rik",
  "1700066O22Rik",
  "1700067G17Rik",
  "1700067K01Rik",
  "1700067P10Rik",
  "1700069B07Rik",
  "1700069L16Rik",
  "1700069P05Rik",
  "1700074P13Rik",
  "1700080N15Rik",
  "1700080O16Rik",
  "1700081H04Rik",
  "1700083H02Rik",
  "1700084D21Rik",
  "1700084E18Rik",
  "1700084F23Rik",
  "1700084M14Rik",
  "1700085C21Rik",
  "1700086D15Rik",
  "1700086O06Rik",
  "1700086P04Rik",
  "1700087I21Rik",
  "1700088E04Rik",
  "1700092E19Rik",
  "1700092K14Rik",
  "1700092M07Rik",
  "1700093J21Rik",
  "1700093K21Rik",
  "1700094M24Rik",
  "1700095A21Rik",
  "1700095J03Rik",
  "1700096J18Rik",
  "1700096K18Rik",
  "1700097N02Rik",
  "1700099I09Rik",
  "1700100L14Rik",
  "1700101I11Rik",
  "1700101I19Rik",
  "1700102H20Rik",
  "1700102P08Rik",
  "1700104B16Rik",
  "1700104L18Rik",
  "1700108F19Rik",
  "1700108J01Rik",
  "1700109G14Rik",
  "1700109H08Rik",
  "1700110C19Rik",
  "1700111E14Rik",
  "1700111N16Rik",
  "1700112J05Rik",
  "1700112J16Rik",
  "1700113A16Rik",
  "1700113H08Rik",
  "1700116B05Rik",
  "1700119I11Rik",
  "1700120B22Rik",
  "1700120C14Rik",
  "1700120K04Rik",
  "1700120O09Rik",
  "1700121L16Rik",
  "1700121N20Rik",
  "1700122C19Rik",
  "1700122H20Rik",
  "1700122O11Rik",
  "1700123K08Rik",
  "1700123L14Rik",
  "1700123M08Rik",
  "1700123O12Rik",
  "1700123O20Rik",
  "1700124L16Rik",
  "1700125H20Rik",
  "1700126A01Rik",
  "1700128A07Rik",
  "1700128E19Rik",
  "1700129C05Rik",
  "1700129L04Rik",
  "1810006J02Rik",
  "1810007D17Rik",
  "1810008I18Rik",
  "1810009J06Rik",
  "1810010H24Rik",
  "1810010K12Rik",
  "1810013D15Rik",
  "1810013L24Rik",
  "1810019N24Rik",
  "1810020O05Rik",
  "1810024B03Rik",
  "1810026B05Rik",
  "1810030O07Rik",
  "1810034E14Rik",
  "1810037I17Rik",
  "1810046K07Rik",
  "1810053B23Rik",
  "1810055G02Rik",
  "1810058I24Rik",
  "1810058N15Rik",
  "1810059H22Rik",
  "1810062G17Rik",
  "1810062O18Rik",
  "1810064F22Rik",
  "1810065E05Rik",
  "1810073O08Rik",
  "2010001K21Rik",
  "2010003K11Rik",
  "2010007H06Rik",
  "2010013B24Rik",
  "2010016I18Rik",
  "2010106E10Rik",
  "2010109A12Rik",
  "2010110K18Rik",
  "2010204K13Rik",
  "2010300C02Rik",
  "2010308F09Rik",
  "2010310C07Rik",
  "2010315B03Rik",
  "2200002D01Rik",
  "2200002J24Rik",
  "2210010C04Rik",
  "2210011K15Rik",
  "2210016F16Rik",
  "2210016L21Rik",
  "2210017I01Rik",
  "2210039B01Rik",
  "2210404E10Rik",
  "2210406O10Rik",
  "2210407C18Rik",
  "2210408F21Rik",
  "2210408I21Rik",
  "2210409D07Rik",
  "2210417A02Rik",
  "2300002M23Rik",
  "2300003K06Rik",
  "2300009A05Rik",
  "2310001H17Rik",
  "2310001K24Rik",
  "2310002D06Rik",
  "2310002L09Rik",
  "2310003L06Rik",
  "2310005A03Rik",
  "2310005E17Rik",
  "2310008N11Rik",
  "2310009B15Rik",
  "2310010J17Rik",
  "2310011J03Rik",
  "2310014F06Rik",
  "2310015A16Rik",
  "2310015D24Rik",
  "2310015K22Rik",
  "2310016D03Rik",
  "2310016D23Rik",
  "2310016G11Rik",
  "2310022A10Rik",
  "2310022B05Rik",
  "2310026I22Rik",
  "2310026L22Rik",
  "2310030G06Rik",
  "2310033P09Rik",
  "2310034C09Rik",
  "2310034G01Rik",
  "2310039H08Rik",
  "2310040G07Rik",
  "2310040G24Rik",
  "2310043M15Rik",
  "2310046K23Rik",
  "2310047D07Rik",
  "2310050C09Rik",
  "2310057J18Rik",
  "2310057M21Rik",
  "2310057N15Rik",
  "2310061I04Rik",
  "2310061N02Rik",
  "2310065F04Rik",
  "2310067P03Rik",
  "2310068J16Rik",
  "2310075C17Rik",
  "2310079G19Rik",
  "2400006E01Rik",
  "2410002F23Rik",
  "2410003L11Rik",
  "2410004B18Rik",
  "2410004I01Rik",
  "2410004P03Rik",
  "2410006H16Rik",
  "2410012E07Rik",
  "2410017I17Rik",
  "2410022M11Rik",
  "2410080I02Rik",
  "2410124H12Rik",
  "2410131K14Rik",
  "2410137M14Rik",
  "2410141K09Rik",
  "2500002B13Rik",
  "2500004C02Rik",
  "2510002D24Rik",
  "2510009E07Rik",
  "2510039O18Rik",
  "2510046G10Rik",
  "2600006K01Rik",
  "2610001J05Rik",
  "2610002M06Rik",
  "2610008E11Rik",
  "2610020C07Rik",
  "2610021A01Rik",
  "2610027F03Rik",
  "2610027K06Rik",
  "2610028E06Rik",
  "2610028H24Rik",
  "2610035D17Rik",
  "2610035F20Rik",
  "2610037D02Rik",
  "2610042L04Rik",
  "2610044O15Rik8",
  "2610203C22Rik",
  "2610206C17Rik",
  "2610301B20Rik",
  "2610303G11Rik",
  "2610306M01Rik",
  "2610307P16Rik",
  "2610316D01Rik",
  "2610318N02Rik",
  "2610507B11Rik",
  "2610528A11Rik",
  "2610528J11Rik",
  "2700046A07Rik",
  "2700046G09Rik",
  "2700049A03Rik",
  "2700054A10Rik",
  "2700062C07Rik",
  "2700068H02Rik",
  "2700081O15Rik",
  "2700089I24Rik",
  "2700097O09Rik",
  "2810001G20Rik",
  "2810004N23Rik",
  "2810006K23Rik",
  "2810013P06Rik",
  "2810021J22Rik",
  "2810030D12Rik",
  "2810032G03Rik",
  "2810402E24Rik",
  "2810403D21Rik",
  "2810403G07Rik",
  "2810404F17Rik",
  "2810404M03Rik",
  "2810405F17Rik",
  "2810407A14Rik",
  "2810408A11Rik",
  "2810408I11Rik",
  "2810410L24Rik",
  "2810429I04Rik",
  "2810455O05Rik",
  "2810459M11Rik",
  "2810471M01Rik",
  "2900005J15Rik",
  "2900026A02Rik",
  "2900040C04Rik",
  "2900041M22Rik",
  "2900052L18Rik",
  "2900052N01Rik",
  "2900057B20Rik",
  "2900076A07Rik",
  "2900079G21Rik",
  "2900092C05Rik",
  "2900097C17Rik",
  "3010001F23Rik",
  "3010003L21Rik",
  "3110001I22Rik",
  "3110009E18Rik",
  "3110009F21Rik",
  "3110018I06Rik",
  "3110039M20Rik",
  "3110040N11Rik",
  "3110070M22Rik",
  "3110082I17Rik",
  "3110099E03Rik",
  "3222401L13Rik",
  "3300002A11Rik",
  "3300002I08Rik",
  "3300002P13Rik",
  "3300005D01Rik",
  "3425401B19Rik",
  "3632454L22Rik",
  "3830406C13Rik",
  "3830408C21Rik",
  "3830417A13Rik",
  "3930402G23Rik",
  "4430402I18Rik",
  "4631405J19Rik",
  "4632404H12Rik",
  "4632411P08Rik",
  "4632427E13Rik",
  "4632428C04Rik",
  "4731419I09Rik",
  "4732414G09Rik",
  "4732419C18Rik",
  "4732440D04Rik",
  "4732465J04Rik",
  "4732471J01Rik",
  "4732491K20Rik",
  "4831440D22Rik",
  "4833405L11Rik",
  "4833407H14Rik",
  "4833411C07Rik",
  "4833413E03Rik",
  "4833415N18Rik",
  "4833418N02Rik",
  "4833419O12Rik",
  "4833420G17Rik",
  "4833422C13Rik",
  "4833422M21Rik",
  "4833427F10Rik",
  "4833427G06Rik",
  "4833428L15Rik",
  "4833439L19Rik",
  "4833445I07Rik",
  "4921504A21Rik",
  "4921504E06Rik",
  "4921507G05Rik",
  "4921507P07Rik",
  "4921508D12Rik",
  "4921509C19Rik",
  "4921509O07Rik",
  "4921511C20Rik",
  "4921511I17Rik",
  "4921513I03Rik",
  "4921515E04Rik",
  "4921515L22Rik",
  "4921517D16Rik",
  "4921517D22Rik",
  "4921518K17Rik",
  "4921521D15Rik",
  "4921522P10Rik",
  "4921524J17Rik",
  "4921524L21Rik",
  "4921525O09Rik",
  "4921528I07Rik",
  "4921529L05Rik",
  "4921531C22Rik",
  "4921531P14Rik",
  "4921534H16Rik",
  "4921536K21Rik",
  "4921539E11Rik",
  "4921539H07Rik",
  "4930401C15Rik",
  "4930402F06Rik",
  "4930402F11Rik",
  "4930402H24Rik",
  "4930402K13Rik",
  "4930404H24Rik",
  "4930404N11Rik",
  "4930405A10Rik",
  "4930405D01Rik",
  "4930405H06Rik",
  "4930407I10Rik",
  "4930407I19Rik",
  "4930408O17Rik",
  "4930412C18Rik",
  "4930412F09Rik",
  "4930412L05Rik",
  "4930412M03Rik",
  "4930412O13Rik",
  "4930413E15Rik",
  "4930413F20Rik",
  "4930414F18Rik",
  "4930415L06Rik",
  "4930415O20Rik",
  "4930417H01Rik",
  "4930417O13Rik",
  "4930417O22Rik",
  "4930422C21Rik",
  "4930422I22Rik",
  "4930423D22Rik",
  "4930423M02Rik",
  "4930425O10Rik",
  "4930426D05Rik",
  "4930426I24Rik",
  "4930426L09Rik",
  "4930428N03Rik",
  "4930429D17Rik",
  "4930430F08Rik",
  "4930430O22Rik",
  "4930431F10Rik",
  "4930431F12Rik",
  "4930432B10Rik",
  "4930432J09Rik",
  "4930432K21Rik",
  "4930432L08Rik",
  "4930432M17Rik",
  "4930432O09Rik",
  "4930433I11Rik",
  "4930433N12Rik",
  "4930434B07Rik",
  "4930434J06Rik",
  "4930435F18Rik",
  "4930435M08Rik",
  "4930438A08Rik",
  "4930440C22Rik",
  "4930442G15Rik",
  "4930443O20Rik",
  "4930444A19Rik",
  "4930444F02Rik",
  "4930444P10Rik",
  "4930445B16Rik",
  "4930445E18Rik",
  "4930445N18Rik",
  "4930447A16Rik",
  "4930447C04Rik",
  "4930447F04Rik",
  "4930447F24Rik",
  "4930447J18Rik",
  "4930447N08Rik",
  "4930448A20Rik",
  "4930448E22Rik",
  "4930448F12Rik",
  "4930449C09Rik",
  "4930449E01Rik",
  "4930451E10Rik",
  "4930451I11Rik",
  "4930452A19Rik",
  "4930452B06Rik",
  "4930452G13Rik",
  "4930453H23Rik",
  "4930453L07Rik",
  "4930453N24Rik",
  "4930453O03Rik",
  "4930455C13Rik",
  "4930455H04Rik",
  "4930455J16Rik",
  "4930456L15Rik",
  "4930458A03Rik",
  "4930458D05Rik",
  "4930458K08Rik",
  "4930459C07Rik",
  "4930461C15Rik",
  "4930463O16Rik",
  "4930465M20Rik",
  "4930467D21Rik",
  "4930467J12Rik",
  "4930469B13Rik",
  "4930470G03Rik",
  "4930470H14Rik",
  "4930470P17Rik",
  "4930471D02Rik",
  "4930471E19Rik",
  "4930473A02Rik",
  "4930473D10Rik",
  "4930473H19Rik",
  "4930473O22Rik",
  "4930474H20Rik",
  "4930474M22Rik",
  "4930474N05Rik",
  "4930474N09Rik",
  "4930477E14Rik",
  "4930478E11Rik",
  "4930478K11Rik",
  "4930478M13Rik",
  "4930478P22Rik",
  "4930480E11Rik",
  "4930480G23Rik",
  "4930480K15Rik",
  "4930481A15Rik",
  "4930481B07Rik",
  "4930483J18Rik",
  "4930483K19Rik",
  "4930484H19Rik",
  "4930487D11Rik",
  "4930488B22Rik",
  "4930488N15Rik",
  "4930500A05Rik",
  "4930500F04Rik",
  "4930500H12Rik",
  "4930500M09Rik",
  "4930502A04Rik",
  "4930503B20Rik",
  "4930503E14Rik",
  "4930503L19Rik",
  "4930504D19Rik",
  "4930505A04Rik",
  "4930505M18Rik",
  "4930505N22Rik",
  "4930505O20Rik",
  "4930506C21Rik",
  "4930507D10Rik",
  "4930509E16Rik",
  "4930509J09Rik",
  "4930509K18Rik",
  "4930510E17Rik",
  "4930511A02Rik",
  "4930511E03Rik",
  "4930511J24Rik",
  "4930511M06Rik",
  "4930512B01Rik",
  "4930512H18Rik",
  "4930512J16Rik",
  "4930513L16Rik",
  "4930513N10Rik",
  "4930513O06Rik",
  "4930515G01Rik",
  "4930516K23Rik",
  "4930517E11Rik",
  "4930517E14Rik",
  "4930518C09Rik",
  "4930518P08Rik",
  "4930519A11Rik",
  "4930519D14Rik",
  "4930519F24Rik",
  "4930519G04Rik",
  "4930519H02Rik",
  "4930519L02Rik",
  "4930519P11Rik",
  "4930520O04Rik",
  "4930520P13Rik",
  "4930522H14Rik",
  "4930522L14Rik",
  "4930522N08Rik",
  "4930522O17Rik",
  "4930523C07Rik",
  "4930523O13Rik",
  "4930524B15Rik",
  "4930524B17Rik",
  "4930524C18Rik",
  "4930524O08Rik",
  "4930525G20Rik",
  "4930527F14Rik",
  "4930527J03Rik",
  "4930528D03Rik",
  "4930528G23Rik",
  "4930528H21Rik",
  "4930529I22Rik",
  "4930529K09Rik",
  "4930532I03Rik",
  "4930533B01Rik",
  "4930533K18Rik",
  "4930534H18Rik",
  "4930539C22Rik",
  "4930539J05Rik",
  "4930540M05Rik",
  "4930542C16Rik",
  "4930542D17Rik",
  "4930543E12Rik",
  "4930543N07Rik",
  "4930544D05Rik",
  "4930544G11Rik",
  "4930544I03Rik",
  "4930544M13Rik",
  "4930545H06Rik",
  "4930545L08Rik",
  "4930545L23Rik",
  "4930546C10Rik",
  "4930547H16Rik",
  "4930547M16Rik",
  "4930548G14Rik",
  "4930548H24Rik",
  "4930549C15Rik",
  "4930550C14Rik",
  "4930550C17Rik",
  "4930550L24Rik",
  "4930551O13Rik",
  "4930553J12Rik",
  "4930553M12Rik",
  "4930553P18Rik",
  "4930554C24Rik",
  "4930554G24Rik",
  "4930554H23Rik",
  "4930555B11Rik",
  "4930555F03Rik",
  "4930556J24Rik",
  "4930556L07Rik",
  "4930556M19Rik",
  "4930556N09Rik",
  "4930556N13Rik",
  "4930557J02Rik",
  "4930558C23Rik",
  "4930558J22Rik",
  "4930558K02Rik",
  "4930558N11Rik",
  "4930562A09Rik",
  "4930562C15Rik",
  "4930562D21Rik",
  "4930562F07Rik",
  "4930562F17Rik",
  "4930563E18Rik",
  "4930563E22Rik",
  "4930563F08Rik",
  "4930563J15Rik",
  "4930563M21Rik",
  "4930564D02Rik",
  "4930565D16Rik",
  "4930565N06Rik",
  "4930567H12Rik",
  "4930567H17Rik",
  "4930567K12Rik",
  "4930568D16Rik",
  "4930568E12Rik",
  "4930568G15Rik",
  "4930570E03Rik",
  "4930570G19Rik",
  "4930571K23Rik",
  "4930572G02Rik",
  "4930577H14Rik",
  "4930578E11Rik",
  "4930578G10Rik",
  "4930578I06Rik",
  "4930578M01Rik",
  "4930578N18Rik",
  "4930579C12Rik",
  "4930579D07Rik",
  "4930579G18Rik",
  "4930579G24Rik",
  "4930579H20Rik",
  "4930579K19Rik",
  "4930579P08Rik",
  "4930580E04Rik",
  "4930581F22Rik",
  "4930583I09Rik",
  "4930583K01Rik",
  "4930584F24Rik",
  "4930588A03Rik",
  "4930588G17Rik",
  "4930588J15Rik",
  "4930588K23Rik",
  "4930589L23Rik",
  "4930590J08Rik",
  "4930590L14Rik",
  "4930591A17Rik",
  "4930592A05Rik",
  "4930592I03Rik",
  "4930593A02Rik",
  "4930594M17Rik",
  "4930594M22Rik",
  "4930595D18Rik",
  "4930595M18Rik",
  "4930596D02Rik",
  "4930597G03Rik",
  "4930597O21Rik",
  "4931400O07Rik",
  "4931406B18Rik",
  "4931406C07Rik",
  "4931406P16Rik",
  "4931407E12Rik",
  "4931408C20Rik",
  "4931413K12Rik",
  "4931414P19Rik",
  "4931415C17Rik",
  "4931417E11Rik",
  "4931419H13Rik",
  "4931422A03Rik",
  "4931428F04Rik",
  "4931429L15Rik",
  "4931429P17Rik",
  "4931431B13Rik",
  "4931431C16Rik",
  "4931439C15Rik",
  "4932411K12Rik",
  "4932411N23Rik",
  "4932412D23Rik",
  "4932413F04Rik",
  "4932414J04Rik",
  "4932414N04Rik",
  "4932415D10Rik",
  "4932416K20Rik",
  "4932422M17Rik",
  "4932429P05Rik",
  "4932435O22Rik",
  "4932438A13Rik",
  "4932438H23Rik",
  "4932441J04Rik",
  "4932443L11Rik",
  "4933400A11Rik",
  "4933400C23Rik",
  "4933400F21Rik",
  "4933401D09Rik",
  "4933401H06Rik",
  "4933401L05Rik",
  "4933402C06Rik",
  "4933402D24Rik",
  "4933402E13Rik",
  "4933402J07Rik",
  "4933402J15Rik",
  "4933402N03Rik",
  "4933403O08Rik",
  "4933404G15Rik",
  "4933404I11Rik",
  "4933404K08Rik",
  "4933404K13Rik",
  "4933404O12Rik",
  "4933405D12Rik",
  "4933405E24Rik",
  "4933405L10Rik",
  "4933405O20Rik",
  "4933406B15Rik",
  "4933406B17Rik",
  "4933406C10Rik",
  "4933406G16Rik",
  "4933406I18Rik",
  "4933406K04Rik",
  "4933406P04Rik",
  "4933407I05Rik",
  "4933407K13Rik",
  "4933407L21Rik",
  "4933408J17Rik",
  "4933408N05Rik",
  "4933409D19Rik",
  "4933409F18Rik",
  "4933409G03Rik",
  "4933411E02Rik",
  "4933411G06Rik",
  "4933411K16Rik",
  "4933411O13Rik",
  "4933412E12Rik",
  "4933412E24Rik",
  "4933412O06Rik",
  "4933413C19Rik",
  "4933413J09Rik",
  "4933414I15Rik",
  "4933415A04Rik",
  "4933416E03Rik",
  "4933416O17Rik",
  "4933417A18Rik",
  "4933417E11Rik",
  "4933417O13Rik",
  "4933421D24Rik",
  "4933421I07Rik",
  "4933421O10Rik",
  "4933422A05Rik",
  "4933423P22Rik",
  "4933424G05Rik",
  "4933424G06Rik",
  "4933424M12Rik",
  "4933425B07Rik",
  "4933425D22Rik",
  "4933426B08Rik",
  "4933426K07Rik",
  "4933427D14Rik",
  "4933427E11Rik",
  "4933427E13Rik",
  "4933427I22Rik",
  "4933427J07Rik",
  "4933428G20Rik",
  "4933428M09Rik",
  "4933429O19Rik",
  "4933430A20Rik",
  "4933430H16Rik",
  "4933430I17Rik",
  "4933430L12Rik",
  "4933430N04Rik",
  "4933431J24Rik",
  "4933431K23Rik",
  "4933431M02Rik",
  "4933432I03Rik",
  "4933432I09Rik",
  "4933432K03Rik",
  "4933433F19Rik",
  "4933433G08Rik",
  "4933433G15Rik",
  "4933433G19Rik",
  "4933433H22Rik",
  "4933434E20Rik",
  "4933434M16Rik",
  "4933434P08Rik",
  "4933435F18Rik",
  "4933435G04Rik",
  "4933436E23Rik",
  "4933436H12Rik",
  "4933436I01Rik",
  "4933436I20Rik",
  "4933438B17Rik",
  "4933438K21Rik",
  "4933439C10Rik",
  "4933439N14Rik",
  "4933440J02Rik",
  "4933440M02Rik",
  "5031425E22Rik",
  "5031434C07Rik",
  "5031434O11Rik",
  "5031439G07Rik",
  "5033403F01Rik",
  "5033406O09Rik",
  "5033417F24Rik",
  "5033421B08Rik",
  "5033428I22Rik",
  "5033430I15Rik",
  "5330411J11Rik",
  "5330411O13Rik",
  "5330416C01Rik",
  "5330417C22Rik",
  "5330426L24Rik",
  "5330434G04Rik",
  "5330438D12Rik",
  "5330439A09Rik",
  "5330439B14Rik",
  "5330439K02Rik",
  "5430400D12Rik",
  "5430401H09Rik",
  "5430402P08Rik",
  "5430403G16Rik",
  "5430405H02Rik",
  "5430416N02Rik",
  "5430425K12Rik",
  "5430427M07Rik",
  "5430427O19Rik",
  "5430431A17Rik",
  "5430434I15Rik",
  "5530401A14Rik",
  "5530601H04Rik",
  "5730403I07Rik",
  "5730405O15Rik",
  "5730409E04Rik",
  "5730412P04Rik",
  "5730414N17Rik",
  "5730419F03Rik",
  "5730420D15Rik",
  "5730455P16Rik",
  "5730460C07Rik",
  "5730480H06Rik",
  "5730507C01Rik",
  "5730522E02Rik",
  "5830408C22Rik",
  "5830411N06Rik",
  "5830416I19Rik",
  "5830428M24Rik",
  "5830432E09Rik",
  "5830444B04Rik",
  "5830454E08Rik",
  "5830468F06Rik",
  "5830487J09Rik",
  "5930403N24Rik",
  "5930430L01Rik",
  "5930438M14Rik",
  "6030407O03Rik",
  "6030440G07Rik",
  "6030443J06Rik",
  "6030445D17Rik",
  "6030452D12Rik",
  "6030468B19Rik",
  "6030469F06Rik",
  "6030498E09Rik",
  "6230400D17Rik",
  "6330403K07Rik",
  "6330403L08Rik",
  "6330409D20Rik",
  "6330411D24Rik",
  "6330415G19Rik",
  "6330418K02Rik",
  "6330562C20Rik",
  "6330576A10Rik",
  "6430503K07Rik",
  "6430548M08Rik",
  "6430550D23Rik",
  "6430553K19Rik",
  "6430562O15Rik",
  "6430571L13Rik",
  "6430573P05Rik",
  "6430584L05Rik",
  "6430590A07Rik",
  "6430628N08Rik",
  "6430710C18Rik",
  "6530401F13Rik",
  "6530402F18Rik",
  "6530403H02Rik",
  "6530409C15Rik",
  "6530411M01Rik",
  "6720427I07Rik",
  "6720483E21Rik",
  "6720489N17Rik",
  "6820408C15Rik",
  "6820426E19Rik",
  "7420426K07Rik",
  "7420700N18Rik",
  "7630403G23Rik",
  "8030423F21Rik",
  "8030423J24Rik",
  "8030451A03Rik",
  "8030453O22Rik",
  "8030456M14Rik",
  "8030462N17Rik",
  "8030474K03Rik",
  "8430419K02Rik",
  "8430422H06Rik",
  "8430423G03Rik",
  "8430426J06Rik",
  "8430429K09Rik",
  "8430430B14Rik",
  "8430432A02Rik",
  "8430436N08Rik",
  "8430437L04Rik",
  "9030404E10Rik",
  "9030407P20Rik",
  "9030612E09Rik",
  "9030622O22Rik",
  "9030625G05Rik",
  "9130008F23Rik",
  "9130015A21Rik",
  "9130015G15Rik",
  "9130016M20Rik",
  "9130017K11Rik",
  "9130019O22Rik",
  "9130019P16Rik",
  "9130023H24Rik",
  "9130024F11Rik",
  "9130209A04Rik",
  "9130213A22Rik",
  "9130227L01Rik",
  "9130230L23Rik",
  "9130230N09Rik",
  "9130401M01Rik",
  "9130409I23Rik",
  "9130410C08Rik",
  "9130604C24Rik",
  "9230009I02Rik",
  "9230019H11Rik",
  "9230020A06Rik",
  "9230102K24Rik",
  "9230102O04Rik",
  "9230109A22Rik",
  "9230111E07Rik",
  "9230112D13Rik",
  "9230112J17Rik",
  "9230114J08Rik",
  "9230114K14Rik",
  "9230116L04Rik",
  "9230116N13Rik",
  "9330102E08Rik",
  "9330118I20Rik",
  "9330151L19Rik",
  "9330154J02Rik",
  "9330158H04Rik",
  "9330159F19Rik",
  "9330159M07Rik",
  "9330160F10Rik",
  "9330162012Rik",
  "9330162B11Rik",
  "9330171B17Rik",
  "9330175E14Rik",
  "9330175M20Rik",
  "9330178D15Rik",
  "9330179D12Rik",
  "9330182L06Rik",
  "9330185C12Rik",
  "9330188P03Rik",
  "9330198I05Rik",
  "9330198N18Rik",
  "9330199G10Rik",
  "9430002A10Rik",
  "9430014N10Rik",
  "9430015G10Rik",
  "9430018G01Rik",
  "9430021M05Rik",
  "9430024E24Rik",
  "9430037G07Rik",
  "9430038I01Rik",
  "9430041J12Rik",
  "9430060I03Rik",
  "9430065F17Rik",
  "9430069I07Rik",
  "9430078K24Rik",
  "9430097D07Rik",
  "9530003J23Rik",
  "9530020I12Rik",
  "9530026P05Rik",
  "9530034A14Rik",
  "9530036M11Rik",
  "9530036O11Rik",
  "9530046B11Rik",
  "9530051G07Rik",
  "9530053A07Rik",
  "9530059O14Rik",
  "9530062K07Rik",
  "9530068E07Rik",
  "9530077C05Rik",
  "9530080O11Rik",
  "9530082P21Rik",
  "9530097N15Rik",
  "9630001P10Rik",
  "9630009A06Rik",
  "9630013A20Rik",
  "9630013D21Rik",
  "9630013K17Rik",
  "9630014M24Rik",
  "9630028B13Rik",
  "9630028H03Rik",
  "9630028I04Rik",
  "9630041A04Rik",
  "9830107B12Rik",
  "9830166K06Rik",
  "9930012K11Rik",
  "9930014A18Rik",
  "9930021J03Rik",
  "9930022D16Rik",
  "9930038B18Rik",
  "9930104L06Rik",
  "A030001D20Rik",
  "A030003K21Rik",
  "A030005K14Rik",
  "A030005L19Rik",
  "A030014E15Rik",
  "A130010J15Rik",
  "A130014A01Rik",
  "A130023I24Rik",
  "A130030D18Rik",
  "A130051J06Rik",
  "A1bg",
  "A230001M10Rik",
  "A230004M16Rik",
  "A230009B12Rik",
  "A230028O05Rik",
  "A230051N06Rik",
  "A230057D06Rik",
  "A230059L01Rik",
  "A230065N10Rik",
  "A230072C01Rik",
  "A230072E10Rik",
  "A230072I06Rik",
  "A230083G16Rik",
  "A230098N10Rik",
  "A230103O09Rik",
  "A230107N01Rik",
  "A26c2",
  "A26c3",
  "A2m",
  "A2ml1",
  "A330008L17Rik",
  "A330009N23Rik",
  "A330015K06Rik",
  "A330023F24Rik",
  "A330032B11Rik",
  "A330033J07Rik",
  "A330041J22Rik",
  "A330043C09Rik",
  "A330049N07Rik",
  "A330069K06Rik",
  "A330070K13Rik",
  "A330072L02Rik",
  "A330074K22Rik",
  "A330076H08Rik",
  "A330084C13Rik",
  "A330093E20Rik",
  "A330094K24Rik",
  "A330102I10Rik",
  "A3galt2",
  "A430005L14Rik",
  "A430027H14Rik",
  "A430033K04Rik",
  "A430034D21Rik",
  "A430035B10Rik",
  "A430048G15Rik",
  "A430088P11Rik",
  "A430090L17Rik",
  "A430093F15Rik",
  "A430105J06Rik",
  "A430110L20Rik",
  "A4gnt",
  "A530001N23Rik",
  "A530006G24Rik",
  "A530010F05Rik",
  "A530010L16Rik",
  "A530013C23Rik",
  "A530016L24Rik",
  "A530017D24Rik",
  "A530020G20Rik",
  "A530021J07Rik",
  "A530032D15Rik",
  "A530041M06Rik",
  "A530053G22Rik",
  "A530058N18Rik",
  "A530064D06Rik",
  "A530065N20Rik",
  "A530072M11Rik",
  "A530076I17Rik",
  "A530083I20Rik",
  "A530088E08Rik",
  "A530095I07Rik",
  "A630001G21Rik",
  "A630010A05Rik",
  "A630012P03Rik",
  "A630014C17Rik",
  "A630023A22Rik",
  "A630023P12Rik",
  "A630052C17Rik",
  "A630072L19Rik",
  "A630072M18Rik",
  "A630073D07Rik",
  "A630076J17Rik",
  "A630089N07Rik",
  "A630091E08Rik",
  "A630095N17Rik",
  "A730009L09Rik",
  "A730011C13Rik",
  "A730017L22Rik",
  "A730018C14Rik",
  "A730020E08Rik",
  "A730020M07Rik",
  "A730032A03Rik",
  "A730036I17Rik",
  "A730045E13Rik",
  "A730046J19Rik",
  "A730049H05Rik",
  "A730056A06Rik",
  "A730061H03Rik",
  "A730063M14Rik",
  "A730081D07Rik",
  "A730082K24Rik",
  "A730085E03Rik",
  "A830005F24Rik",
  "A830009L08Rik",
  "A830012C17Rik",
  "A830018L16Rik",
  "A830019P07Rik",
  "A830029E22Rik",
  "A830031A19Rik",
  "A830035O19Rik",
  "A830052D11Rik",
  "A830082K12Rik",
  "A830082N09Rik",
  "A830092H15Rik",
  "A930001A20Rik",
  "A930002H24Rik",
  "A930002I21Rik",
  "A930003A15Rik",
  "A930004D18Rik",
  "A930005H10Rik",
  "A930006I01Rik",
  "A930007I19Rik",
  "A930009A15Rik",
  "A930012O16Rik",
  "A930015D03Rik",
  "A930016O22Rik",
  "A930017K11Rik",
  "A930018P22Rik",
  "A930019D19Rik",
  "A930023M06Rik",
  "A930024E05Rik",
  "A930028N01Rik",
  "A930029G22Rik",
  "A930033H14Rik",
  "A930038B10Rik",
  "A930104D05Rik",
  "AA387883",
  "AA465934",
  "AA467197",
  "AA545190",
  "AA792892",
  "AA986860",
  "AAdacl4fm3",
  "AB041806",
  "AB124611",
  "AC106834.1",
  "AC142100.1",
  "AC160336.1",
  "AC160966.1",
  "AC241534.1",
  "AF366264",
  "AI182371",
  "AI197445",
  "AI413582",
  "AI427809",
  "AI429214",
  "AI463229",
  "AI467606",
  "AI480526",
  "AI504432",
  "AI506816",
  "AI507597",
  "AI593442",
  "AI597479",
  "AI646519",
  "AI661453",
  "AI837181",
  "AI838599",
  "AI847159",
  "AI849053",
  "AI854703",
  "AI987944",
  "AU015791",
  "AU015836",
  "AU016765",
  "AU017674",
  "AU018091",
  "AU019990",
  "AU021092",
  "AU022252",
  "AU022751",
  "AU022793",
  "AU023762",
  "AU040320",
  "AU040972",
  "AU041133",
  "AV099323",
  "AV205837",
  "AV356131",
  "AW011738",
  "AW046200",
  "AW112010",
  "AW146154",
  "AW209491",
  "AW549877",
  "AW551984",
  "AW554918",
  "AY074887",
  "AY358078",
  "AY512915",
  "AY512931",
  "AY702103",
  "AY761185",
  "Aaas",
  "Aacs",
  "Aadac",
  "Aadacl2",
  "Aadacl2fm1",
  "Aadacl2fm2",
  "Aadacl2fm3",
  "Aadacl3",
  "Aadacl4",
  "Aadacl4fm1",
  "Aadacl4fm2",
  "Aadacl4fm4",
  "Aadacl4fm5",
  "Aadat",
  "Aagab",
  "Aak1",
  "Aamdc",
  "Aamp",
  "Aanat",
  "Aar2",
  "Aard",
  "Aars",
  "Aars2",
  "Aasdh",
  "Aasdhppt",
  "Aass",
  "Aatf",
  "Abat",
  "Abca1",
  "Abca12",
  "Abca13",
  "Abca14",
  "Abca15",
  "Abca16",
  "Abca17",
  "Abca2",
  "Abca3",
  "Abca4",
  "Abca5",
  "Abca6",
  "Abca7",
  "Abca8a",
  "Abca8b",
  "Abca9",
  "Abcb10",
  "Abcb11",
  "Abcb1a",
  "Abcb1b",
  "Abcb4",
  "Abcb5",
  "Abcb7",
  "Abcb8",
  "Abcb9",
  "Abcc1",
  "Abcc10",
  "Abcc12",
  "Abcc2",
  "Abcc3",
  "Abcc4",
  "Abcc5",
  "Abcc6",
  "Abcc8",
  "Abcc9",
  "Abcd1",
  "Abcd3",
  "Abcd4",
  "Abce1",
  "Abcf1",
  "Abcf2",
  "Abcf3",
  "Abcg1",
  "Abcg2",
  "Abcg3",
  "Abcg4",
  "Abcg5",
  "Abcg8",
  "Abhd10",
  "Abhd11",
  "Abhd12",
  "Abhd12b",
  "Abhd13",
  "Abhd14a",
  "Abhd14b",
  "Abhd15",
  "Abhd16a",
  "Abhd16b",
  "Abhd17a",
  "Abhd17b",
  "Abhd17c",
  "Abhd18",
  "Abhd2",
  "Abhd3",
  "Abhd4",
  "Abhd5",
  "Abhd6",
  "Abhd8",
  "Abi1",
  "Abi2",
  "Abi3",
  "Abi3bp",
  "Abl1",
  "Abl2",
  "Ablim1",
  "Ablim2",
  "Ablim3",
  "Abo",
  "Abr",
  "Abra",
  "Abracl",
  "Abraxas1",
  "Abraxas2",
  "Abt1",
  "Abtb1",
  "Abtb2",
  "Acaa1a",
  "Acaa1b",
  "Acaa2",
  "Acaca",
  "Acacb",
  "Acad10",
  "Acad11",
  "Acad12",
  "Acad8",
  "Acadl",
  "Acadm",
  "Acads",
  "Acadsb",
  "Acadvl",
  "Acan",
  "Acap1",
  "Acap2",
  "Acap3",
  "Acat1",
  "Acat2",
  "Acat3",
  "Acbd3",
  "Acbd4",
  "Acbd5",
  "Acbd6",
  "Acbd7",
  "Accs",
  "Accsl",
  "Acd",
  "Ace",
  "Ace2",
  "Ace3",
  "Acer1",
  "Acer2",
  "Acer3",
  "Ache",
  "Acin1",
  "Ackr1",
  "Ackr2",
  "Ackr3",
  "Ackr4",
  "Acly",
  "Acmsd",
  "Acnat1",
  "Acnat2",
  "Aco2",
  "Acod1",
  "Acot1",
  "Acot10",
  "Acot11",
  "Acot12",
  "Acot13",
  "Acot2",
  "Acot4",
  "Acot5",
  "Acot6",
  "Acot7",
  "Acot8",
  "Acot9",
  "Acox1",
  "Acox2",
  "Acox3",
  "Acoxl",
  "Acp1",
  "Acp2",
  "Acp4",
  "Acp5",
  "Acp6",
  "Acp7",
  "Acpp",
  "Acr",
  "Acrbp",
  "Acrv1",
  "Acsbg1",
  "Acsbg2",
  "Acsbg3",
  "Acsf2",
  "Acsf3",
  "Acsl1",
  "Acsl3",
  "Acsl4",
  "Acsl5",
  "Acsl6",
  "Acsm1",
  "Acsm2",
  "Acsm3",
  "Acsm4",
  "Acsm5",
  "Acss1",
  "Acss2",
  "Acss3",
  "Acta1",
  "Acta2",
  "Actb",
  "Actbl2",
  "Actc1",
  "Acte1",
  "Actg2",
  "Actl10",
  "Actl11",
  "Actl6a",
  "Actl6b",
  "Actl7a",
  "Actl7b",
  "Actl9",
  "Actn1",
  "Actn2",
  "Actn3",
  "Actn4",
  "Actr10",
  "Actr1a",
  "Actr1b",
  "Actr2",
  "Actr3b",
  "Actr5",
  "Actr6",
  "Actr8",
  "Actrt1",
  "Actrt2",
  "Actrt3",
  "Acvr1",
  "Acvr1b",
  "Acvr1c",
  "Acvr2a",
  "Acvr2b",
  "Acvrl1",
  "Acy1",
  "Acy3",
  "Acyp1",
  "Acyp2",
  "Ada",
  "Adad1",
  "Adad2",
  "Adal",
  "Adam10",
  "Adam11",
  "Adam12",
  "Adam15",
  "Adam17",
  "Adam18",
  "Adam19",
  "Adam1a",
  "Adam1b",
  "Adam2",
  "Adam20",
  "Adam21",
  "Adam22",
  "Adam23",
  "Adam24",
  "Adam25",
  "Adam26a",
  "Adam26b",
  "Adam28",
  "Adam29",
  "Adam3",
  "Adam30",
  "Adam32",
  "Adam33",
  "Adam34",
  "Adam34l",
  "Adam39",
  "Adam4",
  "Adam5",
  "Adam6a",
  "Adam6b",
  "Adam7",
  "Adam8",
  "Adam9",
  "Adamdec1",
  "Adamts1",
  "Adamts10",
  "Adamts12",
  "Adamts13",
  "Adamts14",
  "Adamts15",
  "Adamts16",
  "Adamts17",
  "Adamts18",
  "Adamts19",
  "Adamts2",
  "Adamts20",
  "Adamts3",
  "Adamts4",
  "Adamts5",
  "Adamts6",
  "Adamts7",
  "Adamts8",
  "Adamts9",
  "Adamtsl1",
  "Adamtsl2",
  "Adamtsl3",
  "Adamtsl4",
  "Adamtsl5",
  "Adap1",
  "Adap2",
  "Adar",
  "Adarb1",
  "Adarb2",
  "Adat1",
  "Adat2",
  "Adck1",
  "Adck5",
  "Adcy10",
  "Adcy2",
  "Adcy3",
  "Adcy4",
  "Adcy5",
  "Adcy6",
  "Adcy7",
  "Adcy8",
  "Adcy9",
  "Adcyap1",
  "Adcyap1r1",
  "Add1",
  "Add2",
  "Add3",
  "Adgb",
  "Adgra1",
  "Adgra2",
  "Adgra3",
  "Adgrb1",
  "Adgrb2",
  "Adgrb3",
  "Adgrd1",
  "Adgre1",
  "Adgre4",
  "Adgre5",
  "Adgrf1",
  "Adgrf2",
  "Adgrf3",
  "Adgrf4",
  "Adgrf5",
  "Adgrg1",
  "Adgrg2",
  "Adgrg3",
  "Adgrg4",
  "Adgrg5",
  "Adgrg6",
  "Adgrg7",
  "Adgrl1",
  "Adgrl2",
  "Adgrl3",
  "Adgrl4",
  "Adgrv1",
  "Adh1",
  "Adh4",
  "Adh5",
  "Adh6a",
  "Adh7",
  "Adhfe1",
  "Adi1",
  "Adig",
  "Adipoq",
  "Adipor1",
  "Adipor2",
  "Adk",
  "Adm",
  "Adm2",
  "Adnp2",
  "Ado",
  "Adora1",
  "Adora2a",
  "Adora2b",
  "Adora3",
  "Adpgk",
  "Adprh",
  "Adprhl1",
  "Adprhl2",
  "Adprm",
  "Adra1a",
  "Adra1d",
  "Adra2a",
  "Adra2c",
  "Adrb1",
  "Adrb2",
  "Adrb3",
  "Adsl",
  "Adss",
  "Adssl1",
  "Adtrp",
  "Aebp1",
  "Aebp2",
  "Aen",
  "Afap1",
  "Afap1l1",
  "Afap1l2",
  "Afdn",
  "Aff1",
  "Aff2",
  "Aff3",
  "Aff4",
  "Afg1l",
  "Afg3l1",
  "Afg3l2",
  "Afm",
  "Afp",
  "Aftph",
  "Aga",
  "Agap1",
  "Agap2",
  "Agap3",
  "Agbl1",
  "Agbl2",
  "Agbl3",
  "Agbl4",
  "Agbl5",
  "Ager",
  "Agfg1",
  "Agfg2",
  "Aggf1",
  "Agk",
  "Agl",
  "Agmat",
  "Agmo",
  "Ago1",
  "Ago2",
  "Ago3",
  "Ago4",
  "Agpat1",
  "Agpat2",
  "Agpat3",
  "Agpat4",
  "Agpat5",
  "Agps",
  "Agr2",
  "Agr3",
  "Agrn",
  "Agrp",
  "Agtpbp1",
  "Agtr1a",
  "Agtr1b",
  "Agtr2",
  "Agtrap",
  "Agxt",
  "Agxt2",
  "Ahctf1",
  "Ahcy",
  "Ahcyl1",
  "Ahcyl2",
  "Ahdc1",
  "Ahi1",
  "Ahnak",
  "Ahnak2",
  "Ahr",
  "Ahrr",
  "Ahsa2",
  "Ahsg",
  "Aicda",
  "Aida",
  "Aif1",
  "Aif1l",
  "Aifm1",
  "Aifm2",
  "Aifm3",
  "Aig1",
  "Aim2",
  "Aimp1",
  "Aimp2",
  "Aip",
  "Aipl1",
  "Aire",
  "Airn",
  "Ajap1",
  "Ajm1",
  "Ajuba",
  "Ak1",
  "Ak2",
  "Ak3",
  "Ak4",
  "Ak5",
  "Ak6",
  "Ak7",
  "Ak8",
  "Ak9",
  "Akain1",
  "Akap1",
  "Akap10",
  "Akap11",
  "Akap12",
  "Akap13",
  "Akap14",
  "Akap17b",
  "Akap3",
  "Akap4",
  "Akap5",
  "Akap6",
  "Akap7",
  "Akap8",
  "Akap8l",
  "Akap9",
  "Akip1",
  "Akirin1",
  "Akirin2",
  "Akna",
  "Aknad1",
  "Akp3",
  "Akr1a1",
  "Akr1b10",
  "Akr1b3",
  "Akr1b7",
  "Akr1b8",
  "Akr1c12",
  "Akr1c13",
  "Akr1c14",
  "Akr1c18",
  "Akr1c19",
  "Akr1c20",
  "Akr1c21",
  "Akr1c6",
  "Akr1cl",
  "Akr1d1",
  "Akr1e1",
  "Akr7a5",
  "Akt1",
  "Akt1s1",
  "Akt2",
  "Akt3",
  "Aktip",
  "Alad",
  "Alas1",
  "Alas2",
  "Alb",
  "Albfm1",
  "Alcam",
  "Aldh16a1",
  "Aldh18a1",
  "Aldh1a1",
  "Aldh1a2",
  "Aldh1a3",
  "Aldh1a7",
  "Aldh1b1",
  "Aldh1l1",
  "Aldh1l2",
  "Aldh2",
  "Aldh3a1",
  "Aldh3a2",
  "Aldh3b1",
  "Aldh3b2",
  "Aldh3b3",
  "Aldh4a1",
  "Aldh5a1",
  "Aldh6a1",
  "Aldh7a1",
  "Aldh8a1",
  "Aldh9a1",
  "Aldoa",
  "Aldoart1",
  "Aldoart2",
  "Aldob",
  "Aldoc",
  "Alg1",
  "Alg10b",
  "Alg11",
  "Alg12",
  "Alg13",
  "Alg14",
  "Alg2",
  "Alg3",
  "Alg5",
  "Alg6",
  "Alg8",
  "Alg9",
  "Alk",
  "Alkal1",
  "Alkal2",
  "Alkbh1",
  "Alkbh2",
  "Alkbh3",
  "Alkbh3os1",
  "Alkbh4",
  "Alkbh5",
  "Alkbh6",
  "Alkbh7",
  "Alkbh8",
  "Allc",
  "Alms1",
  "Alox12",
  "Alox12b",
  "Alox12e",
  "Alox15",
  "Alox5",
  "Alox5ap",
  "Aloxe3",
  "Alpi",
  "Alpk1",
  "Alpk2",
  "Alpk3",
  "Alpl",
  "Alppl2",
  "Als2",
  "Als2cl",
  "Alx1",
  "Alx3",
  "Alx4",
  "Alyref",
  "Alyref2",
  "Amacr",
  "Ambn",
  "Ambp",
  "Ambra1",
  "Amdhd1",
  "Amdhd2",
  "Amelx",
  "Amer1",
  "Amer2",
  "Amer3",
  "Amfr",
  "Amh",
  "Amhr2",
  "Amigo1",
  "Amigo2",
  "Amigo3",
  "Ammecr1",
  "Ammecr1l",
  "Amn",
  "Amn1",
  "Amot",
  "Amotl1",
  "Amotl2",
  "Ampd1",
  "Ampd2",
  "Ampd3",
  "Amph",
  "Amt",
  "Amtn",
  "Amy1",
  "Amy2a5",
  "Amz1",
  "Amz2",
  "Anapc1",
  "Anapc10",
  "Anapc11",
  "Anapc13",
  "Anapc15",
  "Anapc16",
  "Anapc2",
  "Anapc4",
  "Anapc5",
  "Anapc7",
  "Ang",
  "Ang2",
  "Ang4",
  "Ang5",
  "Angel1",
  "Angel2",
  "Angpt1",
  "Angpt2",
  "Angpt4",
  "Angptl1",
  "Angptl3",
  "Angptl4",
  "Angptl6",
  "Angptl7",
  "Angptl8",
  "Ank",
  "Ank2",
  "Ank3",
  "Ankar",
  "Ankdd1a",
  "Ankdd1b",
  "Ankef1",
  "Ankfn1",
  "Ankfy1",
  "Ankhd1",
  "Ankib1",
  "Ankk1",
  "Ankle1",
  "Ankle2",
  "Ankmy1",
  "Ankmy2",
  "Ankra2",
  "Ankrd1",
  "Ankrd10",
  "Ankrd11",
  "Ankrd13a",
  "Ankrd13b",
  "Ankrd13c",
  "Ankrd13d",
  "Ankrd16",
  "Ankrd17",
  "Ankrd2",
  "Ankrd22",
  "Ankrd23",
  "Ankrd24",
  "Ankrd26",
  "Ankrd27",
  "Ankrd28",
  "Ankrd29",
  "Ankrd33",
  "Ankrd33b",
  "Ankrd34a",
  "Ankrd34b",
  "Ankrd34c",
  "Ankrd35",
  "Ankrd36",
  "Ankrd37",
  "Ankrd39",
  "Ankrd40",
  "Ankrd42",
  "Ankrd44",
  "Ankrd45",
  "Ankrd46",
  "Ankrd49",
  "Ankrd50",
  "Ankrd52",
  "Ankrd53",
  "Ankrd54",
  "Ankrd55",
  "Ankrd6",
  "Ankrd60",
  "Ankrd61",
  "Ankrd63",
  "Ankrd65",
  "Ankrd66",
  "Ankrd7",
  "Ankrd9",
  "Anks1",
  "Anks1b",
  "Anks3",
  "Anks4b",
  "Anks6",
  "Ankub1",
  "Ankzf1",
  "Anln",
  "Ano1",
  "Ano10",
  "Ano2",
  "Ano3",
  "Ano4",
  "Ano5",
  "Ano6",
  "Ano7",
  "Ano8",
  "Ano9",
  "Anp32a",
  "Anp32b",
  "Anp32e",
  "Anpep",
  "Antxr1",
  "Antxr2",
  "Antxrl",
  "Anxa1",
  "Anxa10",
  "Anxa11",
  "Anxa11os",
  "Anxa13",
  "Anxa2",
  "Anxa3",
  "Anxa4",
  "Anxa5",
  "Anxa6",
  "Anxa7",
  "Anxa8",
  "Anxa9",
  "Aoah",
  "Aoc1",
  "Aoc2",
  "Aoc3",
  "Aopep",
  "Aox1",
  "Aox2",
  "Aox3",
  "Aox4",
  "Ap1ar",
  "Ap1b1",
  "Ap1g1",
  "Ap1g2",
  "Ap1m1",
  "Ap1m2",
  "Ap1s1",
  "Ap1s2",
  "Ap1s3",
  "Ap2a1",
  "Ap2a2",
  "Ap2b1",
  "Ap2m1",
  "Ap2s1",
  "Ap3b1",
  "Ap3b2",
  "Ap3d1",
  "Ap3m1",
  "Ap3m2",
  "Ap3s1",
  "Ap3s2",
  "Ap4b1",
  "Ap4e1",
  "Ap4m1",
  "Ap4s1",
  "Ap5b1",
  "Ap5m1",
  "Ap5s1",
  "Ap5z1",
  "Apaf1",
  "Apba1",
  "Apba2",
  "Apba3",
  "Apbb1",
  "Apbb1ip",
  "Apbb2",
  "Apbb3",
  "Apc",
  "Apc2",
  "Apcdd1",
  "Apcs",
  "Apeh",
  "Apela",
  "Apex1",
  "Apex2",
  "Aph1a",
  "Aph1b",
  "Apip",
  "Aplf",
  "Apln",
  "Aplnr",
  "Aplp1",
  "Aplp2",
  "Apmap",
  "Apoa1",
  "Apoa2",
  "Apoa4",
  "Apoa5",
  "Apob",
  "Apobec1",
  "Apobec2",
  "Apobec3",
  "Apobec4",
  "Apobr",
  "Apoc1",
  "Apoc3",
  "Apoc4",
  "Apod",
  "Apoe",
  "Apof",
  "Apoh",
  "Apol10a",
  "Apol10b",
  "Apol11b",
  "Apol6",
  "Apol7a",
  "Apol7c",
  "Apol7d",
  "Apol8",
  "Apol9a",
  "Apol9b",
  "Apold1",
  "Apom",
  "Apon",
  "Apoo",
  "Apool",
  "Apopt1",
  "App",
  "Appbp2",
  "Appbp2os",
  "Appl1",
  "Appl2",
  "Aprt",
  "Aptx",
  "Aqp1",
  "Aqp11",
  "Aqp12",
  "Aqp2",
  "Aqp3",
  "Aqp4",
  "Aqp5",
  "Aqp6",
  "Aqp7",
  "Aqp8",
  "Aqp9",
  "Aqr",
  "Ar",
  "Araf",
  "Arap1",
  "Arap2",
  "Arap3",
  "Arc",
  "Arcn1",
  "Areg",
  "Arel1",
  "Arf1",
  "Arf2",
  "Arf3",
  "Arf4",
  "Arf5",
  "Arf6",
  "Arfgap1",
  "Arfgap2",
  "Arfgap3",
  "Arfgef1",
  "Arfgef2",
  "Arfgef3",
  "Arfip2",
  "Arfrp1",
  "Arg1",
  "Arg2",
  "Arglu1",
  "Arhgap1",
  "Arhgap10",
  "Arhgap11a",
  "Arhgap12",
  "Arhgap15",
  "Arhgap17",
  "Arhgap18",
  "Arhgap19",
  "Arhgap20",
  "Arhgap20os",
  "Arhgap21",
  "Arhgap22",
  "Arhgap23",
  "Arhgap25",
  "Arhgap26",
  "Arhgap27",
  "Arhgap28",
  "Arhgap29",
  "Arhgap30",
  "Arhgap31",
  "Arhgap32",
  "Arhgap33",
  "Arhgap33os",
  "Arhgap35",
  "Arhgap36",
  "Arhgap39",
  "Arhgap4",
  "Arhgap40",
  "Arhgap42",
  "Arhgap44",
  "Arhgap45",
  "Arhgap5",
  "Arhgap6",
  "Arhgap8",
  "Arhgap9",
  "Arhgdia",
  "Arhgdib",
  "Arhgdig",
  "Arhgef1",
  "Arhgef10",
  "Arhgef10l",
  "Arhgef11",
  "Arhgef12",
  "Arhgef15",
  "Arhgef16",
  "Arhgef17",
  "Arhgef18",
  "Arhgef19",
  "Arhgef2",
  "Arhgef25",
  "Arhgef26",
  "Arhgef28",
  "Arhgef3",
  "Arhgef33",
  "Arhgef37",
  "Arhgef38",
  "Arhgef39",
  "Arhgef4",
  "Arhgef40",
  "Arhgef5",
  "Arhgef6",
  "Arhgef7",
  "Arhgef9",
  "Arid1a",
  "Arid1b",
  "Arid2",
  "Arid3a",
  "Arid3b",
  "Arid3c",
  "Arid4a",
  "Arid4b",
  "Arid5a",
  "Arid5b",
  "Arih1",
  "Arih2",
  "Arl1",
  "Arl10",
  "Arl11",
  "Arl13a",
  "Arl13b",
  "Arl14",
  "Arl14ep",
  "Arl14epl",
  "Arl15",
  "Arl2",
  "Arl2bp",
  "Arl3",
  "Arl4a",
  "Arl4aos",
  "Arl4c",
  "Arl4d",
  "Arl5a",
  "Arl5b",
  "Arl5c",
  "Arl6",
  "Arl6ip1",
  "Arl6ip4",
  "Arl6ip5",
  "Arl6ip6",
  "Arl8a",
  "Arl8b",
  "Arl9",
  "Armc1",
  "Armc10",
  "Armc12",
  "Armc2",
  "Armc3",
  "Armc4",
  "Armc5",
  "Armc6",
  "Armc7",
  "Armc8",
  "Armc9",
  "Armcx1",
  "Armcx2",
  "Armcx3",
  "Armcx4",
  "Armcx5",
  "Armh1",
  "Armh2",
  "Armh3",
  "Armh4",
  "Armt1",
  "Arnt",
  "Arnt2",
  "Arntl",
  "Arntl2",
  "Arpc1a",
  "Arpc1b",
  "Arpc2",
  "Arpc3",
  "Arpc4",
  "Arpc5",
  "Arpc5l",
  "Arpin",
  "Arpp19",
  "Arpp21",
  "Arr3",
  "Arrb1",
  "Arrb2",
  "Arrdc1",
  "Arrdc2",
  "Arrdc3",
  "Arrdc4",
  "Arrdc5",
  "Arsa",
  "Arsb",
  "Arsg",
  "Arsi",
  "Arsj",
  "Arsk",
  "Art1",
  "Art2b",
  "Art3",
  "Art4",
  "Art5",
  "Artn",
  "Arv1",
  "Arvcf",
  "Arx",
  "Arxes1",
  "Arxes2",
  "As3mt",
  "Asah1",
  "Asah2",
  "Asap1",
  "Asap2",
  "Asap3",
  "Asb1",
  "Asb10",
  "Asb11",
  "Asb12",
  "Asb13",
  "Asb14",
  "Asb15",
  "Asb16",
  "Asb17",
  "Asb18",
  "Asb2",
  "Asb3",
  "Asb4",
  "Asb5",
  "Asb6",
  "Asb7",
  "Asb8",
  "Asb9",
  "Ascc1",
  "Ascc2",
  "Ascc3",
  "Ascl1",
  "Ascl2",
  "Ascl3",
  "Ascl4",
  "Asf1a",
  "Asf1b",
  "Asgr1",
  "Asgr2",
  "Ash1l",
  "Ash2l",
  "Asic1",
  "Asic2",
  "Asic3",
  "Asic4",
  "Asic5",
  "Asl",
  "Asna1",
  "Asns",
  "Asnsd1",
  "Aspa",
  "Aspdh",
  "Aspg",
  "Asph",
  "Asphd1",
  "Asphd2",
  "Aspm",
  "Aspn",
  "Asprv1",
  "Aspscr1",
  "Asrgl1",
  "Ass1",
  "Aste1",
  "Astl",
  "Astn1",
  "Astn2",
  "Astx3",
  "Asxl1",
  "Asxl2",
  "Asxl3",
  "Asz1",
  "Atad1",
  "Atad2",
  "Atad2b",
  "Atad3a",
  "Atad3aos",
  "Atad5",
  "Atat1",
  "Atcay",
  "Atcayos",
  "Ate1",
  "Atf1",
  "Atf2",
  "Atf3",
  "Atf4",
  "Atf5",
  "Atf6",
  "Atf6b",
  "Atf7",
  "Atf7ip",
  "Atf7ip2",
  "Atg10",
  "Atg101",
  "Atg12",
  "Atg13",
  "Atg14",
  "Atg16l1",
  "Atg16l2",
  "Atg2a",
  "Atg3",
  "Atg4a",
  "Atg4b",
  "Atg4c",
  "Atg4d",
  "Atg5",
  "Atg7",
  "Atg9a",
  "Atg9b",
  "Atic",
  "Atl1",
  "Atl2",
  "Atl3",
  "Atm",
  "Atmin",
  "Atoh1",
  "Atoh7",
  "Atoh8",
  "Atox1",
  "Atp10a",
  "Atp10b",
  "Atp11a",
  "Atp11b",
  "Atp11c",
  "Atp12a",
  "Atp13a1",
  "Atp13a2",
  "Atp13a3",
  "Atp13a4",
  "Atp13a5",
  "Atp1a1",
  "Atp1a2",
  "Atp1a3",
  "Atp1a4",
  "Atp1b1",
  "Atp1b2",
  "Atp1b3",
  "Atp1b4",
  "Atp23",
  "Atp2a1",
  "Atp2a2",
  "Atp2a3",
  "Atp2b1",
  "Atp2b2",
  "Atp2b3",
  "Atp2b4",
  "Atp2c1",
  "Atp2c2",
  "Atp4a",
  "Atp4b",
  "Atp5a1",
  "Atp5b",
  "Atp5c1",
  "Atp5d",
  "Atp5e",
  "Atp5g1",
  "Atp5g3",
  "Atp5h",
  "Atp5j",
  "Atp5j2",
  "Atp5k",
  "Atp5l",
  "Atp5mpl",
  "Atp5pb",
  "Atp6ap1",
  "Atp6ap1l",
  "Atp6ap2",
  "Atp6v0a1",
  "Atp6v0a2",
  "Atp6v0a4",
  "Atp6v0b",
  "Atp6v0c",
  "Atp6v0d1",
  "Atp6v0d2",
  "Atp6v0e",
  "Atp6v0e2",
  "Atp6v1a",
  "Atp6v1b1",
  "Atp6v1b2",
  "Atp6v1c1",
  "Atp6v1c2",
  "Atp6v1d",
  "Atp6v1e1",
  "Atp6v1e2",
  "Atp6v1f",
  "Atp6v1fnb",
  "Atp6v1g1",
  "Atp6v1g2",
  "Atp6v1g3",
  "Atp6v1h",
  "Atp7a",
  "Atp7b",
  "Atp8a1",
  "Atp8a2",
  "Atp8b1",
  "Atp8b2",
  "Atp8b3",
  "Atp8b4",
  "Atp8b5",
  "Atp9a",
  "Atp9b",
  "Atpaf1",
  "Atpaf2",
  "Atpif1",
  "Atpsckmt",
  "Atr",
  "Atraid",
  "Atrip",
  "Atrn",
  "Atrnl1",
  "Atrx",
  "Atxn1",
  "Atxn10",
  "Atxn1l",
  "Atxn2",
  "Atxn2l",
  "Atxn3",
  "Atxn7",
  "Atxn7l1",
  "Atxn7l1os1",
  "Atxn7l1os2",
  "Atxn7l2",
  "Atxn7l3",
  "Atxn7l3b",
  "Auh",
  "Aunip",
  "Aup1",
  "Aurka",
  "Aurkaip1",
  "Aurkb",
  "Auts2",
  "Aven",
  "Avil",
  "Avl9",
  "Avp",
  "Avpi1",
  "Avpr1a",
  "Avpr1b",
  "Avpr2",
  "Awat1",
  "Awat2",
  "Axdnd1",
  "Axin1",
  "Axin2",
  "Axl",
  "Aym1",
  "Azgp1",
  "Azi2",
  "Azin1",
  "Azin2",
  "B020004C17Rik",
  "B020004J07Rik",
  "B020011L13Rik",
  "B020031H02Rik",
  "B130024G19Rik",
  "B130034C11Rik",
  "B130046B21Rik",
  "B230104I21Rik",
  "B230110C06Rik",
  "B230110G15Rik",
  "B230118H07Rik",
  "B230206H07Rik",
  "B230206L02Rik",
  "B230217C12Rik",
  "B230219D22Rik",
  "B230303O12Rik",
  "B230307C23Rik",
  "B230311B06Rik",
  "B230317F23Rik",
  "B230319C09Rik",
  "B230334C09Rik",
  "B230354K17Rik",
  "B230369F24Rik",
  "B230398E01Rik",
  "B2m",
  "B3galnt1",
  "B3galnt2",
  "B3galt1",
  "B3galt2",
  "B3galt4",
  "B3galt5",
  "B3galt6",
  "B3gat1",
  "B3gat2",
  "B3gat3",
  "B3glct",
  "B3gnt3",
  "B3gnt4",
  "B3gnt5",
  "B3gnt6",
  "B3gnt7",
  "B3gnt8",
  "B3gnt9",
  "B3gntl1",
  "B430212C06Rik",
  "B430305J03Rik",
  "B430306N03Rik",
  "B430319H21Rik",
  "B4galnt1",
  "B4galnt3",
  "B4galnt4",
  "B4galt1",
  "B4galt2",
  "B4galt3",
  "B4galt4",
  "B4galt5",
  "B4galt6",
  "B4galt7",
  "B530045E10Rik",
  "B630019A10Rik",
  "B630019K06Rik",
  "B830012L14Rik",
  "B830017H08Rik",
  "B930018H19Rik",
  "B930025P03Rik",
  "B930036N10Rik",
  "B930059L03Rik",
  "B930095G15Rik",
  "B9d1",
  "B9d1os",
  "B9d2",
  "BB014433",
  "BB031773",
  "BB557941",
  "BC001981",
  "BC002059",
  "BC002189",
  "BC003965",
  "BC004004",
  "BC005537",
  "BC005624",
  "BC006965",
  "BC016548",
  "BC016579",
  "BC017158",
  "BC018473",
  "BC024139",
  "BC024978",
  "BC025920",
  "BC026513",
  "BC026762",
  "BC028471",
  "BC028528",
  "BC028777",
  "BC029722",
  "BC030500",
  "BC031181",
  "BC034090",
  "BC037156",
  "BC037704",
  "BC039966",
  "BC042761",
  "BC043934",
  "BC048403",
  "BC048507",
  "BC048562",
  "BC048644",
  "BC048671",
  "BC048679",
  "BC049715",
  "BC049762",
  "BC050972",
  "BC051019",
  "BC051076",
  "BC051142",
  "BC051226",
  "BC051408",
  "BC051537",
  "BC051665",
  "BC052040",
  "BC053393",
  "BC055324",
  "BC065397",
  "BC065403",
  "BC067074",
  "BC080695",
  "BC106179",
  "BC107364",
  "Baalc",
  "Baat",
  "Babam1",
  "Babam2",
  "Bace1",
  "Bace2",
  "Bach1",
  "Bach2",
  "Bad",
  "Bag1",
  "Bag2",
  "Bag3",
  "Bag4",
  "Bag5",
  "Bag6",
  "Bahcc1",
  "Bahd1",
  "Baiap2",
  "Baiap2l1",
  "Baiap2l2",
  "Baiap3",
  "Bak1",
  "Bambi",
  "Banf1",
  "Banf2",
  "Bank1",
  "Banp",
  "Bap1",
  "Bard1",
  "Barhl1",
  "Barhl2",
  "Barx1",
  "Barx2",
  "Basp1",
  "Batf2",
  "Batf3",
  "Bax",
  "Baz1a",
  "Baz1b",
  "Baz2b",
  "Bbc3",
  "Bbip1",
  "Bbof1",
  "Bbox1",
  "Bbs1",
  "Bbs10",
  "Bbs12",
  "Bbs2",
  "Bbs4",
  "Bbs5",
  "Bbs7",
  "Bbs9",
  "Bbx",
  "Bcam",
  "Bcan",
  "Bcap29",
  "Bcap31",
  "Bcar1",
  "Bcar3",
  "Bcas1",
  "Bcas1os2",
  "Bcas2",
  "Bcas3",
  "Bcat1",
  "Bcat2",
  "Bccip",
  "Bcdin3d",
  "Bche",
  "Bckdha",
  "Bckdhb",
  "Bckdk",
  "Bcl10",
  "Bcl11a",
  "Bcl11b",
  "Bcl2",
  "Bcl2a1c",
  "Bcl2l1",
  "Bcl2l10",
  "Bcl2l11",
  "Bcl2l12",
  "Bcl2l13",
  "Bcl2l14",
  "Bcl2l15",
  "Bcl2l2",
  "Bcl3",
  "Bcl6",
  "Bcl6b",
  "Bcl7a",
  "Bcl7b",
  "Bcl7c",
  "Bcl9",
  "Bcl9l",
  "Bclaf1",
  "Bclaf3",
  "Bco1",
  "Bco2",
  "Bcor",
  "Bcorl1",
  "Bcr",
  "Bcs1l",
  "Bdh1",
  "Bdh2",
  "Bdkrb1",
  "Bdkrb2",
  "Bdnf",
  "Bdp1",
  "Bean1",
  "Becn1",
  "Begain",
  "Bend3",
  "Bend4",
  "Bend5",
  "Bend6",
  "Bend7",
  "Best1",
  "Best2",
  "Best3",
  "Bet1",
  "Bex1",
  "Bex2",
  "Bex4",
  "Bex6",
  "Bfar",
  "Bfsp1",
  "Bfsp2",
  "Bglap",
  "Bglap3",
  "Bgn",
  "Bhlha15",
  "Bhlha9",
  "Bhlhb9",
  "Bhlhe22",
  "Bhlhe23",
  "Bhlhe40",
  "Bhlhe41",
  "Bhmt",
  "Bhmt2",
  "Bicc1",
  "Bicd1",
  "Bicd2",
  "Bicdl1",
  "Bicdl2",
  "Bicra",
  "Bicral",
  "Bid",
  "Bik",
  "Bin1",
  "Bin2",
  "Bin3",
  "Birc2",
  "Birc3",
  "Birc5",
  "Birc6",
  "Birc7",
  "Bivm",
  "Blcap",
  "Blk",
  "Blm",
  "Blmh",
  "Blnk",
  "Bloc1s1",
  "Bloc1s2",
  "Bloc1s3",
  "Bloc1s4",
  "Bloc1s5",
  "Bloc1s6",
  "Blvra",
  "Blvrb",
  "Blzf1",
  "Bmerb1",
  "Bmf",
  "Bmi1",
  "Bmp1",
  "Bmp10",
  "Bmp15",
  "Bmp2",
  "Bmp2k",
  "Bmp3",
  "Bmp4",
  "Bmp5",
  "Bmp6",
  "Bmp7",
  "Bmp8a",
  "Bmp8b",
  "Bmper",
  "Bmpr1a",
  "Bmpr1b",
  "Bmpr2",
  "Bms1",
  "Bmt2",
  "Bmx",
  "Bmyc",
  "Bnc1",
  "Bnc2",
  "Bnip1",
  "Bnip2",
  "Bnip3",
  "Bnip3l",
  "Bnip5",
  "Bnipl",
  "Boc",
  "Bod1",
  "Bola1",
  "Bola2",
  "Bola3",
  "Boll",
  "Bop1",
  "Bora",
  "Borcs5",
  "Borcs6",
  "Borcs7",
  "Borcs8",
  "Bpgm",
  "Bphl",
  "Bpi",
  "Bpifa1",
  "Bpifa2",
  "Bpifa3",
  "Bpifa5",
  "Bpifa6",
  "Bpifb1",
  "Bpifb2",
  "Bpifb3",
  "Bpifb4",
  "Bpifb5",
  "Bpifb6",
  "Bpifc",
  "Bpnt1",
  "Bptf",
  "Braf",
  "Brap",
  "Brat1",
  "Brca1",
  "Brca2",
  "Brcc3",
  "Brd1",
  "Brd2",
  "Brd3",
  "Brd7",
  "Brd8",
  "Brd8dc",
  "Brd9",
  "Brdt",
  "Brf1",
  "Brf2",
  "Bri3",
  "Bri3bp",
  "Bricd5",
  "Brinp1",
  "Brinp2",
  "Brinp3",
  "Brip1",
  "Brip1os",
  "Brix1",
  "Brk1",
  "Brms1l",
  "Brox",
  "Brpf1",
  "Brpf3",
  "Brs3",
  "Brsk1",
  "Brsk2",
  "Brwd1",
  "Brwd3",
  "Bscl2",
  "Bsg",
  "Bsn",
  "Bsnd",
  "Bsph1",
  "Bsph2",
  "Bspry",
  "Bst1",
  "Bst2",
  "Bsx",
  "Btbd1",
  "Btbd10",
  "Btbd11",
  "Btbd16",
  "Btbd17",
  "Btbd19",
  "Btbd2",
  "Btbd6",
  "Btbd7",
  "Btbd8",
  "Btbd9",
  "Btc",
  "Btd",
  "Btf3",
  "Btf3l4",
  "Btg1",
  "Btg1b",
  "Btg1c",
  "Btg2",
  "Btg3",
  "Btg4",
  "Btk",
  "Btla",
  "Btn1a1",
  "Btn2a2",
  "Btnl1",
  "Btnl10",
  "Btnl2",
  "Btnl4",
  "Btnl6",
  "Btnl9",
  "Btrc",
  "Bub1",
  "Bub1b",
  "Bub3",
  "Bud13",
  "Bud31",
  "Bves",
  "Bvht",
  "Bysl",
  "Bzw1",
  "Bzw2",
  "C030005K15Rik",
  "C030006K11Rik",
  "C030010L15Rik",
  "C030013C21Rik",
  "C030013G03Rik",
  "C030014I23Rik",
  "C030017B01Rik",
  "C030034I22Rik",
  "C030037D09Rik",
  "C030037F17Rik",
  "C030047K22Rik",
  "C130021I20Rik",
  "C130026I21Rik",
  "C130026L21Rik",
  "C130036L24Rik",
  "C130040N14Rik",
  "C130050O18Rik",
  "C130071C03Rik",
  "C130073F10Rik",
  "C130074G19Rik",
  "C130083M11Rik",
  "C1d",
  "C1galt1",
  "C1galt1c1",
  "C1qa",
  "C1qb",
  "C1qbp",
  "C1qc",
  "C1ql1",
  "C1ql2",
  "C1ql4",
  "C1qtnf1",
  "C1qtnf12",
  "C1qtnf2",
  "C1qtnf3",
  "C1qtnf4",
  "C1qtnf6",
  "C1qtnf7",
  "C1qtnf9",
  "C1ra",
  "C1rl",
  "C1s1",
  "C1s2",
  "C2",
  "C230012O17Rik",
  "C230013L11Rik",
  "C230014O12Rik",
  "C230035I16Rik",
  "C230057A21Rik",
  "C230057M02Rik",
  "C230062I16Rik",
  "C230072F16Rik",
  "C230099D08Rik",
  "C2cd2",
  "C2cd2l",
  "C2cd3",
  "C2cd4a",
  "C2cd4b",
  "C2cd4c",
  "C2cd4d",
  "C2cd5",
  "C2cd6",
  "C3",
  "C330007P06Rik",
  "C330008A17Rik",
  "C330011M18Rik",
  "C330018A13Rik",
  "C330018D20Rik",
  "C330022C24Rik",
  "C330024D21Rik",
  "C3ar1",
  "C430002N11Rik",
  "C430042M11Rik",
  "C430049B03Rik",
  "C4b",
  "C4bp",
  "C530005A16Rik",
  "C530008M17Rik",
  "C530025M09Rik",
  "C530043A13Rik",
  "C530044C16Rik",
  "C5ar1",
  "C6",
  "C7",
  "C730002L08Rik",
  "C77080",
  "C78283",
  "C78859",
  "C79798",
  "C86187",
  "C87414",
  "C87436",
  "C87487",
  "C87499",
  "C87977",
  "C8a",
  "C8b",
  "C8g",
  "C9",
  "C920021L13Rik",
  "C9orf72",
  "CJ186046Rik",
  "CK137956",
  "CN725425",
  "Caap1",
  "Cab39",
  "Cab39l",
  "Cabcoco1",
  "Cabin1",
  "Cables1",
  "Cables2",
  "Cabp1",
  "Cabp2",
  "Cabp5",
  "Cabp7",
  "Cabs1",
  "Cabyr",
  "Cacfd1",
  "Cachd1",
  "Cacna1a",
  "Cacna1b",
  "Cacna1c",
  "Cacna1d",
  "Cacna1e",
  "Cacna1f",
  "Cacna1g",
  "Cacna1h",
  "Cacna1i",
  "Cacna1s",
  "Cacna2d1",
  "Cacna2d2",
  "Cacna2d3",
  "Cacna2d4",
  "Cacnb1",
  "Cacnb2",
  "Cacnb3",
  "Cacng1",
  "Cacng2",
  "Cacng3",
  "Cacng4",
  "Cacng5",
  "Cacng6",
  "Cacng7",
  "Cacng8",
  "Cactin",
  "Cacul1",
  "Cacybp",
  "Cad",
  "Cadm1",
  "Cadm2",
  "Cadm3",
  "Cadps",
  "Cadps2",
  "Cage1",
  "Calb1",
  "Calb2",
  "Calca",
  "Calcb",
  "Calcoco1",
  "Calcoco2",
  "Calcr",
  "Calcrl",
  "Cald1",
  "Calhm1",
  "Calhm2",
  "Calhm3",
  "Calhm4",
  "Calhm5",
  "Calhm6",
  "Calm1",
  "Calm2",
  "Calm3",
  "Calm4",
  "Calm5",
  "Calml3",
  "Calml4",
  "Caln1",
  "Calr",
  "Calr3",
  "Calr4",
  "Calu",
  "Caly",
  "Camk1",
  "Camk1d",
  "Camk1g",
  "Camk2a",
  "Camk2b",
  "Camk2d",
  "Camk2g",
  "Camk2n1",
  "Camk2n2",
  "Camk4",
  "Camkk1",
  "Camkk2",
  "Camkmt",
  "Camkv",
  "Caml",
  "Camp",
  "Camsap1",
  "Camsap2",
  "Camsap3",
  "Camta1",
  "Camta2",
  "Cand1",
  "Cand2",
  "Cant1",
  "Canx",
  "Cap1",
  "Cap2",
  "Capg",
  "Capn1",
  "Capn10",
  "Capn11",
  "Capn12",
  "Capn13",
  "Capn15",
  "Capn2",
  "Capn3",
  "Capn5",
  "Capn6",
  "Capn7",
  "Capn8",
  "Capn9",
  "Capns1",
  "Capns2",
  "Caprin1",
  "Caprin2",
  "Caps2",
  "Capsl",
  "Capza1",
  "Capza2",
  "Capza3",
  "Capzb",
  "Car1",
  "Car10",
  "Car11",
  "Car12",
  "Car13",
  "Car14",
  "Car2",
  "Car3",
  "Car4",
  "Car5a",
  "Car5b",
  "Car6",
  "Car7",
  "Car8",
  "Car9",
  "Card10",
  "Card11",
  "Card14",
  "Card19",
  "Card6",
  "Card9",
  "Carf",
  "Carhsp1",
  "Carlr",
  "Carm1",
  "Carmil1",
  "Carmil2",
  "Carmil3",
  "Carmn",
  "Carnmt1",
  "Carns1",
  "Cars",
  "Cars2",
  "Cartpt",
  "Casc1",
  "Casc3",
  "Casc4",
  "Casd1",
  "Cask",
  "Caskin2",
  "Casp1",
  "Casp12",
  "Casp14",
  "Casp2",
  "Casp3",
  "Casp4",
  "Casp6",
  "Casp7",
  "Casp8",
  "Casp8ap2",
  "Casp9",
  "Casq1",
  "Casq2",
  "Casr",
  "Cass4",
  "Cast",
  "Castor1",
  "Castor2",
  "Casz1",
  "Cat",
  "Catip",
  "Catsper1",
  "Catsper2",
  "Catsper3",
  "Catsper4",
  "Catsperb",
  "Catsperd",
  "Catspere2",
  "Catsperg2",
  "Catsperz",
  "Cav1",
  "Cav2",
  "Cav3",
  "Cavin1",
  "Cavin2",
  "Cavin3",
  "Cavin4",
  "Cbarp",
  "Cbfa2t2",
  "Cbfa2t3",
  "Cbfb",
  "Cbl",
  "Cblb",
  "Cblc",
  "Cblif",
  "Cbll1",
  "Cbln1",
  "Cbln3",
  "Cbln4",
  "Cbr1",
  "Cbr2",
  "Cbr3",
  "Cbr4",
  "Cbs",
  "Cbwd1",
  "Cbx1",
  "Cbx2",
  "Cbx3",
  "Cbx4",
  "Cbx5",
  "Cbx6",
  "Cbx7",
  "Cbx8",
  "Cby1",
  "Cby2",
  "Cby3",
  "Cc2d1a",
  "Cc2d1b",
  "Cc2d2a",
  "Cc2d2b",
  "Ccar1",
  "Ccar2",
  "Ccbe1",
  "Ccdc102a",
  "Ccdc103",
  "Ccdc105",
  "Ccdc106",
  "Ccdc107",
  "Ccdc110",
  "Ccdc112",
  "Ccdc113",
  "Ccdc114",
  "Ccdc115",
  "Ccdc116",
  "Ccdc117",
  "Ccdc12",
  "Ccdc120",
  "Ccdc121",
  "Ccdc122",
  "Ccdc124",
  "Ccdc125",
  "Ccdc126",
  "Ccdc127",
  "Ccdc13",
  "Ccdc130",
  "Ccdc134",
  "Ccdc136",
  "Ccdc137",
  "Ccdc138",
  "Ccdc14",
  "Ccdc141",
  "Ccdc144b",
  "Ccdc146",
  "Ccdc148",
  "Ccdc149",
  "Ccdc15",
  "Ccdc150",
  "Ccdc151",
  "Ccdc152",
  "Ccdc153",
  "Ccdc154",
  "Ccdc155",
  "Ccdc157",
  "Ccdc158",
  "Ccdc159",
  "Ccdc160",
  "Ccdc162",
  "Ccdc163",
  "Ccdc166",
  "Ccdc167",
  "Ccdc169",
  "Ccdc17",
  "Ccdc170",
  "Ccdc171",
  "Ccdc172",
  "Ccdc173",
  "Ccdc174",
  "Ccdc175",
  "Ccdc177",
  "Ccdc178",
  "Ccdc18",
  "Ccdc180",
  "Ccdc181",
  "Ccdc182",
  "Ccdc183",
  "Ccdc184",
  "Ccdc185",
  "Ccdc186",
  "Ccdc187",
  "Ccdc188",
  "Ccdc189",
  "Ccdc190",
  "Ccdc191",
  "Ccdc192",
  "Ccdc194",
  "Ccdc201",
  "Ccdc22",
  "Ccdc24",
  "Ccdc25",
  "Ccdc27",
  "Ccdc28a",
  "Ccdc28b",
  "Ccdc3",
  "Ccdc30",
  "Ccdc32",
  "Ccdc33",
  "Ccdc34",
  "Ccdc36",
  "Ccdc38",
  "Ccdc39",
  "Ccdc40",
  "Ccdc42",
  "Ccdc42os",
  "Ccdc43",
  "Ccdc47",
  "Ccdc50",
  "Ccdc51",
  "Ccdc54",
  "Ccdc57",
  "Ccdc58",
  "Ccdc59",
  "Ccdc6",
  "Ccdc60",
  "Ccdc61",
  "Ccdc62",
  "Ccdc63",
  "Ccdc65",
  "Ccdc66",
  "Ccdc68",
  "Ccdc69",
  "Ccdc70",
  "Ccdc71",
  "Ccdc71l",
  "Ccdc73",
  "Ccdc74a",
  "Ccdc77",
  "Ccdc78",
  "Ccdc7a",
  "Ccdc7b",
  "Ccdc8",
  "Ccdc80",
  "Ccdc81",
  "Ccdc82",
  "Ccdc83",
  "Ccdc84",
  "Ccdc85a",
  "Ccdc85b",
  "Ccdc85c",
  "Ccdc86",
  "Ccdc87",
  "Ccdc88a",
  "Ccdc88b",
  "Ccdc88c",
  "Ccdc89",
  "Ccdc9",
  "Ccdc90b",
  "Ccdc91",
  "Ccdc92",
  "Ccdc92b",
  "Ccdc93",
  "Ccdc96",
  "Ccdc97",
  "Ccdc9b",
  "Ccer1",
  "Ccer2",
  "Cchcr1",
  "Ccin",
  "Cck",
  "Cckar",
  "Cckbr",
  "Ccl1",
  "Ccl11",
  "Ccl12",
  "Ccl17",
  "Ccl19",
  "Ccl2",
  "Ccl20",
  "Ccl21a",
  "Ccl22",
  "Ccl24",
  "Ccl25",
  "Ccl26",
  "Ccl27a",
  "Ccl28",
  "Ccl3",
  "Ccl4",
  "Ccl5",
  "Ccl6",
  "Ccl7",
  "Ccl8",
  "Ccl9",
  "Ccm2",
  "Ccm2l",
  "Ccn1",
  "Ccn2",
  "Ccn3",
  "Ccn4",
  "Ccn5",
  "Ccn6",
  "Ccna1",
  "Ccna2",
  "Ccnb1",
  "Ccnb1ip1",
  "Ccnb3",
  "Ccnc",
  "Ccnd1",
  "Ccnd2",
  "Ccnd3",
  "Ccndbp1",
  "Ccne1",
  "Ccne2",
  "Ccnf",
  "Ccng1",
  "Ccng2",
  "Ccnh",
  "Ccni",
  "Ccnj",
  "Ccnjl",
  "Ccnk",
  "Ccnl1",
  "Ccnl2",
  "Ccno",
  "Ccnq",
  "Ccnt1",
  "Ccnt2",
  "Ccny",
  "Ccnyl1",
  "Ccp110",
  "Ccpg1",
  "Ccpg1os",
  "Ccr1",
  "Ccr10",
  "Ccr1l1",
  "Ccr2",
  "Ccr3",
  "Ccr4",
  "Ccr5",
  "Ccr6",
  "Ccr7",
  "Ccr8",
  "Ccr9",
  "Ccrl2",
  "Ccs",
  "Ccsap",
  "Ccser1",
  "Ccser2",
  "Cct2",
  "Cct5",
  "Cct6a",
  "Cct6b",
  "Cct7",
  "Cct8",
  "Cct8l1",
  "Ccz1",
  "Cd101",
  "Cd109",
  "Cd14",
  "Cd151",
  "Cd160",
  "Cd163",
  "Cd163l1",
  "Cd164",
  "Cd164l2",
  "Cd177",
  "Cd180",
  "Cd19",
  "Cd1d1",
  "Cd2",
  "Cd200",
  "Cd200r1",
  "Cd200r2",
  "Cd200r3",
  "Cd200r4",
  "Cd207",
  "Cd209a",
  "Cd209b",
  "Cd209c",
  "Cd209d",
  "Cd209e",
  "Cd209f",
  "Cd209g",
  "Cd22",
  "Cd226",
  "Cd244a",
  "Cd247",
  "Cd248",
  "Cd24a",
  "Cd27",
  "Cd274",
  "Cd276",
  "Cd28",
  "Cd2ap",
  "Cd2bp2",
  "Cd300c",
  "Cd300c2",
  "Cd300e",
  "Cd300lb",
  "Cd300ld",
  "Cd300ld2",
  "Cd300lf",
  "Cd300lg",
  "Cd302",
  "Cd320",
  "Cd33",
  "Cd34",
  "Cd36",
  "Cd37",
  "Cd38",
  "Cd3d",
  "Cd3e",
  "Cd3eap",
  "Cd3g",
  "Cd4",
  "Cd40",
  "Cd40lg",
  "Cd44",
  "Cd46",
  "Cd47",
  "Cd48",
  "Cd5",
  "Cd52",
  "Cd53",
  "Cd55",
  "Cd55b",
  "Cd59a",
  "Cd59b",
  "Cd5l",
  "Cd6",
  "Cd68",
  "Cd69",
  "Cd7",
  "Cd70",
  "Cd72",
  "Cd74",
  "Cd79a",
  "Cd79b",
  "Cd80",
  "Cd81",
  "Cd82",
  "Cd83",
  "Cd84",
  "Cd86",
  "Cd8a",
  "Cd8b1",
  "Cd9",
  "Cd93",
  "Cd96",
  "Cd99l2",
  "Cda",
  "Cdadc1",
  "Cdan1",
  "Cdc123",
  "Cdc14a",
  "Cdc14b",
  "Cdc16",
  "Cdc20",
  "Cdc20b",
  "Cdc23",
  "Cdc25b",
  "Cdc25c",
  "Cdc26",
  "Cdc27",
  "Cdc37",
  "Cdc37l1",
  "Cdc40",
  "Cdc42",
  "Cdc42bpa",
  "Cdc42bpb",
  "Cdc42bpg",
  "Cdc42ep1",
  "Cdc42ep2",
  "Cdc42ep3",
  "Cdc42ep4",
  "Cdc42ep5",
  "Cdc42se1",
  "Cdc42se2",
  "Cdc45",
  "Cdc5l",
  "Cdc6",
  "Cdc7",
  "Cdc73",
  "Cdca2",
  "Cdca3",
  "Cdca4",
  "Cdca5",
  "Cdca7",
  "Cdca7l",
  "Cdca8",
  "Cdcp1",
  "Cdcp2",
  "Cdcp3",
  "Cdh1",
  "Cdh10",
  "Cdh11",
  "Cdh12",
  "Cdh13",
  "Cdh15",
  "Cdh16",
  "Cdh17",
  "Cdh18",
  "Cdh19",
  "Cdh2",
  "Cdh20",
  "Cdh22",
  "Cdh23",
  "Cdh24",
  "Cdh26",
  "Cdh3",
  "Cdh4",
  "Cdh5",
  "Cdh6",
  "Cdh7",
  "Cdh8",
  "Cdh9",
  "Cdhr1",
  "Cdhr2",
  "Cdhr3",
  "Cdhr4",
  "Cdhr5",
  "Cdip1",
  "Cdipt",
  "Cdiptos",
  "Cdk1",
  "Cdk10",
  "Cdk11b",
  "Cdk12",
  "Cdk13",
  "Cdk14",
  "Cdk15",
  "Cdk16",
  "Cdk17",
  "Cdk18",
  "Cdk19",
  "Cdk19os",
  "Cdk2",
  "Cdk20",
  "Cdk2ap2",
  "Cdk4",
  "Cdk5",
  "Cdk5r1",
  "Cdk5r2",
  "Cdk5rap1",
  "Cdk5rap2",
  "Cdk5rap3",
  "Cdk6",
  "Cdk7",
  "Cdk8",
  "Cdk9",
  "Cdkal1",
  "Cdkl1",
  "Cdkl2",
  "Cdkl3",
  "Cdkl4",
  "Cdkl5",
  "Cdkn1a",
  "Cdkn1b",
  "Cdkn1c",
  "Cdkn2a",
  "Cdkn2aip",
  "Cdkn2aipnl",
  "Cdkn2b",
  "Cdkn2c",
  "Cdkn2d",
  "Cdkn3",
  "Cdnf",
  "Cdo1",
  "Cdon",
  "Cdpf1",
  "Cdr1os",
  "Cdr2",
  "Cdr2l",
  "Cdrt4",
  "Cdrt4os1",
  "Cds1",
  "Cds2",
  "Cdsn",
  "Cdt1",
  "Cdv3",
  "Cdx1",
  "Cdx2",
  "Cdx4",
  "Cdyl",
  "Cdyl2",
  "Ceacam10",
  "Ceacam11",
  "Ceacam12",
  "Ceacam13",
  "Ceacam14",
  "Ceacam15",
  "Ceacam16",
  "Ceacam18",
  "Ceacam19",
  "Ceacam2",
  "Ceacam20",
  "Ceacam3",
  "Ceacam5",
  "Ceacam9",
  "Cebpa",
  "Cebpb",
  "Cebpd",
  "Cebpe",
  "Cebpg",
  "Cebpz",
  "Cebpzos",
  "Cecr2",
  "Cel",
  "Cela1",
  "Cela2a",
  "Cela3a",
  "Cela3b",
  "Celf1",
  "Celf2",
  "Celf3",
  "Celf4",
  "Celf5",
  "Celf6",
  "Celrr",
  "Celsr1",
  "Celsr2",
  "Celsr3",
  "Cemip",
  "Cemip2",
  "Cend1",
  "Cenpa",
  "Cenpb",
  "Cenpe",
  "Cenpf",
  "Cenph",
  "Cenpi",
  "Cenpj",
  "Cenpk",
  "Cenpl",
  "Cenpm",
  "Cenpn",
  "Cenpo",
  "Cenpp",
  "Cenpq",
  "Cenps",
  "Cenpt",
  "Cenpu",
  "Cenpv",
  "Cenpx",
  "Cep104",
  "Cep112",
  "Cep112it",
  "Cep120",
  "Cep126",
  "Cep128",
  "Cep131",
  "Cep135",
  "Cep152",
  "Cep162",
  "Cep164",
  "Cep170",
  "Cep19",
  "Cep192",
  "Cep250",
  "Cep290",
  "Cep295",
  "Cep295nl",
  "Cep350",
  "Cep41",
  "Cep44",
  "Cep55",
  "Cep57",
  "Cep57l1",
  "Cep63",
  "Cep68",
  "Cep70",
  "Cep72",
  "Cep76",
  "Cep78",
  "Cep83",
  "Cep83os",
  "Cep85",
  "Cep85l",
  "Cep89",
  "Cep95",
  "Cep97",
  "Cept1",
  "Cer1",
  "Cercam",
  "Cerk",
  "Cerkl",
  "Cerox1",
  "Cers1",
  "Cers2",
  "Cers3",
  "Cers4",
  "Cers5",
  "Cers6",
  "Ces1a",
  "Ces1b",
  "Ces1c",
  "Ces1d",
  "Ces1e",
  "Ces1f",
  "Ces1g",
  "Ces1h",
  "Ces2a",
  "Ces2b",
  "Ces2c",
  "Ces2e",
  "Ces2f",
  "Ces2g",
  "Ces2h",
  "Ces3a",
  "Ces3b",
  "Ces4a",
  "Ces5a",
  "Cetn1",
  "Cetn2",
  "Cetn3",
  "Cetn4",
  "Cfap100",
  "Cfap126",
  "Cfap157",
  "Cfap161",
  "Cfap20",
  "Cfap206",
  "Cfap221",
  "Cfap298",
  "Cfap299",
  "Cfap300",
  "Cfap36",
  "Cfap410",
  "Cfap43",
  "Cfap44",
  "Cfap45",
  "Cfap46",
  "Cfap47",

  "Cfap52",
  "Cfap53",
  "Cfap54",
  "Cfap57",
  "Cfap58",
  "Cfap61",
  "Cfap65",
  "Cfap69",
  "Cfap70",
  "Cfap73",
  "Cfap74",
  "Cfap77",
  "Cfap97",
  "Cfap97d1",
  "Cfap97d2",
  "Cfc1",
  "Cfd",
  "Cfdp1",
  "Cfh",
  "Cfhr1",
  "Cfhr2",
  "Cfi",
  "Cfl1",
  "Cfl2",
  "Cflar",
  "Cfp",
  "Cftr",
  "Cga",
  "Cgas",
  "Cggbp1",
  "Cgn",
  "Cgnl1",
  "Cgref1",
  "Cgrrf1",
  "Ch25h",
  "Chac1",
  "Chac2",
  "Chad",
  "Chadl",
  "Chaf1a",
  "Chaf1b",
  "Champ1",
  "Chat",
  "Chchd1",
  "Chchd10",
  "Chchd2",
  "Chchd3",
  "Chchd4",
  "Chchd5",
  "Chchd6",
  "Chchd7",
  "Chd1",
  "Chd1l",
  "Chd2",
  "Chd3",
  "Chd4",
  "Chd5",
  "Chd6",
  "Chd7",
  "Chd8",
  "Chd9",
  "Chdh",
  "Chek1",
  "Chek2",
  "Cherp",
  "Chfr",
  "Chga",
  "Chgb",
  "Chia1",
  "Chic1",
  "Chic2",
  "Chid1",
  "Chil1",
  "Chil3",
  "Chil4",
  "Chil5",
  "Chil6",
  "Chit1",
  "Chka",
  "Chl1",
  "Chm",
  "Chml",
  "Chmp1a",
  "Chmp1b",
  "Chmp2b",
  "Chmp3",
  "Chmp4b",
  "Chmp4c",
  "Chmp5",
  "Chmp6",
  "Chmp7",
  "Chn1",
  "Chn1os3",
  "Chn2",
  "Chodl",
  "Chordc1",
  "Chp1",
  "Chp2",
  "Chpf",
  "Chpf2",
  "Chpt1",
  "Chrac1",
  "Chrd",
  "Chrdl1",
  "Chrdl2",
  "Chrm1",
  "Chrm2",
  "Chrm3",
  "Chrm4",
  "Chrm5",
  "Chrna1",
  "Chrna10",
  "Chrna2",
  "Chrna3",
  "Chrna4",
  "Chrna5",
  "Chrna6",
  "Chrna7",
  "Chrna9",
  "Chrnb1",
  "Chrnb2",
  "Chrnb3",
  "Chrnb4",
  "Chrnd",
  "Chrne",
  "Chrng",
  "Chst1",
  "Chst10",
  "Chst11",
  "Chst12",
  "Chst13",
  "Chst14",
  "Chst15",
  "Chst2",
  "Chst3",
  "Chst4",
  "Chst5",
  "Chst7",
  "Chst8",
  "Chst9",
  "Chsy1",
  "Chsy3",
  "Chtf18",
  "Chtop",
  "Chuk",
  "Churc1",
  "Ciao1",
  "Ciao2a",
  "Ciao2b",
  "Ciao3",
  "Ciapin1",
  "Ciart",
  "Cib1",
  "Cib2",
  "Cib3",
  "Cib4",
  "Cic",
  "Cidea",
  "Cideb",
  "Cidec",
  "Ciita",
  "Cilk1",
  "Cilp",
  "Cilp2",
  "Cinp",
  "Cip2a",
  "Cipc",
  "Cirbp",
  "Cisd1",
  "Cisd2",
  "Cisd3",
  "Cish",
  "Cit",
  "Cited1",
  "Cited2",
  "Cited4",
  "Ciz1",
  "Ckap2",
  "Ckap2l",
  "Ckap4",
  "Ckap5",
  "Ckb",
  "Cklf",
  "Ckm",
  "Ckmt1",
  "Ckmt2",
  "Cks1b",
  "Cks1brt",
  "Cks2",
  "Clasp1",
  "Clasp2",
  "Clasrp",
  "Clba1",
  "Clca1",
  "Clca2",
  "Clca3a1",
  "Clca3a2",
  "Clca3b",
  "Clca4a",
  "Clca4b",
  "Clcc1",
  "Clcf1",
  "Clcn1",
  "Clcn2",
  "Clcn3",
  "Clcn4",
  "Clcn5",
  "Clcn6",
  "Clcn7",
  "Clcnka",
  "Clcnkb",
  "Cldn1",
  "Cldn10",
  "Cldn11",
  "Cldn12",
  "Cldn13",
  "Cldn14",
  "Cldn15",
  "Cldn16",
  "Cldn17",
  "Cldn18",
  "Cldn19",
  "Cldn2",
  "Cldn20",
  "Cldn22",
  "Cldn23",
  "Cldn24",
  "Cldn3",
  "Cldn34a",
  "Cldn34b1",
  "Cldn34b2",
  "Cldn34b3",
  "Cldn34b4",
  "Cldn34c1",
  "Cldn34c4",
  "Cldn34d",
  "Cldn4",
  "Cldn5",
  "Cldn6",
  "Cldn7",
  "Cldn8",
  "Cldn9",
  "Cldnd1",
  "Cldnd2",
  "Clec10a",
  "Clec11a",
  "Clec12a",
  "Clec12b",
  "Clec14a",
  "Clec16a",
  "Clec18a",
  "Clec1a",
  "Clec1b",
  "Clec2d",
  "Clec2e",
  "Clec2g",
  "Clec2h",
  "Clec2i",
  "Clec2l",
  "Clec2m",
  "Clec3a",
  "Clec3b",
  "Clec4a1",
  "Clec4a2",
  "Clec4a3",
  "Clec4a4",
  "Clec4b1",
  "Clec4b2",
  "Clec4d",
  "Clec4e",
  "Clec4f",
  "Clec4g",
  "Clec4n",
  "Clec5a",
  "Clec9a",
  "Clhc1",
  "Clic1",
  "Clic3",
  "Clic4",
  "Clic5",
  "Clic6",
  "Clint1",
  "Clip1",
  "Clip2",
  "Clip3",
  "Clip4",
  "Clk1",
  "Clk2",
  "Clk3",
  "Clk4",
  "Clmn",
  "Clmp",
  "Cln3",
  "Cln5",
  "Cln6",
  "Cln8",
  "Clnk",
  "Clnkos",
  "Clns1a",
  "Clock",
  "Clp1",
  "Clpb",
  "Clpp",
  "Clps",
  "Clpsl2",
  "Clptm1",
  "Clptm1l",
  "Clpx",
  "Clrn1",
  "Clrn2",
  "Clrn3",
  "Clspn",
  "Clstn1",
  "Clstn2",
  "Clstn3",
  "Clta",
  "Cltb",
  "Cltc",
  "Cltrn",
  "Clu",
  "Cluap1",
  "Cluh",
  "Clvs1",
  "Clvs2",
  "Clybl",
  "Cma1",
  "Cmah",
  "Cmas",
  "Cmbl",
  "Cmc1",
  "Cmc2",
  "Cmc4",
  "Cmip",
  "Cmklr1",
  "Cmpk1",
  "Cmpk2",
  "Cmss1",
  "Cmtm2a",
  "Cmtm2b",
  "Cmtm3",
  "Cmtm4",
  "Cmtm5",
  "Cmtm6",
  "Cmtm7",
  "Cmtm8",
  "Cmtr2",
  "Cmya5",
  "Cnbd1",
  "Cnbd2",
  "Cnbp",
  "Cndp1",
  "Cndp2",
  "Cnep1r1",
  "Cnfn",
  "Cnga1",
  "Cnga2",
  "Cnga3",
  "Cnga4",
  "Cngb1",
  "Cngb3",
  "Cnih1",
  "Cnih2",
  "Cnih3",
  "Cnih4",
  "Cnksr1",
  "Cnksr2",
  "Cnksr3",
  "Cnmd",
  "Cnn1",
  "Cnn2",
  "Cnn3",
  "Cnnm1",
  "Cnnm2",
  "Cnnm3",
  "Cnnm4",
  "Cnot1",
  "Cnot10",
  "Cnot11",
  "Cnot2",
  "Cnot4",
  "Cnot6",
  "Cnot6l",
  "Cnot7",
  "Cnot8",
  "Cnot9",
  "Cnp",
  "Cnppd1",
  "Cnpy1",
  "Cnpy2",
  "Cnpy3",
  "Cnpy4",
  "Cnr1",
  "Cnr2",
  "Cnrip1",
  "Cnst",
  "Cntd1",
  "Cntfr",
  "Cntln",
  "Cntn1",
  "Cntn2",
  "Cntn3",
  "Cntn4",
  "Cntn5",
  "Cntn6",
  "Cntnap1",
  "Cntnap2",
  "Cntnap3",
  "Cntnap4",
  "Cntnap5a",
  "Cntnap5b",
  "Cntnap5c",
  "Cntrl",
  "Cntrob",
  "Cntrobos",
  "Coa3",
  "Coa4",
  "Coa5",
  "Coa6",
  "Coa7",
  "Coasy",
  "Cobl",
  "Cobll1",
  "Coch",
  "Cog1",
  "Cog2",
  "Cog3",
  "Cog4",
  "Cog5",
  "Cog6",
  "Cog7",
  "Cog8",
  "Coil",
  "Col10a1",
  "Col11a1",
  "Col11a2",
  "Col12a1",
  "Col13a1",
  "Col14a1",
  "Col15a1",
  "Col16a1",
  "Col17a1",
  "Col18a1",
  "Col19a1",
  "Col1a1",
  "Col1a2",
  "Col20a1",
  "Col22a1",
  "Col23a1",
  "Col24a1",
  "Col25a1",
  "Col26a1",
  "Col27a1",
  "Col28a1",
  "Col2a1",
  "Col3a1",
  "Col4a1",
  "Col4a2",
  "Col4a3",
  "Col4a3bp",
  "Col4a4",
  "Col4a5",
  "Col4a6",
  "Col5a1",
  "Col5a2",
  "Col5a3",
  "Col6a1",
  "Col6a2",
  "Col6a3",
  "Col6a4",
  "Col6a5",
  "Col6a6",
  "Col7a1",
  "Col8a1",
  "Col8a2",
  "Col9a1",
  "Col9a2",
  "Col9a3",
  "Colca2",
  "Colec10",
  "Colec11",
  "Colec12",
  "Colgalt1",
  "Colgalt2",
  "Colq",
  "Commd10",
  "Commd2",
  "Commd3",
  "Commd4",
  "Commd6",
  "Commd7",
  "Commd8",
  "Commd9",
  "Comp",
  "Comt",
  "Comtd1",
  "Cop1",
  "Copa",
  "Copb1",
  "Copb2",
  "Cope",
  "Copg1",
  "Copg2",
  "Coprs",
  "Cops2",
  "Cops3",
  "Cops4",
  "Cops5",
  "Cops6",
  "Cops7a",
  "Cops7b",
  "Cops8",
  "Cops9",
  "Copz1",
  "Copz2",
  "Coq10a",
  "Coq10b",
  "Coq2",
  "Coq3",
  "Coq4",
  "Coq5",
  "Coq6",
  "Coq7",
  "Coq8a",
  "Coq8b",
  "Coq9",
  "Corin",
  "Coro1a",
  "Coro1b",
  "Coro1c",
  "Coro2a",
  "Coro2b",
  "Coro6",
  "Coro7",
  "Cort",
  "Cotl1",
  "Cox10",
  "Cox11",
  "Cox14",
  "Cox15",
  "Cox17",
  "Cox18",
  "Cox19",
  "Cox20",
  "Cox4i1",
  "Cox4i2",
  "Cox5a",
  "Cox5b",
  "Cox6a1",
  "Cox6a2",
  "Cox6b1",
  "Cox6b2",
  "Cox6c",
  "Cox7a1",
  "Cox7a2",
  "Cox7a2l",
  "Cox7b",
  "Cox7b2",
  "Cox7c",
  "Cox8a",
  "Cox8b",
  "Cox8c",
  "Cp",
  "Cpa1",
  "Cpa2",
  "Cpa3",
  "Cpa4",
  "Cpa5",
  "Cpa6",
  "Cpb1",
  "Cpb2",
  "Cpd",
  "Cpe",
  "Cpeb1",
  "Cpeb2",
  "Cpeb3",
  "Cpeb4",
  "Cped1",
  "Cplane1",
  "Cplane2",
  "Cplx1",
  "Cplx2",
  "Cplx3",
  "Cplx4",
  "Cpm",
  "Cpn1",
  "Cpn2",
  "Cpne2",
  "Cpne3",
  "Cpne4",
  "Cpne5",
  "Cpne6",
  "Cpne7",
  "Cpne8",
  "Cpne9",
  "Cpox",
  "Cpped1",
  "Cpq",
  "Cps1",
  "Cpsf1",
  "Cpsf2",
  "Cpsf3",
  "Cpsf4",
  "Cpsf4l",
  "Cpsf6",
  "Cpsf7",
  "Cpt1a",
  "Cpt1c",
  "Cpt2",
  "Cptp",
  "Cpvl",
  "Cpxcr1",
  "Cpxm1",
  "Cpxm2",
  "Cpz",
  "Cr1l",
  "Cr2",
  "Crabp1",
  "Crabp2",
  "Cracr2a",
  "Cracr2b",
  "Cradd",
  "Cramp1l",
  "Crat",
  "Crb1",
  "Crb2",
  "Crb3",
  "Crbn",
  "Crcp",
  "Crct1",
  "Creb1",
  "Creb3",
  "Creb3l1",
  "Creb3l2",
  "Creb3l3",
  "Creb3l4",
  "Creb5",
  "Crebbp",
  "Crebl2",
  "Crebrf",
  "Crebzf",
  "Creg1",
  "Creg2",
  "Creld1",
  "Creld2",
  "Crem",
  "Crh",
  "Crhbp",
  "Crhr1",
  "Crhr2",
  "Crim1",
  "Crip1",
  "Crip2",
  "Crip3",
  "Cript",
  "Crisp1",
  "Crisp2",
  "Crisp3",
  "Crisp4",
  "Crispld1",
  "Crispld2",
  "Crk",
  "Crkl",
  "Crlf1",
  "Crlf2",
  "Crlf3",
  "Crls1",
  "Crmp1",
  "Crnde",
  "Crnkl1",
  "Crnn",
  "Crocc",
  "Crocc2",
  "Crot",
  "Crp",
  "Crppa",
  "Crtac1",
  "Crtam",
  "Crtap",
  "Crtc1",
  "Crtc2",
  "Crtc3",
  "Crx",
  "Crxos",
  "Cry1",
  "Cry2",
  "Cryaa",
  "Cryab",
  "Cryba1",
  "Cryba2",
  "Cryba4",
  "Crybb1",
  "Crybb2",
  "Crybb3",
  "Crybg1",
  "Crybg2",
  "Crybg3",
  "Cryga",
  "Crygb",
  "Crygc",
  "Crygd",
  "Cryge",
  "Crygf",
  "Crygn",
  "Crygs",
  "Cryl1",
  "Crym",
  "Cryz",
  "Cryzl1",
  "Cryzl2",
  "Cs",
  "Csad",
  "Csdc2",
  "Csde1",
  "Cse1l",
  "Csf1",
  "Csf1r",
  "Csf2",
  "Csf2ra",
  "Csf2rb",
  "Csf2rb2",
  "Csf3",
  "Csf3r",
  "Csgalnact1",
  "Csgalnact2",
  "Csk",
  "Csl",
  "Csmd1",
  "Csmd2",
  "Csmd2os",
  "Csmd3",
  "Csn1s1",
  "Csn1s2a",
  "Csn1s2b",
  "Csn2",
  "Csn3",
  "Csnk1a1",
  "Csnk1d",
  "Csnk1e",
  "Csnk1g1",
  "Csnk1g2",
  "Csnk1g3",
  "Csnk2a1",
  "Csnk2a2",
  "Csnk2b",
  "Csnka2ip",
  "Cspg4",
  "Cspg5",
  "Cspp1",
  "Csrnp1",
  "Csrnp2",
  "Csrnp3",
  "Csrp1",
  "Csrp2",
  "Csrp3",
  "Cst10",
  "Cst11",
  "Cst12",
  "Cst13",
  "Cst3",
  "Cst6",
  "Cst7",
  "Cst8",
  "Cst9",
  "Csta1",
  "Csta2",
  "Csta3",
  "Cstad",
  "Cstb",
  "Cstdc1",
  "Cstdc2",
  "Cstdc4",
  "Cstdc6",
  "Cstf1",
  "Cstf2",
  "Cstf2t",
  "Cstf3",
  "Cstl1",
  "Ct55",
  "Ctag2",
  "Ctag2l2",
  "Ctbp1",
  "Ctbp2",
  "Ctbs",
  "Ctc1",
  "Ctcf",
  "Ctcfl",
  "Ctcflos",
  "Ctdnep1",
  "Ctdp1",
  "Ctdsp1",
  "Ctdsp2",
  "Ctdspl",
  "Ctdspl2",
  "Ctf1",
  "Ctf2",
  "Cth",
  "Cthrc1",
  "Ctif",
  "Ctla2a",
  "Ctla4",
  "Ctnna1",
  "Ctnna2",
  "Ctnna3",
  "Ctnnal1",
  "Ctnnb1",
  "Ctnnbip1",
  "Ctnnbl1",
  "Ctnnd1",
  "Ctnnd2",
  "Ctns",
  "Ctps",
  "Ctps2",
  "Ctr9",
  "Ctrb1",
  "Ctrc",
  "Ctrl",
  "Cts3",
  "Cts6",
  "Cts7",
  "Cts8",
  "Ctsa",
  "Ctsb",
  "Ctsc",
  "Ctsd",
  "Ctse",
  "Ctsf",
  "Ctsg",
  "Ctsh",
  "Ctsj",
  "Ctsk",
  "Ctsl",
  "Ctsll3",
  "Ctsm",
  "Ctso",
  "Ctsq",
  "Ctsr",
  "Ctss",
  "Ctsw",
  "Ctsz",
  "Cttn",
  "Cttnbp2",
  "Cttnbp2nl",
  "Ctu1",
  "Ctu2",
  "Ctxn1",
  "Ctxn2",
  "Ctxn3",
  "Cubn",
  "Cuedc1",
  "Cuedc2",
  "Cul1",
  "Cul2",
  "Cul3",
  "Cul4a",
  "Cul4b",
  "Cul5",
  "Cul7",
  "Cul9",
  "Cuta",
  "Cutal",
  "Cutc",
  "Cux1",
  "Cux2",
  "Cuzd1",
  "Cwc15",
  "Cwc22",
  "Cwc25",
  "Cwc27",
  "Cwf19l1",
  "Cwf19l2",
  "Cwh43",
  "Cx3cl1",
  "Cx3cr1",
  "Cxadr",
  "Cxcl1",
  "Cxcl10",
  "Cxcl12",
  "Cxcl13",
  "Cxcl14",
  "Cxcl15",
  "Cxcl16",
  "Cxcl17",
  "Cxcl2",
  "Cxcl3",
  "Cxcl5",
  "Cxcl9",
  "Cxcr1",
  "Cxcr2",
  "Cxcr3",
  "Cxcr4",
  "Cxcr5",
  "Cxcr6",
  "Cxxc1",
  "Cxxc4",
  "Cxxc5",
  "Cyb561",
  "Cyb561a3",
  "Cyb561d1",
  "Cyb561d2",
  "Cyb5a",
  "Cyb5b",
  "Cyb5d1",
  "Cyb5d2",
  "Cyb5r1",
  "Cyb5r2",
  "Cyb5r3",
  "Cyb5r4",
  "Cyb5rl",
  "Cyba",
  "Cybb",
  "Cybc1",
  "Cybrd1",
  "Cyc1",
  "Cycs",
  "Cyct",
  "Cyfip1",
  "Cyfip2",
  "Cygb",
  "Cyhr1",
  "Cylc1",
  "Cylc2",
  "Cyld",
  "Cym",
  "Cyp11a1",
  "Cyp11b1",
  "Cyp11b2",
  "Cyp17a1",
  "Cyp19a1",
  "Cyp1a1",
  "Cyp1a2",
  "Cyp1b1",
  "Cyp20a1",
  "Cyp21a1",
  "Cyp24a1",
  "Cyp26a1",
  "Cyp26b1",
  "Cyp26c1",
  "Cyp27a1",
  "Cyp27b1",
  "Cyp2a12",
  "Cyp2a22",
  "Cyp2ab1",
  "Cyp2b10",
  "Cyp2b13",
  "Cyp2b19",
  "Cyp2b9",
  "Cyp2c23",
  "Cyp2c29",
  "Cyp2c37",
  "Cyp2c38",
  "Cyp2c50",
  "Cyp2c54",
  "Cyp2c55",
  "Cyp2c65",
  "Cyp2c68",
  "Cyp2c70",
  "Cyp2d10",
  "Cyp2d11",
  "Cyp2d12",
  "Cyp2d22",
  "Cyp2d26",
  "Cyp2d34",
  "Cyp2d40",
  "Cyp2d9",
  "Cyp2e1",
  "Cyp2f2",
  "Cyp2g1",
  "Cyp2j11",
  "Cyp2j12",
  "Cyp2j13",
  "Cyp2j6",
  "Cyp2j7",
  "Cyp2j8",
  "Cyp2j9",
  "Cyp2r1",
  "Cyp2s1",
  "Cyp2t4",
  "Cyp2u1",
  "Cyp2w1",
  "Cyp39a1",
  "Cyp3a11",
  "Cyp3a13",
  "Cyp3a16",
  "Cyp3a25",
  "Cyp3a57",
  "Cyp3a59",
  "Cyp46a1",
  "Cyp4a10",
  "Cyp4a31",
  "Cyp4b1",
  "Cyp4f13",
  "Cyp4f14",
  "Cyp4f15",
  "Cyp4f16",
  "Cyp4f17",
  "Cyp4f18",
  "Cyp4f37",
  "Cyp4f39",
  "Cyp4f40",
  "Cyp4v3",
  "Cyp4x1",
  "Cyp51",
  "Cyp7a1",
  "Cyp7b1",
  "Cyp8b1",
  "Cypt1",
  "Cypt12",
  "Cypt14",
  "Cypt15",
  "Cypt2",
  "Cypt3",
  "Cypt4",
  "Cyren",
  "Cys1",
  "Cysltr1",
  "Cysltr2",
  "Cysrt1",
  "Cystm1",
  "Cyth1",
  "Cyth2",
  "Cyth3",
  "Cyth4",
  "Cytip",
  "Cytl1",
  "Cyyr1",
  "Czib",
  "D030007L05Rik",
  "D030018L15Rik",
  "D030025E07Rik",
  "D030028A08Rik",
  "D030045P18Rik",
  "D030047H15Rik",
  "D030056L22Rik",
  "D10Wsu102e",
  "D11Wsu47e",
  "D130009I18Rik",
  "D130017N08Rik",
  "D130020L05Rik",
  "D130040H23Rik",
  "D130043K22Rik",
  "D130052B06Rik",
  "D130062J10Rik",
  "D130079A08Rik",
  "D14Ertd670e",
  "D16Ertd472e",
  "D16Ertd519e",
  "D17H6S53E",
  "D1Ertd622e",
  "D1Pas1",
  "D230022J07Rik",
  "D230025D16Rik",
  "D230030E09Rik",
  "D2hgdh",
  "D330023K18Rik",
  "D330041H03Rik",
  "D330050G23Rik",
  "D330050I16Rik",
  "D3Ertd751e",
  "D430018E03Rik",
  "D430019H16Rik",
  "D430020J02Rik",
  "D430036J16Rik",
  "D430040D24Rik",
  "D430041D05Rik",
  "D430042O09Rik",
  "D5Ertd577e",
  "D5Ertd579e",
  "D5Ertd605e",
  "D5Ertd615e",
  "D630003M21Rik",
  "D630008O14Rik",
  "D630011A20Rik",
  "D630014O11Rik",
  "D630023F18Rik",
  "D630029K05Rik",
  "D630033O11Rik",
  "D630039A03Rik",
  "D630045J12Rik",
  "D6Ertd474e",
  "D6Ertd527e",
  "D6Wsu163e",
  "D730002M21Rik",
  "D730003I15Rik",
  "D730003K21Rik",
  "D730045A05Rik",
  "D730045B01Rik",
  "D7Ertd443e",
  "D830013O20Rik",
  "D830024N08Rik",
  "D830025C05Rik",
  "D830026I12Rik",
  "D830036C21Rik",
  "D830039M14Rik",
  "D830044D21Rik",
  "D830050J10Rik",
  "D930007J09Rik",
  "D930007P13Rik",
  "D930020B18Rik",
  "D930028M14Rik",
  "D930032P07Rik",
  "D930048N14Rik",
  "Daam2",
  "Dab1",
  "Dab2",
  "Dab2ip",
  "Dach1",
  "Dach2",
  "Dact1",
  "Dact2",
  "Dact3",
  "Dad1",
  "Dag1",
  "Dagla",
  "Daglb",
  "Dalrd3",
  "Dancr",
  "Dand5",
  "Dao",
  "Dap",
  "Dap3",
  "Dapk1",
  "Dapk2",
  "Dapk3",
  "Dapl1",
  "Dapp1",
  "Dars",
  "Dars2",
  "Daw1",
  "Daxx",
  "Dazap1",
  "Dazap2",
  "Dazl",
  "Dbf4",
  "Dbhos",
  "Dbi",
  "Dbil5",
  "Dbn1",
  "Dbndd1",
  "Dbndd2",
  "Dbnl",
  "Dbp",
  "Dbpht2",
  "Dbr1",
  "Dbt",
  "Dbx1",
  "Dbx2",
  "Dcaf1",
  "Dcaf10",
  "Dcaf11",
  "Dcaf12",
  "Dcaf12l1",
  "Dcaf12l2",
  "Dcaf13",
  "Dcaf15",
  "Dcaf17",
  "Dcaf4",
  "Dcaf5",
  "Dcaf6",
  "Dcaf7",
  "Dcaf8",
  "Dcaf8l",
  "Dcakd",
  "Dcbld1",
  "Dcbld2",
  "Dcc",
  "Dcdc2a",
  "Dcdc2b",
  "Dcdc2c",
  "Dchs1",
  "Dchs2",
  "Dck",
  "Dclk1",
  "Dclk2",
  "Dclk3",
  "Dclre1a",
  "Dclre1b",
  "Dclre1c",
  "Dcn",
  "Dcp1a",
  "Dcp1b",
  "Dcp2",
  "Dcpp1",
  "Dcpp2",
  "Dcps",
  "Dcst1",
  "Dcstamp",
  "Dct",
  "Dctd",
  "Dctn1",
  "Dctn2",
  "Dctn3",
  "Dctn4",
  "Dctn5",
  "Dctn6",
  "Dctpp1",
  "Dcun1d1",
  "Dcun1d2",
  "Dcun1d3",
  "Dcun1d4",
  "Dcun1d5",
  "Dcx",
  "Dcxr",
  "Dda1",
  "Ddah1",
  "Ddah2",
  "Ddb1",
  "Ddb2",
  "Ddc",
  "Ddhd1",
  "Ddhd2",
  "Ddi1",
  "Ddi2",
  "Ddias",
  "Ddit3",
  "Ddit4",
  "Ddit4l",
  "Ddn",
  "Ddo",
  "Ddost",
  "Ddr1",
  "Ddr2",
  "Ddrgk1",
  "Ddt",
  "Ddx1",
  "Ddx10",
  "Ddx11",
  "Ddx17",
  "Ddx19a",
  "Ddx19b",
  "Ddx20",
  "Ddx21",
  "Ddx23",
  "Ddx24",
  "Ddx25",
  "Ddx27",
  "Ddx28",
  "Ddx31",
  "Ddx39",
  "Ddx39b",
  "Ddx3x",
  "Ddx3y",
  "Ddx4",
  "Ddx41",
  "Ddx42",
  "Ddx43",
  "Ddx46",
  "Ddx47",
  "Ddx49",
  "Ddx5",
  "Ddx50",
  "Ddx51",
  "Ddx52",
  "Ddx54",
  "Ddx55",
  "Ddx56",
  "Ddx58",
  "Ddx59",
  "Ddx6",
  "Ddx60",
  "Deaf1",
  "Decr1",
  "Decr2",
  "Dedd",
  "Dedd2",
  "Def6",
  "Def8",
  "Defa39",
  "Defa43",
  "Defb1",
  "Defb10",
  "Defb11",
  "Defb12",
  "Defb13",
  "Defb14",
  "Defb15",
  "Defb18",
  "Defb19",
  "Defb2",
  "Defb20",
  "Defb21",
  "Defb22",
  "Defb23",
  "Defb25",
  "Defb26",
  "Defb28",
  "Defb29",
  "Defb30",
  "Defb33",
  "Defb34",
  "Defb35",
  "Defb36",
  "Defb37",
  "Defb38",
  "Defb39",
  "Defb4",
  "Defb40",
  "Defb41",
  "Defb42",
  "Defb43",
  "Defb45",
  "Defb46",
  "Defb47",
  "Defb48",
  "Defb5",
  "Defb50",
  "Defb6",
  "Defb7",
  "Defb8",
  "Defb9",
  "Degs1",
  "Degs2",
  "Dek",
  "Dele1",
  "Denn2b",
  "Dennd11",
  "Dennd1a",
  "Dennd1b",
  "Dennd1c",
  "Dennd2a",
  "Dennd2c",
  "Dennd2d",
  "Dennd3",
  "Dennd4a",
  "Dennd4b",
  "Dennd4c",
  "Dennd5a",
  "Dennd5b",
  "Dennd6a",
  "Dennd6b",
  "Denr",
  "Depdc1b",
  "Depdc5",
  "Depdc7",
  "Depp1",
  "Deptor",
  "Dera",
  "Derl1",
  "Derl2",
  "Derl3",
  "Des",
  "Desi1",
  "Desi2",
  "Det1",
  "Deup1",
  "Dexi",
  "Dffa",
  "Dffb",
  "Dgat1",
  "Dgat2",
  "Dgat2l6",
  "Dgcr2",
  "Dgcr6",
  "Dgcr8",
  "Dgka",
  "Dgkb",
  "Dgkd",
  "Dgke",
  "Dgkeos",
  "Dgkg",
  "Dgkh",
  "Dgki",
  "Dgkk",
  "Dgkq",
  "Dgkz",
  "Dglucy",
  "Dguok",
  "Dhcr24",
  "Dhcr7",
  "Dhdds",
  "Dhdh",
  "Dhfr",
  "Dhh",
  "Dhodh",
  "Dhps",
  "Dhrs1",
  "Dhrs11",
  "Dhrs13",
  "Dhrs2",
  "Dhrs3",
  "Dhrs4",
  "Dhrs7",
  "Dhrs7b",
  "Dhrs7c",
  "Dhrs9",
  "Dhtkd1",
  "Dhx15",
  "Dhx16",
  "Dhx29",
  "Dhx30",
  "Dhx32",
  "Dhx33",
  "Dhx34",
  "Dhx35",
  "Dhx36",
  "Dhx37",
  "Dhx38",
  "Dhx40",
  "Dhx57",
  "Dhx58",
  "Dhx8",
  "Dhx9",
  "Diaph1",
  "Diaph2",
  "Diaph3",
  "Dicer1",
  "Dido1",
  "Dio1",
  "Dio2",
  "Dio3",
  "Dio3os",
  "Dip2a",
  "Dip2b",
  "Dip2c",
  "Dipk1a",
  "Dipk1b",
  "Dipk1c",
  "Dipk2a",
  "Dipk2b",
  "Diras1",
  "Dis3",
  "Dis3l",
  "Dis3l2",
  "Disc1",
  "Disp1",
  "Disp2",
  "Disp3",
  "Dixdc1",
  "Dkc1",
  "Dkk1",
  "Dkk2",
  "Dkk3",
  "Dkk4",
  "Dkkl1",
  "Dlat",
  "Dlc1",
  "Dld",
  "Dlec1",
  "Dleu2",
  "Dleu7",
  "Dlg1",
  "Dlg2",
  "Dlg3",
  "Dlg4",
  "Dlg5",
  "Dlgap1",
  "Dlgap2",
  "Dlgap3",
  "Dlgap4",
  "Dlgap5",
  "Dlk1",
  "Dlk2",
  "Dll1",
  "Dll3",
  "Dll4",
  "Dlst",
  "Dlx1",
  "Dlx1as",
  "Dlx2",
  "Dlx3",
  "Dlx4",
  "Dlx4os",
  "Dlx5",
  "Dlx6",
  "Dmac1",
  "Dmac2",
  "Dmac2l",
  "Dmap1",
  "Dmbt1",
  "Dmbx1",
  "Dmc1",
  "Dmd",
  "Dmgdh",
  "Dmkn",
  "Dmp1",
  "Dmpk",
  "Dmrt1",
  "Dmrt2",
  "Dmrt3",
  "Dmrta1",
  "Dmrta2",
  "Dmrtb1",
  "Dmrtc1a",
  "Dmrtc1b",
  "Dmrtc2",
  "Dmtf1",
  "Dmtn",
  "Dmwd",
  "Dmxl1",
  "Dmxl2",
  "Dna2",
  "Dnaaf1",
  "Dnaaf3",
  "Dnaaf4",
  "Dnaaf5",
  "Dnah1",
  "Dnah10",
  "Dnah11",
  "Dnah12",
  "Dnah14",
  "Dnah17",
  "Dnah2",
  "Dnah3",
  "Dnah5",
  "Dnah6",
  "Dnah7a",
  "Dnah7b",
  "Dnah8",
  "Dnah9",
  "Dnaic1",
  "Dnaic2",
  "Dnaja1",
  "Dnaja2",
  "Dnaja3",
  "Dnaja4",
  "Dnajb1",
  "Dnajb11",
  "Dnajb12",
  "Dnajb13",
  "Dnajb14",
  "Dnajb2",
  "Dnajb3",
  "Dnajb4",
  "Dnajb5",
  "Dnajb6",
  "Dnajb7",
  "Dnajb8",
  "Dnajb9",
  "Dnajc1",
  "Dnajc10",
  "Dnajc11",
  "Dnajc12",
  "Dnajc13",
  "Dnajc14",
  "Dnajc15",
  "Dnajc16",
  "Dnajc18",
  "Dnajc19",
  "Dnajc2",
  "Dnajc21",
  "Dnajc22",
  "Dnajc24",
  "Dnajc25",
  "Dnajc27",
  "Dnajc28",
  "Dnajc3",
  "Dnajc30",
  "Dnajc4",
  "Dnajc5",
  "Dnajc5b",
  "Dnajc5g",
  "Dnajc6",
  "Dnajc7",
  "Dnajc8",
  "Dnajc9",
  "Dnal1",
  "Dnal4",
  "Dnali1",
  "Dnase1",
  "Dnase1l1",
  "Dnase1l2",
  "Dnase1l3",
  "Dnase2a",
  "Dnase2b",
  "Dnd1",
  "Dner",
  "Dnhd1",
  "Dnlz",
  "Dnm1",
  "Dnm1l",
  "Dnm2",
  "Dnm3",
  "Dnm3os",
  "Dnmbp",
  "Dnmt1",
  "Dnmt3a",
  "Dnmt3aos",
  "Dnmt3b",
  "Dnmt3bos",
  "Dnmt3l",
  "Dnpep",
  "Dnph1",
  "Dntt",
  "Dnttip1",
  "Dnttip2",
  "Doc2a",
  "Doc2b",
  "Doc2g",
  "Dock1",
  "Dock10",
  "Dock11",
  "Dock2",
  "Dock3",
  "Dock4",
  "Dock5",
  "Dock6",
  "Dock7",
  "Dock8",
  "Dock9",
  "Dohh",
  "Dok1",
  "Dok2",
  "Dok3",
  "Dok4",
  "Dok5",
  "Dok6",
  "Dok7",
  "Dolk",
  "Dolpp1",
  "Donson",
  "Dop1a",
  "Dop1b",
  "Dot1l",
  "Doxl1",
  "Doxl2",
  "Dpagt1",
  "Dpep1",
  "Dpep3",
  "Dpf1",
  "Dpf2",
  "Dpf3",
  "Dph1",
  "Dph2",
  "Dph3",
  "Dph5",
  "Dph6",
  "Dph7",
  "Dpm1",
  "Dpm2",
  "Dpm3",
  "Dpp10",
  "Dpp3",
  "Dpp4",
  "Dpp6",
  "Dpp7",
  "Dpp8",
  "Dpp9",
  "Dppa1",
  "Dppa2",
  "Dppa3",
  "Dppa4",
  "Dppa5a",
  "Dpt",
  "Dpy19l1",
  "Dpy19l2",
  "Dpy19l3",
  "Dpy19l4",
  "Dpy30",
  "Dpyd",
  "Dpys",
  "Dpysl2",
  "Dpysl3",
  "Dpysl4",
  "Dpysl5",
  "Dqx1",
  "Dr1",
  "Dram1",
  "Drap1",
  "Draxin",
  "Drc1",
  "Drc3",
  "Drc7",
  "Drd1",
  "Drd2",
  "Drd3",
  "Drd4",
  "Drd5",
  "Drg1",
  "Drg2",
  "Drosha",
  "Drp2",
  "Dsc1",
  "Dsc2",
  "Dsc3",
  "Dscam",
  "Dscaml1",
  "Dscc1",
  "Dse",
  "Dsel",
  "Dsg1a",
  "Dsg1b",
  "Dsg1c",
  "Dsg2",
  "Dsg3",
  "Dsg4",
  "Dsn1",
  "Dsp",
  "Dspp",
  "Dst",
  "Dstn",
  "Dstyk",
  "Dtd1",
  "Dtd2",
  "Dtl",
  "Dtna",
  "Dtnb",
  "Dtnbp1",
  "Dtwd1",
  "Dtwd2",
  "Dtx1",
  "Dtx2",
  "Dtx3",
  "Dtx3l",
  "Dtx4",
  "Dtymk",
  "Dubr",
  "Duox1",
  "Duox2",
  "Duoxa1",
  "Duoxa2",
  "Dupd1",
  "Dus1l",
  "Dus2",
  "Dus3l",
  "Dus4l",
  "Dusp1",
  "Dusp10",
  "Dusp11",
  "Dusp12",
  "Dusp13",
  "Dusp14",
  "Dusp15",
  "Dusp16",
  "Dusp18",
  "Dusp19",
  "Dusp2",
  "Dusp21",
  "Dusp22",
  "Dusp23",
  "Dusp26",
  "Dusp27",
  "Dusp28",
  "Dusp3",
  "Dusp4",
  "Dusp5",
  "Dusp6",
  "Dusp7",
  "Dusp8",
  "Dusp9",
  "Dut",
  "Dvl1",
  "Dvl2",
  "Dvl3",
  "Dxo",
  "Dydc1",
  "Dydc2",
  "Dym",
  "Dynap",
  "Dync1h1",
  "Dync1i1",
  "Dync1i2",
  "Dync1li1",
  "Dync1li2",
  "Dync2h1",
  "Dync2li1",
  "Dynll1",
  "Dynll2",
  "Dynlrb2",
  "Dynlt1c",
  "Dynlt3",
  "Dyrk1a",
  "Dyrk1b",
  "Dyrk2",
  "Dyrk3",
  "Dyrk4",
  "Dysf",
  "Dytn",
  "Dzank1",
  "Dzip1",
  "Dzip3",
  "E030013I19Rik",
  "E030018B13Rik",
  "E030025P04Rik",
  "E030030I06Rik",
  "E030042O20Rik",
  "E030044B06Rik",
  "E130018N17Rik",
  "E130018O15Rik",
  "E130101E03Rik",
  "E130102H24Rik",
  "E130114P18Rik",
  "E130116L18Rik",
  "E130119H09Rik",
  "E130202H07Rik",
  "E130218I03Rik",
  "E130304I02Rik",
  "E130307A14Rik",
  "E130308A19Rik",
  "E130309D02Rik",
  "E130311K13Rik",
  "E230001N04Rik",
  "E230016K23Rik",
  "E230016M11Rik",
  "E230025N22Rik",
  "E230029C05Rik",
  "E230032D23Rik",
  "E2f1",
  "E2f2",
  "E2f3",
  "E2f4",
  "E2f5",
  "E2f6",
  "E2f7",
  "E2f8",
  "E330011O21Rik",
  "E330012B07Rik",
  "E330013P04Rik",
  "E330017L17Rik",
  "E330018M18Rik",
  "E330020D12Rik",
  "E330021D16Rik",
  "E330032C10Rik",
  "E330034G19Rik",
  "E430018J23Rik",
  "E4f1",
  "E530011L22Rik",
  "Eaf1",
  "Eaf2",
  "Eapp",
  "Ear14",
  "Ear6",
  "Ears2",
  "Ebf1",
  "Ebf2",
  "Ebf3",
  "Ebf4",
  "Ebi3",
  "Ebna1bp2",
  "Ebp",
  "Ebpl",
  "Ecd",
  "Ece1",
  "Ece2",
  "Ecel1",
  "Ech1",
  "Echdc1",
  "Echdc2",
  "Echdc3",
  "Echs1",
  "Eci1",
  "Eci2",
  "Eci3",
  "Ecm1",
  "Ecm2",
  "Ecpas",
  "Ecrg4",
  "Ecscr",
  "Ecsit",
  "Ect2",
  "Ect2l",
  "Eda",
  "Eda2r",
  "Edar",
  "Edaradd",
  "Edc3",
  "Edc4",
  "Eddm3b",
  "Edem1",
  "Edem2",
  "Edem3",
  "Edf1",
  "Edil3",
  "Edn1",
  "Edn2",
  "Edn3",
  "Ednra",
  "Ednrb",
  "Edrf1",
  "Eea1",
  "Eed",
  "Eef1a1",
  "Eef1a2",
  "Eef1akmt1",
  "Eef1akmt2",
  "Eef1akmt3",
  "Eef1akmt4",
  "Eef1aknmt",
  "Eef1b2",
  "Eef1d",
  "Eef1e1",
  "Eef2",
  "Eef2k",
  "Eef2kmt",
  "Eefsec",
  "Eepd1",
  "Efcab1",
  "Efcab10",
  "Efcab11",
  "Efcab12",
  "Efcab14",
  "Efcab15",
  "Efcab2",
  "Efcab5",
  "Efcab6",
  "Efcab7",
  "Efcab8",
  "Efcab9",
  "Efcc1",
  "Efemp1",
  "Efemp2",
  "Efhb",
  "Efhc1",
  "Efhc2",
  "Efhd1",
  "Efhd2",
  "Efl1",
  "Efna1",
  "Efna2",
  "Efna3",
  "Efna4",
  "Efna5",
  "Efnb1",
  "Efnb2",
  "Efnb3",
  "Efr3a",
  "Efr3b",
  "Efs",
  "Eftud2",
  "Egf",
  "Egfem1",
  "Egfl6",
  "Egfl7",
  "Egfl8",
  "Egflam",
  "Egfr",
  "Egln1",
  "Egln2",
  "Egln3",
  "Egr1",
  "Egr2",
  "Egr3",
  "Egr4",
  "Ehbp1",
  "Ehbp1l1",
  "Ehd1",
  "Ehd2",
  "Ehd3",
  "Ehd4",
  "Ehf",
  "Ehhadh",
  "Ehmt1",
  "Ehmt2",
  "Ei24",
  "Eid1",
  "Eid2",
  "Eid2b",
  "Eid3",
  "Eif1",
  "Eif1a",
  "Eif1ad",
  "Eif1ax",
  "Eif1b",
  "Eif2a",
  "Eif2ak1",
  "Eif2ak2",
  "Eif2ak3",
  "Eif2ak4",
  "Eif2b1",
  "Eif2b2",
  "Eif2b3",
  "Eif2b4",
  "Eif2b5",
  "Eif2d",
  "Eif2s1",
  "Eif2s2",
  "Eif2s3x",
  "Eif2s3y",
  "Eif3a",
  "Eif3b",
  "Eif3c",
  "Eif3d",
  "Eif3e",
  "Eif3f",
  "Eif3g",
  "Eif3h",
  "Eif3i",
  "Eif3j1",
  "Eif3k",
  "Eif3l",
  "Eif4a1",
  "Eif4a2",
  "Eif4a3",
  "Eif4a3l2",
  "Eif4b",
  "Eif4e",
  "Eif4e1b",
  "Eif4e2",
  "Eif4e3",
  "Eif4ebp1",
  "Eif4ebp2",
  "Eif4enif1",
  "Eif4g1",
  "Eif4g2",
  "Eif4g3",
  "Eif4h",
  "Eif5",
  "Eif5a",
  "Eif5a2",
  "Eif5b",
  "Eif6",
  "Eipr1",
  "Elac1",
  "Elac2",
  "Elane",
  "Elavl1",
  "Elavl2",
  "Elavl3",
  "Elavl4",
  "Eldr",
  "Elf1",
  "Elf2",
  "Elf3",
  "Elf4",
  "Elf5",
  "Elfn2",
  "Elk1",
  "Elk3",
  "Elk4",
  "Ell",
  "Ell2",
  "Ell3",
  "Elmo1",
  "Elmo2",
  "Elmo3",
  "Elmod1",
  "Elmod2",
  "Elmod3",
  "Elmsan1",
  "Eln",
  "Eloa",
  "Elob",
  "Elobl",
  "Eloc",
  "Elof1",
  "Elovl1",
  "Elovl2",
  "Elovl3",
  "Elovl4",
  "Elovl5",
  "Elovl6",
  "Elovl7",
  "Elp1",
  "Elp2",
  "Elp3",
  "Elp4",
  "Elp5",
  "Elp6",
  "Emb",
  "Emc1",
  "Emc10",
  "Emc3",
  "Emc4",
  "Emc6",
  "Emc7",
  "Emc8",
  "Emc9",
  "Emcn",
  "Emd",
  "Eme1",
  "Eme2",
  "Emg1",
  "Emid1",
  "Emilin1",
  "Emilin2",
  "Emilin3",
  "Eml1",
  "Eml2",
  "Eml3",
  "Eml4",
  "Eml5",
  "Eml6",
  "Emp1",
  "Emp2",
  "Emp3",
  "Emsy",
  "Emx1",
  "Emx2",
  "Emx2os",
  "En1",
  "En2",
  "Enah",
  "Enam",
  "Enc1",
  "Endod1",
  "Endog",
  "Endou",
  "Eng",
  "Engase",
  "Enho",
  "Enkd1",
  "Enkur",
  "Eno1b",
  "Eno2",
  "Eno3",
  "Eno4",
  "Enoph1",
  "Enox1",
  "Enox2",
  "Enpep",
  "Enpp1",
  "Enpp2",
  "Enpp3",
  "Enpp4",
  "Enpp5",
  "Enpp6",
  "Enpp7",
  "Ensa",
  "Enthd1",
  "Entpd1",
  "Entpd2",
  "Entpd3",
  "Entpd5",
  "Entpd6",
  "Entpd7",
  "Entpd8",
  "Entr1",
  "Eny2",
  "Eogt",
  "Eomes",
  "Ep300",
  "Ep400",
  "Epas1",
  "Epb41",
  "Epb41l1",
  "Epb41l2",
  "Epb41l3",
  "Epb41l4a",
  "Epb41l4b",
  "Epb41l5",
  "Epb42",
  "Epc1",
  "Epc2",
  "Epcam",
  "Epdr1",
  "Epg5",
  "Epgn",
  "Epha1",
  "Epha10",
  "Epha2",
  "Epha3",
  "Epha4",
  "Epha5",
  "Epha6",
  "Epha7",
  "Epha8",
  "Ephb1",
  "Ephb2",
  "Ephb4",
  "Ephb6",
  "Ephx1",
  "Ephx2",
  "Ephx3",
  "Ephx4",
  "Epm2a",
  "Epm2aip1",
  "Epn1",
  "Epn2",
  "Epn3",
  "Epo",
  "Epop",
  "Epor",
  "Epp13",
  "Eppin",
  "Eppk1",
  "Eprn",
  "Eprs",
  "Eps15",
  "Eps15l1",
  "Eps8",
  "Eps8l1",
  "Eps8l2",
  "Eps8l3",
  "Epsti1",
  "Epx",
  "Epyc",
  "Eqtn",
  "Eral1",
  "Erap1",
  "Eras",
  "Erbb2",
  "Erbb3",
  "Erbb4",
  "Erbin",
  "Erc1",
  "Erc2",
  "Ercc1",
  "Ercc2",
  "Ercc3",
  "Ercc4",
  "Ercc5",
  "Ercc6",
  "Ercc6l",
  "Ercc6l2",
  "Ercc8",
  "Ereg",
  "Erf",
  "Erfe",
  "Erfl",
  "Erg",
  "Erg28",
  "Ergic1",
  "Ergic2",
  "Ergic3",
  "Erh",
  "Eri1",
  "Eri2",
  "Eri3",
  "Erich1",
  "Erich2",
  "Erich3",
  "Erich4",
  "Erich6",
  "Erich6b",
  "Erlin1",
  "Erlin2",
  "Ermap",
  "Ermard",
  "Ermn",
  "Ermp1",
  "Ern1",
  "Ern2",
  "Ero1l",
  "Ero1lb",
  "Erp27",
  "Erp29",
  "Erp44",
  "Errfi1",
  "Erv3",
  "Esam",
  "Esco1",
  "Esco2",
  "Esd",
  "Esf1",
  "Esm1",
  "Esp1",
  "Esp6",
  "Espl1",
  "Espn",
  "Espnl",
  "Esr1",
  "Esr2",
  "Esrp1",
  "Esrp2",
  "Esrra",
  "Esrrb",
  "Esrrg",
  "Ess2",
  "Esx1",
  "Esyt1",
  "Esyt2",
  "Esyt3",
  "Etaa1",
  "Etd",
  "Etf1",
  "Etfa",
  "Etfb",
  "Etfbkmt",
  "Etfdh",
  "Etfrf1",
  "Ethe1",
  "Etl4",
  "Etnk1",
  "Etnk2",
  "Etnppl",
  "Etohd2",
  "Etos1",
  "Ets1",
  "Ets2",
  "Etv1",
  "Etv2",
  "Etv3",
  "Etv4",
  "Etv5",
  "Etv6",
  "Eva1a",
  "Eva1b",
  "Eva1c",
  "Evc",
  "Evc2",
  "Evi2",
  "Evi2a",
  "Evi5",
  "Evi5l",
  "Evl",
  "Evpl",
  "Evx1",
  "Evx1os",
  "Evx2",
  "Ewsr1",
  "Exd1",
  "Exd2",
  "Exo1",
  "Exo5",
  "Exoc1",
  "Exoc2",
  "Exoc3",
  "Exoc3l",
  "Exoc3l2",
  "Exoc3l4",
  "Exoc4",
  "Exoc5",
  "Exoc6",
  "Exoc6b",
  "Exoc7",
  "Exoc8",
  "Exog",
  "Exosc1",
  "Exosc10",
  "Exosc2",
  "Exosc3",
  "Exosc4",
  "Exosc5",
  "Exosc7",
  "Exosc8",
  "Exph5",
  "Ext1",
  "Ext2",
  "Extl1",
  "Extl2",
  "Extl3",
  "Eya2",
  "Eya3",
  "Eya4",
  "Ezh1",
  "Ezh2",
  "Ezr",
  "F10",
  "F11",
  "F11r",
  "F12",
  "F13a1",
  "F13b",
  "F2",
  "F2r",
  "F2rl1",
  "F2rl3",
  "F3",
  "F420014N23Rik",
  "F5",
  "F530104D19Rik",
  "F630028O10Rik",
  "F630042J09Rik",
  "F7",
  "F730016J06Rik",
  "F730035P03Rik",
  "F730043M19Rik",
  "F730048M01Rik",
  "F8",
  "F830016B08Rik",
  "F830045P16Rik",
  "F830208F22Rik",
  "F8a",
  "F9",
  "F930017D23Rik",
  "Fa2h",
  "Faah",
  "Faap100",
  "Faap20",
  "Faap24",
  "Fabp1",
  "Fabp12",
  "Fabp2",
  "Fabp3",
  "Fabp4",
  "Fabp5",
  "Fabp6",
  "Fabp7",
  "Fabp9",
  "Fadd",
  "Faddos",
  "Fads1",
  "Fads2",
  "Fads2b",
  "Fads3",
  "Fads6",
  "Faf1",
  "Faf2",
  "Fah",
  "Fahd1",
  "Fahd2a",
  "Faim",
  "Faiml",
  "Fam102a",
  "Fam102b",
  "Fam104a",
  "Fam107a",
  "Fam107b",
  "Fam110a",
  "Fam110b",
  "Fam110c",
  "Fam111a",
  "Fam114a1",
  "Fam114a2",
  "Fam117a",
  "Fam117b",
  "Fam118a",
  "Fam118b",
  "Fam120a",
  "Fam120b",
  "Fam120c",
  "Fam122a",
  "Fam122b",
  "Fam122c",
  "Fam124a",
  "Fam124b",
  "Fam126a",
  "Fam126b",
  "Fam129a",
  "Fam129b",
  "Fam129c",
  "Fam131a",
  "Fam131b",
  "Fam131c",
  "Fam135a",
  "Fam135b",
  "Fam136a",
  "Fam13a",
  "Fam13b",
  "Fam13c",
  "Fam149a",
  "Fam149b",
  "Fam151a",
  "Fam151b",
  "Fam155a",
  "Fam160a1",
  "Fam160a2",
  "Fam160b1",
  "Fam160b2",
  "Fam161a",
  "Fam161b",
  "Fam162a",
  "Fam162b",
  "Fam163a",
  "Fam163b",
  "Fam166a",
  "Fam166b",
  "Fam166c",
  "Fam167a",
  "Fam167b",
  "Fam168a",
  "Fam168b",
  "Fam169a",
  "Fam169b",
  "Fam170a",
  "Fam170b",
  "Fam171a1",
  "Fam171a2",
  "Fam171b",
  "Fam172a",
  "Fam174a",
  "Fam174b",
  "Fam174c",
  "Fam178b",
  "Fam180a",
  "Fam181a",
  "Fam181b",
  "Fam183b",
  "Fam184a",
  "Fam184b",
  "Fam185a",
  "Fam186a",
  "Fam187a",
  "Fam187b",
  "Fam189a1",
  "Fam189a2",
  "Fam189b",
  "Fam192a",
  "Fam193a",
  "Fam193b",
  "Fam199x",
  "Fam204a",
  "Fam205c",
  "Fam207a",
  "Fam209",
  "Fam20a",
  "Fam20b",
  "Fam20c",
  "Fam210a",
  "Fam210b",
  "Fam214a",
  "Fam214b",
  "Fam216a",
  "Fam216b",
  "Fam217a",
  "Fam217b",
  "Fam219aos",
  "Fam219b",
  "Fam221a",
  "Fam221b",
  "Fam222a",
  "Fam222b",
  "Fam227a",
  "Fam227b",
  "Fam228a",
  "Fam228b",
  "Fam229a",
  "Fam229b",
  "Fam234a",
  "Fam234b",
  "Fam240a",
  "Fam240b",
  "Fam241a",
  "Fam241b",
  "Fam243",
  "Fam24a",
  "Fam24b",
  "Fam25c",
  "Fam32a",
  "Fam3a",
  "Fam3b",
  "Fam3c",
  "Fam43b",
  "Fam45a",
  "Fam47c",
  "Fam47e",
  "Fam49a",
  "Fam49b",
  "Fam50a",
  "Fam50b",
  "Fam53a",
  "Fam53b",
  "Fam53c",
  "Fam57a",
  "Fam57b",
  "Fam71a",
  "Fam71b",
  "Fam71d",
  "Fam71e1",
  "Fam71e2",
  "Fam71f1",
  "Fam71f2",
  "Fam72a",
  "Fam76a",
  "Fam76b",
  "Fam78a",
  "Fam78b",
  "Fam81a",
  "Fam81b",
  "Fam83a",
  "Fam83b",
  "Fam83c",
  "Fam83d",
  "Fam83e",
  "Fam83f",
  "Fam83g",
  "Fam83h",
  "Fam89a",
  "Fam8a1",
  "Fam90a1a",
  "Fam90a1b",
  "Fam91a1",
  "Fam92a",
  "Fam92b",
  "Fam98a",
  "Fam98b",
  "Fam98c",
  "Fan1",
  "Fanca",
  "Fancb",
  "Fancc",
  "Fancd2",
  "Fancd2os",
  "Fance",
  "Fancf",
  "Fancg",
  "Fanci",
  "Fancl",
  "Fancm",
  "Fank1",
  "Fap",
  "Far1",
  "Far2",
  "Far2os2",
  "Farp1",
  "Farp2",
  "Fars2",
  "Farsa",
  "Farsb",
  "Fas",
  "Fasl",
  "Fasn",
  "Fastk",
  "Fastkd1",
  "Fastkd2",
  "Fastkd3",
  "Fastkd5",
  "Fat1",
  "Fat2",
  "Fat3",
  "Fat4",
  "Fate1",
  "Fau",
  "Faxc",
  "Fbf1",
  "Fbh1",
  "Fbl",
  "Fblim1",
  "Fbll1",
  "Fbln1",
  "Fbln2",
  "Fbln5",
  "Fbln7",
  "Fbn1",
  "Fbn2",
  "Fbp1",
  "Fbp2",
  "Fbrs",
  "Fbrsl1",
  "Fbxl12",
  "Fbxl12os",
  "Fbxl13",
  "Fbxl14",
  "Fbxl15",
  "Fbxl16",
  "Fbxl17",
  "Fbxl18",
  "Fbxl19",
  "Fbxl2",
  "Fbxl20",
  "Fbxl21",
  "Fbxl22",
  "Fbxl3",
  "Fbxl4",
  "Fbxl5",
  "Fbxl6",
  "Fbxl7",
  "Fbxl8",
  "Fbxo10",
  "Fbxo11",
  "Fbxo15",
  "Fbxo16",
  "Fbxo17",
  "Fbxo2",
  "Fbxo21",
  "Fbxo22",
  "Fbxo24",
  "Fbxo25",
  "Fbxo27",
  "Fbxo28",
  "Fbxo3",
  "Fbxo30",
  "Fbxo31",
  "Fbxo32",
  "Fbxo33",
  "Fbxo34",
  "Fbxo36",
  "Fbxo38",
  "Fbxo39",
  "Fbxo4",
  "Fbxo40",
  "Fbxo41",
  "Fbxo42",
  "Fbxo43",
  "Fbxo44",
  "Fbxo45",
  "Fbxo46",
  "Fbxo47",
  "Fbxo48",
  "Fbxo5",
  "Fbxo6",
  "Fbxo7",
  "Fbxo8",
  "Fbxo9",
  "Fbxw10",
  "Fbxw11",
  "Fbxw13",
  "Fbxw15",
  "Fbxw16",
  "Fbxw17",
  "Fbxw18",
  "Fbxw19",
  "Fbxw2",
  "Fbxw20",
  "Fbxw21",
  "Fbxw22",
  "Fbxw24",
  "Fbxw25",
  "Fbxw26",
  "Fbxw27",
  "Fbxw4",
  "Fbxw5",
  "Fbxw7",
  "Fbxw8",
  "Fbxw9",
  "Fcamr",
  "Fcer1a",
  "Fcer1g",
  "Fcer2a",
  "Fcgbp",
  "Fcgr1",
  "Fcgr2b",
  "Fcgr4",
  "Fcgrt",
  "Fcho1",
  "Fcho2",
  "Fchsd1",
  "Fchsd2",
  "Fcmr",
  "Fcna",
  "Fcnaos",
  "Fcnb",
  "Fcrl1",
  "Fcrl5",
  "Fcrl6",
  "Fcrla",
  "Fcrlb",
  "Fcrls",
  "Fcsk",
  "Fdft1",
  "Fdps",
  "Fdx1",
  "Fdxacb1",
  "Fdxr",
  "Fech",
  "Fem1a",
  "Fem1al",
  "Fem1b",
  "Fem1c",
  "Fen1",
  "Fendrr",
  "Fer",
  "Fer1l4",
  "Fer1l5",
  "Fer1l6",
  "Ferd3l",
  "Fermt1",
  "Fermt2",
  "Fermt3",
  "Fes",
  "Fetub",
  "Fev",
  "Fez1",
  "Fez2",
  "Fezf1",
  "Fezf2",
  "Ffar1",
  "Ffar2",
  "Ffar3",
  "Ffar4",
  "Fga",
  "Fgb",
  "Fgd1",
  "Fgd2",
  "Fgd3",
  "Fgd4",
  "Fgd6",
  "Fgf1",
  "Fgf10",
  "Fgf11",
  "Fgf12",
  "Fgf13",
  "Fgf14",
  "Fgf15",
  "Fgf17",
  "Fgf18",
  "Fgf2",
  "Fgf20",
  "Fgf22",
  "Fgf23",
  "Fgf2os",
  "Fgf3",
  "Fgf4",
  "Fgf5",
  "Fgf6",
  "Fgf7",
  "Fgf8",
  "Fgf9",
  "Fgfbp3",
  "Fgfr1",
  "Fgfr1op",
  "Fgfr1op2",
  "Fgfr2",
  "Fgfr3",
  "Fgfr4",
  "Fgfrl1",
  "Fgg",
  "Fggy",
  "Fgl1",
  "Fgl2",
  "Fgr",
  "Fh1",
  "Fhad1",
  "Fhdc1",
  "Fhit",
  "Fhitos",
  "Fhl1",
  "Fhl2",
  "Fhl3",
  "Fhl4",
  "Fhl5",
  "Fhod1",
  "Fhod3",
  "Fibcd1",
  "Fibin",
  "Fibp",
  "Ficd",
  "Fig4",
  "Figla",
  "Fign",
  "Fignl1",
  "Fignl2",
  "Filip1",
  "Filip1l",
  "Fip1l1",
  "Firre",
  "Fis1",
  "Fitm1",
  "Fitm2",
  "Fiz1",
  "Fjx1",
  "Fkbp11",
  "Fkbp14",
  "Fkbp15",
  "Fkbp1a",
  "Fkbp1b",
  "Fkbp2",
  "Fkbp3",
  "Fkbp4",
  "Fkbp5",
  "Fkbp6",
  "Fkbp7",
  "Fkbp8",
  "Fkbp9",
  "Fkbpl",
  "Fkrp",
  "Fktn",
  "Flacc1",
  "Flad1",
  "Flcn",
  "Flg",
  "Flg2",
  "Fli1",
  "Flicr",
  "Flii",
  "Flna",
  "Flnb",
  "Flnc",
  "Flot1",
  "Flot2",
  "Flrt1",
  "Flrt2",
  "Flrt3",
  "Flt1",
  "Flt3",
  "Flt3l",
  "Flt4",
  "Flvcr1",
  "Flvcr2",
  "Flywch1",
  "Flywch2",
  "Fmc1",
  "Fmn1",
  "Fmn2",
  "Fmnl1",
  "Fmnl2",
  "Fmnl3",
  "Fmo1",
  "Fmo2",
  "Fmo3",
  "Fmo4",
  "Fmo5",
  "Fmo6",
  "Fmo9",
  "Fmod",
  "Fmr1",
  "Fmr1nb",
  "Fn1",
  "Fn3k",
  "Fn3krp",
  "Fnbp1",
  "Fnbp1l",
  "Fnbp4",
  "Fnd3c2",
  "Fndc1",
  "Fndc10",
  "Fndc11",
  "Fndc3a",
  "Fndc3b",
  "Fndc3c1",
  "Fndc4",
  "Fndc5",
  "Fndc7",
  "Fndc8",
  "Fndc9",
  "Fnip1",
  "Fnip2",
  "Fnta",
  "Fntb",
  "Focad",
  "Folh1",
  "Folr1",
  "Folr2",
  "Fopnl",
  "Fos",
  "Fosb",
  "Fosl1",
  "Fosl2",
  "Foxa1",
  "Foxa2",
  "Foxa3",
  "Foxb1",
  "Foxb2",
  "Foxc1",
  "Foxc2",
  "Foxd1",
  "Foxd2",
  "Foxd2os",
  "Foxd3",
  "Foxd4",
  "Foxe1",
  "Foxe3",
  "Foxf1",
  "Foxf2",
  "Foxg1",
  "Foxh1",
  "Foxi1",
  "Foxi2",
  "Foxi3",
  "Foxj1",
  "Foxj2",
  "Foxj3",
  "Foxk1",
  "Foxk2",
  "Foxl1",
  "Foxl2",
  "Foxl2os",
  "Foxm1",
  "Foxn1",
  "Foxn2",
  "Foxn3",
  "Foxn4",
  "Foxo1",
  "Foxo3",
  "Foxo4",
  "Foxo6",
  "Foxo6os",
  "Foxp1",
  "Foxp2",
  "Foxp3",
  "Foxp4",
  "Foxq1",
  "Foxr1",
  "Foxr2",
  "Foxred1",
  "Foxred2",
  "Foxs1",
  "Fpgs",
  "Fpgt",
  "Fpr-rs3",
  "Fpr-rs4",
  "Fpr-rs6",
  "Fpr-rs7",
  "Fpr1",
  "Fpr2",
  "Fpr3",
  "Fra10ac1",
  "Fras1",
  "Frat1",
  "Frat2",
  "Frem1",
  "Frem2",
  "Frem3",
  "Frg1",
  "Frg2f1",
  "Frk",
  "Frmd3",
  "Frmd4a",
  "Frmd4b",
  "Frmd5",
  "Frmd6",
  "Frmd8",
  "Frmd8os",
  "Frmpd1",
  "Frmpd2",
  "Frmpd3",
  "Frmpd4",
  "Frrs1",
  "Frrs1l",
  "Frs2",
  "Frs3os",
  "Fry",
  "Fryl",
  "Frzb",
  "Fsbp",
  "Fscb",
  "Fscn1",
  "Fscn2",
  "Fscn3",
  "Fsd1",
  "Fsd1l",
  "Fsd2",
  "Fshb",
  "Fshr",
  "Fsip1",
  "Fsip2",
  "Fsip2l",
  "Fst",
  "Fstl1",
  "Fstl3",
  "Fstl4",
  "Fstl5",
  "Ftcd",
  "Ftdc1",
  "Ftdc2",
  "Fth1",
  "Fthl17a",
  "Fthl17c",
  "Fthl17f",
  "Ftl1-ps1",
  "Ftmt",
  "Fto",
  "Ftsj1",
  "Ftsj3",
  "Ftx",
  "Fubp1",
  "Fuca1",
  "Fuca2",
  "Fundc1",
  "Fundc2",
  "Fuom",
  "Furin",
  "Fus",
  "Fut1",
  "Fut10",
  "Fut11",
  "Fut2",
  "Fut4",
  "Fut7",
  "Fut8",
  "Fut9",
  "Fuz",
  "Fv1",
  "Fxr1",
  "Fxr2",
  "Fxyd1",
  "Fxyd2",
  "Fxyd3",
  "Fxyd4",
  "Fxyd5",
  "Fxyd6",
  "Fxyd7",
  "Fyb",
  "Fyb2",
  "Fyco1",
  "Fyn",
  "Fyttd1",
  "Fzd1",
  "Fzd10",
  "Fzd10os",
  "Fzd2",
  "Fzd3",
  "Fzd4",
  "Fzd5",
  "Fzd6",
  "Fzd7",
  "Fzd8",
  "Fzd9",
  "Fzr1",
  "G0s2",
  "G2e3",
  "G3bp1",
  "G3bp2",
  "G430095P16Rik",
  "G530011O06Rik",
  "G630016G05Rik",
  "G630018N14Rik",
  "G630030J09Rik",
  "G630055G22Rik",
  "G6pc",
  "G6pc3",
  "G6pd2",
  "G6pdx",
  "G930009F23Rik",
  "G930045G22Rik",
  "Gaa",
  "Gab1",
  "Gab2",
  "Gab3",
  "Gabarap",
  "Gabarapl1",
  "Gabarapl2",
  "Gabbr1",
  "Gabbr2",
  "Gabpa",
  "Gabpb1",
  "Gabpb2",
  "Gabra1",
  "Gabra2",
  "Gabra3",
  "Gabra4",
  "Gabra5",
  "Gabra6",
  "Gabrb1",
  "Gabrb3",
  "Gabrd",
  "Gabre",
  "Gabrg1",
  "Gabrg2",
  "Gabrg3",
  "Gabrp",
  "Gabrq",
  "Gabrr2",
  "Gabrr3",
  "Gad1",
  "Gad2",
  "Gadd45a",
  "Gadd45b",
  "Gadd45g",
  "Gadd45gip1",
  "Gadl1",
  "Gal",
  "Gal3st1",
  "Gal3st2c",
  "Gal3st3",
  "Galc",
  "Gale",
  "Galk1",
  "Galk2",
  "Galm",
  "Galns",
  "Galnt1",
  "Galnt10",
  "Galnt11",
  "Galnt12",
  "Galnt13",
  "Galnt14",
  "Galnt15",
  "Galnt16",
  "Galnt17",
  "Galnt18",
  "Galnt3",
  "Galnt4",
  "Galnt5",
  "Galnt6",
  "Galnt9",
  "Galntl5",
  "Galntl6",
  "Galp",
  "Galr1",
  "Galr2",
  "Galr3",
  "Galt",
  "Gamt",
  "Gan",
  "Ganab",
  "Ganc",
  "Gap43",
  "Gapdhs",
  "Gapt",
  "Gapvd1",
  "Gar1",
  "Garem1",
  "Garem2",
  "Garnl3",
  "Gars",
  "Gart",
  "Gas1",
  "Gas2",
  "Gas2l1",
  "Gas2l2",
  "Gas2l3",
  "Gas5",
  "Gas6",
  "Gas7",
  "Gas8",
  "Gask1a",
  "Gask1b",
  "Gast",
  "Gata1",
  "Gata2",
  "Gata3",
  "Gata4",
  "Gata5",
  "Gata5os",
  "Gata6",
  "Gata6os",
  "Gatad1",
  "Gatad2a",
  "Gatad2b",
  "Gatb",
  "Gatc",
  "Gatd1",
  "Gatd3a",
  "Gatm",
  "Gba",
  "Gba2",
  "Gbe1",
  "Gbf1",
  "Gbgt1",
  "Gbp2",
  "Gbp2b",
  "Gbp3",
  "Gbp4",
  "Gbp5",
  "Gbp7",
  "Gbp8",
  "Gbp9",
  "Gbx1",
  "Gbx2",
  "Gc",
  "Gca",
  "Gcat",
  "Gcc1",
  "Gcc2",
  "Gcdh",
  "Gcfc2",
  "Gcg",
  "Gcgr",
  "Gch1",
  "Gchfr",
  "Gck",
  "Gckr",
  "Gclc",
  "Gclm",
  "Gcm1",
  "Gcn1",
  "Gcnt1",
  "Gcnt2",
  "Gcnt3",
  "Gcnt4",
  "Gcnt7",
  "Gcsam",
  "Gcsh",
  "Gda",
  "Gdap1",
  "Gdap1l1",
  "Gdap2",
  "Gde1",
  "Gdf10",
  "Gdf11",
  "Gdf15",
  "Gdf2",
  "Gdf3",
  "Gdf5",
  "Gdf6",
  "Gdf7",
  "Gdf9",
  "Gdi1",
  "Gdi2",
  "Gdnf",
  "Gdpd1",
  "Gdpd2",
  "Gdpd3",
  "Gdpd4",
  "Gdpd5",
  "Gdpgp1",
  "Gem",
  "Gemin2",
  "Gemin5",
  "Gemin6",
  "Gemin7",
  "Gemin8",
  "Gen1",
  "Get1",
  "Get4",
  "Gfap",
  "Gfer",
  "Gfi1",
  "Gfi1b",
  "Gfm1",
  "Gfm2",
  "Gfod1",
  "Gfod2",
  "Gfpt1",
  "Gfpt2",
  "Gfra1",
  "Gfra2",
  "Gfra3",
  "Gfra4",
  "Gfral",
  "Gfy",
  "Gga1",
  "Gga2",
  "Gga3",
  "Ggact",
  "Ggct",
  "Ggcx",
  "Ggh",
  "Ggn",
  "Ggnbp1",
  "Ggnbp2",
  "Ggps1",
  "Ggt1",
  "Ggt5",
  "Ggt6",
  "Ggt7",
  "Ggta1",
  "Gh",
  "Ghdc",
  "Ghitm",
  "Ghr",
  "Ghrh",
  "Ghrhr",
  "Ghrl",
  "Ghsr",
  "Gid4",
  "Gid8",
  "Gigyf1",
  "Gigyf2",
  "Gimap1",
  "Gimap1os",
  "Gimap3",
  "Gimap4",
  "Gimap5",
  "Gimap6",
  "Gimap7",
  "Gimap8",
  "Gimap9",
  "Gimd1",
  "Gin1",
  "Ginm1",
  "Gins1",
  "Gins2",
  "Gins3",
  "Gins4",
  "Gip",
  "Gipc1",
  "Gipc2",
  "Gipc3",
  "Gipr",
  "Git1",
  "Gja1",
  "Gja10",
  "Gja3",
  "Gja4",
  "Gja5",
  "Gja6",
  "Gja8",
  "Gjb1",
  "Gjb2",
  "Gjb3",
  "Gjb4",
  "Gjb5",
  "Gjb6",
  "Gjc1",
  "Gjc2",
  "Gjc3",
  "Gjd2",
  "Gjd3",
  "Gjd4",
  "Gje1",
  "Gk",
  "Gk2",
  "Gk5",
  "Gkap1",
  "Gkn1",
  "Gkn2",
  "Gkn3",
  "Gla",
  "Glb1",
  "Glb1l",
  "Glb1l2",
  "Glb1l3",
  "Glcci1",
  "Glce",
  "Gldc",
  "Gldn",
  "Gldnos",
  "Gle1",
  "Glg1",
  "Gli1",
  "Gli2",
  "Gli3",
  "Glipr1",
  "Glipr1l1",
  "Glipr1l2",
  "Glipr1l3",
  "Glipr2",
  "Glis1",
  "Glis2",
  "Glis3",
  "Glmn",
  "Glmp",
  "Glo1",
  "Glod4",
  "Glod5",
  "Glp1r",
  "Glp2r",
  "Glra1",
  "Glra2",
  "Glra3",
  "Glra4",
  "Glrb",
  "Glrp1",
  "Glrx",
  "Glrx2",
  "Glrx3",
  "Glrx5",
  "Gls",
  "Gls2",
  "Glt1d1",
  "Glt28d2",
  "Glt6d1",
  "Glt8d1",
  "Glt8d2",
  "Gltp",
  "Gltpd2",
  "Glud1",
  "Glul",
  "Glyat",
  "Glyatl3",
  "Glycam1",
  "Glyctk",
  "Glyr1",
  "Gm10000",
  "Gm10010",
  "Gm10024",
  "Gm10030",
  "Gm10032",
  "Gm10037",
  "Gm10061",
  "Gm10062",
  "Gm10067",
  "Gm10097",
  "Gm10101",
  "Gm10113",
  "Gm10115",
  "Gm10125",
  "Gm10130",
  "Gm10134",
  "Gm10135",
  "Gm10142",
  "Gm10143",
  "Gm10153",
  "Gm10160",
  "Gm10165",
  "Gm10188",
  "Gm10190",
  "Gm10197",
  "Gm10220",
  "Gm10228",
  "Gm10229",
  "Gm10244",
  "Gm10248",
  "Gm10267",
  "Gm10272",
  "Gm10277",
  "Gm10280",
  "Gm10283",
  "Gm10287",
  "Gm10300",
  "Gm10308",
  "Gm10309",
  "Gm10320",
  "Gm10322",
  "Gm10334",
  "Gm10337",
  "Gm10366",
  "Gm10369",
  "Gm10371",
  "Gm10373",
  "Gm10382",
  "Gm10384",
  "Gm10385",
  "Gm10389",
  "Gm10392",
  "Gm10398",
  "Gm10399",
  "Gm10415",
  "Gm10419",
  "Gm10421",
  "Gm10425",
  "Gm1043",
  "Gm10432",
  "Gm10433",
  "Gm10435",
  "Gm10440",
  "Gm10441",
  "Gm10445",
  "Gm10447",
  "Gm10451",
  "Gm10457",
  "Gm10465",
  "Gm10466",
  "Gm10475",
  "Gm10482",
  "Gm10501",
  "Gm10503",
  "Gm10505",
  "Gm10516",
  "Gm10518",
  "Gm10521",
  "Gm10522",
  "Gm10530",
  "Gm10532",
  "Gm10535",
  "Gm10538",
  "Gm10542",
  "Gm10544",
  "Gm10545",
  "Gm10549",
  "Gm10550",
  "Gm10556",
  "Gm10558",
  "Gm10561",
  "Gm10564",
  "Gm10570",
  "Gm10575",
  "Gm10578",
  "Gm10584",
  "Gm10603",
  "Gm10605",
  "Gm10610",
  "Gm10614",
  "Gm10629",
  "Gm10635",
  "Gm10636",
  "Gm10637",
  "Gm10638",
  "Gm10639",
  "Gm10640",
  "Gm10642",
  "Gm10643",
  "Gm10644",
  "Gm10646",
  "Gm10648",
  "Gm10649",
  "Gm10655",
  "Gm10658",
  "Gm10660",
  "Gm10680",
  "Gm10684",
  "Gm10687",
  "Gm10688",
  "Gm1070",
  "Gm10701",
  "Gm10706",
  "Gm10710",
  "Gm10714",
  "Gm10732",
  "Gm10734",
  "Gm10739",
  "Gm10745",
  "Gm10747",
  "Gm10754",
  "Gm10762",
  "Gm10770",
  "Gm10775",
  "Gm10782",
  "Gm10785",
  "Gm10787",
  "Gm10790",
  "Gm10791",
  "Gm10799",
  "Gm10802",
  "Gm10804",
  "Gm10817",
  "Gm10823",
  "Gm10824",
  "Gm10827",
  "Gm10832",
  "Gm10837",
  "Gm10845",
  "Gm10847",
  "Gm10848",
  "Gm10851",
  "Gm10855",
  "Gm10856",
  "Gm10857",
  "Gm10860",
  "Gm10862",
  "Gm10863",
  "Gm10865",
  "Gm10873",
  "Gm10876",
  "Gm10941",
  "Gm10999",
  "Gm11033",
  "Gm1110",
  "Gm11100",
  "Gm11110",
  "Gm11127",
  "Gm11131",
  "Gm11149",
  "Gm11175",
  "Gm11190",
  "Gm11210",
  "Gm11213",
  "Gm11214",
  "Gm11217",
  "Gm1123",
  "Gm11232",
  "Gm11240",
  "Gm11274",
  "Gm11292",
  "Gm11335",
  "Gm1141",
  "Gm11412",
  "Gm11423",
  "Gm11426",
  "Gm11437",
  "Gm11444",
  "Gm11468",
  "Gm11494",
  "Gm11496",
  "Gm11497",
  "Gm11515",
  "Gm11520",
  "Gm11523",
  "Gm11527",
  "Gm11528",
  "Gm11541",
  "Gm11555",
  "Gm11559",
  "Gm11563",
  "Gm11564",
  "Gm11565",
  "Gm11567",
  "Gm11568",
  "Gm11595",
  "Gm11608",
  "Gm11613",
  "Gm11627",
  "Gm11634",
  "Gm11639",
  "Gm11680",
  "Gm11681",
  "Gm11696",
  "Gm11716",
  "Gm11728",
  "Gm11733",
  "Gm11747",
  "Gm11766",
  "Gm11767",
  "Gm11789",
  "Gm11823",
  "Gm11840",
  "Gm11938",
  "Gm11939",
  "Gm11961",
  "Gm11981",
  "Gm11992",
  "Gm12018",
  "Gm12022",
  "Gm12027",
  "Gm12052",
  "Gm12059",
  "Gm12089",
  "Gm12108",
  "Gm12128",
  "Gm12169",
  "Gm12171",
  "Gm12185",
  "Gm12200",
  "Gm12216",
  "Gm12253",
  "Gm12258",
  "Gm12273",
  "Gm12292",
  "Gm12298",
  "Gm12347",
  "Gm12349",
  "Gm12359",
  "Gm12371",
  "Gm12408",
  "Gm12409",
  "Gm12414",
  "Gm12474",
  "Gm12480",
  "Gm12500",
  "Gm12503",
  "Gm12511",
  "Gm12522",
  "Gm12526",
  "Gm12542",
  "Gm12569",
  "Gm12576",
  "Gm12580",
  "Gm12592",
  "Gm12609",
  "Gm12610",
  "Gm12665",
  "Gm12668",
  "Gm12689",
  "Gm12690",
  "Gm12695",
  "Gm12703",
  "Gm12711",
  "Gm12758",
  "Gm12784",
  "Gm12789",
  "Gm12790",
  "Gm12794",
  "Gm128",
  "Gm12800",
  "Gm12827",
  "Gm12830",
  "Gm12863",
  "Gm12866",
  "Gm12886",
  "Gm12888",
  "Gm12945",
  "Gm12962",
  "Gm12973",
  "Gm12992",
  "Gm13017",
  "Gm13021",
  "Gm13023",
  "Gm13030",
  "Gm13032",
  "Gm13063",
  "Gm13078",
  "Gm13083",
  "Gm13084",
  "Gm13088",
  "Gm13090",
  "Gm13101",
  "Gm13103",
  "Gm13112",
  "Gm13180",
  "Gm13199",
  "Gm13200",
  "Gm13201",
  "Gm13209",
  "Gm13218",
  "Gm13219",
  "Gm13262",
  "Gm13274",
  "Gm1330",
  "Gm13344",
  "Gm13375",
  "Gm13391",
  "Gm13403",
  "Gm13420",
  "Gm13429",
  "Gm13446",
  "Gm13483",
  "Gm13490",
  "Gm13496",
  "Gm13522",
  "Gm13546",
  "Gm13547",
  "Gm13580",
  "Gm13582",
  "Gm136",
  "Gm13619",
  "Gm13661",
  "Gm13663",
  "Gm13686",
  "Gm13710",
  "Gm13713",
  "Gm13749",
  "Gm13773",
  "Gm13775",
  "Gm13793",
  "Gm13807",
  "Gm13816",
  "Gm13832",
  "Gm13833",
  "Gm13861",
  "Gm13889",
  "Gm13941",
  "Gm13943",
  "Gm13954",
  "Gm13977",
  "Gm13986",
  "Gm13997",
  "Gm13999",
  "Gm14003",
  "Gm14004",
  "Gm14010",
  "Gm14014",
  "Gm14015",
  "Gm14066",
  "Gm14089",
  "Gm14110",
  "Gm14124",
  "Gm14135",
  "Gm14137",
  "Gm14139",
  "Gm14149",
  "Gm14154",
  "Gm14164",
  "Gm14168",
  "Gm14204",
  "Gm14206",
  "Gm14207",
  "Gm14211",
  "Gm14214",
  "Gm14221",
  "Gm14222",
  "Gm14230",
  "Gm14243",
  "Gm14246",
  "Gm14296",
  "Gm14317",
  "Gm14321",
  "Gm14340",
  "Gm14393",
  "Gm14410",
  "Gm14412",
  "Gm14424",
  "Gm14443",
  "Gm14461",
  "Gm14488",
  "Gm14496",
  "Gm14634",
  "Gm14635",
  "Gm14636",
  "Gm14661",
  "Gm14703",
  "Gm14717",
  "Gm14762",
  "Gm14773",
  "Gm14812",
  "Gm14820",
  "Gm14862",
  "Gm14963",
  "Gm14964",
  "Gm14966",
  "Gm15046",
  "Gm15056",
  "Gm15104",
  "Gm15130",
  "Gm15155",
  "Gm15217",
  "Gm15222",
  "Gm15232",
  "Gm15234",
  "Gm15262",
  "Gm15270",
  "Gm15283",
  "Gm15294",
  "Gm15298",
  "Gm15318",
  "Gm15328",
  "Gm15340",
  "Gm15342",
  "Gm15348",
  "Gm15353",
  "Gm15401",
  "Gm15406",
  "Gm15407",
  "Gm15409",
  "Gm15412",
  "Gm15413",
  "Gm15417",
  "Gm15441",
  "Gm15448",
  "Gm15457",
  "Gm15458",
  "Gm15478",
  "Gm15479",
  "Gm15482",
  "Gm15498",
  "Gm15503",
  "Gm15520",
  "Gm1553",
  "Gm15535",
  "Gm15557",
  "Gm15612",
  "Gm15688",
  "Gm15691",
  "Gm15704",
  "Gm15706",
  "Gm15737",
  "Gm15743",
  "Gm15762",
  "Gm15821",
  "Gm15850",
  "Gm1587",
  "Gm15952",
  "Gm15998",
  "Gm16046",
  "Gm16062",
  "Gm16066",
  "Gm16140",
  "Gm16155",
  "Gm16181",
  "Gm16223",
  "Gm16267",
  "Gm16286",
  "Gm16287",
  "Gm16294",
  "Gm16347",
  "Gm16401",
  "Gm16497",
  "Gm16499",
  "Gm16506",
  "Gm16508",
  "Gm16551",
  "Gm16675",
  "Gm1673",
  "Gm16853",
  "Gm16863",
  "Gm16894",
  "Gm16958",
  "Gm16982",
  "Gm17018",
  "Gm17019",
  "Gm17092",
  "Gm1715",
  "Gm17175",
  "Gm17202",
  "Gm17216",
  "Gm17226",
  "Gm17359",
  "Gm17382",
  "Gm17399",
  "Gm17484",
  "Gm17494",
  "Gm17501",
  "Gm17509",
  "Gm17745",
  "Gm17749",
  "Gm17751",
  "Gm17753",
  "Gm17767",
  "Gm17783",
  "Gm19299",
  "Gm19325",
  "Gm19410",
  "Gm19510",
  "Gm1965",
  "Gm1968",
  "Gm19705",
  "Gm1976",
  "Gm1992",
  "Gm19935",
  "Gm20036",
  "Gm20089",
  "Gm2018",
  "Gm20219",
  "Gm20342",
  "Gm2044",
  "Gm20443",
  "Gm20468",
  "Gm20485",
  "Gm20492",
  "Gm20508",
  "Gm20556",
  "Gm20559",
  "Gm2061",
  "Gm20611",
  "Gm20619",
  "Gm20627",
  "Gm20634",
  "Gm20661",
  "Gm20703",
  "Gm20707",
  "Gm20732",
  "Gm20754",
  "Gm20755",
  "Gm20757",
  "Gm20758",
  "Gm20767",
  "Gm20914",
  "Gm20918",
  "Gm2093",
  "Gm20939",
  "Gm2109",
  "Gm2115",
  "Gm21292",
  "Gm21319",
  "Gm2155",
  "Gm2164",
  "Gm21719",
  "Gm21762",
  "Gm21781",
  "Gm21917",
  "Gm21958",
  "Gm21976",
  "Gm21987",
  "Gm2233",
  "Gm2245",
  "Gm2270",
  "Gm2310",
  "Gm2350",
  "Gm2366",
  "Gm2379",
  "Gm2381",
  "Gm2415",
  "Gm2447",
  "Gm2453",
  "Gm2464",
  "Gm2479",
  "Gm2511",
  "Gm2516",
  "Gm2582",
  "Gm2629",
  "Gm26513",
  "Gm26562",
  "Gm26569",
  "Gm26576",
  "Gm266",
  "Gm26604",
  "Gm26651",
  "Gm26652",
  "Gm26654",
  "Gm26671",
  "Gm26682",
  "Gm2670",
  "Gm26708",
  "Gm26737",
  "Gm2675",
  "Gm26760",
  "Gm26783",
  "Gm26795",
  "Gm26806",
  "Gm2682",
  "Gm26850",
  "Gm26879",
  "Gm26936",
  "Gm2694",
  "Gm26967",
  "Gm26979",
  "Gm27003",
  "Gm27008",
  "Gm27011",
  "Gm27017",
  "Gm27022",
  "Gm27042",
  "Gm27048",
  "Gm27194",
  "Gm27197",
  "Gm2721",
  "Gm27230",
  "Gm27253",
  "Gm27255",
  "Gm2788",
  "Gm28050",
  "Gm28055",
  "Gm281",
  "Gm2814",
  "Gm2822",
  "Gm28229",
  "Gm28237",
  "Gm2824",
  "Gm28269",
  "Gm28271",
  "Gm28286",
  "Gm28386",
  "Gm28410",
  "Gm28415",
  "Gm28502",
  "Gm28557",
  "Gm28653",
  "Gm28694",
  "Gm28721",
  "Gm28723",
  "Gm28750",
  "Gm28856",
  "Gm28876",
  "Gm28905",
  "Gm28919",
  "Gm28981",
  "Gm29016",
  "Gm29018",
  "Gm29055",
  "Gm29106",
  "Gm29113",
  "Gm29152",
  "Gm29187",
  "Gm29243",
  "Gm29254",
  "Gm29323",
  "Gm29340",
  "Gm29346",
  "Gm29455",
  "Gm29456",
  "Gm29458",
  "Gm29482",
  "Gm29483",
  "Gm29538",
  "Gm29554",
  "Gm29630",
  "Gm29666",
  "Gm29674",
  "Gm29676",
  "Gm29677",
  "Gm29683",
  "Gm29684",
  "Gm29686",
  "Gm29707",
  "Gm29724",
  "Gm29735",
  "Gm29776",
  "Gm29797",
  "Gm29825",
  "Gm2990",
  "Gm29992",
  "Gm29999",
  "Gm2a",
  "Gm30015",
  "Gm30074",
  "Gm30085",
  "Gm30211",
  "Gm30228",
  "Gm30302",
  "Gm30363",
  "Gm3045",
  "Gm30454",
  "Gm30498",
  "Gm30524",
  "Gm30551",
  "Gm30556",
  "Gm30613",
  "Gm30641",
  "Gm30784",
  "Gm30984",
  "Gm31013",
  "Gm31048",
  "Gm31087",
  "Gm31105",
  "Gm31135",
  "Gm31363",
  "Gm31373",
  "Gm31503",
  "Gm31562",
  "Gm31698",
  "Gm31718",
  "Gm31727",
  "Gm31805",
  "Gm31854",
  "Gm3187",
  "Gm31908",
  "Gm31946",
  "Gm31992",
  "Gm32031",
  "Gm3211",
  "Gm32126",
  "Gm32219",
  "Gm32235",
  "Gm3227",
  "Gm32296",
  "Gm3234",
  "Gm3235",
  "Gm32357",
  "Gm32364",
  "Gm32391",
  "Gm32442",
  "Gm32461",
  "Gm32468",
  "Gm32479",
  "Gm3252",
  "Gm32531",
  "Gm32548",
  "Gm32585",
  "Gm32591",
  "Gm32618",
  "Gm32742",
  "Gm32743",
  "Gm3279",
  "Gm32828",
  "Gm3285",
  "Gm32856",
  "Gm3287",
  "Gm3289",
  "Gm32916",
  "Gm33045",
  "Gm33055",
  "Gm33103",
  "Gm33125",
  "Gm33206",
  "Gm33222",
  "Gm33251",
  "Gm3327",
  "Gm33299",
  "Gm3331",
  "Gm33337",
  "Gm3336",
  "Gm33424",
  "Gm3364",
  "Gm33651",
  "Gm33782",
  "Gm33819",
  "Gm33948",
  "Gm33969",
  "Gm33994",
  "Gm34006",
  "Gm34059",
  "Gm34066",
  "Gm34093",
  "Gm3428",
  "Gm34307",
  "Gm34342",
  "Gm34388",
  "Gm34466",
  "Gm3455",
  "Gm34590",
  "Gm34653",
  "Gm34684",
  "Gm34808",
  "Gm34983",
  "Gm35028",
  "Gm35040",
  "Gm3509",
  "Gm3510",
  "Gm3513",
  "Gm35189",
  "Gm3519",
  "Gm35208",
  "Gm35229",
  "Gm35239",
  "Gm35256",
  "Gm35298",
  "Gm35315",
  "Gm35333",
  "Gm35339",
  "Gm35404",
  "Gm35438",
  "Gm35453",
  "Gm35550",
  "Gm35554",
  "Gm35625",
  "Gm35665",
  "Gm35696",
  "Gm3573",
  "Gm35772",
  "Gm35835",
  "Gm3587",
  "Gm36028",
  "Gm36033",
  "Gm36037",
  "Gm3604",
  "Gm36107",
  "Gm36199",
  "Gm36200",
  "Gm36210",
  "Gm36235",
  "Gm36236",
  "Gm36283",
  "Gm36315",
  "Gm36377",
  "Gm364",
  "Gm36426",
  "Gm3646",
  "Gm36535",
  "Gm36543",
  "Gm36560",
  "Gm36696",
  "Gm36723",
  "Gm36738",
  "Gm36756",
  "Gm36860",
  "Gm36862",
  "Gm36864",
  "Gm36913",
  "Gm3693",
  "Gm37146",
  "Gm3716",
  "Gm37170",
  "Gm37267",
  "Gm3734",
  "Gm37494",
  "Gm37573",
  "Gm3764",
  "Gm37640",
  "Gm3772",
  "Gm37855",
  "Gm37896",
  "Gm38100",
  "Gm38102",
  "Gm38132",
  "Gm38186",
  "Gm382",
  "Gm38246",
  "Gm38293",
  "Gm3830",
  "Gm38324",
  "Gm38398",
  "Gm38403",
  "Gm38405",
  "Gm3848",
  "Gm3854",
  "Gm38563",
  "Gm3880",
  "Gm38944",
  "Gm3896",
  "Gm39090",
  "Gm39164",
  "Gm3924",
  "Gm39317",
  "Gm39348",
  "Gm39436",
  "Gm39464",
  "Gm3970",
  "Gm3985",
  "Gm40038",
  "Gm40040",
  "Gm4013",
  "Gm4022",
  "Gm40271",
  "Gm40304",
  "Gm40331",
  "Gm4035",
  "Gm4065",
  "Gm40770",
  "Gm40787",
  "Gm40828",
  "Gm40841",
  "Gm40847",
  "Gm4107",
  "Gm4117",
  "Gm4128",
  "Gm4131",
  "Gm41600",
  "Gm41638",
  "Gm41639",
  "Gm4189",
  "Gm4208",
  "Gm4211",
  "Gm4219",
  "Gm42205",
  "Gm4221",
  "Gm4224",
  "Gm4230",
  "Gm42372",
  "Gm42375",
  "Gm4241",
  "Gm42425",
  "Gm42450",
  "Gm4251",
  "Gm4258",
  "Gm42609",
  "Gm42610",
  "Gm42688",
  "Gm42707",
  "Gm42722",
  "Gm42723",
  "Gm42743",
  "Gm42745",
  "Gm4278",
  "Gm4279",
  "Gm42800",
  "Gm42812",
  "Gm4285",
  "Gm42884",
  "Gm42916",
  "Gm42921",
  "Gm42931",
  "Gm42945",
  "Gm43050",
  "Gm43077",
  "Gm43086",
  "Gm43091",
  "Gm43100",
  "Gm43154",
  "Gm4316",
  "Gm4319",
  "Gm43262",
  "Gm43263",
  "Gm43293",
  "Gm43305",
  "Gm43517",
  "Gm43538",
  "Gm4356",
  "Gm43573",
  "Gm43598",
  "Gm43635",
  "Gm43660",
  "Gm43661",
  "Gm43672",
  "Gm43688",
  "Gm43803",
  "Gm43851",
  "Gm43950",
  "Gm44",
  "Gm44006",
  "Gm44028",
  "Gm44081",
  "Gm4409",
  "Gm44113",
  "Gm44166",
  "Gm4419",
  "Gm44196",
  "Gm44204",
  "Gm4425",
  "Gm44257",
  "Gm44366",
  "Gm44410",
  "Gm4450",
  "Gm44621",
  "Gm4473",
  "Gm44777",
  "Gm44864",
  "Gm4489",
  "Gm44899",
  "Gm4491",
  "Gm44997",
  "Gm45014",
  "Gm45052",
  "Gm45079",
  "Gm45083",
  "Gm45151",
  "Gm45155",
  "Gm45188",
  "Gm45202",
  "Gm45231",
  "Gm4524",
  "Gm45337",
  "Gm45338",
  "Gm45408",
  "Gm4544",
  "Gm45447",
  "Gm45494",
  "Gm4553",
  "Gm45627",
  "Gm4566",
  "Gm45666",
  "Gm45716",
  "Gm4577",
  "Gm45844",
  "Gm45866",
  "Gm45869",
  "Gm45901",
  "Gm45941",
  "Gm4598",
  "Gm4610",
  "Gm4631",
  "Gm4632",
  "Gm46336",
  "Gm46376",
  "Gm4640",
  "Gm46447",
  "Gm4651",
  "Gm46606",
  "Gm4665",
  "Gm4668",
  "Gm4673",
  "Gm4675",
  "Gm4681",
  "Gm47003",
  "Gm47015",
  "Gm47047",
  "Gm47076",
  "Gm4710",
  "Gm47112",
  "Gm47157",
  "Gm4719",
  "Gm47204",
  "Gm47235",
  "Gm47289",
  "Gm47336",
  "Gm47359",
  "Gm4744",
  "Gm47461",
  "Gm47469",
  "Gm47480",
  "Gm47547",
  "Gm4755",
  "Gm47552",
  "Gm47633",
  "Gm47671",
  "Gm47705",
  "Gm47715",
  "Gm47774",
  "Gm4779",
  "Gm47792",
  "Gm47818",
  "Gm4787",
  "Gm4788",
  "Gm47902",
  "Gm4791",
  "Gm4792",
  "Gm47921",
  "Gm4793",
  "Gm47966",
  "Gm48038",
  "Gm48053",
  "Gm48075",
  "Gm48081",
  "Gm48089",
  "Gm4814",
  "Gm48294",
  "Gm48311",
  "Gm48395",
  "Gm48399",
  "Gm4841",
  "Gm4847",
  "Gm48505",
  "Gm48538",
  "Gm48551",
  "Gm4861",
  "Gm48610",
  "Gm48622",
  "Gm48623",
  "Gm48671",
  "Gm4869",
  "Gm4871",
  "Gm4872",
  "Gm48727",
  "Gm48742",
  "Gm4876",
  "Gm48812",
  "Gm48866",
  "Gm48879",
  "Gm4890",
  "Gm48925",
  "Gm4894",
  "Gm48957",
  "Gm48960",
  "Gm48969",
  "Gm48970",
  "Gm49066",
  "Gm4907",
  "Gm49086",
  "Gm49097",
  "Gm49173",
  "Gm4922",
  "Gm49224",
  "Gm49237",
  "Gm4924",
  "Gm49240",
  "Gm49249",
  "Gm49336",
  "Gm49390",
  "Gm49448",
  "Gm49451",
  "Gm49452",
  "Gm49454",
  "Gm49472",
  "Gm4951",
  "Gm4952",
  "Gm49539",
  "Gm49575",
  "Gm49580",
  "Gm49632",
  "Gm4969",
  "Gm49698",
  "Gm49708",
  "Gm49711",
  "Gm49723",
  "Gm49793",
  "Gm49797",
  "Gm49808",
  "Gm4981",
  "Gm4985",
  "Gm49857",
  "Gm49872",
  "Gm49891",
  "Gm49932",
  "Gm49975",
  "Gm5",
  "Gm50019",
  "Gm50100",
  "Gm50114",
  "Gm50145",
  "Gm50218",
  "Gm50227",
  "Gm50304",
  "Gm50335",
  "Gm50350",
  "Gm50475",
  "Gm50477",
  "Gm5065",
  "Gm5082",
  "Gm5083",
  "Gm5084",
  "Gm5086",
  "Gm5087",
  "Gm5089",
  "Gm5091",
  "Gm5092",
  "Gm5094",
  "Gm5095",
  "Gm5099",
  "Gm5103",
  "Gm5105",
  "Gm5106",
  "Gm5108",
  "Gm5111",
  "Gm5114",
  "Gm5118",
  "Gm5122",
  "Gm5127",
  "Gm5128",
  "Gm5129",
  "Gm5134",
  "Gm5136",
  "Gm5141",
  "Gm5142",
  "Gm5144",
  "Gm5148",
  "Gm5150",
  "Gm5155",
  "Gm5244",
  "Gm525",
  "Gm527",
  "Gm5294",
  "Gm53",
  "Gm534",
  "Gm536",
  "Gm5414",
  "Gm5420",
  "Gm5427",
  "Gm5431",
  "Gm5432",
  "Gm5441",
  "Gm5444",
  "Gm5458",
  "Gm5460",
  "Gm5463",
  "Gm5464",
  "Gm5468",
  "Gm5475",
  "Gm5478",
  "Gm5485",
  "Gm5493",
  "Gm550",
  "Gm5524",
  "Gm553",
  "Gm5532",
  "Gm5533",
  "Gm5535",
  "Gm5544",
  "Gm5546",
  "Gm5547",
  "Gm5565",
  "Gm5570",
  "Gm5577",
  "Gm5591",
  "Gm5592",
  "Gm5600",
  "Gm5602",
  "Gm5608",
  "Gm5617",
  "Gm5627",
  "Gm567",
  "Gm5678",
  "Gm568",
  "Gm572",
  "Gm5724",
  "Gm5737",
  "Gm5741",
  "Gm5751",
  "Gm5767",
  "Gm5784",
  "Gm5800",
  "Gm5820",
  "Gm5833",
  "Gm5849",
  "Gm5860",
  "Gm5878",
  "Gm5893",
  "Gm5901",
  "Gm5907",
  "Gm5914",
  "Gm5916",
  "Gm5922",
  "Gm5938",
  "Gm5965",
  "Gm597",
  "Gm6034",
  "Gm6040",
  "Gm609",
  "Gm6093",
  "Gm6116",
  "Gm6117",
  "Gm6133",
  "Gm614",
  "Gm6145",
  "Gm6209",
  "Gm6213",
  "Gm6217",
  "Gm6225",
  "Gm6249",
  "Gm6260",
  "Gm6268",
  "Gm6270",
  "Gm6277",
  "Gm6288",
  "Gm6313",
  "Gm6329",
  "Gm6358",
  "Gm6377",
  "Gm6410",
  "Gm6416",
  "Gm6471",
  "Gm648",
  "Gm6525",
  "Gm6559",
  "Gm6566",
  "Gm6567",
  "Gm6569",
  "Gm6583",
  "Gm6588",
  "Gm6598",
  "Gm6602",
  "Gm6614",
  "Gm6639",
  "Gm671",
  "Gm6712",
  "Gm6760",
  "Gm6878",
  "Gm6938",
  "Gm6961",
  "Gm6980",
  "Gm6993",
  "Gm6999",
  "Gm7008",
  "Gm7072",
  "Gm7073",
  "Gm7097",
  "Gm7102",
  "Gm7160",
  "Gm7247",
  "Gm7271",
  "Gm7276",
  "Gm7298",
  "Gm732",
  "Gm7457",
  "Gm7467",
  "Gm7480",
  "Gm7489",
  "Gm7568",
  "Gm7579",
  "Gm7580",
  "Gm7596",
  "Gm7607",
  "Gm7616",
  "Gm7628",
  "Gm7644",
  "Gm765",
  "Gm7694",
  "Gm7706",
  "Gm7714",
  "Gm773",
  "Gm7854",
  "Gm7932",
  "Gm7967",
  "Gm8005",
  "Gm8013",
  "Gm805",
  "Gm8066",
  "Gm807",
  "Gm8075",
  "Gm8098",
  "Gm8104",
  "Gm8126",
  "Gm815",
  "Gm8212",
  "Gm8220",
  "Gm8247",
  "Gm8251",
  "Gm826",
  "Gm8300",
  "Gm831",
  "Gm833",
  "Gm8334",
  "Gm8369",
  "Gm8378",
  "Gm839",
  "Gm8439",
  "Gm8579",
  "Gm8633",
  "Gm867",
  "Gm8817",
  "Gm884",
  "Gm8883",
  "Gm8909",
  "Gm9",
  "Gm9008",
  "Gm9017",
  "Gm9112",
  "Gm9195",
  "Gm9376",
  "Gm94",
  "Gm9465",
  "Gm9530",
  "Gm9573",
  "Gm960",
  "Gm973",
  "Gm9733",
  "Gm9736",
  "Gm9747",
  "Gm9748",
  "Gm9750",
  "Gm9752",
  "Gm9754",
  "Gm9767",
  "Gm9774",
  "Gm9776",
  "Gm9796",
  "Gm9801",
  "Gm9803",
  "Gm9821",
  "Gm9828",
  "Gm9833",
  "Gm9856",
  "Gm9857",
  "Gm9866",
  "Gm9871",
  "Gm9873",
  "Gm9885",
  "Gm9887",
  "Gm9888",
  "Gm9889",
  "Gm9895",
  "Gm9898",
  "Gm9902",
  "Gm9903",
  "Gm9908",
  "Gm9913",
  "Gm9915",
  "Gm9916",
  "Gm9917",
  "Gm9918",
  "Gm9920",
  "Gm9921",
  "Gm9922",
  "Gm9925",
  "Gm9926",
  "Gm9932",
  "Gm9934",
  "Gm9936",
  "Gm9937",
  "Gm9944",
  "Gm9946",
  "Gm9947",
  "Gm9949",
  "Gm9951",
  "Gm9955",
  "Gm9958",
  "Gm9961",
  "Gm9962",
  "Gm9967",

  "Gm9968",
  "Gm9970",
  "Gm9979",
  "Gm9984",
  "Gm9989",
  "Gm9991",
  "Gm9993",
  "Gm9999",
  "Gmcl1",
  "Gmds",
  "Gmeb1",
  "Gmeb2",
  "Gmfb",
  "Gmfg",
  "Gmip",
  "Gml",
  "Gml2",
  "Gmnc",
  "Gmnn",
  "Gmppa",
  "Gmppb",
  "Gmpr",
  "Gmpr2",
  "Gmps",
  "Gna11",
  "Gna12",
  "Gna13",
  "Gna14",
  "Gna15",
  "Gnai1",
  "Gnai2",
  "Gnai3",
  "Gnal",
  "Gnao1",
  "Gnaq",
  "Gnas",
  "Gnasas1",
  "Gnat1",
  "Gnat2",
  "Gnat3",
  "Gnaz",
  "Gnb1",
  "Gnb1l",
  "Gnb3",
  "Gnb4",
  "Gnb5",
  "Gne",
  "Gng11",
  "Gng12",
  "Gng13",
  "Gng2",
  "Gng3",
  "Gng4",
  "Gng7",
  "Gng8",
  "Gngt1",
  "Gngt2",
  "Gnl1",
  "Gnl2",
  "Gnl3",
  "Gnl3l",
  "Gnmt",
  "Gnpat",
  "Gnpda1",
  "Gnpda2",
  "Gnpnat1",
  "Gnptab",
  "Gnptg",
  "Gnrh1",
  "Gnrhr",
  "Gns",
  "Golga1",
  "Golga2",
  "Golga3",
  "Golga4",
  "Golga5",
  "Golga7",
  "Golga7b",
  "Golgb1",
  "Golim4",
  "Golm1",
  "Golph3",
  "Golph3l",
  "Golt1a",
  "Golt1b",
  "Gon7",
  "Gopc",
  "Gorab",
  "Gorasp1",
  "Gorasp2",
  "Gosr1",
  "Gosr2",
  "Got1",
  "Got1l1",
  "Got2",
  "Gp1ba",
  "Gp2",
  "Gp5",
  "Gp6",
  "Gp9",
  "Gpa33",
  "Gpaa1",
  "Gpalpp1",
  "Gpam",
  "Gpank1",
  "Gpat2",
  "Gpat3",
  "Gpat4",
  "Gpatch1",
  "Gpatch11",
  "Gpatch2",
  "Gpatch2l",
  "Gpatch3",
  "Gpatch4",
  "Gpatch8",
  "Gpbar1",
  "Gpbp1",
  "Gpbp1l1",
  "Gpc1",
  "Gpc3",
  "Gpc4",
  "Gpc5",
  "Gpc6",
  "Gpcpd1",
  "Gpd1",
  "Gpd1l",
  "Gpd2",
  "Gper1",
  "Gpha2",
  "Gphb5",
  "Gphn",
  "Gpi1",
  "Gpihbp1",
  "Gpkow",
  "Gpld1",
  "Gpm6a",
  "Gpm6b",
  "Gpn1",
  "Gpn2",
  "Gpn3",
  "Gpnmb",
  "Gpr1",
  "Gpr101",
  "Gpr107",
  "Gpr108",
  "Gpr119",
  "Gpr12",
  "Gpr132",
  "Gpr135",
  "Gpr137",
  "Gpr137b",
  "Gpr137c",
  "Gpr139",
  "Gpr141",
  "Gpr141b",
  "Gpr142",
  "Gpr143",
  "Gpr146",
  "Gpr149",
  "Gpr15",
  "Gpr150",
  "Gpr151",
  "Gpr152",
  "Gpr153",
  "Gpr155",
  "Gpr156",
  "Gpr158",
  "Gpr160",
  "Gpr161",
  "Gpr162",
  "Gpr165",
  "Gpr17",
  "Gpr171",
  "Gpr173",
  "Gpr174",
  "Gpr176",
  "Gpr179",
  "Gpr18",
  "Gpr182",
  "Gpr183",
  "Gpr19",
  "Gpr20",
  "Gpr21",
  "Gpr22",
  "Gpr25",
  "Gpr26",
  "Gpr27",
  "Gpr3",
  "Gpr33",
  "Gpr34",
  "Gpr35",
  "Gpr37",
  "Gpr37l1",
  "Gpr39",
  "Gpr4",
  "Gpr45",
  "Gpr52",
  "Gpr55",
  "Gpr6",
  "Gpr61",
  "Gpr63",
  "Gpr65",
  "Gpr68",
  "Gpr75",
  "Gpr82",
  "Gpr83",
  "Gpr84",
  "Gpr85",
  "Gpr87",
  "Gpr88",
  "Gpr89",
  "Gprasp1",
  "Gprasp2",
  "Gprc5b",
  "Gprc5c",
  "Gprc5d",
  "Gprc6a",
  "Gprin1",
  "Gprin2",
  "Gprin3",
  "Gps1",
  "Gps2",
  "Gpsm1",
  "Gpsm2",
  "Gpsm3",
  "Gpt",
  "Gpt2",
  "Gpx1",
  "Gpx2",
  "Gpx3",
  "Gpx5",
  "Gpx6",
  "Gpx7",
  "Gpx8",
  "Gramd1a",
  "Gramd1b",
  "Gramd2",
  "Gramd3",
  "Gramd4",
  "Grap",
  "Grap2",
  "Grasp",
  "Grb10",
  "Grb14",
  "Grb2",
  "Grb7",
  "Grcc10",
  "Greb1",
  "Greb1l",
  "Grem1",
  "Grem2",
  "Grhl1",
  "Grhl2",
  "Grhl3",
  "Grhpr",
  "Gria1",
  "Gria2",
  "Gria3",
  "Gria4",
  "Grid1",
  "Grid2",
  "Grid2ip",
  "Grifin",
  "Grik1",
  "Grik2",
  "Grik3",
  "Grik4",
  "Grik5",
  "Grin1",
  "Grin2a",
  "Grin2b",
  "Grin2c",
  "Grin2d",
  "Grin3a",
  "Grin3b",
  "Grina",
  "Grip1",
  "Grip1os1",
  "Grip2",
  "Grk1",
  "Grk2",
  "Grk3",
  "Grk4",
  "Grk5",
  "Grk6",
  "Grm1",
  "Grm2",
  "Grm4",
  "Grm5",
  "Grm6",
  "Grm7",
  "Grm8",
  "Grn",
  "Grp",
  "Grpel1",
  "Grpel2",
  "Grpr",
  "Grrp1",
  "Grsf1",
  "Grtp1",
  "Grwd1",
  "Grxcr1",
  "Grxcr2",
  "Gsap",
  "Gsc",
  "Gsc2",
  "Gsdma",
  "Gsdma2",
  "Gsdma3",
  "Gsdmc3",
  "Gsdmc4",
  "Gsdmd",
  "Gsdme",
  "Gse1",
  "Gsg1",
  "Gsg1l",
  "Gsg1l2",
  "Gsk3a",
  "Gsk3b",
  "Gskip",
  "Gsn",
  "Gspt1",
  "Gspt2",
  "Gsr",
  "Gss",
  "Gsta3",
  "Gsta4",
  "Gstcd",
  "Gstk1",
  "Gstm1",
  "Gstm2",
  "Gstm3",
  "Gstm4",
  "Gstm5",
  "Gstm6",
  "Gstm7",
  "Gsto1",
  "Gsto2",
  "Gstp1",
  "Gstp3",
  "Gstt1",
  "Gstt2",
  "Gstt3",
  "Gstt4",
  "Gstz1",
  "Gsx1",
  "Gsx2",
  "Gt(ROSA)26Sor",
  "Gtdc1",
  "Gtf2a1",
  "Gtf2a1l",
  "Gtf2a2",
  "Gtf2b",
  "Gtf2e1",
  "Gtf2e2",
  "Gtf2f1",
  "Gtf2f2",
  "Gtf2h1",
  "Gtf2h2",
  "Gtf2h3",
  "Gtf2h4",
  "Gtf2h5",
  "Gtf2i",
  "Gtf2ird1",
  "Gtf2ird2",
  "Gtf3a",
  "Gtf3c1",
  "Gtf3c2",
  "Gtf3c3",
  "Gtf3c4",
  "Gtf3c5",
  "Gtf3c6",
  "Gtpbp1",
  "Gtpbp10",
  "Gtpbp2",
  "Gtpbp3",
  "Gtpbp4",
  "Gtpbp6",
  "Gtpbp8",
  "Gtse1",
  "Gtsf1",
  "Gtsf1l",
  "Gtsf2",
  "Guca1b",
  "Guca2a",
  "Guca2b",
  "Gucd1",
  "Gucy1a1",
  "Gucy1a2",
  "Gucy1b1",
  "Gucy1b2",
  "Gucy2c",
  "Gucy2d",
  "Gucy2e",
  "Gucy2f",
  "Gucy2g",
  "Guf1",
  "Guk1",
  "Gulo",
  "Gulp1",
  "Gusb",
  "Gxylt1",
  "Gxylt2",
  "Gyg",
  "Gykl1",
  "Gypa",
  "Gypc",
  "Gys1",
  "Gys2",
  "Gzf1",
  "Gzma",
  "Gzmb",
  "Gzmc",
  "Gzmd",
  "Gzme",
  "Gzmf",
  "Gzmg",
  "Gzmk",
  "Gzmm",
  "Gzmn",
  "H13",
  "H19",
  "H1f0",
  "H1f1",
  "H1f10",
  "H1f2",
  "H1f3",
  "H1f4",
  "H1f5",
  "H1f6",
  "H1f7",
  "H1f8",
  "H2-Aa",
  "H2-Ab1",
  "H2-D1",
  "H2-DMa",
  "H2-DMb1",
  "H2-DMb2",
  "H2-Eb1",
  "H2-Eb2",
  "H2-K1",
  "H2-M1",
  "H2-M10.1",
  "H2-M10.2",
  "H2-M10.3",
  "H2-M10.4",
  "H2-M10.6",
  "H2-M11",
  "H2-M2",
  "H2-M3",
  "H2-M5",
  "H2-M9",
  "H2-Oa",
  "H2-Ob",
  "H2-Q1",
  "H2-Q10",
  "H2-Q2",
  "H2-Q4",
  "H2-Q6",
  "H2-T22",
  "H2-T23",
  "H2-T24",
  "H2-T3",
  "H2ab2",
  "H2ac1",
  "H2ac10",
  "H2ac11",
  "H2ac12",
  "H2ac15",
  "H2ac20",
  "H2ac21",
  "H2ac4",
  "H2ac6",
  "H2ac8",
  "H2aj",
  "H2al1j",
  "H2al1n",
  "H2al2a",
  "H2al3",
  "H2ap",
  "H2aw",
  "H2ax",
  "H2az1",
  "H2az2",
  "H2bc1",
  "H2bc21",
  "H2bc3",
  "H2bc4",
  "H2bc6",
  "H2bc8",
  "H2bfm",
  "H2bl1",
  "H2bu2",
  "H3c1",
  "H3c3",
  "H3c7",
  "H3c8",
  "H3f3a",
  "H3f3aos",
  "H3f3b",
  "H4c11",
  "H4c14",
  "H4c4",
  "H4c8",
  "H4c9",
  "H4f16",
  "H60b",
  "H60c",
  "H6pd",
  "Haao",
  "Habp2",
  "Habp4",
  "Hacd1",
  "Hacd2",
  "Hacd3",
  "Hacd4",
  "Hace1",
  "Hacl1",
  "Hadh",
  "Hadha",
  "Hadhb",
  "Hagh",
  "Haghl",
  "Haglr",
  "Hal",
  "Hamp",
  "Hamp2",
  "Hand1",
  "Hand2",
  "Hand2os1",
  "Hao1",
  "Hao2",
  "Hap1",
  "Hapln2",
  "Hapln3",
  "Hapln4",
  "Harbi1",
  "Hars",
  "Hars2",
  "Has1",
  "Has2",
  "Has2os",
  "Has3",
  "Haspin",
  "Hat1",
  "Haus1",
  "Haus2",
  "Haus3",
  "Haus4",
  "Haus5",
  "Haus6",
  "Haus7",
  "Haus8",
  "Havcr1",
  "Havcr2",
  "Hax1",
  "Hba-x",
  "Hbb-bh1",
  "Hbb-bh2",
  "Hbb-bs",
  "Hbb-bt",
  "Hbb-y",
  "Hbegf",
  "Hbp1",
  "Hbq1a",
  "Hbq1b",
  "Hbs1l",
  "Hc",
  "Hcar1",
  "Hcar2",
  "Hccs",
  "Hcfc1",
  "Hcfc1r1",
  "Hcfc2",
  "Hck",
  "Hcls1",
  "Hcn1",
  "Hcn2",
  "Hcn3",
  "Hcn4",
  "Hcrt",
  "Hcrtr1",
  "Hcrtr2",
  "Hcst",
  "Hdac1",
  "Hdac10",
  "Hdac11",
  "Hdac2",
  "Hdac3",
  "Hdac4",
  "Hdac5",
  "Hdac6",
  "Hdac7",
  "Hdac8",
  "Hdac9",
  "Hdc",
  "Hddc2",
  "Hddc3",
  "Hdgf",
  "Hdgfl1",
  "Hdgfl2",
  "Hdgfl3",
  "Hdhd2",
  "Hdhd3",
  "Hdhd5",
  "Hdlbp",
  "Hdx",
  "Heatr1",
  "Heatr3",
  "Heatr5a",
  "Heatr5b",
  "Heatr6",
  "Heatr9",
  "Hebp1",
  "Hebp2",
  "Heca",
  "Hectd1",
  "Hectd2",
  "Hectd2os",
  "Hectd3",
  "Hectd4",
  "Hecw2",
  "Heg1",
  "Helb",
  "Hells",
  "Helq",
  "Helt",
  "Helz",
  "Helz2",
  "Hemk1",
  "Henmt1",
  "Hepacam",
  "Hepacam2",
  "Heph",
  "Hephl1",
  "Herc1",
  "Herc2",
  "Herc3",
  "Herc4",
  "Herc6",
  "Herpud1",
  "Herpud2",
  "Hes1",
  "Hes2",
  "Hes3",
  "Hes5",
  "Hes6",
  "Hes7",
  "Hesx1",
  "Hexa",
  "Hexb",
  "Hexdc",
  "Hexim1",
  "Hexim2",
  "Hey1",
  "Hey2",
  "Heyl",
  "Hfe",
  "Hfm1",
  "Hgd",
  "Hgf",
  "Hgfac",
  "Hgh1",
  "Hgs",
  "Hgsnat",
  "Hhat",
  "Hhatl",
  "Hhex",
  "Hhip",
  "Hhipl1",
  "Hhipl2",
  "Hhla1",
  "Hibadh",
  "Hibch",
  "Hic1",
  "Hic2",
  "Hid1",
  "Hif1a",
  "Hif1an",
  "Hif3a",
  "Higd1a",
  "Higd1b",
  "Higd2a",
  "Hikeshi",
  "Hilpda",
  "Hils1",
  "Hinfp",
  "Hint1",
  "Hint2",
  "Hint3",
  "Hip1",
  "Hip1r",
  "Hipk1",
  "Hipk2",
  "Hipk3",
  "Hipk4",
  "Hirip3",
  "Hist1h2bp",
  "Hist1h2br",
  "Hist1h4n",
  "Hivep1",
  "Hivep2",
  "Hivep3",
  "Hjurp",
  "Hjv",
  "Hk1",
  "Hk1os",
  "Hk2",
  "Hk3",
  "Hkdc1",
  "Hlcs",
  "Hlf",
  "Hltf",
  "Hlx",
  "Hmbox1",
  "Hmbs",
  "Hmces",
  "Hmcn1",
  "Hmcn2",
  "Hmg20a",
  "Hmg20b",
  "Hmga1",
  "Hmga2",
  "Hmgb1",
  "Hmgb2",
  "Hmgb3",
  "Hmgb4",
  "Hmgb4os",
  "Hmgcl",
  "Hmgcll1",
  "Hmgcr",
  "Hmgcs1",
  "Hmgcs2",
  "Hmgn1",
  "Hmgn3",
  "Hmgn5",
  "Hmgxb3",
  "Hmgxb4",
  "Hmox1",
  "Hmox2",
  "Hmx1",
  "Hmx2",
  "Hmx3",
  "Hnf1a",
  "Hnf1b",
  "Hnf4a",
  "Hnf4aos",
  "Hnf4g",
  "Hnmt",
  "Hnrnpa0",
  "Hnrnpa1",
  "Hnrnpa2b1",
  "Hnrnpa3",
  "Hnrnpab",
  "Hnrnpc",
  "Hnrnpd",
  "Hnrnpdl",
  "Hnrnpf",
  "Hnrnph1",
  "Hnrnph2",
  "Hnrnph3",
  "Hnrnpk",
  "Hnrnpl",
  "Hnrnpll",
  "Hnrnpm",
  "Hnrnpr",
  "Hnrnpu",
  "Hnrnpul1",
  "Hnrnpul2",
  "Hoga1",
  "Homer1",
  "Homer2",
  "Homer3",
  "Homez",
  "Hook1",
  "Hook2",
  "Hook3",
  "Hopx",
  "Hormad1",
  "Hormad2",
  "Hotair",
  "Hottip",
  "Hoxa1",
  "Hoxa10",
  "Hoxa11",
  "Hoxa11os",
  "Hoxa13",
  "Hoxa2",
  "Hoxa3",
  "Hoxa4",
  "Hoxa5",
  "Hoxa6",
  "Hoxa7",
  "Hoxa9",
  "Hoxaas2",
  "Hoxb1",
  "Hoxb13",
  "Hoxb2",
  "Hoxb3",
  "Hoxb4",
  "Hoxb5",
  "Hoxb5os",
  "Hoxb6",
  "Hoxb7",
  "Hoxb9",
  "Hoxc10",
  "Hoxc11",
  "Hoxc12",
  "Hoxc13",
  "Hoxc4",
  "Hoxc5",
  "Hoxc6",
  "Hoxc8",
  "Hoxc9",
  "Hoxd1",
  "Hoxd10",
  "Hoxd11",
  "Hoxd12",
  "Hoxd13",
  "Hoxd3os1",
  "Hoxd4",
  "Hoxd8",
  "Hoxd9",
  "Hp",
  "Hp1bp3",
  "Hpca",
  "Hpcal1",
  "Hpcal4",
  "Hpd",
  "Hpdl",
  "Hpf1",
  "Hpgd",
  "Hpgds",
  "Hpn",
  "Hprt",
  "Hps1",
  "Hps3",
  "Hps4",
  "Hps5",
  "Hps6",
  "Hpse",
  "Hpse2",
  "Hpx",
  "Hr",
  "Hras",
  "Hrc",
  "Hrct1",
  "Hrg",
  "Hrh1",
  "Hrh2",
  "Hrh3",
  "Hrh4",
  "Hrk",
  "Hrnr",
  "Hrob",
  "Hs1bp3",
  "Hs2st1",
  "Hs3st1",
  "Hs3st2",
  "Hs3st3a1",
  "Hs3st4",
  "Hs3st5",
  "Hs3st6",
  "Hs6st1",
  "Hs6st2",
  "Hs6st3",
  "Hsbp1",
  "Hsbp1l1",
  "Hscb",
  "Hsd11b1",
  "Hsd11b2",
  "Hsd17b1",
  "Hsd17b10",
  "Hsd17b11",
  "Hsd17b12",
  "Hsd17b13",
  "Hsd17b14",
  "Hsd17b2",
  "Hsd17b3",
  "Hsd17b4",
  "Hsd17b6",
  "Hsd17b7",
  "Hsd3b1",
  "Hsd3b2",
  "Hsd3b3",
  "Hsd3b5",
  "Hsd3b6",
  "Hsdl1",
  "Hsdl2",
  "Hsf1",
  "Hsf2",
  "Hsf2bp",
  "Hsf3",
  "Hsf4",
  "Hsf5",
  "Hsfy2",
  "Hsh2d",
  "Hsp90aa1",
  "Hsp90ab1",
  "Hsp90b1",
  "Hspa12a",
  "Hspa12b",
  "Hspa13",
  "Hspa14",
  "Hspa1l",
  "Hspa2",
  "Hspa4",
  "Hspa4l",
  "Hspa5",
  "Hspa8",
  "Hspa9",
  "Hspb1",
  "Hspb11",
  "Hspb2",
  "Hspb3",
  "Hspb6",
  "Hspb7",
  "Hspb8",
  "Hspb9",
  "Hspbap1",
  "Hspbp1",
  "Hspd1",
  "Hspe1-rs1",
  "Hspg2",
  "Hsph1",
  "Htatip2",
  "Htatsf1",
  "Htr1a",
  "Htr1b",
  "Htr1d",
  "Htr1f",
  "Htr2a",
  "Htr2b",
  "Htr2c",
  "Htr3a",
  "Htr3b",
  "Htr4",
  "Htr5a",
  "Htr5b",
  "Htr6",
  "Htr7",
  "Htra1",
  "Htra2",
  "Htra3",
  "Htra4",
  "Htt",
  "Hunk",
  "Hus1",
  "Hus1b",
  "Huwe1",
  "Hvcn1",
  "Hyal1",
  "Hyal2",
  "Hyal3",
  "Hyal4",
  "Hyal5",
  "Hyal6",
  "Hydin",
  "Hykk",
  "Hyls1",
  "Hyou1",
  "Hypk",
  "I0C0044D17Rik",
  "I830077J02Rik",
  "I830134H01Rik",
  "Iah1",
  "Iapp",
  "Iars",
  "Iars2",
  "Iba57",
  "Ibsp",
  "Ica1",
  "Ica1l",
  "Icam1",
  "Icam2",
  "Icam4",
  "Icam5",
  "Ice1",
  "Ice2",
  "Icmt",
  "Icos",
  "Icosl",
  "Id1",
  "Id2",
  "Id3",
  "Id4",
  "Ide",
  "Idh1",
  "Idh2",
  "Idh3a",
  "Idh3b",
  "Idh3g",
  "Idi1",
  "Idi2",
  "Idnk",
  "Ido1",
  "Ido2",
  "Ids",
  "Idua",
  "Ier2",
  "Ier3",
  "Ier3ip1",
  "Ier5",
  "Ier5l",
  "Iffo1",
  "Iffo2",
  "Ifi202b",
  "Ifi203",
  "Ifi204",
  "Ifi206",
  "Ifi207",
  "Ifi208",
  "Ifi209",
  "Ifi213",
  "Ifi214",
  "Ifi27",
  "Ifi27l2a",
  "Ifi27l2b",
  "Ifi30",
  "Ifi35",
  "Ifi44",
  "Ifi44l",
  "Ifi47",
  "Ifih1",
  "Ifit1",
  "Ifit1bl1",
  "Ifit1bl2",
  "Ifit2",
  "Ifit3",
  "Ifit3b",
  "Ifitm1",
  "Ifitm10",
  "Ifitm2",
  "Ifitm3",
  "Ifitm5",
  "Ifitm6",
  "Ifitm7",
  "Ifna12",
  "Ifna13",
  "Ifna14",
  "Ifna16",
  "Ifna2",
  "Ifna4",
  "Ifna5",
  "Ifnab",
  "Ifnar1",
  "Ifnb1",
  "Ifne",
  "Ifng",
  "Ifngas1",
  "Ifngr1",
  "Ifngr2",
  "Ifnk",
  "Ifnl3",
  "Ifnlr1",
  "Ifrd1",
  "Ifrd2",
  "Ift122",
  "Ift140",
  "Ift172",
  "Ift20",
  "Ift22",
  "Ift27",
  "Ift43",
  "Ift46",
  "Ift52",
  "Ift57",
  "Ift74",
  "Ift80",
  "Ift81",
  "Ift88",
  "Ift88os",
  "Igbp1",
  "Igbp1b",
  "Igdcc3",
  "Igdcc4",
  "Igf1",
  "Igf1os",
  "Igf1r",
  "Igf2bp1",
  "Igf2bp2",
  "Igf2bp3",
  "Igf2os",
  "Igf2r",
  "Igfals",
  "Igfbp1",
  "Igfbp2",
  "Igfbp3",
  "Igfbp4",
  "Igfbp5",
  "Igfbp6",
  "Igfbp7",
  "Igfbpl1",
  "Igfl3",
  "Igfn1",
  "Ighmbp2",
  "Igip",
  "Igll1",
  "Iglon5",
  "Igsf1",
  "Igsf10",
  "Igsf11",
  "Igsf21",
  "Igsf23",
  "Igsf3",
  "Igsf5",
  "Igsf6",
  "Igsf8",
  "Igsf9",
  "Igsf9b",
  "Igtp",
  "Ihh",
  "Iigp1",
  "Ik",
  "Ikbip",
  "Ikbkb",
  "Ikbke",
  "Ikbkg",
  "Ikzf1",
  "Ikzf2",
  "Ikzf4",
  "Ikzf5",
  "Il10",
  "Il10ra",
  "Il10rb",
  "Il11",
  "Il12b",
  "Il12rb1",
  "Il12rb2",
  "Il13",
  "Il13ra1",
  "Il13ra2",
  "Il15",
  "Il15ra",
  "Il16",
  "Il17a",
  "Il17b",
  "Il17c",
  "Il17d",
  "Il17f",
  "Il17ra",
  "Il17rb",
  "Il17rc",
  "Il17rd",
  "Il17re",
  "Il18",
  "Il18bp",
  "Il18r1",
  "Il18rap",
  "Il19",
  "Il1a",
  "Il1b",
  "Il1bos",
  "Il1f10",
  "Il1f5",
  "Il1f6",
  "Il1f8",
  "Il1f9",
  "Il1r1",
  "Il1r2",
  "Il1rap",
  "Il1rapl1",
  "Il1rapl2",
  "Il1rl1",
  "Il1rl2",
  "Il1rn",
  "Il2",
  "Il20",
  "Il20ra",
  "Il20rb",
  "Il21",
  "Il21r",
  "Il22ra1",
  "Il22ra2",
  "Il23a",
  "Il23r",
  "Il24",
  "Il25",
  "Il27",
  "Il27ra",
  "Il2ra",
  "Il2rb",
  "Il2rg",
  "Il31",
  "Il31ra",
  "Il33",
  "Il34",
  "Il3ra",
  "Il4",
  "Il4i1",
  "Il4ra",
  "Il5",
  "Il5ra",
  "Il6",
  "Il6ra",
  "Il6st",
  "Il7",
  "Il7r",
  "Il9",
  "Il9r",
  "Ildr1",
  "Ildr2",
  "Ilf2",
  "Ilf3",
  "Ilkap",
  "Ilrun",
  "Iltifb",
  "Ilvbl",
  "Immp1l",
  "Immp2l",
  "Imp3",
  "Imp4",
  "Impa1",
  "Impa2",
  "Impact",
  "Impad1",
  "Impdh1",
  "Impdh2",
  "Impg1",
  "Impg2",
  "Ina",
  "Inafm1",
  "Inafm2",
  "Inava",
  "Inca1",
  "Incenp",
  "Inf2",
  "Ing1",
  "Ing2",
  "Ing3",
  "Ing5",
  "Inha",
  "Inhba",
  "Inhbb",
  "Inhbc",
  "Inhbe",
  "Inip",
  "Inka1",
  "Inka2",
  "Inmt",
  "Ino80",
  "Ino80c",
  "Ino80d",
  "Ino80e",
  "Inpp1",
  "Inpp4a",
  "Inpp4b",
  "Inpp5a",
  "Inpp5b",
  "Inpp5d",
  "Inpp5e",
  "Inpp5f",
  "Inpp5j",
  "Inpp5k",
  "Inppl1",
  "Ins1",
  "Ins2",
  "Insc",
  "Insig1",
  "Insig2",
  "Insl5",
  "Insl6",
  "Insm1",
  "Insm2",
  "Insr",
  "Insrr",
  "Insyn1",
  "Insyn2a",
  "Insyn2b",
  "Ints1",
  "Ints10",
  "Ints11",
  "Ints12",
  "Ints13",
  "Ints14",
  "Ints2",
  "Ints3",
  "Ints4",
  "Ints5",
  "Ints6",
  "Ints6l",
  "Ints7",
  "Ints8",
  "Ints9",
  "Intu",
  "Invs",
  "Ip6k1",
  "Ip6k2",
  "Ip6k3",
  "Ipcef1",
  "Ipmk",
  "Ipo11",
  "Ipo13",
  "Ipo4",
  "Ipo5",
  "Ipo7",
  "Ipo8",
  "Ipp",
  "Ippk",
  "Iqank1",
  "Iqca",
  "Iqca1l",
  "Iqcb1",
  "Iqcc",
  "Iqcd",
  "Iqce",
  "Iqcf1",
  "Iqcf4",
  "Iqcf5",
  "Iqcf6",
  "Iqcg",
  "Iqch",
  "Iqcj",
  "Iqck",
  "Iqcm",
  "Iqcn",
  "Iqgap1",
  "Iqgap2",
  "Iqgap3",
  "Iqsec1",
  "Iqsec2",
  "Iqsec3",
  "Iqub",
  "Irak1",
  "Irak1bp1",
  "Irak2",
  "Irak3",
  "Irak4",
  "Ireb2",
  "Irf1",
  "Irf2",
  "Irf2bp1",
  "Irf2bp2",
  "Irf2bpl",
  "Irf3",
  "Irf4",
  "Irf5",
  "Irf6",
  "Irf7",
  "Irf8",
  "Irf9",
  "Irgc1",
  "Irgm1",
  "Irgm2",
  "Irgq",
  "Irs1",
  "Irs2",
  "Irs3",
  "Irs4",
  "Irx1",
  "Irx2",
  "Irx3",
  "Irx3os",
  "Irx4",
  "Irx5",
  "Irx6",
  "Isca1",
  "Isca2",
  "Iscu",
  "Isg15",
  "Isg20",
  "Isg20l2",
  "Isl1",
  "Isl2",
  "Islr",
  "Islr2",
  "Ism1",
  "Ism2",
  "Isoc1",
  "Isoc2a",
  "Isoc2b",
  "Ist1",
  "Isx",
  "Isy1",
  "Isyna1",
  "Itch",
  "Itfg1",
  "Itfg2",
  "Itga1",
  "Itga10",
  "Itga11",
  "Itga2",
  "Itga2b",
  "Itga3",
  "Itga4",
  "Itga5",
  "Itga7",
  "Itga8",
  "Itga9",
  "Itgad",
  "Itgae",
  "Itgal",
  "Itgam",
  "Itgav",
  "Itgax",
  "Itgb1",
  "Itgb1bp1",
  "Itgb1bp2",
  "Itgb2",
  "Itgb2l",
  "Itgb3",
  "Itgb3bp",
  "Itgb4",
  "Itgb5",
  "Itgb6",
  "Itgb7",
  "Itgb8",
  "Itgbl1",
  "Itih1",
  "Itih2",
  "Itih3",
  "Itih4",
  "Itih5",
  "Itk",
  "Itln1",
  "Itm2a",
  "Itm2b",
  "Itm2c",
  "Itpa",
  "Itpk1",
  "Itpka",
  "Itpkb",
  "Itpkc",
  "Itpr1",
  "Itpr2",
  "Itpr3",
  "Itpr3os",
  "Itprid1",
  "Itprid2",
  "Itprip",
  "Itpripl1",
  "Itpripl2",
  "Itsn1",
  "Itsn2",
  "Ivd",
  "Ivl",
  "Ivns1abp",
  "Iws1",
  "Iyd",
  "Izumo1",
  "Izumo1r",
  "Izumo2",
  "Izumo3",
  "Izumo4",
  "Jade1",
  "Jade2",
  "Jade3",
  "Jag1",
  "Jag2",
  "Jagn1",
  "Jak1",
  "Jak2",
  "Jak3",
  "Jakmip1",
  "Jakmip2",
  "Jakmip3",
  "Jam2",
  "Jam3",
  "Jaml",
  "Jarid2",
  "Jazf1",
  "Jcad",
  "Jchain",
  "Jdp2",
  "Jhy",
  "Jkamp",
  "Jmjd1c",
  "Jmjd4",
  "Jmjd6",
  "Jmjd8",
  "Jmy",
  "Josd1",
  "Josd2",
  "Jph1",
  "Jph2",
  "Jph3",
  "Jph4",
  "Jpt1",
  "Jpt2",
  "Jpx",
  "Jrk",
  "Jsrp1",
  "Jtb",
  "Jun",
  "Junb",
  "Jund",
  "Junos",
  "Jup",
  "K230015D01Rik",
  "Kalrn",
  "Kank1",
  "Kank2",
  "Kank3",
  "Kank4",
  "Kansl1",
  "Kansl1l",
  "Kansl2",
  "Kansl3",
  "Kantr",
  "Kap",
  "Kars",
  "Kat14",
  "Kat2a",
  "Kat2b",
  "Kat5",
  "Kat6a",
  "Kat6b",
  "Kat7",
  "Kat8",
  "Katna1",
  "Katnal1",
  "Katnal2",
  "Katnb1",
  "Katnbl1",
  "Kazald1",
  "Kazn",
  "Kbtbd11",
  "Kbtbd12",
  "Kbtbd13",
  "Kbtbd2",
  "Kbtbd3",
  "Kbtbd4",
  "Kbtbd6",
  "Kbtbd7",
  "Kbtbd8",
  "Kcmf1",
  "Kcna10",
  "Kcna2",
  "Kcna3",
  "Kcna5",
  "Kcna6",
  "Kcna7",
  "Kcnab1",
  "Kcnab2",
  "Kcnab3",
  "Kcnb1",
  "Kcnb2",
  "Kcnc1",
  "Kcnc2",
  "Kcnc3",
  "Kcnc4",
  "Kcnd1",
  "Kcnd2",
  "Kcnd3",
  "Kcnd3os",
  "Kcne1",
  "Kcne1l",
  "Kcne2",
  "Kcne3",
  "Kcne4",
  "Kcnf1",
  "Kcng1",
  "Kcng2",
  "Kcng3",
  "Kcng4",
  "Kcnh1",
  "Kcnh2",
  "Kcnh3",
  "Kcnh4",
  "Kcnh5",
  "Kcnh6",
  "Kcnh7",
  "Kcnh8",
  "Kcnip1",
  "Kcnip2",
  "Kcnip3",
  "Kcnip4",
  "Kcnj1",
  "Kcnj10",
  "Kcnj11",
  "Kcnj12",
  "Kcnj13",
  "Kcnj14",
  "Kcnj15",
  "Kcnj16",
  "Kcnj2",
  "Kcnj3",
  "Kcnj4",
  "Kcnj5",
  "Kcnj6",
  "Kcnj8",
  "Kcnj9",
  "Kcnk1",
  "Kcnk10",
  "Kcnk12",
  "Kcnk13",
  "Kcnk15",
  "Kcnk16",
  "Kcnk18",
  "Kcnk2",
  "Kcnk3",
  "Kcnk4",
  "Kcnk5",
  "Kcnk6",
  "Kcnk7",
  "Kcnk9",
  "Kcnma1",
  "Kcnmb1",
  "Kcnmb2",
  "Kcnmb3",
  "Kcnmb4",
  "Kcnmb4os1",
  "Kcnmb4os2",
  "Kcnn1",
  "Kcnn2",
  "Kcnn3",
  "Kcnn4",
  "Kcnq1",
  "Kcnq1ot1",
  "Kcnq2",
  "Kcnq3",
  "Kcnq4",
  "Kcnq5",
  "Kcnrg",
  "Kcns1",
  "Kcns2",
  "Kcns3",
  "Kcnt1",
  "Kcnt2",
  "Kcnu1",
  "Kcnv1",
  "Kcnv2",
  "Kcp",
  "Kctd1",
  "Kctd10",
  "Kctd11",
  "Kctd12",
  "Kctd12b",
  "Kctd13",
  "Kctd14",
  "Kctd15",
  "Kctd16",
  "Kctd17",
  "Kctd18",
  "Kctd19",
  "Kctd2",
  "Kctd20",
  "Kctd21",
  "Kctd3",
  "Kctd4",
  "Kctd5",
  "Kctd6",
  "Kctd7",
  "Kctd8",
  "Kctd9",
  "Kdelr1",
  "Kdelr2",
  "Kdelr3",
  "Kdf1",
  "Kdm1a",
  "Kdm1b",
  "Kdm2a",
  "Kdm2b",
  "Kdm3a",
  "Kdm3b",
  "Kdm4a",
  "Kdm4b",
  "Kdm4c",
  "Kdm4d",
  "Kdm5a",
  "Kdm5b",
  "Kdm5c",
  "Kdm5d",
  "Kdm6a",
  "Kdm6b",
  "Kdm6bos",
  "Kdm7a",
  "Kdm8",
  "Kdr",
  "Kdsr",
  "Keap1",
  "Keg1",
  "Kel",
  "Kera",
  "Khdc1b",
  "Khdc3",
  "Khdc4",
  "Khdrbs1",
  "Khdrbs2",
  "Khdrbs3",
  "Khk",
  "Khnyn",
  "Khsrp",
  "Kidins220",
  "Kif11",
  "Kif12",
  "Kif13a",
  "Kif13b",
  "Kif14",
  "Kif15",
  "Kif16b",
  "Kif17",
  "Kif18a",
  "Kif18b",
  "Kif19a",
  "Kif1a",
  "Kif1b",
  "Kif1c",
  "Kif20a",
  "Kif20b",
  "Kif21a",
  "Kif21b",
  "Kif22",
  "Kif23",
  "Kif24",
  "Kif26a",
  "Kif26b",
  "Kif27",
  "Kif28",
  "Kif2a",
  "Kif2b",
  "Kif2c",
  "Kif3a",
  "Kif3b",
  "Kif3c",
  "Kif4",
  "Kif5a",
  "Kif5b",
  "Kif5c",
  "Kif6",
  "Kif7",
  "Kif9",
  "Kifap3",
  "Kifbp",
  "Kifc2",
  "Kifc3",
  "Kifc5b",
  "Kin",
  "Kir3dl1",
  "Kir3dl2",
  "Kirrel",
  "Kirrel2",
  "Kirrel3",
  "Kirrel3os",
  "Kis2",
  "Kiss1r",
  "Kit",
  "Kitl",
  "Kiz",
  "Kl",
  "Klb",
  "Klc1",
  "Klc2",
  "Klc3",
  "Klc4",
  "Klf1",
  "Klf10",
  "Klf11",
  "Klf12",
  "Klf13",
  "Klf14",
  "Klf15",
  "Klf16",
  "Klf17",
  "Klf2",
  "Klf3",
  "Klf4",
  "Klf5",
  "Klf6",
  "Klf7",
  "Klf8",
  "Klf9",
  "Klhdc1",
  "Klhdc10",
  "Klhdc2",
  "Klhdc3",
  "Klhdc4",
  "Klhdc7a",
  "Klhdc7b",
  "Klhdc8a",
  "Klhdc8b",
  "Klhdc9",
  "Klhl1",
  "Klhl10",
  "Klhl11",
  "Klhl12",
  "Klhl13",
  "Klhl14",
  "Klhl15",
  "Klhl18",
  "Klhl2",
  "Klhl20",
  "Klhl21",
  "Klhl22",
  "Klhl23",
  "Klhl24",
  "Klhl25",
  "Klhl26",
  "Klhl28",
  "Klhl29",
  "Klhl3",
  "Klhl30",
  "Klhl31",
  "Klhl32",
  "Klhl33",
  "Klhl34",
  "Klhl35",
  "Klhl36",
  "Klhl38",
  "Klhl4",
  "Klhl40",
  "Klhl41",
  "Klhl42",
  "Klhl5",
  "Klhl6",
  "Klhl7",
  "Klhl8",
  "Klhl9",
  "Klk1",
  "Klk10",
  "Klk11",
  "Klk12",
  "Klk13",
  "Klk14",
  "Klk15",
  "Klk1b1",
  "Klk1b11",
  "Klk1b16",
  "Klk1b21",
  "Klk1b22",
  "Klk1b24",
  "Klk1b26",
  "Klk1b27",
  "Klk1b3",
  "Klk1b4",
  "Klk1b5",
  "Klk1b8",
  "Klk1b9",
  "Klk4",
  "Klk5",
  "Klk6",
  "Klk7",
  "Klk8",
  "Klk9",
  "Klkb1",
  "Klra1",
  "Klra10",
  "Klra17",
  "Klra2",
  "Klra4",
  "Klra5",
  "Klra6",
  "Klra7",
  "Klra8",
  "Klra9",
  "Klrb1",
  "Klrb1a",
  "Klrb1b",
  "Klrb1c",
  "Klrb1f",
  "Klrc1",
  "Klrc2",
  "Klrc3",
  "Klrd1",
  "Klre1",
  "Klrg1",
  "Klrg2",
  "Klrh1",
  "Klri1",
  "Klri2",
  "Klrk1",
  "Kmo",
  "Kmt2a",
  "Kmt2b",
  "Kmt2c",
  "Kmt2d",
  "Kmt2e",
  "Kmt5a",
  "Kmt5b",
  "Kmt5c",
  "Kncn",
  "Kndc1",
  "Knl1",
  "Knop1",
  "Knstrn",
  "Kntc1",
  "Kpna1",
  "Kpna2",
  "Kpna3",
  "Kpna4",
  "Kpna6",
  "Kpna7",
  "Kpnb1",
  "Kprp",
  "Kptn",
  "Kras",
  "Krba1",
  "Krcc1",
  "Kremen1",
  "Kremen2",
  "Kri1",
  "Krit1",
  "Krr1",
  "Krt1",
  "Krt10",
  "Krt12",
  "Krt13",
  "Krt14",
  "Krt15",
  "Krt16",
  "Krt17",
  "Krt18",
  "Krt19",
  "Krt2",
  "Krt20",
  "Krt222",
  "Krt23",
  "Krt24",
  "Krt25",
  "Krt26",
  "Krt27",
  "Krt28",
  "Krt31",
  "Krt32",
  "Krt33a",
  "Krt33b",
  "Krt34",
  "Krt35",
  "Krt36",
  "Krt39",
  "Krt4",
  "Krt40",
  "Krt42",
  "Krt5",
  "Krt6a",
  "Krt6b",
  "Krt7",
  "Krt71",
  "Krt72",
  "Krt73",
  "Krt75",
  "Krt76",
  "Krt77",
  "Krt78",
  "Krt79",
  "Krt8",
  "Krt80",
  "Krt82",
  "Krt84",
  "Krt86",
  "Krt87",
  "Krt88",
  "Krt9",
  "Krt90",
  "Krtap1-3",
  "Krtap1-4",
  "Krtap1-5",
  "Krtap10-10",
  "Krtap10-4",
  "Krtap11-1",
  "Krtap12-1",
  "Krtap13",
  "Krtap13-1",
  "Krtap14",
  "Krtap15",
  "Krtap16-1",
  "Krtap16-3",
  "Krtap17-1",
  "Krtap19-1",
  "Krtap19-2",
  "Krtap19-3",
  "Krtap19-4",
  "Krtap19-5",
  "Krtap19-9b",
  "Krtap2-4",
  "Krtap20-2",
  "Krtap21-1",
  "Krtap22-2",
  "Krtap24-1",
  "Krtap26-1",
  "Krtap28-10",
  "Krtap28-13",
  "Krtap29-1",
  "Krtap3-1",
  "Krtap3-2",
  "Krtap3-3",
  "Krtap31-1",
  "Krtap31-2",
  "Krtap4-1",
  "Krtap4-16",
  "Krtap4-2",
  "Krtap4-6",
  "Krtap4-7",
  "Krtap4-8",
  "Krtap4-9",
  "Krtap5-2",
  "Krtap5-4",
  "Krtap5-5",
  "Krtap6-1",
  "Krtap6-5",
  "Krtap7-1",
  "Krtap8-1",
  "Krtap9-3",
  "Krtap9-5",
  "Krtcap2",
  "Krtcap3",
  "Krtdap",
  "Ksr1",
  "Ksr2",
  "Kti12",
  "Ktn1",
  "Kxd1",
  "Ky",
  "Kyat1",
  "Kyat3",
  "Kynu",
  "L1cam",
  "L1td1",
  "L2hgdh",
  "L3hypdh",
  "L3mbtl1",
  "L3mbtl2",
  "L3mbtl3",
  "L3mbtl4",
  "LTO1",
  "Lacc1",
  "Lactb",
  "Lactb2",
  "Lactbl1",
  "Lad1",
  "Lag3",
  "Lage3",
  "Lair1",
  "Lalba",
  "Lama1",
  "Lama2",
  "Lama3",
  "Lama4",
  "Lama5",
  "Lamb1",
  "Lamb2",
  "Lamb3",
  "Lamc1",
  "Lamc2",
  "Lamc3",
  "Lamp1",
  "Lamp2",
  "Lamp5",
  "Lamtor2",
  "Lamtor3",
  "Lamtor4",
  "Lamtor5",
  "Lancl1",
  "Lancl2",
  "Lancl3",
  "Lao1",
  "Lap3",
  "Laptm4a",
  "Laptm4b",
  "Laptm5",
  "Large1",
  "Large2",
  "Larp1",
  "Larp1b",
  "Larp4",
  "Larp4b",
  "Larp6",
  "Larp7",
  "Lars",
  "Lars2",
  "Las1l",
  "Lasp1",
  "Lat",
  "Lat2",
  "Lats1",
  "Lats2",
  "Lax1",
  "Layn",
  "Lbh",
  "Lbhd2",
  "Lbp",
  "Lbr",
  "Lbx1",
  "Lbx2",
  "Lca5",
  "Lca5l",
  "Lcat",
  "Lce1a1",
  "Lce1a2",
  "Lce1b",
  "Lce1c",
  "Lce1d",
  "Lce1e",
  "Lce1f",
  "Lce1g",
  "Lce1h",
  "Lce1i",
  "Lce1j",
  "Lce1k",
  "Lce1l",
  "Lce1m",
  "Lce3a",
  "Lce3b",
  "Lce3c",
  "Lce3e",
  "Lce3f",
  "Lck",
  "Lclat1",
  "Lcmt1",
  "Lcmt2",
  "Lcn10",
  "Lcn11",
  "Lcn12",
  "Lcn2",
  "Lcn3",
  "Lcn4",
  "Lcn5",
  "Lcn6",
  "Lcn8",
  "Lcn9",
  "Lcor",
  "Lcorl",
  "Lcp1",
  "Lcp2",
  "Lct",
  "Lctl",
  "Ldah",
  "Ldb1",
  "Ldb2",
  "Ldb3",
  "Ldc1",
  "Ldha",
  "Ldhal6b",
  "Ldhb",
  "Ldhc",
  "Ldhd",
  "Ldlr",
  "Ldlrad1",
  "Ldlrad2",
  "Ldlrad3",
  "Ldlrad4",
  "Ldlrap1",
  "Ldoc1",
  "Leap2",
  "Lect2",
  "Lef1",
  "Lefty1",
  "Lefty2",
  "Lekr1",
  "Lelp1",
  "Lemd1",
  "Lemd2",
  "Lemd3",
  "Leng1",
  "Leng8",
  "Leng9",
  "Leo1",
  "Lep",
  "Lepr",
  "Leprot",
  "Leprotl1",
  "Letm1",
  "Letm2",
  "Letmd1",
  "Lexm",
  "Lfng",
  "Lgals1",
  "Lgals12",
  "Lgals2",
  "Lgals3",
  "Lgals3bp",
  "Lgals4",
  "Lgals7",
  "Lgals8",
  "Lgals9",
  "Lgalsl",
  "Lgi1",
  "Lgi2",
  "Lgi3",
  "Lgi4",
  "Lgmn",
  "Lgr4",
  "Lgr5",
  "Lgr6",
  "Lgsn",
  "Lhcgr",
  "Lhfp",
  "Lhfpl1",
  "Lhfpl2",
  "Lhfpl3",
  "Lhfpl4",
  "Lhfpl5",
  "Lhpp",
  "Lhx1",
  "Lhx2",
  "Lhx3",
  "Lhx4",
  "Lhx5",
  "Lhx6",
  "Lhx8",
  "Lhx9",
  "Lias",
  "Lif",
  "Lifr",
  "Lig1",
  "Lig3",
  "Lig4",
  "Lilra5",
  "Lilra6",
  "Lim2",
  "Lima1",
  "Limch1",
  "Limd1",
  "Limd2",
  "Limk1",
  "Lims1",
  "Lims2",
  "Lin28a",
  "Lin28b",
  "Lin37",
  "Lin52",
  "Lin54",
  "Lin7a",
  "Lin7b",
  "Lin7c",
  "Lin9",
  "Lingo1",
  "Lingo2",
  "Lingo3",
  "Lingo4",
  "Lins1",
  "Lipa",
  "Lipc",
  "Lipe",
  "Lipf",
  "Lipg",
  "Liph",
  "Lipi",
  "Lipk",
  "Lipm",
  "Lipn",
  "Lipo1",
  "Lipo3",
  "Lipo4",
  "Lipt1",
  "Lipt2",
  "Litaf",
  "Lix1",
  "Lix1l",
  "Lkaaear1",
  "Llcfc1",
  "Llgl1",
  "Llgl2",
  "Llph",
  "Lman1",
  "Lman1l",
  "Lman2",
  "Lman2l",
  "Lmbr1",
  "Lmbr1l",
  "Lmbrd1",
  "Lmbrd2",
  "Lmcd1",
  "Lmf1",
  "Lmf2",
  "Lmln",
  "Lmna",
  "Lmnb1",
  "Lmnb2",
  "Lmntd1",
  "Lmntd2",
  "Lmo1",
  "Lmo2",
  "Lmo3",
  "Lmo4",
  "Lmo7",
  "Lmod1",
  "Lmod2",
  "Lmod3",
  "Lmtk2",
  "Lmtk3",
  "Lmx1a",
  "Lmx1b",
  "Lncbate6",
  "Lncenc1",
  "Lncpint",
  "Lncppara",
  "Lnpep",
  "Lnpk",
  "Lnx1",
  "Lnx2",
  "Lockd",
  "Lonp1",
  "Lonp2",
  "Lonrf1",
  "Lonrf2",
  "Lonrf3",
  "Lor",
  "Lox",
  "Loxhd1",
  "Loxl1",
  "Loxl2",
  "Loxl3",
  "Loxl4",
  "Lpar1",
  "Lpar2",
  "Lpar3",
  "Lpar4",
  "Lpar5",
  "Lpar6",
  "Lpcat1",
  "Lpcat2",
  "Lpcat2b",
  "Lpcat3",
  "Lpgat1",
  "Lpin1",
  "Lpin2",
  "Lpin3",
  "Lpl",
  "Lpo",
  "Lpp",
  "Lpxn",
  "Lrat",
  "Lratd1",
  "Lratd2",
  "Lrba",
  "Lrch1",
  "Lrch2",
  "Lrcol1",
  "Lrfn1",
  "Lrfn2",
  "Lrfn3",
  "Lrfn4",
  "Lrfn5",
  "Lrg1",
  "Lrguk",
  "Lrif1",
  "Lrig1",
  "Lrig2",
  "Lrig3",
  "Lrit1",
  "Lrit2",
  "Lrit3",
  "Lrmda",
  "Lrmp",
  "Lrp1",
  "Lrp10",
  "Lrp11",
  "Lrp12",
  "Lrp1b",
  "Lrp2",
  "Lrp2bp",
  "Lrp3",
  "Lrp4",
  "Lrp5",
  "Lrp6",
  "Lrp8",
  "Lrp8os1",
  "Lrp8os2",
  "Lrpap1",
  "Lrpprc",
  "Lrr1",
  "Lrrc1",
  "Lrrc10",
  "Lrrc10b",
  "Lrrc14",
  "Lrrc14b",
  "Lrrc15",
  "Lrrc17",
  "Lrrc18",
  "Lrrc19",
  "Lrrc2",
  "Lrrc20",
  "Lrrc23",
  "Lrrc24",
  "Lrrc25",
  "Lrrc26",
  "Lrrc27",
  "Lrrc28",
  "Lrrc29",
  "Lrrc3",
  "Lrrc30",
  "Lrrc31",
  "Lrrc32",
  "Lrrc34",
  "Lrrc36",
  "Lrrc37a",
  "Lrrc38",
  "Lrrc39",
  "Lrrc3b",
  "Lrrc3c",
  "Lrrc4",
  "Lrrc40",
  "Lrrc41",
  "Lrrc42",
  "Lrrc43",
  "Lrrc45",
  "Lrrc46",
  "Lrrc47",
  "Lrrc49",
  "Lrrc4b",
  "Lrrc4c",
  "Lrrc51",
  "Lrrc52",
  "Lrrc55",
  "Lrrc56",
  "Lrrc57",
  "Lrrc58",
  "Lrrc59",
  "Lrrc6",
  "Lrrc61",
  "Lrrc63",
  "Lrrc66",
  "Lrrc69",
  "Lrrc7",
  "Lrrc71",
  "Lrrc72",
  "Lrrc73",
  "Lrrc74a",
  "Lrrc74b",
  "Lrrc75a",
  "Lrrc75b",
  "Lrrc8a",
  "Lrrc8b",
  "Lrrc8c",
  "Lrrc8d",
  "Lrrc8e",
  "Lrrc9",
  "Lrrcc1",
  "Lrrd1",
  "Lrrfip1",
  "Lrrfip2",
  "Lrriq1",
  "Lrriq3",
  "Lrrk1",
  "Lrrk2",
  "Lrrn1",
  "Lrrn2",
  "Lrrn3",
  "Lrrn4",
  "Lrrn4cl",
  "Lrrtm1",
  "Lrrtm2",
  "Lrrtm3",
  "Lrrtm4",
  "Lrsam1",
  "Lrtm1",
  "Lrtm2",
  "Lrwd1",
  "Lsamp",
  "Lsg1",
  "Lsm1",
  "Lsm10",
  "Lsm11",
  "Lsm14b",
  "Lsm2",
  "Lsm3",
  "Lsm4",
  "Lsm5",
  "Lsm6",
  "Lsm7",
  "Lsm8",
  "Lsmem1",
  "Lsmem2",
  "Lsp1",
  "Lsr",
  "Lss",
  "Lst1",
  "Lta",
  "Lta4h",
  "Ltb",
  "Ltb4r1",
  "Ltb4r2",
  "Ltbp1",
  "Ltbp2",
  "Ltbp3",
  "Ltbp4",
  "Ltbr",
  "Ltc4s",
  "Ltf",
  "Ltk",
  "Ltn1",
  "Ltv1",
  "Luc7l",
  "Luc7l2",
  "Luc7l3",
  "Lum",
  "Lurap1",
  "Lurap1l",
  "Luzp1",
  "Luzp2",
  "Lvrn",
  "Lxn",
  "Ly6a",
  "Ly6d",
  "Ly6e",
  "Ly6f",
  "Ly6g",
  "Ly6g2",
  "Ly6g5b",
  "Ly6g5c",
  "Ly6g6c",
  "Ly6g6e",
  "Ly6g6f",
  "Ly6h",
  "Ly6i",
  "Ly6k",
  "Ly6l",
  "Ly6m",
  "Ly75",
  "Ly86",
  "Ly9",
  "Ly96",
  "Lyar",
  "Lyg1",
  "Lyg2",
  "Lyl1",
  "Lyn",
  "Lynx1",
  "Lypd1",
  "Lypd10",
  "Lypd11",
  "Lypd2",
  "Lypd3",
  "Lypd4",
  "Lypd5",
  "Lypd6",
  "Lypd6b",
  "Lypd8",
  "Lypd9",
  "Lypla1",
  "Lypla2",
  "Lyplal1",
  "Lyrm1",
  "Lyrm2",
  "Lyrm4",
  "Lyrm7",
  "Lyrm9",
  "Lysmd1",
  "Lysmd2",
  "Lysmd3",
  "Lysmd4",
  "Lyst",
  "Lyve1",
  "Lyz1",
  "Lyz2",
  "Lyzl1",
  "Lyzl4",
  "Lyzl6",
  "Lzic",
  "Lztfl1",
  "Lztr1",
  "Lzts1",
  "Lzts2",
  "Lzts3",
  "M1ap",
  "M5C1000I18Rik",
  "M6pr",
  "Maats1",
  "Mab21l1",
  "Mab21l2",
  "Mab21l3",
  "Mab21l4",
  "Macc1",
  "Macf1",
  "Maco1",
  "Macrod1",
  "Macrod2",
  "Macrod2os2",
  "Macroh2a1",
  "Macroh2a2",
  "Mad1l1",
  "Mad2l1",
  "Mad2l1bp",
  "Mad2l2",
  "Madcam1",
  "Madd",
  "Maea",
  "Mael",
  "Maf",
  "Maf1",
  "Mafa",
  "Mafb",
  "Maff",
  "Mafg",
  "Mafk",
  "Mag",
  "Magea10",
  "Magea2",
  "Magea4",
  "Magea5",
  "Magea6",
  "Magea8",
  "Mageb16",
  "Mageb18",
  "Mageb4",
  "Maged1",
  "Maged2",
  "Magee1",
  "Magee2",
  "Mageh1",
  "Magel2",
  "Magi1",
  "Magi2",
  "Magi3",
  "Magix",
  "Magoh",
  "Magohb",
  "Magt1",
  "Maip1",
  "Majin",
  "Mak",
  "Mak16",
  "Mal",
  "Mal2",
  "Malat1",
  "Mall",
  "Malrd1",
  "Malsu1",
  "Malt1",
  "Mamdc2",
  "Mamdc4",
  "Maml1",
  "Maml2",
  "Maml3",
  "Mamld1",
  "Mamstr",
  "Man1a",
  "Man1a2",
  "Man1b1",
  "Man1c1",
  "Man2a1",
  "Man2a2",
  "Man2b1",
  "Man2b2",
  "Man2c1",
  "Man2c1os",
  "Manba",
  "Manbal",
  "Manea",
  "Maneal",
  "Manf",
  "Mansc1",
  "Mansc4",
  "Maoa",
  "Maob",
  "Map10",
  "Map11",
  "Map1a",
  "Map1b",
  "Map1lc3a",
  "Map1lc3b",
  "Map1s",
  "Map2",
  "Map2k1",
  "Map2k2",
  "Map2k3",
  "Map2k3os",
  "Map2k4",
  "Map2k5",
  "Map2k6",
  "Map3k1",
  "Map3k10",
  "Map3k11",
  "Map3k12",
  "Map3k13",
  "Map3k14",
  "Map3k15",
  "Map3k19",
  "Map3k2",
  "Map3k20",
  "Map3k21",
  "Map3k3",
  "Map3k4",
  "Map3k5",
  "Map3k6",
  "Map3k7",
  "Map3k7cl",
  "Map3k8",
  "Map3k9",
  "Map4",
  "Map4k1",
  "Map4k2",
  "Map4k3",
  "Map4k4",
  "Map4k5",
  "Map6",
  "Map6d1",
  "Map7",
  "Map7d1",
  "Map7d2",
  "Map7d3",
  "Map9",
  "Mapk1",
  "Mapk11",
  "Mapk12",
  "Mapk13",
  "Mapk14",
  "Mapk15",
  "Mapk1ip1",
  "Mapk1ip1l",
  "Mapk3",
  "Mapk4",
  "Mapk6",
  "Mapk7",
  "Mapk8",
  "Mapk8ip1",
  "Mapk8ip2",
  "Mapk8ip3",
  "Mapk9",
  "Mapkap1",
  "Mapkapk2",
  "Mapkapk3",
  "Mapkbp1",
  "Mapre1",
  "Mapre2",
  "Mapre3",
  "Mapt",
  "Marchf1",
  "Marchf10",
  "Marchf11",
  "Marchf2",
  "Marchf3",
  "Marchf4",
  "Marchf5",
  "Marchf6",
  "Marchf7",
  "Marchf8",
  "Marchf9",
  "Marcks",
  "Marco",
  "Marf1",
  "Mark1",
  "Mark2",
  "Mark3",
  "Mark4",
  "Mars1",
  "Mars2",
  "Marveld1",
  "Marveld2",
  "Marveld3",
  "Mas1",
  "Masp1",
  "Masp2",
  "Mast1",
  "Mast2",
  "Mast3",
  "Mast4",
  "Mastl",
  "Mat1a",
  "Mat2a",
  "Mat2b",
  "Matk",
  "Matn1",
  "Matn2",
  "Matn3",
  "Matn4",
  "Matr3",
  "Mau2",
  "Mavs",
  "Max",
  "Maz",
  "Mb",
  "Mb21d2",
  "Mbd1",
  "Mbd2",
  "Mbd3",
  "Mbd3l1",
  "Mbd3l2",
  "Mbd4",
  "Mbd5",
  "Mbd6",
  "Mbip",
  "Mbl1",
  "Mbl2",
  "Mblac1",
  "Mblac2",
  "Mbnl1",
  "Mbnl2",
  "Mbnl3",
  "Mboat1",
  "Mboat2",
  "Mboat4",
  "Mboat7",
  "Mbp",
  "Mbtd1",
  "Mbtps1",
  "Mbtps2",
  "Mc1r",
  "Mc2r",
  "Mc3r",
  "Mc4r",
  "Mc5r",
  "Mcam",
  "Mcat",
  "Mcc",
  "Mccc1",
  "Mccc1os",
  "Mccc2",
  "Mcee",
  "Mcemp1",
  "Mcf2",
  "Mcf2l",
  "Mcfd2",
  "Mchr1",
  "Mcl1",
  "Mcm10",
  "Mcm2",
  "Mcm3",
  "Mcm3ap",
  "Mcm4",
  "Mcm5",
  "Mcm6",
  "Mcm7",
  "Mcm8",
  "Mcm9",
  "Mcmbp",
  "Mcmdc2",
  "Mcoln1",
  "Mcoln2",
  "Mcoln3",
  "Mcph1",
  "Mcpt1",
  "Mcpt2",
  "Mcpt4",
  "Mcpt8",
  "Mcpt9",
  "Mcrip1",
  "Mcrip2",
  "Mcrs1",
  "Mctp1",
  "Mctp2",
  "Mcts2",
  "Mcu",
  "Mcub",
  "Mcur1",
  "Mdc1",
  "Mdfi",
  "Mdfic",
  "Mdga1",
  "Mdga2",
  "Mdh1",
  "Mdh1b",
  "Mdh2",
  "Mdk",
  "Mdm1",
  "Mdm2",
  "Mdm4",
  "Mdn1",
  "Mdp1",
  "Me1",
  "Me2",
  "Me3",
  "Mea1",
  "Meaf6",
  "Meak7",
  "Mecom",
  "Mecp2",
  "Mecr",
  "Med1",
  "Med10",
  "Med11",
  "Med12l",
  "Med13l",
  "Med14",
  "Med15",
  "Med16",
  "Med17",
  "Med18",
  "Med19",
  "Med20",
  "Med21",
  "Med22",
  "Med23",
  "Med24",
  "Med25",
  "Med26",
  "Med27",
  "Med28",
  "Med29",
  "Med30",
  "Med31",
  "Med4",
  "Med6",
  "Med7",
  "Med8",
  "Med9",
  "Med9os",
  "Medag",
  "Mef2a",
  "Mef2b",
  "Mef2c",
  "Mef2d",
  "Mefv",
  "Meg3",
  "Megf10",
  "Megf11",
  "Megf6",
  "Megf8",
  "Megf9",
  "Mei1",
  "Mei4",
  "Meig1",
  "Meikin",
  "Meiob",
  "Meioc",
  "Meis1",
  "Meis2",
  "Meis3",
  "Melk",
  "Meltf",
  "Memo1",
  "Men1",
  "Meox1",
  "Meox2",
  "Mep1a",
  "Mep1b",
  "Mepce",
  "Mepe",
  "Mertk",
  "Mesd",
  "Mesp1",
  "Mesp2",
  "Mest",
  "Met",
  "Metap1",
  "Metap1d",
  "Metap2",
  "Methig1",
  "Metrn",
  "Metrnl",
  "Mettl1",
  "Mettl11b",
  "Mettl14",
  "Mettl15",
  "Mettl16",
  "Mettl17",
  "Mettl18",
  "Mettl2",
  "Mettl21a",
  "Mettl21c",
  "Mettl21e",
  "Mettl22",
  "Mettl23",
  "Mettl24",
  "Mettl25",
  "Mettl26",
  "Mettl27",
  "Mettl3",
  "Mettl4",
  "Mettl5",
  "Mettl6",
  "Mettl7a1",
  "Mettl7a2",
  "Mettl7a3",
  "Mettl7b",
  "Mettl8",
  "Mettl9",
  "Mex3a",
  "Mex3b",
  "Mex3c",
  "Mex3d",
  "Mfap1a",
  "Mfap2",
  "Mfap3",
  "Mfap3l",
  "Mfap4",
  "Mfap5",
  "Mff",
  "Mfge8",
  "Mfhas1",
  "Mfn1",
  "Mfn2",
  "Mfng",
  "Mfsd1",
  "Mfsd10",
  "Mfsd11",
  "Mfsd12",
  "Mfsd13a",
  "Mfsd13b",
  "Mfsd14a",
  "Mfsd14b",
  "Mfsd2a",
  "Mfsd2b",
  "Mfsd3",
  "Mfsd4a",
  "Mfsd4b1",
  "Mfsd4b2",
  "Mfsd4b3-ps",
  "Mfsd4b4",
  "Mfsd4b5",
  "Mfsd5",
  "Mfsd6",
  "Mfsd6l",
  "Mfsd7a",
  "Mfsd8",
  "Mfsd9",
  "Mga",
  "Mgam",
  "Mgam2-ps",
  "Mgarp",
  "Mgat1",
  "Mgat2",
  "Mgat3",
  "Mgat4a",
  "Mgat4b",
  "Mgat4c",
  "Mgat4d",
  "Mgat4e",
  "Mgat4f",
  "Mgat5",
  "Mgat5b",
  "Mgll",
  "Mgme1",
  "Mgmt",
  "Mgp",
  "Mgrn1",
  "Mgst1",
  "Mgst2",
  "Mgst3",
  "Mhrt",
  "Mia",
  "Mia2",
  "Mia3",
  "Mib1",
  "Mib2",
  "Mical1",
  "Mical2",
  "Mical3",
  "Micall1",
  "Micall2",
  "Micos10",
  "Micos13",
  "Micu1",
  "Micu2",
  "Micu3",
  "Mid1",
  "Mid1ip1",
  "Mid2",
  "Midn",
  "Mief1",
  "Mief2",
  "Mien1",
  "Mier1",
  "Mier2",
  "Mier3",
  "Mif",
  "Mif4gd",
  "Miga1",
  "Miga2",
  "Miip",
  "Mill1",
  "Mill2",
  "Milr1",
  "Minar1",
  "Minar2",
  "Mindy1",
  "Mindy2",
  "Mindy3",
  "Mindy4",
  "Mindy4b-ps",
  "Mink1",
  "Minpp1",
  "Mios",
  "Miox",
  "Mip",
  "Mipep",
  "Mipol1",
  "Mir100hg",
  "Mir124-2hg",
  "Mir124a-1hg",
  "Mir17hg",
  "Mir181a-1hg",
  "Mir205hg",
  "Mir22hg",
  "Mir670hg",
  "Mir9-3hg",
  "Mir99ahg",
  "Mirg",
  "Mirt1",
  "Mirt2",
  "Mis12",
  "Mis18a",
  "Mis18bp1",
  "Misp",
  "Misp3",
  "Mitd1",
  "Mitf",
  "Mixl1",
  "Mki67",
  "Mkks",
  "Mkln1",
  "Mknk1",
  "Mknk2",
  "Mkrn1",
  "Mkrn2",
  "Mkrn2os",
  "Mkrn3",
  "Mks1",
  "Mkx",
  "Mlana",
  "Mlc1",
  "Mlec",
  "Mlf1",
  "Mlf2",
  "Mlh1",
  "Mlh3",
  "Mlip",
  "Mlkl",
  "Mllt1",
  "Mllt11",
  "Mllt3",
  "Mllt6",
  "Mlph",
  "Mlst8",
  "Mlx",
  "Mlxip",
  "Mlxipl",
  "Mlycd",
  "Mm2pr",
  "Mmaa",
  "Mmab",
  "Mmachc",
  "Mmadhc",
  "Mmd",
  "Mmd2",
  "Mme",
  "Mmel1",
  "Mmgt1",
  "Mmgt2",
  "Mmp10",
  "Mmp11",
  "Mmp12",
  "Mmp13",
  "Mmp14",
  "Mmp15",
  "Mmp16",
  "Mmp17",
  "Mmp19",
  "Mmp2",
  "Mmp20",
  "Mmp21",
  "Mmp23",
  "Mmp24",
  "Mmp25",
  "Mmp27",
  "Mmp28",
  "Mmp3",
  "Mmp7",
  "Mmp8",
  "Mmp9",
  "Mmrn1",
  "Mmrn2",
  "Mms19",
  "Mms22l",
  "Mmut",
  "Mn1",
  "Mnat1",
  "Mnd1",
  "Mndal",
  "Mns1",
  "Mnt",
  "Mnx1",
  "Mob1a",
  "Mob1b",
  "Mob2",
  "Mob3a",
  "Mob3b",
  "Mob3c",
  "Mob4",
  "Mobp",
  "Mocos",
  "Mocs1",
  "Mocs2",
  "Mocs3",
  "Mog",
  "Mogat1",
  "Mogat2",
  "Mogs",
  "Mok",
  "Mon1a",
  "Mon1b",
  "Mon2",
  "Morc1",
  "Morc2a",
  "Morc2b",
  "Morc3",
  "Morc4",
  "Morf4l2",
  "Morn1",
  "Morn2",
  "Morn3",
  "Morn4",
  "Morn5",
  "Morrbid",
  "Mos",
  "Mosmo",
  "Mospd1",
  "Mospd2",
  "Mospd3",
  "Mov10",
  "Mov10l1",
  "Moxd1",
  "Moxd2",
  "Mpc1",
  "Mpc2",
  "Mpdu1",
  "Mpdz",
  "Mpeg1",
  "Mpg",
  "Mphosph10",
  "Mphosph6",
  "Mphosph8",
  "Mphosph9",
  "Mpi",
  "Mpig6b",
  "Mpl",
  "Mplkip",
  "Mpnd",
  "Mpo",
  "Mpp1",
  "Mpp2",
  "Mpp3",
  "Mpp4",
  "Mpp5",
  "Mpp6",
  "Mpp7",
  "Mppe1",
  "Mpped1",
  "Mpped2",
  "Mprip",
  "Mpst",
  "Mptx1",
  "Mpv17",
  "Mpv17l",
  "Mpv17l2",
  "Mpz",
  "Mpzl1",
  "Mpzl2",
  "Mpzl3",
  "Mr1",
  "Mrap",
  "Mrap2",
  "Mras",
  "Mrc1",
  "Mrc2",
  "Mre11a",
  "Mreg",
  "Mrfap1",
  "Mrgbp",
  "Mrgpra1",
  "Mrgpra2a",
  "Mrgpra3",
  "Mrgpra4",
  "Mrgpra6",
  "Mrgpra9",
  "Mrgprb1",
  "Mrgprb2",
  "Mrgprb3",
  "Mrgprb4",
  "Mrgprb5",
  "Mrgprb8",
  "Mrgprd",
  "Mrgpre",
  "Mrgprf",
  "Mrgprg",
  "Mrgprh",
  "Mrgprx1",
  "Mrgprx2",
  "Mri1",
  "Mrln",
  "Mrm1",
  "Mrm2",
  "Mrm3",
  "Mrnip",
  "Mro",
  "Mroh1",
  "Mroh2a",
  "Mroh2b",
  "Mroh3",
  "Mroh4",
  "Mroh5",
  "Mroh7",
  "Mroh8",
  "Mroh9",
  "Mrpl1",
  "Mrpl10",
  "Mrpl11",
  "Mrpl12",
  "Mrpl13",
  "Mrpl14",
  "Mrpl15",
  "Mrpl16",
  "Mrpl17",
  "Mrpl18",
  "Mrpl19",
  "Mrpl2",
  "Mrpl20",
  "Mrpl21",
  "Mrpl22",
  "Mrpl24",
  "Mrpl27",
  "Mrpl28",
  "Mrpl3",
  "Mrpl30",
  "Mrpl32",
  "Mrpl33",
  "Mrpl34",
  "Mrpl35",
  "Mrpl36",
  "Mrpl37",
  "Mrpl38",
  "Mrpl39",
  "Mrpl4",
  "Mrpl40",
  "Mrpl41",
  "Mrpl42",
  "Mrpl43",
  "Mrpl44",
  "Mrpl45",
  "Mrpl46",
  "Mrpl47",
  "Mrpl48",
  "Mrpl49",
  "Mrpl50",
  "Mrpl52",
  "Mrpl54",
  "Mrpl55",
  "Mrpl57",
  "Mrpl58",
  "Mrpl9",
  "Mrps10",
  "Mrps11",
  "Mrps12",
  "Mrps14",
  "Mrps15",
  "Mrps16",
  "Mrps17",
  "Mrps18a",
  "Mrps18b",
  "Mrps18c",
  "Mrps2",
  "Mrps21",
  "Mrps23",
  "Mrps24",
  "Mrps25",
  "Mrps26",
  "Mrps27",
  "Mrps28",
  "Mrps30",
  "Mrps31",
  "Mrps33",
  "Mrps34",
  "Mrps35",
  "Mrps36",
  "Mrps6",
  "Mrps7",
  "Mrps9",
  "Mrrf",
  "Mrs2",
  "Mrtfa",
  "Mrtfb",
  "Mrto4",
  "Mrvi1",
  "Ms4a1",
  "Ms4a10",
  "Ms4a12",
  "Ms4a13",
  "Ms4a14",
  "Ms4a15",
  "Ms4a18",
  "Ms4a19",
  "Ms4a2",
  "Ms4a20",
  "Ms4a3",
  "Ms4a4a",
  "Ms4a4b",
  "Ms4a4c",
  "Ms4a4d",
  "Ms4a5",
  "Ms4a6b",
  "Ms4a6c",
  "Ms4a6d",
  "Ms4a7",
  "Ms4a8a",
  "Msantd1",
  "Msantd2",
  "Msantd3",
  "Msantd4",
  "Msc",
  "Msgn1",
  "Msh2",
  "Msh3",
  "Msh4",
  "Msh5",
  "Msh6",
  "Msi1",
  "Msi2",
  "Msl1",
  "Msl2",
  "Msl3",
  "Msl3l2",
  "Msln",
  "Mslnl",
  "Msmb",
  "Msmo1",
  "Msmp",
  "Msn",
  "Msr1",
  "Msra",
  "Msrb1",
  "Msrb2",
  "Msrb3",
  "Mss51",
  "Mst1",
  "Mst1r",
  "Mstn",
  "Msto1",
  "Msx1",
  "Msx2",
  "Msx3",
  "Mt1",
  "Mt2",
  "Mt3",
  "Mt4",
  "Mta1",
  "Mta2",
  "Mta3",
  "Mtag2",
  "Mtap",
  "Mtarc1",
  "Mtarc2",
  "Mtbp",
  "Mtch1",
  "Mtch2",
  "Mtcl1",
  "Mtcp1",
  "Mtdh",
  "Mterf1a",
  "Mterf2",
  "Mterf3",
  "Mterf4",
  "Mtf1",
  "Mtf2",
  "Mtfmt",
  "Mtfp1",
  "Mtfr1",
  "Mtfr1l",
  "Mtfr2",
  "Mtg1",
  "Mtg2",
  "Mthfd1",
  "Mthfd1l",
  "Mthfd2",
  "Mthfd2l",
  "Mthfr",
  "Mthfsd",
  "Mthfsl",
  "Mtif2",
  "Mtif3",
  "Mtln",
  "Mtm1",
  "Mtmr1",
  "Mtmr10",
  "Mtmr11",
  "Mtmr12",
  "Mtmr14",
  "Mtmr2",
  "Mtmr3",
  "Mtmr4",
  "Mtmr6",
  "Mtmr7",
  "Mtmr9",
  "Mtnr1a",
  "Mtnr1b",
  "Mto1",
  "Mtor",
  "Mtpap",
  "Mtpn",
  "Mtr",
  "Mtres1",
  "Mtrex",
  "Mtrf1",
  "Mtrf1l",
  "Mtrr",
  "Mtss1",
  "Mtss2",
  "Mttp",
  "Mturn",
  "Mtus1",
  "Mtus2",
  "Mtx1",
  "Mtx2",
  "Mtx3",
  "Muc1",
  "Muc13",
  "Muc15",
  "Muc16",
  "Muc19",
  "Muc2",
  "Muc20",
  "Muc3",
  "Muc4",
  "Muc5ac",
  "Muc5b",
  "Muc6",
  "Mucl1",
  "Mucl2",
  "Mucl3",
  "Mug1",
  "Mug2",
  "Mul1",
  "Mup1",
  "Mup20",
  "Mup4",
  "Mup5",
  "Mup6",
  "Mus81",
  "Musk",
  "Mustn1",
  "Mutyh",
  "Mvb12a",
  "Mvb12b",
  "Mvd",
  "Mvk",
  "Mvp",
  "Mxd1",
  "Mxd3",
  "Mxd4",
  "Mxi1",
  "Mxra7",
  "Mxra8",
  "Mxra8os",
  "Myadm",
  "Myadml2",
  "Mybbp1a",
  "Mybl1",
  "Mybl2",
  "Mybpc1",
  "Mybpc2",
  "Mybpc3",
  "Mybph",
  "Mybphl",
  "Myc",
  "Mycbp",
  "Mycbp2",
  "Mycbpap",
  "Mycl",
  "Mycn",
  "Mycs",
  "Myct1",
  "Myd88",
  "Mydgf",
  "Myef2",
  "Myf5",
  "Myf6",
  "Myg1",
  "Myh1",
  "Myh10",
  "Myh11",
  "Myh13",
  "Myh14",
  "Myh15",
  "Myh2",
  "Myh3",
  "Myh4",
  "Myh6",
  "Myh7",
  "Myh7b",
  "Myh8",
  "Myh9",
  "Myl1",
  "Myl10",
  "Myl12a",
  "Myl12b",
  "Myl2",
  "Myl3",
  "Myl4",
  "Myl6",
  "Myl6b",
  "Myl7",
  "Myl9",
  "Mylip",
  "Mylk",
  "Mylk2",
  "Mylk3",
  "Mylk4",
  "Mylpf",
  "Mymk",
  "Mymx",
  "Mynn",
  "Myo10",
  "Myo15",
  "Myo15b",
  "Myo16",
  "Myo18a",
  "Myo18b",
  "Myo19",
  "Myo1a",
  "Myo1b",
  "Myo1c",
  "Myo1d",
  "Myo1e",
  "Myo1f",
  "Myo1g",
  "Myo1h",
  "Myo3a",
  "Myo3b",
  "Myo5a",
  "Myo5b",
  "Myo6",
  "Myo7a",
  "Myo7b",
  "Myo9a",
  "Myo9b",
  "Myoc",
  "Myocd",
  "Myof",
  "Myom1",
  "Myom2",
  "Myom3",
  "Myorg",
  "Myot",
  "Myoz1",
  "Myoz2",
  "Myoz3",
  "Mypn",
  "Mypop",
  "Myrf",
  "Myrfl",
  "Myrip",
  "Mysm1",
  "Myt1l",
  "Myzap",
  "Mzf1",
  "Mzt1",
  "Mzt2",
  "N4bp1",
  "N4bp2",
  "N4bp2l1",
  "N4bp2l2",
  "N4bp2os",
  "N4bp3",
  "N6amt1",
  "Naa10",
  "Naa11",
  "Naa15",
  "Naa16",
  "Naa20",
  "Naa25",
  "Naa30",
  "Naa35",
  "Naa38",
  "Naa40",
  "Naa50",
  "Naa60",
  "Naa80",
  "Naaa",
  "Naalad2",
  "Naaladl1",
  "Naaladl2",
  "Nab1",
  "Nab2",
  "Nabp1",
  "Nabp2",
  "Nacad",
  "Nacc1",
  "Nacc2",
  "Nadk",
  "Nadk2",
  "Nadsyn1",
  "Nae1",
  "Naf1",
  "Naga",
  "Nagk",
  "Naglu",
  "Nagpa",
  "Nags",
  "Naif1",
  "Naip1",
  "Naip2",
  "Naip5",
  "Naip6",
  "Nalcn",
  "Nampt",
  "Nanog",
  "Nanos1",
  "Nanos2",
  "Nanos3",
  "Nanp",
  "Nans",
  "Nap1l1",
  "Nap1l2",
  "Nap1l3",
  "Nap1l4",
  "Nap1l5",
  "Napa",
  "Napb",
  "Napepld",
  "Napg",
  "Naprt",
  "Napsa",
  "Narf",
  "Nars",
  "Nars2",
  "Nasp",
  "Nat1",
  "Nat10",
  "Nat14",
  "Nat2",
  "Nat3",
  "Nat8",
  "Nat8f1",
  "Nat8f2",
  "Nat8f4",
  "Nat8f5",
  "Nat8l",
  "Nat9",
  "Natd1",
  "Nav1",
  "Nav2",
  "Nav3",
  "Naxd",
  "Naxe",
  "Nbas",
  "Nbdy",
  "Nbea",
  "Nbeal1",
  "Nbeal2",
  "Nbl1",
  "Nbn",
  "Nbr1",
  "Ncald",
  "Ncam1",
  "Ncam2",
  "Ncan",
  "Ncapd2",
  "Ncapd3",
  "Ncapg",
  "Ncapg2",
  "Ncaph",
  "Ncaph2",
  "Ncbp1",
  "Ncbp2",

  "Ncbp3",
  "Nccrp1",
  "Ncdn",
  "Nceh1",
  "Ncf1",
  "Ncf2",
  "Ncf4",
  "Nck1",
  "Nck2",
  "Nckap1",
  "Nckap1l",
  "Nckap5",
  "Nckap5l",
  "Nckap5los",
  "Nckipsd",
  "Ncl",
  "Ncln",
  "Ncmap",
  "Ncoa1",
  "Ncoa2",
  "Ncoa3",
  "Ncoa4",
  "Ncoa5",
  "Ncoa6",
  "Ncoa7",
  "Ncor1",
  "Ncor2",
  "Ncr1",
  "Ncs1",
  "Ncstn",
  "Nctc1",
  "Ndc1",
  "Ndc80",
  "Nde1",
  "Ndel1",
  "Ndfip1",
  "Ndfip2",
  "Ndn",
  "Ndnf",
  "Ndor1",
  "Ndp",
  "Ndrg1",
  "Ndrg2",
  "Ndrg3",
  "Ndrg4",
  "Ndst1",
  "Ndst2",
  "Ndst3",
  "Ndst4",
  "Ndufa1",
  "Ndufa10",
  "Ndufa11",
  "Ndufa12",
  "Ndufa13",
  "Ndufa2",
  "Ndufa3",
  "Ndufa4",
  "Ndufa4l2",
  "Ndufa5",
  "Ndufa6",
  "Ndufa7",
  "Ndufa8",
  "Ndufa9",
  "Ndufab1",
  "Ndufaf1",
  "Ndufaf2",
  "Ndufaf3",
  "Ndufaf4",
  "Ndufaf5",
  "Ndufaf6",
  "Ndufaf7",
  "Ndufaf8",
  "Ndufb1-ps",
  "Ndufb10",
  "Ndufb11",
  "Ndufb2",
  "Ndufb3",
  "Ndufb4",
  "Ndufb5",
  "Ndufb6",
  "Ndufb7",
  "Ndufb8",
  "Ndufb9",
  "Ndufc1",
  "Ndufc2",
  "Ndufs1",
  "Ndufs2",
  "Ndufs3",
  "Ndufs4",
  "Ndufs5",
  "Ndufs6",
  "Ndufs7",
  "Ndufs8",
  "Ndufv1",
  "Ndufv2",
  "Ndufv3",
  "Neat1",
  "Neb",
  "Nebl",
  "Necab1",
  "Necab2",
  "Necab3",
  "Necap1",
  "Necap2",
  "Nectin1",
  "Nectin2",
  "Nectin3",
  "Nectin4",
  "Nedd1",
  "Nedd4",
  "Nedd4l",
  "Nedd8",
  "Nedd9",
  "Nefh",
  "Nefl",
  "Nefm",
  "Negr1",
  "Neil1",
  "Neil2",
  "Neil3",
  "Nek1",
  "Nek10",
  "Nek11",
  "Nek2",
  "Nek3",
  "Nek4",
  "Nek5",
  "Nek6",
  "Nek7",
  "Nek8",
  "Nek9",
  "Nelfa",
  "Nelfb",
  "Nelfe",
  "Nell1",
  "Nell2",
  "Nemf",
  "Nemp1",
  "Nemp2",
  "Nenf",
  "Neo1",
  "Nepn",
  "Nepro",
  "Nes",
  "Net1",
  "Neto1",
  "Neto2",
  "Neu1",
  "Neu2",
  "Neu3",
  "Neu4",
  "Neurl1a",
  "Neurl1b",
  "Neurl2",
  "Neurl3",
  "Neurl4",
  "Neurod1",
  "Neurod4",
  "Neurod6",
  "Neurog1",
  "Neurog2",
  "Neurog3",
  "Nexmif",
  "Nexn",
  "Nf1",
  "Nf2",
  "Nfam1",
  "Nfasc",
  "Nfat5",
  "Nfatc1",
  "Nfatc2",
  "Nfatc2ip",
  "Nfatc3",
  "Nfatc4",
  "Nfe2",
  "Nfe2l1",
  "Nfe2l2",
  "Nfe2l3",
  "Nfia",
  "Nfib",
  "Nfic",
  "Nfil3",
  "Nfix",
  "Nfkb1",
  "Nfkb2",
  "Nfkbia",
  "Nfkbib",
  "Nfkbid",
  "Nfkbie",
  "Nfkbil1",
  "Nfkbiz",
  "Nfrkb",
  "Nfs1",
  "Nfu1",
  "Nfx1",
  "Nfxl1",
  "Nfya",
  "Nfyb",
  "Nfyc",
  "Ngb",
  "Ngdn",
  "Ngef",
  "Ngf",
  "Ngfr",
  "Ngly1",
  "Ngp",
  "Ngrn",
  "Nhej1",
  "Nhlh1",
  "Nhlh2",
  "Nhlrc1",
  "Nhlrc2",
  "Nhlrc3",
  "Nhlrc4",
  "Nhp2",
  "Nhs",
  "Nhsl1",
  "Nhsl2",
  "Nicn1",
  "Nid1",
  "Nid2",
  "Nif3l1",
  "Nifk",
  "Nim1k",
  "Nin",
  "Ninj1",
  "Ninj2",
  "Ninl",
  "Nip7",
  "Nipa1",
  "Nipa2",
  "Nipal1",
  "Nipal2",
  "Nipal3",
  "Nipal4",
  "Nipbl",
  "Nipsnap1",
  "Nipsnap2",
  "Nipsnap3a",
  "Nipsnap3b",
  "Nisch",
  "Nit1",
  "Nit2",
  "Nkain2",
  "Nkain3",
  "Nkain4",
  "Nkap",
  "Nkapd1",
  "Nkapl",
  "Nkd1",
  "Nkd2",
  "Nkg7",
  "Nkiras1",
  "Nkiras2",
  "Nkpd1",
  "Nkrf",
  "Nktr",
  "Nkx1-1",
  "Nkx1-2",
  "Nkx2-1",
  "Nkx2-2",
  "Nkx2-3",
  "Nkx2-4",
  "Nkx2-5",
  "Nkx2-6",
  "Nkx2-9",
  "Nkx3-1",
  "Nkx3-2",
  "Nkx6-1",
  "Nkx6-2",
  "Nkx6-3",
  "Nle1",
  "Nlgn1",
  "Nlgn2",
  "Nlgn3",
  "Nlk",
  "Nln",
  "Nlrc3",
  "Nlrc4",
  "Nlrc5",
  "Nlrp10",
  "Nlrp12",
  "Nlrp14",
  "Nlrp1a",
  "Nlrp1b",
  "Nlrp2",
  "Nlrp3",
  "Nlrp4a",
  "Nlrp4b",
  "Nlrp4c",
  "Nlrp4e",
  "Nlrp4f",
  "Nlrp5",
  "Nlrp6",
  "Nlrp9a",
  "Nlrp9b",
  "Nlrp9c",
  "Nlrx1",
  "Nmb",
  "Nmbr",
  "Nmd3",
  "Nme1",
  "Nme4",
  "Nme5",
  "Nme6",
  "Nme7",
  "Nme8",
  "Nme9",
  "Nmi",
  "Nmnat1",
  "Nmnat2",
  "Nmnat3",
  "Nmral1",
  "Nmrk1",
  "Nmrk2",
  "Nms",
  "Nmt1",
  "Nmt2",
  "Nmu",
  "Nmur1",
  "Nmur2",
  "Nnat",
  "Nnmt",
  "Nnt",
  "Noa1",
  "Nob1",
  "Nobox",
  "Noc2l",
  "Noc3l",
  "Noc4l",
  "Noct",
  "Nod1",
  "Nod2",
  "Nodal",
  "Nog",
  "Nol10",
  "Nol11",
  "Nol12",
  "Nol3",
  "Nol4",
  "Nol4l",
  "Nol6",
  "Nol7",
  "Nol8",
  "Nol9",
  "Nolc1",
  "Nom1",
  "Nomo1",
  "Nono",
  "Nop10",
  "Nop14",
  "Nop16",
  "Nop2",
  "Nop53",
  "Nop56",
  "Nop58",
  "Nop9",
  "Nos1",
  "Nos1ap",
  "Nos2",
  "Nos3",
  "Nosip",
  "Nostrin",
  "Notch1",
  "Notch2",
  "Notch3",
  "Notch4",
  "Noto",
  "Notum",
  "Notumos",
  "Nova1",
  "Nova2",
  "Nox1",
  "Nox3",
  "Nox4",
  "Noxa1",
  "Noxo1",
  "Noxred1",
  "Npas1",
  "Npas2",
  "Npas3",
  "Npas4",
  "Npb",
  "Npbwr1",
  "Npc1",
  "Npc1l1",
  "Npc2",
  "Npdc1",
  "Npepl1",
  "Npepps",
  "Npffr1",
  "Npffr2",
  "Nphp1",
  "Nphp3",
  "Nphp4",
  "Nphs1os",
  "Nphs2",
  "Npl",
  "Nploc4",
  "Npm1",
  "Npm2",
  "Npm3",
  "Npnt",
  "Nppa",
  "Nppb",
  "Nppc",
  "Npr1",
  "Npr2",
  "Npr3",
  "Nprl2",
  "Nprl3",
  "Nps",
  "Npsr1",
  "Nptn",
  "Nptx1",
  "Nptx2",
  "Nptxr",
  "Npvf",
  "Npw",
  "Npy",
  "Npy1r",
  "Npy2r",
  "Npy4r",
  "Npy5r",
  "Npy6r",
  "Nqo1",
  "Nqo2",
  "Nr0b1",
  "Nr0b2",
  "Nr1d1",
  "Nr1d2",
  "Nr1h2",
  "Nr1h3",
  "Nr1h4",
  "Nr1h5",
  "Nr1i2",
  "Nr1i3",
  "Nr2c1",
  "Nr2c2",
  "Nr2c2ap",
  "Nr2e1",
  "Nr2e3",
  "Nr2f1",
  "Nr2f2",
  "Nr2f6",
  "Nr3c1",
  "Nr3c2",
  "Nr4a1",
  "Nr4a2",
  "Nr4a3",
  "Nr5a1",
  "Nr5a2",
  "Nr6a1",
  "Nradd",
  "Nrap",
  "Nrarp",
  "Nras",
  "Nrbp1",
  "Nrbp2",
  "Nrcam",
  "Nrd1",
  "Nrde2",
  "Nrep",
  "Nrf1",
  "Nrg1",
  "Nrg2",
  "Nrg3",
  "Nrg3os",
  "Nrg4",
  "Nrgn",
  "Nrip1",
  "Nrip2",
  "Nrip3",
  "Nrk",
  "Nrl",
  "Nrm",
  "Nrn1",
  "Nrn1l",
  "Nron",
  "Nrp1",
  "Nrp2",
  "Nrros",
  "Nrsn1",
  "Nrsn2",
  "Nrtn",
  "Nrxn1",
  "Nrxn2",
  "Nrxn3",
  "Nsa2",
  "Nsd1",
  "Nsd2",
  "Nsd3",
  "Nsdhl",
  "Nsf",
  "Nsfl1c",
  "Nsg1",
  "Nsg2",
  "Nsl1",
  "Nsmaf",
  "Nsmce2",
  "Nsmce3",
  "Nsmce4a",
  "Nsmf",
  "Nsrp1",
  "Nsun2",
  "Nsun3",
  "Nsun4",
  "Nsun5",
  "Nsun6",
  "Nsun7",
  "Nt5c",
  "Nt5c1a",
  "Nt5c1b",
  "Nt5c2",
  "Nt5c3",
  "Nt5c3b",
  "Nt5dc1",
  "Nt5dc2",
  "Nt5dc3",
  "Nt5e",
  "Nt5el",
  "Nt5m",
  "Ntan1",
  "Ntf3",
  "Ntf5",
  "Nthl1",
  "Ntm",
  "Ntmt1",
  "Ntn1",
  "Ntn3",
  "Ntn5",
  "Ntng1",
  "Ntng2",
  "Ntpcr",
  "Ntrk1",
  "Ntrk2",
  "Ntrk3",
  "Nts",
  "Ntsr1",
  "Ntsr2",
  "Nuak1",
  "Nub1",
  "Nubp1",
  "Nubp2",
  "Nubpl",
  "Nucb1",
  "Nucb2",
  "Nucks1",
  "Nudc",
  "Nudcd1",
  "Nudcd2",
  "Nudcd3",
  "Nudt1",
  "Nudt10",
  "Nudt11",
  "Nudt12",
  "Nudt12os",
  "Nudt13",
  "Nudt14",
  "Nudt15",
  "Nudt16",
  "Nudt16l1",
  "Nudt16l2",
  "Nudt17",
  "Nudt18",
  "Nudt19",
  "Nudt2",
  "Nudt21",
  "Nudt22",
  "Nudt3",
  "Nudt4",
  "Nudt5",
  "Nudt6",
  "Nudt7",
  "Nudt9",
  "Nuf2",
  "Nufip1",
  "Nufip2",
  "Nuggc",
  "Numa1",
  "Numb",
  "Numbl",
  "Nup107",
  "Nup133",
  "Nup153",
  "Nup155",
  "Nup160",
  "Nup188",
  "Nup205",
  "Nup210",
  "Nup210l",
  "Nup214",
  "Nup35",
  "Nup37",
  "Nup43",
  "Nup50",
  "Nup54",
  "Nup62",
  "Nup62cl",
  "Nup85",
  "Nup88",
  "Nup93",
  "Nup98",
  "Nupl2",
  "Nupr1",
  "Nupr1l",
  "Nus1",
  "Nusap1",
  "Nutm1",
  "Nutm2",
  "Nvl",
  "Nwd1",
  "Nwd2",
  "Nxf1",
  "Nxf2",
  "Nxf3",
  "Nxf7",
  "Nxn",
  "Nxnl1",
  "Nxnl2",
  "Nxpe2",
  "Nxpe3",
  "Nxpe4",
  "Nxpe5",
  "Nxph1",
  "Nxph2",
  "Nxph3",
  "Nxph4",
  "Nxt1",
  "Nxt2",
  "Nyap1",
  "Nyap2",
  "Nynrin",
  "Nyx",
  "Oacyl",
  "Oaf",
  "Oard1",
  "Oas1a",
  "Oas1c",
  "Oas1d",
  "Oas1e",
  "Oas1f",
  "Oas1g",
  "Oas1h",
  "Oas2",
  "Oas3",
  "Oasl1",
  "Oasl2",
  "Oat",
  "Oaz1",
  "Oaz2",
  "Oaz3",
  "Obox3",
  "Obox5",
  "Obox6",
  "Obox7",
  "Obox8",
  "Obp1a",
  "Obp1b",
  "Obp2a",
  "Obp2b",
  "Obscn",
  "Obsl1",
  "Oc90",
  "Oca2",
  "Ocel1",
  "Ociad1",
  "Ociad2",
  "Ocln",
  "Ocm",
  "Ocrl",
  "Ocstamp",
  "Odam",
  "Odaph",
  "Odc1",
  "Odf1",
  "Odf2",
  "Odf2l",
  "Odf3",
  "Odf3b",
  "Odf3l1",
  "Odf3l2",
  "Odf4",
  "Odr4",
  "Ofcc1",
  "Ofd1",
  "Oga",
  "Ogdh",
  "Ogdhl",
  "Ogfod1",
  "Ogfod2",
  "Ogfod3",
  "Ogfr",
  "Ogfrl1",
  "Ogg1",
  "Ogn",
  "Ogt",
  "Oip5",
  "Oip5os1",
  "Oit1",
  "Oit3",
  "Ola1",
  "Olah",
  "Olfm1",
  "Olfm2",
  "Olfm3",
  "Olfm4",
  "Olfm5",
  "Olfml1",
  "Olfml2a",
  "Olfml2b",
  "Olfml3",
  "Olfr1",
  "Olfr10",
  "Olfr1000",
  "Olfr1002",
  "Olfr1006",
  "Olfr1008",
  "Olfr1009",
  "Olfr101",
  "Olfr1012",
  "Olfr1013",
  "Olfr1014",
  "Olfr1015",
  "Olfr1016",
  "Olfr1018",
  "Olfr1019",
  "Olfr1020",
  "Olfr1022",
  "Olfr1023",
  "Olfr1024",
  "Olfr1025-ps1",
  "Olfr1026",
  "Olfr1028",
  "Olfr1029",
  "Olfr103",
  "Olfr1030",
  "Olfr1031",
  "Olfr1032",
  "Olfr1033",
  "Olfr1036",
  "Olfr1037",
  "Olfr1039",
  "Olfr104-ps",
  "Olfr1040",
  "Olfr1042",
  "Olfr1043",
  "Olfr1044",
  "Olfr1045",
  "Olfr1047",
  "Olfr1048",
  "Olfr105-ps",
  "Olfr1052",
  "Olfr1053",
  "Olfr1054",
  "Olfr1055",
  "Olfr1056",
  "Olfr1058",
  "Olfr106-ps",
  "Olfr1061",
  "Olfr1062",
  "Olfr107",
  "Olfr1076",
  "Olfr1079",
  "Olfr108",
  "Olfr1080",
  "Olfr1084",
  "Olfr1086",
  "Olfr1087",
  "Olfr1089",
  "Olfr109",
  "Olfr1090",
  "Olfr1093",
  "Olfr1094",
  "Olfr1099",
  "Olfr11",
  "Olfr110",
  "Olfr1100",
  "Olfr1101",
  "Olfr1102",
  "Olfr1104",
  "Olfr1105",
  "Olfr1106",
  "Olfr1107",
  "Olfr1109",
  "Olfr111",
  "Olfr1110",
  "Olfr1111",
  "Olfr1112",
  "Olfr112",
  "Olfr1120",
  "Olfr1121",
  "Olfr1122",
  "Olfr1123",
  "Olfr1124",
  "Olfr1126",
  "Olfr113",
  "Olfr1131",
  "Olfr1132",
  "Olfr1133",
  "Olfr1134",
  "Olfr1135",
  "Olfr1136",
  "Olfr1137",
  "Olfr1138",
  "Olfr114",
  "Olfr1140",
  "Olfr1141",
  "Olfr1143",
  "Olfr1145",
  "Olfr1148",
  "Olfr115",
  "Olfr1151",
  "Olfr1152",
  "Olfr1153",
  "Olfr1154",
  "Olfr1155",
  "Olfr1156",
  "Olfr1157",
  "Olfr1158",
  "Olfr116",
  "Olfr1160",
  "Olfr1161",
  "Olfr1162",
  "Olfr1163",
  "Olfr1164",
  "Olfr1166",
  "Olfr1168",
  "Olfr117",
  "Olfr1170",
  "Olfr1173",
  "Olfr1175",
  "Olfr1176",
  "Olfr1177-ps",
  "Olfr1178",
  "Olfr1179",
  "Olfr1180",
  "Olfr1181",
  "Olfr1183",
  "Olfr1184",
  "Olfr1186",
  "Olfr1188",
  "Olfr1189",
  "Olfr119",
  "Olfr1195",
  "Olfr1197",
  "Olfr1198",
  "Olfr12",
  "Olfr120",
  "Olfr1200",
  "Olfr1201",
  "Olfr1202",
  "Olfr1204",
  "Olfr1205",
  "Olfr1206",
  "Olfr1208",
  "Olfr1209",
  "Olfr121",
  "Olfr1211",
  "Olfr1212",
  "Olfr1213",
  "Olfr1214",
  "Olfr1215",
  "Olfr1216",
  "Olfr1217",
  "Olfr1218",
  "Olfr1219",
  "Olfr122",
  "Olfr1220",
  "Olfr1221",
  "Olfr1222",
  "Olfr1223",
  "Olfr1224-ps1",
  "Olfr1225",
  "Olfr1226",
  "Olfr1228",
  "Olfr1229",
  "Olfr123",
  "Olfr1230",
  "Olfr1231",
  "Olfr1232",
  "Olfr1233",
  "Olfr1234",
  "Olfr1238",
  "Olfr1239",
  "Olfr124",
  "Olfr1240",
  "Olfr1241",
  "Olfr1242",
  "Olfr1243",
  "Olfr1245",
  "Olfr1246",
  "Olfr1247",
  "Olfr1249",
  "Olfr125",
  "Olfr1250",
  "Olfr1251",
  "Olfr1252",
  "Olfr1253",
  "Olfr1254",
  "Olfr1255",
  "Olfr1256",
  "Olfr1257",
  "Olfr1258",
  "Olfr1259",
  "Olfr126",
  "Olfr1260",
  "Olfr1262",
  "Olfr1264",
  "Olfr1265",
  "Olfr127",
  "Olfr1270",
  "Olfr1271",
  "Olfr1275",
  "Olfr1276",
  "Olfr1277",
  "Olfr1278",
  "Olfr1279",
  "Olfr128",
  "Olfr1280",
  "Olfr1283",
  "Olfr1284",
  "Olfr1288",
  "Olfr1289",
  "Olfr129",
  "Olfr1290",
  "Olfr1293-ps",
  "Olfr1294",
  "Olfr1295",
  "Olfr1297",
  "Olfr1298",
  "Olfr1299",
  "Olfr13",
  "Olfr130",
  "Olfr1301",
  "Olfr1302",
  "Olfr1303",
  "Olfr1305",
  "Olfr1306",
  "Olfr1307",
  "Olfr1308",
  "Olfr1309",
  "Olfr131",
  "Olfr1310",
  "Olfr1311",
  "Olfr1312",
  "Olfr1313",
  "Olfr1314",
  "Olfr1317",
  "Olfr1318",
  "Olfr132",
  "Olfr1320",
  "Olfr1321",
  "Olfr1322",
  "Olfr1323",
  "Olfr1324",
  "Olfr1325",
  "Olfr1326-ps1",
  "Olfr1328",
  "Olfr1329",
  "Olfr133",
  "Olfr1330",
  "Olfr1333",
  "Olfr1335",
  "Olfr1336",
  "Olfr1337",
  "Olfr1338",
  "Olfr1339",
  "Olfr134",
  "Olfr1340",
  "Olfr1341",
  "Olfr1342",
  "Olfr1346",
  "Olfr1347",
  "Olfr1348",
  "Olfr1349",
  "Olfr135",
  "Olfr1350",
  "Olfr1351",
  "Olfr1352",
  "Olfr1353",
  "Olfr1354",
  "Olfr1356",
  "Olfr1357",
  "Olfr1358",
  "Olfr136",
  "Olfr1361",
  "Olfr1362",
  "Olfr1364",
  "Olfr1366",
  "Olfr1367",
  "Olfr1368",
  "Olfr1369-ps1",
  "Olfr137",
  "Olfr1370",
  "Olfr1375",
  "Olfr1377",
  "Olfr1378",
  "Olfr138",
  "Olfr1382",
  "Olfr1383",
  "Olfr1384",
  "Olfr1385",
  "Olfr1386",
  "Olfr1387",
  "Olfr1388",
  "Olfr1389",
  "Olfr139",
  "Olfr1390",
  "Olfr1391",
  "Olfr1393",
  "Olfr1394",
  "Olfr1395",
  "Olfr1396",
  "Olfr140",
  "Olfr1402",
  "Olfr1404",
  "Olfr1406",
  "Olfr1408",
  "Olfr141",
  "Olfr1410",
  "Olfr1411",
  "Olfr1412",
  "Olfr1413",
  "Olfr1414",
  "Olfr1415",
  "Olfr1416",
  "Olfr1417",
  "Olfr1418",
  "Olfr1419",
  "Olfr142",
  "Olfr1420",
  "Olfr1423",
  "Olfr1424",
  "Olfr1425",
  "Olfr1426",
  "Olfr1427",
  "Olfr1428",
  "Olfr143",
  "Olfr1431",
  "Olfr1434",
  "Olfr1436",
  "Olfr1440",
  "Olfr1441",
  "Olfr1442",
  "Olfr1443",
  "Olfr1444",
  "Olfr1445",
  "Olfr1446",
  "Olfr1447",
  "Olfr1448",
  "Olfr1449",
  "Olfr145",
  "Olfr1450",
  "Olfr1451",
  "Olfr1453",
  "Olfr1454",
  "Olfr1457",
  "Olfr1459",
  "Olfr146",
  "Olfr1461",
  "Olfr1462",
  "Olfr1463",
  "Olfr1465",
  "Olfr1466",
  "Olfr1467",
  "Olfr1469",
  "Olfr147",
  "Olfr1471",
  "Olfr1472",
  "Olfr1477",
  "Olfr148",
  "Olfr1487",
  "Olfr1489",
  "Olfr149",
  "Olfr1490",
  "Olfr1491",
  "Olfr1494",
  "Olfr1495",
  "Olfr1496",
  "Olfr1497",
  "Olfr1499",
  "Olfr15",
  "Olfr1500",
  "Olfr1501",
  "Olfr1502",
  "Olfr1504",
  "Olfr1505",
  "Olfr1507",
  "Olfr1508",
  "Olfr1509",
  "Olfr1510",
  "Olfr1511",
  "Olfr1512",
  "Olfr1513",
  "Olfr152",
  "Olfr153",
  "Olfr1532-ps1",
  "Olfr1535",
  "Olfr1537",
  "Olfr154",
  "Olfr155",
  "Olfr156",
  "Olfr1564",
  "Olfr157",
  "Olfr159",
  "Olfr16",
  "Olfr160",
  "Olfr161",
  "Olfr164",
  "Olfr165",
  "Olfr166",
  "Olfr167",
  "Olfr168",
  "Olfr169",
  "Olfr17",
  "Olfr171",
  "Olfr173",
  "Olfr175",
  "Olfr178",
  "Olfr18",
  "Olfr180",
  "Olfr181",
  "Olfr183",
  "Olfr186",
  "Olfr187",
  "Olfr19",
  "Olfr190",
  "Olfr191",
  "Olfr193",
  "Olfr194",
  "Olfr195",
  "Olfr196",
  "Olfr198",
  "Olfr199",
  "Olfr2",
  "Olfr202",
  "Olfr203",
  "Olfr204",
  "Olfr206",
  "Olfr209",
  "Olfr211",
  "Olfr212",
  "Olfr213",
  "Olfr214",
  "Olfr215",
  "Olfr218",
  "Olfr220",
  "Olfr221",
  "Olfr222",
  "Olfr223",
  "Olfr224",
  "Olfr228",
  "Olfr229",
  "Olfr23",
  "Olfr231",
  "Olfr235",
  "Olfr237",
  "Olfr24",
  "Olfr243",
  "Olfr248",
  "Olfr25",
  "Olfr250",
  "Olfr251",
  "Olfr259",
  "Olfr26",
  "Olfr262",
  "Olfr263",
  "Olfr266",
  "Olfr267",
  "Olfr270",
  "Olfr272",
  "Olfr273",
  "Olfr275",
  "Olfr279",
  "Olfr281",
  "Olfr282",
  "Olfr283",
  "Olfr284",
  "Olfr285",
  "Olfr286",
  "Olfr292",
  "Olfr293",
  "Olfr294",
  "Olfr295",
  "Olfr297",
  "Olfr298",
  "Olfr299",
  "Olfr3",
  "Olfr30",
  "Olfr301",
  "Olfr303",
  "Olfr304",
  "Olfr305",
  "Olfr307",
  "Olfr308",
  "Olfr309",
  "Olfr31",
  "Olfr310",
  "Olfr311",
  "Olfr312",
  "Olfr314",
  "Olfr315",
  "Olfr316",
  "Olfr317",
  "Olfr318",
  "Olfr319",
  "Olfr32",
  "Olfr320",
  "Olfr322",
  "Olfr323",
  "Olfr324",
  "Olfr325",
  "Olfr328",
  "Olfr329",
  "Olfr33",
  "Olfr330",
  "Olfr331",
  "Olfr332",
  "Olfr338",
  "Olfr339",
  "Olfr340",
  "Olfr341",
  "Olfr342",
  "Olfr344",
  "Olfr345",
  "Olfr347",
  "Olfr348",
  "Olfr350",
  "Olfr351",
  "Olfr352",
  "Olfr353",
  "Olfr354",
  "Olfr355",
  "Olfr356",
  "Olfr357",
  "Olfr358",
  "Olfr360",
  "Olfr361",
  "Olfr362",
  "Olfr364-ps1",
  "Olfr365",
  "Olfr366",
  "Olfr368",
  "Olfr370",
  "Olfr371",
  "Olfr372",
  "Olfr373",
  "Olfr374",
  "Olfr376",
  "Olfr378",
  "Olfr38",
  "Olfr381",
  "Olfr382",
  "Olfr384",
  "Olfr385",
  "Olfr389",
  "Olfr39",
  "Olfr390",
  "Olfr391",
  "Olfr392",
  "Olfr393",
  "Olfr394",
  "Olfr395",
  "Olfr397",
  "Olfr398",
  "Olfr399",
  "Olfr401",
  "Olfr402",
  "Olfr406",
  "Olfr411",
  "Olfr412",
  "Olfr414",
  "Olfr417",
  "Olfr418",
  "Olfr419",
  "Olfr420",
  "Olfr424",
  "Olfr427",
  "Olfr429",
  "Olfr43",
  "Olfr430",
  "Olfr432",
  "Olfr433",
  "Olfr434",
  "Olfr435",
  "Olfr437",
  "Olfr44",
  "Olfr441",
  "Olfr444",
  "Olfr446",
  "Olfr447",
  "Olfr448",
  "Olfr449",
  "Olfr45",
  "Olfr450",
  "Olfr452",
  "Olfr453",
  "Olfr455",
  "Olfr458",
  "Olfr459",
  "Olfr46",
  "Olfr460",
  "Olfr461",
  "Olfr462",
  "Olfr463",
  "Olfr464",
  "Olfr466",
  "Olfr467",
  "Olfr47",
  "Olfr470",
  "Olfr472",
  "Olfr473",
  "Olfr474",
  "Olfr478",
  "Olfr479",
  "Olfr48",
  "Olfr480",
  "Olfr481",
  "Olfr482",
  "Olfr483",
  "Olfr484",
  "Olfr485",
  "Olfr486",
  "Olfr488",
  "Olfr49",
  "Olfr490",
  "Olfr491",
  "Olfr492",
  "Olfr493",
  "Olfr494",
  "Olfr495",
  "Olfr497",
  "Olfr498",
  "Olfr5",
  "Olfr50",
  "Olfr502",
  "Olfr503",
  "Olfr506",
  "Olfr507",
  "Olfr508",
  "Olfr509",
  "Olfr51",
  "Olfr510",
  "Olfr512",
  "Olfr513",
  "Olfr514",
  "Olfr516",
  "Olfr517",
  "Olfr518",
  "Olfr519",
  "Olfr52",
  "Olfr520",
  "Olfr521",
  "Olfr522",
  "Olfr523",
  "Olfr524",
  "Olfr525",
  "Olfr527",
  "Olfr53",
  "Olfr530",
  "Olfr531",
  "Olfr532",
  "Olfr533",
  "Olfr535",
  "Olfr536",
  "Olfr539",
  "Olfr54",
  "Olfr541",
  "Olfr543",
  "Olfr544",
  "Olfr545",
  "Olfr547",
  "Olfr549",
  "Olfr550",
  "Olfr551",
  "Olfr552",
  "Olfr553",
  "Olfr554",
  "Olfr555",
  "Olfr556",
  "Olfr557",
  "Olfr558",
  "Olfr559",
  "Olfr56",
  "Olfr560",
  "Olfr561",
  "Olfr564",
  "Olfr566",
  "Olfr568",
  "Olfr569",
  "Olfr57",
  "Olfr570",
  "Olfr571",
  "Olfr572",
  "Olfr573-ps1",
  "Olfr574",
  "Olfr575",
  "Olfr576",
  "Olfr577",
  "Olfr578",
  "Olfr58",
  "Olfr582",
  "Olfr583",
  "Olfr585",
  "Olfr586",
  "Olfr589",
  "Olfr59",
  "Olfr591",
  "Olfr592",
  "Olfr593",
  "Olfr594",
  "Olfr599",
  "Olfr6",
  "Olfr60",
  "Olfr600",
  "Olfr601",
  "Olfr605",
  "Olfr606",
  "Olfr607",
  "Olfr608",
  "Olfr609",
  "Olfr61",
  "Olfr611",
  "Olfr612",
  "Olfr613",
  "Olfr615",
  "Olfr616",
  "Olfr617",
  "Olfr618",
  "Olfr619",
  "Olfr62",
  "Olfr620",
  "Olfr622",
  "Olfr623",
  "Olfr624",
  "Olfr625-ps1",
  "Olfr629",
  "Olfr63",
  "Olfr630",
  "Olfr631",
  "Olfr632",
  "Olfr633",
  "Olfr635",
  "Olfr638",
  "Olfr64",
  "Olfr640",
  "Olfr641",
  "Olfr642",
  "Olfr644",
  "Olfr645",
  "Olfr646",
  "Olfr648",
  "Olfr649",
  "Olfr65",
  "Olfr651",
  "Olfr652",
  "Olfr653",
  "Olfr654",
  "Olfr655",
  "Olfr656",
  "Olfr658",
  "Olfr659",
  "Olfr66",
  "Olfr661",
  "Olfr665",
  "Olfr666",
  "Olfr667",
  "Olfr669",
  "Olfr67",
  "Olfr670",
  "Olfr671",
  "Olfr672",
  "Olfr675",
  "Olfr676",
  "Olfr677",
  "Olfr678",
  "Olfr683",
  "Olfr684",
  "Olfr685",
  "Olfr686",
  "Olfr689",
  "Olfr69",
  "Olfr690",
  "Olfr691",
  "Olfr692",
  "Olfr693",
  "Olfr694",
  "Olfr695",
  "Olfr697",
  "Olfr698",
  "Olfr699",
  "Olfr70",
  "Olfr700",
  "Olfr701",
  "Olfr702",
  "Olfr703",
  "Olfr704",
  "Olfr705",
  "Olfr706",
  "Olfr707",
  "Olfr71",
  "Olfr710",
  "Olfr711",
  "Olfr713",
  "Olfr714",
  "Olfr715",
  "Olfr716",
  "Olfr720",
  "Olfr722",
  "Olfr723",
  "Olfr725",
  "Olfr726",
  "Olfr727",
  "Olfr728",
  "Olfr729",
  "Olfr73",
  "Olfr730",
  "Olfr731",
  "Olfr732",
  "Olfr733",
  "Olfr734",
  "Olfr735",
  "Olfr736",
  "Olfr738",
  "Olfr739",
  "Olfr74",
  "Olfr740",
  "Olfr741",
  "Olfr742",
  "Olfr743",
  "Olfr744",
  "Olfr745",
  "Olfr746",
  "Olfr747",
  "Olfr748",
  "Olfr749",
  "Olfr750",
  "Olfr76",
  "Olfr761",
  "Olfr763",
  "Olfr764-ps1",
  "Olfr766-ps1",
  "Olfr767",
  "Olfr768",
  "Olfr769",
  "Olfr77",
  "Olfr770",
  "Olfr771",
  "Olfr773",
  "Olfr774",
  "Olfr775",
  "Olfr78",
  "Olfr780",
  "Olfr781",
  "Olfr782",
  "Olfr784",
  "Olfr785",
  "Olfr786",
  "Olfr787",
  "Olfr788",
  "Olfr790",
  "Olfr791",
  "Olfr792",
  "Olfr794",
  "Olfr796",
  "Olfr798",
  "Olfr799",
  "Olfr8",
  "Olfr801",
  "Olfr802",
  "Olfr803",
  "Olfr804",
  "Olfr805",
  "Olfr806",
  "Olfr807",
  "Olfr808",
  "Olfr809",
  "Olfr810",
  "Olfr811",
  "Olfr812",
  "Olfr813",
  "Olfr815",
  "Olfr816",
  "Olfr818",
  "Olfr820",
  "Olfr821",
  "Olfr822",
  "Olfr825",
  "Olfr826",
  "Olfr827",
  "Olfr828",
  "Olfr829",
  "Olfr830",
  "Olfr832",
  "Olfr834",
  "Olfr835",
  "Olfr836",
  "Olfr837",
  "Olfr843",
  "Olfr844",
  "Olfr845",
  "Olfr846",
  "Olfr847",
  "Olfr849",
  "Olfr850",
  "Olfr851",
  "Olfr853",
  "Olfr854",
  "Olfr855",
  "Olfr857",
  "Olfr859",
  "Olfr860",
  "Olfr862",
  "Olfr866",
  "Olfr867",
  "Olfr868",
  "Olfr869",
  "Olfr870",
  "Olfr872",
  "Olfr873",
  "Olfr874",
  "Olfr875",
  "Olfr876",
  "Olfr877",
  "Olfr878",
  "Olfr881",
  "Olfr883",
  "Olfr884",
  "Olfr885",
  "Olfr887",
  "Olfr888",
  "Olfr889",
  "Olfr891",
  "Olfr893",
  "Olfr894",
  "Olfr895",
  "Olfr898",
  "Olfr9",
  "Olfr90",
  "Olfr901",
  "Olfr902",
  "Olfr904",
  "Olfr905",
  "Olfr906",
  "Olfr907",
  "Olfr91",
  "Olfr910",
  "Olfr911-ps1",
  "Olfr913",
  "Olfr914",
  "Olfr916",
  "Olfr917",
  "Olfr918",
  "Olfr92",
  "Olfr920",
  "Olfr921",
  "Olfr922",
  "Olfr923",
  "Olfr924",
  "Olfr926",
  "Olfr93",
  "Olfr930",
  "Olfr933",
  "Olfr934",
  "Olfr935",
  "Olfr937",
  "Olfr938",
  "Olfr94",
  "Olfr943",
  "Olfr944",
  "Olfr945",
  "Olfr948",
  "Olfr95",
  "Olfr951",
  "Olfr952",
  "Olfr954",
  "Olfr955",
  "Olfr957",
  "Olfr958",
  "Olfr959",
  "Olfr96",
  "Olfr960",
  "Olfr961",
  "Olfr963",
  "Olfr965",
  "Olfr967",
  "Olfr968",
  "Olfr969",
  "Olfr97",
  "Olfr970",
  "Olfr971",
  "Olfr972",
  "Olfr974",
  "Olfr975",
  "Olfr976",
  "Olfr978",
  "Olfr979",
  "Olfr98",
  "Olfr980",
  "Olfr981",
  "Olfr982",
  "Olfr983",
  "Olfr984",
  "Olfr985",
  "Olfr986",
  "Olfr987",
  "Olfr988",
  "Olfr99",
  "Olfr992",
  "Olfr993",
  "Olfr994",
  "Olfr995",
  "Olfr996",
  "Olfr998",
  "Olig1",
  "Olig2",
  "Olig3",
  "Olr1",
  "Oma1",
  "Omd",
  "Omg",
  "Omp",
  "Omt2a",
  "Omt2b",
  "Onecut1",
  "Onecut2",
  "Onecut3",
  "Ooep",
  "Oog2",
  "Oog3",
  "Oog4",
  "Oosp1",
  "Oosp2",
  "Oosp3",
  "Opa1",
  "Opa3",
  "Opalin",
  "Opcml",
  "Ophn1",
  "Oplah",
  "Opn1mw",
  "Opn1sw",
  "Opn3",
  "Opn4",
  "Opn5",
  "Oprd1",
  "Oprk1",
  "Oprl1",
  "Oprm1",
  "Optc",
  "Optn",
  "Orai1",
  "Orai2",
  "Orai3",
  "Orc1",
  "Orc2",
  "Orc3",
  "Orc4",
  "Orc5",
  "Orc6",
  "Orm1",
  "Orm2",
  "Orm3",
  "Ormdl1",
  "Ormdl2",
  "Ormdl3",
  "Os9",
  "Osbp",
  "Osbp2",
  "Osbpl10",
  "Osbpl11",
  "Osbpl1a",
  "Osbpl2",
  "Osbpl3",
  "Osbpl5",
  "Osbpl6",
  "Osbpl7",
  "Osbpl8",
  "Oscar",
  "Oscp1",
  "Oser1",
  "Osgep",
  "Osgepl1",
  "Osgin1",
  "Osgin2",
  "Osm",
  "Osmr",
  "Osr1",
  "Osr2",
  "Ost4",
  "Ostc",
  "Ostf1",
  "Ostm1",
  "Ostn",
  "Otc",
  "Otoa",
  "Otof",
  "Otog",
  "Otogl",
  "Otol1",
  "Otop1",
  "Otop2",
  "Otop3",
  "Otor",
  "Otos",
  "Otp",
  "Otub1",
  "Otub2",
  "Otud1",
  "Otud3",
  "Otud4",
  "Otud5",
  "Otud6a",
  "Otud6b",
  "Otud7a",
  "Otud7b",
  "Otulin",
  "Otulinl",
  "Otx1",
  "Otx2",
  "Otx2os1",
  "Ovca2",
  "Ovch2",
  "Ovgp1",
  "Ovol1",
  "Ovol3",
  "Oxa1l",
  "Oxct1",
  "Oxct2b",
  "Oxgr1",
  "Oxld1",
  "Oxnad1",
  "Oxr1",
  "Oxsm",
  "Oxsr1",
  "Oxt",
  "Oxtr",
  "P2rx1",
  "P2rx2",
  "P2rx3",
  "P2rx4",
  "P2rx5",
  "P2rx6",
  "P2rx7",
  "P2ry1",
  "P2ry10",
  "P2ry10b",
  "P2ry12",
  "P2ry13",
  "P2ry14",
  "P2ry2",
  "P2ry4",
  "P2ry6",
  "P3h1",
  "P3h2",
  "P3h3",
  "P3h4",
  "P4ha1",
  "P4ha2",
  "P4ha3",
  "P4hb",
  "P4htm",
  "Pa2g4",
  "Pabpc1l",
  "Pabpc2",
  "Pabpc4l",
  "Pabpc5",
  "Pabpc6",
  "Pabpn1l",
  "Pacc1",
  "Pacrg",
  "Pacs1",
  "Pacs2",
  "Pacsin1",
  "Pacsin2",
  "Pacsin3",
  "Padi1",
  "Padi2",
  "Padi3",
  "Padi4",
  "Padi6",
  "Paf1",
  "Pafah1b1",
  "Pafah1b2",
  "Pafah1b3",
  "Pag1",
  "Pah",
  "Paics",
  "Paip1",
  "Paip2",
  "Paip2b",
  "Pak1",
  "Pak1ip1",
  "Pak2",
  "Pak3",
  "Pak6",
  "Pak7",
  "Pakap",
  "Palb2",
  "Pald1",
  "Palld",
  "Palm",
  "Palm3",
  "Palmd",
  "Pam",
  "Pam16",
  "Pamr1",
  "Pan2",
  "Pan3",
  "Panct2",
  "Pank1",
  "Pank2",
  "Pank3",
  "Pank4",
  "Pantr1",
  "Panx1",
  "Panx2",
  "Panx3",
  "Paox",
  "Papln",
  "Papola",
  "Papolb",
  "Papolg",
  "Pappa",
  "Pappa2",
  "Papss1",
  "Papss2",
  "Paqr3",
  "Paqr4",
  "Paqr5",
  "Paqr6",
  "Paqr7",
  "Paqr8",
  "Paqr9",
  "Pard3",
  "Pard3b",
  "Pard3bos1",
  "Pard6a",
  "Pard6b",
  "Pard6g",
  "Parg",
  "Park7",
  "Parl",
  "Parm1",
  "Parn",
  "Parp1",
  "Parp10",
  "Parp11",
  "Parp12",
  "Parp14",
  "Parp16",
  "Parp2",
  "Parp3",
  "Parp4",
  "Parp6",
  "Parp8",
  "Parp9",
  "Parpbp",
  "Pars2",
  "Parva",
  "Parvb",
  "Parvg",
  "Pask",
  "Pate11",
  "Pate12",
  "Pate13",
  "Pate2",
  "Pate4",
  "Pate5",
  "Pate6",
  "Pate9",
  "Patj",
  "Patl1",
  "Patl2",
  "Patz1",
  "Pax1",
  "Pax2",
  "Pax3",
  "Pax4",
  "Pax5",
  "Pax6",
  "Pax6os1",
  "Pax7",
  "Pax8",
  "Pax9",
  "Paxbp1",
  "Paxip1",
  "Paxx",
  "Pbdc1",
  "Pbk",
  "Pbld1",
  "Pbld2",
  "Pbp2",
  "Pbrm1",
  "Pbsn",
  "Pbx1",
  "Pbx2",
  "Pbx3",
  "Pbx4",
  "Pbxip1",
  "Pcare",
  "Pcbd1",
  "Pcbd2",
  "Pcbp1",
  "Pcbp2",
  "Pcbp3",
  "Pcbp4",
  "Pcca",
  "Pccb",
  "Pcdh1",
  "Pcdh10",
  "Pcdh11x",
  "Pcdh12",
  "Pcdh15",
  "Pcdh17",
  "Pcdh18",
  "Pcdh19",
  "Pcdh20",
  "Pcdh7",
  "Pcdh8",
  "Pcdh9",
  "Pcdha11",
  "Pcdha12",
  "Pcdha8",
  "Pcdha9",
  "Pcdhac1",
  "Pcdhac2",
  "Pcdhb1",
  "Pcdhb10",
  "Pcdhb11",
  "Pcdhb12",
  "Pcdhb13",
  "Pcdhb14",
  "Pcdhb15",
  "Pcdhb16",
  "Pcdhb17",
  "Pcdhb18",
  "Pcdhb19",
  "Pcdhb2",
  "Pcdhb20",
  "Pcdhb21",
  "Pcdhb22",
  "Pcdhb3",
  "Pcdhb4",
  "Pcdhb5",
  "Pcdhb6",
  "Pcdhb7",
  "Pcdhb8",
  "Pcdhb9",
  "Pcdhga1",
  "Pcdhga10",
  "Pcdhga5",
  "Pcdhgc5",
  "Pced1a",
  "Pced1b",
  "Pcf11",
  "Pcgf1",
  "Pcgf2",
  "Pcgf3",
  "Pcgf5",
  "Pcgf6",
  "Pcid2",
  "Pcif1",
  "Pck1",
  "Pck2",
  "Pclaf",
  "Pclo",
  "Pcm1",
  "Pcmt1",
  "Pcmtd1",
  "Pcmtd2",
  "Pcna",
  "Pcnp",
  "Pcnt",
  "Pcnx",
  "Pcnx2",
  "Pcnx3",
  "Pcnx4",
  "Pcolce",
  "Pcolce2",
  "Pcp2",
  "Pcp4",
  "Pcp4l1",
  "Pcsk1",
  "Pcsk1n",
  "Pcsk2",
  "Pcsk4",
  "Pcsk5",
  "Pcsk6",
  "Pcsk7",
  "Pcsk9",
  "Pctp",
  "Pcx",
  "Pcyox1",
  "Pcyox1l",
  "Pcyt1a",
  "Pcyt1b",
  "Pcyt2",
  "Pdap1",
  "Pdc",
  "Pdcd1",
  "Pdcd10",
  "Pdcd11",
  "Pdcd1lg2",
  "Pdcd2",
  "Pdcd2l",
  "Pdcd5",
  "Pdcd6",
  "Pdcd6ip",
  "Pdcd7",
  "Pdcl",
  "Pdcl2",
  "Pdcl3",
  "Pde10a",
  "Pde11a",
  "Pde12",
  "Pde1a",
  "Pde1b",
  "Pde1c",
  "Pde3a",
  "Pde3b",
  "Pde4a",
  "Pde4b",
  "Pde4c",
  "Pde4d",
  "Pde4dip",
  "Pde5a",
  "Pde6a",
  "Pde6b",
  "Pde6c",
  "Pde6d",
  "Pde6g",
  "Pde6h",
  "Pde7a",
  "Pde7b",
  "Pde8a",
  "Pde8b",
  "Pde9a",
  "Pdgfa",
  "Pdgfb",
  "Pdgfc",
  "Pdgfd",
  "Pdgfra",
  "Pdgfrb",
  "Pdgfrl",
  "Pdha1",
  "Pdha2",
  "Pdhb",
  "Pdhx",
  "Pdia2",
  "Pdia3",
  "Pdia4",
  "Pdia5",
  "Pdia6",
  "Pdik1l",
  "Pdilt",
  "Pdk1",
  "Pdk2",
  "Pdk3",
  "Pdk4",
  "Pdlim1",
  "Pdlim2",
  "Pdlim3",
  "Pdlim4",
  "Pdlim5",
  "Pdlim7",
  "Pdp1",
  "Pdp2",
  "Pdpk1",
  "Pdpn",
  "Pdpr",
  "Pdrg1",
  "Pds5a",
  "Pds5b",
  "Pdss1",
  "Pdss2",
  "Pdx1",
  "Pdxdc1",
  "Pdxk",
  "Pdxp",
  "Pdyn",
  "Pdzd11",
  "Pdzd2",
  "Pdzd3",
  "Pdzd4",
  "Pdzd7",
  "Pdzd8",
  "Pdzd9",
  "Pdzk1",
  "Pdzk1ip1",
  "Pdzph1",
  "Pdzrn4",
  "Pea15a",
  "Peak1",
  "Pear1",
  "Pebp1",
  "Pebp4",
  "Pecam1",
  "Pecr",
  "Pef1",
  "Peg10",
  "Peg12",
  "Peg13",
  "Peg3",
  "Peli1",
  "Peli2",
  "Peli3",
  "Pelp1",
  "Pemt",
  "Penk",
  "Pepd",
  "Per1",
  "Per2",
  "Per3",
  "Perm1",
  "Pes1",
  "Pex1",
  "Pex10",
  "Pex11a",
  "Pex11b",
  "Pex11g",
  "Pex12",
  "Pex13",
  "Pex14",
  "Pex16",
  "Pex19",
  "Pex2",
  "Pex26",
  "Pex3",
  "Pex5",
  "Pex5l",
  "Pex6",
  "Pex7",
  "Pf4",
  "Pfas",
  "Pfdn1",
  "Pfdn2",
  "Pfdn4",
  "Pfdn6",
  "Pfkfb1",
  "Pfkfb2",
  "Pfkfb3",
  "Pfkfb4",
  "Pfkl",
  "Pfkm",
  "Pfkp",
  "Pfn1",
  "Pfn2",
  "Pfn3",
  "Pfn4",
  "Pfn5",
  "Pfpl",
  "Pga5",
  "Pgam1",
  "Pgam2",
  "Pgam5",
  "Pgap1",
  "Pgap2",
  "Pgbd5",
  "Pgc",
  "Pgd",
  "Pgf",
  "Pgghg",
  "Pggt1b",
  "Pgk1",
  "Pgk2",
  "Pgls",
  "Pglyrp1",
  "Pglyrp2",
  "Pglyrp3",
  "Pglyrp4",
  "Pgm1",
  "Pgm2",
  "Pgm2l1",
  "Pgm3",
  "Pgm5",
  "Pgp",
  "Pgpep1",
  "Pgpep1l",
  "Pgr",
  "Pgr15l",
  "Pgrmc1",
  "Pgrmc2",
  "Pgs1",
  "Phactr1",
  "Phactr2",
  "Phactr3",
  "Phactr4",
  "Phax",
  "Phb",
  "Phb2",
  "Phc1",
  "Phc2",
  "Phc3",
  "Pheta1",
  "Pheta2",
  "Phex",
  "Phf1",
  "Phf10",
  "Phf11",
  "Phf11a",
  "Phf11b",
  "Phf11c",
  "Phf11d",
  "Phf12",
  "Phf13",
  "Phf14",
  "Phf19",
  "Phf2",
  "Phf20",
  "Phf20l1",
  "Phf21a",
  "Phf21b",
  "Phf23",
  "Phf24",
  "Phf2os1",
  "Phf3",
  "Phf5a",
  "Phf6",
  "Phf7",
  "Phf8",
  "Phgdh",
  "Phgr1",
  "Phip",
  "Phka1",
  "Phka2",
  "Phkb",
  "Phkg1",
  "Phkg2",
  "Phlda1",
  "Phlda2",
  "Phlda3",
  "Phldb1",
  "Phldb2",
  "Phldb3",
  "Phlpp1",
  "Phlpp2",
  "Phospho2",
  "Phox2b",
  "Phpt1",
  "Phrf1",
  "Phtf1",
  "Phtf1os",
  "Phtf2",
  "Phxr2",
  "Phyh",
  "Phyhd1",
  "Phyhip",
  "Phyhipl",
  "Phykpl",
  "Pi15",
  "Pi16",
  "Pi4k2a",
  "Pi4k2b",
  "Pi4ka",
  "Pi4kb",
  "Pianp",
  "Pias1",
  "Pias2",
  "Pias3",
  "Pias4",
  "Pibf1",
  "Picalm",
  "Pick1",
  "Pid1",
  "Pidd1",
  "Piezo1",
  "Piezo2",
  "Pif1",
  "Pifo",
  "Piga",
  "Pigb",
  "Pigbos1",
  "Pigc",
  "Pigf",
  "Pigg",
  "Pigh",
  "Pigk",
  "Pigl",
  "Pigm",
  "Pign",
  "Pigo",
  "Pigp",
  "Pigq",
  "Pigr",
  "Pigs",
  "Pigt",
  "Pigu",
  "Pigv",
  "Pigw",
  "Pigx",
  "Pigyl",
  "Pigz",
  "Pih1d1",
  "Pih1d2",
  "Pih1d3",
  "Pih1h3b",
  "Pik3ap1",
  "Pik3c2a",
  "Pik3c2b",
  "Pik3c2g",
  "Pik3c3",
  "Pik3ca",
  "Pik3cb",
  "Pik3cd",
  "Pik3cg",
  "Pik3ip1",
  "Pik3r1",
  "Pik3r2",
  "Pik3r3",
  "Pik3r4",
  "Pik3r6",
  "Pikfyve",
  "Pilra",
  "Pilrb1",
  "Pilrb2",
  "Pim1",
  "Pim2",
  "Pim3",
  "Pimreg",
  "Pin1",
  "Pin1rt1",
  "Pinc",
  "Pink1",
  "Pinlyp",
  "Pinx1",
  "Pip",
  "Pip4k2a",
  "Pip4k2b",
  "Pip4k2c",
  "Pip4p1",
  "Pip4p2",
  "Pip5k1a",
  "Pip5k1b",
  "Pip5k1c",
  "Pip5kl1",
  "Pipox",
  "Pir",
  "Pirb",
  "Pirt",
  "Pisd",
  "Pithd1",
  "Pitpna",
  "Pitpnb",
  "Pitpnc1",
  "Pitpnm1",
  "Pitpnm2",
  "Pitpnm2os1",
  "Pitpnm3",
  "Pitrm1",
  "Pitx1",
  "Pitx2",
  "Pitx3",
  "Piwil1",
  "Piwil2",
  "Piwil4",
  "Pja1",
  "Pja2",
  "Pjvk",
  "Pkd1",
  "Pkd1l1",
  "Pkd1l2",
  "Pkd1l3",
  "Pkd2",
  "Pkd2l1",
  "Pkd2l2",
  "Pkdcc",
  "Pkdrej",
  "Pkhd1",
  "Pkhd1l1",
  "Pkia",
  "Pkib",
  "Pkig",
  "Pklr",
  "Pkm",
  "Pkmyt1",
  "Pkn1",
  "Pkn2",
  "Pkn3",
  "Pknox1",
  "Pknox2",
  "Pkp1",
  "Pkp2",
  "Pkp4",
  "Pla1a",
  "Pla2g10",
  "Pla2g12a",
  "Pla2g12b",
  "Pla2g15",
  "Pla2g1b",
  "Pla2g2c",
  "Pla2g2d",
  "Pla2g2e",
  "Pla2g2f",
  "Pla2g3",
  "Pla2g4a",
  "Pla2g4c",
  "Pla2g4d",
  "Pla2g4e",
  "Pla2g4f",
  "Pla2g5",
  "Pla2g6",
  "Pla2g7",
  "Pla2r1",
  "Plaa",
  "Plaat1",
  "Plaat3",
  "Plaat5",
  "Plac1",
  "Plac8",
  "Plac8l1",
  "Plag1",
  "Plagl1",
  "Plagl2",
  "Plat",
  "Platr10",
  "Platr14",
  "Platr20",
  "Platr21",
  "Platr22",
  "Platr23",
  "Platr25",
  "Platr26",
  "Platr28",
  "Platr29",
  "Platr4",
  "Platr5",
  "Platr6",
  "Platr8",
  "Plau",
  "Plaur",
  "Plb1",
  "Plbd1",
  "Plbd2",
  "Plcb1",
  "Plcb2",
  "Plcb3",
  "Plcb4",
  "Plcd1",
  "Plcd3",
  "Plcd4",
  "Plce1",
  "Plcg1",
  "Plcg2",
  "Plch1",
  "Plch2",
  "Plcl1",
  "Plcl2",
  "Plcxd1",
  "Plcxd2",
  "Plcxd3",
  "Plcz1",
  "Pld1",
  "Pld2",
  "Pld3",
  "Pld4",
  "Pld5",
  "Pld6",
  "Pldi",
  "Plec",
  "Plek",
  "Plek2",
  "Plekha1",
  "Plekha2",
  "Plekha3",
  "Plekha4",
  "Plekha5",
  "Plekha6",
  "Plekha7",
  "Plekha8",
  "Plekhb1",
  "Plekhb2",
  "Plekhd1",
  "Plekhd1os",
  "Plekhf1",
  "Plekhf2",
  "Plekhg1",
  "Plekhg2",
  "Plekhg4",
  "Plekhg5",
  "Plekhg6",
  "Plekhh1",
  "Plekhh2",
  "Plekhh3",
  "Plekhj1",
  "Plekhm1",
  "Plekhm2",
  "Plekhm3",
  "Plekhn1",
  "Plekho1",
  "Plekho2",
  "Plekhs1",
  "Plet1",
  "Plg",
  "Plgrkt",
  "Plin1",
  "Plin2",
  "Plin3",
  "Plin4",
  "Plin5",
  "Plk1",
  "Plk2",
  "Plk3",
  "Plk4",
  "Plk5",
  "Pllp",
  "Pln",
  "Plod1",
  "Plod2",
  "Plod3",
  "Plp1",
  "Plpbp",
  "Plpp1",
  "Plpp2",
  "Plpp3",
  "Plpp4",
  "Plpp5",
  "Plpp6",
  "Plpp7",
  "Plppr1",
  "Plppr2",
  "Plppr3",
  "Plppr4",
  "Plppr5",
  "Plrg1",
  "Pls1",
  "Pls3",
  "Plscr1",
  "Plscr2",
  "Plscr3",
  "Plscr4",
  "Plscr5",
  "Pltp",
  "Plut",
  "Plvap",
  "Plxdc1",
  "Plxdc2",
  "Plxna1",
  "Plxna2",
  "Plxna3",
  "Plxna4",
  "Plxna4os1",
  "Plxnb1",
  "Plxnb2",
  "Plxnb3",
  "Plxnc1",
  "Plxnd1",
  "Pm20d1",
  "Pm20d2",
  "Pmaip1",
  "Pmch",
  "Pmel",
  "Pmepa1",
  "Pmf1",
  "Pmfbp1",
  "Pmis2",
  "Pml",
  "Pmm1",
  "Pmm2",
  "Pmp22",
  "Pmpca",
  "Pmpcb",
  "Pms1",
  "Pms2",
  "Pmvk",
  "Pnck",
  "Pnisr",
  "Pnkd",
  "Pnkp",
  "Pnldc1",
  "Pnlip",
  "Pnliprp1",
  "Pnliprp2",
  "Pnma1",
  "Pnma2",
  "Pnma3",
  "Pnma5",
  "Pnmal1",
  "Pnmal2",
  "Pnmt",
  "Pnn",
  "Pno1",
  "Pnoc",
  "Pnp",
  "Pnp2",
  "Pnpla1",
  "Pnpla2",
  "Pnpla5",
  "Pnpla6",
  "Pnpla7",
  "Pnpla8",
  "Pnpo",
  "Pnpt1",
  "Pnrc1",
  "Pnrc2",
  "Poc1a",
  "Poc1b",
  "Poc5",
  "Podn",
  "Podnl1",
  "Podxl",
  "Podxl2",
  "Pof1b",
  "Pofut1",
  "Pofut2",
  "Pogk",
  "Poglut1",
  "Poglut2",
  "Poglut3",
  "Pogz",
  "Pola1",
  "Pola2",
  "Polb",
  "Pold1",
  "Pold2",
  "Pold3",
  "Poldip2",
  "Poldip3",
  "Pole",
  "Pole2",
  "Pole3",
  "Polg",
  "Polg2",
  "Polh",
  "Poli",
  "Polk",
  "Poll",
  "Polm",
  "Poln",
  "Polq",
  "Polr1a",
  "Polr1b",
  "Polr1c",
  "Polr1d",
  "Polr1e",
  "Polr2a",
  "Polr2b",
  "Polr2c",
  "Polr2d",
  "Polr2e",
  "Polr2f",
  "Polr2g",
  "Polr2h",
  "Polr2i",
  "Polr2j",
  "Polr2k",
  "Polr2l",
  "Polr2m",
  "Polr3a",
  "Polr3b",
  "Polr3c",
  "Polr3d",
  "Polr3e",
  "Polr3f",
  "Polr3g",
  "Polr3gl",
  "Polr3h",
  "Polr3k",
  "Polrmt",
  "Pom121",
  "Pom121l12",
  "Pom121l2",
  "Pomc",
  "Pomgnt1",
  "Pomgnt2",
  "Pomk",
  "Pomp",
  "Pomt1",
  "Pomt2",
  "Pon1",
  "Pon2",
  "Pon3",
  "Pop1",
  "Pop4",
  "Pop5",
  "Pop7",
  "Popdc2",
  "Popdc3",
  "Por",
  "Porcn",
  "Postn",
  "Pot1a",
  "Pot1b",
  "Potegl",
  "Pou1f1",
  "Pou2af1",
  "Pou2f1",
  "Pou2f2",
  "Pou2f3",
  "Pou3f1",
  "Pou3f2",
  "Pou3f3",
  "Pou3f4",
  "Pou4f1",
  "Pou4f2",
  "Pou4f3",
  "Pou5f1",
  "Pou5f2",
  "Pou6f1",
  "Pou6f2",
  "Pp2d1",
  "Ppa1",
  "Ppa2",
  "Ppan",
  "Ppara",
  "Ppard",
  "Pparg",
  "Ppargc1a",
  "Ppargc1b",
  "Ppat",
  "Ppbp",
  "Ppcdc",
  "Ppcs",
  "Ppdpf",
  "Ppef1",
  "Ppef2",
  "Ppfia1",
  "Ppfia2",
  "Ppfia3",
  "Ppfia4",
  "Ppfibp1",
  "Ppfibp2",
  "Pphln1",
  "Ppia",
  "Ppib",
  "Ppic",
  "Ppid",
  "Ppie",
  "Ppif",
  "Ppifos",
  "Ppig",
  "Ppih",
  "Ppil1",
  "Ppil2",
  "Ppil3",
  "Ppil4",
  "Ppil6",
  "Ppip5k1",
  "Ppip5k2",
  "Ppl",
  "Ppm1a",
  "Ppm1b",
  "Ppm1d",
  "Ppm1e",
  "Ppm1f",
  "Ppm1g",
  "Ppm1h",
  "Ppm1j",
  "Ppm1k",
  "Ppm1l",
  "Ppm1m",
  "Ppm1n",
  "Ppme1",
  "Ppox",
  "Ppp1ca",
  "Ppp1cb",
  "Ppp1cc",
  "Ppp1r10",
  "Ppp1r11",
  "Ppp1r12a",
  "Ppp1r12b",
  "Ppp1r12c",
  "Ppp1r13b",
  "Ppp1r13l",
  "Ppp1r14a",
  "Ppp1r14b",
  "Ppp1r14bl",
  "Ppp1r14d",
  "Ppp1r15a",
  "Ppp1r15b",
  "Ppp1r16a",
  "Ppp1r16b",
  "Ppp1r17",
  "Ppp1r18",
  "Ppp1r1a",
  "Ppp1r1b",
  "Ppp1r1c",
  "Ppp1r2",
  "Ppp1r21",
  "Ppp1r26",
  "Ppp1r27",
  "Ppp1r32",
  "Ppp1r35",
  "Ppp1r36",
  "Ppp1r37",
  "Ppp1r3a",
  "Ppp1r3b",
  "Ppp1r3c",
  "Ppp1r3d",
  "Ppp1r3e",
  "Ppp1r3g",
  "Ppp1r42",
  "Ppp1r7",
  "Ppp1r8",
  "Ppp1r9a",
  "Ppp1r9b",
  "Ppp2ca",
  "Ppp2r1a",
  "Ppp2r1b",
  "Ppp2r2a",
  "Ppp2r2b",
  "Ppp2r2c",
  "Ppp2r2cos",
  "Ppp2r2d",
  "Ppp2r3a",
  "Ppp2r3c",
  "Ppp2r3d",
  "Ppp2r5a",
  "Ppp2r5b",
  "Ppp2r5c",
  "Ppp2r5d",
  "Ppp3ca",
  "Ppp3cb",
  "Ppp3cc",
  "Ppp3r1",
  "Ppp3r2",
  "Ppp4c",
  "Ppp4r1",
  "Ppp4r2",
  "Ppp4r3a",
  "Ppp4r3b",
  "Ppp4r4",
  "Ppp5c",
  "Ppp6c",
  "Ppp6r1",
  "Ppp6r2",
  "Ppp6r3",
  "Pprc1",
  "Ppt1",
  "Ppt2",
  "Pptc7",
  "Ppwd1",
  "Ppy",
  "Pqbp1",
  "Pqlc2",
  "Pqlc3",
  "Pradc1",
  "Praf2",
  "Prag1",
  "Prame",
  "Pramef12",
  "Pramef12os",
  "Pramef17",
  "Pramef20",
  "Pramef8",
  "Pramel1",
  "Pramel5",
  "Pramel6",
  "Pramel7",
  "Prap1",
  "Prb1",
  "Prc1",
  "Prcc",
  "Prcd",
  "Prcp",
  "Prdm1",
  "Prdm10",
  "Prdm11",
  "Prdm12",
  "Prdm13",
  "Prdm14",
  "Prdm15",
  "Prdm16",
  "Prdm16os",
  "Prdm2",
  "Prdm4",
  "Prdm5",
  "Prdm6",
  "Prdm8",
  "Prdm9",
  "Prdx1",
  "Prdx2",
  "Prdx3",
  "Prdx4",
  "Prdx5",
  "Prdx6",
  "Prdx6b",
  "Preb",
  "Prelid1",
  "Prelid2",
  "Prelid3a",
  "Prelid3b",
  "Prep",
  "Prepl",
  "Prex1",
  "Prex2",
  "Prf1",
  "Prg2",
  "Prg3",
  "Prg4",
  "Prh1",
  "Prickle1",
  "Prickle2",
  "Prickle3",
  "Prim1",
  "Prim2",
  "Prima1",
  "Primpol",
  "Prkaa1",
  "Prkaa2",
  "Prkab1",
  "Prkab2",
  "Prkaca",
  "Prkacb",
  "Prkag1",
  "Prkag2os1",
  "Prkag3",
  "Prkar1a",
  "Prkar1b",
  "Prkar2a",
  "Prkar2b",
  "Prkca",
  "Prkcb",
  "Prkcd",
  "Prkce",
  "Prkcg",
  "Prkch",
  "Prkci",
  "Prkcq",
  "Prkcsh",
  "Prkcz",
  "Prkd1",
  "Prkd2",
  "Prkd3",
  "Prkdc",
  "Prkg1",
  "Prkg2",
  "Prkn",
  "Prkra",
  "Prkrip1",
  "Prkx",
  "Prl",
  "Prl2a1",
  "Prl2b1",
  "Prl2c1",
  "Prl3a1",
  "Prl3b1",
  "Prl3c1",
  "Prl3d1",
  "Prl4a1",
  "Prl5a1",
  "Prl6a1",
  "Prl7a1",
  "Prl7a2",
  "Prl7b1",
  "Prl7c1",
  "Prl7d1",
  "Prl8a1",
  "Prl8a2",
  "Prl8a6",
  "Prl8a8",
  "Prl8a9",
  "Prlh",
  "Prlhr",
  "Prlr",
  "Prm1",
  "Prm2",
  "Prm3",
  "Prmt1",
  "Prmt2",
  "Prmt3",
  "Prmt5",
  "Prmt6",
  "Prmt7",
  "Prmt8",
  "Prmt9",
  "Prnp",
  "Prob1",
  "Proc",
  "Proca1",
  "Procr",
  "Prodh",
  "Prodh2",
  "Prok1",
  "Prok2",
  "Prokr1",
  "Prokr2",
  "Prol1",
  "Prom1",
  "Prom2",
  "Prop1",
  "Prorp",
  "Prorsd1",
  "Pros1",
  "Proser1",
  "Proser2",
  "Prox1",
  "Prox2",
  "Proz",
  "Prp2",
  "Prpf18",
  "Prpf19",
  "Prpf3",
  "Prpf31",
  "Prpf38a",
  "Prpf38b",
  "Prpf39",
  "Prpf4",
  "Prpf40a",
  "Prpf40b",
  "Prpf4b",
  "Prpf6",
  "Prpf8",
  "Prph",
  "Prph2",
  "Prpmp5",
  "Prps1",
  "Prps1l1",
  "Prps1l3",
  "Prps2",
  "Prpsap1",
  "Prpsap2",
  "Prr11",
  "Prr12",
  "Prr13",
  "Prr14",
  "Prr14l",
  "Prr15",
  "Prr15l",
  "Prr16",
  "Prr18",
  "Prr19",
  "Prr22",
  "Prr23a3",
  "Prr27",
  "Prr29",
  "Prr3",
  "Prr30",
  "Prr32",
  "Prr33",
  "Prr36",
  "Prr5",
  "Prr5l",
  "Prr7",
  "Prr9",
  "Prrc1",
  "Prrc2a",
  "Prrc2b",
  "Prrc2c",
  "Prrg1",
  "Prrg2",
  "Prrg3",
  "Prrg4",
  "Prrt1",
  "Prrt3",
  "Prrt4",
  "Prrx1",
  "Prrx2",
  "Prrxl1",
  "Prss1",
  "Prss12",
  "Prss16",
  "Prss2",
  "Prss21",
  "Prss22",
  "Prss23",
  "Prss23os",
  "Prss27",
  "Prss28",
  "Prss29",
  "Prss30",
  "Prss32",
  "Prss33",
  "Prss34",
  "Prss35",
  "Prss36",
  "Prss37",
  "Prss38",
  "Prss39",
  "Prss40",
  "Prss41",
  "Prss42",
  "Prss43",
  "Prss44",
  "Prss45",
  "Prss46",
  "Prss47",
  "Prss48",
  "Prss50",
  "Prss51",
  "Prss52",
  "Prss53",
  "Prss54",
  "Prss55",
  "Prss56",
  "Prss57",
  "Prss58",
  "Prss8",
  "Prtg",
  "Prtn3",
  "Prune1",
  "Prune2",
  "Prx",
  "Prxl2a",
  "Prxl2b",
  "Prxl2c",
  "Psap",
  "Psapl1",
  "Psat1",
  "Psca",
  "Psd",
  "Psd3",
  "Psd4",
  "Psen1",
  "Psen2",
  "Psenen",
  "Psg16",
  "Psg17",
  "Psg19",
  "Psg20",
  "Psg21",
  "Psg22",
  "Psg23",
  "Psg25",
  "Psg26",
  "Psg27",
  "Psg28",
  "Psg29",
  "Psip1",
  "Pskh1",
  "Psma1",
  "Psma2",
  "Psma3",
  "Psma4",
  "Psma6",
  "Psma7",
  "Psma8",
  "Psmb1",
  "Psmb10",
  "Psmb11",
  "Psmb2",
  "Psmb3",
  "Psmb4",
  "Psmb5",
  "Psmb6",
  "Psmb7",
  "Psmb8",
  "Psmb9",
  "Psmc1",
  "Psmc2",
  "Psmc3",
  "Psmc3ip",
  "Psmc4",
  "Psmc5",
  "Psmc6",
  "Psmd10",
  "Psmd12",
  "Psmd13",
  "Psmd14",
  "Psmd2",
  "Psmd3",
  "Psmd4",
  "Psmd5",
  "Psmd6",
  "Psmd7",
  "Psmd8",
  "Psmd9",
  "Psme1",
  "Psme2b",
  "Psme4",
  "Psmf1",
  "Psmg1",
  "Psmg2",
  "Psmg3",
  "Psmg4",
  "Psors1c2",
  "Pspc1",
  "Psph",
  "Pspn",
  "Psrc1",
  "Pstk",
  "Pstpip1",
  "Pstpip2",
  "Ptafr",
  "Ptar1",
  "Ptbp1",
  "Ptbp2",
  "Ptbp3",
  "Ptcd1",
  "Ptcd2",
  "Ptcd3",
  "Ptch1",
  "Ptch2",
  "Ptchd1",
  "Ptchd3",
  "Ptchd4",
  "Ptcra",
  "Ptdss1",
  "Ptdss2",
  "Pten",
  "Pter",
  "Ptf1a",
  "Ptgdr",
  "Ptgdr2",
  "Ptgds",
  "Ptger1",
  "Ptger2",
  "Ptger3",
  "Ptger4",
  "Ptges",
  "Ptges2",
  "Ptges3",
  "Ptges3l",
  "Ptgfr",
  "Ptgfrn",
  "Ptgir",
  "Ptgis",
  "Ptgr1",
  "Ptgr2",
  "Ptgs1",
  "Ptgs2",
  "Pth",
  "Pth1r",
  "Pth2",
  "Pth2r",
  "Pthlh",
  "Ptk2",
  "Ptk2b",
  "Ptk6",
  "Ptk7",
  "Ptma",
  "Ptms",
  "Ptn",
  "Ptov1",
  "Ptp4a2",
  "Ptp4a3",
  "Ptpa",
  "Ptpdc1",
  "Ptpmt1",
  "Ptpn1",
  "Ptpn11",
  "Ptpn12",
  "Ptpn13",
  "Ptpn14",
  "Ptpn18",
  "Ptpn2",
  "Ptpn20",
  "Ptpn21",
  "Ptpn22",
  "Ptpn23",
  "Ptpn3",
  "Ptpn4",
  "Ptpn5",
  "Ptpn6",
  "Ptpn7",
  "Ptpn9",
  "Ptpra",
  "Ptprb",
  "Ptprc",
  "Ptprcap",
  "Ptprd",
  "Ptpre",
  "Ptprg",
  "Ptprh",
  "Ptprj",
  "Ptprk",
  "Ptprm",
  "Ptprn",
  "Ptprn2",
  "Ptpro",
  "Ptprq",
  "Ptprr",
  "Ptprs",
  "Ptprt",
  "Ptpru",
  "Ptprv",
  "Ptprz1",
  "Ptrh1",
  "Ptrh2",
  "Ptrhd1",
  "Pts",
  "Pttg1",
  "Pttg1ip",
  "Ptx3",
  "Ptx4",
  "Pudp",
  "Puf60",
  "Pum1",
  "Pum2",
  "Pum3",
  "Pura",
  "Purb",
  "Purg",
  "Pus1",
  "Pus10",
  "Pus3",
  "Pus7",
  "Pus7l",
  "Pusl1",
  "Pvalb",
  "Pvr",
  "Pvt1",
  "Pwp1",
  "Pwp2",
  "Pwwp2a",
  "Pwwp2b",
  "Pwwp3a",
  "Pwwp3b",
  "Pxdc1",
  "Pxdn",
  "Pxk",
  "Pxmp2",
  "Pxmp4",
  "Pxn",
  "Pxt1",
  "Pxylp1",
  "Pycard",
  "Pycr1",
  "Pycr2",
  "Pycrl",
  "Pygb",
  "Pygl",
  "Pygm",
  "Pygo1",
  "Pygo2",
  "Pym1",
  "Pyroxd1",
  "Pyroxd2",
  "Pyurf",
  "Pyy",
  "Pzp",
  "Qars",
  "Qdpr",
  "Qk",
  "Qpct",
  "Qpctl",
  "Qprt",
  "Qrfp",
  "Qrfpr",
  "Qrfprl",
  "Qrich1",
  "Qrich2",
  "Qrsl1",
  "Qser1",
  "Qsox1",
  "Qsox2",
  "Qtrt1",
  "Qtrt2",
  "R3hcc1",
  "R3hcc1l",
  "R3hdm1",
  "R3hdm2",
  "R3hdm4",
  "R3hdml",
  "R74862",
  "Rab10",
  "Rab10os",
  "Rab11a",
  "Rab11b",
  "Rab11fip1",
  "Rab11fip2",
  "Rab11fip3",
  "Rab11fip4",
  "Rab11fip4os1",
  "Rab11fip5",
  "Rab12",
  "Rab13",
  "Rab14",
  "Rab15",
  "Rab17",
  "Rab18",
  "Rab19",
  "Rab1a",
  "Rab1b",
  "Rab20",
  "Rab21",
  "Rab22a",
  "Rab23",
  "Rab24",
  "Rab25",
  "Rab26",
  "Rab27a",
  "Rab27b",
  "Rab28",
  "Rab29",
  "Rab2a",
  "Rab2b",
  "Rab30",
  "Rab31",
  "Rab32",
  "Rab33a",
  "Rab33b",
  "Rab34",
  "Rab35",
  "Rab36",
  "Rab37",
  "Rab38",
  "Rab39",
  "Rab39b",
  "Rab3a",
  "Rab3b",
  "Rab3c",
  "Rab3d",
  "Rab3gap1",
  "Rab3gap2",
  "Rab3il1",
  "Rab3ip",
  "Rab40b",
  "Rab40c",
  "Rab42",
  "Rab44",
  "Rab4a",
  "Rab5a",
  "Rab5b",
  "Rab5c",
  "Rab5if",
  "Rab6a",
  "Rab6b",
  "Rab7",
  "Rab7b",
  "Rab8a",
  "Rab8b",
  "Rab9",
  "Rab9b",
  "Rabac1",
  "Rabep1",
  "Rabep2",
  "Rabepk",
  "Rabgap1",
  "Rabgap1l",
  "Rabgef1",
  "Rabggta",
  "Rabggtb",
  "Rabif",
  "Rabl2",
  "Rabl3",
  "Rabl6",
  "Rac1",
  "Rac2",
  "Rac3",
  "Racgap1",
  "Rack1",
  "Rad1",
  "Rad17",
  "Rad18",
  "Rad21",
  "Rad21l",
  "Rad23a",
  "Rad23b",
  "Rad50",
  "Rad51",
  "Rad51ap1",
  "Rad51ap2",
  "Rad51b",
  "Rad51c",
  "Rad51d",
  "Rad52",
  "Rad54b",
  "Rad54l",
  "Rad54l2",
  "Rad9a",
  "Rad9b",
  "Radil",
  "Radx",
  "Rae1",
  "Raet1e",
  "Raf1",
  "Rag1",
  "Rag2",
  "Rai1",
  "Rai14",
  "Rai2",
  "Rala",
  "Ralb",
  "Ralbp1",
  "Ralgapa1",
  "Ralgapa2",
  "Ralgapb",
  "Ralgds",
  "Ralgps1",
  "Ralgps2",
  "Raly",
  "Ralyl",
  "Ramp1",
  "Ramp2",
  "Ramp3",
  "Ran",
  "Ranbp1",
  "Ranbp10",
  "Ranbp17",
  "Ranbp2",
  "Ranbp3",
  "Ranbp3l",
  "Ranbp6",
  "Ranbp9",
  "Rangap1",
  "Rap1a",
  "Rap1b",
  "Rap1gap",
  "Rap1gap2",
  "Rap1gds1",
  "Rap2a",
  "Rap2b",
  "Rap2c",
  "Rapgef1",
  "Rapgef2",
  "Rapgef3",
  "Rapgef4",
  "Rapgef5",
  "Rapgef6",
  "Rapgefl1",
  "Raph1",
  "Rapsn",
  "Rara",
  "Rarb",
  "Rarg",
  "Rarres1",
  "Rarres2",
  "Rars",
  "Rars2",
  "Rasa1",
  "Rasa2",
  "Rasa3",
  "Rasa4",
  "Rasal1",
  "Rasal2",
  "Rasal3",
  "Rasd1",
  "Rasd2",
  "Rasef",
  "Rasgef1a",
  "Rasgef1b",
  "Rasgef1c",
  "Rasgrf1",
  "Rasgrf2",
  "Rasgrp1",
  "Rasgrp2",
  "Rasgrp3",
  "Rasgrp4",
  "Rasip1",
  "Rasl10a",
  "Rasl10b",
  "Rasl11a",
  "Rasl11b",
  "Rasl12",
  "Rasl2-9",
  "Rassf1",
  "Rassf10",
  "Rassf2",
  "Rassf3",
  "Rassf4",
  "Rassf5",
  "Rassf6",
  "Rassf7",
  "Rassf8",
  "Rassf9",
  "Raver2",
  "Rax",
  "Rb1",
  "Rb1cc1",
  "Rbak",
  "Rbakdn",
  "Rbbp4",
  "Rbbp5",
  "Rbbp6",
  "Rbbp7",
  "Rbbp8",
  "Rbbp8nl",
  "Rbbp9",
  "Rbck1",
  "Rbfa",
  "Rbfox1",
  "Rbfox2",
  "Rbfox3",
  "Rbks",
  "Rbl1",
  "Rbl2",
  "Rbm10",
  "Rbm11",
  "Rbm12",
  "Rbm12b1",
  "Rbm12b2",
  "Rbm14",
  "Rbm15",
  "Rbm15b",
  "Rbm17",
  "Rbm18",
  "Rbm19",
  "Rbm20",
  "Rbm22",
  "Rbm24",
  "Rbm25",
  "Rbm26",
  "Rbm28",
  "Rbm3",
  "Rbm33",
  "Rbm34",
  "Rbm38",
  "Rbm39",
  "Rbm3os",
  "Rbm41",
  "Rbm43",
  "Rbm44",
  "Rbm45",
  "Rbm46",
  "Rbm46os",
  "Rbm47",
  "Rbm48",
  "Rbm4b",
  "Rbm5",
  "Rbm6",
  "Rbm7",
  "Rbms1",
  "Rbms3",
  "Rbmx",
  "Rbmx2",
  "Rbmxl2",
  "Rbp1",
  "Rbp2",
  "Rbp3",
  "Rbp4",
  "Rbp7",
  "Rbpj",
  "Rbpjl",
  "Rbpms",
  "Rbpms2",
  "Rbsn",
  "Rbx1",
  "Rc3h1",
  "Rc3h2",
  "Rcan1",
  "Rcan2",
  "Rcan3",
  "Rcbtb1",
  "Rcbtb2",
  "Rcc1",
  "Rcc1l",
  "Rcc2",
  "Rccd1",
  "Rce1",
  "Rchy1",
  "Rcl1",
  "Rcn1",
  "Rcn2",
  "Rcn3",
  "Rcor1",
  "Rcor2",
  "Rcor3",
  "Rcsd1",
  "Rcvrn",
  "Rd3",
  "Rd3l",
  "Rdh1",
  "Rdh10",
  "Rdh11",
  "Rdh12",
  "Rdh13",
  "Rdh14",
  "Rdh16",
  "Rdh16f2",
  "Rdh19",
  "Rdh7",
  "Rdm1",
  "Rdx",
  "Rec114",
  "Rec8",
  "Reck",
  "Recql",
  "Recql4",
  "Recql5",
  "Redrum",
  "Reep1",
  "Reep2",
  "Reep3",
  "Reep4",
  "Reep5",
  "Reep6",
  "Reg1",
  "Reg2",
  "Reg3a",
  "Reg3b",
  "Reg3d",
  "Reg3g",
  "Reg4",
  "Rel",
  "Rela",
  "Relb",
  "Relch",
  "Rell1",
  "Rell2",
  "Reln",
  "Relt",
  "Rem1",
  "Rem2",
  "Ren1",
  "Renbp",
  "Rep15",
  "Repin1",
  "Reps1",
  "Reps2",
  "Rer1",
  "Rere",
  "Rerg",
  "Rergl",
  "Resf1",
  "Resp18",
  "Rest",
  "Ret",
  "Retn",
  "Retnla",
  "Retnlb",
  "Retnlg",
  "Retreg1",
  "Retreg2",
  "Retreg3",
  "Retsat",
  "Rev1",
  "Rev3l",
  "Rex1bd",
  "Rexo1",
  "Rexo2",
  "Rexo4",
  "Rexo5",
  "Rfc1",
  "Rfc2",
  "Rfc3",
  "Rfc4",
  "Rfc5",
  "Rfesd",
  "Rffl",
  "Rfk",
  "Rflna",
  "Rflnb",
  "Rfng",
  "Rfpl4",
  "Rfpl4b",
  "Rft1",
  "Rftn1",
  "Rftn2",
  "Rfwd3",
  "Rfx1",
  "Rfx2",
  "Rfx3",
  "Rfx4",
  "Rfx5",
  "Rfx6",
  "Rfx7",
  "Rfx8",
  "Rfxank",
  "Rfxap",
  "Rgl1",
  "Rgl2",
  "Rgl3",
  "Rgma",
  "Rgmb",
  "Rgn",
  "Rgp1",
  "Rgr",
  "Rgs1",
  "Rgs10",
  "Rgs11",
  "Rgs12",
  "Rgs13",
  "Rgs14",
  "Rgs16",
  "Rgs17",
  "Rgs18",
  "Rgs19",
  "Rgs2",
  "Rgs20",
  "Rgs21",
  "Rgs22",
  "Rgs3",
  "Rgs4",
  "Rgs5",
  "Rgs6",
  "Rgs7",
  "Rgs7bp",
  "Rgs8",
  "Rgs9",
  "Rgs9bp",
  "Rgsl1",
  "Rhag",
  "Rhbdd2",
  "Rhbdd3",
  "Rhbdf1",
  "Rhbdf2",
  "Rhbdl2",
  "Rhbdl3",
  "Rhbg",
  "Rhcg",
  "Rhd",
  "Rheb",
  "Rhebl1",
  "Rhno1",
  "Rho",
  "Rhoa",
  "Rhob",
  "Rhobtb2",
  "Rhobtb3",
  "Rhoc",
  "Rhod",
  "Rhof",
  "Rhog",
  "Rhoh",
  "Rhoj",
  "Rhoq",
  "Rhot1",
  "Rhot2",
  "Rhou",
  "Rhov",
  "Rhox1",
  "Rhox10",
  "Rhox11",
  "Rhox12",
  "Rhox13",
  "Rhox2h",
  "Rhox5",
  "Rhox6",
  "Rhox8",
  "Rhox9",
  "Rhpn1",
  "Rhpn2",
  "Rian",
  "Ribc1",
  "Ribc2",
  "Ric1",
  "Ric3",
  "Ric8a",
  "Ric8b",
  "Rictor",
  "Rida",
  "Rif1",
  "Riiad1",
  "Rilp",
  "Rilpl1",
  "Rilpl2",
  "Rimbp2",
  "Rimbp3",
  "Rimkla",
  "Rimklb",
  "Rims1",
  "Rims2",
  "Rims3",
  "Rims4",
  "Rin1",
  "Rin2",
  "Rin3",
  "Ring1",
  "Rinl",
  "Riok1",
  "Riok2",
  "Riok3",
  "Riox1",
  "Riox2",
  "Ripk1",
  "Ripk2",
  "Ripk3",
  "Ripk4",
  "Ripor1",
  "Ripor2",
  "Ripor3",
  "Ripply1",
  "Ripply2",
  "Ripply3",
  "Rit1",
  "Rit2",
  "Rita1",
  "Rlbp1",
  "Rlf",
  "Rlim",
  "Rln1",
  "Rln3",
  "Rmc1",
  "Rmdn1",
  "Rmdn2",
  "Rmdn3",
  "Rmi1",
  "Rmi2",
  "Rmnd1",
  "Rmnd5a",
  "Rmnd5b",
  "Rmst",
  "Rnase1",
  "Rnase10",
  "Rnase11",
  "Rnase12",
  "Rnase13",
  "Rnase2a",
  "Rnase2b",
  "Rnase4",
  "Rnase6",
  "Rnase9",
  "Rnaseh1",
  "Rnaseh2a",
  "Rnaseh2b",
  "Rnaseh2c",
  "Rnasel",
  "Rnaset2b",
  "Rnd1",
  "Rnd2",
  "Rnd3",
  "Rnf10",
  "Rnf103",
  "Rnf11",
  "Rnf111",
  "Rnf112",
  "Rnf113a1",
  "Rnf113a2",
  "Rnf114",
  "Rnf115",
  "Rnf121",
  "Rnf122",
  "Rnf123",
  "Rnf125",
  "Rnf126",
  "Rnf128",
  "Rnf13",
  "Rnf130",
  "Rnf133",
  "Rnf135",
  "Rnf138",
  "Rnf138rt1",
  "Rnf139",
  "Rnf14",
  "Rnf141",
  "Rnf144a",
  "Rnf144b",
  "Rnf145",
  "Rnf146",
  "Rnf148",
  "Rnf150",
  "Rnf151",
  "Rnf152",
  "Rnf157",
  "Rnf165",
  "Rnf166",
  "Rnf167",
  "Rnf168",
  "Rnf169",
  "Rnf17",
  "Rnf170",
  "Rnf180",
  "Rnf181",
  "Rnf182",
  "Rnf183",
  "Rnf185",
  "Rnf186",
  "Rnf187",
  "Rnf19a",
  "Rnf19b",
  "Rnf2",
  "Rnf20",
  "Rnf207",
  "Rnf208",
  "Rnf212",
  "Rnf212b",
  "Rnf213",
  "Rnf214",
  "Rnf215",
  "Rnf216",
  "Rnf217",
  "Rnf219",
  "Rnf220",
  "Rnf222",
  "Rnf224",
  "Rnf225",
  "Rnf227",
  "Rnf24",
  "Rnf25",
  "Rnf31",
  "Rnf32",
  "Rnf34",
  "Rnf38",
  "Rnf39",
  "Rnf4",
  "Rnf40",
  "Rnf41",
  "Rnf43",
  "Rnf44",
  "Rnf5",
  "Rnf6",
  "Rnf7",
  "Rnf8",
  "Rnft1",
  "Rnft2",
  "Rngtt",
  "Rnh1",
  "Rnmt",
  "Rnpc3",
  "Rnpep",
  "Rnpepl1",
  "Rnps1",
  "Ro60",
  "Robo1",
  "Robo2",
  "Robo3",
  "Robo4",
  "Rock1",
  "Rock2",
  "Rogdi",
  "Rom1",
  "Romo1",
  "Ropn1",
  "Ropn1l",
  "Ror1",
  "Ror2",
  "Rora",
  "Rorb",
  "Rorc",
  "Ros1",
  "Rp1",
  "Rp1l1",
  "Rp2",
  "Rp9",
  "Rpa1",
  "Rpa2",
  "Rpa3",
  "Rpain",
  "Rpap1",
  "Rpap2",
  "Rpap3",
  "Rpe",
  "Rpe65",
  "Rpf1",
  "Rpf2",
  "Rpgr",
  "Rpgrip1",
  "Rpgrip1l",
  "Rph3a",
  "Rph3al",
  "Rpia",
  "Rpl10a",
  "Rpl10l",
  "Rpl11",
  "Rpl13",
  "Rpl13a",
  "Rpl14",
  "Rpl15",
  "Rpl17",
  "Rpl18a",
  "Rpl21",
  "Rpl22",
  "Rpl22l1",
  "Rpl23",
  "Rpl24",
  "Rpl26",
  "Rpl27",
  "Rpl27a",
  "Rpl28",
  "Rpl29",
  "Rpl30",
  "Rpl31",
  "Rpl32",
  "Rpl35a",
  "Rpl36al",
  "Rpl37a",
  "Rpl38",
  "Rpl39",
  "Rpl39l",
  "Rpl3l",
  "Rpl4",
  "Rpl6",
  "Rpl7",
  "Rpl7l1",
  "Rpl8",
  "Rplp0",
  "Rpn1",
  "Rpn2",
  "Rpp14",
  "Rpp21",
  "Rpp25",
  "Rpp25l",
  "Rpp30",
  "Rpp38",
  "Rpp40",
  "Rprd1a",
  "Rprd1b",
  "Rprd2",
  "Rprm",
  "Rprml",
  "Rps10",
  "Rps12",
  "Rps14",
  "Rps15a",
  "Rps16",
  "Rps18-ps4",
  "Rps19bp1",
  "Rps20",
  "Rps21",
  "Rps23rg1",
  "Rps24",
  "Rps25",
  "Rps27a",
  "Rps27l",
  "Rps27rt",
  "Rps28",
  "Rps29",
  "Rps3",
  "Rps3a1",
  "Rps5",
  "Rps6ka1",
  "Rps6ka2",
  "Rps6ka3",
  "Rps6ka5",
  "Rps6ka6",
  "Rps6kb1",
  "Rps6kb2",
  "Rps6kc1",
  "Rps6kl1",
  "Rps8",
  "Rpsa",
  "Rptn",
  "Rptor",
  "Rpusd1",
  "Rpusd2",
  "Rpusd3",
  "Rpusd4",
  "Rrad",
  "Rraga",
  "Rragb",
  "Rragc",
  "Rragd",
  "Rras",
  "Rras2",
  "Rrbp1",
  "Rreb1",
  "Rrh",
  "Rrm1",
  "Rrm2",
  "Rrm2b",
  "Rrn3",
  "Rrnad1",
  "Rrp1",
  "Rrp12",
  "Rrp15",
  "Rrp1b",
  "Rrp36",
  "Rrp7a",
  "Rrp8",
  "Rrp9",
  "Rrs1",
  "Rs1",
  "Rsad1",
  "Rsad2",
  "Rsbn1",
  "Rsbn1l",
  "Rsf1",
  "Rsf1os2",
  "Rskr",
  "Rsl1",
  "Rsl1d1",
  "Rsl24d1",

  "Rslcan18",
  "Rsph1",
  "Rsph10b",
  "Rsph14",
  "Rsph4a",
  "Rsph6a",
  "Rsph9",
  "Rspo1",
  "Rspo2",
  "Rspo3",
  "Rspo4",
  "Rspry1",
  "Rsrc1",
  "Rsrc2",
  "Rsrp1",
  "Rsu1",
  "Rtbdn",
  "Rtcb",
  "Rtel1",
  "Rtf1",
  "Rtf2",
  "Rtkn",
  "Rtkn2",
  "Rtl1",
  "Rtl3",
  "Rtl4",
  "Rtl5",
  "Rtl6",
  "Rtl8a",
  "Rtl8c",
  "Rtn1",
  "Rtn2",
  "Rtn3",
  "Rtn4",
  "Rtn4ip1",
  "Rtn4r",
  "Rtn4rl1",
  "Rtn4rl2",
  "Rtp1",
  "Rtp2",
  "Rtp3",
  "Rtp4",
  "Rtraf",
  "Rttn",
  "Rubcn",
  "Rubcnl",
  "Rubie",
  "Rufy1",
  "Rufy2",
  "Rufy3",
  "Rufy4",
  "Rundc1",
  "Rundc3a",
  "Rundc3b",
  "Runx1",
  "Runx1t1",
  "Runx2",
  "Runx2os1",
  "Runx3",
  "Rusc1",
  "Rusc2",
  "Ruvbl1",
  "Ruvbl2",
  "Rwdd1",
  "Rwdd2a",
  "Rwdd2b",
  "Rwdd3",
  "Rwdd4a",
  "Rxfp1",
  "Rxfp2",
  "Rxfp3",
  "Rxfp4",
  "Rxra",
  "Rxrb",
  "Rxrg",
  "Rxylt1",
  "Rybp",
  "Ryk",
  "Ryr1",
  "Ryr2",
  "Ryr3",
  "S100a1",
  "S100a10",
  "S100a11",
  "S100a13",
  "S100a14",
  "S100a16",
  "S100a2",
  "S100a3",
  "S100a4",
  "S100a5",
  "S100a6",
  "S100a7a",
  "S100a7l2",
  "S100a8",
  "S100a9",
  "S100b",
  "S100g",
  "S100pbp",
  "S100z",
  "S1pr1",
  "S1pr2",
  "S1pr3",
  "S1pr4",
  "S1pr5",
  "Saa1",
  "Saa2",
  "Saa3",
  "Saa4",
  "Saal1",
  "Sac3d1",
  "Sacm1l",
  "Sacs",
  "Sae1",
  "Safb",
  "Safb2",
  "Sag",
  "Sall1",
  "Sall2",
  "Sall3",
  "Sall4",
  "Samd1",
  "Samd10",
  "Samd11",
  "Samd12",
  "Samd13",
  "Samd14",
  "Samd15",
  "Samd3",
  "Samd4",
  "Samd4b",
  "Samd5",
  "Samd7",
  "Samd8",
  "Samd9l",
  "Samhd1",
  "Samm50",
  "Samsn1",
  "Samt2b",
  "Samt3",
  "Samt4",
  "Sap130",
  "Sap18",
  "Sap30",
  "Sap30bp",
  "Sap30l",
  "Sapcd1",
  "Sapcd2",
  "Sar1a",
  "Sar1b",
  "Saraf",
  "Sardh",
  "Sarm1",
  "Sarnp",
  "Sars",
  "Sars2",
  "Sart1",
  "Sart3",
  "Sash1",
  "Sash3",
  "Sass6",
  "Sat1",
  "Sat2",
  "Satb1",
  "Satb2",
  "Satl1",
  "Sav1",
  "Saxo1",
  "Saxo2",
  "Saysd1",
  "Sbds",
  "Sbf1",
  "Sbf2",
  "Sbk1",
  "Sbk3",
  "Sbno1",
  "Sbno2",
  "Sbp",
  "Sbpl",
  "Sbspon",
  "Sc5d",
  "Scaf1",
  "Scaf11",
  "Scaf4",
  "Scaf8",
  "Scai",
  "Scamp1",
  "Scamp2",
  "Scamp3",
  "Scamp5",
  "Scand1",
  "Scap",
  "Scaper",
  "Scara3",
  "Scara5",
  "Scarb1",
  "Scarb2",
  "Scarf1",
  "Scarf2",
  "Scarletltr",
  "Sccpdh",
  "Scd1",
  "Scd2",
  "Scd3",
  "Scd4",
  "Scfd1",
  "Scfd2",
  "Scg2",
  "Scg3",
  "Scg5",
  "Scgb1a1",
  "Scgb1b2",
  "Scgb1b20",
  "Scgb1b27",
  "Scgb1b3",
  "Scgb1b30",
  "Scgb1c1",
  "Scgb2a2",
  "Scgb2b2",
  "Scgb2b21",
  "Scgb2b24",
  "Scgb2b26",
  "Scgb2b27",
  "Scgb2b3",
  "Scgb3a1",
  "Scgb3a2",
  "Scgn",
  "Scimp",
  "Scin",
  "Sclt1",
  "Scly",
  "Scmh1",
  "Scml2",
  "Scml4",
  "Scn10a",
  "Scn11a",
  "Scn1a",
  "Scn1b",
  "Scn2a",
  "Scn2b",
  "Scn3a",
  "Scn3b",
  "Scn4a",
  "Scn4b",
  "Scn5a",
  "Scn7a",
  "Scn8a",
  "Scn9a",
  "Scnm1",
  "Scnn1a",
  "Scnn1b",
  "Scnn1g",
  "Sco1",
  "Sco2",
  "Scoc",
  "Scp2d1",
  "Scpep1",
  "Scrg1",
  "Scrib",
  "Scrn1",
  "Scrn2",
  "Scrn3",
  "Scrt1",
  "Scrt2",
  "Sct",
  "Sctr",
  "Scube1",
  "Scube2",
  "Scube3",
  "Scx",
  "Scyl1",
  "Scyl2",
  "Scyl3",
  "Sdad1",
  "Sdc1",
  "Sdc2",
  "Sdc3",
  "Sdc4",
  "Sdcbp",
  "Sdcbp2",
  "Sdccag8",
  "Sde2",
  "Sdf2",
  "Sdf2l1",
  "Sdf4",
  "Sdha",
  "Sdhaf1",
  "Sdhaf2",
  "Sdhaf3",
  "Sdhaf4",
  "Sdhb",
  "Sdhc",
  "Sdhd",
  "Sdk1",
  "Sdk2",
  "Sdr16c5",
  "Sdr16c6",
  "Sdr39u1",
  "Sdr42e1",
  "Sdr9c7",
  "Sds",
  "Sdsl",
  "Sebox",
  "Sec1",
  "Sec11a",
  "Sec11c",
  "Sec13",
  "Sec14l1",
  "Sec14l2",
  "Sec14l3",
  "Sec14l4",
  "Sec14l5",
  "Sec16a",
  "Sec16b",
  "Sec22a",
  "Sec22b",
  "Sec22c",
  "Sec23a",
  "Sec23b",
  "Sec23ip",
  "Sec24a",
  "Sec24b",
  "Sec24c",
  "Sec24d",
  "Sec31a",
  "Sec31b",
  "Sec61a1",
  "Sec61a2",
  "Sec61b",
  "Sec62",
  "Sec63",
  "Secisbp2",
  "Secisbp2l",
  "Sectm1a",
  "Sectm1b",
  "Seh1l",
  "Sel1l",
  "Sel1l2",
  "Sel1l3",
  "Sele",
  "Selenbp1",
  "Selenof",
  "Selenoh",
  "Selenoi",
  "Selenok",
  "Selenok-ps1",
  "Selenom",
  "Selenon",
  "Selenoo",
  "Selenop",
  "Selenos",
  "Selenot",
  "Selenov",
  "Selenow",
  "Sell",
  "Selp",
  "Selplg",
  "Sem1",
  "Sema3a",
  "Sema3b",
  "Sema3c",
  "Sema3d",
  "Sema3f",
  "Sema3g",
  "Sema4a",
  "Sema4b",
  "Sema4c",
  "Sema4d",
  "Sema4f",
  "Sema4g",
  "Sema5a",
  "Sema5b",
  "Sema6a",
  "Sema6b",
  "Sema6c",
  "Sema6d",
  "Sema7a",
  "Senp1",
  "Senp2",
  "Senp3",
  "Senp5",
  "Senp6",
  "Senp7",
  "Senp8",
  "Sephs1",
  "Sephs2",
  "Sepsecs",
  "Septin1",
  "Septin10",
  "Septin11",
  "Septin12",
  "Septin14",
  "Septin3",
  "Septin4",
  "Septin6",
  "Septin7",
  "Septin8",
  "Septin9",
  "Serac1",
  "Serbp1",
  "Serf1",
  "Serf2",
  "Sergef",
  "Serhl",
  "Serinc1",
  "Serinc2",
  "Serinc3",
  "Serinc4",
  "Serinc5",
  "Serp1",
  "Serp2",
  "Serpina10",
  "Serpina11",
  "Serpina12",
  "Serpina16",
  "Serpina1d",
  "Serpina1e",
  "Serpina1f",
  "Serpina3a",
  "Serpina3b",
  "Serpina3c",
  "Serpina3f",
  "Serpina3g",
  "Serpina3i",
  "Serpina3j",
  "Serpina3k",
  "Serpina3m",
  "Serpina3n",
  "Serpina5",
  "Serpina6",
  "Serpina7",
  "Serpina9",
  "Serpinb11",
  "Serpinb12",
  "Serpinb13",
  "Serpinb1a",
  "Serpinb1b",
  "Serpinb1c",
  "Serpinb2",
  "Serpinb3a",
  "Serpinb3b",
  "Serpinb3c",
  "Serpinb3d",
  "Serpinb5",
  "Serpinb6a",
  "Serpinb6b",
  "Serpinb6c",
  "Serpinb6e",
  "Serpinb7",
  "Serpinb9",
  "Serpinb9b",
  "Serpinb9c",
  "Serpinb9e",
  "Serpinb9g",
  "Serpinc1",
  "Serpind1",
  "Serpine1",
  "Serpine2",
  "Serpinf1",
  "Serpinf2",
  "Serping1",
  "Serpinh1",
  "Serpini1",
  "Serpini2",
  "Sertad1",
  "Sertad2",
  "Sertad3",
  "Sertad4",
  "Sertm1",
  "Sesn1",
  "Sesn2",
  "Sesn3",
  "Sestd1",
  "Set",
  "Setbp1",
  "Setd1a",
  "Setd1b",
  "Setd2",
  "Setd3",
  "Setd4",
  "Setd5",
  "Setd6",
  "Setd7",
  "Setdb1",
  "Setdb2",
  "Setmar",
  "Setx",
  "Sez6",
  "Sez6l2",
  "Sf1",
  "Sf3a1",
  "Sf3a2",
  "Sf3a3",
  "Sf3b1",
  "Sf3b2",
  "Sf3b3",
  "Sf3b4",
  "Sf3b5",
  "Sf3b6",
  "Sfi1",
  "Sfmbt1",
  "Sfmbt2",
  "Sfpq",
  "Sfr1",
  "Sfrp1",
  "Sfrp2",
  "Sfrp4",
  "Sfrp5",
  "Sfswap",
  "Sft2d2",
  "Sft2d3",
  "Sfta2",
  "Sfta3-ps",
  "Sftpa1",
  "Sftpb",
  "Sftpc",
  "Sftpd",
  "Sfxn1",
  "Sfxn2",
  "Sfxn3",
  "Sfxn4",
  "Sfxn5",
  "Sgca",
  "Sgcb",
  "Sgcd",
  "Sgce",
  "Sgcg",
  "Sgcz",
  "Sgf29",
  "Sgip1",
  "Sgk1",
  "Sgk2",
  "Sgk3",
  "Sgms1",
  "Sgms2",
  "Sgo1",
  "Sgo2a",
  "Sgo2b",
  "Sgpl1",
  "Sgpp1",
  "Sgpp2",
  "Sgsh",
  "Sgsm1",
  "Sgsm2",
  "Sgsm3",
  "Sgta",
  "Sgtb",
  "Sh2b1",
  "Sh2b2",
  "Sh2b3",
  "Sh2d1a",
  "Sh2d1b1",
  "Sh2d1b2",
  "Sh2d2a",
  "Sh2d3c",
  "Sh2d4a",
  "Sh2d4b",
  "Sh2d5",
  "Sh2d6",
  "Sh2d7",
  "Sh3bgr",
  "Sh3bgrl",
  "Sh3bgrl2",
  "Sh3bgrl3",
  "Sh3bp1",
  "Sh3bp2",
  "Sh3bp4",
  "Sh3bp5",
  "Sh3bp5l",
  "Sh3d19",
  "Sh3d21",
  "Sh3gl1",
  "Sh3gl2",
  "Sh3gl3",
  "Sh3glb1",
  "Sh3glb2",
  "Sh3kbp1",
  "Sh3pxd2a",
  "Sh3pxd2b",
  "Sh3rf1",
  "Sh3rf2",
  "Sh3rf3",
  "Sh3tc1",
  "Sh3tc2",
  "Sh3yl1",
  "Shank1",
  "Shank2",
  "Shank3",
  "Sharpin",
  "Shb",
  "Shbg",
  "Shc1",
  "Shc2",
  "Shc3",
  "Shc4",
  "Shcbp1",
  "Shcbp1l",
  "Shd",
  "She",
  "Shf",
  "Shfl",
  "Shh",
  "Shisa2",
  "Shisa3",
  "Shisa4",
  "Shisa5",
  "Shisa6",
  "Shisa7",
  "Shisa8",
  "Shisa9",
  "Shisal1",
  "Shisal2a",
  "Shisal2b",
  "Shkbp1",
  "Shld1",
  "Shld2",
  "Shmt1",
  "Shmt2",
  "Shoc1",
  "Shoc2",
  "Shox2",
  "Shpk",
  "Shprh",
  "Shq1",
  "Shroom1",
  "Shroom2",
  "Shroom4",
  "Shtn1",
  "Siae",
  "Siah1a",
  "Siah3",
  "Sidt1",
  "Sidt2",
  "Sigirr",
  "Siglec1",
  "Siglec15",
  "Siglece",
  "Siglecf",
  "Siglecg",
  "Siglech",
  "Sigmar1",
  "Sik1",
  "Sik2",
  "Sik3",
  "Sike1",
  "Sil1",
  "Sim1",
  "Sim2",
  "Simc1",
  "Sin3a",
  "Sin3b",
  "Sipa1",
  "Sipa1l1",
  "Sipa1l2",
  "Sipa1l3",
  "Sirpa",
  "Sirpb1a",
  "Sirt1",
  "Sirt2",
  "Sirt3",
  "Sirt4",
  "Sirt5",
  "Sirt6",
  "Sirt7",
  "Sis",
  "Sit1",
  "Siva1",
  "Six1",
  "Six2",
  "Six3",
  "Six3os1",
  "Six4",
  "Six5",
  "Six6",
  "Ska1",
  "Ska2",
  "Ska3",
  "Skap1",
  "Skap2",
  "Ski",
  "Skida1",
  "Skil",
  "Skint1",
  "Skint10",
  "Skint11",
  "Skint2",
  "Skint3",
  "Skint4",
  "Skint5",
  "Skint6",
  "Skint7",
  "Skint8",
  "Skint9",
  "Skiv2l",
  "Skor1",
  "Skor2",
  "Skp1a",
  "Skp2",
  "Sla",
  "Sla2",
  "Slain1",
  "Slain1os",
  "Slain2",
  "Slamf1",
  "Slamf6",
  "Slamf7",
  "Slamf8",
  "Slamf9",
  "Slbp",
  "Slc10a1",
  "Slc10a2",
  "Slc10a3",
  "Slc10a4",
  "Slc10a4l",
  "Slc10a5",
  "Slc10a6",
  "Slc10a7",
  "Slc11a1",
  "Slc11a2",
  "Slc12a1",
  "Slc12a2",
  "Slc12a3",
  "Slc12a4",
  "Slc12a5",
  "Slc12a6",
  "Slc12a7",
  "Slc12a8",
  "Slc12a9",
  "Slc13a1",
  "Slc13a2",
  "Slc13a2os",
  "Slc13a3",
  "Slc13a4",
  "Slc13a5",
  "Slc14a1",
  "Slc14a2",
  "Slc15a1",
  "Slc15a2",
  "Slc15a3",
  "Slc15a4",
  "Slc15a5",
  "Slc16a1",
  "Slc16a10",
  "Slc16a11",
  "Slc16a12",
  "Slc16a13",
  "Slc16a14",
  "Slc16a3",
  "Slc16a4",
  "Slc16a5",
  "Slc16a6",
  "Slc16a7",
  "Slc16a8",
  "Slc16a9",
  "Slc17a1",
  "Slc17a2",
  "Slc17a3",
  "Slc17a4",
  "Slc17a5",
  "Slc17a6",
  "Slc17a7",
  "Slc17a8",
  "Slc17a9",
  "Slc18a1",
  "Slc18a2",
  "Slc18a3",
  "Slc18b1",
  "Slc19a1",
  "Slc19a2",
  "Slc19a3",
  "Slc1a1",
  "Slc1a2",
  "Slc1a3",
  "Slc1a4",
  "Slc1a5",
  "Slc1a6",
  "Slc1a7",
  "Slc20a1",
  "Slc20a2",
  "Slc22a1",
  "Slc22a12",
  "Slc22a13",
  "Slc22a14",
  "Slc22a15",
  "Slc22a16",
  "Slc22a17",
  "Slc22a18",
  "Slc22a19",
  "Slc22a2",
  "Slc22a20",
  "Slc22a21",
  "Slc22a22",
  "Slc22a23",
  "Slc22a26",
  "Slc22a27",
  "Slc22a28",
  "Slc22a29",
  "Slc22a3",
  "Slc22a30",
  "Slc22a4",
  "Slc22a6",
  "Slc22a7",
  "Slc22a8",
  "Slc23a1",
  "Slc23a2",
  "Slc23a3",
  "Slc23a4",
  "Slc24a1",
  "Slc24a2",
  "Slc24a3",
  "Slc24a4",
  "Slc24a5",
  "Slc25a1",
  "Slc25a10",
  "Slc25a11",
  "Slc25a12",
  "Slc25a13",
  "Slc25a14",
  "Slc25a15",
  "Slc25a16",
  "Slc25a17",
  "Slc25a18",
  "Slc25a19",
  "Slc25a2",
  "Slc25a20",
  "Slc25a21",
  "Slc25a22",
  "Slc25a23",
  "Slc25a24",
  "Slc25a25",
  "Slc25a26",
  "Slc25a27",
  "Slc25a28",
  "Slc25a29",
  "Slc25a3",
  "Slc25a30",
  "Slc25a31",
  "Slc25a32",
  "Slc25a33",
  "Slc25a34",
  "Slc25a35",
  "Slc25a36",
  "Slc25a37",
  "Slc25a38",
  "Slc25a39",
  "Slc25a4",
  "Slc25a40",
  "Slc25a41",
  "Slc25a42",
  "Slc25a43",
  "Slc25a44",
  "Slc25a45",
  "Slc25a46",
  "Slc25a47",
  "Slc25a48",
  "Slc25a51",
  "Slc25a53",
  "Slc25a54",
  "Slc26a1",
  "Slc26a10",
  "Slc26a11",
  "Slc26a2",
  "Slc26a3",
  "Slc26a4",
  "Slc26a5",
  "Slc26a6",
  "Slc26a7",
  "Slc26a8",
  "Slc26a9",
  "Slc27a1",
  "Slc27a2",
  "Slc27a3",
  "Slc27a4",
  "Slc27a5",
  "Slc28a1",
  "Slc28a2",
  "Slc28a2b",
  "Slc28a3",
  "Slc29a1",
  "Slc29a2",
  "Slc29a3",
  "Slc29a4",
  "Slc2a1",
  "Slc2a10",
  "Slc2a12",
  "Slc2a13",
  "Slc2a2",
  "Slc2a3",
  "Slc2a4",
  "Slc2a5",
  "Slc2a6",
  "Slc2a7",
  "Slc2a8",
  "Slc2a9",
  "Slc30a1",
  "Slc30a10",
  "Slc30a2",
  "Slc30a3",
  "Slc30a4",
  "Slc30a5",
  "Slc30a6",
  "Slc30a7",
  "Slc30a8",
  "Slc30a9",
  "Slc31a1",
  "Slc31a2",
  "Slc32a1",
  "Slc33a1",
  "Slc34a1",
  "Slc34a2",
  "Slc34a3",
  "Slc35a1",
  "Slc35a2",
  "Slc35a3",
  "Slc35a4",
  "Slc35a5",
  "Slc35b1",
  "Slc35b2",
  "Slc35b3",
  "Slc35b4",
  "Slc35c1",
  "Slc35c2",
  "Slc35d1",
  "Slc35d2",
  "Slc35d3",
  "Slc35e1",
  "Slc35e2",
  "Slc35e3",
  "Slc35e4",
  "Slc35f1",
  "Slc35f2",
  "Slc35f3",
  "Slc35f4",
  "Slc35f5",
  "Slc35f6",
  "Slc35g1",
  "Slc35g2",
  "Slc35g3",
  "Slc36a1",
  "Slc36a1os",
  "Slc36a2",
  "Slc36a3",
  "Slc36a4",
  "Slc37a1",
  "Slc37a2",
  "Slc37a3",
  "Slc37a4",
  "Slc38a1",
  "Slc38a10",
  "Slc38a11",
  "Slc38a2",
  "Slc38a3",
  "Slc38a4",
  "Slc38a5",
  "Slc38a6",
  "Slc38a7",
  "Slc38a8",
  "Slc38a9",
  "Slc39a1",
  "Slc39a10",
  "Slc39a11",
  "Slc39a12",
  "Slc39a13",
  "Slc39a14",
  "Slc39a2",
  "Slc39a3",
  "Slc39a4",
  "Slc39a5",
  "Slc39a6",
  "Slc39a8",
  "Slc39a9",
  "Slc3a1",
  "Slc3a2",
  "Slc40a1",
  "Slc41a1",
  "Slc41a2",
  "Slc41a3",
  "Slc43a1",
  "Slc43a2",
  "Slc43a3",
  "Slc44a1",
  "Slc44a2",
  "Slc44a3",
  "Slc44a4",
  "Slc44a5",
  "Slc45a1",
  "Slc45a2",
  "Slc45a3",
  "Slc45a4",
  "Slc46a1",
  "Slc46a2",
  "Slc46a3",
  "Slc47a1",
  "Slc47a2",
  "Slc48a1",
  "Slc49a4",
  "Slc4a1",
  "Slc4a10",
  "Slc4a11",
  "Slc4a1ap",
  "Slc4a2",
  "Slc4a3",
  "Slc4a4",
  "Slc4a5",
  "Slc4a7",
  "Slc4a8",
  "Slc4a9",
  "Slc50a1",
  "Slc51a",
  "Slc51b",
  "Slc52a2",
  "Slc52a3",
  "Slc5a1",
  "Slc5a10",
  "Slc5a11",
  "Slc5a12",
  "Slc5a2",
  "Slc5a3",
  "Slc5a4a",
  "Slc5a4b",
  "Slc5a5",
  "Slc5a6",
  "Slc5a7",
  "Slc5a8",
  "Slc5a9",
  "Slc66a2",
  "Slc6a1",
  "Slc6a11",
  "Slc6a12",
  "Slc6a13",
  "Slc6a14",
  "Slc6a15",
  "Slc6a16",
  "Slc6a17",
  "Slc6a18",
  "Slc6a19",
  "Slc6a19os",
  "Slc6a2",
  "Slc6a20a",
  "Slc6a20b",
  "Slc6a21",
  "Slc6a3",
  "Slc6a4",
  "Slc6a5",
  "Slc6a6",
  "Slc6a7",
  "Slc6a8",
  "Slc6a9",
  "Slc7a1",
  "Slc7a10",
  "Slc7a11",
  "Slc7a12",
  "Slc7a13",
  "Slc7a14",
  "Slc7a15",
  "Slc7a2",
  "Slc7a3",
  "Slc7a4",
  "Slc7a5",
  "Slc7a6",
  "Slc7a6os",
  "Slc7a7",
  "Slc7a8",
  "Slc7a9",
  "Slc8a1",
  "Slc8a2",
  "Slc8a3",
  "Slc8b1",
  "Slc9a1",
  "Slc9a2",
  "Slc9a3",
  "Slc9a3r1",
  "Slc9a3r2",
  "Slc9a5",
  "Slc9a6",
  "Slc9a7",
  "Slc9a8",
  "Slc9a9",
  "Slc9b1",
  "Slc9b2",
  "Slc9c1",
  "Slco1a1",
  "Slco1a4",
  "Slco1a5",
  "Slco1a6",
  "Slco1b2",
  "Slco1c1",
  "Slco2a1",
  "Slco2b1",
  "Slco3a1",
  "Slco4a1",
  "Slco4c1",
  "Slco5a1",
  "Slco6c1",
  "Slco6d1",
  "Slf1",
  "Slf2",
  "Slfn1",
  "Slfn14",
  "Slfn2",
  "Slfn3",
  "Slfn4",
  "Slfn5",
  "Slfn5os",
  "Slfn8",
  "Slfn9",
  "Slfnl1",
  "Slirp",
  "Slit1",
  "Slit2",
  "Slit3",
  "Slitrk1",
  "Slitrk2",
  "Slitrk3",
  "Slitrk4",
  "Slitrk5",
  "Slitrk6",
  "Slk",
  "Slmap",
  "Sln",
  "Slpi",
  "Sltm",
  "Slu7",
  "Slurp1",
  "Slurp2",
  "Slx1b",
  "Slx4",
  "Slx4ip",
  "Smad1",
  "Smad2",
  "Smad3",
  "Smad4",
  "Smad5",
  "Smad6",
  "Smad7",
  "Smad9",
  "Smagp",
  "Smap1",
  "Smap2",
  "Smarca1",
  "Smarca2",
  "Smarca4",
  "Smarca5",
  "Smarcad1",
  "Smarcal1",
  "Smarcb1",
  "Smarcc1",
  "Smarcc2",
  "Smarcd1",
  "Smarcd2",
  "Smarcd3",
  "Smarce1",
  "Smc1a",
  "Smc1b",
  "Smc2",
  "Smc2os",
  "Smc3",
  "Smc4",
  "Smc5",
  "Smc6",
  "Smchd1",
  "Smco1",
  "Smco2",
  "Smco3",
  "Smco4",
  "Smcp",
  "Smcr8",
  "Smdt1",
  "Smg1",
  "Smg5",
  "Smg6",
  "Smg7",
  "Smg8",
  "Smg9",
  "Smgc",
  "Smim1",
  "Smim10l1",
  "Smim10l2a",
  "Smim11",
  "Smim12",
  "Smim13",
  "Smim14",
  "Smim15",
  "Smim17",
  "Smim18",
  "Smim19",
  "Smim20",
  "Smim22",
  "Smim23",
  "Smim24",
  "Smim26",
  "Smim27",
  "Smim3",
  "Smim31",
  "Smim38",
  "Smim4",
  "Smim40",
  "Smim41",
  "Smim5",
  "Smim6",
  "Smim7",
  "Smim8",
  "Smim9",
  "Smlr1",
  "Smn1",
  "Smndc1",
  "Smo",
  "Smoc1",
  "Smoc2",
  "Smok2a",
  "Smok3a",
  "Smox",
  "Smpd1",
  "Smpd2",
  "Smpd3",
  "Smpd4",
  "Smpd5",
  "Smpdl3a",
  "Smpdl3b",
  "Smpx",
  "Smr2",
  "Smr3a",
  "Sms",
  "Smtn",
  "Smtnl1",
  "Smtnl2",
  "Smu1",
  "Smug1",
  "Smurf1",
  "Smurf2",
  "Smyd1",
  "Smyd2",
  "Smyd3",
  "Smyd4",
  "Smyd5",
  "Snai1",
  "Snai2",
  "Snai3",
  "Snap23",
  "Snap25",
  "Snap29",
  "Snap47",
  "Snap91",
  "Snapc1",
  "Snapc2",
  "Snapc3",
  "Snapc4",
  "Snapc5",
  "Snapin",
  "Snca",
  "Sncaip",
  "Sncb",
  "Sncg",
  "Snd1",
  "Sned1",
  "Snf8",
  "Snhg1",
  "Snhg10",
  "Snhg11",
  "Snhg12",
  "Snhg14",
  "Snhg15",
  "Snhg16",
  "Snhg17",
  "Snhg18",
  "Snhg20",
  "Snhg3",
  "Snhg5",
  "Snhg6",
  "Snhg7os",
  "Snip1",
  "Snn",
  "Snorc",
  "Snph",
  "Snrk",
  "Snrnp200",
  "Snrnp25",
  "Snrnp27",
  "Snrnp35",
  "Snrnp40",
  "Snrnp48",
  "Snrnp70",
  "Snrpa",
  "Snrpa1",
  "Snrpb",
  "Snrpb2",
  "Snrpc",
  "Snrpd1",
  "Snrpd2",
  "Snrpd3",
  "Snrpe",
  "Snrpf",
  "Snrpg",
  "Snrpn",
  "Snta1",
  "Sntb1",
  "Sntb2",
  "Sntg1",
  "Sntg2",
  "Sntn",
  "Snu13",
  "Snupn",
  "Snw1",
  "Snx1",
  "Snx10",
  "Snx11",
  "Snx12",
  "Snx13",
  "Snx14",
  "Snx15",
  "Snx16",
  "Snx17",
  "Snx19",
  "Snx2",
  "Snx20",
  "Snx21",
  "Snx22",
  "Snx24",
  "Snx25",
  "Snx27",
  "Snx29",
  "Snx3",
  "Snx30",
  "Snx31",
  "Snx32",
  "Snx33",
  "Snx4",
  "Snx5",
  "Snx6",
  "Snx7",
  "Snx8",
  "Snx9",
  "Soat1",
  "Soat2",
  "Sobp",
  "Socs1",
  "Socs2",
  "Socs3",
  "Socs4",
  "Socs5",
  "Socs6",
  "Socs7",
  "Sod1",
  "Sod2",
  "Sod3",
  "Soga1",
  "Soga3",
  "Sohlh1",
  "Sohlh2",
  "Son",
  "Sorbs2",
  "Sorbs2os",
  "Sorbs3",
  "Sorcs1",
  "Sorcs2",
  "Sorcs3",
  "Sord",
  "Sorl1",
  "Sort1",
  "Sos1",
  "Sos2",
  "Sost",
  "Sostdc1",
  "Sowaha",
  "Sowahb",
  "Sowahc",
  "Sowahd",
  "Sox1",
  "Sox10",
  "Sox11",
  "Sox12",
  "Sox13",
  "Sox14",
  "Sox15",
  "Sox17",
  "Sox18",
  "Sox1ot",
  "Sox2",
  "Sox21",
  "Sox2ot",
  "Sox3",
  "Sox30",
  "Sox4",
  "Sox5",
  "Sox5it",
  "Sox5os1",
  "Sox5os3",
  "Sox5os5",
  "Sox6",
  "Sox6os",
  "Sox7",
  "Sox8",
  "Sox9",
  "Sp1",
  "Sp100",
  "Sp110",
  "Sp140",
  "Sp2",
  "Sp3os",
  "Sp4",
  "Sp5",
  "Sp7",
  "Sp8",
  "Sp9",
  "Spa17",
  "Spaar",
  "Spaca1",
  "Spaca3",
  "Spaca4",
  "Spaca5",
  "Spaca6",
  "Spaca7",
  "Spaca9",
  "Spag1",
  "Spag11b",
  "Spag16",
  "Spag17",
  "Spag17os",
  "Spag4",
  "Spag5",
  "Spag6",
  "Spag6l",
  "Spag7",
  "Spag8",
  "Spag9",
  "Spam1",
  "Sparc",
  "Sparcl1",
  "Spast",
  "Spata1",
  "Spata13",
  "Spata16",
  "Spata17",
  "Spata18",
  "Spata19",
  "Spata2",
  "Spata20",
  "Spata21",
  "Spata22",
  "Spata25",
  "Spata2l",
  "Spata3",
  "Spata31",
  "Spata31d1a",
  "Spata31d1b",
  "Spata31d1c",
  "Spata31d1d",
  "Spata32",
  "Spata33",
  "Spata4",
  "Spata45",
  "Spata46",
  "Spata48",
  "Spata5",
  "Spata5l1",
  "Spata6",
  "Spata7",
  "Spata9",
  "Spatc1",
  "Spatc1l",
  "Spats1",
  "Spats2",
  "Spats2l",
  "Spc24",
  "Spc25",
  "Spcs1",
  "Spcs2",
  "Spcs3",
  "Spdef",
  "Spdl1",
  "Spdya",
  "Spdye4a",
  "Spdye4b",
  "Spdye4c",
  "Specc1",
  "Specc1l",
  "Speer2",
  "Speer4a",
  "Speer4e",
  "Speer4f1",
  "Speer9-ps1",
  "Spef1",
  "Spef1l",
  "Spef2",
  "Speg",
  "Spem1",
  "Spem2",
  "Spen",
  "Spertl",
  "Spesp1",
  "Spg11",
  "Spg20",
  "Spg21",
  "Spg7",
  "Sphk1",
  "Sphk2",
  "Sphkap",
  "Spi1",
  "Spib",
  "Spic",
  "Spice1",
  "Spidr",
  "Spin1",
  "Spin2c",
  "Spin2g",
  "Spin4",
  "Spindoc",
  "Spink1",
  "Spink10",
  "Spink11",
  "Spink12",
  "Spink13",
  "Spink14",
  "Spink2",
  "Spink4",
  "Spink5",
  "Spink6",
  "Spink7",
  "Spink8",
  "Spinkl",
  "Spint1",
  "Spint2",
  "Spint3",
  "Spint4",
  "Spint5",
  "Spire1",
  "Spn",
  "Spns1",
  "Spns2",
  "Spns3",
  "Spo11",
  "Spocd1",
  "Spock1",
  "Spock2",
  "Spock3",
  "Spon1",
  "Spon2",
  "Spop",
  "Spopfm1",
  "Spopfm2",
  "Spopl",
  "Spout1",
  "Spp1",
  "Spp2",
  "Sppl2a",
  "Sppl2b",
  "Sppl2c",
  "Sppl3",
  "Spr",
  "Spred1",
  "Spred2",
  "Spred3",
  "Sprn",
  "Sprr1a",
  "Sprr1b",
  "Sprr2b",
  "Sprr2d",
  "Sprr2e",
  "Sprr2f",
  "Sprr2h",
  "Sprr2i",
  "Sprr2j-ps",
  "Sprr2k",
  "Sprr3",
  "Sprr4",
  "Sprtn",
  "Spry1",
  "Spry2",
  "Spry4",
  "Spryd3",
  "Spryd4",
  "Spryd7",
  "Spsb1",
  "Spsb2",
  "Spsb3",
  "Spsb4",
  "Spta1",
  "Sptan1",
  "Sptb",
  "Sptbn1",
  "Sptbn2",
  "Sptbn4",
  "Sptbn5",
  "Sptlc1",
  "Sptlc2",
  "Sptlc3",
  "Sptssa",
  "Sptssb",
  "Spty2d1",
  "Spx",
  "Spz1",
  "Sqle",
  "Sqor",
  "Sqstm1",
  "Sra1",
  "Srarp",
  "Srbd1",
  "Src",
  "Srcap",
  "Srcin1",
  "Srd5a1",
  "Srd5a2",
  "Srd5a3",
  "Srebf1",
  "Srebf2",
  "Srek1",
  "Srek1ip1",
  "Srf",
  "Srfbp1",
  "Srgap1",
  "Srgap2",
  "Srgap3",
  "Srgn",
  "Sri",
  "Srl",
  "Srm",
  "Srms",
  "Srp14",
  "Srp19",
  "Srp68",
  "Srp72",
  "Srp9",
  "Srpk1",
  "Srpk3",
  "Srpr",
  "Srprb",
  "Srpx",
  "Srpx2",
  "Srr",
  "Srrd",
  "Srrm1",
  "Srrm2",
  "Srrm3",
  "Srrm3os",
  "Srrm4",
  "Srrt",
  "Srsf1",
  "Srsf10",
  "Srsf11",
  "Srsf12",
  "Srsf2",
  "Srsf3",
  "Srsf4",
  "Srsf6",
  "Srsf7",
  "Srsf9",
  "Srxn1",
  "Sry",
  "Ss18",
  "Ss18l1",
  "Ss18l2",
  "Ssb",
  "Ssbp1",
  "Ssbp2",
  "Ssbp3",
  "Ssbp4",
  "Ssc4d",
  "Ssc5d",
  "Ssh1",
  "Ssh2",
  "Ssh3",
  "Ssmem1",
  "Ssna1",
  "Sspn",
  "Sspo",
  "Ssr1",
  "Ssr2",
  "Ssr3",
  "Ssr4",
  "Ssrp1",
  "Sst",
  "Sstr1",
  "Sstr2",
  "Sstr3",
  "Sstr4",
  "Sstr5",
  "Ssu2",
  "Ssu72",
  "Ssx2ip",
  "Ssx9",
  "Ssxa1",
  "Ssxb10",
  "Ssxb6",
  "St13",
  "St14",
  "St18",
  "St3gal1",
  "St3gal2",
  "St3gal3",
  "St3gal4",
  "St3gal5",
  "St3gal6",
  "St6gal1",
  "St6gal2",
  "St6galnac1",
  "St6galnac2",
  "St6galnac3",
  "St6galnac4",
  "St6galnac5",
  "St6galnac6",
  "St7",
  "St7l",
  "St8sia1",
  "St8sia2",
  "St8sia3",
  "St8sia4",
  "St8sia5",
  "St8sia6",
  "Stab1",
  "Stab2",
  "Stac",
  "Stac2",
  "Stac3",
  "Stag1",
  "Stag2",
  "Stag3",
  "Stam",
  "Stam2",
  "Stambp",
  "Stambpl1",
  "Stap1",
  "Stap2",
  "Star",
  "Stard10",
  "Stard13",
  "Stard3",
  "Stard3nl",
  "Stard4",
  "Stard5",
  "Stard6",
  "Stard7",
  "Stard8",
  "Stard9",
  "Stat1",
  "Stat2",
  "Stat3",
  "Stat4",
  "Stat5a",
  "Stat5b",
  "Stat6",
  "Stau1",
  "Stau2",
  "Stbd1",
  "Stc1",
  "Stc2",
  "Steap1",
  "Steap2",
  "Steap3",
  "Steap4",
  "Stfa1",
  "Stfa2l1",
  "Stfa3",
  "Stil",
  "Stim1",
  "Stim2",
  "Stimate",
  "Sting1",
  "Stip1",
  "Stk10",
  "Stk11",
  "Stk11ip",
  "Stk16",
  "Stk17b",
  "Stk19",
  "Stk24",
  "Stk25",
  "Stk26",
  "Stk3",
  "Stk31",
  "Stk32a",
  "Stk32b",
  "Stk32c",
  "Stk33",
  "Stk35",
  "Stk36",
  "Stk38",
  "Stk38l",
  "Stk39",
  "Stk4",
  "Stk40",
  "Stkld1",
  "Stmn1",
  "Stmn2",
  "Stmn4",
  "Stmnd1",
  "Stn1",
  "Stom",
  "Stoml1",
  "Stoml2",
  "Stoml3",
  "Ston1",
  "Ston2",
  "Stox1",
  "Stox2",
  "Stpg1",
  "Stpg2",
  "Stpg3",
  "Stpg4",
  "Stra6",
  "Stra6l",
  "Stra8",
  "Strada",
  "Stradb",
  "Strap",
  "Strbp",
  "Strc",
  "Strip1",
  "Strip2",
  "Strn",
  "Strn3",
  "Strn4",
  "Stt3a",
  "Stt3b",
  "Stub1",
  "Stum",
  "Stx11",
  "Stx12",
  "Stx16",
  "Stx17",
  "Stx18",
  "Stx19",
  "Stx1a",
  "Stx1b",
  "Stx2",
  "Stx3",
  "Stx4a",
  "Stx5a",
  "Stx6",
  "Stx7",
  "Stx8",
  "Stxbp1",
  "Stxbp2",
  "Stxbp3",
  "Stxbp4",
  "Stxbp5",
  "Stxbp5l",
  "Stxbp6",
  "Styk1",
  "Styx",
  "Styxl1",
  "Sub1",
  "Sucla2",
  "Suclg1",
  "Suclg2",
  "Sucnr1",
  "Suco",
  "Suds3",
  "Sufu",
  "Sugct",
  "Sugp1",
  "Sugp2",
  "Sugt1",
  "Sulf1",
  "Sulf2",
  "Sult1a1",
  "Sult1b1",
  "Sult1c1",
  "Sult1c2",
  "Sult1d1",
  "Sult1e1",
  "Sult2a1",
  "Sult2a3",
  "Sult2a4",
  "Sult2a5",
  "Sult2a6",
  "Sult2a7",
  "Sult2a8",
  "Sult2b1",
  "Sult3a1",
  "Sult3a2",
  "Sult4a1",
  "Sult5a1",
  "Sult6b1",
  "Sult6b2",
  "Sumf1",
  "Sumf2",
  "Sumo1",
  "Sumo2",
  "Sumo3",
  "Sun1",
  "Sun2",
  "Sun3",
  "Sun5",
  "Suox",
  "Supt16",
  "Supt20",
  "Supt3",
  "Supt4a",
  "Supt5",
  "Supt6",
  "Supt7l",
  "Supv3l1",
  "Surf1",
  "Surf2",
  "Surf4",
  "Surf6",
  "Susd1",
  "Susd3",
  "Susd4",
  "Susd5",
  "Susd6",
  "Suv39h1",
  "Suv39h2",
  "Suz12",
  "Sv2a",
  "Sv2b",
  "Sv2c",
  "Sva",
  "Sval1",
  "Sval2",
  "Sval3",
  "Svbp",
  "Svep1",
  "Svil",
  "Svip",
  "Svop",
  "Svopl",
  "Svs1",
  "Svs2",
  "Svs3a",
  "Svs3b",
  "Svs4",
  "Svs5",
  "Svs6",
  "Swap70",
  "Swi5",
  "Swsap1",
  "Swt1",
  "Syap1",
  "Sybu",
  "Syce1",
  "Syce1l",
  "Syce2",
  "Syce3",
  "Sycn",
  "Sycp1",
  "Sycp2",
  "Sycp2l",
  "Sycp3",
  "Syde1",
  "Syde2",
  "Syf2",
  "Syk",
  "Sympk",
  "Syn1",
  "Syn2",
  "Syn3",
  "Syna",
  "Sync",
  "Syncrip",
  "Syndig1",
  "Syndig1l",
  "Syne1",
  "Syne2",
  "Syne3",
  "Syne4",
  "Syngap1",
  "Syngr1",
  "Syngr2",
  "Syngr3",
  "Syngr4",
  "Synj1",
  "Synj2",
  "Synj2bp",
  "Synm",
  "Synpo",
  "Synpo2",
  "Synpo2l",
  "Synpr",
  "Synrg",
  "Syp",
  "Sypl",
  "Sypl2",
  "Sys1",
  "Syt1",
  "Syt10",
  "Syt11",
  "Syt12",
  "Syt13",
  "Syt14",
  "Syt15",
  "Syt16",
  "Syt17",
  "Syt2",
  "Syt3",
  "Syt4",
  "Syt5",
  "Syt6",
  "Syt7",
  "Syt8",
  "Syt9",
  "Sytl1",
  "Sytl2",
  "Sytl3",
  "Sytl4",
  "Sytl5",
  "Syvn1",
  "Szt2",
  "T",
  "T2",
  "Taar1",
  "Taar2",
  "Taar3",
  "Taar4",
  "Taar5",
  "Taar6",
  "Taar7b",
  "Taar7d",
  "Taar7e",
  "Taar7f",
  "Taar8a",
  "Taar8b",
  "Taar9",
  "Tab1",
  "Tab2",
  "Tab3",
  "Tac1",
  "Tac2",
  "Tac4",
  "Tacc1",
  "Tacc2",
  "Tacc3",
  "Taco1",
  "Tacr1",
  "Tacr2",
  "Tacr3",
  "Tacstd2",
  "Tada1",
  "Tada2a",
  "Tada2b",
  "Tada3",
  "Taf1",
  "Taf10",
  "Taf11",
  "Taf12",
  "Taf13",
  "Taf15",
  "Taf1a",
  "Taf1b",
  "Taf1c",
  "Taf1d",
  "Taf2",
  "Taf3",
  "Taf4",
  "Taf4b",
  "Taf5",
  "Taf5l",
  "Taf6",
  "Taf6l",
  "Taf7",
  "Taf7l",
  "Taf7l2",
  "Taf8",
  "Taf9b",
  "Tafa1",
  "Tafa2",
  "Tafa3",
  "Tafa4",
  "Tafa5",
  "Tagln",
  "Tagln2",
  "Tagln3",
  "Tal1",
  "Tal2",
  "Taldo1",
  "Tamm41",
  "Tanc1",
  "Tanc2",
  "Tango2",
  "Tango6",
  "Tank",
  "Taok2",
  "Taok3",
  "Tap1",
  "Tap2",
  "Tapbp",
  "Tapbpl",
  "Tapt1",
  "Tarbp2",
  "Tardbp",
  "Tarm1",
  "Tars",
  "Tars2",
  "Tarsl2",
  "Tas1r1",
  "Tas1r2",
  "Tas1r3",
  "Tas2r102",
  "Tas2r103",
  "Tas2r104",
  "Tas2r105",
  "Tas2r106",
  "Tas2r107",
  "Tas2r108",
  "Tas2r109",
  "Tas2r110",
  "Tas2r113",
  "Tas2r114",
  "Tas2r115",
  "Tas2r116",
  "Tas2r118",
  "Tas2r119",
  "Tas2r120",
  "Tas2r121",
  "Tas2r122",
  "Tas2r123",
  "Tas2r124",
  "Tas2r125",
  "Tas2r126",
  "Tas2r129",
  "Tas2r130",
  "Tas2r131",
  "Tas2r134",
  "Tas2r135",
  "Tas2r136",
  "Tas2r137",
  "Tas2r138",
  "Tas2r139",
  "Tas2r140",
  "Tas2r143",
  "Tas2r144",
  "Tasor",
  "Tasor2",
  "Tasp1",
  "Tat",
  "Tatdn1",
  "Tatdn2",
  "Tatdn3",
  "Tax1bp1",
  "Tax1bp3",
  "Taz",
  "Tbata",
  "Tbc1d1",
  "Tbc1d10a",
  "Tbc1d10b",
  "Tbc1d10c",
  "Tbc1d12",
  "Tbc1d13",
  "Tbc1d14",
  "Tbc1d15",
  "Tbc1d16",
  "Tbc1d17",
  "Tbc1d19",
  "Tbc1d2",
  "Tbc1d20",
  "Tbc1d21",
  "Tbc1d22a",
  "Tbc1d22b",
  "Tbc1d22bos",
  "Tbc1d23",
  "Tbc1d24",
  "Tbc1d25",
  "Tbc1d2b",
  "Tbc1d30",
  "Tbc1d31",
  "Tbc1d32",
  "Tbc1d4",
  "Tbc1d5",
  "Tbc1d7",
  "Tbc1d8",
  "Tbc1d8b",
  "Tbc1d9",
  "Tbc1d9b",
  "Tbca",
  "Tbcb",
  "Tbcc",
  "Tbccd1",
  "Tbcd",
  "Tbce",
  "Tbcel",
  "Tbck",
  "Tbk1",
  "Tbkbp1",
  "Tbl1x",
  "Tbl1xr1",
  "Tbl2",
  "Tbl3",
  "Tbp",
  "Tbpl1",
  "Tbpl2",
  "Tbr1",
  "Tbrg1",
  "Tbrg4",
  "Tbx1",
  "Tbx10",
  "Tbx15",
  "Tbx18",
  "Tbx19",
  "Tbx2",
  "Tbx20",
  "Tbx21",
  "Tbx22",
  "Tbx3",
  "Tbx3os1",
  "Tbx4",
  "Tbx5",
  "Tbx6",
  "Tbxa2r",
  "Tbxas1",
  "Tc2n",
  "Tcaf1",
  "Tcaf2",
  "Tcaf3",
  "Tcaim",
  "Tcam1",
  "Tcap",
  "Tcea1",
  "Tcea2",
  "Tcea3",
  "Tceal1",
  "Tceal3",
  "Tceal5",
  "Tceal6",
  "Tceal7",
  "Tceal8",
  "Tceal9",
  "Tceanc",
  "Tceanc2",
  "Tcerg1l",
  "Tcf12",
  "Tcf15",
  "Tcf19",
  "Tcf20",
  "Tcf21",
  "Tcf23",
  "Tcf24",
  "Tcf25",
  "Tcf3",
  "Tcf4",
  "Tcf7",
  "Tcf7l1",
  "Tcf7l2",
  "Tcfl5",
  "Tchh",
  "Tchhl1",
  "Tchp",
  "Tcim",
  "Tcirg1",
  "Tcl1",
  "Tcl1b2",
  "Tcl1b3",
  "Tcl1b4",
  "Tcl1b5",
  "Tcn2",
  "Tcof1",
  "Tcp1",
  "Tcp10c",
  "Tcp11",
  "Tcp11l1",
  "Tcp11l2",
  "Tcp11x2",
  "Tcstv1",
  "Tcstv3",
  "Tcta",
  "Tcte1",
  "Tcte2",
  "Tcte3",
  "Tctex1d1",
  "Tctex1d2",
  "Tctex1d4",
  "Tctn1",
  "Tctn2",
  "Tctn3",
  "Tdg",
  "Tdgf1",
  "Tdh",
  "Tdo2",
  "Tdp1",
  "Tdp2",
  "Tdpoz1",
  "Tdpoz2",
  "Tdpoz3",
  "Tdpoz8",
  "Tdrd1",
  "Tdrd12",
  "Tdrd3",
  "Tdrd5",
  "Tdrd6",
  "Tdrd7",
  "Tdrd9",
  "Tdrkh",
  "Tdrp",
  "Tead1",
  "Tead2",
  "Tead3",
  "Tead4",
  "Tec",
  "Tecpr2",
  "Tecr",
  "Tecrl",
  "Tecta",
  "Tectb",
  "Tedc1",
  "Tedc2",
  "Teddm1a",
  "Teddm1b",
  "Teddm2",
  "Teddm3",
  "Tef",
  "Tefm",
  "Tek",
  "Tekt1",
  "Tekt2",
  "Tekt3",
  "Tekt4",
  "Tekt5",
  "Telo2",
  "Ten1",
  "Tenm1",
  "Tenm2",
  "Tenm3",
  "Tenm4",
  "Tent2",
  "Tent4a",
  "Tent4b",
  "Tent5a",
  "Tent5b",
  "Tent5c",
  "Tent5d",
  "Tep1",
  "Tepp",
  "Tepsin",
  "Terb1",
  "Terb2",
  "Terf1",
  "Terf2",
  "Terf2ip",
  "Tert",
  "Tes",
  "Tesc",
  "Tescl",
  "Tesk1",
  "Tesk2",
  "Tesmin",
  "Tespa1",
  "Tet1",
  "Tet2",
  "Tet3",
  "Tex10",
  "Tex101",
  "Tex11",
  "Tex12",
  "Tex13a",
  "Tex13b",
  "Tex13c1",
  "Tex13c2",
  "Tex14",
  "Tex15",
  "Tex16",
  "Tex19.1",
  "Tex19.2",
  "Tex2",
  "Tex21",
  "Tex22",
  "Tex24",
  "Tex26",
  "Tex261",
  "Tex264",
  "Tex28",
  "Tex29",
  "Tex30",
  "Tex33",
  "Tex35",
  "Tex36",
  "Tex37",
  "Tex38",
  "Tex43",
  "Tex44",
  "Tex45",
  "Tex46",
  "Tex47",
  "Tex48",
  "Tex50",
  "Tex52",
  "Tex55",
  "Tex9",
  "Tfam",
  "Tfap2a",
  "Tfap2b",
  "Tfap2c",
  "Tfap2d",
  "Tfap2e",
  "Tfap4",
  "Tfb1m",
  "Tfb2m",
  "Tfcp2",
  "Tfcp2l1",
  "Tfdp1",
  "Tfdp2",
  "Tfe3",
  "Tfeb",
  "Tfec",
  "Tff1",
  "Tff2",
  "Tff3",
  "Tfg",
  "Tfip11",
  "Tfpi",
  "Tfpi2",
  "Tfpt",
  "Tfr2",
  "Tfrc",
  "Tg",
  "Tgds",
  "Tgfa",
  "Tgfb1",
  "Tgfb1i1",
  "Tgfb2",
  "Tgfb3",
  "Tgfbi",
  "Tgfbr1",
  "Tgfbr2",
  "Tgfbr3",
  "Tgfbrap1",
  "Tgif1",
  "Tgif2",
  "Tgm1",
  "Tgm2",
  "Tgm3",
  "Tgm4",
  "Tgm5",
  "Tgm6",
  "Tgm7",
  "Tgoln1",
  "Tgs1",
  "Th",
  "Tha1",
  "Thada",
  "Thap1",
  "Thap11",
  "Thap12",
  "Thap2",
  "Thap3",
  "Thap4",
  "Thap7",
  "Thap8",
  "Thbd",
  "Thbs1",
  "Thbs2",
  "Thbs3",
  "Thbs4",
  "Theg",
  "Thegl",
  "Them4",
  "Them5",
  "Them6",
  "Them7",
  "Themis",
  "Themis2",
  "Themis3",
  "Thg1l",
  "Thnsl1",
  "Thnsl2",
  "Thoc1",
  "Thoc2",
  "Thoc2l",
  "Thoc3",
  "Thoc5",
  "Thoc6",
  "Thoc7",
  "Thop1",
  "Thpo",
  "Thra",
  "Thrap3",
  "Thrb",
  "Thrsp",
  "Thsd1",
  "Thsd4",
  "Thsd7a",
  "Thsd7b",
  "Thumpd1",
  "Thumpd2",
  "Thumpd3",
  "Thy1",
  "Thyn1",
  "Tia1",
  "Tial1",
  "Tiam1",
  "Tiam2",
  "Ticam1",
  "Ticam2",
  "Ticrr",
  "Tie1",
  "Tifab",
  "Tigar",
  "Tigd2",
  "Tigd3",
  "Tigd4",
  "Tigd5",
  "Tigit",
  "Timd2",
  "Timd4",
  "Timeless",
  "Timm10",
  "Timm10b",
  "Timm13",
  "Timm17a",
  "Timm17b",
  "Timm21",
  "Timm22",
  "Timm23",
  "Timm29",
  "Timm44",
  "Timm50",
  "Timm8a1",
  "Timm8a2",
  "Timm8b",
  "Timm9",
  "Timmdc1",
  "Timp1",
  "Timp2",
  "Timp3",
  "Timp4",
  "Tinag",
  "Tinagl1",
  "Tinf2",
  "Tiparp",
  "Tipin",
  "Tiprl",
  "Tirap",
  "Tjap1",
  "Tjp1",
  "Tjp2",
  "Tjp3",
  "Tk1",
  "Tk2",
  "Tkfc",
  "Tkt",
  "Tktl1",
  "Tktl2",
  "Tlcd1",
  "Tlcd2",
  "Tlcd4",
  "Tlcd5",
  "Tldc2",
  "Tle1",
  "Tle2",
  "Tle3",
  "Tle4",
  "Tle5",
  "Tle6",
  "Tle7",
  "Tlk1",
  "Tlk2",
  "Tll1",
  "Tll2",
  "Tln1",
  "Tln2",
  "Tlnrd1",
  "Tlr1",
  "Tlr11",
  "Tlr12",
  "Tlr13",
  "Tlr2",
  "Tlr3",
  "Tlr4",
  "Tlr5",
  "Tlr6",
  "Tlr7",
  "Tlr8",
  "Tlr9",
  "Tlx1",
  "Tlx2",
  "Tlx3",
  "Tm2d1",
  "Tm2d2",
  "Tm2d3",
  "Tm4sf1",
  "Tm4sf19",
  "Tm4sf4",
  "Tm4sf5",
  "Tm6sf1",
  "Tm6sf2",
  "Tm7sf2",
  "Tm7sf3",
  "Tm9sf2",
  "Tm9sf3",
  "Tm9sf4",
  "Tma16",
  "Tma7",
  "Tmbim1",
  "Tmbim4",
  "Tmbim6",
  "Tmbim7",
  "Tmc1",
  "Tmc2",
  "Tmc3",
  "Tmc4",
  "Tmc5",
  "Tmc6",
  "Tmc7",
  "Tmc8",
  "Tmcc1",
  "Tmcc2",
  "Tmcc3",
  "Tmco1",
  "Tmco2",
  "Tmco3",
  "Tmco4",
  "Tmco5",
  "Tmco5b",
  "Tmco6",
  "Tmed1",
  "Tmed10",
  "Tmed2",
  "Tmed3",
  "Tmed4",
  "Tmed5",
  "Tmed6",
  "Tmed7",
  "Tmed8",
  "Tmed9",
  "Tmeff1",
  "Tmeff2",
  "Tmem100",
  "Tmem101",
  "Tmem102",
  "Tmem104",
  "Tmem106a",
  "Tmem106b",
  "Tmem106c",
  "Tmem107",
  "Tmem108",
  "Tmem109",
  "Tmem11",
  "Tmem114",
  "Tmem115",
  "Tmem116",
  "Tmem117",
  "Tmem119",
  "Tmem120a",
  "Tmem120b",
  "Tmem121",
  "Tmem121b",
  "Tmem123",
  "Tmem125",
  "Tmem126b",
  "Tmem127",
  "Tmem128",
  "Tmem129",
  "Tmem130",
  "Tmem131",
  "Tmem131l",
  "Tmem132a",
  "Tmem132b",
  "Tmem132c",
  "Tmem132d",
  "Tmem132e",
  "Tmem134",
  "Tmem135",
  "Tmem138",
  "Tmem139",
  "Tmem140",
  "Tmem141",
  "Tmem143",
  "Tmem144",
  "Tmem145",
  "Tmem147",
  "Tmem147os",
  "Tmem14a",
  "Tmem14c",
  "Tmem150a",
  "Tmem150b",
  "Tmem150c",
  "Tmem150cos",
  "Tmem151a",
  "Tmem151b",
  "Tmem154",
  "Tmem156",
  "Tmem158",
  "Tmem159",
  "Tmem160",
  "Tmem161a",
  "Tmem161b",
  "Tmem163",
  "Tmem164",
  "Tmem165",
  "Tmem167",
  "Tmem167b",
  "Tmem168",
  "Tmem169",
  "Tmem17",
  "Tmem170",
  "Tmem170b",
  "Tmem171",
  "Tmem174",
  "Tmem175",
  "Tmem176a",
  "Tmem176b",
  "Tmem177",
  "Tmem178",
  "Tmem178b",
  "Tmem179",
  "Tmem179b",
  "Tmem18",
  "Tmem182",
  "Tmem183a",
  "Tmem184a",
  "Tmem184b",
  "Tmem184c",
  "Tmem185a",
  "Tmem185b",
  "Tmem186",
  "Tmem189",
  "Tmem19",
  "Tmem190",
  "Tmem191c",
  "Tmem192",
  "Tmem196",
  "Tmem198",
  "Tmem198b",
  "Tmem199",
  "Tmem200a",
  "Tmem200b",
  "Tmem200c",
  "Tmem201",
  "Tmem202",
  "Tmem203",
  "Tmem204",
  "Tmem205",
  "Tmem207",
  "Tmem208",
  "Tmem209",
  "Tmem210",
  "Tmem211",
  "Tmem212",
  "Tmem213",
  "Tmem214",
  "Tmem215",
  "Tmem216",
  "Tmem217",
  "Tmem218",
  "Tmem219",
  "Tmem220",
  "Tmem221",
  "Tmem222",
  "Tmem223",
  "Tmem225",
  "Tmem229a",
  "Tmem229b",
  "Tmem230",
  "Tmem231",
  "Tmem232",
  "Tmem233",
  "Tmem234",
  "Tmem235",
  "Tmem236",
  "Tmem237",
  "Tmem238",
  "Tmem238l",
  "Tmem240",
  "Tmem241",
  "Tmem242",
  "Tmem243",
  "Tmem245",
  "Tmem246",
  "Tmem247",
  "Tmem248",
  "Tmem249",
  "Tmem25",
  "Tmem250-ps",
  "Tmem251",
  "Tmem252",
  "Tmem253",
  "Tmem254a",
  "Tmem255a",
  "Tmem255b",
  "Tmem256",
  "Tmem258",
  "Tmem259",
  "Tmem26",
  "Tmem260",
  "Tmem262",
  "Tmem263",
  "Tmem267",
  "Tmem268",
  "Tmem269",
  "Tmem270",
  "Tmem273",
  "Tmem28",
  "Tmem29",
  "Tmem30a",
  "Tmem30b",
  "Tmem30c",
  "Tmem33",
  "Tmem35a",
  "Tmem35b",
  "Tmem37",
  "Tmem38a",
  "Tmem38b",
  "Tmem39a",
  "Tmem39b",
  "Tmem40",
  "Tmem41a",
  "Tmem41b",
  "Tmem42",
  "Tmem43",
  "Tmem44",
  "Tmem45a",
  "Tmem45a2",
  "Tmem45b",
  "Tmem47",
  "Tmem50a",
  "Tmem50b",
  "Tmem51",
  "Tmem51os1",
  "Tmem52",
  "Tmem52b",
  "Tmem53",
  "Tmem54",
  "Tmem59",
  "Tmem59l",
  "Tmem60",
  "Tmem61",
  "Tmem62",
  "Tmem63a",
  "Tmem63b",
  "Tmem63c",
  "Tmem64",
  "Tmem65",
  "Tmem67",
  "Tmem68",
  "Tmem69",
  "Tmem70",
  "Tmem71",
  "Tmem72",
  "Tmem74",
  "Tmem74b",
  "Tmem74bos",
  "Tmem79",
  "Tmem8",
  "Tmem80",
  "Tmem81",
  "Tmem82",
  "Tmem86a",
  "Tmem86b",
  "Tmem87a",
  "Tmem87b",
  "Tmem88",
  "Tmem88b",
  "Tmem89",
  "Tmem8b",
  "Tmem9",
  "Tmem91",
  "Tmem92",
  "Tmem94",
  "Tmem95",
  "Tmem97",
  "Tmem98",
  "Tmem9b",
  "Tmf1",
  "Tmie",
  "Tmigd1",
  "Tmigd3",
  "Tmod1",
  "Tmod2",
  "Tmod3",
  "Tmod4",
  "Tmpo",
  "Tmprss11a",
  "Tmprss11b",
  "Tmprss11c",
  "Tmprss11d",
  "Tmprss11e",
  "Tmprss11f",
  "Tmprss11g",
  "Tmprss12",
  "Tmprss13",
  "Tmprss15",
  "Tmprss2",
  "Tmprss3",
  "Tmprss4",
  "Tmprss5",
  "Tmprss6",
  "Tmprss7",
  "Tmprss9",
  "Tmsb15a",
  "Tmsb15b1",
  "Tmsb15l",
  "Tmsb4x",
  "Tmtc1",
  "Tmtc2",
  "Tmtc3",
  "Tmtc4",
  "Tmub1",
  "Tmub2",
  "Tmx1",
  "Tmx2",
  "Tmx3",
  "Tmx4",
  "Tnc",
  "Tnf",
  "Tnfaip1",
  "Tnfaip2",
  "Tnfaip3",
  "Tnfaip6",
  "Tnfaip8",
  "Tnfaip8l1",
  "Tnfaip8l2",
  "Tnfaip8l3",
  "Tnfrsf10b",
  "Tnfrsf11a",
  "Tnfrsf11b",
  "Tnfrsf12a",
  "Tnfrsf13b",
  "Tnfrsf13c",
  "Tnfrsf14",
  "Tnfrsf17",
  "Tnfrsf18",
  "Tnfrsf19",
  "Tnfrsf1a",
  "Tnfrsf21",
  "Tnfrsf22",
  "Tnfrsf23",
  "Tnfrsf25",
  "Tnfrsf26",
  "Tnfrsf4",
  "Tnfrsf8",
  "Tnfrsf9",
  "Tnfsf10",
  "Tnfsf11",
  "Tnfsf12",
  "Tnfsf13b",
  "Tnfsf13os",
  "Tnfsf14",
  "Tnfsf15",
  "Tnfsf18",
  "Tnfsf8",
  "Tnfsf9",
  "Tnfsfm13",
  "Tnik",
  "Tnip1",
  "Tnip2",
  "Tnip3",
  "Tnk1",
  "Tnk2",
  "Tnk2os",
  "Tnks",
  "Tnks1bp1",
  "Tnks2",
  "Tnmd",
  "Tnn",
  "Tnnc1",
  "Tnnc2",
  "Tnni1",
  "Tnni2",
  "Tnni3",
  "Tnni3k",
  "Tnnt1",
  "Tnnt2",
  "Tnnt3",
  "Tnp1",
  "Tnp2",
  "Tnpo1",
  "Tnpo2",
  "Tnpo3",
  "Tnr",
  "Tnrc18",
  "Tnrc6a",
  "Tnrc6b",
  "Tnrc6c",
  "Tns1",
  "Tns2",
  "Tns3",
  "Tns4",
  "Tnxb",
  "Tob1",
  "Tob2",
  "Toe1",
  "Togaram1",
  "Togaram2",
  "Tollip",
  "Tom1",
  "Tom1l1",
  "Tom1l2",
  "Tomm20",
  "Tomm20l",
  "Tomm22",
  "Tomm40",
  "Tomm40l",
  "Tomm5",
  "Tomm6",
  "Tomm7",
  "Tomm70a",
  "Tomt",
  "Tonsl",
  "Top1",
  "Top1mt",
  "Top2a",
  "Top2b",
  "Top3a",
  "Top3b",
  "Topaz1",
  "Topbp1",
  "Topors",
  "Toporsl",
  "Tor1a",
  "Tor1aip1",
  "Tor1aip2",
  "Tor1b",
  "Tor2a",
  "Tor3a",
  "Tor4a",
  "Tox",
  "Tox2",
  "Tox3",
  "Tox4",
  "Tpbg",
  "Tpbgl",
  "Tpbpa",
  "Tpbpb",
  "Tpcn1",
  "Tpcn2",
  "Tpd52",
  "Tpd52l1",
  "Tpd52l2",
  "Tpgs1",
  "Tpgs2",
  "Tph1",
  "Tph2",
  "Tpi1",
  "Tpk1",
  "Tpm1",
  "Tpm2",
  "Tpm3",
  "Tpm4",
  "Tpmt",
  "Tpo",
  "Tpp1",
  "Tpp2",
  "Tppp",
  "Tppp2",
  "Tppp3",
  "Tpr",
  "Tpra1",
  "Tprg",
  "Tprgl",
  "Tprkb",
  "Tprn",
  "Tpsab1",
  "Tpsb2",
  "Tpsg1",
  "Tpst1",
  "Tpst2",
  "Tpt1",
  "Tpte",
  "Tpx2",
  "Tra2a",
  "Trabd2b",
  "Tradd",
  "Traf1",
  "Traf2",
  "Traf3",
  "Traf3ip1",
  "Traf3ip2",
  "Traf3ip3",
  "Traf4",
  "Traf5",
  "Traf6",
  "Traf7",
  "Trafd1",
  "Traip",
  "Trak1",
  "Trak2",
  "Tram1",
  "Tram1l1",
  "Tram2",
  "Trank1",
  "Trap1",
  "Trap1a",
  "Trappc1",
  "Trappc10",
  "Trappc11",
  "Trappc12",
  "Trappc13",
  "Trappc2",
  "Trappc2l",
  "Trappc3",
  "Trappc3l",
  "Trappc4",
  "Trappc5",
  "Trappc6a",
  "Trappc6b",
  "Trappc8",
  "Trappc9",
  "Trarg1",
  "Trat1",
  "Trcg1",
  "Trdmt1",
  "Trdn",
  "Treh",
  "Trem2",
  "Trem3",
  "Treml1",
  "Treml2",
  "Treml4",
  "Trerf1",
  "Trex2",
  "Trf",
  "Trh",
  "Trhde",
  "Trhr",
  "Trhr2",
  "Triap1",
  "Trib1",
  "Trib2",
  "Trib3",
  "Tril",
  "Trim10",
  "Trim11",
  "Trim12a",
  "Trim12c",
  "Trim13",
  "Trim14",
  "Trim15",
  "Trim16",
  "Trim17",
  "Trim2",
  "Trim21",
  "Trim23",
  "Trim24",
  "Trim25",
  "Trim26",
  "Trim27",
  "Trim28",
  "Trim29",
  "Trim3",
  "Trim30a",
  "Trim30b",
  "Trim30c",
  "Trim30d",
  "Trim31",
  "Trim32",
  "Trim33",
  "Trim34a",
  "Trim35",
  "Trim36",
  "Trim37",
  "Trim38",
  "Trim39",
  "Trim40",
  "Trim41",
  "Trim42",
  "Trim43b",
  "Trim43c",
  "Trim44",
  "Trim45",
  "Trim46",
  "Trim47",
  "Trim5",
  "Trim50",
  "Trim52",
  "Trim54",
  "Trim55",
  "Trim56",
  "Trim58",
  "Trim59",
  "Trim6",
  "Trim60",
  "Trim61",
  "Trim62",
  "Trim63",
  "Trim65",
  "Trim66",
  "Trim67",
  "Trim68",
  "Trim69",
  "Trim7",
  "Trim71",
  "Trim75",
  "Trim8",
  "Trim80",
  "Trim9",
  "Triml1",
  "Triml2",
  "Trio",
  "Triobp",
  "Trip10",
  "Trip11",
  "Trip12",
  "Trip13",
  "Trip4",
  "Trip6",
  "Triqk",
  "Trir",
  "Trit1",
  "Trmo",
  "Trmt1",
  "Trmt10a",
  "Trmt10b",
  "Trmt10c",
  "Trmt11",
  "Trmt112",
  "Trmt12",
  "Trmt13",
  "Trmt1l",
  "Trmt2a",
  "Trmt2b",
  "Trmt44",
  "Trmt5",
  "Trmt6",
  "Trmt61a",
  "Trmt61b",
  "Trmt9b",
  "Trmu",
  "Trnau1ap",
  "Trnp1",
  "Trnt1",
  "Tro",
  "Troap",
  "Trp53bp1",
  "Trp53bp2",
  "Trp53cor1",
  "Trp53i11",
  "Trp53i13",
  "Trp53inp1",
  "Trp53inp2",
  "Trp53rka",
  "Trp53rkb",
  "Trp53tg5",
  "Trp63",
  "Trp73",
  "Trpa1",
  "Trpc1",
  "Trpc3",
  "Trpc4",
  "Trpc4ap",
  "Trpc5",
  "Trpc5os",
  "Trpc6",
  "Trpc7",
  "Trpd52l3",
  "Trpm1",
  "Trpm2",
  "Trpm3",
  "Trpm4",
  "Trpm5",
  "Trpm6",
  "Trpm7",
  "Trpm8",
  "Trps1",
  "Trpt1",
  "Trpv1",
  "Trpv2",
  "Trpv3",
  "Trpv4",
  "Trpv5",
  "Trpv6",
  "Trrap",
  "Trub1",
  "Trub2",
  "Try4",
  "Try5",
  "Tsacc",
  "Tsc1",
  "Tsc2",
  "Tsc22d1",
  "Tsc22d2",
  "Tsc22d3",
  "Tsc22d4",
  "Tsen15",
  "Tsen34",
  "Tsen54",
  "Tsfm",
  "Tsga10",
  "Tsga10ip",
  "Tsga13",
  "Tsga8",
  "Tshb",
  "Tshr",
  "Tshz1",
  "Tshz2",
  "Tshz3",
  "Tsix",
  "Tsks",
  "Tsku",
  "Tslp",
  "Tslrn1",
  "Tsn",
  "Tsnax",
  "Tsnaxip1",
  "Tspan1",
  "Tspan10",
  "Tspan11",
  "Tspan12",
  "Tspan13",
  "Tspan14",
  "Tspan15",
  "Tspan17",
  "Tspan18",
  "Tspan2",
  "Tspan3",
  "Tspan32",
  "Tspan32os",
  "Tspan33",
  "Tspan4",
  "Tspan5",
  "Tspan6",
  "Tspan7",
  "Tspan8",
  "Tspan9",
  "Tspear",
  "Tspo",
  "Tspo2",
  "Tspoap1",
  "Tspyl1",
  "Tspyl2",
  "Tspyl3",
  "Tspyl4",
  "Tspyl5",
  "Tsr1",
  "Tsr2",
  "Tsr3",
  "Tssc4",
  "Tssk1",
  "Tssk2",
  "Tssk3",
  "Tssk4",
  "Tssk5",
  "Tssk6",
  "Tst",
  "Tsta3",
  "Tstd1",
  "Tstd2",
  "Tstd3",
  "Tsx",
  "Ttbk1",
  "Ttbk2",
  "Ttc1",
  "Ttc12",
  "Ttc13",
  "Ttc14",
  "Ttc16",
  "Ttc17",
  "Ttc19",
  "Ttc21a",
  "Ttc21b",
  "Ttc22",
  "Ttc23",
  "Ttc23l",
  "Ttc24",
  "Ttc25",
  "Ttc26",
  "Ttc27",
  "Ttc28",
  "Ttc29",
  "Ttc3",
  "Ttc30a1",
  "Ttc30a2",
  "Ttc30b",
  "Ttc32",
  "Ttc33",
  "Ttc34",
  "Ttc36",
  "Ttc37",
  "Ttc38",
  "Ttc39a",
  "Ttc39b",
  "Ttc39c",
  "Ttc39d",
  "Ttc4",
  "Ttc41",
  "Ttc5",
  "Ttc6",
  "Ttc7",
  "Ttc7b",
  "Ttc8",
  "Ttc9",
  "Ttc9b",
  "Ttc9c",
  "Ttf1",
  "Ttf2",
  "Tti1",
  "Tti2",
  "Ttk",
  "Ttl",
  "Ttll1",
  "Ttll10",
  "Ttll11",
  "Ttll12",
  "Ttll13",
  "Ttll3",
  "Ttll4",
  "Ttll5",
  "Ttll6",
  "Ttll7",
  "Ttll8",
  "Ttll9",
  "Ttn",
  "Ttpa",
  "Ttpal",
  "Ttr",
  "Ttyh1",
  "Ttyh2",
  "Ttyh3",
  "Tub",
  "Tuba1a",
  "Tuba1b",
  "Tuba1c",
  "Tuba3a",
  "Tuba3b",
  "Tuba4a",
  "Tuba8",
  "Tubal3",
  "Tubb1",
  "Tubb2a",
  "Tubb2b",
  "Tubb3",
  "Tubb4a",
  "Tubb5",
  "Tubb6",
  "Tubd1",
  "Tube1",
  "Tubg1",
  "Tubg2",
  "Tubgcp2",
  "Tubgcp3",
  "Tubgcp4",
  "Tubgcp5",
  "Tubgcp6",
  "Tufm",
  "Tuft1",
  "Tug1",
  "Tulp1",
  "Tulp2",
  "Tulp3",
  "Tulp4",
  "Tunar",
  "Tusc1",
  "Tusc2",
  "Tusc3",
  "Tut1",
  "Tut4",
  "Tut7",
  "Tvp23a",
  "Tvp23b",
  "Twf1",
  "Twf2",
  "Twist1",
  "Twist2",
  "Twistnb",
  "Twnk",
  "Twsg1",
  "Txk",
  "Txlna",
  "Txlnb",
  "Txlng",
  "Txn1",
  "Txn2",
  "Txndc11",
  "Txndc12",
  "Txndc15",
  "Txndc16",
  "Txndc17",
  "Txndc2",
  "Txndc5",
  "Txndc8",
  "Txndc9",
  "Txnip",
  "Txnl1",
  "Txnl4a",
  "Txnl4b",
  "Txnrd1",
  "Txnrd2",
  "Txnrd3",
  "Tyk2",
  "Tyms",
  "Tyr",
  "Tyro3",
  "Tyrobp",
  "Tyrp1",
  "Tysnd1",
  "Tyw1",
  "Tyw3",
  "Tyw5",
  "U2af1",
  "U2af2",
  "U2surp",
  "U90926",
  "Uaca",
  "Uap1",
  "Uap1l1",
  "Uba1",
  "Uba1y",
  "Uba2",
  "Uba3",
  "Uba5",
  "Uba6",
  "Uba7",
  "Ubac1",
  "Ubac2",
  "Ubald1",
  "Ubald2",
  "Ubap1",
  "Ubap1l",
  "Ubap2",
  "Ubash3a",
  "Ubash3b",
  "Ubc",
  "Ubd",
  "Ube2a",
  "Ube2b",
  "Ube2c",
  "Ube2d1",
  "Ube2d2a",
  "Ube2d2b",
  "Ube2d3",
  "Ube2dnl1",
  "Ube2dnl2",
  "Ube2e1",
  "Ube2e2",
  "Ube2e3",
  "Ube2f",
  "Ube2g1",
  "Ube2g2",
  "Ube2i",
  "Ube2j1",
  "Ube2j2",
  "Ube2k",
  "Ube2l3",
  "Ube2l6",
  "Ube2m",
  "Ube2n",
  "Ube2o",
  "Ube2q1",
  "Ube2q2",
  "Ube2ql1",
  "Ube2r2",
  "Ube2s",
  "Ube2t",
  "Ube2u",
  "Ube2v2",
  "Ube2w",
  "Ube2z",
  "Ube3a",
  "Ube3b",
  "Ube3c",
  "Ube4a",
  "Ube4b",
  "Ube4bos1",
  "Ubfd1",
  "Ubiad1",
  "Ubl3",
  "Ubl4a",
  "Ubl4b",
  "Ubl5",
  "Ubl7",
  "Ublcp1",
  "Ubn1",
  "Ubn2",
  "Ubox5",
  "Ubp1",
  "Ubqln1",
  "Ubqln2",
  "Ubqln3",
  "Ubqln4",
  "Ubqln5",
  "Ubqlnl",
  "Ubr1",
  "Ubr2",
  "Ubr3",
  "Ubr4",
  "Ubr5",
  "Ubr7",
  "Ubtd1",
  "Ubtf",
  "Ubtfl1",
  "Ubxn1",
  "Ubxn10",
  "Ubxn11",
  "Ubxn2a",
  "Ubxn2b",
  "Ubxn4",
  "Ubxn6",
  "Ubxn7",
  "Ubxn8",
  "Uchl1",
  "Uchl3",
  "Uchl5",
  "Uck1",
  "Uck2",
  "Uckl1",
  "Ucma",
  "Ucn2",
  "Ucn3",
  "Ucp1",
  "Ucp2",
  "Ucp3",
  "Uevld",
  "Ufc1",
  "Ufd1",
  "Ufl1",
  "Ufm1",
  "Ufsp1",
  "Ufsp2",
  "Ugcg",
  "Ugdh",
  "Uggt1",
  "Uggt2",
  "Ugp2",
  "Ugt1a7c",
  "Ugt1a8",
  "Ugt2a3",
  "Ugt2b1",
  "Ugt2b34",
  "Ugt2b35",
  "Ugt2b37",
  "Ugt2b38",
  "Ugt2b5",
  "Ugt3a1",
  "Ugt3a2",
  "Ugt8a",
  "Uhmk1",
  "Uhrf1",
  "Uhrf1bp1",
  "Uhrf1bp1l",
  "Uhrf2",
  "Uimc1",
  "Ulbp1",
  "Ulk1",
  "Ulk2",
  "Ulk3",
  "Ulk4",
  "Umad1",
  "Umod",
  "Umodl1",
  "Umps",
  "Unc119",
  "Unc119b",
  "Unc13a",
  "Unc13b",
  "Unc13c",
  "Unc13d",
  "Unc45a",
  "Unc45b",
  "Unc45bos",
  "Unc50",
  "Unc5a",
  "Unc5b",
  "Unc5c",
  "Unc5cl",
  "Unc5d",
  "Unc79",
  "Unc80",
  "Unc93a2",
  "Unc93b1",
  "Uncx",
  "Unk",
  "Unkl",
  "Uox",
  "Upb1",
  "Upf1",
  "Upf2",
  "Upf3a",
  "Upf3b",
  "Upk1a",
  "Upk1b",
  "Upk2",
  "Upk3a",
  "Upk3b",
  "Upk3bl",
  "Upp1",
  "Upp2",
  "Uprt",
  "Uqcc1",
  "Uqcc2",
  "Uqcc3",
  "Uqcr10",
  "Uqcr11",
  "Uqcrb",
  "Uqcrc1",
  "Uqcrc2",
  "Uqcrfs1",
  "Uqcrh",
  "Uqcrq",
  "Urad",
  "Urah",
  "Urb1",
  "Urb2",
  "Urgcp",
  "Uri1",
  "Urm1",
  "Uroc1",
  "Urod",
  "Uros",
  "Usb1",
  "Use1",
  "Usf2",
  "Usf3",
  "Ush1c",
  "Ush1g",
  "Ush2a",
  "Ushbp1",
  "Uso1",
  "Usp1",
  "Usp10",
  "Usp11",
  "Usp12",
  "Usp13",
  "Usp14",
  "Usp15",
  "Usp16",
  "Usp17la",
  "Usp17lb",
  "Usp17lc",
  "Usp17ld",
  "Usp17le",
  "Usp18",
  "Usp19",
  "Usp2",
  "Usp20",
  "Usp21",
  "Usp22",
  "Usp24",
  "Usp25",
  "Usp26",
  "Usp27x",
  "Usp28",
  "Usp29",
  "Usp3",
  "Usp30",
  "Usp31",
  "Usp32",
  "Usp33",
  "Usp34",
  "Usp35",
  "Usp36",
  "Usp37",
  "Usp38",
  "Usp39",
  "Usp4",
  "Usp40",
  "Usp42",
  "Usp43",
  "Usp44",
  "Usp45",
  "Usp46",
  "Usp47",
  "Usp48",
  "Usp49",
  "Usp5",
  "Usp50",
  "Usp51",
  "Usp53",
  "Usp54",
  "Usp6nl",
  "Usp7",
  "Usp9x",
  "Usp9y",
  "Uspl1",
  "Ust",
  "Utf1",
  "Utp11",
  "Utp14a",
  "Utp15",
  "Utp18",
  "Utp20",
  "Utp23",
  "Utp25",
  "Utp3",
  "Utp4",
  "Utp6",
  "Utrn",
  "Uts2",
  "Uts2b",
  "Uts2r",
  "Uty",
  "Uvrag",
  "Uvssa",
  "Uxs1",
  "Uxt",
  "V1ra8",
  "V1rd19",
  "Vac14",
  "Vamp1",
  "Vamp2",
  "Vamp3",
  "Vamp4",
  "Vamp8",
  "Vamp9",
  "Vangl1",
  "Vangl2",
  "Vapa",
  "Vapb",
  "Vars",
  "Vars2",
  "Vash1",
  "Vash2",
  "Vasn",
  "Vasp",
  "Vat1",
  "Vat1l",
  "Vav1",
  "Vav2",
  "Vav3",
  "Vax1",
  "Vax2",
  "Vax2os",
  "Vbp1",
  "Vcam1",
  "Vcan",
  "Vcl",
  "Vcp",
  "Vcpip1",
  "Vcpkmt",
  "Vdac1",
  "Vdac2",
  "Vdac3",
  "Vdr",
  "Vegfa",
  "Vegfb",
  "Vegfc",
  "Vegfd",
  "Veph1",
  "Vezf1",
  "Vezt",
  "Vgf",
  "Vgll1",
  "Vgll2",
  "Vgll3",
  "Vgll4",
  "Vhl",
  "Vil1",
  "Vill",
  "Vim",
  "Vinac1",
  "Vip",
  "Vipas39",
  "Vipr1",
  "Vipr2",
  "Virma",
  "Vit",
  "Vkorc1",
  "Vldlr",
  "Vma21",
  "Vmac",
  "Vmn1r1",
  "Vmn1r10",
  "Vmn1r11",

  "Vmn1r12",
  "Vmn1r13",
  "Vmn1r139",
  "Vmn1r14",
  "Vmn1r15",
  "Vmn1r16",
  "Vmn1r167",
  "Vmn1r168",
  "Vmn1r170",
  "Vmn1r171",
  "Vmn1r174",
  "Vmn1r175",
  "Vmn1r178",
  "Vmn1r179",
  "Vmn1r18",
  "Vmn1r180",
  "Vmn1r181",
  "Vmn1r183",
  "Vmn1r185",
  "Vmn1r188",
  "Vmn1r19",
  "Vmn1r191",
  "Vmn1r192",
  "Vmn1r193",
  "Vmn1r194",
  "Vmn1r195",
  "Vmn1r196",
  "Vmn1r197",
  "Vmn1r198",
  "Vmn1r199",
  "Vmn1r20",
  "Vmn1r200",
  "Vmn1r201",
  "Vmn1r202",
  "Vmn1r203",
  "Vmn1r204",
  "Vmn1r205",
  "Vmn1r206",
  "Vmn1r207",
  "Vmn1r209",
  "Vmn1r21",
  "Vmn1r210",
  "Vmn1r211",
  "Vmn1r212",
  "Vmn1r213",
  "Vmn1r214",
  "Vmn1r215",
  "Vmn1r217",
  "Vmn1r218",
  "Vmn1r219",
  "Vmn1r22",
  "Vmn1r220",
  "Vmn1r222",
  "Vmn1r223",
  "Vmn1r224",
  "Vmn1r225",
  "Vmn1r226",
  "Vmn1r227",
  "Vmn1r228",
  "Vmn1r229",
  "Vmn1r23",
  "Vmn1r230",
  "Vmn1r231",
  "Vmn1r232",
  "Vmn1r233",
  "Vmn1r234",
  "Vmn1r235",
  "Vmn1r236",
  "Vmn1r237",
  "Vmn1r24",
  "Vmn1r25",
  "Vmn1r26",
  "Vmn1r27",
  "Vmn1r30",
  "Vmn1r31",
  "Vmn1r32",
  "Vmn1r33",
  "Vmn1r34",
  "Vmn1r35",
  "Vmn1r36",
  "Vmn1r37",
  "Vmn1r38",
  "Vmn1r39",
  "Vmn1r4",
  "Vmn1r40",
  "Vmn1r42",
  "Vmn1r43",
  "Vmn1r44",
  "Vmn1r45",
  "Vmn1r46",
  "Vmn1r47",
  "Vmn1r49",
  "Vmn1r5",
  "Vmn1r50",
  "Vmn1r51",
  "Vmn1r52",
  "Vmn1r53",
  "Vmn1r54",
  "Vmn1r55",
  "Vmn1r57",
  "Vmn1r58",
  "Vmn1r59",
  "Vmn1r6",
  "Vmn1r61",
  "Vmn1r62",
  "Vmn1r63",
  "Vmn1r64",
  "Vmn1r65",
  "Vmn1r66",
  "Vmn1r68",
  "Vmn1r69",
  "Vmn1r7",
  "Vmn1r70",
  "Vmn1r71",
  "Vmn1r72",
  "Vmn1r73",
  "Vmn1r74",
  "Vmn1r75",
  "Vmn1r76",
  "Vmn1r78",
  "Vmn1r8",
  "Vmn1r80",
  "Vmn1r81",
  "Vmn1r82",
  "Vmn1r83",
  "Vmn1r84",
  "Vmn1r85",
  "Vmn1r86",
  "Vmn1r87",
  "Vmn1r88",
  "Vmn1r89",
  "Vmn1r9",
  "Vmn1r91",
  "Vmn2r1",
  "Vmn2r10",
  "Vmn2r100",
  "Vmn2r101",
  "Vmn2r102",
  "Vmn2r103",
  "Vmn2r104",
  "Vmn2r105",
  "Vmn2r106",
  "Vmn2r107",
  "Vmn2r108",
  "Vmn2r109",
  "Vmn2r110",
  "Vmn2r111",
  "Vmn2r112",
  "Vmn2r113",
  "Vmn2r114",
  "Vmn2r116",
  "Vmn2r118",
  "Vmn2r120",
  "Vmn2r121",
  "Vmn2r13",
  "Vmn2r15",
  "Vmn2r17",
  "Vmn2r19",
  "Vmn2r23",
  "Vmn2r24",
  "Vmn2r25",
  "Vmn2r26",
  "Vmn2r27",
  "Vmn2r28",
  "Vmn2r3",
  "Vmn2r4",
  "Vmn2r52",
  "Vmn2r53",
  "Vmn2r54",
  "Vmn2r55",
  "Vmn2r59",
  "Vmn2r60",
  "Vmn2r61",
  "Vmn2r65",
  "Vmn2r66",
  "Vmn2r67",
  "Vmn2r68",
  "Vmn2r69",
  "Vmn2r70",
  "Vmn2r71",
  "Vmn2r72",
  "Vmn2r73",
  "Vmn2r75",
  "Vmn2r76",
  "Vmn2r80",
  "Vmn2r81",
  "Vmn2r83",
  "Vmn2r86",
  "Vmn2r88",
  "Vmn2r89",
  "Vmn2r90",
  "Vmn2r94",
  "Vmn2r97",
  "Vmn2r98",
  "Vmn2r99",
  "Vmo1",
  "Vmp1",
  "Vnn1",
  "Vnn3",
  "Vopp1",
  "Vpreb1",
  "Vpreb3",
  "Vps11",
  "Vps13a",
  "Vps13b",
  "Vps13c",
  "Vps13d",
  "Vps16",
  "Vps18",
  "Vps26a",
  "Vps26b",
  "Vps26c",
  "Vps28",
  "Vps29",
  "Vps33a",
  "Vps33b",
  "Vps35",
  "Vps35l",
  "Vps36",
  "Vps37a",
  "Vps37b",
  "Vps37c",
  "Vps37d",
  "Vps39",
  "Vps41",
  "Vps45",
  "Vps4a",
  "Vps4b",
  "Vps50",
  "Vps52",
  "Vps53",
  "Vps54",
  "Vps8",
  "Vps9d1",
  "Vrk1",
  "Vrk2",
  "Vrk3",
  "Vrtn",
  "Vsig1",
  "Vsig10",
  "Vsig10l",
  "Vsig2",
  "Vsig4",
  "Vsig8",
  "Vsir",
  "Vsnl1",
  "Vstm2a",
  "Vstm2b",
  "Vstm2l",
  "Vstm4",
  "Vstm5",
  "Vsx1",
  "Vsx2",
  "Vta1",
  "Vtcn1",
  "Vti1a",
  "Vti1b",
  "Vtn",
  "Vwa1",
  "Vwa2",
  "Vwa3a",
  "Vwa3b",
  "Vwa5a",
  "Vwa5b1",
  "Vwa5b2",
  "Vwa7",
  "Vwa8",
  "Vwc2",
  "Vwc2l",
  "Vwce",
  "Vwde",
  "Vwf",
  "Vxn",
  "Wac",
  "Wap",
  "Wapl",
  "Wars",
  "Wars2",
  "Was",
  "Wasf1",
  "Wasf2",
  "Wasf3",
  "Washc1",
  "Washc2",
  "Washc3",
  "Washc4",
  "Washc5",
  "Wasl",
  "Wbp1",
  "Wbp11",
  "Wbp1l",
  "Wbp2",
  "Wbp2nl",
  "Wbp4",
  "Wdcp",
  "Wdfy1",
  "Wdfy2",
  "Wdfy3",
  "Wdfy4",
  "Wdhd1",
  "Wdpcp",
  "Wdr1",
  "Wdr11",
  "Wdr12",
  "Wdr13",
  "Wdr17",
  "Wdr18",
  "Wdr19",
  "Wdr20",
  "Wdr20rt",
  "Wdr24",
  "Wdr25",
  "Wdr26",
  "Wdr27",
  "Wdr3",
  "Wdr31",
  "Wdr33",
  "Wdr34",
  "Wdr35",
  "Wdr36",
  "Wdr37",
  "Wdr38",
  "Wdr4",
  "Wdr41",
  "Wdr43",
  "Wdr44",
  "Wdr45",
  "Wdr45b",
  "Wdr46",
  "Wdr47",
  "Wdr48",
  "Wdr49",
  "Wdr5",
  "Wdr53",
  "Wdr54",
  "Wdr55",
  "Wdr59",
  "Wdr5b",
  "Wdr6",
  "Wdr60",
  "Wdr61",
  "Wdr62",
  "Wdr63",
  "Wdr64",
  "Wdr66",
  "Wdr7",
  "Wdr70",
  "Wdr72",
  "Wdr73",
  "Wdr74",
  "Wdr75",
  "Wdr76",
  "Wdr77",
  "Wdr78",
  "Wdr81",
  "Wdr82",
  "Wdr83",
  "Wdr83os",
  "Wdr86",
  "Wdr89",
  "Wdr90",
  "Wdr91",
  "Wdr93",
  "Wdr95",
  "Wdsub1",
  "Wdtc1",
  "Wdyhv1",
  "Wee1",
  "Wee2",
  "Wfdc1",
  "Wfdc10",
  "Wfdc11",
  "Wfdc12",
  "Wfdc13",
  "Wfdc15a",
  "Wfdc15b",
  "Wfdc16",
  "Wfdc17",
  "Wfdc18",
  "Wfdc2",
  "Wfdc21",
  "Wfdc3",
  "Wfdc5",
  "Wfdc6a",
  "Wfdc6b",
  "Wfdc8",
  "Wfdc9",
  "Wfikkn1",
  "Wfikkn2",
  "Whamm",
  "Whrn",
  "Wif1",
  "Wincr1",
  "Wipf1",
  "Wipf2",
  "Wipf3",
  "Wipi1",
  "Wipi2",
  "Wiz",
  "Wls",
  "Wnk1",
  "Wnk2",
  "Wnk3",
  "Wnk4",
  "Wnt1",
  "Wnt10a",
  "Wnt10b",
  "Wnt11",
  "Wnt16",
  "Wnt2",
  "Wnt2b",
  "Wnt3",
  "Wnt3a",
  "Wnt4",
  "Wnt5b",
  "Wnt6",
  "Wnt7a",
  "Wnt7b",
  "Wnt8a",
  "Wnt8b",
  "Wnt9a",
  "Wnt9b",
  "Wrap53",
  "Wrap73",
  "Wrn",
  "Wrnip1",
  "Wsb1",
  "Wscd1",
  "Wscd2",
  "Wt1",
  "Wt1os",
  "Wtap",
  "Wtip",
  "Wwc1",
  "Wwc2",
  "Wwox",
  "Wwp1",
  "Wwp2",
  "Wwtr1",
  "Xab2",
  "Xaf1",
  "Xbp1",
  "Xcl1",
  "Xcr1",
  "Xdh",
  "Xiap",
  "Xirp1",
  "Xirp2",
  "Xist",
  "Xk",
  "Xkr4",
  "Xkr5",
  "Xkr6",
  "Xkr7",
  "Xkr8",
  "Xkr9",
  "Xkrx",
  "Xlr",
  "Xlr5c",
  "Xpa",
  "Xpc",
  "Xpnpep1",
  "Xpnpep2",
  "Xpnpep3",
  "Xpo1",
  "Xpo4",
  "Xpo5",
  "Xpo6",
  "Xpo7",
  "Xpot",
  "Xpr1",
  "Xrcc1",
  "Xrcc2",
  "Xrcc3",
  "Xrcc4",
  "Xrcc5",
  "Xrcc6",
  "Xrn1",
  "Xrn2",
  "Xrra1",
  "Xxylt1",
  "Xylt1",
  "Xylt2",
  "Yae1d1",
  "Yaf2",
  "Yap1",
  "Yars",
  "Yars2",
  "Ybey",
  "Ybx1",
  "Ybx2",
  "Ybx3",
  "Ydjc",
  "Yeats2",
  "Yeats4",
  "Yes1",
  "Yif1a",
  "Yif1b",
  "Yipf1",
  "Yipf2",
  "Yipf3",
  "Yipf4",
  "Yipf5",
  "Yipf6",
  "Yipf7",
  "Yjefn3",
  "Yju2",
  "Ykt6",
  "Ylpm1",
  "Yme1l1",
  "Yod1",
  "Ypel1",
  "Ypel2",
  "Ypel3",
  "Ypel4",
  "Ypel5",
  "Yrdc",
  "Ythdc1",
  "Ythdc2",
  "Ythdf1",
  "Ythdf2",
  "Ythdf3",
  "Ywhab",
  "Ywhae",
  "Ywhag",
  "Ywhah",
  "Ywhaq",
  "Ywhaz",
  "Yy1",
  "Zadh2",
  "Zan",
  "Zap70",
  "Zar1",
  "Zar1l",
  "Zbbx",
  "Zbed3",
  "Zbed4",
  "Zbed5",
  "Zbp1",
  "Zbtb1",
  "Zbtb10",
  "Zbtb11",
  "Zbtb11os1",
  "Zbtb12",
  "Zbtb14",
  "Zbtb16",
  "Zbtb18",
  "Zbtb2",
  "Zbtb20",
  "Zbtb21",
  "Zbtb22",
  "Zbtb24",
  "Zbtb25",
  "Zbtb26",
  "Zbtb3",
  "Zbtb32",
  "Zbtb33",
  "Zbtb34",
  "Zbtb37",
  "Zbtb38",
  "Zbtb39",
  "Zbtb4",
  "Zbtb40",
  "Zbtb41",
  "Zbtb42",
  "Zbtb43",
  "Zbtb44",
  "Zbtb45",
  "Zbtb46",
  "Zbtb48",
  "Zbtb49",
  "Zbtb5",
  "Zbtb6",
  "Zbtb7a",
  "Zbtb7b",
  "Zbtb7c",
  "Zbtb8a",
  "Zbtb8b",
  "Zbtb8os",
  "Zc2hc1a",
  "Zc2hc1b",
  "Zc2hc1c",
  "Zc3h10",
  "Zc3h12a",
  "Zc3h12b",
  "Zc3h12c",
  "Zc3h12d",
  "Zc3h13",
  "Zc3h14",
  "Zc3h15",
  "Zc3h18",
  "Zc3h3",
  "Zc3h4",
  "Zc3h6",
  "Zc3h7a",
  "Zc3h7b",
  "Zc3h8",
  "Zc3hav1",
  "Zc3hav1l",
  "Zc3hc1",
  "Zc4h2",
  "Zcchc10",
  "Zcchc12",
  "Zcchc13",
  "Zcchc14",
  "Zcchc17",
  "Zcchc18",
  "Zcchc2",
  "Zcchc24",
  "Zcchc3",
  "Zcchc4",
  "Zcchc7",
  "Zcchc8",
  "Zcchc9",
  "Zcrb1",
  "Zcwpw1",
  "Zdbf2",
  "Zdhhc1",
  "Zdhhc11",
  "Zdhhc12",
  "Zdhhc13",
  "Zdhhc14",
  "Zdhhc15",
  "Zdhhc16",
  "Zdhhc17",
  "Zdhhc18",
  "Zdhhc19",
  "Zdhhc2",
  "Zdhhc20",
  "Zdhhc21",
  "Zdhhc22",
  "Zdhhc23",
  "Zdhhc24",
  "Zdhhc25",
  "Zdhhc3",
  "Zdhhc4",
  "Zdhhc5",
  "Zdhhc6",
  "Zdhhc7",
  "Zdhhc8",
  "Zdhhc9",
  "Zeb1",
  "Zeb2",
  "Zeb2os",
  "Zer1",
  "Zfand1",
  "Zfand2a",
  "Zfand2b",
  "Zfand3",
  "Zfand4",
  "Zfand5",
  "Zfand6",
  "Zfas1",
  "Zfat",
  "Zfc3h1",
  "Zfhx2",
  "Zfhx2os",
  "Zfhx3",
  "Zfhx4",
  "Zfp1",
  "Zfp101",
  "Zfp105",
  "Zfp106",
  "Zfp108",
  "Zfp109",
  "Zfp11",
  "Zfp110",
  "Zfp111",
  "Zfp112",
  "Zfp113",
  "Zfp114",
  "Zfp119a",
  "Zfp119b",
  "Zfp12",
  "Zfp120",
  "Zfp128",
  "Zfp13",
  "Zfp131",
  "Zfp14",
  "Zfp141",
  "Zfp142",
  "Zfp143",
  "Zfp146",
  "Zfp148",
  "Zfp157",
  "Zfp160",
  "Zfp169",
  "Zfp174",
  "Zfp180",
  "Zfp182",
  "Zfp184",
  "Zfp185",
  "Zfp189",
  "Zfp2",
  "Zfp202",
  "Zfp207",
  "Zfp212",
  "Zfp213",
  "Zfp217",
  "Zfp219",
  "Zfp229",
  "Zfp235",
  "Zfp236",
  "Zfp239",
  "Zfp24",
  "Zfp248",
  "Zfp251",
  "Zfp26",
  "Zfp260",
  "Zfp263",
  "Zfp266",
  "Zfp267",
  "Zfp27",
  "Zfp273",
  "Zfp275",
  "Zfp276",
  "Zfp277",
  "Zfp28",
  "Zfp280b",
  "Zfp280c",
  "Zfp280d",
  "Zfp281",
  "Zfp282",
  "Zfp286",
  "Zfp287",
  "Zfp292",
  "Zfp296",
  "Zfp3",
  "Zfp30",
  "Zfp300",
  "Zfp316",
  "Zfp317",
  "Zfp318",
  "Zfp319",
  "Zfp322a",
  "Zfp324",
  "Zfp326",
  "Zfp329",
  "Zfp330",
  "Zfp334",
  "Zfp335",
  "Zfp335os",
  "Zfp341",
  "Zfp345",
  "Zfp346",
  "Zfp35",
  "Zfp354a",
  "Zfp354b",
  "Zfp354c",
  "Zfp358",
  "Zfp362",
  "Zfp365",
  "Zfp366",
  "Zfp367",
  "Zfp369",
  "Zfp36l1",
  "Zfp36l2",
  "Zfp36l3",
  "Zfp37",
  "Zfp383",
  "Zfp385a",
  "Zfp385b",
  "Zfp385c",
  "Zfp386",
  "Zfp39",
  "Zfp395",
  "Zfp397",
  "Zfp398",
  "Zfp40",
  "Zfp407",
  "Zfp408",
  "Zfp41",
  "Zfp410",
  "Zfp414",
  "Zfp418",
  "Zfp42",
  "Zfp420",
  "Zfp422",
  "Zfp423",
  "Zfp426",
  "Zfp428",
  "Zfp429",
  "Zfp438",
  "Zfp442",
  "Zfp444",
  "Zfp445",
  "Zfp446",
  "Zfp449",
  "Zfp451",
  "Zfp454",
  "Zfp455",
  "Zfp456",
  "Zfp457",
  "Zfp458",
  "Zfp459",
  "Zfp46",
  "Zfp462",
  "Zfp467",
  "Zfp469",
  "Zfp472",
  "Zfp473",
  "Zfp474",
  "Zfp488",
  "Zfp503",
  "Zfp507",
  "Zfp51",
  "Zfp511",
  "Zfp512",
  "Zfp512b",
  "Zfp513",
  "Zfp516",
  "Zfp518a",
  "Zfp518b",
  "Zfp52",
  "Zfp521",
  "Zfp523",
  "Zfp524",
  "Zfp526",
  "Zfp53",
  "Zfp532",
  "Zfp536",
  "Zfp54",
  "Zfp541",
  "Zfp551",
  "Zfp553",
  "Zfp558",
  "Zfp560",
  "Zfp563",
  "Zfp566",
  "Zfp568",
  "Zfp57",
  "Zfp574",
  "Zfp575",
  "Zfp579",
  "Zfp58",
  "Zfp580",
  "Zfp583",
  "Zfp592",
  "Zfp593",
  "Zfp595",
  "Zfp597",
  "Zfp598",
  "Zfp599",
  "Zfp60",
  "Zfp605",
  "Zfp606",
  "Zfp607a",
  "Zfp607b",
  "Zfp608",
  "Zfp609",
  "Zfp61",
  "Zfp612",
  "Zfp616",
  "Zfp617",
  "Zfp618",
  "Zfp619",
  "Zfp62",
  "Zfp622",
  "Zfp623",
  "Zfp626",
  "Zfp628",
  "Zfp629",
  "Zfp637",
  "Zfp638",
  "Zfp639",
  "Zfp64",
  "Zfp641",
  "Zfp644",
  "Zfp646",
  "Zfp647",
  "Zfp648",
  "Zfp65",
  "Zfp651",
  "Zfp652",
  "Zfp652os",
  "Zfp653",
  "Zfp654",
  "Zfp655",
  "Zfp658",
  "Zfp661",
  "Zfp663",
  "Zfp664",
  "Zfp667",
  "Zfp668",
  "Zfp672",
  "Zfp677",
  "Zfp68",
  "Zfp683",
  "Zfp687",
  "Zfp688",
  "Zfp689",
  "Zfp69",
  "Zfp691",
  "Zfp692",
  "Zfp697",
  "Zfp7",
  "Zfp703",
  "Zfp704",
  "Zfp706",
  "Zfp707",
  "Zfp709",
  "Zfp710",
  "Zfp711",
  "Zfp712",
  "Zfp715",
  "Zfp719",
  "Zfp72",
  "Zfp729a",
  "Zfp729b",
  "Zfp735",
  "Zfp738",
  "Zfp74",
  "Zfp740",
  "Zfp746",
  "Zfp747",
  "Zfp748",
  "Zfp750",
  "Zfp758",
  "Zfp759",
  "Zfp760",
  "Zfp763",
  "Zfp768",
  "Zfp770",
  "Zfp771",
  "Zfp773",
  "Zfp775",
  "Zfp777",
  "Zfp78",
  "Zfp780b",
  "Zfp784",
  "Zfp786",
  "Zfp787",
  "Zfp788",
  "Zfp790",
  "Zfp791",
  "Zfp799",
  "Zfp800",
  "Zfp804a",
  "Zfp809",
  "Zfp81",
  "Zfp810",
  "Zfp811",
  "Zfp819",
  "Zfp820",
  "Zfp821",
  "Zfp825",
  "Zfp827",
  "Zfp830",
  "Zfp831",
  "Zfp839",
  "Zfp84",
  "Zfp846",
  "Zfp85",
  "Zfp850",
  "Zfp853",
  "Zfp85os",
  "Zfp865",
  "Zfp866",
  "Zfp867",
  "Zfp868",
  "Zfp869",
  "Zfp87",
  "Zfp870",
  "Zfp871",
  "Zfp872",
  "Zfp873",
  "Zfp874a",
  "Zfp874b",
  "Zfp879",
  "Zfp882",
  "Zfp9",
  "Zfp90",
  "Zfp92",
  "Zfp93",
  "Zfp930",
  "Zfp931",
  "Zfp932",
  "Zfp933",
  "Zfp934",
  "Zfp936",
  "Zfp937",
  "Zfp938",
  "Zfp94",
  "Zfp940",
  "Zfp941",
  "Zfp942",
  "Zfp943",
  "Zfp944",
  "Zfp945",
  "Zfp946",
  "Zfp948",
  "Zfp949",
  "Zfp950",
  "Zfp951",
  "Zfp952",
  "Zfp953",
  "Zfp954",
  "Zfp955b",
  "Zfp956",
  "Zfp957",
  "Zfp958",
  "Zfp959",
  "Zfp961",
  "Zfp963",
  "Zfp964",
  "Zfp972",
  "Zfp974",
  "Zfp975",
  "Zfp976",
  "Zfp977",
  "Zfp979",
  "Zfp981",
  "Zfp983",
  "Zfp985",
  "Zfp990",
  "Zfp992",
  "Zfp994",
  "Zfp995",
  "Zfpl1",
  "Zfpm1",
  "Zfpm2",
  "Zfr",
  "Zfr2",
  "Zfx",
  "Zfy1",
  "Zfy2",
  "Zfyve1",
  "Zfyve16",
  "Zfyve19",
  "Zfyve21",
  "Zfyve26",
  "Zfyve27",
  "Zfyve28",
  "Zfyve9",
  "Zg16",
  "Zgpat",
  "Zgrf1",
  "Zhx1",
  "Zhx2",
  "Zhx3",
  "Zic1",
  "Zic3",
  "Zic4",
  "Zic5",
  "Zik1",
  "Zim1",
  "Zim2",
  "Zim3",
  "Zkscan1",
  "Zkscan14",
  "Zkscan16",
  "Zkscan17",
  "Zkscan2",
  "Zkscan3",
  "Zkscan4",
  "Zkscan5",
  "Zkscan6",
  "Zkscan7",
  "Zkscan8",
  "Zmat1",
  "Zmat2",
  "Zmat3",
  "Zmat4",
  "Zmat5",
  "Zmiz1",
  "Zmiz1os1",
  "Zmiz2",
  "Zmpste24",
  "Zmym1",
  "Zmym2",
  "Zmym3",
  "Zmym4",
  "Zmym5",
  "Zmynd10",
  "Zmynd11",
  "Zmynd12",
  "Zmynd15",
  "Zmynd19",
  "Zmynd8",
  "Znfx1",
  "Znhit1",
  "Znhit2",
  "Znhit3",
  "Znhit6",
  "Znrd1",
  "Znrd1as",
  "Znrf2",
  "Znrf3",
  "Znrf4",
  "Zp1",
  "Zp2",
  "Zp3",
  "Zp3r",
  "Zpbp",
  "Zpbp2",
  "Zpld1",
  "Zpld2",
  "Zpr1",
  "Zranb1",
  "Zranb2",
  "Zranb3",
  "Zrsr1",
  "Zrsr2",
  "Zscan10",
  "Zscan12",
  "Zscan18",
  "Zscan2",
  "Zscan20",
  "Zscan21",
  "Zscan22",
  "Zscan26",
  "Zscan29",
  "Zscan4-ps1",
  "Zscan5b",
  "Zswim1",
  "Zswim2",
  "Zswim3",
  "Zswim4",
  "Zswim5",
  "Zswim6",
  "Zswim7",
  "Zswim8",
  "Zswim9",
  "Zup1",
  "Zw10",
  "Zwilch",
  "Zwint",
  "Zxdb",
  "Zxdc",
  "Zyg11a",
  "Zyg11b",
  "Zyx",
  "Zzef1",
  "Zzz3",
  "a",
  "ccdc198",
  "mmu-let-7a-1-3p",
  "mmu-let-7a-5p",
  "mmu-let-7b-5p",
  "mmu-let-7c-2-3p",
  "mmu-let-7c-5p",
  "mmu-let-7d-3p",
  "mmu-let-7d-5p",
  "mmu-let-7e-5p",
  "mmu-let-7f-1-3p",
  "mmu-let-7f-5p",
  "mmu-let-7g-3p",
  "mmu-let-7g-5p",
  "mmu-let-7i-3p",
  "mmu-let-7i-5p",
  "mmu-miR-100-5p",
  "mmu-miR-101a-3p",
  "mmu-miR-101b-3p",
  "mmu-miR-101c",
  "mmu-miR-122-5p",
  "mmu-miR-125b-5p",
  "mmu-miR-126-3p",
  "mmu-miR-126-5p",
  "mmu-miR-127-3p",
  "mmu-miR-130a-3p",
  "mmu-miR-133a-3p",
  "mmu-miR-133a-5p",
  "mmu-miR-133b-3p",
  "mmu-miR-136-3p",
  "mmu-miR-136-5p",
  "mmu-miR-140-3p",
  "mmu-miR-140-5p",
  "mmu-miR-142-3p",
  "mmu-miR-143-3p",
  "mmu-miR-149-5p",
  "mmu-miR-150-5p",
  "mmu-miR-151-3p",
  "mmu-miR-154-5p",
  "mmu-miR-185-5p",
  "mmu-miR-186-5p",
  "mmu-miR-1895",
  "mmu-miR-18a-5p",
  "mmu-miR-190a-5p",
  "mmu-miR-191-5p",
  "mmu-miR-194-5p",
  "mmu-miR-195a-5p",
  "mmu-miR-196a-5p",
  "mmu-miR-196b-5p",
  "mmu-miR-199a-3p",
  "mmu-miR-199b-3p",
  "mmu-miR-19b-3p",
  "mmu-miR-1a-1-5p",
  "mmu-miR-1a-2-5p",
  "mmu-miR-1a-3p",
  "mmu-miR-200b-3p",
  "mmu-miR-200c-3p",
  "mmu-miR-206-3p",
  "mmu-miR-208b-3p",
  "mmu-miR-2137",
  "mmu-miR-214-3p",
  "mmu-miR-22-3p",
  "mmu-miR-22-5p",
  "mmu-miR-23a-3p",
  "mmu-miR-23b-3p",
  "mmu-miR-24-1-5p",
  "mmu-miR-24-2-5p",
  "mmu-miR-24-3p",
  "mmu-miR-25-3p",
  "mmu-miR-26a-5p",
  "mmu-miR-26b-3p",
  "mmu-miR-27a-3p",
  "mmu-miR-27b-3p",
  "mmu-miR-28a-3p",
  "mmu-miR-299a-3p",
  "mmu-miR-29a-3p",
  "mmu-miR-29b-2-5p",
  "mmu-miR-29b-3p",
  "mmu-miR-29c-3p",
  "mmu-miR-300-5p",
  "mmu-miR-301a-3p",
  "mmu-miR-3096a-3p",
  "mmu-miR-30a-3p",
  "mmu-miR-30b-5p",
  "mmu-miR-30c-5p",
  "mmu-miR-30e-3p",
  "mmu-miR-30e-5p",
  "mmu-miR-3102-3p",
  "mmu-miR-3107-3p",
  "mmu-miR-320-3p",
  "mmu-miR-322-3p",
  "mmu-miR-322-5p",
  "mmu-miR-328-3p",
  "mmu-miR-329-3p",
  "mmu-miR-337-3p",
  "mmu-miR-338-3p",
  "mmu-miR-369-5p",
  "mmu-miR-376c-3p",
  "mmu-miR-378a-3p",
  "mmu-miR-378a-5p",
  "mmu-miR-378c",
  "mmu-miR-378d",
  "mmu-miR-379-5p",
  "mmu-miR-380-5p",
  "mmu-miR-382-5p",
  "mmu-miR-3960",
  "mmu-miR-410-3p",
  "mmu-miR-411-5p",
  "mmu-miR-423-3p",
  "mmu-miR-425-5p",
  "mmu-miR-466f-3p",
  "mmu-miR-484",
  "mmu-miR-486-3p",
  "mmu-miR-486-5p",
  "mmu-miR-489-3p",
  "mmu-miR-500-5p",
  "mmu-miR-503-5p",
  "mmu-miR-505-5p",
  "mmu-miR-5106",
  "mmu-miR-5108",
  "mmu-miR-5109",
  "mmu-miR-5112",
  "mmu-miR-5119",
  "mmu-miR-532-3p",
  "mmu-miR-532-5p",
  "mmu-miR-539-5p",
  "mmu-miR-544-3p",
  "mmu-miR-6240",
  "mmu-miR-6243",
  "mmu-miR-6416-3p",
  "mmu-miR-669c-3p",
  "mmu-miR-674-3p",
  "mmu-miR-708-5p",
  "mmu-miR-744-5p",
  "mmu-miR-7a-1-3p",
  "mmu-miR-872-5p",
  "mmu-miR-877-5p",
  "mmu-miR-93-5p",
  "mmu-miR-98-5p",
  "mmu-miR-99a-5p",
  "mmu-miR-99b-5p",
  "mt-Atp8",
  "mt-Cytb",
  "mt-Nd1",
  "mt-Nd2",
  "mt-Nd4",
  "mt-Nd4l",
  "mt-Nd5",
  "mt-Nd6"
];
