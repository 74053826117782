import React from "react";
import css from "./Network.module.css";

const NetworkLegend = () => {
  return (
    <div className={css.netLegend}>
      <div className={css.netLegendNodeContainer}>
        <div className={css.netLegendCircle} /> <div>mRNA</div>
      </div>
      <div className={css.netLegendNodeContainer}>
        <div className={css.netLegendTriangle} /> <div>lncRNA</div>
      </div>
      <div className={css.netLegendNodeContainer}>
        <div className={css.netLegendDiamond} /> <div>miRNA</div>
      </div>
      <div className={css.netLegendEdgeContainer}>
        <div className={css.netLegendValidated} />
        <div style={{ marginLeft: "5px" }}>Validated Interaction</div>
      </div>
      <div className={css.netLegendEdgeContainer}>
        <div className={css.netLegendPredicted} />
        <div style={{ marginLeft: "5px" }}>Predicted Interaction</div>
      </div>
      <div className={css.netLegendEdgeContainer}>
        <div className={css.netLegendCorrelation} />
        <div style={{ marginLeft: "5px" }}>Correlation</div>
      </div>
    </div>
  );
};

export default NetworkLegend;
