import React from "react";
import Spinner from "../Spinner";
import PathwayGraphSection from "./PathwayGraphSection";
import "./Pathway.css";

const PathwayNetwork = ({
  flag,
  nodes,
  edges,
  title,
  computing,
  selectElement,
  selectedElement
}) => {
  return flag ? (
    <div className="pathwayNetwork">
      <h2 className="pathwayTitle">{title}</h2>
      <PathwayGraphSection
        flag={flag}
        selectElement={selectElement}
        selectedElement={selectedElement}
        nodes={nodes}
        edges={edges}
      />
    </div>
  ) : computing ? (
    <Spinner size={"4x"} />
  ) : (
    <div />
  );
};
export default PathwayNetwork;
