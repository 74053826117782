// Copyright 2019 Davide Corso
import React from "react";
import "../style.css";
import Chart from "react-apexcharts";
import Spinner from "../Spinner";
import { makeApexChartOption } from "./setupApexCharts";
import Legend from "./Legend";

const Expression = ({
  flag,
  series,
  source,
  target,
  height,
  width,
  plotName
}) => {
  const options = makeApexChartOption(plotName);

  return flag ? (
    <div className="expression">
      <Chart
        className="chart"
        options={options}
        series={series}
        type="bar"
        height={height}
        width={width}
      />
      <Legend source={source} target={target} />
    </div>
  ) : (
    <div className="expression">
      <Spinner size={"2x"} />
    </div>
  );
};

export default Expression;
