import React from "react";
import "./Pathway.css";

const PathwayLegend = () => {
  return (
    <div className={"patLegend"}>
      <div className={"patLegendNodeContainer"}>
        <div className={"patLegendCircle"} /> <div>mRNA</div>
      </div>
      <div className={"patLegendNodeContainer"}>
        <div className={"patLegendTriangle"} /> <div>lncRNA</div>
      </div>
      <div className={"patLegendNodeContainer"}>
        <div className={"patLegendDiamond"} /> <div>miRNA</div>
      </div>
      <div className={"patLegendNodeContainer"}>
        <div className={"patLegendSquare"} /> <div>User nodes</div>
      </div>
      <div className={"patLegendEdgeContainer"}>
        <div className={"patLegendValidated"} />
        <div style={{ marginLeft: "5px" }}>Validated Interaction</div>
      </div>
      <div className={"patLegendEdgeContainer"}>
        <div className={"patLegendPredicted"} />
        <div style={{ marginLeft: "5px" }}>Predicted Interaction</div>
      </div>
      <div className={"patLegendEdgeContainer"}>
        <div className={"patLegendCorrelation"} />
        <div style={{ marginLeft: "5px" }}>Correlation</div>
      </div>
      <div className={"patLegendEdgeContainer"}>
        <div className={"patLegendOrigin"} />
        <div style={{ marginLeft: "5px" }}>Original Pathway</div>
      </div>
    </div>
  );
};

export default PathwayLegend;
