import React, { useState } from "react";
import "./UserNetwork/UserNetwork.css";

const RejectedNodes = ({ rejectedNodes }) => {
  const [visible, setVisible] = useState(false);

  return rejectedNodes.length > 0 ? (
    <div className="rejectedContainer">
      <div className="buttonRejectedNodes">
        <button onClick={() => setVisible(!visible)}>
          Toggle Rejected Nodes
        </button>
      </div>
      <textarea
        className={"textareaRejectedNodes"}
        style={{ display: `${visible ? "block" : "none"}` }}
        value={rejectedNodes.join("\n")}
        readOnly={true}
      />
    </div>
  ) : (
    <div />
  );
};

export default RejectedNodes;
