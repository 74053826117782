export const exampleSeries = [
  {
    name: "Source",
    data: [
      { x: "slow", y: 12, fillColor: "#faa385" },
      { x: "slow", y: 2, fillColor: "#faa385" },
      { x: "int", y: 34, fillColor: "#dc6053" },
      { x: "int", y: 5, fillColor: "#dc6053" },
      { x: "fast", y: 34, fillColor: "#b71d33" },
      { x: "fast", y: 34, fillColor: "#b71d33" }
    ]
  },
  {
    name: "Target",
    data: [
      { x: "slow", y: 12, fillColor: "#8ac3db" },
      { x: "slow", y: 6, fillColor: "#8ac3db" },
      { x: "int", y: 17, fillColor: "#3392bf" },
      { x: "int", y: 26, fillColor: "#3392bf" },
      { x: "fast", y: 34, fillColor: "#0066a8" },
      { x: "fast", y: 34, fillColor: "#0066a8" }
    ]
  }
];
export const makeApexChartOption = plotName => {
  const options = {
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        },
        export: {
          csv: {
            filename: `${plotName}`
          },
          svg: {
            filename: `${plotName}`
          },
          png: {
            filename: `${plotName}`
          }
        }
      },
      zoom: {
        enable: false
      }
    },
    plotOptions: {
      bar: {
        // distributed: true
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      fontSize: "15px",
      showForSingleSeries: false,
      markers: {
        fillColors: ["#0066a8", "#b71d33"]
      },
      itemMargin: {
        horizontal: 15,
        vertical: 0
      }
    },
    yaxis: {
      title: {
        text: "Expression in log2",
        style: {
          fontSize: "1rem"
        }
      }
    },
    xaxis: {
      type: "category",
      // categories: [
      //   "Slow",
      //   "Slow",
      //   "Intermediate",
      //   "Intermediate",
      //   "Fast",
      //   "Fast"
      // ],
      tickPlacement: "on",
      labels: {
        show: true,
        style: {
          fontSize: 0
        }
      }
    }
  };

  return options;
};
