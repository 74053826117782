import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./Menu.css";

const Menu = () => {
  return (
    <div className="absoluteMenu">
      <FontAwesomeIcon
        className="fai"
        icon="list-ul"
        size="2x"
        color="rgb(70, 71, 73)"
      />
      <div className="absoluteMenu-content">
        <Link to={"/"}>Homepage</Link>
        <Link to={"/network"}>Computing Network</Link>
        <Link to={"/pathways"}>Pathway</Link>
        <Link to={"/help"}>Help</Link>
      </div>
    </div>
  );
};

export default Menu;
