import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import css from "./EPAutocompleteSearch.module.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// EP sta per EntryPage

const EPAutocompleteSearch = ({ searchedEntry, options }) => {
  const btn = useRef();
  const [searchedText, setSearchedText] = useState(searchedEntry);
  const [value, setValue] = useState({
    filteredOptions: [],
    showOptions: false,
    userInput: searchedEntry
  });

  const onClick = e => {
    setValue({
      filteredOptions: [],
      showOptions: false,
      userInput: e.currentTarget.innerText
    });
    setSearchedText(e.currentTarget.innerText);
  };

  const onChange = e => {
    const userInput = e.currentTarget.value;
    const filteredOptions = options.filter(
      option => option.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setValue({
      filteredOptions,
      showOptions: true,
      userInput
    });
    setSearchedText(userInput);
  };

  const onEnterDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      setSearchedText(e.target.value);
      btn.current.click();
    }
  };

  const EPOptionList = () => {
    if (value.showOptions && value.userInput) {
      if (value.filteredOptions.length) {
        return (
          <div className={css.epContainerOptionsAbsolute}>
            <div />
            <div className={css.epContainerOptionsList}>
              <ul className={css.epOptionsList}>
                {value.filteredOptions.map(optionName => {
                  return (
                    <li key={optionName} onClick={onClick} value={optionName}>
                      {optionName}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        );
      } else {
        if (
          value.userInput.toLowerCase().slice(0, 7) === "ensmusg" &&
          value.userInput.toLowerCase().length > 7
        ) {
          return <div />;
        } else {
          return (
            <div className={css.epContainerOptionsAbsolute}>
              <div />
              <div className={css.epContainerOptionsList}>
                <div className={css.epNoOptionsList}>
                  <em>No IDs found.</em>
                </div>
              </div>
            </div>
          );
        }
      }
    } else {
      return <div />;
    }
  };

  return (
    <div className={css.epSearchContainer}>
      <div className={css.epSearch}>
        <div className={css.epNavbarDropdown}>
          <FontAwesomeIcon
            className={css.epFai}
            icon="list-ul"
            size="2x"
            color="rgb(70, 71, 73)"
          />
          <div className={css.epNavbarDropdownContent}>
            <Link to={"/"}>Homepage</Link>
            <Link to={"/network"}>Computing Network</Link>
            <Link to={"/pathways"}>Pathway</Link>
            <Link to={"/help"}>Help</Link>
          </div>
        </div>
        <input
          className={css.epSearchBar}
          type="text"
          placeholder="Search for symbol, ensembl gene or miRNA mature name"
          onChange={onChange}
          value={searchedText}
          onKeyDown={onEnterDown}
        />
        <div />
        <div className={css.epSearchButton}>
          <Link to={{ pathname: `/entry/${searchedText}` }}>
            <button type="button" ref={btn}>
              Search
            </button>
          </Link>
        </div>
      </div>
      <EPOptionList />
    </div>
  );
};

export default EPAutocompleteSearch;

EPAutocompleteSearch.propTypes = {
  searchedEntry: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
};
