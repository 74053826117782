import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import css from "./HPAutocompleteSearch.module.css";
import { Link } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";

// HP sta per HomePage

const HPAutocompleteSearch = ({ options }) => {
  const btn = useRef();
  const [searchedText, setSearchedText] = useState("");
  const [value, setValue] = useState({
    filteredOptions: [],
    showOptions: false,
    userInput: ""
  });
  const { trackPageView, trackEvent } = useMatomo();

  // Track page view
  React.useEffect(trackPageView, []);

  const onClickExample = () => {
    const entries = [
      "Myh4",
      "Pvt1",
      "ENSMUSG00000057003",
      "ENSMUSG00000097039",
      "mmu-miR-486-5p",
      "mmu-miR-337-3p"
    ];

    const random = Math.floor(Math.random() * entries.length);
    setSearchedText(entries[random]);
  };

  const onClick = e => {
    setValue({
      filteredOptions: [],
      showOptions: false,
      userInput: e.currentTarget.innerText
    });
    setSearchedText(e.currentTarget.innerText);
  };

  const onChange = e => {
    const userInput = e.currentTarget.value;
    const filteredOptions = options.filter(
      option => option.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setValue({
      filteredOptions,
      showOptions: true,
      userInput
    });
    setSearchedText(userInput);
  };

  const onEnterDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      setSearchedText(e.target.value);
      btn.current.click();
      trackEvent({ category: "homepage", action: "search-event-by-enter" });
    }
  };

  const OptionList = () => {
    if (value.showOptions && value.userInput) {
      if (value.filteredOptions.length) {
        return (
          <div className={css.containerOptionsAbsolute}>
            <div className={css.containerOptionsList}>
              <ul className={css.optionsList}>
                {value.filteredOptions.map(optionName => {
                  return (
                    <li key={optionName} onClick={onClick} value={optionName}>
                      {optionName}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        );
      } else {
        if (
          value.userInput.toLowerCase().slice(0, 7) === "ensmusg" &&
          value.userInput.toLowerCase().length > 7
        ) {
          return <div />;
        } else {
          return (
            <div className={css.containerOptionsAbsolute}>
              <div className={css.containerOptionsList}>
                <div className={css.noOptionsList}>
                  <em>No IDs found.</em>
                </div>
              </div>
            </div>
          );
        }
      }
    } else {
      return <div />;
    }
  };

  return (
    <div className={css.hpSearchContainer}>
      <div className={css.hpSearch}>
        <input
          className={css.hpSearchInputBox}
          type="text"
          placeholder="Ensembl gene or symbol or miRNA name"
          onChange={onChange}
          value={searchedText}
          onKeyDown={onEnterDown}
        />
        <div className={css.hpSearchButton}>
          <Link to={{ pathname: `/entry/${searchedText}` }}>
            <button type="submit" ref={btn}>
              Search
            </button>
          </Link>
        </div>
        <div className={css.hpExample}>
          <button onClick={onClickExample}>Example</button>
        </div>
      </div>
      <OptionList />
    </div>
  );
};

export default HPAutocompleteSearch;

HPAutocompleteSearch.propTypes = {
  options: PropTypes.array.isRequired
};
