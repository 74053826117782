import React from "react";
import "../style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Legend = ({ source, target }) => {
  let targetEntry, slowCol, intCol, fastCol;

  if (!!target) {
    targetEntry = <div className="elemLegend">{target}</div>;
    slowCol = (
      <FontAwesomeIcon
        className="elemLegend"
        icon="circle"
        size="1x"
        color={"#faa385"}
      />
    );
    intCol = (
      <FontAwesomeIcon
        className="elemLegend"
        icon="circle"
        size="1x"
        color={"#dc6053"}
      />
    );
    fastCol = (
      <FontAwesomeIcon
        className="elemLegend"
        icon="circle"
        size="1x"
        color={"#b71d33"}
      />
    );
  }

  return (
    <div className="legend">
      <div />
      <div className="elemLegend">Slow</div>
      <div className="elemLegend">Intermediate</div>
      <div className="elemLegend">Fast</div>
      <div className="elemLegend">{source}</div>
      <FontAwesomeIcon
        className="elemLegend"
        icon="circle"
        size="1x"
        color={"#8ac3db"}
      />
      <FontAwesomeIcon
        className="elemLegend"
        icon="circle"
        size="1x"
        color={"#3392bf"}
      />
      <FontAwesomeIcon
        className="elemLegend"
        icon="circle"
        size="1x"
        color={"#0066a8"}
      />
      {targetEntry}
      {slowCol}
      {intCol}
      {fastCol}
    </div>
  );
};

export default Legend;
