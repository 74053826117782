import React from "react";
import { saveAs } from "file-saver";
import css from "./Help.module.css";
import { lncRNAs } from "./entries/lncRNAs";
import { miRNAs } from "./entries/miRNAs";
import { coding } from "./entries/coding";
import { symbols } from "./entries/symbols";
import { all_entries } from "./entries/all_entries";

const DownloadBox = () => {
  return (
    <div className={css.downloadBox}>
      <div className={css.downloadTitle}>Download Entries</div>
      <p className={css.annotationText}>
        <b>Annotation inferred from Gencode:</b> annotation release vM22,
        evidence-based annotation of the mouse genome GRCm38, version M22
        Ensembl 97
      </p>
      <p className={css.specificDownload}>
        Download all specific entries available on MyoData:
      </p>
      <div className={css.codingButton}>
        <button onClick={() => prepareEntries(coding, "myodata_mRNAs.txt")}>
          mRNAs.txt
        </button>
      </div>
      <div className={css.lncRNAButton}>
        <button onClick={() => prepareEntries(lncRNAs, "myodata_lncRNAs.txt")}>
          lncRNAs.txt
        </button>
      </div>
      <div className={css.miRNAButton}>
        <button onClick={() => prepareEntries(miRNAs, "myodata_miRNAs.txt")}>
          miRNAs.txt
        </button>
      </div>
      <div className={css.symbolsButton}>
        <button onClick={() => prepareEntries(symbols, "myodata_symbols.txt")}>
          Symbols.txt
        </button>
      </div>
      <p className={css.allDownload}>
        Alternatively, you can download all the entries in one file
      </p>
      <div className={css.allEntriesButton}>
        <button
          onClick={() => prepareEntries(all_entries, "myodata_entries.txt")}
        >
          All entries
        </button>
      </div>
    </div>
  );
};

export default DownloadBox;

const prepareEntries = (entries, filename) => {
  const testo = composeEntriesText(entries);

  const blob = new Blob([testo], {
    type: "text/plain;charset=utf-8"
  });
  saveAs(blob, filename);
};

const composeEntriesText = entries => {
  let s = "";

  entries.forEach(e => {
    s += `${e}\n`;
  });

  return s;
};
