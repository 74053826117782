export const lncRNAs = [
  "ENSMUSG00000000031",
  "ENSMUSG00000006462",
  "ENSMUSG00000011350",
  "ENSMUSG00000013562",
  "ENSMUSG00000020033",
  "ENSMUSG00000020164",
  "ENSMUSG00000020624",
  "ENSMUSG00000020812",
  "ENSMUSG00000020992",
  "ENSMUSG00000021101",
  "ENSMUSG00000021268",
  "ENSMUSG00000021566",
  "ENSMUSG00000021600",
  "ENSMUSG00000021874",
  "ENSMUSG00000022116",
  "ENSMUSG00000022187",
  "ENSMUSG00000022639",
  "ENSMUSG00000024546",
  "ENSMUSG00000024658",
  "ENSMUSG00000024845",
  "ENSMUSG00000025450",
  "ENSMUSG00000025644",
  "ENSMUSG00000026729",
  "ENSMUSG00000026736",
  "ENSMUSG00000027196",
  "ENSMUSG00000027345",
  "ENSMUSG00000028009",
  "ENSMUSG00000028177",
  "ENSMUSG00000028244",
  "ENSMUSG00000028590",
  "ENSMUSG00000028612",
  "ENSMUSG00000029044",
  "ENSMUSG00000029092",
  "ENSMUSG00000029331",
  "ENSMUSG00000029360",
  "ENSMUSG00000029386",
  "ENSMUSG00000029409",
  "ENSMUSG00000029837",
  "ENSMUSG00000029878",
  "ENSMUSG00000030623",
  "ENSMUSG00000030636",
  "ENSMUSG00000030664",
  "ENSMUSG00000031294",
  "ENSMUSG00000031510",
  "ENSMUSG00000031559",
  "ENSMUSG00000031736",
  "ENSMUSG00000031838",
  "ENSMUSG00000032048",
  "ENSMUSG00000032846",
  "ENSMUSG00000033015",
  "ENSMUSG00000033731",
  "ENSMUSG00000034587",
  "ENSMUSG00000034764",
  "ENSMUSG00000035983",
  "ENSMUSG00000036162",
  "ENSMUSG00000037247",
  "ENSMUSG00000037535",
  "ENSMUSG00000037735",
  "ENSMUSG00000037884",
  "ENSMUSG00000037910",
  "ENSMUSG00000038152",
  "ENSMUSG00000038311",
  "ENSMUSG00000038408",
  "ENSMUSG00000038491",
  "ENSMUSG00000038917",
  "ENSMUSG00000039098",
  "ENSMUSG00000039133",
  "ENSMUSG00000039138",
  "ENSMUSG00000039545",
  "ENSMUSG00000039563",
  "ENSMUSG00000039798",
  "ENSMUSG00000040467",
  "ENSMUSG00000040657",
  "ENSMUSG00000040705",
  "ENSMUSG00000040840",
  "ENSMUSG00000041674",
  "ENSMUSG00000041789",
  "ENSMUSG00000041878",
  "ENSMUSG00000042360",
  "ENSMUSG00000042549",
  "ENSMUSG00000042579",
  "ENSMUSG00000042857",
  "ENSMUSG00000042874",
  "ENSMUSG00000042976",
  "ENSMUSG00000043126",
  "ENSMUSG00000043145",
  "ENSMUSG00000043168",
  "ENSMUSG00000043340",
  "ENSMUSG00000043488",
  "ENSMUSG00000043644",
  "ENSMUSG00000043773",
  "ENSMUSG00000043794",
  "ENSMUSG00000043833",
  "ENSMUSG00000043993",
  "ENSMUSG00000044062",
  "ENSMUSG00000044081",
  "ENSMUSG00000044125",
  "ENSMUSG00000044276",
  "ENSMUSG00000044306",
  "ENSMUSG00000044387",
  "ENSMUSG00000044471",
  "ENSMUSG00000044522",
  "ENSMUSG00000044544",
  "ENSMUSG00000044574",
  "ENSMUSG00000044633",
  "ENSMUSG00000044689",
  "ENSMUSG00000044694",
  "ENSMUSG00000044867",
  "ENSMUSG00000044997",
  "ENSMUSG00000045008",
  "ENSMUSG00000045075",
  "ENSMUSG00000045178",
  "ENSMUSG00000045231",
  "ENSMUSG00000045339",
  "ENSMUSG00000045391",
  "ENSMUSG00000045746",
  "ENSMUSG00000045813",
  "ENSMUSG00000045815",
  "ENSMUSG00000045877",
  "ENSMUSG00000045928",
  "ENSMUSG00000046005",
  "ENSMUSG00000046109",
  "ENSMUSG00000046119",
  "ENSMUSG00000046219",
  "ENSMUSG00000046413",
  "ENSMUSG00000046415",
  "ENSMUSG00000046463",
  "ENSMUSG00000046683",
  "ENSMUSG00000046764",
  "ENSMUSG00000046840",
  "ENSMUSG00000046995",
  "ENSMUSG00000047046",
  "ENSMUSG00000047324",
  "ENSMUSG00000047661",
  "ENSMUSG00000047692",
  "ENSMUSG00000047828",
  "ENSMUSG00000047935",
  "ENSMUSG00000047938",
  "ENSMUSG00000048215",
  "ENSMUSG00000048603",
  "ENSMUSG00000048628",
  "ENSMUSG00000048824",
  "ENSMUSG00000049476",
  "ENSMUSG00000050066",
  "ENSMUSG00000050179",
  "ENSMUSG00000050189",
  "ENSMUSG00000050334",
  "ENSMUSG00000050765",
  "ENSMUSG00000050936",
  "ENSMUSG00000051008",
  "ENSMUSG00000051074",
  "ENSMUSG00000051198",
  "ENSMUSG00000051237",
  "ENSMUSG00000051295",
  "ENSMUSG00000051297",
  "ENSMUSG00000051606",
  "ENSMUSG00000051638",
  "ENSMUSG00000051729",
  "ENSMUSG00000051758",
  "ENSMUSG00000051805",
  "ENSMUSG00000051832",
  "ENSMUSG00000051844",
  "ENSMUSG00000051986",
  "ENSMUSG00000052125",
  "ENSMUSG00000052188",
  "ENSMUSG00000052236",
  "ENSMUSG00000052248",
  "ENSMUSG00000052281",
  "ENSMUSG00000052291",
  "ENSMUSG00000052295",
  "ENSMUSG00000052368",
  "ENSMUSG00000052371",
  "ENSMUSG00000052403",
  "ENSMUSG00000052479",
  "ENSMUSG00000052629",
  "ENSMUSG00000052658",
  "ENSMUSG00000052673",
  "ENSMUSG00000052724",
  "ENSMUSG00000052730",
  "ENSMUSG00000052767",
  "ENSMUSG00000052769",
  "ENSMUSG00000052848",
  "ENSMUSG00000052865",
  "ENSMUSG00000052951",
  "ENSMUSG00000053049",
  "ENSMUSG00000053081",
  "ENSMUSG00000053117",
  "ENSMUSG00000053121",
  "ENSMUSG00000053185",
  "ENSMUSG00000053263",
  "ENSMUSG00000053332",
  "ENSMUSG00000053353",
  "ENSMUSG00000053360",
  "ENSMUSG00000053442",
  "ENSMUSG00000053499",
  "ENSMUSG00000053528",
  "ENSMUSG00000053545",
  "ENSMUSG00000053574",
  "ENSMUSG00000053588",
  "ENSMUSG00000053613",
  "ENSMUSG00000053615",
  "ENSMUSG00000053640",
  "ENSMUSG00000053714",
  "ENSMUSG00000053749",
  "ENSMUSG00000053821",
  "ENSMUSG00000053889",
  "ENSMUSG00000053957",
  "ENSMUSG00000053979",
  "ENSMUSG00000054006",
  "ENSMUSG00000054061",
  "ENSMUSG00000054069",
  "ENSMUSG00000054135",
  "ENSMUSG00000054181",
  "ENSMUSG00000054258",
  "ENSMUSG00000054351",
  "ENSMUSG00000054412",
  "ENSMUSG00000054418",
  "ENSMUSG00000054426",
  "ENSMUSG00000054457",
  "ENSMUSG00000054488",
  "ENSMUSG00000054493",
  "ENSMUSG00000054510",
  "ENSMUSG00000054514",
  "ENSMUSG00000054556",
  "ENSMUSG00000054589",
  "ENSMUSG00000054618",
  "ENSMUSG00000054622",
  "ENSMUSG00000054779",
  "ENSMUSG00000054822",
  "ENSMUSG00000054910",
  "ENSMUSG00000054944",
  "ENSMUSG00000054945",
  "ENSMUSG00000055015",
  "ENSMUSG00000055045",
  "ENSMUSG00000055048",
  "ENSMUSG00000055061",
  "ENSMUSG00000055110",
  "ENSMUSG00000055114",
  "ENSMUSG00000055125",
  "ENSMUSG00000055134",
  "ENSMUSG00000055138",
  "ENSMUSG00000055159",
  "ENSMUSG00000055188",
  "ENSMUSG00000055323",
  "ENSMUSG00000055370",
  "ENSMUSG00000055408",
  "ENSMUSG00000055424",
  "ENSMUSG00000055446",
  "ENSMUSG00000055494",
  "ENSMUSG00000055732",
  "ENSMUSG00000055818",
  "ENSMUSG00000055833",
  "ENSMUSG00000055944",
  "ENSMUSG00000055961",
  "ENSMUSG00000055972",
  "ENSMUSG00000056023",
  "ENSMUSG00000056031",
  "ENSMUSG00000056032",
  "ENSMUSG00000056061",
  "ENSMUSG00000056089",
  "ENSMUSG00000056128",
  "ENSMUSG00000056145",
  "ENSMUSG00000056288",
  "ENSMUSG00000056329",
  "ENSMUSG00000056332",
  "ENSMUSG00000056359",
  "ENSMUSG00000056411",
  "ENSMUSG00000056418",
  "ENSMUSG00000056445",
  "ENSMUSG00000056579",
  "ENSMUSG00000056687",
  "ENSMUSG00000056699",
  "ENSMUSG00000056716",
  "ENSMUSG00000056735",
  "ENSMUSG00000056738",
  "ENSMUSG00000056753",
  "ENSMUSG00000056771",
  "ENSMUSG00000056821",
  "ENSMUSG00000057215",
  "ENSMUSG00000057359",
  "ENSMUSG00000057802",
  "ENSMUSG00000057913",
  "ENSMUSG00000058812",
  "ENSMUSG00000058934",
  "ENSMUSG00000059027",
  "ENSMUSG00000059244",
  "ENSMUSG00000059277",
  "ENSMUSG00000059631",
  "ENSMUSG00000059972",
  "ENSMUSG00000060029",
  "ENSMUSG00000060149",
  "ENSMUSG00000060375",
  "ENSMUSG00000060380",
  "ENSMUSG00000060416",
  "ENSMUSG00000060424",
  "ENSMUSG00000060808",
  "ENSMUSG00000061510",
  "ENSMUSG00000062046",
  "ENSMUSG00000062224",
  "ENSMUSG00000062282",
  "ENSMUSG00000062319",
  "ENSMUSG00000062391",
  "ENSMUSG00000062470",
  "ENSMUSG00000062496",
  "ENSMUSG00000062704",
  "ENSMUSG00000063018",
  "ENSMUSG00000063087",
  "ENSMUSG00000063109",
  "ENSMUSG00000063235",
  "ENSMUSG00000063611",
  "ENSMUSG00000063623",
  "ENSMUSG00000063714",
  "ENSMUSG00000063751",
  "ENSMUSG00000064032",
  "ENSMUSG00000064043",
  "ENSMUSG00000064052",
  "ENSMUSG00000064263",
  "ENSMUSG00000064299",
  "ENSMUSG00000066057",
  "ENSMUSG00000066060",
  "ENSMUSG00000066158",
  "ENSMUSG00000066170",
  "ENSMUSG00000066175",
  "ENSMUSG00000066176",
  "ENSMUSG00000066178",
  "ENSMUSG00000066477",
  "ENSMUSG00000067101",
  "ENSMUSG00000067103",
  "ENSMUSG00000067352",
  "ENSMUSG00000067577",
  "ENSMUSG00000067745",
  "ENSMUSG00000067835",
  "ENSMUSG00000068062",
  "ENSMUSG00000068180",
  "ENSMUSG00000068463",
  "ENSMUSG00000068601",
  "ENSMUSG00000068680",
  "ENSMUSG00000068776",
  "ENSMUSG00000068957",
  "ENSMUSG00000069223",
  "ENSMUSG00000069554",
  "ENSMUSG00000069713",
  "ENSMUSG00000069796",
  "ENSMUSG00000069925",
  "ENSMUSG00000070315",
  "ENSMUSG00000070342",
  "ENSMUSG00000070392",
  "ENSMUSG00000070489",
  "ENSMUSG00000070574",
  "ENSMUSG00000070717",
  "ENSMUSG00000070794",
  "ENSMUSG00000070995",
  "ENSMUSG00000070997",
  "ENSMUSG00000071033",
  "ENSMUSG00000071181",
  "ENSMUSG00000071280",
  "ENSMUSG00000071434",
  "ENSMUSG00000071456",
  "ENSMUSG00000071721",
  "ENSMUSG00000071753",
  "ENSMUSG00000071818",
  "ENSMUSG00000072573",
  "ENSMUSG00000072589",
  "ENSMUSG00000072591",
  "ENSMUSG00000072592",
  "ENSMUSG00000072612",
  "ENSMUSG00000072618",
  "ENSMUSG00000072660",
  "ENSMUSG00000072673",
  "ENSMUSG00000072677",
  "ENSMUSG00000072679",
  "ENSMUSG00000072683",
  "ENSMUSG00000072686",
  "ENSMUSG00000072688",
  "ENSMUSG00000072753",
  "ENSMUSG00000072761",
  "ENSMUSG00000072769",
  "ENSMUSG00000072809",
  "ENSMUSG00000072834",
  "ENSMUSG00000072844",
  "ENSMUSG00000072847",
  "ENSMUSG00000072852",
  "ENSMUSG00000072874",
  "ENSMUSG00000072884",
  "ENSMUSG00000072893",
  "ENSMUSG00000072902",
  "ENSMUSG00000072952",
  "ENSMUSG00000072962",
  "ENSMUSG00000072963",
  "ENSMUSG00000072982",
  "ENSMUSG00000072999",
  "ENSMUSG00000073000",
  "ENSMUSG00000073018",
  "ENSMUSG00000073045",
  "ENSMUSG00000073051",
  "ENSMUSG00000073052",
  "ENSMUSG00000073067",
  "ENSMUSG00000073073",
  "ENSMUSG00000073103",
  "ENSMUSG00000073141",
  "ENSMUSG00000073147",
  "ENSMUSG00000073152",
  "ENSMUSG00000073154",
  "ENSMUSG00000073155",
  "ENSMUSG00000073174",
  "ENSMUSG00000073226",
  "ENSMUSG00000073236",
  "ENSMUSG00000073242",
  "ENSMUSG00000073274",
  "ENSMUSG00000073374",
  "ENSMUSG00000073377",
  "ENSMUSG00000073394",
  "ENSMUSG00000073415",
  "ENSMUSG00000073430",
  "ENSMUSG00000073437",
  "ENSMUSG00000073478",
  "ENSMUSG00000073485",
  "ENSMUSG00000073486",
  "ENSMUSG00000073528",
  "ENSMUSG00000073529",
  "ENSMUSG00000073532",
  "ENSMUSG00000073535",
  "ENSMUSG00000073538",
  "ENSMUSG00000073590",
  "ENSMUSG00000073594",
  "ENSMUSG00000073627",
  "ENSMUSG00000073652",
  "ENSMUSG00000073656",
  "ENSMUSG00000073679",
  "ENSMUSG00000073686",
  "ENSMUSG00000073699",
  "ENSMUSG00000073728",
  "ENSMUSG00000073731",
  "ENSMUSG00000073739",
  "ENSMUSG00000073752",
  "ENSMUSG00000073765",
  "ENSMUSG00000073778",
  "ENSMUSG00000073779",
  "ENSMUSG00000073781",
  "ENSMUSG00000073787",
  "ENSMUSG00000073821",
  "ENSMUSG00000073843",
  "ENSMUSG00000073846",
  "ENSMUSG00000073862",
  "ENSMUSG00000073976",
  "ENSMUSG00000074024",
  "ENSMUSG00000074029",
  "ENSMUSG00000074039",
  "ENSMUSG00000074052",
  "ENSMUSG00000074113",
  "ENSMUSG00000074136",
  "ENSMUSG00000074146",
  "ENSMUSG00000074178",
  "ENSMUSG00000074213",
  "ENSMUSG00000074219",
  "ENSMUSG00000074235",
  "ENSMUSG00000074256",
  "ENSMUSG00000074274",
  "ENSMUSG00000074281",
  "ENSMUSG00000074284",
  "ENSMUSG00000074292",
  "ENSMUSG00000074343",
  "ENSMUSG00000074346",
  "ENSMUSG00000074354",
  "ENSMUSG00000074373",
  "ENSMUSG00000074385",
  "ENSMUSG00000074388",
  "ENSMUSG00000074398",
  "ENSMUSG00000074415",
  "ENSMUSG00000074465",
  "ENSMUSG00000074466",
  "ENSMUSG00000074469",
  "ENSMUSG00000074507",
  "ENSMUSG00000074508",
  "ENSMUSG00000074517",
  "ENSMUSG00000074555",
  "ENSMUSG00000074578",
  "ENSMUSG00000074635",
  "ENSMUSG00000074664",
  "ENSMUSG00000074737",
  "ENSMUSG00000074758",
  "ENSMUSG00000074763",
  "ENSMUSG00000074776",
  "ENSMUSG00000074782",
  "ENSMUSG00000074783",
  "ENSMUSG00000074805",
  "ENSMUSG00000074807",
  "ENSMUSG00000074813",
  "ENSMUSG00000074876",
  "ENSMUSG00000074885",
  "ENSMUSG00000074912",
  "ENSMUSG00000074919",
  "ENSMUSG00000074987",
  "ENSMUSG00000075010",
  "ENSMUSG00000075020",
  "ENSMUSG00000075025",
  "ENSMUSG00000075027",
  "ENSMUSG00000075029",
  "ENSMUSG00000075070",
  "ENSMUSG00000075184",
  "ENSMUSG00000075265",
  "ENSMUSG00000075277",
  "ENSMUSG00000075286",
  "ENSMUSG00000075314",
  "ENSMUSG00000075325",
  "ENSMUSG00000075330",
  "ENSMUSG00000075335",
  "ENSMUSG00000075389",
  "ENSMUSG00000075405",
  "ENSMUSG00000075416",
  "ENSMUSG00000075437",
  "ENSMUSG00000075463",
  "ENSMUSG00000075465",
  "ENSMUSG00000075511",
  "ENSMUSG00000075512",
  "ENSMUSG00000075514",
  "ENSMUSG00000075534",
  "ENSMUSG00000075538",
  "ENSMUSG00000075549",
  "ENSMUSG00000075553",
  "ENSMUSG00000075555",
  "ENSMUSG00000075576",
  "ENSMUSG00000075585",
  "ENSMUSG00000078122",
  "ENSMUSG00000078190",
  "ENSMUSG00000078247",
  "ENSMUSG00000078314",
  "ENSMUSG00000078349",
  "ENSMUSG00000078444",
  "ENSMUSG00000078492",
  "ENSMUSG00000078494",
  "ENSMUSG00000078519",
  "ENSMUSG00000078531",
  "ENSMUSG00000078600",
  "ENSMUSG00000078618",
  "ENSMUSG00000078635",
  "ENSMUSG00000078700",
  "ENSMUSG00000078706",
  "ENSMUSG00000078838",
  "ENSMUSG00000078925",
  "ENSMUSG00000078952",
  "ENSMUSG00000078955",
  "ENSMUSG00000078956",
  "ENSMUSG00000078957",
  "ENSMUSG00000078962",
  "ENSMUSG00000079000",
  "ENSMUSG00000079011",
  "ENSMUSG00000079053",
  "ENSMUSG00000079069",
  "ENSMUSG00000079070",
  "ENSMUSG00000079143",
  "ENSMUSG00000079146",
  "ENSMUSG00000079163",
  "ENSMUSG00000079179",
  "ENSMUSG00000079183",
  "ENSMUSG00000079346",
  "ENSMUSG00000079357",
  "ENSMUSG00000079414",
  "ENSMUSG00000079467",
  "ENSMUSG00000079499",
  "ENSMUSG00000079505",
  "ENSMUSG00000079528",
  "ENSMUSG00000079564",
  "ENSMUSG00000079603",
  "ENSMUSG00000079604",
  "ENSMUSG00000079671",
  "ENSMUSG00000080058",
  "ENSMUSG00000080727",
  "ENSMUSG00000081207",
  "ENSMUSG00000081512",
  "ENSMUSG00000082488",
  "ENSMUSG00000082848",
  "ENSMUSG00000084085",
  "ENSMUSG00000084757",
  "ENSMUSG00000084771",
  "ENSMUSG00000084774",
  "ENSMUSG00000084797",
  "ENSMUSG00000084798",
  "ENSMUSG00000084802",
  "ENSMUSG00000084803",
  "ENSMUSG00000084805",
  "ENSMUSG00000084806",
  "ENSMUSG00000084811",
  "ENSMUSG00000084816",
  "ENSMUSG00000084823",
  "ENSMUSG00000084826",
  "ENSMUSG00000084834",
  "ENSMUSG00000084840",
  "ENSMUSG00000084846",
  "ENSMUSG00000084848",
  "ENSMUSG00000084856",
  "ENSMUSG00000084859",
  "ENSMUSG00000084864",
  "ENSMUSG00000084869",
  "ENSMUSG00000084870",
  "ENSMUSG00000084873",
  "ENSMUSG00000084885",
  "ENSMUSG00000084898",
  "ENSMUSG00000084908",
  "ENSMUSG00000084909",
  "ENSMUSG00000084913",
  "ENSMUSG00000084925",
  "ENSMUSG00000084929",
  "ENSMUSG00000084938",
  "ENSMUSG00000084945",
  "ENSMUSG00000084946",
  "ENSMUSG00000084950",
  "ENSMUSG00000084964",
  "ENSMUSG00000084966",
  "ENSMUSG00000084979",
  "ENSMUSG00000084980",
  "ENSMUSG00000084983",
  "ENSMUSG00000085004",
  "ENSMUSG00000085008",
  "ENSMUSG00000085014",
  "ENSMUSG00000085015",
  "ENSMUSG00000085016",
  "ENSMUSG00000085022",
  "ENSMUSG00000085024",
  "ENSMUSG00000085026",
  "ENSMUSG00000085027",
  "ENSMUSG00000085030",
  "ENSMUSG00000085032",
  "ENSMUSG00000085034",
  "ENSMUSG00000085037",
  "ENSMUSG00000085041",
  "ENSMUSG00000085058",
  "ENSMUSG00000085069",
  "ENSMUSG00000085071",
  "ENSMUSG00000085074",
  "ENSMUSG00000085078",
  "ENSMUSG00000085084",
  "ENSMUSG00000085085",
  "ENSMUSG00000085088",
  "ENSMUSG00000085090",
  "ENSMUSG00000085105",
  "ENSMUSG00000085112",
  "ENSMUSG00000085114",
  "ENSMUSG00000085122",
  "ENSMUSG00000085123",
  "ENSMUSG00000085133",
  "ENSMUSG00000085135",
  "ENSMUSG00000085141",
  "ENSMUSG00000085143",
  "ENSMUSG00000085147",
  "ENSMUSG00000085148",
  "ENSMUSG00000085151",
  "ENSMUSG00000085152",
  "ENSMUSG00000085156",
  "ENSMUSG00000085161",
  "ENSMUSG00000085165",
  "ENSMUSG00000085169",
  "ENSMUSG00000085171",
  "ENSMUSG00000085175",
  "ENSMUSG00000085178",
  "ENSMUSG00000085180",
  "ENSMUSG00000085183",
  "ENSMUSG00000085185",
  "ENSMUSG00000085196",
  "ENSMUSG00000085198",
  "ENSMUSG00000085207",
  "ENSMUSG00000085208",
  "ENSMUSG00000085223",
  "ENSMUSG00000085227",
  "ENSMUSG00000085235",
  "ENSMUSG00000085236",
  "ENSMUSG00000085237",
  "ENSMUSG00000085241",
  "ENSMUSG00000085244",
  "ENSMUSG00000085247",
  "ENSMUSG00000085250",
  "ENSMUSG00000085252",
  "ENSMUSG00000085260",
  "ENSMUSG00000085277",
  "ENSMUSG00000085287",
  "ENSMUSG00000085316",
  "ENSMUSG00000085321",
  "ENSMUSG00000085323",
  "ENSMUSG00000085324",
  "ENSMUSG00000085329",
  "ENSMUSG00000085331",
  "ENSMUSG00000085338",
  "ENSMUSG00000085347",
  "ENSMUSG00000085350",
  "ENSMUSG00000085352",
  "ENSMUSG00000085354",
  "ENSMUSG00000085355",
  "ENSMUSG00000085363",
  "ENSMUSG00000085382",
  "ENSMUSG00000085385",
  "ENSMUSG00000085389",
  "ENSMUSG00000085396",
  "ENSMUSG00000085399",
  "ENSMUSG00000085407",
  "ENSMUSG00000085408",
  "ENSMUSG00000085409",
  "ENSMUSG00000085415",
  "ENSMUSG00000085418",
  "ENSMUSG00000085420",
  "ENSMUSG00000085427",
  "ENSMUSG00000085436",
  "ENSMUSG00000085437",
  "ENSMUSG00000085438",
  "ENSMUSG00000085440",
  "ENSMUSG00000085449",
  "ENSMUSG00000085450",
  "ENSMUSG00000085452",
  "ENSMUSG00000085453",
  "ENSMUSG00000085457",
  "ENSMUSG00000085463",
  "ENSMUSG00000085471",
  "ENSMUSG00000085472",
  "ENSMUSG00000085492",
  "ENSMUSG00000085496",
  "ENSMUSG00000085527",
  "ENSMUSG00000085532",
  "ENSMUSG00000085555",
  "ENSMUSG00000085558",
  "ENSMUSG00000085561",
  "ENSMUSG00000085562",
  "ENSMUSG00000085566",
  "ENSMUSG00000085578",
  "ENSMUSG00000085582",
  "ENSMUSG00000085586",
  "ENSMUSG00000085600",
  "ENSMUSG00000085614",
  "ENSMUSG00000085620",
  "ENSMUSG00000085628",
  "ENSMUSG00000085631",
  "ENSMUSG00000085641",
  "ENSMUSG00000085645",
  "ENSMUSG00000085649",
  "ENSMUSG00000085655",
  "ENSMUSG00000085656",
  "ENSMUSG00000085658",
  "ENSMUSG00000085664",
  "ENSMUSG00000085665",
  "ENSMUSG00000085667",
  "ENSMUSG00000085675",
  "ENSMUSG00000085704",
  "ENSMUSG00000085705",
  "ENSMUSG00000085709",
  "ENSMUSG00000085715",
  "ENSMUSG00000085717",
  "ENSMUSG00000085720",
  "ENSMUSG00000085732",
  "ENSMUSG00000085741",
  "ENSMUSG00000085743",
  "ENSMUSG00000085747",
  "ENSMUSG00000085751",
  "ENSMUSG00000085755",
  "ENSMUSG00000085757",
  "ENSMUSG00000085763",
  "ENSMUSG00000085779",
  "ENSMUSG00000085785",
  "ENSMUSG00000085788",
  "ENSMUSG00000085794",
  "ENSMUSG00000085797",
  "ENSMUSG00000085798",
  "ENSMUSG00000085800",
  "ENSMUSG00000085804",
  "ENSMUSG00000085811",
  "ENSMUSG00000085817",
  "ENSMUSG00000085819",
  "ENSMUSG00000085820",
  "ENSMUSG00000085828",
  "ENSMUSG00000085829",
  "ENSMUSG00000085837",
  "ENSMUSG00000085842",
  "ENSMUSG00000085851",
  "ENSMUSG00000085852",
  "ENSMUSG00000085860",
  "ENSMUSG00000085862",
  "ENSMUSG00000085876",
  "ENSMUSG00000085883",
  "ENSMUSG00000085884",
  "ENSMUSG00000085886",
  "ENSMUSG00000085889",
  "ENSMUSG00000085890",
  "ENSMUSG00000085891",
  "ENSMUSG00000085902",
  "ENSMUSG00000085903",
  "ENSMUSG00000085912",
  "ENSMUSG00000085918",
  "ENSMUSG00000085928",
  "ENSMUSG00000085933",
  "ENSMUSG00000085936",
  "ENSMUSG00000085937",
  "ENSMUSG00000085944",
  "ENSMUSG00000085945",
  "ENSMUSG00000085956",
  "ENSMUSG00000085958",
  "ENSMUSG00000085965",
  "ENSMUSG00000085972",
  "ENSMUSG00000085976",
  "ENSMUSG00000085980",
  "ENSMUSG00000085982",
  "ENSMUSG00000085987",
  "ENSMUSG00000085992",
  "ENSMUSG00000085995",
  "ENSMUSG00000085996",
  "ENSMUSG00000086003",
  "ENSMUSG00000086008",
  "ENSMUSG00000086010",
  "ENSMUSG00000086013",
  "ENSMUSG00000086019",
  "ENSMUSG00000086025",
  "ENSMUSG00000086029",
  "ENSMUSG00000086035",
  "ENSMUSG00000086044",
  "ENSMUSG00000086046",
  "ENSMUSG00000086047",
  "ENSMUSG00000086058",
  "ENSMUSG00000086066",
  "ENSMUSG00000086070",
  "ENSMUSG00000086074",
  "ENSMUSG00000086080",
  "ENSMUSG00000086095",
  "ENSMUSG00000086108",
  "ENSMUSG00000086109",
  "ENSMUSG00000086119",
  "ENSMUSG00000086124",
  "ENSMUSG00000086126",
  "ENSMUSG00000086135",
  "ENSMUSG00000086141",
  "ENSMUSG00000086145",
  "ENSMUSG00000086155",
  "ENSMUSG00000086163",
  "ENSMUSG00000086172",
  "ENSMUSG00000086173",
  "ENSMUSG00000086179",
  "ENSMUSG00000086191",
  "ENSMUSG00000086201",
  "ENSMUSG00000086202",
  "ENSMUSG00000086207",
  "ENSMUSG00000086209",
  "ENSMUSG00000086241",
  "ENSMUSG00000086253",
  "ENSMUSG00000086256",
  "ENSMUSG00000086259",
  "ENSMUSG00000086263",
  "ENSMUSG00000086264",
  "ENSMUSG00000086266",
  "ENSMUSG00000086272",
  "ENSMUSG00000086273",
  "ENSMUSG00000086290",
  "ENSMUSG00000086298",
  "ENSMUSG00000086308",
  "ENSMUSG00000086315",
  "ENSMUSG00000086326",
  "ENSMUSG00000086327",
  "ENSMUSG00000086330",
  "ENSMUSG00000086332",
  "ENSMUSG00000086350",
  "ENSMUSG00000086359",
  "ENSMUSG00000086363",
  "ENSMUSG00000086369",
  "ENSMUSG00000086370",
  "ENSMUSG00000086379",
  "ENSMUSG00000086386",
  "ENSMUSG00000086389",
  "ENSMUSG00000086391",
  "ENSMUSG00000086392",
  "ENSMUSG00000086395",
  "ENSMUSG00000086396",
  "ENSMUSG00000086405",
  "ENSMUSG00000086425",
  "ENSMUSG00000086427",
  "ENSMUSG00000086429",
  "ENSMUSG00000086430",
  "ENSMUSG00000086437",
  "ENSMUSG00000086441",
  "ENSMUSG00000086446",
  "ENSMUSG00000086447",
  "ENSMUSG00000086448",
  "ENSMUSG00000086451",
  "ENSMUSG00000086454",
  "ENSMUSG00000086470",
  "ENSMUSG00000086484",
  "ENSMUSG00000086485",
  "ENSMUSG00000086486",
  "ENSMUSG00000086490",
  "ENSMUSG00000086494",
  "ENSMUSG00000086496",
  "ENSMUSG00000086503",
  "ENSMUSG00000086514",
  "ENSMUSG00000086522",
  "ENSMUSG00000086537",
  "ENSMUSG00000086541",
  "ENSMUSG00000086544",
  "ENSMUSG00000086552",
  "ENSMUSG00000086555",
  "ENSMUSG00000086594",
  "ENSMUSG00000086607",
  "ENSMUSG00000086608",
  "ENSMUSG00000086629",
  "ENSMUSG00000086631",
  "ENSMUSG00000086632",
  "ENSMUSG00000086641",
  "ENSMUSG00000086645",
  "ENSMUSG00000086646",
  "ENSMUSG00000086650",
  "ENSMUSG00000086655",
  "ENSMUSG00000086693",
  "ENSMUSG00000086702",
  "ENSMUSG00000086703",
  "ENSMUSG00000086711",
  "ENSMUSG00000086712",
  "ENSMUSG00000086714",
  "ENSMUSG00000086725",
  "ENSMUSG00000086728",
  "ENSMUSG00000086731",
  "ENSMUSG00000086732",
  "ENSMUSG00000086735",
  "ENSMUSG00000086739",
  "ENSMUSG00000086746",
  "ENSMUSG00000086760",
  "ENSMUSG00000086763",
  "ENSMUSG00000086775",
  "ENSMUSG00000086777",
  "ENSMUSG00000086782",
  "ENSMUSG00000086788",
  "ENSMUSG00000086800",
  "ENSMUSG00000086804",
  "ENSMUSG00000086805",
  "ENSMUSG00000086807",
  "ENSMUSG00000086808",
  "ENSMUSG00000086827",
  "ENSMUSG00000086841",
  "ENSMUSG00000086843",
  "ENSMUSG00000086844",
  "ENSMUSG00000086859",
  "ENSMUSG00000086862",
  "ENSMUSG00000086866",
  "ENSMUSG00000086867",
  "ENSMUSG00000086877",
  "ENSMUSG00000086889",
  "ENSMUSG00000086898",
  "ENSMUSG00000086902",
  "ENSMUSG00000086903",
  "ENSMUSG00000086907",
  "ENSMUSG00000086911",
  "ENSMUSG00000086912",
  "ENSMUSG00000086919",
  "ENSMUSG00000086924",
  "ENSMUSG00000086927",
  "ENSMUSG00000086930",
  "ENSMUSG00000086933",
  "ENSMUSG00000086938",
  "ENSMUSG00000086943",
  "ENSMUSG00000086945",
  "ENSMUSG00000086947",
  "ENSMUSG00000086951",
  "ENSMUSG00000086960",
  "ENSMUSG00000086963",
  "ENSMUSG00000086969",
  "ENSMUSG00000086978",
  "ENSMUSG00000086981",
  "ENSMUSG00000086993",
  "ENSMUSG00000086999",
  "ENSMUSG00000087003",
  "ENSMUSG00000087004",
  "ENSMUSG00000087009",
  "ENSMUSG00000087013",
  "ENSMUSG00000087017",
  "ENSMUSG00000087022",
  "ENSMUSG00000087037",
  "ENSMUSG00000087038",
  "ENSMUSG00000087056",
  "ENSMUSG00000087060",
  "ENSMUSG00000087061",
  "ENSMUSG00000087069",
  "ENSMUSG00000087077",
  "ENSMUSG00000087090",
  "ENSMUSG00000087095",
  "ENSMUSG00000087102",
  "ENSMUSG00000087103",
  "ENSMUSG00000087117",
  "ENSMUSG00000087137",
  "ENSMUSG00000087143",
  "ENSMUSG00000087145",
  "ENSMUSG00000087170",
  "ENSMUSG00000087171",
  "ENSMUSG00000087174",
  "ENSMUSG00000087176",
  "ENSMUSG00000087177",
  "ENSMUSG00000087179",
  "ENSMUSG00000087192",
  "ENSMUSG00000087193",
  "ENSMUSG00000087203",
  "ENSMUSG00000087213",
  "ENSMUSG00000087217",
  "ENSMUSG00000087222",
  "ENSMUSG00000087225",
  "ENSMUSG00000087226",
  "ENSMUSG00000087228",
  "ENSMUSG00000087231",
  "ENSMUSG00000087249",
  "ENSMUSG00000087254",
  "ENSMUSG00000087259",
  "ENSMUSG00000087265",
  "ENSMUSG00000087267",
  "ENSMUSG00000087269",
  "ENSMUSG00000087272",
  "ENSMUSG00000087277",
  "ENSMUSG00000087278",
  "ENSMUSG00000087283",
  "ENSMUSG00000087289",
  "ENSMUSG00000087292",
  "ENSMUSG00000087302",
  "ENSMUSG00000087305",
  "ENSMUSG00000087306",
  "ENSMUSG00000087312",
  "ENSMUSG00000087326",
  "ENSMUSG00000087332",
  "ENSMUSG00000087341",
  "ENSMUSG00000087347",
  "ENSMUSG00000087358",
  "ENSMUSG00000087360",
  "ENSMUSG00000087361",
  "ENSMUSG00000087362",
  "ENSMUSG00000087363",
  "ENSMUSG00000087365",
  "ENSMUSG00000087366",
  "ENSMUSG00000087368",
  "ENSMUSG00000087374",
  "ENSMUSG00000087377",
  "ENSMUSG00000087378",
  "ENSMUSG00000087380",
  "ENSMUSG00000087382",
  "ENSMUSG00000087388",
  "ENSMUSG00000087393",
  "ENSMUSG00000087394",
  "ENSMUSG00000087396",
  "ENSMUSG00000087400",
  "ENSMUSG00000087410",
  "ENSMUSG00000087424",
  "ENSMUSG00000087434",
  "ENSMUSG00000087437",
  "ENSMUSG00000087455",
  "ENSMUSG00000087458",
  "ENSMUSG00000087459",
  "ENSMUSG00000087461",
  "ENSMUSG00000087466",
  "ENSMUSG00000087475",
  "ENSMUSG00000087478",
  "ENSMUSG00000087487",
  "ENSMUSG00000087497",
  "ENSMUSG00000087516",
  "ENSMUSG00000087517",
  "ENSMUSG00000087521",
  "ENSMUSG00000087535",
  "ENSMUSG00000087536",
  "ENSMUSG00000087540",
  "ENSMUSG00000087553",
  "ENSMUSG00000087555",
  "ENSMUSG00000087561",
  "ENSMUSG00000087574",
  "ENSMUSG00000087579",
  "ENSMUSG00000087586",
  "ENSMUSG00000087589",
  "ENSMUSG00000087591",
  "ENSMUSG00000087604",
  "ENSMUSG00000087611",
  "ENSMUSG00000087620",
  "ENSMUSG00000087621",
  "ENSMUSG00000087624",
  "ENSMUSG00000087627",
  "ENSMUSG00000087644",
  "ENSMUSG00000087647",
  "ENSMUSG00000087648",
  "ENSMUSG00000087660",
  "ENSMUSG00000087670",
  "ENSMUSG00000087682",
  "ENSMUSG00000087684",
  "ENSMUSG00000087694",
  "ENSMUSG00000087700",
  "ENSMUSG00000089633",
  "ENSMUSG00000089647",
  "ENSMUSG00000089685",
  "ENSMUSG00000089699",
  "ENSMUSG00000089707",
  "ENSMUSG00000089708",
  "ENSMUSG00000089718",
  "ENSMUSG00000089726",
  "ENSMUSG00000089737",
  "ENSMUSG00000089746",
  "ENSMUSG00000089759",
  "ENSMUSG00000089815",
  "ENSMUSG00000089833",
  "ENSMUSG00000089835",
  "ENSMUSG00000089875",
  "ENSMUSG00000089887",
  "ENSMUSG00000089889",
  "ENSMUSG00000089900",
  "ENSMUSG00000089934",
  "ENSMUSG00000089940",
  "ENSMUSG00000089952",
  "ENSMUSG00000089968",
  "ENSMUSG00000089982",
  "ENSMUSG00000089998",
  "ENSMUSG00000090031",
  "ENSMUSG00000090086",
  "ENSMUSG00000090146",
  "ENSMUSG00000090160",
  "ENSMUSG00000090168",
  "ENSMUSG00000090220",
  "ENSMUSG00000090249",
  "ENSMUSG00000090254",
  "ENSMUSG00000090255",
  "ENSMUSG00000090257",
  "ENSMUSG00000090263",
  "ENSMUSG00000090270",
  "ENSMUSG00000090358",
  "ENSMUSG00000090386",
  "ENSMUSG00000090457",
  "ENSMUSG00000090534",
  "ENSMUSG00000090558",
  "ENSMUSG00000090722",
  "ENSMUSG00000090778",
  "ENSMUSG00000090925",
  "ENSMUSG00000091061",
  "ENSMUSG00000091378",
  "ENSMUSG00000091423",
  "ENSMUSG00000091475",
  "ENSMUSG00000091510",
  "ENSMUSG00000091514",
  "ENSMUSG00000091523",
  "ENSMUSG00000091575",
  "ENSMUSG00000091623",
  "ENSMUSG00000091906",
  "ENSMUSG00000091947",
  "ENSMUSG00000091993",
  "ENSMUSG00000092066",
  "ENSMUSG00000092071",
  "ENSMUSG00000092131",
  "ENSMUSG00000092171",
  "ENSMUSG00000092183",
  "ENSMUSG00000092203",
  "ENSMUSG00000092219",
  "ENSMUSG00000092239",
  "ENSMUSG00000092274",
  "ENSMUSG00000092276",
  "ENSMUSG00000092309",
  "ENSMUSG00000092341",
  "ENSMUSG00000092368",
  "ENSMUSG00000092384",
  "ENSMUSG00000092399",
  "ENSMUSG00000092402",
  "ENSMUSG00000092404",
  "ENSMUSG00000092539",
  "ENSMUSG00000092564",
  "ENSMUSG00000092570",
  "ENSMUSG00000092578",
  "ENSMUSG00000092581",
  "ENSMUSG00000092593",
  "ENSMUSG00000092604",
  "ENSMUSG00000092626",
  "ENSMUSG00000093386",
  "ENSMUSG00000093387",
  "ENSMUSG00000093424",
  "ENSMUSG00000093452",
  "ENSMUSG00000093460",
  "ENSMUSG00000093482",
  "ENSMUSG00000093483",
  "ENSMUSG00000093507",
  "ENSMUSG00000093528",
  "ENSMUSG00000093540",
  "ENSMUSG00000093568",
  "ENSMUSG00000093579",
  "ENSMUSG00000093594",
  "ENSMUSG00000093606",
  "ENSMUSG00000093617",
  "ENSMUSG00000093622",
  "ENSMUSG00000093634",
  "ENSMUSG00000093721",
  "ENSMUSG00000093759",
  "ENSMUSG00000093812",
  "ENSMUSG00000093846",
  "ENSMUSG00000093908",
  "ENSMUSG00000094002",
  "ENSMUSG00000094707",
  "ENSMUSG00000094732",
  "ENSMUSG00000094841",
  "ENSMUSG00000095123",
  "ENSMUSG00000095193",
  "ENSMUSG00000095213",
  "ENSMUSG00000095279",
  "ENSMUSG00000095332",
  "ENSMUSG00000095385",
  "ENSMUSG00000095563",
  "ENSMUSG00000095663",
  "ENSMUSG00000095953",
  "ENSMUSG00000095956",
  "ENSMUSG00000095959",
  "ENSMUSG00000096111",
  "ENSMUSG00000096144",
  "ENSMUSG00000096221",
  "ENSMUSG00000096553",
  "ENSMUSG00000096573",
  "ENSMUSG00000096655",
  "ENSMUSG00000096796",
  "ENSMUSG00000096917",
  "ENSMUSG00000096918",
  "ENSMUSG00000096929",
  "ENSMUSG00000096932",
  "ENSMUSG00000096935",
  "ENSMUSG00000096936",
  "ENSMUSG00000096940",
  "ENSMUSG00000096946",
  "ENSMUSG00000096948",
  "ENSMUSG00000096950",
  "ENSMUSG00000096954",
  "ENSMUSG00000096956",
  "ENSMUSG00000096960",
  "ENSMUSG00000096965",
  "ENSMUSG00000096971",
  "ENSMUSG00000096975",
  "ENSMUSG00000096977",
  "ENSMUSG00000096982",
  "ENSMUSG00000096986",
  "ENSMUSG00000096988",
  "ENSMUSG00000097002",
  "ENSMUSG00000097003",
  "ENSMUSG00000097004",
  "ENSMUSG00000097006",
  "ENSMUSG00000097007",
  "ENSMUSG00000097011",
  "ENSMUSG00000097016",
  "ENSMUSG00000097021",
  "ENSMUSG00000097022",
  "ENSMUSG00000097023",
  "ENSMUSG00000097032",
  "ENSMUSG00000097039",
  "ENSMUSG00000097040",
  "ENSMUSG00000097043",
  "ENSMUSG00000097044",
  "ENSMUSG00000097047",
  "ENSMUSG00000097048",
  "ENSMUSG00000097049",
  "ENSMUSG00000097055",
  "ENSMUSG00000097061",
  "ENSMUSG00000097068",
  "ENSMUSG00000097071",
  "ENSMUSG00000097072",
  "ENSMUSG00000097073",
  "ENSMUSG00000097074",
  "ENSMUSG00000097079",
  "ENSMUSG00000097080",
  "ENSMUSG00000097081",
  "ENSMUSG00000097082",
  "ENSMUSG00000097088",
  "ENSMUSG00000097089",
  "ENSMUSG00000097095",
  "ENSMUSG00000097099",
  "ENSMUSG00000097101",
  "ENSMUSG00000097103",
  "ENSMUSG00000097107",
  "ENSMUSG00000097113",
  "ENSMUSG00000097115",
  "ENSMUSG00000097119",
  "ENSMUSG00000097121",
  "ENSMUSG00000097124",
  "ENSMUSG00000097131",
  "ENSMUSG00000097134",
  "ENSMUSG00000097135",
  "ENSMUSG00000097145",
  "ENSMUSG00000097146",
  "ENSMUSG00000097149",
  "ENSMUSG00000097150",
  "ENSMUSG00000097156",
  "ENSMUSG00000097160",
  "ENSMUSG00000097162",
  "ENSMUSG00000097164",
  "ENSMUSG00000097166",
  "ENSMUSG00000097170",
  "ENSMUSG00000097172",
  "ENSMUSG00000097173",
  "ENSMUSG00000097174",
  "ENSMUSG00000097177",
  "ENSMUSG00000097181",
  "ENSMUSG00000097182",
  "ENSMUSG00000097183",
  "ENSMUSG00000097184",
  "ENSMUSG00000097191",
  "ENSMUSG00000097194",
  "ENSMUSG00000097195",
  "ENSMUSG00000097203",
  "ENSMUSG00000097207",
  "ENSMUSG00000097211",
  "ENSMUSG00000097216",
  "ENSMUSG00000097222",
  "ENSMUSG00000097229",
  "ENSMUSG00000097230",
  "ENSMUSG00000097247",
  "ENSMUSG00000097248",
  "ENSMUSG00000097251",
  "ENSMUSG00000097252",
  "ENSMUSG00000097254",
  "ENSMUSG00000097258",
  "ENSMUSG00000097276",
  "ENSMUSG00000097277",
  "ENSMUSG00000097279",
  "ENSMUSG00000097280",
  "ENSMUSG00000097284",
  "ENSMUSG00000097287",
  "ENSMUSG00000097288",
  "ENSMUSG00000097289",
  "ENSMUSG00000097290",
  "ENSMUSG00000097292",
  "ENSMUSG00000097299",
  "ENSMUSG00000097308",
  "ENSMUSG00000097310",
  "ENSMUSG00000097313",
  "ENSMUSG00000097316",
  "ENSMUSG00000097320",
  "ENSMUSG00000097321",
  "ENSMUSG00000097322",
  "ENSMUSG00000097323",
  "ENSMUSG00000097324",
  "ENSMUSG00000097329",
  "ENSMUSG00000097331",
  "ENSMUSG00000097334",
  "ENSMUSG00000097336",
  "ENSMUSG00000097337",
  "ENSMUSG00000097339",
  "ENSMUSG00000097343",
  "ENSMUSG00000097346",
  "ENSMUSG00000097350",
  "ENSMUSG00000097353",
  "ENSMUSG00000097354",
  "ENSMUSG00000097360",
  "ENSMUSG00000097361",
  "ENSMUSG00000097375",
  "ENSMUSG00000097377",
  "ENSMUSG00000097383",
  "ENSMUSG00000097386",
  "ENSMUSG00000097387",
  "ENSMUSG00000097391",
  "ENSMUSG00000097392",
  "ENSMUSG00000097398",
  "ENSMUSG00000097402",
  "ENSMUSG00000097403",
  "ENSMUSG00000097407",
  "ENSMUSG00000097413",
  "ENSMUSG00000097414",
  "ENSMUSG00000097421",
  "ENSMUSG00000097430",
  "ENSMUSG00000097435",
  "ENSMUSG00000097440",
  "ENSMUSG00000097444",
  "ENSMUSG00000097448",
  "ENSMUSG00000097451",
  "ENSMUSG00000097452",
  "ENSMUSG00000097453",
  "ENSMUSG00000097456",
  "ENSMUSG00000097460",
  "ENSMUSG00000097462",
  "ENSMUSG00000097463",
  "ENSMUSG00000097466",
  "ENSMUSG00000097467",
  "ENSMUSG00000097468",
  "ENSMUSG00000097471",
  "ENSMUSG00000097483",
  "ENSMUSG00000097491",
  "ENSMUSG00000097493",
  "ENSMUSG00000097494",
  "ENSMUSG00000097495",
  "ENSMUSG00000097497",
  "ENSMUSG00000097502",
  "ENSMUSG00000097515",
  "ENSMUSG00000097516",
  "ENSMUSG00000097523",
  "ENSMUSG00000097527",
  "ENSMUSG00000097534",
  "ENSMUSG00000097536",
  "ENSMUSG00000097537",
  "ENSMUSG00000097539",
  "ENSMUSG00000097543",
  "ENSMUSG00000097545",
  "ENSMUSG00000097547",
  "ENSMUSG00000097553",
  "ENSMUSG00000097559",
  "ENSMUSG00000097561",
  "ENSMUSG00000097571",
  "ENSMUSG00000097577",
  "ENSMUSG00000097580",
  "ENSMUSG00000097583",
  "ENSMUSG00000097585",
  "ENSMUSG00000097587",
  "ENSMUSG00000097589",
  "ENSMUSG00000097591",
  "ENSMUSG00000097595",
  "ENSMUSG00000097602",
  "ENSMUSG00000097615",
  "ENSMUSG00000097616",
  "ENSMUSG00000097617",
  "ENSMUSG00000097619",
  "ENSMUSG00000097621",
  "ENSMUSG00000097622",
  "ENSMUSG00000097624",
  "ENSMUSG00000097626",
  "ENSMUSG00000097627",
  "ENSMUSG00000097632",
  "ENSMUSG00000097636",
  "ENSMUSG00000097638",
  "ENSMUSG00000097639",
  "ENSMUSG00000097643",
  "ENSMUSG00000097648",
  "ENSMUSG00000097649",
  "ENSMUSG00000097650",
  "ENSMUSG00000097652",
  "ENSMUSG00000097662",
  "ENSMUSG00000097665",
  "ENSMUSG00000097666",
  "ENSMUSG00000097675",
  "ENSMUSG00000097682",
  "ENSMUSG00000097689",
  "ENSMUSG00000097690",
  "ENSMUSG00000097699",
  "ENSMUSG00000097709",
  "ENSMUSG00000097710",
  "ENSMUSG00000097712",
  "ENSMUSG00000097723",
  "ENSMUSG00000097724",
  "ENSMUSG00000097725",
  "ENSMUSG00000097726",
  "ENSMUSG00000097735",
  "ENSMUSG00000097736",
  "ENSMUSG00000097738",
  "ENSMUSG00000097740",
  "ENSMUSG00000097746",
  "ENSMUSG00000097750",
  "ENSMUSG00000097755",
  "ENSMUSG00000097756",
  "ENSMUSG00000097766",
  "ENSMUSG00000097768",
  "ENSMUSG00000097772",
  "ENSMUSG00000097773",
  "ENSMUSG00000097776",
  "ENSMUSG00000097779",
  "ENSMUSG00000097784",
  "ENSMUSG00000097787",
  "ENSMUSG00000097792",
  "ENSMUSG00000097794",
  "ENSMUSG00000097800",
  "ENSMUSG00000097814",
  "ENSMUSG00000097820",
  "ENSMUSG00000097825",
  "ENSMUSG00000097827",
  "ENSMUSG00000097828",
  "ENSMUSG00000097831",
  "ENSMUSG00000097841",
  "ENSMUSG00000097847",
  "ENSMUSG00000097848",
  "ENSMUSG00000097850",
  "ENSMUSG00000097852",
  "ENSMUSG00000097855",
  "ENSMUSG00000097867",
  "ENSMUSG00000097868",
  "ENSMUSG00000097869",
  "ENSMUSG00000097871",
  "ENSMUSG00000097874",
  "ENSMUSG00000097881",
  "ENSMUSG00000097885",
  "ENSMUSG00000097888",
  "ENSMUSG00000097890",
  "ENSMUSG00000097893",
  "ENSMUSG00000097899",
  "ENSMUSG00000097908",
  "ENSMUSG00000097910",
  "ENSMUSG00000097913",
  "ENSMUSG00000097915",
  "ENSMUSG00000097921",
  "ENSMUSG00000097924",
  "ENSMUSG00000097927",
  "ENSMUSG00000097933",
  "ENSMUSG00000097934",
  "ENSMUSG00000097944",
  "ENSMUSG00000097960",
  "ENSMUSG00000097962",
  "ENSMUSG00000097971",
  "ENSMUSG00000097974",
  "ENSMUSG00000097988",
  "ENSMUSG00000097993",
  "ENSMUSG00000097997",
  "ENSMUSG00000098001",
  "ENSMUSG00000098008",
  "ENSMUSG00000098014",
  "ENSMUSG00000098024",
  "ENSMUSG00000098040",
  "ENSMUSG00000098045",
  "ENSMUSG00000098097",
  "ENSMUSG00000098098",
  "ENSMUSG00000098108",
  "ENSMUSG00000098120",
  "ENSMUSG00000098127",
  "ENSMUSG00000098128",
  "ENSMUSG00000098145",
  "ENSMUSG00000098150",
  "ENSMUSG00000098155",
  "ENSMUSG00000098164",
  "ENSMUSG00000098178",
  "ENSMUSG00000098184",
  "ENSMUSG00000098197",
  "ENSMUSG00000098202",
  "ENSMUSG00000098206",
  "ENSMUSG00000098234",
  "ENSMUSG00000098243",
  "ENSMUSG00000098284",
  "ENSMUSG00000098292",
  "ENSMUSG00000098318",
  "ENSMUSG00000098322",
  "ENSMUSG00000098332",
  "ENSMUSG00000098340",
  "ENSMUSG00000098383",
  "ENSMUSG00000098449",
  "ENSMUSG00000098609",
  "ENSMUSG00000098627",
  "ENSMUSG00000098659",
  "ENSMUSG00000098682",
  "ENSMUSG00000098702",
  "ENSMUSG00000098705",
  "ENSMUSG00000098727",
  "ENSMUSG00000098758",
  "ENSMUSG00000098760",
  "ENSMUSG00000098801",
  "ENSMUSG00000098854",
  "ENSMUSG00000098912",
  "ENSMUSG00000098959",
  "ENSMUSG00000099061",
  "ENSMUSG00000099098",
  "ENSMUSG00000099137",
  "ENSMUSG00000099139",
  "ENSMUSG00000099148",
  "ENSMUSG00000099170",
  "ENSMUSG00000099207",
  "ENSMUSG00000099338",
  "ENSMUSG00000099354",
  "ENSMUSG00000099360",
  "ENSMUSG00000099364",
  "ENSMUSG00000099370",
  "ENSMUSG00000099378",
  "ENSMUSG00000099384",
  "ENSMUSG00000099391",
  "ENSMUSG00000099411",
  "ENSMUSG00000099413",
  "ENSMUSG00000099446",
  "ENSMUSG00000099452",
  "ENSMUSG00000099465",
  "ENSMUSG00000099468",
  "ENSMUSG00000099470",
  "ENSMUSG00000099474",
  "ENSMUSG00000099508",
  "ENSMUSG00000099528",
  "ENSMUSG00000099553",
  "ENSMUSG00000099561",
  "ENSMUSG00000099579",
  "ENSMUSG00000099590",
  "ENSMUSG00000099616",
  "ENSMUSG00000099630",
  "ENSMUSG00000099639",
  "ENSMUSG00000099665",
  "ENSMUSG00000099681",
  "ENSMUSG00000099696",
  "ENSMUSG00000099707",
  "ENSMUSG00000099724",
  "ENSMUSG00000099735",
  "ENSMUSG00000099747",
  "ENSMUSG00000099749",
  "ENSMUSG00000099751",
  "ENSMUSG00000099752",
  "ENSMUSG00000099759",
  "ENSMUSG00000099794",
  "ENSMUSG00000099808",
  "ENSMUSG00000099843",
  "ENSMUSG00000099847",
  "ENSMUSG00000099860",
  "ENSMUSG00000099877",
  "ENSMUSG00000099881",
  "ENSMUSG00000099906",
  "ENSMUSG00000099907",
  "ENSMUSG00000099922",
  "ENSMUSG00000099945",
  "ENSMUSG00000099950",
  "ENSMUSG00000099957",
  "ENSMUSG00000099958",
  "ENSMUSG00000099966",
  "ENSMUSG00000100000",
  "ENSMUSG00000100001",
  "ENSMUSG00000100005",
  "ENSMUSG00000100009",
  "ENSMUSG00000100017",
  "ENSMUSG00000100084",
  "ENSMUSG00000100094",
  "ENSMUSG00000100106",
  "ENSMUSG00000100119",
  "ENSMUSG00000100120",
  "ENSMUSG00000100121",
  "ENSMUSG00000100147",
  "ENSMUSG00000100155",
  "ENSMUSG00000100164",
  "ENSMUSG00000100166",
  "ENSMUSG00000100182",
  "ENSMUSG00000100219",
  "ENSMUSG00000100252",
  "ENSMUSG00000100253",
  "ENSMUSG00000100274",
  "ENSMUSG00000100277",
  "ENSMUSG00000100282",
  "ENSMUSG00000100294",
  "ENSMUSG00000100301",
  "ENSMUSG00000100315",
  "ENSMUSG00000100335",
  "ENSMUSG00000100354",
  "ENSMUSG00000100364",
  "ENSMUSG00000100426",
  "ENSMUSG00000100431",
  "ENSMUSG00000100437",
  "ENSMUSG00000100444",
  "ENSMUSG00000100459",
  "ENSMUSG00000100483",
  "ENSMUSG00000100502",
  "ENSMUSG00000100510",
  "ENSMUSG00000100511",
  "ENSMUSG00000100518",
  "ENSMUSG00000100546",
  "ENSMUSG00000100553",
  "ENSMUSG00000100557",
  "ENSMUSG00000100573",
  "ENSMUSG00000100580",
  "ENSMUSG00000100585",
  "ENSMUSG00000100599",
  "ENSMUSG00000100603",
  "ENSMUSG00000100605",
  "ENSMUSG00000100632",
  "ENSMUSG00000100666",
  "ENSMUSG00000100668",
  "ENSMUSG00000100714",
  "ENSMUSG00000100727",
  "ENSMUSG00000100733",
  "ENSMUSG00000100747",
  "ENSMUSG00000100760",
  "ENSMUSG00000100768",
  "ENSMUSG00000100783",
  "ENSMUSG00000100826",
  "ENSMUSG00000100837",
  "ENSMUSG00000100850",
  "ENSMUSG00000100857",
  "ENSMUSG00000100890",
  "ENSMUSG00000100969",
  "ENSMUSG00000100985",
  "ENSMUSG00000101009",
  "ENSMUSG00000101012",
  "ENSMUSG00000101013",
  "ENSMUSG00000101028",
  "ENSMUSG00000101051",
  "ENSMUSG00000101126",
  "ENSMUSG00000101153",
  "ENSMUSG00000101179",
  "ENSMUSG00000101189",
  "ENSMUSG00000101225",
  "ENSMUSG00000101257",
  "ENSMUSG00000101268",
  "ENSMUSG00000101275",
  "ENSMUSG00000101324",
  "ENSMUSG00000101334",
  "ENSMUSG00000101335",
  "ENSMUSG00000101356",
  "ENSMUSG00000101360",
  "ENSMUSG00000101365",
  "ENSMUSG00000101386",
  "ENSMUSG00000101404",
  "ENSMUSG00000101463",
  "ENSMUSG00000101483",
  "ENSMUSG00000101493",
  "ENSMUSG00000101505",
  "ENSMUSG00000101514",
  "ENSMUSG00000101517",
  "ENSMUSG00000101549",
  "ENSMUSG00000101553",
  "ENSMUSG00000101568",
  "ENSMUSG00000101581",
  "ENSMUSG00000101584",
  "ENSMUSG00000101585",
  "ENSMUSG00000101599",
  "ENSMUSG00000101609",
  "ENSMUSG00000101621",
  "ENSMUSG00000101636",
  "ENSMUSG00000101655",
  "ENSMUSG00000101674",
  "ENSMUSG00000101714",
  "ENSMUSG00000101734",
  "ENSMUSG00000101741",
  "ENSMUSG00000101768",
  "ENSMUSG00000101791",
  "ENSMUSG00000101799",
  "ENSMUSG00000101848",
  "ENSMUSG00000101849",
  "ENSMUSG00000101856",
  "ENSMUSG00000101888",
  "ENSMUSG00000101894",
  "ENSMUSG00000101895",
  "ENSMUSG00000101930",
  "ENSMUSG00000101951",
  "ENSMUSG00000101952",
  "ENSMUSG00000101968",
  "ENSMUSG00000101970",
  "ENSMUSG00000102004",
  "ENSMUSG00000102009",
  "ENSMUSG00000102046",
  "ENSMUSG00000102060",
  "ENSMUSG00000102069",
  "ENSMUSG00000102080",
  "ENSMUSG00000102098",
  "ENSMUSG00000102099",
  "ENSMUSG00000102101",
  "ENSMUSG00000102123",
  "ENSMUSG00000102158",
  "ENSMUSG00000102215",
  "ENSMUSG00000102224",
  "ENSMUSG00000102279",
  "ENSMUSG00000102329",
  "ENSMUSG00000102362",
  "ENSMUSG00000102397",
  "ENSMUSG00000102408",
  "ENSMUSG00000102544",
  "ENSMUSG00000102545",
  "ENSMUSG00000102574",
  "ENSMUSG00000102608",
  "ENSMUSG00000102629",
  "ENSMUSG00000102715",
  "ENSMUSG00000102716",
  "ENSMUSG00000102774",
  "ENSMUSG00000102785",
  "ENSMUSG00000102862",
  "ENSMUSG00000102869",
  "ENSMUSG00000102887",
  "ENSMUSG00000102893",
  "ENSMUSG00000102909",
  "ENSMUSG00000102987",
  "ENSMUSG00000103103",
  "ENSMUSG00000103156",
  "ENSMUSG00000103170",
  "ENSMUSG00000103181",
  "ENSMUSG00000103346",
  "ENSMUSG00000103428",
  "ENSMUSG00000103464",
  "ENSMUSG00000103503",
  "ENSMUSG00000103577",
  "ENSMUSG00000103594",
  "ENSMUSG00000103668",
  "ENSMUSG00000103695",
  "ENSMUSG00000103708",
  "ENSMUSG00000103726",
  "ENSMUSG00000103844",
  "ENSMUSG00000103912",
  "ENSMUSG00000103923",
  "ENSMUSG00000103927",
  "ENSMUSG00000104004",
  "ENSMUSG00000104011",
  "ENSMUSG00000104014",
  "ENSMUSG00000104015",
  "ENSMUSG00000104055",
  "ENSMUSG00000104093",
  "ENSMUSG00000104106",
  "ENSMUSG00000104156",
  "ENSMUSG00000104161",
  "ENSMUSG00000104178",
  "ENSMUSG00000104234",
  "ENSMUSG00000104284",
  "ENSMUSG00000104340",
  "ENSMUSG00000104366",
  "ENSMUSG00000104465",
  "ENSMUSG00000104487",
  "ENSMUSG00000104507",
  "ENSMUSG00000104509",
  "ENSMUSG00000104529",
  "ENSMUSG00000104543",
  "ENSMUSG00000104553",
  "ENSMUSG00000104554",
  "ENSMUSG00000104586",
  "ENSMUSG00000104597",
  "ENSMUSG00000104610",
  "ENSMUSG00000104703",
  "ENSMUSG00000104717",
  "ENSMUSG00000104786",
  "ENSMUSG00000104818",
  "ENSMUSG00000104830",
  "ENSMUSG00000104835",
  "ENSMUSG00000104861",
  "ENSMUSG00000104904",
  "ENSMUSG00000104939",
  "ENSMUSG00000104955",
  "ENSMUSG00000104956",
  "ENSMUSG00000104968",
  "ENSMUSG00000104973",
  "ENSMUSG00000105039",
  "ENSMUSG00000105105",
  "ENSMUSG00000105185",
  "ENSMUSG00000105222",
  "ENSMUSG00000105224",
  "ENSMUSG00000105239",
  "ENSMUSG00000105258",
  "ENSMUSG00000105260",
  "ENSMUSG00000105265",
  "ENSMUSG00000105274",
  "ENSMUSG00000105279",
  "ENSMUSG00000105297",
  "ENSMUSG00000105300",
  "ENSMUSG00000105376",
  "ENSMUSG00000105385",
  "ENSMUSG00000105402",
  "ENSMUSG00000105426",
  "ENSMUSG00000105437",
  "ENSMUSG00000105439",
  "ENSMUSG00000105490",
  "ENSMUSG00000105501",
  "ENSMUSG00000105531",
  "ENSMUSG00000105535",
  "ENSMUSG00000105602",
  "ENSMUSG00000105615",
  "ENSMUSG00000105618",
  "ENSMUSG00000105619",
  "ENSMUSG00000105646",
  "ENSMUSG00000105652",
  "ENSMUSG00000105662",
  "ENSMUSG00000105703",
  "ENSMUSG00000105709",
  "ENSMUSG00000105759",
  "ENSMUSG00000105765",
  "ENSMUSG00000105773",
  "ENSMUSG00000105795",
  "ENSMUSG00000105811",
  "ENSMUSG00000105816",
  "ENSMUSG00000105838",
  "ENSMUSG00000105851",
  "ENSMUSG00000105881",
  "ENSMUSG00000105888",
  "ENSMUSG00000105891",
  "ENSMUSG00000105912",
  "ENSMUSG00000105975",
  "ENSMUSG00000105987",
  "ENSMUSG00000106000",
  "ENSMUSG00000106002",
  "ENSMUSG00000106019",
  "ENSMUSG00000106093",
  "ENSMUSG00000106122",
  "ENSMUSG00000106168",
  "ENSMUSG00000106210",
  "ENSMUSG00000106219",
  "ENSMUSG00000106223",
  "ENSMUSG00000106237",
  "ENSMUSG00000106269",
  "ENSMUSG00000106306",
  "ENSMUSG00000106357",
  "ENSMUSG00000106371",
  "ENSMUSG00000106378",
  "ENSMUSG00000106380",
  "ENSMUSG00000106422",
  "ENSMUSG00000106441",
  "ENSMUSG00000106461",
  "ENSMUSG00000106464",
  "ENSMUSG00000106492",
  "ENSMUSG00000106540",
  "ENSMUSG00000106547",
  "ENSMUSG00000106555",
  "ENSMUSG00000106583",
  "ENSMUSG00000106586",
  "ENSMUSG00000106589",
  "ENSMUSG00000106644",
  "ENSMUSG00000106683",
  "ENSMUSG00000106726",
  "ENSMUSG00000106734",
  "ENSMUSG00000106738",
  "ENSMUSG00000106762",
  "ENSMUSG00000106779",
  "ENSMUSG00000106791",
  "ENSMUSG00000106795",
  "ENSMUSG00000106807",
  "ENSMUSG00000106808",
  "ENSMUSG00000106820",
  "ENSMUSG00000106847",
  "ENSMUSG00000106871",
  "ENSMUSG00000106902",
  "ENSMUSG00000106927",
  "ENSMUSG00000106932",
  "ENSMUSG00000106943",
  "ENSMUSG00000106951",
  "ENSMUSG00000106968",
  "ENSMUSG00000106978",
  "ENSMUSG00000107010",
  "ENSMUSG00000107013",
  "ENSMUSG00000107036",
  "ENSMUSG00000107044",
  "ENSMUSG00000107090",
  "ENSMUSG00000107108",
  "ENSMUSG00000107110",
  "ENSMUSG00000107111",
  "ENSMUSG00000107143",
  "ENSMUSG00000107144",
  "ENSMUSG00000107164",
  "ENSMUSG00000107214",
  "ENSMUSG00000107217",
  "ENSMUSG00000107248",
  "ENSMUSG00000107271",
  "ENSMUSG00000107301",
  "ENSMUSG00000107305",
  "ENSMUSG00000107341",
  "ENSMUSG00000107352",
  "ENSMUSG00000107353",
  "ENSMUSG00000107385",
  "ENSMUSG00000107386",
  "ENSMUSG00000107434",
  "ENSMUSG00000107477",
  "ENSMUSG00000107496",
  "ENSMUSG00000107516",
  "ENSMUSG00000107526",
  "ENSMUSG00000107585",
  "ENSMUSG00000107614",
  "ENSMUSG00000107619",
  "ENSMUSG00000107622",
  "ENSMUSG00000107623",
  "ENSMUSG00000107637",
  "ENSMUSG00000107638",
  "ENSMUSG00000107651",
  "ENSMUSG00000107660",
  "ENSMUSG00000107667",
  "ENSMUSG00000107681",
  "ENSMUSG00000107709",
  "ENSMUSG00000107732",
  "ENSMUSG00000107785",
  "ENSMUSG00000107797",
  "ENSMUSG00000107815",
  "ENSMUSG00000107879",
  "ENSMUSG00000107880",
  "ENSMUSG00000107887",
  "ENSMUSG00000107895",
  "ENSMUSG00000107926",
  "ENSMUSG00000107933",
  "ENSMUSG00000107935",
  "ENSMUSG00000107948",
  "ENSMUSG00000107956",
  "ENSMUSG00000107988",
  "ENSMUSG00000107993",
  "ENSMUSG00000107994",
  "ENSMUSG00000108015",
  "ENSMUSG00000108020",
  "ENSMUSG00000108030",
  "ENSMUSG00000108043",
  "ENSMUSG00000108060",
  "ENSMUSG00000108062",
  "ENSMUSG00000108063",
  "ENSMUSG00000108071",
  "ENSMUSG00000108084",
  "ENSMUSG00000108103",
  "ENSMUSG00000108111",
  "ENSMUSG00000108113",
  "ENSMUSG00000108129",
  "ENSMUSG00000108175",
  "ENSMUSG00000108187",
  "ENSMUSG00000108192",
  "ENSMUSG00000108207",
  "ENSMUSG00000108228",
  "ENSMUSG00000108236",
  "ENSMUSG00000108242",
  "ENSMUSG00000108255",
  "ENSMUSG00000108313",
  "ENSMUSG00000108322",
  "ENSMUSG00000108345",
  "ENSMUSG00000108354",
  "ENSMUSG00000108379",
  "ENSMUSG00000108382",
  "ENSMUSG00000108383",
  "ENSMUSG00000108413",
  "ENSMUSG00000108414",
  "ENSMUSG00000108427",
  "ENSMUSG00000108431",
  "ENSMUSG00000108446",
  "ENSMUSG00000108461",
  "ENSMUSG00000108470",
  "ENSMUSG00000108523",
  "ENSMUSG00000108575",
  "ENSMUSG00000108603",
  "ENSMUSG00000108616",
  "ENSMUSG00000108621",
  "ENSMUSG00000108627",
  "ENSMUSG00000108652",
  "ENSMUSG00000108695",
  "ENSMUSG00000108736",
  "ENSMUSG00000108738",
  "ENSMUSG00000108757",
  "ENSMUSG00000108801",
  "ENSMUSG00000108811",
  "ENSMUSG00000108848",
  "ENSMUSG00000108854",
  "ENSMUSG00000108885",
  "ENSMUSG00000108888",
  "ENSMUSG00000108924",
  "ENSMUSG00000108930",
  "ENSMUSG00000108935",
  "ENSMUSG00000108950",
  "ENSMUSG00000108980",
  "ENSMUSG00000109002",
  "ENSMUSG00000109008",
  "ENSMUSG00000109080",
  "ENSMUSG00000109089",
  "ENSMUSG00000109097",
  "ENSMUSG00000109106",
  "ENSMUSG00000109110",
  "ENSMUSG00000109113",
  "ENSMUSG00000109118",
  "ENSMUSG00000109127",
  "ENSMUSG00000109190",
  "ENSMUSG00000109215",
  "ENSMUSG00000109251",
  "ENSMUSG00000109270",
  "ENSMUSG00000109282",
  "ENSMUSG00000109284",
  "ENSMUSG00000109291",
  "ENSMUSG00000109321",
  "ENSMUSG00000109382",
  "ENSMUSG00000109394",
  "ENSMUSG00000109473",
  "ENSMUSG00000109499",
  "ENSMUSG00000109582",
  "ENSMUSG00000109587",
  "ENSMUSG00000109644",
  "ENSMUSG00000109701",
  "ENSMUSG00000109722",
  "ENSMUSG00000109770",
  "ENSMUSG00000109780",
  "ENSMUSG00000109783",
  "ENSMUSG00000109808",
  "ENSMUSG00000109809",
  "ENSMUSG00000109819",
  "ENSMUSG00000109827",
  "ENSMUSG00000109841",
  "ENSMUSG00000109846",
  "ENSMUSG00000109853",
  "ENSMUSG00000109890",
  "ENSMUSG00000109915",
  "ENSMUSG00000109962",
  "ENSMUSG00000109995",
  "ENSMUSG00000110001",
  "ENSMUSG00000110009",
  "ENSMUSG00000110013",
  "ENSMUSG00000110026",
  "ENSMUSG00000110037",
  "ENSMUSG00000110042",
  "ENSMUSG00000110058",
  "ENSMUSG00000110067",
  "ENSMUSG00000110080",
  "ENSMUSG00000110101",
  "ENSMUSG00000110106",
  "ENSMUSG00000110116",
  "ENSMUSG00000110138",
  "ENSMUSG00000110148",
  "ENSMUSG00000110161",
  "ENSMUSG00000110217",
  "ENSMUSG00000110231",
  "ENSMUSG00000110235",
  "ENSMUSG00000110264",
  "ENSMUSG00000110278",
  "ENSMUSG00000110281",
  "ENSMUSG00000110286",
  "ENSMUSG00000110321",
  "ENSMUSG00000110340",
  "ENSMUSG00000110356",
  "ENSMUSG00000110357",
  "ENSMUSG00000110390",
  "ENSMUSG00000110409",
  "ENSMUSG00000110410",
  "ENSMUSG00000110417",
  "ENSMUSG00000110419",
  "ENSMUSG00000110424",
  "ENSMUSG00000110427",
  "ENSMUSG00000110476",
  "ENSMUSG00000110523",
  "ENSMUSG00000110537",
  "ENSMUSG00000110541",
  "ENSMUSG00000110554",
  "ENSMUSG00000110580",
  "ENSMUSG00000110592",
  "ENSMUSG00000110594",
  "ENSMUSG00000110605",
  "ENSMUSG00000110629",
  "ENSMUSG00000110630",
  "ENSMUSG00000110647",
  "ENSMUSG00000110661",
  "ENSMUSG00000110682",
  "ENSMUSG00000110684",
  "ENSMUSG00000110697",
  "ENSMUSG00000110710",
  "ENSMUSG00000110737",
  "ENSMUSG00000110752",
  "ENSMUSG00000110781",
  "ENSMUSG00000110784",
  "ENSMUSG00000110862",
  "ENSMUSG00000110874",
  "ENSMUSG00000110876",
  "ENSMUSG00000110945",
  "ENSMUSG00000110980",
  "ENSMUSG00000111008",
  "ENSMUSG00000111013",
  "ENSMUSG00000111020",
  "ENSMUSG00000111028",
  "ENSMUSG00000111037",
  "ENSMUSG00000111054",
  "ENSMUSG00000111056",
  "ENSMUSG00000111058",
  "ENSMUSG00000111078",
  "ENSMUSG00000111079",
  "ENSMUSG00000111085",
  "ENSMUSG00000111086",
  "ENSMUSG00000111103",
  "ENSMUSG00000111180",
  "ENSMUSG00000111243",
  "ENSMUSG00000111246",
  "ENSMUSG00000111250",
  "ENSMUSG00000111278",
  "ENSMUSG00000111290",
  "ENSMUSG00000111293",
  "ENSMUSG00000111303",
  "ENSMUSG00000111329",
  "ENSMUSG00000111334",
  "ENSMUSG00000111342",
  "ENSMUSG00000111357",
  "ENSMUSG00000111412",
  "ENSMUSG00000111422",
  "ENSMUSG00000111439",
  "ENSMUSG00000111489",
  "ENSMUSG00000111494",
  "ENSMUSG00000111511",
  "ENSMUSG00000111520",
  "ENSMUSG00000111556",
  "ENSMUSG00000111593",
  "ENSMUSG00000111629",
  "ENSMUSG00000111637",
  "ENSMUSG00000111669",
  "ENSMUSG00000111673",
  "ENSMUSG00000111699",
  "ENSMUSG00000111729",
  "ENSMUSG00000111731",
  "ENSMUSG00000111734",
  "ENSMUSG00000111746",
  "ENSMUSG00000111761",
  "ENSMUSG00000111765",
  "ENSMUSG00000111774",
  "ENSMUSG00000111785",
  "ENSMUSG00000111794",
  "ENSMUSG00000111797",
  "ENSMUSG00000111807",
  "ENSMUSG00000111818",
  "ENSMUSG00000111825",
  "ENSMUSG00000111830",
  "ENSMUSG00000111836",
  "ENSMUSG00000111857",
  "ENSMUSG00000111866",
  "ENSMUSG00000111867",
  "ENSMUSG00000111903",
  "ENSMUSG00000111924",
  "ENSMUSG00000111952",
  "ENSMUSG00000111961",
  "ENSMUSG00000111970",
  "ENSMUSG00000111994",
  "ENSMUSG00000112029",
  "ENSMUSG00000112030",
  "ENSMUSG00000112041",
  "ENSMUSG00000112071",
  "ENSMUSG00000112117",
  "ENSMUSG00000112121",
  "ENSMUSG00000112141",
  "ENSMUSG00000112157",
  "ENSMUSG00000112198",
  "ENSMUSG00000112201",
  "ENSMUSG00000112206",
  "ENSMUSG00000112210",
  "ENSMUSG00000112230",
  "ENSMUSG00000112242",
  "ENSMUSG00000112258",
  "ENSMUSG00000112264",
  "ENSMUSG00000112265",
  "ENSMUSG00000112276",
  "ENSMUSG00000112280",
  "ENSMUSG00000112290",
  "ENSMUSG00000112314",
  "ENSMUSG00000112323",
  "ENSMUSG00000112343",
  "ENSMUSG00000112397",
  "ENSMUSG00000112398",
  "ENSMUSG00000112399",
  "ENSMUSG00000112404",
  "ENSMUSG00000112406",
  "ENSMUSG00000112412",
  "ENSMUSG00000112421",
  "ENSMUSG00000112444",
  "ENSMUSG00000112452",
  "ENSMUSG00000112466",
  "ENSMUSG00000112481",
  "ENSMUSG00000112482",
  "ENSMUSG00000112484",
  "ENSMUSG00000112489",
  "ENSMUSG00000112505",
  "ENSMUSG00000112513",
  "ENSMUSG00000112519",
  "ENSMUSG00000112527",
  "ENSMUSG00000112532",
  "ENSMUSG00000112533",
  "ENSMUSG00000112539",
  "ENSMUSG00000112540",
  "ENSMUSG00000112544",
  "ENSMUSG00000112558",
  "ENSMUSG00000112609",
  "ENSMUSG00000112627",
  "ENSMUSG00000112639",
  "ENSMUSG00000112647",
  "ENSMUSG00000112654",
  "ENSMUSG00000112701",
  "ENSMUSG00000112705",
  "ENSMUSG00000112716",
  "ENSMUSG00000112762",
  "ENSMUSG00000112765",
  "ENSMUSG00000112796",
  "ENSMUSG00000112803",
  "ENSMUSG00000112821",
  "ENSMUSG00000112832",
  "ENSMUSG00000112837",
  "ENSMUSG00000112846",
  "ENSMUSG00000112854",
  "ENSMUSG00000112871",
  "ENSMUSG00000112896",
  "ENSMUSG00000112907",
  "ENSMUSG00000112911",
  "ENSMUSG00000112924",
  "ENSMUSG00000112940",
  "ENSMUSG00000112941",
  "ENSMUSG00000112957",
  "ENSMUSG00000112963",
  "ENSMUSG00000112980",
  "ENSMUSG00000112988",
  "ENSMUSG00000113035",
  "ENSMUSG00000113047",
  "ENSMUSG00000113056",
  "ENSMUSG00000113057",
  "ENSMUSG00000113058",
  "ENSMUSG00000113074",
  "ENSMUSG00000113087",
  "ENSMUSG00000113093",
  "ENSMUSG00000113101",
  "ENSMUSG00000113210",
  "ENSMUSG00000113211",
  "ENSMUSG00000113216",
  "ENSMUSG00000113218",
  "ENSMUSG00000113220",
  "ENSMUSG00000113302",
  "ENSMUSG00000113303",
  "ENSMUSG00000113314",
  "ENSMUSG00000113321",
  "ENSMUSG00000113331",
  "ENSMUSG00000113334",
  "ENSMUSG00000113336",
  "ENSMUSG00000113346",
  "ENSMUSG00000113361",
  "ENSMUSG00000113404",
  "ENSMUSG00000113415",
  "ENSMUSG00000113447",
  "ENSMUSG00000113463",
  "ENSMUSG00000113465",
  "ENSMUSG00000113470",
  "ENSMUSG00000113484",
  "ENSMUSG00000113492",
  "ENSMUSG00000113535",
  "ENSMUSG00000113544",
  "ENSMUSG00000113573",
  "ENSMUSG00000113575",
  "ENSMUSG00000113581",
  "ENSMUSG00000113616",
  "ENSMUSG00000113628",
  "ENSMUSG00000113634",
  "ENSMUSG00000113645",
  "ENSMUSG00000113652",
  "ENSMUSG00000113669",
  "ENSMUSG00000113671",
  "ENSMUSG00000113679",
  "ENSMUSG00000113689",
  "ENSMUSG00000113696",
  "ENSMUSG00000113707",
  "ENSMUSG00000113722",
  "ENSMUSG00000113769",
  "ENSMUSG00000113774",
  "ENSMUSG00000113778",
  "ENSMUSG00000113796",
  "ENSMUSG00000113800",
  "ENSMUSG00000113802",
  "ENSMUSG00000113816",
  "ENSMUSG00000113837",
  "ENSMUSG00000113856",
  "ENSMUSG00000113875",
  "ENSMUSG00000113884",
  "ENSMUSG00000113902",
  "ENSMUSG00000113909",
  "ENSMUSG00000113918",
  "ENSMUSG00000113919",
  "ENSMUSG00000113930",
  "ENSMUSG00000113947",
  "ENSMUSG00000113960",
  "ENSMUSG00000113985",
  "ENSMUSG00000114024",
  "ENSMUSG00000114033",
  "ENSMUSG00000114048",
  "ENSMUSG00000114069",
  "ENSMUSG00000114136",
  "ENSMUSG00000114159",
  "ENSMUSG00000114163",
  "ENSMUSG00000114181",
  "ENSMUSG00000114189",
  "ENSMUSG00000114196",
  "ENSMUSG00000114205",
  "ENSMUSG00000114206",
  "ENSMUSG00000114208",
  "ENSMUSG00000114210",
  "ENSMUSG00000114213",
  "ENSMUSG00000114235",
  "ENSMUSG00000114255",
  "ENSMUSG00000114274",
  "ENSMUSG00000114290",
  "ENSMUSG00000114303",
  "ENSMUSG00000114306",
  "ENSMUSG00000114333",
  "ENSMUSG00000114345",
  "ENSMUSG00000114350",
  "ENSMUSG00000114352",
  "ENSMUSG00000114362",
  "ENSMUSG00000114372",
  "ENSMUSG00000114379",
  "ENSMUSG00000114382",
  "ENSMUSG00000114384",
  "ENSMUSG00000114390",
  "ENSMUSG00000114393",
  "ENSMUSG00000114412",
  "ENSMUSG00000114439",
  "ENSMUSG00000114442",
  "ENSMUSG00000114452",
  "ENSMUSG00000114468",
  "ENSMUSG00000114469",
  "ENSMUSG00000114474",
  "ENSMUSG00000114491",
  "ENSMUSG00000114492",
  "ENSMUSG00000114511",
  "ENSMUSG00000114531",
  "ENSMUSG00000114539",
  "ENSMUSG00000114553",
  "ENSMUSG00000114554",
  "ENSMUSG00000114564",
  "ENSMUSG00000114567",
  "ENSMUSG00000114590",
  "ENSMUSG00000114598",
  "ENSMUSG00000114613",
  "ENSMUSG00000114632",
  "ENSMUSG00000114639",
  "ENSMUSG00000114656",
  "ENSMUSG00000114665",
  "ENSMUSG00000114689",
  "ENSMUSG00000114702",
  "ENSMUSG00000114736",
  "ENSMUSG00000114753",
  "ENSMUSG00000114772",
  "ENSMUSG00000114780",
  "ENSMUSG00000114790",
  "ENSMUSG00000114792",
  "ENSMUSG00000114796",
  "ENSMUSG00000114821",
  "ENSMUSG00000114826",
  "ENSMUSG00000114828",
  "ENSMUSG00000114875",
  "ENSMUSG00000114879",
  "ENSMUSG00000114892",
  "ENSMUSG00000114898",
  "ENSMUSG00000114922",
  "ENSMUSG00000114926",
  "ENSMUSG00000114978",
  "ENSMUSG00000114980",
  "ENSMUSG00000114981",
  "ENSMUSG00000114994",
  "ENSMUSG00000115001",
  "ENSMUSG00000115002",
  "ENSMUSG00000115008",
  "ENSMUSG00000115009",
  "ENSMUSG00000115012",
  "ENSMUSG00000115019",
  "ENSMUSG00000115092",
  "ENSMUSG00000115099",
  "ENSMUSG00000115111",
  "ENSMUSG00000115123",
  "ENSMUSG00000115139",
  "ENSMUSG00000115142",
  "ENSMUSG00000115146",
  "ENSMUSG00000115149",
  "ENSMUSG00000115153",
  "ENSMUSG00000115172",
  "ENSMUSG00000115177",
  "ENSMUSG00000115180",
  "ENSMUSG00000115230",
  "ENSMUSG00000115240",
  "ENSMUSG00000115245",
  "ENSMUSG00000115263",
  "ENSMUSG00000115271",
  "ENSMUSG00000115304",
  "ENSMUSG00000115317",
  "ENSMUSG00000115320",
  "ENSMUSG00000115324",
  "ENSMUSG00000115333",
  "ENSMUSG00000115355",
  "ENSMUSG00000115395",
  "ENSMUSG00000115398",
  "ENSMUSG00000115422",
  "ENSMUSG00000115424",
  "ENSMUSG00000115426",
  "ENSMUSG00000115432",
  "ENSMUSG00000115439",
  "ENSMUSG00000115454",
  "ENSMUSG00000115480",
  "ENSMUSG00000115506",
  "ENSMUSG00000115518",
  "ENSMUSG00000115522",
  "ENSMUSG00000115526",
  "ENSMUSG00000115529",
  "ENSMUSG00000115540",
  "ENSMUSG00000115576",
  "ENSMUSG00000115596",
  "ENSMUSG00000115609",
  "ENSMUSG00000115625",
  "ENSMUSG00000115665",
  "ENSMUSG00000115667",
  "ENSMUSG00000115676",
  "ENSMUSG00000115681",
  "ENSMUSG00000115689",
  "ENSMUSG00000115699",
  "ENSMUSG00000115725",
  "ENSMUSG00000115727",
  "ENSMUSG00000115729",
  "ENSMUSG00000115767",
  "ENSMUSG00000115774",
  "ENSMUSG00000115778",
  "ENSMUSG00000115779",
  "ENSMUSG00000115796",
  "ENSMUSG00000115801",
  "ENSMUSG00000115810",
  "ENSMUSG00000115813",
  "ENSMUSG00000115821",
  "ENSMUSG00000115829",
  "ENSMUSG00000115834",
  "ENSMUSG00000115845",
  "ENSMUSG00000115858",
  "ENSMUSG00000115867",
  "ENSMUSG00000115936",
  "ENSMUSG00000115946",
  "ENSMUSG00000115970",
  "ENSMUSG00000115971",
  "ENSMUSG00000115992",
  "ENSMUSG00000116004",
  "ENSMUSG00000116056",
  "ENSMUSG00000116061",
  "ENSMUSG00000116096",
  "ENSMUSG00000116097",
  "ENSMUSG00000116116",
  "ENSMUSG00000116123",
  "ENSMUSG00000116173",
  "ENSMUSG00000116220",
  "ENSMUSG00000116245",
  "ENSMUSG00000116260",
  "ENSMUSG00000116287",
  "ENSMUSG00000116305",
  "ENSMUSG00000116311",
  "ENSMUSG00000116350",
  "ENSMUSG00000116360",
  "ENSMUSG00000116396",
  "ENSMUSG00000116469",
  "ENSMUSG00000116491",
  "ENSMUSG00000116498",
  "ENSMUSG00000116500",
  "ENSMUSG00000116505",
  "ENSMUSG00000116506",
  "ENSMUSG00000116520",
  "ENSMUSG00000116537",
  "ENSMUSG00000116556",
  "ENSMUSG00000116557",
  "ENSMUSG00000116597",
  "ENSMUSG00000116607",
  "ENSMUSG00000116644",
  "ENSMUSG00000116654",
  "ENSMUSG00000116656",
  "ENSMUSG00000116658",
  "ENSMUSG00000116660",
  "ENSMUSG00000116689",
  "ENSMUSG00000116695",
  "ENSMUSG00000116725",
  "ENSMUSG00000116751",
  "ENSMUSG00000116755",
  "ENSMUSG00000116786",
  "ENSMUSG00000116792",
  "ENSMUSG00000116799",
  "ENSMUSG00000116805",
  "ENSMUSG00000116813",
  "ENSMUSG00000116848",
  "ENSMUSG00000116852",
  "ENSMUSG00000116858",
  "ENSMUSG00000116877",
  "ENSMUSG00000116884",
  "ENSMUSG00000116920",
  "ENSMUSG00000116922",
  "ENSMUSG00000116924",
  "ENSMUSG00000116955",
  "ENSMUSG00000116973",
  "ENSMUSG00000116975",
  "ENSMUSG00000116976",
  "ENSMUSG00000116983",
  "ENSMUSG00000116993",
  "ENSMUSG00000117026",
  "ENSMUSG00000117035",
  "ENSMUSG00000117042",
  "ENSMUSG00000117076",
  "ENSMUSG00000117100",
  "ENSMUSG00000117106",
  "ENSMUSG00000117110",
  "ENSMUSG00000117171",
  "ENSMUSG00000117172",
  "ENSMUSG00000117199",
  "ENSMUSG00000117210",
  "ENSMUSG00000117220",
  "ENSMUSG00000117229",
  "ENSMUSG00000117232",
  "ENSMUSG00000117251",
  "ENSMUSG00000117264",
  "ENSMUSG00000117287",
  "ENSMUSG00000117289",
  "ENSMUSG00000117292",
  "ENSMUSG00000117314",
  "ENSMUSG00000117315",
  "ENSMUSG00000117322",
  "ENSMUSG00000117328",
  "ENSMUSG00000117341",
  "ENSMUSG00000117350",
  "ENSMUSG00000117361",
  "ENSMUSG00000117365",
  "ENSMUSG00000117371",
  "ENSMUSG00000117391",
  "ENSMUSG00000117410",
  "ENSMUSG00000117421",
  "ENSMUSG00000117422",
  "ENSMUSG00000117426",
  "ENSMUSG00000117427",
  "ENSMUSG00000117431",
  "ENSMUSG00000117442",
  "ENSMUSG00000117450",
  "ENSMUSG00000117473",
  "ENSMUSG00000117478",
  "ENSMUSG00000117505",
  "ENSMUSG00000117509",
  "ENSMUSG00000117524",
  "ENSMUSG00000117539",
  "ENSMUSG00000117564",
  "ENSMUSG00000117571",
  "ENSMUSG00000117602",
  "ENSMUSG00000117611",
  "ENSMUSG00000117613",
  "ENSMUSG00000117618",
  "ENSMUSG00000117633",
  "ENSMUSG00000117652",
  "ENSMUSG00000117661",
  "ENSMUSG00000117662",
  "ENSMUSG00000117664",
  "ENSMUSG00000117674",
  "ENSMUSG00000117692",
  "ENSMUSG00000117696",
  "ENSMUSG00000117698",
  "ENSMUSG00000117710",
  "ENSMUSG00000117718",
  "ENSMUSG00000117727",
  "ENSMUSG00000117731",
  "ENSMUSG00000117752",
  "ENSMUSG00000117763",
  "ENSMUSG00000117768",
  "ENSMUSG00000117780",
  "ENSMUSG00000117786",
  "ENSMUSG00000117792",
  "ENSMUSG00000117797",
  "ENSMUSG00000117800",
  "ENSMUSG00000117811",
  "ENSMUSG00000117824",
  "ENSMUSG00000117827",
  "ENSMUSG00000117861",
  "ENSMUSG00000117872",
  "ENSMUSG00000117879",
  "ENSMUSG00000117889",
  "ENSMUSG00000117892",
  "ENSMUSG00000117896",
  "ENSMUSG00000117899",
  "ENSMUSG00000117916",
  "ENSMUSG00000117959",
  "ENSMUSG00000117985",
  "ENSMUSG00000117993",
  "ENSMUSG00000117994",
  "ENSMUSG00000118001",
  "ENSMUSG00000118002",
  "ENSMUSG00000118004",
  "ENSMUSG00000118008",
  "ENSMUSG00000118016",
  "ENSMUSG00000118026",
  "ENSMUSG00000118027",
  "ENSMUSG00000118038",
  "ENSMUSG00000118060",
  "ENSMUSG00000118086",
  "ENSMUSG00000118094",
  "ENSMUSG00000118101",
  "ENSMUSG00000118103",
  "ENSMUSG00000118119",
  "ENSMUSG00000118141",
  "ENSMUSG00000118157",
  "ENSMUSG00000118202",
  "ENSMUSG00000118209",
  "ENSMUSG00000118225",
  "ENSMUSG00000118257",
  "ENSMUSG00000118259",
  "ENSMUSG00000118267",
  "ENSMUSG00000118292",
  "ENSMUSG00000118311",
  "ENSMUSG00000118314",
  "ENSMUSG00000118316",
  "ENSMUSG00000118317",
  "ENSMUSG00000118322",
  "ENSMUSG00000118333",
  "ENSMUSG00000118337",
  "ENSMUSG00000118339",
  "ENSMUSG00000118347",
  "ENSMUSG00000118358",
  "ENSMUSG00000118366",
  "ENSMUSG00000118376",
  "ENSMUSG00000118380",
  "ENSMUSG00000118384",
  "ENSMUSG00000118394",
  "ENSMUSG00000118444",
  "ENSMUSG00000118449"
];
