// Copyright 2019 Davide Corso

import React, { useEffect } from "react";
import "normalize.css";
import "./style.css";
import Expression from "./Expression/Expression";
import { useGene } from "../hooks/gene";
import Title from "./Title";
import Correlation from "./Correlation/Correlation";
import Footer from "./Footer/Footer";
import Network from "./Network/Network";
import EPAutocompleteSearch from "./EPAutocompleteSearch/EPAutocompleteSearch";
import { autocomplete_entries } from "./HPAutocompleteSearch/autocomplete_entries";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const Viewer = ({ match }) => {
  const data = useGene(match.params.geneId);
  const { trackPageView } = useMatomo();

  // Track page view
  useEffect(trackPageView, []);

  return (
    <div className="viewer">
      <EPAutocompleteSearch
        searchedEntry={match.params.geneId}
        options={autocomplete_entries}
      />
      <div className="container">
        <div />
        <Title
          flag={data.flag}
          probe={data.source_entry}
          geneName={data.source_gene_name}
          entryType={data.entry_type}
        />
        <Expression
          flag={data.flag}
          series={data.main_expression.series}
          height={400}
          width={1000}
          plotName={makeSimplePlotName(data.source_entry, data.entry_type)}
        />
        <Network
          entry={data.source_entry}
          flag={data.networkInfo.flag}
          networkData={data.networkInfo.networkData}
          networkChoices={data.networkInfo.networkChoices}
        />
        <h2 className="correlationTitle">
          Correlations {data.correlations.label.first}
        </h2>
        <Correlation
          flag={data.flag}
          source={data.entry_type}
          series={data.expression.first_corr.series}
          correlations={data.correlations.first}
          expToFetch={data.exp_to_fetch.first}
        />
        <h2 className="correlationTitle">
          Correlations {data.correlations.label.second}
        </h2>
        <Correlation
          flag={data.flag}
          source={data.entry_type}
          series={data.expression.second_corr.series}
          correlations={data.correlations.second}
          expToFetch={data.exp_to_fetch.second}
        />
        <Footer />
      </div>
    </div>
  );
};

export default Viewer;

const makeSimplePlotName = (entry, entry_type) => {
  if (entry_type === "miRNA") {
    return `${entry} compressed expression`;
  } else {
    return `${entry} normal expression`;
  }
};

export const filterMethod = (filter, row, column) => {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id])
        .toLowerCase()
        .includes(filter.value.toLowerCase())
    : true;
};
