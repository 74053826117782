import React from "react";
import Menu from "../Menu/Menu";
import css from "./Help.module.css";
import Footer from "../Footer/Footer";
import FAQBox from "./FAQBox";
import DownloadBox from "./DownloadBox";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const Help = () => {
  const { trackPageView } = useMatomo();

  // Track page view
  React.useEffect(trackPageView, []);

  return (
    <div className={css.containerHelp}>
      <Menu />

      <div className={css.contentHelp}>
        <h1 className={css.helpTitle}>Help</h1>
        <FAQBox />
        <DownloadBox />
      </div>
      <Footer />
    </div>
  );
};

export default Help;
