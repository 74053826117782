export const all_entries = [
  "0610005C13Rik",
  "0610006L08Rik",
  "0610009B22Rik",
  "0610009E02Rik",
  "0610009L18Rik",
  "0610010F05Rik",
  "0610012G03Rik",
  "0610025J13Rik",
  "0610030E20Rik",
  "0610033M10Rik",
  "0610039K10Rik",
  "0610040B10Rik",
  "0610040F04Rik",
  "0610040J01Rik",
  "0610043K17Rik",
  "0710001A04Rik",
  "1110002E22Rik",
  "1110002J07Rik",
  "1110002L01Rik",
  "1110004F10Rik",
  "1110008E08Rik",
  "1110008P14Rik",
  "1110012L19Rik",
  "1110015O18Rik",
  "1110017D15Rik",
  "1110018N20Rik",
  "1110019D14Rik",
  "1110020A21Rik",
  "1110025L11Rik",
  "1110028F11Rik",
  "1110028F18Rik",
  "1110032A03Rik",
  "1110032F04Rik",
  "1110035H17Rik",
  "1110038B12Rik",
  "1110038F14Rik",
  "1110046J04Rik",
  "1110051M20Rik",
  "1110057P08Rik",
  "1110059E24Rik",
  "1110059G10Rik",
  "1110065P20Rik",
  "1190001M18Rik",
  "1190005I06Rik",
  "1190028D05Rik",
  "1200007C13Rik",
  "1300002E11Rik",
  "1300017J02Rik",
  "1500002C15Rik",
  "1500002F19Rik",
  "1500004A13Rik",
  "1500009C09Rik",
  "1500009L16Rik",
  "1500011B03Rik",
  "1500012K07Rik",
  "1500015A07Rik",
  "1500015L24Rik",
  "1500026H17Rik",
  "1500032F14Rik",
  "1500035N22Rik",
  "1520401A03Rik",
  "1600002D24Rik",
  "1600010M07Rik",
  "1600012H06Rik",
  "1600012P17Rik",
  "1600014C10Rik",
  "1600014C23Rik",
  "1600020E01Rik",
  "1600022D10Rik",
  "1600025M17Rik",
  "1600027J07Rik",
  "1700001C19Rik",
  "1700001G01Rik",
  "1700001J03Rik",
  "1700001J04Rik",
  "1700001K19Rik",
  "1700001K23Rik",
  "1700001L05Rik",
  "1700001L19Rik",
  "1700001N15Rik",
  "1700001O22Rik",
  "1700001P01Rik",
  "1700003C15Rik",
  "1700003D09Rik",
  "1700003E16Rik",
  "1700003F12Rik",
  "1700003G18Rik",
  "1700003H04Rik",
  "1700003I16Rik",
  "1700003M07Rik",
  "1700003O11Rik",
  "1700003P14Rik",
  "1700006A11Rik",
  "1700006F04Rik",
  "1700006J14Rik",
  "1700007F19Rik",
  "1700007H22Rik",
  "1700007J10Rik",
  "1700007K13Rik",
  "1700008J07Rik",
  "1700008K24Rik",
  "1700008O03Rik",
  "1700008P02Rik",
  "1700009C05Rik",
  "1700009J07Rik",
  "1700009N14Rik",
  "1700010B08Rik",
  "1700010B13Rik",
  "1700010D01Rik",
  "1700010H22Rik",
  "1700010I14Rik",
  "1700010L04Rik",
  "1700011B04Rik",
  "1700011L22Rik",
  "1700011M02Rik",
  "1700012A03Rik",
  "1700012B07Rik",
  "1700012B09Rik",
  "1700012D14Rik",
  "1700012E03Rik",
  "1700012I11Rik",
  "1700012P22Rik",
  "1700013D24Rik",
  "1700013F07Rik",
  "1700013G24Rik",
  "1700013H16Rik",
  "1700013M08Rik",
  "1700014D04Rik",
  "1700015C15Rik",
  "1700015F17Rik",
  "1700016C15Rik",
  "1700016D06Rik",
  "1700016F12Rik",
  "1700016H13Rik",
  "1700016J18Rik",
  "1700016K19Rik",
  "1700016L21Rik",
  "1700016P04Rik",
  "1700017B05Rik",
  "1700017G19Rik",
  "1700017I07Rik",
  "1700017N19Rik",
  "1700018A04Rik",
  "1700018B08Rik",
  "1700018B24Rik",
  "1700018F24Rik",
  "1700018G05Rik",
  "1700019A02Rik",
  "1700019D03Rik",
  "1700019E08Rik",
  "1700019J19Rik",
  "1700019N19Rik",
  "1700019P21Rik",
  "1700020A23Rik",
  "1700020D05Rik",
  "1700020D12Rik",
  "1700020L24Rik",
  "1700020N01Rik",
  "1700020N18Rik",
  "1700021A07Rik",
  "1700021F02Rik",
  "1700021F07Rik",
  "1700021P04Rik",
  "1700022A22Rik",
  "1700022H16Rik",
  "1700022I11Rik",
  "1700022N22Rik",
  "1700023C21Rik",
  "1700023F02Rik",
  "1700023G09Rik",
  "1700024G13Rik",
  "1700025C18Rik",
  "1700025D23Rik",
  "1700025G04Rik",
  "1700025H01Rik",
  "1700025K04Rik",
  "1700025K24Rik",
  "1700025L06Rik",
  "1700025N23Rik",
  "1700025O08Rik",
  "1700026D11Rik",
  "1700026J12Rik",
  "1700027A07Rik",
  "1700027A15Rik",
  "1700027J07Rik",
  "1700028E10Rik",
  "1700028I16Rik",
  "1700028J19Rik",
  "1700028K03Rik",
  "1700028M03Rik",
  "1700028N14Rik",
  "1700028P14Rik",
  "1700029E06Rik",
  "1700029F12Rik",
  "1700029H14Rik",
  "1700029I15Rik",
  "1700029J07Rik",
  "1700029M03Rik",
  "1700029M20Rik",
  "1700029P11Rik",
  "1700030C10Rik",
  "1700030J22Rik",
  "1700030K09Rik",
  "1700030L20Rik",
  "1700030N03Rik",
  "1700031A10Rik",
  "1700031F05Rik",
  "1700031F10Rik",
  "1700031P21Rik",
  "1700034E13Rik",
  "1700034G24Rik",
  "1700034H15Rik",
  "1700034I23Rik",
  "1700034J05Rik",
  "1700034K08Rik",
  "1700034P13Rik",
  "1700036A12Rik",
  "1700037C18Rik",
  "1700037H04Rik",
  "1700039M10Rik",
  "1700040D17Rik",
  "1700040F17Rik",
  "1700041B01Rik",
  "1700041C23Rik",
  "1700041G16Rik",
  "1700041M19Rik",
  "1700042G07Rik",
  "1700042O10Rik",
  "1700045H11Rik",
  "1700046C09Rik",
  "1700047A11Rik",
  "1700047K16Rik",
  "1700047M11Rik",
  "1700048F04Rik",
  "1700048O14Rik",
  "1700048O20Rik",
  "1700049E22Rik",
  "1700051A21Rik",
  "1700052H01Rik",
  "1700052K11Rik",
  "1700054K19Rik",
  "1700054M17Rik",
  "1700055D18Rik",
  "1700056E22Rik",
  "1700057G04Rik",
  "1700057H15Rik",
  "1700060C20Rik",
  "1700061E17Rik",
  "1700061I17Rik",
  "1700062C10Rik",
  "1700063D05Rik",
  "1700063H04Rik",
  "1700064H15Rik",
  "1700065D16Rik",
  "1700065J11Rik",
  "1700066B19Rik",
  "1700066M21Rik",
  "1700066O22Rik",
  "1700067G17Rik",
  "1700067K01Rik",
  "1700067P10Rik",
  "1700069B07Rik",
  "1700069L16Rik",
  "1700069P05Rik",
  "1700074P13Rik",
  "1700080N15Rik",
  "1700080O16Rik",
  "1700081H04Rik",
  "1700083H02Rik",
  "1700084D21Rik",
  "1700084E18Rik",
  "1700084F23Rik",
  "1700084M14Rik",
  "1700085C21Rik",
  "1700086D15Rik",
  "1700086O06Rik",
  "1700086P04Rik",
  "1700087I21Rik",
  "1700088E04Rik",
  "1700092E19Rik",
  "1700092K14Rik",
  "1700092M07Rik",
  "1700093J21Rik",
  "1700093K21Rik",
  "1700094M24Rik",
  "1700095A21Rik",
  "1700095J03Rik",
  "1700096J18Rik",
  "1700096K18Rik",
  "1700097N02Rik",
  "1700099I09Rik",
  "1700100L14Rik",
  "1700101I11Rik",
  "1700101I19Rik",
  "1700102H20Rik",
  "1700102P08Rik",
  "1700104B16Rik",
  "1700104L18Rik",
  "1700108F19Rik",
  "1700108J01Rik",
  "1700109G14Rik",
  "1700109H08Rik",
  "1700110C19Rik",
  "1700111E14Rik",
  "1700111N16Rik",
  "1700112J05Rik",
  "1700112J16Rik",
  "1700113A16Rik",
  "1700113H08Rik",
  "1700116B05Rik",
  "1700119I11Rik",
  "1700120B22Rik",
  "1700120C14Rik",
  "1700120K04Rik",
  "1700120O09Rik",
  "1700121L16Rik",
  "1700121N20Rik",
  "1700122C19Rik",
  "1700122H20Rik",
  "1700122O11Rik",
  "1700123K08Rik",
  "1700123L14Rik",
  "1700123M08Rik",
  "1700123O12Rik",
  "1700123O20Rik",
  "1700124L16Rik",
  "1700125H20Rik",
  "1700126A01Rik",
  "1700128A07Rik",
  "1700128E19Rik",
  "1700129C05Rik",
  "1700129L04Rik",
  "1810006J02Rik",
  "1810007D17Rik",
  "1810008I18Rik",
  "1810009J06Rik",
  "1810010H24Rik",
  "1810010K12Rik",
  "1810013D15Rik",
  "1810013L24Rik",
  "1810019N24Rik",
  "1810020O05Rik",
  "1810024B03Rik",
  "1810026B05Rik",
  "1810030O07Rik",
  "1810034E14Rik",
  "1810037I17Rik",
  "1810046K07Rik",
  "1810053B23Rik",
  "1810055G02Rik",
  "1810058I24Rik",
  "1810058N15Rik",
  "1810059H22Rik",
  "1810062G17Rik",
  "1810062O18Rik",
  "1810064F22Rik",
  "1810065E05Rik",
  "1810073O08Rik",
  "2010001K21Rik",
  "2010003K11Rik",
  "2010007H06Rik",
  "2010013B24Rik",
  "2010016I18Rik",
  "2010106E10Rik",
  "2010109A12Rik",
  "2010110K18Rik",
  "2010204K13Rik",
  "2010300C02Rik",
  "2010308F09Rik",
  "2010310C07Rik",
  "2010315B03Rik",
  "2200002D01Rik",
  "2200002J24Rik",
  "2210010C04Rik",
  "2210011K15Rik",
  "2210016F16Rik",
  "2210016L21Rik",
  "2210017I01Rik",
  "2210039B01Rik",
  "2210404E10Rik",
  "2210406O10Rik",
  "2210407C18Rik",
  "2210408F21Rik",
  "2210408I21Rik",
  "2210409D07Rik",
  "2210417A02Rik",
  "2300002M23Rik",
  "2300003K06Rik",
  "2300009A05Rik",
  "2310001H17Rik",
  "2310001K24Rik",
  "2310002D06Rik",
  "2310002L09Rik",
  "2310003L06Rik",
  "2310005A03Rik",
  "2310005E17Rik",
  "2310008N11Rik",
  "2310009B15Rik",
  "2310010J17Rik",
  "2310011J03Rik",
  "2310014F06Rik",
  "2310015A16Rik",
  "2310015D24Rik",
  "2310015K22Rik",
  "2310016D03Rik",
  "2310016D23Rik",
  "2310016G11Rik",
  "2310022A10Rik",
  "2310022B05Rik",
  "2310026I22Rik",
  "2310026L22Rik",
  "2310030G06Rik",
  "2310033P09Rik",
  "2310034C09Rik",
  "2310034G01Rik",
  "2310039H08Rik",
  "2310040G07Rik",
  "2310040G24Rik",
  "2310043M15Rik",
  "2310046K23Rik",
  "2310047D07Rik",
  "2310050C09Rik",
  "2310057J18Rik",
  "2310057M21Rik",
  "2310057N15Rik",
  "2310061I04Rik",
  "2310061N02Rik",
  "2310065F04Rik",
  "2310067P03Rik",
  "2310068J16Rik",
  "2310075C17Rik",
  "2310079G19Rik",
  "2400006E01Rik",
  "2410002F23Rik",
  "2410003L11Rik",
  "2410004B18Rik",
  "2410004I01Rik",
  "2410004P03Rik",
  "2410006H16Rik",
  "2410012E07Rik",
  "2410017I17Rik",
  "2410022M11Rik",
  "2410080I02Rik",
  "2410124H12Rik",
  "2410131K14Rik",
  "2410137M14Rik",
  "2410141K09Rik",
  "2500002B13Rik",
  "2500004C02Rik",
  "2510002D24Rik",
  "2510009E07Rik",
  "2510039O18Rik",
  "2510046G10Rik",
  "2600006K01Rik",
  "2610001J05Rik",
  "2610002M06Rik",
  "2610008E11Rik",
  "2610020C07Rik",
  "2610021A01Rik",
  "2610027F03Rik",
  "2610027K06Rik",
  "2610028E06Rik",
  "2610028H24Rik",
  "2610035D17Rik",
  "2610035F20Rik",
  "2610037D02Rik",
  "2610042L04Rik",
  "2610044O15Rik8",
  "2610203C22Rik",
  "2610206C17Rik",
  "2610301B20Rik",
  "2610303G11Rik",
  "2610306M01Rik",
  "2610307P16Rik",
  "2610316D01Rik",
  "2610318N02Rik",
  "2610507B11Rik",
  "2610528A11Rik",
  "2610528J11Rik",
  "2700046A07Rik",
  "2700046G09Rik",
  "2700049A03Rik",
  "2700054A10Rik",
  "2700062C07Rik",
  "2700068H02Rik",
  "2700081O15Rik",
  "2700089I24Rik",
  "2700097O09Rik",
  "2810001G20Rik",
  "2810004N23Rik",
  "2810006K23Rik",
  "2810013P06Rik",
  "2810021J22Rik",
  "2810030D12Rik",
  "2810032G03Rik",
  "2810402E24Rik",
  "2810403D21Rik",
  "2810403G07Rik",
  "2810404F17Rik",
  "2810404M03Rik",
  "2810405F17Rik",
  "2810407A14Rik",
  "2810408A11Rik",
  "2810408I11Rik",
  "2810410L24Rik",
  "2810429I04Rik",
  "2810455O05Rik",
  "2810459M11Rik",
  "2810471M01Rik",
  "2900005J15Rik",
  "2900026A02Rik",
  "2900040C04Rik",
  "2900041M22Rik",
  "2900052L18Rik",
  "2900052N01Rik",
  "2900057B20Rik",
  "2900076A07Rik",
  "2900079G21Rik",
  "2900092C05Rik",
  "2900097C17Rik",
  "3010001F23Rik",
  "3010003L21Rik",
  "3110001I22Rik",
  "3110009E18Rik",
  "3110009F21Rik",
  "3110018I06Rik",
  "3110039M20Rik",
  "3110040N11Rik",
  "3110070M22Rik",
  "3110082I17Rik",
  "3110099E03Rik",
  "3222401L13Rik",
  "3300002A11Rik",
  "3300002I08Rik",
  "3300002P13Rik",
  "3300005D01Rik",
  "3425401B19Rik",
  "3632454L22Rik",
  "3830406C13Rik",
  "3830408C21Rik",
  "3830417A13Rik",
  "3930402G23Rik",
  "4430402I18Rik",
  "4631405J19Rik",
  "4632404H12Rik",
  "4632411P08Rik",
  "4632427E13Rik",
  "4632428C04Rik",
  "4731419I09Rik",
  "4732414G09Rik",
  "4732419C18Rik",
  "4732440D04Rik",
  "4732465J04Rik",
  "4732471J01Rik",
  "4732491K20Rik",
  "4831440D22Rik",
  "4833405L11Rik",
  "4833407H14Rik",
  "4833411C07Rik",
  "4833413E03Rik",
  "4833415N18Rik",
  "4833418N02Rik",
  "4833419O12Rik",
  "4833420G17Rik",
  "4833422C13Rik",
  "4833422M21Rik",
  "4833427F10Rik",
  "4833427G06Rik",
  "4833428L15Rik",
  "4833439L19Rik",
  "4833445I07Rik",
  "4921504A21Rik",
  "4921504E06Rik",
  "4921507G05Rik",
  "4921507P07Rik",
  "4921508D12Rik",
  "4921509C19Rik",
  "4921509O07Rik",
  "4921511C20Rik",
  "4921511I17Rik",
  "4921513I03Rik",
  "4921515E04Rik",
  "4921515L22Rik",
  "4921517D16Rik",
  "4921517D22Rik",
  "4921518K17Rik",
  "4921521D15Rik",
  "4921522P10Rik",
  "4921524J17Rik",
  "4921524L21Rik",
  "4921525O09Rik",
  "4921528I07Rik",
  "4921529L05Rik",
  "4921531C22Rik",
  "4921531P14Rik",
  "4921534H16Rik",
  "4921536K21Rik",
  "4921539E11Rik",
  "4921539H07Rik",
  "4930401C15Rik",
  "4930402F06Rik",
  "4930402F11Rik",
  "4930402H24Rik",
  "4930402K13Rik",
  "4930404H24Rik",
  "4930404N11Rik",
  "4930405A10Rik",
  "4930405D01Rik",
  "4930405H06Rik",
  "4930407I10Rik",
  "4930407I19Rik",
  "4930408O17Rik",
  "4930412C18Rik",
  "4930412F09Rik",
  "4930412L05Rik",
  "4930412M03Rik",
  "4930412O13Rik",
  "4930413E15Rik",
  "4930413F20Rik",
  "4930414F18Rik",
  "4930415L06Rik",
  "4930415O20Rik",
  "4930417H01Rik",
  "4930417O13Rik",
  "4930417O22Rik",
  "4930422C21Rik",
  "4930422I22Rik",
  "4930423D22Rik",
  "4930423M02Rik",
  "4930425O10Rik",
  "4930426D05Rik",
  "4930426I24Rik",
  "4930426L09Rik",
  "4930428N03Rik",
  "4930429D17Rik",
  "4930430F08Rik",
  "4930430O22Rik",
  "4930431F10Rik",
  "4930431F12Rik",
  "4930432B10Rik",
  "4930432J09Rik",
  "4930432K21Rik",
  "4930432L08Rik",
  "4930432M17Rik",
  "4930432O09Rik",
  "4930433I11Rik",
  "4930433N12Rik",
  "4930434B07Rik",
  "4930434J06Rik",
  "4930435F18Rik",
  "4930435M08Rik",
  "4930438A08Rik",
  "4930440C22Rik",
  "4930442G15Rik",
  "4930443O20Rik",
  "4930444A19Rik",
  "4930444F02Rik",
  "4930444P10Rik",
  "4930445B16Rik",
  "4930445E18Rik",
  "4930445N18Rik",
  "4930447A16Rik",
  "4930447C04Rik",
  "4930447F04Rik",
  "4930447F24Rik",
  "4930447J18Rik",
  "4930447N08Rik",
  "4930448A20Rik",
  "4930448E22Rik",
  "4930448F12Rik",
  "4930449C09Rik",
  "4930449E01Rik",
  "4930451E10Rik",
  "4930451I11Rik",
  "4930452A19Rik",
  "4930452B06Rik",
  "4930452G13Rik",
  "4930453H23Rik",
  "4930453L07Rik",
  "4930453N24Rik",
  "4930453O03Rik",
  "4930455C13Rik",
  "4930455H04Rik",
  "4930455J16Rik",
  "4930456L15Rik",
  "4930458A03Rik",
  "4930458D05Rik",
  "4930458K08Rik",
  "4930459C07Rik",
  "4930461C15Rik",
  "4930463O16Rik",
  "4930465M20Rik",
  "4930467D21Rik",
  "4930467J12Rik",
  "4930469B13Rik",
  "4930470G03Rik",
  "4930470H14Rik",
  "4930470P17Rik",
  "4930471D02Rik",
  "4930471E19Rik",
  "4930473A02Rik",
  "4930473D10Rik",
  "4930473H19Rik",
  "4930473O22Rik",
  "4930474H20Rik",
  "4930474M22Rik",
  "4930474N05Rik",
  "4930474N09Rik",
  "4930477E14Rik",
  "4930478E11Rik",
  "4930478K11Rik",
  "4930478M13Rik",
  "4930478P22Rik",
  "4930480E11Rik",
  "4930480G23Rik",
  "4930480K15Rik",
  "4930481A15Rik",
  "4930481B07Rik",
  "4930483J18Rik",
  "4930483K19Rik",
  "4930484H19Rik",
  "4930487D11Rik",
  "4930488B22Rik",
  "4930488N15Rik",
  "4930500A05Rik",
  "4930500F04Rik",
  "4930500H12Rik",
  "4930500M09Rik",
  "4930502A04Rik",
  "4930503B20Rik",
  "4930503E14Rik",
  "4930503L19Rik",
  "4930504D19Rik",
  "4930505A04Rik",
  "4930505M18Rik",
  "4930505N22Rik",
  "4930505O20Rik",
  "4930506C21Rik",
  "4930507D10Rik",
  "4930509E16Rik",
  "4930509J09Rik",
  "4930509K18Rik",
  "4930510E17Rik",
  "4930511A02Rik",
  "4930511E03Rik",
  "4930511J24Rik",
  "4930511M06Rik",
  "4930512B01Rik",
  "4930512H18Rik",
  "4930512J16Rik",
  "4930513L16Rik",
  "4930513N10Rik",
  "4930513O06Rik",
  "4930515G01Rik",
  "4930516K23Rik",
  "4930517E11Rik",
  "4930517E14Rik",
  "4930518C09Rik",
  "4930518P08Rik",
  "4930519A11Rik",
  "4930519D14Rik",
  "4930519F24Rik",
  "4930519G04Rik",
  "4930519H02Rik",
  "4930519L02Rik",
  "4930519P11Rik",
  "4930520O04Rik",
  "4930520P13Rik",
  "4930522H14Rik",
  "4930522L14Rik",
  "4930522N08Rik",
  "4930522O17Rik",
  "4930523C07Rik",
  "4930523O13Rik",
  "4930524B15Rik",
  "4930524B17Rik",
  "4930524C18Rik",
  "4930524O08Rik",
  "4930525G20Rik",
  "4930527F14Rik",
  "4930527J03Rik",
  "4930528D03Rik",
  "4930528G23Rik",
  "4930528H21Rik",
  "4930529I22Rik",
  "4930529K09Rik",
  "4930532I03Rik",
  "4930533B01Rik",
  "4930533K18Rik",
  "4930534H18Rik",
  "4930539C22Rik",
  "4930539J05Rik",
  "4930540M05Rik",
  "4930542C16Rik",
  "4930542D17Rik",
  "4930543E12Rik",
  "4930543N07Rik",
  "4930544D05Rik",
  "4930544G11Rik",
  "4930544I03Rik",
  "4930544M13Rik",
  "4930545H06Rik",
  "4930545L08Rik",
  "4930545L23Rik",
  "4930546C10Rik",
  "4930547H16Rik",
  "4930547M16Rik",
  "4930548G14Rik",
  "4930548H24Rik",
  "4930549C15Rik",
  "4930550C14Rik",
  "4930550C17Rik",
  "4930550L24Rik",
  "4930551O13Rik",
  "4930553J12Rik",
  "4930553M12Rik",
  "4930553P18Rik",
  "4930554C24Rik",
  "4930554G24Rik",
  "4930554H23Rik",
  "4930555B11Rik",
  "4930555F03Rik",
  "4930556J24Rik",
  "4930556L07Rik",
  "4930556M19Rik",
  "4930556N09Rik",
  "4930556N13Rik",
  "4930557J02Rik",
  "4930558C23Rik",
  "4930558J22Rik",
  "4930558K02Rik",
  "4930558N11Rik",
  "4930562A09Rik",
  "4930562C15Rik",
  "4930562D21Rik",
  "4930562F07Rik",
  "4930562F17Rik",
  "4930563E18Rik",
  "4930563E22Rik",
  "4930563F08Rik",
  "4930563J15Rik",
  "4930563M21Rik",
  "4930564D02Rik",
  "4930565D16Rik",
  "4930565N06Rik",
  "4930567H12Rik",
  "4930567H17Rik",
  "4930567K12Rik",
  "4930568D16Rik",
  "4930568E12Rik",
  "4930568G15Rik",
  "4930570E03Rik",
  "4930570G19Rik",
  "4930571K23Rik",
  "4930572G02Rik",
  "4930577H14Rik",
  "4930578E11Rik",
  "4930578G10Rik",
  "4930578I06Rik",
  "4930578M01Rik",
  "4930578N18Rik",
  "4930579C12Rik",
  "4930579D07Rik",
  "4930579G18Rik",
  "4930579G24Rik",
  "4930579H20Rik",
  "4930579K19Rik",
  "4930579P08Rik",
  "4930580E04Rik",
  "4930581F22Rik",
  "4930583I09Rik",
  "4930583K01Rik",
  "4930584F24Rik",
  "4930588A03Rik",
  "4930588G17Rik",
  "4930588J15Rik",
  "4930588K23Rik",
  "4930589L23Rik",
  "4930590J08Rik",
  "4930590L14Rik",
  "4930591A17Rik",
  "4930592A05Rik",
  "4930592I03Rik",
  "4930593A02Rik",
  "4930594M17Rik",
  "4930594M22Rik",
  "4930595D18Rik",
  "4930595M18Rik",
  "4930596D02Rik",
  "4930597G03Rik",
  "4930597O21Rik",
  "4931400O07Rik",
  "4931406B18Rik",
  "4931406C07Rik",
  "4931406P16Rik",
  "4931407E12Rik",
  "4931408C20Rik",
  "4931413K12Rik",
  "4931414P19Rik",
  "4931415C17Rik",
  "4931417E11Rik",
  "4931419H13Rik",
  "4931422A03Rik",
  "4931428F04Rik",
  "4931429L15Rik",
  "4931429P17Rik",
  "4931431B13Rik",
  "4931431C16Rik",
  "4931439C15Rik",
  "4932411K12Rik",
  "4932411N23Rik",
  "4932412D23Rik",
  "4932413F04Rik",
  "4932414J04Rik",
  "4932414N04Rik",
  "4932415D10Rik",
  "4932416K20Rik",
  "4932422M17Rik",
  "4932429P05Rik",
  "4932435O22Rik",
  "4932438A13Rik",
  "4932438H23Rik",
  "4932441J04Rik",
  "4932443L11Rik",
  "4933400A11Rik",
  "4933400C23Rik",
  "4933400F21Rik",
  "4933401D09Rik",
  "4933401H06Rik",
  "4933401L05Rik",
  "4933402C06Rik",
  "4933402D24Rik",
  "4933402E13Rik",
  "4933402J07Rik",
  "4933402J15Rik",
  "4933402N03Rik",
  "4933403O08Rik",
  "4933404G15Rik",
  "4933404I11Rik",
  "4933404K08Rik",
  "4933404K13Rik",
  "4933404O12Rik",
  "4933405D12Rik",
  "4933405E24Rik",
  "4933405L10Rik",
  "4933405O20Rik",
  "4933406B15Rik",
  "4933406B17Rik",
  "4933406C10Rik",
  "4933406G16Rik",
  "4933406I18Rik",
  "4933406K04Rik",
  "4933406P04Rik",
  "4933407I05Rik",
  "4933407K13Rik",
  "4933407L21Rik",
  "4933408J17Rik",
  "4933408N05Rik",
  "4933409D19Rik",
  "4933409F18Rik",
  "4933409G03Rik",
  "4933411E02Rik",
  "4933411G06Rik",
  "4933411K16Rik",
  "4933411O13Rik",
  "4933412E12Rik",
  "4933412E24Rik",
  "4933412O06Rik",
  "4933413C19Rik",
  "4933413J09Rik",
  "4933414I15Rik",
  "4933415A04Rik",
  "4933416E03Rik",
  "4933416O17Rik",
  "4933417A18Rik",
  "4933417E11Rik",
  "4933417O13Rik",
  "4933421D24Rik",
  "4933421I07Rik",
  "4933421O10Rik",
  "4933422A05Rik",
  "4933423P22Rik",
  "4933424G05Rik",
  "4933424G06Rik",
  "4933424M12Rik",
  "4933425B07Rik",
  "4933425D22Rik",
  "4933426B08Rik",
  "4933426K07Rik",
  "4933427D14Rik",
  "4933427E11Rik",
  "4933427E13Rik",
  "4933427I22Rik",
  "4933427J07Rik",
  "4933428G20Rik",
  "4933428M09Rik",
  "4933429O19Rik",
  "4933430A20Rik",
  "4933430H16Rik",
  "4933430I17Rik",
  "4933430L12Rik",
  "4933430N04Rik",
  "4933431J24Rik",
  "4933431K23Rik",
  "4933431M02Rik",
  "4933432I03Rik",
  "4933432I09Rik",
  "4933432K03Rik",
  "4933433F19Rik",
  "4933433G08Rik",
  "4933433G15Rik",
  "4933433G19Rik",
  "4933433H22Rik",
  "4933434E20Rik",
  "4933434M16Rik",
  "4933434P08Rik",
  "4933435F18Rik",
  "4933435G04Rik",
  "4933436E23Rik",
  "4933436H12Rik",
  "4933436I01Rik",
  "4933436I20Rik",
  "4933438B17Rik",
  "4933438K21Rik",
  "4933439C10Rik",
  "4933439N14Rik",
  "4933440J02Rik",
  "4933440M02Rik",
  "5031425E22Rik",
  "5031434C07Rik",
  "5031434O11Rik",
  "5031439G07Rik",
  "5033403F01Rik",
  "5033406O09Rik",
  "5033417F24Rik",
  "5033421B08Rik",
  "5033428I22Rik",
  "5033430I15Rik",
  "5330411J11Rik",
  "5330411O13Rik",
  "5330416C01Rik",
  "5330417C22Rik",
  "5330426L24Rik",
  "5330434G04Rik",
  "5330438D12Rik",
  "5330439A09Rik",
  "5330439B14Rik",
  "5330439K02Rik",
  "5430400D12Rik",
  "5430401H09Rik",
  "5430402P08Rik",
  "5430403G16Rik",
  "5430405H02Rik",
  "5430416N02Rik",
  "5430425K12Rik",
  "5430427M07Rik",
  "5430427O19Rik",
  "5430431A17Rik",
  "5430434I15Rik",
  "5530401A14Rik",
  "5530601H04Rik",
  "5730403I07Rik",
  "5730405O15Rik",
  "5730409E04Rik",
  "5730412P04Rik",
  "5730414N17Rik",
  "5730419F03Rik",
  "5730420D15Rik",
  "5730455P16Rik",
  "5730460C07Rik",
  "5730480H06Rik",
  "5730507C01Rik",
  "5730522E02Rik",
  "5830408C22Rik",
  "5830411N06Rik",
  "5830416I19Rik",
  "5830428M24Rik",
  "5830432E09Rik",
  "5830444B04Rik",
  "5830454E08Rik",
  "5830468F06Rik",
  "5830487J09Rik",
  "5930403N24Rik",
  "5930430L01Rik",
  "5930438M14Rik",
  "6030407O03Rik",
  "6030440G07Rik",
  "6030443J06Rik",
  "6030445D17Rik",
  "6030452D12Rik",
  "6030468B19Rik",
  "6030469F06Rik",
  "6030498E09Rik",
  "6230400D17Rik",
  "6330403K07Rik",
  "6330403L08Rik",
  "6330409D20Rik",
  "6330411D24Rik",
  "6330415G19Rik",
  "6330418K02Rik",
  "6330562C20Rik",
  "6330576A10Rik",
  "6430503K07Rik",
  "6430548M08Rik",
  "6430550D23Rik",
  "6430553K19Rik",
  "6430562O15Rik",
  "6430571L13Rik",
  "6430573P05Rik",
  "6430584L05Rik",
  "6430590A07Rik",
  "6430628N08Rik",
  "6430710C18Rik",
  "6530401F13Rik",
  "6530402F18Rik",
  "6530403H02Rik",
  "6530409C15Rik",
  "6530411M01Rik",
  "6720427I07Rik",
  "6720483E21Rik",
  "6720489N17Rik",
  "6820408C15Rik",
  "6820426E19Rik",
  "7420426K07Rik",
  "7420700N18Rik",
  "7630403G23Rik",
  "8030423F21Rik",
  "8030423J24Rik",
  "8030451A03Rik",
  "8030453O22Rik",
  "8030456M14Rik",
  "8030462N17Rik",
  "8030474K03Rik",
  "8430419K02Rik",
  "8430422H06Rik",
  "8430423G03Rik",
  "8430426J06Rik",
  "8430429K09Rik",
  "8430430B14Rik",
  "8430432A02Rik",
  "8430436N08Rik",
  "8430437L04Rik",
  "9030404E10Rik",
  "9030407P20Rik",
  "9030612E09Rik",
  "9030622O22Rik",
  "9030625G05Rik",
  "9130008F23Rik",
  "9130015A21Rik",
  "9130015G15Rik",
  "9130016M20Rik",
  "9130017K11Rik",
  "9130019O22Rik",
  "9130019P16Rik",
  "9130023H24Rik",
  "9130024F11Rik",
  "9130209A04Rik",
  "9130213A22Rik",
  "9130227L01Rik",
  "9130230L23Rik",
  "9130230N09Rik",
  "9130401M01Rik",
  "9130409I23Rik",
  "9130410C08Rik",
  "9130604C24Rik",
  "9230009I02Rik",
  "9230019H11Rik",
  "9230020A06Rik",
  "9230102K24Rik",
  "9230102O04Rik",
  "9230109A22Rik",
  "9230111E07Rik",
  "9230112D13Rik",
  "9230112J17Rik",
  "9230114J08Rik",
  "9230114K14Rik",
  "9230116L04Rik",
  "9230116N13Rik",
  "9330102E08Rik",
  "9330118I20Rik",
  "9330151L19Rik",
  "9330154J02Rik",
  "9330158H04Rik",
  "9330159F19Rik",
  "9330159M07Rik",
  "9330160F10Rik",
  "9330162012Rik",
  "9330162B11Rik",
  "9330171B17Rik",
  "9330175E14Rik",
  "9330175M20Rik",
  "9330178D15Rik",
  "9330179D12Rik",
  "9330182L06Rik",
  "9330185C12Rik",
  "9330188P03Rik",
  "9330198I05Rik",
  "9330198N18Rik",
  "9330199G10Rik",
  "9430002A10Rik",
  "9430014N10Rik",
  "9430015G10Rik",
  "9430018G01Rik",
  "9430021M05Rik",
  "9430024E24Rik",
  "9430037G07Rik",
  "9430038I01Rik",
  "9430041J12Rik",
  "9430060I03Rik",
  "9430065F17Rik",
  "9430069I07Rik",
  "9430078K24Rik",
  "9430097D07Rik",
  "9530003J23Rik",
  "9530020I12Rik",
  "9530026P05Rik",
  "9530034A14Rik",
  "9530036M11Rik",
  "9530036O11Rik",
  "9530046B11Rik",
  "9530051G07Rik",
  "9530053A07Rik",
  "9530059O14Rik",
  "9530062K07Rik",
  "9530068E07Rik",
  "9530077C05Rik",
  "9530080O11Rik",
  "9530082P21Rik",
  "9530097N15Rik",
  "9630001P10Rik",
  "9630009A06Rik",
  "9630013A20Rik",
  "9630013D21Rik",
  "9630013K17Rik",
  "9630014M24Rik",
  "9630028B13Rik",
  "9630028H03Rik",
  "9630028I04Rik",
  "9630041A04Rik",
  "9830107B12Rik",
  "9830166K06Rik",
  "9930012K11Rik",
  "9930014A18Rik",
  "9930021J03Rik",
  "9930022D16Rik",
  "9930038B18Rik",
  "9930104L06Rik",
  "A030001D20Rik",
  "A030003K21Rik",
  "A030005K14Rik",
  "A030005L19Rik",
  "A030014E15Rik",
  "A130010J15Rik",
  "A130014A01Rik",
  "A130023I24Rik",
  "A130030D18Rik",
  "A130051J06Rik",
  "A1bg",
  "A230001M10Rik",
  "A230004M16Rik",
  "A230009B12Rik",
  "A230028O05Rik",
  "A230051N06Rik",
  "A230057D06Rik",
  "A230059L01Rik",
  "A230065N10Rik",
  "A230072C01Rik",
  "A230072E10Rik",
  "A230072I06Rik",
  "A230083G16Rik",
  "A230098N10Rik",
  "A230103O09Rik",
  "A230107N01Rik",
  "A26c2",
  "A26c3",
  "A2m",
  "A2ml1",
  "A330008L17Rik",
  "A330009N23Rik",
  "A330015K06Rik",
  "A330023F24Rik",
  "A330032B11Rik",
  "A330033J07Rik",
  "A330041J22Rik",
  "A330043C09Rik",
  "A330049N07Rik",
  "A330069K06Rik",
  "A330070K13Rik",
  "A330072L02Rik",
  "A330074K22Rik",
  "A330076H08Rik",
  "A330084C13Rik",
  "A330093E20Rik",
  "A330094K24Rik",
  "A330102I10Rik",
  "A3galt2",
  "A430005L14Rik",
  "A430027H14Rik",
  "A430033K04Rik",
  "A430034D21Rik",
  "A430035B10Rik",
  "A430048G15Rik",
  "A430088P11Rik",
  "A430090L17Rik",
  "A430093F15Rik",
  "A430105J06Rik",
  "A430110L20Rik",
  "A4gnt",
  "A530001N23Rik",
  "A530006G24Rik",
  "A530010F05Rik",
  "A530010L16Rik",
  "A530013C23Rik",
  "A530016L24Rik",
  "A530017D24Rik",
  "A530020G20Rik",
  "A530021J07Rik",
  "A530032D15Rik",
  "A530041M06Rik",
  "A530053G22Rik",
  "A530058N18Rik",
  "A530064D06Rik",
  "A530065N20Rik",
  "A530072M11Rik",
  "A530076I17Rik",
  "A530083I20Rik",
  "A530088E08Rik",
  "A530095I07Rik",
  "A630001G21Rik",
  "A630010A05Rik",
  "A630012P03Rik",
  "A630014C17Rik",
  "A630023A22Rik",
  "A630023P12Rik",
  "A630052C17Rik",
  "A630072L19Rik",
  "A630072M18Rik",
  "A630073D07Rik",
  "A630076J17Rik",
  "A630089N07Rik",
  "A630091E08Rik",
  "A630095N17Rik",
  "A730009L09Rik",
  "A730011C13Rik",
  "A730017L22Rik",
  "A730018C14Rik",
  "A730020E08Rik",
  "A730020M07Rik",
  "A730032A03Rik",
  "A730036I17Rik",
  "A730045E13Rik",
  "A730046J19Rik",
  "A730049H05Rik",
  "A730056A06Rik",
  "A730061H03Rik",
  "A730063M14Rik",
  "A730081D07Rik",
  "A730082K24Rik",
  "A730085E03Rik",
  "A830005F24Rik",
  "A830009L08Rik",
  "A830012C17Rik",
  "A830018L16Rik",
  "A830019P07Rik",
  "A830029E22Rik",
  "A830031A19Rik",
  "A830035O19Rik",
  "A830052D11Rik",
  "A830082K12Rik",
  "A830082N09Rik",
  "A830092H15Rik",
  "A930001A20Rik",
  "A930002H24Rik",
  "A930002I21Rik",
  "A930003A15Rik",
  "A930004D18Rik",
  "A930005H10Rik",
  "A930006I01Rik",
  "A930007I19Rik",
  "A930009A15Rik",
  "A930012O16Rik",
  "A930015D03Rik",
  "A930016O22Rik",
  "A930017K11Rik",
  "A930018P22Rik",
  "A930019D19Rik",
  "A930023M06Rik",
  "A930024E05Rik",
  "A930028N01Rik",
  "A930029G22Rik",
  "A930033H14Rik",
  "A930038B10Rik",
  "A930104D05Rik",
  "AA387883",
  "AA465934",
  "AA467197",
  "AA545190",
  "AA792892",
  "AA986860",
  "AAdacl4fm3",
  "AB041806",
  "AB124611",
  "AC106834.1",
  "AC142100.1",
  "AC160336.1",
  "AC160966.1",
  "AC241534.1",
  "AF366264",
  "AI182371",
  "AI197445",
  "AI413582",
  "AI427809",
  "AI429214",
  "AI463229",
  "AI467606",
  "AI480526",
  "AI504432",
  "AI506816",
  "AI507597",
  "AI593442",
  "AI597479",
  "AI646519",
  "AI661453",
  "AI837181",
  "AI838599",
  "AI847159",
  "AI849053",
  "AI854703",
  "AI987944",
  "AU015791",
  "AU015836",
  "AU016765",
  "AU017674",
  "AU018091",
  "AU019990",
  "AU021092",
  "AU022252",
  "AU022751",
  "AU022793",
  "AU023762",
  "AU040320",
  "AU040972",
  "AU041133",
  "AV099323",
  "AV205837",
  "AV356131",
  "AW011738",
  "AW046200",
  "AW112010",
  "AW146154",
  "AW209491",
  "AW549877",
  "AW551984",
  "AW554918",
  "AY074887",
  "AY358078",
  "AY512915",
  "AY512931",
  "AY702103",
  "AY761185",
  "Aaas",
  "Aacs",
  "Aadac",
  "Aadacl2",
  "Aadacl2fm1",
  "Aadacl2fm2",
  "Aadacl2fm3",
  "Aadacl3",
  "Aadacl4",
  "Aadacl4fm1",
  "Aadacl4fm2",
  "Aadacl4fm4",
  "Aadacl4fm5",
  "Aadat",
  "Aagab",
  "Aak1",
  "Aamdc",
  "Aamp",
  "Aanat",
  "Aar2",
  "Aard",
  "Aars",
  "Aars2",
  "Aasdh",
  "Aasdhppt",
  "Aass",
  "Aatf",
  "Abat",
  "Abca1",
  "Abca12",
  "Abca13",
  "Abca14",
  "Abca15",
  "Abca16",
  "Abca17",
  "Abca2",
  "Abca3",
  "Abca4",
  "Abca5",
  "Abca6",
  "Abca7",
  "Abca8a",
  "Abca8b",
  "Abca9",
  "Abcb10",
  "Abcb11",
  "Abcb1a",
  "Abcb1b",
  "Abcb4",
  "Abcb5",
  "Abcb7",
  "Abcb8",
  "Abcb9",
  "Abcc1",
  "Abcc10",
  "Abcc12",
  "Abcc2",
  "Abcc3",
  "Abcc4",
  "Abcc5",
  "Abcc6",
  "Abcc8",
  "Abcc9",
  "Abcd1",
  "Abcd3",
  "Abcd4",
  "Abce1",
  "Abcf1",
  "Abcf2",
  "Abcf3",
  "Abcg1",
  "Abcg2",
  "Abcg3",
  "Abcg4",
  "Abcg5",
  "Abcg8",
  "Abhd10",
  "Abhd11",
  "Abhd12",
  "Abhd12b",
  "Abhd13",
  "Abhd14a",
  "Abhd14b",
  "Abhd15",
  "Abhd16a",
  "Abhd16b",
  "Abhd17a",
  "Abhd17b",
  "Abhd17c",
  "Abhd18",
  "Abhd2",
  "Abhd3",
  "Abhd4",
  "Abhd5",
  "Abhd6",
  "Abhd8",
  "Abi1",
  "Abi2",
  "Abi3",
  "Abi3bp",
  "Abl1",
  "Abl2",
  "Ablim1",
  "Ablim2",
  "Ablim3",
  "Abo",
  "Abr",
  "Abra",
  "Abracl",
  "Abraxas1",
  "Abraxas2",
  "Abt1",
  "Abtb1",
  "Abtb2",
  "Acaa1a",
  "Acaa1b",
  "Acaa2",
  "Acaca",
  "Acacb",
  "Acad10",
  "Acad11",
  "Acad12",
  "Acad8",
  "Acadl",
  "Acadm",
  "Acads",
  "Acadsb",
  "Acadvl",
  "Acan",
  "Acap1",
  "Acap2",
  "Acap3",
  "Acat1",
  "Acat2",
  "Acat3",
  "Acbd3",
  "Acbd4",
  "Acbd5",
  "Acbd6",
  "Acbd7",
  "Accs",
  "Accsl",
  "Acd",
  "Ace",
  "Ace2",
  "Ace3",
  "Acer1",
  "Acer2",
  "Acer3",
  "Ache",
  "Acin1",
  "Ackr1",
  "Ackr2",
  "Ackr3",
  "Ackr4",
  "Acly",
  "Acmsd",
  "Acnat1",
  "Acnat2",
  "Aco2",
  "Acod1",
  "Acot1",
  "Acot10",
  "Acot11",
  "Acot12",
  "Acot13",
  "Acot2",
  "Acot4",
  "Acot5",
  "Acot6",
  "Acot7",
  "Acot8",
  "Acot9",
  "Acox1",
  "Acox2",
  "Acox3",
  "Acoxl",
  "Acp1",
  "Acp2",
  "Acp4",
  "Acp5",
  "Acp6",
  "Acp7",
  "Acpp",
  "Acr",
  "Acrbp",
  "Acrv1",
  "Acsbg1",
  "Acsbg2",
  "Acsbg3",
  "Acsf2",
  "Acsf3",
  "Acsl1",
  "Acsl3",
  "Acsl4",
  "Acsl5",
  "Acsl6",
  "Acsm1",
  "Acsm2",
  "Acsm3",
  "Acsm4",
  "Acsm5",
  "Acss1",
  "Acss2",
  "Acss3",
  "Acta1",
  "Acta2",
  "Actb",
  "Actbl2",
  "Actc1",
  "Acte1",
  "Actg2",
  "Actl10",
  "Actl11",
  "Actl6a",
  "Actl6b",
  "Actl7a",
  "Actl7b",
  "Actl9",
  "Actn1",
  "Actn2",
  "Actn3",
  "Actn4",
  "Actr10",
  "Actr1a",
  "Actr1b",
  "Actr2",
  "Actr3b",
  "Actr5",
  "Actr6",
  "Actr8",
  "Actrt1",
  "Actrt2",
  "Actrt3",
  "Acvr1",
  "Acvr1b",
  "Acvr1c",
  "Acvr2a",
  "Acvr2b",
  "Acvrl1",
  "Acy1",
  "Acy3",
  "Acyp1",
  "Acyp2",
  "Ada",
  "Adad1",
  "Adad2",
  "Adal",
  "Adam10",
  "Adam11",
  "Adam12",
  "Adam15",
  "Adam17",
  "Adam18",
  "Adam19",
  "Adam1a",
  "Adam1b",
  "Adam2",
  "Adam20",
  "Adam21",
  "Adam22",
  "Adam23",
  "Adam24",
  "Adam25",
  "Adam26a",
  "Adam26b",
  "Adam28",
  "Adam29",
  "Adam3",
  "Adam30",
  "Adam32",
  "Adam33",
  "Adam34",
  "Adam34l",
  "Adam39",
  "Adam4",
  "Adam5",
  "Adam6a",
  "Adam6b",
  "Adam7",
  "Adam8",
  "Adam9",
  "Adamdec1",
  "Adamts1",
  "Adamts10",
  "Adamts12",
  "Adamts13",
  "Adamts14",
  "Adamts15",
  "Adamts16",
  "Adamts17",
  "Adamts18",
  "Adamts19",
  "Adamts2",
  "Adamts20",
  "Adamts3",
  "Adamts4",
  "Adamts5",
  "Adamts6",
  "Adamts7",
  "Adamts8",
  "Adamts9",
  "Adamtsl1",
  "Adamtsl2",
  "Adamtsl3",
  "Adamtsl4",
  "Adamtsl5",
  "Adap1",
  "Adap2",
  "Adar",
  "Adarb1",
  "Adarb2",
  "Adat1",
  "Adat2",
  "Adck1",
  "Adck5",
  "Adcy10",
  "Adcy2",
  "Adcy3",
  "Adcy4",
  "Adcy5",
  "Adcy6",
  "Adcy7",
  "Adcy8",
  "Adcy9",
  "Adcyap1",
  "Adcyap1r1",
  "Add1",
  "Add2",
  "Add3",
  "Adgb",
  "Adgra1",
  "Adgra2",
  "Adgra3",
  "Adgrb1",
  "Adgrb2",
  "Adgrb3",
  "Adgrd1",
  "Adgre1",
  "Adgre4",
  "Adgre5",
  "Adgrf1",
  "Adgrf2",
  "Adgrf3",
  "Adgrf4",
  "Adgrf5",
  "Adgrg1",
  "Adgrg2",
  "Adgrg3",
  "Adgrg4",
  "Adgrg5",
  "Adgrg6",
  "Adgrg7",
  "Adgrl1",
  "Adgrl2",
  "Adgrl3",
  "Adgrl4",
  "Adgrv1",
  "Adh1",
  "Adh4",
  "Adh5",
  "Adh6a",
  "Adh7",
  "Adhfe1",
  "Adi1",
  "Adig",
  "Adipoq",
  "Adipor1",
  "Adipor2",
  "Adk",
  "Adm",
  "Adm2",
  "Adnp2",
  "Ado",
  "Adora1",
  "Adora2a",
  "Adora2b",
  "Adora3",
  "Adpgk",
  "Adprh",
  "Adprhl1",
  "Adprhl2",
  "Adprm",
  "Adra1a",
  "Adra1d",
  "Adra2a",
  "Adra2c",
  "Adrb1",
  "Adrb2",
  "Adrb3",
  "Adsl",
  "Adss",
  "Adssl1",
  "Adtrp",
  "Aebp1",
  "Aebp2",
  "Aen",
  "Afap1",
  "Afap1l1",
  "Afap1l2",
  "Afdn",
  "Aff1",
  "Aff2",
  "Aff3",
  "Aff4",
  "Afg1l",
  "Afg3l1",
  "Afg3l2",
  "Afm",
  "Afp",
  "Aftph",
  "Aga",
  "Agap1",
  "Agap2",
  "Agap3",
  "Agbl1",
  "Agbl2",
  "Agbl3",
  "Agbl4",
  "Agbl5",
  "Ager",
  "Agfg1",
  "Agfg2",
  "Aggf1",
  "Agk",
  "Agl",
  "Agmat",
  "Agmo",
  "Ago1",
  "Ago2",
  "Ago3",
  "Ago4",
  "Agpat1",
  "Agpat2",
  "Agpat3",
  "Agpat4",
  "Agpat5",
  "Agps",
  "Agr2",
  "Agr3",
  "Agrn",
  "Agrp",
  "Agtpbp1",
  "Agtr1a",
  "Agtr1b",
  "Agtr2",
  "Agtrap",
  "Agxt",
  "Agxt2",
  "Ahctf1",
  "Ahcy",
  "Ahcyl1",
  "Ahcyl2",
  "Ahdc1",
  "Ahi1",
  "Ahnak",
  "Ahnak2",
  "Ahr",
  "Ahrr",
  "Ahsa2",
  "Ahsg",
  "Aicda",
  "Aida",
  "Aif1",
  "Aif1l",
  "Aifm1",
  "Aifm2",
  "Aifm3",
  "Aig1",
  "Aim2",
  "Aimp1",
  "Aimp2",
  "Aip",
  "Aipl1",
  "Aire",
  "Airn",
  "Ajap1",
  "Ajm1",
  "Ajuba",
  "Ak1",
  "Ak2",
  "Ak3",
  "Ak4",
  "Ak5",
  "Ak6",
  "Ak7",
  "Ak8",
  "Ak9",
  "Akain1",
  "Akap1",
  "Akap10",
  "Akap11",
  "Akap12",
  "Akap13",
  "Akap14",
  "Akap17b",
  "Akap3",
  "Akap4",
  "Akap5",
  "Akap6",
  "Akap7",
  "Akap8",
  "Akap8l",
  "Akap9",
  "Akip1",
  "Akirin1",
  "Akirin2",
  "Akna",
  "Aknad1",
  "Akp3",
  "Akr1a1",
  "Akr1b10",
  "Akr1b3",
  "Akr1b7",
  "Akr1b8",
  "Akr1c12",
  "Akr1c13",
  "Akr1c14",
  "Akr1c18",
  "Akr1c19",
  "Akr1c20",
  "Akr1c21",
  "Akr1c6",
  "Akr1cl",
  "Akr1d1",
  "Akr1e1",
  "Akr7a5",
  "Akt1",
  "Akt1s1",
  "Akt2",
  "Akt3",
  "Aktip",
  "Alad",
  "Alas1",
  "Alas2",
  "Alb",
  "Albfm1",
  "Alcam",
  "Aldh16a1",
  "Aldh18a1",
  "Aldh1a1",
  "Aldh1a2",
  "Aldh1a3",
  "Aldh1a7",
  "Aldh1b1",
  "Aldh1l1",
  "Aldh1l2",
  "Aldh2",
  "Aldh3a1",
  "Aldh3a2",
  "Aldh3b1",
  "Aldh3b2",
  "Aldh3b3",
  "Aldh4a1",
  "Aldh5a1",
  "Aldh6a1",
  "Aldh7a1",
  "Aldh8a1",
  "Aldh9a1",
  "Aldoa",
  "Aldoart1",
  "Aldoart2",
  "Aldob",
  "Aldoc",
  "Alg1",
  "Alg10b",
  "Alg11",
  "Alg12",
  "Alg13",
  "Alg14",
  "Alg2",
  "Alg3",
  "Alg5",
  "Alg6",
  "Alg8",
  "Alg9",
  "Alk",
  "Alkal1",
  "Alkal2",
  "Alkbh1",
  "Alkbh2",
  "Alkbh3",
  "Alkbh3os1",
  "Alkbh4",
  "Alkbh5",
  "Alkbh6",
  "Alkbh7",
  "Alkbh8",
  "Allc",
  "Alms1",
  "Alox12",
  "Alox12b",
  "Alox12e",
  "Alox15",
  "Alox5",
  "Alox5ap",
  "Aloxe3",
  "Alpi",
  "Alpk1",
  "Alpk2",
  "Alpk3",
  "Alpl",
  "Alppl2",
  "Als2",
  "Als2cl",
  "Alx1",
  "Alx3",
  "Alx4",
  "Alyref",
  "Alyref2",
  "Amacr",
  "Ambn",
  "Ambp",
  "Ambra1",
  "Amdhd1",
  "Amdhd2",
  "Amelx",
  "Amer1",
  "Amer2",
  "Amer3",
  "Amfr",
  "Amh",
  "Amhr2",
  "Amigo1",
  "Amigo2",
  "Amigo3",
  "Ammecr1",
  "Ammecr1l",
  "Amn",
  "Amn1",
  "Amot",
  "Amotl1",
  "Amotl2",
  "Ampd1",
  "Ampd2",
  "Ampd3",
  "Amph",
  "Amt",
  "Amtn",
  "Amy1",
  "Amy2a5",
  "Amz1",
  "Amz2",
  "Anapc1",
  "Anapc10",
  "Anapc11",
  "Anapc13",
  "Anapc15",
  "Anapc16",
  "Anapc2",
  "Anapc4",
  "Anapc5",
  "Anapc7",
  "Ang",
  "Ang2",
  "Ang4",
  "Ang5",
  "Angel1",
  "Angel2",
  "Angpt1",
  "Angpt2",
  "Angpt4",
  "Angptl1",
  "Angptl3",
  "Angptl4",
  "Angptl6",
  "Angptl7",
  "Angptl8",
  "Ank",
  "Ank2",
  "Ank3",
  "Ankar",
  "Ankdd1a",
  "Ankdd1b",
  "Ankef1",
  "Ankfn1",
  "Ankfy1",
  "Ankhd1",
  "Ankib1",
  "Ankk1",
  "Ankle1",
  "Ankle2",
  "Ankmy1",
  "Ankmy2",
  "Ankra2",
  "Ankrd1",
  "Ankrd10",
  "Ankrd11",
  "Ankrd13a",
  "Ankrd13b",
  "Ankrd13c",
  "Ankrd13d",
  "Ankrd16",
  "Ankrd17",
  "Ankrd2",
  "Ankrd22",
  "Ankrd23",
  "Ankrd24",
  "Ankrd26",
  "Ankrd27",
  "Ankrd28",
  "Ankrd29",
  "Ankrd33",
  "Ankrd33b",
  "Ankrd34a",
  "Ankrd34b",
  "Ankrd34c",
  "Ankrd35",
  "Ankrd36",
  "Ankrd37",
  "Ankrd39",
  "Ankrd40",
  "Ankrd42",
  "Ankrd44",
  "Ankrd45",
  "Ankrd46",
  "Ankrd49",
  "Ankrd50",
  "Ankrd52",
  "Ankrd53",
  "Ankrd54",
  "Ankrd55",
  "Ankrd6",
  "Ankrd60",
  "Ankrd61",
  "Ankrd63",
  "Ankrd65",
  "Ankrd66",
  "Ankrd7",
  "Ankrd9",
  "Anks1",
  "Anks1b",
  "Anks3",
  "Anks4b",
  "Anks6",
  "Ankub1",
  "Ankzf1",
  "Anln",
  "Ano1",
  "Ano10",
  "Ano2",
  "Ano3",
  "Ano4",
  "Ano5",
  "Ano6",
  "Ano7",
  "Ano8",
  "Ano9",
  "Anp32a",
  "Anp32b",
  "Anp32e",
  "Anpep",
  "Antxr1",
  "Antxr2",
  "Antxrl",
  "Anxa1",
  "Anxa10",
  "Anxa11",
  "Anxa11os",
  "Anxa13",
  "Anxa2",
  "Anxa3",
  "Anxa4",
  "Anxa5",
  "Anxa6",
  "Anxa7",
  "Anxa8",
  "Anxa9",
  "Aoah",
  "Aoc1",
  "Aoc2",
  "Aoc3",
  "Aopep",
  "Aox1",
  "Aox2",
  "Aox3",
  "Aox4",
  "Ap1ar",
  "Ap1b1",
  "Ap1g1",
  "Ap1g2",
  "Ap1m1",
  "Ap1m2",
  "Ap1s1",
  "Ap1s2",
  "Ap1s3",
  "Ap2a1",
  "Ap2a2",
  "Ap2b1",
  "Ap2m1",
  "Ap2s1",
  "Ap3b1",
  "Ap3b2",
  "Ap3d1",
  "Ap3m1",
  "Ap3m2",
  "Ap3s1",
  "Ap3s2",
  "Ap4b1",
  "Ap4e1",
  "Ap4m1",
  "Ap4s1",
  "Ap5b1",
  "Ap5m1",
  "Ap5s1",
  "Ap5z1",
  "Apaf1",
  "Apba1",
  "Apba2",
  "Apba3",
  "Apbb1",
  "Apbb1ip",
  "Apbb2",
  "Apbb3",
  "Apc",
  "Apc2",
  "Apcdd1",
  "Apcs",
  "Apeh",
  "Apela",
  "Apex1",
  "Apex2",
  "Aph1a",
  "Aph1b",
  "Apip",
  "Aplf",
  "Apln",
  "Aplnr",
  "Aplp1",
  "Aplp2",
  "Apmap",
  "Apoa1",
  "Apoa2",
  "Apoa4",
  "Apoa5",
  "Apob",
  "Apobec1",
  "Apobec2",
  "Apobec3",
  "Apobec4",
  "Apobr",
  "Apoc1",
  "Apoc3",
  "Apoc4",
  "Apod",
  "Apoe",
  "Apof",
  "Apoh",
  "Apol10a",
  "Apol10b",
  "Apol11b",
  "Apol6",
  "Apol7a",
  "Apol7c",
  "Apol7d",
  "Apol8",
  "Apol9a",
  "Apol9b",
  "Apold1",
  "Apom",
  "Apon",
  "Apoo",
  "Apool",
  "Apopt1",
  "App",
  "Appbp2",
  "Appbp2os",
  "Appl1",
  "Appl2",
  "Aprt",
  "Aptx",
  "Aqp1",
  "Aqp11",
  "Aqp12",
  "Aqp2",
  "Aqp3",
  "Aqp4",
  "Aqp5",
  "Aqp6",
  "Aqp7",
  "Aqp8",
  "Aqp9",
  "Aqr",
  "Ar",
  "Araf",
  "Arap1",
  "Arap2",
  "Arap3",
  "Arc",
  "Arcn1",
  "Areg",
  "Arel1",
  "Arf1",
  "Arf2",
  "Arf3",
  "Arf4",
  "Arf5",
  "Arf6",
  "Arfgap1",
  "Arfgap2",
  "Arfgap3",
  "Arfgef1",
  "Arfgef2",
  "Arfgef3",
  "Arfip2",
  "Arfrp1",
  "Arg1",
  "Arg2",
  "Arglu1",
  "Arhgap1",
  "Arhgap10",
  "Arhgap11a",
  "Arhgap12",
  "Arhgap15",
  "Arhgap17",
  "Arhgap18",
  "Arhgap19",
  "Arhgap20",
  "Arhgap20os",
  "Arhgap21",
  "Arhgap22",
  "Arhgap23",
  "Arhgap25",
  "Arhgap26",
  "Arhgap27",
  "Arhgap28",
  "Arhgap29",
  "Arhgap30",
  "Arhgap31",
  "Arhgap32",
  "Arhgap33",
  "Arhgap33os",
  "Arhgap35",
  "Arhgap36",
  "Arhgap39",
  "Arhgap4",
  "Arhgap40",
  "Arhgap42",
  "Arhgap44",
  "Arhgap45",
  "Arhgap5",
  "Arhgap6",
  "Arhgap8",
  "Arhgap9",
  "Arhgdia",
  "Arhgdib",
  "Arhgdig",
  "Arhgef1",
  "Arhgef10",
  "Arhgef10l",
  "Arhgef11",
  "Arhgef12",
  "Arhgef15",
  "Arhgef16",
  "Arhgef17",
  "Arhgef18",
  "Arhgef19",
  "Arhgef2",
  "Arhgef25",
  "Arhgef26",
  "Arhgef28",
  "Arhgef3",
  "Arhgef33",
  "Arhgef37",
  "Arhgef38",
  "Arhgef39",
  "Arhgef4",
  "Arhgef40",
  "Arhgef5",
  "Arhgef6",
  "Arhgef7",
  "Arhgef9",
  "Arid1a",
  "Arid1b",
  "Arid2",
  "Arid3a",
  "Arid3b",
  "Arid3c",
  "Arid4a",
  "Arid4b",
  "Arid5a",
  "Arid5b",
  "Arih1",
  "Arih2",
  "Arl1",
  "Arl10",
  "Arl11",
  "Arl13a",
  "Arl13b",
  "Arl14",
  "Arl14ep",
  "Arl14epl",
  "Arl15",
  "Arl2",
  "Arl2bp",
  "Arl3",
  "Arl4a",
  "Arl4aos",
  "Arl4c",
  "Arl4d",
  "Arl5a",
  "Arl5b",
  "Arl5c",
  "Arl6",
  "Arl6ip1",
  "Arl6ip4",
  "Arl6ip5",
  "Arl6ip6",
  "Arl8a",
  "Arl8b",
  "Arl9",
  "Armc1",
  "Armc10",
  "Armc12",
  "Armc2",
  "Armc3",
  "Armc4",
  "Armc5",
  "Armc6",
  "Armc7",
  "Armc8",
  "Armc9",
  "Armcx1",
  "Armcx2",
  "Armcx3",
  "Armcx4",
  "Armcx5",
  "Armh1",
  "Armh2",
  "Armh3",
  "Armh4",
  "Armt1",
  "Arnt",
  "Arnt2",
  "Arntl",
  "Arntl2",
  "Arpc1a",
  "Arpc1b",
  "Arpc2",
  "Arpc3",
  "Arpc4",
  "Arpc5",
  "Arpc5l",
  "Arpin",
  "Arpp19",
  "Arpp21",
  "Arr3",
  "Arrb1",
  "Arrb2",
  "Arrdc1",
  "Arrdc2",
  "Arrdc3",
  "Arrdc4",
  "Arrdc5",
  "Arsa",
  "Arsb",
  "Arsg",
  "Arsi",
  "Arsj",
  "Arsk",
  "Art1",
  "Art2b",
  "Art3",
  "Art4",
  "Art5",
  "Artn",
  "Arv1",
  "Arvcf",
  "Arx",
  "Arxes1",
  "Arxes2",
  "As3mt",
  "Asah1",
  "Asah2",
  "Asap1",
  "Asap2",
  "Asap3",
  "Asb1",
  "Asb10",
  "Asb11",
  "Asb12",
  "Asb13",
  "Asb14",
  "Asb15",
  "Asb16",
  "Asb17",
  "Asb18",
  "Asb2",
  "Asb3",
  "Asb4",
  "Asb5",
  "Asb6",
  "Asb7",
  "Asb8",
  "Asb9",
  "Ascc1",
  "Ascc2",
  "Ascc3",
  "Ascl1",
  "Ascl2",
  "Ascl3",
  "Ascl4",
  "Asf1a",
  "Asf1b",
  "Asgr1",
  "Asgr2",
  "Ash1l",
  "Ash2l",
  "Asic1",
  "Asic2",
  "Asic3",
  "Asic4",
  "Asic5",
  "Asl",
  "Asna1",
  "Asns",
  "Asnsd1",
  "Aspa",
  "Aspdh",
  "Aspg",
  "Asph",
  "Asphd1",
  "Asphd2",
  "Aspm",
  "Aspn",
  "Asprv1",
  "Aspscr1",
  "Asrgl1",
  "Ass1",
  "Aste1",
  "Astl",
  "Astn1",
  "Astn2",
  "Astx3",
  "Asxl1",
  "Asxl2",
  "Asxl3",
  "Asz1",
  "Atad1",
  "Atad2",
  "Atad2b",
  "Atad3a",
  "Atad3aos",
  "Atad5",
  "Atat1",
  "Atcay",
  "Atcayos",
  "Ate1",
  "Atf1",
  "Atf2",
  "Atf3",
  "Atf4",
  "Atf5",
  "Atf6",
  "Atf6b",
  "Atf7",
  "Atf7ip",
  "Atf7ip2",
  "Atg10",
  "Atg101",
  "Atg12",
  "Atg13",
  "Atg14",
  "Atg16l1",
  "Atg16l2",
  "Atg2a",
  "Atg3",
  "Atg4a",
  "Atg4b",
  "Atg4c",
  "Atg4d",
  "Atg5",
  "Atg7",
  "Atg9a",
  "Atg9b",
  "Atic",
  "Atl1",
  "Atl2",
  "Atl3",
  "Atm",
  "Atmin",
  "Atoh1",
  "Atoh7",
  "Atoh8",
  "Atox1",
  "Atp10a",
  "Atp10b",
  "Atp11a",
  "Atp11b",
  "Atp11c",
  "Atp12a",
  "Atp13a1",
  "Atp13a2",
  "Atp13a3",
  "Atp13a4",
  "Atp13a5",
  "Atp1a1",
  "Atp1a2",
  "Atp1a3",
  "Atp1a4",
  "Atp1b1",
  "Atp1b2",
  "Atp1b3",
  "Atp1b4",
  "Atp23",
  "Atp2a1",
  "Atp2a2",
  "Atp2a3",
  "Atp2b1",
  "Atp2b2",
  "Atp2b3",
  "Atp2b4",
  "Atp2c1",
  "Atp2c2",
  "Atp4a",
  "Atp4b",
  "Atp5a1",
  "Atp5b",
  "Atp5c1",
  "Atp5d",
  "Atp5e",
  "Atp5g1",
  "Atp5g3",
  "Atp5h",
  "Atp5j",
  "Atp5j2",
  "Atp5k",
  "Atp5l",
  "Atp5mpl",
  "Atp5pb",
  "Atp6ap1",
  "Atp6ap1l",
  "Atp6ap2",
  "Atp6v0a1",
  "Atp6v0a2",
  "Atp6v0a4",
  "Atp6v0b",
  "Atp6v0c",
  "Atp6v0d1",
  "Atp6v0d2",
  "Atp6v0e",
  "Atp6v0e2",
  "Atp6v1a",
  "Atp6v1b1",
  "Atp6v1b2",
  "Atp6v1c1",
  "Atp6v1c2",
  "Atp6v1d",
  "Atp6v1e1",
  "Atp6v1e2",
  "Atp6v1f",
  "Atp6v1fnb",
  "Atp6v1g1",
  "Atp6v1g2",
  "Atp6v1g3",
  "Atp6v1h",
  "Atp7a",
  "Atp7b",
  "Atp8a1",
  "Atp8a2",
  "Atp8b1",
  "Atp8b2",
  "Atp8b3",
  "Atp8b4",
  "Atp8b5",
  "Atp9a",
  "Atp9b",
  "Atpaf1",
  "Atpaf2",
  "Atpif1",
  "Atpsckmt",
  "Atr",
  "Atraid",
  "Atrip",
  "Atrn",
  "Atrnl1",
  "Atrx",
  "Atxn1",
  "Atxn10",
  "Atxn1l",
  "Atxn2",
  "Atxn2l",
  "Atxn3",
  "Atxn7",
  "Atxn7l1",
  "Atxn7l1os1",
  "Atxn7l1os2",
  "Atxn7l2",
  "Atxn7l3",
  "Atxn7l3b",
  "Auh",
  "Aunip",
  "Aup1",
  "Aurka",
  "Aurkaip1",
  "Aurkb",
  "Auts2",
  "Aven",
  "Avil",
  "Avl9",
  "Avp",
  "Avpi1",
  "Avpr1a",
  "Avpr1b",
  "Avpr2",
  "Awat1",
  "Awat2",
  "Axdnd1",
  "Axin1",
  "Axin2",
  "Axl",
  "Aym1",
  "Azgp1",
  "Azi2",
  "Azin1",
  "Azin2",
  "B020004C17Rik",
  "B020004J07Rik",
  "B020011L13Rik",
  "B020031H02Rik",
  "B130024G19Rik",
  "B130034C11Rik",
  "B130046B21Rik",
  "B230104I21Rik",
  "B230110C06Rik",
  "B230110G15Rik",
  "B230118H07Rik",
  "B230206H07Rik",
  "B230206L02Rik",
  "B230217C12Rik",
  "B230219D22Rik",
  "B230303O12Rik",
  "B230307C23Rik",
  "B230311B06Rik",
  "B230317F23Rik",
  "B230319C09Rik",
  "B230334C09Rik",
  "B230354K17Rik",
  "B230369F24Rik",
  "B230398E01Rik",
  "B2m",
  "B3galnt1",
  "B3galnt2",
  "B3galt1",
  "B3galt2",
  "B3galt4",
  "B3galt5",
  "B3galt6",
  "B3gat1",
  "B3gat2",
  "B3gat3",
  "B3glct",
  "B3gnt3",
  "B3gnt4",
  "B3gnt5",
  "B3gnt6",
  "B3gnt7",
  "B3gnt8",
  "B3gnt9",
  "B3gntl1",
  "B430212C06Rik",
  "B430305J03Rik",
  "B430306N03Rik",
  "B430319H21Rik",
  "B4galnt1",
  "B4galnt3",
  "B4galnt4",
  "B4galt1",
  "B4galt2",
  "B4galt3",
  "B4galt4",
  "B4galt5",
  "B4galt6",
  "B4galt7",
  "B530045E10Rik",
  "B630019A10Rik",
  "B630019K06Rik",
  "B830012L14Rik",
  "B830017H08Rik",
  "B930018H19Rik",
  "B930025P03Rik",
  "B930036N10Rik",
  "B930059L03Rik",
  "B930095G15Rik",
  "B9d1",
  "B9d1os",
  "B9d2",
  "BB014433",
  "BB031773",
  "BB557941",
  "BC001981",
  "BC002059",
  "BC002189",
  "BC003965",
  "BC004004",
  "BC005537",
  "BC005624",
  "BC006965",
  "BC016548",
  "BC016579",
  "BC017158",
  "BC018473",
  "BC024139",
  "BC024978",
  "BC025920",
  "BC026513",
  "BC026762",
  "BC028471",
  "BC028528",
  "BC028777",
  "BC029722",
  "BC030500",
  "BC031181",
  "BC034090",
  "BC037156",
  "BC037704",
  "BC039966",
  "BC042761",
  "BC043934",
  "BC048403",
  "BC048507",
  "BC048562",
  "BC048644",
  "BC048671",
  "BC048679",
  "BC049715",
  "BC049762",
  "BC050972",
  "BC051019",
  "BC051076",
  "BC051142",
  "BC051226",
  "BC051408",
  "BC051537",
  "BC051665",
  "BC052040",
  "BC053393",
  "BC055324",
  "BC065397",
  "BC065403",
  "BC067074",
  "BC080695",
  "BC106179",
  "BC107364",
  "Baalc",
  "Baat",
  "Babam1",
  "Babam2",
  "Bace1",
  "Bace2",
  "Bach1",
  "Bach2",
  "Bad",
  "Bag1",
  "Bag2",
  "Bag3",
  "Bag4",
  "Bag5",
  "Bag6",
  "Bahcc1",
  "Bahd1",
  "Baiap2",
  "Baiap2l1",
  "Baiap2l2",
  "Baiap3",
  "Bak1",
  "Bambi",
  "Banf1",
  "Banf2",
  "Bank1",
  "Banp",
  "Bap1",
  "Bard1",
  "Barhl1",
  "Barhl2",
  "Barx1",
  "Barx2",
  "Basp1",
  "Batf2",
  "Batf3",
  "Bax",
  "Baz1a",
  "Baz1b",
  "Baz2b",
  "Bbc3",
  "Bbip1",
  "Bbof1",
  "Bbox1",
  "Bbs1",
  "Bbs10",
  "Bbs12",
  "Bbs2",
  "Bbs4",
  "Bbs5",
  "Bbs7",
  "Bbs9",
  "Bbx",
  "Bcam",
  "Bcan",
  "Bcap29",
  "Bcap31",
  "Bcar1",
  "Bcar3",
  "Bcas1",
  "Bcas1os2",
  "Bcas2",
  "Bcas3",
  "Bcat1",
  "Bcat2",
  "Bccip",
  "Bcdin3d",
  "Bche",
  "Bckdha",
  "Bckdhb",
  "Bckdk",
  "Bcl10",
  "Bcl11a",
  "Bcl11b",
  "Bcl2",
  "Bcl2a1c",
  "Bcl2l1",
  "Bcl2l10",
  "Bcl2l11",
  "Bcl2l12",
  "Bcl2l13",
  "Bcl2l14",
  "Bcl2l15",
  "Bcl2l2",
  "Bcl3",
  "Bcl6",
  "Bcl6b",
  "Bcl7a",
  "Bcl7b",
  "Bcl7c",
  "Bcl9",
  "Bcl9l",
  "Bclaf1",
  "Bclaf3",
  "Bco1",
  "Bco2",
  "Bcor",
  "Bcorl1",
  "Bcr",
  "Bcs1l",
  "Bdh1",
  "Bdh2",
  "Bdkrb1",
  "Bdkrb2",
  "Bdnf",
  "Bdp1",
  "Bean1",
  "Becn1",
  "Begain",
  "Bend3",
  "Bend4",
  "Bend5",
  "Bend6",
  "Bend7",
  "Best1",
  "Best2",
  "Best3",
  "Bet1",
  "Bex1",
  "Bex2",
  "Bex4",
  "Bex6",
  "Bfar",
  "Bfsp1",
  "Bfsp2",
  "Bglap",
  "Bglap3",
  "Bgn",
  "Bhlha15",
  "Bhlha9",
  "Bhlhb9",
  "Bhlhe22",
  "Bhlhe23",
  "Bhlhe40",
  "Bhlhe41",
  "Bhmt",
  "Bhmt2",
  "Bicc1",
  "Bicd1",
  "Bicd2",
  "Bicdl1",
  "Bicdl2",
  "Bicra",
  "Bicral",
  "Bid",
  "Bik",
  "Bin1",
  "Bin2",
  "Bin3",
  "Birc2",
  "Birc3",
  "Birc5",
  "Birc6",
  "Birc7",
  "Bivm",
  "Blcap",
  "Blk",
  "Blm",
  "Blmh",
  "Blnk",
  "Bloc1s1",
  "Bloc1s2",
  "Bloc1s3",
  "Bloc1s4",
  "Bloc1s5",
  "Bloc1s6",
  "Blvra",
  "Blvrb",
  "Blzf1",
  "Bmerb1",
  "Bmf",
  "Bmi1",
  "Bmp1",
  "Bmp10",
  "Bmp15",
  "Bmp2",
  "Bmp2k",
  "Bmp3",
  "Bmp4",
  "Bmp5",
  "Bmp6",
  "Bmp7",
  "Bmp8a",
  "Bmp8b",
  "Bmper",
  "Bmpr1a",
  "Bmpr1b",
  "Bmpr2",
  "Bms1",
  "Bmt2",
  "Bmx",
  "Bmyc",
  "Bnc1",
  "Bnc2",
  "Bnip1",
  "Bnip2",
  "Bnip3",
  "Bnip3l",
  "Bnip5",
  "Bnipl",
  "Boc",
  "Bod1",
  "Bola1",
  "Bola2",
  "Bola3",
  "Boll",
  "Bop1",
  "Bora",
  "Borcs5",
  "Borcs6",
  "Borcs7",
  "Borcs8",
  "Bpgm",
  "Bphl",
  "Bpi",
  "Bpifa1",
  "Bpifa2",
  "Bpifa3",
  "Bpifa5",
  "Bpifa6",
  "Bpifb1",
  "Bpifb2",
  "Bpifb3",
  "Bpifb4",
  "Bpifb5",
  "Bpifb6",
  "Bpifc",
  "Bpnt1",
  "Bptf",
  "Braf",
  "Brap",
  "Brat1",
  "Brca1",
  "Brca2",
  "Brcc3",
  "Brd1",
  "Brd2",
  "Brd3",
  "Brd7",
  "Brd8",
  "Brd8dc",
  "Brd9",
  "Brdt",
  "Brf1",
  "Brf2",
  "Bri3",
  "Bri3bp",
  "Bricd5",
  "Brinp1",
  "Brinp2",
  "Brinp3",
  "Brip1",
  "Brip1os",
  "Brix1",
  "Brk1",
  "Brms1l",
  "Brox",
  "Brpf1",
  "Brpf3",
  "Brs3",
  "Brsk1",
  "Brsk2",
  "Brwd1",
  "Brwd3",
  "Bscl2",
  "Bsg",
  "Bsn",
  "Bsnd",
  "Bsph1",
  "Bsph2",
  "Bspry",
  "Bst1",
  "Bst2",
  "Bsx",
  "Btbd1",
  "Btbd10",
  "Btbd11",
  "Btbd16",
  "Btbd17",
  "Btbd19",
  "Btbd2",
  "Btbd6",
  "Btbd7",
  "Btbd8",
  "Btbd9",
  "Btc",
  "Btd",
  "Btf3",
  "Btf3l4",
  "Btg1",
  "Btg1b",
  "Btg1c",
  "Btg2",
  "Btg3",
  "Btg4",
  "Btk",
  "Btla",
  "Btn1a1",
  "Btn2a2",
  "Btnl1",
  "Btnl10",
  "Btnl2",
  "Btnl4",
  "Btnl6",
  "Btnl9",
  "Btrc",
  "Bub1",
  "Bub1b",
  "Bub3",
  "Bud13",
  "Bud31",
  "Bves",
  "Bvht",
  "Bysl",
  "Bzw1",
  "Bzw2",
  "C030005K15Rik",
  "C030006K11Rik",
  "C030010L15Rik",
  "C030013C21Rik",
  "C030013G03Rik",
  "C030014I23Rik",
  "C030017B01Rik",
  "C030034I22Rik",
  "C030037D09Rik",
  "C030037F17Rik",
  "C030047K22Rik",
  "C130021I20Rik",
  "C130026I21Rik",
  "C130026L21Rik",
  "C130036L24Rik",
  "C130040N14Rik",
  "C130050O18Rik",
  "C130071C03Rik",
  "C130073F10Rik",
  "C130074G19Rik",
  "C130083M11Rik",
  "C1d",
  "C1galt1",
  "C1galt1c1",
  "C1qa",
  "C1qb",
  "C1qbp",
  "C1qc",
  "C1ql1",
  "C1ql2",
  "C1ql4",
  "C1qtnf1",
  "C1qtnf12",
  "C1qtnf2",
  "C1qtnf3",
  "C1qtnf4",
  "C1qtnf6",
  "C1qtnf7",
  "C1qtnf9",
  "C1ra",
  "C1rl",
  "C1s1",
  "C1s2",
  "C2",
  "C230012O17Rik",
  "C230013L11Rik",
  "C230014O12Rik",
  "C230035I16Rik",
  "C230057A21Rik",
  "C230057M02Rik",
  "C230062I16Rik",
  "C230072F16Rik",
  "C230099D08Rik",
  "C2cd2",
  "C2cd2l",
  "C2cd3",
  "C2cd4a",
  "C2cd4b",
  "C2cd4c",
  "C2cd4d",
  "C2cd5",
  "C2cd6",
  "C3",
  "C330007P06Rik",
  "C330008A17Rik",
  "C330011M18Rik",
  "C330018A13Rik",
  "C330018D20Rik",
  "C330022C24Rik",
  "C330024D21Rik",
  "C3ar1",
  "C430002N11Rik",
  "C430042M11Rik",
  "C430049B03Rik",
  "C4b",
  "C4bp",
  "C530005A16Rik",
  "C530008M17Rik",
  "C530025M09Rik",
  "C530043A13Rik",
  "C530044C16Rik",
  "C5ar1",
  "C6",
  "C7",
  "C730002L08Rik",
  "C77080",
  "C78283",
  "C78859",
  "C79798",
  "C86187",
  "C87414",
  "C87436",
  "C87487",
  "C87499",
  "C87977",
  "C8a",
  "C8b",
  "C8g",
  "C9",
  "C920021L13Rik",
  "C9orf72",
  "CJ186046Rik",
  "CK137956",
  "CN725425",
  "Caap1",
  "Cab39",
  "Cab39l",
  "Cabcoco1",
  "Cabin1",
  "Cables1",
  "Cables2",
  "Cabp1",
  "Cabp2",
  "Cabp5",
  "Cabp7",
  "Cabs1",
  "Cabyr",
  "Cacfd1",
  "Cachd1",
  "Cacna1a",
  "Cacna1b",
  "Cacna1c",
  "Cacna1d",
  "Cacna1e",
  "Cacna1f",
  "Cacna1g",
  "Cacna1h",
  "Cacna1i",
  "Cacna1s",
  "Cacna2d1",
  "Cacna2d2",
  "Cacna2d3",
  "Cacna2d4",
  "Cacnb1",
  "Cacnb2",
  "Cacnb3",
  "Cacng1",
  "Cacng2",
  "Cacng3",
  "Cacng4",
  "Cacng5",
  "Cacng6",
  "Cacng7",
  "Cacng8",
  "Cactin",
  "Cacul1",
  "Cacybp",
  "Cad",
  "Cadm1",
  "Cadm2",
  "Cadm3",
  "Cadps",
  "Cadps2",
  "Cage1",
  "Calb1",
  "Calb2",
  "Calca",
  "Calcb",
  "Calcoco1",
  "Calcoco2",
  "Calcr",
  "Calcrl",
  "Cald1",
  "Calhm1",
  "Calhm2",
  "Calhm3",
  "Calhm4",
  "Calhm5",
  "Calhm6",
  "Calm1",
  "Calm2",
  "Calm3",
  "Calm4",
  "Calm5",
  "Calml3",
  "Calml4",
  "Caln1",
  "Calr",
  "Calr3",
  "Calr4",
  "Calu",
  "Caly",
  "Camk1",
  "Camk1d",
  "Camk1g",
  "Camk2a",
  "Camk2b",
  "Camk2d",
  "Camk2g",
  "Camk2n1",
  "Camk2n2",
  "Camk4",

  "Camkk1",
  "Camkk2",
  "Camkmt",
  "Camkv",
  "Caml",
  "Camp",
  "Camsap1",
  "Camsap2",
  "Camsap3",
  "Camta1",
  "Camta2",
  "Cand1",
  "Cand2",
  "Cant1",
  "Canx",
  "Cap1",
  "Cap2",
  "Capg",
  "Capn1",
  "Capn10",
  "Capn11",
  "Capn12",
  "Capn13",
  "Capn15",
  "Capn2",
  "Capn3",
  "Capn5",
  "Capn6",
  "Capn7",
  "Capn8",
  "Capn9",
  "Capns1",
  "Capns2",
  "Caprin1",
  "Caprin2",
  "Caps2",
  "Capsl",
  "Capza1",
  "Capza2",
  "Capza3",
  "Capzb",
  "Car1",
  "Car10",
  "Car11",
  "Car12",
  "Car13",
  "Car14",
  "Car2",
  "Car3",
  "Car4",
  "Car5a",
  "Car5b",
  "Car6",
  "Car7",
  "Car8",
  "Car9",
  "Card10",
  "Card11",
  "Card14",
  "Card19",
  "Card6",
  "Card9",
  "Carf",
  "Carhsp1",
  "Carlr",
  "Carm1",
  "Carmil1",
  "Carmil2",
  "Carmil3",
  "Carmn",
  "Carnmt1",
  "Carns1",
  "Cars",
  "Cars2",
  "Cartpt",
  "Casc1",
  "Casc3",
  "Casc4",
  "Casd1",
  "Cask",
  "Caskin2",
  "Casp1",
  "Casp12",
  "Casp14",
  "Casp2",
  "Casp3",
  "Casp4",
  "Casp6",
  "Casp7",
  "Casp8",
  "Casp8ap2",
  "Casp9",
  "Casq1",
  "Casq2",
  "Casr",
  "Cass4",
  "Cast",
  "Castor1",
  "Castor2",
  "Casz1",
  "Cat",
  "Catip",
  "Catsper1",
  "Catsper2",
  "Catsper3",
  "Catsper4",
  "Catsperb",
  "Catsperd",
  "Catspere2",
  "Catsperg2",
  "Catsperz",
  "Cav1",
  "Cav2",
  "Cav3",
  "Cavin1",
  "Cavin2",
  "Cavin3",
  "Cavin4",
  "Cbarp",
  "Cbfa2t2",
  "Cbfa2t3",
  "Cbfb",
  "Cbl",
  "Cblb",
  "Cblc",
  "Cblif",
  "Cbll1",
  "Cbln1",
  "Cbln3",
  "Cbln4",
  "Cbr1",
  "Cbr2",
  "Cbr3",
  "Cbr4",
  "Cbs",
  "Cbwd1",
  "Cbx1",
  "Cbx2",
  "Cbx3",
  "Cbx4",
  "Cbx5",
  "Cbx6",
  "Cbx7",
  "Cbx8",
  "Cby1",
  "Cby2",
  "Cby3",
  "Cc2d1a",
  "Cc2d1b",
  "Cc2d2a",
  "Cc2d2b",
  "Ccar1",
  "Ccar2",
  "Ccbe1",
  "Ccdc102a",
  "Ccdc103",
  "Ccdc105",
  "Ccdc106",
  "Ccdc107",
  "Ccdc110",
  "Ccdc112",
  "Ccdc113",
  "Ccdc114",
  "Ccdc115",
  "Ccdc116",
  "Ccdc117",
  "Ccdc12",
  "Ccdc120",
  "Ccdc121",
  "Ccdc122",
  "Ccdc124",
  "Ccdc125",
  "Ccdc126",
  "Ccdc127",
  "Ccdc13",
  "Ccdc130",
  "Ccdc134",
  "Ccdc136",
  "Ccdc137",
  "Ccdc138",
  "Ccdc14",
  "Ccdc141",
  "Ccdc144b",
  "Ccdc146",
  "Ccdc148",
  "Ccdc149",
  "Ccdc15",
  "Ccdc150",
  "Ccdc151",
  "Ccdc152",
  "Ccdc153",
  "Ccdc154",
  "Ccdc155",
  "Ccdc157",
  "Ccdc158",
  "Ccdc159",
  "Ccdc160",
  "Ccdc162",
  "Ccdc163",
  "Ccdc166",
  "Ccdc167",
  "Ccdc169",
  "Ccdc17",
  "Ccdc170",
  "Ccdc171",
  "Ccdc172",
  "Ccdc173",
  "Ccdc174",
  "Ccdc175",
  "Ccdc177",
  "Ccdc178",
  "Ccdc18",
  "Ccdc180",
  "Ccdc181",
  "Ccdc182",
  "Ccdc183",
  "Ccdc184",
  "Ccdc185",
  "Ccdc186",
  "Ccdc187",
  "Ccdc188",
  "Ccdc189",
  "Ccdc190",
  "Ccdc191",
  "Ccdc192",
  "Ccdc194",
  "Ccdc201",
  "Ccdc22",
  "Ccdc24",
  "Ccdc25",
  "Ccdc27",
  "Ccdc28a",
  "Ccdc28b",
  "Ccdc3",
  "Ccdc30",
  "Ccdc32",
  "Ccdc33",
  "Ccdc34",
  "Ccdc36",
  "Ccdc38",
  "Ccdc39",
  "Ccdc40",
  "Ccdc42",
  "Ccdc42os",
  "Ccdc43",
  "Ccdc47",
  "Ccdc50",
  "Ccdc51",
  "Ccdc54",
  "Ccdc57",
  "Ccdc58",
  "Ccdc59",
  "Ccdc6",
  "Ccdc60",
  "Ccdc61",
  "Ccdc62",
  "Ccdc63",
  "Ccdc65",
  "Ccdc66",
  "Ccdc68",
  "Ccdc69",
  "Ccdc70",
  "Ccdc71",
  "Ccdc71l",
  "Ccdc73",
  "Ccdc74a",
  "Ccdc77",
  "Ccdc78",
  "Ccdc7a",
  "Ccdc7b",
  "Ccdc8",
  "Ccdc80",
  "Ccdc81",
  "Ccdc82",
  "Ccdc83",
  "Ccdc84",
  "Ccdc85a",
  "Ccdc85b",
  "Ccdc85c",
  "Ccdc86",
  "Ccdc87",
  "Ccdc88a",
  "Ccdc88b",
  "Ccdc88c",
  "Ccdc89",
  "Ccdc9",
  "Ccdc90b",
  "Ccdc91",
  "Ccdc92",
  "Ccdc92b",
  "Ccdc93",
  "Ccdc96",
  "Ccdc97",
  "Ccdc9b",
  "Ccer1",
  "Ccer2",
  "Cchcr1",
  "Ccin",
  "Cck",
  "Cckar",
  "Cckbr",
  "Ccl1",
  "Ccl11",
  "Ccl12",
  "Ccl17",
  "Ccl19",
  "Ccl2",
  "Ccl20",
  "Ccl21a",
  "Ccl22",
  "Ccl24",
  "Ccl25",
  "Ccl26",
  "Ccl27a",
  "Ccl28",
  "Ccl3",
  "Ccl4",
  "Ccl5",
  "Ccl6",
  "Ccl7",
  "Ccl8",
  "Ccl9",
  "Ccm2",
  "Ccm2l",
  "Ccn1",
  "Ccn2",
  "Ccn3",
  "Ccn4",
  "Ccn5",
  "Ccn6",
  "Ccna1",
  "Ccna2",
  "Ccnb1",
  "Ccnb1ip1",
  "Ccnb3",
  "Ccnc",
  "Ccnd1",
  "Ccnd2",
  "Ccnd3",
  "Ccndbp1",
  "Ccne1",
  "Ccne2",
  "Ccnf",
  "Ccng1",
  "Ccng2",
  "Ccnh",
  "Ccni",
  "Ccnj",
  "Ccnjl",
  "Ccnk",
  "Ccnl1",
  "Ccnl2",
  "Ccno",
  "Ccnq",
  "Ccnt1",
  "Ccnt2",
  "Ccny",
  "Ccnyl1",
  "Ccp110",
  "Ccpg1",
  "Ccpg1os",
  "Ccr1",
  "Ccr10",
  "Ccr1l1",
  "Ccr2",
  "Ccr3",
  "Ccr4",
  "Ccr5",
  "Ccr6",
  "Ccr7",
  "Ccr8",
  "Ccr9",
  "Ccrl2",
  "Ccs",
  "Ccsap",
  "Ccser1",
  "Ccser2",
  "Cct2",
  "Cct5",
  "Cct6a",
  "Cct6b",
  "Cct7",
  "Cct8",
  "Cct8l1",
  "Ccz1",
  "Cd101",
  "Cd109",
  "Cd14",
  "Cd151",
  "Cd160",
  "Cd163",
  "Cd163l1",
  "Cd164",
  "Cd164l2",
  "Cd177",
  "Cd180",
  "Cd19",
  "Cd1d1",
  "Cd2",
  "Cd200",
  "Cd200r1",
  "Cd200r2",
  "Cd200r3",
  "Cd200r4",
  "Cd207",
  "Cd209a",
  "Cd209b",
  "Cd209c",
  "Cd209d",
  "Cd209e",
  "Cd209f",
  "Cd209g",
  "Cd22",
  "Cd226",
  "Cd244a",
  "Cd247",
  "Cd248",
  "Cd24a",
  "Cd27",
  "Cd274",
  "Cd276",
  "Cd28",
  "Cd2ap",
  "Cd2bp2",
  "Cd300c",
  "Cd300c2",
  "Cd300e",
  "Cd300lb",
  "Cd300ld",
  "Cd300ld2",
  "Cd300lf",
  "Cd300lg",
  "Cd302",
  "Cd320",
  "Cd33",
  "Cd34",
  "Cd36",
  "Cd37",
  "Cd38",
  "Cd3d",
  "Cd3e",
  "Cd3eap",
  "Cd3g",
  "Cd4",
  "Cd40",
  "Cd40lg",
  "Cd44",
  "Cd46",
  "Cd47",
  "Cd48",
  "Cd5",
  "Cd52",
  "Cd53",
  "Cd55",
  "Cd55b",
  "Cd59a",
  "Cd59b",
  "Cd5l",
  "Cd6",
  "Cd68",
  "Cd69",
  "Cd7",
  "Cd70",
  "Cd72",
  "Cd74",
  "Cd79a",
  "Cd79b",
  "Cd80",
  "Cd81",
  "Cd82",
  "Cd83",
  "Cd84",
  "Cd86",
  "Cd8a",
  "Cd8b1",
  "Cd9",
  "Cd93",
  "Cd96",
  "Cd99l2",
  "Cda",
  "Cdadc1",
  "Cdan1",
  "Cdc123",
  "Cdc14a",
  "Cdc14b",
  "Cdc16",
  "Cdc20",
  "Cdc20b",
  "Cdc23",
  "Cdc25b",
  "Cdc25c",
  "Cdc26",
  "Cdc27",
  "Cdc37",
  "Cdc37l1",
  "Cdc40",
  "Cdc42",
  "Cdc42bpa",
  "Cdc42bpb",
  "Cdc42bpg",
  "Cdc42ep1",
  "Cdc42ep2",
  "Cdc42ep3",
  "Cdc42ep4",
  "Cdc42ep5",
  "Cdc42se1",
  "Cdc42se2",
  "Cdc45",
  "Cdc5l",
  "Cdc6",
  "Cdc7",
  "Cdc73",
  "Cdca2",
  "Cdca3",
  "Cdca4",
  "Cdca5",
  "Cdca7",
  "Cdca7l",
  "Cdca8",
  "Cdcp1",
  "Cdcp2",
  "Cdcp3",
  "Cdh1",
  "Cdh10",
  "Cdh11",
  "Cdh12",
  "Cdh13",
  "Cdh15",
  "Cdh16",
  "Cdh17",
  "Cdh18",
  "Cdh19",
  "Cdh2",
  "Cdh20",
  "Cdh22",
  "Cdh23",
  "Cdh24",
  "Cdh26",
  "Cdh3",
  "Cdh4",
  "Cdh5",
  "Cdh6",
  "Cdh7",
  "Cdh8",
  "Cdh9",
  "Cdhr1",
  "Cdhr2",
  "Cdhr3",
  "Cdhr4",
  "Cdhr5",
  "Cdip1",
  "Cdipt",
  "Cdiptos",
  "Cdk1",
  "Cdk10",
  "Cdk11b",
  "Cdk12",
  "Cdk13",
  "Cdk14",
  "Cdk15",
  "Cdk16",
  "Cdk17",
  "Cdk18",
  "Cdk19",
  "Cdk19os",
  "Cdk2",
  "Cdk20",
  "Cdk2ap2",
  "Cdk4",
  "Cdk5",
  "Cdk5r1",
  "Cdk5r2",
  "Cdk5rap1",
  "Cdk5rap2",
  "Cdk5rap3",
  "Cdk6",
  "Cdk7",
  "Cdk8",
  "Cdk9",
  "Cdkal1",
  "Cdkl1",
  "Cdkl2",
  "Cdkl3",
  "Cdkl4",
  "Cdkl5",
  "Cdkn1a",
  "Cdkn1b",
  "Cdkn1c",
  "Cdkn2a",
  "Cdkn2aip",
  "Cdkn2aipnl",
  "Cdkn2b",
  "Cdkn2c",
  "Cdkn2d",
  "Cdkn3",
  "Cdnf",
  "Cdo1",
  "Cdon",
  "Cdpf1",
  "Cdr1os",
  "Cdr2",
  "Cdr2l",
  "Cdrt4",
  "Cdrt4os1",
  "Cds1",
  "Cds2",
  "Cdsn",
  "Cdt1",
  "Cdv3",
  "Cdx1",
  "Cdx2",
  "Cdx4",
  "Cdyl",
  "Cdyl2",
  "Ceacam10",
  "Ceacam11",
  "Ceacam12",
  "Ceacam13",
  "Ceacam14",
  "Ceacam15",
  "Ceacam16",
  "Ceacam18",
  "Ceacam19",
  "Ceacam2",
  "Ceacam20",
  "Ceacam3",
  "Ceacam5",
  "Ceacam9",
  "Cebpa",
  "Cebpb",
  "Cebpd",
  "Cebpe",
  "Cebpg",
  "Cebpz",
  "Cebpzos",
  "Cecr2",
  "Cel",
  "Cela1",
  "Cela2a",
  "Cela3a",
  "Cela3b",
  "Celf1",
  "Celf2",
  "Celf3",
  "Celf4",
  "Celf5",
  "Celf6",
  "Celrr",
  "Celsr1",
  "Celsr2",
  "Celsr3",
  "Cemip",
  "Cemip2",
  "Cend1",
  "Cenpa",
  "Cenpb",
  "Cenpe",
  "Cenpf",
  "Cenph",
  "Cenpi",
  "Cenpj",
  "Cenpk",
  "Cenpl",
  "Cenpm",
  "Cenpn",
  "Cenpo",
  "Cenpp",
  "Cenpq",
  "Cenps",
  "Cenpt",
  "Cenpu",
  "Cenpv",
  "Cenpx",
  "Cep104",
  "Cep112",
  "Cep112it",
  "Cep120",
  "Cep126",
  "Cep128",
  "Cep131",
  "Cep135",
  "Cep152",
  "Cep162",
  "Cep164",
  "Cep170",
  "Cep19",
  "Cep192",
  "Cep250",
  "Cep290",
  "Cep295",
  "Cep295nl",
  "Cep350",
  "Cep41",
  "Cep44",
  "Cep55",
  "Cep57",
  "Cep57l1",
  "Cep63",
  "Cep68",
  "Cep70",
  "Cep72",
  "Cep76",
  "Cep78",
  "Cep83",
  "Cep83os",
  "Cep85",
  "Cep85l",
  "Cep89",
  "Cep95",
  "Cep97",
  "Cept1",
  "Cer1",
  "Cercam",
  "Cerk",
  "Cerkl",
  "Cerox1",
  "Cers1",
  "Cers2",
  "Cers3",
  "Cers4",
  "Cers5",
  "Cers6",
  "Ces1a",
  "Ces1b",
  "Ces1c",
  "Ces1d",
  "Ces1e",
  "Ces1f",
  "Ces1g",
  "Ces1h",
  "Ces2a",
  "Ces2b",
  "Ces2c",
  "Ces2e",
  "Ces2f",
  "Ces2g",
  "Ces2h",
  "Ces3a",
  "Ces3b",
  "Ces4a",
  "Ces5a",
  "Cetn1",
  "Cetn2",
  "Cetn3",
  "Cetn4",
  "Cfap100",
  "Cfap126",
  "Cfap157",
  "Cfap161",
  "Cfap20",
  "Cfap206",
  "Cfap221",
  "Cfap298",
  "Cfap299",
  "Cfap300",
  "Cfap36",
  "Cfap410",
  "Cfap43",
  "Cfap44",
  "Cfap45",
  "Cfap46",
  "Cfap47",
  "Cfap52",
  "Cfap53",
  "Cfap54",
  "Cfap57",
  "Cfap58",
  "Cfap61",
  "Cfap65",
  "Cfap69",
  "Cfap70",
  "Cfap73",
  "Cfap74",
  "Cfap77",
  "Cfap97",
  "Cfap97d1",
  "Cfap97d2",
  "Cfc1",
  "Cfd",
  "Cfdp1",
  "Cfh",
  "Cfhr1",
  "Cfhr2",
  "Cfi",
  "Cfl1",
  "Cfl2",
  "Cflar",
  "Cfp",
  "Cftr",
  "Cga",
  "Cgas",
  "Cggbp1",
  "Cgn",
  "Cgnl1",
  "Cgref1",
  "Cgrrf1",
  "Ch25h",
  "Chac1",
  "Chac2",
  "Chad",
  "Chadl",
  "Chaf1a",
  "Chaf1b",
  "Champ1",
  "Chat",
  "Chchd1",
  "Chchd10",
  "Chchd2",
  "Chchd3",
  "Chchd4",
  "Chchd5",
  "Chchd6",
  "Chchd7",
  "Chd1",
  "Chd1l",
  "Chd2",
  "Chd3",
  "Chd4",
  "Chd5",
  "Chd6",
  "Chd7",
  "Chd8",
  "Chd9",
  "Chdh",
  "Chek1",
  "Chek2",
  "Cherp",
  "Chfr",
  "Chga",
  "Chgb",
  "Chia1",
  "Chic1",
  "Chic2",
  "Chid1",
  "Chil1",
  "Chil3",
  "Chil4",
  "Chil5",
  "Chil6",
  "Chit1",
  "Chka",
  "Chl1",
  "Chm",
  "Chml",
  "Chmp1a",
  "Chmp1b",
  "Chmp2b",
  "Chmp3",
  "Chmp4b",
  "Chmp4c",
  "Chmp5",
  "Chmp6",
  "Chmp7",
  "Chn1",
  "Chn1os3",
  "Chn2",
  "Chodl",
  "Chordc1",
  "Chp1",
  "Chp2",
  "Chpf",
  "Chpf2",
  "Chpt1",
  "Chrac1",
  "Chrd",
  "Chrdl1",
  "Chrdl2",
  "Chrm1",
  "Chrm2",
  "Chrm3",
  "Chrm4",
  "Chrm5",
  "Chrna1",
  "Chrna10",
  "Chrna2",
  "Chrna3",
  "Chrna4",
  "Chrna5",
  "Chrna6",
  "Chrna7",
  "Chrna9",
  "Chrnb1",
  "Chrnb2",
  "Chrnb3",
  "Chrnb4",
  "Chrnd",
  "Chrne",
  "Chrng",
  "Chst1",
  "Chst10",
  "Chst11",
  "Chst12",
  "Chst13",
  "Chst14",
  "Chst15",
  "Chst2",
  "Chst3",
  "Chst4",
  "Chst5",
  "Chst7",
  "Chst8",
  "Chst9",
  "Chsy1",
  "Chsy3",
  "Chtf18",
  "Chtop",
  "Chuk",
  "Churc1",
  "Ciao1",
  "Ciao2a",
  "Ciao2b",
  "Ciao3",
  "Ciapin1",
  "Ciart",
  "Cib1",
  "Cib2",
  "Cib3",
  "Cib4",
  "Cic",
  "Cidea",
  "Cideb",
  "Cidec",
  "Ciita",
  "Cilk1",
  "Cilp",
  "Cilp2",
  "Cinp",
  "Cip2a",
  "Cipc",
  "Cirbp",
  "Cisd1",
  "Cisd2",
  "Cisd3",
  "Cish",
  "Cit",
  "Cited1",
  "Cited2",
  "Cited4",
  "Ciz1",
  "Ckap2",
  "Ckap2l",
  "Ckap4",
  "Ckap5",
  "Ckb",
  "Cklf",
  "Ckm",
  "Ckmt1",
  "Ckmt2",
  "Cks1b",
  "Cks1brt",
  "Cks2",
  "Clasp1",
  "Clasp2",
  "Clasrp",
  "Clba1",
  "Clca1",
  "Clca2",
  "Clca3a1",
  "Clca3a2",
  "Clca3b",
  "Clca4a",
  "Clca4b",
  "Clcc1",
  "Clcf1",
  "Clcn1",
  "Clcn2",
  "Clcn3",
  "Clcn4",
  "Clcn5",
  "Clcn6",
  "Clcn7",
  "Clcnka",
  "Clcnkb",
  "Cldn1",
  "Cldn10",
  "Cldn11",
  "Cldn12",
  "Cldn13",
  "Cldn14",
  "Cldn15",
  "Cldn16",
  "Cldn17",
  "Cldn18",
  "Cldn19",
  "Cldn2",
  "Cldn20",
  "Cldn22",
  "Cldn23",
  "Cldn24",
  "Cldn3",
  "Cldn34a",
  "Cldn34b1",
  "Cldn34b2",
  "Cldn34b3",
  "Cldn34b4",
  "Cldn34c1",
  "Cldn34c4",
  "Cldn34d",
  "Cldn4",
  "Cldn5",
  "Cldn6",
  "Cldn7",
  "Cldn8",
  "Cldn9",
  "Cldnd1",
  "Cldnd2",
  "Clec10a",
  "Clec11a",
  "Clec12a",
  "Clec12b",
  "Clec14a",
  "Clec16a",
  "Clec18a",
  "Clec1a",
  "Clec1b",
  "Clec2d",
  "Clec2e",
  "Clec2g",
  "Clec2h",
  "Clec2i",
  "Clec2l",
  "Clec2m",
  "Clec3a",
  "Clec3b",
  "Clec4a1",
  "Clec4a2",
  "Clec4a3",
  "Clec4a4",
  "Clec4b1",
  "Clec4b2",
  "Clec4d",
  "Clec4e",
  "Clec4f",
  "Clec4g",
  "Clec4n",
  "Clec5a",
  "Clec9a",
  "Clhc1",
  "Clic1",
  "Clic3",
  "Clic4",
  "Clic5",
  "Clic6",
  "Clint1",
  "Clip1",
  "Clip2",
  "Clip3",
  "Clip4",
  "Clk1",
  "Clk2",
  "Clk3",
  "Clk4",
  "Clmn",
  "Clmp",
  "Cln3",
  "Cln5",
  "Cln6",
  "Cln8",
  "Clnk",
  "Clnkos",
  "Clns1a",
  "Clock",
  "Clp1",
  "Clpb",
  "Clpp",
  "Clps",
  "Clpsl2",
  "Clptm1",
  "Clptm1l",
  "Clpx",
  "Clrn1",
  "Clrn2",
  "Clrn3",
  "Clspn",
  "Clstn1",
  "Clstn2",
  "Clstn3",
  "Clta",
  "Cltb",
  "Cltc",
  "Cltrn",
  "Clu",
  "Cluap1",
  "Cluh",
  "Clvs1",
  "Clvs2",
  "Clybl",
  "Cma1",
  "Cmah",
  "Cmas",
  "Cmbl",
  "Cmc1",
  "Cmc2",
  "Cmc4",
  "Cmip",
  "Cmklr1",
  "Cmpk1",
  "Cmpk2",
  "Cmss1",
  "Cmtm2a",
  "Cmtm2b",
  "Cmtm3",
  "Cmtm4",
  "Cmtm5",
  "Cmtm6",
  "Cmtm7",
  "Cmtm8",
  "Cmtr2",
  "Cmya5",
  "Cnbd1",
  "Cnbd2",
  "Cnbp",
  "Cndp1",
  "Cndp2",
  "Cnep1r1",
  "Cnfn",
  "Cnga1",
  "Cnga2",
  "Cnga3",
  "Cnga4",
  "Cngb1",
  "Cngb3",
  "Cnih1",
  "Cnih2",
  "Cnih3",
  "Cnih4",
  "Cnksr1",
  "Cnksr2",
  "Cnksr3",
  "Cnmd",
  "Cnn1",
  "Cnn2",
  "Cnn3",
  "Cnnm1",
  "Cnnm2",
  "Cnnm3",
  "Cnnm4",
  "Cnot1",
  "Cnot10",
  "Cnot11",
  "Cnot2",
  "Cnot4",
  "Cnot6",
  "Cnot6l",
  "Cnot7",
  "Cnot8",
  "Cnot9",
  "Cnp",
  "Cnppd1",
  "Cnpy1",
  "Cnpy2",
  "Cnpy3",
  "Cnpy4",
  "Cnr1",
  "Cnr2",
  "Cnrip1",
  "Cnst",
  "Cntd1",
  "Cntfr",
  "Cntln",
  "Cntn1",
  "Cntn2",
  "Cntn3",
  "Cntn4",
  "Cntn5",
  "Cntn6",
  "Cntnap1",
  "Cntnap2",
  "Cntnap3",
  "Cntnap4",
  "Cntnap5a",
  "Cntnap5b",
  "Cntnap5c",
  "Cntrl",
  "Cntrob",
  "Cntrobos",
  "Coa3",
  "Coa4",
  "Coa5",
  "Coa6",
  "Coa7",
  "Coasy",
  "Cobl",
  "Cobll1",
  "Coch",
  "Cog1",
  "Cog2",
  "Cog3",
  "Cog4",
  "Cog5",
  "Cog6",
  "Cog7",
  "Cog8",
  "Coil",
  "Col10a1",
  "Col11a1",
  "Col11a2",
  "Col12a1",
  "Col13a1",
  "Col14a1",
  "Col15a1",
  "Col16a1",
  "Col17a1",
  "Col18a1",
  "Col19a1",
  "Col1a1",
  "Col1a2",
  "Col20a1",
  "Col22a1",
  "Col23a1",
  "Col24a1",
  "Col25a1",
  "Col26a1",
  "Col27a1",
  "Col28a1",
  "Col2a1",
  "Col3a1",
  "Col4a1",
  "Col4a2",
  "Col4a3",
  "Col4a3bp",
  "Col4a4",
  "Col4a5",
  "Col4a6",
  "Col5a1",
  "Col5a2",
  "Col5a3",
  "Col6a1",
  "Col6a2",
  "Col6a3",
  "Col6a4",
  "Col6a5",
  "Col6a6",
  "Col7a1",
  "Col8a1",
  "Col8a2",
  "Col9a1",
  "Col9a2",
  "Col9a3",
  "Colca2",
  "Colec10",
  "Colec11",
  "Colec12",
  "Colgalt1",
  "Colgalt2",
  "Colq",
  "Commd10",
  "Commd2",
  "Commd3",
  "Commd4",
  "Commd6",
  "Commd7",
  "Commd8",
  "Commd9",
  "Comp",
  "Comt",
  "Comtd1",
  "Cop1",
  "Copa",
  "Copb1",
  "Copb2",
  "Cope",
  "Copg1",
  "Copg2",
  "Coprs",
  "Cops2",
  "Cops3",
  "Cops4",
  "Cops5",
  "Cops6",
  "Cops7a",
  "Cops7b",
  "Cops8",
  "Cops9",
  "Copz1",
  "Copz2",
  "Coq10a",
  "Coq10b",
  "Coq2",
  "Coq3",
  "Coq4",
  "Coq5",
  "Coq6",
  "Coq7",
  "Coq8a",
  "Coq8b",
  "Coq9",
  "Corin",
  "Coro1a",
  "Coro1b",
  "Coro1c",
  "Coro2a",
  "Coro2b",
  "Coro6",
  "Coro7",
  "Cort",
  "Cotl1",
  "Cox10",
  "Cox11",
  "Cox14",
  "Cox15",
  "Cox17",
  "Cox18",
  "Cox19",
  "Cox20",
  "Cox4i1",
  "Cox4i2",
  "Cox5a",
  "Cox5b",
  "Cox6a1",
  "Cox6a2",
  "Cox6b1",
  "Cox6b2",
  "Cox6c",
  "Cox7a1",
  "Cox7a2",
  "Cox7a2l",
  "Cox7b",
  "Cox7b2",
  "Cox7c",
  "Cox8a",
  "Cox8b",
  "Cox8c",
  "Cp",
  "Cpa1",
  "Cpa2",
  "Cpa3",
  "Cpa4",
  "Cpa5",
  "Cpa6",
  "Cpb1",
  "Cpb2",
  "Cpd",
  "Cpe",
  "Cpeb1",
  "Cpeb2",
  "Cpeb3",
  "Cpeb4",
  "Cped1",
  "Cplane1",
  "Cplane2",
  "Cplx1",
  "Cplx2",
  "Cplx3",
  "Cplx4",
  "Cpm",
  "Cpn1",
  "Cpn2",
  "Cpne2",
  "Cpne3",
  "Cpne4",
  "Cpne5",
  "Cpne6",
  "Cpne7",
  "Cpne8",
  "Cpne9",
  "Cpox",
  "Cpped1",
  "Cpq",
  "Cps1",
  "Cpsf1",
  "Cpsf2",
  "Cpsf3",
  "Cpsf4",
  "Cpsf4l",
  "Cpsf6",
  "Cpsf7",
  "Cpt1a",
  "Cpt1c",
  "Cpt2",
  "Cptp",
  "Cpvl",
  "Cpxcr1",
  "Cpxm1",
  "Cpxm2",
  "Cpz",
  "Cr1l",
  "Cr2",
  "Crabp1",
  "Crabp2",
  "Cracr2a",
  "Cracr2b",
  "Cradd",
  "Cramp1l",
  "Crat",
  "Crb1",
  "Crb2",
  "Crb3",
  "Crbn",
  "Crcp",
  "Crct1",
  "Creb1",
  "Creb3",
  "Creb3l1",
  "Creb3l2",
  "Creb3l3",
  "Creb3l4",
  "Creb5",
  "Crebbp",
  "Crebl2",
  "Crebrf",
  "Crebzf",
  "Creg1",
  "Creg2",
  "Creld1",
  "Creld2",
  "Crem",
  "Crh",
  "Crhbp",
  "Crhr1",
  "Crhr2",
  "Crim1",
  "Crip1",
  "Crip2",
  "Crip3",
  "Cript",
  "Crisp1",
  "Crisp2",
  "Crisp3",
  "Crisp4",
  "Crispld1",
  "Crispld2",
  "Crk",
  "Crkl",
  "Crlf1",
  "Crlf2",
  "Crlf3",
  "Crls1",
  "Crmp1",
  "Crnde",
  "Crnkl1",
  "Crnn",
  "Crocc",
  "Crocc2",
  "Crot",
  "Crp",
  "Crppa",
  "Crtac1",
  "Crtam",
  "Crtap",
  "Crtc1",
  "Crtc2",
  "Crtc3",
  "Crx",
  "Crxos",
  "Cry1",
  "Cry2",
  "Cryaa",
  "Cryab",
  "Cryba1",
  "Cryba2",
  "Cryba4",
  "Crybb1",
  "Crybb2",
  "Crybb3",
  "Crybg1",
  "Crybg2",
  "Crybg3",
  "Cryga",
  "Crygb",
  "Crygc",
  "Crygd",
  "Cryge",
  "Crygf",
  "Crygn",
  "Crygs",
  "Cryl1",
  "Crym",
  "Cryz",
  "Cryzl1",
  "Cryzl2",
  "Cs",
  "Csad",
  "Csdc2",
  "Csde1",
  "Cse1l",
  "Csf1",
  "Csf1r",
  "Csf2",
  "Csf2ra",
  "Csf2rb",
  "Csf2rb2",
  "Csf3",
  "Csf3r",
  "Csgalnact1",
  "Csgalnact2",
  "Csk",
  "Csl",
  "Csmd1",
  "Csmd2",
  "Csmd2os",
  "Csmd3",
  "Csn1s1",
  "Csn1s2a",
  "Csn1s2b",
  "Csn2",
  "Csn3",
  "Csnk1a1",
  "Csnk1d",
  "Csnk1e",
  "Csnk1g1",
  "Csnk1g2",
  "Csnk1g3",
  "Csnk2a1",
  "Csnk2a2",
  "Csnk2b",
  "Csnka2ip",
  "Cspg4",
  "Cspg5",
  "Cspp1",
  "Csrnp1",
  "Csrnp2",
  "Csrnp3",
  "Csrp1",
  "Csrp2",
  "Csrp3",
  "Cst10",
  "Cst11",
  "Cst12",
  "Cst13",
  "Cst3",
  "Cst6",
  "Cst7",
  "Cst8",
  "Cst9",
  "Csta1",
  "Csta2",
  "Csta3",
  "Cstad",
  "Cstb",
  "Cstdc1",
  "Cstdc2",
  "Cstdc4",
  "Cstdc6",
  "Cstf1",
  "Cstf2",
  "Cstf2t",
  "Cstf3",
  "Cstl1",
  "Ct55",
  "Ctag2",
  "Ctag2l2",
  "Ctbp1",
  "Ctbp2",
  "Ctbs",
  "Ctc1",
  "Ctcf",
  "Ctcfl",
  "Ctcflos",
  "Ctdnep1",
  "Ctdp1",
  "Ctdsp1",
  "Ctdsp2",
  "Ctdspl",
  "Ctdspl2",
  "Ctf1",
  "Ctf2",
  "Cth",
  "Cthrc1",
  "Ctif",
  "Ctla2a",
  "Ctla4",
  "Ctnna1",
  "Ctnna2",
  "Ctnna3",
  "Ctnnal1",
  "Ctnnb1",
  "Ctnnbip1",
  "Ctnnbl1",
  "Ctnnd1",
  "Ctnnd2",
  "Ctns",
  "Ctps",
  "Ctps2",
  "Ctr9",
  "Ctrb1",
  "Ctrc",
  "Ctrl",
  "Cts3",
  "Cts6",
  "Cts7",
  "Cts8",
  "Ctsa",
  "Ctsb",
  "Ctsc",
  "Ctsd",
  "Ctse",
  "Ctsf",
  "Ctsg",
  "Ctsh",
  "Ctsj",
  "Ctsk",
  "Ctsl",
  "Ctsll3",
  "Ctsm",
  "Ctso",
  "Ctsq",
  "Ctsr",
  "Ctss",
  "Ctsw",
  "Ctsz",
  "Cttn",
  "Cttnbp2",
  "Cttnbp2nl",
  "Ctu1",
  "Ctu2",
  "Ctxn1",
  "Ctxn2",
  "Ctxn3",
  "Cubn",
  "Cuedc1",
  "Cuedc2",
  "Cul1",
  "Cul2",
  "Cul3",
  "Cul4a",
  "Cul4b",
  "Cul5",
  "Cul7",
  "Cul9",
  "Cuta",
  "Cutal",
  "Cutc",
  "Cux1",
  "Cux2",
  "Cuzd1",
  "Cwc15",
  "Cwc22",
  "Cwc25",
  "Cwc27",
  "Cwf19l1",
  "Cwf19l2",
  "Cwh43",
  "Cx3cl1",
  "Cx3cr1",
  "Cxadr",
  "Cxcl1",
  "Cxcl10",
  "Cxcl12",
  "Cxcl13",
  "Cxcl14",
  "Cxcl15",
  "Cxcl16",
  "Cxcl17",
  "Cxcl2",
  "Cxcl3",
  "Cxcl5",
  "Cxcl9",
  "Cxcr1",
  "Cxcr2",
  "Cxcr3",
  "Cxcr4",
  "Cxcr5",
  "Cxcr6",
  "Cxxc1",
  "Cxxc4",
  "Cxxc5",
  "Cyb561",
  "Cyb561a3",
  "Cyb561d1",
  "Cyb561d2",
  "Cyb5a",
  "Cyb5b",
  "Cyb5d1",
  "Cyb5d2",
  "Cyb5r1",
  "Cyb5r2",
  "Cyb5r3",
  "Cyb5r4",
  "Cyb5rl",
  "Cyba",
  "Cybb",
  "Cybc1",
  "Cybrd1",
  "Cyc1",
  "Cycs",
  "Cyct",
  "Cyfip1",
  "Cyfip2",
  "Cygb",
  "Cyhr1",
  "Cylc1",
  "Cylc2",
  "Cyld",
  "Cym",
  "Cyp11a1",
  "Cyp11b1",
  "Cyp11b2",
  "Cyp17a1",
  "Cyp19a1",
  "Cyp1a1",
  "Cyp1a2",
  "Cyp1b1",
  "Cyp20a1",
  "Cyp21a1",
  "Cyp24a1",
  "Cyp26a1",
  "Cyp26b1",
  "Cyp26c1",
  "Cyp27a1",
  "Cyp27b1",
  "Cyp2a12",
  "Cyp2a22",
  "Cyp2ab1",
  "Cyp2b10",
  "Cyp2b13",
  "Cyp2b19",
  "Cyp2b9",
  "Cyp2c23",
  "Cyp2c29",
  "Cyp2c37",
  "Cyp2c38",
  "Cyp2c50",
  "Cyp2c54",
  "Cyp2c55",
  "Cyp2c65",
  "Cyp2c68",
  "Cyp2c70",
  "Cyp2d10",
  "Cyp2d11",
  "Cyp2d12",
  "Cyp2d22",
  "Cyp2d26",
  "Cyp2d34",
  "Cyp2d40",
  "Cyp2d9",
  "Cyp2e1",
  "Cyp2f2",
  "Cyp2g1",
  "Cyp2j11",
  "Cyp2j12",
  "Cyp2j13",
  "Cyp2j6",
  "Cyp2j7",
  "Cyp2j8",
  "Cyp2j9",
  "Cyp2r1",
  "Cyp2s1",
  "Cyp2t4",
  "Cyp2u1",
  "Cyp2w1",
  "Cyp39a1",
  "Cyp3a11",
  "Cyp3a13",
  "Cyp3a16",
  "Cyp3a25",
  "Cyp3a57",
  "Cyp3a59",
  "Cyp46a1",
  "Cyp4a10",
  "Cyp4a31",
  "Cyp4b1",
  "Cyp4f13",
  "Cyp4f14",
  "Cyp4f15",
  "Cyp4f16",
  "Cyp4f17",
  "Cyp4f18",
  "Cyp4f37",
  "Cyp4f39",
  "Cyp4f40",
  "Cyp4v3",
  "Cyp4x1",
  "Cyp51",
  "Cyp7a1",
  "Cyp7b1",
  "Cyp8b1",
  "Cypt1",
  "Cypt12",
  "Cypt14",
  "Cypt15",
  "Cypt2",
  "Cypt3",
  "Cypt4",
  "Cyren",
  "Cys1",
  "Cysltr1",
  "Cysltr2",
  "Cysrt1",
  "Cystm1",
  "Cyth1",
  "Cyth2",
  "Cyth3",
  "Cyth4",
  "Cytip",
  "Cytl1",
  "Cyyr1",
  "Czib",
  "D030007L05Rik",
  "D030018L15Rik",
  "D030025E07Rik",
  "D030028A08Rik",
  "D030045P18Rik",
  "D030047H15Rik",
  "D030056L22Rik",
  "D10Wsu102e",
  "D11Wsu47e",
  "D130009I18Rik",
  "D130017N08Rik",
  "D130020L05Rik",
  "D130040H23Rik",
  "D130043K22Rik",
  "D130052B06Rik",
  "D130062J10Rik",
  "D130079A08Rik",
  "D14Ertd670e",
  "D16Ertd472e",
  "D16Ertd519e",
  "D17H6S53E",
  "D1Ertd622e",
  "D1Pas1",
  "D230022J07Rik",
  "D230025D16Rik",
  "D230030E09Rik",
  "D2hgdh",
  "D330023K18Rik",
  "D330041H03Rik",
  "D330050G23Rik",
  "D330050I16Rik",
  "D3Ertd751e",
  "D430018E03Rik",
  "D430019H16Rik",
  "D430020J02Rik",
  "D430036J16Rik",
  "D430040D24Rik",
  "D430041D05Rik",
  "D430042O09Rik",
  "D5Ertd577e",
  "D5Ertd579e",
  "D5Ertd605e",
  "D5Ertd615e",
  "D630003M21Rik",
  "D630008O14Rik",
  "D630011A20Rik",
  "D630014O11Rik",
  "D630023F18Rik",
  "D630029K05Rik",
  "D630033O11Rik",
  "D630039A03Rik",
  "D630045J12Rik",
  "D6Ertd474e",
  "D6Ertd527e",
  "D6Wsu163e",
  "D730002M21Rik",
  "D730003I15Rik",
  "D730003K21Rik",
  "D730045A05Rik",
  "D730045B01Rik",
  "D7Ertd443e",
  "D830013O20Rik",
  "D830024N08Rik",
  "D830025C05Rik",
  "D830026I12Rik",
  "D830036C21Rik",
  "D830039M14Rik",
  "D830044D21Rik",
  "D830050J10Rik",
  "D930007J09Rik",
  "D930007P13Rik",
  "D930020B18Rik",
  "D930028M14Rik",
  "D930032P07Rik",
  "D930048N14Rik",
  "Daam2",
  "Dab1",
  "Dab2",
  "Dab2ip",
  "Dach1",
  "Dach2",
  "Dact1",
  "Dact2",
  "Dact3",
  "Dad1",
  "Dag1",
  "Dagla",
  "Daglb",
  "Dalrd3",
  "Dancr",
  "Dand5",
  "Dao",
  "Dap",
  "Dap3",
  "Dapk1",
  "Dapk2",
  "Dapk3",
  "Dapl1",
  "Dapp1",
  "Dars",
  "Dars2",
  "Daw1",
  "Daxx",
  "Dazap1",
  "Dazap2",
  "Dazl",
  "Dbf4",
  "Dbhos",
  "Dbi",
  "Dbil5",
  "Dbn1",
  "Dbndd1",
  "Dbndd2",
  "Dbnl",
  "Dbp",
  "Dbpht2",
  "Dbr1",
  "Dbt",
  "Dbx1",
  "Dbx2",
  "Dcaf1",
  "Dcaf10",
  "Dcaf11",
  "Dcaf12",
  "Dcaf12l1",
  "Dcaf12l2",
  "Dcaf13",
  "Dcaf15",
  "Dcaf17",
  "Dcaf4",
  "Dcaf5",
  "Dcaf6",
  "Dcaf7",
  "Dcaf8",
  "Dcaf8l",
  "Dcakd",
  "Dcbld1",
  "Dcbld2",
  "Dcc",
  "Dcdc2a",
  "Dcdc2b",
  "Dcdc2c",
  "Dchs1",
  "Dchs2",
  "Dck",
  "Dclk1",
  "Dclk2",
  "Dclk3",
  "Dclre1a",
  "Dclre1b",
  "Dclre1c",
  "Dcn",
  "Dcp1a",
  "Dcp1b",
  "Dcp2",
  "Dcpp1",
  "Dcpp2",
  "Dcps",
  "Dcst1",
  "Dcstamp",
  "Dct",
  "Dctd",
  "Dctn1",
  "Dctn2",
  "Dctn3",
  "Dctn4",
  "Dctn5",
  "Dctn6",
  "Dctpp1",
  "Dcun1d1",
  "Dcun1d2",
  "Dcun1d3",
  "Dcun1d4",
  "Dcun1d5",
  "Dcx",
  "Dcxr",
  "Dda1",
  "Ddah1",
  "Ddah2",
  "Ddb1",
  "Ddb2",
  "Ddc",
  "Ddhd1",
  "Ddhd2",
  "Ddi1",
  "Ddi2",
  "Ddias",
  "Ddit3",
  "Ddit4",
  "Ddit4l",
  "Ddn",
  "Ddo",
  "Ddost",
  "Ddr1",
  "Ddr2",
  "Ddrgk1",
  "Ddt",
  "Ddx1",
  "Ddx10",
  "Ddx11",
  "Ddx17",
  "Ddx19a",
  "Ddx19b",
  "Ddx20",
  "Ddx21",
  "Ddx23",
  "Ddx24",
  "Ddx25",
  "Ddx27",
  "Ddx28",
  "Ddx31",
  "Ddx39",
  "Ddx39b",
  "Ddx3x",
  "Ddx3y",
  "Ddx4",
  "Ddx41",
  "Ddx42",
  "Ddx43",
  "Ddx46",
  "Ddx47",
  "Ddx49",
  "Ddx5",
  "Ddx50",
  "Ddx51",
  "Ddx52",
  "Ddx54",
  "Ddx55",
  "Ddx56",
  "Ddx58",
  "Ddx59",
  "Ddx6",
  "Ddx60",
  "Deaf1",
  "Decr1",
  "Decr2",
  "Dedd",
  "Dedd2",
  "Def6",
  "Def8",
  "Defa39",
  "Defa43",
  "Defb1",
  "Defb10",
  "Defb11",
  "Defb12",
  "Defb13",
  "Defb14",
  "Defb15",
  "Defb18",
  "Defb19",
  "Defb2",
  "Defb20",
  "Defb21",
  "Defb22",
  "Defb23",
  "Defb25",
  "Defb26",
  "Defb28",
  "Defb29",
  "Defb30",
  "Defb33",
  "Defb34",
  "Defb35",
  "Defb36",
  "Defb37",
  "Defb38",
  "Defb39",
  "Defb4",
  "Defb40",
  "Defb41",
  "Defb42",
  "Defb43",
  "Defb45",
  "Defb46",
  "Defb47",
  "Defb48",
  "Defb5",
  "Defb50",
  "Defb6",
  "Defb7",
  "Defb8",
  "Defb9",
  "Degs1",
  "Degs2",
  "Dek",
  "Dele1",
  "Denn2b",
  "Dennd11",
  "Dennd1a",
  "Dennd1b",
  "Dennd1c",
  "Dennd2a",
  "Dennd2c",
  "Dennd2d",
  "Dennd3",
  "Dennd4a",
  "Dennd4b",
  "Dennd4c",
  "Dennd5a",
  "Dennd5b",
  "Dennd6a",
  "Dennd6b",
  "Denr",
  "Depdc1b",
  "Depdc5",
  "Depdc7",
  "Depp1",
  "Deptor",
  "Dera",
  "Derl1",
  "Derl2",
  "Derl3",
  "Des",
  "Desi1",
  "Desi2",
  "Det1",
  "Deup1",
  "Dexi",
  "Dffa",
  "Dffb",
  "Dgat1",
  "Dgat2",
  "Dgat2l6",
  "Dgcr2",
  "Dgcr6",
  "Dgcr8",
  "Dgka",
  "Dgkb",
  "Dgkd",
  "Dgke",
  "Dgkeos",
  "Dgkg",
  "Dgkh",
  "Dgki",
  "Dgkk",
  "Dgkq",
  "Dgkz",
  "Dglucy",
  "Dguok",
  "Dhcr24",
  "Dhcr7",
  "Dhdds",
  "Dhdh",
  "Dhfr",
  "Dhh",
  "Dhodh",
  "Dhps",
  "Dhrs1",
  "Dhrs11",
  "Dhrs13",
  "Dhrs2",
  "Dhrs3",
  "Dhrs4",
  "Dhrs7",
  "Dhrs7b",
  "Dhrs7c",
  "Dhrs9",
  "Dhtkd1",
  "Dhx15",
  "Dhx16",
  "Dhx29",
  "Dhx30",
  "Dhx32",
  "Dhx33",
  "Dhx34",
  "Dhx35",
  "Dhx36",
  "Dhx37",
  "Dhx38",
  "Dhx40",
  "Dhx57",
  "Dhx58",
  "Dhx8",
  "Dhx9",
  "Diaph1",
  "Diaph2",
  "Diaph3",
  "Dicer1",
  "Dido1",
  "Dio1",
  "Dio2",
  "Dio3",
  "Dio3os",
  "Dip2a",
  "Dip2b",
  "Dip2c",
  "Dipk1a",
  "Dipk1b",
  "Dipk1c",
  "Dipk2a",
  "Dipk2b",
  "Diras1",
  "Dis3",
  "Dis3l",
  "Dis3l2",
  "Disc1",
  "Disp1",
  "Disp2",
  "Disp3",
  "Dixdc1",
  "Dkc1",
  "Dkk1",
  "Dkk2",
  "Dkk3",
  "Dkk4",
  "Dkkl1",
  "Dlat",
  "Dlc1",
  "Dld",
  "Dlec1",
  "Dleu2",
  "Dleu7",
  "Dlg1",
  "Dlg2",
  "Dlg3",
  "Dlg4",
  "Dlg5",
  "Dlgap1",
  "Dlgap2",
  "Dlgap3",
  "Dlgap4",
  "Dlgap5",
  "Dlk1",
  "Dlk2",
  "Dll1",
  "Dll3",
  "Dll4",
  "Dlst",
  "Dlx1",
  "Dlx1as",
  "Dlx2",
  "Dlx3",
  "Dlx4",
  "Dlx4os",
  "Dlx5",
  "Dlx6",
  "Dmac1",
  "Dmac2",
  "Dmac2l",
  "Dmap1",
  "Dmbt1",
  "Dmbx1",
  "Dmc1",
  "Dmd",
  "Dmgdh",
  "Dmkn",
  "Dmp1",
  "Dmpk",
  "Dmrt1",
  "Dmrt2",
  "Dmrt3",
  "Dmrta1",
  "Dmrta2",
  "Dmrtb1",
  "Dmrtc1a",
  "Dmrtc1b",
  "Dmrtc2",
  "Dmtf1",
  "Dmtn",
  "Dmwd",
  "Dmxl1",
  "Dmxl2",
  "Dna2",
  "Dnaaf1",
  "Dnaaf3",
  "Dnaaf4",
  "Dnaaf5",
  "Dnah1",
  "Dnah10",
  "Dnah11",
  "Dnah12",
  "Dnah14",
  "Dnah17",
  "Dnah2",
  "Dnah3",
  "Dnah5",
  "Dnah6",
  "Dnah7a",
  "Dnah7b",
  "Dnah8",
  "Dnah9",
  "Dnaic1",
  "Dnaic2",
  "Dnaja1",
  "Dnaja2",
  "Dnaja3",
  "Dnaja4",
  "Dnajb1",
  "Dnajb11",
  "Dnajb12",
  "Dnajb13",
  "Dnajb14",
  "Dnajb2",
  "Dnajb3",
  "Dnajb4",
  "Dnajb5",
  "Dnajb6",
  "Dnajb7",
  "Dnajb8",
  "Dnajb9",
  "Dnajc1",
  "Dnajc10",
  "Dnajc11",
  "Dnajc12",
  "Dnajc13",
  "Dnajc14",
  "Dnajc15",
  "Dnajc16",
  "Dnajc18",
  "Dnajc19",
  "Dnajc2",
  "Dnajc21",
  "Dnajc22",
  "Dnajc24",
  "Dnajc25",
  "Dnajc27",
  "Dnajc28",
  "Dnajc3",
  "Dnajc30",
  "Dnajc4",
  "Dnajc5",
  "Dnajc5b",
  "Dnajc5g",
  "Dnajc6",
  "Dnajc7",
  "Dnajc8",
  "Dnajc9",
  "Dnal1",
  "Dnal4",
  "Dnali1",
  "Dnase1",
  "Dnase1l1",
  "Dnase1l2",
  "Dnase1l3",
  "Dnase2a",
  "Dnase2b",
  "Dnd1",
  "Dner",
  "Dnhd1",
  "Dnlz",
  "Dnm1",
  "Dnm1l",
  "Dnm2",
  "Dnm3",
  "Dnm3os",
  "Dnmbp",
  "Dnmt1",
  "Dnmt3a",
  "Dnmt3aos",
  "Dnmt3b",
  "Dnmt3bos",
  "Dnmt3l",
  "Dnpep",
  "Dnph1",
  "Dntt",
  "Dnttip1",
  "Dnttip2",
  "Doc2a",
  "Doc2b",
  "Doc2g",
  "Dock1",
  "Dock10",
  "Dock11",
  "Dock2",
  "Dock3",
  "Dock4",
  "Dock5",
  "Dock6",
  "Dock7",
  "Dock8",
  "Dock9",
  "Dohh",
  "Dok1",
  "Dok2",
  "Dok3",
  "Dok4",
  "Dok5",
  "Dok6",
  "Dok7",
  "Dolk",
  "Dolpp1",
  "Donson",
  "Dop1a",
  "Dop1b",
  "Dot1l",
  "Doxl1",
  "Doxl2",
  "Dpagt1",
  "Dpep1",
  "Dpep3",
  "Dpf1",
  "Dpf2",
  "Dpf3",
  "Dph1",
  "Dph2",
  "Dph3",
  "Dph5",
  "Dph6",
  "Dph7",
  "Dpm1",
  "Dpm2",
  "Dpm3",
  "Dpp10",
  "Dpp3",
  "Dpp4",
  "Dpp6",
  "Dpp7",
  "Dpp8",
  "Dpp9",
  "Dppa1",
  "Dppa2",
  "Dppa3",
  "Dppa4",
  "Dppa5a",
  "Dpt",
  "Dpy19l1",
  "Dpy19l2",
  "Dpy19l3",
  "Dpy19l4",
  "Dpy30",
  "Dpyd",
  "Dpys",
  "Dpysl2",
  "Dpysl3",
  "Dpysl4",
  "Dpysl5",
  "Dqx1",
  "Dr1",
  "Dram1",
  "Drap1",
  "Draxin",
  "Drc1",
  "Drc3",
  "Drc7",
  "Drd1",
  "Drd2",
  "Drd3",
  "Drd4",
  "Drd5",
  "Drg1",
  "Drg2",
  "Drosha",
  "Drp2",
  "Dsc1",
  "Dsc2",
  "Dsc3",
  "Dscam",
  "Dscaml1",
  "Dscc1",
  "Dse",
  "Dsel",
  "Dsg1a",
  "Dsg1b",
  "Dsg1c",
  "Dsg2",
  "Dsg3",
  "Dsg4",
  "Dsn1",
  "Dsp",
  "Dspp",
  "Dst",
  "Dstn",
  "Dstyk",
  "Dtd1",
  "Dtd2",
  "Dtl",
  "Dtna",
  "Dtnb",
  "Dtnbp1",
  "Dtwd1",
  "Dtwd2",
  "Dtx1",
  "Dtx2",
  "Dtx3",
  "Dtx3l",
  "Dtx4",
  "Dtymk",
  "Dubr",
  "Duox1",
  "Duox2",
  "Duoxa1",
  "Duoxa2",
  "Dupd1",
  "Dus1l",
  "Dus2",
  "Dus3l",
  "Dus4l",
  "Dusp1",
  "Dusp10",
  "Dusp11",
  "Dusp12",
  "Dusp13",
  "Dusp14",
  "Dusp15",
  "Dusp16",
  "Dusp18",
  "Dusp19",
  "Dusp2",
  "Dusp21",
  "Dusp22",
  "Dusp23",
  "Dusp26",
  "Dusp27",
  "Dusp28",
  "Dusp3",
  "Dusp4",
  "Dusp5",
  "Dusp6",
  "Dusp7",
  "Dusp8",
  "Dusp9",
  "Dut",
  "Dvl1",
  "Dvl2",
  "Dvl3",
  "Dxo",
  "Dydc1",
  "Dydc2",
  "Dym",
  "Dynap",
  "Dync1h1",
  "Dync1i1",
  "Dync1i2",
  "Dync1li1",
  "Dync1li2",
  "Dync2h1",
  "Dync2li1",
  "Dynll1",
  "Dynll2",
  "Dynlrb2",
  "Dynlt1c",
  "Dynlt3",
  "Dyrk1a",
  "Dyrk1b",
  "Dyrk2",
  "Dyrk3",
  "Dyrk4",
  "Dysf",
  "Dytn",
  "Dzank1",
  "Dzip1",
  "Dzip3",
  "E030013I19Rik",
  "E030018B13Rik",
  "E030025P04Rik",
  "E030030I06Rik",
  "E030042O20Rik",
  "E030044B06Rik",
  "E130018N17Rik",
  "E130018O15Rik",
  "E130101E03Rik",
  "E130102H24Rik",
  "E130114P18Rik",
  "E130116L18Rik",
  "E130119H09Rik",
  "E130202H07Rik",
  "E130218I03Rik",
  "E130304I02Rik",
  "E130307A14Rik",
  "E130308A19Rik",
  "E130309D02Rik",
  "E130311K13Rik",
  "E230001N04Rik",
  "E230016K23Rik",
  "E230016M11Rik",
  "E230025N22Rik",
  "E230029C05Rik",
  "E230032D23Rik",
  "E2f1",
  "E2f2",
  "E2f3",
  "E2f4",
  "E2f5",
  "E2f6",
  "E2f7",
  "E2f8",
  "E330011O21Rik",
  "E330012B07Rik",
  "E330013P04Rik",
  "E330017L17Rik",
  "E330018M18Rik",
  "E330020D12Rik",
  "E330021D16Rik",
  "E330032C10Rik",
  "E330034G19Rik",
  "E430018J23Rik",
  "E4f1",
  "E530011L22Rik",
  "ENSMUSG00000000001",
  "ENSMUSG00000000003",
  "ENSMUSG00000000028",
  "ENSMUSG00000000031",
  "ENSMUSG00000000037",
  "ENSMUSG00000000049",
  "ENSMUSG00000000056",
  "ENSMUSG00000000058",
  "ENSMUSG00000000078",
  "ENSMUSG00000000085",
  "ENSMUSG00000000088",
  "ENSMUSG00000000093",
  "ENSMUSG00000000094",
  "ENSMUSG00000000103",
  "ENSMUSG00000000120",
  "ENSMUSG00000000125",
  "ENSMUSG00000000126",
  "ENSMUSG00000000127",
  "ENSMUSG00000000131",
  "ENSMUSG00000000134",
  "ENSMUSG00000000142",
  "ENSMUSG00000000148",
  "ENSMUSG00000000149",
  "ENSMUSG00000000154",
  "ENSMUSG00000000157",
  "ENSMUSG00000000159",
  "ENSMUSG00000000167",
  "ENSMUSG00000000168",
  "ENSMUSG00000000171",
  "ENSMUSG00000000182",
  "ENSMUSG00000000183",
  "ENSMUSG00000000184",
  "ENSMUSG00000000194",
  "ENSMUSG00000000197",
  "ENSMUSG00000000202",
  "ENSMUSG00000000204",
  "ENSMUSG00000000214",
  "ENSMUSG00000000215",
  "ENSMUSG00000000216",
  "ENSMUSG00000000223",
  "ENSMUSG00000000244",
  "ENSMUSG00000000247",
  "ENSMUSG00000000248",
  "ENSMUSG00000000253",
  "ENSMUSG00000000263",
  "ENSMUSG00000000266",
  "ENSMUSG00000000275",
  "ENSMUSG00000000276",
  "ENSMUSG00000000278",
  "ENSMUSG00000000282",
  "ENSMUSG00000000290",
  "ENSMUSG00000000295",
  "ENSMUSG00000000296",
  "ENSMUSG00000000301",
  "ENSMUSG00000000303",
  "ENSMUSG00000000305",
  "ENSMUSG00000000308",
  "ENSMUSG00000000317",
  "ENSMUSG00000000318",
  "ENSMUSG00000000320",
  "ENSMUSG00000000325",
  "ENSMUSG00000000326",
  "ENSMUSG00000000340",
  "ENSMUSG00000000346",
  "ENSMUSG00000000359",
  "ENSMUSG00000000365",
  "ENSMUSG00000000374",
  "ENSMUSG00000000378",
  "ENSMUSG00000000381",
  "ENSMUSG00000000384",
  "ENSMUSG00000000385",
  "ENSMUSG00000000392",
  "ENSMUSG00000000394",
  "ENSMUSG00000000399",
  "ENSMUSG00000000402",
  "ENSMUSG00000000409",
  "ENSMUSG00000000411",
  "ENSMUSG00000000416",
  "ENSMUSG00000000420",
  "ENSMUSG00000000435",
  "ENSMUSG00000000439",
  "ENSMUSG00000000440",
  "ENSMUSG00000000441",
  "ENSMUSG00000000486",
  "ENSMUSG00000000489",
  "ENSMUSG00000000530",
  "ENSMUSG00000000531",
  "ENSMUSG00000000532",
  "ENSMUSG00000000538",
  "ENSMUSG00000000544",
  "ENSMUSG00000000552",
  "ENSMUSG00000000555",
  "ENSMUSG00000000560",
  "ENSMUSG00000000561",
  "ENSMUSG00000000562",
  "ENSMUSG00000000563",
  "ENSMUSG00000000567",
  "ENSMUSG00000000568",
  "ENSMUSG00000000579",
  "ENSMUSG00000000581",
  "ENSMUSG00000000594",
  "ENSMUSG00000000600",
  "ENSMUSG00000000605",
  "ENSMUSG00000000606",
  "ENSMUSG00000000617",
  "ENSMUSG00000000627",
  "ENSMUSG00000000628",
  "ENSMUSG00000000631",
  "ENSMUSG00000000632",
  "ENSMUSG00000000673",
  "ENSMUSG00000000682",
  "ENSMUSG00000000686",
  "ENSMUSG00000000690",
  "ENSMUSG00000000693",
  "ENSMUSG00000000701",
  "ENSMUSG00000000706",
  "ENSMUSG00000000708",
  "ENSMUSG00000000711",
  "ENSMUSG00000000724",
  "ENSMUSG00000000730",
  "ENSMUSG00000000731",
  "ENSMUSG00000000732",
  "ENSMUSG00000000738",
  "ENSMUSG00000000739",
  "ENSMUSG00000000740",
  "ENSMUSG00000000743",
  "ENSMUSG00000000751",
  "ENSMUSG00000000753",
  "ENSMUSG00000000759",
  "ENSMUSG00000000766",
  "ENSMUSG00000000776",
  "ENSMUSG00000000782",
  "ENSMUSG00000000787",
  "ENSMUSG00000000791",
  "ENSMUSG00000000792",
  "ENSMUSG00000000794",
  "ENSMUSG00000000804",
  "ENSMUSG00000000805",
  "ENSMUSG00000000811",
  "ENSMUSG00000000817",
  "ENSMUSG00000000823",
  "ENSMUSG00000000826",
  "ENSMUSG00000000827",
  "ENSMUSG00000000838",
  "ENSMUSG00000000861",
  "ENSMUSG00000000869",
  "ENSMUSG00000000876",
  "ENSMUSG00000000881",
  "ENSMUSG00000000884",
  "ENSMUSG00000000901",
  "ENSMUSG00000000902",
  "ENSMUSG00000000903",
  "ENSMUSG00000000915",
  "ENSMUSG00000000916",
  "ENSMUSG00000000934",
  "ENSMUSG00000000938",
  "ENSMUSG00000000942",
  "ENSMUSG00000000957",
  "ENSMUSG00000000958",
  "ENSMUSG00000000959",
  "ENSMUSG00000000976",
  "ENSMUSG00000000982",
  "ENSMUSG00000000983",
  "ENSMUSG00000001014",
  "ENSMUSG00000001016",
  "ENSMUSG00000001017",
  "ENSMUSG00000001018",
  "ENSMUSG00000001020",
  "ENSMUSG00000001021",
  "ENSMUSG00000001023",
  "ENSMUSG00000001025",
  "ENSMUSG00000001027",
  "ENSMUSG00000001029",
  "ENSMUSG00000001034",
  "ENSMUSG00000001036",
  "ENSMUSG00000001039",
  "ENSMUSG00000001052",
  "ENSMUSG00000001053",
  "ENSMUSG00000001054",
  "ENSMUSG00000001056",
  "ENSMUSG00000001062",
  "ENSMUSG00000001065",
  "ENSMUSG00000001076",
  "ENSMUSG00000001082",
  "ENSMUSG00000001089",
  "ENSMUSG00000001095",
  "ENSMUSG00000001098",
  "ENSMUSG00000001100",
  "ENSMUSG00000001103",
  "ENSMUSG00000001105",
  "ENSMUSG00000001119",
  "ENSMUSG00000001120",
  "ENSMUSG00000001123",
  "ENSMUSG00000001127",
  "ENSMUSG00000001128",
  "ENSMUSG00000001131",
  "ENSMUSG00000001134",
  "ENSMUSG00000001138",
  "ENSMUSG00000001143",
  "ENSMUSG00000001150",
  "ENSMUSG00000001151",
  "ENSMUSG00000001155",
  "ENSMUSG00000001156",
  "ENSMUSG00000001157",
  "ENSMUSG00000001158",
  "ENSMUSG00000001166",
  "ENSMUSG00000001168",
  "ENSMUSG00000001173",
  "ENSMUSG00000001175",
  "ENSMUSG00000001211",
  "ENSMUSG00000001225",
  "ENSMUSG00000001227",
  "ENSMUSG00000001228",
  "ENSMUSG00000001229",
  "ENSMUSG00000001240",
  "ENSMUSG00000001247",
  "ENSMUSG00000001248",
  "ENSMUSG00000001249",
  "ENSMUSG00000001260",
  "ENSMUSG00000001270",
  "ENSMUSG00000001280",
  "ENSMUSG00000001281",
  "ENSMUSG00000001285",
  "ENSMUSG00000001288",
  "ENSMUSG00000001300",
  "ENSMUSG00000001305",
  "ENSMUSG00000001313",
  "ENSMUSG00000001323",
  "ENSMUSG00000001333",
  "ENSMUSG00000001334",
  "ENSMUSG00000001348",
  "ENSMUSG00000001349",
  "ENSMUSG00000001366",
  "ENSMUSG00000001376",
  "ENSMUSG00000001380",
  "ENSMUSG00000001383",
  "ENSMUSG00000001403",
  "ENSMUSG00000001415",
  "ENSMUSG00000001418",
  "ENSMUSG00000001419",
  "ENSMUSG00000001420",
  "ENSMUSG00000001424",
  "ENSMUSG00000001435",
  "ENSMUSG00000001436",
  "ENSMUSG00000001440",
  "ENSMUSG00000001441",
  "ENSMUSG00000001444",
  "ENSMUSG00000001445",
  "ENSMUSG00000001467",
  "ENSMUSG00000001472",
  "ENSMUSG00000001473",
  "ENSMUSG00000001482",
  "ENSMUSG00000001493",
  "ENSMUSG00000001494",
  "ENSMUSG00000001496",
  "ENSMUSG00000001497",
  "ENSMUSG00000001504",
  "ENSMUSG00000001506",
  "ENSMUSG00000001507",
  "ENSMUSG00000001508",
  "ENSMUSG00000001510",
  "ENSMUSG00000001517",
  "ENSMUSG00000001518",
  "ENSMUSG00000001520",
  "ENSMUSG00000001521",
  "ENSMUSG00000001524",
  "ENSMUSG00000001525",
  "ENSMUSG00000001542",
  "ENSMUSG00000001552",
  "ENSMUSG00000001558",
  "ENSMUSG00000001566",
  "ENSMUSG00000001569",
  "ENSMUSG00000001576",
  "ENSMUSG00000001583",
  "ENSMUSG00000001588",
  "ENSMUSG00000001604",
  "ENSMUSG00000001622",
  "ENSMUSG00000001627",
  "ENSMUSG00000001630",
  "ENSMUSG00000001632",
  "ENSMUSG00000001642",
  "ENSMUSG00000001655",
  "ENSMUSG00000001656",
  "ENSMUSG00000001657",
  "ENSMUSG00000001661",
  "ENSMUSG00000001663",
  "ENSMUSG00000001665",
  "ENSMUSG00000001666",
  "ENSMUSG00000001670",
  "ENSMUSG00000001672",
  "ENSMUSG00000001687",
  "ENSMUSG00000001700",
  "ENSMUSG00000001707",
  "ENSMUSG00000001729",
  "ENSMUSG00000001739",
  "ENSMUSG00000001741",
  "ENSMUSG00000001750",
  "ENSMUSG00000001751",
  "ENSMUSG00000001755",
  "ENSMUSG00000001761",
  "ENSMUSG00000001763",
  "ENSMUSG00000001767",
  "ENSMUSG00000001768",
  "ENSMUSG00000001773",
  "ENSMUSG00000001774",
  "ENSMUSG00000001783",
  "ENSMUSG00000001785",
  "ENSMUSG00000001786",
  "ENSMUSG00000001794",
  "ENSMUSG00000001802",
  "ENSMUSG00000001804",
  "ENSMUSG00000001815",
  "ENSMUSG00000001819",
  "ENSMUSG00000001823",
  "ENSMUSG00000001827",
  "ENSMUSG00000001829",
  "ENSMUSG00000001833",
  "ENSMUSG00000001844",
  "ENSMUSG00000001847",
  "ENSMUSG00000001855",
  "ENSMUSG00000001864",
  "ENSMUSG00000001865",
  "ENSMUSG00000001870",
  "ENSMUSG00000001891",
  "ENSMUSG00000001901",
  "ENSMUSG00000001909",
  "ENSMUSG00000001910",
  "ENSMUSG00000001911",
  "ENSMUSG00000001918",
  "ENSMUSG00000001924",
  "ENSMUSG00000001930",
  "ENSMUSG00000001942",
  "ENSMUSG00000001943",
  "ENSMUSG00000001946",
  "ENSMUSG00000001948",
  "ENSMUSG00000001962",
  "ENSMUSG00000001964",
  "ENSMUSG00000001983",
  "ENSMUSG00000001985",
  "ENSMUSG00000001986",
  "ENSMUSG00000001988",
  "ENSMUSG00000001995",
  "ENSMUSG00000001998",
  "ENSMUSG00000001999",
  "ENSMUSG00000002006",
  "ENSMUSG00000002007",
  "ENSMUSG00000002010",
  "ENSMUSG00000002012",
  "ENSMUSG00000002014",
  "ENSMUSG00000002015",
  "ENSMUSG00000002017",
  "ENSMUSG00000002020",
  "ENSMUSG00000002028",
  "ENSMUSG00000002031",
  "ENSMUSG00000002032",
  "ENSMUSG00000002033",
  "ENSMUSG00000002043",
  "ENSMUSG00000002052",
  "ENSMUSG00000002055",
  "ENSMUSG00000002057",
  "ENSMUSG00000002058",
  "ENSMUSG00000002059",
  "ENSMUSG00000002064",
  "ENSMUSG00000002068",
  "ENSMUSG00000002076",
  "ENSMUSG00000002083",
  "ENSMUSG00000002100",
  "ENSMUSG00000002102",
  "ENSMUSG00000002103",
  "ENSMUSG00000002104",
  "ENSMUSG00000002105",
  "ENSMUSG00000002107",
  "ENSMUSG00000002108",
  "ENSMUSG00000002109",
  "ENSMUSG00000002111",
  "ENSMUSG00000002129",
  "ENSMUSG00000002147",
  "ENSMUSG00000002204",
  "ENSMUSG00000002205",
  "ENSMUSG00000002210",
  "ENSMUSG00000002221",
  "ENSMUSG00000002222",
  "ENSMUSG00000002227",
  "ENSMUSG00000002228",
  "ENSMUSG00000002233",
  "ENSMUSG00000002240",
  "ENSMUSG00000002249",
  "ENSMUSG00000002250",
  "ENSMUSG00000002257",
  "ENSMUSG00000002265",
  "ENSMUSG00000002266",
  "ENSMUSG00000002274",
  "ENSMUSG00000002279",
  "ENSMUSG00000002280",
  "ENSMUSG00000002289",
  "ENSMUSG00000002297",
  "ENSMUSG00000002307",
  "ENSMUSG00000002308",
  "ENSMUSG00000002319",
  "ENSMUSG00000002324",
  "ENSMUSG00000002325",
  "ENSMUSG00000002326",
  "ENSMUSG00000002329",
  "ENSMUSG00000002332",
  "ENSMUSG00000002341",
  "ENSMUSG00000002342",
  "ENSMUSG00000002343",
  "ENSMUSG00000002345",
  "ENSMUSG00000002346",
  "ENSMUSG00000002365",
  "ENSMUSG00000002372",
  "ENSMUSG00000002379",
  "ENSMUSG00000002384",
  "ENSMUSG00000002393",
  "ENSMUSG00000002395",
  "ENSMUSG00000002396",
  "ENSMUSG00000002409",
  "ENSMUSG00000002413",
  "ENSMUSG00000002416",
  "ENSMUSG00000002428",
  "ENSMUSG00000002455",
  "ENSMUSG00000002458",
  "ENSMUSG00000002459",
  "ENSMUSG00000002475",
  "ENSMUSG00000002477",
  "ENSMUSG00000002486",
  "ENSMUSG00000002489",
  "ENSMUSG00000002496",
  "ENSMUSG00000002500",
  "ENSMUSG00000002504",
  "ENSMUSG00000002524",
  "ENSMUSG00000002546",
  "ENSMUSG00000002550",
  "ENSMUSG00000002565",
  "ENSMUSG00000002578",
  "ENSMUSG00000002580",
  "ENSMUSG00000002588",
  "ENSMUSG00000002602",
  "ENSMUSG00000002603",
  "ENSMUSG00000002608",
  "ENSMUSG00000002617",
  "ENSMUSG00000002625",
  "ENSMUSG00000002633",
  "ENSMUSG00000002635",
  "ENSMUSG00000002658",
  "ENSMUSG00000002660",
  "ENSMUSG00000002661",
  "ENSMUSG00000002664",
  "ENSMUSG00000002668",
  "ENSMUSG00000002679",
  "ENSMUSG00000002688",
  "ENSMUSG00000002699",
  "ENSMUSG00000002718",
  "ENSMUSG00000002728",
  "ENSMUSG00000002731",
  "ENSMUSG00000002732",
  "ENSMUSG00000002733",
  "ENSMUSG00000002741",
  "ENSMUSG00000002748",
  "ENSMUSG00000002763",
  "ENSMUSG00000002767",
  "ENSMUSG00000002768",
  "ENSMUSG00000002769",
  "ENSMUSG00000002771",
  "ENSMUSG00000002778",
  "ENSMUSG00000002781",
  "ENSMUSG00000002797",
  "ENSMUSG00000002799",
  "ENSMUSG00000002803",
  "ENSMUSG00000002804",
  "ENSMUSG00000002808",
  "ENSMUSG00000002812",
  "ENSMUSG00000002814",
  "ENSMUSG00000002820",
  "ENSMUSG00000002825",
  "ENSMUSG00000002831",
  "ENSMUSG00000002833",
  "ENSMUSG00000002835",
  "ENSMUSG00000002844",
  "ENSMUSG00000002845",
  "ENSMUSG00000002846",
  "ENSMUSG00000002847",
  "ENSMUSG00000002870",
  "ENSMUSG00000002871",
  "ENSMUSG00000002881",
  "ENSMUSG00000002885",
  "ENSMUSG00000002897",
  "ENSMUSG00000002900",
  "ENSMUSG00000002908",
  "ENSMUSG00000002910",
  "ENSMUSG00000002930",
  "ENSMUSG00000002944",
  "ENSMUSG00000002949",
  "ENSMUSG00000002957",
  "ENSMUSG00000002963",
  "ENSMUSG00000002968",
  "ENSMUSG00000002980",
  "ENSMUSG00000002981",
  "ENSMUSG00000002983",
  "ENSMUSG00000002984",
  "ENSMUSG00000002985",
  "ENSMUSG00000002996",
  "ENSMUSG00000002997",
  "ENSMUSG00000003031",
  "ENSMUSG00000003032",
  "ENSMUSG00000003033",
  "ENSMUSG00000003037",
  "ENSMUSG00000003039",
  "ENSMUSG00000003051",
  "ENSMUSG00000003053",
  "ENSMUSG00000003062",
  "ENSMUSG00000003068",
  "ENSMUSG00000003070",
  "ENSMUSG00000003072",
  "ENSMUSG00000003099",
  "ENSMUSG00000003119",
  "ENSMUSG00000003123",
  "ENSMUSG00000003131",
  "ENSMUSG00000003134",
  "ENSMUSG00000003135",
  "ENSMUSG00000003153",
  "ENSMUSG00000003154",
  "ENSMUSG00000003161",
  "ENSMUSG00000003166",
  "ENSMUSG00000003184",
  "ENSMUSG00000003190",
  "ENSMUSG00000003198",
  "ENSMUSG00000003199",
  "ENSMUSG00000003200",
  "ENSMUSG00000003206",
  "ENSMUSG00000003208",
  "ENSMUSG00000003226",
  "ENSMUSG00000003227",
  "ENSMUSG00000003228",
  "ENSMUSG00000003233",
  "ENSMUSG00000003234",
  "ENSMUSG00000003235",
  "ENSMUSG00000003269",
  "ENSMUSG00000003271",
  "ENSMUSG00000003273",
  "ENSMUSG00000003279",
  "ENSMUSG00000003282",
  "ENSMUSG00000003283",
  "ENSMUSG00000003299",
  "ENSMUSG00000003308",
  "ENSMUSG00000003309",
  "ENSMUSG00000003316",
  "ENSMUSG00000003341",
  "ENSMUSG00000003344",
  "ENSMUSG00000003345",
  "ENSMUSG00000003346",
  "ENSMUSG00000003348",
  "ENSMUSG00000003352",
  "ENSMUSG00000003354",
  "ENSMUSG00000003355",
  "ENSMUSG00000003360",
  "ENSMUSG00000003363",
  "ENSMUSG00000003378",
  "ENSMUSG00000003379",
  "ENSMUSG00000003380",
  "ENSMUSG00000003382",
  "ENSMUSG00000003402",
  "ENSMUSG00000003410",
  "ENSMUSG00000003411",
  "ENSMUSG00000003418",
  "ENSMUSG00000003420",
  "ENSMUSG00000003421",
  "ENSMUSG00000003423",
  "ENSMUSG00000003435",
  "ENSMUSG00000003436",
  "ENSMUSG00000003437",
  "ENSMUSG00000003438",
  "ENSMUSG00000003444",
  "ENSMUSG00000003452",
  "ENSMUSG00000003458",
  "ENSMUSG00000003464",
  "ENSMUSG00000003469",
  "ENSMUSG00000003476",
  "ENSMUSG00000003477",
  "ENSMUSG00000003484",
  "ENSMUSG00000003500",
  "ENSMUSG00000003518",
  "ENSMUSG00000003526",
  "ENSMUSG00000003527",
  "ENSMUSG00000003528",
  "ENSMUSG00000003531",
  "ENSMUSG00000003534",
  "ENSMUSG00000003541",
  "ENSMUSG00000003545",
  "ENSMUSG00000003546",
  "ENSMUSG00000003549",
  "ENSMUSG00000003555",
  "ENSMUSG00000003559",
  "ENSMUSG00000003573",
  "ENSMUSG00000003575",
  "ENSMUSG00000003581",
  "ENSMUSG00000003585",
  "ENSMUSG00000003604",
  "ENSMUSG00000003617",
  "ENSMUSG00000003623",
  "ENSMUSG00000003644",
  "ENSMUSG00000003657",
  "ENSMUSG00000003660",
  "ENSMUSG00000003662",
  "ENSMUSG00000003665",
  "ENSMUSG00000003680",
  "ENSMUSG00000003721",
  "ENSMUSG00000003731",
  "ENSMUSG00000003746",
  "ENSMUSG00000003752",
  "ENSMUSG00000003762",
  "ENSMUSG00000003778",
  "ENSMUSG00000003779",
  "ENSMUSG00000003808",
  "ENSMUSG00000003809",
  "ENSMUSG00000003810",
  "ENSMUSG00000003812",
  "ENSMUSG00000003813",
  "ENSMUSG00000003814",
  "ENSMUSG00000003824",
  "ENSMUSG00000003847",
  "ENSMUSG00000003848",
  "ENSMUSG00000003849",
  "ENSMUSG00000003863",
  "ENSMUSG00000003865",
  "ENSMUSG00000003868",
  "ENSMUSG00000003872",
  "ENSMUSG00000003873",
  "ENSMUSG00000003882",
  "ENSMUSG00000003923",
  "ENSMUSG00000003929",
  "ENSMUSG00000003934",
  "ENSMUSG00000003948",
  "ENSMUSG00000003949",
  "ENSMUSG00000003955",
  "ENSMUSG00000003970",
  "ENSMUSG00000003992",
  "ENSMUSG00000004018",
  "ENSMUSG00000004031",
  "ENSMUSG00000004032",
  "ENSMUSG00000004035",
  "ENSMUSG00000004038",
  "ENSMUSG00000004040",
  "ENSMUSG00000004043",
  "ENSMUSG00000004044",
  "ENSMUSG00000004054",
  "ENSMUSG00000004056",
  "ENSMUSG00000004069",
  "ENSMUSG00000004070",
  "ENSMUSG00000004071",
  "ENSMUSG00000004085",
  "ENSMUSG00000004096",
  "ENSMUSG00000004098",
  "ENSMUSG00000004099",
  "ENSMUSG00000004100",
  "ENSMUSG00000004110",
  "ENSMUSG00000004113",
  "ENSMUSG00000004127",
  "ENSMUSG00000004151",
  "ENSMUSG00000004187",
  "ENSMUSG00000004207",
  "ENSMUSG00000004221",
  "ENSMUSG00000004231",
  "ENSMUSG00000004233",
  "ENSMUSG00000004264",
  "ENSMUSG00000004266",
  "ENSMUSG00000004267",
  "ENSMUSG00000004268",
  "ENSMUSG00000004270",
  "ENSMUSG00000004285",
  "ENSMUSG00000004296",
  "ENSMUSG00000004317",
  "ENSMUSG00000004319",
  "ENSMUSG00000004328",
  "ENSMUSG00000004341",
  "ENSMUSG00000004344",
  "ENSMUSG00000004347",
  "ENSMUSG00000004356",
  "ENSMUSG00000004359",
  "ENSMUSG00000004360",
  "ENSMUSG00000004364",
  "ENSMUSG00000004366",
  "ENSMUSG00000004371",
  "ENSMUSG00000004383",
  "ENSMUSG00000004393",
  "ENSMUSG00000004394",
  "ENSMUSG00000004415",
  "ENSMUSG00000004446",
  "ENSMUSG00000004451",
  "ENSMUSG00000004455",
  "ENSMUSG00000004460",
  "ENSMUSG00000004462",
  "ENSMUSG00000004473",
  "ENSMUSG00000004500",
  "ENSMUSG00000004508",
  "ENSMUSG00000004530",
  "ENSMUSG00000004535",
  "ENSMUSG00000004540",
  "ENSMUSG00000004542",
  "ENSMUSG00000004552",
  "ENSMUSG00000004558",
  "ENSMUSG00000004561",
  "ENSMUSG00000004562",
  "ENSMUSG00000004565",
  "ENSMUSG00000004567",
  "ENSMUSG00000004568",
  "ENSMUSG00000004591",
  "ENSMUSG00000004609",
  "ENSMUSG00000004610",
  "ENSMUSG00000004612",
  "ENSMUSG00000004626",
  "ENSMUSG00000004630",
  "ENSMUSG00000004631",
  "ENSMUSG00000004633",
  "ENSMUSG00000004637",
  "ENSMUSG00000004642",
  "ENSMUSG00000004651",
  "ENSMUSG00000004654",
  "ENSMUSG00000004655",
  "ENSMUSG00000004661",
  "ENSMUSG00000004665",
  "ENSMUSG00000004667",
  "ENSMUSG00000004668",
  "ENSMUSG00000004677",
  "ENSMUSG00000004698",
  "ENSMUSG00000004707",
  "ENSMUSG00000004709",
  "ENSMUSG00000004730",
  "ENSMUSG00000004748",
  "ENSMUSG00000004768",
  "ENSMUSG00000004771",
  "ENSMUSG00000004788",
  "ENSMUSG00000004789",
  "ENSMUSG00000004791",
  "ENSMUSG00000004798",
  "ENSMUSG00000004814",
  "ENSMUSG00000004815",
  "ENSMUSG00000004837",
  "ENSMUSG00000004842",
  "ENSMUSG00000004843",
  "ENSMUSG00000004846",
  "ENSMUSG00000004849",
  "ENSMUSG00000004864",
  "ENSMUSG00000004865",
  "ENSMUSG00000004872",
  "ENSMUSG00000004880",
  "ENSMUSG00000004885",
  "ENSMUSG00000004891",
  "ENSMUSG00000004892",
  "ENSMUSG00000004894",
  "ENSMUSG00000004895",
  "ENSMUSG00000004896",
  "ENSMUSG00000004897",
  "ENSMUSG00000004902",
  "ENSMUSG00000004929",
  "ENSMUSG00000004931",
  "ENSMUSG00000004933",
  "ENSMUSG00000004934",
  "ENSMUSG00000004936",
  "ENSMUSG00000004937",
  "ENSMUSG00000004939",
  "ENSMUSG00000004945",
  "ENSMUSG00000004947",
  "ENSMUSG00000004948",
  "ENSMUSG00000004951",
  "ENSMUSG00000004952",
  "ENSMUSG00000004961",
  "ENSMUSG00000004980",
  "ENSMUSG00000004988",
  "ENSMUSG00000004994",
  "ENSMUSG00000004996",
  "ENSMUSG00000005034",
  "ENSMUSG00000005043",
  "ENSMUSG00000005045",
  "ENSMUSG00000005054",
  "ENSMUSG00000005057",
  "ENSMUSG00000005069",
  "ENSMUSG00000005078",
  "ENSMUSG00000005087",
  "ENSMUSG00000005089",
  "ENSMUSG00000005102",
  "ENSMUSG00000005103",
  "ENSMUSG00000005107",
  "ENSMUSG00000005124",
  "ENSMUSG00000005125",
  "ENSMUSG00000005131",
  "ENSMUSG00000005142",
  "ENSMUSG00000005148",
  "ENSMUSG00000005150",
  "ENSMUSG00000005161",
  "ENSMUSG00000005198",
  "ENSMUSG00000005202",
  "ENSMUSG00000005204",
  "ENSMUSG00000005220",
  "ENSMUSG00000005225",
  "ENSMUSG00000005233",
  "ENSMUSG00000005237",
  "ENSMUSG00000005251",
  "ENSMUSG00000005262",
  "ENSMUSG00000005267",
  "ENSMUSG00000005268",
  "ENSMUSG00000005299",
  "ENSMUSG00000005312",
  "ENSMUSG00000005320",
  "ENSMUSG00000005338",
  "ENSMUSG00000005339",
  "ENSMUSG00000005354",
  "ENSMUSG00000005355",
  "ENSMUSG00000005357",
  "ENSMUSG00000005360",
  "ENSMUSG00000005362",
  "ENSMUSG00000005364",
  "ENSMUSG00000005370",
  "ENSMUSG00000005371",
  "ENSMUSG00000005373",
  "ENSMUSG00000005374",
  "ENSMUSG00000005397",
  "ENSMUSG00000005410",
  "ENSMUSG00000005413",
  "ENSMUSG00000005417",
  "ENSMUSG00000005442",
  "ENSMUSG00000005447",
  "ENSMUSG00000005465",
  "ENSMUSG00000005469",
  "ENSMUSG00000005470",
  "ENSMUSG00000005474",
  "ENSMUSG00000005481",
  "ENSMUSG00000005483",
  "ENSMUSG00000005493",
  "ENSMUSG00000005501",
  "ENSMUSG00000005503",
  "ENSMUSG00000005505",
  "ENSMUSG00000005506",
  "ENSMUSG00000005510",
  "ENSMUSG00000005514",
  "ENSMUSG00000005533",
  "ENSMUSG00000005534",
  "ENSMUSG00000005540",
  "ENSMUSG00000005553",
  "ENSMUSG00000005566",
  "ENSMUSG00000005575",
  "ENSMUSG00000005580",
  "ENSMUSG00000005583",
  "ENSMUSG00000005609",
  "ENSMUSG00000005610",
  "ENSMUSG00000005611",
  "ENSMUSG00000005615",
  "ENSMUSG00000005621",
  "ENSMUSG00000005625",
  "ENSMUSG00000005628",
  "ENSMUSG00000005640",
  "ENSMUSG00000005649",
  "ENSMUSG00000005656",
  "ENSMUSG00000005667",
  "ENSMUSG00000005672",
  "ENSMUSG00000005674",
  "ENSMUSG00000005677",
  "ENSMUSG00000005681",
  "ENSMUSG00000005682",
  "ENSMUSG00000005683",
  "ENSMUSG00000005686",
  "ENSMUSG00000005687",
  "ENSMUSG00000005696",
  "ENSMUSG00000005698",
  "ENSMUSG00000005699",
  "ENSMUSG00000005705",
  "ENSMUSG00000005716",
  "ENSMUSG00000005718",
  "ENSMUSG00000005732",
  "ENSMUSG00000005763",
  "ENSMUSG00000005774",
  "ENSMUSG00000005779",
  "ENSMUSG00000005800",
  "ENSMUSG00000005802",
  "ENSMUSG00000005803",
  "ENSMUSG00000005804",
  "ENSMUSG00000005813",
  "ENSMUSG00000005823",
  "ENSMUSG00000005824",
  "ENSMUSG00000005836",
  "ENSMUSG00000005846",
  "ENSMUSG00000005864",
  "ENSMUSG00000005871",
  "ENSMUSG00000005873",
  "ENSMUSG00000005881",
  "ENSMUSG00000005882",
  "ENSMUSG00000005883",
  "ENSMUSG00000005886",
  "ENSMUSG00000005891",
  "ENSMUSG00000005892",
  "ENSMUSG00000005893",
  "ENSMUSG00000005897",
  "ENSMUSG00000005899",
  "ENSMUSG00000005907",
  "ENSMUSG00000005917",
  "ENSMUSG00000005936",
  "ENSMUSG00000005947",
  "ENSMUSG00000005949",
  "ENSMUSG00000005950",
  "ENSMUSG00000005951",
  "ENSMUSG00000005952",
  "ENSMUSG00000005968",
  "ENSMUSG00000005973",
  "ENSMUSG00000005980",
  "ENSMUSG00000005981",
  "ENSMUSG00000005982",
  "ENSMUSG00000005983",
  "ENSMUSG00000005986",
  "ENSMUSG00000005994",
  "ENSMUSG00000006005",
  "ENSMUSG00000006007",
  "ENSMUSG00000006010",
  "ENSMUSG00000006014",
  "ENSMUSG00000006019",
  "ENSMUSG00000006021",
  "ENSMUSG00000006024",
  "ENSMUSG00000006050",
  "ENSMUSG00000006056",
  "ENSMUSG00000006057",
  "ENSMUSG00000006058",
  "ENSMUSG00000006095",
  "ENSMUSG00000006127",
  "ENSMUSG00000006134",
  "ENSMUSG00000006143",
  "ENSMUSG00000006154",
  "ENSMUSG00000006169",
  "ENSMUSG00000006179",
  "ENSMUSG00000006191",
  "ENSMUSG00000006200",
  "ENSMUSG00000006204",
  "ENSMUSG00000006205",
  "ENSMUSG00000006216",
  "ENSMUSG00000006218",
  "ENSMUSG00000006219",
  "ENSMUSG00000006221",
  "ENSMUSG00000006235",
  "ENSMUSG00000006241",
  "ENSMUSG00000006262",
  "ENSMUSG00000006269",
  "ENSMUSG00000006270",
  "ENSMUSG00000006273",
  "ENSMUSG00000006276",
  "ENSMUSG00000006281",
  "ENSMUSG00000006288",
  "ENSMUSG00000006289",
  "ENSMUSG00000006299",
  "ENSMUSG00000006301",
  "ENSMUSG00000006304",
  "ENSMUSG00000006307",
  "ENSMUSG00000006310",
  "ENSMUSG00000006311",
  "ENSMUSG00000006313",
  "ENSMUSG00000006315",
  "ENSMUSG00000006335",
  "ENSMUSG00000006344",
  "ENSMUSG00000006345",
  "ENSMUSG00000006356",
  "ENSMUSG00000006360",
  "ENSMUSG00000006362",
  "ENSMUSG00000006369",
  "ENSMUSG00000006373",
  "ENSMUSG00000006378",
  "ENSMUSG00000006386",
  "ENSMUSG00000006389",
  "ENSMUSG00000006390",
  "ENSMUSG00000006392",
  "ENSMUSG00000006398",
  "ENSMUSG00000006403",
  "ENSMUSG00000006411",
  "ENSMUSG00000006412",
  "ENSMUSG00000006418",
  "ENSMUSG00000006423",
  "ENSMUSG00000006435",
  "ENSMUSG00000006442",
  "ENSMUSG00000006445",
  "ENSMUSG00000006456",
  "ENSMUSG00000006457",
  "ENSMUSG00000006462",
  "ENSMUSG00000006463",
  "ENSMUSG00000006464",
  "ENSMUSG00000006469",
  "ENSMUSG00000006471",
  "ENSMUSG00000006476",
  "ENSMUSG00000006488",
  "ENSMUSG00000006490",
  "ENSMUSG00000006494",
  "ENSMUSG00000006498",
  "ENSMUSG00000006517",
  "ENSMUSG00000006519",
  "ENSMUSG00000006522",
  "ENSMUSG00000006526",
  "ENSMUSG00000006527",
  "ENSMUSG00000006529",
  "ENSMUSG00000006538",
  "ENSMUSG00000006542",
  "ENSMUSG00000006546",
  "ENSMUSG00000006567",
  "ENSMUSG00000006570",
  "ENSMUSG00000006574",
  "ENSMUSG00000006575",
  "ENSMUSG00000006576",
  "ENSMUSG00000006585",
  "ENSMUSG00000006586",
  "ENSMUSG00000006587",
  "ENSMUSG00000006589",
  "ENSMUSG00000006599",
  "ENSMUSG00000006611",
  "ENSMUSG00000006641",
  "ENSMUSG00000006642",
  "ENSMUSG00000006651",
  "ENSMUSG00000006673",
  "ENSMUSG00000006675",
  "ENSMUSG00000006676",
  "ENSMUSG00000006678",
  "ENSMUSG00000006699",
  "ENSMUSG00000006705",
  "ENSMUSG00000006711",
  "ENSMUSG00000006715",
  "ENSMUSG00000006717",
  "ENSMUSG00000006720",
  "ENSMUSG00000006724",
  "ENSMUSG00000006728",
  "ENSMUSG00000006731",
  "ENSMUSG00000006732",
  "ENSMUSG00000006740",
  "ENSMUSG00000006763",
  "ENSMUSG00000006764",
  "ENSMUSG00000006777",
  "ENSMUSG00000006782",
  "ENSMUSG00000006784",
  "ENSMUSG00000006800",
  "ENSMUSG00000006818",
  "ENSMUSG00000006850",
  "ENSMUSG00000006906",
  "ENSMUSG00000006920",
  "ENSMUSG00000006930",
  "ENSMUSG00000006931",
  "ENSMUSG00000006932",
  "ENSMUSG00000006941",
  "ENSMUSG00000006948",
  "ENSMUSG00000006958",
  "ENSMUSG00000006998",
  "ENSMUSG00000007021",
  "ENSMUSG00000007029",
  "ENSMUSG00000007030",
  "ENSMUSG00000007033",
  "ENSMUSG00000007034",
  "ENSMUSG00000007035",
  "ENSMUSG00000007036",
  "ENSMUSG00000007038",
  "ENSMUSG00000007039",
  "ENSMUSG00000007041",
  "ENSMUSG00000007050",
  "ENSMUSG00000007080",
  "ENSMUSG00000007097",
  "ENSMUSG00000007107",
  "ENSMUSG00000007122",
  "ENSMUSG00000007207",
  "ENSMUSG00000007209",
  "ENSMUSG00000007216",
  "ENSMUSG00000007279",
  "ENSMUSG00000007338",
  "ENSMUSG00000007379",
  "ENSMUSG00000007411",
  "ENSMUSG00000007415",
  "ENSMUSG00000007457",
  "ENSMUSG00000007458",
  "ENSMUSG00000007476",
  "ENSMUSG00000007480",
  "ENSMUSG00000007564",
  "ENSMUSG00000007570",
  "ENSMUSG00000007589",
  "ENSMUSG00000007591",
  "ENSMUSG00000007594",
  "ENSMUSG00000007603",
  "ENSMUSG00000007610",
  "ENSMUSG00000007613",
  "ENSMUSG00000007617",
  "ENSMUSG00000007646",
  "ENSMUSG00000007655",
  "ENSMUSG00000007656",
  "ENSMUSG00000007659",
  "ENSMUSG00000007670",
  "ENSMUSG00000007682",
  "ENSMUSG00000007721",
  "ENSMUSG00000007777",
  "ENSMUSG00000007783",
  "ENSMUSG00000007805",
  "ENSMUSG00000007812",
  "ENSMUSG00000007815",
  "ENSMUSG00000007817",
  "ENSMUSG00000007827",
  "ENSMUSG00000007833",
  "ENSMUSG00000007836",
  "ENSMUSG00000007837",
  "ENSMUSG00000007850",
  "ENSMUSG00000007867",
  "ENSMUSG00000007872",
  "ENSMUSG00000007877",
  "ENSMUSG00000007880",
  "ENSMUSG00000007888",
  "ENSMUSG00000007891",
  "ENSMUSG00000007907",
  "ENSMUSG00000007908",
  "ENSMUSG00000007944",
  "ENSMUSG00000007950",
  "ENSMUSG00000007987",
  "ENSMUSG00000007989",
  "ENSMUSG00000008028",
  "ENSMUSG00000008035",
  "ENSMUSG00000008036",
  "ENSMUSG00000008090",
  "ENSMUSG00000008129",
  "ENSMUSG00000008136",
  "ENSMUSG00000008140",
  "ENSMUSG00000008153",
  "ENSMUSG00000008167",
  "ENSMUSG00000008193",
  "ENSMUSG00000008200",
  "ENSMUSG00000008206",
  "ENSMUSG00000008226",
  "ENSMUSG00000008301",
  "ENSMUSG00000008305",
  "ENSMUSG00000008307",
  "ENSMUSG00000008318",
  "ENSMUSG00000008333",
  "ENSMUSG00000008348",
  "ENSMUSG00000008373",
  "ENSMUSG00000008384",
  "ENSMUSG00000008393",
  "ENSMUSG00000008398",
  "ENSMUSG00000008429",
  "ENSMUSG00000008435",
  "ENSMUSG00000008438",
  "ENSMUSG00000008461",
  "ENSMUSG00000008475",
  "ENSMUSG00000008482",
  "ENSMUSG00000008489",
  "ENSMUSG00000008496",
  "ENSMUSG00000008540",
  "ENSMUSG00000008575",
  "ENSMUSG00000008590",
  "ENSMUSG00000008601",
  "ENSMUSG00000008604",
  "ENSMUSG00000008658",
  "ENSMUSG00000008683",
  "ENSMUSG00000008690",
  "ENSMUSG00000008730",
  "ENSMUSG00000008734",
  "ENSMUSG00000008763",
  "ENSMUSG00000008789",
  "ENSMUSG00000008813",
  "ENSMUSG00000008822",
  "ENSMUSG00000008843",
  "ENSMUSG00000008845",
  "ENSMUSG00000008855",
  "ENSMUSG00000008859",
  "ENSMUSG00000008874",
  "ENSMUSG00000008892",
  "ENSMUSG00000008932",
  "ENSMUSG00000008976",
  "ENSMUSG00000008999",
  "ENSMUSG00000009013",
  "ENSMUSG00000009030",
  "ENSMUSG00000009035",
  "ENSMUSG00000009047",
  "ENSMUSG00000009070",
  "ENSMUSG00000009073",
  "ENSMUSG00000009075",
  "ENSMUSG00000009076",
  "ENSMUSG00000009079",
  "ENSMUSG00000009090",
  "ENSMUSG00000009092",
  "ENSMUSG00000009093",
  "ENSMUSG00000009097",
  "ENSMUSG00000009108",
  "ENSMUSG00000009112",
  "ENSMUSG00000009114",
  "ENSMUSG00000009115",
  "ENSMUSG00000009145",
  "ENSMUSG00000009185",
  "ENSMUSG00000009207",
  "ENSMUSG00000009210",
  "ENSMUSG00000009214",
  "ENSMUSG00000009216",
  "ENSMUSG00000009246",
  "ENSMUSG00000009248",
  "ENSMUSG00000009281",
  "ENSMUSG00000009291",
  "ENSMUSG00000009292",
  "ENSMUSG00000009293",
  "ENSMUSG00000009350",
  "ENSMUSG00000009356",
  "ENSMUSG00000009376",
  "ENSMUSG00000009378",
  "ENSMUSG00000009394",
  "ENSMUSG00000009406",
  "ENSMUSG00000009418",
  "ENSMUSG00000009470",
  "ENSMUSG00000009487",
  "ENSMUSG00000009535",
  "ENSMUSG00000009545",
  "ENSMUSG00000009549",
  "ENSMUSG00000009551",
  "ENSMUSG00000009555",
  "ENSMUSG00000009563",
  "ENSMUSG00000009566",
  "ENSMUSG00000009569",
  "ENSMUSG00000009575",
  "ENSMUSG00000009580",
  "ENSMUSG00000009585",
  "ENSMUSG00000009588",
  "ENSMUSG00000009596",
  "ENSMUSG00000009614",
  "ENSMUSG00000009621",
  "ENSMUSG00000009628",
  "ENSMUSG00000009633",
  "ENSMUSG00000009640",
  "ENSMUSG00000009646",
  "ENSMUSG00000009647",
  "ENSMUSG00000009654",
  "ENSMUSG00000009670",
  "ENSMUSG00000009681",
  "ENSMUSG00000009687",
  "ENSMUSG00000009731",
  "ENSMUSG00000009733",
  "ENSMUSG00000009734",
  "ENSMUSG00000009739",
  "ENSMUSG00000009741",
  "ENSMUSG00000009828",
  "ENSMUSG00000009863",
  "ENSMUSG00000009876",
  "ENSMUSG00000009894",
  "ENSMUSG00000009900",
  "ENSMUSG00000009905",
  "ENSMUSG00000009907",
  "ENSMUSG00000009927",
  "ENSMUSG00000009941",
  "ENSMUSG00000009995",
  "ENSMUSG00000010021",
  "ENSMUSG00000010025",
  "ENSMUSG00000010044",
  "ENSMUSG00000010045",
  "ENSMUSG00000010047",
  "ENSMUSG00000010048",
  "ENSMUSG00000010051",
  "ENSMUSG00000010054",
  "ENSMUSG00000010057",
  "ENSMUSG00000010064",
  "ENSMUSG00000010066",
  "ENSMUSG00000010067",
  "ENSMUSG00000010080",
  "ENSMUSG00000010086",
  "ENSMUSG00000010095",
  "ENSMUSG00000010097",
  "ENSMUSG00000010110",
  "ENSMUSG00000010122",
  "ENSMUSG00000010136",
  "ENSMUSG00000010142",
  "ENSMUSG00000010175",
  "ENSMUSG00000010277",
  "ENSMUSG00000010290",
  "ENSMUSG00000010307",
  "ENSMUSG00000010311",
  "ENSMUSG00000010342",
  "ENSMUSG00000010358",
  "ENSMUSG00000010362",
  "ENSMUSG00000010376",
  "ENSMUSG00000010392",
  "ENSMUSG00000010406",
  "ENSMUSG00000010435",
  "ENSMUSG00000010453",
  "ENSMUSG00000010461",
  "ENSMUSG00000010476",
  "ENSMUSG00000010517",
  "ENSMUSG00000010529",
  "ENSMUSG00000010538",
  "ENSMUSG00000010554",
  "ENSMUSG00000010592",
  "ENSMUSG00000010601",
  "ENSMUSG00000010607",
  "ENSMUSG00000010608",
  "ENSMUSG00000010609",
  "ENSMUSG00000010651",
  "ENSMUSG00000010660",
  "ENSMUSG00000010663",
  "ENSMUSG00000010721",
  "ENSMUSG00000010751",
  "ENSMUSG00000010755",
  "ENSMUSG00000010760",
  "ENSMUSG00000010796",

  "ENSMUSG00000010797",
  "ENSMUSG00000010803",
  "ENSMUSG00000010825",
  "ENSMUSG00000010830",
  "ENSMUSG00000010841",
  "ENSMUSG00000010911",
  "ENSMUSG00000010914",
  "ENSMUSG00000010936",
  "ENSMUSG00000011008",
  "ENSMUSG00000011034",
  "ENSMUSG00000011096",
  "ENSMUSG00000011114",
  "ENSMUSG00000011118",
  "ENSMUSG00000011148",
  "ENSMUSG00000011154",
  "ENSMUSG00000011158",
  "ENSMUSG00000011171",
  "ENSMUSG00000011179",
  "ENSMUSG00000011254",
  "ENSMUSG00000011256",
  "ENSMUSG00000011263",
  "ENSMUSG00000011267",
  "ENSMUSG00000011305",
  "ENSMUSG00000011306",
  "ENSMUSG00000011349",
  "ENSMUSG00000011350",
  "ENSMUSG00000011382",
  "ENSMUSG00000011427",
  "ENSMUSG00000011463",
  "ENSMUSG00000011486",
  "ENSMUSG00000011589",
  "ENSMUSG00000011632",
  "ENSMUSG00000011658",
  "ENSMUSG00000011751",
  "ENSMUSG00000011752",
  "ENSMUSG00000011831",
  "ENSMUSG00000011832",
  "ENSMUSG00000011837",
  "ENSMUSG00000011877",
  "ENSMUSG00000011884",
  "ENSMUSG00000011958",
  "ENSMUSG00000011960",
  "ENSMUSG00000012017",
  "ENSMUSG00000012076",
  "ENSMUSG00000012114",
  "ENSMUSG00000012117",
  "ENSMUSG00000012123",
  "ENSMUSG00000012126",
  "ENSMUSG00000012187",
  "ENSMUSG00000012211",
  "ENSMUSG00000012282",
  "ENSMUSG00000012296",
  "ENSMUSG00000012350",
  "ENSMUSG00000012396",
  "ENSMUSG00000012405",
  "ENSMUSG00000012422",
  "ENSMUSG00000012428",
  "ENSMUSG00000012429",
  "ENSMUSG00000012443",
  "ENSMUSG00000012483",
  "ENSMUSG00000012519",
  "ENSMUSG00000012520",
  "ENSMUSG00000012535",
  "ENSMUSG00000012609",
  "ENSMUSG00000012640",
  "ENSMUSG00000012705",
  "ENSMUSG00000012777",
  "ENSMUSG00000012819",
  "ENSMUSG00000012848",
  "ENSMUSG00000012889",
  "ENSMUSG00000013033",
  "ENSMUSG00000013076",
  "ENSMUSG00000013083",
  "ENSMUSG00000013089",
  "ENSMUSG00000013091",
  "ENSMUSG00000013150",
  "ENSMUSG00000013155",
  "ENSMUSG00000013158",
  "ENSMUSG00000013160",
  "ENSMUSG00000013236",
  "ENSMUSG00000013275",
  "ENSMUSG00000013338",
  "ENSMUSG00000013353",
  "ENSMUSG00000013367",
  "ENSMUSG00000013415",
  "ENSMUSG00000013419",
  "ENSMUSG00000013465",
  "ENSMUSG00000013483",
  "ENSMUSG00000013495",
  "ENSMUSG00000013523",
  "ENSMUSG00000013539",
  "ENSMUSG00000013562",
  "ENSMUSG00000013584",
  "ENSMUSG00000013593",
  "ENSMUSG00000013611",
  "ENSMUSG00000013622",
  "ENSMUSG00000013629",
  "ENSMUSG00000013643",
  "ENSMUSG00000013646",
  "ENSMUSG00000013653",
  "ENSMUSG00000013662",
  "ENSMUSG00000013663",
  "ENSMUSG00000013668",
  "ENSMUSG00000013698",
  "ENSMUSG00000013701",
  "ENSMUSG00000013707",
  "ENSMUSG00000013736",
  "ENSMUSG00000013766",
  "ENSMUSG00000013787",
  "ENSMUSG00000013822",
  "ENSMUSG00000013833",
  "ENSMUSG00000013846",
  "ENSMUSG00000013858",
  "ENSMUSG00000013878",
  "ENSMUSG00000013921",
  "ENSMUSG00000013928",
  "ENSMUSG00000013936",
  "ENSMUSG00000013973",
  "ENSMUSG00000013974",
  "ENSMUSG00000013997",
  "ENSMUSG00000014030",
  "ENSMUSG00000014039",
  "ENSMUSG00000014074",
  "ENSMUSG00000014075",
  "ENSMUSG00000014077",
  "ENSMUSG00000014104",
  "ENSMUSG00000014158",
  "ENSMUSG00000014164",
  "ENSMUSG00000014177",
  "ENSMUSG00000014195",
  "ENSMUSG00000014198",
  "ENSMUSG00000014226",
  "ENSMUSG00000014232",
  "ENSMUSG00000014243",
  "ENSMUSG00000014245",
  "ENSMUSG00000014294",
  "ENSMUSG00000014301",
  "ENSMUSG00000014303",
  "ENSMUSG00000014313",
  "ENSMUSG00000014329",
  "ENSMUSG00000014349",
  "ENSMUSG00000014351",
  "ENSMUSG00000014353",
  "ENSMUSG00000014355",
  "ENSMUSG00000014361",
  "ENSMUSG00000014418",
  "ENSMUSG00000014426",
  "ENSMUSG00000014444",
  "ENSMUSG00000014453",
  "ENSMUSG00000014470",
  "ENSMUSG00000014496",
  "ENSMUSG00000014498",
  "ENSMUSG00000014503",
  "ENSMUSG00000014504",
  "ENSMUSG00000014529",
  "ENSMUSG00000014542",
  "ENSMUSG00000014543",
  "ENSMUSG00000014547",
  "ENSMUSG00000014550",
  "ENSMUSG00000014551",
  "ENSMUSG00000014554",
  "ENSMUSG00000014592",
  "ENSMUSG00000014599",
  "ENSMUSG00000014601",
  "ENSMUSG00000014602",
  "ENSMUSG00000014603",
  "ENSMUSG00000014606",
  "ENSMUSG00000014609",
  "ENSMUSG00000014633",
  "ENSMUSG00000014668",
  "ENSMUSG00000014686",
  "ENSMUSG00000014704",
  "ENSMUSG00000014725",
  "ENSMUSG00000014747",
  "ENSMUSG00000014748",
  "ENSMUSG00000014763",
  "ENSMUSG00000014767",
  "ENSMUSG00000014769",
  "ENSMUSG00000014771",
  "ENSMUSG00000014773",
  "ENSMUSG00000014776",
  "ENSMUSG00000014778",
  "ENSMUSG00000014782",
  "ENSMUSG00000014786",
  "ENSMUSG00000014791",
  "ENSMUSG00000014813",
  "ENSMUSG00000014837",
  "ENSMUSG00000014846",
  "ENSMUSG00000014850",
  "ENSMUSG00000014852",
  "ENSMUSG00000014856",
  "ENSMUSG00000014859",
  "ENSMUSG00000014867",
  "ENSMUSG00000014873",
  "ENSMUSG00000014905",
  "ENSMUSG00000014907",
  "ENSMUSG00000014932",
  "ENSMUSG00000014956",
  "ENSMUSG00000014959",
  "ENSMUSG00000014980",
  "ENSMUSG00000015001",
  "ENSMUSG00000015002",
  "ENSMUSG00000015013",
  "ENSMUSG00000015016",
  "ENSMUSG00000015023",
  "ENSMUSG00000015027",
  "ENSMUSG00000015053",
  "ENSMUSG00000015083",
  "ENSMUSG00000015085",
  "ENSMUSG00000015087",
  "ENSMUSG00000015090",
  "ENSMUSG00000015092",
  "ENSMUSG00000015093",
  "ENSMUSG00000015094",
  "ENSMUSG00000015095",
  "ENSMUSG00000015112",
  "ENSMUSG00000015120",
  "ENSMUSG00000015126",
  "ENSMUSG00000015127",
  "ENSMUSG00000015133",
  "ENSMUSG00000015134",
  "ENSMUSG00000015143",
  "ENSMUSG00000015149",
  "ENSMUSG00000015165",
  "ENSMUSG00000015176",
  "ENSMUSG00000015189",
  "ENSMUSG00000015202",
  "ENSMUSG00000015214",
  "ENSMUSG00000015217",
  "ENSMUSG00000015222",
  "ENSMUSG00000015224",
  "ENSMUSG00000015242",
  "ENSMUSG00000015243",
  "ENSMUSG00000015247",
  "ENSMUSG00000015289",
  "ENSMUSG00000015290",
  "ENSMUSG00000015291",
  "ENSMUSG00000015305",
  "ENSMUSG00000015312",
  "ENSMUSG00000015314",
  "ENSMUSG00000015316",
  "ENSMUSG00000015335",
  "ENSMUSG00000015337",
  "ENSMUSG00000015340",
  "ENSMUSG00000015341",
  "ENSMUSG00000015342",
  "ENSMUSG00000015354",
  "ENSMUSG00000015355",
  "ENSMUSG00000015357",
  "ENSMUSG00000015365",
  "ENSMUSG00000015377",
  "ENSMUSG00000015396",
  "ENSMUSG00000015401",
  "ENSMUSG00000015405",
  "ENSMUSG00000015437",
  "ENSMUSG00000015441",
  "ENSMUSG00000015443",
  "ENSMUSG00000015452",
  "ENSMUSG00000015461",
  "ENSMUSG00000015467",
  "ENSMUSG00000015468",
  "ENSMUSG00000015474",
  "ENSMUSG00000015476",
  "ENSMUSG00000015478",
  "ENSMUSG00000015484",
  "ENSMUSG00000015488",
  "ENSMUSG00000015501",
  "ENSMUSG00000015519",
  "ENSMUSG00000015522",
  "ENSMUSG00000015533",
  "ENSMUSG00000015536",
  "ENSMUSG00000015542",
  "ENSMUSG00000015568",
  "ENSMUSG00000015575",
  "ENSMUSG00000015579",
  "ENSMUSG00000015597",
  "ENSMUSG00000015599",
  "ENSMUSG00000015605",
  "ENSMUSG00000015619",
  "ENSMUSG00000015627",
  "ENSMUSG00000015647",
  "ENSMUSG00000015652",
  "ENSMUSG00000015653",
  "ENSMUSG00000015656",
  "ENSMUSG00000015659",
  "ENSMUSG00000015665",
  "ENSMUSG00000015668",
  "ENSMUSG00000015671",
  "ENSMUSG00000015672",
  "ENSMUSG00000015697",
  "ENSMUSG00000015702",
  "ENSMUSG00000015709",
  "ENSMUSG00000015711",
  "ENSMUSG00000015714",
  "ENSMUSG00000015721",
  "ENSMUSG00000015733",
  "ENSMUSG00000015745",
  "ENSMUSG00000015747",
  "ENSMUSG00000015748",
  "ENSMUSG00000015749",
  "ENSMUSG00000015750",
  "ENSMUSG00000015755",
  "ENSMUSG00000015757",
  "ENSMUSG00000015759",
  "ENSMUSG00000015766",
  "ENSMUSG00000015776",
  "ENSMUSG00000015787",
  "ENSMUSG00000015790",
  "ENSMUSG00000015804",
  "ENSMUSG00000015806",
  "ENSMUSG00000015812",
  "ENSMUSG00000015829",
  "ENSMUSG00000015837",
  "ENSMUSG00000015839",
  "ENSMUSG00000015843",
  "ENSMUSG00000015846",
  "ENSMUSG00000015850",
  "ENSMUSG00000015852",
  "ENSMUSG00000015854",
  "ENSMUSG00000015869",
  "ENSMUSG00000015879",
  "ENSMUSG00000015880",
  "ENSMUSG00000015882",
  "ENSMUSG00000015889",
  "ENSMUSG00000015890",
  "ENSMUSG00000015932",
  "ENSMUSG00000015937",
  "ENSMUSG00000015942",
  "ENSMUSG00000015943",
  "ENSMUSG00000015944",
  "ENSMUSG00000015947",
  "ENSMUSG00000015950",
  "ENSMUSG00000015957",
  "ENSMUSG00000015961",
  "ENSMUSG00000015962",
  "ENSMUSG00000015966",
  "ENSMUSG00000015968",
  "ENSMUSG00000015970",
  "ENSMUSG00000015971",
  "ENSMUSG00000015980",
  "ENSMUSG00000015981",
  "ENSMUSG00000015994",
  "ENSMUSG00000016018",
  "ENSMUSG00000016024",
  "ENSMUSG00000016028",
  "ENSMUSG00000016087",
  "ENSMUSG00000016128",
  "ENSMUSG00000016150",
  "ENSMUSG00000016179",
  "ENSMUSG00000016181",
  "ENSMUSG00000016194",
  "ENSMUSG00000016200",
  "ENSMUSG00000016206",
  "ENSMUSG00000016239",
  "ENSMUSG00000016252",
  "ENSMUSG00000016255",
  "ENSMUSG00000016256",
  "ENSMUSG00000016257",
  "ENSMUSG00000016262",
  "ENSMUSG00000016283",
  "ENSMUSG00000016308",
  "ENSMUSG00000016327",
  "ENSMUSG00000016344",
  "ENSMUSG00000016346",
  "ENSMUSG00000016349",
  "ENSMUSG00000016356",
  "ENSMUSG00000016382",
  "ENSMUSG00000016386",
  "ENSMUSG00000016409",
  "ENSMUSG00000016427",
  "ENSMUSG00000016458",
  "ENSMUSG00000016477",
  "ENSMUSG00000016481",
  "ENSMUSG00000016487",
  "ENSMUSG00000016493",
  "ENSMUSG00000016494",
  "ENSMUSG00000016495",
  "ENSMUSG00000016496",
  "ENSMUSG00000016498",
  "ENSMUSG00000016503",
  "ENSMUSG00000016510",
  "ENSMUSG00000016520",
  "ENSMUSG00000016524",
  "ENSMUSG00000016526",
  "ENSMUSG00000016528",
  "ENSMUSG00000016529",
  "ENSMUSG00000016534",
  "ENSMUSG00000016541",
  "ENSMUSG00000016552",
  "ENSMUSG00000016554",
  "ENSMUSG00000016559",
  "ENSMUSG00000016619",
  "ENSMUSG00000016624",
  "ENSMUSG00000016626",
  "ENSMUSG00000016637",
  "ENSMUSG00000016664",
  "ENSMUSG00000016756",
  "ENSMUSG00000016757",
  "ENSMUSG00000016758",
  "ENSMUSG00000016763",
  "ENSMUSG00000016831",
  "ENSMUSG00000016833",
  "ENSMUSG00000016918",
  "ENSMUSG00000016921",
  "ENSMUSG00000016933",
  "ENSMUSG00000016940",
  "ENSMUSG00000016942",
  "ENSMUSG00000016946",
  "ENSMUSG00000016982",
  "ENSMUSG00000016984",
  "ENSMUSG00000016995",
  "ENSMUSG00000016998",
  "ENSMUSG00000017000",
  "ENSMUSG00000017002",
  "ENSMUSG00000017003",
  "ENSMUSG00000017004",
  "ENSMUSG00000017007",
  "ENSMUSG00000017009",
  "ENSMUSG00000017049",
  "ENSMUSG00000017057",
  "ENSMUSG00000017064",
  "ENSMUSG00000017119",
  "ENSMUSG00000017132",
  "ENSMUSG00000017144",
  "ENSMUSG00000017146",
  "ENSMUSG00000017165",
  "ENSMUSG00000017167",
  "ENSMUSG00000017176",
  "ENSMUSG00000017188",
  "ENSMUSG00000017195",
  "ENSMUSG00000017204",
  "ENSMUSG00000017210",
  "ENSMUSG00000017211",
  "ENSMUSG00000017221",
  "ENSMUSG00000017264",
  "ENSMUSG00000017286",
  "ENSMUSG00000017288",
  "ENSMUSG00000017299",
  "ENSMUSG00000017300",
  "ENSMUSG00000017307",
  "ENSMUSG00000017309",
  "ENSMUSG00000017310",
  "ENSMUSG00000017311",
  "ENSMUSG00000017314",
  "ENSMUSG00000017316",
  "ENSMUSG00000017344",
  "ENSMUSG00000017376",
  "ENSMUSG00000017386",
  "ENSMUSG00000017390",
  "ENSMUSG00000017400",
  "ENSMUSG00000017405",
  "ENSMUSG00000017417",
  "ENSMUSG00000017418",
  "ENSMUSG00000017421",
  "ENSMUSG00000017446",
  "ENSMUSG00000017453",
  "ENSMUSG00000017466",
  "ENSMUSG00000017478",
  "ENSMUSG00000017485",
  "ENSMUSG00000017491",
  "ENSMUSG00000017493",
  "ENSMUSG00000017499",
  "ENSMUSG00000017548",
  "ENSMUSG00000017550",
  "ENSMUSG00000017561",
  "ENSMUSG00000017588",
  "ENSMUSG00000017607",
  "ENSMUSG00000017615",
  "ENSMUSG00000017631",
  "ENSMUSG00000017639",
  "ENSMUSG00000017652",
  "ENSMUSG00000017664",
  "ENSMUSG00000017667",
  "ENSMUSG00000017670",
  "ENSMUSG00000017677",
  "ENSMUSG00000017679",
  "ENSMUSG00000017686",
  "ENSMUSG00000017688",
  "ENSMUSG00000017692",
  "ENSMUSG00000017697",
  "ENSMUSG00000017707",
  "ENSMUSG00000017713",
  "ENSMUSG00000017715",
  "ENSMUSG00000017716",
  "ENSMUSG00000017720",
  "ENSMUSG00000017721",
  "ENSMUSG00000017723",
  "ENSMUSG00000017724",
  "ENSMUSG00000017733",
  "ENSMUSG00000017734",
  "ENSMUSG00000017737",
  "ENSMUSG00000017740",
  "ENSMUSG00000017747",
  "ENSMUSG00000017754",
  "ENSMUSG00000017756",
  "ENSMUSG00000017760",
  "ENSMUSG00000017764",
  "ENSMUSG00000017765",
  "ENSMUSG00000017767",
  "ENSMUSG00000017774",
  "ENSMUSG00000017776",
  "ENSMUSG00000017778",
  "ENSMUSG00000017781",
  "ENSMUSG00000017801",
  "ENSMUSG00000017802",
  "ENSMUSG00000017817",
  "ENSMUSG00000017830",
  "ENSMUSG00000017831",
  "ENSMUSG00000017832",
  "ENSMUSG00000017837",
  "ENSMUSG00000017843",
  "ENSMUSG00000017858",
  "ENSMUSG00000017861",
  "ENSMUSG00000017868",
  "ENSMUSG00000017897",
  "ENSMUSG00000017922",
  "ENSMUSG00000017929",
  "ENSMUSG00000017943",
  "ENSMUSG00000017950",
  "ENSMUSG00000017969",
  "ENSMUSG00000017978",
  "ENSMUSG00000017999",
  "ENSMUSG00000018001",
  "ENSMUSG00000018008",
  "ENSMUSG00000018012",
  "ENSMUSG00000018040",
  "ENSMUSG00000018042",
  "ENSMUSG00000018068",
  "ENSMUSG00000018076",
  "ENSMUSG00000018102",
  "ENSMUSG00000018126",
  "ENSMUSG00000018143",
  "ENSMUSG00000018160",
  "ENSMUSG00000018166",
  "ENSMUSG00000018167",
  "ENSMUSG00000018169",
  "ENSMUSG00000018171",
  "ENSMUSG00000018189",
  "ENSMUSG00000018196",
  "ENSMUSG00000018199",
  "ENSMUSG00000018209",
  "ENSMUSG00000018211",
  "ENSMUSG00000018217",
  "ENSMUSG00000018238",
  "ENSMUSG00000018239",
  "ENSMUSG00000018259",
  "ENSMUSG00000018263",
  "ENSMUSG00000018286",
  "ENSMUSG00000018287",
  "ENSMUSG00000018293",
  "ENSMUSG00000018326",
  "ENSMUSG00000018334",
  "ENSMUSG00000018339",
  "ENSMUSG00000018340",
  "ENSMUSG00000018341",
  "ENSMUSG00000018347",
  "ENSMUSG00000018362",
  "ENSMUSG00000018363",
  "ENSMUSG00000018372",
  "ENSMUSG00000018377",
  "ENSMUSG00000018378",
  "ENSMUSG00000018379",
  "ENSMUSG00000018381",
  "ENSMUSG00000018387",
  "ENSMUSG00000018395",
  "ENSMUSG00000018398",
  "ENSMUSG00000018401",
  "ENSMUSG00000018405",
  "ENSMUSG00000018411",
  "ENSMUSG00000018412",
  "ENSMUSG00000018415",
  "ENSMUSG00000018417",
  "ENSMUSG00000018425",
  "ENSMUSG00000018427",
  "ENSMUSG00000018428",
  "ENSMUSG00000018433",
  "ENSMUSG00000018442",
  "ENSMUSG00000018446",
  "ENSMUSG00000018449",
  "ENSMUSG00000018451",
  "ENSMUSG00000018459",
  "ENSMUSG00000018470",
  "ENSMUSG00000018474",
  "ENSMUSG00000018476",
  "ENSMUSG00000018479",
  "ENSMUSG00000018481",
  "ENSMUSG00000018486",
  "ENSMUSG00000018500",
  "ENSMUSG00000018501",
  "ENSMUSG00000018507",
  "ENSMUSG00000018509",
  "ENSMUSG00000018537",
  "ENSMUSG00000018541",
  "ENSMUSG00000018543",
  "ENSMUSG00000018547",
  "ENSMUSG00000018548",
  "ENSMUSG00000018554",
  "ENSMUSG00000018559",
  "ENSMUSG00000018565",
  "ENSMUSG00000018566",
  "ENSMUSG00000018567",
  "ENSMUSG00000018569",
  "ENSMUSG00000018570",
  "ENSMUSG00000018572",
  "ENSMUSG00000018574",
  "ENSMUSG00000018581",
  "ENSMUSG00000018583",
  "ENSMUSG00000018585",
  "ENSMUSG00000018589",
  "ENSMUSG00000018593",
  "ENSMUSG00000018595",
  "ENSMUSG00000018599",
  "ENSMUSG00000018604",
  "ENSMUSG00000018620",
  "ENSMUSG00000018623",
  "ENSMUSG00000018634",
  "ENSMUSG00000018648",
  "ENSMUSG00000018651",
  "ENSMUSG00000018654",
  "ENSMUSG00000018656",
  "ENSMUSG00000018659",
  "ENSMUSG00000018661",
  "ENSMUSG00000018666",
  "ENSMUSG00000018669",
  "ENSMUSG00000018672",
  "ENSMUSG00000018677",
  "ENSMUSG00000018678",
  "ENSMUSG00000018697",
  "ENSMUSG00000018698",
  "ENSMUSG00000018707",
  "ENSMUSG00000018727",
  "ENSMUSG00000018733",
  "ENSMUSG00000018736",
  "ENSMUSG00000018740",
  "ENSMUSG00000018750",
  "ENSMUSG00000018752",
  "ENSMUSG00000018761",
  "ENSMUSG00000018765",
  "ENSMUSG00000018770",
  "ENSMUSG00000018774",
  "ENSMUSG00000018776",
  "ENSMUSG00000018796",
  "ENSMUSG00000018800",
  "ENSMUSG00000018809",
  "ENSMUSG00000018819",
  "ENSMUSG00000018820",
  "ENSMUSG00000018821",
  "ENSMUSG00000018822",
  "ENSMUSG00000018830",
  "ENSMUSG00000018841",
  "ENSMUSG00000018844",
  "ENSMUSG00000018845",
  "ENSMUSG00000018846",
  "ENSMUSG00000018848",
  "ENSMUSG00000018849",
  "ENSMUSG00000018858",
  "ENSMUSG00000018861",
  "ENSMUSG00000018862",
  "ENSMUSG00000018865",
  "ENSMUSG00000018868",
  "ENSMUSG00000018882",
  "ENSMUSG00000018893",
  "ENSMUSG00000018899",
  "ENSMUSG00000018906",
  "ENSMUSG00000018907",
  "ENSMUSG00000018909",
  "ENSMUSG00000018916",
  "ENSMUSG00000018919",
  "ENSMUSG00000018920",
  "ENSMUSG00000018921",
  "ENSMUSG00000018923",
  "ENSMUSG00000018924",
  "ENSMUSG00000018925",
  "ENSMUSG00000018927",
  "ENSMUSG00000018930",
  "ENSMUSG00000018931",
  "ENSMUSG00000018932",
  "ENSMUSG00000018965",
  "ENSMUSG00000018973",
  "ENSMUSG00000018974",
  "ENSMUSG00000018983",
  "ENSMUSG00000018986",
  "ENSMUSG00000018995",
  "ENSMUSG00000018999",
  "ENSMUSG00000019027",
  "ENSMUSG00000019039",
  "ENSMUSG00000019054",
  "ENSMUSG00000019055",
  "ENSMUSG00000019066",
  "ENSMUSG00000019080",
  "ENSMUSG00000019082",
  "ENSMUSG00000019087",
  "ENSMUSG00000019088",
  "ENSMUSG00000019102",
  "ENSMUSG00000019122",
  "ENSMUSG00000019124",
  "ENSMUSG00000019132",
  "ENSMUSG00000019139",
  "ENSMUSG00000019143",
  "ENSMUSG00000019146",
  "ENSMUSG00000019158",
  "ENSMUSG00000019173",
  "ENSMUSG00000019178",
  "ENSMUSG00000019179",
  "ENSMUSG00000019188",
  "ENSMUSG00000019189",
  "ENSMUSG00000019194",
  "ENSMUSG00000019210",
  "ENSMUSG00000019214",
  "ENSMUSG00000019230",
  "ENSMUSG00000019232",
  "ENSMUSG00000019235",
  "ENSMUSG00000019254",
  "ENSMUSG00000019256",
  "ENSMUSG00000019261",
  "ENSMUSG00000019278",
  "ENSMUSG00000019295",
  "ENSMUSG00000019297",
  "ENSMUSG00000019301",
  "ENSMUSG00000019302",
  "ENSMUSG00000019303",
  "ENSMUSG00000019312",
  "ENSMUSG00000019320",
  "ENSMUSG00000019326",
  "ENSMUSG00000019338",
  "ENSMUSG00000019359",
  "ENSMUSG00000019368",
  "ENSMUSG00000019370",
  "ENSMUSG00000019373",
  "ENSMUSG00000019428",
  "ENSMUSG00000019429",
  "ENSMUSG00000019432",
  "ENSMUSG00000019433",
  "ENSMUSG00000019437",
  "ENSMUSG00000019461",
  "ENSMUSG00000019464",
  "ENSMUSG00000019467",
  "ENSMUSG00000019470",
  "ENSMUSG00000019471",
  "ENSMUSG00000019478",
  "ENSMUSG00000019487",
  "ENSMUSG00000019489",
  "ENSMUSG00000019494",
  "ENSMUSG00000019518",
  "ENSMUSG00000019528",
  "ENSMUSG00000019539",
  "ENSMUSG00000019558",
  "ENSMUSG00000019564",
  "ENSMUSG00000019577",
  "ENSMUSG00000019578",
  "ENSMUSG00000019579",
  "ENSMUSG00000019590",
  "ENSMUSG00000019647",
  "ENSMUSG00000019659",
  "ENSMUSG00000019689",
  "ENSMUSG00000019699",
  "ENSMUSG00000019710",
  "ENSMUSG00000019715",
  "ENSMUSG00000019718",
  "ENSMUSG00000019726",
  "ENSMUSG00000019731",
  "ENSMUSG00000019732",
  "ENSMUSG00000019734",
  "ENSMUSG00000019737",
  "ENSMUSG00000019738",
  "ENSMUSG00000019756",
  "ENSMUSG00000019761",
  "ENSMUSG00000019762",
  "ENSMUSG00000019763",
  "ENSMUSG00000019767",
  "ENSMUSG00000019768",
  "ENSMUSG00000019772",
  "ENSMUSG00000019773",
  "ENSMUSG00000019774",
  "ENSMUSG00000019775",
  "ENSMUSG00000019777",
  "ENSMUSG00000019779",
  "ENSMUSG00000019782",
  "ENSMUSG00000019785",
  "ENSMUSG00000019787",
  "ENSMUSG00000019789",
  "ENSMUSG00000019790",
  "ENSMUSG00000019791",
  "ENSMUSG00000019792",
  "ENSMUSG00000019794",
  "ENSMUSG00000019795",
  "ENSMUSG00000019796",
  "ENSMUSG00000019797",
  "ENSMUSG00000019802",
  "ENSMUSG00000019803",
  "ENSMUSG00000019804",
  "ENSMUSG00000019806",
  "ENSMUSG00000019808",
  "ENSMUSG00000019809",
  "ENSMUSG00000019810",
  "ENSMUSG00000019813",
  "ENSMUSG00000019814",
  "ENSMUSG00000019815",
  "ENSMUSG00000019817",
  "ENSMUSG00000019818",
  "ENSMUSG00000019820",
  "ENSMUSG00000019822",
  "ENSMUSG00000019823",
  "ENSMUSG00000019826",
  "ENSMUSG00000019828",
  "ENSMUSG00000019831",
  "ENSMUSG00000019832",
  "ENSMUSG00000019834",
  "ENSMUSG00000019837",
  "ENSMUSG00000019838",
  "ENSMUSG00000019841",
  "ENSMUSG00000019842",
  "ENSMUSG00000019843",
  "ENSMUSG00000019845",
  "ENSMUSG00000019846",
  "ENSMUSG00000019848",
  "ENSMUSG00000019849",
  "ENSMUSG00000019850",
  "ENSMUSG00000019852",
  "ENSMUSG00000019853",
  "ENSMUSG00000019854",
  "ENSMUSG00000019856",
  "ENSMUSG00000019857",
  "ENSMUSG00000019861",
  "ENSMUSG00000019863",
  "ENSMUSG00000019864",
  "ENSMUSG00000019865",
  "ENSMUSG00000019866",
  "ENSMUSG00000019867",
  "ENSMUSG00000019868",
  "ENSMUSG00000019872",
  "ENSMUSG00000019873",
  "ENSMUSG00000019874",
  "ENSMUSG00000019876",
  "ENSMUSG00000019877",
  "ENSMUSG00000019878",
  "ENSMUSG00000019880",
  "ENSMUSG00000019883",
  "ENSMUSG00000019888",
  "ENSMUSG00000019889",
  "ENSMUSG00000019890",
  "ENSMUSG00000019891",
  "ENSMUSG00000019892",
  "ENSMUSG00000019893",
  "ENSMUSG00000019894",
  "ENSMUSG00000019897",
  "ENSMUSG00000019899",
  "ENSMUSG00000019900",
  "ENSMUSG00000019905",
  "ENSMUSG00000019906",
  "ENSMUSG00000019907",
  "ENSMUSG00000019909",
  "ENSMUSG00000019913",
  "ENSMUSG00000019916",
  "ENSMUSG00000019917",
  "ENSMUSG00000019920",
  "ENSMUSG00000019923",
  "ENSMUSG00000019927",
  "ENSMUSG00000019929",
  "ENSMUSG00000019932",
  "ENSMUSG00000019933",
  "ENSMUSG00000019935",
  "ENSMUSG00000019936",
  "ENSMUSG00000019942",
  "ENSMUSG00000019943",
  "ENSMUSG00000019945",
  "ENSMUSG00000019947",
  "ENSMUSG00000019948",
  "ENSMUSG00000019951",
  "ENSMUSG00000019952",
  "ENSMUSG00000019960",
  "ENSMUSG00000019961",
  "ENSMUSG00000019966",
  "ENSMUSG00000019969",
  "ENSMUSG00000019970",
  "ENSMUSG00000019971",
  "ENSMUSG00000019975",
  "ENSMUSG00000019977",
  "ENSMUSG00000019978",
  "ENSMUSG00000019979",
  "ENSMUSG00000019984",
  "ENSMUSG00000019986",
  "ENSMUSG00000019987",
  "ENSMUSG00000019988",
  "ENSMUSG00000019989",
  "ENSMUSG00000019990",
  "ENSMUSG00000019992",
  "ENSMUSG00000019996",
  "ENSMUSG00000019997",
  "ENSMUSG00000019998",
  "ENSMUSG00000020000",
  "ENSMUSG00000020003",
  "ENSMUSG00000020007",
  "ENSMUSG00000020009",
  "ENSMUSG00000020010",
  "ENSMUSG00000020014",
  "ENSMUSG00000020015",
  "ENSMUSG00000020017",
  "ENSMUSG00000020018",
  "ENSMUSG00000020020",
  "ENSMUSG00000020021",
  "ENSMUSG00000020022",
  "ENSMUSG00000020023",
  "ENSMUSG00000020024",
  "ENSMUSG00000020027",
  "ENSMUSG00000020029",
  "ENSMUSG00000020032",
  "ENSMUSG00000020033",
  "ENSMUSG00000020034",
  "ENSMUSG00000020037",
  "ENSMUSG00000020038",
  "ENSMUSG00000020042",
  "ENSMUSG00000020044",
  "ENSMUSG00000020048",
  "ENSMUSG00000020051",
  "ENSMUSG00000020052",
  "ENSMUSG00000020053",
  "ENSMUSG00000020056",
  "ENSMUSG00000020057",
  "ENSMUSG00000020059",
  "ENSMUSG00000020061",
  "ENSMUSG00000020062",
  "ENSMUSG00000020063",
  "ENSMUSG00000020064",
  "ENSMUSG00000020067",
  "ENSMUSG00000020069",
  "ENSMUSG00000020070",
  "ENSMUSG00000020072",
  "ENSMUSG00000020074",
  "ENSMUSG00000020075",
  "ENSMUSG00000020076",
  "ENSMUSG00000020077",
  "ENSMUSG00000020078",
  "ENSMUSG00000020079",
  "ENSMUSG00000020080",
  "ENSMUSG00000020081",
  "ENSMUSG00000020083",
  "ENSMUSG00000020085",
  "ENSMUSG00000020086",
  "ENSMUSG00000020087",
  "ENSMUSG00000020088",
  "ENSMUSG00000020089",
  "ENSMUSG00000020090",
  "ENSMUSG00000020091",
  "ENSMUSG00000020092",
  "ENSMUSG00000020096",
  "ENSMUSG00000020097",
  "ENSMUSG00000020098",
  "ENSMUSG00000020099",
  "ENSMUSG00000020100",
  "ENSMUSG00000020101",
  "ENSMUSG00000020102",
  "ENSMUSG00000020105",
  "ENSMUSG00000020107",
  "ENSMUSG00000020108",
  "ENSMUSG00000020109",
  "ENSMUSG00000020111",
  "ENSMUSG00000020114",
  "ENSMUSG00000020115",
  "ENSMUSG00000020116",
  "ENSMUSG00000020120",
  "ENSMUSG00000020121",
  "ENSMUSG00000020122",
  "ENSMUSG00000020123",
  "ENSMUSG00000020124",
  "ENSMUSG00000020125",
  "ENSMUSG00000020128",
  "ENSMUSG00000020130",
  "ENSMUSG00000020131",
  "ENSMUSG00000020132",
  "ENSMUSG00000020133",
  "ENSMUSG00000020134",
  "ENSMUSG00000020135",
  "ENSMUSG00000020137",
  "ENSMUSG00000020140",
  "ENSMUSG00000020142",
  "ENSMUSG00000020143",
  "ENSMUSG00000020149",
  "ENSMUSG00000020150",
  "ENSMUSG00000020151",
  "ENSMUSG00000020152",
  "ENSMUSG00000020153",
  "ENSMUSG00000020154",
  "ENSMUSG00000020155",
  "ENSMUSG00000020156",
  "ENSMUSG00000020159",
  "ENSMUSG00000020160",
  "ENSMUSG00000020163",
  "ENSMUSG00000020164",
  "ENSMUSG00000020166",
  "ENSMUSG00000020167",
  "ENSMUSG00000020168",
  "ENSMUSG00000020169",
  "ENSMUSG00000020170",
  "ENSMUSG00000020171",
  "ENSMUSG00000020173",
  "ENSMUSG00000020175",
  "ENSMUSG00000020176",
  "ENSMUSG00000020177",
  "ENSMUSG00000020178",
  "ENSMUSG00000020180",
  "ENSMUSG00000020181",
  "ENSMUSG00000020182",
  "ENSMUSG00000020183",
  "ENSMUSG00000020184",
  "ENSMUSG00000020185",
  "ENSMUSG00000020186",
  "ENSMUSG00000020189",
  "ENSMUSG00000020190",
  "ENSMUSG00000020191",
  "ENSMUSG00000020193",
  "ENSMUSG00000020196",
  "ENSMUSG00000020198",
  "ENSMUSG00000020205",
  "ENSMUSG00000020211",
  "ENSMUSG00000020212",
  "ENSMUSG00000020213",
  "ENSMUSG00000020214",
  "ENSMUSG00000020216",
  "ENSMUSG00000020218",
  "ENSMUSG00000020219",
  "ENSMUSG00000020220",
  "ENSMUSG00000020224",
  "ENSMUSG00000020225",
  "ENSMUSG00000020226",
  "ENSMUSG00000020227",
  "ENSMUSG00000020228",
  "ENSMUSG00000020229",
  "ENSMUSG00000020230",
  "ENSMUSG00000020231",
  "ENSMUSG00000020232",
  "ENSMUSG00000020234",
  "ENSMUSG00000020235",
  "ENSMUSG00000020238",
  "ENSMUSG00000020241",
  "ENSMUSG00000020246",
  "ENSMUSG00000020248",
  "ENSMUSG00000020250",
  "ENSMUSG00000020251",
  "ENSMUSG00000020253",
  "ENSMUSG00000020255",
  "ENSMUSG00000020256",
  "ENSMUSG00000020257",
  "ENSMUSG00000020258",
  "ENSMUSG00000020260",
  "ENSMUSG00000020261",
  "ENSMUSG00000020262",
  "ENSMUSG00000020263",
  "ENSMUSG00000020264",
  "ENSMUSG00000020265",
  "ENSMUSG00000020267",
  "ENSMUSG00000020268",
  "ENSMUSG00000020270",
  "ENSMUSG00000020271",
  "ENSMUSG00000020272",
  "ENSMUSG00000020273",
  "ENSMUSG00000020275",
  "ENSMUSG00000020277",
  "ENSMUSG00000020279",
  "ENSMUSG00000020280",
  "ENSMUSG00000020282",
  "ENSMUSG00000020283",
  "ENSMUSG00000020284",
  "ENSMUSG00000020286",
  "ENSMUSG00000020287",
  "ENSMUSG00000020288",
  "ENSMUSG00000020289",
  "ENSMUSG00000020290",
  "ENSMUSG00000020295",
  "ENSMUSG00000020297",
  "ENSMUSG00000020299",
  "ENSMUSG00000020300",
  "ENSMUSG00000020303",
  "ENSMUSG00000020305",
  "ENSMUSG00000020308",
  "ENSMUSG00000020309",
  "ENSMUSG00000020310",
  "ENSMUSG00000020312",
  "ENSMUSG00000020315",
  "ENSMUSG00000020317",
  "ENSMUSG00000020319",
  "ENSMUSG00000020321",
  "ENSMUSG00000020323",
  "ENSMUSG00000020325",
  "ENSMUSG00000020326",
  "ENSMUSG00000020327",
  "ENSMUSG00000020328",
  "ENSMUSG00000020329",
  "ENSMUSG00000020331",
  "ENSMUSG00000020332",
  "ENSMUSG00000020333",
  "ENSMUSG00000020334",
  "ENSMUSG00000020335",
  "ENSMUSG00000020340",
  "ENSMUSG00000020346",
  "ENSMUSG00000020349",
  "ENSMUSG00000020354",
  "ENSMUSG00000020357",
  "ENSMUSG00000020358",
  "ENSMUSG00000020359",
  "ENSMUSG00000020361",
  "ENSMUSG00000020362",
  "ENSMUSG00000020363",
  "ENSMUSG00000020364",
  "ENSMUSG00000020366",
  "ENSMUSG00000020368",
  "ENSMUSG00000020372",
  "ENSMUSG00000020374",
  "ENSMUSG00000020375",
  "ENSMUSG00000020376",
  "ENSMUSG00000020377",
  "ENSMUSG00000020380",
  "ENSMUSG00000020381",
  "ENSMUSG00000020383",
  "ENSMUSG00000020385",
  "ENSMUSG00000020386",
  "ENSMUSG00000020387",
  "ENSMUSG00000020388",
  "ENSMUSG00000020389",
  "ENSMUSG00000020390",
  "ENSMUSG00000020392",
  "ENSMUSG00000020393",
  "ENSMUSG00000020395",
  "ENSMUSG00000020396",
  "ENSMUSG00000020397",
  "ENSMUSG00000020399",
  "ENSMUSG00000020400",
  "ENSMUSG00000020401",
  "ENSMUSG00000020402",
  "ENSMUSG00000020405",
  "ENSMUSG00000020407",
  "ENSMUSG00000020409",
  "ENSMUSG00000020411",
  "ENSMUSG00000020412",
  "ENSMUSG00000020413",
  "ENSMUSG00000020415",
  "ENSMUSG00000020419",
  "ENSMUSG00000020420",
  "ENSMUSG00000020422",
  "ENSMUSG00000020423",
  "ENSMUSG00000020424",
  "ENSMUSG00000020427",
  "ENSMUSG00000020428",
  "ENSMUSG00000020429",
  "ENSMUSG00000020430",
  "ENSMUSG00000020432",
  "ENSMUSG00000020434",
  "ENSMUSG00000020435",
  "ENSMUSG00000020436",
  "ENSMUSG00000020437",
  "ENSMUSG00000020439",
  "ENSMUSG00000020440",
  "ENSMUSG00000020441",
  "ENSMUSG00000020444",
  "ENSMUSG00000020447",
  "ENSMUSG00000020448",
  "ENSMUSG00000020453",
  "ENSMUSG00000020454",
  "ENSMUSG00000020455",
  "ENSMUSG00000020456",
  "ENSMUSG00000020457",
  "ENSMUSG00000020458",
  "ENSMUSG00000020459",
  "ENSMUSG00000020460",
  "ENSMUSG00000020461",
  "ENSMUSG00000020462",
  "ENSMUSG00000020463",
  "ENSMUSG00000020464",
  "ENSMUSG00000020467",
  "ENSMUSG00000020469",
  "ENSMUSG00000020471",
  "ENSMUSG00000020472",
  "ENSMUSG00000020473",
  "ENSMUSG00000020474",
  "ENSMUSG00000020475",
  "ENSMUSG00000020476",
  "ENSMUSG00000020477",
  "ENSMUSG00000020481",
  "ENSMUSG00000020482",
  "ENSMUSG00000020483",
  "ENSMUSG00000020484",
  "ENSMUSG00000020485",
  "ENSMUSG00000020486",
  "ENSMUSG00000020490",
  "ENSMUSG00000020491",
  "ENSMUSG00000020492",
  "ENSMUSG00000020493",
  "ENSMUSG00000020495",
  "ENSMUSG00000020496",
  "ENSMUSG00000020513",
  "ENSMUSG00000020514",
  "ENSMUSG00000020515",
  "ENSMUSG00000020516",
  "ENSMUSG00000020519",
  "ENSMUSG00000020520",
  "ENSMUSG00000020521",
  "ENSMUSG00000020522",
  "ENSMUSG00000020523",
  "ENSMUSG00000020524",
  "ENSMUSG00000020525",
  "ENSMUSG00000020526",
  "ENSMUSG00000020527",
  "ENSMUSG00000020528",
  "ENSMUSG00000020530",
  "ENSMUSG00000020532",
  "ENSMUSG00000020534",
  "ENSMUSG00000020536",
  "ENSMUSG00000020537",
  "ENSMUSG00000020538",
  "ENSMUSG00000020541",
  "ENSMUSG00000020542",
  "ENSMUSG00000020544",
  "ENSMUSG00000020545",
  "ENSMUSG00000020546",
  "ENSMUSG00000020547",
  "ENSMUSG00000020548",
  "ENSMUSG00000020549",
  "ENSMUSG00000020553",
  "ENSMUSG00000020561",
  "ENSMUSG00000020562",
  "ENSMUSG00000020564",
  "ENSMUSG00000020566",
  "ENSMUSG00000020570",
  "ENSMUSG00000020571",
  "ENSMUSG00000020572",
  "ENSMUSG00000020573",
  "ENSMUSG00000020576",
  "ENSMUSG00000020577",
  "ENSMUSG00000020580",
  "ENSMUSG00000020581",
  "ENSMUSG00000020583",
  "ENSMUSG00000020585",
  "ENSMUSG00000020589",
  "ENSMUSG00000020590",
  "ENSMUSG00000020591",
  "ENSMUSG00000020592",
  "ENSMUSG00000020593",
  "ENSMUSG00000020594",
  "ENSMUSG00000020598",
  "ENSMUSG00000020599",
  "ENSMUSG00000020600",
  "ENSMUSG00000020601",
  "ENSMUSG00000020604",
  "ENSMUSG00000020605",
  "ENSMUSG00000020607",
  "ENSMUSG00000020608",
  "ENSMUSG00000020609",
  "ENSMUSG00000020610",
  "ENSMUSG00000020611",
  "ENSMUSG00000020612",
  "ENSMUSG00000020614",
  "ENSMUSG00000020617",
  "ENSMUSG00000020620",
  "ENSMUSG00000020621",
  "ENSMUSG00000020622",
  "ENSMUSG00000020623",
  "ENSMUSG00000020624",
  "ENSMUSG00000020627",
  "ENSMUSG00000020628",
  "ENSMUSG00000020629",
  "ENSMUSG00000020630",
  "ENSMUSG00000020633",
  "ENSMUSG00000020634",
  "ENSMUSG00000020635",
  "ENSMUSG00000020636",
  "ENSMUSG00000020638",
  "ENSMUSG00000020639",
  "ENSMUSG00000020640",
  "ENSMUSG00000020641",
  "ENSMUSG00000020642",
  "ENSMUSG00000020644",
  "ENSMUSG00000020646",
  "ENSMUSG00000020647",
  "ENSMUSG00000020648",
  "ENSMUSG00000020649",
  "ENSMUSG00000020650",
  "ENSMUSG00000020651",
  "ENSMUSG00000020652",
  "ENSMUSG00000020653",
  "ENSMUSG00000020654",
  "ENSMUSG00000020656",
  "ENSMUSG00000020657",
  "ENSMUSG00000020658",
  "ENSMUSG00000020659",
  "ENSMUSG00000020660",
  "ENSMUSG00000020661",
  "ENSMUSG00000020664",
  "ENSMUSG00000020668",
  "ENSMUSG00000020669",
  "ENSMUSG00000020671",
  "ENSMUSG00000020672",
  "ENSMUSG00000020673",
  "ENSMUSG00000020674",
  "ENSMUSG00000020676",
  "ENSMUSG00000020677",
  "ENSMUSG00000020679",
  "ENSMUSG00000020680",
  "ENSMUSG00000020681",
  "ENSMUSG00000020682",
  "ENSMUSG00000020684",
  "ENSMUSG00000020686",
  "ENSMUSG00000020687",
  "ENSMUSG00000020689",
  "ENSMUSG00000020691",
  "ENSMUSG00000020692",
  "ENSMUSG00000020694",
  "ENSMUSG00000020695",
  "ENSMUSG00000020696",
  "ENSMUSG00000020697",
  "ENSMUSG00000020698",
  "ENSMUSG00000020700",
  "ENSMUSG00000020701",
  "ENSMUSG00000020702",
  "ENSMUSG00000020703",
  "ENSMUSG00000020704",
  "ENSMUSG00000020705",
  "ENSMUSG00000020706",
  "ENSMUSG00000020707",
  "ENSMUSG00000020708",
  "ENSMUSG00000020709",
  "ENSMUSG00000020712",
  "ENSMUSG00000020713",
  "ENSMUSG00000020715",
  "ENSMUSG00000020716",
  "ENSMUSG00000020717",
  "ENSMUSG00000020718",
  "ENSMUSG00000020719",
  "ENSMUSG00000020720",
  "ENSMUSG00000020721",
  "ENSMUSG00000020722",
  "ENSMUSG00000020723",
  "ENSMUSG00000020728",
  "ENSMUSG00000020732",
  "ENSMUSG00000020733",
  "ENSMUSG00000020734",
  "ENSMUSG00000020736",
  "ENSMUSG00000020737",
  "ENSMUSG00000020738",
  "ENSMUSG00000020739",
  "ENSMUSG00000020740",
  "ENSMUSG00000020741",
  "ENSMUSG00000020743",
  "ENSMUSG00000020744",
  "ENSMUSG00000020745",
  "ENSMUSG00000020747",
  "ENSMUSG00000020752",
  "ENSMUSG00000020755",
  "ENSMUSG00000020758",
  "ENSMUSG00000020766",
  "ENSMUSG00000020770",
  "ENSMUSG00000020773",
  "ENSMUSG00000020774",
  "ENSMUSG00000020775",
  "ENSMUSG00000020776",
  "ENSMUSG00000020777",
  "ENSMUSG00000020778",
  "ENSMUSG00000020780",
  "ENSMUSG00000020781",
  "ENSMUSG00000020782",
  "ENSMUSG00000020783",
  "ENSMUSG00000020785",
  "ENSMUSG00000020787",
  "ENSMUSG00000020788",
  "ENSMUSG00000020790",
  "ENSMUSG00000020792",
  "ENSMUSG00000020793",
  "ENSMUSG00000020794",
  "ENSMUSG00000020798",
  "ENSMUSG00000020799",
  "ENSMUSG00000020801",
  "ENSMUSG00000020802",
  "ENSMUSG00000020803",
  "ENSMUSG00000020804",
  "ENSMUSG00000020805",
  "ENSMUSG00000020806",
  "ENSMUSG00000020807",
  "ENSMUSG00000020808",
  "ENSMUSG00000020810",
  "ENSMUSG00000020811",
  "ENSMUSG00000020812",
  "ENSMUSG00000020814",
  "ENSMUSG00000020817",
  "ENSMUSG00000020818",
  "ENSMUSG00000020821",
  "ENSMUSG00000020823",
  "ENSMUSG00000020826",
  "ENSMUSG00000020827",
  "ENSMUSG00000020828",
  "ENSMUSG00000020829",
  "ENSMUSG00000020830",
  "ENSMUSG00000020832",
  "ENSMUSG00000020834",
  "ENSMUSG00000020836",
  "ENSMUSG00000020838",
  "ENSMUSG00000020839",
  "ENSMUSG00000020840",
  "ENSMUSG00000020841",
  "ENSMUSG00000020843",
  "ENSMUSG00000020844",
  "ENSMUSG00000020846",
  "ENSMUSG00000020847",
  "ENSMUSG00000020848",
  "ENSMUSG00000020849",
  "ENSMUSG00000020850",
  "ENSMUSG00000020859",
  "ENSMUSG00000020863",
  "ENSMUSG00000020864",
  "ENSMUSG00000020865",
  "ENSMUSG00000020866",
  "ENSMUSG00000020867",
  "ENSMUSG00000020868",
  "ENSMUSG00000020869",
  "ENSMUSG00000020871",
  "ENSMUSG00000020872",
  "ENSMUSG00000020873",
  "ENSMUSG00000020875",
  "ENSMUSG00000020876",
  "ENSMUSG00000020877",
  "ENSMUSG00000020878",
  "ENSMUSG00000020882",
  "ENSMUSG00000020883",
  "ENSMUSG00000020884",
  "ENSMUSG00000020886",
  "ENSMUSG00000020888",
  "ENSMUSG00000020889",
  "ENSMUSG00000020890",
  "ENSMUSG00000020892",
  "ENSMUSG00000020893",
  "ENSMUSG00000020894",
  "ENSMUSG00000020895",
  "ENSMUSG00000020897",
  "ENSMUSG00000020898",
  "ENSMUSG00000020899",
  "ENSMUSG00000020900",
  "ENSMUSG00000020902",
  "ENSMUSG00000020903",
  "ENSMUSG00000020904",
  "ENSMUSG00000020905",
  "ENSMUSG00000020907",
  "ENSMUSG00000020908",
  "ENSMUSG00000020910",
  "ENSMUSG00000020911",
  "ENSMUSG00000020912",
  "ENSMUSG00000020913",
  "ENSMUSG00000020914",
  "ENSMUSG00000020916",
  "ENSMUSG00000020917",
  "ENSMUSG00000020918",
  "ENSMUSG00000020919",
  "ENSMUSG00000020921",
  "ENSMUSG00000020923",
  "ENSMUSG00000020925",
  "ENSMUSG00000020926",
  "ENSMUSG00000020928",
  "ENSMUSG00000020929",
  "ENSMUSG00000020930",
  "ENSMUSG00000020932",
  "ENSMUSG00000020935",
  "ENSMUSG00000020936",
  "ENSMUSG00000020937",
  "ENSMUSG00000020940",
  "ENSMUSG00000020941",
  "ENSMUSG00000020945",
  "ENSMUSG00000020946",
  "ENSMUSG00000020948",
  "ENSMUSG00000020949",
  "ENSMUSG00000020950",
  "ENSMUSG00000020952",
  "ENSMUSG00000020953",
  "ENSMUSG00000020954",
  "ENSMUSG00000020955",
  "ENSMUSG00000020956",
  "ENSMUSG00000020961",
  "ENSMUSG00000020962",
  "ENSMUSG00000020963",
  "ENSMUSG00000020964",
  "ENSMUSG00000020974",
  "ENSMUSG00000020978",
  "ENSMUSG00000020982",
  "ENSMUSG00000020986",
  "ENSMUSG00000020988",
  "ENSMUSG00000020990",
  "ENSMUSG00000020992",
  "ENSMUSG00000020993",
  "ENSMUSG00000020994",
  "ENSMUSG00000021000",
  "ENSMUSG00000021003",
  "ENSMUSG00000021007",
  "ENSMUSG00000021009",
  "ENSMUSG00000021010",
  "ENSMUSG00000021012",
  "ENSMUSG00000021013",
  "ENSMUSG00000021018",
  "ENSMUSG00000021022",
  "ENSMUSG00000021023",
  "ENSMUSG00000021024",
  "ENSMUSG00000021025",
  "ENSMUSG00000021027",
  "ENSMUSG00000021028",
  "ENSMUSG00000021032",
  "ENSMUSG00000021033",
  "ENSMUSG00000021036",
  "ENSMUSG00000021038",
  "ENSMUSG00000021039",
  "ENSMUSG00000021040",
  "ENSMUSG00000021044",
  "ENSMUSG00000021047",
  "ENSMUSG00000021048",
  "ENSMUSG00000021054",
  "ENSMUSG00000021055",
  "ENSMUSG00000021056",
  "ENSMUSG00000021057",
  "ENSMUSG00000021061",
  "ENSMUSG00000021062",
  "ENSMUSG00000021065",
  "ENSMUSG00000021066",
  "ENSMUSG00000021067",
  "ENSMUSG00000021068",
  "ENSMUSG00000021069",
  "ENSMUSG00000021070",
  "ENSMUSG00000021071",
  "ENSMUSG00000021072",
  "ENSMUSG00000021076",
  "ENSMUSG00000021078",
  "ENSMUSG00000021079",
  "ENSMUSG00000021081",
  "ENSMUSG00000021086",
  "ENSMUSG00000021087",
  "ENSMUSG00000021090",
  "ENSMUSG00000021091",
  "ENSMUSG00000021094",
  "ENSMUSG00000021095",
  "ENSMUSG00000021096",
  "ENSMUSG00000021097",
  "ENSMUSG00000021098",
  "ENSMUSG00000021099",
  "ENSMUSG00000021101",
  "ENSMUSG00000021102",
  "ENSMUSG00000021103",
  "ENSMUSG00000021108",
  "ENSMUSG00000021109",
  "ENSMUSG00000021111",
  "ENSMUSG00000021112",
  "ENSMUSG00000021113",
  "ENSMUSG00000021114",
  "ENSMUSG00000021115",
  "ENSMUSG00000021116",
  "ENSMUSG00000021118",
  "ENSMUSG00000021120",
  "ENSMUSG00000021123",
  "ENSMUSG00000021124",
  "ENSMUSG00000021125",
  "ENSMUSG00000021127",
  "ENSMUSG00000021130",
  "ENSMUSG00000021131",
  "ENSMUSG00000021133",
  "ENSMUSG00000021135",
  "ENSMUSG00000021136",
  "ENSMUSG00000021140",
  "ENSMUSG00000021143",
  "ENSMUSG00000021144",
  "ENSMUSG00000021147",
  "ENSMUSG00000021149",
  "ENSMUSG00000021156",
  "ENSMUSG00000021171",
  "ENSMUSG00000021175",
  "ENSMUSG00000021176",
  "ENSMUSG00000021177",
  "ENSMUSG00000021178",
  "ENSMUSG00000021179",
  "ENSMUSG00000021180",
  "ENSMUSG00000021182",
  "ENSMUSG00000021185",
  "ENSMUSG00000021186",
  "ENSMUSG00000021187",
  "ENSMUSG00000021188",
  "ENSMUSG00000021189",
  "ENSMUSG00000021190",
  "ENSMUSG00000021192",
  "ENSMUSG00000021193",
  "ENSMUSG00000021194",
  "ENSMUSG00000021196",
  "ENSMUSG00000021198",
  "ENSMUSG00000021200",
  "ENSMUSG00000021203",
  "ENSMUSG00000021207",
  "ENSMUSG00000021208",
  "ENSMUSG00000021209",
  "ENSMUSG00000021210",
  "ENSMUSG00000021211",
  "ENSMUSG00000021213",
  "ENSMUSG00000021214",
  "ENSMUSG00000021215",
  "ENSMUSG00000021216",
  "ENSMUSG00000021217",
  "ENSMUSG00000021218",
  "ENSMUSG00000021219",
  "ENSMUSG00000021221",
  "ENSMUSG00000021222",
  "ENSMUSG00000021223",
  "ENSMUSG00000021224",
  "ENSMUSG00000021226",
  "ENSMUSG00000021234",
  "ENSMUSG00000021235",
  "ENSMUSG00000021236",
  "ENSMUSG00000021238",
  "ENSMUSG00000021239",
  "ENSMUSG00000021240",
  "ENSMUSG00000021241",
  "ENSMUSG00000021242",
  "ENSMUSG00000021244",
  "ENSMUSG00000021245",
  "ENSMUSG00000021248",
  "ENSMUSG00000021250",
  "ENSMUSG00000021252",
  "ENSMUSG00000021253",
  "ENSMUSG00000021254",
  "ENSMUSG00000021255",
  "ENSMUSG00000021256",
  "ENSMUSG00000021257",
  "ENSMUSG00000021258",
  "ENSMUSG00000021259",
  "ENSMUSG00000021260",
  "ENSMUSG00000021262",
  "ENSMUSG00000021263",
  "ENSMUSG00000021264",
  "ENSMUSG00000021265",
  "ENSMUSG00000021266",
  "ENSMUSG00000021268",
  "ENSMUSG00000021270",
  "ENSMUSG00000021271",
  "ENSMUSG00000021273",
  "ENSMUSG00000021275",
  "ENSMUSG00000021276",
  "ENSMUSG00000021277",
  "ENSMUSG00000021278",
  "ENSMUSG00000021279",
  "ENSMUSG00000021280",
  "ENSMUSG00000021281",
  "ENSMUSG00000021282",
  "ENSMUSG00000021285",
  "ENSMUSG00000021286",
  "ENSMUSG00000021287",
  "ENSMUSG00000021288",
  "ENSMUSG00000021290",
  "ENSMUSG00000021294",
  "ENSMUSG00000021298",
  "ENSMUSG00000021302",
  "ENSMUSG00000021303",
  "ENSMUSG00000021306",
  "ENSMUSG00000021311",
  "ENSMUSG00000021313",
  "ENSMUSG00000021314",
  "ENSMUSG00000021318",
  "ENSMUSG00000021319",
  "ENSMUSG00000021322",
  "ENSMUSG00000021326",
  "ENSMUSG00000021327",
  "ENSMUSG00000021335",
  "ENSMUSG00000021336",
  "ENSMUSG00000021337",
  "ENSMUSG00000021338",
  "ENSMUSG00000021339",
  "ENSMUSG00000021340",
  "ENSMUSG00000021342",
  "ENSMUSG00000021345",
  "ENSMUSG00000021346",
  "ENSMUSG00000021347",
  "ENSMUSG00000021348",
  "ENSMUSG00000021356",
  "ENSMUSG00000021357",
  "ENSMUSG00000021359",
  "ENSMUSG00000021360",
  "ENSMUSG00000021361",
  "ENSMUSG00000021363",
  "ENSMUSG00000021364",
  "ENSMUSG00000021365",
  "ENSMUSG00000021366",
  "ENSMUSG00000021367",
  "ENSMUSG00000021368",
  "ENSMUSG00000021371",
  "ENSMUSG00000021373",
  "ENSMUSG00000021374",
  "ENSMUSG00000021375",
  "ENSMUSG00000021376",
  "ENSMUSG00000021377",
  "ENSMUSG00000021379",
  "ENSMUSG00000021381",
  "ENSMUSG00000021384",
  "ENSMUSG00000021385",
  "ENSMUSG00000021388",
  "ENSMUSG00000021390",
  "ENSMUSG00000021391",
  "ENSMUSG00000021392",
  "ENSMUSG00000021395",
  "ENSMUSG00000021396",
  "ENSMUSG00000021400",
  "ENSMUSG00000021403",
  "ENSMUSG00000021404",
  "ENSMUSG00000021408",
  "ENSMUSG00000021411",
  "ENSMUSG00000021413",
  "ENSMUSG00000021414",
  "ENSMUSG00000021415",
  "ENSMUSG00000021416",
  "ENSMUSG00000021417",
  "ENSMUSG00000021418",
  "ENSMUSG00000021420",
  "ENSMUSG00000021423",
  "ENSMUSG00000021427",
  "ENSMUSG00000021428",
  "ENSMUSG00000021431",
  "ENSMUSG00000021432",
  "ENSMUSG00000021439",
  "ENSMUSG00000021440",
  "ENSMUSG00000021441",
  "ENSMUSG00000021448",
  "ENSMUSG00000021451",
  "ENSMUSG00000021453",
  "ENSMUSG00000021456",
  "ENSMUSG00000021457",
  "ENSMUSG00000021458",
  "ENSMUSG00000021460",
  "ENSMUSG00000021461",
  "ENSMUSG00000021464",
  "ENSMUSG00000021466",
  "ENSMUSG00000021468",
  "ENSMUSG00000021469",
  "ENSMUSG00000021470",
  "ENSMUSG00000021474",
  "ENSMUSG00000021476",
  "ENSMUSG00000021477",
  "ENSMUSG00000021478",
  "ENSMUSG00000021481",
  "ENSMUSG00000021482",
  "ENSMUSG00000021483",
  "ENSMUSG00000021484",
  "ENSMUSG00000021485",
  "ENSMUSG00000021486",
  "ENSMUSG00000021488",
  "ENSMUSG00000021490",
  "ENSMUSG00000021492",
  "ENSMUSG00000021493",
  "ENSMUSG00000021494",
  "ENSMUSG00000021495",
  "ENSMUSG00000021496",
  "ENSMUSG00000021497",
  "ENSMUSG00000021499",
  "ENSMUSG00000021500",
  "ENSMUSG00000021501",
  "ENSMUSG00000021504",
  "ENSMUSG00000021506",
  "ENSMUSG00000021508",
  "ENSMUSG00000021509",
  "ENSMUSG00000021510",
  "ENSMUSG00000021514",
  "ENSMUSG00000021518",
  "ENSMUSG00000021519",
  "ENSMUSG00000021520",
  "ENSMUSG00000021532",
  "ENSMUSG00000021534",
  "ENSMUSG00000021536",
  "ENSMUSG00000021537",
  "ENSMUSG00000021538",
  "ENSMUSG00000021539",
  "ENSMUSG00000021540",
  "ENSMUSG00000021541",
  "ENSMUSG00000021545",
  "ENSMUSG00000021546",
  "ENSMUSG00000021548",
  "ENSMUSG00000021549",
  "ENSMUSG00000021550",
  "ENSMUSG00000021552",
  "ENSMUSG00000021553",
  "ENSMUSG00000021555",
  "ENSMUSG00000021556",
  "ENSMUSG00000021557",
  "ENSMUSG00000021559",
  "ENSMUSG00000021565",
  "ENSMUSG00000021566",
  "ENSMUSG00000021567",
  "ENSMUSG00000021569",
  "ENSMUSG00000021572",
  "ENSMUSG00000021573",
  "ENSMUSG00000021575",
  "ENSMUSG00000021576",
  "ENSMUSG00000021577",
  "ENSMUSG00000021578",
  "ENSMUSG00000021579",
  "ENSMUSG00000021583",
  "ENSMUSG00000021585",
  "ENSMUSG00000021587",
  "ENSMUSG00000021589",
  "ENSMUSG00000021590",
  "ENSMUSG00000021591",
  "ENSMUSG00000021592",
  "ENSMUSG00000021594",
  "ENSMUSG00000021595",
  "ENSMUSG00000021596",
  "ENSMUSG00000021597",
  "ENSMUSG00000021598",
  "ENSMUSG00000021600",
  "ENSMUSG00000021604",
  "ENSMUSG00000021606",
  "ENSMUSG00000021607",
  "ENSMUSG00000021608",
  "ENSMUSG00000021609",
  "ENSMUSG00000021610",
  "ENSMUSG00000021611",
  "ENSMUSG00000021612",
  "ENSMUSG00000021614",
  "ENSMUSG00000021615",
  "ENSMUSG00000021619",
  "ENSMUSG00000021620",
  "ENSMUSG00000021621",
  "ENSMUSG00000021622",
  "ENSMUSG00000021624",
  "ENSMUSG00000021629",
  "ENSMUSG00000021635",
  "ENSMUSG00000021636",
  "ENSMUSG00000021638",
  "ENSMUSG00000021639",
  "ENSMUSG00000021640",
  "ENSMUSG00000021643",
  "ENSMUSG00000021645",
  "ENSMUSG00000021646",
  "ENSMUSG00000021647",
  "ENSMUSG00000021650",
  "ENSMUSG00000021660",
  "ENSMUSG00000021661",
  "ENSMUSG00000021662",
  "ENSMUSG00000021665",
  "ENSMUSG00000021666",
  "ENSMUSG00000021668",
  "ENSMUSG00000021669",
  "ENSMUSG00000021670",
  "ENSMUSG00000021671",
  "ENSMUSG00000021676",
  "ENSMUSG00000021678",
  "ENSMUSG00000021679",
  "ENSMUSG00000021680",
  "ENSMUSG00000021681",
  "ENSMUSG00000021684",
  "ENSMUSG00000021685",
  "ENSMUSG00000021686",
  "ENSMUSG00000021687",
  "ENSMUSG00000021690",
  "ENSMUSG00000021693",
  "ENSMUSG00000021694",
  "ENSMUSG00000021696",
  "ENSMUSG00000021697",
  "ENSMUSG00000021699",
  "ENSMUSG00000021700",
  "ENSMUSG00000021701",
  "ENSMUSG00000021702",
  "ENSMUSG00000021703",
  "ENSMUSG00000021704",
  "ENSMUSG00000021706",
  "ENSMUSG00000021707",
  "ENSMUSG00000021708",
  "ENSMUSG00000021709",
  "ENSMUSG00000021710",
  "ENSMUSG00000021711",
  "ENSMUSG00000021712",
  "ENSMUSG00000021713",
  "ENSMUSG00000021714",
  "ENSMUSG00000021715",
  "ENSMUSG00000021716",
  "ENSMUSG00000021718",
  "ENSMUSG00000021719",
  "ENSMUSG00000021720",
  "ENSMUSG00000021721",
  "ENSMUSG00000021725",
  "ENSMUSG00000021728",
  "ENSMUSG00000021730",
  "ENSMUSG00000021731",
  "ENSMUSG00000021732",
  "ENSMUSG00000021733",
  "ENSMUSG00000021737",
  "ENSMUSG00000021738",
  "ENSMUSG00000021743",
  "ENSMUSG00000021745",
  "ENSMUSG00000021747",
  "ENSMUSG00000021748",
  "ENSMUSG00000021749",
  "ENSMUSG00000021750",
  "ENSMUSG00000021751",
  "ENSMUSG00000021752",
  "ENSMUSG00000021754",
  "ENSMUSG00000021756",
  "ENSMUSG00000021758",
  "ENSMUSG00000021759",
  "ENSMUSG00000021760",
  "ENSMUSG00000021763",
  "ENSMUSG00000021764",
  "ENSMUSG00000021765",
  "ENSMUSG00000021767",
  "ENSMUSG00000021768",
  "ENSMUSG00000021770",
  "ENSMUSG00000021771",
  "ENSMUSG00000021772",
  "ENSMUSG00000021773",
  "ENSMUSG00000021774",
  "ENSMUSG00000021775",
  "ENSMUSG00000021779",
  "ENSMUSG00000021782",
  "ENSMUSG00000021785",
  "ENSMUSG00000021786",
  "ENSMUSG00000021789",
  "ENSMUSG00000021790",
  "ENSMUSG00000021791",
  "ENSMUSG00000021792",
  "ENSMUSG00000021794",
  "ENSMUSG00000021795",
  "ENSMUSG00000021796",
  "ENSMUSG00000021797",
  "ENSMUSG00000021798",
  "ENSMUSG00000021799",
  "ENSMUSG00000021803",
  "ENSMUSG00000021804",
  "ENSMUSG00000021806",
  "ENSMUSG00000021807",
  "ENSMUSG00000021809",
  "ENSMUSG00000021810",
  "ENSMUSG00000021811",
  "ENSMUSG00000021814",
  "ENSMUSG00000021815",
  "ENSMUSG00000021816",
  "ENSMUSG00000021819",
  "ENSMUSG00000021820",
  "ENSMUSG00000021822",
  "ENSMUSG00000021823",
  "ENSMUSG00000021824",
  "ENSMUSG00000021830",
  "ENSMUSG00000021831",
  "ENSMUSG00000021832",
  "ENSMUSG00000021835",
  "ENSMUSG00000021838",
  "ENSMUSG00000021840",
  "ENSMUSG00000021843",
  "ENSMUSG00000021846",
  "ENSMUSG00000021848",
  "ENSMUSG00000021850",
  "ENSMUSG00000021852",
  "ENSMUSG00000021866",
  "ENSMUSG00000021868",
  "ENSMUSG00000021870",
  "ENSMUSG00000021872",
  "ENSMUSG00000021874",
  "ENSMUSG00000021876",
  "ENSMUSG00000021877",
  "ENSMUSG00000021879",
  "ENSMUSG00000021880",
  "ENSMUSG00000021884",
  "ENSMUSG00000021886",
  "ENSMUSG00000021890",
  "ENSMUSG00000021891",
  "ENSMUSG00000021892",
  "ENSMUSG00000021893",
  "ENSMUSG00000021895",
  "ENSMUSG00000021898",
  "ENSMUSG00000021900",
  "ENSMUSG00000021901",
  "ENSMUSG00000021902",
  "ENSMUSG00000021903",
  "ENSMUSG00000021904",
  "ENSMUSG00000021905",
  "ENSMUSG00000021906",
  "ENSMUSG00000021907",
  "ENSMUSG00000021910",
  "ENSMUSG00000021911",
  "ENSMUSG00000021913",
  "ENSMUSG00000021916",
  "ENSMUSG00000021917",
  "ENSMUSG00000021918",
  "ENSMUSG00000021919",
  "ENSMUSG00000021922",
  "ENSMUSG00000021928",
  "ENSMUSG00000021929",
  "ENSMUSG00000021930",
  "ENSMUSG00000021932",
  "ENSMUSG00000021933",
  "ENSMUSG00000021936",
  "ENSMUSG00000021938",
  "ENSMUSG00000021939",
  "ENSMUSG00000021940",
  "ENSMUSG00000021943",
  "ENSMUSG00000021944",
  "ENSMUSG00000021945",
  "ENSMUSG00000021947",
  "ENSMUSG00000021948",
  "ENSMUSG00000021950",
  "ENSMUSG00000021951",
  "ENSMUSG00000021952",
  "ENSMUSG00000021953",
  "ENSMUSG00000021957",
  "ENSMUSG00000021958",
  "ENSMUSG00000021959",
  "ENSMUSG00000021961",
  "ENSMUSG00000021962",
  "ENSMUSG00000021963",
  "ENSMUSG00000021965",
  "ENSMUSG00000021966",
  "ENSMUSG00000021967",
  "ENSMUSG00000021969",
  "ENSMUSG00000021972",
  "ENSMUSG00000021973",
  "ENSMUSG00000021974",
  "ENSMUSG00000021975",
  "ENSMUSG00000021977",
  "ENSMUSG00000021978",
  "ENSMUSG00000021981",
  "ENSMUSG00000021982",
  "ENSMUSG00000021983",
  "ENSMUSG00000021986",
  "ENSMUSG00000021987",
  "ENSMUSG00000021990",
  "ENSMUSG00000021991",
  "ENSMUSG00000021993",
  "ENSMUSG00000021996",
  "ENSMUSG00000021997",
  "ENSMUSG00000021998",
  "ENSMUSG00000021999",
  "ENSMUSG00000022000",
  "ENSMUSG00000022002",
  "ENSMUSG00000022003",
  "ENSMUSG00000022008",
  "ENSMUSG00000022009",
  "ENSMUSG00000022010",
  "ENSMUSG00000022012",
  "ENSMUSG00000022013",
  "ENSMUSG00000022014",
  "ENSMUSG00000022015",
  "ENSMUSG00000022016",
  "ENSMUSG00000022019",
  "ENSMUSG00000022020",
  "ENSMUSG00000022021",
  "ENSMUSG00000022022",
  "ENSMUSG00000022023",
  "ENSMUSG00000022024",
  "ENSMUSG00000022025",
  "ENSMUSG00000022026",
  "ENSMUSG00000022031",
  "ENSMUSG00000022032",
  "ENSMUSG00000022033",
  "ENSMUSG00000022034",
  "ENSMUSG00000022035",
  "ENSMUSG00000022037",
  "ENSMUSG00000022039",
  "ENSMUSG00000022040",
  "ENSMUSG00000022041",
  "ENSMUSG00000022043",
  "ENSMUSG00000022044",
  "ENSMUSG00000022048",
  "ENSMUSG00000022051",
  "ENSMUSG00000022052",
  "ENSMUSG00000022053",
  "ENSMUSG00000022054",
  "ENSMUSG00000022055",
  "ENSMUSG00000022056",
  "ENSMUSG00000022057",
  "ENSMUSG00000022061",
  "ENSMUSG00000022064",
  "ENSMUSG00000022070",
  "ENSMUSG00000022074",
  "ENSMUSG00000022075",
  "ENSMUSG00000022076",
  "ENSMUSG00000022085",
  "ENSMUSG00000022089",
  "ENSMUSG00000022090",
  "ENSMUSG00000022091",
  "ENSMUSG00000022092",
  "ENSMUSG00000022094",
  "ENSMUSG00000022095",
  "ENSMUSG00000022096",
  "ENSMUSG00000022097",
  "ENSMUSG00000022098",
  "ENSMUSG00000022099",
  "ENSMUSG00000022100",
  "ENSMUSG00000022101",
  "ENSMUSG00000022102",
  "ENSMUSG00000022103",
  "ENSMUSG00000022105",
  "ENSMUSG00000022106",
  "ENSMUSG00000022108",
  "ENSMUSG00000022109",
  "ENSMUSG00000022110",
  "ENSMUSG00000022111",
  "ENSMUSG00000022112",
  "ENSMUSG00000022113",
  "ENSMUSG00000022114",
  "ENSMUSG00000022116",
  "ENSMUSG00000022119",
  "ENSMUSG00000022120",
  "ENSMUSG00000022122",
  "ENSMUSG00000022124",
  "ENSMUSG00000022125",
  "ENSMUSG00000022126",
  "ENSMUSG00000022129",
  "ENSMUSG00000022130",
  "ENSMUSG00000022132",
  "ENSMUSG00000022136",
  "ENSMUSG00000022139",
  "ENSMUSG00000022141",
  "ENSMUSG00000022142",
  "ENSMUSG00000022144",
  "ENSMUSG00000022146",
  "ENSMUSG00000022148",
  "ENSMUSG00000022149",
  "ENSMUSG00000022150",
  "ENSMUSG00000022151",
  "ENSMUSG00000022155",
  "ENSMUSG00000022156",
  "ENSMUSG00000022157",
  "ENSMUSG00000022159",
  "ENSMUSG00000022160",
  "ENSMUSG00000022174",
  "ENSMUSG00000022175",
  "ENSMUSG00000022176",
  "ENSMUSG00000022177",
  "ENSMUSG00000022178",
  "ENSMUSG00000022179",
  "ENSMUSG00000022180",
  "ENSMUSG00000022181",
  "ENSMUSG00000022184",
  "ENSMUSG00000022185",
  "ENSMUSG00000022186",
  "ENSMUSG00000022187",
  "ENSMUSG00000022191",
  "ENSMUSG00000022193",
  "ENSMUSG00000022197",
  "ENSMUSG00000022199",
  "ENSMUSG00000022200",
  "ENSMUSG00000022201",
  "ENSMUSG00000022203",
  "ENSMUSG00000022204",
  "ENSMUSG00000022205",
  "ENSMUSG00000022206",
  "ENSMUSG00000022208",
  "ENSMUSG00000022209",
  "ENSMUSG00000022210",
  "ENSMUSG00000022211",
  "ENSMUSG00000022212",
  "ENSMUSG00000022214",
  "ENSMUSG00000022215",
  "ENSMUSG00000022216",
  "ENSMUSG00000022217",
  "ENSMUSG00000022218",
  "ENSMUSG00000022219",
  "ENSMUSG00000022220",
  "ENSMUSG00000022221",
  "ENSMUSG00000022223",
  "ENSMUSG00000022225",
  "ENSMUSG00000022226",
  "ENSMUSG00000022227",
  "ENSMUSG00000022228",
  "ENSMUSG00000022229",
  "ENSMUSG00000022231",
  "ENSMUSG00000022234",
  "ENSMUSG00000022235",
  "ENSMUSG00000022236",
  "ENSMUSG00000022237",
  "ENSMUSG00000022240",
  "ENSMUSG00000022241",
  "ENSMUSG00000022243",
  "ENSMUSG00000022244",
  "ENSMUSG00000022245",
  "ENSMUSG00000022246",
  "ENSMUSG00000022247",
  "ENSMUSG00000022248",
  "ENSMUSG00000022249",
  "ENSMUSG00000022253",
  "ENSMUSG00000022255",
  "ENSMUSG00000022257",
  "ENSMUSG00000022261",
  "ENSMUSG00000022262",
  "ENSMUSG00000022263",
  "ENSMUSG00000022265",
  "ENSMUSG00000022269",
  "ENSMUSG00000022270",
  "ENSMUSG00000022272",
  "ENSMUSG00000022280",
  "ENSMUSG00000022285",
  "ENSMUSG00000022286",
  "ENSMUSG00000022288",
  "ENSMUSG00000022292",
  "ENSMUSG00000022295",
  "ENSMUSG00000022296",
  "ENSMUSG00000022297",
  "ENSMUSG00000022299",
  "ENSMUSG00000022300",
  "ENSMUSG00000022303",
  "ENSMUSG00000022304",
  "ENSMUSG00000022305",
  "ENSMUSG00000022306",
  "ENSMUSG00000022307",
  "ENSMUSG00000022309",
  "ENSMUSG00000022311",
  "ENSMUSG00000022312",
  "ENSMUSG00000022313",
  "ENSMUSG00000022314",
  "ENSMUSG00000022315",
  "ENSMUSG00000022321",
  "ENSMUSG00000022322",
  "ENSMUSG00000022323",
  "ENSMUSG00000022324",
  "ENSMUSG00000022325",
  "ENSMUSG00000022329",
  "ENSMUSG00000022330",
  "ENSMUSG00000022332",
  "ENSMUSG00000022335",
  "ENSMUSG00000022336",
  "ENSMUSG00000022338",
  "ENSMUSG00000022340",
  "ENSMUSG00000022342",
  "ENSMUSG00000022346",
  "ENSMUSG00000022347",
  "ENSMUSG00000022350",
  "ENSMUSG00000022351",
  "ENSMUSG00000022353",
  "ENSMUSG00000022354",
  "ENSMUSG00000022357",
  "ENSMUSG00000022358",
  "ENSMUSG00000022359",
  "ENSMUSG00000022360",
  "ENSMUSG00000022361",
  "ENSMUSG00000022364",
  "ENSMUSG00000022365",
  "ENSMUSG00000022366",
  "ENSMUSG00000022367",
  "ENSMUSG00000022369",
  "ENSMUSG00000022370",
  "ENSMUSG00000022371",
  "ENSMUSG00000022372",
  "ENSMUSG00000022375",
  "ENSMUSG00000022376",
  "ENSMUSG00000022377",
  "ENSMUSG00000022378",
  "ENSMUSG00000022382",
  "ENSMUSG00000022383",
  "ENSMUSG00000022385",
  "ENSMUSG00000022386",
  "ENSMUSG00000022387",
  "ENSMUSG00000022388",
  "ENSMUSG00000022389",
  "ENSMUSG00000022390",
  "ENSMUSG00000022391",
  "ENSMUSG00000022394",
  "ENSMUSG00000022400",
  "ENSMUSG00000022401",
  "ENSMUSG00000022403",
  "ENSMUSG00000022404",
  "ENSMUSG00000022407",
  "ENSMUSG00000022408",
  "ENSMUSG00000022412",
  "ENSMUSG00000022414",
  "ENSMUSG00000022415",
  "ENSMUSG00000022416",
  "ENSMUSG00000022419",
  "ENSMUSG00000022420",
  "ENSMUSG00000022421",
  "ENSMUSG00000022422",
  "ENSMUSG00000022425",
  "ENSMUSG00000022426",
  "ENSMUSG00000022427",
  "ENSMUSG00000022428",
  "ENSMUSG00000022429",
  "ENSMUSG00000022431",
  "ENSMUSG00000022432",
  "ENSMUSG00000022433",
  "ENSMUSG00000022434",
  "ENSMUSG00000022435",
  "ENSMUSG00000022436",
  "ENSMUSG00000022437",
  "ENSMUSG00000022438",
  "ENSMUSG00000022439",
  "ENSMUSG00000022440",
  "ENSMUSG00000022441",
  "ENSMUSG00000022442",
  "ENSMUSG00000022443",
  "ENSMUSG00000022445",
  "ENSMUSG00000022449",
  "ENSMUSG00000022450",
  "ENSMUSG00000022451",
  "ENSMUSG00000022452",
  "ENSMUSG00000022453",
  "ENSMUSG00000022454",
  "ENSMUSG00000022455",
  "ENSMUSG00000022456",
  "ENSMUSG00000022462",
  "ENSMUSG00000022463",
  "ENSMUSG00000022464",
  "ENSMUSG00000022466",
  "ENSMUSG00000022468",
  "ENSMUSG00000022469",
  "ENSMUSG00000022471",
  "ENSMUSG00000022472",
  "ENSMUSG00000022474",
  "ENSMUSG00000022475",
  "ENSMUSG00000022476",
  "ENSMUSG00000022477",
  "ENSMUSG00000022479",
  "ENSMUSG00000022483",
  "ENSMUSG00000022484",
  "ENSMUSG00000022485",
  "ENSMUSG00000022487",
  "ENSMUSG00000022488",
  "ENSMUSG00000022489",
  "ENSMUSG00000022490",
  "ENSMUSG00000022491",
  "ENSMUSG00000022494",
  "ENSMUSG00000022496",
  "ENSMUSG00000022498",
  "ENSMUSG00000022500",
  "ENSMUSG00000022501",
  "ENSMUSG00000022503",
  "ENSMUSG00000022504",
  "ENSMUSG00000022505",
  "ENSMUSG00000022507",
  "ENSMUSG00000022508",
  "ENSMUSG00000022510",
  "ENSMUSG00000022512",
  "ENSMUSG00000022514",
  "ENSMUSG00000022515",
  "ENSMUSG00000022516",
  "ENSMUSG00000022517",
  "ENSMUSG00000022518",
  "ENSMUSG00000022519",
  "ENSMUSG00000022521",
  "ENSMUSG00000022523",
  "ENSMUSG00000022525",
  "ENSMUSG00000022526",
  "ENSMUSG00000022528",
  "ENSMUSG00000022529",
  "ENSMUSG00000022533",
  "ENSMUSG00000022534",
  "ENSMUSG00000022536",
  "ENSMUSG00000022537",
  "ENSMUSG00000022538",
  "ENSMUSG00000022540",
  "ENSMUSG00000022542",
  "ENSMUSG00000022544",
  "ENSMUSG00000022545",
  "ENSMUSG00000022546",
  "ENSMUSG00000022548",
  "ENSMUSG00000022550",
  "ENSMUSG00000022551",
  "ENSMUSG00000022552",
  "ENSMUSG00000022553",
  "ENSMUSG00000022554",
  "ENSMUSG00000022555",
  "ENSMUSG00000022556",
  "ENSMUSG00000022557",
  "ENSMUSG00000022558",
  "ENSMUSG00000022559",
  "ENSMUSG00000022560",
  "ENSMUSG00000022561",
  "ENSMUSG00000022562",
  "ENSMUSG00000022564",
  "ENSMUSG00000022565",
  "ENSMUSG00000022568",
  "ENSMUSG00000022570",
  "ENSMUSG00000022571",
  "ENSMUSG00000022574",
  "ENSMUSG00000022575",
  "ENSMUSG00000022577",
  "ENSMUSG00000022579",
  "ENSMUSG00000022580",
  "ENSMUSG00000022582",
  "ENSMUSG00000022583",
  "ENSMUSG00000022586",
  "ENSMUSG00000022587",
  "ENSMUSG00000022589",
  "ENSMUSG00000022591",
  "ENSMUSG00000022594",
  "ENSMUSG00000022595",
  "ENSMUSG00000022596",
  "ENSMUSG00000022598",
  "ENSMUSG00000022601",
  "ENSMUSG00000022602",
  "ENSMUSG00000022603",
  "ENSMUSG00000022604",
  "ENSMUSG00000022607",
  "ENSMUSG00000022610",
  "ENSMUSG00000022613",
  "ENSMUSG00000022614",
  "ENSMUSG00000022619",
  "ENSMUSG00000022620",
  "ENSMUSG00000022621",
  "ENSMUSG00000022622",
  "ENSMUSG00000022623",
  "ENSMUSG00000022629",
  "ENSMUSG00000022634",
  "ENSMUSG00000022635",
  "ENSMUSG00000022636",
  "ENSMUSG00000022637",
  "ENSMUSG00000022639",
  "ENSMUSG00000022641",
  "ENSMUSG00000022650",
  "ENSMUSG00000022651",
  "ENSMUSG00000022652",
  "ENSMUSG00000022656",
  "ENSMUSG00000022657",
  "ENSMUSG00000022658",
  "ENSMUSG00000022659",
  "ENSMUSG00000022661",
  "ENSMUSG00000022663",
  "ENSMUSG00000022664",
  "ENSMUSG00000022665",
  "ENSMUSG00000022667",
  "ENSMUSG00000022668",
  "ENSMUSG00000022671",
  "ENSMUSG00000022672",
  "ENSMUSG00000022673",
  "ENSMUSG00000022674",
  "ENSMUSG00000022676",
  "ENSMUSG00000022677",
  "ENSMUSG00000022678",
  "ENSMUSG00000022679",
  "ENSMUSG00000022680",
  "ENSMUSG00000022681",
  "ENSMUSG00000022682",
  "ENSMUSG00000022683",
  "ENSMUSG00000022684",
  "ENSMUSG00000022685",
  "ENSMUSG00000022686",
  "ENSMUSG00000022687",
  "ENSMUSG00000022696",
  "ENSMUSG00000022698",
  "ENSMUSG00000022701",
  "ENSMUSG00000022704",
  "ENSMUSG00000022705",
  "ENSMUSG00000022706",
  "ENSMUSG00000022707",
  "ENSMUSG00000022708",
  "ENSMUSG00000022710",
  "ENSMUSG00000022711",
  "ENSMUSG00000022715",
  "ENSMUSG00000022718",
  "ENSMUSG00000022721",
  "ENSMUSG00000022722",
  "ENSMUSG00000022723",
  "ENSMUSG00000022724",
  "ENSMUSG00000022738",
  "ENSMUSG00000022742",
  "ENSMUSG00000022744",
  "ENSMUSG00000022747",
  "ENSMUSG00000022748",
  "ENSMUSG00000022749",
  "ENSMUSG00000022750",
  "ENSMUSG00000022751",
  "ENSMUSG00000022752",
  "ENSMUSG00000022753",
  "ENSMUSG00000022754",
  "ENSMUSG00000022755",
  "ENSMUSG00000022756",
  "ENSMUSG00000022757",
  "ENSMUSG00000022758",
  "ENSMUSG00000022759",
  "ENSMUSG00000022760",
  "ENSMUSG00000022761",
  "ENSMUSG00000022762",
  "ENSMUSG00000022763",
  "ENSMUSG00000022765",
  "ENSMUSG00000022766",
  "ENSMUSG00000022768",
  "ENSMUSG00000022769",
  "ENSMUSG00000022770",
  "ENSMUSG00000022771",
  "ENSMUSG00000022772",
  "ENSMUSG00000022773",
  "ENSMUSG00000022774",
  "ENSMUSG00000022779",
  "ENSMUSG00000022780",
  "ENSMUSG00000022781",
  "ENSMUSG00000022783",
  "ENSMUSG00000022787",
  "ENSMUSG00000022788",
  "ENSMUSG00000022789",
  "ENSMUSG00000022790",
  "ENSMUSG00000022791",
  "ENSMUSG00000022792",
  "ENSMUSG00000022793",
  "ENSMUSG00000022797",
  "ENSMUSG00000022798",
  "ENSMUSG00000022799",
  "ENSMUSG00000022800",
  "ENSMUSG00000022802",
  "ENSMUSG00000022803",
  "ENSMUSG00000022805",
  "ENSMUSG00000022807",
  "ENSMUSG00000022808",
  "ENSMUSG00000022809",
  "ENSMUSG00000022811",
  "ENSMUSG00000022812",
  "ENSMUSG00000022814",
  "ENSMUSG00000022816",
  "ENSMUSG00000022817",
  "ENSMUSG00000022818",
  "ENSMUSG00000022820",
  "ENSMUSG00000022821",
  "ENSMUSG00000022822",
  "ENSMUSG00000022824",
  "ENSMUSG00000022827",
  "ENSMUSG00000022828",
  "ENSMUSG00000022829",
  "ENSMUSG00000022831",
  "ENSMUSG00000022832",
  "ENSMUSG00000022833",
  "ENSMUSG00000022836",
  "ENSMUSG00000022837",
  "ENSMUSG00000022838",
  "ENSMUSG00000022840",
  "ENSMUSG00000022841",
  "ENSMUSG00000022842",
  "ENSMUSG00000022843",
  "ENSMUSG00000022844",
  "ENSMUSG00000022847",
  "ENSMUSG00000022848",
  "ENSMUSG00000022849",
  "ENSMUSG00000022853",
  "ENSMUSG00000022855",
  "ENSMUSG00000022856",
  "ENSMUSG00000022857",
  "ENSMUSG00000022860",
  "ENSMUSG00000022861",
  "ENSMUSG00000022863",
  "ENSMUSG00000022864",
  "ENSMUSG00000022865",
  "ENSMUSG00000022867",
  "ENSMUSG00000022868",
  "ENSMUSG00000022871",
  "ENSMUSG00000022876",
  "ENSMUSG00000022877",
  "ENSMUSG00000022878",
  "ENSMUSG00000022881",
  "ENSMUSG00000022883",
  "ENSMUSG00000022884",
  "ENSMUSG00000022885",
  "ENSMUSG00000022886",
  "ENSMUSG00000022887",
  "ENSMUSG00000022889",
  "ENSMUSG00000022890",
  "ENSMUSG00000022892",
  "ENSMUSG00000022893",
  "ENSMUSG00000022894",
  "ENSMUSG00000022895",
  "ENSMUSG00000022897",
  "ENSMUSG00000022898",
  "ENSMUSG00000022899",
  "ENSMUSG00000022900",
  "ENSMUSG00000022901",
  "ENSMUSG00000022905",
  "ENSMUSG00000022906",
  "ENSMUSG00000022911",
  "ENSMUSG00000022912",
  "ENSMUSG00000022913",
  "ENSMUSG00000022914",
  "ENSMUSG00000022931",
  "ENSMUSG00000022935",
  "ENSMUSG00000022938",
  "ENSMUSG00000022940",
  "ENSMUSG00000022941",
  "ENSMUSG00000022945",
  "ENSMUSG00000022946",
  "ENSMUSG00000022947",
  "ENSMUSG00000022948",
  "ENSMUSG00000022949",
  "ENSMUSG00000022951",
  "ENSMUSG00000022952",
  "ENSMUSG00000022957",
  "ENSMUSG00000022960",
  "ENSMUSG00000022961",
  "ENSMUSG00000022962",
  "ENSMUSG00000022964",
  "ENSMUSG00000022965",
  "ENSMUSG00000022967",
  "ENSMUSG00000022969",
  "ENSMUSG00000022972",
  "ENSMUSG00000022973",
  "ENSMUSG00000022974",
  "ENSMUSG00000022978",
  "ENSMUSG00000022982",
  "ENSMUSG00000022983",
  "ENSMUSG00000022986",
  "ENSMUSG00000022987",
  "ENSMUSG00000022991",
  "ENSMUSG00000022992",
  "ENSMUSG00000022993",
  "ENSMUSG00000022994",
  "ENSMUSG00000022995",
  "ENSMUSG00000022996",
  "ENSMUSG00000022997",
  "ENSMUSG00000022999",
  "ENSMUSG00000023000",
  "ENSMUSG00000023004",
  "ENSMUSG00000023007",
  "ENSMUSG00000023008",
  "ENSMUSG00000023009",
  "ENSMUSG00000023010",
  "ENSMUSG00000023013",
  "ENSMUSG00000023015",
  "ENSMUSG00000023017",
  "ENSMUSG00000023018",
  "ENSMUSG00000023019",
  "ENSMUSG00000023020",
  "ENSMUSG00000023021",
  "ENSMUSG00000023022",
  "ENSMUSG00000023025",
  "ENSMUSG00000023026",
  "ENSMUSG00000023027",
  "ENSMUSG00000023030",
  "ENSMUSG00000023031",
  "ENSMUSG00000023032",
  "ENSMUSG00000023033",
  "ENSMUSG00000023034",
  "ENSMUSG00000023039",
  "ENSMUSG00000023041",
  "ENSMUSG00000023043",
  "ENSMUSG00000023044",
  "ENSMUSG00000023045",
  "ENSMUSG00000023046",
  "ENSMUSG00000023047",
  "ENSMUSG00000023048",
  "ENSMUSG00000023050",
  "ENSMUSG00000023051",
  "ENSMUSG00000023055",
  "ENSMUSG00000023057",
  "ENSMUSG00000023064",
  "ENSMUSG00000023066",
  "ENSMUSG00000023067",
  "ENSMUSG00000023068",
  "ENSMUSG00000023070",
  "ENSMUSG00000023072",
  "ENSMUSG00000023073",
  "ENSMUSG00000023074",
  "ENSMUSG00000023075",
  "ENSMUSG00000023078",
  "ENSMUSG00000023079",
  "ENSMUSG00000023083",
  "ENSMUSG00000023084",
  "ENSMUSG00000023087",
  "ENSMUSG00000023088",
  "ENSMUSG00000023089",
  "ENSMUSG00000023092",
  "ENSMUSG00000023093",
  "ENSMUSG00000023094",
  "ENSMUSG00000023104",
  "ENSMUSG00000023106",
  "ENSMUSG00000023110",
  "ENSMUSG00000023118",
  "ENSMUSG00000023120",
  "ENSMUSG00000023122",
  "ENSMUSG00000023132",
  "ENSMUSG00000023140",
  "ENSMUSG00000023143",
  "ENSMUSG00000023147",
  "ENSMUSG00000023150",
  "ENSMUSG00000023151",
  "ENSMUSG00000023153",
  "ENSMUSG00000023156",
  "ENSMUSG00000023159",
  "ENSMUSG00000023169",
  "ENSMUSG00000023170",
  "ENSMUSG00000023175",
  "ENSMUSG00000023176",
  "ENSMUSG00000023185",
  "ENSMUSG00000023186",
  "ENSMUSG00000023191",
  "ENSMUSG00000023192",
  "ENSMUSG00000023206",
  "ENSMUSG00000023210",
  "ENSMUSG00000023216",
  "ENSMUSG00000023224",
  "ENSMUSG00000023232",
  "ENSMUSG00000023235",
  "ENSMUSG00000023236",
  "ENSMUSG00000023243",
  "ENSMUSG00000023247",
  "ENSMUSG00000023249",
  "ENSMUSG00000023257",
  "ENSMUSG00000023259",
  "ENSMUSG00000023262",
  "ENSMUSG00000023267",
  "ENSMUSG00000023272",
  "ENSMUSG00000023274",
  "ENSMUSG00000023277",
  "ENSMUSG00000023279",
  "ENSMUSG00000023284",
  "ENSMUSG00000023286",
  "ENSMUSG00000023289",
  "ENSMUSG00000023307",
  "ENSMUSG00000023328",
  "ENSMUSG00000023330",
  "ENSMUSG00000023333",
  "ENSMUSG00000023336",
  "ENSMUSG00000023345",
  "ENSMUSG00000023348",
  "ENSMUSG00000023349",
  "ENSMUSG00000023353",
  "ENSMUSG00000023367",
  "ENSMUSG00000023387",
  "ENSMUSG00000023391",
  "ENSMUSG00000023393",
  "ENSMUSG00000023403",
  "ENSMUSG00000023411",
  "ENSMUSG00000023433",
  "ENSMUSG00000023439",
  "ENSMUSG00000023443",
  "ENSMUSG00000023452",
  "ENSMUSG00000023456",
  "ENSMUSG00000023460",
  "ENSMUSG00000023467",
  "ENSMUSG00000023473",
  "ENSMUSG00000023484",
  "ENSMUSG00000023495",
  "ENSMUSG00000023505",
  "ENSMUSG00000023571",
  "ENSMUSG00000023572",
  "ENSMUSG00000023577",
  "ENSMUSG00000023707",
  "ENSMUSG00000023723",
  "ENSMUSG00000023755",
  "ENSMUSG00000023764",
  "ENSMUSG00000023781",
  "ENSMUSG00000023791",
  "ENSMUSG00000023800",
  "ENSMUSG00000023802",
  "ENSMUSG00000023805",
  "ENSMUSG00000023809",
  "ENSMUSG00000023826",
  "ENSMUSG00000023827",
  "ENSMUSG00000023828",
  "ENSMUSG00000023829",
  "ENSMUSG00000023830",
  "ENSMUSG00000023832",
  "ENSMUSG00000023845",
  "ENSMUSG00000023852",
  "ENSMUSG00000023861",
  "ENSMUSG00000023868",
  "ENSMUSG00000023873",
  "ENSMUSG00000023882",
  "ENSMUSG00000023883",
  "ENSMUSG00000023885",
  "ENSMUSG00000023886",
  "ENSMUSG00000023892",
  "ENSMUSG00000023902",
  "ENSMUSG00000023903",
  "ENSMUSG00000023904",
  "ENSMUSG00000023905",
  "ENSMUSG00000023906",
  "ENSMUSG00000023908",
  "ENSMUSG00000023909",
  "ENSMUSG00000023911",
  "ENSMUSG00000023912",
  "ENSMUSG00000023913",
  "ENSMUSG00000023914",
  "ENSMUSG00000023915",
  "ENSMUSG00000023918",
  "ENSMUSG00000023919",
  "ENSMUSG00000023921",
  "ENSMUSG00000023923",
  "ENSMUSG00000023926",
  "ENSMUSG00000023927",
  "ENSMUSG00000023930",
  "ENSMUSG00000023931",
  "ENSMUSG00000023932",
  "ENSMUSG00000023935",
  "ENSMUSG00000023938",
  "ENSMUSG00000023939",
  "ENSMUSG00000023940",
  "ENSMUSG00000023942",
  "ENSMUSG00000023943",
  "ENSMUSG00000023944",
  "ENSMUSG00000023945",
  "ENSMUSG00000023947",
  "ENSMUSG00000023949",
  "ENSMUSG00000023951",
  "ENSMUSG00000023952",
  "ENSMUSG00000023953",
  "ENSMUSG00000023959",
  "ENSMUSG00000023960",
  "ENSMUSG00000023961",
  "ENSMUSG00000023963",
  "ENSMUSG00000023964",
  "ENSMUSG00000023965",
  "ENSMUSG00000023966",
  "ENSMUSG00000023967",
  "ENSMUSG00000023968",
  "ENSMUSG00000023971",
  "ENSMUSG00000023972",
  "ENSMUSG00000023973",
  "ENSMUSG00000023977",
  "ENSMUSG00000023978",
  "ENSMUSG00000023979",
  "ENSMUSG00000023980",
  "ENSMUSG00000023987",
  "ENSMUSG00000023988",
  "ENSMUSG00000023990",
  "ENSMUSG00000023991",
  "ENSMUSG00000023992",
  "ENSMUSG00000023993",
  "ENSMUSG00000023994",
  "ENSMUSG00000023995",
  "ENSMUSG00000023999",
  "ENSMUSG00000024006",
  "ENSMUSG00000024007",
  "ENSMUSG00000024008",
  "ENSMUSG00000024011",
  "ENSMUSG00000024012",
  "ENSMUSG00000024013",
  "ENSMUSG00000024014",
  "ENSMUSG00000024018",
  "ENSMUSG00000024026",
  "ENSMUSG00000024027",
  "ENSMUSG00000024028",
  "ENSMUSG00000024029",
  "ENSMUSG00000024030",
  "ENSMUSG00000024032",
  "ENSMUSG00000024033",
  "ENSMUSG00000024034",
  "ENSMUSG00000024036",
  "ENSMUSG00000024037",
  "ENSMUSG00000024038",
  "ENSMUSG00000024039",
  "ENSMUSG00000024041",
  "ENSMUSG00000024042",
  "ENSMUSG00000024043",
  "ENSMUSG00000024044",
  "ENSMUSG00000024045",
  "ENSMUSG00000024048",
  "ENSMUSG00000024049",
  "ENSMUSG00000024050",
  "ENSMUSG00000024052",
  "ENSMUSG00000024053",
  "ENSMUSG00000024054",
  "ENSMUSG00000024055",
  "ENSMUSG00000024056",
  "ENSMUSG00000024059",
  "ENSMUSG00000024063",
  "ENSMUSG00000024064",
  "ENSMUSG00000024065",
  "ENSMUSG00000024066",
  "ENSMUSG00000024067",
  "ENSMUSG00000024068",
  "ENSMUSG00000024069",
  "ENSMUSG00000024070",
  "ENSMUSG00000024072",
  "ENSMUSG00000024073",
  "ENSMUSG00000024074",
  "ENSMUSG00000024076",
  "ENSMUSG00000024077",
  "ENSMUSG00000024078",
  "ENSMUSG00000024079",
  "ENSMUSG00000024081",
  "ENSMUSG00000024082",
  "ENSMUSG00000024083",
  "ENSMUSG00000024084",
  "ENSMUSG00000024085",
  "ENSMUSG00000024087",
  "ENSMUSG00000024088",
  "ENSMUSG00000024091",
  "ENSMUSG00000024095",
  "ENSMUSG00000024096",
  "ENSMUSG00000024097",
  "ENSMUSG00000024098",
  "ENSMUSG00000024099",
  "ENSMUSG00000024101",
  "ENSMUSG00000024104",
  "ENSMUSG00000024105",
  "ENSMUSG00000024107",
  "ENSMUSG00000024109",
  "ENSMUSG00000024112",
  "ENSMUSG00000024114",
  "ENSMUSG00000024116",
  "ENSMUSG00000024118",
  "ENSMUSG00000024120",
  "ENSMUSG00000024121",
  "ENSMUSG00000024122",
  "ENSMUSG00000024124",
  "ENSMUSG00000024125",
  "ENSMUSG00000024127",
  "ENSMUSG00000024128",
  "ENSMUSG00000024130",
  "ENSMUSG00000024131",
  "ENSMUSG00000024132",
  "ENSMUSG00000024134",
  "ENSMUSG00000024135",
  "ENSMUSG00000024136",
  "ENSMUSG00000024137",
  "ENSMUSG00000024140",
  "ENSMUSG00000024142",
  "ENSMUSG00000024143",
  "ENSMUSG00000024145",
  "ENSMUSG00000024146",
  "ENSMUSG00000024150",
  "ENSMUSG00000024151",
  "ENSMUSG00000024154",
  "ENSMUSG00000024155",
  "ENSMUSG00000024158",
  "ENSMUSG00000024160",
  "ENSMUSG00000024163",
  "ENSMUSG00000024164",
  "ENSMUSG00000024165",
  "ENSMUSG00000024168",
  "ENSMUSG00000024169",
  "ENSMUSG00000024170",
  "ENSMUSG00000024171",
  "ENSMUSG00000024172",
  "ENSMUSG00000024173",
  "ENSMUSG00000024174",
  "ENSMUSG00000024175",
  "ENSMUSG00000024176",
  "ENSMUSG00000024177",
  "ENSMUSG00000024180",
  "ENSMUSG00000024181",
  "ENSMUSG00000024182",
  "ENSMUSG00000024184",
  "ENSMUSG00000024186",
  "ENSMUSG00000024187",
  "ENSMUSG00000024188",
  "ENSMUSG00000024190",
  "ENSMUSG00000024191",
  "ENSMUSG00000024193",
  "ENSMUSG00000024194",
  "ENSMUSG00000024197",
  "ENSMUSG00000024201",
  "ENSMUSG00000024206",
  "ENSMUSG00000024207",
  "ENSMUSG00000024208",
  "ENSMUSG00000024209",
  "ENSMUSG00000024210",
  "ENSMUSG00000024211",
  "ENSMUSG00000024212",
  "ENSMUSG00000024213",
  "ENSMUSG00000024215",
  "ENSMUSG00000024217",
  "ENSMUSG00000024218",
  "ENSMUSG00000024219",
  "ENSMUSG00000024220",
  "ENSMUSG00000024222",
  "ENSMUSG00000024223",
  "ENSMUSG00000024224",
  "ENSMUSG00000024225",
  "ENSMUSG00000024227",
  "ENSMUSG00000024228",
  "ENSMUSG00000024231",
  "ENSMUSG00000024232",
  "ENSMUSG00000024233",
  "ENSMUSG00000024234",
  "ENSMUSG00000024235",
  "ENSMUSG00000024236",
  "ENSMUSG00000024238",
  "ENSMUSG00000024240",
  "ENSMUSG00000024241",
  "ENSMUSG00000024242",
  "ENSMUSG00000024245",
  "ENSMUSG00000024246",
  "ENSMUSG00000024247",
  "ENSMUSG00000024248",
  "ENSMUSG00000024251",
  "ENSMUSG00000024253",
  "ENSMUSG00000024254",
  "ENSMUSG00000024256",
  "ENSMUSG00000024258",
  "ENSMUSG00000024259",
  "ENSMUSG00000024260",
  "ENSMUSG00000024261",
  "ENSMUSG00000024266",
  "ENSMUSG00000024268",
  "ENSMUSG00000024269",
  "ENSMUSG00000024270",
  "ENSMUSG00000024271",
  "ENSMUSG00000024273",
  "ENSMUSG00000024276",
  "ENSMUSG00000024277",
  "ENSMUSG00000024283",
  "ENSMUSG00000024286",
  "ENSMUSG00000024287",
  "ENSMUSG00000024290",
  "ENSMUSG00000024292",
  "ENSMUSG00000024293",
  "ENSMUSG00000024294",
  "ENSMUSG00000024298",
  "ENSMUSG00000024299",
  "ENSMUSG00000024300",
  "ENSMUSG00000024301",
  "ENSMUSG00000024302",
  "ENSMUSG00000024304",
  "ENSMUSG00000024306",
  "ENSMUSG00000024308",
  "ENSMUSG00000024309",
  "ENSMUSG00000024312",
  "ENSMUSG00000024313",
  "ENSMUSG00000024317",
  "ENSMUSG00000024319",
  "ENSMUSG00000024325",
  "ENSMUSG00000024330",
  "ENSMUSG00000024331",
  "ENSMUSG00000024334",
  "ENSMUSG00000024335",
  "ENSMUSG00000024338",
  "ENSMUSG00000024339",
  "ENSMUSG00000024340",
  "ENSMUSG00000024346",
  "ENSMUSG00000024349",
  "ENSMUSG00000024350",
  "ENSMUSG00000024354",
  "ENSMUSG00000024357",
  "ENSMUSG00000024359",
  "ENSMUSG00000024360",
  "ENSMUSG00000024365",
  "ENSMUSG00000024366",
  "ENSMUSG00000024369",
  "ENSMUSG00000024370",
  "ENSMUSG00000024371",
  "ENSMUSG00000024376",
  "ENSMUSG00000024378",
  "ENSMUSG00000024379",
  "ENSMUSG00000024381",
  "ENSMUSG00000024382",
  "ENSMUSG00000024383",
  "ENSMUSG00000024384",
  "ENSMUSG00000024386",
  "ENSMUSG00000024387",
  "ENSMUSG00000024388",
  "ENSMUSG00000024391",
  "ENSMUSG00000024392",
  "ENSMUSG00000024393",
  "ENSMUSG00000024395",
  "ENSMUSG00000024397",
  "ENSMUSG00000024399",
  "ENSMUSG00000024400",
  "ENSMUSG00000024401",
  "ENSMUSG00000024402",
  "ENSMUSG00000024403",
  "ENSMUSG00000024404",
  "ENSMUSG00000024406",
  "ENSMUSG00000024409",
  "ENSMUSG00000024410",
  "ENSMUSG00000024411",
  "ENSMUSG00000024413",
  "ENSMUSG00000024414",
  "ENSMUSG00000024420",
  "ENSMUSG00000024421",
  "ENSMUSG00000024422",
  "ENSMUSG00000024423",
  "ENSMUSG00000024424",
  "ENSMUSG00000024425",
  "ENSMUSG00000024426",
  "ENSMUSG00000024427",
  "ENSMUSG00000024429",
  "ENSMUSG00000024430",
  "ENSMUSG00000024431",
  "ENSMUSG00000024436",
  "ENSMUSG00000024440",
  "ENSMUSG00000024442",
  "ENSMUSG00000024446",
  "ENSMUSG00000024448",
  "ENSMUSG00000024451",
  "ENSMUSG00000024454",
  "ENSMUSG00000024456",
  "ENSMUSG00000024457",
  "ENSMUSG00000024459",
  "ENSMUSG00000024462",
  "ENSMUSG00000024471",
  "ENSMUSG00000024472",
  "ENSMUSG00000024474",
  "ENSMUSG00000024477",
  "ENSMUSG00000024479",
  "ENSMUSG00000024480",
  "ENSMUSG00000024481",
  "ENSMUSG00000024483",
  "ENSMUSG00000024485",
  "ENSMUSG00000024486",
  "ENSMUSG00000024487",
  "ENSMUSG00000024493",
  "ENSMUSG00000024497",
  "ENSMUSG00000024500",
  "ENSMUSG00000024501",
  "ENSMUSG00000024502",
  "ENSMUSG00000024503",
  "ENSMUSG00000024505",
  "ENSMUSG00000024507",
  "ENSMUSG00000024510",
  "ENSMUSG00000024511",
  "ENSMUSG00000024512",
  "ENSMUSG00000024513",
  "ENSMUSG00000024515",
  "ENSMUSG00000024516",
  "ENSMUSG00000024517",
  "ENSMUSG00000024518",
  "ENSMUSG00000024519",
  "ENSMUSG00000024521",
  "ENSMUSG00000024524",
  "ENSMUSG00000024525",
  "ENSMUSG00000024526",
  "ENSMUSG00000024527",
  "ENSMUSG00000024528",
  "ENSMUSG00000024529",
  "ENSMUSG00000024530",
  "ENSMUSG00000024532",
  "ENSMUSG00000024533",
  "ENSMUSG00000024534",
  "ENSMUSG00000024535",
  "ENSMUSG00000024537",
  "ENSMUSG00000024538",
  "ENSMUSG00000024539",
  "ENSMUSG00000024542",
  "ENSMUSG00000024544",
  "ENSMUSG00000024546",
  "ENSMUSG00000024548",
  "ENSMUSG00000024552",
  "ENSMUSG00000024553",
  "ENSMUSG00000024556",
  "ENSMUSG00000024558",
  "ENSMUSG00000024560",
  "ENSMUSG00000024561",
  "ENSMUSG00000024563",
  "ENSMUSG00000024565",
  "ENSMUSG00000024566",
  "ENSMUSG00000024570",
  "ENSMUSG00000024571",
  "ENSMUSG00000024575",
  "ENSMUSG00000024576",
  "ENSMUSG00000024578",
  "ENSMUSG00000024579",
  "ENSMUSG00000024580",
  "ENSMUSG00000024581",
  "ENSMUSG00000024583",
  "ENSMUSG00000024587",
  "ENSMUSG00000024588",
  "ENSMUSG00000024589",
  "ENSMUSG00000024590",
  "ENSMUSG00000024592",
  "ENSMUSG00000024593",
  "ENSMUSG00000024594",
  "ENSMUSG00000024597",
  "ENSMUSG00000024598",
  "ENSMUSG00000024601",
  "ENSMUSG00000024603",
  "ENSMUSG00000024604",
  "ENSMUSG00000024608",
  "ENSMUSG00000024610",
  "ENSMUSG00000024613",
  "ENSMUSG00000024614",
  "ENSMUSG00000024617",
  "ENSMUSG00000024619",
  "ENSMUSG00000024620",
  "ENSMUSG00000024621",
  "ENSMUSG00000024622",
  "ENSMUSG00000024639",
  "ENSMUSG00000024640",
  "ENSMUSG00000024642",
  "ENSMUSG00000024644",
  "ENSMUSG00000024645",
  "ENSMUSG00000024646",
  "ENSMUSG00000024650",
  "ENSMUSG00000024653",
  "ENSMUSG00000024654",
  "ENSMUSG00000024658",
  "ENSMUSG00000024659",
  "ENSMUSG00000024660",
  "ENSMUSG00000024661",
  "ENSMUSG00000024663",
  "ENSMUSG00000024664",
  "ENSMUSG00000024665",
  "ENSMUSG00000024666",
  "ENSMUSG00000024667",
  "ENSMUSG00000024668",
  "ENSMUSG00000024669",
  "ENSMUSG00000024670",
  "ENSMUSG00000024672",
  "ENSMUSG00000024673",
  "ENSMUSG00000024675",
  "ENSMUSG00000024677",
  "ENSMUSG00000024678",
  "ENSMUSG00000024679",
  "ENSMUSG00000024680",
  "ENSMUSG00000024681",
  "ENSMUSG00000024682",
  "ENSMUSG00000024683",
  "ENSMUSG00000024687",
  "ENSMUSG00000024691",
  "ENSMUSG00000024694",
  "ENSMUSG00000024696",
  "ENSMUSG00000024697",
  "ENSMUSG00000024712",
  "ENSMUSG00000024713",
  "ENSMUSG00000024725",
  "ENSMUSG00000024726",
  "ENSMUSG00000024727",
  "ENSMUSG00000024728",
  "ENSMUSG00000024729",
  "ENSMUSG00000024730",
  "ENSMUSG00000024731",
  "ENSMUSG00000024732",
  "ENSMUSG00000024734",
  "ENSMUSG00000024735",
  "ENSMUSG00000024736",
  "ENSMUSG00000024737",
  "ENSMUSG00000024738",
  "ENSMUSG00000024740",
  "ENSMUSG00000024742",
  "ENSMUSG00000024743",
  "ENSMUSG00000024747",
  "ENSMUSG00000024749",
  "ENSMUSG00000024750",
  "ENSMUSG00000024754",
  "ENSMUSG00000024757",
  "ENSMUSG00000024758",
  "ENSMUSG00000024759",
  "ENSMUSG00000024764",
  "ENSMUSG00000024766",
  "ENSMUSG00000024767",
  "ENSMUSG00000024768",
  "ENSMUSG00000024769",
  "ENSMUSG00000024770",
  "ENSMUSG00000024771",
  "ENSMUSG00000024772",
  "ENSMUSG00000024773",
  "ENSMUSG00000024774",
  "ENSMUSG00000024776",
  "ENSMUSG00000024777",
  "ENSMUSG00000024778",
  "ENSMUSG00000024780",
  "ENSMUSG00000024781",
  "ENSMUSG00000024782",
  "ENSMUSG00000024784",
  "ENSMUSG00000024785",
  "ENSMUSG00000024786",
  "ENSMUSG00000024787",
  "ENSMUSG00000024789",
  "ENSMUSG00000024790",
  "ENSMUSG00000024791",
  "ENSMUSG00000024792",
  "ENSMUSG00000024793",
  "ENSMUSG00000024795",
  "ENSMUSG00000024798",
  "ENSMUSG00000024799",
  "ENSMUSG00000024800",
  "ENSMUSG00000024803",
  "ENSMUSG00000024805",
  "ENSMUSG00000024806",
  "ENSMUSG00000024807",
  "ENSMUSG00000024810",
  "ENSMUSG00000024811",
  "ENSMUSG00000024812",
  "ENSMUSG00000024815",
  "ENSMUSG00000024816",
  "ENSMUSG00000024817",
  "ENSMUSG00000024818",
  "ENSMUSG00000024824",
  "ENSMUSG00000024826",
  "ENSMUSG00000024827",
  "ENSMUSG00000024829",
  "ENSMUSG00000024830",
  "ENSMUSG00000024831",
  "ENSMUSG00000024833",
  "ENSMUSG00000024835",
  "ENSMUSG00000024837",
  "ENSMUSG00000024841",
  "ENSMUSG00000024843",
  "ENSMUSG00000024844",
  "ENSMUSG00000024845",
  "ENSMUSG00000024846",
  "ENSMUSG00000024847",
  "ENSMUSG00000024851",
  "ENSMUSG00000024853",
  "ENSMUSG00000024855",
  "ENSMUSG00000024856",
  "ENSMUSG00000024857",
  "ENSMUSG00000024858",
  "ENSMUSG00000024862",
  "ENSMUSG00000024863",
  "ENSMUSG00000024866",
  "ENSMUSG00000024867",
  "ENSMUSG00000024868",
  "ENSMUSG00000024870",
  "ENSMUSG00000024871",
  "ENSMUSG00000024873",
  "ENSMUSG00000024875",
  "ENSMUSG00000024878",
  "ENSMUSG00000024883",
  "ENSMUSG00000024885",
  "ENSMUSG00000024887",
  "ENSMUSG00000024889",
  "ENSMUSG00000024891",
  "ENSMUSG00000024892",
  "ENSMUSG00000024896",
  "ENSMUSG00000024897",
  "ENSMUSG00000024899",
  "ENSMUSG00000024900",
  "ENSMUSG00000024901",
  "ENSMUSG00000024902",
  "ENSMUSG00000024903",
  "ENSMUSG00000024905",
  "ENSMUSG00000024906",
  "ENSMUSG00000024907",
  "ENSMUSG00000024908",
  "ENSMUSG00000024909",
  "ENSMUSG00000024910",
  "ENSMUSG00000024911",
  "ENSMUSG00000024912",
  "ENSMUSG00000024913",
  "ENSMUSG00000024914",
  "ENSMUSG00000024921",
  "ENSMUSG00000024922",
  "ENSMUSG00000024924",
  "ENSMUSG00000024925",
  "ENSMUSG00000024926",
  "ENSMUSG00000024927",
  "ENSMUSG00000024935",
  "ENSMUSG00000024936",
  "ENSMUSG00000024937",
  "ENSMUSG00000024940",
  "ENSMUSG00000024941",
  "ENSMUSG00000024942",
  "ENSMUSG00000024943",
  "ENSMUSG00000024944",
  "ENSMUSG00000024947",
  "ENSMUSG00000024948",
  "ENSMUSG00000024949",
  "ENSMUSG00000024953",
  "ENSMUSG00000024955",
  "ENSMUSG00000024957",
  "ENSMUSG00000024958",
  "ENSMUSG00000024959",
  "ENSMUSG00000024960",
  "ENSMUSG00000024962",
  "ENSMUSG00000024963",
  "ENSMUSG00000024965",
  "ENSMUSG00000024966",
  "ENSMUSG00000024968",
  "ENSMUSG00000024969",
  "ENSMUSG00000024970",
  "ENSMUSG00000024972",
  "ENSMUSG00000024973",
  "ENSMUSG00000024974",
  "ENSMUSG00000024976",
  "ENSMUSG00000024978",
  "ENSMUSG00000024979",
  "ENSMUSG00000024981",
  "ENSMUSG00000024982",
  "ENSMUSG00000024983",
  "ENSMUSG00000024985",
  "ENSMUSG00000024986",
  "ENSMUSG00000024987",
  "ENSMUSG00000024989",
  "ENSMUSG00000024990",
  "ENSMUSG00000024991",
  "ENSMUSG00000024992",
  "ENSMUSG00000024993",
  "ENSMUSG00000024997",
  "ENSMUSG00000024998",
  "ENSMUSG00000024999",
  "ENSMUSG00000025001",
  "ENSMUSG00000025002",
  "ENSMUSG00000025007",
  "ENSMUSG00000025008",
  "ENSMUSG00000025010",
  "ENSMUSG00000025013",
  "ENSMUSG00000025014",
  "ENSMUSG00000025016",
  "ENSMUSG00000025017",
  "ENSMUSG00000025019",
  "ENSMUSG00000025020",
  "ENSMUSG00000025024",
  "ENSMUSG00000025025",
  "ENSMUSG00000025026",
  "ENSMUSG00000025027",
  "ENSMUSG00000025034",
  "ENSMUSG00000025035",
  "ENSMUSG00000025036",
  "ENSMUSG00000025037",
  "ENSMUSG00000025038",
  "ENSMUSG00000025040",
  "ENSMUSG00000025041",
  "ENSMUSG00000025043",
  "ENSMUSG00000025044",
  "ENSMUSG00000025047",
  "ENSMUSG00000025049",
  "ENSMUSG00000025050",
  "ENSMUSG00000025051",
  "ENSMUSG00000025056",
  "ENSMUSG00000025058",
  "ENSMUSG00000025059",
  "ENSMUSG00000025060",
  "ENSMUSG00000025064",
  "ENSMUSG00000025066",
  "ENSMUSG00000025068",
  "ENSMUSG00000025069",
  "ENSMUSG00000025075",
  "ENSMUSG00000025076",
  "ENSMUSG00000025077",
  "ENSMUSG00000025078",
  "ENSMUSG00000025081",
  "ENSMUSG00000025082",
  "ENSMUSG00000025083",
  "ENSMUSG00000025085",
  "ENSMUSG00000025086",
  "ENSMUSG00000025089",
  "ENSMUSG00000025090",
  "ENSMUSG00000025091",
  "ENSMUSG00000025092",
  "ENSMUSG00000025094",
  "ENSMUSG00000025102",
  "ENSMUSG00000025103",
  "ENSMUSG00000025104",
  "ENSMUSG00000025105",
  "ENSMUSG00000025127",
  "ENSMUSG00000025128",
  "ENSMUSG00000025129",
  "ENSMUSG00000025130",
  "ENSMUSG00000025132",
  "ENSMUSG00000025133",
  "ENSMUSG00000025134",
  "ENSMUSG00000025135",
  "ENSMUSG00000025137",
  "ENSMUSG00000025138",
  "ENSMUSG00000025139",
  "ENSMUSG00000025140",
  "ENSMUSG00000025141",
  "ENSMUSG00000025142",
  "ENSMUSG00000025144",
  "ENSMUSG00000025145",
  "ENSMUSG00000025147",
  "ENSMUSG00000025150",
  "ENSMUSG00000025151",
  "ENSMUSG00000025153",
  "ENSMUSG00000025154",
  "ENSMUSG00000025155",
  "ENSMUSG00000025156",
  "ENSMUSG00000025157",
  "ENSMUSG00000025158",
  "ENSMUSG00000025159",
  "ENSMUSG00000025161",
  "ENSMUSG00000025162",
  "ENSMUSG00000025163",
  "ENSMUSG00000025165",
  "ENSMUSG00000025169",
  "ENSMUSG00000025170",
  "ENSMUSG00000025171",
  "ENSMUSG00000025172",
  "ENSMUSG00000025173",
  "ENSMUSG00000025175",
  "ENSMUSG00000025176",
  "ENSMUSG00000025178",
  "ENSMUSG00000025184",
  "ENSMUSG00000025185",
  "ENSMUSG00000025188",
  "ENSMUSG00000025189",
  "ENSMUSG00000025190",
  "ENSMUSG00000025192",
  "ENSMUSG00000025193",
  "ENSMUSG00000025194",
  "ENSMUSG00000025195",
  "ENSMUSG00000025196",
  "ENSMUSG00000025197",
  "ENSMUSG00000025198",
  "ENSMUSG00000025199",
  "ENSMUSG00000025200",
  "ENSMUSG00000025202",
  "ENSMUSG00000025203",
  "ENSMUSG00000025204",
  "ENSMUSG00000025207",
  "ENSMUSG00000025208",
  "ENSMUSG00000025209",
  "ENSMUSG00000025212",
  "ENSMUSG00000025213",
  "ENSMUSG00000025215",
  "ENSMUSG00000025216",
  "ENSMUSG00000025217",
  "ENSMUSG00000025218",
  "ENSMUSG00000025219",
  "ENSMUSG00000025220",
  "ENSMUSG00000025221",
  "ENSMUSG00000025223",
  "ENSMUSG00000025224",
  "ENSMUSG00000025225",
  "ENSMUSG00000025226",
  "ENSMUSG00000025227",
  "ENSMUSG00000025228",
  "ENSMUSG00000025229",
  "ENSMUSG00000025231",
  "ENSMUSG00000025232",
  "ENSMUSG00000025234",
  "ENSMUSG00000025235",
  "ENSMUSG00000025236",
  "ENSMUSG00000025237",
  "ENSMUSG00000025239",
  "ENSMUSG00000025240",
  "ENSMUSG00000025241",
  "ENSMUSG00000025243",
  "ENSMUSG00000025245",
  "ENSMUSG00000025246",
  "ENSMUSG00000025255",
  "ENSMUSG00000025257",
  "ENSMUSG00000025260",
  "ENSMUSG00000025261",
  "ENSMUSG00000025262",
  "ENSMUSG00000025264",
  "ENSMUSG00000025265",
  "ENSMUSG00000025266",
  "ENSMUSG00000025268",
  "ENSMUSG00000025269",
  "ENSMUSG00000025270",
  "ENSMUSG00000025271",
  "ENSMUSG00000025272",
  "ENSMUSG00000025277",
  "ENSMUSG00000025278",
  "ENSMUSG00000025279",
  "ENSMUSG00000025280",
  "ENSMUSG00000025283",
  "ENSMUSG00000025287",
  "ENSMUSG00000025288",
  "ENSMUSG00000025289",
  "ENSMUSG00000025290",
  "ENSMUSG00000025314",
  "ENSMUSG00000025316",
  "ENSMUSG00000025317",
  "ENSMUSG00000025318",
  "ENSMUSG00000025321",
  "ENSMUSG00000025323",
  "ENSMUSG00000025324",
  "ENSMUSG00000025326",
  "ENSMUSG00000025328",
  "ENSMUSG00000025329",
  "ENSMUSG00000025330",
  "ENSMUSG00000025332",
  "ENSMUSG00000025333",
  "ENSMUSG00000025337",
  "ENSMUSG00000025340",
  "ENSMUSG00000025347",
  "ENSMUSG00000025348",
  "ENSMUSG00000025352",
  "ENSMUSG00000025353",
  "ENSMUSG00000025354",
  "ENSMUSG00000025355",
  "ENSMUSG00000025357",
  "ENSMUSG00000025358",
  "ENSMUSG00000025359",
  "ENSMUSG00000025364",
  "ENSMUSG00000025366",
  "ENSMUSG00000025369",
  "ENSMUSG00000025370",
  "ENSMUSG00000025371",
  "ENSMUSG00000025372",
  "ENSMUSG00000025373",
  "ENSMUSG00000025374",
  "ENSMUSG00000025377",
  "ENSMUSG00000025380",
  "ENSMUSG00000025381",
  "ENSMUSG00000025383",
  "ENSMUSG00000025384",
  "ENSMUSG00000025386",
  "ENSMUSG00000025389",
  "ENSMUSG00000025393",
  "ENSMUSG00000025395",
  "ENSMUSG00000025396",
  "ENSMUSG00000025400",
  "ENSMUSG00000025401",
  "ENSMUSG00000025402",
  "ENSMUSG00000025403",
  "ENSMUSG00000025404",
  "ENSMUSG00000025405",
  "ENSMUSG00000025407",
  "ENSMUSG00000025408",
  "ENSMUSG00000025409",
  "ENSMUSG00000025410",
  "ENSMUSG00000025413",
  "ENSMUSG00000025417",
  "ENSMUSG00000025418",
  "ENSMUSG00000025420",
  "ENSMUSG00000025421",
  "ENSMUSG00000025422",
  "ENSMUSG00000025423",
  "ENSMUSG00000025425",
  "ENSMUSG00000025427",
  "ENSMUSG00000025428",
  "ENSMUSG00000025429",
  "ENSMUSG00000025431",
  "ENSMUSG00000025432",
  "ENSMUSG00000025433",
  "ENSMUSG00000025436",
  "ENSMUSG00000025437",
  "ENSMUSG00000025439",
  "ENSMUSG00000025450",
  "ENSMUSG00000025451",
  "ENSMUSG00000025453",
  "ENSMUSG00000025461",
  "ENSMUSG00000025464",
  "ENSMUSG00000025465",
  "ENSMUSG00000025466",
  "ENSMUSG00000025467",
  "ENSMUSG00000025468",
  "ENSMUSG00000025469",
  "ENSMUSG00000025470",
  "ENSMUSG00000025473",
  "ENSMUSG00000025474",
  "ENSMUSG00000025475",
  "ENSMUSG00000025477",
  "ENSMUSG00000025478",
  "ENSMUSG00000025479",
  "ENSMUSG00000025480",
  "ENSMUSG00000025481",
  "ENSMUSG00000025482",
  "ENSMUSG00000025485",
  "ENSMUSG00000025486",
  "ENSMUSG00000025487",
  "ENSMUSG00000025488",
  "ENSMUSG00000025489",
  "ENSMUSG00000025491",
  "ENSMUSG00000025492",
  "ENSMUSG00000025494",
  "ENSMUSG00000025495",
  "ENSMUSG00000025496",
  "ENSMUSG00000025497",
  "ENSMUSG00000025498",
  "ENSMUSG00000025499",
  "ENSMUSG00000025500",
  "ENSMUSG00000025503",
  "ENSMUSG00000025504",
  "ENSMUSG00000025505",
  "ENSMUSG00000025507",
  "ENSMUSG00000025509",
  "ENSMUSG00000025510",
  "ENSMUSG00000025511",
  "ENSMUSG00000025512",
  "ENSMUSG00000025515",
  "ENSMUSG00000025519",
  "ENSMUSG00000025521",
  "ENSMUSG00000025525",
  "ENSMUSG00000025527",
  "ENSMUSG00000025528",
  "ENSMUSG00000025529",
  "ENSMUSG00000025531",
  "ENSMUSG00000025532",
  "ENSMUSG00000025533",
  "ENSMUSG00000025534",
  "ENSMUSG00000025537",
  "ENSMUSG00000025538",
  "ENSMUSG00000025544",
  "ENSMUSG00000025545",
  "ENSMUSG00000025551",
  "ENSMUSG00000025555",
  "ENSMUSG00000025557",
  "ENSMUSG00000025558",
  "ENSMUSG00000025571",
  "ENSMUSG00000025572",
  "ENSMUSG00000025573",
  "ENSMUSG00000025574",
  "ENSMUSG00000025575",
  "ENSMUSG00000025576",
  "ENSMUSG00000025577",
  "ENSMUSG00000025578",
  "ENSMUSG00000025579",
  "ENSMUSG00000025580",
  "ENSMUSG00000025582",
  "ENSMUSG00000025583",
  "ENSMUSG00000025584",
  "ENSMUSG00000025586",
  "ENSMUSG00000025588",
  "ENSMUSG00000025591",
  "ENSMUSG00000025592",
  "ENSMUSG00000025597",
  "ENSMUSG00000025602",
  "ENSMUSG00000025607",
  "ENSMUSG00000025608",
  "ENSMUSG00000025609",
  "ENSMUSG00000025610",
  "ENSMUSG00000025612",
  "ENSMUSG00000025613",
  "ENSMUSG00000025616",
  "ENSMUSG00000025626",
  "ENSMUSG00000025630",
  "ENSMUSG00000025644",
  "ENSMUSG00000025645",
  "ENSMUSG00000025646",
  "ENSMUSG00000025647",
  "ENSMUSG00000025648",
  "ENSMUSG00000025650",
  "ENSMUSG00000025651",
  "ENSMUSG00000025652",
  "ENSMUSG00000025656",
  "ENSMUSG00000025658",
  "ENSMUSG00000025665",
  "ENSMUSG00000025666",
  "ENSMUSG00000025701",
  "ENSMUSG00000025702",
  "ENSMUSG00000025716",
  "ENSMUSG00000025722",
  "ENSMUSG00000025723",
  "ENSMUSG00000025724",
  "ENSMUSG00000025726",
  "ENSMUSG00000025727",
  "ENSMUSG00000025728",
  "ENSMUSG00000025730",
  "ENSMUSG00000025731",
  "ENSMUSG00000025732",
  "ENSMUSG00000025733",
  "ENSMUSG00000025736",
  "ENSMUSG00000025737",
  "ENSMUSG00000025738",
  "ENSMUSG00000025739",
  "ENSMUSG00000025742",
  "ENSMUSG00000025743",
  "ENSMUSG00000025745",
  "ENSMUSG00000025746",
  "ENSMUSG00000025747",
  "ENSMUSG00000025754",
  "ENSMUSG00000025757",
  "ENSMUSG00000025758",
  "ENSMUSG00000025759",
  "ENSMUSG00000025762",
  "ENSMUSG00000025764",
  "ENSMUSG00000025766",
  "ENSMUSG00000025774",
  "ENSMUSG00000025776",
  "ENSMUSG00000025777",
  "ENSMUSG00000025779",
  "ENSMUSG00000025780",
  "ENSMUSG00000025781",
  "ENSMUSG00000025782",
  "ENSMUSG00000025783",
  "ENSMUSG00000025784",
  "ENSMUSG00000025785",
  "ENSMUSG00000025786",
  "ENSMUSG00000025787",
  "ENSMUSG00000025789",
  "ENSMUSG00000025790",
  "ENSMUSG00000025791",
  "ENSMUSG00000025792",
  "ENSMUSG00000025793",
  "ENSMUSG00000025794",
  "ENSMUSG00000025795",
  "ENSMUSG00000025804",
  "ENSMUSG00000025808",
  "ENSMUSG00000025809",
  "ENSMUSG00000025810",
  "ENSMUSG00000025812",
  "ENSMUSG00000025813",
  "ENSMUSG00000025815",
  "ENSMUSG00000025816",
  "ENSMUSG00000025817",
  "ENSMUSG00000025821",
  "ENSMUSG00000025823",
  "ENSMUSG00000025825",
  "ENSMUSG00000025838",
  "ENSMUSG00000025839",
  "ENSMUSG00000025854",
  "ENSMUSG00000025855",
  "ENSMUSG00000025856",
  "ENSMUSG00000025857",
  "ENSMUSG00000025858",
  "ENSMUSG00000025860",
  "ENSMUSG00000025862",
  "ENSMUSG00000025867",
  "ENSMUSG00000025868",
  "ENSMUSG00000025869",
  "ENSMUSG00000025870",
  "ENSMUSG00000025871",
  "ENSMUSG00000025872",
  "ENSMUSG00000025873",
  "ENSMUSG00000025875",
  "ENSMUSG00000025876",
  "ENSMUSG00000025877",
  "ENSMUSG00000025878",
  "ENSMUSG00000025880",
  "ENSMUSG00000025885",
  "ENSMUSG00000025887",
  "ENSMUSG00000025888",
  "ENSMUSG00000025889",
  "ENSMUSG00000025892",
  "ENSMUSG00000025893",
  "ENSMUSG00000025894",
  "ENSMUSG00000025898",
  "ENSMUSG00000025899",
  "ENSMUSG00000025900",
  "ENSMUSG00000025902",
  "ENSMUSG00000025903",
  "ENSMUSG00000025905",
  "ENSMUSG00000025907",
  "ENSMUSG00000025909",
  "ENSMUSG00000025911",
  "ENSMUSG00000025912",
  "ENSMUSG00000025915",
  "ENSMUSG00000025916",
  "ENSMUSG00000025917",
  "ENSMUSG00000025920",
  "ENSMUSG00000025921",
  "ENSMUSG00000025925",
  "ENSMUSG00000025927",
  "ENSMUSG00000025929",
  "ENSMUSG00000025930",
  "ENSMUSG00000025931",
  "ENSMUSG00000025933",
  "ENSMUSG00000025934",
  "ENSMUSG00000025935",
  "ENSMUSG00000025937",
  "ENSMUSG00000025938",
  "ENSMUSG00000025939",
  "ENSMUSG00000025940",
  "ENSMUSG00000025945",
  "ENSMUSG00000025946",
  "ENSMUSG00000025949",
  "ENSMUSG00000025950",
  "ENSMUSG00000025952",
  "ENSMUSG00000025955",
  "ENSMUSG00000025956",
  "ENSMUSG00000025958",
  "ENSMUSG00000025959",
  "ENSMUSG00000025961",
  "ENSMUSG00000025962",
  "ENSMUSG00000025963",
  "ENSMUSG00000025964",
  "ENSMUSG00000025967",
  "ENSMUSG00000025968",
  "ENSMUSG00000025969",
  "ENSMUSG00000025971",
  "ENSMUSG00000025977",
  "ENSMUSG00000025978",
  "ENSMUSG00000025979",
  "ENSMUSG00000025980",
  "ENSMUSG00000025981",
  "ENSMUSG00000025982",
  "ENSMUSG00000025983",
  "ENSMUSG00000025986",
  "ENSMUSG00000025991",
  "ENSMUSG00000025993",
  "ENSMUSG00000025995",
  "ENSMUSG00000025997",
  "ENSMUSG00000026000",
  "ENSMUSG00000026003",
  "ENSMUSG00000026004",
  "ENSMUSG00000026005",
  "ENSMUSG00000026009",
  "ENSMUSG00000026011",
  "ENSMUSG00000026012",
  "ENSMUSG00000026014",
  "ENSMUSG00000026017",
  "ENSMUSG00000026018",
  "ENSMUSG00000026019",
  "ENSMUSG00000026020",
  "ENSMUSG00000026021",
  "ENSMUSG00000026023",
  "ENSMUSG00000026024",
  "ENSMUSG00000026027",
  "ENSMUSG00000026028",
  "ENSMUSG00000026029",
  "ENSMUSG00000026031",
  "ENSMUSG00000026032",
  "ENSMUSG00000026034",
  "ENSMUSG00000026035",
  "ENSMUSG00000026036",
  "ENSMUSG00000026037",
  "ENSMUSG00000026039",
  "ENSMUSG00000026042",
  "ENSMUSG00000026043",
  "ENSMUSG00000026047",
  "ENSMUSG00000026048",
  "ENSMUSG00000026049",
  "ENSMUSG00000026051",
  "ENSMUSG00000026058",
  "ENSMUSG00000026062",
  "ENSMUSG00000026063",
  "ENSMUSG00000026068",
  "ENSMUSG00000026069",
  "ENSMUSG00000026070",
  "ENSMUSG00000026072",
  "ENSMUSG00000026073",
  "ENSMUSG00000026074",
  "ENSMUSG00000026077",
  "ENSMUSG00000026078",
  "ENSMUSG00000026080",
  "ENSMUSG00000026082",
  "ENSMUSG00000026083",
  "ENSMUSG00000026085",
  "ENSMUSG00000026087",
  "ENSMUSG00000026088",
  "ENSMUSG00000026090",
  "ENSMUSG00000026094",
  "ENSMUSG00000026095",
  "ENSMUSG00000026096",
  "ENSMUSG00000026097",
  "ENSMUSG00000026098",
  "ENSMUSG00000026100",
  "ENSMUSG00000026102",
  "ENSMUSG00000026103",
  "ENSMUSG00000026104",
  "ENSMUSG00000026107",
  "ENSMUSG00000026109",
  "ENSMUSG00000026110",
  "ENSMUSG00000026111",
  "ENSMUSG00000026112",
  "ENSMUSG00000026113",
  "ENSMUSG00000026114",
  "ENSMUSG00000026116",
  "ENSMUSG00000026117",
  "ENSMUSG00000026121",
  "ENSMUSG00000026123",
  "ENSMUSG00000026124",
  "ENSMUSG00000026125",
  "ENSMUSG00000026126",
  "ENSMUSG00000026127",
  "ENSMUSG00000026131",
  "ENSMUSG00000026134",
  "ENSMUSG00000026135",
  "ENSMUSG00000026141",
  "ENSMUSG00000026147",
  "ENSMUSG00000026150",
  "ENSMUSG00000026153",
  "ENSMUSG00000026154",
  "ENSMUSG00000026155",
  "ENSMUSG00000026156",
  "ENSMUSG00000026158",
  "ENSMUSG00000026159",
  "ENSMUSG00000026162",
  "ENSMUSG00000026163",
  "ENSMUSG00000026166",
  "ENSMUSG00000026167",
  "ENSMUSG00000026170",
  "ENSMUSG00000026171",
  "ENSMUSG00000026172",
  "ENSMUSG00000026173",
  "ENSMUSG00000026174",
  "ENSMUSG00000026175",
  "ENSMUSG00000026176",
  "ENSMUSG00000026177",
  "ENSMUSG00000026179",
  "ENSMUSG00000026180",
  "ENSMUSG00000026181",
  "ENSMUSG00000026182",
  "ENSMUSG00000026185",
  "ENSMUSG00000026187",
  "ENSMUSG00000026188",
  "ENSMUSG00000026189",
  "ENSMUSG00000026192",
  "ENSMUSG00000026193",
  "ENSMUSG00000026196",
  "ENSMUSG00000026197",
  "ENSMUSG00000026199",
  "ENSMUSG00000026200",
  "ENSMUSG00000026201",
  "ENSMUSG00000026202",
  "ENSMUSG00000026203",
  "ENSMUSG00000026204",
  "ENSMUSG00000026205",
  "ENSMUSG00000026207",
  "ENSMUSG00000026208",
  "ENSMUSG00000026209",
  "ENSMUSG00000026211",
  "ENSMUSG00000026213",
  "ENSMUSG00000026219",
  "ENSMUSG00000026220",
  "ENSMUSG00000026222",
  "ENSMUSG00000026223",
  "ENSMUSG00000026224",
  "ENSMUSG00000026226",
  "ENSMUSG00000026227",
  "ENSMUSG00000026228",
  "ENSMUSG00000026234",
  "ENSMUSG00000026235",
  "ENSMUSG00000026237",
  "ENSMUSG00000026238",
  "ENSMUSG00000026239",
  "ENSMUSG00000026240",
  "ENSMUSG00000026241",
  "ENSMUSG00000026245",
  "ENSMUSG00000026246",
  "ENSMUSG00000026247",
  "ENSMUSG00000026248",
  "ENSMUSG00000026249",
  "ENSMUSG00000026251",
  "ENSMUSG00000026253",
  "ENSMUSG00000026254",
  "ENSMUSG00000026255",
  "ENSMUSG00000026258",
  "ENSMUSG00000026259",
  "ENSMUSG00000026260",
  "ENSMUSG00000026269",
  "ENSMUSG00000026270",
  "ENSMUSG00000026271",
  "ENSMUSG00000026272",
  "ENSMUSG00000026273",
  "ENSMUSG00000026274",
  "ENSMUSG00000026275",
  "ENSMUSG00000026277",
  "ENSMUSG00000026279",
  "ENSMUSG00000026280",
  "ENSMUSG00000026281",
  "ENSMUSG00000026283",
  "ENSMUSG00000026285",
  "ENSMUSG00000026288",
  "ENSMUSG00000026289",
  "ENSMUSG00000026295",
  "ENSMUSG00000026301",
  "ENSMUSG00000026303",
  "ENSMUSG00000026304",
  "ENSMUSG00000026305",
  "ENSMUSG00000026307",
  "ENSMUSG00000026308",
  "ENSMUSG00000026309",
  "ENSMUSG00000026311",
  "ENSMUSG00000026312",
  "ENSMUSG00000026313",
  "ENSMUSG00000026317",
  "ENSMUSG00000026319",
  "ENSMUSG00000026321",
  "ENSMUSG00000026322",
  "ENSMUSG00000026327",
  "ENSMUSG00000026331",
  "ENSMUSG00000026333",
  "ENSMUSG00000026335",
  "ENSMUSG00000026336",
  "ENSMUSG00000026339",
  "ENSMUSG00000026342",
  "ENSMUSG00000026343",
  "ENSMUSG00000026344",
  "ENSMUSG00000026347",
  "ENSMUSG00000026348",
  "ENSMUSG00000026349",
  "ENSMUSG00000026353",
  "ENSMUSG00000026354",
  "ENSMUSG00000026355",
  "ENSMUSG00000026356",
  "ENSMUSG00000026357",
  "ENSMUSG00000026358",
  "ENSMUSG00000026360",
  "ENSMUSG00000026361",
  "ENSMUSG00000026365",
  "ENSMUSG00000026368",
  "ENSMUSG00000026374",
  "ENSMUSG00000026377",
  "ENSMUSG00000026380",
  "ENSMUSG00000026383",
  "ENSMUSG00000026384",
  "ENSMUSG00000026385",
  "ENSMUSG00000026387",
  "ENSMUSG00000026388",
  "ENSMUSG00000026389",
  "ENSMUSG00000026390",
  "ENSMUSG00000026393",
  "ENSMUSG00000026394",
  "ENSMUSG00000026395",
  "ENSMUSG00000026398",
  "ENSMUSG00000026399",
  "ENSMUSG00000026401",
  "ENSMUSG00000026404",
  "ENSMUSG00000026405",
  "ENSMUSG00000026407",
  "ENSMUSG00000026409",
  "ENSMUSG00000026411",
  "ENSMUSG00000026413",
  "ENSMUSG00000026414",
  "ENSMUSG00000026415",
  "ENSMUSG00000026416",
  "ENSMUSG00000026417",
  "ENSMUSG00000026418",
  "ENSMUSG00000026420",
  "ENSMUSG00000026421",
  "ENSMUSG00000026424",
  "ENSMUSG00000026425",
  "ENSMUSG00000026426",
  "ENSMUSG00000026427",
  "ENSMUSG00000026429",
  "ENSMUSG00000026430",
  "ENSMUSG00000026432",
  "ENSMUSG00000026433",
  "ENSMUSG00000026434",
  "ENSMUSG00000026435",
  "ENSMUSG00000026436",
  "ENSMUSG00000026437",
  "ENSMUSG00000026439",
  "ENSMUSG00000026442",
  "ENSMUSG00000026443",
  "ENSMUSG00000026447",
  "ENSMUSG00000026450",
  "ENSMUSG00000026452",
  "ENSMUSG00000026455",
  "ENSMUSG00000026456",
  "ENSMUSG00000026457",
  "ENSMUSG00000026458",
  "ENSMUSG00000026463",
  "ENSMUSG00000026466",
  "ENSMUSG00000026468",
  "ENSMUSG00000026469",
  "ENSMUSG00000026470",
  "ENSMUSG00000026471",
  "ENSMUSG00000026473",
  "ENSMUSG00000026475",
  "ENSMUSG00000026478",
  "ENSMUSG00000026479",
  "ENSMUSG00000026480",
  "ENSMUSG00000026482",
  "ENSMUSG00000026483",
  "ENSMUSG00000026484",
  "ENSMUSG00000026489",
  "ENSMUSG00000026490",
  "ENSMUSG00000026491",
  "ENSMUSG00000026492",
  "ENSMUSG00000026494",
  "ENSMUSG00000026495",
  "ENSMUSG00000026496",
  "ENSMUSG00000026497",
  "ENSMUSG00000026499",
  "ENSMUSG00000026500",
  "ENSMUSG00000026502",
  "ENSMUSG00000026504",
  "ENSMUSG00000026509",
  "ENSMUSG00000026510",
  "ENSMUSG00000026511",
  "ENSMUSG00000026514",
  "ENSMUSG00000026516",
  "ENSMUSG00000026519",
  "ENSMUSG00000026520",
  "ENSMUSG00000026523",
  "ENSMUSG00000026525",
  "ENSMUSG00000026526",
  "ENSMUSG00000026527",
  "ENSMUSG00000026531",
  "ENSMUSG00000026532",
  "ENSMUSG00000026535",
  "ENSMUSG00000026542",
  "ENSMUSG00000026544",
  "ENSMUSG00000026546",
  "ENSMUSG00000026547",
  "ENSMUSG00000026548",
  "ENSMUSG00000026553",
  "ENSMUSG00000026554",
  "ENSMUSG00000026556",
  "ENSMUSG00000026558",
  "ENSMUSG00000026560",
  "ENSMUSG00000026563",
  "ENSMUSG00000026564",
  "ENSMUSG00000026565",
  "ENSMUSG00000026566",
  "ENSMUSG00000026567",
  "ENSMUSG00000026568",
  "ENSMUSG00000026571",
  "ENSMUSG00000026572",
  "ENSMUSG00000026573",
  "ENSMUSG00000026574",
  "ENSMUSG00000026575",
  "ENSMUSG00000026576",
  "ENSMUSG00000026577",
  "ENSMUSG00000026578",
  "ENSMUSG00000026579",
  "ENSMUSG00000026580",
  "ENSMUSG00000026581",
  "ENSMUSG00000026582",
  "ENSMUSG00000026584",
  "ENSMUSG00000026585",
  "ENSMUSG00000026586",
  "ENSMUSG00000026587",
  "ENSMUSG00000026589",
  "ENSMUSG00000026592",
  "ENSMUSG00000026594",
  "ENSMUSG00000026596",
  "ENSMUSG00000026600",
  "ENSMUSG00000026601",
  "ENSMUSG00000026602",
  "ENSMUSG00000026603",
  "ENSMUSG00000026604",
  "ENSMUSG00000026605",
  "ENSMUSG00000026608",
  "ENSMUSG00000026609",
  "ENSMUSG00000026610",
  "ENSMUSG00000026611",
  "ENSMUSG00000026614",
  "ENSMUSG00000026615",
  "ENSMUSG00000026616",
  "ENSMUSG00000026617",
  "ENSMUSG00000026618",
  "ENSMUSG00000026620",
  "ENSMUSG00000026621",
  "ENSMUSG00000026622",
  "ENSMUSG00000026623",
  "ENSMUSG00000026626",
  "ENSMUSG00000026627",
  "ENSMUSG00000026628",
  "ENSMUSG00000026630",
  "ENSMUSG00000026632",
  "ENSMUSG00000026634",
  "ENSMUSG00000026637",
  "ENSMUSG00000026638",
  "ENSMUSG00000026639",
  "ENSMUSG00000026640",
  "ENSMUSG00000026643",
  "ENSMUSG00000026644",
  "ENSMUSG00000026645",
  "ENSMUSG00000026646",
  "ENSMUSG00000026648",
  "ENSMUSG00000026649",
  "ENSMUSG00000026650",
  "ENSMUSG00000026655",
  "ENSMUSG00000026656",
  "ENSMUSG00000026657",
  "ENSMUSG00000026659",
  "ENSMUSG00000026662",
  "ENSMUSG00000026663",
  "ENSMUSG00000026664",
  "ENSMUSG00000026667",
  "ENSMUSG00000026668",
  "ENSMUSG00000026669",
  "ENSMUSG00000026670",
  "ENSMUSG00000026672",
  "ENSMUSG00000026674",
  "ENSMUSG00000026675",
  "ENSMUSG00000026676",
  "ENSMUSG00000026678",
  "ENSMUSG00000026679",
  "ENSMUSG00000026683",
  "ENSMUSG00000026686",
  "ENSMUSG00000026687",
  "ENSMUSG00000026688",
  "ENSMUSG00000026691",
  "ENSMUSG00000026692",
  "ENSMUSG00000026694",
  "ENSMUSG00000026696",
  "ENSMUSG00000026697",
  "ENSMUSG00000026698",
  "ENSMUSG00000026701",
  "ENSMUSG00000026705",
  "ENSMUSG00000026707",
  "ENSMUSG00000026708",
  "ENSMUSG00000026709",
  "ENSMUSG00000026712",
  "ENSMUSG00000026715",
  "ENSMUSG00000026718",
  "ENSMUSG00000026721",
  "ENSMUSG00000026723",
  "ENSMUSG00000026725",
  "ENSMUSG00000026726",
  "ENSMUSG00000026727",
  "ENSMUSG00000026728",
  "ENSMUSG00000026729",
  "ENSMUSG00000026730",
  "ENSMUSG00000026734",
  "ENSMUSG00000026735",
  "ENSMUSG00000026736",
  "ENSMUSG00000026737",
  "ENSMUSG00000026739",
  "ENSMUSG00000026740",
  "ENSMUSG00000026748",
  "ENSMUSG00000026749",
  "ENSMUSG00000026750",
  "ENSMUSG00000026751",
  "ENSMUSG00000026753",
  "ENSMUSG00000026754",
  "ENSMUSG00000026755",
  "ENSMUSG00000026761",
  "ENSMUSG00000026764",
  "ENSMUSG00000026765",
  "ENSMUSG00000026766",
  "ENSMUSG00000026767",
  "ENSMUSG00000026768",
  "ENSMUSG00000026770",
  "ENSMUSG00000026771",
  "ENSMUSG00000026773",
  "ENSMUSG00000026774",
  "ENSMUSG00000026775",
  "ENSMUSG00000026778",
  "ENSMUSG00000026779",
  "ENSMUSG00000026781",
  "ENSMUSG00000026782",
  "ENSMUSG00000026784",
  "ENSMUSG00000026785",
  "ENSMUSG00000026786",
  "ENSMUSG00000026787",
  "ENSMUSG00000026788",
  "ENSMUSG00000026790",
  "ENSMUSG00000026791",
  "ENSMUSG00000026792",
  "ENSMUSG00000026796",
  "ENSMUSG00000026797",
  "ENSMUSG00000026798",
  "ENSMUSG00000026799",
  "ENSMUSG00000026803",
  "ENSMUSG00000026805",
  "ENSMUSG00000026806",
  "ENSMUSG00000026807",
  "ENSMUSG00000026809",
  "ENSMUSG00000026810",
  "ENSMUSG00000026811",
  "ENSMUSG00000026812",
  "ENSMUSG00000026814",
  "ENSMUSG00000026815",
  "ENSMUSG00000026816",
  "ENSMUSG00000026817",
  "ENSMUSG00000026818",
  "ENSMUSG00000026819",
  "ENSMUSG00000026820",
  "ENSMUSG00000026821",
  "ENSMUSG00000026822",
  "ENSMUSG00000026824",
  "ENSMUSG00000026825",
  "ENSMUSG00000026826",
  "ENSMUSG00000026827",
  "ENSMUSG00000026828",
  "ENSMUSG00000026829",
  "ENSMUSG00000026830",
  "ENSMUSG00000026831",
  "ENSMUSG00000026832",
  "ENSMUSG00000026833",
  "ENSMUSG00000026834",
  "ENSMUSG00000026835",
  "ENSMUSG00000026836",
  "ENSMUSG00000026837",
  "ENSMUSG00000026839",
  "ENSMUSG00000026840",
  "ENSMUSG00000026841",
  "ENSMUSG00000026842",
  "ENSMUSG00000026848",
  "ENSMUSG00000026849",
  "ENSMUSG00000026851",
  "ENSMUSG00000026853",
  "ENSMUSG00000026854",
  "ENSMUSG00000026856",
  "ENSMUSG00000026857",
  "ENSMUSG00000026858",
  "ENSMUSG00000026860",
  "ENSMUSG00000026864",
  "ENSMUSG00000026866",
  "ENSMUSG00000026867",
  "ENSMUSG00000026869",
  "ENSMUSG00000026870",
  "ENSMUSG00000026872",
  "ENSMUSG00000026873",
  "ENSMUSG00000026874",
  "ENSMUSG00000026875",
  "ENSMUSG00000026878",
  "ENSMUSG00000026879",
  "ENSMUSG00000026880",
  "ENSMUSG00000026882",
  "ENSMUSG00000026883",
  "ENSMUSG00000026885",
  "ENSMUSG00000026887",
  "ENSMUSG00000026888",
  "ENSMUSG00000026889",
  "ENSMUSG00000026890",
  "ENSMUSG00000026893",
  "ENSMUSG00000026894",
  "ENSMUSG00000026895",
  "ENSMUSG00000026896",
  "ENSMUSG00000026904",
  "ENSMUSG00000026914",
  "ENSMUSG00000026915",
  "ENSMUSG00000026917",
  "ENSMUSG00000026918",
  "ENSMUSG00000026919",
  "ENSMUSG00000026921",
  "ENSMUSG00000026922",
  "ENSMUSG00000026923",
  "ENSMUSG00000026924",
  "ENSMUSG00000026925",
  "ENSMUSG00000026926",
  "ENSMUSG00000026927",
  "ENSMUSG00000026928",
  "ENSMUSG00000026930",
  "ENSMUSG00000026931",
  "ENSMUSG00000026932",
  "ENSMUSG00000026933",
  "ENSMUSG00000026934",
  "ENSMUSG00000026936",
  "ENSMUSG00000026937",
  "ENSMUSG00000026938",
  "ENSMUSG00000026939",
  "ENSMUSG00000026940",
  "ENSMUSG00000026941",
  "ENSMUSG00000026942",
  "ENSMUSG00000026943",
  "ENSMUSG00000026944",
  "ENSMUSG00000026946",
  "ENSMUSG00000026950",
  "ENSMUSG00000026955",
  "ENSMUSG00000026956",
  "ENSMUSG00000026958",
  "ENSMUSG00000026959",
  "ENSMUSG00000026960",
  "ENSMUSG00000026961",
  "ENSMUSG00000026963",
  "ENSMUSG00000026965",
  "ENSMUSG00000026966",
  "ENSMUSG00000026969",
  "ENSMUSG00000026970",
  "ENSMUSG00000026971",
  "ENSMUSG00000026972",
  "ENSMUSG00000026974",
  "ENSMUSG00000026975",
  "ENSMUSG00000026976",
  "ENSMUSG00000026977",
  "ENSMUSG00000026979",
  "ENSMUSG00000026980",
  "ENSMUSG00000026981",
  "ENSMUSG00000026983",
  "ENSMUSG00000026984",
  "ENSMUSG00000026985",
  "ENSMUSG00000026986",
  "ENSMUSG00000026987",
  "ENSMUSG00000026988",
  "ENSMUSG00000026989",
  "ENSMUSG00000026991",
  "ENSMUSG00000026994",
  "ENSMUSG00000026999",
  "ENSMUSG00000027001",
  "ENSMUSG00000027002",
  "ENSMUSG00000027004",
  "ENSMUSG00000027006",
  "ENSMUSG00000027007",
  "ENSMUSG00000027009",
  "ENSMUSG00000027010",
  "ENSMUSG00000027011",
  "ENSMUSG00000027012",
  "ENSMUSG00000027014",
  "ENSMUSG00000027015",
  "ENSMUSG00000027016",
  "ENSMUSG00000027018",
  "ENSMUSG00000027022",
  "ENSMUSG00000027030",
  "ENSMUSG00000027035",
  "ENSMUSG00000027048",
  "ENSMUSG00000027067",
  "ENSMUSG00000027068",
  "ENSMUSG00000027070",
  "ENSMUSG00000027071",
  "ENSMUSG00000027072",
  "ENSMUSG00000027073",
  "ENSMUSG00000027074",
  "ENSMUSG00000027075",
  "ENSMUSG00000027076",
  "ENSMUSG00000027077",
  "ENSMUSG00000027078",
  "ENSMUSG00000027079",
  "ENSMUSG00000027080",
  "ENSMUSG00000027082",
  "ENSMUSG00000027086",
  "ENSMUSG00000027087",
  "ENSMUSG00000027088",
  "ENSMUSG00000027091",
  "ENSMUSG00000027099",
  "ENSMUSG00000027102",
  "ENSMUSG00000027104",
  "ENSMUSG00000027107",
  "ENSMUSG00000027108",
  "ENSMUSG00000027115",
  "ENSMUSG00000027120",
  "ENSMUSG00000027122",
  "ENSMUSG00000027130",
  "ENSMUSG00000027131",
  "ENSMUSG00000027132",
  "ENSMUSG00000027133",
  "ENSMUSG00000027157",
  "ENSMUSG00000027160",
  "ENSMUSG00000027162",
  "ENSMUSG00000027163",
  "ENSMUSG00000027164",
  "ENSMUSG00000027165",
  "ENSMUSG00000027166",
  "ENSMUSG00000027167",
  "ENSMUSG00000027168",
  "ENSMUSG00000027171",
  "ENSMUSG00000027173",
  "ENSMUSG00000027175",
  "ENSMUSG00000027176",
  "ENSMUSG00000027177",
  "ENSMUSG00000027180",
  "ENSMUSG00000027184",
  "ENSMUSG00000027185",
  "ENSMUSG00000027186",
  "ENSMUSG00000027187",
  "ENSMUSG00000027188",
  "ENSMUSG00000027189",
  "ENSMUSG00000027194",
  "ENSMUSG00000027195",
  "ENSMUSG00000027196",
  "ENSMUSG00000027198",
  "ENSMUSG00000027199",
  "ENSMUSG00000027200",
  "ENSMUSG00000027201",
  "ENSMUSG00000027202",
  "ENSMUSG00000027203",
  "ENSMUSG00000027204",
  "ENSMUSG00000027206",
  "ENSMUSG00000027207",
  "ENSMUSG00000027208",
  "ENSMUSG00000027209",
  "ENSMUSG00000027210",
  "ENSMUSG00000027215",
  "ENSMUSG00000027217",
  "ENSMUSG00000027219",
  "ENSMUSG00000027220",
  "ENSMUSG00000027221",
  "ENSMUSG00000027222",
  "ENSMUSG00000027223",
  "ENSMUSG00000027224",
  "ENSMUSG00000027225",
  "ENSMUSG00000027227",
  "ENSMUSG00000027229",
  "ENSMUSG00000027230",
  "ENSMUSG00000027233",
  "ENSMUSG00000027236",
  "ENSMUSG00000027238",
  "ENSMUSG00000027239",
  "ENSMUSG00000027242",
  "ENSMUSG00000027243",
  "ENSMUSG00000027244",
  "ENSMUSG00000027245",
  "ENSMUSG00000027246",
  "ENSMUSG00000027247",
  "ENSMUSG00000027248",
  "ENSMUSG00000027249",
  "ENSMUSG00000027253",
  "ENSMUSG00000027254",
  "ENSMUSG00000027255",
  "ENSMUSG00000027257",
  "ENSMUSG00000027259",
  "ENSMUSG00000027261",
  "ENSMUSG00000027263",
  "ENSMUSG00000027270",
  "ENSMUSG00000027272",
  "ENSMUSG00000027273",
  "ENSMUSG00000027274",
  "ENSMUSG00000027276",
  "ENSMUSG00000027281",
  "ENSMUSG00000027282",
  "ENSMUSG00000027284",
  "ENSMUSG00000027285",
  "ENSMUSG00000027286",
  "ENSMUSG00000027287",
  "ENSMUSG00000027288",
  "ENSMUSG00000027291",
  "ENSMUSG00000027293",
  "ENSMUSG00000027296",
  "ENSMUSG00000027297",
  "ENSMUSG00000027298",
  "ENSMUSG00000027300",
  "ENSMUSG00000027301",
  "ENSMUSG00000027303",
  "ENSMUSG00000027304",
  "ENSMUSG00000027305",
  "ENSMUSG00000027306",
  "ENSMUSG00000027309",
  "ENSMUSG00000027312",
  "ENSMUSG00000027313",
  "ENSMUSG00000027314",
  "ENSMUSG00000027315",
  "ENSMUSG00000027316",
  "ENSMUSG00000027317",
  "ENSMUSG00000027318",
  "ENSMUSG00000027322",
  "ENSMUSG00000027323",
  "ENSMUSG00000027324",
  "ENSMUSG00000027326",
  "ENSMUSG00000027327",
  "ENSMUSG00000027329",
  "ENSMUSG00000027330",
  "ENSMUSG00000027331",
  "ENSMUSG00000027332",
  "ENSMUSG00000027333",
  "ENSMUSG00000027335",
  "ENSMUSG00000027339",
  "ENSMUSG00000027340",
  "ENSMUSG00000027341",
  "ENSMUSG00000027342",
  "ENSMUSG00000027344",
  "ENSMUSG00000027345",
  "ENSMUSG00000027346",
  "ENSMUSG00000027347",
  "ENSMUSG00000027349",
  "ENSMUSG00000027350",
  "ENSMUSG00000027351",
  "ENSMUSG00000027353",
  "ENSMUSG00000027355",
  "ENSMUSG00000027356",
  "ENSMUSG00000027357",
  "ENSMUSG00000027358",
  "ENSMUSG00000027359",
  "ENSMUSG00000027360",
  "ENSMUSG00000027361",
  "ENSMUSG00000027364",
  "ENSMUSG00000027365",
  "ENSMUSG00000027366",
  "ENSMUSG00000027367",
  "ENSMUSG00000027368",
  "ENSMUSG00000027371",
  "ENSMUSG00000027375",
  "ENSMUSG00000027376",
  "ENSMUSG00000027377",
  "ENSMUSG00000027378",
  "ENSMUSG00000027379",
  "ENSMUSG00000027380",
  "ENSMUSG00000027381",
  "ENSMUSG00000027384",
  "ENSMUSG00000027386",
  "ENSMUSG00000027387",
  "ENSMUSG00000027394",
  "ENSMUSG00000027395",
  "ENSMUSG00000027397",
  "ENSMUSG00000027398",
  "ENSMUSG00000027399",
  "ENSMUSG00000027400",
  "ENSMUSG00000027401",
  "ENSMUSG00000027403",
  "ENSMUSG00000027404",
  "ENSMUSG00000027405",
  "ENSMUSG00000027406",
  "ENSMUSG00000027408",
  "ENSMUSG00000027409",
  "ENSMUSG00000027411",
  "ENSMUSG00000027412",
  "ENSMUSG00000027416",
  "ENSMUSG00000027419",
  "ENSMUSG00000027420",
  "ENSMUSG00000027422",
  "ENSMUSG00000027423",
  "ENSMUSG00000027424",
  "ENSMUSG00000027425",
  "ENSMUSG00000027427",
  "ENSMUSG00000027428",
  "ENSMUSG00000027429",
  "ENSMUSG00000027430",
  "ENSMUSG00000027431",
  "ENSMUSG00000027433",
  "ENSMUSG00000027434",
  "ENSMUSG00000027435",
  "ENSMUSG00000027438",
  "ENSMUSG00000027439",
  "ENSMUSG00000027442",
  "ENSMUSG00000027443",
  "ENSMUSG00000027444",
  "ENSMUSG00000027445",
  "ENSMUSG00000027446",
  "ENSMUSG00000027447",
  "ENSMUSG00000027452",
  "ENSMUSG00000027454",
  "ENSMUSG00000027455",
  "ENSMUSG00000027456",
  "ENSMUSG00000027457",
  "ENSMUSG00000027459",
  "ENSMUSG00000027460",
  "ENSMUSG00000027463",
  "ENSMUSG00000027465",
  "ENSMUSG00000027466",
  "ENSMUSG00000027468",
  "ENSMUSG00000027469",
  "ENSMUSG00000027470",
  "ENSMUSG00000027472",
  "ENSMUSG00000027474",
  "ENSMUSG00000027475",
  "ENSMUSG00000027478",
  "ENSMUSG00000027479",
  "ENSMUSG00000027480",
  "ENSMUSG00000027481",
  "ENSMUSG00000027482",
  "ENSMUSG00000027483",
  "ENSMUSG00000027484",
  "ENSMUSG00000027485",
  "ENSMUSG00000027487",
  "ENSMUSG00000027488",
  "ENSMUSG00000027489",
  "ENSMUSG00000027490",
  "ENSMUSG00000027495",
  "ENSMUSG00000027496",
  "ENSMUSG00000027498",
  "ENSMUSG00000027499",
  "ENSMUSG00000027500",
  "ENSMUSG00000027502",
  "ENSMUSG00000027505",
  "ENSMUSG00000027506",
  "ENSMUSG00000027508",
  "ENSMUSG00000027509",
  "ENSMUSG00000027510",
  "ENSMUSG00000027513",
  "ENSMUSG00000027514",
  "ENSMUSG00000027517",
  "ENSMUSG00000027518",
  "ENSMUSG00000027519",
  "ENSMUSG00000027520",
  "ENSMUSG00000027522",
  "ENSMUSG00000027523",
  "ENSMUSG00000027524",
  "ENSMUSG00000027525",
  "ENSMUSG00000027528",
  "ENSMUSG00000027530",
  "ENSMUSG00000027531",
  "ENSMUSG00000027533",
  "ENSMUSG00000027534",
  "ENSMUSG00000027536",
  "ENSMUSG00000027540",
  "ENSMUSG00000027544",
  "ENSMUSG00000027546",
  "ENSMUSG00000027547",
  "ENSMUSG00000027550",
  "ENSMUSG00000027551",
  "ENSMUSG00000027552",
  "ENSMUSG00000027555",
  "ENSMUSG00000027556",
  "ENSMUSG00000027559",
  "ENSMUSG00000027560",
  "ENSMUSG00000027562",
  "ENSMUSG00000027564",
  "ENSMUSG00000027566",
  "ENSMUSG00000027568",
  "ENSMUSG00000027569",
  "ENSMUSG00000027570",
  "ENSMUSG00000027573",
  "ENSMUSG00000027574",
  "ENSMUSG00000027575",
  "ENSMUSG00000027577",
  "ENSMUSG00000027579",
  "ENSMUSG00000027580",
  "ENSMUSG00000027582",
  "ENSMUSG00000027583",
  "ENSMUSG00000027584",
  "ENSMUSG00000027589",
  "ENSMUSG00000027593",
  "ENSMUSG00000027596",
  "ENSMUSG00000027597",
  "ENSMUSG00000027598",
  "ENSMUSG00000027599",
  "ENSMUSG00000027601",
  "ENSMUSG00000027602",
  "ENSMUSG00000027603",
  "ENSMUSG00000027605",
  "ENSMUSG00000027606",
  "ENSMUSG00000027610",
  "ENSMUSG00000027611",
  "ENSMUSG00000027612",
  "ENSMUSG00000027613",
  "ENSMUSG00000027615",
  "ENSMUSG00000027618",
  "ENSMUSG00000027620",
  "ENSMUSG00000027624",
  "ENSMUSG00000027628",
  "ENSMUSG00000027630",
  "ENSMUSG00000027634",
  "ENSMUSG00000027635",
  "ENSMUSG00000027636",
  "ENSMUSG00000027637",
  "ENSMUSG00000027639",
  "ENSMUSG00000027641",
  "ENSMUSG00000027642",
  "ENSMUSG00000027643",
  "ENSMUSG00000027646",
  "ENSMUSG00000027649",
  "ENSMUSG00000027650",
  "ENSMUSG00000027651",
  "ENSMUSG00000027652",
  "ENSMUSG00000027654",
  "ENSMUSG00000027655",
  "ENSMUSG00000027656",
  "ENSMUSG00000027660",
  "ENSMUSG00000027661",
  "ENSMUSG00000027663",
  "ENSMUSG00000027665",
  "ENSMUSG00000027667",
  "ENSMUSG00000027668",
  "ENSMUSG00000027669",
  "ENSMUSG00000027670",
  "ENSMUSG00000027671",
  "ENSMUSG00000027673",
  "ENSMUSG00000027674",
  "ENSMUSG00000027676",
  "ENSMUSG00000027677",
  "ENSMUSG00000027678",
  "ENSMUSG00000027679",
  "ENSMUSG00000027680",
  "ENSMUSG00000027684",
  "ENSMUSG00000027690",
  "ENSMUSG00000027692",
  "ENSMUSG00000027695",
  "ENSMUSG00000027698",
  "ENSMUSG00000027699",
  "ENSMUSG00000027702",
  "ENSMUSG00000027706",
  "ENSMUSG00000027708",
  "ENSMUSG00000027709",
  "ENSMUSG00000027712",
  "ENSMUSG00000027713",
  "ENSMUSG00000027715",
  "ENSMUSG00000027716",
  "ENSMUSG00000027718",
  "ENSMUSG00000027719",
  "ENSMUSG00000027720",
  "ENSMUSG00000027722",
  "ENSMUSG00000027737",
  "ENSMUSG00000027739",
  "ENSMUSG00000027742",
  "ENSMUSG00000027744",
  "ENSMUSG00000027746",
  "ENSMUSG00000027748",
  "ENSMUSG00000027750",
  "ENSMUSG00000027751",
  "ENSMUSG00000027752",
  "ENSMUSG00000027761",
  "ENSMUSG00000027762",
  "ENSMUSG00000027763",
  "ENSMUSG00000027765",
  "ENSMUSG00000027770",
  "ENSMUSG00000027774",
  "ENSMUSG00000027775",
  "ENSMUSG00000027778",
  "ENSMUSG00000027782",
  "ENSMUSG00000027784",
  "ENSMUSG00000027787",
  "ENSMUSG00000027788",
  "ENSMUSG00000027790",
  "ENSMUSG00000027792",
  "ENSMUSG00000027793",
  "ENSMUSG00000027794",
  "ENSMUSG00000027796",
  "ENSMUSG00000027797",
  "ENSMUSG00000027799",
  "ENSMUSG00000027800",
  "ENSMUSG00000027801",
  "ENSMUSG00000027803",
  "ENSMUSG00000027804",
  "ENSMUSG00000027805",
  "ENSMUSG00000027806",
  "ENSMUSG00000027808",
  "ENSMUSG00000027809",
  "ENSMUSG00000027810",
  "ENSMUSG00000027811",
  "ENSMUSG00000027820",
  "ENSMUSG00000027822",
  "ENSMUSG00000027823",
  "ENSMUSG00000027824",
  "ENSMUSG00000027827",
  "ENSMUSG00000027828",
  "ENSMUSG00000027829",
  "ENSMUSG00000027831",
  "ENSMUSG00000027832",
  "ENSMUSG00000027833",
  "ENSMUSG00000027834",
  "ENSMUSG00000027835",
  "ENSMUSG00000027840",
  "ENSMUSG00000027843",
  "ENSMUSG00000027845",
  "ENSMUSG00000027848",
  "ENSMUSG00000027849",
  "ENSMUSG00000027852",
  "ENSMUSG00000027854",
  "ENSMUSG00000027855",
  "ENSMUSG00000027857",
  "ENSMUSG00000027858",
  "ENSMUSG00000027859",
  "ENSMUSG00000027860",
  "ENSMUSG00000027861",
  "ENSMUSG00000027863",
  "ENSMUSG00000027864",
  "ENSMUSG00000027865",
  "ENSMUSG00000027867",
  "ENSMUSG00000027868",
  "ENSMUSG00000027869",
  "ENSMUSG00000027870",
  "ENSMUSG00000027871",
  "ENSMUSG00000027875",
  "ENSMUSG00000027876",
  "ENSMUSG00000027878",
  "ENSMUSG00000027879",
  "ENSMUSG00000027880",
  "ENSMUSG00000027881",
  "ENSMUSG00000027882",
  "ENSMUSG00000027883",
  "ENSMUSG00000027884",
  "ENSMUSG00000027886",
  "ENSMUSG00000027887",
  "ENSMUSG00000027889",
  "ENSMUSG00000027890",
  "ENSMUSG00000027893",
  "ENSMUSG00000027894",
  "ENSMUSG00000027895",
  "ENSMUSG00000027896",
  "ENSMUSG00000027901",
  "ENSMUSG00000027902",
  "ENSMUSG00000027905",
  "ENSMUSG00000027907",
  "ENSMUSG00000027908",
  "ENSMUSG00000027912",
  "ENSMUSG00000027913",
  "ENSMUSG00000027919",
  "ENSMUSG00000027923",
  "ENSMUSG00000027925",
  "ENSMUSG00000027927",
  "ENSMUSG00000027931",
  "ENSMUSG00000027932",
  "ENSMUSG00000027933",
  "ENSMUSG00000027935",
  "ENSMUSG00000027936",
  "ENSMUSG00000027937",
  "ENSMUSG00000027938",
  "ENSMUSG00000027939",
  "ENSMUSG00000027940",
  "ENSMUSG00000027942",
  "ENSMUSG00000027944",
  "ENSMUSG00000027947",
  "ENSMUSG00000027950",
  "ENSMUSG00000027951",
  "ENSMUSG00000027952",
  "ENSMUSG00000027953",
  "ENSMUSG00000027954",
  "ENSMUSG00000027955",
  "ENSMUSG00000027956",
  "ENSMUSG00000027957",
  "ENSMUSG00000027959",
  "ENSMUSG00000027961",
  "ENSMUSG00000027962",
  "ENSMUSG00000027963",
  "ENSMUSG00000027965",
  "ENSMUSG00000027966",
  "ENSMUSG00000027967",
  "ENSMUSG00000027968",
  "ENSMUSG00000027971",
  "ENSMUSG00000027973",
  "ENSMUSG00000027977",
  "ENSMUSG00000027978",
  "ENSMUSG00000027981",

  "ENSMUSG00000027983",
  "ENSMUSG00000027984",
  "ENSMUSG00000027985",
  "ENSMUSG00000027993",
  "ENSMUSG00000027994",
  "ENSMUSG00000027995",
  "ENSMUSG00000027996",
  "ENSMUSG00000027997",
  "ENSMUSG00000027998",
  "ENSMUSG00000027999",
  "ENSMUSG00000028001",
  "ENSMUSG00000028003",
  "ENSMUSG00000028004",
  "ENSMUSG00000028005",
  "ENSMUSG00000028007",
  "ENSMUSG00000028008",
  "ENSMUSG00000028009",
  "ENSMUSG00000028010",
  "ENSMUSG00000028011",
  "ENSMUSG00000028012",
  "ENSMUSG00000028013",
  "ENSMUSG00000028015",
  "ENSMUSG00000028016",
  "ENSMUSG00000028017",
  "ENSMUSG00000028018",
  "ENSMUSG00000028019",
  "ENSMUSG00000028020",
  "ENSMUSG00000028023",
  "ENSMUSG00000028024",
  "ENSMUSG00000028028",
  "ENSMUSG00000028029",
  "ENSMUSG00000028030",
  "ENSMUSG00000028031",
  "ENSMUSG00000028032",
  "ENSMUSG00000028033",
  "ENSMUSG00000028034",
  "ENSMUSG00000028035",
  "ENSMUSG00000028036",
  "ENSMUSG00000028037",
  "ENSMUSG00000028039",
  "ENSMUSG00000028040",
  "ENSMUSG00000028041",
  "ENSMUSG00000028042",
  "ENSMUSG00000028044",
  "ENSMUSG00000028047",
  "ENSMUSG00000028048",
  "ENSMUSG00000028049",
  "ENSMUSG00000028051",
  "ENSMUSG00000028053",
  "ENSMUSG00000028057",
  "ENSMUSG00000028059",
  "ENSMUSG00000028060",
  "ENSMUSG00000028062",
  "ENSMUSG00000028063",
  "ENSMUSG00000028064",
  "ENSMUSG00000028066",
  "ENSMUSG00000028068",
  "ENSMUSG00000028069",
  "ENSMUSG00000028070",
  "ENSMUSG00000028071",
  "ENSMUSG00000028072",
  "ENSMUSG00000028073",
  "ENSMUSG00000028076",
  "ENSMUSG00000028078",
  "ENSMUSG00000028080",
  "ENSMUSG00000028081",
  "ENSMUSG00000028082",
  "ENSMUSG00000028085",
  "ENSMUSG00000028086",
  "ENSMUSG00000028088",
  "ENSMUSG00000028089",
  "ENSMUSG00000028093",
  "ENSMUSG00000028096",
  "ENSMUSG00000028098",
  "ENSMUSG00000028099",
  "ENSMUSG00000028100",
  "ENSMUSG00000028101",
  "ENSMUSG00000028102",
  "ENSMUSG00000028104",
  "ENSMUSG00000028106",
  "ENSMUSG00000028107",
  "ENSMUSG00000028108",
  "ENSMUSG00000028109",
  "ENSMUSG00000028111",
  "ENSMUSG00000028114",
  "ENSMUSG00000028115",
  "ENSMUSG00000028116",
  "ENSMUSG00000028121",
  "ENSMUSG00000028124",
  "ENSMUSG00000028125",
  "ENSMUSG00000028126",
  "ENSMUSG00000028127",
  "ENSMUSG00000028128",
  "ENSMUSG00000028132",
  "ENSMUSG00000028133",
  "ENSMUSG00000028134",
  "ENSMUSG00000028136",
  "ENSMUSG00000028137",
  "ENSMUSG00000028138",
  "ENSMUSG00000028139",
  "ENSMUSG00000028140",
  "ENSMUSG00000028141",
  "ENSMUSG00000028145",
  "ENSMUSG00000028148",
  "ENSMUSG00000028149",
  "ENSMUSG00000028150",
  "ENSMUSG00000028152",
  "ENSMUSG00000028156",
  "ENSMUSG00000028158",
  "ENSMUSG00000028159",
  "ENSMUSG00000028161",
  "ENSMUSG00000028163",
  "ENSMUSG00000028164",
  "ENSMUSG00000028165",
  "ENSMUSG00000028167",
  "ENSMUSG00000028172",
  "ENSMUSG00000028173",
  "ENSMUSG00000028174",
  "ENSMUSG00000028176",
  "ENSMUSG00000028177",
  "ENSMUSG00000028179",
  "ENSMUSG00000028180",
  "ENSMUSG00000028182",
  "ENSMUSG00000028184",
  "ENSMUSG00000028185",
  "ENSMUSG00000028186",
  "ENSMUSG00000028187",
  "ENSMUSG00000028188",
  "ENSMUSG00000028189",
  "ENSMUSG00000028191",
  "ENSMUSG00000028194",
  "ENSMUSG00000028195",
  "ENSMUSG00000028197",
  "ENSMUSG00000028199",
  "ENSMUSG00000028207",
  "ENSMUSG00000028211",
  "ENSMUSG00000028212",
  "ENSMUSG00000028214",
  "ENSMUSG00000028217",
  "ENSMUSG00000028218",
  "ENSMUSG00000028221",
  "ENSMUSG00000028222",
  "ENSMUSG00000028223",
  "ENSMUSG00000028224",
  "ENSMUSG00000028226",
  "ENSMUSG00000028228",
  "ENSMUSG00000028229",
  "ENSMUSG00000028232",
  "ENSMUSG00000028233",
  "ENSMUSG00000028234",
  "ENSMUSG00000028236",
  "ENSMUSG00000028238",
  "ENSMUSG00000028240",
  "ENSMUSG00000028243",
  "ENSMUSG00000028244",
  "ENSMUSG00000028245",
  "ENSMUSG00000028246",
  "ENSMUSG00000028247",
  "ENSMUSG00000028248",
  "ENSMUSG00000028249",
  "ENSMUSG00000028251",
  "ENSMUSG00000028252",
  "ENSMUSG00000028255",
  "ENSMUSG00000028256",
  "ENSMUSG00000028259",
  "ENSMUSG00000028261",
  "ENSMUSG00000028262",
  "ENSMUSG00000028264",
  "ENSMUSG00000028266",
  "ENSMUSG00000028268",
  "ENSMUSG00000028270",
  "ENSMUSG00000028271",
  "ENSMUSG00000028273",
  "ENSMUSG00000028274",
  "ENSMUSG00000028277",
  "ENSMUSG00000028278",
  "ENSMUSG00000028282",
  "ENSMUSG00000028284",
  "ENSMUSG00000028287",
  "ENSMUSG00000028289",
  "ENSMUSG00000028291",
  "ENSMUSG00000028292",
  "ENSMUSG00000028293",
  "ENSMUSG00000028294",
  "ENSMUSG00000028295",
  "ENSMUSG00000028298",
  "ENSMUSG00000028300",
  "ENSMUSG00000028307",
  "ENSMUSG00000028309",
  "ENSMUSG00000028310",
  "ENSMUSG00000028312",
  "ENSMUSG00000028314",
  "ENSMUSG00000028318",
  "ENSMUSG00000028322",
  "ENSMUSG00000028327",
  "ENSMUSG00000028328",
  "ENSMUSG00000028329",
  "ENSMUSG00000028330",
  "ENSMUSG00000028331",
  "ENSMUSG00000028333",
  "ENSMUSG00000028334",
  "ENSMUSG00000028337",
  "ENSMUSG00000028339",
  "ENSMUSG00000028341",
  "ENSMUSG00000028343",
  "ENSMUSG00000028344",
  "ENSMUSG00000028345",
  "ENSMUSG00000028347",
  "ENSMUSG00000028348",
  "ENSMUSG00000028351",
  "ENSMUSG00000028354",
  "ENSMUSG00000028356",
  "ENSMUSG00000028357",
  "ENSMUSG00000028358",
  "ENSMUSG00000028359",
  "ENSMUSG00000028360",
  "ENSMUSG00000028362",
  "ENSMUSG00000028364",
  "ENSMUSG00000028367",
  "ENSMUSG00000028369",
  "ENSMUSG00000028370",
  "ENSMUSG00000028373",
  "ENSMUSG00000028378",
  "ENSMUSG00000028381",
  "ENSMUSG00000028382",
  "ENSMUSG00000028383",
  "ENSMUSG00000028385",
  "ENSMUSG00000028386",
  "ENSMUSG00000028389",
  "ENSMUSG00000028391",
  "ENSMUSG00000028392",
  "ENSMUSG00000028393",
  "ENSMUSG00000028394",
  "ENSMUSG00000028396",
  "ENSMUSG00000028397",
  "ENSMUSG00000028398",
  "ENSMUSG00000028399",
  "ENSMUSG00000028402",
  "ENSMUSG00000028403",
  "ENSMUSG00000028407",
  "ENSMUSG00000028409",
  "ENSMUSG00000028410",
  "ENSMUSG00000028411",
  "ENSMUSG00000028412",
  "ENSMUSG00000028413",
  "ENSMUSG00000028414",
  "ENSMUSG00000028415",
  "ENSMUSG00000028416",
  "ENSMUSG00000028417",
  "ENSMUSG00000028419",
  "ENSMUSG00000028420",
  "ENSMUSG00000028423",
  "ENSMUSG00000028426",
  "ENSMUSG00000028427",
  "ENSMUSG00000028430",
  "ENSMUSG00000028431",
  "ENSMUSG00000028433",
  "ENSMUSG00000028434",
  "ENSMUSG00000028435",
  "ENSMUSG00000028436",
  "ENSMUSG00000028437",
  "ENSMUSG00000028438",
  "ENSMUSG00000028441",
  "ENSMUSG00000028443",
  "ENSMUSG00000028444",
  "ENSMUSG00000028445",
  "ENSMUSG00000028447",
  "ENSMUSG00000028451",
  "ENSMUSG00000028452",
  "ENSMUSG00000028453",
  "ENSMUSG00000028454",
  "ENSMUSG00000028455",
  "ENSMUSG00000028456",
  "ENSMUSG00000028457",
  "ENSMUSG00000028458",
  "ENSMUSG00000028459",
  "ENSMUSG00000028460",
  "ENSMUSG00000028461",
  "ENSMUSG00000028463",
  "ENSMUSG00000028464",
  "ENSMUSG00000028465",
  "ENSMUSG00000028466",
  "ENSMUSG00000028467",
  "ENSMUSG00000028468",
  "ENSMUSG00000028469",
  "ENSMUSG00000028470",
  "ENSMUSG00000028475",
  "ENSMUSG00000028476",
  "ENSMUSG00000028478",
  "ENSMUSG00000028479",
  "ENSMUSG00000028480",
  "ENSMUSG00000028483",
  "ENSMUSG00000028484",
  "ENSMUSG00000028487",
  "ENSMUSG00000028488",
  "ENSMUSG00000028492",
  "ENSMUSG00000028494",
  "ENSMUSG00000028496",
  "ENSMUSG00000028497",
  "ENSMUSG00000028514",
  "ENSMUSG00000028517",
  "ENSMUSG00000028518",
  "ENSMUSG00000028519",
  "ENSMUSG00000028520",
  "ENSMUSG00000028521",
  "ENSMUSG00000028522",
  "ENSMUSG00000028523",
  "ENSMUSG00000028524",
  "ENSMUSG00000028525",
  "ENSMUSG00000028527",
  "ENSMUSG00000028528",
  "ENSMUSG00000028530",
  "ENSMUSG00000028532",
  "ENSMUSG00000028533",
  "ENSMUSG00000028536",
  "ENSMUSG00000028538",
  "ENSMUSG00000028539",
  "ENSMUSG00000028540",
  "ENSMUSG00000028541",
  "ENSMUSG00000028542",
  "ENSMUSG00000028544",
  "ENSMUSG00000028545",
  "ENSMUSG00000028546",
  "ENSMUSG00000028549",
  "ENSMUSG00000028550",
  "ENSMUSG00000028551",
  "ENSMUSG00000028552",
  "ENSMUSG00000028553",
  "ENSMUSG00000028555",
  "ENSMUSG00000028556",
  "ENSMUSG00000028557",
  "ENSMUSG00000028558",
  "ENSMUSG00000028560",
  "ENSMUSG00000028563",
  "ENSMUSG00000028565",
  "ENSMUSG00000028567",
  "ENSMUSG00000028568",
  "ENSMUSG00000028571",
  "ENSMUSG00000028572",
  "ENSMUSG00000028573",
  "ENSMUSG00000028575",
  "ENSMUSG00000028576",
  "ENSMUSG00000028577",
  "ENSMUSG00000028578",
  "ENSMUSG00000028580",
  "ENSMUSG00000028581",
  "ENSMUSG00000028582",
  "ENSMUSG00000028583",
  "ENSMUSG00000028584",
  "ENSMUSG00000028587",
  "ENSMUSG00000028589",
  "ENSMUSG00000028590",
  "ENSMUSG00000028591",
  "ENSMUSG00000028593",
  "ENSMUSG00000028597",
  "ENSMUSG00000028600",
  "ENSMUSG00000028601",
  "ENSMUSG00000028602",
  "ENSMUSG00000028607",
  "ENSMUSG00000028608",
  "ENSMUSG00000028609",
  "ENSMUSG00000028610",
  "ENSMUSG00000028612",
  "ENSMUSG00000028613",
  "ENSMUSG00000028614",
  "ENSMUSG00000028617",
  "ENSMUSG00000028618",
  "ENSMUSG00000028619",
  "ENSMUSG00000028621",
  "ENSMUSG00000028622",
  "ENSMUSG00000028626",
  "ENSMUSG00000028629",
  "ENSMUSG00000028630",
  "ENSMUSG00000028631",
  "ENSMUSG00000028633",
  "ENSMUSG00000028634",
  "ENSMUSG00000028635",
  "ENSMUSG00000028636",
  "ENSMUSG00000028637",
  "ENSMUSG00000028639",
  "ENSMUSG00000028640",
  "ENSMUSG00000028641",
  "ENSMUSG00000028642",
  "ENSMUSG00000028643",
  "ENSMUSG00000028644",
  "ENSMUSG00000028645",
  "ENSMUSG00000028646",
  "ENSMUSG00000028647",
  "ENSMUSG00000028648",
  "ENSMUSG00000028649",
  "ENSMUSG00000028651",
  "ENSMUSG00000028653",
  "ENSMUSG00000028654",
  "ENSMUSG00000028655",
  "ENSMUSG00000028656",
  "ENSMUSG00000028657",
  "ENSMUSG00000028661",
  "ENSMUSG00000028664",
  "ENSMUSG00000028668",
  "ENSMUSG00000028669",
  "ENSMUSG00000028670",
  "ENSMUSG00000028671",
  "ENSMUSG00000028672",
  "ENSMUSG00000028673",
  "ENSMUSG00000028675",
  "ENSMUSG00000028676",
  "ENSMUSG00000028677",
  "ENSMUSG00000028678",
  "ENSMUSG00000028680",
  "ENSMUSG00000028681",
  "ENSMUSG00000028683",
  "ENSMUSG00000028684",
  "ENSMUSG00000028687",
  "ENSMUSG00000028688",
  "ENSMUSG00000028689",
  "ENSMUSG00000028690",
  "ENSMUSG00000028691",
  "ENSMUSG00000028692",
  "ENSMUSG00000028693",
  "ENSMUSG00000028696",
  "ENSMUSG00000028698",
  "ENSMUSG00000028699",
  "ENSMUSG00000028700",
  "ENSMUSG00000028701",
  "ENSMUSG00000028702",
  "ENSMUSG00000028703",
  "ENSMUSG00000028706",
  "ENSMUSG00000028707",
  "ENSMUSG00000028708",
  "ENSMUSG00000028709",
  "ENSMUSG00000028710",
  "ENSMUSG00000028712",
  "ENSMUSG00000028713",
  "ENSMUSG00000028716",
  "ENSMUSG00000028717",
  "ENSMUSG00000028718",
  "ENSMUSG00000028719",
  "ENSMUSG00000028729",
  "ENSMUSG00000028730",
  "ENSMUSG00000028736",
  "ENSMUSG00000028737",
  "ENSMUSG00000028738",
  "ENSMUSG00000028741",
  "ENSMUSG00000028743",
  "ENSMUSG00000028744",
  "ENSMUSG00000028745",
  "ENSMUSG00000028747",
  "ENSMUSG00000028749",
  "ENSMUSG00000028750",
  "ENSMUSG00000028751",
  "ENSMUSG00000028753",
  "ENSMUSG00000028755",
  "ENSMUSG00000028756",
  "ENSMUSG00000028757",
  "ENSMUSG00000028758",
  "ENSMUSG00000028759",
  "ENSMUSG00000028760",
  "ENSMUSG00000028763",
  "ENSMUSG00000028766",
  "ENSMUSG00000028771",
  "ENSMUSG00000028772",
  "ENSMUSG00000028773",
  "ENSMUSG00000028776",
  "ENSMUSG00000028777",
  "ENSMUSG00000028778",
  "ENSMUSG00000028779",
  "ENSMUSG00000028780",
  "ENSMUSG00000028782",
  "ENSMUSG00000028784",
  "ENSMUSG00000028785",
  "ENSMUSG00000028786",
  "ENSMUSG00000028788",
  "ENSMUSG00000028789",
  "ENSMUSG00000028790",
  "ENSMUSG00000028792",
  "ENSMUSG00000028793",
  "ENSMUSG00000028794",
  "ENSMUSG00000028795",
  "ENSMUSG00000028796",
  "ENSMUSG00000028797",
  "ENSMUSG00000028798",
  "ENSMUSG00000028799",
  "ENSMUSG00000028800",
  "ENSMUSG00000028801",
  "ENSMUSG00000028803",
  "ENSMUSG00000028804",
  "ENSMUSG00000028807",
  "ENSMUSG00000028809",
  "ENSMUSG00000028811",
  "ENSMUSG00000028813",
  "ENSMUSG00000028820",
  "ENSMUSG00000028821",
  "ENSMUSG00000028822",
  "ENSMUSG00000028825",
  "ENSMUSG00000028826",
  "ENSMUSG00000028830",
  "ENSMUSG00000028832",
  "ENSMUSG00000028833",
  "ENSMUSG00000028834",
  "ENSMUSG00000028836",
  "ENSMUSG00000028837",
  "ENSMUSG00000028838",
  "ENSMUSG00000028840",
  "ENSMUSG00000028841",
  "ENSMUSG00000028842",
  "ENSMUSG00000028843",
  "ENSMUSG00000028845",
  "ENSMUSG00000028847",
  "ENSMUSG00000028848",
  "ENSMUSG00000028849",
  "ENSMUSG00000028850",
  "ENSMUSG00000028851",
  "ENSMUSG00000028854",
  "ENSMUSG00000028857",
  "ENSMUSG00000028859",
  "ENSMUSG00000028860",
  "ENSMUSG00000028861",
  "ENSMUSG00000028862",
  "ENSMUSG00000028863",
  "ENSMUSG00000028864",
  "ENSMUSG00000028865",
  "ENSMUSG00000028868",
  "ENSMUSG00000028869",
  "ENSMUSG00000028871",
  "ENSMUSG00000028873",
  "ENSMUSG00000028874",
  "ENSMUSG00000028876",
  "ENSMUSG00000028878",
  "ENSMUSG00000028879",
  "ENSMUSG00000028882",
  "ENSMUSG00000028883",
  "ENSMUSG00000028884",
  "ENSMUSG00000028885",
  "ENSMUSG00000028886",
  "ENSMUSG00000028889",
  "ENSMUSG00000028890",
  "ENSMUSG00000028893",
  "ENSMUSG00000028894",
  "ENSMUSG00000028896",
  "ENSMUSG00000028898",
  "ENSMUSG00000028899",
  "ENSMUSG00000028901",
  "ENSMUSG00000028902",
  "ENSMUSG00000028906",
  "ENSMUSG00000028907",
  "ENSMUSG00000028909",
  "ENSMUSG00000028910",
  "ENSMUSG00000028911",
  "ENSMUSG00000028914",
  "ENSMUSG00000028917",
  "ENSMUSG00000028919",
  "ENSMUSG00000028920",
  "ENSMUSG00000028923",
  "ENSMUSG00000028926",
  "ENSMUSG00000028927",
  "ENSMUSG00000028931",
  "ENSMUSG00000028932",
  "ENSMUSG00000028933",
  "ENSMUSG00000028936",
  "ENSMUSG00000028937",
  "ENSMUSG00000028938",
  "ENSMUSG00000028940",
  "ENSMUSG00000028943",
  "ENSMUSG00000028945",
  "ENSMUSG00000028946",
  "ENSMUSG00000028948",
  "ENSMUSG00000028949",
  "ENSMUSG00000028950",
  "ENSMUSG00000028952",
  "ENSMUSG00000028953",
  "ENSMUSG00000028954",
  "ENSMUSG00000028955",
  "ENSMUSG00000028957",
  "ENSMUSG00000028958",
  "ENSMUSG00000028959",
  "ENSMUSG00000028960",
  "ENSMUSG00000028961",
  "ENSMUSG00000028962",
  "ENSMUSG00000028963",
  "ENSMUSG00000028964",
  "ENSMUSG00000028965",
  "ENSMUSG00000028967",
  "ENSMUSG00000028969",
  "ENSMUSG00000028970",
  "ENSMUSG00000028971",
  "ENSMUSG00000028972",
  "ENSMUSG00000028973",
  "ENSMUSG00000028974",
  "ENSMUSG00000028975",
  "ENSMUSG00000028976",
  "ENSMUSG00000028977",
  "ENSMUSG00000028978",
  "ENSMUSG00000028979",
  "ENSMUSG00000028980",
  "ENSMUSG00000028982",
  "ENSMUSG00000028986",
  "ENSMUSG00000028988",
  "ENSMUSG00000028989",
  "ENSMUSG00000028990",
  "ENSMUSG00000028991",
  "ENSMUSG00000028992",
  "ENSMUSG00000028995",
  "ENSMUSG00000028996",
  "ENSMUSG00000028998",
  "ENSMUSG00000029001",
  "ENSMUSG00000029003",
  "ENSMUSG00000029004",
  "ENSMUSG00000029005",
  "ENSMUSG00000029007",
  "ENSMUSG00000029009",
  "ENSMUSG00000029012",
  "ENSMUSG00000029014",
  "ENSMUSG00000029015",
  "ENSMUSG00000029016",
  "ENSMUSG00000029017",
  "ENSMUSG00000029019",
  "ENSMUSG00000029020",
  "ENSMUSG00000029022",
  "ENSMUSG00000029026",
  "ENSMUSG00000029027",
  "ENSMUSG00000029028",
  "ENSMUSG00000029029",
  "ENSMUSG00000029030",
  "ENSMUSG00000029032",
  "ENSMUSG00000029033",
  "ENSMUSG00000029034",
  "ENSMUSG00000029036",
  "ENSMUSG00000029038",
  "ENSMUSG00000029044",
  "ENSMUSG00000029047",
  "ENSMUSG00000029048",
  "ENSMUSG00000029049",
  "ENSMUSG00000029050",
  "ENSMUSG00000029053",
  "ENSMUSG00000029054",
  "ENSMUSG00000029055",
  "ENSMUSG00000029056",
  "ENSMUSG00000029059",
  "ENSMUSG00000029060",
  "ENSMUSG00000029061",
  "ENSMUSG00000029062",
  "ENSMUSG00000029063",
  "ENSMUSG00000029064",
  "ENSMUSG00000029066",
  "ENSMUSG00000029068",
  "ENSMUSG00000029070",
  "ENSMUSG00000029071",
  "ENSMUSG00000029072",
  "ENSMUSG00000029073",
  "ENSMUSG00000029074",
  "ENSMUSG00000029075",
  "ENSMUSG00000029076",
  "ENSMUSG00000029082",
  "ENSMUSG00000029084",
  "ENSMUSG00000029086",
  "ENSMUSG00000029088",
  "ENSMUSG00000029089",
  "ENSMUSG00000029090",
  "ENSMUSG00000029092",
  "ENSMUSG00000029093",
  "ENSMUSG00000029094",
  "ENSMUSG00000029095",
  "ENSMUSG00000029096",
  "ENSMUSG00000029097",
  "ENSMUSG00000029098",
  "ENSMUSG00000029101",
  "ENSMUSG00000029102",
  "ENSMUSG00000029103",
  "ENSMUSG00000029104",
  "ENSMUSG00000029106",
  "ENSMUSG00000029108",
  "ENSMUSG00000029110",
  "ENSMUSG00000029111",
  "ENSMUSG00000029112",
  "ENSMUSG00000029119",
  "ENSMUSG00000029120",
  "ENSMUSG00000029121",
  "ENSMUSG00000029122",
  "ENSMUSG00000029123",
  "ENSMUSG00000029125",
  "ENSMUSG00000029126",
  "ENSMUSG00000029127",
  "ENSMUSG00000029128",
  "ENSMUSG00000029130",
  "ENSMUSG00000029131",
  "ENSMUSG00000029134",
  "ENSMUSG00000029135",
  "ENSMUSG00000029136",
  "ENSMUSG00000029138",
  "ENSMUSG00000029141",
  "ENSMUSG00000029145",
  "ENSMUSG00000029146",
  "ENSMUSG00000029147",
  "ENSMUSG00000029148",
  "ENSMUSG00000029149",
  "ENSMUSG00000029151",
  "ENSMUSG00000029152",
  "ENSMUSG00000029153",
  "ENSMUSG00000029154",
  "ENSMUSG00000029155",
  "ENSMUSG00000029156",
  "ENSMUSG00000029158",
  "ENSMUSG00000029161",
  "ENSMUSG00000029162",
  "ENSMUSG00000029163",
  "ENSMUSG00000029165",
  "ENSMUSG00000029166",
  "ENSMUSG00000029167",
  "ENSMUSG00000029168",
  "ENSMUSG00000029169",
  "ENSMUSG00000029171",
  "ENSMUSG00000029173",
  "ENSMUSG00000029174",
  "ENSMUSG00000029175",
  "ENSMUSG00000029176",
  "ENSMUSG00000029177",
  "ENSMUSG00000029178",
  "ENSMUSG00000029179",
  "ENSMUSG00000029182",
  "ENSMUSG00000029184",
  "ENSMUSG00000029185",
  "ENSMUSG00000029186",
  "ENSMUSG00000029188",
  "ENSMUSG00000029189",
  "ENSMUSG00000029190",
  "ENSMUSG00000029191",
  "ENSMUSG00000029192",
  "ENSMUSG00000029193",
  "ENSMUSG00000029195",
  "ENSMUSG00000029196",
  "ENSMUSG00000029198",
  "ENSMUSG00000029199",
  "ENSMUSG00000029201",
  "ENSMUSG00000029202",
  "ENSMUSG00000029203",
  "ENSMUSG00000029204",
  "ENSMUSG00000029205",
  "ENSMUSG00000029206",
  "ENSMUSG00000029207",
  "ENSMUSG00000029208",
  "ENSMUSG00000029209",
  "ENSMUSG00000029211",
  "ENSMUSG00000029212",
  "ENSMUSG00000029213",
  "ENSMUSG00000029217",
  "ENSMUSG00000029219",
  "ENSMUSG00000029221",
  "ENSMUSG00000029223",
  "ENSMUSG00000029227",
  "ENSMUSG00000029228",
  "ENSMUSG00000029229",
  "ENSMUSG00000029231",
  "ENSMUSG00000029233",
  "ENSMUSG00000029234",
  "ENSMUSG00000029235",
  "ENSMUSG00000029236",
  "ENSMUSG00000029238",
  "ENSMUSG00000029245",
  "ENSMUSG00000029246",
  "ENSMUSG00000029247",
  "ENSMUSG00000029248",
  "ENSMUSG00000029249",
  "ENSMUSG00000029250",
  "ENSMUSG00000029254",
  "ENSMUSG00000029255",
  "ENSMUSG00000029260",
  "ENSMUSG00000029263",
  "ENSMUSG00000029265",
  "ENSMUSG00000029267",
  "ENSMUSG00000029269",
  "ENSMUSG00000029270",
  "ENSMUSG00000029272",
  "ENSMUSG00000029273",
  "ENSMUSG00000029275",
  "ENSMUSG00000029276",
  "ENSMUSG00000029279",
  "ENSMUSG00000029280",
  "ENSMUSG00000029281",
  "ENSMUSG00000029282",
  "ENSMUSG00000029283",
  "ENSMUSG00000029286",
  "ENSMUSG00000029287",
  "ENSMUSG00000029288",
  "ENSMUSG00000029290",
  "ENSMUSG00000029291",
  "ENSMUSG00000029298",
  "ENSMUSG00000029299",
  "ENSMUSG00000029304",
  "ENSMUSG00000029306",
  "ENSMUSG00000029307",
  "ENSMUSG00000029309",
  "ENSMUSG00000029310",
  "ENSMUSG00000029311",
  "ENSMUSG00000029312",
  "ENSMUSG00000029313",
  "ENSMUSG00000029314",
  "ENSMUSG00000029319",
  "ENSMUSG00000029320",
  "ENSMUSG00000029321",
  "ENSMUSG00000029322",
  "ENSMUSG00000029326",
  "ENSMUSG00000029328",
  "ENSMUSG00000029330",
  "ENSMUSG00000029331",
  "ENSMUSG00000029334",
  "ENSMUSG00000029335",
  "ENSMUSG00000029337",
  "ENSMUSG00000029338",
  "ENSMUSG00000029343",
  "ENSMUSG00000029344",
  "ENSMUSG00000029345",
  "ENSMUSG00000029346",
  "ENSMUSG00000029348",
  "ENSMUSG00000029352",
  "ENSMUSG00000029359",
  "ENSMUSG00000029360",
  "ENSMUSG00000029361",
  "ENSMUSG00000029363",
  "ENSMUSG00000029366",
  "ENSMUSG00000029368",
  "ENSMUSG00000029369",
  "ENSMUSG00000029370",
  "ENSMUSG00000029371",
  "ENSMUSG00000029372",
  "ENSMUSG00000029373",
  "ENSMUSG00000029375",
  "ENSMUSG00000029376",
  "ENSMUSG00000029377",
  "ENSMUSG00000029378",
  "ENSMUSG00000029379",
  "ENSMUSG00000029380",
  "ENSMUSG00000029384",
  "ENSMUSG00000029385",
  "ENSMUSG00000029386",
  "ENSMUSG00000029387",
  "ENSMUSG00000029388",
  "ENSMUSG00000029389",
  "ENSMUSG00000029390",
  "ENSMUSG00000029392",
  "ENSMUSG00000029397",
  "ENSMUSG00000029401",
  "ENSMUSG00000029402",
  "ENSMUSG00000029403",
  "ENSMUSG00000029404",
  "ENSMUSG00000029405",
  "ENSMUSG00000029406",
  "ENSMUSG00000029407",
  "ENSMUSG00000029408",
  "ENSMUSG00000029409",
  "ENSMUSG00000029410",
  "ENSMUSG00000029413",
  "ENSMUSG00000029414",
  "ENSMUSG00000029415",
  "ENSMUSG00000029416",
  "ENSMUSG00000029417",
  "ENSMUSG00000029419",
  "ENSMUSG00000029420",
  "ENSMUSG00000029422",
  "ENSMUSG00000029423",
  "ENSMUSG00000029426",
  "ENSMUSG00000029427",
  "ENSMUSG00000029428",
  "ENSMUSG00000029430",
  "ENSMUSG00000029431",
  "ENSMUSG00000029432",
  "ENSMUSG00000029434",
  "ENSMUSG00000029436",
  "ENSMUSG00000029437",
  "ENSMUSG00000029438",
  "ENSMUSG00000029439",
  "ENSMUSG00000029440",
  "ENSMUSG00000029442",
  "ENSMUSG00000029445",
  "ENSMUSG00000029446",
  "ENSMUSG00000029447",
  "ENSMUSG00000029449",
  "ENSMUSG00000029451",
  "ENSMUSG00000029452",
  "ENSMUSG00000029455",
  "ENSMUSG00000029456",
  "ENSMUSG00000029458",
  "ENSMUSG00000029461",
  "ENSMUSG00000029462",
  "ENSMUSG00000029463",
  "ENSMUSG00000029464",
  "ENSMUSG00000029465",
  "ENSMUSG00000029466",
  "ENSMUSG00000029467",
  "ENSMUSG00000029468",
  "ENSMUSG00000029469",
  "ENSMUSG00000029470",
  "ENSMUSG00000029471",
  "ENSMUSG00000029472",
  "ENSMUSG00000029474",
  "ENSMUSG00000029475",
  "ENSMUSG00000029477",
  "ENSMUSG00000029478",
  "ENSMUSG00000029480",
  "ENSMUSG00000029482",
  "ENSMUSG00000029484",
  "ENSMUSG00000029486",
  "ENSMUSG00000029490",
  "ENSMUSG00000029491",
  "ENSMUSG00000029499",
  "ENSMUSG00000029500",
  "ENSMUSG00000029501",
  "ENSMUSG00000029502",
  "ENSMUSG00000029503",
  "ENSMUSG00000029504",
  "ENSMUSG00000029505",
  "ENSMUSG00000029507",
  "ENSMUSG00000029512",
  "ENSMUSG00000029513",
  "ENSMUSG00000029516",
  "ENSMUSG00000029517",
  "ENSMUSG00000029518",
  "ENSMUSG00000029521",
  "ENSMUSG00000029522",
  "ENSMUSG00000029524",
  "ENSMUSG00000029526",
  "ENSMUSG00000029528",
  "ENSMUSG00000029530",
  "ENSMUSG00000029534",
  "ENSMUSG00000029535",
  "ENSMUSG00000029536",
  "ENSMUSG00000029538",
  "ENSMUSG00000029541",
  "ENSMUSG00000029544",
  "ENSMUSG00000029545",
  "ENSMUSG00000029546",
  "ENSMUSG00000029547",
  "ENSMUSG00000029550",
  "ENSMUSG00000029551",
  "ENSMUSG00000029552",
  "ENSMUSG00000029553",
  "ENSMUSG00000029554",
  "ENSMUSG00000029556",
  "ENSMUSG00000029557",
  "ENSMUSG00000029559",
  "ENSMUSG00000029560",
  "ENSMUSG00000029561",
  "ENSMUSG00000029563",
  "ENSMUSG00000029564",
  "ENSMUSG00000029569",
  "ENSMUSG00000029570",
  "ENSMUSG00000029571",
  "ENSMUSG00000029575",
  "ENSMUSG00000029576",
  "ENSMUSG00000029577",
  "ENSMUSG00000029578",
  "ENSMUSG00000029580",
  "ENSMUSG00000029581",
  "ENSMUSG00000029586",
  "ENSMUSG00000029587",
  "ENSMUSG00000029592",
  "ENSMUSG00000029594",
  "ENSMUSG00000029595",
  "ENSMUSG00000029596",
  "ENSMUSG00000029597",
  "ENSMUSG00000029598",
  "ENSMUSG00000029599",
  "ENSMUSG00000029600",
  "ENSMUSG00000029601",
  "ENSMUSG00000029602",
  "ENSMUSG00000029603",
  "ENSMUSG00000029607",
  "ENSMUSG00000029608",
  "ENSMUSG00000029610",
  "ENSMUSG00000029613",
  "ENSMUSG00000029614",
  "ENSMUSG00000029616",
  "ENSMUSG00000029617",
  "ENSMUSG00000029618",
  "ENSMUSG00000029620",
  "ENSMUSG00000029621",
  "ENSMUSG00000029622",
  "ENSMUSG00000029623",
  "ENSMUSG00000029624",
  "ENSMUSG00000029625",
  "ENSMUSG00000029627",
  "ENSMUSG00000029629",
  "ENSMUSG00000029630",
  "ENSMUSG00000029632",
  "ENSMUSG00000029634",
  "ENSMUSG00000029635",
  "ENSMUSG00000029636",
  "ENSMUSG00000029638",
  "ENSMUSG00000029640",
  "ENSMUSG00000029641",
  "ENSMUSG00000029642",
  "ENSMUSG00000029644",
  "ENSMUSG00000029646",
  "ENSMUSG00000029647",
  "ENSMUSG00000029648",
  "ENSMUSG00000029649",
  "ENSMUSG00000029650",
  "ENSMUSG00000029651",
  "ENSMUSG00000029655",
  "ENSMUSG00000029656",
  "ENSMUSG00000029657",
  "ENSMUSG00000029658",
  "ENSMUSG00000029659",
  "ENSMUSG00000029660",
  "ENSMUSG00000029661",
  "ENSMUSG00000029663",
  "ENSMUSG00000029664",
  "ENSMUSG00000029669",
  "ENSMUSG00000029670",
  "ENSMUSG00000029671",
  "ENSMUSG00000029672",
  "ENSMUSG00000029673",
  "ENSMUSG00000029674",
  "ENSMUSG00000029675",
  "ENSMUSG00000029676",
  "ENSMUSG00000029678",
  "ENSMUSG00000029679",
  "ENSMUSG00000029680",
  "ENSMUSG00000029681",
  "ENSMUSG00000029682",
  "ENSMUSG00000029683",
  "ENSMUSG00000029684",
  "ENSMUSG00000029685",
  "ENSMUSG00000029686",
  "ENSMUSG00000029687",
  "ENSMUSG00000029695",
  "ENSMUSG00000029697",
  "ENSMUSG00000029699",
  "ENSMUSG00000029700",
  "ENSMUSG00000029701",
  "ENSMUSG00000029703",
  "ENSMUSG00000029705",
  "ENSMUSG00000029706",
  "ENSMUSG00000029707",
  "ENSMUSG00000029708",
  "ENSMUSG00000029710",
  "ENSMUSG00000029711",
  "ENSMUSG00000029712",
  "ENSMUSG00000029714",
  "ENSMUSG00000029715",
  "ENSMUSG00000029716",
  "ENSMUSG00000029718",
  "ENSMUSG00000029722",
  "ENSMUSG00000029723",
  "ENSMUSG00000029725",
  "ENSMUSG00000029726",
  "ENSMUSG00000029727",
  "ENSMUSG00000029729",
  "ENSMUSG00000029730",
  "ENSMUSG00000029735",
  "ENSMUSG00000029736",
  "ENSMUSG00000029752",
  "ENSMUSG00000029754",
  "ENSMUSG00000029755",
  "ENSMUSG00000029757",
  "ENSMUSG00000029759",
  "ENSMUSG00000029761",
  "ENSMUSG00000029762",
  "ENSMUSG00000029763",
  "ENSMUSG00000029765",
  "ENSMUSG00000029766",
  "ENSMUSG00000029767",
  "ENSMUSG00000029769",
  "ENSMUSG00000029771",
  "ENSMUSG00000029772",
  "ENSMUSG00000029775",
  "ENSMUSG00000029776",
  "ENSMUSG00000029777",
  "ENSMUSG00000029778",
  "ENSMUSG00000029780",
  "ENSMUSG00000029781",
  "ENSMUSG00000029782",
  "ENSMUSG00000029784",
  "ENSMUSG00000029787",
  "ENSMUSG00000029788",
  "ENSMUSG00000029790",
  "ENSMUSG00000029797",
  "ENSMUSG00000029798",
  "ENSMUSG00000029802",
  "ENSMUSG00000029804",
  "ENSMUSG00000029810",
  "ENSMUSG00000029811",
  "ENSMUSG00000029813",
  "ENSMUSG00000029814",
  "ENSMUSG00000029815",
  "ENSMUSG00000029816",
  "ENSMUSG00000029817",
  "ENSMUSG00000029819",
  "ENSMUSG00000029821",
  "ENSMUSG00000029822",
  "ENSMUSG00000029823",
  "ENSMUSG00000029826",
  "ENSMUSG00000029828",
  "ENSMUSG00000029829",
  "ENSMUSG00000029830",
  "ENSMUSG00000029831",
  "ENSMUSG00000029832",
  "ENSMUSG00000029833",
  "ENSMUSG00000029836",
  "ENSMUSG00000029837",
  "ENSMUSG00000029838",
  "ENSMUSG00000029840",
  "ENSMUSG00000029843",
  "ENSMUSG00000029844",
  "ENSMUSG00000029847",
  "ENSMUSG00000029848",
  "ENSMUSG00000029851",
  "ENSMUSG00000029859",
  "ENSMUSG00000029860",
  "ENSMUSG00000029861",
  "ENSMUSG00000029862",
  "ENSMUSG00000029863",
  "ENSMUSG00000029864",
  "ENSMUSG00000029865",
  "ENSMUSG00000029866",
  "ENSMUSG00000029867",
  "ENSMUSG00000029868",
  "ENSMUSG00000029869",
  "ENSMUSG00000029875",
  "ENSMUSG00000029878",
  "ENSMUSG00000029882",
  "ENSMUSG00000029883",
  "ENSMUSG00000029885",
  "ENSMUSG00000029909",
  "ENSMUSG00000029910",
  "ENSMUSG00000029911",
  "ENSMUSG00000029913",
  "ENSMUSG00000029915",
  "ENSMUSG00000029916",
  "ENSMUSG00000029917",
  "ENSMUSG00000029918",
  "ENSMUSG00000029919",
  "ENSMUSG00000029920",
  "ENSMUSG00000029922",
  "ENSMUSG00000029923",
  "ENSMUSG00000029924",
  "ENSMUSG00000029925",
  "ENSMUSG00000029992",
  "ENSMUSG00000029993",
  "ENSMUSG00000029994",
  "ENSMUSG00000029998",
  "ENSMUSG00000029999",
  "ENSMUSG00000030000",
  "ENSMUSG00000030001",
  "ENSMUSG00000030002",
  "ENSMUSG00000030004",
  "ENSMUSG00000030007",
  "ENSMUSG00000030008",
  "ENSMUSG00000030016",
  "ENSMUSG00000030017",
  "ENSMUSG00000030019",
  "ENSMUSG00000030020",
  "ENSMUSG00000030022",
  "ENSMUSG00000030029",
  "ENSMUSG00000030030",
  "ENSMUSG00000030031",
  "ENSMUSG00000030032",
  "ENSMUSG00000030035",
  "ENSMUSG00000030036",
  "ENSMUSG00000030041",
  "ENSMUSG00000030043",
  "ENSMUSG00000030045",
  "ENSMUSG00000030046",
  "ENSMUSG00000030047",
  "ENSMUSG00000030048",
  "ENSMUSG00000030049",
  "ENSMUSG00000030050",
  "ENSMUSG00000030051",
  "ENSMUSG00000030054",
  "ENSMUSG00000030056",
  "ENSMUSG00000030057",
  "ENSMUSG00000030058",
  "ENSMUSG00000030059",
  "ENSMUSG00000030060",
  "ENSMUSG00000030061",
  "ENSMUSG00000030062",
  "ENSMUSG00000030064",
  "ENSMUSG00000030067",
  "ENSMUSG00000030069",
  "ENSMUSG00000030074",
  "ENSMUSG00000030075",
  "ENSMUSG00000030077",
  "ENSMUSG00000030079",
  "ENSMUSG00000030082",
  "ENSMUSG00000030083",
  "ENSMUSG00000030084",
  "ENSMUSG00000030086",
  "ENSMUSG00000030087",
  "ENSMUSG00000030088",
  "ENSMUSG00000030089",
  "ENSMUSG00000030091",
  "ENSMUSG00000030092",
  "ENSMUSG00000030093",
  "ENSMUSG00000030094",
  "ENSMUSG00000030095",
  "ENSMUSG00000030096",
  "ENSMUSG00000030098",
  "ENSMUSG00000030101",
  "ENSMUSG00000030102",
  "ENSMUSG00000030103",
  "ENSMUSG00000030104",
  "ENSMUSG00000030105",
  "ENSMUSG00000030107",
  "ENSMUSG00000030108",
  "ENSMUSG00000030109",
  "ENSMUSG00000030110",
  "ENSMUSG00000030111",
  "ENSMUSG00000030114",
  "ENSMUSG00000030116",
  "ENSMUSG00000030117",
  "ENSMUSG00000030120",
  "ENSMUSG00000030122",
  "ENSMUSG00000030123",
  "ENSMUSG00000030124",
  "ENSMUSG00000030125",
  "ENSMUSG00000030126",
  "ENSMUSG00000030127",
  "ENSMUSG00000030131",
  "ENSMUSG00000030134",
  "ENSMUSG00000030137",
  "ENSMUSG00000030138",
  "ENSMUSG00000030142",
  "ENSMUSG00000030144",
  "ENSMUSG00000030145",
  "ENSMUSG00000030147",
  "ENSMUSG00000030148",
  "ENSMUSG00000030149",
  "ENSMUSG00000030154",
  "ENSMUSG00000030155",
  "ENSMUSG00000030156",
  "ENSMUSG00000030157",
  "ENSMUSG00000030158",
  "ENSMUSG00000030159",
  "ENSMUSG00000030160",
  "ENSMUSG00000030161",
  "ENSMUSG00000030162",
  "ENSMUSG00000030165",
  "ENSMUSG00000030166",
  "ENSMUSG00000030167",
  "ENSMUSG00000030168",
  "ENSMUSG00000030170",
  "ENSMUSG00000030172",
  "ENSMUSG00000030173",
  "ENSMUSG00000030177",
  "ENSMUSG00000030180",
  "ENSMUSG00000030187",
  "ENSMUSG00000030188",
  "ENSMUSG00000030189",
  "ENSMUSG00000030194",
  "ENSMUSG00000030196",
  "ENSMUSG00000030199",
  "ENSMUSG00000030200",
  "ENSMUSG00000030201",
  "ENSMUSG00000030203",
  "ENSMUSG00000030204",
  "ENSMUSG00000030205",
  "ENSMUSG00000030206",
  "ENSMUSG00000030207",
  "ENSMUSG00000030208",
  "ENSMUSG00000030209",
  "ENSMUSG00000030213",
  "ENSMUSG00000030214",
  "ENSMUSG00000030216",
  "ENSMUSG00000030217",
  "ENSMUSG00000030218",
  "ENSMUSG00000030219",
  "ENSMUSG00000030220",
  "ENSMUSG00000030222",
  "ENSMUSG00000030223",
  "ENSMUSG00000030224",
  "ENSMUSG00000030225",
  "ENSMUSG00000030226",
  "ENSMUSG00000030228",
  "ENSMUSG00000030230",
  "ENSMUSG00000030231",
  "ENSMUSG00000030232",
  "ENSMUSG00000030235",
  "ENSMUSG00000030236",
  "ENSMUSG00000030237",
  "ENSMUSG00000030243",
  "ENSMUSG00000030244",
  "ENSMUSG00000030245",
  "ENSMUSG00000030246",
  "ENSMUSG00000030247",
  "ENSMUSG00000030249",
  "ENSMUSG00000030254",
  "ENSMUSG00000030255",
  "ENSMUSG00000030256",
  "ENSMUSG00000030257",
  "ENSMUSG00000030259",
  "ENSMUSG00000030263",
  "ENSMUSG00000030264",
  "ENSMUSG00000030265",
  "ENSMUSG00000030268",
  "ENSMUSG00000030269",
  "ENSMUSG00000030270",
  "ENSMUSG00000030271",
  "ENSMUSG00000030272",
  "ENSMUSG00000030275",
  "ENSMUSG00000030276",
  "ENSMUSG00000030278",
  "ENSMUSG00000030279",
  "ENSMUSG00000030281",
  "ENSMUSG00000030282",
  "ENSMUSG00000030283",
  "ENSMUSG00000030284",
  "ENSMUSG00000030286",
  "ENSMUSG00000030287",
  "ENSMUSG00000030291",
  "ENSMUSG00000030292",
  "ENSMUSG00000030298",
  "ENSMUSG00000030301",
  "ENSMUSG00000030302",
  "ENSMUSG00000030303",
  "ENSMUSG00000030304",
  "ENSMUSG00000030306",
  "ENSMUSG00000030307",
  "ENSMUSG00000030309",
  "ENSMUSG00000030310",
  "ENSMUSG00000030313",
  "ENSMUSG00000030314",
  "ENSMUSG00000030315",
  "ENSMUSG00000030316",
  "ENSMUSG00000030317",
  "ENSMUSG00000030319",
  "ENSMUSG00000030321",
  "ENSMUSG00000030322",
  "ENSMUSG00000030323",
  "ENSMUSG00000030324",
  "ENSMUSG00000030325",
  "ENSMUSG00000030327",
  "ENSMUSG00000030329",
  "ENSMUSG00000030336",
  "ENSMUSG00000030337",
  "ENSMUSG00000030339",
  "ENSMUSG00000030340",
  "ENSMUSG00000030341",
  "ENSMUSG00000030342",
  "ENSMUSG00000030344",
  "ENSMUSG00000030345",
  "ENSMUSG00000030346",
  "ENSMUSG00000030347",
  "ENSMUSG00000030350",
  "ENSMUSG00000030351",
  "ENSMUSG00000030352",
  "ENSMUSG00000030353",
  "ENSMUSG00000030357",
  "ENSMUSG00000030359",
  "ENSMUSG00000030361",
  "ENSMUSG00000030364",
  "ENSMUSG00000030365",
  "ENSMUSG00000030366",
  "ENSMUSG00000030368",
  "ENSMUSG00000030373",
  "ENSMUSG00000030374",
  "ENSMUSG00000030376",
  "ENSMUSG00000030378",
  "ENSMUSG00000030380",
  "ENSMUSG00000030382",
  "ENSMUSG00000030385",
  "ENSMUSG00000030386",
  "ENSMUSG00000030393",
  "ENSMUSG00000030397",
  "ENSMUSG00000030399",
  "ENSMUSG00000030400",
  "ENSMUSG00000030401",
  "ENSMUSG00000030402",
  "ENSMUSG00000030403",
  "ENSMUSG00000030406",
  "ENSMUSG00000030407",
  "ENSMUSG00000030409",
  "ENSMUSG00000030410",
  "ENSMUSG00000030411",
  "ENSMUSG00000030413",
  "ENSMUSG00000030417",
  "ENSMUSG00000030421",
  "ENSMUSG00000030423",
  "ENSMUSG00000030427",
  "ENSMUSG00000030428",
  "ENSMUSG00000030431",
  "ENSMUSG00000030432",
  "ENSMUSG00000030435",
  "ENSMUSG00000030443",
  "ENSMUSG00000030446",
  "ENSMUSG00000030447",
  "ENSMUSG00000030450",
  "ENSMUSG00000030451",
  "ENSMUSG00000030452",
  "ENSMUSG00000030463",
  "ENSMUSG00000030465",
  "ENSMUSG00000030468",
  "ENSMUSG00000030469",
  "ENSMUSG00000030470",
  "ENSMUSG00000030471",
  "ENSMUSG00000030472",
  "ENSMUSG00000030474",
  "ENSMUSG00000030483",
  "ENSMUSG00000030484",
  "ENSMUSG00000030486",
  "ENSMUSG00000030491",
  "ENSMUSG00000030492",
  "ENSMUSG00000030493",
  "ENSMUSG00000030494",
  "ENSMUSG00000030495",
  "ENSMUSG00000030498",
  "ENSMUSG00000030499",
  "ENSMUSG00000030500",
  "ENSMUSG00000030505",
  "ENSMUSG00000030507",
  "ENSMUSG00000030509",
  "ENSMUSG00000030510",
  "ENSMUSG00000030512",
  "ENSMUSG00000030513",
  "ENSMUSG00000030515",
  "ENSMUSG00000030516",
  "ENSMUSG00000030518",
  "ENSMUSG00000030519",
  "ENSMUSG00000030521",
  "ENSMUSG00000030522",
  "ENSMUSG00000030523",
  "ENSMUSG00000030525",
  "ENSMUSG00000030527",
  "ENSMUSG00000030528",
  "ENSMUSG00000030530",
  "ENSMUSG00000030532",
  "ENSMUSG00000030533",
  "ENSMUSG00000030534",
  "ENSMUSG00000030536",
  "ENSMUSG00000030538",
  "ENSMUSG00000030539",
  "ENSMUSG00000030541",
  "ENSMUSG00000030543",
  "ENSMUSG00000030544",
  "ENSMUSG00000030545",
  "ENSMUSG00000030546",
  "ENSMUSG00000030549",
  "ENSMUSG00000030551",
  "ENSMUSG00000030553",
  "ENSMUSG00000030554",
  "ENSMUSG00000030555",
  "ENSMUSG00000030556",
  "ENSMUSG00000030557",
  "ENSMUSG00000030559",
  "ENSMUSG00000030560",
  "ENSMUSG00000030562",
  "ENSMUSG00000030577",
  "ENSMUSG00000030579",
  "ENSMUSG00000030583",
  "ENSMUSG00000030584",
  "ENSMUSG00000030587",
  "ENSMUSG00000030588",
  "ENSMUSG00000030589",
  "ENSMUSG00000030590",
  "ENSMUSG00000030591",
  "ENSMUSG00000030592",
  "ENSMUSG00000030595",
  "ENSMUSG00000030598",
  "ENSMUSG00000030600",
  "ENSMUSG00000030603",
  "ENSMUSG00000030604",
  "ENSMUSG00000030605",
  "ENSMUSG00000030606",
  "ENSMUSG00000030607",
  "ENSMUSG00000030609",
  "ENSMUSG00000030610",
  "ENSMUSG00000030611",
  "ENSMUSG00000030612",
  "ENSMUSG00000030613",
  "ENSMUSG00000030614",
  "ENSMUSG00000030616",
  "ENSMUSG00000030617",
  "ENSMUSG00000030619",
  "ENSMUSG00000030621",
  "ENSMUSG00000030623",
  "ENSMUSG00000030629",
  "ENSMUSG00000030630",
  "ENSMUSG00000030636",
  "ENSMUSG00000030638",
  "ENSMUSG00000030641",
  "ENSMUSG00000030643",
  "ENSMUSG00000030647",
  "ENSMUSG00000030649",
  "ENSMUSG00000030650",
  "ENSMUSG00000030651",
  "ENSMUSG00000030652",
  "ENSMUSG00000030654",
  "ENSMUSG00000030655",
  "ENSMUSG00000030657",
  "ENSMUSG00000030659",
  "ENSMUSG00000030660",
  "ENSMUSG00000030662",
  "ENSMUSG00000030663",
  "ENSMUSG00000030664",
  "ENSMUSG00000030666",
  "ENSMUSG00000030669",
  "ENSMUSG00000030670",
  "ENSMUSG00000030671",
  "ENSMUSG00000030672",
  "ENSMUSG00000030674",
  "ENSMUSG00000030677",
  "ENSMUSG00000030678",
  "ENSMUSG00000030681",
  "ENSMUSG00000030682",
  "ENSMUSG00000030683",
  "ENSMUSG00000030685",
  "ENSMUSG00000030688",
  "ENSMUSG00000030689",
  "ENSMUSG00000030691",
  "ENSMUSG00000030693",
  "ENSMUSG00000030695",
  "ENSMUSG00000030697",
  "ENSMUSG00000030699",
  "ENSMUSG00000030701",
  "ENSMUSG00000030703",
  "ENSMUSG00000030704",
  "ENSMUSG00000030706",
  "ENSMUSG00000030707",
  "ENSMUSG00000030708",
  "ENSMUSG00000030711",
  "ENSMUSG00000030713",
  "ENSMUSG00000030714",
  "ENSMUSG00000030717",
  "ENSMUSG00000030718",
  "ENSMUSG00000030720",
  "ENSMUSG00000030722",
  "ENSMUSG00000030724",
  "ENSMUSG00000030725",
  "ENSMUSG00000030726",
  "ENSMUSG00000030727",
  "ENSMUSG00000030729",
  "ENSMUSG00000030730",
  "ENSMUSG00000030731",
  "ENSMUSG00000030732",
  "ENSMUSG00000030733",
  "ENSMUSG00000030737",
  "ENSMUSG00000030738",
  "ENSMUSG00000030739",
  "ENSMUSG00000030741",
  "ENSMUSG00000030742",
  "ENSMUSG00000030744",
  "ENSMUSG00000030745",
  "ENSMUSG00000030747",
  "ENSMUSG00000030748",
  "ENSMUSG00000030751",
  "ENSMUSG00000030752",
  "ENSMUSG00000030753",
  "ENSMUSG00000030754",
  "ENSMUSG00000030757",
  "ENSMUSG00000030759",
  "ENSMUSG00000030760",
  "ENSMUSG00000030761",
  "ENSMUSG00000030762",
  "ENSMUSG00000030763",
  "ENSMUSG00000030766",
  "ENSMUSG00000030768",
  "ENSMUSG00000030769",
  "ENSMUSG00000030770",
  "ENSMUSG00000030772",
  "ENSMUSG00000030774",
  "ENSMUSG00000030775",
  "ENSMUSG00000030779",
  "ENSMUSG00000030780",
  "ENSMUSG00000030781",
  "ENSMUSG00000030782",
  "ENSMUSG00000030785",
  "ENSMUSG00000030786",
  "ENSMUSG00000030787",
  "ENSMUSG00000030788",
  "ENSMUSG00000030789",
  "ENSMUSG00000030790",
  "ENSMUSG00000030792",
  "ENSMUSG00000030793",
  "ENSMUSG00000030795",
  "ENSMUSG00000030796",
  "ENSMUSG00000030798",
  "ENSMUSG00000030800",
  "ENSMUSG00000030801",
  "ENSMUSG00000030802",
  "ENSMUSG00000030805",
  "ENSMUSG00000030806",
  "ENSMUSG00000030811",
  "ENSMUSG00000030814",
  "ENSMUSG00000030815",
  "ENSMUSG00000030816",
  "ENSMUSG00000030822",
  "ENSMUSG00000030823",
  "ENSMUSG00000030824",
  "ENSMUSG00000030825",
  "ENSMUSG00000030826",
  "ENSMUSG00000030830",
  "ENSMUSG00000030834",
  "ENSMUSG00000030835",
  "ENSMUSG00000030838",
  "ENSMUSG00000030839",
  "ENSMUSG00000030844",
  "ENSMUSG00000030846",
  "ENSMUSG00000030847",
  "ENSMUSG00000030849",
  "ENSMUSG00000030850",
  "ENSMUSG00000030851",
  "ENSMUSG00000030852",
  "ENSMUSG00000030854",
  "ENSMUSG00000030858",
  "ENSMUSG00000030859",
  "ENSMUSG00000030861",
  "ENSMUSG00000030862",
  "ENSMUSG00000030865",
  "ENSMUSG00000030866",
  "ENSMUSG00000030867",
  "ENSMUSG00000030868",
  "ENSMUSG00000030869",
  "ENSMUSG00000030870",
  "ENSMUSG00000030871",
  "ENSMUSG00000030872",
  "ENSMUSG00000030873",
  "ENSMUSG00000030876",
  "ENSMUSG00000030877",
  "ENSMUSG00000030878",
  "ENSMUSG00000030879",
  "ENSMUSG00000030880",
  "ENSMUSG00000030881",
  "ENSMUSG00000030882",
  "ENSMUSG00000030884",
  "ENSMUSG00000030887",
  "ENSMUSG00000030888",
  "ENSMUSG00000030889",
  "ENSMUSG00000030894",
  "ENSMUSG00000030895",
  "ENSMUSG00000030897",
  "ENSMUSG00000030898",
  "ENSMUSG00000030905",
  "ENSMUSG00000030909",
  "ENSMUSG00000030911",
  "ENSMUSG00000030917",
  "ENSMUSG00000030921",
  "ENSMUSG00000030922",
  "ENSMUSG00000030924",
  "ENSMUSG00000030929",
  "ENSMUSG00000030930",
  "ENSMUSG00000030934",
  "ENSMUSG00000030935",
  "ENSMUSG00000030942",
  "ENSMUSG00000030945",
  "ENSMUSG00000030946",
  "ENSMUSG00000030954",
  "ENSMUSG00000030956",
  "ENSMUSG00000030960",
  "ENSMUSG00000030963",
  "ENSMUSG00000030965",
  "ENSMUSG00000030966",
  "ENSMUSG00000030967",
  "ENSMUSG00000030968",
  "ENSMUSG00000030970",
  "ENSMUSG00000030972",
  "ENSMUSG00000030976",
  "ENSMUSG00000030978",
  "ENSMUSG00000030979",
  "ENSMUSG00000030980",
  "ENSMUSG00000030981",
  "ENSMUSG00000030982",
  "ENSMUSG00000030983",
  "ENSMUSG00000030986",
  "ENSMUSG00000030987",
  "ENSMUSG00000030990",
  "ENSMUSG00000030994",
  "ENSMUSG00000030996",
  "ENSMUSG00000031004",
  "ENSMUSG00000031007",
  "ENSMUSG00000031010",
  "ENSMUSG00000031012",
  "ENSMUSG00000031015",
  "ENSMUSG00000031016",
  "ENSMUSG00000031021",
  "ENSMUSG00000031022",
  "ENSMUSG00000031023",
  "ENSMUSG00000031024",
  "ENSMUSG00000031026",
  "ENSMUSG00000031027",
  "ENSMUSG00000031028",
  "ENSMUSG00000031029",
  "ENSMUSG00000031059",
  "ENSMUSG00000031060",
  "ENSMUSG00000031065",
  "ENSMUSG00000031066",
  "ENSMUSG00000031068",
  "ENSMUSG00000031070",
  "ENSMUSG00000031072",
  "ENSMUSG00000031073",
  "ENSMUSG00000031074",
  "ENSMUSG00000031075",
  "ENSMUSG00000031077",
  "ENSMUSG00000031078",
  "ENSMUSG00000031079",
  "ENSMUSG00000031085",
  "ENSMUSG00000031089",
  "ENSMUSG00000031090",
  "ENSMUSG00000031093",
  "ENSMUSG00000031095",
  "ENSMUSG00000031097",
  "ENSMUSG00000031098",
  "ENSMUSG00000031099",
  "ENSMUSG00000031101",
  "ENSMUSG00000031103",
  "ENSMUSG00000031104",
  "ENSMUSG00000031105",
  "ENSMUSG00000031107",
  "ENSMUSG00000031109",
  "ENSMUSG00000031111",
  "ENSMUSG00000031112",
  "ENSMUSG00000031118",
  "ENSMUSG00000031119",
  "ENSMUSG00000031129",
  "ENSMUSG00000031130",
  "ENSMUSG00000031131",
  "ENSMUSG00000031132",
  "ENSMUSG00000031133",
  "ENSMUSG00000031134",
  "ENSMUSG00000031137",
  "ENSMUSG00000031138",
  "ENSMUSG00000031139",
  "ENSMUSG00000031142",
  "ENSMUSG00000031143",
  "ENSMUSG00000031144",
  "ENSMUSG00000031145",
  "ENSMUSG00000031147",
  "ENSMUSG00000031148",
  "ENSMUSG00000031149",
  "ENSMUSG00000031150",
  "ENSMUSG00000031154",
  "ENSMUSG00000031155",
  "ENSMUSG00000031156",
  "ENSMUSG00000031157",
  "ENSMUSG00000031158",
  "ENSMUSG00000031160",
  "ENSMUSG00000031161",
  "ENSMUSG00000031162",
  "ENSMUSG00000031163",
  "ENSMUSG00000031165",
  "ENSMUSG00000031166",
  "ENSMUSG00000031167",
  "ENSMUSG00000031168",
  "ENSMUSG00000031169",
  "ENSMUSG00000031170",
  "ENSMUSG00000031171",
  "ENSMUSG00000031173",
  "ENSMUSG00000031174",
  "ENSMUSG00000031176",
  "ENSMUSG00000031179",
  "ENSMUSG00000031181",
  "ENSMUSG00000031182",
  "ENSMUSG00000031189",
  "ENSMUSG00000031194",
  "ENSMUSG00000031196",
  "ENSMUSG00000031197",
  "ENSMUSG00000031198",
  "ENSMUSG00000031200",
  "ENSMUSG00000031201",
  "ENSMUSG00000031202",
  "ENSMUSG00000031204",
  "ENSMUSG00000031207",
  "ENSMUSG00000031209",
  "ENSMUSG00000031210",
  "ENSMUSG00000031212",
  "ENSMUSG00000031214",
  "ENSMUSG00000031216",
  "ENSMUSG00000031217",
  "ENSMUSG00000031220",
  "ENSMUSG00000031221",
  "ENSMUSG00000031224",
  "ENSMUSG00000031226",
  "ENSMUSG00000031227",
  "ENSMUSG00000031229",
  "ENSMUSG00000031231",
  "ENSMUSG00000031232",
  "ENSMUSG00000031233",
  "ENSMUSG00000031239",
  "ENSMUSG00000031241",
  "ENSMUSG00000031242",
  "ENSMUSG00000031245",
  "ENSMUSG00000031246",
  "ENSMUSG00000031250",
  "ENSMUSG00000031253",
  "ENSMUSG00000031255",
  "ENSMUSG00000031256",
  "ENSMUSG00000031257",
  "ENSMUSG00000031258",
  "ENSMUSG00000031262",
  "ENSMUSG00000031264",
  "ENSMUSG00000031266",
  "ENSMUSG00000031270",
  "ENSMUSG00000031271",
  "ENSMUSG00000031273",
  "ENSMUSG00000031274",
  "ENSMUSG00000031278",
  "ENSMUSG00000031283",
  "ENSMUSG00000031284",
  "ENSMUSG00000031285",
  "ENSMUSG00000031286",
  "ENSMUSG00000031289",
  "ENSMUSG00000031290",
  "ENSMUSG00000031292",
  "ENSMUSG00000031293",
  "ENSMUSG00000031294",
  "ENSMUSG00000031295",
  "ENSMUSG00000031297",
  "ENSMUSG00000031298",
  "ENSMUSG00000031299",
  "ENSMUSG00000031302",
  "ENSMUSG00000031303",
  "ENSMUSG00000031304",
  "ENSMUSG00000031309",
  "ENSMUSG00000031310",
  "ENSMUSG00000031311",
  "ENSMUSG00000031312",
  "ENSMUSG00000031314",
  "ENSMUSG00000031323",
  "ENSMUSG00000031326",
  "ENSMUSG00000031327",
  "ENSMUSG00000031328",
  "ENSMUSG00000031329",
  "ENSMUSG00000031330",
  "ENSMUSG00000031333",
  "ENSMUSG00000031337",
  "ENSMUSG00000031340",
  "ENSMUSG00000031342",
  "ENSMUSG00000031343",
  "ENSMUSG00000031344",
  "ENSMUSG00000031347",
  "ENSMUSG00000031349",
  "ENSMUSG00000031351",
  "ENSMUSG00000031352",
  "ENSMUSG00000031353",
  "ENSMUSG00000031354",
  "ENSMUSG00000031355",
  "ENSMUSG00000031357",
  "ENSMUSG00000031358",
  "ENSMUSG00000031360",
  "ENSMUSG00000031364",
  "ENSMUSG00000031365",
  "ENSMUSG00000031367",
  "ENSMUSG00000031370",
  "ENSMUSG00000031371",
  "ENSMUSG00000031372",
  "ENSMUSG00000031373",
  "ENSMUSG00000031374",
  "ENSMUSG00000031375",
  "ENSMUSG00000031376",
  "ENSMUSG00000031377",
  "ENSMUSG00000031378",
  "ENSMUSG00000031379",
  "ENSMUSG00000031380",
  "ENSMUSG00000031381",
  "ENSMUSG00000031382",
  "ENSMUSG00000031383",
  "ENSMUSG00000031384",
  "ENSMUSG00000031385",
  "ENSMUSG00000031386",
  "ENSMUSG00000031387",
  "ENSMUSG00000031388",
  "ENSMUSG00000031389",
  "ENSMUSG00000031390",
  "ENSMUSG00000031391",
  "ENSMUSG00000031392",
  "ENSMUSG00000031393",
  "ENSMUSG00000031394",
  "ENSMUSG00000031397",
  "ENSMUSG00000031398",
  "ENSMUSG00000031399",
  "ENSMUSG00000031400",
  "ENSMUSG00000031402",
  "ENSMUSG00000031403",
  "ENSMUSG00000031409",
  "ENSMUSG00000031410",
  "ENSMUSG00000031411",
  "ENSMUSG00000031422",
  "ENSMUSG00000031424",
  "ENSMUSG00000031425",
  "ENSMUSG00000031428",
  "ENSMUSG00000031429",
  "ENSMUSG00000031430",
  "ENSMUSG00000031431",
  "ENSMUSG00000031432",
  "ENSMUSG00000031433",
  "ENSMUSG00000031434",
  "ENSMUSG00000031438",
  "ENSMUSG00000031441",
  "ENSMUSG00000031442",
  "ENSMUSG00000031443",
  "ENSMUSG00000031444",
  "ENSMUSG00000031445",
  "ENSMUSG00000031446",
  "ENSMUSG00000031447",
  "ENSMUSG00000031448",
  "ENSMUSG00000031449",
  "ENSMUSG00000031450",
  "ENSMUSG00000031451",
  "ENSMUSG00000031452",
  "ENSMUSG00000031453",
  "ENSMUSG00000031458",
  "ENSMUSG00000031461",
  "ENSMUSG00000031465",
  "ENSMUSG00000031467",
  "ENSMUSG00000031471",
  "ENSMUSG00000031478",
  "ENSMUSG00000031479",
  "ENSMUSG00000031480",
  "ENSMUSG00000031481",
  "ENSMUSG00000031482",
  "ENSMUSG00000031483",
  "ENSMUSG00000031485",
  "ENSMUSG00000031486",
  "ENSMUSG00000031487",
  "ENSMUSG00000031488",
  "ENSMUSG00000031489",
  "ENSMUSG00000031490",
  "ENSMUSG00000031491",
  "ENSMUSG00000031492",
  "ENSMUSG00000031493",
  "ENSMUSG00000031494",
  "ENSMUSG00000031495",
  "ENSMUSG00000031497",
  "ENSMUSG00000031502",
  "ENSMUSG00000031503",
  "ENSMUSG00000031504",
  "ENSMUSG00000031505",
  "ENSMUSG00000031506",
  "ENSMUSG00000031508",
  "ENSMUSG00000031509",
  "ENSMUSG00000031510",
  "ENSMUSG00000031511",
  "ENSMUSG00000031512",
  "ENSMUSG00000031513",
  "ENSMUSG00000031516",
  "ENSMUSG00000031517",
  "ENSMUSG00000031518",
  "ENSMUSG00000031519",
  "ENSMUSG00000031520",
  "ENSMUSG00000031521",
  "ENSMUSG00000031523",
  "ENSMUSG00000031527",
  "ENSMUSG00000031529",
  "ENSMUSG00000031530",
  "ENSMUSG00000031532",
  "ENSMUSG00000031533",
  "ENSMUSG00000031534",
  "ENSMUSG00000031535",
  "ENSMUSG00000031536",
  "ENSMUSG00000031537",
  "ENSMUSG00000031538",
  "ENSMUSG00000031539",
  "ENSMUSG00000031540",
  "ENSMUSG00000031545",
  "ENSMUSG00000031546",
  "ENSMUSG00000031548",
  "ENSMUSG00000031549",
  "ENSMUSG00000031551",
  "ENSMUSG00000031552",
  "ENSMUSG00000031553",
  "ENSMUSG00000031554",
  "ENSMUSG00000031555",
  "ENSMUSG00000031556",
  "ENSMUSG00000031557",
  "ENSMUSG00000031558",
  "ENSMUSG00000031559",
  "ENSMUSG00000031561",
  "ENSMUSG00000031562",
  "ENSMUSG00000031563",
  "ENSMUSG00000031565",
  "ENSMUSG00000031568",
  "ENSMUSG00000031570",
  "ENSMUSG00000031574",
  "ENSMUSG00000031575",
  "ENSMUSG00000031576",
  "ENSMUSG00000031577",
  "ENSMUSG00000031578",
  "ENSMUSG00000031583",
  "ENSMUSG00000031584",
  "ENSMUSG00000031585",
  "ENSMUSG00000031586",
  "ENSMUSG00000031590",
  "ENSMUSG00000031591",
  "ENSMUSG00000031592",
  "ENSMUSG00000031594",
  "ENSMUSG00000031595",
  "ENSMUSG00000031596",
  "ENSMUSG00000031600",
  "ENSMUSG00000031601",
  "ENSMUSG00000031603",
  "ENSMUSG00000031604",
  "ENSMUSG00000031605",
  "ENSMUSG00000031609",
  "ENSMUSG00000031610",
  "ENSMUSG00000031613",
  "ENSMUSG00000031616",
  "ENSMUSG00000031617",
  "ENSMUSG00000031618",
  "ENSMUSG00000031620",
  "ENSMUSG00000031621",
  "ENSMUSG00000031622",
  "ENSMUSG00000031626",
  "ENSMUSG00000031627",
  "ENSMUSG00000031628",
  "ENSMUSG00000031629",
  "ENSMUSG00000031631",
  "ENSMUSG00000031633",
  "ENSMUSG00000031634",
  "ENSMUSG00000031635",
  "ENSMUSG00000031636",
  "ENSMUSG00000031637",
  "ENSMUSG00000031639",
  "ENSMUSG00000031641",
  "ENSMUSG00000031642",
  "ENSMUSG00000031644",
  "ENSMUSG00000031645",
  "ENSMUSG00000031647",
  "ENSMUSG00000031651",
  "ENSMUSG00000031652",
  "ENSMUSG00000031654",
  "ENSMUSG00000031657",
  "ENSMUSG00000031659",
  "ENSMUSG00000031660",
  "ENSMUSG00000031661",
  "ENSMUSG00000031662",
  "ENSMUSG00000031665",
  "ENSMUSG00000031666",
  "ENSMUSG00000031667",
  "ENSMUSG00000031668",
  "ENSMUSG00000031669",
  "ENSMUSG00000031671",
  "ENSMUSG00000031672",
  "ENSMUSG00000031673",
  "ENSMUSG00000031681",
  "ENSMUSG00000031682",
  "ENSMUSG00000031683",
  "ENSMUSG00000031684",
  "ENSMUSG00000031688",
  "ENSMUSG00000031691",
  "ENSMUSG00000031696",
  "ENSMUSG00000031697",
  "ENSMUSG00000031698",
  "ENSMUSG00000031700",
  "ENSMUSG00000031701",
  "ENSMUSG00000031703",
  "ENSMUSG00000031706",
  "ENSMUSG00000031708",
  "ENSMUSG00000031709",
  "ENSMUSG00000031710",
  "ENSMUSG00000031711",
  "ENSMUSG00000031712",
  "ENSMUSG00000031714",
  "ENSMUSG00000031715",
  "ENSMUSG00000031722",
  "ENSMUSG00000031723",
  "ENSMUSG00000031725",
  "ENSMUSG00000031727",
  "ENSMUSG00000031728",
  "ENSMUSG00000031729",
  "ENSMUSG00000031730",
  "ENSMUSG00000031731",
  "ENSMUSG00000031732",
  "ENSMUSG00000031734",
  "ENSMUSG00000031736",
  "ENSMUSG00000031737",
  "ENSMUSG00000031738",
  "ENSMUSG00000031740",
  "ENSMUSG00000031748",
  "ENSMUSG00000031749",
  "ENSMUSG00000031750",
  "ENSMUSG00000031751",
  "ENSMUSG00000031753",
  "ENSMUSG00000031754",
  "ENSMUSG00000031755",
  "ENSMUSG00000031756",
  "ENSMUSG00000031757",
  "ENSMUSG00000031758",
  "ENSMUSG00000031760",
  "ENSMUSG00000031762",
  "ENSMUSG00000031765",
  "ENSMUSG00000031766",
  "ENSMUSG00000031767",
  "ENSMUSG00000031770",
  "ENSMUSG00000031772",
  "ENSMUSG00000031774",
  "ENSMUSG00000031775",
  "ENSMUSG00000031776",
  "ENSMUSG00000031778",
  "ENSMUSG00000031779",
  "ENSMUSG00000031780",
  "ENSMUSG00000031781",
  "ENSMUSG00000031782",
  "ENSMUSG00000031783",
  "ENSMUSG00000031785",
  "ENSMUSG00000031786",
  "ENSMUSG00000031787",
  "ENSMUSG00000031788",
  "ENSMUSG00000031789",
  "ENSMUSG00000031790",
  "ENSMUSG00000031791",
  "ENSMUSG00000031792",
  "ENSMUSG00000031796",
  "ENSMUSG00000031799",
  "ENSMUSG00000031803",
  "ENSMUSG00000031805",
  "ENSMUSG00000031807",
  "ENSMUSG00000031808",
  "ENSMUSG00000031809",
  "ENSMUSG00000031812",
  "ENSMUSG00000031813",
  "ENSMUSG00000031816",
  "ENSMUSG00000031818",
  "ENSMUSG00000031819",
  "ENSMUSG00000031820",
  "ENSMUSG00000031821",
  "ENSMUSG00000031822",
  "ENSMUSG00000031823",
  "ENSMUSG00000031824",
  "ENSMUSG00000031825",
  "ENSMUSG00000031826",
  "ENSMUSG00000031827",
  "ENSMUSG00000031828",
  "ENSMUSG00000031831",
  "ENSMUSG00000031832",
  "ENSMUSG00000031833",
  "ENSMUSG00000031834",
  "ENSMUSG00000031835",
  "ENSMUSG00000031837",
  "ENSMUSG00000031838",
  "ENSMUSG00000031839",
  "ENSMUSG00000031840",
  "ENSMUSG00000031841",
  "ENSMUSG00000031842",
  "ENSMUSG00000031843",
  "ENSMUSG00000031844",
  "ENSMUSG00000031845",
  "ENSMUSG00000031847",
  "ENSMUSG00000031848",
  "ENSMUSG00000031849",
  "ENSMUSG00000031851",
  "ENSMUSG00000031853",
  "ENSMUSG00000031858",
  "ENSMUSG00000031860",
  "ENSMUSG00000031861",
  "ENSMUSG00000031862",
  "ENSMUSG00000031864",
  "ENSMUSG00000031865",
  "ENSMUSG00000031870",
  "ENSMUSG00000031871",
  "ENSMUSG00000031872",
  "ENSMUSG00000031875",
  "ENSMUSG00000031877",
  "ENSMUSG00000031878",
  "ENSMUSG00000031879",
  "ENSMUSG00000031880",
  "ENSMUSG00000031881",
  "ENSMUSG00000031883",
  "ENSMUSG00000031885",
  "ENSMUSG00000031886",
  "ENSMUSG00000031887",
  "ENSMUSG00000031889",
  "ENSMUSG00000031891",
  "ENSMUSG00000031893",
  "ENSMUSG00000031896",
  "ENSMUSG00000031897",
  "ENSMUSG00000031898",
  "ENSMUSG00000031901",
  "ENSMUSG00000031902",
  "ENSMUSG00000031903",
  "ENSMUSG00000031904",
  "ENSMUSG00000031906",
  "ENSMUSG00000031907",
  "ENSMUSG00000031910",
  "ENSMUSG00000031913",
  "ENSMUSG00000031916",
  "ENSMUSG00000031917",
  "ENSMUSG00000031918",
  "ENSMUSG00000031919",
  "ENSMUSG00000031921",
  "ENSMUSG00000031922",
  "ENSMUSG00000031924",
  "ENSMUSG00000031925",
  "ENSMUSG00000031927",
  "ENSMUSG00000031928",
  "ENSMUSG00000031930",
  "ENSMUSG00000031931",
  "ENSMUSG00000031932",
  "ENSMUSG00000031933",
  "ENSMUSG00000031934",
  "ENSMUSG00000031935",
  "ENSMUSG00000031936",
  "ENSMUSG00000031937",
  "ENSMUSG00000031938",
  "ENSMUSG00000031939",
  "ENSMUSG00000031948",
  "ENSMUSG00000031949",
  "ENSMUSG00000031950",
  "ENSMUSG00000031951",
  "ENSMUSG00000031952",
  "ENSMUSG00000031953",
  "ENSMUSG00000031954",
  "ENSMUSG00000031955",
  "ENSMUSG00000031957",
  "ENSMUSG00000031958",
  "ENSMUSG00000031959",
  "ENSMUSG00000031960",
  "ENSMUSG00000031962",
  "ENSMUSG00000031963",
  "ENSMUSG00000031965",
  "ENSMUSG00000031966",
  "ENSMUSG00000031967",
  "ENSMUSG00000031969",
  "ENSMUSG00000031970",
  "ENSMUSG00000031971",
  "ENSMUSG00000031972",
  "ENSMUSG00000031974",
  "ENSMUSG00000031976",
  "ENSMUSG00000031979",
  "ENSMUSG00000031981",
  "ENSMUSG00000031982",
  "ENSMUSG00000031983",
  "ENSMUSG00000031984",
  "ENSMUSG00000031985",
  "ENSMUSG00000031986",
  "ENSMUSG00000031987",
  "ENSMUSG00000031988",
  "ENSMUSG00000031990",
  "ENSMUSG00000031991",
  "ENSMUSG00000031993",
  "ENSMUSG00000031994",
  "ENSMUSG00000031995",
  "ENSMUSG00000031996",
  "ENSMUSG00000031997",
  "ENSMUSG00000032000",
  "ENSMUSG00000032002",
  "ENSMUSG00000032006",
  "ENSMUSG00000032009",
  "ENSMUSG00000032010",
  "ENSMUSG00000032011",
  "ENSMUSG00000032012",
  "ENSMUSG00000032013",
  "ENSMUSG00000032014",
  "ENSMUSG00000032015",
  "ENSMUSG00000032017",
  "ENSMUSG00000032018",
  "ENSMUSG00000032020",
  "ENSMUSG00000032021",
  "ENSMUSG00000032023",
  "ENSMUSG00000032024",
  "ENSMUSG00000032026",
  "ENSMUSG00000032028",
  "ENSMUSG00000032030",
  "ENSMUSG00000032033",
  "ENSMUSG00000032034",
  "ENSMUSG00000032035",
  "ENSMUSG00000032036",
  "ENSMUSG00000032038",
  "ENSMUSG00000032040",
  "ENSMUSG00000032041",
  "ENSMUSG00000032042",
  "ENSMUSG00000032044",
  "ENSMUSG00000032046",
  "ENSMUSG00000032047",
  "ENSMUSG00000032048",
  "ENSMUSG00000032050",
  "ENSMUSG00000032051",
  "ENSMUSG00000032053",
  "ENSMUSG00000032056",
  "ENSMUSG00000032057",
  "ENSMUSG00000032058",
  "ENSMUSG00000032059",
  "ENSMUSG00000032060",
  "ENSMUSG00000032062",
  "ENSMUSG00000032064",
  "ENSMUSG00000032065",
  "ENSMUSG00000032066",
  "ENSMUSG00000032067",
  "ENSMUSG00000032068",
  "ENSMUSG00000032076",
  "ENSMUSG00000032077",
  "ENSMUSG00000032078",
  "ENSMUSG00000032079",
  "ENSMUSG00000032080",
  "ENSMUSG00000032081",
  "ENSMUSG00000032083",
  "ENSMUSG00000032085",
  "ENSMUSG00000032086",
  "ENSMUSG00000032087",
  "ENSMUSG00000032089",
  "ENSMUSG00000032091",
  "ENSMUSG00000032092",
  "ENSMUSG00000032093",
  "ENSMUSG00000032094",
  "ENSMUSG00000032096",
  "ENSMUSG00000032097",
  "ENSMUSG00000032098",
  "ENSMUSG00000032099",
  "ENSMUSG00000032101",
  "ENSMUSG00000032103",
  "ENSMUSG00000032105",
  "ENSMUSG00000032108",
  "ENSMUSG00000032109",
  "ENSMUSG00000032110",
  "ENSMUSG00000032112",
  "ENSMUSG00000032113",
  "ENSMUSG00000032114",
  "ENSMUSG00000032115",
  "ENSMUSG00000032116",
  "ENSMUSG00000032118",
  "ENSMUSG00000032119",
  "ENSMUSG00000032120",
  "ENSMUSG00000032121",
  "ENSMUSG00000032122",
  "ENSMUSG00000032123",
  "ENSMUSG00000032125",
  "ENSMUSG00000032126",
  "ENSMUSG00000032127",
  "ENSMUSG00000032128",
  "ENSMUSG00000032131",
  "ENSMUSG00000032135",
  "ENSMUSG00000032171",
  "ENSMUSG00000032172",
  "ENSMUSG00000032174",
  "ENSMUSG00000032175",
  "ENSMUSG00000032177",
  "ENSMUSG00000032178",
  "ENSMUSG00000032179",
  "ENSMUSG00000032180",
  "ENSMUSG00000032181",
  "ENSMUSG00000032182",
  "ENSMUSG00000032184",
  "ENSMUSG00000032185",
  "ENSMUSG00000032186",
  "ENSMUSG00000032187",
  "ENSMUSG00000032191",
  "ENSMUSG00000032192",
  "ENSMUSG00000032193",
  "ENSMUSG00000032194",
  "ENSMUSG00000032198",
  "ENSMUSG00000032199",
  "ENSMUSG00000032202",
  "ENSMUSG00000032204",
  "ENSMUSG00000032207",
  "ENSMUSG00000032212",
  "ENSMUSG00000032215",
  "ENSMUSG00000032216",
  "ENSMUSG00000032217",
  "ENSMUSG00000032220",
  "ENSMUSG00000032221",
  "ENSMUSG00000032224",
  "ENSMUSG00000032226",
  "ENSMUSG00000032228",
  "ENSMUSG00000032231",
  "ENSMUSG00000032232",
  "ENSMUSG00000032235",
  "ENSMUSG00000032238",
  "ENSMUSG00000032239",
  "ENSMUSG00000032243",
  "ENSMUSG00000032244",
  "ENSMUSG00000032245",
  "ENSMUSG00000032246",
  "ENSMUSG00000032249",
  "ENSMUSG00000032251",
  "ENSMUSG00000032252",
  "ENSMUSG00000032253",
  "ENSMUSG00000032254",
  "ENSMUSG00000032257",
  "ENSMUSG00000032258",
  "ENSMUSG00000032259",
  "ENSMUSG00000032261",
  "ENSMUSG00000032262",
  "ENSMUSG00000032263",
  "ENSMUSG00000032264",
  "ENSMUSG00000032265",
  "ENSMUSG00000032267",
  "ENSMUSG00000032268",
  "ENSMUSG00000032269",
  "ENSMUSG00000032271",
  "ENSMUSG00000032274",
  "ENSMUSG00000032278",
  "ENSMUSG00000032279",
  "ENSMUSG00000032280",
  "ENSMUSG00000032281",
  "ENSMUSG00000032285",
  "ENSMUSG00000032288",
  "ENSMUSG00000032289",
  "ENSMUSG00000032290",
  "ENSMUSG00000032291",
  "ENSMUSG00000032292",
  "ENSMUSG00000032293",
  "ENSMUSG00000032294",
  "ENSMUSG00000032295",
  "ENSMUSG00000032297",
  "ENSMUSG00000032298",
  "ENSMUSG00000032299",
  "ENSMUSG00000032300",
  "ENSMUSG00000032301",
  "ENSMUSG00000032303",
  "ENSMUSG00000032305",
  "ENSMUSG00000032306",
  "ENSMUSG00000032307",
  "ENSMUSG00000032308",
  "ENSMUSG00000032309",
  "ENSMUSG00000032310",
  "ENSMUSG00000032311",
  "ENSMUSG00000032312",
  "ENSMUSG00000032314",
  "ENSMUSG00000032315",
  "ENSMUSG00000032316",
  "ENSMUSG00000032318",
  "ENSMUSG00000032320",
  "ENSMUSG00000032322",
  "ENSMUSG00000032323",
  "ENSMUSG00000032324",
  "ENSMUSG00000032327",
  "ENSMUSG00000032328",
  "ENSMUSG00000032329",
  "ENSMUSG00000032330",
  "ENSMUSG00000032332",
  "ENSMUSG00000032333",
  "ENSMUSG00000032334",
  "ENSMUSG00000032336",
  "ENSMUSG00000032338",
  "ENSMUSG00000032340",
  "ENSMUSG00000032342",
  "ENSMUSG00000032343",
  "ENSMUSG00000032344",
  "ENSMUSG00000032346",
  "ENSMUSG00000032348",
  "ENSMUSG00000032349",
  "ENSMUSG00000032350",
  "ENSMUSG00000032352",
  "ENSMUSG00000032353",
  "ENSMUSG00000032355",
  "ENSMUSG00000032356",
  "ENSMUSG00000032357",
  "ENSMUSG00000032358",
  "ENSMUSG00000032359",
  "ENSMUSG00000032360",
  "ENSMUSG00000032363",
  "ENSMUSG00000032366",
  "ENSMUSG00000032368",
  "ENSMUSG00000032369",
  "ENSMUSG00000032370",
  "ENSMUSG00000032372",
  "ENSMUSG00000032373",
  "ENSMUSG00000032374",
  "ENSMUSG00000032375",
  "ENSMUSG00000032376",
  "ENSMUSG00000032377",
  "ENSMUSG00000032380",
  "ENSMUSG00000032381",
  "ENSMUSG00000032382",
  "ENSMUSG00000032383",
  "ENSMUSG00000032384",
  "ENSMUSG00000032386",
  "ENSMUSG00000032387",
  "ENSMUSG00000032388",
  "ENSMUSG00000032392",
  "ENSMUSG00000032393",
  "ENSMUSG00000032394",
  "ENSMUSG00000032396",
  "ENSMUSG00000032397",
  "ENSMUSG00000032398",
  "ENSMUSG00000032399",
  "ENSMUSG00000032400",
  "ENSMUSG00000032401",
  "ENSMUSG00000032402",
  "ENSMUSG00000032403",
  "ENSMUSG00000032405",
  "ENSMUSG00000032407",
  "ENSMUSG00000032409",
  "ENSMUSG00000032410",
  "ENSMUSG00000032411",
  "ENSMUSG00000032412",
  "ENSMUSG00000032413",
  "ENSMUSG00000032417",
  "ENSMUSG00000032418",
  "ENSMUSG00000032419",
  "ENSMUSG00000032420",
  "ENSMUSG00000032422",
  "ENSMUSG00000032423",
  "ENSMUSG00000032425",
  "ENSMUSG00000032431",
  "ENSMUSG00000032434",
  "ENSMUSG00000032435",
  "ENSMUSG00000032436",
  "ENSMUSG00000032437",
  "ENSMUSG00000032440",
  "ENSMUSG00000032446",
  "ENSMUSG00000032449",
  "ENSMUSG00000032451",
  "ENSMUSG00000032452",
  "ENSMUSG00000032454",
  "ENSMUSG00000032456",
  "ENSMUSG00000032458",
  "ENSMUSG00000032462",
  "ENSMUSG00000032463",
  "ENSMUSG00000032468",
  "ENSMUSG00000032469",
  "ENSMUSG00000032470",
  "ENSMUSG00000032473",
  "ENSMUSG00000032475",
  "ENSMUSG00000032478",
  "ENSMUSG00000032479",
  "ENSMUSG00000032480",
  "ENSMUSG00000032481",
  "ENSMUSG00000032482",
  "ENSMUSG00000032484",
  "ENSMUSG00000032485",
  "ENSMUSG00000032487",
  "ENSMUSG00000032489",
  "ENSMUSG00000032491",
  "ENSMUSG00000032492",
  "ENSMUSG00000032493",
  "ENSMUSG00000032494",
  "ENSMUSG00000032495",
  "ENSMUSG00000032496",
  "ENSMUSG00000032497",
  "ENSMUSG00000032498",
  "ENSMUSG00000032500",
  "ENSMUSG00000032501",
  "ENSMUSG00000032502",
  "ENSMUSG00000032503",
  "ENSMUSG00000032504",
  "ENSMUSG00000032507",
  "ENSMUSG00000032508",
  "ENSMUSG00000032511",
  "ENSMUSG00000032512",
  "ENSMUSG00000032513",
  "ENSMUSG00000032514",
  "ENSMUSG00000032515",
  "ENSMUSG00000032517",
  "ENSMUSG00000032518",
  "ENSMUSG00000032519",
  "ENSMUSG00000032523",
  "ENSMUSG00000032525",
  "ENSMUSG00000032526",
  "ENSMUSG00000032527",
  "ENSMUSG00000032528",
  "ENSMUSG00000032530",
  "ENSMUSG00000032531",
  "ENSMUSG00000032532",
  "ENSMUSG00000032534",
  "ENSMUSG00000032536",
  "ENSMUSG00000032537",
  "ENSMUSG00000032540",
  "ENSMUSG00000032547",
  "ENSMUSG00000032548",
  "ENSMUSG00000032549",
  "ENSMUSG00000032551",
  "ENSMUSG00000032553",
  "ENSMUSG00000032554",
  "ENSMUSG00000032555",
  "ENSMUSG00000032556",
  "ENSMUSG00000032557",
  "ENSMUSG00000032558",
  "ENSMUSG00000032560",
  "ENSMUSG00000032561",
  "ENSMUSG00000032562",
  "ENSMUSG00000032563",
  "ENSMUSG00000032564",
  "ENSMUSG00000032565",
  "ENSMUSG00000032566",
  "ENSMUSG00000032567",
  "ENSMUSG00000032570",
  "ENSMUSG00000032571",
  "ENSMUSG00000032572",
  "ENSMUSG00000032575",
  "ENSMUSG00000032577",
  "ENSMUSG00000032578",
  "ENSMUSG00000032579",
  "ENSMUSG00000032580",
  "ENSMUSG00000032582",
  "ENSMUSG00000032583",
  "ENSMUSG00000032584",
  "ENSMUSG00000032586",
  "ENSMUSG00000032589",
  "ENSMUSG00000032590",
  "ENSMUSG00000032591",
  "ENSMUSG00000032593",
  "ENSMUSG00000032594",
  "ENSMUSG00000032595",
  "ENSMUSG00000032596",
  "ENSMUSG00000032598",
  "ENSMUSG00000032599",
  "ENSMUSG00000032601",
  "ENSMUSG00000032602",
  "ENSMUSG00000032604",
  "ENSMUSG00000032606",
  "ENSMUSG00000032607",
  "ENSMUSG00000032609",
  "ENSMUSG00000032611",
  "ENSMUSG00000032612",
  "ENSMUSG00000032615",
  "ENSMUSG00000032621",
  "ENSMUSG00000032623",
  "ENSMUSG00000032624",
  "ENSMUSG00000032625",
  "ENSMUSG00000032633",
  "ENSMUSG00000032637",
  "ENSMUSG00000032640",
  "ENSMUSG00000032641",
  "ENSMUSG00000032643",
  "ENSMUSG00000032648",
  "ENSMUSG00000032649",
  "ENSMUSG00000032652",
  "ENSMUSG00000032656",
  "ENSMUSG00000032657",
  "ENSMUSG00000032661",
  "ENSMUSG00000032666",
  "ENSMUSG00000032667",
  "ENSMUSG00000032671",
  "ENSMUSG00000032673",
  "ENSMUSG00000032679",
  "ENSMUSG00000032680",
  "ENSMUSG00000032688",
  "ENSMUSG00000032690",
  "ENSMUSG00000032691",
  "ENSMUSG00000032698",
  "ENSMUSG00000032702",
  "ENSMUSG00000032705",
  "ENSMUSG00000032712",
  "ENSMUSG00000032714",
  "ENSMUSG00000032715",
  "ENSMUSG00000032717",
  "ENSMUSG00000032718",
  "ENSMUSG00000032719",
  "ENSMUSG00000032724",
  "ENSMUSG00000032725",
  "ENSMUSG00000032726",
  "ENSMUSG00000032727",
  "ENSMUSG00000032733",
  "ENSMUSG00000032735",
  "ENSMUSG00000032737",
  "ENSMUSG00000032740",
  "ENSMUSG00000032741",
  "ENSMUSG00000032743",
  "ENSMUSG00000032744",
  "ENSMUSG00000032745",
  "ENSMUSG00000032750",
  "ENSMUSG00000032754",
  "ENSMUSG00000032757",
  "ENSMUSG00000032758",
  "ENSMUSG00000032763",
  "ENSMUSG00000032766",
  "ENSMUSG00000032769",
  "ENSMUSG00000032773",
  "ENSMUSG00000032776",
  "ENSMUSG00000032777",
  "ENSMUSG00000032782",
  "ENSMUSG00000032783",
  "ENSMUSG00000032786",
  "ENSMUSG00000032788",
  "ENSMUSG00000032796",
  "ENSMUSG00000032802",
  "ENSMUSG00000032803",
  "ENSMUSG00000032806",
  "ENSMUSG00000032807",
  "ENSMUSG00000032808",
  "ENSMUSG00000032812",
  "ENSMUSG00000032815",
  "ENSMUSG00000032816",
  "ENSMUSG00000032818",
  "ENSMUSG00000032826",
  "ENSMUSG00000032827",
  "ENSMUSG00000032834",
  "ENSMUSG00000032839",
  "ENSMUSG00000032840",
  "ENSMUSG00000032841",
  "ENSMUSG00000032842",
  "ENSMUSG00000032845",
  "ENSMUSG00000032846",
  "ENSMUSG00000032849",
  "ENSMUSG00000032850",
  "ENSMUSG00000032852",
  "ENSMUSG00000032854",
  "ENSMUSG00000032855",
  "ENSMUSG00000032860",
  "ENSMUSG00000032864",
  "ENSMUSG00000032867",
  "ENSMUSG00000032869",
  "ENSMUSG00000032870",
  "ENSMUSG00000032872",
  "ENSMUSG00000032875",
  "ENSMUSG00000032878",
  "ENSMUSG00000032883",
  "ENSMUSG00000032890",
  "ENSMUSG00000032894",
  "ENSMUSG00000032897",
  "ENSMUSG00000032898",
  "ENSMUSG00000032899",
  "ENSMUSG00000032900",
  "ENSMUSG00000032902",
  "ENSMUSG00000032905",
  "ENSMUSG00000032908",
  "ENSMUSG00000032911",
  "ENSMUSG00000032913",
  "ENSMUSG00000032915",
  "ENSMUSG00000032921",
  "ENSMUSG00000032925",
  "ENSMUSG00000032932",
  "ENSMUSG00000032936",
  "ENSMUSG00000032937",
  "ENSMUSG00000032939",
  "ENSMUSG00000032940",
  "ENSMUSG00000032942",
  "ENSMUSG00000032946",
  "ENSMUSG00000032948",
  "ENSMUSG00000032952",
  "ENSMUSG00000032959",
  "ENSMUSG00000032965",
  "ENSMUSG00000032966",
  "ENSMUSG00000032968",
  "ENSMUSG00000032977",
  "ENSMUSG00000032978",
  "ENSMUSG00000032985",
  "ENSMUSG00000032987",
  "ENSMUSG00000032988",
  "ENSMUSG00000032997",
  "ENSMUSG00000032998",
  "ENSMUSG00000032999",
  "ENSMUSG00000033004",
  "ENSMUSG00000033006",
  "ENSMUSG00000033007",
  "ENSMUSG00000033009",
  "ENSMUSG00000033014",
  "ENSMUSG00000033015",
  "ENSMUSG00000033016",
  "ENSMUSG00000033020",
  "ENSMUSG00000033021",
  "ENSMUSG00000033022",
  "ENSMUSG00000033024",
  "ENSMUSG00000033027",
  "ENSMUSG00000033029",
  "ENSMUSG00000033031",
  "ENSMUSG00000033032",
  "ENSMUSG00000033033",
  "ENSMUSG00000033039",
  "ENSMUSG00000033044",
  "ENSMUSG00000033047",
  "ENSMUSG00000033053",
  "ENSMUSG00000033055",
  "ENSMUSG00000033059",
  "ENSMUSG00000033060",
  "ENSMUSG00000033061",
  "ENSMUSG00000033063",
  "ENSMUSG00000033065",
  "ENSMUSG00000033066",

  "ENSMUSG00000033068",
  "ENSMUSG00000033075",
  "ENSMUSG00000033080",
  "ENSMUSG00000033082",
  "ENSMUSG00000033083",
  "ENSMUSG00000033088",
  "ENSMUSG00000033096",
  "ENSMUSG00000033099",
  "ENSMUSG00000033102",
  "ENSMUSG00000033105",
  "ENSMUSG00000033106",
  "ENSMUSG00000033107",
  "ENSMUSG00000033111",
  "ENSMUSG00000033114",
  "ENSMUSG00000033122",
  "ENSMUSG00000033124",
  "ENSMUSG00000033126",
  "ENSMUSG00000033128",
  "ENSMUSG00000033147",
  "ENSMUSG00000033149",
  "ENSMUSG00000033152",
  "ENSMUSG00000033156",
  "ENSMUSG00000033157",
  "ENSMUSG00000033159",
  "ENSMUSG00000033161",
  "ENSMUSG00000033166",
  "ENSMUSG00000033170",
  "ENSMUSG00000033174",
  "ENSMUSG00000033177",
  "ENSMUSG00000033182",
  "ENSMUSG00000033184",
  "ENSMUSG00000033186",
  "ENSMUSG00000033187",
  "ENSMUSG00000033191",
  "ENSMUSG00000033192",
  "ENSMUSG00000033196",
  "ENSMUSG00000033200",
  "ENSMUSG00000033207",
  "ENSMUSG00000033208",
  "ENSMUSG00000033209",
  "ENSMUSG00000033210",
  "ENSMUSG00000033213",
  "ENSMUSG00000033214",
  "ENSMUSG00000033216",
  "ENSMUSG00000033220",
  "ENSMUSG00000033222",
  "ENSMUSG00000033227",
  "ENSMUSG00000033228",
  "ENSMUSG00000033233",
  "ENSMUSG00000033237",
  "ENSMUSG00000033249",
  "ENSMUSG00000033253",
  "ENSMUSG00000033255",
  "ENSMUSG00000033256",
  "ENSMUSG00000033257",
  "ENSMUSG00000033268",
  "ENSMUSG00000033272",
  "ENSMUSG00000033276",
  "ENSMUSG00000033278",
  "ENSMUSG00000033282",
  "ENSMUSG00000033285",
  "ENSMUSG00000033287",
  "ENSMUSG00000033294",
  "ENSMUSG00000033306",
  "ENSMUSG00000033307",
  "ENSMUSG00000033308",
  "ENSMUSG00000033313",
  "ENSMUSG00000033316",
  "ENSMUSG00000033318",
  "ENSMUSG00000033319",
  "ENSMUSG00000033323",
  "ENSMUSG00000033326",
  "ENSMUSG00000033327",
  "ENSMUSG00000033335",
  "ENSMUSG00000033342",
  "ENSMUSG00000033343",
  "ENSMUSG00000033350",
  "ENSMUSG00000033352",
  "ENSMUSG00000033355",
  "ENSMUSG00000033356",
  "ENSMUSG00000033361",
  "ENSMUSG00000033364",
  "ENSMUSG00000033365",
  "ENSMUSG00000033368",
  "ENSMUSG00000033373",
  "ENSMUSG00000033377",
  "ENSMUSG00000033379",
  "ENSMUSG00000033382",
  "ENSMUSG00000033383",
  "ENSMUSG00000033386",
  "ENSMUSG00000033389",
  "ENSMUSG00000033392",
  "ENSMUSG00000033396",
  "ENSMUSG00000033400",
  "ENSMUSG00000033405",
  "ENSMUSG00000033409",
  "ENSMUSG00000033411",
  "ENSMUSG00000033416",
  "ENSMUSG00000033417",
  "ENSMUSG00000033419",
  "ENSMUSG00000033420",
  "ENSMUSG00000033423",
  "ENSMUSG00000033427",
  "ENSMUSG00000033429",
  "ENSMUSG00000033430",
  "ENSMUSG00000033434",
  "ENSMUSG00000033436",
  "ENSMUSG00000033439",
  "ENSMUSG00000033444",
  "ENSMUSG00000033446",
  "ENSMUSG00000033453",
  "ENSMUSG00000033454",
  "ENSMUSG00000033458",
  "ENSMUSG00000033460",
  "ENSMUSG00000033467",
  "ENSMUSG00000033470",
  "ENSMUSG00000033475",
  "ENSMUSG00000033478",
  "ENSMUSG00000033486",
  "ENSMUSG00000033487",
  "ENSMUSG00000033488",
  "ENSMUSG00000033491",
  "ENSMUSG00000033498",
  "ENSMUSG00000033499",
  "ENSMUSG00000033501",
  "ENSMUSG00000033502",
  "ENSMUSG00000033508",
  "ENSMUSG00000033510",
  "ENSMUSG00000033520",
  "ENSMUSG00000033526",
  "ENSMUSG00000033530",
  "ENSMUSG00000033533",
  "ENSMUSG00000033538",
  "ENSMUSG00000033540",
  "ENSMUSG00000033542",
  "ENSMUSG00000033543",
  "ENSMUSG00000033544",
  "ENSMUSG00000033554",
  "ENSMUSG00000033557",
  "ENSMUSG00000033565",
  "ENSMUSG00000033569",
  "ENSMUSG00000033576",
  "ENSMUSG00000033577",
  "ENSMUSG00000033578",
  "ENSMUSG00000033579",
  "ENSMUSG00000033581",
  "ENSMUSG00000033585",
  "ENSMUSG00000033589",
  "ENSMUSG00000033594",
  "ENSMUSG00000033595",
  "ENSMUSG00000033596",
  "ENSMUSG00000033610",
  "ENSMUSG00000033615",
  "ENSMUSG00000033618",
  "ENSMUSG00000033623",
  "ENSMUSG00000033624",
  "ENSMUSG00000033628",
  "ENSMUSG00000033629",
  "ENSMUSG00000033632",
  "ENSMUSG00000033633",
  "ENSMUSG00000033634",
  "ENSMUSG00000033644",
  "ENSMUSG00000033653",
  "ENSMUSG00000033658",
  "ENSMUSG00000033669",
  "ENSMUSG00000033671",
  "ENSMUSG00000033676",
  "ENSMUSG00000033684",
  "ENSMUSG00000033685",
  "ENSMUSG00000033688",
  "ENSMUSG00000033697",
  "ENSMUSG00000033701",
  "ENSMUSG00000033703",
  "ENSMUSG00000033705",
  "ENSMUSG00000033706",
  "ENSMUSG00000033707",
  "ENSMUSG00000033712",
  "ENSMUSG00000033713",
  "ENSMUSG00000033715",
  "ENSMUSG00000033717",
  "ENSMUSG00000033720",
  "ENSMUSG00000033721",
  "ENSMUSG00000033722",
  "ENSMUSG00000033726",
  "ENSMUSG00000033728",
  "ENSMUSG00000033730",
  "ENSMUSG00000033731",
  "ENSMUSG00000033732",
  "ENSMUSG00000033735",
  "ENSMUSG00000033737",
  "ENSMUSG00000033739",
  "ENSMUSG00000033740",
  "ENSMUSG00000033751",
  "ENSMUSG00000033752",
  "ENSMUSG00000033760",
  "ENSMUSG00000033762",
  "ENSMUSG00000033763",
  "ENSMUSG00000033765",
  "ENSMUSG00000033767",
  "ENSMUSG00000033768",
  "ENSMUSG00000033769",
  "ENSMUSG00000033770",
  "ENSMUSG00000033773",
  "ENSMUSG00000033774",
  "ENSMUSG00000033777",
  "ENSMUSG00000033781",
  "ENSMUSG00000033788",
  "ENSMUSG00000033790",
  "ENSMUSG00000033792",
  "ENSMUSG00000033793",
  "ENSMUSG00000033794",
  "ENSMUSG00000033799",
  "ENSMUSG00000033805",
  "ENSMUSG00000033808",
  "ENSMUSG00000033809",
  "ENSMUSG00000033813",
  "ENSMUSG00000033819",
  "ENSMUSG00000033825",
  "ENSMUSG00000033826",
  "ENSMUSG00000033831",
  "ENSMUSG00000033834",
  "ENSMUSG00000033837",
  "ENSMUSG00000033845",
  "ENSMUSG00000033847",
  "ENSMUSG00000033849",
  "ENSMUSG00000033850",
  "ENSMUSG00000033854",
  "ENSMUSG00000033855",
  "ENSMUSG00000033857",
  "ENSMUSG00000033860",
  "ENSMUSG00000033862",
  "ENSMUSG00000033863",
  "ENSMUSG00000033871",
  "ENSMUSG00000033880",
  "ENSMUSG00000033882",
  "ENSMUSG00000033883",
  "ENSMUSG00000033885",
  "ENSMUSG00000033898",
  "ENSMUSG00000033900",
  "ENSMUSG00000033902",
  "ENSMUSG00000033904",
  "ENSMUSG00000033906",
  "ENSMUSG00000033909",
  "ENSMUSG00000033910",
  "ENSMUSG00000033917",
  "ENSMUSG00000033918",
  "ENSMUSG00000033931",
  "ENSMUSG00000033933",
  "ENSMUSG00000033938",
  "ENSMUSG00000033940",
  "ENSMUSG00000033943",
  "ENSMUSG00000033948",
  "ENSMUSG00000033949",
  "ENSMUSG00000033952",
  "ENSMUSG00000033953",
  "ENSMUSG00000033955",
  "ENSMUSG00000033960",
  "ENSMUSG00000033961",
  "ENSMUSG00000033963",
  "ENSMUSG00000033964",
  "ENSMUSG00000033966",
  "ENSMUSG00000033967",
  "ENSMUSG00000033970",
  "ENSMUSG00000033972",
  "ENSMUSG00000033981",
  "ENSMUSG00000033983",
  "ENSMUSG00000033985",
  "ENSMUSG00000033987",
  "ENSMUSG00000033991",
  "ENSMUSG00000033998",
  "ENSMUSG00000034000",
  "ENSMUSG00000034006",
  "ENSMUSG00000034007",
  "ENSMUSG00000034009",
  "ENSMUSG00000034021",
  "ENSMUSG00000034022",
  "ENSMUSG00000034023",
  "ENSMUSG00000034024",
  "ENSMUSG00000034028",
  "ENSMUSG00000034031",
  "ENSMUSG00000034032",
  "ENSMUSG00000034035",
  "ENSMUSG00000034039",
  "ENSMUSG00000034040",
  "ENSMUSG00000034041",
  "ENSMUSG00000034042",
  "ENSMUSG00000034055",
  "ENSMUSG00000034057",
  "ENSMUSG00000034059",
  "ENSMUSG00000034063",
  "ENSMUSG00000034064",
  "ENSMUSG00000034066",
  "ENSMUSG00000034071",
  "ENSMUSG00000034075",
  "ENSMUSG00000034083",
  "ENSMUSG00000034087",
  "ENSMUSG00000034088",
  "ENSMUSG00000034098",
  "ENSMUSG00000034101",
  "ENSMUSG00000034105",
  "ENSMUSG00000034107",
  "ENSMUSG00000034108",
  "ENSMUSG00000034109",
  "ENSMUSG00000034110",
  "ENSMUSG00000034111",
  "ENSMUSG00000034112",
  "ENSMUSG00000034115",
  "ENSMUSG00000034116",
  "ENSMUSG00000034117",
  "ENSMUSG00000034118",
  "ENSMUSG00000034120",
  "ENSMUSG00000034121",
  "ENSMUSG00000034126",
  "ENSMUSG00000034127",
  "ENSMUSG00000034135",
  "ENSMUSG00000034139",
  "ENSMUSG00000034145",
  "ENSMUSG00000034151",
  "ENSMUSG00000034152",
  "ENSMUSG00000034154",
  "ENSMUSG00000034156",
  "ENSMUSG00000034157",
  "ENSMUSG00000034158",
  "ENSMUSG00000034159",
  "ENSMUSG00000034160",
  "ENSMUSG00000034161",
  "ENSMUSG00000034163",
  "ENSMUSG00000034164",
  "ENSMUSG00000034165",
  "ENSMUSG00000034168",
  "ENSMUSG00000034171",
  "ENSMUSG00000034173",
  "ENSMUSG00000034175",
  "ENSMUSG00000034177",
  "ENSMUSG00000034185",
  "ENSMUSG00000034187",
  "ENSMUSG00000034189",
  "ENSMUSG00000034190",
  "ENSMUSG00000034192",
  "ENSMUSG00000034194",
  "ENSMUSG00000034201",
  "ENSMUSG00000034203",
  "ENSMUSG00000034205",
  "ENSMUSG00000034206",
  "ENSMUSG00000034209",
  "ENSMUSG00000034210",
  "ENSMUSG00000034211",
  "ENSMUSG00000034212",
  "ENSMUSG00000034216",
  "ENSMUSG00000034218",
  "ENSMUSG00000034219",
  "ENSMUSG00000034220",
  "ENSMUSG00000034224",
  "ENSMUSG00000034226",
  "ENSMUSG00000034227",
  "ENSMUSG00000034235",
  "ENSMUSG00000034239",
  "ENSMUSG00000034243",
  "ENSMUSG00000034245",
  "ENSMUSG00000034247",
  "ENSMUSG00000034248",
  "ENSMUSG00000034252",
  "ENSMUSG00000034254",
  "ENSMUSG00000034255",
  "ENSMUSG00000034258",
  "ENSMUSG00000034259",
  "ENSMUSG00000034263",
  "ENSMUSG00000034265",
  "ENSMUSG00000034269",
  "ENSMUSG00000034271",
  "ENSMUSG00000034274",
  "ENSMUSG00000034275",
  "ENSMUSG00000034282",
  "ENSMUSG00000034285",
  "ENSMUSG00000034290",
  "ENSMUSG00000034292",
  "ENSMUSG00000034295",
  "ENSMUSG00000034300",
  "ENSMUSG00000034303",
  "ENSMUSG00000034308",
  "ENSMUSG00000034310",
  "ENSMUSG00000034311",
  "ENSMUSG00000034312",
  "ENSMUSG00000034317",
  "ENSMUSG00000034320",
  "ENSMUSG00000034321",
  "ENSMUSG00000034324",
  "ENSMUSG00000034327",
  "ENSMUSG00000034329",
  "ENSMUSG00000034330",
  "ENSMUSG00000034333",
  "ENSMUSG00000034334",
  "ENSMUSG00000034336",
  "ENSMUSG00000034341",
  "ENSMUSG00000034342",
  "ENSMUSG00000034343",
  "ENSMUSG00000034345",
  "ENSMUSG00000034349",
  "ENSMUSG00000034353",
  "ENSMUSG00000034354",
  "ENSMUSG00000034359",
  "ENSMUSG00000034361",
  "ENSMUSG00000034362",
  "ENSMUSG00000034371",
  "ENSMUSG00000034377",
  "ENSMUSG00000034379",
  "ENSMUSG00000034382",
  "ENSMUSG00000034384",
  "ENSMUSG00000034387",
  "ENSMUSG00000034390",
  "ENSMUSG00000034391",
  "ENSMUSG00000034394",
  "ENSMUSG00000034401",
  "ENSMUSG00000034402",
  "ENSMUSG00000034403",
  "ENSMUSG00000034412",
  "ENSMUSG00000034413",
  "ENSMUSG00000034416",
  "ENSMUSG00000034422",
  "ENSMUSG00000034424",
  "ENSMUSG00000034427",
  "ENSMUSG00000034429",
  "ENSMUSG00000034430",
  "ENSMUSG00000034432",
  "ENSMUSG00000034435",
  "ENSMUSG00000034438",
  "ENSMUSG00000034442",
  "ENSMUSG00000034445",
  "ENSMUSG00000034449",
  "ENSMUSG00000034450",
  "ENSMUSG00000034452",
  "ENSMUSG00000034453",
  "ENSMUSG00000034456",
  "ENSMUSG00000034457",
  "ENSMUSG00000034459",
  "ENSMUSG00000034460",
  "ENSMUSG00000034462",
  "ENSMUSG00000034463",
  "ENSMUSG00000034467",
  "ENSMUSG00000034471",
  "ENSMUSG00000034472",
  "ENSMUSG00000034473",
  "ENSMUSG00000034480",
  "ENSMUSG00000034482",
  "ENSMUSG00000034484",
  "ENSMUSG00000034485",
  "ENSMUSG00000034486",
  "ENSMUSG00000034487",
  "ENSMUSG00000034488",
  "ENSMUSG00000034493",
  "ENSMUSG00000034501",
  "ENSMUSG00000034509",
  "ENSMUSG00000034518",
  "ENSMUSG00000034520",
  "ENSMUSG00000034522",
  "ENSMUSG00000034525",
  "ENSMUSG00000034528",
  "ENSMUSG00000034532",
  "ENSMUSG00000034533",
  "ENSMUSG00000034538",
  "ENSMUSG00000034543",
  "ENSMUSG00000034544",
  "ENSMUSG00000034551",
  "ENSMUSG00000034552",
  "ENSMUSG00000034555",
  "ENSMUSG00000034557",
  "ENSMUSG00000034560",
  "ENSMUSG00000034563",
  "ENSMUSG00000034566",
  "ENSMUSG00000034570",
  "ENSMUSG00000034573",
  "ENSMUSG00000034575",
  "ENSMUSG00000034579",
  "ENSMUSG00000034583",
  "ENSMUSG00000034584",
  "ENSMUSG00000034586",
  "ENSMUSG00000034587",
  "ENSMUSG00000034591",
  "ENSMUSG00000034593",
  "ENSMUSG00000034595",
  "ENSMUSG00000034601",
  "ENSMUSG00000034602",
  "ENSMUSG00000034607",
  "ENSMUSG00000034610",
  "ENSMUSG00000034612",
  "ENSMUSG00000034613",
  "ENSMUSG00000034614",
  "ENSMUSG00000034616",
  "ENSMUSG00000034617",
  "ENSMUSG00000034620",
  "ENSMUSG00000034621",
  "ENSMUSG00000034623",
  "ENSMUSG00000034634",
  "ENSMUSG00000034636",
  "ENSMUSG00000034639",
  "ENSMUSG00000034640",
  "ENSMUSG00000034641",
  "ENSMUSG00000034645",
  "ENSMUSG00000034648",
  "ENSMUSG00000034653",
  "ENSMUSG00000034656",
  "ENSMUSG00000034659",
  "ENSMUSG00000034660",
  "ENSMUSG00000034663",
  "ENSMUSG00000034664",
  "ENSMUSG00000034667",
  "ENSMUSG00000034673",
  "ENSMUSG00000034674",
  "ENSMUSG00000034675",
  "ENSMUSG00000034677",
  "ENSMUSG00000034681",
  "ENSMUSG00000034683",
  "ENSMUSG00000034684",
  "ENSMUSG00000034685",
  "ENSMUSG00000034686",
  "ENSMUSG00000034687",
  "ENSMUSG00000034689",
  "ENSMUSG00000034690",
  "ENSMUSG00000034701",
  "ENSMUSG00000034706",
  "ENSMUSG00000034707",
  "ENSMUSG00000034708",
  "ENSMUSG00000034709",
  "ENSMUSG00000034714",
  "ENSMUSG00000034723",
  "ENSMUSG00000034724",
  "ENSMUSG00000034729",
  "ENSMUSG00000034730",
  "ENSMUSG00000034731",
  "ENSMUSG00000034732",
  "ENSMUSG00000034738",
  "ENSMUSG00000034744",
  "ENSMUSG00000034748",
  "ENSMUSG00000034751",
  "ENSMUSG00000034755",
  "ENSMUSG00000034757",
  "ENSMUSG00000034758",
  "ENSMUSG00000034761",
  "ENSMUSG00000034762",
  "ENSMUSG00000034764",
  "ENSMUSG00000034765",
  "ENSMUSG00000034768",
  "ENSMUSG00000034771",
  "ENSMUSG00000034773",
  "ENSMUSG00000034774",
  "ENSMUSG00000034777",
  "ENSMUSG00000034780",
  "ENSMUSG00000034781",
  "ENSMUSG00000034783",
  "ENSMUSG00000034785",
  "ENSMUSG00000034786",
  "ENSMUSG00000034789",
  "ENSMUSG00000034792",
  "ENSMUSG00000034793",
  "ENSMUSG00000034795",
  "ENSMUSG00000034796",
  "ENSMUSG00000034799",
  "ENSMUSG00000034800",
  "ENSMUSG00000034801",
  "ENSMUSG00000034807",
  "ENSMUSG00000034810",
  "ENSMUSG00000034813",
  "ENSMUSG00000034818",
  "ENSMUSG00000034820",
  "ENSMUSG00000034825",
  "ENSMUSG00000034826",
  "ENSMUSG00000034829",
  "ENSMUSG00000034832",
  "ENSMUSG00000034833",
  "ENSMUSG00000034837",
  "ENSMUSG00000034839",
  "ENSMUSG00000034842",
  "ENSMUSG00000034845",
  "ENSMUSG00000034848",
  "ENSMUSG00000034850",
  "ENSMUSG00000034853",
  "ENSMUSG00000034854",
  "ENSMUSG00000034855",
  "ENSMUSG00000034858",
  "ENSMUSG00000034863",
  "ENSMUSG00000034867",
  "ENSMUSG00000034868",
  "ENSMUSG00000034871",
  "ENSMUSG00000034872",
  "ENSMUSG00000034875",
  "ENSMUSG00000034880",
  "ENSMUSG00000034881",
  "ENSMUSG00000034883",
  "ENSMUSG00000034889",
  "ENSMUSG00000034891",
  "ENSMUSG00000034892",
  "ENSMUSG00000034893",
  "ENSMUSG00000034898",
  "ENSMUSG00000034902",
  "ENSMUSG00000034903",
  "ENSMUSG00000034906",
  "ENSMUSG00000034908",
  "ENSMUSG00000034910",
  "ENSMUSG00000034911",
  "ENSMUSG00000034912",
  "ENSMUSG00000034913",
  "ENSMUSG00000034917",
  "ENSMUSG00000034918",
  "ENSMUSG00000034919",
  "ENSMUSG00000034923",
  "ENSMUSG00000034926",
  "ENSMUSG00000034928",
  "ENSMUSG00000034930",
  "ENSMUSG00000034931",
  "ENSMUSG00000034932",
  "ENSMUSG00000034936",
  "ENSMUSG00000034940",
  "ENSMUSG00000034947",
  "ENSMUSG00000034949",
  "ENSMUSG00000034951",
  "ENSMUSG00000034957",
  "ENSMUSG00000034958",
  "ENSMUSG00000034959",
  "ENSMUSG00000034968",
  "ENSMUSG00000034973",
  "ENSMUSG00000034974",
  "ENSMUSG00000034981",
  "ENSMUSG00000034987",
  "ENSMUSG00000034990",
  "ENSMUSG00000034993",
  "ENSMUSG00000034994",
  "ENSMUSG00000034997",
  "ENSMUSG00000034998",
  "ENSMUSG00000035000",
  "ENSMUSG00000035004",
  "ENSMUSG00000035007",
  "ENSMUSG00000035011",
  "ENSMUSG00000035020",
  "ENSMUSG00000035021",
  "ENSMUSG00000035024",
  "ENSMUSG00000035027",
  "ENSMUSG00000035031",
  "ENSMUSG00000035032",
  "ENSMUSG00000035033",
  "ENSMUSG00000035041",
  "ENSMUSG00000035042",
  "ENSMUSG00000035045",
  "ENSMUSG00000035047",
  "ENSMUSG00000035048",
  "ENSMUSG00000035049",
  "ENSMUSG00000035051",
  "ENSMUSG00000035057",
  "ENSMUSG00000035062",
  "ENSMUSG00000035064",
  "ENSMUSG00000035067",
  "ENSMUSG00000035069",
  "ENSMUSG00000035078",
  "ENSMUSG00000035085",
  "ENSMUSG00000035086",
  "ENSMUSG00000035093",
  "ENSMUSG00000035095",
  "ENSMUSG00000035104",
  "ENSMUSG00000035105",
  "ENSMUSG00000035107",
  "ENSMUSG00000035109",
  "ENSMUSG00000035112",
  "ENSMUSG00000035121",
  "ENSMUSG00000035125",
  "ENSMUSG00000035126",
  "ENSMUSG00000035131",
  "ENSMUSG00000035133",
  "ENSMUSG00000035139",
  "ENSMUSG00000035142",
  "ENSMUSG00000035148",
  "ENSMUSG00000035150",
  "ENSMUSG00000035151",
  "ENSMUSG00000035152",
  "ENSMUSG00000035158",
  "ENSMUSG00000035161",
  "ENSMUSG00000035164",
  "ENSMUSG00000035165",
  "ENSMUSG00000035168",
  "ENSMUSG00000035171",
  "ENSMUSG00000035172",
  "ENSMUSG00000035173",
  "ENSMUSG00000035177",
  "ENSMUSG00000035179",
  "ENSMUSG00000035181",
  "ENSMUSG00000035183",
  "ENSMUSG00000035184",
  "ENSMUSG00000035186",
  "ENSMUSG00000035187",
  "ENSMUSG00000035189",
  "ENSMUSG00000035191",
  "ENSMUSG00000035198",
  "ENSMUSG00000035199",
  "ENSMUSG00000035200",
  "ENSMUSG00000035201",
  "ENSMUSG00000035202",
  "ENSMUSG00000035203",
  "ENSMUSG00000035206",
  "ENSMUSG00000035208",
  "ENSMUSG00000035211",
  "ENSMUSG00000035212",
  "ENSMUSG00000035215",
  "ENSMUSG00000035226",
  "ENSMUSG00000035227",
  "ENSMUSG00000035228",
  "ENSMUSG00000035232",
  "ENSMUSG00000035234",
  "ENSMUSG00000035235",
  "ENSMUSG00000035236",
  "ENSMUSG00000035237",
  "ENSMUSG00000035238",
  "ENSMUSG00000035239",
  "ENSMUSG00000035242",
  "ENSMUSG00000035245",
  "ENSMUSG00000035246",
  "ENSMUSG00000035247",
  "ENSMUSG00000035248",
  "ENSMUSG00000035258",
  "ENSMUSG00000035262",
  "ENSMUSG00000035266",
  "ENSMUSG00000035268",
  "ENSMUSG00000035270",
  "ENSMUSG00000035273",
  "ENSMUSG00000035274",
  "ENSMUSG00000035275",
  "ENSMUSG00000035277",
  "ENSMUSG00000035278",
  "ENSMUSG00000035279",
  "ENSMUSG00000035283",
  "ENSMUSG00000035284",
  "ENSMUSG00000035285",
  "ENSMUSG00000035293",
  "ENSMUSG00000035295",
  "ENSMUSG00000035296",
  "ENSMUSG00000035297",
  "ENSMUSG00000035298",
  "ENSMUSG00000035299",
  "ENSMUSG00000035305",
  "ENSMUSG00000035310",
  "ENSMUSG00000035311",
  "ENSMUSG00000035314",
  "ENSMUSG00000035325",
  "ENSMUSG00000035329",
  "ENSMUSG00000035342",
  "ENSMUSG00000035351",
  "ENSMUSG00000035352",
  "ENSMUSG00000035354",
  "ENSMUSG00000035355",
  "ENSMUSG00000035356",
  "ENSMUSG00000035365",
  "ENSMUSG00000035367",
  "ENSMUSG00000035371",
  "ENSMUSG00000035372",
  "ENSMUSG00000035373",
  "ENSMUSG00000035376",
  "ENSMUSG00000035378",
  "ENSMUSG00000035382",
  "ENSMUSG00000035383",
  "ENSMUSG00000035385",
  "ENSMUSG00000035387",
  "ENSMUSG00000035390",
  "ENSMUSG00000035392",
  "ENSMUSG00000035394",
  "ENSMUSG00000035395",
  "ENSMUSG00000035397",
  "ENSMUSG00000035399",
  "ENSMUSG00000035401",
  "ENSMUSG00000035403",
  "ENSMUSG00000035407",
  "ENSMUSG00000035413",
  "ENSMUSG00000035420",
  "ENSMUSG00000035427",
  "ENSMUSG00000035429",
  "ENSMUSG00000035431",
  "ENSMUSG00000035435",
  "ENSMUSG00000035437",
  "ENSMUSG00000035439",
  "ENSMUSG00000035441",
  "ENSMUSG00000035443",
  "ENSMUSG00000035448",
  "ENSMUSG00000035451",
  "ENSMUSG00000035454",
  "ENSMUSG00000035455",
  "ENSMUSG00000035456",
  "ENSMUSG00000035458",
  "ENSMUSG00000035459",
  "ENSMUSG00000035469",
  "ENSMUSG00000035472",
  "ENSMUSG00000035473",
  "ENSMUSG00000035476",
  "ENSMUSG00000035478",
  "ENSMUSG00000035486",
  "ENSMUSG00000035491",
  "ENSMUSG00000035493",
  "ENSMUSG00000035495",
  "ENSMUSG00000035498",
  "ENSMUSG00000035504",
  "ENSMUSG00000035505",
  "ENSMUSG00000035506",
  "ENSMUSG00000035509",
  "ENSMUSG00000035513",
  "ENSMUSG00000035517",
  "ENSMUSG00000035521",
  "ENSMUSG00000035522",
  "ENSMUSG00000035528",
  "ENSMUSG00000035529",
  "ENSMUSG00000035530",
  "ENSMUSG00000035539",
  "ENSMUSG00000035540",
  "ENSMUSG00000035545",
  "ENSMUSG00000035547",
  "ENSMUSG00000035551",
  "ENSMUSG00000035557",
  "ENSMUSG00000035559",
  "ENSMUSG00000035560",
  "ENSMUSG00000035561",
  "ENSMUSG00000035566",
  "ENSMUSG00000035569",
  "ENSMUSG00000035572",
  "ENSMUSG00000035575",
  "ENSMUSG00000035576",
  "ENSMUSG00000035578",
  "ENSMUSG00000035580",
  "ENSMUSG00000035582",
  "ENSMUSG00000035585",
  "ENSMUSG00000035592",
  "ENSMUSG00000035594",
  "ENSMUSG00000035595",
  "ENSMUSG00000035596",
  "ENSMUSG00000035597",
  "ENSMUSG00000035601",
  "ENSMUSG00000035606",
  "ENSMUSG00000035614",
  "ENSMUSG00000035615",
  "ENSMUSG00000035620",
  "ENSMUSG00000035621",
  "ENSMUSG00000035623",
  "ENSMUSG00000035626",
  "ENSMUSG00000035629",
  "ENSMUSG00000035637",
  "ENSMUSG00000035638",
  "ENSMUSG00000035640",
  "ENSMUSG00000035642",
  "ENSMUSG00000035649",
  "ENSMUSG00000035651",
  "ENSMUSG00000035653",
  "ENSMUSG00000035666",
  "ENSMUSG00000035671",
  "ENSMUSG00000035673",
  "ENSMUSG00000035674",
  "ENSMUSG00000035678",
  "ENSMUSG00000035681",
  "ENSMUSG00000035683",
  "ENSMUSG00000035686",
  "ENSMUSG00000035692",
  "ENSMUSG00000035694",
  "ENSMUSG00000035696",
  "ENSMUSG00000035697",
  "ENSMUSG00000035699",
  "ENSMUSG00000035704",
  "ENSMUSG00000035711",
  "ENSMUSG00000035713",
  "ENSMUSG00000035722",
  "ENSMUSG00000035725",
  "ENSMUSG00000035726",
  "ENSMUSG00000035735",
  "ENSMUSG00000035745",
  "ENSMUSG00000035754",
  "ENSMUSG00000035757",
  "ENSMUSG00000035759",
  "ENSMUSG00000035762",
  "ENSMUSG00000035764",
  "ENSMUSG00000035765",
  "ENSMUSG00000035770",
  "ENSMUSG00000035772",
  "ENSMUSG00000035773",
  "ENSMUSG00000035775",
  "ENSMUSG00000035776",
  "ENSMUSG00000035778",
  "ENSMUSG00000035780",
  "ENSMUSG00000035781",
  "ENSMUSG00000035783",
  "ENSMUSG00000035785",
  "ENSMUSG00000035790",
  "ENSMUSG00000035798",
  "ENSMUSG00000035799",
  "ENSMUSG00000035804",
  "ENSMUSG00000035805",
  "ENSMUSG00000035811",
  "ENSMUSG00000035818",
  "ENSMUSG00000035824",
  "ENSMUSG00000035828",
  "ENSMUSG00000035829",
  "ENSMUSG00000035831",
  "ENSMUSG00000035834",
  "ENSMUSG00000035835",
  "ENSMUSG00000035836",
  "ENSMUSG00000035840",
  "ENSMUSG00000035842",
  "ENSMUSG00000035845",
  "ENSMUSG00000035847",
  "ENSMUSG00000035849",
  "ENSMUSG00000035851",
  "ENSMUSG00000035852",
  "ENSMUSG00000035860",
  "ENSMUSG00000035861",
  "ENSMUSG00000035863",
  "ENSMUSG00000035864",
  "ENSMUSG00000035868",
  "ENSMUSG00000035875",
  "ENSMUSG00000035877",
  "ENSMUSG00000035878",
  "ENSMUSG00000035885",
  "ENSMUSG00000035890",
  "ENSMUSG00000035891",
  "ENSMUSG00000035896",
  "ENSMUSG00000035898",
  "ENSMUSG00000035900",
  "ENSMUSG00000035901",
  "ENSMUSG00000035910",
  "ENSMUSG00000035914",
  "ENSMUSG00000035916",
  "ENSMUSG00000035919",
  "ENSMUSG00000035923",
  "ENSMUSG00000035929",
  "ENSMUSG00000035930",
  "ENSMUSG00000035932",
  "ENSMUSG00000035933",
  "ENSMUSG00000035934",
  "ENSMUSG00000035936",
  "ENSMUSG00000035944",
  "ENSMUSG00000035946",
  "ENSMUSG00000035948",
  "ENSMUSG00000035949",
  "ENSMUSG00000035951",
  "ENSMUSG00000035953",
  "ENSMUSG00000035954",
  "ENSMUSG00000035958",
  "ENSMUSG00000035960",
  "ENSMUSG00000035963",
  "ENSMUSG00000035964",
  "ENSMUSG00000035967",
  "ENSMUSG00000035969",
  "ENSMUSG00000035983",
  "ENSMUSG00000035984",
  "ENSMUSG00000035992",
  "ENSMUSG00000036002",
  "ENSMUSG00000036006",
  "ENSMUSG00000036009",
  "ENSMUSG00000036013",
  "ENSMUSG00000036019",
  "ENSMUSG00000036022",
  "ENSMUSG00000036023",
  "ENSMUSG00000036026",
  "ENSMUSG00000036027",
  "ENSMUSG00000036030",
  "ENSMUSG00000036036",
  "ENSMUSG00000036040",
  "ENSMUSG00000036046",
  "ENSMUSG00000036052",
  "ENSMUSG00000036053",
  "ENSMUSG00000036054",
  "ENSMUSG00000036057",
  "ENSMUSG00000036061",
  "ENSMUSG00000036062",
  "ENSMUSG00000036067",
  "ENSMUSG00000036073",
  "ENSMUSG00000036078",
  "ENSMUSG00000036083",
  "ENSMUSG00000036086",
  "ENSMUSG00000036087",
  "ENSMUSG00000036091",
  "ENSMUSG00000036093",
  "ENSMUSG00000036095",
  "ENSMUSG00000036097",
  "ENSMUSG00000036098",
  "ENSMUSG00000036099",
  "ENSMUSG00000036103",
  "ENSMUSG00000036104",
  "ENSMUSG00000036106",
  "ENSMUSG00000036109",
  "ENSMUSG00000036110",
  "ENSMUSG00000036111",
  "ENSMUSG00000036112",
  "ENSMUSG00000036114",
  "ENSMUSG00000036117",
  "ENSMUSG00000036120",
  "ENSMUSG00000036123",
  "ENSMUSG00000036136",
  "ENSMUSG00000036138",
  "ENSMUSG00000036139",
  "ENSMUSG00000036144",
  "ENSMUSG00000036151",
  "ENSMUSG00000036155",
  "ENSMUSG00000036158",
  "ENSMUSG00000036160",
  "ENSMUSG00000036162",
  "ENSMUSG00000036167",
  "ENSMUSG00000036168",
  "ENSMUSG00000036169",
  "ENSMUSG00000036172",
  "ENSMUSG00000036180",
  "ENSMUSG00000036181",
  "ENSMUSG00000036185",
  "ENSMUSG00000036186",
  "ENSMUSG00000036188",
  "ENSMUSG00000036192",
  "ENSMUSG00000036196",
  "ENSMUSG00000036197",
  "ENSMUSG00000036198",
  "ENSMUSG00000036199",
  "ENSMUSG00000036202",
  "ENSMUSG00000036206",
  "ENSMUSG00000036208",
  "ENSMUSG00000036211",
  "ENSMUSG00000036214",
  "ENSMUSG00000036216",
  "ENSMUSG00000036218",
  "ENSMUSG00000036223",
  "ENSMUSG00000036225",
  "ENSMUSG00000036231",
  "ENSMUSG00000036241",
  "ENSMUSG00000036242",
  "ENSMUSG00000036244",
  "ENSMUSG00000036246",
  "ENSMUSG00000036249",
  "ENSMUSG00000036251",
  "ENSMUSG00000036256",
  "ENSMUSG00000036257",
  "ENSMUSG00000036264",
  "ENSMUSG00000036270",
  "ENSMUSG00000036273",
  "ENSMUSG00000036275",
  "ENSMUSG00000036278",
  "ENSMUSG00000036281",
  "ENSMUSG00000036282",
  "ENSMUSG00000036285",
  "ENSMUSG00000036291",
  "ENSMUSG00000036295",
  "ENSMUSG00000036298",
  "ENSMUSG00000036299",
  "ENSMUSG00000036304",
  "ENSMUSG00000036306",
  "ENSMUSG00000036309",
  "ENSMUSG00000036315",
  "ENSMUSG00000036323",
  "ENSMUSG00000036327",
  "ENSMUSG00000036330",
  "ENSMUSG00000036333",
  "ENSMUSG00000036334",
  "ENSMUSG00000036339",
  "ENSMUSG00000036352",
  "ENSMUSG00000036353",
  "ENSMUSG00000036356",
  "ENSMUSG00000036357",
  "ENSMUSG00000036362",
  "ENSMUSG00000036368",
  "ENSMUSG00000036371",
  "ENSMUSG00000036372",
  "ENSMUSG00000036376",
  "ENSMUSG00000036377",
  "ENSMUSG00000036381",
  "ENSMUSG00000036390",
  "ENSMUSG00000036391",
  "ENSMUSG00000036395",
  "ENSMUSG00000036398",
  "ENSMUSG00000036401",
  "ENSMUSG00000036402",
  "ENSMUSG00000036403",
  "ENSMUSG00000036411",
  "ENSMUSG00000036412",
  "ENSMUSG00000036422",
  "ENSMUSG00000036427",
  "ENSMUSG00000036430",
  "ENSMUSG00000036435",
  "ENSMUSG00000036437",
  "ENSMUSG00000036438",
  "ENSMUSG00000036442",
  "ENSMUSG00000036446",
  "ENSMUSG00000036449",
  "ENSMUSG00000036450",
  "ENSMUSG00000036452",
  "ENSMUSG00000036459",
  "ENSMUSG00000036461",
  "ENSMUSG00000036463",
  "ENSMUSG00000036466",
  "ENSMUSG00000036469",
  "ENSMUSG00000036473",
  "ENSMUSG00000036478",
  "ENSMUSG00000036480",
  "ENSMUSG00000036492",
  "ENSMUSG00000036499",
  "ENSMUSG00000036500",
  "ENSMUSG00000036501",
  "ENSMUSG00000036502",
  "ENSMUSG00000036503",
  "ENSMUSG00000036504",
  "ENSMUSG00000036510",
  "ENSMUSG00000036513",
  "ENSMUSG00000036521",
  "ENSMUSG00000036523",
  "ENSMUSG00000036526",
  "ENSMUSG00000036528",
  "ENSMUSG00000036529",
  "ENSMUSG00000036533",
  "ENSMUSG00000036534",
  "ENSMUSG00000036537",
  "ENSMUSG00000036545",
  "ENSMUSG00000036550",
  "ENSMUSG00000036551",
  "ENSMUSG00000036552",
  "ENSMUSG00000036553",
  "ENSMUSG00000036555",
  "ENSMUSG00000036557",
  "ENSMUSG00000036560",
  "ENSMUSG00000036561",
  "ENSMUSG00000036564",
  "ENSMUSG00000036565",
  "ENSMUSG00000036568",
  "ENSMUSG00000036570",
  "ENSMUSG00000036572",
  "ENSMUSG00000036574",
  "ENSMUSG00000036578",
  "ENSMUSG00000036580",
  "ENSMUSG00000036585",
  "ENSMUSG00000036586",
  "ENSMUSG00000036587",
  "ENSMUSG00000036591",
  "ENSMUSG00000036594",
  "ENSMUSG00000036596",
  "ENSMUSG00000036598",
  "ENSMUSG00000036599",
  "ENSMUSG00000036602",
  "ENSMUSG00000036606",
  "ENSMUSG00000036611",
  "ENSMUSG00000036613",
  "ENSMUSG00000036615",
  "ENSMUSG00000036617",
  "ENSMUSG00000036620",
  "ENSMUSG00000036622",
  "ENSMUSG00000036632",
  "ENSMUSG00000036634",
  "ENSMUSG00000036636",
  "ENSMUSG00000036639",
  "ENSMUSG00000036641",
  "ENSMUSG00000036644",
  "ENSMUSG00000036646",
  "ENSMUSG00000036647",
  "ENSMUSG00000036655",
  "ENSMUSG00000036658",
  "ENSMUSG00000036661",
  "ENSMUSG00000036667",
  "ENSMUSG00000036672",
  "ENSMUSG00000036676",
  "ENSMUSG00000036678",
  "ENSMUSG00000036686",
  "ENSMUSG00000036687",
  "ENSMUSG00000036693",
  "ENSMUSG00000036698",
  "ENSMUSG00000036699",
  "ENSMUSG00000036707",
  "ENSMUSG00000036712",
  "ENSMUSG00000036718",
  "ENSMUSG00000036721",
  "ENSMUSG00000036731",
  "ENSMUSG00000036737",
  "ENSMUSG00000036743",
  "ENSMUSG00000036744",
  "ENSMUSG00000036745",
  "ENSMUSG00000036748",
  "ENSMUSG00000036749",
  "ENSMUSG00000036751",
  "ENSMUSG00000036760",
  "ENSMUSG00000036764",
  "ENSMUSG00000036766",
  "ENSMUSG00000036768",
  "ENSMUSG00000036769",
  "ENSMUSG00000036770",
  "ENSMUSG00000036775",
  "ENSMUSG00000036777",
  "ENSMUSG00000036779",
  "ENSMUSG00000036781",
  "ENSMUSG00000036782",
  "ENSMUSG00000036790",
  "ENSMUSG00000036792",
  "ENSMUSG00000036800",
  "ENSMUSG00000036805",
  "ENSMUSG00000036810",
  "ENSMUSG00000036813",
  "ENSMUSG00000036814",
  "ENSMUSG00000036815",
  "ENSMUSG00000036816",
  "ENSMUSG00000036817",
  "ENSMUSG00000036819",
  "ENSMUSG00000036820",
  "ENSMUSG00000036822",
  "ENSMUSG00000036825",
  "ENSMUSG00000036832",
  "ENSMUSG00000036833",
  "ENSMUSG00000036834",
  "ENSMUSG00000036835",
  "ENSMUSG00000036840",
  "ENSMUSG00000036845",
  "ENSMUSG00000036850",
  "ENSMUSG00000036853",
  "ENSMUSG00000036854",
  "ENSMUSG00000036855",
  "ENSMUSG00000036856",
  "ENSMUSG00000036858",
  "ENSMUSG00000036860",
  "ENSMUSG00000036862",
  "ENSMUSG00000036863",
  "ENSMUSG00000036867",
  "ENSMUSG00000036872",
  "ENSMUSG00000036873",
  "ENSMUSG00000036875",
  "ENSMUSG00000036879",
  "ENSMUSG00000036880",
  "ENSMUSG00000036882",
  "ENSMUSG00000036885",
  "ENSMUSG00000036887",
  "ENSMUSG00000036890",
  "ENSMUSG00000036892",
  "ENSMUSG00000036893",
  "ENSMUSG00000036894",
  "ENSMUSG00000036896",
  "ENSMUSG00000036898",
  "ENSMUSG00000036899",
  "ENSMUSG00000036902",
  "ENSMUSG00000036904",
  "ENSMUSG00000036905",
  "ENSMUSG00000036907",
  "ENSMUSG00000036908",
  "ENSMUSG00000036912",
  "ENSMUSG00000036913",
  "ENSMUSG00000036915",
  "ENSMUSG00000036916",
  "ENSMUSG00000036918",
  "ENSMUSG00000036921",
  "ENSMUSG00000036923",
  "ENSMUSG00000036924",
  "ENSMUSG00000036925",
  "ENSMUSG00000036928",
  "ENSMUSG00000036931",
  "ENSMUSG00000036932",
  "ENSMUSG00000036934",
  "ENSMUSG00000036938",
  "ENSMUSG00000036940",
  "ENSMUSG00000036941",
  "ENSMUSG00000036943",
  "ENSMUSG00000036944",
  "ENSMUSG00000036948",
  "ENSMUSG00000036949",
  "ENSMUSG00000036951",
  "ENSMUSG00000036955",
  "ENSMUSG00000036957",
  "ENSMUSG00000036958",
  "ENSMUSG00000036959",
  "ENSMUSG00000036960",
  "ENSMUSG00000036961",
  "ENSMUSG00000036962",
  "ENSMUSG00000036964",
  "ENSMUSG00000036966",
  "ENSMUSG00000036968",
  "ENSMUSG00000036972",
  "ENSMUSG00000036975",
  "ENSMUSG00000036977",
  "ENSMUSG00000036980",
  "ENSMUSG00000036983",
  "ENSMUSG00000036985",
  "ENSMUSG00000036986",
  "ENSMUSG00000036989",
  "ENSMUSG00000036990",
  "ENSMUSG00000036992",
  "ENSMUSG00000036995",
  "ENSMUSG00000037001",
  "ENSMUSG00000037003",
  "ENSMUSG00000037005",
  "ENSMUSG00000037007",
  "ENSMUSG00000037010",
  "ENSMUSG00000037012",
  "ENSMUSG00000037013",
  "ENSMUSG00000037014",
  "ENSMUSG00000037016",
  "ENSMUSG00000037017",
  "ENSMUSG00000037020",
  "ENSMUSG00000037022",
  "ENSMUSG00000037025",
  "ENSMUSG00000037028",
  "ENSMUSG00000037029",
  "ENSMUSG00000037031",
  "ENSMUSG00000037032",
  "ENSMUSG00000037033",
  "ENSMUSG00000037034",
  "ENSMUSG00000037035",
  "ENSMUSG00000037049",
  "ENSMUSG00000037053",
  "ENSMUSG00000037058",
  "ENSMUSG00000037060",
  "ENSMUSG00000037062",
  "ENSMUSG00000037071",
  "ENSMUSG00000037072",
  "ENSMUSG00000037075",
  "ENSMUSG00000037085",
  "ENSMUSG00000037086",
  "ENSMUSG00000037089",
  "ENSMUSG00000037095",
  "ENSMUSG00000037098",
  "ENSMUSG00000037101",
  "ENSMUSG00000037103",
  "ENSMUSG00000037104",
  "ENSMUSG00000037106",
  "ENSMUSG00000037108",
  "ENSMUSG00000037110",
  "ENSMUSG00000037111",
  "ENSMUSG00000037112",
  "ENSMUSG00000037119",
  "ENSMUSG00000037124",
  "ENSMUSG00000037126",
  "ENSMUSG00000037129",
  "ENSMUSG00000037130",
  "ENSMUSG00000037134",
  "ENSMUSG00000037138",
  "ENSMUSG00000037139",
  "ENSMUSG00000037140",
  "ENSMUSG00000037143",
  "ENSMUSG00000037145",
  "ENSMUSG00000037148",
  "ENSMUSG00000037149",
  "ENSMUSG00000037151",
  "ENSMUSG00000037152",
  "ENSMUSG00000037157",
  "ENSMUSG00000037159",
  "ENSMUSG00000037161",
  "ENSMUSG00000037166",
  "ENSMUSG00000037167",
  "ENSMUSG00000037169",
  "ENSMUSG00000037171",
  "ENSMUSG00000037172",
  "ENSMUSG00000037174",
  "ENSMUSG00000037185",
  "ENSMUSG00000037188",
  "ENSMUSG00000037190",
  "ENSMUSG00000037196",
  "ENSMUSG00000037197",
  "ENSMUSG00000037202",
  "ENSMUSG00000037204",
  "ENSMUSG00000037206",
  "ENSMUSG00000037210",
  "ENSMUSG00000037211",
  "ENSMUSG00000037214",
  "ENSMUSG00000037216",
  "ENSMUSG00000037217",
  "ENSMUSG00000037221",
  "ENSMUSG00000037224",
  "ENSMUSG00000037225",
  "ENSMUSG00000037234",
  "ENSMUSG00000037235",
  "ENSMUSG00000037236",
  "ENSMUSG00000037239",
  "ENSMUSG00000037242",
  "ENSMUSG00000037243",
  "ENSMUSG00000037247",
  "ENSMUSG00000037251",
  "ENSMUSG00000037253",
  "ENSMUSG00000037254",
  "ENSMUSG00000037257",
  "ENSMUSG00000037259",
  "ENSMUSG00000037260",
  "ENSMUSG00000037262",
  "ENSMUSG00000037263",
  "ENSMUSG00000037266",
  "ENSMUSG00000037270",
  "ENSMUSG00000037275",
  "ENSMUSG00000037278",
  "ENSMUSG00000037280",
  "ENSMUSG00000037286",
  "ENSMUSG00000037287",
  "ENSMUSG00000037295",
  "ENSMUSG00000037296",
  "ENSMUSG00000037300",
  "ENSMUSG00000037306",
  "ENSMUSG00000037307",
  "ENSMUSG00000037313",
  "ENSMUSG00000037315",
  "ENSMUSG00000037316",
  "ENSMUSG00000037318",
  "ENSMUSG00000037321",
  "ENSMUSG00000037325",
  "ENSMUSG00000037326",
  "ENSMUSG00000037331",
  "ENSMUSG00000037334",
  "ENSMUSG00000037335",
  "ENSMUSG00000037336",
  "ENSMUSG00000037337",
  "ENSMUSG00000037339",
  "ENSMUSG00000037341",
  "ENSMUSG00000037343",
  "ENSMUSG00000037344",
  "ENSMUSG00000037346",
  "ENSMUSG00000037347",
  "ENSMUSG00000037348",
  "ENSMUSG00000037349",
  "ENSMUSG00000037351",
  "ENSMUSG00000037353",
  "ENSMUSG00000037355",
  "ENSMUSG00000037358",
  "ENSMUSG00000037361",
  "ENSMUSG00000037362",
  "ENSMUSG00000037363",
  "ENSMUSG00000037364",
  "ENSMUSG00000037369",
  "ENSMUSG00000037370",
  "ENSMUSG00000037373",
  "ENSMUSG00000037375",
  "ENSMUSG00000037376",
  "ENSMUSG00000037379",
  "ENSMUSG00000037386",
  "ENSMUSG00000037390",
  "ENSMUSG00000037393",
  "ENSMUSG00000037395",
  "ENSMUSG00000037400",
  "ENSMUSG00000037405",
  "ENSMUSG00000037406",
  "ENSMUSG00000037408",
  "ENSMUSG00000037410",
  "ENSMUSG00000037411",
  "ENSMUSG00000037415",
  "ENSMUSG00000037416",
  "ENSMUSG00000037418",
  "ENSMUSG00000037419",
  "ENSMUSG00000037424",
  "ENSMUSG00000037426",
  "ENSMUSG00000037428",
  "ENSMUSG00000037432",
  "ENSMUSG00000037434",
  "ENSMUSG00000037437",
  "ENSMUSG00000037440",
  "ENSMUSG00000037443",
  "ENSMUSG00000037446",
  "ENSMUSG00000037447",
  "ENSMUSG00000037451",
  "ENSMUSG00000037455",
  "ENSMUSG00000037458",
  "ENSMUSG00000037461",
  "ENSMUSG00000037463",
  "ENSMUSG00000037465",
  "ENSMUSG00000037466",
  "ENSMUSG00000037469",
  "ENSMUSG00000037470",
  "ENSMUSG00000037474",
  "ENSMUSG00000037475",
  "ENSMUSG00000037477",
  "ENSMUSG00000037482",
  "ENSMUSG00000037486",
  "ENSMUSG00000037487",
  "ENSMUSG00000037490",
  "ENSMUSG00000037492",
  "ENSMUSG00000037493",
  "ENSMUSG00000037499",
  "ENSMUSG00000037503",
  "ENSMUSG00000037509",
  "ENSMUSG00000037514",
  "ENSMUSG00000037519",
  "ENSMUSG00000037523",
  "ENSMUSG00000037525",
  "ENSMUSG00000037526",
  "ENSMUSG00000037529",
  "ENSMUSG00000037531",
  "ENSMUSG00000037533",
  "ENSMUSG00000037535",
  "ENSMUSG00000037536",
  "ENSMUSG00000037537",
  "ENSMUSG00000037541",
  "ENSMUSG00000037542",
  "ENSMUSG00000037544",
  "ENSMUSG00000037548",
  "ENSMUSG00000037552",
  "ENSMUSG00000037553",
  "ENSMUSG00000037563",
  "ENSMUSG00000037568",
  "ENSMUSG00000037570",
  "ENSMUSG00000037572",
  "ENSMUSG00000037573",
  "ENSMUSG00000037577",
  "ENSMUSG00000037578",
  "ENSMUSG00000037579",
  "ENSMUSG00000037580",
  "ENSMUSG00000037583",
  "ENSMUSG00000037593",
  "ENSMUSG00000037594",
  "ENSMUSG00000037600",
  "ENSMUSG00000037601",
  "ENSMUSG00000037605",
  "ENSMUSG00000037606",
  "ENSMUSG00000037608",
  "ENSMUSG00000037610",
  "ENSMUSG00000037613",
  "ENSMUSG00000037617",
  "ENSMUSG00000037621",
  "ENSMUSG00000037622",
  "ENSMUSG00000037624",
  "ENSMUSG00000037625",
  "ENSMUSG00000037627",
  "ENSMUSG00000037628",
  "ENSMUSG00000037636",
  "ENSMUSG00000037638",
  "ENSMUSG00000037640",
  "ENSMUSG00000037643",
  "ENSMUSG00000037646",
  "ENSMUSG00000037649",
  "ENSMUSG00000037652",
  "ENSMUSG00000037653",
  "ENSMUSG00000037656",
  "ENSMUSG00000037660",
  "ENSMUSG00000037661",
  "ENSMUSG00000037664",
  "ENSMUSG00000037669",
  "ENSMUSG00000037674",
  "ENSMUSG00000037679",
  "ENSMUSG00000037681",
  "ENSMUSG00000037683",
  "ENSMUSG00000037685",
  "ENSMUSG00000037686",
  "ENSMUSG00000037689",
  "ENSMUSG00000037692",
  "ENSMUSG00000037697",
  "ENSMUSG00000037703",
  "ENSMUSG00000037705",
  "ENSMUSG00000037706",
  "ENSMUSG00000037708",
  "ENSMUSG00000037709",
  "ENSMUSG00000037710",
  "ENSMUSG00000037712",
  "ENSMUSG00000037716",
  "ENSMUSG00000037720",
  "ENSMUSG00000037722",
  "ENSMUSG00000037725",
  "ENSMUSG00000037727",
  "ENSMUSG00000037730",
  "ENSMUSG00000037731",
  "ENSMUSG00000037735",
  "ENSMUSG00000037736",
  "ENSMUSG00000037737",
  "ENSMUSG00000037738",
  "ENSMUSG00000037740",
  "ENSMUSG00000037742",
  "ENSMUSG00000037747",
  "ENSMUSG00000037750",
  "ENSMUSG00000037752",
  "ENSMUSG00000037754",
  "ENSMUSG00000037759",
  "ENSMUSG00000037761",
  "ENSMUSG00000037762",
  "ENSMUSG00000037771",
  "ENSMUSG00000037773",
  "ENSMUSG00000037780",
  "ENSMUSG00000037787",
  "ENSMUSG00000037788",
  "ENSMUSG00000037790",
  "ENSMUSG00000037791",
  "ENSMUSG00000037795",
  "ENSMUSG00000037797",
  "ENSMUSG00000037798",
  "ENSMUSG00000037801",
  "ENSMUSG00000037805",
  "ENSMUSG00000037808",
  "ENSMUSG00000037813",
  "ENSMUSG00000037815",
  "ENSMUSG00000037816",
  "ENSMUSG00000037818",
  "ENSMUSG00000037820",
  "ENSMUSG00000037822",
  "ENSMUSG00000037824",
  "ENSMUSG00000037826",
  "ENSMUSG00000037833",
  "ENSMUSG00000037843",
  "ENSMUSG00000037845",
  "ENSMUSG00000037846",
  "ENSMUSG00000037847",
  "ENSMUSG00000037849",
  "ENSMUSG00000037851",
  "ENSMUSG00000037852",
  "ENSMUSG00000037855",
  "ENSMUSG00000037857",
  "ENSMUSG00000037860",
  "ENSMUSG00000037868",
  "ENSMUSG00000037872",
  "ENSMUSG00000037876",
  "ENSMUSG00000037884",
  "ENSMUSG00000037885",
  "ENSMUSG00000037887",
  "ENSMUSG00000037890",
  "ENSMUSG00000037892",
  "ENSMUSG00000037894",
  "ENSMUSG00000037896",
  "ENSMUSG00000037902",
  "ENSMUSG00000037904",
  "ENSMUSG00000037905",
  "ENSMUSG00000037907",
  "ENSMUSG00000037910",
  "ENSMUSG00000037913",
  "ENSMUSG00000037916",
  "ENSMUSG00000037921",
  "ENSMUSG00000037922",
  "ENSMUSG00000037924",
  "ENSMUSG00000037926",
  "ENSMUSG00000037933",
  "ENSMUSG00000037935",
  "ENSMUSG00000037936",
  "ENSMUSG00000037938",
  "ENSMUSG00000037940",
  "ENSMUSG00000037942",
  "ENSMUSG00000037944",
  "ENSMUSG00000037946",
  "ENSMUSG00000037949",
  "ENSMUSG00000037953",
  "ENSMUSG00000037957",
  "ENSMUSG00000037958",
  "ENSMUSG00000037960",
  "ENSMUSG00000037962",
  "ENSMUSG00000037965",
  "ENSMUSG00000037966",
  "ENSMUSG00000037971",
  "ENSMUSG00000037972",
  "ENSMUSG00000037973",
  "ENSMUSG00000037974",
  "ENSMUSG00000037977",
  "ENSMUSG00000037979",
  "ENSMUSG00000037984",
  "ENSMUSG00000037989",
  "ENSMUSG00000037990",
  "ENSMUSG00000037991",
  "ENSMUSG00000037992",
  "ENSMUSG00000037993",
  "ENSMUSG00000037994",
  "ENSMUSG00000037995",
  "ENSMUSG00000037996",
  "ENSMUSG00000037997",
  "ENSMUSG00000037999",
  "ENSMUSG00000038000",
  "ENSMUSG00000038002",
  "ENSMUSG00000038005",
  "ENSMUSG00000038007",
  "ENSMUSG00000038009",
  "ENSMUSG00000038010",
  "ENSMUSG00000038011",
  "ENSMUSG00000038013",
  "ENSMUSG00000038014",
  "ENSMUSG00000038015",
  "ENSMUSG00000038020",
  "ENSMUSG00000038022",
  "ENSMUSG00000038023",
  "ENSMUSG00000038024",
  "ENSMUSG00000038025",
  "ENSMUSG00000038026",
  "ENSMUSG00000038028",
  "ENSMUSG00000038034",
  "ENSMUSG00000038037",
  "ENSMUSG00000038039",
  "ENSMUSG00000038042",
  "ENSMUSG00000038044",
  "ENSMUSG00000038045",
  "ENSMUSG00000038046",
  "ENSMUSG00000038047",
  "ENSMUSG00000038048",
  "ENSMUSG00000038055",
  "ENSMUSG00000038056",
  "ENSMUSG00000038057",
  "ENSMUSG00000038058",
  "ENSMUSG00000038059",
  "ENSMUSG00000038060",
  "ENSMUSG00000038064",
  "ENSMUSG00000038065",
  "ENSMUSG00000038067",
  "ENSMUSG00000038068",
  "ENSMUSG00000038069",
  "ENSMUSG00000038070",
  "ENSMUSG00000038071",
  "ENSMUSG00000038072",
  "ENSMUSG00000038074",
  "ENSMUSG00000038077",
  "ENSMUSG00000038079",
  "ENSMUSG00000038080",
  "ENSMUSG00000038084",
  "ENSMUSG00000038085",
  "ENSMUSG00000038086",
  "ENSMUSG00000038092",
  "ENSMUSG00000038094",
  "ENSMUSG00000038095",
  "ENSMUSG00000038102",
  "ENSMUSG00000038112",
  "ENSMUSG00000038115",
  "ENSMUSG00000038116",
  "ENSMUSG00000038119",
  "ENSMUSG00000038121",
  "ENSMUSG00000038122",
  "ENSMUSG00000038126",
  "ENSMUSG00000038127",
  "ENSMUSG00000038128",
  "ENSMUSG00000038132",
  "ENSMUSG00000038135",
  "ENSMUSG00000038143",
  "ENSMUSG00000038145",
  "ENSMUSG00000038146",
  "ENSMUSG00000038147",
  "ENSMUSG00000038148",
  "ENSMUSG00000038150",
  "ENSMUSG00000038151",
  "ENSMUSG00000038152",
  "ENSMUSG00000038156",
  "ENSMUSG00000038160",
  "ENSMUSG00000038167",
  "ENSMUSG00000038168",
  "ENSMUSG00000038170",
  "ENSMUSG00000038172",
  "ENSMUSG00000038173",
  "ENSMUSG00000038174",
  "ENSMUSG00000038175",
  "ENSMUSG00000038178",
  "ENSMUSG00000038179",
  "ENSMUSG00000038180",
  "ENSMUSG00000038181",
  "ENSMUSG00000038187",
  "ENSMUSG00000038188",
  "ENSMUSG00000038192",
  "ENSMUSG00000038193",
  "ENSMUSG00000038195",
  "ENSMUSG00000038199",
  "ENSMUSG00000038201",
  "ENSMUSG00000038203",
  "ENSMUSG00000038204",
  "ENSMUSG00000038205",
  "ENSMUSG00000038206",
  "ENSMUSG00000038209",
  "ENSMUSG00000038210",
  "ENSMUSG00000038212",
  "ENSMUSG00000038213",
  "ENSMUSG00000038214",
  "ENSMUSG00000038215",
  "ENSMUSG00000038216",
  "ENSMUSG00000038217",
  "ENSMUSG00000038224",
  "ENSMUSG00000038225",
  "ENSMUSG00000038227",
  "ENSMUSG00000038233",
  "ENSMUSG00000038235",
  "ENSMUSG00000038236",
  "ENSMUSG00000038239",
  "ENSMUSG00000038240",
  "ENSMUSG00000038241",
  "ENSMUSG00000038242",
  "ENSMUSG00000038244",
  "ENSMUSG00000038246",
  "ENSMUSG00000038248",
  "ENSMUSG00000038250",
  "ENSMUSG00000038252",
  "ENSMUSG00000038253",
  "ENSMUSG00000038256",
  "ENSMUSG00000038257",
  "ENSMUSG00000038259",
  "ENSMUSG00000038260",
  "ENSMUSG00000038264",
  "ENSMUSG00000038267",
  "ENSMUSG00000038268",
  "ENSMUSG00000038271",
  "ENSMUSG00000038274",
  "ENSMUSG00000038276",
  "ENSMUSG00000038279",
  "ENSMUSG00000038280",
  "ENSMUSG00000038286",
  "ENSMUSG00000038290",
  "ENSMUSG00000038291",
  "ENSMUSG00000038292",
  "ENSMUSG00000038295",
  "ENSMUSG00000038296",
  "ENSMUSG00000038298",
  "ENSMUSG00000038299",
  "ENSMUSG00000038300",
  "ENSMUSG00000038301",
  "ENSMUSG00000038302",
  "ENSMUSG00000038304",
  "ENSMUSG00000038305",
  "ENSMUSG00000038311",
  "ENSMUSG00000038312",
  "ENSMUSG00000038319",
  "ENSMUSG00000038323",
  "ENSMUSG00000038324",
  "ENSMUSG00000038330",
  "ENSMUSG00000038331",
  "ENSMUSG00000038332",
  "ENSMUSG00000038335",
  "ENSMUSG00000038342",
  "ENSMUSG00000038344",
  "ENSMUSG00000038347",
  "ENSMUSG00000038349",
  "ENSMUSG00000038351",
  "ENSMUSG00000038352",
  "ENSMUSG00000038354",
  "ENSMUSG00000038357",
  "ENSMUSG00000038365",
  "ENSMUSG00000038366",
  "ENSMUSG00000038368",
  "ENSMUSG00000038369",
  "ENSMUSG00000038370",
  "ENSMUSG00000038371",
  "ENSMUSG00000038372",
  "ENSMUSG00000038375",
  "ENSMUSG00000038379",
  "ENSMUSG00000038383",
  "ENSMUSG00000038384",
  "ENSMUSG00000038387",
  "ENSMUSG00000038388",
  "ENSMUSG00000038390",
  "ENSMUSG00000038393",
  "ENSMUSG00000038398",
  "ENSMUSG00000038400",
  "ENSMUSG00000038402",
  "ENSMUSG00000038403",
  "ENSMUSG00000038406",
  "ENSMUSG00000038408",
  "ENSMUSG00000038412",
  "ENSMUSG00000038415",
  "ENSMUSG00000038416",
  "ENSMUSG00000038417",
  "ENSMUSG00000038418",
  "ENSMUSG00000038421",
  "ENSMUSG00000038422",
  "ENSMUSG00000038425",
  "ENSMUSG00000038429",
  "ENSMUSG00000038437",
  "ENSMUSG00000038446",
  "ENSMUSG00000038451",
  "ENSMUSG00000038453",
  "ENSMUSG00000038456",
  "ENSMUSG00000038457",
  "ENSMUSG00000038459",
  "ENSMUSG00000038462",
  "ENSMUSG00000038463",
  "ENSMUSG00000038467",
  "ENSMUSG00000038473",
  "ENSMUSG00000038481",
  "ENSMUSG00000038482",
  "ENSMUSG00000038485",
  "ENSMUSG00000038486",
  "ENSMUSG00000038489",
  "ENSMUSG00000038491",
  "ENSMUSG00000038495",
  "ENSMUSG00000038496",
  "ENSMUSG00000038497",
  "ENSMUSG00000038498",
  "ENSMUSG00000038500",
  "ENSMUSG00000038502",
  "ENSMUSG00000038503",
  "ENSMUSG00000038506",
  "ENSMUSG00000038507",
  "ENSMUSG00000038508",
  "ENSMUSG00000038510",
  "ENSMUSG00000038515",
  "ENSMUSG00000038517",
  "ENSMUSG00000038518",
  "ENSMUSG00000038520",
  "ENSMUSG00000038521",
  "ENSMUSG00000038522",
  "ENSMUSG00000038523",
  "ENSMUSG00000038524",
  "ENSMUSG00000038525",
  "ENSMUSG00000038526",
  "ENSMUSG00000038527",
  "ENSMUSG00000038528",
  "ENSMUSG00000038530",
  "ENSMUSG00000038533",
  "ENSMUSG00000038534",
  "ENSMUSG00000038535",
  "ENSMUSG00000038537",
  "ENSMUSG00000038538",
  "ENSMUSG00000038539",
  "ENSMUSG00000038540",
  "ENSMUSG00000038541",
  "ENSMUSG00000038542",
  "ENSMUSG00000038543",
  "ENSMUSG00000038544",
  "ENSMUSG00000038545",
  "ENSMUSG00000038546",
  "ENSMUSG00000038550",
  "ENSMUSG00000038552",
  "ENSMUSG00000038555",
  "ENSMUSG00000038563",
  "ENSMUSG00000038564",
  "ENSMUSG00000038567",
  "ENSMUSG00000038569",
  "ENSMUSG00000038570",
  "ENSMUSG00000038572",
  "ENSMUSG00000038576",
  "ENSMUSG00000038578",
  "ENSMUSG00000038580",
  "ENSMUSG00000038582",
  "ENSMUSG00000038583",
  "ENSMUSG00000038587",
  "ENSMUSG00000038591",
  "ENSMUSG00000038593",
  "ENSMUSG00000038594",
  "ENSMUSG00000038598",
  "ENSMUSG00000038599",
  "ENSMUSG00000038600",
  "ENSMUSG00000038602",
  "ENSMUSG00000038604",
  "ENSMUSG00000038605",
  "ENSMUSG00000038608",
  "ENSMUSG00000038611",
  "ENSMUSG00000038612",
  "ENSMUSG00000038615",
  "ENSMUSG00000038618",
  "ENSMUSG00000038619",
  "ENSMUSG00000038622",
  "ENSMUSG00000038623",
  "ENSMUSG00000038624",
  "ENSMUSG00000038628",
  "ENSMUSG00000038630",
  "ENSMUSG00000038633",
  "ENSMUSG00000038637",
  "ENSMUSG00000038641",
  "ENSMUSG00000038642",
  "ENSMUSG00000038644",
  "ENSMUSG00000038648",
  "ENSMUSG00000038650",
  "ENSMUSG00000038651",
  "ENSMUSG00000038656",
  "ENSMUSG00000038658",
  "ENSMUSG00000038663",
  "ENSMUSG00000038664",
  "ENSMUSG00000038665",
  "ENSMUSG00000038668",
  "ENSMUSG00000038670",
  "ENSMUSG00000038671",
  "ENSMUSG00000038677",
  "ENSMUSG00000038679",
  "ENSMUSG00000038683",
  "ENSMUSG00000038685",
  "ENSMUSG00000038690",
  "ENSMUSG00000038691",
  "ENSMUSG00000038692",
  "ENSMUSG00000038695",
  "ENSMUSG00000038696",
  "ENSMUSG00000038697",
  "ENSMUSG00000038700",
  "ENSMUSG00000038702",
  "ENSMUSG00000038704",
  "ENSMUSG00000038705",
  "ENSMUSG00000038708",
  "ENSMUSG00000038709",
  "ENSMUSG00000038712",
  "ENSMUSG00000038717",
  "ENSMUSG00000038718",
  "ENSMUSG00000038721",
  "ENSMUSG00000038722",
  "ENSMUSG00000038725",
  "ENSMUSG00000038732",
  "ENSMUSG00000038733",
  "ENSMUSG00000038736",
  "ENSMUSG00000038738",
  "ENSMUSG00000038740",
  "ENSMUSG00000038742",
  "ENSMUSG00000038745",
  "ENSMUSG00000038750",
  "ENSMUSG00000038751",
  "ENSMUSG00000038754",
  "ENSMUSG00000038756",
  "ENSMUSG00000038759",
  "ENSMUSG00000038760",
  "ENSMUSG00000038762",
  "ENSMUSG00000038763",
  "ENSMUSG00000038764",
  "ENSMUSG00000038765",
  "ENSMUSG00000038766",
  "ENSMUSG00000038768",
  "ENSMUSG00000038770",
  "ENSMUSG00000038773",
  "ENSMUSG00000038774",
  "ENSMUSG00000038775",
  "ENSMUSG00000038776",
  "ENSMUSG00000038777",
  "ENSMUSG00000038780",
  "ENSMUSG00000038781",
  "ENSMUSG00000038782",
  "ENSMUSG00000038784",
  "ENSMUSG00000038791",
  "ENSMUSG00000038793",
  "ENSMUSG00000038797",
  "ENSMUSG00000038801",
  "ENSMUSG00000038803",
  "ENSMUSG00000038805",
  "ENSMUSG00000038806",
  "ENSMUSG00000038807",
  "ENSMUSG00000038811",
  "ENSMUSG00000038812",
  "ENSMUSG00000038816",
  "ENSMUSG00000038822",
  "ENSMUSG00000038828",
  "ENSMUSG00000038831",
  "ENSMUSG00000038836",
  "ENSMUSG00000038838",
  "ENSMUSG00000038840",
  "ENSMUSG00000038843",
  "ENSMUSG00000038844",
  "ENSMUSG00000038845",
  "ENSMUSG00000038848",
  "ENSMUSG00000038855",
  "ENSMUSG00000038859",
  "ENSMUSG00000038860",
  "ENSMUSG00000038861",
  "ENSMUSG00000038866",
  "ENSMUSG00000038871",
  "ENSMUSG00000038872",
  "ENSMUSG00000038876",
  "ENSMUSG00000038879",
  "ENSMUSG00000038880",
  "ENSMUSG00000038883",
  "ENSMUSG00000038884",
  "ENSMUSG00000038886",
  "ENSMUSG00000038888",
  "ENSMUSG00000038891",
  "ENSMUSG00000038893",
  "ENSMUSG00000038894",
  "ENSMUSG00000038895",
  "ENSMUSG00000038902",
  "ENSMUSG00000038903",
  "ENSMUSG00000038909",
  "ENSMUSG00000038910",
  "ENSMUSG00000038914",
  "ENSMUSG00000038916",
  "ENSMUSG00000038917",
  "ENSMUSG00000038925",
  "ENSMUSG00000038930",
  "ENSMUSG00000038932",
  "ENSMUSG00000038936",
  "ENSMUSG00000038943",
  "ENSMUSG00000038949",
  "ENSMUSG00000038954",
  "ENSMUSG00000038957",
  "ENSMUSG00000038963",
  "ENSMUSG00000038965",
  "ENSMUSG00000038967",
  "ENSMUSG00000038968",
  "ENSMUSG00000038970",
  "ENSMUSG00000038973",
  "ENSMUSG00000038975",
  "ENSMUSG00000038976",
  "ENSMUSG00000038980",
  "ENSMUSG00000038982",
  "ENSMUSG00000038984",
  "ENSMUSG00000038987",
  "ENSMUSG00000038990",
  "ENSMUSG00000038991",
  "ENSMUSG00000038994",
  "ENSMUSG00000038997",
  "ENSMUSG00000039000",
  "ENSMUSG00000039001",
  "ENSMUSG00000039004",
  "ENSMUSG00000039005",
  "ENSMUSG00000039007",
  "ENSMUSG00000039013",
  "ENSMUSG00000039016",
  "ENSMUSG00000039018",
  "ENSMUSG00000039021",
  "ENSMUSG00000039031",
  "ENSMUSG00000039032",
  "ENSMUSG00000039033",
  "ENSMUSG00000039037",
  "ENSMUSG00000039043",
  "ENSMUSG00000039046",
  "ENSMUSG00000039047",
  "ENSMUSG00000039048",
  "ENSMUSG00000039050",
  "ENSMUSG00000039055",
  "ENSMUSG00000039057",
  "ENSMUSG00000039058",
  "ENSMUSG00000039059",
  "ENSMUSG00000039062",
  "ENSMUSG00000039063",
  "ENSMUSG00000039065",
  "ENSMUSG00000039067",
  "ENSMUSG00000039068",
  "ENSMUSG00000039069",
  "ENSMUSG00000039070",
  "ENSMUSG00000039079",
  "ENSMUSG00000039081",
  "ENSMUSG00000039084",
  "ENSMUSG00000039086",
  "ENSMUSG00000039087",
  "ENSMUSG00000039089",
  "ENSMUSG00000039092",
  "ENSMUSG00000039095",
  "ENSMUSG00000039096",
  "ENSMUSG00000039097",
  "ENSMUSG00000039098",
  "ENSMUSG00000039099",
  "ENSMUSG00000039100",
  "ENSMUSG00000039103",
  "ENSMUSG00000039105",
  "ENSMUSG00000039106",
  "ENSMUSG00000039108",
  "ENSMUSG00000039109",
  "ENSMUSG00000039110",
  "ENSMUSG00000039114",
  "ENSMUSG00000039115",
  "ENSMUSG00000039116",
  "ENSMUSG00000039117",
  "ENSMUSG00000039126",
  "ENSMUSG00000039128",
  "ENSMUSG00000039130",
  "ENSMUSG00000039131",
  "ENSMUSG00000039133",
  "ENSMUSG00000039137",
  "ENSMUSG00000039138",
  "ENSMUSG00000039145",
  "ENSMUSG00000039146",
  "ENSMUSG00000039148",
  "ENSMUSG00000039153",
  "ENSMUSG00000039154",
  "ENSMUSG00000039155",
  "ENSMUSG00000039156",
  "ENSMUSG00000039157",
  "ENSMUSG00000039158",
  "ENSMUSG00000039163",
  "ENSMUSG00000039164",
  "ENSMUSG00000039166",
  "ENSMUSG00000039167",
  "ENSMUSG00000039168",
  "ENSMUSG00000039174",
  "ENSMUSG00000039176",
  "ENSMUSG00000039178",
  "ENSMUSG00000039179",
  "ENSMUSG00000039182",
  "ENSMUSG00000039183",
  "ENSMUSG00000039187",
  "ENSMUSG00000039191",
  "ENSMUSG00000039193",
  "ENSMUSG00000039194",
  "ENSMUSG00000039195",
  "ENSMUSG00000039196",
  "ENSMUSG00000039197",
  "ENSMUSG00000039198",
  "ENSMUSG00000039199",
  "ENSMUSG00000039200",
  "ENSMUSG00000039201",
  "ENSMUSG00000039202",
  "ENSMUSG00000039205",
  "ENSMUSG00000039206",
  "ENSMUSG00000039208",
  "ENSMUSG00000039209",
  "ENSMUSG00000039210",
  "ENSMUSG00000039215",
  "ENSMUSG00000039217",
  "ENSMUSG00000039218",
  "ENSMUSG00000039219",
  "ENSMUSG00000039220",
  "ENSMUSG00000039221",
  "ENSMUSG00000039224",
  "ENSMUSG00000039230",
  "ENSMUSG00000039231",
  "ENSMUSG00000039232",
  "ENSMUSG00000039233",
  "ENSMUSG00000039234",
  "ENSMUSG00000039236",
  "ENSMUSG00000039238",
  "ENSMUSG00000039239",
  "ENSMUSG00000039242",
  "ENSMUSG00000039244",
  "ENSMUSG00000039246",
  "ENSMUSG00000039252",
  "ENSMUSG00000039253",
  "ENSMUSG00000039254",
  "ENSMUSG00000039257",
  "ENSMUSG00000039262",
  "ENSMUSG00000039263",
  "ENSMUSG00000039264",
  "ENSMUSG00000039269",
  "ENSMUSG00000039270",
  "ENSMUSG00000039275",
  "ENSMUSG00000039278",
  "ENSMUSG00000039285",
  "ENSMUSG00000039286",
  "ENSMUSG00000039294",
  "ENSMUSG00000039296",
  "ENSMUSG00000039298",
  "ENSMUSG00000039304",
  "ENSMUSG00000039307",
  "ENSMUSG00000039308",
  "ENSMUSG00000039313",
  "ENSMUSG00000039315",
  "ENSMUSG00000039316",
  "ENSMUSG00000039318",
  "ENSMUSG00000039321",
  "ENSMUSG00000039323",
  "ENSMUSG00000039328",
  "ENSMUSG00000039329",
  "ENSMUSG00000039330",
  "ENSMUSG00000039335",
  "ENSMUSG00000039337",
  "ENSMUSG00000039339",
  "ENSMUSG00000039342",
  "ENSMUSG00000039345",
  "ENSMUSG00000039347",
  "ENSMUSG00000039349",
  "ENSMUSG00000039354",
  "ENSMUSG00000039356",
  "ENSMUSG00000039357",
  "ENSMUSG00000039358",
  "ENSMUSG00000039361",
  "ENSMUSG00000039364",
  "ENSMUSG00000039367",
  "ENSMUSG00000039372",
  "ENSMUSG00000039375",
  "ENSMUSG00000039376",
  "ENSMUSG00000039377",
  "ENSMUSG00000039382",
  "ENSMUSG00000039384",
  "ENSMUSG00000039385",
  "ENSMUSG00000039391",
  "ENSMUSG00000039395",
  "ENSMUSG00000039396",
  "ENSMUSG00000039405",
  "ENSMUSG00000039410",
  "ENSMUSG00000039414",
  "ENSMUSG00000039419",
  "ENSMUSG00000039427",
  "ENSMUSG00000039428",
  "ENSMUSG00000039431",
  "ENSMUSG00000039438",
  "ENSMUSG00000039449",
  "ENSMUSG00000039450",
  "ENSMUSG00000039452",
  "ENSMUSG00000039456",
  "ENSMUSG00000039457",
  "ENSMUSG00000039458",
  "ENSMUSG00000039461",
  "ENSMUSG00000039462",
  "ENSMUSG00000039463",
  "ENSMUSG00000039470",
  "ENSMUSG00000039473",
  "ENSMUSG00000039476",
  "ENSMUSG00000039477",
  "ENSMUSG00000039478",
  "ENSMUSG00000039480",
  "ENSMUSG00000039481",
  "ENSMUSG00000039483",
  "ENSMUSG00000039485",
  "ENSMUSG00000039488",
  "ENSMUSG00000039492",
  "ENSMUSG00000039496",
  "ENSMUSG00000039497",
  "ENSMUSG00000039501",
  "ENSMUSG00000039508",
  "ENSMUSG00000039509",
  "ENSMUSG00000039512",
  "ENSMUSG00000039515",
  "ENSMUSG00000039518",
  "ENSMUSG00000039519",
  "ENSMUSG00000039521",
  "ENSMUSG00000039523",
  "ENSMUSG00000039529",
  "ENSMUSG00000039530",
  "ENSMUSG00000039531",
  "ENSMUSG00000039533",
  "ENSMUSG00000039536",
  "ENSMUSG00000039539",
  "ENSMUSG00000039540",
  "ENSMUSG00000039542",
  "ENSMUSG00000039543",
  "ENSMUSG00000039545",
  "ENSMUSG00000039546",
  "ENSMUSG00000039552",
  "ENSMUSG00000039555",
  "ENSMUSG00000039563",
  "ENSMUSG00000039568",
  "ENSMUSG00000039577",
  "ENSMUSG00000039578",
  "ENSMUSG00000039579",
  "ENSMUSG00000039585",
  "ENSMUSG00000039599",
  "ENSMUSG00000039601",
  "ENSMUSG00000039607",
  "ENSMUSG00000039608",
  "ENSMUSG00000039611",
  "ENSMUSG00000039615",
  "ENSMUSG00000039616",
  "ENSMUSG00000039620",
  "ENSMUSG00000039621",
  "ENSMUSG00000039623",
  "ENSMUSG00000039628",
  "ENSMUSG00000039629",
  "ENSMUSG00000039630",
  "ENSMUSG00000039632",
  "ENSMUSG00000039633",
  "ENSMUSG00000039634",
  "ENSMUSG00000039637",
  "ENSMUSG00000039639",
  "ENSMUSG00000039640",
  "ENSMUSG00000039646",
  "ENSMUSG00000039648",
  "ENSMUSG00000039652",
  "ENSMUSG00000039653",
  "ENSMUSG00000039656",
  "ENSMUSG00000039660",
  "ENSMUSG00000039661",
  "ENSMUSG00000039662",
  "ENSMUSG00000039670",
  "ENSMUSG00000039671",
  "ENSMUSG00000039672",
  "ENSMUSG00000039676",
  "ENSMUSG00000039678",
  "ENSMUSG00000039680",
  "ENSMUSG00000039682",
  "ENSMUSG00000039683",
  "ENSMUSG00000039686",
  "ENSMUSG00000039691",
  "ENSMUSG00000039693",
  "ENSMUSG00000039697",
  "ENSMUSG00000039699",
  "ENSMUSG00000039701",
  "ENSMUSG00000039703",
  "ENSMUSG00000039704",
  "ENSMUSG00000039706",
  "ENSMUSG00000039710",
  "ENSMUSG00000039713",
  "ENSMUSG00000039714",
  "ENSMUSG00000039715",
  "ENSMUSG00000039716",
  "ENSMUSG00000039717",
  "ENSMUSG00000039720",
  "ENSMUSG00000039725",
  "ENSMUSG00000039728",
  "ENSMUSG00000039735",
  "ENSMUSG00000039737",
  "ENSMUSG00000039738",
  "ENSMUSG00000039740",
  "ENSMUSG00000039741",
  "ENSMUSG00000039742",
  "ENSMUSG00000039745",
  "ENSMUSG00000039747",
  "ENSMUSG00000039748",
  "ENSMUSG00000039753",
  "ENSMUSG00000039754",
  "ENSMUSG00000039756",
  "ENSMUSG00000039759",
  "ENSMUSG00000039760",
  "ENSMUSG00000039763",
  "ENSMUSG00000039765",
  "ENSMUSG00000039768",
  "ENSMUSG00000039770",
  "ENSMUSG00000039771",
  "ENSMUSG00000039774",
  "ENSMUSG00000039781",
  "ENSMUSG00000039782",
  "ENSMUSG00000039783",
  "ENSMUSG00000039785",
  "ENSMUSG00000039787",
  "ENSMUSG00000039789",
  "ENSMUSG00000039795",
  "ENSMUSG00000039798",
  "ENSMUSG00000039801",
  "ENSMUSG00000039804",
  "ENSMUSG00000039809",
  "ENSMUSG00000039810",
  "ENSMUSG00000039813",
  "ENSMUSG00000039814",
  "ENSMUSG00000039824",
  "ENSMUSG00000039826",
  "ENSMUSG00000039828",
  "ENSMUSG00000039830",
  "ENSMUSG00000039831",
  "ENSMUSG00000039834",
  "ENSMUSG00000039835",
  "ENSMUSG00000039838",
  "ENSMUSG00000039840",
  "ENSMUSG00000039841",
  "ENSMUSG00000039842",
  "ENSMUSG00000039844",
  "ENSMUSG00000039849",
  "ENSMUSG00000039851",
  "ENSMUSG00000039852",
  "ENSMUSG00000039853",
  "ENSMUSG00000039860",
  "ENSMUSG00000039865",
  "ENSMUSG00000039873",
  "ENSMUSG00000039878",
  "ENSMUSG00000039879",
  "ENSMUSG00000039883",
  "ENSMUSG00000039886",
  "ENSMUSG00000039887",
  "ENSMUSG00000039891",
  "ENSMUSG00000039899",
  "ENSMUSG00000039901",
  "ENSMUSG00000039903",
  "ENSMUSG00000039904",
  "ENSMUSG00000039908",
  "ENSMUSG00000039910",
  "ENSMUSG00000039911",
  "ENSMUSG00000039913",
  "ENSMUSG00000039914",
  "ENSMUSG00000039917",
  "ENSMUSG00000039929",
  "ENSMUSG00000039934",
  "ENSMUSG00000039936",
  "ENSMUSG00000039942",
  "ENSMUSG00000039943",
  "ENSMUSG00000039952",
  "ENSMUSG00000039953",
  "ENSMUSG00000039954",
  "ENSMUSG00000039956",
  "ENSMUSG00000039958",
  "ENSMUSG00000039959",
  "ENSMUSG00000039960",
  "ENSMUSG00000039962",
  "ENSMUSG00000039963",
  "ENSMUSG00000039967",
  "ENSMUSG00000039968",
  "ENSMUSG00000039976",
  "ENSMUSG00000039977",
  "ENSMUSG00000039981",
  "ENSMUSG00000039982",
  "ENSMUSG00000039983",
  "ENSMUSG00000039987",
  "ENSMUSG00000039988",
  "ENSMUSG00000039989",
  "ENSMUSG00000039990",
  "ENSMUSG00000039994",
  "ENSMUSG00000039997",
  "ENSMUSG00000040003",
  "ENSMUSG00000040006",
  "ENSMUSG00000040007",
  "ENSMUSG00000040009",
  "ENSMUSG00000040010",
  "ENSMUSG00000040013",
  "ENSMUSG00000040016",
  "ENSMUSG00000040017",
  "ENSMUSG00000040018",
  "ENSMUSG00000040021",
  "ENSMUSG00000040022",
  "ENSMUSG00000040025",
  "ENSMUSG00000040026",
  "ENSMUSG00000040028",
  "ENSMUSG00000040029",
  "ENSMUSG00000040033",
  "ENSMUSG00000040034",
  "ENSMUSG00000040035",
  "ENSMUSG00000040037",
  "ENSMUSG00000040040",
  "ENSMUSG00000040044",
  "ENSMUSG00000040046",
  "ENSMUSG00000040048",
  "ENSMUSG00000040055",
  "ENSMUSG00000040061",
  "ENSMUSG00000040065",
  "ENSMUSG00000040084",
  "ENSMUSG00000040086",
  "ENSMUSG00000040093",
  "ENSMUSG00000040097",
  "ENSMUSG00000040102",
  "ENSMUSG00000040105",
  "ENSMUSG00000040111",
  "ENSMUSG00000040112",
  "ENSMUSG00000040113",
  "ENSMUSG00000040118",
  "ENSMUSG00000040121",
  "ENSMUSG00000040123",
  "ENSMUSG00000040124",
  "ENSMUSG00000040125",
  "ENSMUSG00000040127",
  "ENSMUSG00000040128",
  "ENSMUSG00000040132",
  "ENSMUSG00000040133",
  "ENSMUSG00000040134",
  "ENSMUSG00000040136",
  "ENSMUSG00000040138",
  "ENSMUSG00000040139",
  "ENSMUSG00000040140",
  "ENSMUSG00000040146",
  "ENSMUSG00000040147",
  "ENSMUSG00000040148",
  "ENSMUSG00000040151",
  "ENSMUSG00000040152",
  "ENSMUSG00000040154",
  "ENSMUSG00000040158",
  "ENSMUSG00000040163",
  "ENSMUSG00000040164",
  "ENSMUSG00000040165",
  "ENSMUSG00000040167",
  "ENSMUSG00000040170",
  "ENSMUSG00000040174",
  "ENSMUSG00000040177",
  "ENSMUSG00000040181",
  "ENSMUSG00000040183",
  "ENSMUSG00000040187",
  "ENSMUSG00000040188",
  "ENSMUSG00000040189",
  "ENSMUSG00000040195",
  "ENSMUSG00000040197",
  "ENSMUSG00000040204",
  "ENSMUSG00000040205",
  "ENSMUSG00000040209",
  "ENSMUSG00000040212",
  "ENSMUSG00000040213",
  "ENSMUSG00000040219",
  "ENSMUSG00000040220",
  "ENSMUSG00000040225",
  "ENSMUSG00000040229",
  "ENSMUSG00000040231",
  "ENSMUSG00000040234",
  "ENSMUSG00000040236",
  "ENSMUSG00000040242",
  "ENSMUSG00000040247",
  "ENSMUSG00000040249",
  "ENSMUSG00000040250",
  "ENSMUSG00000040253",
  "ENSMUSG00000040254",
  "ENSMUSG00000040258",
  "ENSMUSG00000040260",
  "ENSMUSG00000040263",
  "ENSMUSG00000040264",
  "ENSMUSG00000040265",
  "ENSMUSG00000040268",
  "ENSMUSG00000040269",
  "ENSMUSG00000040270",
  "ENSMUSG00000040272",
  "ENSMUSG00000040274",
  "ENSMUSG00000040276",
  "ENSMUSG00000040280",
  "ENSMUSG00000040282",
  "ENSMUSG00000040283",
  "ENSMUSG00000040284",
  "ENSMUSG00000040287",
  "ENSMUSG00000040289",
  "ENSMUSG00000040296",
  "ENSMUSG00000040297",
  "ENSMUSG00000040298",
  "ENSMUSG00000040302",
  "ENSMUSG00000040310",
  "ENSMUSG00000040312",
  "ENSMUSG00000040314",
  "ENSMUSG00000040321",
  "ENSMUSG00000040322",
  "ENSMUSG00000040325",
  "ENSMUSG00000040327",
  "ENSMUSG00000040328",
  "ENSMUSG00000040329",
  "ENSMUSG00000040331",
  "ENSMUSG00000040339",
  "ENSMUSG00000040340",
  "ENSMUSG00000040345",
  "ENSMUSG00000040350",
  "ENSMUSG00000040351",
  "ENSMUSG00000040354",
  "ENSMUSG00000040356",
  "ENSMUSG00000040359",
  "ENSMUSG00000040363",
  "ENSMUSG00000040364",
  "ENSMUSG00000040365",
  "ENSMUSG00000040367",
  "ENSMUSG00000040370",
  "ENSMUSG00000040372",
  "ENSMUSG00000040373",
  "ENSMUSG00000040374",
  "ENSMUSG00000040380",
  "ENSMUSG00000040383",
  "ENSMUSG00000040385",
  "ENSMUSG00000040387",
  "ENSMUSG00000040389",
  "ENSMUSG00000040390",
  "ENSMUSG00000040396",
  "ENSMUSG00000040405",
  "ENSMUSG00000040407",
  "ENSMUSG00000040410",
  "ENSMUSG00000040412",
  "ENSMUSG00000040413",
  "ENSMUSG00000040414",
  "ENSMUSG00000040415",
  "ENSMUSG00000040420",
  "ENSMUSG00000040423",
  "ENSMUSG00000040424",
  "ENSMUSG00000040428",
  "ENSMUSG00000040429",
  "ENSMUSG00000040430",
  "ENSMUSG00000040432",
  "ENSMUSG00000040433",
  "ENSMUSG00000040434",
  "ENSMUSG00000040435",
  "ENSMUSG00000040441",
  "ENSMUSG00000040446",
  "ENSMUSG00000040447",
  "ENSMUSG00000040451",
  "ENSMUSG00000040452",
  "ENSMUSG00000040455",
  "ENSMUSG00000040456",
  "ENSMUSG00000040459",
  "ENSMUSG00000040462",
  "ENSMUSG00000040463",
  "ENSMUSG00000040464",
  "ENSMUSG00000040466",
  "ENSMUSG00000040467",
  "ENSMUSG00000040471",
  "ENSMUSG00000040472",
  "ENSMUSG00000040473",
  "ENSMUSG00000040478",
  "ENSMUSG00000040479",
  "ENSMUSG00000040481",
  "ENSMUSG00000040482",
  "ENSMUSG00000040483",
  "ENSMUSG00000040485",
  "ENSMUSG00000040488",
  "ENSMUSG00000040489",
  "ENSMUSG00000040490",
  "ENSMUSG00000040495",
  "ENSMUSG00000040498",
  "ENSMUSG00000040502",
  "ENSMUSG00000040505",
  "ENSMUSG00000040506",
  "ENSMUSG00000040511",
  "ENSMUSG00000040514",
  "ENSMUSG00000040520",
  "ENSMUSG00000040521",
  "ENSMUSG00000040522",
  "ENSMUSG00000040524",
  "ENSMUSG00000040525",
  "ENSMUSG00000040528",
  "ENSMUSG00000040532",
  "ENSMUSG00000040533",
  "ENSMUSG00000040536",
  "ENSMUSG00000040537",
  "ENSMUSG00000040541",
  "ENSMUSG00000040543",
  "ENSMUSG00000040548",
  "ENSMUSG00000040549",
  "ENSMUSG00000040550",
  "ENSMUSG00000040552",
  "ENSMUSG00000040554",
  "ENSMUSG00000040557",
  "ENSMUSG00000040560",
  "ENSMUSG00000040562",
  "ENSMUSG00000040563",
  "ENSMUSG00000040564",
  "ENSMUSG00000040569",
  "ENSMUSG00000040570",
  "ENSMUSG00000040576",
  "ENSMUSG00000040583",
  "ENSMUSG00000040584",
  "ENSMUSG00000040586",
  "ENSMUSG00000040591",
  "ENSMUSG00000040592",
  "ENSMUSG00000040594",
  "ENSMUSG00000040596",
  "ENSMUSG00000040599",
  "ENSMUSG00000040600",
  "ENSMUSG00000040601",
  "ENSMUSG00000040605",
  "ENSMUSG00000040606",
  "ENSMUSG00000040610",
  "ENSMUSG00000040612",
  "ENSMUSG00000040613",
  "ENSMUSG00000040614",
  "ENSMUSG00000040616",
  "ENSMUSG00000040618",
  "ENSMUSG00000040620",
  "ENSMUSG00000040621",
  "ENSMUSG00000040624",
  "ENSMUSG00000040627",
  "ENSMUSG00000040629",
  "ENSMUSG00000040631",
  "ENSMUSG00000040632",
  "ENSMUSG00000040640",
  "ENSMUSG00000040648",
  "ENSMUSG00000040649",
  "ENSMUSG00000040651",
  "ENSMUSG00000040652",
  "ENSMUSG00000040657",
  "ENSMUSG00000040658",
  "ENSMUSG00000040659",
  "ENSMUSG00000040660",
  "ENSMUSG00000040661",
  "ENSMUSG00000040663",
  "ENSMUSG00000040666",
  "ENSMUSG00000040667",
  "ENSMUSG00000040669",
  "ENSMUSG00000040675",
  "ENSMUSG00000040680",
  "ENSMUSG00000040681",
  "ENSMUSG00000040687",
  "ENSMUSG00000040688",
  "ENSMUSG00000040690",
  "ENSMUSG00000040693",
  "ENSMUSG00000040694",
  "ENSMUSG00000040697",
  "ENSMUSG00000040699",
  "ENSMUSG00000040701",
  "ENSMUSG00000040703",
  "ENSMUSG00000040705",
  "ENSMUSG00000040706",
  "ENSMUSG00000040710",
  "ENSMUSG00000040711",
  "ENSMUSG00000040712",
  "ENSMUSG00000040713",
  "ENSMUSG00000040714",
  "ENSMUSG00000040717",
  "ENSMUSG00000040720",
  "ENSMUSG00000040721",
  "ENSMUSG00000040722",
  "ENSMUSG00000040723",
  "ENSMUSG00000040724",
  "ENSMUSG00000040725",
  "ENSMUSG00000040726",
  "ENSMUSG00000040728",
  "ENSMUSG00000040729",
  "ENSMUSG00000040731",
  "ENSMUSG00000040732",
  "ENSMUSG00000040734",
  "ENSMUSG00000040738",
  "ENSMUSG00000040740",
  "ENSMUSG00000040746",
  "ENSMUSG00000040747",
  "ENSMUSG00000040751",
  "ENSMUSG00000040752",
  "ENSMUSG00000040759",
  "ENSMUSG00000040760",
  "ENSMUSG00000040761",
  "ENSMUSG00000040767",
  "ENSMUSG00000040770",
  "ENSMUSG00000040771",
  "ENSMUSG00000040774",
  "ENSMUSG00000040782",
  "ENSMUSG00000040785",
  "ENSMUSG00000040794",
  "ENSMUSG00000040795",
  "ENSMUSG00000040797",
  "ENSMUSG00000040808",
  "ENSMUSG00000040809",
  "ENSMUSG00000040811",
  "ENSMUSG00000040812",
  "ENSMUSG00000040813",
  "ENSMUSG00000040818",
  "ENSMUSG00000040820",
  "ENSMUSG00000040822",
  "ENSMUSG00000040824",
  "ENSMUSG00000040828",
  "ENSMUSG00000040829",
  "ENSMUSG00000040836",
  "ENSMUSG00000040838",
  "ENSMUSG00000040840",
  "ENSMUSG00000040841",
  "ENSMUSG00000040843",
  "ENSMUSG00000040848",
  "ENSMUSG00000040850",
  "ENSMUSG00000040852",
  "ENSMUSG00000040855",
  "ENSMUSG00000040856",
  "ENSMUSG00000040857",
  "ENSMUSG00000040860",
  "ENSMUSG00000040865",
  "ENSMUSG00000040866",
  "ENSMUSG00000040867",
  "ENSMUSG00000040875",
  "ENSMUSG00000040877",
  "ENSMUSG00000040883",
  "ENSMUSG00000040888",
  "ENSMUSG00000040891",
  "ENSMUSG00000040896",
  "ENSMUSG00000040899",
  "ENSMUSG00000040901",
  "ENSMUSG00000040907",
  "ENSMUSG00000040913",
  "ENSMUSG00000040918",
  "ENSMUSG00000040919",
  "ENSMUSG00000040928",
  "ENSMUSG00000040929",
  "ENSMUSG00000040935",
  "ENSMUSG00000040936",
  "ENSMUSG00000040938",
  "ENSMUSG00000040940",
  "ENSMUSG00000040943",
  "ENSMUSG00000040945",
  "ENSMUSG00000040957",
  "ENSMUSG00000040963",
  "ENSMUSG00000040964",
  "ENSMUSG00000040966",
  "ENSMUSG00000040969",
  "ENSMUSG00000040972",
  "ENSMUSG00000040978",
  "ENSMUSG00000040985",
  "ENSMUSG00000040987",
  "ENSMUSG00000040990",
  "ENSMUSG00000040997",
  "ENSMUSG00000040998",
  "ENSMUSG00000041000",
  "ENSMUSG00000041009",
  "ENSMUSG00000041012",
  "ENSMUSG00000041014",
  "ENSMUSG00000041020",
  "ENSMUSG00000041025",
  "ENSMUSG00000041028",
  "ENSMUSG00000041035",
  "ENSMUSG00000041037",
  "ENSMUSG00000041040",
  "ENSMUSG00000041044",
  "ENSMUSG00000041046",
  "ENSMUSG00000041052",
  "ENSMUSG00000041057",
  "ENSMUSG00000041058",
  "ENSMUSG00000041062",
  "ENSMUSG00000041064",
  "ENSMUSG00000041068",
  "ENSMUSG00000041073",
  "ENSMUSG00000041075",
  "ENSMUSG00000041078",
  "ENSMUSG00000041079",
  "ENSMUSG00000041084",
  "ENSMUSG00000041096",
  "ENSMUSG00000041112",
  "ENSMUSG00000041115",
  "ENSMUSG00000041117",
  "ENSMUSG00000041119",
  "ENSMUSG00000041120",
  "ENSMUSG00000041124",
  "ENSMUSG00000041126",
  "ENSMUSG00000041130",
  "ENSMUSG00000041132",
  "ENSMUSG00000041133",
  "ENSMUSG00000041134",
  "ENSMUSG00000041135",
  "ENSMUSG00000041138",
  "ENSMUSG00000041141",
  "ENSMUSG00000041143",
  "ENSMUSG00000041144",
  "ENSMUSG00000041147",
  "ENSMUSG00000041153",
  "ENSMUSG00000041161",
  "ENSMUSG00000041164",
  "ENSMUSG00000041165",
  "ENSMUSG00000041168",
  "ENSMUSG00000041180",
  "ENSMUSG00000041187",
  "ENSMUSG00000041189",
  "ENSMUSG00000041193",
  "ENSMUSG00000041199",
  "ENSMUSG00000041202",
  "ENSMUSG00000041203",
  "ENSMUSG00000041205",
  "ENSMUSG00000041215",
  "ENSMUSG00000041216",
  "ENSMUSG00000041219",
  "ENSMUSG00000041220",
  "ENSMUSG00000041225",
  "ENSMUSG00000041229",
  "ENSMUSG00000041231",
  "ENSMUSG00000041235",
  "ENSMUSG00000041236",
  "ENSMUSG00000041237",
  "ENSMUSG00000041238",
  "ENSMUSG00000041241",
  "ENSMUSG00000041245",
  "ENSMUSG00000041248",
  "ENSMUSG00000041255",
  "ENSMUSG00000041258",
  "ENSMUSG00000041261",
  "ENSMUSG00000041263",
  "ENSMUSG00000041264",
  "ENSMUSG00000041268",
  "ENSMUSG00000041272",
  "ENSMUSG00000041278",
  "ENSMUSG00000041287",
  "ENSMUSG00000041293",
  "ENSMUSG00000041297",
  "ENSMUSG00000041298",
  "ENSMUSG00000041301",
  "ENSMUSG00000041303",
  "ENSMUSG00000041308",
  "ENSMUSG00000041309",
  "ENSMUSG00000041313",
  "ENSMUSG00000041319",
  "ENSMUSG00000041323",
  "ENSMUSG00000041324",
  "ENSMUSG00000041328",
  "ENSMUSG00000041329",
  "ENSMUSG00000041333",
  "ENSMUSG00000041343",
  "ENSMUSG00000041346",
  "ENSMUSG00000041347",
  "ENSMUSG00000041351",
  "ENSMUSG00000041353",
  "ENSMUSG00000041354",
  "ENSMUSG00000041355",
  "ENSMUSG00000041358",
  "ENSMUSG00000041359",
  "ENSMUSG00000041360",
  "ENSMUSG00000041361",
  "ENSMUSG00000041362",
  "ENSMUSG00000041372",
  "ENSMUSG00000041375",
  "ENSMUSG00000041377",
  "ENSMUSG00000041378",
  "ENSMUSG00000041380",
  "ENSMUSG00000041390",
  "ENSMUSG00000041396",
  "ENSMUSG00000041399",
  "ENSMUSG00000041406",
  "ENSMUSG00000041408",
  "ENSMUSG00000041415",
  "ENSMUSG00000041417",
  "ENSMUSG00000041420",
  "ENSMUSG00000041423",
  "ENSMUSG00000041426",
  "ENSMUSG00000041429",
  "ENSMUSG00000041431",
  "ENSMUSG00000041438",
  "ENSMUSG00000041439",
  "ENSMUSG00000041440",
  "ENSMUSG00000041444",
  "ENSMUSG00000041445",
  "ENSMUSG00000041453",
  "ENSMUSG00000041459",
  "ENSMUSG00000041460",
  "ENSMUSG00000041468",
  "ENSMUSG00000041471",
  "ENSMUSG00000041476",
  "ENSMUSG00000041477",
  "ENSMUSG00000041479",
  "ENSMUSG00000041481",
  "ENSMUSG00000041482",
  "ENSMUSG00000041483",
  "ENSMUSG00000041488",
  "ENSMUSG00000041491",
  "ENSMUSG00000041498",
  "ENSMUSG00000041506",
  "ENSMUSG00000041515",
  "ENSMUSG00000041523",
  "ENSMUSG00000041528",
  "ENSMUSG00000041530",
  "ENSMUSG00000041534",
  "ENSMUSG00000041536",
  "ENSMUSG00000041538",
  "ENSMUSG00000041540",
  "ENSMUSG00000041544",
  "ENSMUSG00000041548",
  "ENSMUSG00000041550",
  "ENSMUSG00000041552",
  "ENSMUSG00000041556",
  "ENSMUSG00000041559",
  "ENSMUSG00000041560",
  "ENSMUSG00000041565",
  "ENSMUSG00000041566",
  "ENSMUSG00000041567",
  "ENSMUSG00000041570",
  "ENSMUSG00000041571",
  "ENSMUSG00000041578",
  "ENSMUSG00000041583",
  "ENSMUSG00000041592",
  "ENSMUSG00000041594",
  "ENSMUSG00000041598",
  "ENSMUSG00000041605",
  "ENSMUSG00000041607",
  "ENSMUSG00000041608",
  "ENSMUSG00000041609",
  "ENSMUSG00000041616",
  "ENSMUSG00000041617",
  "ENSMUSG00000041623",
  "ENSMUSG00000041624",
  "ENSMUSG00000041625",
  "ENSMUSG00000041629",
  "ENSMUSG00000041632",
  "ENSMUSG00000041633",
  "ENSMUSG00000041638",
  "ENSMUSG00000041642",
  "ENSMUSG00000041644",
  "ENSMUSG00000041645",
  "ENSMUSG00000041649",
  "ENSMUSG00000041650",
  "ENSMUSG00000041654",
  "ENSMUSG00000041658",
  "ENSMUSG00000041660",
  "ENSMUSG00000041669",
  "ENSMUSG00000041670",
  "ENSMUSG00000041671",
  "ENSMUSG00000041673",
  "ENSMUSG00000041674",
  "ENSMUSG00000041679",
  "ENSMUSG00000041681",
  "ENSMUSG00000041684",
  "ENSMUSG00000041685",
  "ENSMUSG00000041688",
  "ENSMUSG00000041695",
  "ENSMUSG00000041696",
  "ENSMUSG00000041697",
  "ENSMUSG00000041698",
  "ENSMUSG00000041700",
  "ENSMUSG00000041702",
  "ENSMUSG00000041703",
  "ENSMUSG00000041707",
  "ENSMUSG00000041708",
  "ENSMUSG00000041710",
  "ENSMUSG00000041712",
  "ENSMUSG00000041718",
  "ENSMUSG00000041720",
  "ENSMUSG00000041729",
  "ENSMUSG00000041730",
  "ENSMUSG00000041731",
  "ENSMUSG00000041733",
  "ENSMUSG00000041734",
  "ENSMUSG00000041735",
  "ENSMUSG00000041736",
  "ENSMUSG00000041737",
  "ENSMUSG00000041740",
  "ENSMUSG00000041741",
  "ENSMUSG00000041747",
  "ENSMUSG00000041754",
  "ENSMUSG00000041757",
  "ENSMUSG00000041762",
  "ENSMUSG00000041763",
  "ENSMUSG00000041765",
  "ENSMUSG00000041769",
  "ENSMUSG00000041771",
  "ENSMUSG00000041773",
  "ENSMUSG00000041774",
  "ENSMUSG00000041775",
  "ENSMUSG00000041779",
  "ENSMUSG00000041781",
  "ENSMUSG00000041782",
  "ENSMUSG00000041789",
  "ENSMUSG00000041791",
  "ENSMUSG00000041794",
  "ENSMUSG00000041797",
  "ENSMUSG00000041798",
  "ENSMUSG00000041801",
  "ENSMUSG00000041805",
  "ENSMUSG00000041809",
  "ENSMUSG00000041815",
  "ENSMUSG00000041817",
  "ENSMUSG00000041827",
  "ENSMUSG00000041828",
  "ENSMUSG00000041831",
  "ENSMUSG00000041836",
  "ENSMUSG00000041837",
  "ENSMUSG00000041840",
  "ENSMUSG00000041842",
  "ENSMUSG00000041845",
  "ENSMUSG00000041846",
  "ENSMUSG00000041849",
  "ENSMUSG00000041852",
  "ENSMUSG00000041857",
  "ENSMUSG00000041859",
  "ENSMUSG00000041870",
  "ENSMUSG00000041872",
  "ENSMUSG00000041878",
  "ENSMUSG00000041881",
  "ENSMUSG00000041886",
  "ENSMUSG00000041889",
  "ENSMUSG00000041891",
  "ENSMUSG00000041895",
  "ENSMUSG00000041907",
  "ENSMUSG00000041911",
  "ENSMUSG00000041912",
  "ENSMUSG00000041915",
  "ENSMUSG00000041920",
  "ENSMUSG00000041921",
  "ENSMUSG00000041923",
  "ENSMUSG00000041926",
  "ENSMUSG00000041930",
  "ENSMUSG00000041935",
  "ENSMUSG00000041936",
  "ENSMUSG00000041939",
  "ENSMUSG00000041945",
  "ENSMUSG00000041949",
  "ENSMUSG00000041954",
  "ENSMUSG00000041957",
  "ENSMUSG00000041958",
  "ENSMUSG00000041959",
  "ENSMUSG00000041961",
  "ENSMUSG00000041966",
  "ENSMUSG00000041974",
  "ENSMUSG00000041975",
  "ENSMUSG00000041977",
  "ENSMUSG00000041984",
  "ENSMUSG00000041986",
  "ENSMUSG00000041991",
  "ENSMUSG00000041992",
  "ENSMUSG00000041995",
  "ENSMUSG00000041997",
  "ENSMUSG00000042002",
  "ENSMUSG00000042010",
  "ENSMUSG00000042015",
  "ENSMUSG00000042029",
  "ENSMUSG00000042031",
  "ENSMUSG00000042032",
  "ENSMUSG00000042035",
  "ENSMUSG00000042041",
  "ENSMUSG00000042042",
  "ENSMUSG00000042043",
  "ENSMUSG00000042045",
  "ENSMUSG00000042046",
  "ENSMUSG00000042050",
  "ENSMUSG00000042055",
  "ENSMUSG00000042063",
  "ENSMUSG00000042064",
  "ENSMUSG00000042066",
  "ENSMUSG00000042073",
  "ENSMUSG00000042078",
  "ENSMUSG00000042079",
  "ENSMUSG00000042082",
  "ENSMUSG00000042092",
  "ENSMUSG00000042096",
  "ENSMUSG00000042097",
  "ENSMUSG00000042099",
  "ENSMUSG00000042102",
  "ENSMUSG00000042104",
  "ENSMUSG00000042105",
  "ENSMUSG00000042106",
  "ENSMUSG00000042109",
  "ENSMUSG00000042111",
  "ENSMUSG00000042115",
  "ENSMUSG00000042116",
  "ENSMUSG00000042118",
  "ENSMUSG00000042121",
  "ENSMUSG00000042124",
  "ENSMUSG00000042129",
  "ENSMUSG00000042133",
  "ENSMUSG00000042138",
  "ENSMUSG00000042148",
  "ENSMUSG00000042155",
  "ENSMUSG00000042156",
  "ENSMUSG00000042157",
  "ENSMUSG00000042165",
  "ENSMUSG00000042167",
  "ENSMUSG00000042178",
  "ENSMUSG00000042179",
  "ENSMUSG00000042182",
  "ENSMUSG00000042184",
  "ENSMUSG00000042185",
  "ENSMUSG00000042189",
  "ENSMUSG00000042190",
  "ENSMUSG00000042195",
  "ENSMUSG00000042197",
  "ENSMUSG00000042198",
  "ENSMUSG00000042200",
  "ENSMUSG00000042202",
  "ENSMUSG00000042203",
  "ENSMUSG00000042207",
  "ENSMUSG00000042208",
  "ENSMUSG00000042210",
  "ENSMUSG00000042211",
  "ENSMUSG00000042212",
  "ENSMUSG00000042213",
  "ENSMUSG00000042215",
  "ENSMUSG00000042216",
  "ENSMUSG00000042219",
  "ENSMUSG00000042225",
  "ENSMUSG00000042228",
  "ENSMUSG00000042229",
  "ENSMUSG00000042240",
  "ENSMUSG00000042243",
  "ENSMUSG00000042244",
  "ENSMUSG00000042246",
  "ENSMUSG00000042248",
  "ENSMUSG00000042249",
  "ENSMUSG00000042250",
  "ENSMUSG00000042251",
  "ENSMUSG00000042254",
  "ENSMUSG00000042256",
  "ENSMUSG00000042258",
  "ENSMUSG00000042262",
  "ENSMUSG00000042268",
  "ENSMUSG00000042269",
  "ENSMUSG00000042271",
  "ENSMUSG00000042272",
  "ENSMUSG00000042279",
  "ENSMUSG00000042282",
  "ENSMUSG00000042284",
  "ENSMUSG00000042286",
  "ENSMUSG00000042292",
  "ENSMUSG00000042293",
  "ENSMUSG00000042298",
  "ENSMUSG00000042302",
  "ENSMUSG00000042303",
  "ENSMUSG00000042305",
  "ENSMUSG00000042306",
  "ENSMUSG00000042308",
  "ENSMUSG00000042312",
  "ENSMUSG00000042320",
  "ENSMUSG00000042323",
  "ENSMUSG00000042328",
  "ENSMUSG00000042331",
  "ENSMUSG00000042333",
  "ENSMUSG00000042340",
  "ENSMUSG00000042345",
  "ENSMUSG00000042348",
  "ENSMUSG00000042349",
  "ENSMUSG00000042350",
  "ENSMUSG00000042351",
  "ENSMUSG00000042353",
  "ENSMUSG00000042354",
  "ENSMUSG00000042357",
  "ENSMUSG00000042359",
  "ENSMUSG00000042360",
  "ENSMUSG00000042363",
  "ENSMUSG00000042367",
  "ENSMUSG00000042369",
  "ENSMUSG00000042371",
  "ENSMUSG00000042372",
  "ENSMUSG00000042377",
  "ENSMUSG00000042379",
  "ENSMUSG00000042380",
  "ENSMUSG00000042385",
  "ENSMUSG00000042386",
  "ENSMUSG00000042388",
  "ENSMUSG00000042390",
  "ENSMUSG00000042396",
  "ENSMUSG00000042401",
  "ENSMUSG00000042404",
  "ENSMUSG00000042406",
  "ENSMUSG00000042410",
  "ENSMUSG00000042414",
  "ENSMUSG00000042417",
  "ENSMUSG00000042419",
  "ENSMUSG00000042423",
  "ENSMUSG00000042425",
  "ENSMUSG00000042426",
  "ENSMUSG00000042428",
  "ENSMUSG00000042429",
  "ENSMUSG00000042433",
  "ENSMUSG00000042436",
  "ENSMUSG00000042439",
  "ENSMUSG00000042444",
  "ENSMUSG00000042446",
  "ENSMUSG00000042447",
  "ENSMUSG00000042448",
  "ENSMUSG00000042451",
  "ENSMUSG00000042453",
  "ENSMUSG00000042459",
  "ENSMUSG00000042460",
  "ENSMUSG00000042462",
  "ENSMUSG00000042472",
  "ENSMUSG00000042473",
  "ENSMUSG00000042474",
  "ENSMUSG00000042476",
  "ENSMUSG00000042477",
  "ENSMUSG00000042485",
  "ENSMUSG00000042487",
  "ENSMUSG00000042489",
  "ENSMUSG00000042492",
  "ENSMUSG00000042496",
  "ENSMUSG00000042498",
  "ENSMUSG00000042499",
  "ENSMUSG00000042500",
  "ENSMUSG00000042501",
  "ENSMUSG00000042502",
  "ENSMUSG00000042505",
  "ENSMUSG00000042506",
  "ENSMUSG00000042507",
  "ENSMUSG00000042508",
  "ENSMUSG00000042510",
  "ENSMUSG00000042514",
  "ENSMUSG00000042515",
  "ENSMUSG00000042523",
  "ENSMUSG00000042524",
  "ENSMUSG00000042525",
  "ENSMUSG00000042529",
  "ENSMUSG00000042532",
  "ENSMUSG00000042535",
  "ENSMUSG00000042540",
  "ENSMUSG00000042541",
  "ENSMUSG00000042548",
  "ENSMUSG00000042549",
  "ENSMUSG00000042554",
  "ENSMUSG00000042557",
  "ENSMUSG00000042558",
  "ENSMUSG00000042564",
  "ENSMUSG00000042567",
  "ENSMUSG00000042569",
  "ENSMUSG00000042570",
  "ENSMUSG00000042572",
  "ENSMUSG00000042579",
  "ENSMUSG00000042581",
  "ENSMUSG00000042589",
  "ENSMUSG00000042590",
  "ENSMUSG00000042594",
  "ENSMUSG00000042595",
  "ENSMUSG00000042596",
  "ENSMUSG00000042599",
  "ENSMUSG00000042605",
  "ENSMUSG00000042606",
  "ENSMUSG00000042607",
  "ENSMUSG00000042608",
  "ENSMUSG00000042613",
  "ENSMUSG00000042616",
  "ENSMUSG00000042622",
  "ENSMUSG00000042625",
  "ENSMUSG00000042626",
  "ENSMUSG00000042628",
  "ENSMUSG00000042631",
  "ENSMUSG00000042632",
  "ENSMUSG00000042638",
  "ENSMUSG00000042641",
  "ENSMUSG00000042642",
  "ENSMUSG00000042644",
  "ENSMUSG00000042647",
  "ENSMUSG00000042650",
  "ENSMUSG00000042655",
  "ENSMUSG00000042659",
  "ENSMUSG00000042660",
  "ENSMUSG00000042662",
  "ENSMUSG00000042670",
  "ENSMUSG00000042671",
  "ENSMUSG00000042672",
  "ENSMUSG00000042675",
  "ENSMUSG00000042677",
  "ENSMUSG00000042678",
  "ENSMUSG00000042680",
  "ENSMUSG00000042682",
  "ENSMUSG00000042684",
  "ENSMUSG00000042686",
  "ENSMUSG00000042688",
  "ENSMUSG00000042694",
  "ENSMUSG00000042699",
  "ENSMUSG00000042700",
  "ENSMUSG00000042705",
  "ENSMUSG00000042707",
  "ENSMUSG00000042708",
  "ENSMUSG00000042709",
  "ENSMUSG00000042712",
  "ENSMUSG00000042717",
  "ENSMUSG00000042719",
  "ENSMUSG00000042724",
  "ENSMUSG00000042726",
  "ENSMUSG00000042729",
  "ENSMUSG00000042734",
  "ENSMUSG00000042737",
  "ENSMUSG00000042742",
  "ENSMUSG00000042743",
  "ENSMUSG00000042744",
  "ENSMUSG00000042745",
  "ENSMUSG00000042747",
  "ENSMUSG00000042750",
  "ENSMUSG00000042751",
  "ENSMUSG00000042757",
  "ENSMUSG00000042759",
  "ENSMUSG00000042761",
  "ENSMUSG00000042763",
  "ENSMUSG00000042766",
  "ENSMUSG00000042770",
  "ENSMUSG00000042772",
  "ENSMUSG00000042774",
  "ENSMUSG00000042784",
  "ENSMUSG00000042787",
  "ENSMUSG00000042788",
  "ENSMUSG00000042790",
  "ENSMUSG00000042793",
  "ENSMUSG00000042796",
  "ENSMUSG00000042797",
  "ENSMUSG00000042800",
  "ENSMUSG00000042801",
  "ENSMUSG00000042804",
  "ENSMUSG00000042807",
  "ENSMUSG00000042808",
  "ENSMUSG00000042810",
  "ENSMUSG00000042812",
  "ENSMUSG00000042814",
  "ENSMUSG00000042816",
  "ENSMUSG00000042817",
  "ENSMUSG00000042821",
  "ENSMUSG00000042826",
  "ENSMUSG00000042831",
  "ENSMUSG00000042834",
  "ENSMUSG00000042842",
  "ENSMUSG00000042845",
  "ENSMUSG00000042846",
  "ENSMUSG00000042848",
  "ENSMUSG00000042849",
  "ENSMUSG00000042851",
  "ENSMUSG00000042854",
  "ENSMUSG00000042857",
  "ENSMUSG00000042861",
  "ENSMUSG00000042863",
  "ENSMUSG00000042869",
  "ENSMUSG00000042870",
  "ENSMUSG00000042873",
  "ENSMUSG00000042874",
  "ENSMUSG00000042888",
  "ENSMUSG00000042894",
  "ENSMUSG00000042895",
  "ENSMUSG00000042901",
  "ENSMUSG00000042903",
  "ENSMUSG00000042909",
  "ENSMUSG00000042918",
  "ENSMUSG00000042942",
  "ENSMUSG00000042961",
  "ENSMUSG00000042976",
  "ENSMUSG00000042978",
  "ENSMUSG00000042985",
  "ENSMUSG00000042988",
  "ENSMUSG00000042992",
  "ENSMUSG00000042993",
  "ENSMUSG00000042997",
  "ENSMUSG00000043003",
  "ENSMUSG00000043004",
  "ENSMUSG00000043008",
  "ENSMUSG00000043013",
  "ENSMUSG00000043015",
  "ENSMUSG00000043017",
  "ENSMUSG00000043019",
  "ENSMUSG00000043020",
  "ENSMUSG00000043029",
  "ENSMUSG00000043036",
  "ENSMUSG00000043050",
  "ENSMUSG00000043051",
  "ENSMUSG00000043059",
  "ENSMUSG00000043060",
  "ENSMUSG00000043061",
  "ENSMUSG00000043065",
  "ENSMUSG00000043066",
  "ENSMUSG00000043067",
  "ENSMUSG00000043068",
  "ENSMUSG00000043073",
  "ENSMUSG00000043079",
  "ENSMUSG00000043085",
  "ENSMUSG00000043087",
  "ENSMUSG00000043088",
  "ENSMUSG00000043090",
  "ENSMUSG00000043091",
  "ENSMUSG00000043099",
  "ENSMUSG00000043102",
  "ENSMUSG00000043110",
  "ENSMUSG00000043119",
  "ENSMUSG00000043122",
  "ENSMUSG00000043126",
  "ENSMUSG00000043131",
  "ENSMUSG00000043140",
  "ENSMUSG00000043144",
  "ENSMUSG00000043145",
  "ENSMUSG00000043153",
  "ENSMUSG00000043154",
  "ENSMUSG00000043155",
  "ENSMUSG00000043157",
  "ENSMUSG00000043162",
  "ENSMUSG00000043164",
  "ENSMUSG00000043165",
  "ENSMUSG00000043168",
  "ENSMUSG00000043183",
  "ENSMUSG00000043190",
  "ENSMUSG00000043207",
  "ENSMUSG00000043219",
  "ENSMUSG00000043226",
  "ENSMUSG00000043230",
  "ENSMUSG00000043241",
  "ENSMUSG00000043243",
  "ENSMUSG00000043251",
  "ENSMUSG00000043252",
  "ENSMUSG00000043257",
  "ENSMUSG00000043259",
  "ENSMUSG00000043262",
  "ENSMUSG00000043263",
  "ENSMUSG00000043267",
  "ENSMUSG00000043274",
  "ENSMUSG00000043279",
  "ENSMUSG00000043282",
  "ENSMUSG00000043284",
  "ENSMUSG00000043286",
  "ENSMUSG00000043289",
  "ENSMUSG00000043290",
  "ENSMUSG00000043298",
  "ENSMUSG00000043300",
  "ENSMUSG00000043301",
  "ENSMUSG00000043308",
  "ENSMUSG00000043310",
  "ENSMUSG00000043311",
  "ENSMUSG00000043312",
  "ENSMUSG00000043313",
  "ENSMUSG00000043314",
  "ENSMUSG00000043319",
  "ENSMUSG00000043323",
  "ENSMUSG00000043331",
  "ENSMUSG00000043333",
  "ENSMUSG00000043336",
  "ENSMUSG00000043340",
  "ENSMUSG00000043342",
  "ENSMUSG00000043354",
  "ENSMUSG00000043357",
  "ENSMUSG00000043366",
  "ENSMUSG00000043372",
  "ENSMUSG00000043383",
  "ENSMUSG00000043384",
  "ENSMUSG00000043385",
  "ENSMUSG00000043388",
  "ENSMUSG00000043391",
  "ENSMUSG00000043398",
  "ENSMUSG00000043410",
  "ENSMUSG00000043411",
  "ENSMUSG00000043415",
  "ENSMUSG00000043418",
  "ENSMUSG00000043419",
  "ENSMUSG00000043421",
  "ENSMUSG00000043429",
  "ENSMUSG00000043430",
  "ENSMUSG00000043432",
  "ENSMUSG00000043439",
  "ENSMUSG00000043441",
  "ENSMUSG00000043445",
  "ENSMUSG00000043448",
  "ENSMUSG00000043453",
  "ENSMUSG00000043456",
  "ENSMUSG00000043458",
  "ENSMUSG00000043460",
  "ENSMUSG00000043461",
  "ENSMUSG00000043463",
  "ENSMUSG00000043467",
  "ENSMUSG00000043468",
  "ENSMUSG00000043485",
  "ENSMUSG00000043487",
  "ENSMUSG00000043488",
  "ENSMUSG00000043496",
  "ENSMUSG00000043501",
  "ENSMUSG00000043505",
  "ENSMUSG00000043510",
  "ENSMUSG00000043518",
  "ENSMUSG00000043529",
  "ENSMUSG00000043531",
  "ENSMUSG00000043535",
  "ENSMUSG00000043537",
  "ENSMUSG00000043541",
  "ENSMUSG00000043542",
  "ENSMUSG00000043549",
  "ENSMUSG00000043556",
  "ENSMUSG00000043557",
  "ENSMUSG00000043569",
  "ENSMUSG00000043572",
  "ENSMUSG00000043583",
  "ENSMUSG00000043587",
  "ENSMUSG00000043592",
  "ENSMUSG00000043602",
  "ENSMUSG00000043605",
  "ENSMUSG00000043613",
  "ENSMUSG00000043614",
  "ENSMUSG00000043621",
  "ENSMUSG00000043629",
  "ENSMUSG00000043631",
  "ENSMUSG00000043633",
  "ENSMUSG00000043635",
  "ENSMUSG00000043639",
  "ENSMUSG00000043644",
  "ENSMUSG00000043648",
  "ENSMUSG00000043659",
  "ENSMUSG00000043664",
  "ENSMUSG00000043668",
  "ENSMUSG00000043670",
  "ENSMUSG00000043671",
  "ENSMUSG00000043673",
  "ENSMUSG00000043681",
  "ENSMUSG00000043683",
  "ENSMUSG00000043687",
  "ENSMUSG00000043692",
  "ENSMUSG00000043698",
  "ENSMUSG00000043702",
  "ENSMUSG00000043705",
  "ENSMUSG00000043715",
  "ENSMUSG00000043716",
  "ENSMUSG00000043719",
  "ENSMUSG00000043727",
  "ENSMUSG00000043733",
  "ENSMUSG00000043740",
  "ENSMUSG00000043747",
  "ENSMUSG00000043753",
  "ENSMUSG00000043760",
  "ENSMUSG00000043773",
  "ENSMUSG00000043782",
  "ENSMUSG00000043787",
  "ENSMUSG00000043789",
  "ENSMUSG00000043794",
  "ENSMUSG00000043795",
  "ENSMUSG00000043807",
  "ENSMUSG00000043811",
  "ENSMUSG00000043822",
  "ENSMUSG00000043827",
  "ENSMUSG00000043831",
  "ENSMUSG00000043832",
  "ENSMUSG00000043833",
  "ENSMUSG00000043843",
  "ENSMUSG00000043850",
  "ENSMUSG00000043855",
  "ENSMUSG00000043857",
  "ENSMUSG00000043865",
  "ENSMUSG00000043866",
  "ENSMUSG00000043872",
  "ENSMUSG00000043873",
  "ENSMUSG00000043880",
  "ENSMUSG00000043881",
  "ENSMUSG00000043885",
  "ENSMUSG00000043892",
  "ENSMUSG00000043895",
  "ENSMUSG00000043903",
  "ENSMUSG00000043909",
  "ENSMUSG00000043911",
  "ENSMUSG00000043913",
  "ENSMUSG00000043923",
  "ENSMUSG00000043924",
  "ENSMUSG00000043925",
  "ENSMUSG00000043929",
  "ENSMUSG00000043931",
  "ENSMUSG00000043932",
  "ENSMUSG00000043939",
  "ENSMUSG00000043940",
  "ENSMUSG00000043943",
  "ENSMUSG00000043945",
  "ENSMUSG00000043948",
  "ENSMUSG00000043953",
  "ENSMUSG00000043962",
  "ENSMUSG00000043964",
  "ENSMUSG00000043969",
  "ENSMUSG00000043972",
  "ENSMUSG00000043982",
  "ENSMUSG00000043986",
  "ENSMUSG00000043987",
  "ENSMUSG00000043991",
  "ENSMUSG00000043993",
  "ENSMUSG00000043998",
  "ENSMUSG00000043999",
  "ENSMUSG00000044005",
  "ENSMUSG00000044006",
  "ENSMUSG00000044014",
  "ENSMUSG00000044017",
  "ENSMUSG00000044018",
  "ENSMUSG00000044021",
  "ENSMUSG00000044022",
  "ENSMUSG00000044024",
  "ENSMUSG00000044025",
  "ENSMUSG00000044026",
  "ENSMUSG00000044029",
  "ENSMUSG00000044030",
  "ENSMUSG00000044033",
  "ENSMUSG00000044034",
  "ENSMUSG00000044037",
  "ENSMUSG00000044039",
  "ENSMUSG00000044040",
  "ENSMUSG00000044041",
  "ENSMUSG00000044042",
  "ENSMUSG00000044043",
  "ENSMUSG00000044052",
  "ENSMUSG00000044055",
  "ENSMUSG00000044056",
  "ENSMUSG00000044062",
  "ENSMUSG00000044066",
  "ENSMUSG00000044067",
  "ENSMUSG00000044068",
  "ENSMUSG00000044071",
  "ENSMUSG00000044072",
  "ENSMUSG00000044080",
  "ENSMUSG00000044081",
  "ENSMUSG00000044083",
  "ENSMUSG00000044084",
  "ENSMUSG00000044086",
  "ENSMUSG00000044092",
  "ENSMUSG00000044098",
  "ENSMUSG00000044103",
  "ENSMUSG00000044106",
  "ENSMUSG00000044117",
  "ENSMUSG00000044120",
  "ENSMUSG00000044122",
  "ENSMUSG00000044125",
  "ENSMUSG00000044134",
  "ENSMUSG00000044139",
  "ENSMUSG00000044145",
  "ENSMUSG00000044147",
  "ENSMUSG00000044148",
  "ENSMUSG00000044149",
  "ENSMUSG00000044150",
  "ENSMUSG00000044155",
  "ENSMUSG00000044156",
  "ENSMUSG00000044162",
  "ENSMUSG00000044164",
  "ENSMUSG00000044165",
  "ENSMUSG00000044167",
  "ENSMUSG00000044170",
  "ENSMUSG00000044172",
  "ENSMUSG00000044176",
  "ENSMUSG00000044177",
  "ENSMUSG00000044186",
  "ENSMUSG00000044197",
  "ENSMUSG00000044199",
  "ENSMUSG00000044201",
  "ENSMUSG00000044205",
  "ENSMUSG00000044206",
  "ENSMUSG00000044213",
  "ENSMUSG00000044216",
  "ENSMUSG00000044217",
  "ENSMUSG00000044220",
  "ENSMUSG00000044221",
  "ENSMUSG00000044222",
  "ENSMUSG00000044224",
  "ENSMUSG00000044229",
  "ENSMUSG00000044231",
  "ENSMUSG00000044243",
  "ENSMUSG00000044244",
  "ENSMUSG00000044248",
  "ENSMUSG00000044249",
  "ENSMUSG00000044250",
  "ENSMUSG00000044252",
  "ENSMUSG00000044254",
  "ENSMUSG00000044258",
  "ENSMUSG00000044265",
  "ENSMUSG00000044276",
  "ENSMUSG00000044279",
  "ENSMUSG00000044286",
  "ENSMUSG00000044287",
  "ENSMUSG00000044288",
  "ENSMUSG00000044292",
  "ENSMUSG00000044293",
  "ENSMUSG00000044294",
  "ENSMUSG00000044296",
  "ENSMUSG00000044303",
  "ENSMUSG00000044306",
  "ENSMUSG00000044308",
  "ENSMUSG00000044309",
  "ENSMUSG00000044312",
  "ENSMUSG00000044313",
  "ENSMUSG00000044317",
  "ENSMUSG00000044320",
  "ENSMUSG00000044322",
  "ENSMUSG00000044328",
  "ENSMUSG00000044337",
  "ENSMUSG00000044338",
  "ENSMUSG00000044339",
  "ENSMUSG00000044340",
  "ENSMUSG00000044345",
  "ENSMUSG00000044348",
  "ENSMUSG00000044349",
  "ENSMUSG00000044350",
  "ENSMUSG00000044352",
  "ENSMUSG00000044359",
  "ENSMUSG00000044361",
  "ENSMUSG00000044362",
  "ENSMUSG00000044364",
  "ENSMUSG00000044365",
  "ENSMUSG00000044367",
  "ENSMUSG00000044375",
  "ENSMUSG00000044378",
  "ENSMUSG00000044387",
  "ENSMUSG00000044390",
  "ENSMUSG00000044393",
  "ENSMUSG00000044400",
  "ENSMUSG00000044405",
  "ENSMUSG00000044408",
  "ENSMUSG00000044429",
  "ENSMUSG00000044430",
  "ENSMUSG00000044433",
  "ENSMUSG00000044441",
  "ENSMUSG00000044442",
  "ENSMUSG00000044444",
  "ENSMUSG00000044447",
  "ENSMUSG00000044452",
  "ENSMUSG00000044453",
  "ENSMUSG00000044454",
  "ENSMUSG00000044456",
  "ENSMUSG00000044461",
  "ENSMUSG00000044465",
  "ENSMUSG00000044468",
  "ENSMUSG00000044469",
  "ENSMUSG00000044471",
  "ENSMUSG00000044475",
  "ENSMUSG00000044477",
  "ENSMUSG00000044485",
  "ENSMUSG00000044487",
  "ENSMUSG00000044496",
  "ENSMUSG00000044499",
  "ENSMUSG00000044501",
  "ENSMUSG00000044502",
  "ENSMUSG00000044505",
  "ENSMUSG00000044518",
  "ENSMUSG00000044519",
  "ENSMUSG00000044522",
  "ENSMUSG00000044526",
  "ENSMUSG00000044528",
  "ENSMUSG00000044534",
  "ENSMUSG00000044542",
  "ENSMUSG00000044544",
  "ENSMUSG00000044548",
  "ENSMUSG00000044550",
  "ENSMUSG00000044551",
  "ENSMUSG00000044556",
  "ENSMUSG00000044560",
  "ENSMUSG00000044562",
  "ENSMUSG00000044566",
  "ENSMUSG00000044573",
  "ENSMUSG00000044574",
  "ENSMUSG00000044576",
  "ENSMUSG00000044581",
  "ENSMUSG00000044583",
  "ENSMUSG00000044594",
  "ENSMUSG00000044595",
  "ENSMUSG00000044597",
  "ENSMUSG00000044600",
  "ENSMUSG00000044617",
  "ENSMUSG00000044624",
  "ENSMUSG00000044626",
  "ENSMUSG00000044627",
  "ENSMUSG00000044628",
  "ENSMUSG00000044629",
  "ENSMUSG00000044633",
  "ENSMUSG00000044636",
  "ENSMUSG00000044641",
  "ENSMUSG00000044646",
  "ENSMUSG00000044647",
  "ENSMUSG00000044649",
  "ENSMUSG00000044664",
  "ENSMUSG00000044667",
  "ENSMUSG00000044674",
  "ENSMUSG00000044676",
  "ENSMUSG00000044678",
  "ENSMUSG00000044681",
  "ENSMUSG00000044689",
  "ENSMUSG00000044694",
  "ENSMUSG00000044700",
  "ENSMUSG00000044701",
  "ENSMUSG00000044702",
  "ENSMUSG00000044703",
  "ENSMUSG00000044705",
  "ENSMUSG00000044707",
  "ENSMUSG00000044708",
  "ENSMUSG00000044709",
  "ENSMUSG00000044712",
  "ENSMUSG00000044715",
  "ENSMUSG00000044716",
  "ENSMUSG00000044719",
  "ENSMUSG00000044724",
  "ENSMUSG00000044730",
  "ENSMUSG00000044734",
  "ENSMUSG00000044737",
  "ENSMUSG00000044743",
  "ENSMUSG00000044748",
  "ENSMUSG00000044749",
  "ENSMUSG00000044763",
  "ENSMUSG00000044768",
  "ENSMUSG00000044770",
  "ENSMUSG00000044772",
  "ENSMUSG00000044783",
  "ENSMUSG00000044787",
  "ENSMUSG00000044788",
  "ENSMUSG00000044791",
  "ENSMUSG00000044792",
  "ENSMUSG00000044795",
  "ENSMUSG00000044798",
  "ENSMUSG00000044801",
  "ENSMUSG00000044807",
  "ENSMUSG00000044811",
  "ENSMUSG00000044813",
  "ENSMUSG00000044814",
  "ENSMUSG00000044816",
  "ENSMUSG00000044819",
  "ENSMUSG00000044820",
  "ENSMUSG00000044824",
  "ENSMUSG00000044827",
  "ENSMUSG00000044835",
  "ENSMUSG00000044847",
  "ENSMUSG00000044854",
  "ENSMUSG00000044857",
  "ENSMUSG00000044860",
  "ENSMUSG00000044863",
  "ENSMUSG00000044864",
  "ENSMUSG00000044867",
  "ENSMUSG00000044876",
  "ENSMUSG00000044881",
  "ENSMUSG00000044894",
  "ENSMUSG00000044897",
  "ENSMUSG00000044899",
  "ENSMUSG00000044903",
  "ENSMUSG00000044906",
  "ENSMUSG00000044912",
  "ENSMUSG00000044916",
  "ENSMUSG00000044921",
  "ENSMUSG00000044923",
  "ENSMUSG00000044927",
  "ENSMUSG00000044933",
  "ENSMUSG00000044934",
  "ENSMUSG00000044937",
  "ENSMUSG00000044938",
  "ENSMUSG00000044948",
  "ENSMUSG00000044950",
  "ENSMUSG00000044951",
  "ENSMUSG00000044952",
  "ENSMUSG00000044957",
  "ENSMUSG00000044966",
  "ENSMUSG00000044968",
  "ENSMUSG00000044976",
  "ENSMUSG00000044982",
  "ENSMUSG00000044985",
  "ENSMUSG00000044986",
  "ENSMUSG00000044988",
  "ENSMUSG00000044991",
  "ENSMUSG00000044994",
  "ENSMUSG00000044997",
  "ENSMUSG00000045004",
  "ENSMUSG00000045005",
  "ENSMUSG00000045007",
  "ENSMUSG00000045008",
  "ENSMUSG00000045009",
  "ENSMUSG00000045010",
  "ENSMUSG00000045013",
  "ENSMUSG00000045019",
  "ENSMUSG00000045022",
  "ENSMUSG00000045027",
  "ENSMUSG00000045030",
  "ENSMUSG00000045031",
  "ENSMUSG00000045034",
  "ENSMUSG00000045036",
  "ENSMUSG00000045038",
  "ENSMUSG00000045039",
  "ENSMUSG00000045045",
  "ENSMUSG00000045052",
  "ENSMUSG00000045053",
  "ENSMUSG00000045062",
  "ENSMUSG00000045064",
  "ENSMUSG00000045065",
  "ENSMUSG00000045071",
  "ENSMUSG00000045075",
  "ENSMUSG00000045078",
  "ENSMUSG00000045083",
  "ENSMUSG00000045087",
  "ENSMUSG00000045091",
  "ENSMUSG00000045092",
  "ENSMUSG00000045094",
  "ENSMUSG00000045095",
  "ENSMUSG00000045098",
  "ENSMUSG00000045100",
  "ENSMUSG00000045102",
  "ENSMUSG00000045103",
  "ENSMUSG00000045106",
  "ENSMUSG00000045107",
  "ENSMUSG00000045109",
  "ENSMUSG00000045111",
  "ENSMUSG00000045126",
  "ENSMUSG00000045128",
  "ENSMUSG00000045132",
  "ENSMUSG00000045136",
  "ENSMUSG00000045140",
  "ENSMUSG00000045148",
  "ENSMUSG00000045150",
  "ENSMUSG00000045160",
  "ENSMUSG00000045165",
  "ENSMUSG00000045174",
  "ENSMUSG00000045176",
  "ENSMUSG00000045178",
  "ENSMUSG00000045179",
  "ENSMUSG00000045180",
  "ENSMUSG00000045193",
  "ENSMUSG00000045201",
  "ENSMUSG00000045202",
  "ENSMUSG00000045204",
  "ENSMUSG00000045205",
  "ENSMUSG00000045210",
  "ENSMUSG00000045211",
  "ENSMUSG00000045215",
  "ENSMUSG00000045216",
  "ENSMUSG00000045225",
  "ENSMUSG00000045231",
  "ENSMUSG00000045232",
  "ENSMUSG00000045236",
  "ENSMUSG00000045237",
  "ENSMUSG00000045246",
  "ENSMUSG00000045248",
  "ENSMUSG00000045251",
  "ENSMUSG00000045252",
  "ENSMUSG00000045257",
  "ENSMUSG00000045259",
  "ENSMUSG00000045267",
  "ENSMUSG00000045268",
  "ENSMUSG00000045273",
  "ENSMUSG00000045275",
  "ENSMUSG00000045281",
  "ENSMUSG00000045282",
  "ENSMUSG00000045284",
  "ENSMUSG00000045287",
  "ENSMUSG00000045288",
  "ENSMUSG00000045291",
  "ENSMUSG00000045294",
  "ENSMUSG00000045302",
  "ENSMUSG00000045306",
  "ENSMUSG00000045312",
  "ENSMUSG00000045314",
  "ENSMUSG00000045316",
  "ENSMUSG00000045318",
  "ENSMUSG00000045319",
  "ENSMUSG00000045322",
  "ENSMUSG00000045326",
  "ENSMUSG00000045328",
  "ENSMUSG00000045330",
  "ENSMUSG00000045331",
  "ENSMUSG00000045333",
  "ENSMUSG00000045336",
  "ENSMUSG00000045337",
  "ENSMUSG00000045339",
  "ENSMUSG00000045340",
  "ENSMUSG00000045341",
  "ENSMUSG00000045348",
  "ENSMUSG00000045349",
  "ENSMUSG00000045350",
  "ENSMUSG00000045362",
  "ENSMUSG00000045364",
  "ENSMUSG00000045374",
  "ENSMUSG00000045377",
  "ENSMUSG00000045378",
  "ENSMUSG00000045381",
  "ENSMUSG00000045382",
  "ENSMUSG00000045391",
  "ENSMUSG00000045392",
  "ENSMUSG00000045394",
  "ENSMUSG00000045395",
  "ENSMUSG00000045404",
  "ENSMUSG00000045409",
  "ENSMUSG00000045410",
  "ENSMUSG00000045411",
  "ENSMUSG00000045414",
  "ENSMUSG00000045417",
  "ENSMUSG00000045421",
  "ENSMUSG00000045427",
  "ENSMUSG00000045435",
  "ENSMUSG00000045438",
  "ENSMUSG00000045440",
  "ENSMUSG00000045441",
  "ENSMUSG00000045466",
  "ENSMUSG00000045467",
  "ENSMUSG00000045471",
  "ENSMUSG00000045474",
  "ENSMUSG00000045475",
  "ENSMUSG00000045479",
  "ENSMUSG00000045482",
  "ENSMUSG00000045493",
  "ENSMUSG00000045498",
  "ENSMUSG00000045502",
  "ENSMUSG00000045503",
  "ENSMUSG00000045506",
  "ENSMUSG00000045508",
  "ENSMUSG00000045509",
  "ENSMUSG00000045514",
  "ENSMUSG00000045515",
  "ENSMUSG00000045518",
  "ENSMUSG00000045519",
  "ENSMUSG00000045521",
  "ENSMUSG00000045528",
  "ENSMUSG00000045532",
  "ENSMUSG00000045534",
  "ENSMUSG00000045538",
  "ENSMUSG00000045539",
  "ENSMUSG00000045540",
  "ENSMUSG00000045545",
  "ENSMUSG00000045551",
  "ENSMUSG00000045555",
  "ENSMUSG00000045559",
  "ENSMUSG00000045566",
  "ENSMUSG00000045569",
  "ENSMUSG00000045573",
  "ENSMUSG00000045575",
  "ENSMUSG00000045576",
  "ENSMUSG00000045581",
  "ENSMUSG00000045587",
  "ENSMUSG00000045589",
  "ENSMUSG00000045591",
  "ENSMUSG00000045594",
  "ENSMUSG00000045598",
  "ENSMUSG00000045608",
  "ENSMUSG00000045613",
  "ENSMUSG00000045620",
  "ENSMUSG00000045624",
  "ENSMUSG00000045625",
  "ENSMUSG00000045629",
  "ENSMUSG00000045631",
  "ENSMUSG00000045636",
  "ENSMUSG00000045639",
  "ENSMUSG00000045648",
  "ENSMUSG00000045655",
  "ENSMUSG00000045657",
  "ENSMUSG00000045658",
  "ENSMUSG00000045659",
  "ENSMUSG00000045662",
  "ENSMUSG00000045664",
  "ENSMUSG00000045665",
  "ENSMUSG00000045667",
  "ENSMUSG00000045671",
  "ENSMUSG00000045672",
  "ENSMUSG00000045678",
  "ENSMUSG00000045679",
  "ENSMUSG00000045680",
  "ENSMUSG00000045684",
  "ENSMUSG00000045689",
  "ENSMUSG00000045690",
  "ENSMUSG00000045693",
  "ENSMUSG00000045708",
  "ENSMUSG00000045725",
  "ENSMUSG00000045730",
  "ENSMUSG00000045731",
  "ENSMUSG00000045733",
  "ENSMUSG00000045744",
  "ENSMUSG00000045746",
  "ENSMUSG00000045751",
  "ENSMUSG00000045752",
  "ENSMUSG00000045761",
  "ENSMUSG00000045763",
  "ENSMUSG00000045767",
  "ENSMUSG00000045775",
  "ENSMUSG00000045776",
  "ENSMUSG00000045777",
  "ENSMUSG00000045780",
  "ENSMUSG00000045790",
  "ENSMUSG00000045792",
  "ENSMUSG00000045794",
  "ENSMUSG00000045795",
  "ENSMUSG00000045797",
  "ENSMUSG00000045802",
  "ENSMUSG00000045812",
  "ENSMUSG00000045813",
  "ENSMUSG00000045815",
  "ENSMUSG00000045817",
  "ENSMUSG00000045822",
  "ENSMUSG00000045824",
  "ENSMUSG00000045826",
  "ENSMUSG00000045827",
  "ENSMUSG00000045835",
  "ENSMUSG00000045838",
  "ENSMUSG00000045854",
  "ENSMUSG00000045867",
  "ENSMUSG00000045871",
  "ENSMUSG00000045875",
  "ENSMUSG00000045876",
  "ENSMUSG00000045877",
  "ENSMUSG00000045883",
  "ENSMUSG00000045886",
  "ENSMUSG00000045896",
  "ENSMUSG00000045903",
  "ENSMUSG00000045912",
  "ENSMUSG00000045915",
  "ENSMUSG00000045917",
  "ENSMUSG00000045928",
  "ENSMUSG00000045930",
  "ENSMUSG00000045932",
  "ENSMUSG00000045934",
  "ENSMUSG00000045942",
  "ENSMUSG00000045948",
  "ENSMUSG00000045954",
  "ENSMUSG00000045962",
  "ENSMUSG00000045967",
  "ENSMUSG00000045968",
  "ENSMUSG00000045969",
  "ENSMUSG00000045973",
  "ENSMUSG00000045975",
  "ENSMUSG00000045980",
  "ENSMUSG00000045983",
  "ENSMUSG00000045989",
  "ENSMUSG00000045991",
  "ENSMUSG00000045994",
  "ENSMUSG00000045996",
  "ENSMUSG00000046000",
  "ENSMUSG00000046005",
  "ENSMUSG00000046006",
  "ENSMUSG00000046008",
  "ENSMUSG00000046010",
  "ENSMUSG00000046016",
  "ENSMUSG00000046020",
  "ENSMUSG00000046027",
  "ENSMUSG00000046031",
  "ENSMUSG00000046032",
  "ENSMUSG00000046034",
  "ENSMUSG00000046041",
  "ENSMUSG00000046049",
  "ENSMUSG00000046058",
  "ENSMUSG00000046062",
  "ENSMUSG00000046070",
  "ENSMUSG00000046079",
  "ENSMUSG00000046080",
  "ENSMUSG00000046082",
  "ENSMUSG00000046085",
  "ENSMUSG00000046093",
  "ENSMUSG00000046095",
  "ENSMUSG00000046096",
  "ENSMUSG00000046101",
  "ENSMUSG00000046108",
  "ENSMUSG00000046109",
  "ENSMUSG00000046110",
  "ENSMUSG00000046111",
  "ENSMUSG00000046119",
  "ENSMUSG00000046133",
  "ENSMUSG00000046138",
  "ENSMUSG00000046139",
  "ENSMUSG00000046150",
  "ENSMUSG00000046152",
  "ENSMUSG00000046157",
  "ENSMUSG00000046159",
  "ENSMUSG00000046160",
  "ENSMUSG00000046167",
  "ENSMUSG00000046168",
  "ENSMUSG00000046169",
  "ENSMUSG00000046173",
  "ENSMUSG00000046178",
  "ENSMUSG00000046179",
  "ENSMUSG00000046180",
  "ENSMUSG00000046182",
  "ENSMUSG00000046185",
  "ENSMUSG00000046186",
  "ENSMUSG00000046191",
  "ENSMUSG00000046192",
  "ENSMUSG00000046196",
  "ENSMUSG00000046201",
  "ENSMUSG00000046204",
  "ENSMUSG00000046207",
  "ENSMUSG00000046210",
  "ENSMUSG00000046213",
  "ENSMUSG00000046215",
  "ENSMUSG00000046219",
  "ENSMUSG00000046223",
  "ENSMUSG00000046229",
  "ENSMUSG00000046230",
  "ENSMUSG00000046240",
  "ENSMUSG00000046242",
  "ENSMUSG00000046245",
  "ENSMUSG00000046258",
  "ENSMUSG00000046259",
  "ENSMUSG00000046262",
  "ENSMUSG00000046269",
  "ENSMUSG00000046272",
  "ENSMUSG00000046275",
  "ENSMUSG00000046280",
  "ENSMUSG00000046282",
  "ENSMUSG00000046287",
  "ENSMUSG00000046295",
  "ENSMUSG00000046300",
  "ENSMUSG00000046311",
  "ENSMUSG00000046312",
  "ENSMUSG00000046314",
  "ENSMUSG00000046317",
  "ENSMUSG00000046318",
  "ENSMUSG00000046321",
  "ENSMUSG00000046323",
  "ENSMUSG00000046324",
  "ENSMUSG00000046329",
  "ENSMUSG00000046330",
  "ENSMUSG00000046337",
  "ENSMUSG00000046338",
  "ENSMUSG00000046345",
  "ENSMUSG00000046351",
  "ENSMUSG00000046352",
  "ENSMUSG00000046354",
  "ENSMUSG00000046364",
  "ENSMUSG00000046367",
  "ENSMUSG00000046378",
  "ENSMUSG00000046380",
  "ENSMUSG00000046387",
  "ENSMUSG00000046396",
  "ENSMUSG00000046402",
  "ENSMUSG00000046404",
  "ENSMUSG00000046408",
  "ENSMUSG00000046410",
  "ENSMUSG00000046413",
  "ENSMUSG00000046415",
  "ENSMUSG00000046417",
  "ENSMUSG00000046431",
  "ENSMUSG00000046434",
  "ENSMUSG00000046435",
  "ENSMUSG00000046438",
  "ENSMUSG00000046441",
  "ENSMUSG00000046442",
  "ENSMUSG00000046447",
  "ENSMUSG00000046449",
  "ENSMUSG00000046450",
  "ENSMUSG00000046456",
  "ENSMUSG00000046460",
  "ENSMUSG00000046463",
  "ENSMUSG00000046470",
  "ENSMUSG00000046474",
  "ENSMUSG00000046480",
  "ENSMUSG00000046486",
  "ENSMUSG00000046490",
  "ENSMUSG00000046491",
  "ENSMUSG00000046493",
  "ENSMUSG00000046500",
  "ENSMUSG00000046516",
  "ENSMUSG00000046518",
  "ENSMUSG00000046519",
  "ENSMUSG00000046523",
  "ENSMUSG00000046532",
  "ENSMUSG00000046541",
  "ENSMUSG00000046550",
  "ENSMUSG00000046556",
  "ENSMUSG00000046561",
  "ENSMUSG00000046562",
  "ENSMUSG00000046567",
  "ENSMUSG00000046572",
  "ENSMUSG00000046573",
  "ENSMUSG00000046574",
  "ENSMUSG00000046585",
  "ENSMUSG00000046589",
  "ENSMUSG00000046591",
  "ENSMUSG00000046593",
  "ENSMUSG00000046598",
  "ENSMUSG00000046603",
  "ENSMUSG00000046605",
  "ENSMUSG00000046607",
  "ENSMUSG00000046610",
  "ENSMUSG00000046613",
  "ENSMUSG00000046615",
  "ENSMUSG00000046618",
  "ENSMUSG00000046623",
  "ENSMUSG00000046634",
  "ENSMUSG00000046637",
  "ENSMUSG00000046643",
  "ENSMUSG00000046650",
  "ENSMUSG00000046652",
  "ENSMUSG00000046658",
  "ENSMUSG00000046667",
  "ENSMUSG00000046668",
  "ENSMUSG00000046671",
  "ENSMUSG00000046675",
  "ENSMUSG00000046676",
  "ENSMUSG00000046678",
  "ENSMUSG00000046679",
  "ENSMUSG00000046683",
  "ENSMUSG00000046694",
  "ENSMUSG00000046697",
  "ENSMUSG00000046699",
  "ENSMUSG00000046707",
  "ENSMUSG00000046711",
  "ENSMUSG00000046714",
  "ENSMUSG00000046717",
  "ENSMUSG00000046718",
  "ENSMUSG00000046719",
  "ENSMUSG00000046722",
  "ENSMUSG00000046723",

  "ENSMUSG00000046727",
  "ENSMUSG00000046731",
  "ENSMUSG00000046743",
  "ENSMUSG00000046748",
  "ENSMUSG00000046750",
  "ENSMUSG00000046753",
  "ENSMUSG00000046755",
  "ENSMUSG00000046756",
  "ENSMUSG00000046761",
  "ENSMUSG00000046764",
  "ENSMUSG00000046768",
  "ENSMUSG00000046774",
  "ENSMUSG00000046782",
  "ENSMUSG00000046785",
  "ENSMUSG00000046790",
  "ENSMUSG00000046791",
  "ENSMUSG00000046792",
  "ENSMUSG00000046793",
  "ENSMUSG00000046794",
  "ENSMUSG00000046798",
  "ENSMUSG00000046804",
  "ENSMUSG00000046805",
  "ENSMUSG00000046806",
  "ENSMUSG00000046807",
  "ENSMUSG00000046811",
  "ENSMUSG00000046814",
  "ENSMUSG00000046818",
  "ENSMUSG00000046822",
  "ENSMUSG00000046826",
  "ENSMUSG00000046828",
  "ENSMUSG00000046834",
  "ENSMUSG00000046836",
  "ENSMUSG00000046840",
  "ENSMUSG00000046841",
  "ENSMUSG00000046844",
  "ENSMUSG00000046845",
  "ENSMUSG00000046846",
  "ENSMUSG00000046854",
  "ENSMUSG00000046856",
  "ENSMUSG00000046861",
  "ENSMUSG00000046862",
  "ENSMUSG00000046865",
  "ENSMUSG00000046873",
  "ENSMUSG00000046876",
  "ENSMUSG00000046879",
  "ENSMUSG00000046881",
  "ENSMUSG00000046886",
  "ENSMUSG00000046897",
  "ENSMUSG00000046899",
  "ENSMUSG00000046908",
  "ENSMUSG00000046909",
  "ENSMUSG00000046913",
  "ENSMUSG00000046916",
  "ENSMUSG00000046922",
  "ENSMUSG00000046924",
  "ENSMUSG00000046932",
  "ENSMUSG00000046934",
  "ENSMUSG00000046942",
  "ENSMUSG00000046949",
  "ENSMUSG00000046957",
  "ENSMUSG00000046959",
  "ENSMUSG00000046961",
  "ENSMUSG00000046962",
  "ENSMUSG00000046971",
  "ENSMUSG00000046974",
  "ENSMUSG00000046975",
  "ENSMUSG00000046982",
  "ENSMUSG00000046985",
  "ENSMUSG00000046991",
  "ENSMUSG00000046994",
  "ENSMUSG00000046995",
  "ENSMUSG00000046997",
  "ENSMUSG00000046999",
  "ENSMUSG00000047002",
  "ENSMUSG00000047003",
  "ENSMUSG00000047013",
  "ENSMUSG00000047014",
  "ENSMUSG00000047021",
  "ENSMUSG00000047022",
  "ENSMUSG00000047025",
  "ENSMUSG00000047026",
  "ENSMUSG00000047030",
  "ENSMUSG00000047031",
  "ENSMUSG00000047033",
  "ENSMUSG00000047034",
  "ENSMUSG00000047036",
  "ENSMUSG00000047037",
  "ENSMUSG00000047039",
  "ENSMUSG00000047040",
  "ENSMUSG00000047044",
  "ENSMUSG00000047045",
  "ENSMUSG00000047046",
  "ENSMUSG00000047048",
  "ENSMUSG00000047050",
  "ENSMUSG00000047053",
  "ENSMUSG00000047067",
  "ENSMUSG00000047079",
  "ENSMUSG00000047084",
  "ENSMUSG00000047085",
  "ENSMUSG00000047090",
  "ENSMUSG00000047094",
  "ENSMUSG00000047098",
  "ENSMUSG00000047102",
  "ENSMUSG00000047104",
  "ENSMUSG00000047108",
  "ENSMUSG00000047109",
  "ENSMUSG00000047115",
  "ENSMUSG00000047117",
  "ENSMUSG00000047123",
  "ENSMUSG00000047126",
  "ENSMUSG00000047129",
  "ENSMUSG00000047139",
  "ENSMUSG00000047141",
  "ENSMUSG00000047143",
  "ENSMUSG00000047146",
  "ENSMUSG00000047149",
  "ENSMUSG00000047150",
  "ENSMUSG00000047153",
  "ENSMUSG00000047155",
  "ENSMUSG00000047161",
  "ENSMUSG00000047171",
  "ENSMUSG00000047180",
  "ENSMUSG00000047181",
  "ENSMUSG00000047182",
  "ENSMUSG00000047187",
  "ENSMUSG00000047193",
  "ENSMUSG00000047197",
  "ENSMUSG00000047203",
  "ENSMUSG00000047205",
  "ENSMUSG00000047207",
  "ENSMUSG00000047213",
  "ENSMUSG00000047216",
  "ENSMUSG00000047220",
  "ENSMUSG00000047221",
  "ENSMUSG00000047222",
  "ENSMUSG00000047225",
  "ENSMUSG00000047227",
  "ENSMUSG00000047228",
  "ENSMUSG00000047230",
  "ENSMUSG00000047237",
  "ENSMUSG00000047238",
  "ENSMUSG00000047242",
  "ENSMUSG00000047246",
  "ENSMUSG00000047248",
  "ENSMUSG00000047250",
  "ENSMUSG00000047253",
  "ENSMUSG00000047257",
  "ENSMUSG00000047259",
  "ENSMUSG00000047260",
  "ENSMUSG00000047261",
  "ENSMUSG00000047264",
  "ENSMUSG00000047284",
  "ENSMUSG00000047286",
  "ENSMUSG00000047293",
  "ENSMUSG00000047295",
  "ENSMUSG00000047298",
  "ENSMUSG00000047307",
  "ENSMUSG00000047324",
  "ENSMUSG00000047330",
  "ENSMUSG00000047342",
  "ENSMUSG00000047343",
  "ENSMUSG00000047344",
  "ENSMUSG00000047352",
  "ENSMUSG00000047356",
  "ENSMUSG00000047361",
  "ENSMUSG00000047363",
  "ENSMUSG00000047368",
  "ENSMUSG00000047369",
  "ENSMUSG00000047371",
  "ENSMUSG00000047388",
  "ENSMUSG00000047390",
  "ENSMUSG00000047394",
  "ENSMUSG00000047407",
  "ENSMUSG00000047409",
  "ENSMUSG00000047412",
  "ENSMUSG00000047414",
  "ENSMUSG00000047415",
  "ENSMUSG00000047417",
  "ENSMUSG00000047419",
  "ENSMUSG00000047420",
  "ENSMUSG00000047423",
  "ENSMUSG00000047428",
  "ENSMUSG00000047434",
  "ENSMUSG00000047441",
  "ENSMUSG00000047443",
  "ENSMUSG00000047444",
  "ENSMUSG00000047446",
  "ENSMUSG00000047454",
  "ENSMUSG00000047462",
  "ENSMUSG00000047466",
  "ENSMUSG00000047473",
  "ENSMUSG00000047485",
  "ENSMUSG00000047492",
  "ENSMUSG00000047495",
  "ENSMUSG00000047496",
  "ENSMUSG00000047497",
  "ENSMUSG00000047501",
  "ENSMUSG00000047502",
  "ENSMUSG00000047507",
  "ENSMUSG00000047508",
  "ENSMUSG00000047511",
  "ENSMUSG00000047514",
  "ENSMUSG00000047515",
  "ENSMUSG00000047517",
  "ENSMUSG00000047518",
  "ENSMUSG00000047528",
  "ENSMUSG00000047531",
  "ENSMUSG00000047534",
  "ENSMUSG00000047535",
  "ENSMUSG00000047539",
  "ENSMUSG00000047544",
  "ENSMUSG00000047545",
  "ENSMUSG00000047547",
  "ENSMUSG00000047554",
  "ENSMUSG00000047557",
  "ENSMUSG00000047562",
  "ENSMUSG00000047564",
  "ENSMUSG00000047565",
  "ENSMUSG00000047583",
  "ENSMUSG00000047586",
  "ENSMUSG00000047591",
  "ENSMUSG00000047592",
  "ENSMUSG00000047594",
  "ENSMUSG00000047603",
  "ENSMUSG00000047604",
  "ENSMUSG00000047606",
  "ENSMUSG00000047613",
  "ENSMUSG00000047617",
  "ENSMUSG00000047619",
  "ENSMUSG00000047626",
  "ENSMUSG00000047631",
  "ENSMUSG00000047632",
  "ENSMUSG00000047635",
  "ENSMUSG00000047636",
  "ENSMUSG00000047638",
  "ENSMUSG00000047641",
  "ENSMUSG00000047642",
  "ENSMUSG00000047648",
  "ENSMUSG00000047649",
  "ENSMUSG00000047654",
  "ENSMUSG00000047655",
  "ENSMUSG00000047656",
  "ENSMUSG00000047658",
  "ENSMUSG00000047661",
  "ENSMUSG00000047667",
  "ENSMUSG00000047669",
  "ENSMUSG00000047671",
  "ENSMUSG00000047674",
  "ENSMUSG00000047675",
  "ENSMUSG00000047678",
  "ENSMUSG00000047686",
  "ENSMUSG00000047692",
  "ENSMUSG00000047694",
  "ENSMUSG00000047696",
  "ENSMUSG00000047702",
  "ENSMUSG00000047710",
  "ENSMUSG00000047712",
  "ENSMUSG00000047714",
  "ENSMUSG00000047716",
  "ENSMUSG00000047719",
  "ENSMUSG00000047720",
  "ENSMUSG00000047721",
  "ENSMUSG00000047728",
  "ENSMUSG00000047730",
  "ENSMUSG00000047731",
  "ENSMUSG00000047733",
  "ENSMUSG00000047735",
  "ENSMUSG00000047746",
  "ENSMUSG00000047747",
  "ENSMUSG00000047749",
  "ENSMUSG00000047751",
  "ENSMUSG00000047757",
  "ENSMUSG00000047759",
  "ENSMUSG00000047766",
  "ENSMUSG00000047767",
  "ENSMUSG00000047773",
  "ENSMUSG00000047777",
  "ENSMUSG00000047786",
  "ENSMUSG00000047787",
  "ENSMUSG00000047789",
  "ENSMUSG00000047793",
  "ENSMUSG00000047794",
  "ENSMUSG00000047797",
  "ENSMUSG00000047798",
  "ENSMUSG00000047799",
  "ENSMUSG00000047804",
  "ENSMUSG00000047810",
  "ENSMUSG00000047819",
  "ENSMUSG00000047821",
  "ENSMUSG00000047822",
  "ENSMUSG00000047824",
  "ENSMUSG00000047828",
  "ENSMUSG00000047832",
  "ENSMUSG00000047841",
  "ENSMUSG00000047843",
  "ENSMUSG00000047844",
  "ENSMUSG00000047854",
  "ENSMUSG00000047861",
  "ENSMUSG00000047866",
  "ENSMUSG00000047867",
  "ENSMUSG00000047868",
  "ENSMUSG00000047879",
  "ENSMUSG00000047880",
  "ENSMUSG00000047881",
  "ENSMUSG00000047884",
  "ENSMUSG00000047888",
  "ENSMUSG00000047894",
  "ENSMUSG00000047897",
  "ENSMUSG00000047898",
  "ENSMUSG00000047904",
  "ENSMUSG00000047907",
  "ENSMUSG00000047909",
  "ENSMUSG00000047910",
  "ENSMUSG00000047911",
  "ENSMUSG00000047921",
  "ENSMUSG00000047935",
  "ENSMUSG00000047938",
  "ENSMUSG00000047940",
  "ENSMUSG00000047953",
  "ENSMUSG00000047959",
  "ENSMUSG00000047960",
  "ENSMUSG00000047963",
  "ENSMUSG00000047969",
  "ENSMUSG00000047980",
  "ENSMUSG00000047986",
  "ENSMUSG00000047988",
  "ENSMUSG00000047989",
  "ENSMUSG00000047990",
  "ENSMUSG00000047992",
  "ENSMUSG00000047995",
  "ENSMUSG00000047996",
  "ENSMUSG00000048000",
  "ENSMUSG00000048001",
  "ENSMUSG00000048003",
  "ENSMUSG00000048004",
  "ENSMUSG00000048007",
  "ENSMUSG00000048012",
  "ENSMUSG00000048013",
  "ENSMUSG00000048015",
  "ENSMUSG00000048022",
  "ENSMUSG00000048027",
  "ENSMUSG00000048029",
  "ENSMUSG00000048031",
  "ENSMUSG00000048038",
  "ENSMUSG00000048039",
  "ENSMUSG00000048040",
  "ENSMUSG00000048047",
  "ENSMUSG00000048058",
  "ENSMUSG00000048062",
  "ENSMUSG00000048065",
  "ENSMUSG00000048067",
  "ENSMUSG00000048070",
  "ENSMUSG00000048076",
  "ENSMUSG00000048077",
  "ENSMUSG00000048078",
  "ENSMUSG00000048080",
  "ENSMUSG00000048096",
  "ENSMUSG00000048100",
  "ENSMUSG00000048101",
  "ENSMUSG00000048108",
  "ENSMUSG00000048109",
  "ENSMUSG00000048118",
  "ENSMUSG00000048120",
  "ENSMUSG00000048126",
  "ENSMUSG00000048138",
  "ENSMUSG00000048142",
  "ENSMUSG00000048148",
  "ENSMUSG00000048153",
  "ENSMUSG00000048154",
  "ENSMUSG00000048155",
  "ENSMUSG00000048163",
  "ENSMUSG00000048170",
  "ENSMUSG00000048173",
  "ENSMUSG00000048174",
  "ENSMUSG00000048175",
  "ENSMUSG00000048186",
  "ENSMUSG00000048191",
  "ENSMUSG00000048197",
  "ENSMUSG00000048200",
  "ENSMUSG00000048206",
  "ENSMUSG00000048215",
  "ENSMUSG00000048216",
  "ENSMUSG00000048217",
  "ENSMUSG00000048218",
  "ENSMUSG00000048226",
  "ENSMUSG00000048230",
  "ENSMUSG00000048231",
  "ENSMUSG00000048232",
  "ENSMUSG00000048236",
  "ENSMUSG00000048240",
  "ENSMUSG00000048249",
  "ENSMUSG00000048251",
  "ENSMUSG00000048264",
  "ENSMUSG00000048271",
  "ENSMUSG00000048277",
  "ENSMUSG00000048279",
  "ENSMUSG00000048280",
  "ENSMUSG00000048281",
  "ENSMUSG00000048284",
  "ENSMUSG00000048285",
  "ENSMUSG00000048292",
  "ENSMUSG00000048299",
  "ENSMUSG00000048304",
  "ENSMUSG00000048307",
  "ENSMUSG00000048310",
  "ENSMUSG00000048327",
  "ENSMUSG00000048329",
  "ENSMUSG00000048330",
  "ENSMUSG00000048332",
  "ENSMUSG00000048337",
  "ENSMUSG00000048347",
  "ENSMUSG00000048349",
  "ENSMUSG00000048351",
  "ENSMUSG00000048355",
  "ENSMUSG00000048356",
  "ENSMUSG00000048368",
  "ENSMUSG00000048371",
  "ENSMUSG00000048376",
  "ENSMUSG00000048377",
  "ENSMUSG00000048378",
  "ENSMUSG00000048379",
  "ENSMUSG00000048385",
  "ENSMUSG00000048387",
  "ENSMUSG00000048388",
  "ENSMUSG00000048391",
  "ENSMUSG00000048398",
  "ENSMUSG00000048399",
  "ENSMUSG00000048400",
  "ENSMUSG00000048402",
  "ENSMUSG00000048410",
  "ENSMUSG00000048411",
  "ENSMUSG00000048416",
  "ENSMUSG00000048424",
  "ENSMUSG00000048425",
  "ENSMUSG00000048429",
  "ENSMUSG00000048439",
  "ENSMUSG00000048440",
  "ENSMUSG00000048442",
  "ENSMUSG00000048445",
  "ENSMUSG00000048450",
  "ENSMUSG00000048455",
  "ENSMUSG00000048456",
  "ENSMUSG00000048458",
  "ENSMUSG00000048469",
  "ENSMUSG00000048473",
  "ENSMUSG00000048478",
  "ENSMUSG00000048480",
  "ENSMUSG00000048481",
  "ENSMUSG00000048482",
  "ENSMUSG00000048483",
  "ENSMUSG00000048485",
  "ENSMUSG00000048486",
  "ENSMUSG00000048489",
  "ENSMUSG00000048490",
  "ENSMUSG00000048495",
  "ENSMUSG00000048497",
  "ENSMUSG00000048498",
  "ENSMUSG00000048500",
  "ENSMUSG00000048501",
  "ENSMUSG00000048503",
  "ENSMUSG00000048516",
  "ENSMUSG00000048520",
  "ENSMUSG00000048521",
  "ENSMUSG00000048528",
  "ENSMUSG00000048534",
  "ENSMUSG00000048537",
  "ENSMUSG00000048540",
  "ENSMUSG00000048546",
  "ENSMUSG00000048550",
  "ENSMUSG00000048562",
  "ENSMUSG00000048572",
  "ENSMUSG00000048573",
  "ENSMUSG00000048578",
  "ENSMUSG00000048581",
  "ENSMUSG00000048582",
  "ENSMUSG00000048602",
  "ENSMUSG00000048603",
  "ENSMUSG00000048612",
  "ENSMUSG00000048616",
  "ENSMUSG00000048617",
  "ENSMUSG00000048620",
  "ENSMUSG00000048621",
  "ENSMUSG00000048626",
  "ENSMUSG00000048628",
  "ENSMUSG00000048636",
  "ENSMUSG00000048644",
  "ENSMUSG00000048647",
  "ENSMUSG00000048652",
  "ENSMUSG00000048655",
  "ENSMUSG00000048661",
  "ENSMUSG00000048668",
  "ENSMUSG00000048677",
  "ENSMUSG00000048686",
  "ENSMUSG00000048693",
  "ENSMUSG00000048696",
  "ENSMUSG00000048697",
  "ENSMUSG00000048699",
  "ENSMUSG00000048701",
  "ENSMUSG00000048706",
  "ENSMUSG00000048707",
  "ENSMUSG00000048720",
  "ENSMUSG00000048721",
  "ENSMUSG00000048728",
  "ENSMUSG00000048731",
  "ENSMUSG00000048732",
  "ENSMUSG00000048745",
  "ENSMUSG00000048747",
  "ENSMUSG00000048752",
  "ENSMUSG00000048755",
  "ENSMUSG00000048756",
  "ENSMUSG00000048758",
  "ENSMUSG00000048763",
  "ENSMUSG00000048764",
  "ENSMUSG00000048766",
  "ENSMUSG00000048772",
  "ENSMUSG00000048775",
  "ENSMUSG00000048776",
  "ENSMUSG00000048779",
  "ENSMUSG00000048782",
  "ENSMUSG00000048787",
  "ENSMUSG00000048794",
  "ENSMUSG00000048796",
  "ENSMUSG00000048799",
  "ENSMUSG00000048806",
  "ENSMUSG00000048807",
  "ENSMUSG00000048810",
  "ENSMUSG00000048814",
  "ENSMUSG00000048824",
  "ENSMUSG00000048826",
  "ENSMUSG00000048827",
  "ENSMUSG00000048830",
  "ENSMUSG00000048832",
  "ENSMUSG00000048833",
  "ENSMUSG00000048834",
  "ENSMUSG00000048852",
  "ENSMUSG00000048856",
  "ENSMUSG00000048865",
  "ENSMUSG00000048874",
  "ENSMUSG00000048875",
  "ENSMUSG00000048878",
  "ENSMUSG00000048895",
  "ENSMUSG00000048897",
  "ENSMUSG00000048899",
  "ENSMUSG00000048904",
  "ENSMUSG00000048905",
  "ENSMUSG00000048911",
  "ENSMUSG00000048915",
  "ENSMUSG00000048919",
  "ENSMUSG00000048920",
  "ENSMUSG00000048921",
  "ENSMUSG00000048922",
  "ENSMUSG00000048924",
  "ENSMUSG00000048930",
  "ENSMUSG00000048933",
  "ENSMUSG00000048938",
  "ENSMUSG00000048939",
  "ENSMUSG00000048960",
  "ENSMUSG00000048965",
  "ENSMUSG00000048967",
  "ENSMUSG00000048970",
  "ENSMUSG00000048978",
  "ENSMUSG00000048981",
  "ENSMUSG00000048982",
  "ENSMUSG00000048992",
  "ENSMUSG00000048994",
  "ENSMUSG00000048996",
  "ENSMUSG00000048997",
  "ENSMUSG00000049001",
  "ENSMUSG00000049008",
  "ENSMUSG00000049010",
  "ENSMUSG00000049011",
  "ENSMUSG00000049013",
  "ENSMUSG00000049015",
  "ENSMUSG00000049018",
  "ENSMUSG00000049028",
  "ENSMUSG00000049036",
  "ENSMUSG00000049037",
  "ENSMUSG00000049038",
  "ENSMUSG00000049041",
  "ENSMUSG00000049044",
  "ENSMUSG00000049047",
  "ENSMUSG00000049052",
  "ENSMUSG00000049057",
  "ENSMUSG00000049073",
  "ENSMUSG00000049076",
  "ENSMUSG00000049086",
  "ENSMUSG00000049090",
  "ENSMUSG00000049091",
  "ENSMUSG00000049092",
  "ENSMUSG00000049093",
  "ENSMUSG00000049097",
  "ENSMUSG00000049098",
  "ENSMUSG00000049100",
  "ENSMUSG00000049103",
  "ENSMUSG00000049106",
  "ENSMUSG00000049107",
  "ENSMUSG00000049109",
  "ENSMUSG00000049112",
  "ENSMUSG00000049115",
  "ENSMUSG00000049119",
  "ENSMUSG00000049122",
  "ENSMUSG00000049123",
  "ENSMUSG00000049128",
  "ENSMUSG00000049130",
  "ENSMUSG00000049133",
  "ENSMUSG00000049134",
  "ENSMUSG00000049148",
  "ENSMUSG00000049149",
  "ENSMUSG00000049152",
  "ENSMUSG00000049154",
  "ENSMUSG00000049160",
  "ENSMUSG00000049164",
  "ENSMUSG00000049168",
  "ENSMUSG00000049173",
  "ENSMUSG00000049176",
  "ENSMUSG00000049184",
  "ENSMUSG00000049191",
  "ENSMUSG00000049214",
  "ENSMUSG00000049217",
  "ENSMUSG00000049225",
  "ENSMUSG00000049229",
  "ENSMUSG00000049230",
  "ENSMUSG00000049232",
  "ENSMUSG00000049241",
  "ENSMUSG00000049252",
  "ENSMUSG00000049265",
  "ENSMUSG00000049280",
  "ENSMUSG00000049281",
  "ENSMUSG00000049285",
  "ENSMUSG00000049287",
  "ENSMUSG00000049288",
  "ENSMUSG00000049291",
  "ENSMUSG00000049295",
  "ENSMUSG00000049299",
  "ENSMUSG00000049300",
  "ENSMUSG00000049303",
  "ENSMUSG00000049305",
  "ENSMUSG00000049307",
  "ENSMUSG00000049313",
  "ENSMUSG00000049314",
  "ENSMUSG00000049315",
  "ENSMUSG00000049321",
  "ENSMUSG00000049323",
  "ENSMUSG00000049327",
  "ENSMUSG00000049334",
  "ENSMUSG00000049336",
  "ENSMUSG00000049339",
  "ENSMUSG00000049349",
  "ENSMUSG00000049350",
  "ENSMUSG00000049353",
  "ENSMUSG00000049354",
  "ENSMUSG00000049357",
  "ENSMUSG00000049362",
  "ENSMUSG00000049372",
  "ENSMUSG00000049382",
  "ENSMUSG00000049387",
  "ENSMUSG00000049401",
  "ENSMUSG00000049404",
  "ENSMUSG00000049409",
  "ENSMUSG00000049410",
  "ENSMUSG00000049411",
  "ENSMUSG00000049420",
  "ENSMUSG00000049421",
  "ENSMUSG00000049422",
  "ENSMUSG00000049436",
  "ENSMUSG00000049439",
  "ENSMUSG00000049456",
  "ENSMUSG00000049470",
  "ENSMUSG00000049476",
  "ENSMUSG00000049482",
  "ENSMUSG00000049488",
  "ENSMUSG00000049489",
  "ENSMUSG00000049491",
  "ENSMUSG00000049493",
  "ENSMUSG00000049498",
  "ENSMUSG00000049502",
  "ENSMUSG00000049504",
  "ENSMUSG00000049506",
  "ENSMUSG00000049511",
  "ENSMUSG00000049515",
  "ENSMUSG00000049516",
  "ENSMUSG00000049521",
  "ENSMUSG00000049526",
  "ENSMUSG00000049528",
  "ENSMUSG00000049530",
  "ENSMUSG00000049532",
  "ENSMUSG00000049536",
  "ENSMUSG00000049537",
  "ENSMUSG00000049538",
  "ENSMUSG00000049539",
  "ENSMUSG00000049548",
  "ENSMUSG00000049550",
  "ENSMUSG00000049551",
  "ENSMUSG00000049553",
  "ENSMUSG00000049555",
  "ENSMUSG00000049556",
  "ENSMUSG00000049560",
  "ENSMUSG00000049561",
  "ENSMUSG00000049562",
  "ENSMUSG00000049565",
  "ENSMUSG00000049571",
  "ENSMUSG00000049573",
  "ENSMUSG00000049577",
  "ENSMUSG00000049580",
  "ENSMUSG00000049583",
  "ENSMUSG00000049588",
  "ENSMUSG00000049593",
  "ENSMUSG00000049598",
  "ENSMUSG00000049600",
  "ENSMUSG00000049604",
  "ENSMUSG00000049605",
  "ENSMUSG00000049606",
  "ENSMUSG00000049608",
  "ENSMUSG00000049612",
  "ENSMUSG00000049618",
  "ENSMUSG00000049620",
  "ENSMUSG00000049624",
  "ENSMUSG00000049625",
  "ENSMUSG00000049641",
  "ENSMUSG00000049643",
  "ENSMUSG00000049648",
  "ENSMUSG00000049649",
  "ENSMUSG00000049653",
  "ENSMUSG00000049657",
  "ENSMUSG00000049658",
  "ENSMUSG00000049659",
  "ENSMUSG00000049670",
  "ENSMUSG00000049672",
  "ENSMUSG00000049674",
  "ENSMUSG00000049680",
  "ENSMUSG00000049685",
  "ENSMUSG00000049686",
  "ENSMUSG00000049687",
  "ENSMUSG00000049690",
  "ENSMUSG00000049691",
  "ENSMUSG00000049694",
  "ENSMUSG00000049699",
  "ENSMUSG00000049709",
  "ENSMUSG00000049717",
  "ENSMUSG00000049719",
  "ENSMUSG00000049721",
  "ENSMUSG00000049723",
  "ENSMUSG00000049728",
  "ENSMUSG00000049737",
  "ENSMUSG00000049739",
  "ENSMUSG00000049741",
  "ENSMUSG00000049744",
  "ENSMUSG00000049751",
  "ENSMUSG00000049755",
  "ENSMUSG00000049758",
  "ENSMUSG00000049760",
  "ENSMUSG00000049761",
  "ENSMUSG00000049764",
  "ENSMUSG00000049775",
  "ENSMUSG00000049791",
  "ENSMUSG00000049792",
  "ENSMUSG00000049796",
  "ENSMUSG00000049797",
  "ENSMUSG00000049799",
  "ENSMUSG00000049800",
  "ENSMUSG00000049804",
  "ENSMUSG00000049806",
  "ENSMUSG00000049807",
  "ENSMUSG00000049809",
  "ENSMUSG00000049811",
  "ENSMUSG00000049815",
  "ENSMUSG00000049823",
  "ENSMUSG00000049843",
  "ENSMUSG00000049848",
  "ENSMUSG00000049858",
  "ENSMUSG00000049864",
  "ENSMUSG00000049866",
  "ENSMUSG00000049871",
  "ENSMUSG00000049872",
  "ENSMUSG00000049878",
  "ENSMUSG00000049882",
  "ENSMUSG00000049892",
  "ENSMUSG00000049894",
  "ENSMUSG00000049897",
  "ENSMUSG00000049902",
  "ENSMUSG00000049904",
  "ENSMUSG00000049907",
  "ENSMUSG00000049908",
  "ENSMUSG00000049916",
  "ENSMUSG00000049922",
  "ENSMUSG00000049926",
  "ENSMUSG00000049928",
  "ENSMUSG00000049929",
  "ENSMUSG00000049932",
  "ENSMUSG00000049939",
  "ENSMUSG00000049940",
  "ENSMUSG00000049946",
  "ENSMUSG00000049950",
  "ENSMUSG00000049957",
  "ENSMUSG00000049960",
  "ENSMUSG00000049969",
  "ENSMUSG00000049971",
  "ENSMUSG00000049972",
  "ENSMUSG00000049985",
  "ENSMUSG00000049988",
  "ENSMUSG00000049999",
  "ENSMUSG00000050002",
  "ENSMUSG00000050010",
  "ENSMUSG00000050014",
  "ENSMUSG00000050015",
  "ENSMUSG00000050017",
  "ENSMUSG00000050022",
  "ENSMUSG00000050023",
  "ENSMUSG00000050028",
  "ENSMUSG00000050029",
  "ENSMUSG00000050030",
  "ENSMUSG00000050035",
  "ENSMUSG00000050043",
  "ENSMUSG00000050050",
  "ENSMUSG00000050052",
  "ENSMUSG00000050058",
  "ENSMUSG00000050063",
  "ENSMUSG00000050064",
  "ENSMUSG00000050066",
  "ENSMUSG00000050069",
  "ENSMUSG00000050071",
  "ENSMUSG00000050074",
  "ENSMUSG00000050075",
  "ENSMUSG00000050079",
  "ENSMUSG00000050085",
  "ENSMUSG00000050087",
  "ENSMUSG00000050088",
  "ENSMUSG00000050089",
  "ENSMUSG00000050092",
  "ENSMUSG00000050097",
  "ENSMUSG00000050100",
  "ENSMUSG00000050102",
  "ENSMUSG00000050103",
  "ENSMUSG00000050105",
  "ENSMUSG00000050106",
  "ENSMUSG00000050107",
  "ENSMUSG00000050108",
  "ENSMUSG00000050114",
  "ENSMUSG00000050121",
  "ENSMUSG00000050122",
  "ENSMUSG00000050128",
  "ENSMUSG00000050132",
  "ENSMUSG00000050134",
  "ENSMUSG00000050138",
  "ENSMUSG00000050141",
  "ENSMUSG00000050144",
  "ENSMUSG00000050147",
  "ENSMUSG00000050148",
  "ENSMUSG00000050150",
  "ENSMUSG00000050157",
  "ENSMUSG00000050158",
  "ENSMUSG00000050164",
  "ENSMUSG00000050174",
  "ENSMUSG00000050179",
  "ENSMUSG00000050188",
  "ENSMUSG00000050189",
  "ENSMUSG00000050190",
  "ENSMUSG00000050192",
  "ENSMUSG00000050195",
  "ENSMUSG00000050197",
  "ENSMUSG00000050198",
  "ENSMUSG00000050199",
  "ENSMUSG00000050201",
  "ENSMUSG00000050211",
  "ENSMUSG00000050212",
  "ENSMUSG00000050213",
  "ENSMUSG00000050215",
  "ENSMUSG00000050217",
  "ENSMUSG00000050222",
  "ENSMUSG00000050224",
  "ENSMUSG00000050229",
  "ENSMUSG00000050232",
  "ENSMUSG00000050234",
  "ENSMUSG00000050239",
  "ENSMUSG00000050240",
  "ENSMUSG00000050241",
  "ENSMUSG00000050244",
  "ENSMUSG00000050248",
  "ENSMUSG00000050251",
  "ENSMUSG00000050266",
  "ENSMUSG00000050270",
  "ENSMUSG00000050271",
  "ENSMUSG00000050272",
  "ENSMUSG00000050276",
  "ENSMUSG00000050281",
  "ENSMUSG00000050288",
  "ENSMUSG00000050295",
  "ENSMUSG00000050296",
  "ENSMUSG00000050304",
  "ENSMUSG00000050310",
  "ENSMUSG00000050312",
  "ENSMUSG00000050315",
  "ENSMUSG00000050321",
  "ENSMUSG00000050323",
  "ENSMUSG00000050328",
  "ENSMUSG00000050332",
  "ENSMUSG00000050334",
  "ENSMUSG00000050335",
  "ENSMUSG00000050343",
  "ENSMUSG00000050350",
  "ENSMUSG00000050357",
  "ENSMUSG00000050359",
  "ENSMUSG00000050366",
  "ENSMUSG00000050368",
  "ENSMUSG00000050370",
  "ENSMUSG00000050373",
  "ENSMUSG00000050377",
  "ENSMUSG00000050379",
  "ENSMUSG00000050382",
  "ENSMUSG00000050383",
  "ENSMUSG00000050390",
  "ENSMUSG00000050395",
  "ENSMUSG00000050397",
  "ENSMUSG00000050410",
  "ENSMUSG00000050423",
  "ENSMUSG00000050424",
  "ENSMUSG00000050425",
  "ENSMUSG00000050428",
  "ENSMUSG00000050435",
  "ENSMUSG00000050439",
  "ENSMUSG00000050440",
  "ENSMUSG00000050445",
  "ENSMUSG00000050447",
  "ENSMUSG00000050463",
  "ENSMUSG00000050468",
  "ENSMUSG00000050471",
  "ENSMUSG00000050473",
  "ENSMUSG00000050478",
  "ENSMUSG00000050493",
  "ENSMUSG00000050503",
  "ENSMUSG00000050504",
  "ENSMUSG00000050505",
  "ENSMUSG00000050511",
  "ENSMUSG00000050520",
  "ENSMUSG00000050526",
  "ENSMUSG00000050530",
  "ENSMUSG00000050534",
  "ENSMUSG00000050538",
  "ENSMUSG00000050545",
  "ENSMUSG00000050549",
  "ENSMUSG00000050552",
  "ENSMUSG00000050553",
  "ENSMUSG00000050555",
  "ENSMUSG00000050556",
  "ENSMUSG00000050558",
  "ENSMUSG00000050565",
  "ENSMUSG00000050567",
  "ENSMUSG00000050578",
  "ENSMUSG00000050587",
  "ENSMUSG00000050592",
  "ENSMUSG00000050600",
  "ENSMUSG00000050603",
  "ENSMUSG00000050605",
  "ENSMUSG00000050608",
  "ENSMUSG00000050612",
  "ENSMUSG00000050613",
  "ENSMUSG00000050619",
  "ENSMUSG00000050621",
  "ENSMUSG00000050623",
  "ENSMUSG00000050625",
  "ENSMUSG00000050627",
  "ENSMUSG00000050628",
  "ENSMUSG00000050635",
  "ENSMUSG00000050640",
  "ENSMUSG00000050641",
  "ENSMUSG00000050645",
  "ENSMUSG00000050650",
  "ENSMUSG00000050654",
  "ENSMUSG00000050663",
  "ENSMUSG00000050666",
  "ENSMUSG00000050668",
  "ENSMUSG00000050671",
  "ENSMUSG00000050675",
  "ENSMUSG00000050677",
  "ENSMUSG00000050685",
  "ENSMUSG00000050697",
  "ENSMUSG00000050700",
  "ENSMUSG00000050702",
  "ENSMUSG00000050704",
  "ENSMUSG00000050705",
  "ENSMUSG00000050711",
  "ENSMUSG00000050714",
  "ENSMUSG00000050721",
  "ENSMUSG00000050730",
  "ENSMUSG00000050732",
  "ENSMUSG00000050737",
  "ENSMUSG00000050742",
  "ENSMUSG00000050747",
  "ENSMUSG00000050751",
  "ENSMUSG00000050756",
  "ENSMUSG00000050762",
  "ENSMUSG00000050763",
  "ENSMUSG00000050765",
  "ENSMUSG00000050766",
  "ENSMUSG00000050772",
  "ENSMUSG00000050776",
  "ENSMUSG00000050777",
  "ENSMUSG00000050783",
  "ENSMUSG00000050786",
  "ENSMUSG00000050788",
  "ENSMUSG00000050796",
  "ENSMUSG00000050799",
  "ENSMUSG00000050803",
  "ENSMUSG00000050808",
  "ENSMUSG00000050810",
  "ENSMUSG00000050812",
  "ENSMUSG00000050813",
  "ENSMUSG00000050815",
  "ENSMUSG00000050818",
  "ENSMUSG00000050821",
  "ENSMUSG00000050822",
  "ENSMUSG00000050824",
  "ENSMUSG00000050830",
  "ENSMUSG00000050840",
  "ENSMUSG00000050844",
  "ENSMUSG00000050846",
  "ENSMUSG00000050853",
  "ENSMUSG00000050854",
  "ENSMUSG00000050855",
  "ENSMUSG00000050856",
  "ENSMUSG00000050865",
  "ENSMUSG00000050866",
  "ENSMUSG00000050870",
  "ENSMUSG00000050875",
  "ENSMUSG00000050876",
  "ENSMUSG00000050890",
  "ENSMUSG00000050891",
  "ENSMUSG00000050896",
  "ENSMUSG00000050901",
  "ENSMUSG00000050908",
  "ENSMUSG00000050910",
  "ENSMUSG00000050912",
  "ENSMUSG00000050914",
  "ENSMUSG00000050917",
  "ENSMUSG00000050919",
  "ENSMUSG00000050921",
  "ENSMUSG00000050926",
  "ENSMUSG00000050930",
  "ENSMUSG00000050931",
  "ENSMUSG00000050933",
  "ENSMUSG00000050936",
  "ENSMUSG00000050944",
  "ENSMUSG00000050945",
  "ENSMUSG00000050947",
  "ENSMUSG00000050953",
  "ENSMUSG00000050954",
  "ENSMUSG00000050957",
  "ENSMUSG00000050961",
  "ENSMUSG00000050963",
  "ENSMUSG00000050965",
  "ENSMUSG00000050966",
  "ENSMUSG00000050967",
  "ENSMUSG00000050973",
  "ENSMUSG00000050982",
  "ENSMUSG00000050989",
  "ENSMUSG00000050994",
  "ENSMUSG00000050996",
  "ENSMUSG00000051000",
  "ENSMUSG00000051003",
  "ENSMUSG00000051007",
  "ENSMUSG00000051008",
  "ENSMUSG00000051022",
  "ENSMUSG00000051029",
  "ENSMUSG00000051034",
  "ENSMUSG00000051036",
  "ENSMUSG00000051037",
  "ENSMUSG00000051038",
  "ENSMUSG00000051041",
  "ENSMUSG00000051043",
  "ENSMUSG00000051046",
  "ENSMUSG00000051048",
  "ENSMUSG00000051050",
  "ENSMUSG00000051051",
  "ENSMUSG00000051054",
  "ENSMUSG00000051056",
  "ENSMUSG00000051062",
  "ENSMUSG00000051065",
  "ENSMUSG00000051067",
  "ENSMUSG00000051074",
  "ENSMUSG00000051076",
  "ENSMUSG00000051079",
  "ENSMUSG00000051081",
  "ENSMUSG00000051095",
  "ENSMUSG00000051098",
  "ENSMUSG00000051111",
  "ENSMUSG00000051113",
  "ENSMUSG00000051118",
  "ENSMUSG00000051124",
  "ENSMUSG00000051136",
  "ENSMUSG00000051146",
  "ENSMUSG00000051147",
  "ENSMUSG00000051153",
  "ENSMUSG00000051154",
  "ENSMUSG00000051156",
  "ENSMUSG00000051159",
  "ENSMUSG00000051160",
  "ENSMUSG00000051166",
  "ENSMUSG00000051169",
  "ENSMUSG00000051172",
  "ENSMUSG00000051176",
  "ENSMUSG00000051177",
  "ENSMUSG00000051180",
  "ENSMUSG00000051182",
  "ENSMUSG00000051184",
  "ENSMUSG00000051185",
  "ENSMUSG00000051190",
  "ENSMUSG00000051198",
  "ENSMUSG00000051200",
  "ENSMUSG00000051207",
  "ENSMUSG00000051209",
  "ENSMUSG00000051212",
  "ENSMUSG00000051220",
  "ENSMUSG00000051223",
  "ENSMUSG00000051224",
  "ENSMUSG00000051225",
  "ENSMUSG00000051228",
  "ENSMUSG00000051232",
  "ENSMUSG00000051234",
  "ENSMUSG00000051235",
  "ENSMUSG00000051236",
  "ENSMUSG00000051237",
  "ENSMUSG00000051238",
  "ENSMUSG00000051242",
  "ENSMUSG00000051243",
  "ENSMUSG00000051246",
  "ENSMUSG00000051251",
  "ENSMUSG00000051256",
  "ENSMUSG00000051257",
  "ENSMUSG00000051258",
  "ENSMUSG00000051276",
  "ENSMUSG00000051278",
  "ENSMUSG00000051279",
  "ENSMUSG00000051285",
  "ENSMUSG00000051295",
  "ENSMUSG00000051297",
  "ENSMUSG00000051306",
  "ENSMUSG00000051313",
  "ENSMUSG00000051314",
  "ENSMUSG00000051316",
  "ENSMUSG00000051319",
  "ENSMUSG00000051323",
  "ENSMUSG00000051329",
  "ENSMUSG00000051331",
  "ENSMUSG00000051335",
  "ENSMUSG00000051339",
  "ENSMUSG00000051340",
  "ENSMUSG00000051341",
  "ENSMUSG00000051343",
  "ENSMUSG00000051344",
  "ENSMUSG00000051346",
  "ENSMUSG00000051351",
  "ENSMUSG00000051354",
  "ENSMUSG00000051359",
  "ENSMUSG00000051361",
  "ENSMUSG00000051362",
  "ENSMUSG00000051367",
  "ENSMUSG00000051373",
  "ENSMUSG00000051375",
  "ENSMUSG00000051378",
  "ENSMUSG00000051379",
  "ENSMUSG00000051390",
  "ENSMUSG00000051391",
  "ENSMUSG00000051392",
  "ENSMUSG00000051397",
  "ENSMUSG00000051401",
  "ENSMUSG00000051403",
  "ENSMUSG00000051413",
  "ENSMUSG00000051414",
  "ENSMUSG00000051424",
  "ENSMUSG00000051427",
  "ENSMUSG00000051431",
  "ENSMUSG00000051435",
  "ENSMUSG00000051437",
  "ENSMUSG00000051439",
  "ENSMUSG00000051444",
  "ENSMUSG00000051451",
  "ENSMUSG00000051452",
  "ENSMUSG00000051455",
  "ENSMUSG00000051456",
  "ENSMUSG00000051457",
  "ENSMUSG00000051469",
  "ENSMUSG00000051481",
  "ENSMUSG00000051483",
  "ENSMUSG00000051486",
  "ENSMUSG00000051490",
  "ENSMUSG00000051493",
  "ENSMUSG00000051495",
  "ENSMUSG00000051497",
  "ENSMUSG00000051498",
  "ENSMUSG00000051499",
  "ENSMUSG00000051502",
  "ENSMUSG00000051503",
  "ENSMUSG00000051504",
  "ENSMUSG00000051506",
  "ENSMUSG00000051509",
  "ENSMUSG00000051510",
  "ENSMUSG00000051515",
  "ENSMUSG00000051517",
  "ENSMUSG00000051518",
  "ENSMUSG00000051527",
  "ENSMUSG00000051528",
  "ENSMUSG00000051550",
  "ENSMUSG00000051557",
  "ENSMUSG00000051579",
  "ENSMUSG00000051582",
  "ENSMUSG00000051586",
  "ENSMUSG00000051590",
  "ENSMUSG00000051591",
  "ENSMUSG00000051592",
  "ENSMUSG00000051593",
  "ENSMUSG00000051596",
  "ENSMUSG00000051599",
  "ENSMUSG00000051606",
  "ENSMUSG00000051611",
  "ENSMUSG00000051615",
  "ENSMUSG00000051617",
  "ENSMUSG00000051618",
  "ENSMUSG00000051627",
  "ENSMUSG00000051638",
  "ENSMUSG00000051648",
  "ENSMUSG00000051652",
  "ENSMUSG00000051663",
  "ENSMUSG00000051669",
  "ENSMUSG00000051671",
  "ENSMUSG00000051674",
  "ENSMUSG00000051675",
  "ENSMUSG00000051678",
  "ENSMUSG00000051680",
  "ENSMUSG00000051682",
  "ENSMUSG00000051687",
  "ENSMUSG00000051695",
  "ENSMUSG00000051703",
  "ENSMUSG00000051705",
  "ENSMUSG00000051706",
  "ENSMUSG00000051716",
  "ENSMUSG00000051721",
  "ENSMUSG00000051726",
  "ENSMUSG00000051727",
  "ENSMUSG00000051728",
  "ENSMUSG00000051729",
  "ENSMUSG00000051730",
  "ENSMUSG00000051732",
  "ENSMUSG00000051735",
  "ENSMUSG00000051736",
  "ENSMUSG00000051747",
  "ENSMUSG00000051748",
  "ENSMUSG00000051758",
  "ENSMUSG00000051768",
  "ENSMUSG00000051769",
  "ENSMUSG00000051777",
  "ENSMUSG00000051786",
  "ENSMUSG00000051788",
  "ENSMUSG00000051790",
  "ENSMUSG00000051793",
  "ENSMUSG00000051802",
  "ENSMUSG00000051804",
  "ENSMUSG00000051805",
  "ENSMUSG00000051811",
  "ENSMUSG00000051817",
  "ENSMUSG00000051832",
  "ENSMUSG00000051839",
  "ENSMUSG00000051844",
  "ENSMUSG00000051851",
  "ENSMUSG00000051853",
  "ENSMUSG00000051855",
  "ENSMUSG00000051860",
  "ENSMUSG00000051864",
  "ENSMUSG00000051877",
  "ENSMUSG00000051879",
  "ENSMUSG00000051890",
  "ENSMUSG00000051896",
  "ENSMUSG00000051900",
  "ENSMUSG00000051906",
  "ENSMUSG00000051910",
  "ENSMUSG00000051917",
  "ENSMUSG00000051920",
  "ENSMUSG00000051934",
  "ENSMUSG00000051936",
  "ENSMUSG00000051950",
  "ENSMUSG00000051951",
  "ENSMUSG00000051952",
  "ENSMUSG00000051956",
  "ENSMUSG00000051965",
  "ENSMUSG00000051969",
  "ENSMUSG00000051977",
  "ENSMUSG00000051978",
  "ENSMUSG00000051980",
  "ENSMUSG00000051984",
  "ENSMUSG00000051985",
  "ENSMUSG00000051986",
  "ENSMUSG00000051989",
  "ENSMUSG00000051998",
  "ENSMUSG00000052012",
  "ENSMUSG00000052013",
  "ENSMUSG00000052014",
  "ENSMUSG00000052026",
  "ENSMUSG00000052033",
  "ENSMUSG00000052040",
  "ENSMUSG00000052056",
  "ENSMUSG00000052058",
  "ENSMUSG00000052062",
  "ENSMUSG00000052075",
  "ENSMUSG00000052085",
  "ENSMUSG00000052087",
  "ENSMUSG00000052099",
  "ENSMUSG00000052102",
  "ENSMUSG00000052105",
  "ENSMUSG00000052117",
  "ENSMUSG00000052125",
  "ENSMUSG00000052131",
  "ENSMUSG00000052133",
  "ENSMUSG00000052135",
  "ENSMUSG00000052137",
  "ENSMUSG00000052139",
  "ENSMUSG00000052142",
  "ENSMUSG00000052144",
  "ENSMUSG00000052146",
  "ENSMUSG00000052151",
  "ENSMUSG00000052155",
  "ENSMUSG00000052160",
  "ENSMUSG00000052180",
  "ENSMUSG00000052182",
  "ENSMUSG00000052187",
  "ENSMUSG00000052188",
  "ENSMUSG00000052212",
  "ENSMUSG00000052214",
  "ENSMUSG00000052217",
  "ENSMUSG00000052221",
  "ENSMUSG00000052229",
  "ENSMUSG00000052234",
  "ENSMUSG00000052236",
  "ENSMUSG00000052248",
  "ENSMUSG00000052253",
  "ENSMUSG00000052270",
  "ENSMUSG00000052271",
  "ENSMUSG00000052273",
  "ENSMUSG00000052276",
  "ENSMUSG00000052281",
  "ENSMUSG00000052291",
  "ENSMUSG00000052295",
  "ENSMUSG00000052296",
  "ENSMUSG00000052298",
  "ENSMUSG00000052299",
  "ENSMUSG00000052301",
  "ENSMUSG00000052302",
  "ENSMUSG00000052303",
  "ENSMUSG00000052305",
  "ENSMUSG00000052310",
  "ENSMUSG00000052316",
  "ENSMUSG00000052331",
  "ENSMUSG00000052336",
  "ENSMUSG00000052353",
  "ENSMUSG00000052363",
  "ENSMUSG00000052364",
  "ENSMUSG00000052368",
  "ENSMUSG00000052369",
  "ENSMUSG00000052371",
  "ENSMUSG00000052372",
  "ENSMUSG00000052373",
  "ENSMUSG00000052374",
  "ENSMUSG00000052382",
  "ENSMUSG00000052384",
  "ENSMUSG00000052387",
  "ENSMUSG00000052392",
  "ENSMUSG00000052395",
  "ENSMUSG00000052396",
  "ENSMUSG00000052397",
  "ENSMUSG00000052403",
  "ENSMUSG00000052406",
  "ENSMUSG00000052407",
  "ENSMUSG00000052415",
  "ENSMUSG00000052417",
  "ENSMUSG00000052419",
  "ENSMUSG00000052423",
  "ENSMUSG00000052428",
  "ENSMUSG00000052430",
  "ENSMUSG00000052435",
  "ENSMUSG00000052446",
  "ENSMUSG00000052456",
  "ENSMUSG00000052459",
  "ENSMUSG00000052469",
  "ENSMUSG00000052477",
  "ENSMUSG00000052479",
  "ENSMUSG00000052485",
  "ENSMUSG00000052488",
  "ENSMUSG00000052496",
  "ENSMUSG00000052504",
  "ENSMUSG00000052508",
  "ENSMUSG00000052512",
  "ENSMUSG00000052516",
  "ENSMUSG00000052525",
  "ENSMUSG00000052533",
  "ENSMUSG00000052534",
  "ENSMUSG00000052537",
  "ENSMUSG00000052539",
  "ENSMUSG00000052544",
  "ENSMUSG00000052549",
  "ENSMUSG00000052551",
  "ENSMUSG00000052554",
  "ENSMUSG00000052557",
  "ENSMUSG00000052560",
  "ENSMUSG00000052562",
  "ENSMUSG00000052563",
  "ENSMUSG00000052565",
  "ENSMUSG00000052566",
  "ENSMUSG00000052572",
  "ENSMUSG00000052581",
  "ENSMUSG00000052584",
  "ENSMUSG00000052593",
  "ENSMUSG00000052605",
  "ENSMUSG00000052613",
  "ENSMUSG00000052616",
  "ENSMUSG00000052625",
  "ENSMUSG00000052629",
  "ENSMUSG00000052631",
  "ENSMUSG00000052632",
  "ENSMUSG00000052642",
  "ENSMUSG00000052656",
  "ENSMUSG00000052658",
  "ENSMUSG00000052673",
  "ENSMUSG00000052675",
  "ENSMUSG00000052676",
  "ENSMUSG00000052681",
  "ENSMUSG00000052684",
  "ENSMUSG00000052688",
  "ENSMUSG00000052698",
  "ENSMUSG00000052707",
  "ENSMUSG00000052712",
  "ENSMUSG00000052713",
  "ENSMUSG00000052724",
  "ENSMUSG00000052726",
  "ENSMUSG00000052727",
  "ENSMUSG00000052730",
  "ENSMUSG00000052736",
  "ENSMUSG00000052738",
  "ENSMUSG00000052748",
  "ENSMUSG00000052749",
  "ENSMUSG00000052751",
  "ENSMUSG00000052752",
  "ENSMUSG00000052759",
  "ENSMUSG00000052760",
  "ENSMUSG00000052763",
  "ENSMUSG00000052767",
  "ENSMUSG00000052769",
  "ENSMUSG00000052776",
  "ENSMUSG00000052783",
  "ENSMUSG00000052785",
  "ENSMUSG00000052794",
  "ENSMUSG00000052798",
  "ENSMUSG00000052812",
  "ENSMUSG00000052818",
  "ENSMUSG00000052819",
  "ENSMUSG00000052821",
  "ENSMUSG00000052833",
  "ENSMUSG00000052837",
  "ENSMUSG00000052848",
  "ENSMUSG00000052850",
  "ENSMUSG00000052852",
  "ENSMUSG00000052854",
  "ENSMUSG00000052861",
  "ENSMUSG00000052865",
  "ENSMUSG00000052889",
  "ENSMUSG00000052906",
  "ENSMUSG00000052911",
  "ENSMUSG00000052914",
  "ENSMUSG00000052915",
  "ENSMUSG00000052917",
  "ENSMUSG00000052920",
  "ENSMUSG00000052921",
  "ENSMUSG00000052922",
  "ENSMUSG00000052926",
  "ENSMUSG00000052928",
  "ENSMUSG00000052934",
  "ENSMUSG00000052942",
  "ENSMUSG00000052949",
  "ENSMUSG00000052951",
  "ENSMUSG00000052955",
  "ENSMUSG00000052957",
  "ENSMUSG00000052962",
  "ENSMUSG00000052974",
  "ENSMUSG00000052981",
  "ENSMUSG00000052997",
  "ENSMUSG00000053004",
  "ENSMUSG00000053007",
  "ENSMUSG00000053012",
  "ENSMUSG00000053024",
  "ENSMUSG00000053025",
  "ENSMUSG00000053030",
  "ENSMUSG00000053044",
  "ENSMUSG00000053046",
  "ENSMUSG00000053049",
  "ENSMUSG00000053054",
  "ENSMUSG00000053062",
  "ENSMUSG00000053063",
  "ENSMUSG00000053070",
  "ENSMUSG00000053080",
  "ENSMUSG00000053081",
  "ENSMUSG00000053091",
  "ENSMUSG00000053093",
  "ENSMUSG00000053094",
  "ENSMUSG00000053101",
  "ENSMUSG00000053110",
  "ENSMUSG00000053111",
  "ENSMUSG00000053113",
  "ENSMUSG00000053117",
  "ENSMUSG00000053119",
  "ENSMUSG00000053121",
  "ENSMUSG00000053129",
  "ENSMUSG00000053134",
  "ENSMUSG00000053137",
  "ENSMUSG00000053141",
  "ENSMUSG00000053146",
  "ENSMUSG00000053153",
  "ENSMUSG00000053158",
  "ENSMUSG00000053161",
  "ENSMUSG00000053164",
  "ENSMUSG00000053166",
  "ENSMUSG00000053175",
  "ENSMUSG00000053181",
  "ENSMUSG00000053182",
  "ENSMUSG00000053184",
  "ENSMUSG00000053185",
  "ENSMUSG00000053192",
  "ENSMUSG00000053194",
  "ENSMUSG00000053198",
  "ENSMUSG00000053199",
  "ENSMUSG00000053205",
  "ENSMUSG00000053211",
  "ENSMUSG00000053216",
  "ENSMUSG00000053217",
  "ENSMUSG00000053219",
  "ENSMUSG00000053226",
  "ENSMUSG00000053228",
  "ENSMUSG00000053251",
  "ENSMUSG00000053253",
  "ENSMUSG00000053263",
  "ENSMUSG00000053268",
  "ENSMUSG00000053279",
  "ENSMUSG00000053286",
  "ENSMUSG00000053287",
  "ENSMUSG00000053289",
  "ENSMUSG00000053293",
  "ENSMUSG00000053297",
  "ENSMUSG00000053303",
  "ENSMUSG00000053310",
  "ENSMUSG00000053317",
  "ENSMUSG00000053318",
  "ENSMUSG00000053329",
  "ENSMUSG00000053332",
  "ENSMUSG00000053333",
  "ENSMUSG00000053334",
  "ENSMUSG00000053338",
  "ENSMUSG00000053347",
  "ENSMUSG00000053353",
  "ENSMUSG00000053360",
  "ENSMUSG00000053367",
  "ENSMUSG00000053368",
  "ENSMUSG00000053375",
  "ENSMUSG00000053388",
  "ENSMUSG00000053389",
  "ENSMUSG00000053390",
  "ENSMUSG00000053391",
  "ENSMUSG00000053395",
  "ENSMUSG00000053398",
  "ENSMUSG00000053399",
  "ENSMUSG00000053411",
  "ENSMUSG00000053414",
  "ENSMUSG00000053420",
  "ENSMUSG00000053436",
  "ENSMUSG00000053441",
  "ENSMUSG00000053442",
  "ENSMUSG00000053453",
  "ENSMUSG00000053460",
  "ENSMUSG00000053461",
  "ENSMUSG00000053465",
  "ENSMUSG00000053469",
  "ENSMUSG00000053470",
  "ENSMUSG00000053475",
  "ENSMUSG00000053477",
  "ENSMUSG00000053483",
  "ENSMUSG00000053490",
  "ENSMUSG00000053499",
  "ENSMUSG00000053508",
  "ENSMUSG00000053510",
  "ENSMUSG00000053519",
  "ENSMUSG00000053522",
  "ENSMUSG00000053528",
  "ENSMUSG00000053536",
  "ENSMUSG00000053545",
  "ENSMUSG00000053550",
  "ENSMUSG00000053552",
  "ENSMUSG00000053553",
  "ENSMUSG00000053559",
  "ENSMUSG00000053560",
  "ENSMUSG00000053565",
  "ENSMUSG00000053574",
  "ENSMUSG00000053580",
  "ENSMUSG00000053581",
  "ENSMUSG00000053588",
  "ENSMUSG00000053593",
  "ENSMUSG00000053600",
  "ENSMUSG00000053604",
  "ENSMUSG00000053613",
  "ENSMUSG00000053615",
  "ENSMUSG00000053617",
  "ENSMUSG00000053624",
  "ENSMUSG00000053626",
  "ENSMUSG00000053640",
  "ENSMUSG00000053641",
  "ENSMUSG00000053644",
  "ENSMUSG00000053646",
  "ENSMUSG00000053647",
  "ENSMUSG00000053654",
  "ENSMUSG00000053675",
  "ENSMUSG00000053678",
  "ENSMUSG00000053684",
  "ENSMUSG00000053693",
  "ENSMUSG00000053695",
  "ENSMUSG00000053702",
  "ENSMUSG00000053706",
  "ENSMUSG00000053714",
  "ENSMUSG00000053716",
  "ENSMUSG00000053719",
  "ENSMUSG00000053729",
  "ENSMUSG00000053730",
  "ENSMUSG00000053742",
  "ENSMUSG00000053746",
  "ENSMUSG00000053747",
  "ENSMUSG00000053749",
  "ENSMUSG00000053754",
  "ENSMUSG00000053765",
  "ENSMUSG00000053768",
  "ENSMUSG00000053769",
  "ENSMUSG00000053774",
  "ENSMUSG00000053783",
  "ENSMUSG00000053790",
  "ENSMUSG00000053797",
  "ENSMUSG00000053799",
  "ENSMUSG00000053801",
  "ENSMUSG00000053815",
  "ENSMUSG00000053819",
  "ENSMUSG00000053820",
  "ENSMUSG00000053821",
  "ENSMUSG00000053825",
  "ENSMUSG00000053835",
  "ENSMUSG00000053838",
  "ENSMUSG00000053841",
  "ENSMUSG00000053846",
  "ENSMUSG00000053852",
  "ENSMUSG00000053856",
  "ENSMUSG00000053861",
  "ENSMUSG00000053862",
  "ENSMUSG00000053863",
  "ENSMUSG00000053868",
  "ENSMUSG00000053870",
  "ENSMUSG00000053873",
  "ENSMUSG00000053877",
  "ENSMUSG00000053886",
  "ENSMUSG00000053889",
  "ENSMUSG00000053896",
  "ENSMUSG00000053897",
  "ENSMUSG00000053898",
  "ENSMUSG00000053907",
  "ENSMUSG00000053909",
  "ENSMUSG00000053914",
  "ENSMUSG00000053916",
  "ENSMUSG00000053929",
  "ENSMUSG00000053930",
  "ENSMUSG00000053931",
  "ENSMUSG00000053950",
  "ENSMUSG00000053957",
  "ENSMUSG00000053961",
  "ENSMUSG00000053963",
  "ENSMUSG00000053964",
  "ENSMUSG00000053965",
  "ENSMUSG00000053977",
  "ENSMUSG00000053979",
  "ENSMUSG00000053985",
  "ENSMUSG00000054000",
  "ENSMUSG00000054003",
  "ENSMUSG00000054005",
  "ENSMUSG00000054006",
  "ENSMUSG00000054008",
  "ENSMUSG00000054013",
  "ENSMUSG00000054021",
  "ENSMUSG00000054027",
  "ENSMUSG00000054033",
  "ENSMUSG00000054034",
  "ENSMUSG00000054036",
  "ENSMUSG00000054046",
  "ENSMUSG00000054051",
  "ENSMUSG00000054052",
  "ENSMUSG00000054054",
  "ENSMUSG00000054057",
  "ENSMUSG00000054061",
  "ENSMUSG00000054069",
  "ENSMUSG00000054072",
  "ENSMUSG00000054074",
  "ENSMUSG00000054079",
  "ENSMUSG00000054083",
  "ENSMUSG00000054091",
  "ENSMUSG00000054099",
  "ENSMUSG00000054102",
  "ENSMUSG00000054106",
  "ENSMUSG00000054115",
  "ENSMUSG00000054116",
  "ENSMUSG00000054117",
  "ENSMUSG00000054128",
  "ENSMUSG00000054134",
  "ENSMUSG00000054135",
  "ENSMUSG00000054136",
  "ENSMUSG00000054141",
  "ENSMUSG00000054142",
  "ENSMUSG00000054146",
  "ENSMUSG00000054150",
  "ENSMUSG00000054156",
  "ENSMUSG00000054160",
  "ENSMUSG00000054161",
  "ENSMUSG00000054162",
  "ENSMUSG00000054169",
  "ENSMUSG00000054181",
  "ENSMUSG00000054191",
  "ENSMUSG00000054196",
  "ENSMUSG00000054200",
  "ENSMUSG00000054204",
  "ENSMUSG00000054206",
  "ENSMUSG00000054215",
  "ENSMUSG00000054226",
  "ENSMUSG00000054236",
  "ENSMUSG00000054237",
  "ENSMUSG00000054252",
  "ENSMUSG00000054256",
  "ENSMUSG00000054258",
  "ENSMUSG00000054263",
  "ENSMUSG00000054277",
  "ENSMUSG00000054280",
  "ENSMUSG00000054293",
  "ENSMUSG00000054302",
  "ENSMUSG00000054309",
  "ENSMUSG00000054312",
  "ENSMUSG00000054320",
  "ENSMUSG00000054321",
  "ENSMUSG00000054325",
  "ENSMUSG00000054342",
  "ENSMUSG00000054351",
  "ENSMUSG00000054360",
  "ENSMUSG00000054362",
  "ENSMUSG00000054364",
  "ENSMUSG00000054381",
  "ENSMUSG00000054383",
  "ENSMUSG00000054385",
  "ENSMUSG00000054387",
  "ENSMUSG00000054400",
  "ENSMUSG00000054404",
  "ENSMUSG00000054405",
  "ENSMUSG00000054406",
  "ENSMUSG00000054408",
  "ENSMUSG00000054409",
  "ENSMUSG00000054412",
  "ENSMUSG00000054414",
  "ENSMUSG00000054418",
  "ENSMUSG00000054422",
  "ENSMUSG00000054423",
  "ENSMUSG00000054426",
  "ENSMUSG00000054428",
  "ENSMUSG00000054431",
  "ENSMUSG00000054434",
  "ENSMUSG00000054435",
  "ENSMUSG00000054446",
  "ENSMUSG00000054452",
  "ENSMUSG00000054453",
  "ENSMUSG00000054455",
  "ENSMUSG00000054457",
  "ENSMUSG00000054459",
  "ENSMUSG00000054469",
  "ENSMUSG00000054474",
  "ENSMUSG00000054477",
  "ENSMUSG00000054484",
  "ENSMUSG00000054488",
  "ENSMUSG00000054493",
  "ENSMUSG00000054497",
  "ENSMUSG00000054498",
  "ENSMUSG00000054499",
  "ENSMUSG00000054509",
  "ENSMUSG00000054510",
  "ENSMUSG00000054514",
  "ENSMUSG00000054517",
  "ENSMUSG00000054519",
  "ENSMUSG00000054520",
  "ENSMUSG00000054523",
  "ENSMUSG00000054526",
  "ENSMUSG00000054537",
  "ENSMUSG00000054555",
  "ENSMUSG00000054556",
  "ENSMUSG00000054568",
  "ENSMUSG00000054580",
  "ENSMUSG00000054582",
  "ENSMUSG00000054589",
  "ENSMUSG00000054594",
  "ENSMUSG00000054598",
  "ENSMUSG00000054604",
  "ENSMUSG00000054611",
  "ENSMUSG00000054612",
  "ENSMUSG00000054618",
  "ENSMUSG00000054619",
  "ENSMUSG00000054622",
  "ENSMUSG00000054626",
  "ENSMUSG00000054630",
  "ENSMUSG00000054640",
  "ENSMUSG00000054641",
  "ENSMUSG00000054648",
  "ENSMUSG00000054659",
  "ENSMUSG00000054662",
  "ENSMUSG00000054666",
  "ENSMUSG00000054667",
  "ENSMUSG00000054672",
  "ENSMUSG00000054675",
  "ENSMUSG00000054676",
  "ENSMUSG00000054679",
  "ENSMUSG00000054690",
  "ENSMUSG00000054693",
  "ENSMUSG00000054702",
  "ENSMUSG00000054708",
  "ENSMUSG00000054715",
  "ENSMUSG00000054716",
  "ENSMUSG00000054717",
  "ENSMUSG00000054720",
  "ENSMUSG00000054723",
  "ENSMUSG00000054727",
  "ENSMUSG00000054728",
  "ENSMUSG00000054733",
  "ENSMUSG00000054737",
  "ENSMUSG00000054745",
  "ENSMUSG00000054746",
  "ENSMUSG00000054752",
  "ENSMUSG00000054753",
  "ENSMUSG00000054757",
  "ENSMUSG00000054759",
  "ENSMUSG00000054763",
  "ENSMUSG00000054764",
  "ENSMUSG00000054766",
  "ENSMUSG00000054770",
  "ENSMUSG00000054779",
  "ENSMUSG00000054792",
  "ENSMUSG00000054808",
  "ENSMUSG00000054814",
  "ENSMUSG00000054822",
  "ENSMUSG00000054823",
  "ENSMUSG00000054827",
  "ENSMUSG00000054836",
  "ENSMUSG00000054843",
  "ENSMUSG00000054850",
  "ENSMUSG00000054855",
  "ENSMUSG00000054863",
  "ENSMUSG00000054871",
  "ENSMUSG00000054874",
  "ENSMUSG00000054885",
  "ENSMUSG00000054889",
  "ENSMUSG00000054890",
  "ENSMUSG00000054892",
  "ENSMUSG00000054893",
  "ENSMUSG00000054894",
  "ENSMUSG00000054901",
  "ENSMUSG00000054905",
  "ENSMUSG00000054910",
  "ENSMUSG00000054920",
  "ENSMUSG00000054931",
  "ENSMUSG00000054932",
  "ENSMUSG00000054934",
  "ENSMUSG00000054938",
  "ENSMUSG00000054939",
  "ENSMUSG00000054940",
  "ENSMUSG00000054942",
  "ENSMUSG00000054944",
  "ENSMUSG00000054945",
  "ENSMUSG00000054951",
  "ENSMUSG00000054958",
  "ENSMUSG00000054966",
  "ENSMUSG00000054967",
  "ENSMUSG00000054976",
  "ENSMUSG00000054978",
  "ENSMUSG00000054986",
  "ENSMUSG00000054988",
  "ENSMUSG00000054999",
  "ENSMUSG00000055003",
  "ENSMUSG00000055010",
  "ENSMUSG00000055013",
  "ENSMUSG00000055015",
  "ENSMUSG00000055022",
  "ENSMUSG00000055024",
  "ENSMUSG00000055026",
  "ENSMUSG00000055027",
  "ENSMUSG00000055030",
  "ENSMUSG00000055033",
  "ENSMUSG00000055044",
  "ENSMUSG00000055045",
  "ENSMUSG00000055048",
  "ENSMUSG00000055053",
  "ENSMUSG00000055061",
  "ENSMUSG00000055065",
  "ENSMUSG00000055067",
  "ENSMUSG00000055069",
  "ENSMUSG00000055078",
  "ENSMUSG00000055088",
  "ENSMUSG00000055095",
  "ENSMUSG00000055102",
  "ENSMUSG00000055108",
  "ENSMUSG00000055109",
  "ENSMUSG00000055110",
  "ENSMUSG00000055114",
  "ENSMUSG00000055116",
  "ENSMUSG00000055125",
  "ENSMUSG00000055128",
  "ENSMUSG00000055134",
  "ENSMUSG00000055137",
  "ENSMUSG00000055138",
  "ENSMUSG00000055148",
  "ENSMUSG00000055150",
  "ENSMUSG00000055159",
  "ENSMUSG00000055170",
  "ENSMUSG00000055172",
  "ENSMUSG00000055177",
  "ENSMUSG00000055184",
  "ENSMUSG00000055188",
  "ENSMUSG00000055193",
  "ENSMUSG00000055194",
  "ENSMUSG00000055197",
  "ENSMUSG00000055198",
  "ENSMUSG00000055200",
  "ENSMUSG00000055202",
  "ENSMUSG00000055204",
  "ENSMUSG00000055210",
  "ENSMUSG00000055214",
  "ENSMUSG00000055235",
  "ENSMUSG00000055239",
  "ENSMUSG00000055240",
  "ENSMUSG00000055254",
  "ENSMUSG00000055296",
  "ENSMUSG00000055298",
  "ENSMUSG00000055301",
  "ENSMUSG00000055302",
  "ENSMUSG00000055305",
  "ENSMUSG00000055312",
  "ENSMUSG00000055319",
  "ENSMUSG00000055320",
  "ENSMUSG00000055322",
  "ENSMUSG00000055323",
  "ENSMUSG00000055333",
  "ENSMUSG00000055334",
  "ENSMUSG00000055341",
  "ENSMUSG00000055357",
  "ENSMUSG00000055368",
  "ENSMUSG00000055370",
  "ENSMUSG00000055371",
  "ENSMUSG00000055373",
  "ENSMUSG00000055385",
  "ENSMUSG00000055401",
  "ENSMUSG00000055407",
  "ENSMUSG00000055408",
  "ENSMUSG00000055409",
  "ENSMUSG00000055415",
  "ENSMUSG00000055421",
  "ENSMUSG00000055424",
  "ENSMUSG00000055430",
  "ENSMUSG00000055435",
  "ENSMUSG00000055436",
  "ENSMUSG00000055446",
  "ENSMUSG00000055447",
  "ENSMUSG00000055471",
  "ENSMUSG00000055480",
  "ENSMUSG00000055485",
  "ENSMUSG00000055489",
  "ENSMUSG00000055491",
  "ENSMUSG00000055493",
  "ENSMUSG00000055494",
  "ENSMUSG00000055515",
  "ENSMUSG00000055523",
  "ENSMUSG00000055531",
  "ENSMUSG00000055538",
  "ENSMUSG00000055540",
  "ENSMUSG00000055541",
  "ENSMUSG00000055546",
  "ENSMUSG00000055547",
  "ENSMUSG00000055553",
  "ENSMUSG00000055555",
  "ENSMUSG00000055560",
  "ENSMUSG00000055561",
  "ENSMUSG00000055567",
  "ENSMUSG00000055571",
  "ENSMUSG00000055609",
  "ENSMUSG00000055610",
  "ENSMUSG00000055612",
  "ENSMUSG00000055629",
  "ENSMUSG00000055632",
  "ENSMUSG00000055633",
  "ENSMUSG00000055639",
  "ENSMUSG00000055643",
  "ENSMUSG00000055652",
  "ENSMUSG00000055653",
  "ENSMUSG00000055660",
  "ENSMUSG00000055670",
  "ENSMUSG00000055675",
  "ENSMUSG00000055679",
  "ENSMUSG00000055681",
  "ENSMUSG00000055691",
  "ENSMUSG00000055692",
  "ENSMUSG00000055707",
  "ENSMUSG00000055717",
  "ENSMUSG00000055720",
  "ENSMUSG00000055723",
  "ENSMUSG00000055725",
  "ENSMUSG00000055730",
  "ENSMUSG00000055732",
  "ENSMUSG00000055733",
  "ENSMUSG00000055737",
  "ENSMUSG00000055745",
  "ENSMUSG00000055746",
  "ENSMUSG00000055748",
  "ENSMUSG00000055760",
  "ENSMUSG00000055761",
  "ENSMUSG00000055762",
  "ENSMUSG00000055775",
  "ENSMUSG00000055780",
  "ENSMUSG00000055789",
  "ENSMUSG00000055799",
  "ENSMUSG00000055805",
  "ENSMUSG00000055809",
  "ENSMUSG00000055811",
  "ENSMUSG00000055817",
  "ENSMUSG00000055818",
  "ENSMUSG00000055820",
  "ENSMUSG00000055826",
  "ENSMUSG00000055827",
  "ENSMUSG00000055833",
  "ENSMUSG00000055835",
  "ENSMUSG00000055838",
  "ENSMUSG00000055839",
  "ENSMUSG00000055850",
  "ENSMUSG00000055862",
  "ENSMUSG00000055865",
  "ENSMUSG00000055866",
  "ENSMUSG00000055874",
  "ENSMUSG00000055882",
  "ENSMUSG00000055884",
  "ENSMUSG00000055891",
  "ENSMUSG00000055895",
  "ENSMUSG00000055900",
  "ENSMUSG00000055912",
  "ENSMUSG00000055917",
  "ENSMUSG00000055923",
  "ENSMUSG00000055926",
  "ENSMUSG00000055932",
  "ENSMUSG00000055933",
  "ENSMUSG00000055937",
  "ENSMUSG00000055942",
  "ENSMUSG00000055943",
  "ENSMUSG00000055944",
  "ENSMUSG00000055945",
  "ENSMUSG00000055960",
  "ENSMUSG00000055961",
  "ENSMUSG00000055963",
  "ENSMUSG00000055971",
  "ENSMUSG00000055972",
  "ENSMUSG00000055976",
  "ENSMUSG00000055978",
  "ENSMUSG00000055980",
  "ENSMUSG00000055991",
  "ENSMUSG00000055994",
  "ENSMUSG00000056004",
  "ENSMUSG00000056008",
  "ENSMUSG00000056014",
  "ENSMUSG00000056018",
  "ENSMUSG00000056019",
  "ENSMUSG00000056023",
  "ENSMUSG00000056025",
  "ENSMUSG00000056031",
  "ENSMUSG00000056032",
  "ENSMUSG00000056035",
  "ENSMUSG00000056043",
  "ENSMUSG00000056050",
  "ENSMUSG00000056054",
  "ENSMUSG00000056055",
  "ENSMUSG00000056061",
  "ENSMUSG00000056069",
  "ENSMUSG00000056071",
  "ENSMUSG00000056073",
  "ENSMUSG00000056076",
  "ENSMUSG00000056078",
  "ENSMUSG00000056089",
  "ENSMUSG00000056091",
  "ENSMUSG00000056115",
  "ENSMUSG00000056116",
  "ENSMUSG00000056121",
  "ENSMUSG00000056124",
  "ENSMUSG00000056128",
  "ENSMUSG00000056130",
  "ENSMUSG00000056131",
  "ENSMUSG00000056133",
  "ENSMUSG00000056144",
  "ENSMUSG00000056145",
  "ENSMUSG00000056153",
  "ENSMUSG00000056155",
  "ENSMUSG00000056158",
  "ENSMUSG00000056162",
  "ENSMUSG00000056167",
  "ENSMUSG00000056174",
  "ENSMUSG00000056184",
  "ENSMUSG00000056185",
  "ENSMUSG00000056197",
  "ENSMUSG00000056201",
  "ENSMUSG00000056203",
  "ENSMUSG00000056204",
  "ENSMUSG00000056209",
  "ENSMUSG00000056211",
  "ENSMUSG00000056214",
  "ENSMUSG00000056215",
  "ENSMUSG00000056216",
  "ENSMUSG00000056220",
  "ENSMUSG00000056222",
  "ENSMUSG00000056223",
  "ENSMUSG00000056228",
  "ENSMUSG00000056234",
  "ENSMUSG00000056258",
  "ENSMUSG00000056260",
  "ENSMUSG00000056267",
  "ENSMUSG00000056268",
  "ENSMUSG00000056270",
  "ENSMUSG00000056271",
  "ENSMUSG00000056281",
  "ENSMUSG00000056288",
  "ENSMUSG00000056290",
  "ENSMUSG00000056296",
  "ENSMUSG00000056300",
  "ENSMUSG00000056305",
  "ENSMUSG00000056306",
  "ENSMUSG00000056310",
  "ENSMUSG00000056313",
  "ENSMUSG00000056328",
  "ENSMUSG00000056329",
  "ENSMUSG00000056332",
  "ENSMUSG00000056342",
  "ENSMUSG00000056350",
  "ENSMUSG00000056359",
  "ENSMUSG00000056367",
  "ENSMUSG00000056370",
  "ENSMUSG00000056379",
  "ENSMUSG00000056383",
  "ENSMUSG00000056394",
  "ENSMUSG00000056399",
  "ENSMUSG00000056411",
  "ENSMUSG00000056413",
  "ENSMUSG00000056418",
  "ENSMUSG00000056423",
  "ENSMUSG00000056426",
  "ENSMUSG00000056427",
  "ENSMUSG00000056429",
  "ENSMUSG00000056436",
  "ENSMUSG00000056445",
  "ENSMUSG00000056458",
  "ENSMUSG00000056459",
  "ENSMUSG00000056476",
  "ENSMUSG00000056481",
  "ENSMUSG00000056486",
  "ENSMUSG00000056487",
  "ENSMUSG00000056492",
  "ENSMUSG00000056493",
  "ENSMUSG00000056494",
  "ENSMUSG00000056498",
  "ENSMUSG00000056501",
  "ENSMUSG00000056508",
  "ENSMUSG00000056509",
  "ENSMUSG00000056515",
  "ENSMUSG00000056529",
  "ENSMUSG00000056531",
  "ENSMUSG00000056536",
  "ENSMUSG00000056537",
  "ENSMUSG00000056544",
  "ENSMUSG00000056553",
  "ENSMUSG00000056564",
  "ENSMUSG00000056569",
  "ENSMUSG00000056579",
  "ENSMUSG00000056586",
  "ENSMUSG00000056592",
  "ENSMUSG00000056596",
  "ENSMUSG00000056598",
  "ENSMUSG00000056600",
  "ENSMUSG00000056602",
  "ENSMUSG00000056605",
  "ENSMUSG00000056608",
  "ENSMUSG00000056612",
  "ENSMUSG00000056617",
  "ENSMUSG00000056629",
  "ENSMUSG00000056632",
  "ENSMUSG00000056643",
  "ENSMUSG00000056656",
  "ENSMUSG00000056665",
  "ENSMUSG00000056666",
  "ENSMUSG00000056671",
  "ENSMUSG00000056673",
  "ENSMUSG00000056679",
  "ENSMUSG00000056687",
  "ENSMUSG00000056692",
  "ENSMUSG00000056696",
  "ENSMUSG00000056698",
  "ENSMUSG00000056699",
  "ENSMUSG00000056706",
  "ENSMUSG00000056708",
  "ENSMUSG00000056716",
  "ENSMUSG00000056718",
  "ENSMUSG00000056724",
  "ENSMUSG00000056728",
  "ENSMUSG00000056735",
  "ENSMUSG00000056737",
  "ENSMUSG00000056738",
  "ENSMUSG00000056749",
  "ENSMUSG00000056752",
  "ENSMUSG00000056753",
  "ENSMUSG00000056755",
  "ENSMUSG00000056758",
  "ENSMUSG00000056763",
  "ENSMUSG00000056770",
  "ENSMUSG00000056771",
  "ENSMUSG00000056782",
  "ENSMUSG00000056812",
  "ENSMUSG00000056820",
  "ENSMUSG00000056821",
  "ENSMUSG00000056822",
  "ENSMUSG00000056824",
  "ENSMUSG00000056829",
  "ENSMUSG00000056832",
  "ENSMUSG00000056851",
  "ENSMUSG00000056854",
  "ENSMUSG00000056856",
  "ENSMUSG00000056858",
  "ENSMUSG00000056863",
  "ENSMUSG00000056870",
  "ENSMUSG00000056880",
  "ENSMUSG00000056883",
  "ENSMUSG00000056888",
  "ENSMUSG00000056895",
  "ENSMUSG00000056899",
  "ENSMUSG00000056900",
  "ENSMUSG00000056901",
  "ENSMUSG00000056910",
  "ENSMUSG00000056912",
  "ENSMUSG00000056917",
  "ENSMUSG00000056919",
  "ENSMUSG00000056921",
  "ENSMUSG00000056926",
  "ENSMUSG00000056938",
  "ENSMUSG00000056941",
  "ENSMUSG00000056946",
  "ENSMUSG00000056947",
  "ENSMUSG00000056952",
  "ENSMUSG00000056959",
  "ENSMUSG00000056962",
  "ENSMUSG00000056966",
  "ENSMUSG00000056972",
  "ENSMUSG00000056973",
  "ENSMUSG00000056978",
  "ENSMUSG00000056987",
  "ENSMUSG00000056995",
  "ENSMUSG00000056999",
  "ENSMUSG00000057000",
  "ENSMUSG00000057003",
  "ENSMUSG00000057037",
  "ENSMUSG00000057047",
  "ENSMUSG00000057050",
  "ENSMUSG00000057054",
  "ENSMUSG00000057058",
  "ENSMUSG00000057060",
  "ENSMUSG00000057067",
  "ENSMUSG00000057068",
  "ENSMUSG00000057069",
  "ENSMUSG00000057072",
  "ENSMUSG00000057074",
  "ENSMUSG00000057092",
  "ENSMUSG00000057093",
  "ENSMUSG00000057098",
  "ENSMUSG00000057101",
  "ENSMUSG00000057103",
  "ENSMUSG00000057110",
  "ENSMUSG00000057113",
  "ENSMUSG00000057116",
  "ENSMUSG00000057123",
  "ENSMUSG00000057130",
  "ENSMUSG00000057132",
  "ENSMUSG00000057133",
  "ENSMUSG00000057134",
  "ENSMUSG00000057135",
  "ENSMUSG00000057137",
  "ENSMUSG00000057143",
  "ENSMUSG00000057147",
  "ENSMUSG00000057149",
  "ENSMUSG00000057156",
  "ENSMUSG00000057163",
  "ENSMUSG00000057170",
  "ENSMUSG00000057173",
  "ENSMUSG00000057174",
  "ENSMUSG00000057176",
  "ENSMUSG00000057177",
  "ENSMUSG00000057179",
  "ENSMUSG00000057181",
  "ENSMUSG00000057182",
  "ENSMUSG00000057191",
  "ENSMUSG00000057193",
  "ENSMUSG00000057195",
  "ENSMUSG00000057203",
  "ENSMUSG00000057207",
  "ENSMUSG00000057215",
  "ENSMUSG00000057219",
  "ENSMUSG00000057228",
  "ENSMUSG00000057229",
  "ENSMUSG00000057230",
  "ENSMUSG00000057234",
  "ENSMUSG00000057236",
  "ENSMUSG00000057240",
  "ENSMUSG00000057246",
  "ENSMUSG00000057265",
  "ENSMUSG00000057270",
  "ENSMUSG00000057278",
  "ENSMUSG00000057280",
  "ENSMUSG00000057286",
  "ENSMUSG00000057321",
  "ENSMUSG00000057322",
  "ENSMUSG00000057329",
  "ENSMUSG00000057335",
  "ENSMUSG00000057337",
  "ENSMUSG00000057342",
  "ENSMUSG00000057346",
  "ENSMUSG00000057349",
  "ENSMUSG00000057359",
  "ENSMUSG00000057363",
  "ENSMUSG00000057367",
  "ENSMUSG00000057375",
  "ENSMUSG00000057378",
  "ENSMUSG00000057381",
  "ENSMUSG00000057388",
  "ENSMUSG00000057396",
  "ENSMUSG00000057400",
  "ENSMUSG00000057402",
  "ENSMUSG00000057406",
  "ENSMUSG00000057409",
  "ENSMUSG00000057421",
  "ENSMUSG00000057424",
  "ENSMUSG00000057425",
  "ENSMUSG00000057439",
  "ENSMUSG00000057440",
  "ENSMUSG00000057443",
  "ENSMUSG00000057446",
  "ENSMUSG00000057447",
  "ENSMUSG00000057454",
  "ENSMUSG00000057455",
  "ENSMUSG00000057457",
  "ENSMUSG00000057461",
  "ENSMUSG00000057464",
  "ENSMUSG00000057465",
  "ENSMUSG00000057469",
  "ENSMUSG00000057497",
  "ENSMUSG00000057503",
  "ENSMUSG00000057506",
  "ENSMUSG00000057522",
  "ENSMUSG00000057530",
  "ENSMUSG00000057531",
  "ENSMUSG00000057534",
  "ENSMUSG00000057540",
  "ENSMUSG00000057541",
  "ENSMUSG00000057551",
  "ENSMUSG00000057554",
  "ENSMUSG00000057561",
  "ENSMUSG00000057564",
  "ENSMUSG00000057572",
  "ENSMUSG00000057596",
  "ENSMUSG00000057604",
  "ENSMUSG00000057606",
  "ENSMUSG00000057609",
  "ENSMUSG00000057614",
  "ENSMUSG00000057615",
  "ENSMUSG00000057637",
  "ENSMUSG00000057649",
  "ENSMUSG00000057650",
  "ENSMUSG00000057654",
  "ENSMUSG00000057667",
  "ENSMUSG00000057672",
  "ENSMUSG00000057674",
  "ENSMUSG00000057691",
  "ENSMUSG00000057697",
  "ENSMUSG00000057699",
  "ENSMUSG00000057706",
  "ENSMUSG00000057710",
  "ENSMUSG00000057715",
  "ENSMUSG00000057716",
  "ENSMUSG00000057719",
  "ENSMUSG00000057722",
  "ENSMUSG00000057723",
  "ENSMUSG00000057726",
  "ENSMUSG00000057729",
  "ENSMUSG00000057738",
  "ENSMUSG00000057751",
  "ENSMUSG00000057754",
  "ENSMUSG00000057761",
  "ENSMUSG00000057766",
  "ENSMUSG00000057777",
  "ENSMUSG00000057778",
  "ENSMUSG00000057788",
  "ENSMUSG00000057789",
  "ENSMUSG00000057801",
  "ENSMUSG00000057802",
  "ENSMUSG00000057805",
  "ENSMUSG00000057816",
  "ENSMUSG00000057817",
  "ENSMUSG00000057835",
  "ENSMUSG00000057841",
  "ENSMUSG00000057842",
  "ENSMUSG00000057858",
  "ENSMUSG00000057880",
  "ENSMUSG00000057890",
  "ENSMUSG00000057894",
  "ENSMUSG00000057895",
  "ENSMUSG00000057897",
  "ENSMUSG00000057899",
  "ENSMUSG00000057903",
  "ENSMUSG00000057913",
  "ENSMUSG00000057914",
  "ENSMUSG00000057948",
  "ENSMUSG00000057963",
  "ENSMUSG00000057967",
  "ENSMUSG00000057969",
  "ENSMUSG00000057977",
  "ENSMUSG00000057981",
  "ENSMUSG00000057982",
  "ENSMUSG00000057997",
  "ENSMUSG00000058005",
  "ENSMUSG00000058006",
  "ENSMUSG00000058013",
  "ENSMUSG00000058014",
  "ENSMUSG00000058017",
  "ENSMUSG00000058019",
  "ENSMUSG00000058022",
  "ENSMUSG00000058028",
  "ENSMUSG00000058030",
  "ENSMUSG00000058046",
  "ENSMUSG00000058052",
  "ENSMUSG00000058056",
  "ENSMUSG00000058057",
  "ENSMUSG00000058063",
  "ENSMUSG00000058070",
  "ENSMUSG00000058071",
  "ENSMUSG00000058076",
  "ENSMUSG00000058084",
  "ENSMUSG00000058093",
  "ENSMUSG00000058099",
  "ENSMUSG00000058114",
  "ENSMUSG00000058124",
  "ENSMUSG00000058132",
  "ENSMUSG00000058135",
  "ENSMUSG00000058145",
  "ENSMUSG00000058152",
  "ENSMUSG00000058159",
  "ENSMUSG00000058163",
  "ENSMUSG00000058172",
  "ENSMUSG00000058173",
  "ENSMUSG00000058174",
  "ENSMUSG00000058183",
  "ENSMUSG00000058188",
  "ENSMUSG00000058192",
  "ENSMUSG00000058194",
  "ENSMUSG00000058200",
  "ENSMUSG00000058207",
  "ENSMUSG00000058216",
  "ENSMUSG00000058230",
  "ENSMUSG00000058239",
  "ENSMUSG00000058240",
  "ENSMUSG00000058244",
  "ENSMUSG00000058246",
  "ENSMUSG00000058248",
  "ENSMUSG00000058250",
  "ENSMUSG00000058251",
  "ENSMUSG00000058252",
  "ENSMUSG00000058254",
  "ENSMUSG00000058258",
  "ENSMUSG00000058260",
  "ENSMUSG00000058267",
  "ENSMUSG00000058270",
  "ENSMUSG00000058275",
  "ENSMUSG00000058287",
  "ENSMUSG00000058290",
  "ENSMUSG00000058291",
  "ENSMUSG00000058295",
  "ENSMUSG00000058297",
  "ENSMUSG00000058298",
  "ENSMUSG00000058301",
  "ENSMUSG00000058317",
  "ENSMUSG00000058318",
  "ENSMUSG00000058325",
  "ENSMUSG00000058331",
  "ENSMUSG00000058351",
  "ENSMUSG00000058354",
  "ENSMUSG00000058355",
  "ENSMUSG00000058368",
  "ENSMUSG00000058385",
  "ENSMUSG00000058388",
  "ENSMUSG00000058392",
  "ENSMUSG00000058396",
  "ENSMUSG00000058398",
  "ENSMUSG00000058400",
  "ENSMUSG00000058402",
  "ENSMUSG00000058407",
  "ENSMUSG00000058420",
  "ENSMUSG00000058427",
  "ENSMUSG00000058435",
  "ENSMUSG00000058440",
  "ENSMUSG00000058441",
  "ENSMUSG00000058444",
  "ENSMUSG00000058446",
  "ENSMUSG00000058454",
  "ENSMUSG00000058470",
  "ENSMUSG00000058486",
  "ENSMUSG00000058488",
  "ENSMUSG00000058491",
  "ENSMUSG00000058498",
  "ENSMUSG00000058499",
  "ENSMUSG00000058513",
  "ENSMUSG00000058515",
  "ENSMUSG00000058523",
  "ENSMUSG00000058550",
  "ENSMUSG00000058568",
  "ENSMUSG00000058569",
  "ENSMUSG00000058571",
  "ENSMUSG00000058579",
  "ENSMUSG00000058586",
  "ENSMUSG00000058587",
  "ENSMUSG00000058589",
  "ENSMUSG00000058594",
  "ENSMUSG00000058600",
  "ENSMUSG00000058618",
  "ENSMUSG00000058624",
  "ENSMUSG00000058626",
  "ENSMUSG00000058628",
  "ENSMUSG00000058631",
  "ENSMUSG00000058638",
  "ENSMUSG00000058643",
  "ENSMUSG00000058655",
  "ENSMUSG00000058656",
  "ENSMUSG00000058659",
  "ENSMUSG00000058662",
  "ENSMUSG00000058665",
  "ENSMUSG00000058669",
  "ENSMUSG00000058670",
  "ENSMUSG00000058672",
  "ENSMUSG00000058690",
  "ENSMUSG00000058692",
  "ENSMUSG00000058704",
  "ENSMUSG00000058706",
  "ENSMUSG00000058709",
  "ENSMUSG00000058715",
  "ENSMUSG00000058717",
  "ENSMUSG00000058728",
  "ENSMUSG00000058729",
  "ENSMUSG00000058740",
  "ENSMUSG00000058741",
  "ENSMUSG00000058743",
  "ENSMUSG00000058748",
  "ENSMUSG00000058755",
  "ENSMUSG00000058756",
  "ENSMUSG00000058761",
  "ENSMUSG00000058773",
  "ENSMUSG00000058793",
  "ENSMUSG00000058794",
  "ENSMUSG00000058799",
  "ENSMUSG00000058802",
  "ENSMUSG00000058806",
  "ENSMUSG00000058807",
  "ENSMUSG00000058812",
  "ENSMUSG00000058818",
  "ENSMUSG00000058820",
  "ENSMUSG00000058831",
  "ENSMUSG00000058833",
  "ENSMUSG00000058835",
  "ENSMUSG00000058856",
  "ENSMUSG00000058881",
  "ENSMUSG00000058884",
  "ENSMUSG00000058886",
  "ENSMUSG00000058897",
  "ENSMUSG00000058900",
  "ENSMUSG00000058904",
  "ENSMUSG00000058914",
  "ENSMUSG00000058921",
  "ENSMUSG00000058925",
  "ENSMUSG00000058934",
  "ENSMUSG00000058935",
  "ENSMUSG00000058952",
  "ENSMUSG00000058966",
  "ENSMUSG00000058975",
  "ENSMUSG00000058976",
  "ENSMUSG00000058979",
  "ENSMUSG00000058981",
  "ENSMUSG00000058997",
  "ENSMUSG00000059003",
  "ENSMUSG00000059005",
  "ENSMUSG00000059013",
  "ENSMUSG00000059022",
  "ENSMUSG00000059023",
  "ENSMUSG00000059027",
  "ENSMUSG00000059030",
  "ENSMUSG00000059031",
  "ENSMUSG00000059040",
  "ENSMUSG00000059042",
  "ENSMUSG00000059043",
  "ENSMUSG00000059049",
  "ENSMUSG00000059060",
  "ENSMUSG00000059069",
  "ENSMUSG00000059077",
  "ENSMUSG00000059087",
  "ENSMUSG00000059089",
  "ENSMUSG00000059105",
  "ENSMUSG00000059106",
  "ENSMUSG00000059108",
  "ENSMUSG00000059113",
  "ENSMUSG00000059114",
  "ENSMUSG00000059119",
  "ENSMUSG00000059136",
  "ENSMUSG00000059142",
  "ENSMUSG00000059146",
  "ENSMUSG00000059149",
  "ENSMUSG00000059169",
  "ENSMUSG00000059173",
  "ENSMUSG00000059182",
  "ENSMUSG00000059183",
  "ENSMUSG00000059187",
  "ENSMUSG00000059189",
  "ENSMUSG00000059201",
  "ENSMUSG00000059203",
  "ENSMUSG00000059206",
  "ENSMUSG00000059208",
  "ENSMUSG00000059213",
  "ENSMUSG00000059218",
  "ENSMUSG00000059230",
  "ENSMUSG00000059244",
  "ENSMUSG00000059246",
  "ENSMUSG00000059248",
  "ENSMUSG00000059251",
  "ENSMUSG00000059256",
  "ENSMUSG00000059263",
  "ENSMUSG00000059273",
  "ENSMUSG00000059277",
  "ENSMUSG00000059278",
  "ENSMUSG00000059279",
  "ENSMUSG00000059288",
  "ENSMUSG00000059291",
  "ENSMUSG00000059303",
  "ENSMUSG00000059305",
  "ENSMUSG00000059316",
  "ENSMUSG00000059319",
  "ENSMUSG00000059323",
  "ENSMUSG00000059325",
  "ENSMUSG00000059326",
  "ENSMUSG00000059327",
  "ENSMUSG00000059334",
  "ENSMUSG00000059336",
  "ENSMUSG00000059343",
  "ENSMUSG00000059355",
  "ENSMUSG00000059361",
  "ENSMUSG00000059366",
  "ENSMUSG00000059371",
  "ENSMUSG00000059375",
  "ENSMUSG00000059379",
  "ENSMUSG00000059382",
  "ENSMUSG00000059383",
  "ENSMUSG00000059395",
  "ENSMUSG00000059397",
  "ENSMUSG00000059401",
  "ENSMUSG00000059406",
  "ENSMUSG00000059408",
  "ENSMUSG00000059409",
  "ENSMUSG00000059410",
  "ENSMUSG00000059411",
  "ENSMUSG00000059412",
  "ENSMUSG00000059423",
  "ENSMUSG00000059429",
  "ENSMUSG00000059430",
  "ENSMUSG00000059434",
  "ENSMUSG00000059436",
  "ENSMUSG00000059439",
  "ENSMUSG00000059447",
  "ENSMUSG00000059455",
  "ENSMUSG00000059456",
  "ENSMUSG00000059460",
  "ENSMUSG00000059463",
  "ENSMUSG00000059473",
  "ENSMUSG00000059474",
  "ENSMUSG00000059475",
  "ENSMUSG00000059479",
  "ENSMUSG00000059481",
  "ENSMUSG00000059482",
  "ENSMUSG00000059486",
  "ENSMUSG00000059488",
  "ENSMUSG00000059493",
  "ENSMUSG00000059495",
  "ENSMUSG00000059503",
  "ENSMUSG00000059504",
  "ENSMUSG00000059518",
  "ENSMUSG00000059534",
  "ENSMUSG00000059540",
  "ENSMUSG00000059547",
  "ENSMUSG00000059554",
  "ENSMUSG00000059555",
  "ENSMUSG00000059562",
  "ENSMUSG00000059586",
  "ENSMUSG00000059588",
  "ENSMUSG00000059595",
  "ENSMUSG00000059602",
  "ENSMUSG00000059606",
  "ENSMUSG00000059610",
  "ENSMUSG00000059623",
  "ENSMUSG00000059625",
  "ENSMUSG00000059631",
  "ENSMUSG00000059632",
  "ENSMUSG00000059639",
  "ENSMUSG00000059648",
  "ENSMUSG00000059654",
  "ENSMUSG00000059657",
  "ENSMUSG00000059663",
  "ENSMUSG00000059668",
  "ENSMUSG00000059669",
  "ENSMUSG00000059674",
  "ENSMUSG00000059687",
  "ENSMUSG00000059689",
  "ENSMUSG00000059708",
  "ENSMUSG00000059713",
  "ENSMUSG00000059714",
  "ENSMUSG00000059729",
  "ENSMUSG00000059734",
  "ENSMUSG00000059741",
  "ENSMUSG00000059742",
  "ENSMUSG00000059743",
  "ENSMUSG00000059762",
  "ENSMUSG00000059763",
  "ENSMUSG00000059772",
  "ENSMUSG00000059791",
  "ENSMUSG00000059796",
  "ENSMUSG00000059810",
  "ENSMUSG00000059811",
  "ENSMUSG00000059816",
  "ENSMUSG00000059820",
  "ENSMUSG00000059821",
  "ENSMUSG00000059824",
  "ENSMUSG00000059832",
  "ENSMUSG00000059834",
  "ENSMUSG00000059839",
  "ENSMUSG00000059842",
  "ENSMUSG00000059845",
  "ENSMUSG00000059851",
  "ENSMUSG00000059852",
  "ENSMUSG00000059854",
  "ENSMUSG00000059857",
  "ENSMUSG00000059862",
  "ENSMUSG00000059864",
  "ENSMUSG00000059866",
  "ENSMUSG00000059867",
  "ENSMUSG00000059873",
  "ENSMUSG00000059878",
  "ENSMUSG00000059883",
  "ENSMUSG00000059887",
  "ENSMUSG00000059890",
  "ENSMUSG00000059891",
  "ENSMUSG00000059895",
  "ENSMUSG00000059897",
  "ENSMUSG00000059898",
  "ENSMUSG00000059900",
  "ENSMUSG00000059901",
  "ENSMUSG00000059908",
  "ENSMUSG00000059910",
  "ENSMUSG00000059920",
  "ENSMUSG00000059921",
  "ENSMUSG00000059923",
  "ENSMUSG00000059934",
  "ENSMUSG00000059939",
  "ENSMUSG00000059956",
  "ENSMUSG00000059964",
  "ENSMUSG00000059970",
  "ENSMUSG00000059972",
  "ENSMUSG00000059974",
  "ENSMUSG00000059975",
  "ENSMUSG00000059981",
  "ENSMUSG00000059991",
  "ENSMUSG00000059994",
  "ENSMUSG00000059995",
  "ENSMUSG00000060002",
  "ENSMUSG00000060012",
  "ENSMUSG00000060017",
  "ENSMUSG00000060024",
  "ENSMUSG00000060029",
  "ENSMUSG00000060030",
  "ENSMUSG00000060032",
  "ENSMUSG00000060034",
  "ENSMUSG00000060038",
  "ENSMUSG00000060044",
  "ENSMUSG00000060049",
  "ENSMUSG00000060057",
  "ENSMUSG00000060063",
  "ENSMUSG00000060073",
  "ENSMUSG00000060081",
  "ENSMUSG00000060084",
  "ENSMUSG00000060090",
  "ENSMUSG00000060098",
  "ENSMUSG00000060112",
  "ENSMUSG00000060114",
  "ENSMUSG00000060121",
  "ENSMUSG00000060126",
  "ENSMUSG00000060131",
  "ENSMUSG00000060147",
  "ENSMUSG00000060149",
  "ENSMUSG00000060152",
  "ENSMUSG00000060166",
  "ENSMUSG00000060176",
  "ENSMUSG00000060177",
  "ENSMUSG00000060180",
  "ENSMUSG00000060181",
  "ENSMUSG00000060187",
  "ENSMUSG00000060188",
  "ENSMUSG00000060201",
  "ENSMUSG00000060204",
  "ENSMUSG00000060205",
  "ENSMUSG00000060206",
  "ENSMUSG00000060212",
  "ENSMUSG00000060216",
  "ENSMUSG00000060224",
  "ENSMUSG00000060227",
  "ENSMUSG00000060240",
  "ENSMUSG00000060244",
  "ENSMUSG00000060245",
  "ENSMUSG00000060254",
  "ENSMUSG00000060257",
  "ENSMUSG00000060260",
  "ENSMUSG00000060261",
  "ENSMUSG00000060268",
  "ENSMUSG00000060275",
  "ENSMUSG00000060279",
  "ENSMUSG00000060284",
  "ENSMUSG00000060288",
  "ENSMUSG00000060301",
  "ENSMUSG00000060303",
  "ENSMUSG00000060311",
  "ENSMUSG00000060314",
  "ENSMUSG00000060317",
  "ENSMUSG00000060332",
  "ENSMUSG00000060335",
  "ENSMUSG00000060336",
  "ENSMUSG00000060371",
  "ENSMUSG00000060373",
  "ENSMUSG00000060375",
  "ENSMUSG00000060376",
  "ENSMUSG00000060380",
  "ENSMUSG00000060397",
  "ENSMUSG00000060402",
  "ENSMUSG00000060404",
  "ENSMUSG00000060407",
  "ENSMUSG00000060412",
  "ENSMUSG00000060416",
  "ENSMUSG00000060424",
  "ENSMUSG00000060427",
  "ENSMUSG00000060429",
  "ENSMUSG00000060441",
  "ENSMUSG00000060445",
  "ENSMUSG00000060450",
  "ENSMUSG00000060461",
  "ENSMUSG00000060469",
  "ENSMUSG00000060470",
  "ENSMUSG00000060475",
  "ENSMUSG00000060477",
  "ENSMUSG00000060480",
  "ENSMUSG00000060487",
  "ENSMUSG00000060491",
  "ENSMUSG00000060499",
  "ENSMUSG00000060503",
  "ENSMUSG00000060508",
  "ENSMUSG00000060509",
  "ENSMUSG00000060510",
  "ENSMUSG00000060512",
  "ENSMUSG00000060519",
  "ENSMUSG00000060523",
  "ENSMUSG00000060534",
  "ENSMUSG00000060538",
  "ENSMUSG00000060548",
  "ENSMUSG00000060556",
  "ENSMUSG00000060560",
  "ENSMUSG00000060565",
  "ENSMUSG00000060568",
  "ENSMUSG00000060572",
  "ENSMUSG00000060579",
  "ENSMUSG00000060583",
  "ENSMUSG00000060586",
  "ENSMUSG00000060591",
  "ENSMUSG00000060594",
  "ENSMUSG00000060600",
  "ENSMUSG00000060601",
  "ENSMUSG00000060613",
  "ENSMUSG00000060615",
  "ENSMUSG00000060621",
  "ENSMUSG00000060630",
  "ENSMUSG00000060636",
  "ENSMUSG00000060639",
  "ENSMUSG00000060657",
  "ENSMUSG00000060663",
  "ENSMUSG00000060671",
  "ENSMUSG00000060673",
  "ENSMUSG00000060675",
  "ENSMUSG00000060679",
  "ENSMUSG00000060681",
  "ENSMUSG00000060685",
  "ENSMUSG00000060688",
  "ENSMUSG00000060691",
  "ENSMUSG00000060699",
  "ENSMUSG00000060701",
  "ENSMUSG00000060703",
  "ENSMUSG00000060708",
  "ENSMUSG00000060715",
  "ENSMUSG00000060716",
  "ENSMUSG00000060724",
  "ENSMUSG00000060726",
  "ENSMUSG00000060733",
  "ENSMUSG00000060735",
  "ENSMUSG00000060738",
  "ENSMUSG00000060739",
  "ENSMUSG00000060742",
  "ENSMUSG00000060743",
  "ENSMUSG00000060747",
  "ENSMUSG00000060756",
  "ENSMUSG00000060759",
  "ENSMUSG00000060765",
  "ENSMUSG00000060771",
  "ENSMUSG00000060780",
  "ENSMUSG00000060787",
  "ENSMUSG00000060791",
  "ENSMUSG00000060794",
  "ENSMUSG00000060798",
  "ENSMUSG00000060802",
  "ENSMUSG00000060803",
  "ENSMUSG00000060807",
  "ENSMUSG00000060808",
  "ENSMUSG00000060816",
  "ENSMUSG00000060843",
  "ENSMUSG00000060860",
  "ENSMUSG00000060862",
  "ENSMUSG00000060863",
  "ENSMUSG00000060878",
  "ENSMUSG00000060882",
  "ENSMUSG00000060888",
  "ENSMUSG00000060890",
  "ENSMUSG00000060904",
  "ENSMUSG00000060913",
  "ENSMUSG00000060918",
  "ENSMUSG00000060923",
  "ENSMUSG00000060924",
  "ENSMUSG00000060935",
  "ENSMUSG00000060938",
  "ENSMUSG00000060950",
  "ENSMUSG00000060961",
  "ENSMUSG00000060962",
  "ENSMUSG00000060967",
  "ENSMUSG00000060969",
  "ENSMUSG00000060974",
  "ENSMUSG00000060981",
  "ENSMUSG00000060985",
  "ENSMUSG00000060988",
  "ENSMUSG00000060992",
  "ENSMUSG00000061000",
  "ENSMUSG00000061013",
  "ENSMUSG00000061022",
  "ENSMUSG00000061024",
  "ENSMUSG00000061028",
  "ENSMUSG00000061032",
  "ENSMUSG00000061039",
  "ENSMUSG00000061046",
  "ENSMUSG00000061048",
  "ENSMUSG00000061068",
  "ENSMUSG00000061079",
  "ENSMUSG00000061080",
  "ENSMUSG00000061082",
  "ENSMUSG00000061086",
  "ENSMUSG00000061099",
  "ENSMUSG00000061100",
  "ENSMUSG00000061111",
  "ENSMUSG00000061118",
  "ENSMUSG00000061119",
  "ENSMUSG00000061126",
  "ENSMUSG00000061130",
  "ENSMUSG00000061132",
  "ENSMUSG00000061136",
  "ENSMUSG00000061143",
  "ENSMUSG00000061144",
  "ENSMUSG00000061165",
  "ENSMUSG00000061171",
  "ENSMUSG00000061175",
  "ENSMUSG00000061184",
  "ENSMUSG00000061186",
  "ENSMUSG00000061195",
  "ENSMUSG00000061207",
  "ENSMUSG00000061208",
  "ENSMUSG00000061210",
  "ENSMUSG00000061232",
  "ENSMUSG00000061244",
  "ENSMUSG00000061259",
  "ENSMUSG00000061273",
  "ENSMUSG00000061286",
  "ENSMUSG00000061288",
  "ENSMUSG00000061292",
  "ENSMUSG00000061296",
  "ENSMUSG00000061298",
  "ENSMUSG00000061306",
  "ENSMUSG00000061311",
  "ENSMUSG00000061313",
  "ENSMUSG00000061322",
  "ENSMUSG00000061336",
  "ENSMUSG00000061342",
  "ENSMUSG00000061353",
  "ENSMUSG00000061356",
  "ENSMUSG00000061360",
  "ENSMUSG00000061361",
  "ENSMUSG00000061367",
  "ENSMUSG00000061371",
  "ENSMUSG00000061374",
  "ENSMUSG00000061376",
  "ENSMUSG00000061387",
  "ENSMUSG00000061388",
  "ENSMUSG00000061393",
  "ENSMUSG00000061397",
  "ENSMUSG00000061410",
  "ENSMUSG00000061411",
  "ENSMUSG00000061414",
  "ENSMUSG00000061436",
  "ENSMUSG00000061451",
  "ENSMUSG00000061455",
  "ENSMUSG00000061458",
  "ENSMUSG00000061461",
  "ENSMUSG00000061462",
  "ENSMUSG00000061469",
  "ENSMUSG00000061474",
  "ENSMUSG00000061479",
  "ENSMUSG00000061482",
  "ENSMUSG00000061489",
  "ENSMUSG00000061510",
  "ENSMUSG00000061517",
  "ENSMUSG00000061518",
  "ENSMUSG00000061520",
  "ENSMUSG00000061525",
  "ENSMUSG00000061527",
  "ENSMUSG00000061531",
  "ENSMUSG00000061533",
  "ENSMUSG00000061535",
  "ENSMUSG00000061536",
  "ENSMUSG00000061540",
  "ENSMUSG00000061544",
  "ENSMUSG00000061549",
  "ENSMUSG00000061559",
  "ENSMUSG00000061561",
  "ENSMUSG00000061576",
  "ENSMUSG00000061577",
  "ENSMUSG00000061578",
  "ENSMUSG00000061584",
  "ENSMUSG00000061589",
  "ENSMUSG00000061601",
  "ENSMUSG00000061602",
  "ENSMUSG00000061603",
  "ENSMUSG00000061607",
  "ENSMUSG00000061613",
  "ENSMUSG00000061614",
  "ENSMUSG00000061615",
  "ENSMUSG00000061616",
  "ENSMUSG00000061633",
  "ENSMUSG00000061637",
  "ENSMUSG00000061650",
  "ENSMUSG00000061653",
  "ENSMUSG00000061665",
  "ENSMUSG00000061666",
  "ENSMUSG00000061689",
  "ENSMUSG00000061701",
  "ENSMUSG00000061702",
  "ENSMUSG00000061707",
  "ENSMUSG00000061718",
  "ENSMUSG00000061723",
  "ENSMUSG00000061731",
  "ENSMUSG00000061740",
  "ENSMUSG00000061742",
  "ENSMUSG00000061751",
  "ENSMUSG00000061758",
  "ENSMUSG00000061759",
  "ENSMUSG00000061762",
  "ENSMUSG00000061769",
  "ENSMUSG00000061778",
  "ENSMUSG00000061780",
  "ENSMUSG00000061798",
  "ENSMUSG00000061802",
  "ENSMUSG00000061808",
  "ENSMUSG00000061809",
  "ENSMUSG00000061815",
  "ENSMUSG00000061816",
  "ENSMUSG00000061825",
  "ENSMUSG00000061829",
  "ENSMUSG00000061838",
  "ENSMUSG00000061847",
  "ENSMUSG00000061859",
  "ENSMUSG00000061875",
  "ENSMUSG00000061877",
  "ENSMUSG00000061878",
  "ENSMUSG00000061882",
  "ENSMUSG00000061887",
  "ENSMUSG00000061894",
  "ENSMUSG00000061898",
  "ENSMUSG00000061904",
  "ENSMUSG00000061906",
  "ENSMUSG00000061911",
  "ENSMUSG00000061923",
  "ENSMUSG00000061928",
  "ENSMUSG00000061937",
  "ENSMUSG00000061947",
  "ENSMUSG00000061950",
  "ENSMUSG00000061952",
  "ENSMUSG00000061959",
  "ENSMUSG00000061961",
  "ENSMUSG00000061972",
  "ENSMUSG00000061974",
  "ENSMUSG00000061977",
  "ENSMUSG00000061979",
  "ENSMUSG00000061981",
  "ENSMUSG00000061983",
  "ENSMUSG00000061984",
  "ENSMUSG00000061991",
  "ENSMUSG00000062007",
  "ENSMUSG00000062012",
  "ENSMUSG00000062014",
  "ENSMUSG00000062017",
  "ENSMUSG00000062028",
  "ENSMUSG00000062031",
  "ENSMUSG00000062037",
  "ENSMUSG00000062040",
  "ENSMUSG00000062042",
  "ENSMUSG00000062044",
  "ENSMUSG00000062046",
  "ENSMUSG00000062054",
  "ENSMUSG00000062061",
  "ENSMUSG00000062064",
  "ENSMUSG00000062070",
  "ENSMUSG00000062074",
  "ENSMUSG00000062075",
  "ENSMUSG00000062077",
  "ENSMUSG00000062078",
  "ENSMUSG00000062082",
  "ENSMUSG00000062101",
  "ENSMUSG00000062103",
  "ENSMUSG00000062105",
  "ENSMUSG00000062110",
  "ENSMUSG00000062115",
  "ENSMUSG00000062116",
  "ENSMUSG00000062121",
  "ENSMUSG00000062124",
  "ENSMUSG00000062127",
  "ENSMUSG00000062132",
  "ENSMUSG00000062142",
  "ENSMUSG00000062148",
  "ENSMUSG00000062151",
  "ENSMUSG00000062154",
  "ENSMUSG00000062157",
  "ENSMUSG00000062165",
  "ENSMUSG00000062168",
  "ENSMUSG00000062169",
  "ENSMUSG00000062170",
  "ENSMUSG00000062175",
  "ENSMUSG00000062181",
  "ENSMUSG00000062184",
  "ENSMUSG00000062186",
  "ENSMUSG00000062190",
  "ENSMUSG00000062198",
  "ENSMUSG00000062199",
  "ENSMUSG00000062202",
  "ENSMUSG00000062203",
  "ENSMUSG00000062209",
  "ENSMUSG00000062210",
  "ENSMUSG00000062224",
  "ENSMUSG00000062232",
  "ENSMUSG00000062248",
  "ENSMUSG00000062252",
  "ENSMUSG00000062257",
  "ENSMUSG00000062275",
  "ENSMUSG00000062282",
  "ENSMUSG00000062296",
  "ENSMUSG00000062300",
  "ENSMUSG00000062309",
  "ENSMUSG00000062310",
  "ENSMUSG00000062312",
  "ENSMUSG00000062314",
  "ENSMUSG00000062319",
  "ENSMUSG00000062327",
  "ENSMUSG00000062328",
  "ENSMUSG00000062329",
  "ENSMUSG00000062342",
  "ENSMUSG00000062345",
  "ENSMUSG00000062352",
  "ENSMUSG00000062369",
  "ENSMUSG00000062372",
  "ENSMUSG00000062373",
  "ENSMUSG00000062376",
  "ENSMUSG00000062380",
  "ENSMUSG00000062382",
  "ENSMUSG00000062391",
  "ENSMUSG00000062393",
  "ENSMUSG00000062397",
  "ENSMUSG00000062400",
  "ENSMUSG00000062410",
  "ENSMUSG00000062421",
  "ENSMUSG00000062426",
  "ENSMUSG00000062432",
  "ENSMUSG00000062434",
  "ENSMUSG00000062438",
  "ENSMUSG00000062444",
  "ENSMUSG00000062464",
  "ENSMUSG00000062470",
  "ENSMUSG00000062478",
  "ENSMUSG00000062480",
  "ENSMUSG00000062483",
  "ENSMUSG00000062488",
  "ENSMUSG00000062496",
  "ENSMUSG00000062497",
  "ENSMUSG00000062510",
  "ENSMUSG00000062515",
  "ENSMUSG00000062519",
  "ENSMUSG00000062524",
  "ENSMUSG00000062526",
  "ENSMUSG00000062527",
  "ENSMUSG00000062528",
  "ENSMUSG00000062542",
  "ENSMUSG00000062545",
  "ENSMUSG00000062546",
  "ENSMUSG00000062551",
  "ENSMUSG00000062556",
  "ENSMUSG00000062563",
  "ENSMUSG00000062564",
  "ENSMUSG00000062580",
  "ENSMUSG00000062585",
  "ENSMUSG00000062590",
  "ENSMUSG00000062591",
  "ENSMUSG00000062598",
  "ENSMUSG00000062608",
  "ENSMUSG00000062609",
  "ENSMUSG00000062619",
  "ENSMUSG00000062621",
  "ENSMUSG00000062627",
  "ENSMUSG00000062629",
  "ENSMUSG00000062638",
  "ENSMUSG00000062646",
  "ENSMUSG00000062649",
  "ENSMUSG00000062651",
  "ENSMUSG00000062661",
  "ENSMUSG00000062687",
  "ENSMUSG00000062691",
  "ENSMUSG00000062694",
  "ENSMUSG00000062704",
  "ENSMUSG00000062705",
  "ENSMUSG00000062712",
  "ENSMUSG00000062713",
  "ENSMUSG00000062729",
  "ENSMUSG00000062732",
  "ENSMUSG00000062743",
  "ENSMUSG00000062751",
  "ENSMUSG00000062753",
  "ENSMUSG00000062757",
  "ENSMUSG00000062760",
  "ENSMUSG00000062761",
  "ENSMUSG00000062762",
  "ENSMUSG00000062773",
  "ENSMUSG00000062778",
  "ENSMUSG00000062782",
  "ENSMUSG00000062785",
  "ENSMUSG00000062791",
  "ENSMUSG00000062793",
  "ENSMUSG00000062794",
  "ENSMUSG00000062797",
  "ENSMUSG00000062814",
  "ENSMUSG00000062818",
  "ENSMUSG00000062822",
  "ENSMUSG00000062826",
  "ENSMUSG00000062833",
  "ENSMUSG00000062859",
  "ENSMUSG00000062861",
  "ENSMUSG00000062866",
  "ENSMUSG00000062867",
  "ENSMUSG00000062868",
  "ENSMUSG00000062878",
  "ENSMUSG00000062892",
  "ENSMUSG00000062901",
  "ENSMUSG00000062905",
  "ENSMUSG00000062906",
  "ENSMUSG00000062908",
  "ENSMUSG00000062929",
  "ENSMUSG00000062931",
  "ENSMUSG00000062937",
  "ENSMUSG00000062939",
  "ENSMUSG00000062944",
  "ENSMUSG00000062949",
  "ENSMUSG00000062952",
  "ENSMUSG00000062960",
  "ENSMUSG00000062961",
  "ENSMUSG00000062963",
  "ENSMUSG00000062980",
  "ENSMUSG00000062981",
  "ENSMUSG00000062991",
  "ENSMUSG00000062995",
  "ENSMUSG00000063011",
  "ENSMUSG00000063015",
  "ENSMUSG00000063018",
  "ENSMUSG00000063019",
  "ENSMUSG00000063020",
  "ENSMUSG00000063021",
  "ENSMUSG00000063047",
  "ENSMUSG00000063049",
  "ENSMUSG00000063052",
  "ENSMUSG00000063060",
  "ENSMUSG00000063063",
  "ENSMUSG00000063065",
  "ENSMUSG00000063077",
  "ENSMUSG00000063087",
  "ENSMUSG00000063089",
  "ENSMUSG00000063106",
  "ENSMUSG00000063108",
  "ENSMUSG00000063109",
  "ENSMUSG00000063120",
  "ENSMUSG00000063129",
  "ENSMUSG00000063130",
  "ENSMUSG00000063133",
  "ENSMUSG00000063142",
  "ENSMUSG00000063145",
  "ENSMUSG00000063146",
  "ENSMUSG00000063157",
  "ENSMUSG00000063160",
  "ENSMUSG00000063163",
  "ENSMUSG00000063172",
  "ENSMUSG00000063173",
  "ENSMUSG00000063176",
  "ENSMUSG00000063177",
  "ENSMUSG00000063179",
  "ENSMUSG00000063188",
  "ENSMUSG00000063193",
  "ENSMUSG00000063200",
  "ENSMUSG00000063216",
  "ENSMUSG00000063221",
  "ENSMUSG00000063225",
  "ENSMUSG00000063229",
  "ENSMUSG00000063230",
  "ENSMUSG00000063232",
  "ENSMUSG00000063234",
  "ENSMUSG00000063235",
  "ENSMUSG00000063236",
  "ENSMUSG00000063239",
  "ENSMUSG00000063240",
  "ENSMUSG00000063251",
  "ENSMUSG00000063252",
  "ENSMUSG00000063253",
  "ENSMUSG00000063260",
  "ENSMUSG00000063268",
  "ENSMUSG00000063273",
  "ENSMUSG00000063275",
  "ENSMUSG00000063281",
  "ENSMUSG00000063296",
  "ENSMUSG00000063297",
  "ENSMUSG00000063305",
  "ENSMUSG00000063316",
  "ENSMUSG00000063317",
  "ENSMUSG00000063334",
  "ENSMUSG00000063350",
  "ENSMUSG00000063354",
  "ENSMUSG00000063358",
  "ENSMUSG00000063362",
  "ENSMUSG00000063364",
  "ENSMUSG00000063374",
  "ENSMUSG00000063376",
  "ENSMUSG00000063380",
  "ENSMUSG00000063382",
  "ENSMUSG00000063386",
  "ENSMUSG00000063394",
  "ENSMUSG00000063406",
  "ENSMUSG00000063409",
  "ENSMUSG00000063410",
  "ENSMUSG00000063415",
  "ENSMUSG00000063428",
  "ENSMUSG00000063430",
  "ENSMUSG00000063434",
  "ENSMUSG00000063439",
  "ENSMUSG00000063445",
  "ENSMUSG00000063446",
  "ENSMUSG00000063447",
  "ENSMUSG00000063450",
  "ENSMUSG00000063455",
  "ENSMUSG00000063458",
  "ENSMUSG00000063478",
  "ENSMUSG00000063480",
  "ENSMUSG00000063488",
  "ENSMUSG00000063506",
  "ENSMUSG00000063507",
  "ENSMUSG00000063511",
  "ENSMUSG00000063522",
  "ENSMUSG00000063529",
  "ENSMUSG00000063535",
  "ENSMUSG00000063549",
  "ENSMUSG00000063550",
  "ENSMUSG00000063558",
  "ENSMUSG00000063564",
  "ENSMUSG00000063568",
  "ENSMUSG00000063576",
  "ENSMUSG00000063582",
  "ENSMUSG00000063583",
  "ENSMUSG00000063590",
  "ENSMUSG00000063594",
  "ENSMUSG00000063600",
  "ENSMUSG00000063605",
  "ENSMUSG00000063611",
  "ENSMUSG00000063615",
  "ENSMUSG00000063623",
  "ENSMUSG00000063626",
  "ENSMUSG00000063632",
  "ENSMUSG00000063646",
  "ENSMUSG00000063651",
  "ENSMUSG00000063652",
  "ENSMUSG00000063656",
  "ENSMUSG00000063659",
  "ENSMUSG00000063660",
  "ENSMUSG00000063661",
  "ENSMUSG00000063663",
  "ENSMUSG00000063672",
  "ENSMUSG00000063681",
  "ENSMUSG00000063683",
  "ENSMUSG00000063687",
  "ENSMUSG00000063689",
  "ENSMUSG00000063694",
  "ENSMUSG00000063698",
  "ENSMUSG00000063704",
  "ENSMUSG00000063713",
  "ENSMUSG00000063714",
  "ENSMUSG00000063715",
  "ENSMUSG00000063727",
  "ENSMUSG00000063728",
  "ENSMUSG00000063730",
  "ENSMUSG00000063751",
  "ENSMUSG00000063760",
  "ENSMUSG00000063762",
  "ENSMUSG00000063764",
  "ENSMUSG00000063765",
  "ENSMUSG00000063767",
  "ENSMUSG00000063777",
  "ENSMUSG00000063779",
  "ENSMUSG00000063780",
  "ENSMUSG00000063785",
  "ENSMUSG00000063787",
  "ENSMUSG00000063796",
  "ENSMUSG00000063800",
  "ENSMUSG00000063801",
  "ENSMUSG00000063802",
  "ENSMUSG00000063804",
  "ENSMUSG00000063808",
  "ENSMUSG00000063810",
  "ENSMUSG00000063820",
  "ENSMUSG00000063821",
  "ENSMUSG00000063823",
  "ENSMUSG00000063827",
  "ENSMUSG00000063838",
  "ENSMUSG00000063842",
  "ENSMUSG00000063844",
  "ENSMUSG00000063849",
  "ENSMUSG00000063851",
  "ENSMUSG00000063856",
  "ENSMUSG00000063867",
  "ENSMUSG00000063870",
  "ENSMUSG00000063873",
  "ENSMUSG00000063881",
  "ENSMUSG00000063882",
  "ENSMUSG00000063884",
  "ENSMUSG00000063887",
  "ENSMUSG00000063888",
  "ENSMUSG00000063889",
  "ENSMUSG00000063894",
  "ENSMUSG00000063900",
  "ENSMUSG00000063903",
  "ENSMUSG00000063904",
  "ENSMUSG00000063919",
  "ENSMUSG00000063931",
  "ENSMUSG00000063935",
  "ENSMUSG00000063952",
  "ENSMUSG00000063971",
  "ENSMUSG00000063972",
  "ENSMUSG00000063975",
  "ENSMUSG00000063994",
  "ENSMUSG00000063998",
  "ENSMUSG00000064006",
  "ENSMUSG00000064010",
  "ENSMUSG00000064016",
  "ENSMUSG00000064023",
  "ENSMUSG00000064030",
  "ENSMUSG00000064032",
  "ENSMUSG00000064036",
  "ENSMUSG00000064037",
  "ENSMUSG00000064039",
  "ENSMUSG00000064043",
  "ENSMUSG00000064044",
  "ENSMUSG00000064052",
  "ENSMUSG00000064057",
  "ENSMUSG00000064061",
  "ENSMUSG00000064063",
  "ENSMUSG00000064065",
  "ENSMUSG00000064068",
  "ENSMUSG00000064080",
  "ENSMUSG00000064084",
  "ENSMUSG00000064090",
  "ENSMUSG00000064105",
  "ENSMUSG00000064109",
  "ENSMUSG00000064110",
  "ENSMUSG00000064115",
  "ENSMUSG00000064120",
  "ENSMUSG00000064121",
  "ENSMUSG00000064125",
  "ENSMUSG00000064127",
  "ENSMUSG00000064128",
  "ENSMUSG00000064137",
  "ENSMUSG00000064138",
  "ENSMUSG00000064140",
  "ENSMUSG00000064141",
  "ENSMUSG00000064145",
  "ENSMUSG00000064147",
  "ENSMUSG00000064156",
  "ENSMUSG00000064158",
  "ENSMUSG00000064165",
  "ENSMUSG00000064177",
  "ENSMUSG00000064179",
  "ENSMUSG00000064181",
  "ENSMUSG00000064194",
  "ENSMUSG00000064201",
  "ENSMUSG00000064202",
  "ENSMUSG00000064210",
  "ENSMUSG00000064215",
  "ENSMUSG00000064223",
  "ENSMUSG00000064224",
  "ENSMUSG00000064225",
  "ENSMUSG00000064232",
  "ENSMUSG00000064246",
  "ENSMUSG00000064247",
  "ENSMUSG00000064252",
  "ENSMUSG00000064254",
  "ENSMUSG00000064259",
  "ENSMUSG00000064262",
  "ENSMUSG00000064263",
  "ENSMUSG00000064264",
  "ENSMUSG00000064267",
  "ENSMUSG00000064272",
  "ENSMUSG00000064280",
  "ENSMUSG00000064284",
  "ENSMUSG00000064289",
  "ENSMUSG00000064293",
  "ENSMUSG00000064294",
  "ENSMUSG00000064299",
  "ENSMUSG00000064302",
  "ENSMUSG00000064307",
  "ENSMUSG00000064308",
  "ENSMUSG00000064310",
  "ENSMUSG00000064325",
  "ENSMUSG00000064326",
  "ENSMUSG00000064329",
  "ENSMUSG00000064330",
  "ENSMUSG00000064333",
  "ENSMUSG00000064341",
  "ENSMUSG00000064345",
  "ENSMUSG00000064356",
  "ENSMUSG00000064363",
  "ENSMUSG00000064367",
  "ENSMUSG00000064368",
  "ENSMUSG00000064370",
  "ENSMUSG00000064373",
  "ENSMUSG00000065947",
  "ENSMUSG00000065952",
  "ENSMUSG00000065954",
  "ENSMUSG00000065968",
  "ENSMUSG00000065979",
  "ENSMUSG00000065987",
  "ENSMUSG00000065990",
  "ENSMUSG00000065999",
  "ENSMUSG00000066000",
  "ENSMUSG00000066026",
  "ENSMUSG00000066030",
  "ENSMUSG00000066031",
  "ENSMUSG00000066036",
  "ENSMUSG00000066037",
  "ENSMUSG00000066042",
  "ENSMUSG00000066043",
  "ENSMUSG00000066057",
  "ENSMUSG00000066058",
  "ENSMUSG00000066060",
  "ENSMUSG00000066061",
  "ENSMUSG00000066072",
  "ENSMUSG00000066090",
  "ENSMUSG00000066097",
  "ENSMUSG00000066101",
  "ENSMUSG00000066108",
  "ENSMUSG00000066113",
  "ENSMUSG00000066122",
  "ENSMUSG00000066129",
  "ENSMUSG00000066141",
  "ENSMUSG00000066148",
  "ENSMUSG00000066149",
  "ENSMUSG00000066150",
  "ENSMUSG00000066151",
  "ENSMUSG00000066152",
  "ENSMUSG00000066158",
  "ENSMUSG00000066170",
  "ENSMUSG00000066175",
  "ENSMUSG00000066176",
  "ENSMUSG00000066178",
  "ENSMUSG00000066189",
  "ENSMUSG00000066191",
  "ENSMUSG00000066196",
  "ENSMUSG00000066197",
  "ENSMUSG00000066224",
  "ENSMUSG00000066232",
  "ENSMUSG00000066233",
  "ENSMUSG00000066235",
  "ENSMUSG00000066239",
  "ENSMUSG00000066240",
  "ENSMUSG00000066241",
  "ENSMUSG00000066242",
  "ENSMUSG00000066257",
  "ENSMUSG00000066258",
  "ENSMUSG00000066262",
  "ENSMUSG00000066268",
  "ENSMUSG00000066269",
  "ENSMUSG00000066272",
  "ENSMUSG00000066273",
  "ENSMUSG00000066278",
  "ENSMUSG00000066279",
  "ENSMUSG00000066306",
  "ENSMUSG00000066319",
  "ENSMUSG00000066324",
  "ENSMUSG00000066357",
  "ENSMUSG00000066361",
  "ENSMUSG00000066363",
  "ENSMUSG00000066364",
  "ENSMUSG00000066368",
  "ENSMUSG00000066372",
  "ENSMUSG00000066378",
  "ENSMUSG00000066382",
  "ENSMUSG00000066383",
  "ENSMUSG00000066392",
  "ENSMUSG00000066406",
  "ENSMUSG00000066415",
  "ENSMUSG00000066438",
  "ENSMUSG00000066440",
  "ENSMUSG00000066441",
  "ENSMUSG00000066456",
  "ENSMUSG00000066463",
  "ENSMUSG00000066477",
  "ENSMUSG00000066500",
  "ENSMUSG00000066510",
  "ENSMUSG00000066512",
  "ENSMUSG00000066513",
  "ENSMUSG00000066515",
  "ENSMUSG00000066516",
  "ENSMUSG00000066551",
  "ENSMUSG00000066571",
  "ENSMUSG00000066583",
  "ENSMUSG00000066584",
  "ENSMUSG00000066586",
  "ENSMUSG00000066595",
  "ENSMUSG00000066607",
  "ENSMUSG00000066613",
  "ENSMUSG00000066637",
  "ENSMUSG00000066640",
  "ENSMUSG00000066643",
  "ENSMUSG00000066652",
  "ENSMUSG00000066671",
  "ENSMUSG00000066672",
  "ENSMUSG00000066677",
  "ENSMUSG00000066682",
  "ENSMUSG00000066684",
  "ENSMUSG00000066687",
  "ENSMUSG00000066688",
  "ENSMUSG00000066704",
  "ENSMUSG00000066705",
  "ENSMUSG00000066720",
  "ENSMUSG00000066721",
  "ENSMUSG00000066747",
  "ENSMUSG00000066748",
  "ENSMUSG00000066749",
  "ENSMUSG00000066750",
  "ENSMUSG00000066755",
  "ENSMUSG00000066756",
  "ENSMUSG00000066760",
  "ENSMUSG00000066772",
  "ENSMUSG00000066797",
  "ENSMUSG00000066798",
  "ENSMUSG00000066800",
  "ENSMUSG00000066803",
  "ENSMUSG00000066804",
  "ENSMUSG00000066820",
  "ENSMUSG00000066829",
  "ENSMUSG00000066839",
  "ENSMUSG00000066842",
  "ENSMUSG00000066850",
  "ENSMUSG00000066861",
  "ENSMUSG00000066867",
  "ENSMUSG00000066877",
  "ENSMUSG00000066880",
  "ENSMUSG00000066892",
  "ENSMUSG00000066894",
  "ENSMUSG00000066896",
  "ENSMUSG00000066897",
  "ENSMUSG00000066899",
  "ENSMUSG00000066900",
  "ENSMUSG00000066905",
  "ENSMUSG00000066936",
  "ENSMUSG00000066938",
  "ENSMUSG00000066952",
  "ENSMUSG00000066964",
  "ENSMUSG00000066975",
  "ENSMUSG00000066979",
  "ENSMUSG00000067001",
  "ENSMUSG00000067006",
  "ENSMUSG00000067010",
  "ENSMUSG00000067028",
  "ENSMUSG00000067064",
  "ENSMUSG00000067071",
  "ENSMUSG00000067081",
  "ENSMUSG00000067085",
  "ENSMUSG00000067101",
  "ENSMUSG00000067103",
  "ENSMUSG00000067144",
  "ENSMUSG00000067148",
  "ENSMUSG00000067149",
  "ENSMUSG00000067150",
  "ENSMUSG00000067158",
  "ENSMUSG00000067173",
  "ENSMUSG00000067186",
  "ENSMUSG00000067194",
  "ENSMUSG00000067199",
  "ENSMUSG00000067201",
  "ENSMUSG00000067206",
  "ENSMUSG00000067212",
  "ENSMUSG00000067215",
  "ENSMUSG00000067219",
  "ENSMUSG00000067220",
  "ENSMUSG00000067225",
  "ENSMUSG00000067231",
  "ENSMUSG00000067235",
  "ENSMUSG00000067242",
  "ENSMUSG00000067261",
  "ENSMUSG00000067274",
  "ENSMUSG00000067276",
  "ENSMUSG00000067279",
  "ENSMUSG00000067285",
  "ENSMUSG00000067288",
  "ENSMUSG00000067297",
  "ENSMUSG00000067299",
  "ENSMUSG00000067336",
  "ENSMUSG00000067338",
  "ENSMUSG00000067341",
  "ENSMUSG00000067352",
  "ENSMUSG00000067365",
  "ENSMUSG00000067367",
  "ENSMUSG00000067369",
  "ENSMUSG00000067370",
  "ENSMUSG00000067377",
  "ENSMUSG00000067399",
  "ENSMUSG00000067424",
  "ENSMUSG00000067430",
  "ENSMUSG00000067438",
  "ENSMUSG00000067455",
  "ENSMUSG00000067513",
  "ENSMUSG00000067519",
  "ENSMUSG00000067522",
  "ENSMUSG00000067524",
  "ENSMUSG00000067525",
  "ENSMUSG00000067526",
  "ENSMUSG00000067528",
  "ENSMUSG00000067529",
  "ENSMUSG00000067541",
  "ENSMUSG00000067543",
  "ENSMUSG00000067545",
  "ENSMUSG00000067567",
  "ENSMUSG00000067571",
  "ENSMUSG00000067577",
  "ENSMUSG00000067578",
  "ENSMUSG00000067586",
  "ENSMUSG00000067594",
  "ENSMUSG00000067597",
  "ENSMUSG00000067599",
  "ENSMUSG00000067604",
  "ENSMUSG00000067610",
  "ENSMUSG00000067614",
  "ENSMUSG00000067616",
  "ENSMUSG00000067629",
  "ENSMUSG00000067642",
  "ENSMUSG00000067649",
  "ENSMUSG00000067653",
  "ENSMUSG00000067656",
  "ENSMUSG00000067679",
  "ENSMUSG00000067684",
  "ENSMUSG00000067698",
  "ENSMUSG00000067702",
  "ENSMUSG00000067704",
  "ENSMUSG00000067713",
  "ENSMUSG00000067714",

  "ENSMUSG00000067722",
  "ENSMUSG00000067724",
  "ENSMUSG00000067745",
  "ENSMUSG00000067764",
  "ENSMUSG00000067767",
  "ENSMUSG00000067773",
  "ENSMUSG00000067780",
  "ENSMUSG00000067786",
  "ENSMUSG00000067787",
  "ENSMUSG00000067795",
  "ENSMUSG00000067813",
  "ENSMUSG00000067818",
  "ENSMUSG00000067825",
  "ENSMUSG00000067835",
  "ENSMUSG00000067847",
  "ENSMUSG00000067851",
  "ENSMUSG00000067860",
  "ENSMUSG00000067872",
  "ENSMUSG00000067873",
  "ENSMUSG00000067878",
  "ENSMUSG00000067879",
  "ENSMUSG00000067889",
  "ENSMUSG00000067925",
  "ENSMUSG00000067928",
  "ENSMUSG00000067931",
  "ENSMUSG00000067942",
  "ENSMUSG00000067951",
  "ENSMUSG00000067971",
  "ENSMUSG00000067995",
  "ENSMUSG00000068008",
  "ENSMUSG00000068009",
  "ENSMUSG00000068011",
  "ENSMUSG00000068015",
  "ENSMUSG00000068036",
  "ENSMUSG00000068037",
  "ENSMUSG00000068039",
  "ENSMUSG00000068040",
  "ENSMUSG00000068048",
  "ENSMUSG00000068062",
  "ENSMUSG00000068067",
  "ENSMUSG00000068071",
  "ENSMUSG00000068073",
  "ENSMUSG00000068074",
  "ENSMUSG00000068075",
  "ENSMUSG00000068078",
  "ENSMUSG00000068079",
  "ENSMUSG00000068082",
  "ENSMUSG00000068083",
  "ENSMUSG00000068085",
  "ENSMUSG00000068086",
  "ENSMUSG00000068099",
  "ENSMUSG00000068101",
  "ENSMUSG00000068105",
  "ENSMUSG00000068113",
  "ENSMUSG00000068114",
  "ENSMUSG00000068115",
  "ENSMUSG00000068117",
  "ENSMUSG00000068122",
  "ENSMUSG00000068129",
  "ENSMUSG00000068130",
  "ENSMUSG00000068134",
  "ENSMUSG00000068154",
  "ENSMUSG00000068167",
  "ENSMUSG00000068173",
  "ENSMUSG00000068180",
  "ENSMUSG00000068182",
  "ENSMUSG00000068184",
  "ENSMUSG00000068196",
  "ENSMUSG00000068205",
  "ENSMUSG00000068206",
  "ENSMUSG00000068219",
  "ENSMUSG00000068220",
  "ENSMUSG00000068227",
  "ENSMUSG00000068231",
  "ENSMUSG00000068232",
  "ENSMUSG00000068234",
  "ENSMUSG00000068245",
  "ENSMUSG00000068246",
  "ENSMUSG00000068250",
  "ENSMUSG00000068259",
  "ENSMUSG00000068263",
  "ENSMUSG00000068264",
  "ENSMUSG00000068267",
  "ENSMUSG00000068270",
  "ENSMUSG00000068284",
  "ENSMUSG00000068290",
  "ENSMUSG00000068299",
  "ENSMUSG00000068302",
  "ENSMUSG00000068323",
  "ENSMUSG00000068327",
  "ENSMUSG00000068328",
  "ENSMUSG00000068329",
  "ENSMUSG00000068335",
  "ENSMUSG00000068341",
  "ENSMUSG00000068349",
  "ENSMUSG00000068373",
  "ENSMUSG00000068391",
  "ENSMUSG00000068392",
  "ENSMUSG00000068394",
  "ENSMUSG00000068399",
  "ENSMUSG00000068407",
  "ENSMUSG00000068417",
  "ENSMUSG00000068428",
  "ENSMUSG00000068431",
  "ENSMUSG00000068437",
  "ENSMUSG00000068452",
  "ENSMUSG00000068457",
  "ENSMUSG00000068463",
  "ENSMUSG00000068479",
  "ENSMUSG00000068506",
  "ENSMUSG00000068522",
  "ENSMUSG00000068535",
  "ENSMUSG00000068536",
  "ENSMUSG00000068547",
  "ENSMUSG00000068551",
  "ENSMUSG00000068566",
  "ENSMUSG00000068574",
  "ENSMUSG00000068580",
  "ENSMUSG00000068587",
  "ENSMUSG00000068600",
  "ENSMUSG00000068601",
  "ENSMUSG00000068606",
  "ENSMUSG00000068614",
  "ENSMUSG00000068615",
  "ENSMUSG00000068617",
  "ENSMUSG00000068647",
  "ENSMUSG00000068663",
  "ENSMUSG00000068680",
  "ENSMUSG00000068686",
  "ENSMUSG00000068696",
  "ENSMUSG00000068697",
  "ENSMUSG00000068699",
  "ENSMUSG00000068732",
  "ENSMUSG00000068735",
  "ENSMUSG00000068739",
  "ENSMUSG00000068740",
  "ENSMUSG00000068742",
  "ENSMUSG00000068744",
  "ENSMUSG00000068745",
  "ENSMUSG00000068747",
  "ENSMUSG00000068748",
  "ENSMUSG00000068758",
  "ENSMUSG00000068762",
  "ENSMUSG00000068776",
  "ENSMUSG00000068794",
  "ENSMUSG00000068798",
  "ENSMUSG00000068806",
  "ENSMUSG00000068808",
  "ENSMUSG00000068809",
  "ENSMUSG00000068814",
  "ENSMUSG00000068815",
  "ENSMUSG00000068816",
  "ENSMUSG00000068817",
  "ENSMUSG00000068818",
  "ENSMUSG00000068819",
  "ENSMUSG00000068823",
  "ENSMUSG00000068854",
  "ENSMUSG00000068855",
  "ENSMUSG00000068856",
  "ENSMUSG00000068859",
  "ENSMUSG00000068860",
  "ENSMUSG00000068874",
  "ENSMUSG00000068876",
  "ENSMUSG00000068882",
  "ENSMUSG00000068885",
  "ENSMUSG00000068887",
  "ENSMUSG00000068888",
  "ENSMUSG00000068889",
  "ENSMUSG00000068890",
  "ENSMUSG00000068917",
  "ENSMUSG00000068921",
  "ENSMUSG00000068922",
  "ENSMUSG00000068923",
  "ENSMUSG00000068940",
  "ENSMUSG00000068947",
  "ENSMUSG00000068950",
  "ENSMUSG00000068957",
  "ENSMUSG00000068959",
  "ENSMUSG00000068962",
  "ENSMUSG00000068966",
  "ENSMUSG00000069020",
  "ENSMUSG00000069036",
  "ENSMUSG00000069038",
  "ENSMUSG00000069041",
  "ENSMUSG00000069044",
  "ENSMUSG00000069045",
  "ENSMUSG00000069049",
  "ENSMUSG00000069053",
  "ENSMUSG00000069072",
  "ENSMUSG00000069080",
  "ENSMUSG00000069085",
  "ENSMUSG00000069089",
  "ENSMUSG00000069094",
  "ENSMUSG00000069114",
  "ENSMUSG00000069118",
  "ENSMUSG00000069132",
  "ENSMUSG00000069135",
  "ENSMUSG00000069170",
  "ENSMUSG00000069171",
  "ENSMUSG00000069184",
  "ENSMUSG00000069189",
  "ENSMUSG00000069206",
  "ENSMUSG00000069208",
  "ENSMUSG00000069223",
  "ENSMUSG00000069227",
  "ENSMUSG00000069237",
  "ENSMUSG00000069248",
  "ENSMUSG00000069255",
  "ENSMUSG00000069258",
  "ENSMUSG00000069259",
  "ENSMUSG00000069265",
  "ENSMUSG00000069270",
  "ENSMUSG00000069272",
  "ENSMUSG00000069280",
  "ENSMUSG00000069289",
  "ENSMUSG00000069292",
  "ENSMUSG00000069294",
  "ENSMUSG00000069295",
  "ENSMUSG00000069296",
  "ENSMUSG00000069297",
  "ENSMUSG00000069299",
  "ENSMUSG00000069301",
  "ENSMUSG00000069302",
  "ENSMUSG00000069303",
  "ENSMUSG00000069305",
  "ENSMUSG00000069308",
  "ENSMUSG00000069310",
  "ENSMUSG00000069372",
  "ENSMUSG00000069378",
  "ENSMUSG00000069385",
  "ENSMUSG00000069390",
  "ENSMUSG00000069421",
  "ENSMUSG00000069430",
  "ENSMUSG00000069441",
  "ENSMUSG00000069456",
  "ENSMUSG00000069476",
  "ENSMUSG00000069495",
  "ENSMUSG00000069515",
  "ENSMUSG00000069516",
  "ENSMUSG00000069520",
  "ENSMUSG00000069539",
  "ENSMUSG00000069554",
  "ENSMUSG00000069565",
  "ENSMUSG00000069581",
  "ENSMUSG00000069582",
  "ENSMUSG00000069583",
  "ENSMUSG00000069584",
  "ENSMUSG00000069588",
  "ENSMUSG00000069601",
  "ENSMUSG00000069631",
  "ENSMUSG00000069633",
  "ENSMUSG00000069662",
  "ENSMUSG00000069668",
  "ENSMUSG00000069670",
  "ENSMUSG00000069678",
  "ENSMUSG00000069706",
  "ENSMUSG00000069707",
  "ENSMUSG00000069708",
  "ENSMUSG00000069713",
  "ENSMUSG00000069717",
  "ENSMUSG00000069718",
  "ENSMUSG00000069721",
  "ENSMUSG00000069722",
  "ENSMUSG00000069727",
  "ENSMUSG00000069729",
  "ENSMUSG00000069733",
  "ENSMUSG00000069743",
  "ENSMUSG00000069744",
  "ENSMUSG00000069763",
  "ENSMUSG00000069769",
  "ENSMUSG00000069785",
  "ENSMUSG00000069792",
  "ENSMUSG00000069793",
  "ENSMUSG00000069796",
  "ENSMUSG00000069804",
  "ENSMUSG00000069805",
  "ENSMUSG00000069806",
  "ENSMUSG00000069808",
  "ENSMUSG00000069814",
  "ENSMUSG00000069816",
  "ENSMUSG00000069818",
  "ENSMUSG00000069823",
  "ENSMUSG00000069830",
  "ENSMUSG00000069833",
  "ENSMUSG00000069835",
  "ENSMUSG00000069844",
  "ENSMUSG00000069855",
  "ENSMUSG00000069867",
  "ENSMUSG00000069873",
  "ENSMUSG00000069874",
  "ENSMUSG00000069895",
  "ENSMUSG00000069910",
  "ENSMUSG00000069911",
  "ENSMUSG00000069920",
  "ENSMUSG00000069922",
  "ENSMUSG00000069925",
  "ENSMUSG00000069971",
  "ENSMUSG00000069998",
  "ENSMUSG00000070000",
  "ENSMUSG00000070002",
  "ENSMUSG00000070003",
  "ENSMUSG00000070023",
  "ENSMUSG00000070031",
  "ENSMUSG00000070034",
  "ENSMUSG00000070044",
  "ENSMUSG00000070047",
  "ENSMUSG00000070056",
  "ENSMUSG00000070280",
  "ENSMUSG00000070283",
  "ENSMUSG00000070284",
  "ENSMUSG00000070287",
  "ENSMUSG00000070291",
  "ENSMUSG00000070298",
  "ENSMUSG00000070304",
  "ENSMUSG00000070305",
  "ENSMUSG00000070306",
  "ENSMUSG00000070311",
  "ENSMUSG00000070315",
  "ENSMUSG00000070319",
  "ENSMUSG00000070323",
  "ENSMUSG00000070324",
  "ENSMUSG00000070327",
  "ENSMUSG00000070330",
  "ENSMUSG00000070331",
  "ENSMUSG00000070332",
  "ENSMUSG00000070334",
  "ENSMUSG00000070336",
  "ENSMUSG00000070337",
  "ENSMUSG00000070342",
  "ENSMUSG00000070345",
  "ENSMUSG00000070348",
  "ENSMUSG00000070354",
  "ENSMUSG00000070366",
  "ENSMUSG00000070368",
  "ENSMUSG00000070369",
  "ENSMUSG00000070371",
  "ENSMUSG00000070372",
  "ENSMUSG00000070374",
  "ENSMUSG00000070375",
  "ENSMUSG00000070377",
  "ENSMUSG00000070379",
  "ENSMUSG00000070380",
  "ENSMUSG00000070382",
  "ENSMUSG00000070383",
  "ENSMUSG00000070385",
  "ENSMUSG00000070388",
  "ENSMUSG00000070390",
  "ENSMUSG00000070392",
  "ENSMUSG00000070394",
  "ENSMUSG00000070417",
  "ENSMUSG00000070419",
  "ENSMUSG00000070421",
  "ENSMUSG00000070423",
  "ENSMUSG00000070424",
  "ENSMUSG00000070426",
  "ENSMUSG00000070427",
  "ENSMUSG00000070436",
  "ENSMUSG00000070448",
  "ENSMUSG00000070458",
  "ENSMUSG00000070462",
  "ENSMUSG00000070464",
  "ENSMUSG00000070469",
  "ENSMUSG00000070471",
  "ENSMUSG00000070473",
  "ENSMUSG00000070476",
  "ENSMUSG00000070489",
  "ENSMUSG00000070493",
  "ENSMUSG00000070495",
  "ENSMUSG00000070498",
  "ENSMUSG00000070501",
  "ENSMUSG00000070504",
  "ENSMUSG00000070509",
  "ENSMUSG00000070520",
  "ENSMUSG00000070524",
  "ENSMUSG00000070526",
  "ENSMUSG00000070527",
  "ENSMUSG00000070529",
  "ENSMUSG00000070530",
  "ENSMUSG00000070531",
  "ENSMUSG00000070532",
  "ENSMUSG00000070533",
  "ENSMUSG00000070544",
  "ENSMUSG00000070546",
  "ENSMUSG00000070547",
  "ENSMUSG00000070550",
  "ENSMUSG00000070551",
  "ENSMUSG00000070552",
  "ENSMUSG00000070563",
  "ENSMUSG00000070564",
  "ENSMUSG00000070565",
  "ENSMUSG00000070568",
  "ENSMUSG00000070570",
  "ENSMUSG00000070574",
  "ENSMUSG00000070576",
  "ENSMUSG00000070577",
  "ENSMUSG00000070583",
  "ENSMUSG00000070594",
  "ENSMUSG00000070604",
  "ENSMUSG00000070605",
  "ENSMUSG00000070609",
  "ENSMUSG00000070618",
  "ENSMUSG00000070637",
  "ENSMUSG00000070639",
  "ENSMUSG00000070643",
  "ENSMUSG00000070644",
  "ENSMUSG00000070645",
  "ENSMUSG00000070661",
  "ENSMUSG00000070677",
  "ENSMUSG00000070683",
  "ENSMUSG00000070686",
  "ENSMUSG00000070687",
  "ENSMUSG00000070690",
  "ENSMUSG00000070691",
  "ENSMUSG00000070695",
  "ENSMUSG00000070697",
  "ENSMUSG00000070699",
  "ENSMUSG00000070702",
  "ENSMUSG00000070705",
  "ENSMUSG00000070708",
  "ENSMUSG00000070709",
  "ENSMUSG00000070717",
  "ENSMUSG00000070719",
  "ENSMUSG00000070720",
  "ENSMUSG00000070730",
  "ENSMUSG00000070732",
  "ENSMUSG00000070733",
  "ENSMUSG00000070737",
  "ENSMUSG00000070738",
  "ENSMUSG00000070777",
  "ENSMUSG00000070780",
  "ENSMUSG00000070794",
  "ENSMUSG00000070796",
  "ENSMUSG00000070797",
  "ENSMUSG00000070798",
  "ENSMUSG00000070799",
  "ENSMUSG00000070802",
  "ENSMUSG00000070803",
  "ENSMUSG00000070806",
  "ENSMUSG00000070808",
  "ENSMUSG00000070810",
  "ENSMUSG00000070814",
  "ENSMUSG00000070815",
  "ENSMUSG00000070816",
  "ENSMUSG00000070817",
  "ENSMUSG00000070820",
  "ENSMUSG00000070821",
  "ENSMUSG00000070822",
  "ENSMUSG00000070852",
  "ENSMUSG00000070858",
  "ENSMUSG00000070866",
  "ENSMUSG00000070867",
  "ENSMUSG00000070868",
  "ENSMUSG00000070870",
  "ENSMUSG00000070871",
  "ENSMUSG00000070873",
  "ENSMUSG00000070875",
  "ENSMUSG00000070877",
  "ENSMUSG00000070880",
  "ENSMUSG00000070883",
  "ENSMUSG00000070890",
  "ENSMUSG00000070891",
  "ENSMUSG00000070904",
  "ENSMUSG00000070923",
  "ENSMUSG00000070934",
  "ENSMUSG00000070939",
  "ENSMUSG00000070942",
  "ENSMUSG00000070943",
  "ENSMUSG00000070953",
  "ENSMUSG00000070972",
  "ENSMUSG00000070979",
  "ENSMUSG00000070980",
  "ENSMUSG00000070983",
  "ENSMUSG00000070985",
  "ENSMUSG00000070990",
  "ENSMUSG00000070995",
  "ENSMUSG00000070997",
  "ENSMUSG00000070999",
  "ENSMUSG00000071000",
  "ENSMUSG00000071001",
  "ENSMUSG00000071005",
  "ENSMUSG00000071014",
  "ENSMUSG00000071015",
  "ENSMUSG00000071019",
  "ENSMUSG00000071033",
  "ENSMUSG00000071036",
  "ENSMUSG00000071037",
  "ENSMUSG00000071042",
  "ENSMUSG00000071047",
  "ENSMUSG00000071054",
  "ENSMUSG00000071064",
  "ENSMUSG00000071065",
  "ENSMUSG00000071068",
  "ENSMUSG00000071072",
  "ENSMUSG00000071073",
  "ENSMUSG00000071074",
  "ENSMUSG00000071076",
  "ENSMUSG00000071078",
  "ENSMUSG00000071089",
  "ENSMUSG00000071103",
  "ENSMUSG00000071104",
  "ENSMUSG00000071112",
  "ENSMUSG00000071113",
  "ENSMUSG00000071138",
  "ENSMUSG00000071147",
  "ENSMUSG00000071149",
  "ENSMUSG00000071150",
  "ENSMUSG00000071158",
  "ENSMUSG00000071165",
  "ENSMUSG00000071169",
  "ENSMUSG00000071172",
  "ENSMUSG00000071176",
  "ENSMUSG00000071177",
  "ENSMUSG00000071179",
  "ENSMUSG00000071180",
  "ENSMUSG00000071181",
  "ENSMUSG00000071185",
  "ENSMUSG00000071192",
  "ENSMUSG00000071202",
  "ENSMUSG00000071203",
  "ENSMUSG00000071226",
  "ENSMUSG00000071229",
  "ENSMUSG00000071230",
  "ENSMUSG00000071234",
  "ENSMUSG00000071235",
  "ENSMUSG00000071252",
  "ENSMUSG00000071253",
  "ENSMUSG00000071256",
  "ENSMUSG00000071262",
  "ENSMUSG00000071266",
  "ENSMUSG00000071267",
  "ENSMUSG00000071275",
  "ENSMUSG00000071276",
  "ENSMUSG00000071280",
  "ENSMUSG00000071281",
  "ENSMUSG00000071291",
  "ENSMUSG00000071302",
  "ENSMUSG00000071317",
  "ENSMUSG00000071324",
  "ENSMUSG00000071335",
  "ENSMUSG00000071337",
  "ENSMUSG00000071340",
  "ENSMUSG00000071341",
  "ENSMUSG00000071342",
  "ENSMUSG00000071347",
  "ENSMUSG00000071350",
  "ENSMUSG00000071356",
  "ENSMUSG00000071359",
  "ENSMUSG00000071361",
  "ENSMUSG00000071369",
  "ENSMUSG00000071379",
  "ENSMUSG00000071392",
  "ENSMUSG00000071398",
  "ENSMUSG00000071415",
  "ENSMUSG00000071424",
  "ENSMUSG00000071428",
  "ENSMUSG00000071434",
  "ENSMUSG00000071451",
  "ENSMUSG00000071454",
  "ENSMUSG00000071456",
  "ENSMUSG00000071470",
  "ENSMUSG00000071471",
  "ENSMUSG00000071477",
  "ENSMUSG00000071481",
  "ENSMUSG00000071489",
  "ENSMUSG00000071490",
  "ENSMUSG00000071491",
  "ENSMUSG00000071494",
  "ENSMUSG00000071506",
  "ENSMUSG00000071517",
  "ENSMUSG00000071522",
  "ENSMUSG00000071531",
  "ENSMUSG00000071533",
  "ENSMUSG00000071537",
  "ENSMUSG00000071540",
  "ENSMUSG00000071547",
  "ENSMUSG00000071550",
  "ENSMUSG00000071551",
  "ENSMUSG00000071552",
  "ENSMUSG00000071553",
  "ENSMUSG00000071562",
  "ENSMUSG00000071586",
  "ENSMUSG00000071604",
  "ENSMUSG00000071629",
  "ENSMUSG00000071632",
  "ENSMUSG00000071633",
  "ENSMUSG00000071636",
  "ENSMUSG00000071637",
  "ENSMUSG00000071645",
  "ENSMUSG00000071646",
  "ENSMUSG00000071647",
  "ENSMUSG00000071648",
  "ENSMUSG00000071649",
  "ENSMUSG00000071650",
  "ENSMUSG00000071652",
  "ENSMUSG00000071654",
  "ENSMUSG00000071655",
  "ENSMUSG00000071656",
  "ENSMUSG00000071657",
  "ENSMUSG00000071658",
  "ENSMUSG00000071659",
  "ENSMUSG00000071660",
  "ENSMUSG00000071661",
  "ENSMUSG00000071662",
  "ENSMUSG00000071665",
  "ENSMUSG00000071669",
  "ENSMUSG00000071679",
  "ENSMUSG00000071686",
  "ENSMUSG00000071691",
  "ENSMUSG00000071708",
  "ENSMUSG00000071711",
  "ENSMUSG00000071713",
  "ENSMUSG00000071714",
  "ENSMUSG00000071715",
  "ENSMUSG00000071719",
  "ENSMUSG00000071721",
  "ENSMUSG00000071722",
  "ENSMUSG00000071723",
  "ENSMUSG00000071724",
  "ENSMUSG00000071738",
  "ENSMUSG00000071749",
  "ENSMUSG00000071753",
  "ENSMUSG00000071757",
  "ENSMUSG00000071764",
  "ENSMUSG00000071766",
  "ENSMUSG00000071773",
  "ENSMUSG00000071818",
  "ENSMUSG00000071847",
  "ENSMUSG00000071855",
  "ENSMUSG00000071856",
  "ENSMUSG00000071858",
  "ENSMUSG00000071862",
  "ENSMUSG00000071866",
  "ENSMUSG00000071890",
  "ENSMUSG00000071893",
  "ENSMUSG00000071909",
  "ENSMUSG00000071937",
  "ENSMUSG00000071984",
  "ENSMUSG00000072049",
  "ENSMUSG00000072066",
  "ENSMUSG00000072082",
  "ENSMUSG00000072100",
  "ENSMUSG00000072115",
  "ENSMUSG00000072235",
  "ENSMUSG00000072244",
  "ENSMUSG00000072249",
  "ENSMUSG00000072258",
  "ENSMUSG00000072259",
  "ENSMUSG00000072294",
  "ENSMUSG00000072295",
  "ENSMUSG00000072419",
  "ENSMUSG00000072423",
  "ENSMUSG00000072437",
  "ENSMUSG00000072473",
  "ENSMUSG00000072476",
  "ENSMUSG00000072487",
  "ENSMUSG00000072494",
  "ENSMUSG00000072501",
  "ENSMUSG00000072511",
  "ENSMUSG00000072553",
  "ENSMUSG00000072568",
  "ENSMUSG00000072571",
  "ENSMUSG00000072572",
  "ENSMUSG00000072573",
  "ENSMUSG00000072575",
  "ENSMUSG00000072582",
  "ENSMUSG00000072584",
  "ENSMUSG00000072589",
  "ENSMUSG00000072591",
  "ENSMUSG00000072592",
  "ENSMUSG00000072595",
  "ENSMUSG00000072612",
  "ENSMUSG00000072618",
  "ENSMUSG00000072620",
  "ENSMUSG00000072623",
  "ENSMUSG00000072624",
  "ENSMUSG00000072625",
  "ENSMUSG00000072640",
  "ENSMUSG00000072647",
  "ENSMUSG00000072660",
  "ENSMUSG00000072662",
  "ENSMUSG00000072663",
  "ENSMUSG00000072664",
  "ENSMUSG00000072673",
  "ENSMUSG00000072676",
  "ENSMUSG00000072677",
  "ENSMUSG00000072679",
  "ENSMUSG00000072683",
  "ENSMUSG00000072686",
  "ENSMUSG00000072688",
  "ENSMUSG00000072694",
  "ENSMUSG00000072704",
  "ENSMUSG00000072707",
  "ENSMUSG00000072708",
  "ENSMUSG00000072718",
  "ENSMUSG00000072720",
  "ENSMUSG00000072722",
  "ENSMUSG00000072753",
  "ENSMUSG00000072754",
  "ENSMUSG00000072761",
  "ENSMUSG00000072762",
  "ENSMUSG00000072763",
  "ENSMUSG00000072769",
  "ENSMUSG00000072770",
  "ENSMUSG00000072772",
  "ENSMUSG00000072774",
  "ENSMUSG00000072778",
  "ENSMUSG00000072780",
  "ENSMUSG00000072791",
  "ENSMUSG00000072809",
  "ENSMUSG00000072812",
  "ENSMUSG00000072834",
  "ENSMUSG00000072844",
  "ENSMUSG00000072845",
  "ENSMUSG00000072847",
  "ENSMUSG00000072849",
  "ENSMUSG00000072852",
  "ENSMUSG00000072872",
  "ENSMUSG00000072874",
  "ENSMUSG00000072875",
  "ENSMUSG00000072878",
  "ENSMUSG00000072884",
  "ENSMUSG00000072889",
  "ENSMUSG00000072893",
  "ENSMUSG00000072902",
  "ENSMUSG00000072915",
  "ENSMUSG00000072919",
  "ENSMUSG00000072934",
  "ENSMUSG00000072941",
  "ENSMUSG00000072944",
  "ENSMUSG00000072945",
  "ENSMUSG00000072946",
  "ENSMUSG00000072949",
  "ENSMUSG00000072952",
  "ENSMUSG00000072955",
  "ENSMUSG00000072962",
  "ENSMUSG00000072963",
  "ENSMUSG00000072964",
  "ENSMUSG00000072966",
  "ENSMUSG00000072969",
  "ENSMUSG00000072972",
  "ENSMUSG00000072974",
  "ENSMUSG00000072980",
  "ENSMUSG00000072982",
  "ENSMUSG00000072983",
  "ENSMUSG00000072995",
  "ENSMUSG00000072999",
  "ENSMUSG00000073000",
  "ENSMUSG00000073001",
  "ENSMUSG00000073006",
  "ENSMUSG00000073007",
  "ENSMUSG00000073008",
  "ENSMUSG00000073010",
  "ENSMUSG00000073012",
  "ENSMUSG00000073016",
  "ENSMUSG00000073018",
  "ENSMUSG00000073027",
  "ENSMUSG00000073043",
  "ENSMUSG00000073045",
  "ENSMUSG00000073051",
  "ENSMUSG00000073052",
  "ENSMUSG00000073062",
  "ENSMUSG00000073063",
  "ENSMUSG00000073067",
  "ENSMUSG00000073073",
  "ENSMUSG00000073077",
  "ENSMUSG00000073085",
  "ENSMUSG00000073094",
  "ENSMUSG00000073096",
  "ENSMUSG00000073102",
  "ENSMUSG00000073103",
  "ENSMUSG00000073110",
  "ENSMUSG00000073111",
  "ENSMUSG00000073119",
  "ENSMUSG00000073130",
  "ENSMUSG00000073131",
  "ENSMUSG00000073139",
  "ENSMUSG00000073141",
  "ENSMUSG00000073147",
  "ENSMUSG00000073152",
  "ENSMUSG00000073154",
  "ENSMUSG00000073155",
  "ENSMUSG00000073174",
  "ENSMUSG00000073176",
  "ENSMUSG00000073177",
  "ENSMUSG00000073197",
  "ENSMUSG00000073207",
  "ENSMUSG00000073209",
  "ENSMUSG00000073226",
  "ENSMUSG00000073236",
  "ENSMUSG00000073242",
  "ENSMUSG00000073243",
  "ENSMUSG00000073274",
  "ENSMUSG00000073293",
  "ENSMUSG00000073294",
  "ENSMUSG00000073295",
  "ENSMUSG00000073374",
  "ENSMUSG00000073375",
  "ENSMUSG00000073377",
  "ENSMUSG00000073380",
  "ENSMUSG00000073386",
  "ENSMUSG00000073394",
  "ENSMUSG00000073396",
  "ENSMUSG00000073399",
  "ENSMUSG00000073400",
  "ENSMUSG00000073402",
  "ENSMUSG00000073407",
  "ENSMUSG00000073408",
  "ENSMUSG00000073409",
  "ENSMUSG00000073411",
  "ENSMUSG00000073412",
  "ENSMUSG00000073414",
  "ENSMUSG00000073415",
  "ENSMUSG00000073418",
  "ENSMUSG00000073421",
  "ENSMUSG00000073423",
  "ENSMUSG00000073424",
  "ENSMUSG00000073427",
  "ENSMUSG00000073430",
  "ENSMUSG00000073433",
  "ENSMUSG00000073434",
  "ENSMUSG00000073436",
  "ENSMUSG00000073437",
  "ENSMUSG00000073458",
  "ENSMUSG00000073460",
  "ENSMUSG00000073478",
  "ENSMUSG00000073481",
  "ENSMUSG00000073485",
  "ENSMUSG00000073486",
  "ENSMUSG00000073489",
  "ENSMUSG00000073490",
  "ENSMUSG00000073491",
  "ENSMUSG00000073492",
  "ENSMUSG00000073494",
  "ENSMUSG00000073497",
  "ENSMUSG00000073514",
  "ENSMUSG00000073528",
  "ENSMUSG00000073529",
  "ENSMUSG00000073530",
  "ENSMUSG00000073532",
  "ENSMUSG00000073535",
  "ENSMUSG00000073538",
  "ENSMUSG00000073542",
  "ENSMUSG00000073551",
  "ENSMUSG00000073555",
  "ENSMUSG00000073557",
  "ENSMUSG00000073563",
  "ENSMUSG00000073565",
  "ENSMUSG00000073568",
  "ENSMUSG00000073572",
  "ENSMUSG00000073573",
  "ENSMUSG00000073574",
  "ENSMUSG00000073590",
  "ENSMUSG00000073591",
  "ENSMUSG00000073594",
  "ENSMUSG00000073598",
  "ENSMUSG00000073599",
  "ENSMUSG00000073600",
  "ENSMUSG00000073601",
  "ENSMUSG00000073602",
  "ENSMUSG00000073608",
  "ENSMUSG00000073609",
  "ENSMUSG00000073616",
  "ENSMUSG00000073627",
  "ENSMUSG00000073633",
  "ENSMUSG00000073639",
  "ENSMUSG00000073643",
  "ENSMUSG00000073650",
  "ENSMUSG00000073652",
  "ENSMUSG00000073656",
  "ENSMUSG00000073658",
  "ENSMUSG00000073664",
  "ENSMUSG00000073678",
  "ENSMUSG00000073679",
  "ENSMUSG00000073680",
  "ENSMUSG00000073684",
  "ENSMUSG00000073686",
  "ENSMUSG00000073699",
  "ENSMUSG00000073700",
  "ENSMUSG00000073702",
  "ENSMUSG00000073705",
  "ENSMUSG00000073721",
  "ENSMUSG00000073722",
  "ENSMUSG00000073725",
  "ENSMUSG00000073728",
  "ENSMUSG00000073730",
  "ENSMUSG00000073731",
  "ENSMUSG00000073733",
  "ENSMUSG00000073735",
  "ENSMUSG00000073739",
  "ENSMUSG00000073747",
  "ENSMUSG00000073752",
  "ENSMUSG00000073755",
  "ENSMUSG00000073758",
  "ENSMUSG00000073764",
  "ENSMUSG00000073765",
  "ENSMUSG00000073768",
  "ENSMUSG00000073771",
  "ENSMUSG00000073774",
  "ENSMUSG00000073775",
  "ENSMUSG00000073778",
  "ENSMUSG00000073779",
  "ENSMUSG00000073781",
  "ENSMUSG00000073785",
  "ENSMUSG00000073786",
  "ENSMUSG00000073787",
  "ENSMUSG00000073791",
  "ENSMUSG00000073792",
  "ENSMUSG00000073794",
  "ENSMUSG00000073795",
  "ENSMUSG00000073802",
  "ENSMUSG00000073804",
  "ENSMUSG00000073805",
  "ENSMUSG00000073811",
  "ENSMUSG00000073821",
  "ENSMUSG00000073838",
  "ENSMUSG00000073843",
  "ENSMUSG00000073846",
  "ENSMUSG00000073856",
  "ENSMUSG00000073862",
  "ENSMUSG00000073888",
  "ENSMUSG00000073893",
  "ENSMUSG00000073894",
  "ENSMUSG00000073896",
  "ENSMUSG00000073897",
  "ENSMUSG00000073898",
  "ENSMUSG00000073899",
  "ENSMUSG00000073900",
  "ENSMUSG00000073901",
  "ENSMUSG00000073906",
  "ENSMUSG00000073907",
  "ENSMUSG00000073910",
  "ENSMUSG00000073913",
  "ENSMUSG00000073914",
  "ENSMUSG00000073915",
  "ENSMUSG00000073916",
  "ENSMUSG00000073917",
  "ENSMUSG00000073920",
  "ENSMUSG00000073922",
  "ENSMUSG00000073924",
  "ENSMUSG00000073925",
  "ENSMUSG00000073926",
  "ENSMUSG00000073927",
  "ENSMUSG00000073928",
  "ENSMUSG00000073931",
  "ENSMUSG00000073932",
  "ENSMUSG00000073937",
  "ENSMUSG00000073938",
  "ENSMUSG00000073940",
  "ENSMUSG00000073943",
  "ENSMUSG00000073944",
  "ENSMUSG00000073945",
  "ENSMUSG00000073946",
  "ENSMUSG00000073947",
  "ENSMUSG00000073948",
  "ENSMUSG00000073949",
  "ENSMUSG00000073950",
  "ENSMUSG00000073954",
  "ENSMUSG00000073955",
  "ENSMUSG00000073956",
  "ENSMUSG00000073960",
  "ENSMUSG00000073961",
  "ENSMUSG00000073962",
  "ENSMUSG00000073963",
  "ENSMUSG00000073964",
  "ENSMUSG00000073965",
  "ENSMUSG00000073966",
  "ENSMUSG00000073967",
  "ENSMUSG00000073968",
  "ENSMUSG00000073969",
  "ENSMUSG00000073970",
  "ENSMUSG00000073971",
  "ENSMUSG00000073972",
  "ENSMUSG00000073973",
  "ENSMUSG00000073974",
  "ENSMUSG00000073975",
  "ENSMUSG00000073976",
  "ENSMUSG00000073977",
  "ENSMUSG00000073979",
  "ENSMUSG00000073982",
  "ENSMUSG00000073987",
  "ENSMUSG00000073988",
  "ENSMUSG00000073991",
  "ENSMUSG00000073997",
  "ENSMUSG00000073998",
  "ENSMUSG00000074001",
  "ENSMUSG00000074003",
  "ENSMUSG00000074004",
  "ENSMUSG00000074006",
  "ENSMUSG00000074024",
  "ENSMUSG00000074028",
  "ENSMUSG00000074029",
  "ENSMUSG00000074030",
  "ENSMUSG00000074037",
  "ENSMUSG00000074039",
  "ENSMUSG00000074052",
  "ENSMUSG00000074059",
  "ENSMUSG00000074060",
  "ENSMUSG00000074061",
  "ENSMUSG00000074062",
  "ENSMUSG00000074063",
  "ENSMUSG00000074064",
  "ENSMUSG00000074071",
  "ENSMUSG00000074088",
  "ENSMUSG00000074093",
  "ENSMUSG00000074102",
  "ENSMUSG00000074109",
  "ENSMUSG00000074111",
  "ENSMUSG00000074113",
  "ENSMUSG00000074115",
  "ENSMUSG00000074121",
  "ENSMUSG00000074123",
  "ENSMUSG00000074127",
  "ENSMUSG00000074129",
  "ENSMUSG00000074136",
  "ENSMUSG00000074139",
  "ENSMUSG00000074141",
  "ENSMUSG00000074146",
  "ENSMUSG00000074151",
  "ENSMUSG00000074155",
  "ENSMUSG00000074156",
  "ENSMUSG00000074158",
  "ENSMUSG00000074165",
  "ENSMUSG00000074166",
  "ENSMUSG00000074170",
  "ENSMUSG00000074178",
  "ENSMUSG00000074179",
  "ENSMUSG00000074182",
  "ENSMUSG00000074194",
  "ENSMUSG00000074195",
  "ENSMUSG00000074199",
  "ENSMUSG00000074203",
  "ENSMUSG00000074207",
  "ENSMUSG00000074211",
  "ENSMUSG00000074212",
  "ENSMUSG00000074213",
  "ENSMUSG00000074215",
  "ENSMUSG00000074217",
  "ENSMUSG00000074218",
  "ENSMUSG00000074219",
  "ENSMUSG00000074221",
  "ENSMUSG00000074227",
  "ENSMUSG00000074235",
  "ENSMUSG00000074238",
  "ENSMUSG00000074240",
  "ENSMUSG00000074247",
  "ENSMUSG00000074248",
  "ENSMUSG00000074256",
  "ENSMUSG00000074259",
  "ENSMUSG00000074261",
  "ENSMUSG00000074264",
  "ENSMUSG00000074268",
  "ENSMUSG00000074269",
  "ENSMUSG00000074274",
  "ENSMUSG00000074277",
  "ENSMUSG00000074281",
  "ENSMUSG00000074282",
  "ENSMUSG00000074283",
  "ENSMUSG00000074284",
  "ENSMUSG00000074291",
  "ENSMUSG00000074292",
  "ENSMUSG00000074300",
  "ENSMUSG00000074305",
  "ENSMUSG00000074311",
  "ENSMUSG00000074336",
  "ENSMUSG00000074340",
  "ENSMUSG00000074342",
  "ENSMUSG00000074343",
  "ENSMUSG00000074344",
  "ENSMUSG00000074345",
  "ENSMUSG00000074346",
  "ENSMUSG00000074354",
  "ENSMUSG00000074358",
  "ENSMUSG00000074359",
  "ENSMUSG00000074364",
  "ENSMUSG00000074365",
  "ENSMUSG00000074366",
  "ENSMUSG00000074373",
  "ENSMUSG00000074375",
  "ENSMUSG00000074377",
  "ENSMUSG00000074378",
  "ENSMUSG00000074384",
  "ENSMUSG00000074385",
  "ENSMUSG00000074388",
  "ENSMUSG00000074397",
  "ENSMUSG00000074398",
  "ENSMUSG00000074405",
  "ENSMUSG00000074406",
  "ENSMUSG00000074415",
  "ENSMUSG00000074419",
  "ENSMUSG00000074424",
  "ENSMUSG00000074433",
  "ENSMUSG00000074435",
  "ENSMUSG00000074448",
  "ENSMUSG00000074452",
  "ENSMUSG00000074454",
  "ENSMUSG00000074457",
  "ENSMUSG00000074465",
  "ENSMUSG00000074466",
  "ENSMUSG00000074469",
  "ENSMUSG00000074472",
  "ENSMUSG00000074473",
  "ENSMUSG00000074476",
  "ENSMUSG00000074480",
  "ENSMUSG00000074483",
  "ENSMUSG00000074489",
  "ENSMUSG00000074491",
  "ENSMUSG00000074497",
  "ENSMUSG00000074500",
  "ENSMUSG00000074502",
  "ENSMUSG00000074505",
  "ENSMUSG00000074507",
  "ENSMUSG00000074508",
  "ENSMUSG00000074517",
  "ENSMUSG00000074527",
  "ENSMUSG00000074529",
  "ENSMUSG00000074555",
  "ENSMUSG00000074569",
  "ENSMUSG00000074570",
  "ENSMUSG00000074575",
  "ENSMUSG00000074576",
  "ENSMUSG00000074577",
  "ENSMUSG00000074578",
  "ENSMUSG00000074579",
  "ENSMUSG00000074582",
  "ENSMUSG00000074591",
  "ENSMUSG00000074593",
  "ENSMUSG00000074594",
  "ENSMUSG00000074595",
  "ENSMUSG00000074596",
  "ENSMUSG00000074604",
  "ENSMUSG00000074607",
  "ENSMUSG00000074619",
  "ENSMUSG00000074622",
  "ENSMUSG00000074623",
  "ENSMUSG00000074625",
  "ENSMUSG00000074627",
  "ENSMUSG00000074628",
  "ENSMUSG00000074634",
  "ENSMUSG00000074635",
  "ENSMUSG00000074637",
  "ENSMUSG00000074639",
  "ENSMUSG00000074646",
  "ENSMUSG00000074647",
  "ENSMUSG00000074649",
  "ENSMUSG00000074652",
  "ENSMUSG00000074653",
  "ENSMUSG00000074656",
  "ENSMUSG00000074657",
  "ENSMUSG00000074664",
  "ENSMUSG00000074665",
  "ENSMUSG00000074671",
  "ENSMUSG00000074673",
  "ENSMUSG00000074676",
  "ENSMUSG00000074678",
  "ENSMUSG00000074679",
  "ENSMUSG00000074680",
  "ENSMUSG00000074681",
  "ENSMUSG00000074682",
  "ENSMUSG00000074698",
  "ENSMUSG00000074704",
  "ENSMUSG00000074715",
  "ENSMUSG00000074731",
  "ENSMUSG00000074733",
  "ENSMUSG00000074734",
  "ENSMUSG00000074736",
  "ENSMUSG00000074737",
  "ENSMUSG00000074738",
  "ENSMUSG00000074743",
  "ENSMUSG00000074746",
  "ENSMUSG00000074748",
  "ENSMUSG00000074749",
  "ENSMUSG00000074754",
  "ENSMUSG00000074758",
  "ENSMUSG00000074763",
  "ENSMUSG00000074764",
  "ENSMUSG00000074766",
  "ENSMUSG00000074768",
  "ENSMUSG00000074771",
  "ENSMUSG00000074776",
  "ENSMUSG00000074781",
  "ENSMUSG00000074782",
  "ENSMUSG00000074783",
  "ENSMUSG00000074785",
  "ENSMUSG00000074793",
  "ENSMUSG00000074794",
  "ENSMUSG00000074796",
  "ENSMUSG00000074797",
  "ENSMUSG00000074802",
  "ENSMUSG00000074805",
  "ENSMUSG00000074807",
  "ENSMUSG00000074811",
  "ENSMUSG00000074812",
  "ENSMUSG00000074813",
  "ENSMUSG00000074817",
  "ENSMUSG00000074818",
  "ENSMUSG00000074824",
  "ENSMUSG00000074825",
  "ENSMUSG00000074829",
  "ENSMUSG00000074832",
  "ENSMUSG00000074837",
  "ENSMUSG00000074847",
  "ENSMUSG00000074849",
  "ENSMUSG00000074852",
  "ENSMUSG00000074862",
  "ENSMUSG00000074863",
  "ENSMUSG00000074865",
  "ENSMUSG00000074870",
  "ENSMUSG00000074871",
  "ENSMUSG00000074872",
  "ENSMUSG00000074876",
  "ENSMUSG00000074882",
  "ENSMUSG00000074884",
  "ENSMUSG00000074885",
  "ENSMUSG00000074886",
  "ENSMUSG00000074890",
  "ENSMUSG00000074892",
  "ENSMUSG00000074895",
  "ENSMUSG00000074896",
  "ENSMUSG00000074899",
  "ENSMUSG00000074909",
  "ENSMUSG00000074912",
  "ENSMUSG00000074913",
  "ENSMUSG00000074916",
  "ENSMUSG00000074918",
  "ENSMUSG00000074919",
  "ENSMUSG00000074922",
  "ENSMUSG00000074923",
  "ENSMUSG00000074925",
  "ENSMUSG00000074928",
  "ENSMUSG00000074934",
  "ENSMUSG00000074939",
  "ENSMUSG00000074945",
  "ENSMUSG00000074946",
  "ENSMUSG00000074947",
  "ENSMUSG00000074952",
  "ENSMUSG00000074955",
  "ENSMUSG00000074965",
  "ENSMUSG00000074966",
  "ENSMUSG00000074968",
  "ENSMUSG00000074971",
  "ENSMUSG00000074987",
  "ENSMUSG00000074991",
  "ENSMUSG00000074994",
  "ENSMUSG00000074996",
  "ENSMUSG00000074997",
  "ENSMUSG00000075002",
  "ENSMUSG00000075006",
  "ENSMUSG00000075010",
  "ENSMUSG00000075012",
  "ENSMUSG00000075020",
  "ENSMUSG00000075023",
  "ENSMUSG00000075025",
  "ENSMUSG00000075027",
  "ENSMUSG00000075028",
  "ENSMUSG00000075029",
  "ENSMUSG00000075031",
  "ENSMUSG00000075033",
  "ENSMUSG00000075040",
  "ENSMUSG00000075044",
  "ENSMUSG00000075045",
  "ENSMUSG00000075054",
  "ENSMUSG00000075062",
  "ENSMUSG00000075063",
  "ENSMUSG00000075065",
  "ENSMUSG00000075066",
  "ENSMUSG00000075068",
  "ENSMUSG00000075069",
  "ENSMUSG00000075070",
  "ENSMUSG00000075072",
  "ENSMUSG00000075073",
  "ENSMUSG00000075074",
  "ENSMUSG00000075075",
  "ENSMUSG00000075078",
  "ENSMUSG00000075079",
  "ENSMUSG00000075081",
  "ENSMUSG00000075084",
  "ENSMUSG00000075085",
  "ENSMUSG00000075086",
  "ENSMUSG00000075088",
  "ENSMUSG00000075090",
  "ENSMUSG00000075091",
  "ENSMUSG00000075092",
  "ENSMUSG00000075093",
  "ENSMUSG00000075094",
  "ENSMUSG00000075095",
  "ENSMUSG00000075097",
  "ENSMUSG00000075099",
  "ENSMUSG00000075100",
  "ENSMUSG00000075101",
  "ENSMUSG00000075102",
  "ENSMUSG00000075104",
  "ENSMUSG00000075105",
  "ENSMUSG00000075107",
  "ENSMUSG00000075110",
  "ENSMUSG00000075111",
  "ENSMUSG00000075112",
  "ENSMUSG00000075113",
  "ENSMUSG00000075114",
  "ENSMUSG00000075115",
  "ENSMUSG00000075117",
  "ENSMUSG00000075119",
  "ENSMUSG00000075121",
  "ENSMUSG00000075122",
  "ENSMUSG00000075125",
  "ENSMUSG00000075127",
  "ENSMUSG00000075128",
  "ENSMUSG00000075132",
  "ENSMUSG00000075133",
  "ENSMUSG00000075136",
  "ENSMUSG00000075137",
  "ENSMUSG00000075139",
  "ENSMUSG00000075140",
  "ENSMUSG00000075141",
  "ENSMUSG00000075142",
  "ENSMUSG00000075143",
  "ENSMUSG00000075144",
  "ENSMUSG00000075145",
  "ENSMUSG00000075146",
  "ENSMUSG00000075148",
  "ENSMUSG00000075149",
  "ENSMUSG00000075150",
  "ENSMUSG00000075151",
  "ENSMUSG00000075153",
  "ENSMUSG00000075155",
  "ENSMUSG00000075158",
  "ENSMUSG00000075159",
  "ENSMUSG00000075160",
  "ENSMUSG00000075161",
  "ENSMUSG00000075163",
  "ENSMUSG00000075164",
  "ENSMUSG00000075165",
  "ENSMUSG00000075166",
  "ENSMUSG00000075167",
  "ENSMUSG00000075168",
  "ENSMUSG00000075172",
  "ENSMUSG00000075174",
  "ENSMUSG00000075175",
  "ENSMUSG00000075179",
  "ENSMUSG00000075184",
  "ENSMUSG00000075185",
  "ENSMUSG00000075186",
  "ENSMUSG00000075188",
  "ENSMUSG00000075189",
  "ENSMUSG00000075190",
  "ENSMUSG00000075192",
  "ENSMUSG00000075195",
  "ENSMUSG00000075196",
  "ENSMUSG00000075198",
  "ENSMUSG00000075199",
  "ENSMUSG00000075200",
  "ENSMUSG00000075201",
  "ENSMUSG00000075202",
  "ENSMUSG00000075203",
  "ENSMUSG00000075204",
  "ENSMUSG00000075205",
  "ENSMUSG00000075206",
  "ENSMUSG00000075208",
  "ENSMUSG00000075209",
  "ENSMUSG00000075210",
  "ENSMUSG00000075211",
  "ENSMUSG00000075212",
  "ENSMUSG00000075214",
  "ENSMUSG00000075215",
  "ENSMUSG00000075217",
  "ENSMUSG00000075218",
  "ENSMUSG00000075219",
  "ENSMUSG00000075220",
  "ENSMUSG00000075221",
  "ENSMUSG00000075222",
  "ENSMUSG00000075223",
  "ENSMUSG00000075224",
  "ENSMUSG00000075225",
  "ENSMUSG00000075227",
  "ENSMUSG00000075229",
  "ENSMUSG00000075249",
  "ENSMUSG00000075254",
  "ENSMUSG00000075256",
  "ENSMUSG00000075265",
  "ENSMUSG00000075267",
  "ENSMUSG00000075269",
  "ENSMUSG00000075270",
  "ENSMUSG00000075271",
  "ENSMUSG00000075272",
  "ENSMUSG00000075273",
  "ENSMUSG00000075277",
  "ENSMUSG00000075284",
  "ENSMUSG00000075286",
  "ENSMUSG00000075289",
  "ENSMUSG00000075296",
  "ENSMUSG00000075297",
  "ENSMUSG00000075302",
  "ENSMUSG00000075304",
  "ENSMUSG00000075307",
  "ENSMUSG00000075314",
  "ENSMUSG00000075316",
  "ENSMUSG00000075318",
  "ENSMUSG00000075324",
  "ENSMUSG00000075325",
  "ENSMUSG00000075327",
  "ENSMUSG00000075330",
  "ENSMUSG00000075334",
  "ENSMUSG00000075335",
  "ENSMUSG00000075370",
  "ENSMUSG00000075376",
  "ENSMUSG00000075377",
  "ENSMUSG00000075378",
  "ENSMUSG00000075379",
  "ENSMUSG00000075380",
  "ENSMUSG00000075382",
  "ENSMUSG00000075383",
  "ENSMUSG00000075384",
  "ENSMUSG00000075387",
  "ENSMUSG00000075389",
  "ENSMUSG00000075394",
  "ENSMUSG00000075395",
  "ENSMUSG00000075402",
  "ENSMUSG00000075405",
  "ENSMUSG00000075408",
  "ENSMUSG00000075410",
  "ENSMUSG00000075415",
  "ENSMUSG00000075416",
  "ENSMUSG00000075419",
  "ENSMUSG00000075420",
  "ENSMUSG00000075425",
  "ENSMUSG00000075437",
  "ENSMUSG00000075463",
  "ENSMUSG00000075465",
  "ENSMUSG00000075467",
  "ENSMUSG00000075470",
  "ENSMUSG00000075478",
  "ENSMUSG00000075486",
  "ENSMUSG00000075502",
  "ENSMUSG00000075510",
  "ENSMUSG00000075511",
  "ENSMUSG00000075512",
  "ENSMUSG00000075514",
  "ENSMUSG00000075520",
  "ENSMUSG00000075524",
  "ENSMUSG00000075534",
  "ENSMUSG00000075538",
  "ENSMUSG00000075543",
  "ENSMUSG00000075549",
  "ENSMUSG00000075553",
  "ENSMUSG00000075555",
  "ENSMUSG00000075566",
  "ENSMUSG00000075567",
  "ENSMUSG00000075569",
  "ENSMUSG00000075570",
  "ENSMUSG00000075571",
  "ENSMUSG00000075572",
  "ENSMUSG00000075573",
  "ENSMUSG00000075574",
  "ENSMUSG00000075576",
  "ENSMUSG00000075585",
  "ENSMUSG00000075588",
  "ENSMUSG00000075590",
  "ENSMUSG00000075592",
  "ENSMUSG00000075595",
  "ENSMUSG00000075599",
  "ENSMUSG00000075600",
  "ENSMUSG00000075602",
  "ENSMUSG00000075604",
  "ENSMUSG00000075605",
  "ENSMUSG00000075610",
  "ENSMUSG00000075700",
  "ENSMUSG00000075701",
  "ENSMUSG00000075702",
  "ENSMUSG00000075703",
  "ENSMUSG00000075704",
  "ENSMUSG00000075705",
  "ENSMUSG00000075707",
  "ENSMUSG00000076430",
  "ENSMUSG00000076431",
  "ENSMUSG00000076432",
  "ENSMUSG00000076433",
  "ENSMUSG00000076434",
  "ENSMUSG00000076435",
  "ENSMUSG00000076437",
  "ENSMUSG00000076438",
  "ENSMUSG00000076439",
  "ENSMUSG00000076441",
  "ENSMUSG00000077450",
  "ENSMUSG00000078080",
  "ENSMUSG00000078116",
  "ENSMUSG00000078118",
  "ENSMUSG00000078122",
  "ENSMUSG00000078127",
  "ENSMUSG00000078129",
  "ENSMUSG00000078130",
  "ENSMUSG00000078131",
  "ENSMUSG00000078132",
  "ENSMUSG00000078137",
  "ENSMUSG00000078144",
  "ENSMUSG00000078153",
  "ENSMUSG00000078157",
  "ENSMUSG00000078161",
  "ENSMUSG00000078179",
  "ENSMUSG00000078185",
  "ENSMUSG00000078190",
  "ENSMUSG00000078198",
  "ENSMUSG00000078201",
  "ENSMUSG00000078202",
  "ENSMUSG00000078234",
  "ENSMUSG00000078235",
  "ENSMUSG00000078247",
  "ENSMUSG00000078252",
  "ENSMUSG00000078253",
  "ENSMUSG00000078254",
  "ENSMUSG00000078255",
  "ENSMUSG00000078256",
  "ENSMUSG00000078257",
  "ENSMUSG00000078258",
  "ENSMUSG00000078262",
  "ENSMUSG00000078280",
  "ENSMUSG00000078302",
  "ENSMUSG00000078307",
  "ENSMUSG00000078314",
  "ENSMUSG00000078315",
  "ENSMUSG00000078317",
  "ENSMUSG00000078320",
  "ENSMUSG00000078346",
  "ENSMUSG00000078348",
  "ENSMUSG00000078349",
  "ENSMUSG00000078350",
  "ENSMUSG00000078354",
  "ENSMUSG00000078355",
  "ENSMUSG00000078365",
  "ENSMUSG00000078420",
  "ENSMUSG00000078427",
  "ENSMUSG00000078429",
  "ENSMUSG00000078435",
  "ENSMUSG00000078439",
  "ENSMUSG00000078440",
  "ENSMUSG00000078442",
  "ENSMUSG00000078444",
  "ENSMUSG00000078451",
  "ENSMUSG00000078453",
  "ENSMUSG00000078485",
  "ENSMUSG00000078486",
  "ENSMUSG00000078487",
  "ENSMUSG00000078490",
  "ENSMUSG00000078491",
  "ENSMUSG00000078492",
  "ENSMUSG00000078494",
  "ENSMUSG00000078503",
  "ENSMUSG00000078504",
  "ENSMUSG00000078505",
  "ENSMUSG00000078506",
  "ENSMUSG00000078507",
  "ENSMUSG00000078509",
  "ENSMUSG00000078510",
  "ENSMUSG00000078513",
  "ENSMUSG00000078515",
  "ENSMUSG00000078517",
  "ENSMUSG00000078518",
  "ENSMUSG00000078519",
  "ENSMUSG00000078520",
  "ENSMUSG00000078521",
  "ENSMUSG00000078531",
  "ENSMUSG00000078546",
  "ENSMUSG00000078552",
  "ENSMUSG00000078554",
  "ENSMUSG00000078566",
  "ENSMUSG00000078570",
  "ENSMUSG00000078572",
  "ENSMUSG00000078576",
  "ENSMUSG00000078577",
  "ENSMUSG00000078578",
  "ENSMUSG00000078580",
  "ENSMUSG00000078584",
  "ENSMUSG00000078588",
  "ENSMUSG00000078591",
  "ENSMUSG00000078593",
  "ENSMUSG00000078598",
  "ENSMUSG00000078599",
  "ENSMUSG00000078600",
  "ENSMUSG00000078605",
  "ENSMUSG00000078607",
  "ENSMUSG00000078611",
  "ENSMUSG00000078612",
  "ENSMUSG00000078616",
  "ENSMUSG00000078618",
  "ENSMUSG00000078619",
  "ENSMUSG00000078621",
  "ENSMUSG00000078622",
  "ENSMUSG00000078624",
  "ENSMUSG00000078625",
  "ENSMUSG00000078626",
  "ENSMUSG00000078627",
  "ENSMUSG00000078630",
  "ENSMUSG00000078632",
  "ENSMUSG00000078635",
  "ENSMUSG00000078639",
  "ENSMUSG00000078640",
  "ENSMUSG00000078650",
  "ENSMUSG00000078651",
  "ENSMUSG00000078653",
  "ENSMUSG00000078657",
  "ENSMUSG00000078668",
  "ENSMUSG00000078670",
  "ENSMUSG00000078671",
  "ENSMUSG00000078672",
  "ENSMUSG00000078676",
  "ENSMUSG00000078681",
  "ENSMUSG00000078683",
  "ENSMUSG00000078689",
  "ENSMUSG00000078695",
  "ENSMUSG00000078698",
  "ENSMUSG00000078700",
  "ENSMUSG00000078706",
  "ENSMUSG00000078713",
  "ENSMUSG00000078716",
  "ENSMUSG00000078719",
  "ENSMUSG00000078752",
  "ENSMUSG00000078754",
  "ENSMUSG00000078762",
  "ENSMUSG00000078763",
  "ENSMUSG00000078768",
  "ENSMUSG00000078771",
  "ENSMUSG00000078773",
  "ENSMUSG00000078776",
  "ENSMUSG00000078780",
  "ENSMUSG00000078783",
  "ENSMUSG00000078786",
  "ENSMUSG00000078787",
  "ENSMUSG00000078789",
  "ENSMUSG00000078793",
  "ENSMUSG00000078794",
  "ENSMUSG00000078795",
  "ENSMUSG00000078796",
  "ENSMUSG00000078798",
  "ENSMUSG00000078799",
  "ENSMUSG00000078800",
  "ENSMUSG00000078808",
  "ENSMUSG00000078810",
  "ENSMUSG00000078812",
  "ENSMUSG00000078813",
  "ENSMUSG00000078815",
  "ENSMUSG00000078816",
  "ENSMUSG00000078817",
  "ENSMUSG00000078838",
  "ENSMUSG00000078840",
  "ENSMUSG00000078851",
  "ENSMUSG00000078853",
  "ENSMUSG00000078861",
  "ENSMUSG00000078868",
  "ENSMUSG00000078870",
  "ENSMUSG00000078899",
  "ENSMUSG00000078902",
  "ENSMUSG00000078905",
  "ENSMUSG00000078908",
  "ENSMUSG00000078919",
  "ENSMUSG00000078920",
  "ENSMUSG00000078924",
  "ENSMUSG00000078925",
  "ENSMUSG00000078926",
  "ENSMUSG00000078932",
  "ENSMUSG00000078934",
  "ENSMUSG00000078935",
  "ENSMUSG00000078938",
  "ENSMUSG00000078940",
  "ENSMUSG00000078941",
  "ENSMUSG00000078942",
  "ENSMUSG00000078945",
  "ENSMUSG00000078949",
  "ENSMUSG00000078952",
  "ENSMUSG00000078954",
  "ENSMUSG00000078955",
  "ENSMUSG00000078956",
  "ENSMUSG00000078957",
  "ENSMUSG00000078958",
  "ENSMUSG00000078962",
  "ENSMUSG00000078963",
  "ENSMUSG00000078964",
  "ENSMUSG00000078972",
  "ENSMUSG00000078994",
  "ENSMUSG00000078995",
  "ENSMUSG00000078998",
  "ENSMUSG00000079000",
  "ENSMUSG00000079001",
  "ENSMUSG00000079003",
  "ENSMUSG00000079007",
  "ENSMUSG00000079008",
  "ENSMUSG00000079009",
  "ENSMUSG00000079011",
  "ENSMUSG00000079012",
  "ENSMUSG00000079013",
  "ENSMUSG00000079014",
  "ENSMUSG00000079017",
  "ENSMUSG00000079020",
  "ENSMUSG00000079022",
  "ENSMUSG00000079033",
  "ENSMUSG00000079034",
  "ENSMUSG00000079036",
  "ENSMUSG00000079037",
  "ENSMUSG00000079038",
  "ENSMUSG00000079042",
  "ENSMUSG00000079043",
  "ENSMUSG00000079049",
  "ENSMUSG00000079051",
  "ENSMUSG00000079053",
  "ENSMUSG00000079055",
  "ENSMUSG00000079056",
  "ENSMUSG00000079057",
  "ENSMUSG00000079058",
  "ENSMUSG00000079065",
  "ENSMUSG00000079069",
  "ENSMUSG00000079070",
  "ENSMUSG00000079071",
  "ENSMUSG00000079084",
  "ENSMUSG00000079103",
  "ENSMUSG00000079104",
  "ENSMUSG00000079105",
  "ENSMUSG00000079109",
  "ENSMUSG00000079110",
  "ENSMUSG00000079111",
  "ENSMUSG00000079112",
  "ENSMUSG00000079113",
  "ENSMUSG00000079120",
  "ENSMUSG00000079143",
  "ENSMUSG00000079144",
  "ENSMUSG00000079146",
  "ENSMUSG00000079157",
  "ENSMUSG00000079162",
  "ENSMUSG00000079163",
  "ENSMUSG00000079164",
  "ENSMUSG00000079168",
  "ENSMUSG00000079169",
  "ENSMUSG00000079170",
  "ENSMUSG00000079173",
  "ENSMUSG00000079177",
  "ENSMUSG00000079179",
  "ENSMUSG00000079183",
  "ENSMUSG00000079184",
  "ENSMUSG00000079186",
  "ENSMUSG00000079215",
  "ENSMUSG00000079227",
  "ENSMUSG00000079235",
  "ENSMUSG00000079243",
  "ENSMUSG00000079258",
  "ENSMUSG00000079259",
  "ENSMUSG00000079261",
  "ENSMUSG00000079262",
  "ENSMUSG00000079263",
  "ENSMUSG00000079278",
  "ENSMUSG00000079283",
  "ENSMUSG00000079298",
  "ENSMUSG00000079299",
  "ENSMUSG00000079304",
  "ENSMUSG00000079316",
  "ENSMUSG00000079317",
  "ENSMUSG00000079323",
  "ENSMUSG00000079324",
  "ENSMUSG00000079330",
  "ENSMUSG00000079334",
  "ENSMUSG00000079339",
  "ENSMUSG00000079342",
  "ENSMUSG00000079343",
  "ENSMUSG00000079344",
  "ENSMUSG00000079346",
  "ENSMUSG00000079349",
  "ENSMUSG00000079350",
  "ENSMUSG00000079355",
  "ENSMUSG00000079357",
  "ENSMUSG00000079363",
  "ENSMUSG00000079374",
  "ENSMUSG00000079388",
  "ENSMUSG00000079414",
  "ENSMUSG00000079418",
  "ENSMUSG00000079419",
  "ENSMUSG00000079421",
  "ENSMUSG00000079426",
  "ENSMUSG00000079427",
  "ENSMUSG00000079428",
  "ENSMUSG00000079429",
  "ENSMUSG00000079434",
  "ENSMUSG00000079436",
  "ENSMUSG00000079440",
  "ENSMUSG00000079442",
  "ENSMUSG00000079445",
  "ENSMUSG00000079450",
  "ENSMUSG00000079451",
  "ENSMUSG00000079460",
  "ENSMUSG00000079462",
  "ENSMUSG00000079465",
  "ENSMUSG00000079466",
  "ENSMUSG00000079467",
  "ENSMUSG00000079469",
  "ENSMUSG00000079471",
  "ENSMUSG00000079476",
  "ENSMUSG00000079477",
  "ENSMUSG00000079479",
  "ENSMUSG00000079481",
  "ENSMUSG00000079484",
  "ENSMUSG00000079492",
  "ENSMUSG00000079494",
  "ENSMUSG00000079497",
  "ENSMUSG00000079499",
  "ENSMUSG00000079502",
  "ENSMUSG00000079505",
  "ENSMUSG00000079507",
  "ENSMUSG00000079508",
  "ENSMUSG00000079509",
  "ENSMUSG00000079511",
  "ENSMUSG00000079513",
  "ENSMUSG00000079516",
  "ENSMUSG00000079521",
  "ENSMUSG00000079525",
  "ENSMUSG00000079528",
  "ENSMUSG00000079532",
  "ENSMUSG00000079539",
  "ENSMUSG00000079547",
  "ENSMUSG00000079550",
  "ENSMUSG00000079554",
  "ENSMUSG00000079555",
  "ENSMUSG00000079557",
  "ENSMUSG00000079559",
  "ENSMUSG00000079560",
  "ENSMUSG00000079562",
  "ENSMUSG00000079563",
  "ENSMUSG00000079564",
  "ENSMUSG00000079579",
  "ENSMUSG00000079580",
  "ENSMUSG00000079583",
  "ENSMUSG00000079584",
  "ENSMUSG00000079588",
  "ENSMUSG00000079594",
  "ENSMUSG00000079597",
  "ENSMUSG00000079598",
  "ENSMUSG00000079602",
  "ENSMUSG00000079603",
  "ENSMUSG00000079604",
  "ENSMUSG00000079606",
  "ENSMUSG00000079608",
  "ENSMUSG00000079610",
  "ENSMUSG00000079614",
  "ENSMUSG00000079619",
  "ENSMUSG00000079620",
  "ENSMUSG00000079625",
  "ENSMUSG00000079627",
  "ENSMUSG00000079641",
  "ENSMUSG00000079642",
  "ENSMUSG00000079644",
  "ENSMUSG00000079652",
  "ENSMUSG00000079654",
  "ENSMUSG00000079657",
  "ENSMUSG00000079658",
  "ENSMUSG00000079659",
  "ENSMUSG00000079666",
  "ENSMUSG00000079671",
  "ENSMUSG00000079679",
  "ENSMUSG00000079685",
  "ENSMUSG00000079694",
  "ENSMUSG00000079697",
  "ENSMUSG00000079700",
  "ENSMUSG00000079702",
  "ENSMUSG00000079707",
  "ENSMUSG00000079737",
  "ENSMUSG00000079806",
  "ENSMUSG00000079852",
  "ENSMUSG00000079853",
  "ENSMUSG00000080058",
  "ENSMUSG00000080115",
  "ENSMUSG00000080316",
  "ENSMUSG00000080717",
  "ENSMUSG00000080727",
  "ENSMUSG00000080907",
  "ENSMUSG00000081044",
  "ENSMUSG00000081207",
  "ENSMUSG00000081225",
  "ENSMUSG00000081234",
  "ENSMUSG00000081362",
  "ENSMUSG00000081443",
  "ENSMUSG00000081512",
  "ENSMUSG00000081534",
  "ENSMUSG00000081607",
  "ENSMUSG00000081649",
  "ENSMUSG00000081650",
  "ENSMUSG00000081683",
  "ENSMUSG00000081724",
  "ENSMUSG00000081769",
  "ENSMUSG00000081836",
  "ENSMUSG00000081945",
  "ENSMUSG00000081984",
  "ENSMUSG00000082101",
  "ENSMUSG00000082229",
  "ENSMUSG00000082361",
  "ENSMUSG00000082482",
  "ENSMUSG00000082488",
  "ENSMUSG00000082766",
  "ENSMUSG00000082815",
  "ENSMUSG00000082848",
  "ENSMUSG00000082882",
  "ENSMUSG00000082932",
  "ENSMUSG00000082976",
  "ENSMUSG00000083193",
  "ENSMUSG00000083282",
  "ENSMUSG00000083361",
  "ENSMUSG00000083649",
  "ENSMUSG00000083695",
  "ENSMUSG00000083855",
  "ENSMUSG00000083947",
  "ENSMUSG00000084085",
  "ENSMUSG00000084128",
  "ENSMUSG00000084135",
  "ENSMUSG00000084136",
  "ENSMUSG00000084174",
  "ENSMUSG00000084234",
  "ENSMUSG00000084757",
  "ENSMUSG00000084771",
  "ENSMUSG00000084774",
  "ENSMUSG00000084782",
  "ENSMUSG00000084786",
  "ENSMUSG00000084797",
  "ENSMUSG00000084798",
  "ENSMUSG00000084802",
  "ENSMUSG00000084803",
  "ENSMUSG00000084805",
  "ENSMUSG00000084806",
  "ENSMUSG00000084811",
  "ENSMUSG00000084816",
  "ENSMUSG00000084823",
  "ENSMUSG00000084826",
  "ENSMUSG00000084834",
  "ENSMUSG00000084840",
  "ENSMUSG00000084845",
  "ENSMUSG00000084846",
  "ENSMUSG00000084848",
  "ENSMUSG00000084856",
  "ENSMUSG00000084859",
  "ENSMUSG00000084864",
  "ENSMUSG00000084869",
  "ENSMUSG00000084870",
  "ENSMUSG00000084873",
  "ENSMUSG00000084883",
  "ENSMUSG00000084885",
  "ENSMUSG00000084898",
  "ENSMUSG00000084902",
  "ENSMUSG00000084908",
  "ENSMUSG00000084909",
  "ENSMUSG00000084913",
  "ENSMUSG00000084925",
  "ENSMUSG00000084927",
  "ENSMUSG00000084929",
  "ENSMUSG00000084938",
  "ENSMUSG00000084945",
  "ENSMUSG00000084946",
  "ENSMUSG00000084950",
  "ENSMUSG00000084957",
  "ENSMUSG00000084964",
  "ENSMUSG00000084966",
  "ENSMUSG00000084979",
  "ENSMUSG00000084980",
  "ENSMUSG00000084983",
  "ENSMUSG00000084989",
  "ENSMUSG00000085004",
  "ENSMUSG00000085008",
  "ENSMUSG00000085014",
  "ENSMUSG00000085015",
  "ENSMUSG00000085016",
  "ENSMUSG00000085022",
  "ENSMUSG00000085024",
  "ENSMUSG00000085026",
  "ENSMUSG00000085027",
  "ENSMUSG00000085030",
  "ENSMUSG00000085032",
  "ENSMUSG00000085034",
  "ENSMUSG00000085037",
  "ENSMUSG00000085041",
  "ENSMUSG00000085058",
  "ENSMUSG00000085069",
  "ENSMUSG00000085071",
  "ENSMUSG00000085074",
  "ENSMUSG00000085078",
  "ENSMUSG00000085079",
  "ENSMUSG00000085084",
  "ENSMUSG00000085085",
  "ENSMUSG00000085088",
  "ENSMUSG00000085090",
  "ENSMUSG00000085105",
  "ENSMUSG00000085111",
  "ENSMUSG00000085112",
  "ENSMUSG00000085114",
  "ENSMUSG00000085122",
  "ENSMUSG00000085123",
  "ENSMUSG00000085133",
  "ENSMUSG00000085135",
  "ENSMUSG00000085139",
  "ENSMUSG00000085141",
  "ENSMUSG00000085143",
  "ENSMUSG00000085147",
  "ENSMUSG00000085148",
  "ENSMUSG00000085151",
  "ENSMUSG00000085152",
  "ENSMUSG00000085156",
  "ENSMUSG00000085161",
  "ENSMUSG00000085165",
  "ENSMUSG00000085169",
  "ENSMUSG00000085171",
  "ENSMUSG00000085175",
  "ENSMUSG00000085178",
  "ENSMUSG00000085180",
  "ENSMUSG00000085183",
  "ENSMUSG00000085185",
  "ENSMUSG00000085196",
  "ENSMUSG00000085198",
  "ENSMUSG00000085207",
  "ENSMUSG00000085208",
  "ENSMUSG00000085223",
  "ENSMUSG00000085227",
  "ENSMUSG00000085235",
  "ENSMUSG00000085236",
  "ENSMUSG00000085237",
  "ENSMUSG00000085241",
  "ENSMUSG00000085244",
  "ENSMUSG00000085247",
  "ENSMUSG00000085250",
  "ENSMUSG00000085252",
  "ENSMUSG00000085260",
  "ENSMUSG00000085272",
  "ENSMUSG00000085277",
  "ENSMUSG00000085287",
  "ENSMUSG00000085316",
  "ENSMUSG00000085321",
  "ENSMUSG00000085323",
  "ENSMUSG00000085324",
  "ENSMUSG00000085329",
  "ENSMUSG00000085331",
  "ENSMUSG00000085338",
  "ENSMUSG00000085347",
  "ENSMUSG00000085350",
  "ENSMUSG00000085352",
  "ENSMUSG00000085354",
  "ENSMUSG00000085355",
  "ENSMUSG00000085363",
  "ENSMUSG00000085382",
  "ENSMUSG00000085385",
  "ENSMUSG00000085389",
  "ENSMUSG00000085396",
  "ENSMUSG00000085399",
  "ENSMUSG00000085407",
  "ENSMUSG00000085408",
  "ENSMUSG00000085409",
  "ENSMUSG00000085415",
  "ENSMUSG00000085418",
  "ENSMUSG00000085420",
  "ENSMUSG00000085427",
  "ENSMUSG00000085436",
  "ENSMUSG00000085437",
  "ENSMUSG00000085438",
  "ENSMUSG00000085440",
  "ENSMUSG00000085449",
  "ENSMUSG00000085450",
  "ENSMUSG00000085452",
  "ENSMUSG00000085453",
  "ENSMUSG00000085457",
  "ENSMUSG00000085463",
  "ENSMUSG00000085471",
  "ENSMUSG00000085472",
  "ENSMUSG00000085486",
  "ENSMUSG00000085492",
  "ENSMUSG00000085496",
  "ENSMUSG00000085527",
  "ENSMUSG00000085532",
  "ENSMUSG00000085555",
  "ENSMUSG00000085558",
  "ENSMUSG00000085561",
  "ENSMUSG00000085562",
  "ENSMUSG00000085566",
  "ENSMUSG00000085576",
  "ENSMUSG00000085578",
  "ENSMUSG00000085582",
  "ENSMUSG00000085586",
  "ENSMUSG00000085600",
  "ENSMUSG00000085601",
  "ENSMUSG00000085614",
  "ENSMUSG00000085620",
  "ENSMUSG00000085628",
  "ENSMUSG00000085631",
  "ENSMUSG00000085641",
  "ENSMUSG00000085645",
  "ENSMUSG00000085649",
  "ENSMUSG00000085655",
  "ENSMUSG00000085656",
  "ENSMUSG00000085658",
  "ENSMUSG00000085664",
  "ENSMUSG00000085665",
  "ENSMUSG00000085667",
  "ENSMUSG00000085675",
  "ENSMUSG00000085683",
  "ENSMUSG00000085704",
  "ENSMUSG00000085705",
  "ENSMUSG00000085709",
  "ENSMUSG00000085715",
  "ENSMUSG00000085717",
  "ENSMUSG00000085720",
  "ENSMUSG00000085732",
  "ENSMUSG00000085741",
  "ENSMUSG00000085743",
  "ENSMUSG00000085747",
  "ENSMUSG00000085751",
  "ENSMUSG00000085755",
  "ENSMUSG00000085757",
  "ENSMUSG00000085763",
  "ENSMUSG00000085779",
  "ENSMUSG00000085785",
  "ENSMUSG00000085788",
  "ENSMUSG00000085793",
  "ENSMUSG00000085794",
  "ENSMUSG00000085795",
  "ENSMUSG00000085797",
  "ENSMUSG00000085798",
  "ENSMUSG00000085800",
  "ENSMUSG00000085804",
  "ENSMUSG00000085811",
  "ENSMUSG00000085817",
  "ENSMUSG00000085819",
  "ENSMUSG00000085820",
  "ENSMUSG00000085828",
  "ENSMUSG00000085829",
  "ENSMUSG00000085837",
  "ENSMUSG00000085842",
  "ENSMUSG00000085851",
  "ENSMUSG00000085852",
  "ENSMUSG00000085860",
  "ENSMUSG00000085861",
  "ENSMUSG00000085862",
  "ENSMUSG00000085876",
  "ENSMUSG00000085883",
  "ENSMUSG00000085884",
  "ENSMUSG00000085886",
  "ENSMUSG00000085889",
  "ENSMUSG00000085890",
  "ENSMUSG00000085891",
  "ENSMUSG00000085902",
  "ENSMUSG00000085903",
  "ENSMUSG00000085912",
  "ENSMUSG00000085918",
  "ENSMUSG00000085925",
  "ENSMUSG00000085928",
  "ENSMUSG00000085933",
  "ENSMUSG00000085936",
  "ENSMUSG00000085937",
  "ENSMUSG00000085944",
  "ENSMUSG00000085945",
  "ENSMUSG00000085956",
  "ENSMUSG00000085957",
  "ENSMUSG00000085958",
  "ENSMUSG00000085965",
  "ENSMUSG00000085972",
  "ENSMUSG00000085976",
  "ENSMUSG00000085980",
  "ENSMUSG00000085982",
  "ENSMUSG00000085987",
  "ENSMUSG00000085992",
  "ENSMUSG00000085995",
  "ENSMUSG00000085996",
  "ENSMUSG00000086003",
  "ENSMUSG00000086008",
  "ENSMUSG00000086010",
  "ENSMUSG00000086013",
  "ENSMUSG00000086019",
  "ENSMUSG00000086022",
  "ENSMUSG00000086025",
  "ENSMUSG00000086029",
  "ENSMUSG00000086035",
  "ENSMUSG00000086040",
  "ENSMUSG00000086044",
  "ENSMUSG00000086046",
  "ENSMUSG00000086047",
  "ENSMUSG00000086058",
  "ENSMUSG00000086066",
  "ENSMUSG00000086070",
  "ENSMUSG00000086074",
  "ENSMUSG00000086080",
  "ENSMUSG00000086095",
  "ENSMUSG00000086108",
  "ENSMUSG00000086109",
  "ENSMUSG00000086119",
  "ENSMUSG00000086124",
  "ENSMUSG00000086126",
  "ENSMUSG00000086135",
  "ENSMUSG00000086141",
  "ENSMUSG00000086145",
  "ENSMUSG00000086155",
  "ENSMUSG00000086158",
  "ENSMUSG00000086163",
  "ENSMUSG00000086172",
  "ENSMUSG00000086173",
  "ENSMUSG00000086179",
  "ENSMUSG00000086191",
  "ENSMUSG00000086201",
  "ENSMUSG00000086202",
  "ENSMUSG00000086207",
  "ENSMUSG00000086209",
  "ENSMUSG00000086228",
  "ENSMUSG00000086241",
  "ENSMUSG00000086253",
  "ENSMUSG00000086256",
  "ENSMUSG00000086259",
  "ENSMUSG00000086263",
  "ENSMUSG00000086264",
  "ENSMUSG00000086266",
  "ENSMUSG00000086272",
  "ENSMUSG00000086273",
  "ENSMUSG00000086277",
  "ENSMUSG00000086290",
  "ENSMUSG00000086298",
  "ENSMUSG00000086308",
  "ENSMUSG00000086315",
  "ENSMUSG00000086316",
  "ENSMUSG00000086322",
  "ENSMUSG00000086326",
  "ENSMUSG00000086327",
  "ENSMUSG00000086330",
  "ENSMUSG00000086332",
  "ENSMUSG00000086350",
  "ENSMUSG00000086359",
  "ENSMUSG00000086361",
  "ENSMUSG00000086363",
  "ENSMUSG00000086369",
  "ENSMUSG00000086370",
  "ENSMUSG00000086379",
  "ENSMUSG00000086386",
  "ENSMUSG00000086389",
  "ENSMUSG00000086391",
  "ENSMUSG00000086392",
  "ENSMUSG00000086395",
  "ENSMUSG00000086396",
  "ENSMUSG00000086405",
  "ENSMUSG00000086425",
  "ENSMUSG00000086427",
  "ENSMUSG00000086429",
  "ENSMUSG00000086430",
  "ENSMUSG00000086437",
  "ENSMUSG00000086441",
  "ENSMUSG00000086446",
  "ENSMUSG00000086447",
  "ENSMUSG00000086448",
  "ENSMUSG00000086451",
  "ENSMUSG00000086454",
  "ENSMUSG00000086470",
  "ENSMUSG00000086484",
  "ENSMUSG00000086485",
  "ENSMUSG00000086486",
  "ENSMUSG00000086490",
  "ENSMUSG00000086494",
  "ENSMUSG00000086496",
  "ENSMUSG00000086503",
  "ENSMUSG00000086514",
  "ENSMUSG00000086522",
  "ENSMUSG00000086537",
  "ENSMUSG00000086541",
  "ENSMUSG00000086544",
  "ENSMUSG00000086545",
  "ENSMUSG00000086552",
  "ENSMUSG00000086555",
  "ENSMUSG00000086564",
  "ENSMUSG00000086594",
  "ENSMUSG00000086596",
  "ENSMUSG00000086607",
  "ENSMUSG00000086608",
  "ENSMUSG00000086629",
  "ENSMUSG00000086631",
  "ENSMUSG00000086632",
  "ENSMUSG00000086641",
  "ENSMUSG00000086645",
  "ENSMUSG00000086646",
  "ENSMUSG00000086650",
  "ENSMUSG00000086655",
  "ENSMUSG00000086693",
  "ENSMUSG00000086702",
  "ENSMUSG00000086703",
  "ENSMUSG00000086711",
  "ENSMUSG00000086712",
  "ENSMUSG00000086714",
  "ENSMUSG00000086725",
  "ENSMUSG00000086728",
  "ENSMUSG00000086731",
  "ENSMUSG00000086732",
  "ENSMUSG00000086735",
  "ENSMUSG00000086739",
  "ENSMUSG00000086746",
  "ENSMUSG00000086760",
  "ENSMUSG00000086763",
  "ENSMUSG00000086775",
  "ENSMUSG00000086777",
  "ENSMUSG00000086782",
  "ENSMUSG00000086784",
  "ENSMUSG00000086788",
  "ENSMUSG00000086800",
  "ENSMUSG00000086804",
  "ENSMUSG00000086805",
  "ENSMUSG00000086807",
  "ENSMUSG00000086808",
  "ENSMUSG00000086827",
  "ENSMUSG00000086841",
  "ENSMUSG00000086843",
  "ENSMUSG00000086844",
  "ENSMUSG00000086859",
  "ENSMUSG00000086862",
  "ENSMUSG00000086866",
  "ENSMUSG00000086867",
  "ENSMUSG00000086877",
  "ENSMUSG00000086889",
  "ENSMUSG00000086898",
  "ENSMUSG00000086902",
  "ENSMUSG00000086903",
  "ENSMUSG00000086907",
  "ENSMUSG00000086911",
  "ENSMUSG00000086912",
  "ENSMUSG00000086919",
  "ENSMUSG00000086924",
  "ENSMUSG00000086927",
  "ENSMUSG00000086930",
  "ENSMUSG00000086933",
  "ENSMUSG00000086938",
  "ENSMUSG00000086943",
  "ENSMUSG00000086945",
  "ENSMUSG00000086947",
  "ENSMUSG00000086951",
  "ENSMUSG00000086960",
  "ENSMUSG00000086963",
  "ENSMUSG00000086969",
  "ENSMUSG00000086978",
  "ENSMUSG00000086981",
  "ENSMUSG00000086993",
  "ENSMUSG00000086999",
  "ENSMUSG00000087003",
  "ENSMUSG00000087004",
  "ENSMUSG00000087006",
  "ENSMUSG00000087009",
  "ENSMUSG00000087013",
  "ENSMUSG00000087017",
  "ENSMUSG00000087022",
  "ENSMUSG00000087035",
  "ENSMUSG00000087037",
  "ENSMUSG00000087038",
  "ENSMUSG00000087056",
  "ENSMUSG00000087060",
  "ENSMUSG00000087061",
  "ENSMUSG00000087069",
  "ENSMUSG00000087075",
  "ENSMUSG00000087077",
  "ENSMUSG00000087090",
  "ENSMUSG00000087095",
  "ENSMUSG00000087102",
  "ENSMUSG00000087103",
  "ENSMUSG00000087117",
  "ENSMUSG00000087137",
  "ENSMUSG00000087141",
  "ENSMUSG00000087143",
  "ENSMUSG00000087145",
  "ENSMUSG00000087166",
  "ENSMUSG00000087170",
  "ENSMUSG00000087171",
  "ENSMUSG00000087174",
  "ENSMUSG00000087176",
  "ENSMUSG00000087177",
  "ENSMUSG00000087179",
  "ENSMUSG00000087192",
  "ENSMUSG00000087193",
  "ENSMUSG00000087194",
  "ENSMUSG00000087203",
  "ENSMUSG00000087213",
  "ENSMUSG00000087217",
  "ENSMUSG00000087222",
  "ENSMUSG00000087225",
  "ENSMUSG00000087226",
  "ENSMUSG00000087228",
  "ENSMUSG00000087230",
  "ENSMUSG00000087231",
  "ENSMUSG00000087236",
  "ENSMUSG00000087247",
  "ENSMUSG00000087249",
  "ENSMUSG00000087254",
  "ENSMUSG00000087259",
  "ENSMUSG00000087260",
  "ENSMUSG00000087265",
  "ENSMUSG00000087267",
  "ENSMUSG00000087269",
  "ENSMUSG00000087272",
  "ENSMUSG00000087277",
  "ENSMUSG00000087278",
  "ENSMUSG00000087279",
  "ENSMUSG00000087283",
  "ENSMUSG00000087289",
  "ENSMUSG00000087292",
  "ENSMUSG00000087302",
  "ENSMUSG00000087305",
  "ENSMUSG00000087306",
  "ENSMUSG00000087312",
  "ENSMUSG00000087326",
  "ENSMUSG00000087332",
  "ENSMUSG00000087341",
  "ENSMUSG00000087347",
  "ENSMUSG00000087358",
  "ENSMUSG00000087360",
  "ENSMUSG00000087361",
  "ENSMUSG00000087362",
  "ENSMUSG00000087363",
  "ENSMUSG00000087365",
  "ENSMUSG00000087366",
  "ENSMUSG00000087368",
  "ENSMUSG00000087370",
  "ENSMUSG00000087374",
  "ENSMUSG00000087377",
  "ENSMUSG00000087378",
  "ENSMUSG00000087380",
  "ENSMUSG00000087382",
  "ENSMUSG00000087385",
  "ENSMUSG00000087388",
  "ENSMUSG00000087393",
  "ENSMUSG00000087394",
  "ENSMUSG00000087396",
  "ENSMUSG00000087400",
  "ENSMUSG00000087403",
  "ENSMUSG00000087408",
  "ENSMUSG00000087410",
  "ENSMUSG00000087424",
  "ENSMUSG00000087434",
  "ENSMUSG00000087437",
  "ENSMUSG00000087444",
  "ENSMUSG00000087455",
  "ENSMUSG00000087458",
  "ENSMUSG00000087459",
  "ENSMUSG00000087461",
  "ENSMUSG00000087466",
  "ENSMUSG00000087475",
  "ENSMUSG00000087478",
  "ENSMUSG00000087487",
  "ENSMUSG00000087497",
  "ENSMUSG00000087512",
  "ENSMUSG00000087516",
  "ENSMUSG00000087517",
  "ENSMUSG00000087521",
  "ENSMUSG00000087535",
  "ENSMUSG00000087536",
  "ENSMUSG00000087540",
  "ENSMUSG00000087553",
  "ENSMUSG00000087555",
  "ENSMUSG00000087561",
  "ENSMUSG00000087574",
  "ENSMUSG00000087579",
  "ENSMUSG00000087586",
  "ENSMUSG00000087589",
  "ENSMUSG00000087591",
  "ENSMUSG00000087598",
  "ENSMUSG00000087604",
  "ENSMUSG00000087611",
  "ENSMUSG00000087620",
  "ENSMUSG00000087621",
  "ENSMUSG00000087624",
  "ENSMUSG00000087627",
  "ENSMUSG00000087644",
  "ENSMUSG00000087647",
  "ENSMUSG00000087648",
  "ENSMUSG00000087651",
  "ENSMUSG00000087660",
  "ENSMUSG00000087670",
  "ENSMUSG00000087679",
  "ENSMUSG00000087682",
  "ENSMUSG00000087684",
  "ENSMUSG00000087694",
  "ENSMUSG00000087700",
  "ENSMUSG00000089633",
  "ENSMUSG00000089647",
  "ENSMUSG00000089661",
  "ENSMUSG00000089675",
  "ENSMUSG00000089678",
  "ENSMUSG00000089682",
  "ENSMUSG00000089685",
  "ENSMUSG00000089687",
  "ENSMUSG00000089696",
  "ENSMUSG00000089699",
  "ENSMUSG00000089707",
  "ENSMUSG00000089708",
  "ENSMUSG00000089715",
  "ENSMUSG00000089717",
  "ENSMUSG00000089718",
  "ENSMUSG00000089724",
  "ENSMUSG00000089726",
  "ENSMUSG00000089727",
  "ENSMUSG00000089737",
  "ENSMUSG00000089746",
  "ENSMUSG00000089753",
  "ENSMUSG00000089759",
  "ENSMUSG00000089762",
  "ENSMUSG00000089768",
  "ENSMUSG00000089773",
  "ENSMUSG00000089774",
  "ENSMUSG00000089789",
  "ENSMUSG00000089798",
  "ENSMUSG00000089809",
  "ENSMUSG00000089815",
  "ENSMUSG00000089824",
  "ENSMUSG00000089832",
  "ENSMUSG00000089833",
  "ENSMUSG00000089835",
  "ENSMUSG00000089840",
  "ENSMUSG00000089844",
  "ENSMUSG00000089847",
  "ENSMUSG00000089857",
  "ENSMUSG00000089862",
  "ENSMUSG00000089872",
  "ENSMUSG00000089875",
  "ENSMUSG00000089876",
  "ENSMUSG00000089887",
  "ENSMUSG00000089889",
  "ENSMUSG00000089900",
  "ENSMUSG00000089911",
  "ENSMUSG00000089917",
  "ENSMUSG00000089922",
  "ENSMUSG00000089934",
  "ENSMUSG00000089940",
  "ENSMUSG00000089952",
  "ENSMUSG00000089953",
  "ENSMUSG00000089968",
  "ENSMUSG00000089982",
  "ENSMUSG00000089984",
  "ENSMUSG00000089992",
  "ENSMUSG00000089997",
  "ENSMUSG00000089998",
  "ENSMUSG00000090000",
  "ENSMUSG00000090019",
  "ENSMUSG00000090031",
  "ENSMUSG00000090035",
  "ENSMUSG00000090053",
  "ENSMUSG00000090059",
  "ENSMUSG00000090061",
  "ENSMUSG00000090066",
  "ENSMUSG00000090071",
  "ENSMUSG00000090083",
  "ENSMUSG00000090084",
  "ENSMUSG00000090086",
  "ENSMUSG00000090100",
  "ENSMUSG00000090102",
  "ENSMUSG00000090107",
  "ENSMUSG00000090110",
  "ENSMUSG00000090112",
  "ENSMUSG00000090113",
  "ENSMUSG00000090115",
  "ENSMUSG00000090121",
  "ENSMUSG00000090122",
  "ENSMUSG00000090124",
  "ENSMUSG00000090125",
  "ENSMUSG00000090132",
  "ENSMUSG00000090141",
  "ENSMUSG00000090146",
  "ENSMUSG00000090150",
  "ENSMUSG00000090160",
  "ENSMUSG00000090168",
  "ENSMUSG00000090173",
  "ENSMUSG00000090176",
  "ENSMUSG00000090202",
  "ENSMUSG00000090206",
  "ENSMUSG00000090210",
  "ENSMUSG00000090213",
  "ENSMUSG00000090219",
  "ENSMUSG00000090220",
  "ENSMUSG00000090223",
  "ENSMUSG00000090225",
  "ENSMUSG00000090247",
  "ENSMUSG00000090249",
  "ENSMUSG00000090254",
  "ENSMUSG00000090255",
  "ENSMUSG00000090257",
  "ENSMUSG00000090258",
  "ENSMUSG00000090263",
  "ENSMUSG00000090266",
  "ENSMUSG00000090270",
  "ENSMUSG00000090272",
  "ENSMUSG00000090273",
  "ENSMUSG00000090291",
  "ENSMUSG00000090298",
  "ENSMUSG00000090302",
  "ENSMUSG00000090304",
  "ENSMUSG00000090314",
  "ENSMUSG00000090315",
  "ENSMUSG00000090336",
  "ENSMUSG00000090346",
  "ENSMUSG00000090356",
  "ENSMUSG00000090358",
  "ENSMUSG00000090369",
  "ENSMUSG00000090386",
  "ENSMUSG00000090394",
  "ENSMUSG00000090411",
  "ENSMUSG00000090417",
  "ENSMUSG00000090436",
  "ENSMUSG00000090451",
  "ENSMUSG00000090457",
  "ENSMUSG00000090461",
  "ENSMUSG00000090470",
  "ENSMUSG00000090471",
  "ENSMUSG00000090509",
  "ENSMUSG00000090523",
  "ENSMUSG00000090527",
  "ENSMUSG00000090534",
  "ENSMUSG00000090550",
  "ENSMUSG00000090553",
  "ENSMUSG00000090558",
  "ENSMUSG00000090572",
  "ENSMUSG00000090588",
  "ENSMUSG00000090594",
  "ENSMUSG00000090619",
  "ENSMUSG00000090622",
  "ENSMUSG00000090626",
  "ENSMUSG00000090629",
  "ENSMUSG00000090634",
  "ENSMUSG00000090641",
  "ENSMUSG00000090655",
  "ENSMUSG00000090658",
  "ENSMUSG00000090667",
  "ENSMUSG00000090675",
  "ENSMUSG00000090685",
  "ENSMUSG00000090698",
  "ENSMUSG00000090700",
  "ENSMUSG00000090710",
  "ENSMUSG00000090715",
  "ENSMUSG00000090722",
  "ENSMUSG00000090777",
  "ENSMUSG00000090778",
  "ENSMUSG00000090799",
  "ENSMUSG00000090806",
  "ENSMUSG00000090812",
  "ENSMUSG00000090817",
  "ENSMUSG00000090840",
  "ENSMUSG00000090841",
  "ENSMUSG00000090874",
  "ENSMUSG00000090881",
  "ENSMUSG00000090891",
  "ENSMUSG00000090894",
  "ENSMUSG00000090919",
  "ENSMUSG00000090925",
  "ENSMUSG00000090935",
  "ENSMUSG00000090942",
  "ENSMUSG00000090946",
  "ENSMUSG00000090951",
  "ENSMUSG00000090958",
  "ENSMUSG00000090966",
  "ENSMUSG00000090967",
  "ENSMUSG00000091002",
  "ENSMUSG00000091006",
  "ENSMUSG00000091012",
  "ENSMUSG00000091013",
  "ENSMUSG00000091014",
  "ENSMUSG00000091017",
  "ENSMUSG00000091039",
  "ENSMUSG00000091043",
  "ENSMUSG00000091045",
  "ENSMUSG00000091055",
  "ENSMUSG00000091061",
  "ENSMUSG00000091077",
  "ENSMUSG00000091091",
  "ENSMUSG00000091119",
  "ENSMUSG00000091122",
  "ENSMUSG00000091129",
  "ENSMUSG00000091142",
  "ENSMUSG00000091144",
  "ENSMUSG00000091151",
  "ENSMUSG00000091175",
  "ENSMUSG00000091183",
  "ENSMUSG00000091204",
  "ENSMUSG00000091205",
  "ENSMUSG00000091212",
  "ENSMUSG00000091239",
  "ENSMUSG00000091243",
  "ENSMUSG00000091255",
  "ENSMUSG00000091259",
  "ENSMUSG00000091260",
  "ENSMUSG00000091264",
  "ENSMUSG00000091297",
  "ENSMUSG00000091311",
  "ENSMUSG00000091337",
  "ENSMUSG00000091345",
  "ENSMUSG00000091375",
  "ENSMUSG00000091376",
  "ENSMUSG00000091378",
  "ENSMUSG00000091381",
  "ENSMUSG00000091382",
  "ENSMUSG00000091387",
  "ENSMUSG00000091402",
  "ENSMUSG00000091405",
  "ENSMUSG00000091411",
  "ENSMUSG00000091415",
  "ENSMUSG00000091423",
  "ENSMUSG00000091455",
  "ENSMUSG00000091474",
  "ENSMUSG00000091475",
  "ENSMUSG00000091476",
  "ENSMUSG00000091490",
  "ENSMUSG00000091491",
  "ENSMUSG00000091497",
  "ENSMUSG00000091504",
  "ENSMUSG00000091510",
  "ENSMUSG00000091512",
  "ENSMUSG00000091514",
  "ENSMUSG00000091519",
  "ENSMUSG00000091523",
  "ENSMUSG00000091530",
  "ENSMUSG00000091537",
  "ENSMUSG00000091541",
  "ENSMUSG00000091572",
  "ENSMUSG00000091575",
  "ENSMUSG00000091586",
  "ENSMUSG00000091601",
  "ENSMUSG00000091618",
  "ENSMUSG00000091620",
  "ENSMUSG00000091623",
  "ENSMUSG00000091625",
  "ENSMUSG00000091635",
  "ENSMUSG00000091636",
  "ENSMUSG00000091648",
  "ENSMUSG00000091649",
  "ENSMUSG00000091652",
  "ENSMUSG00000091656",
  "ENSMUSG00000091662",
  "ENSMUSG00000091670",
  "ENSMUSG00000091680",
  "ENSMUSG00000091685",
  "ENSMUSG00000091692",
  "ENSMUSG00000091694",
  "ENSMUSG00000091705",
  "ENSMUSG00000091712",
  "ENSMUSG00000091718",
  "ENSMUSG00000091721",
  "ENSMUSG00000091722",
  "ENSMUSG00000091725",
  "ENSMUSG00000091764",
  "ENSMUSG00000091771",
  "ENSMUSG00000091780",
  "ENSMUSG00000091792",
  "ENSMUSG00000091805",
  "ENSMUSG00000091811",
  "ENSMUSG00000091813",
  "ENSMUSG00000091844",
  "ENSMUSG00000091859",
  "ENSMUSG00000091863",
  "ENSMUSG00000091867",
  "ENSMUSG00000091873",
  "ENSMUSG00000091879",
  "ENSMUSG00000091888",
  "ENSMUSG00000091896",
  "ENSMUSG00000091897",
  "ENSMUSG00000091898",
  "ENSMUSG00000091906",
  "ENSMUSG00000091924",
  "ENSMUSG00000091930",
  "ENSMUSG00000091931",
  "ENSMUSG00000091937",
  "ENSMUSG00000091945",
  "ENSMUSG00000091947",
  "ENSMUSG00000091956",
  "ENSMUSG00000091972",
  "ENSMUSG00000091993",
  "ENSMUSG00000092009",
  "ENSMUSG00000092032",
  "ENSMUSG00000092035",
  "ENSMUSG00000092043",
  "ENSMUSG00000092049",
  "ENSMUSG00000092060",
  "ENSMUSG00000092066",
  "ENSMUSG00000092071",
  "ENSMUSG00000092077",
  "ENSMUSG00000092083",
  "ENSMUSG00000092109",
  "ENSMUSG00000092111",
  "ENSMUSG00000092116",
  "ENSMUSG00000092118",
  "ENSMUSG00000092120",
  "ENSMUSG00000092131",
  "ENSMUSG00000092148",
  "ENSMUSG00000092162",
  "ENSMUSG00000092164",
  "ENSMUSG00000092171",
  "ENSMUSG00000092183",
  "ENSMUSG00000092192",
  "ENSMUSG00000092203",
  "ENSMUSG00000092210",
  "ENSMUSG00000092219",
  "ENSMUSG00000092225",
  "ENSMUSG00000092239",
  "ENSMUSG00000092260",
  "ENSMUSG00000092274",
  "ENSMUSG00000092276",
  "ENSMUSG00000092292",
  "ENSMUSG00000092305",
  "ENSMUSG00000092309",
  "ENSMUSG00000092335",
  "ENSMUSG00000092341",
  "ENSMUSG00000092349",
  "ENSMUSG00000092368",
  "ENSMUSG00000092384",
  "ENSMUSG00000092399",
  "ENSMUSG00000092402",
  "ENSMUSG00000092404",
  "ENSMUSG00000092413",
  "ENSMUSG00000092416",
  "ENSMUSG00000092417",
  "ENSMUSG00000092456",
  "ENSMUSG00000092473",
  "ENSMUSG00000092518",
  "ENSMUSG00000092519",
  "ENSMUSG00000092539",
  "ENSMUSG00000092558",
  "ENSMUSG00000092564",
  "ENSMUSG00000092570",
  "ENSMUSG00000092578",
  "ENSMUSG00000092579",
  "ENSMUSG00000092581",
  "ENSMUSG00000092586",
  "ENSMUSG00000092593",
  "ENSMUSG00000092604",
  "ENSMUSG00000092607",
  "ENSMUSG00000092618",
  "ENSMUSG00000092622",
  "ENSMUSG00000092626",
  "ENSMUSG00000093376",
  "ENSMUSG00000093386",
  "ENSMUSG00000093387",
  "ENSMUSG00000093424",
  "ENSMUSG00000093452",
  "ENSMUSG00000093460",
  "ENSMUSG00000093482",
  "ENSMUSG00000093483",
  "ENSMUSG00000093507",
  "ENSMUSG00000093528",
  "ENSMUSG00000093536",
  "ENSMUSG00000093540",
  "ENSMUSG00000093568",
  "ENSMUSG00000093579",
  "ENSMUSG00000093594",
  "ENSMUSG00000093606",
  "ENSMUSG00000093617",
  "ENSMUSG00000093622",
  "ENSMUSG00000093626",
  "ENSMUSG00000093634",
  "ENSMUSG00000093639",
  "ENSMUSG00000093661",
  "ENSMUSG00000093668",
  "ENSMUSG00000093696",
  "ENSMUSG00000093721",
  "ENSMUSG00000093755",
  "ENSMUSG00000093759",
  "ENSMUSG00000093789",
  "ENSMUSG00000093803",
  "ENSMUSG00000093804",
  "ENSMUSG00000093812",
  "ENSMUSG00000093825",
  "ENSMUSG00000093839",
  "ENSMUSG00000093846",
  "ENSMUSG00000093865",
  "ENSMUSG00000093866",
  "ENSMUSG00000093877",
  "ENSMUSG00000093901",
  "ENSMUSG00000093904",
  "ENSMUSG00000093908",
  "ENSMUSG00000093910",
  "ENSMUSG00000093920",
  "ENSMUSG00000093927",
  "ENSMUSG00000093930",
  "ENSMUSG00000093934",
  "ENSMUSG00000093942",
  "ENSMUSG00000093973",
  "ENSMUSG00000093980",
  "ENSMUSG00000094002",
  "ENSMUSG00000094004",
  "ENSMUSG00000094012",
  "ENSMUSG00000094018",
  "ENSMUSG00000094027",
  "ENSMUSG00000094035",
  "ENSMUSG00000094063",
  "ENSMUSG00000094080",
  "ENSMUSG00000094084",
  "ENSMUSG00000094119",
  "ENSMUSG00000094133",
  "ENSMUSG00000094140",
  "ENSMUSG00000094146",
  "ENSMUSG00000094152",
  "ENSMUSG00000094156",
  "ENSMUSG00000094182",
  "ENSMUSG00000094186",
  "ENSMUSG00000094187",
  "ENSMUSG00000094192",
  "ENSMUSG00000094197",
  "ENSMUSG00000094200",
  "ENSMUSG00000094219",
  "ENSMUSG00000094254",
  "ENSMUSG00000094266",
  "ENSMUSG00000094269",
  "ENSMUSG00000094282",
  "ENSMUSG00000094285",
  "ENSMUSG00000094311",
  "ENSMUSG00000094347",
  "ENSMUSG00000094379",
  "ENSMUSG00000094380",
  "ENSMUSG00000094426",
  "ENSMUSG00000094443",
  "ENSMUSG00000094447",
  "ENSMUSG00000094449",
  "ENSMUSG00000094461",
  "ENSMUSG00000094464",
  "ENSMUSG00000094483",
  "ENSMUSG00000094487",
  "ENSMUSG00000094488",
  "ENSMUSG00000094493",
  "ENSMUSG00000094496",
  "ENSMUSG00000094500",
  "ENSMUSG00000094504",
  "ENSMUSG00000094520",
  "ENSMUSG00000094531",
  "ENSMUSG00000094535",
  "ENSMUSG00000094539",
  "ENSMUSG00000094543",
  "ENSMUSG00000094553",
  "ENSMUSG00000094559",
  "ENSMUSG00000094578",
  "ENSMUSG00000094584",
  "ENSMUSG00000094588",
  "ENSMUSG00000094595",
  "ENSMUSG00000094612",
  "ENSMUSG00000094613",
  "ENSMUSG00000094626",
  "ENSMUSG00000094628",
  "ENSMUSG00000094637",
  "ENSMUSG00000094649",
  "ENSMUSG00000094669",
  "ENSMUSG00000094672",
  "ENSMUSG00000094673",
  "ENSMUSG00000094678",
  "ENSMUSG00000094686",
  "ENSMUSG00000094690",
  "ENSMUSG00000094692",
  "ENSMUSG00000094701",
  "ENSMUSG00000094707",
  "ENSMUSG00000094719",
  "ENSMUSG00000094721",
  "ENSMUSG00000094724",
  "ENSMUSG00000094732",
  "ENSMUSG00000094733",
  "ENSMUSG00000094734",
  "ENSMUSG00000094745",
  "ENSMUSG00000094747",
  "ENSMUSG00000094755",
  "ENSMUSG00000094778",
  "ENSMUSG00000094805",
  "ENSMUSG00000094806",
  "ENSMUSG00000094808",
  "ENSMUSG00000094810",
  "ENSMUSG00000094819",
  "ENSMUSG00000094822",
  "ENSMUSG00000094841",
  "ENSMUSG00000094845",
  "ENSMUSG00000094846",
  "ENSMUSG00000094858",
  "ENSMUSG00000094870",
  "ENSMUSG00000094878",
  "ENSMUSG00000094884",
  "ENSMUSG00000094892",
  "ENSMUSG00000094893",
  "ENSMUSG00000094898",
  "ENSMUSG00000094910",
  "ENSMUSG00000094921",
  "ENSMUSG00000094928",
  "ENSMUSG00000094942",
  "ENSMUSG00000094950",
  "ENSMUSG00000094970",
  "ENSMUSG00000094978",
  "ENSMUSG00000094985",
  "ENSMUSG00000094986",
  "ENSMUSG00000094992",
  "ENSMUSG00000095002",
  "ENSMUSG00000095024",
  "ENSMUSG00000095026",
  "ENSMUSG00000095030",
  "ENSMUSG00000095040",
  "ENSMUSG00000095061",
  "ENSMUSG00000095075",
  "ENSMUSG00000095093",
  "ENSMUSG00000095095",
  "ENSMUSG00000095098",
  "ENSMUSG00000095105",
  "ENSMUSG00000095115",
  "ENSMUSG00000095123",
  "ENSMUSG00000095125",
  "ENSMUSG00000095138",
  "ENSMUSG00000095139",
  "ENSMUSG00000095171",
  "ENSMUSG00000095180",
  "ENSMUSG00000095189",
  "ENSMUSG00000095193",
  "ENSMUSG00000095201",
  "ENSMUSG00000095212",
  "ENSMUSG00000095213",
  "ENSMUSG00000095218",
  "ENSMUSG00000095236",
  "ENSMUSG00000095239",
  "ENSMUSG00000095240",
  "ENSMUSG00000095241",
  "ENSMUSG00000095253",
  "ENSMUSG00000095257",
  "ENSMUSG00000095276",
  "ENSMUSG00000095279",
  "ENSMUSG00000095286",
  "ENSMUSG00000095312",
  "ENSMUSG00000095315",
  "ENSMUSG00000095322",
  "ENSMUSG00000095325",
  "ENSMUSG00000095332",
  "ENSMUSG00000095377",
  "ENSMUSG00000095385",
  "ENSMUSG00000095390",
  "ENSMUSG00000095401",
  "ENSMUSG00000095407",
  "ENSMUSG00000095430",
  "ENSMUSG00000095432",
  "ENSMUSG00000095440",
  "ENSMUSG00000095448",
  "ENSMUSG00000095464",
  "ENSMUSG00000095493",
  "ENSMUSG00000095522",
  "ENSMUSG00000095525",
  "ENSMUSG00000095527",
  "ENSMUSG00000095563",
  "ENSMUSG00000095567",
  "ENSMUSG00000095569",
  "ENSMUSG00000095576",
  "ENSMUSG00000095603",
  "ENSMUSG00000095620",
  "ENSMUSG00000095629",
  "ENSMUSG00000095632",
  "ENSMUSG00000095640",
  "ENSMUSG00000095654",
  "ENSMUSG00000095659",
  "ENSMUSG00000095663",
  "ENSMUSG00000095664",
  "ENSMUSG00000095667",
  "ENSMUSG00000095670",
  "ENSMUSG00000095696",
  "ENSMUSG00000095706",
  "ENSMUSG00000095724",
  "ENSMUSG00000095765",
  "ENSMUSG00000095774",
  "ENSMUSG00000095788",
  "ENSMUSG00000095789",
  "ENSMUSG00000095809",
  "ENSMUSG00000095821",
  "ENSMUSG00000095831",
  "ENSMUSG00000095839",
  "ENSMUSG00000095845",
  "ENSMUSG00000095858",
  "ENSMUSG00000095870",
  "ENSMUSG00000095893",
  "ENSMUSG00000095896",
  "ENSMUSG00000095903",
  "ENSMUSG00000095916",
  "ENSMUSG00000095917",
  "ENSMUSG00000095928",
  "ENSMUSG00000095930",
  "ENSMUSG00000095932",
  "ENSMUSG00000095941",
  "ENSMUSG00000095953",
  "ENSMUSG00000095956",
  "ENSMUSG00000095957",
  "ENSMUSG00000095959",
  "ENSMUSG00000095961",
  "ENSMUSG00000095992",
  "ENSMUSG00000096000",
  "ENSMUSG00000096001",
  "ENSMUSG00000096002",
  "ENSMUSG00000096009",
  "ENSMUSG00000096010",
  "ENSMUSG00000096014",
  "ENSMUSG00000096035",
  "ENSMUSG00000096051",
  "ENSMUSG00000096054",
  "ENSMUSG00000096068",
  "ENSMUSG00000096069",
  "ENSMUSG00000096094",
  "ENSMUSG00000096099",
  "ENSMUSG00000096109",
  "ENSMUSG00000096111",
  "ENSMUSG00000096140",
  "ENSMUSG00000096141",
  "ENSMUSG00000096144",
  "ENSMUSG00000096145",
  "ENSMUSG00000096146",
  "ENSMUSG00000096167",
  "ENSMUSG00000096169",
  "ENSMUSG00000096188",
  "ENSMUSG00000096199",
  "ENSMUSG00000096209",
  "ENSMUSG00000096210",
  "ENSMUSG00000096215",
  "ENSMUSG00000096220",
  "ENSMUSG00000096221",
  "ENSMUSG00000096225",
  "ENSMUSG00000096228",
  "ENSMUSG00000096229",
  "ENSMUSG00000096257",
  "ENSMUSG00000096268",
  "ENSMUSG00000096278",
  "ENSMUSG00000096284",
  "ENSMUSG00000096316",
  "ENSMUSG00000096320",
  "ENSMUSG00000096323",
  "ENSMUSG00000096330",
  "ENSMUSG00000096351",
  "ENSMUSG00000096356",
  "ENSMUSG00000096365",
  "ENSMUSG00000096393",
  "ENSMUSG00000096405",
  "ENSMUSG00000096424",
  "ENSMUSG00000096427",
  "ENSMUSG00000096433",
  "ENSMUSG00000096442",
  "ENSMUSG00000096445",
  "ENSMUSG00000096446",
  "ENSMUSG00000096465",
  "ENSMUSG00000096472",
  "ENSMUSG00000096477",
  "ENSMUSG00000096485",
  "ENSMUSG00000096497",
  "ENSMUSG00000096534",
  "ENSMUSG00000096537",
  "ENSMUSG00000096546",
  "ENSMUSG00000096553",
  "ENSMUSG00000096555",
  "ENSMUSG00000096566",
  "ENSMUSG00000096573",
  "ENSMUSG00000096584",
  "ENSMUSG00000096593",
  "ENSMUSG00000096606",
  "ENSMUSG00000096621",
  "ENSMUSG00000096630",
  "ENSMUSG00000096655",
  "ENSMUSG00000096679",
  "ENSMUSG00000096682",
  "ENSMUSG00000096687",
  "ENSMUSG00000096695",
  "ENSMUSG00000096705",
  "ENSMUSG00000096714",
  "ENSMUSG00000096717",
  "ENSMUSG00000096727",
  "ENSMUSG00000096753",
  "ENSMUSG00000096757",
  "ENSMUSG00000096773",
  "ENSMUSG00000096794",
  "ENSMUSG00000096796",
  "ENSMUSG00000096806",
  "ENSMUSG00000096822",
  "ENSMUSG00000096840",
  "ENSMUSG00000096847",
  "ENSMUSG00000096852",
  "ENSMUSG00000096858",
  "ENSMUSG00000096861",
  "ENSMUSG00000096872",
  "ENSMUSG00000096879",
  "ENSMUSG00000096883",
  "ENSMUSG00000096910",
  "ENSMUSG00000096914",
  "ENSMUSG00000096916",
  "ENSMUSG00000096917",
  "ENSMUSG00000096918",
  "ENSMUSG00000096929",
  "ENSMUSG00000096932",
  "ENSMUSG00000096935",
  "ENSMUSG00000096936",
  "ENSMUSG00000096940",
  "ENSMUSG00000096946",
  "ENSMUSG00000096948",
  "ENSMUSG00000096950",
  "ENSMUSG00000096954",
  "ENSMUSG00000096956",
  "ENSMUSG00000096960",
  "ENSMUSG00000096965",
  "ENSMUSG00000096971",
  "ENSMUSG00000096975",
  "ENSMUSG00000096977",
  "ENSMUSG00000096982",
  "ENSMUSG00000096986",
  "ENSMUSG00000096988",
  "ENSMUSG00000097002",
  "ENSMUSG00000097003",
  "ENSMUSG00000097004",
  "ENSMUSG00000097006",
  "ENSMUSG00000097007",
  "ENSMUSG00000097011",
  "ENSMUSG00000097016",
  "ENSMUSG00000097021",
  "ENSMUSG00000097022",
  "ENSMUSG00000097023",
  "ENSMUSG00000097032",
  "ENSMUSG00000097039",
  "ENSMUSG00000097040",
  "ENSMUSG00000097043",
  "ENSMUSG00000097044",
  "ENSMUSG00000097047",
  "ENSMUSG00000097048",
  "ENSMUSG00000097049",
  "ENSMUSG00000097050",
  "ENSMUSG00000097055",
  "ENSMUSG00000097061",
  "ENSMUSG00000097068",
  "ENSMUSG00000097071",
  "ENSMUSG00000097072",
  "ENSMUSG00000097073",
  "ENSMUSG00000097074",
  "ENSMUSG00000097075",
  "ENSMUSG00000097079",
  "ENSMUSG00000097080",
  "ENSMUSG00000097081",
  "ENSMUSG00000097082",
  "ENSMUSG00000097084",
  "ENSMUSG00000097088",
  "ENSMUSG00000097089",
  "ENSMUSG00000097095",
  "ENSMUSG00000097099",
  "ENSMUSG00000097101",
  "ENSMUSG00000097103",
  "ENSMUSG00000097107",
  "ENSMUSG00000097113",
  "ENSMUSG00000097115",
  "ENSMUSG00000097119",
  "ENSMUSG00000097121",
  "ENSMUSG00000097124",
  "ENSMUSG00000097131",
  "ENSMUSG00000097134",
  "ENSMUSG00000097135",
  "ENSMUSG00000097145",
  "ENSMUSG00000097146",
  "ENSMUSG00000097149",
  "ENSMUSG00000097150",
  "ENSMUSG00000097156",
  "ENSMUSG00000097160",
  "ENSMUSG00000097162",
  "ENSMUSG00000097164",
  "ENSMUSG00000097166",
  "ENSMUSG00000097170",
  "ENSMUSG00000097172",
  "ENSMUSG00000097173",
  "ENSMUSG00000097174",
  "ENSMUSG00000097177",
  "ENSMUSG00000097181",
  "ENSMUSG00000097182",
  "ENSMUSG00000097183",
  "ENSMUSG00000097184",
  "ENSMUSG00000097191",
  "ENSMUSG00000097194",
  "ENSMUSG00000097195",
  "ENSMUSG00000097203",
  "ENSMUSG00000097207",
  "ENSMUSG00000097211",
  "ENSMUSG00000097216",
  "ENSMUSG00000097222",
  "ENSMUSG00000097229",
  "ENSMUSG00000097230",
  "ENSMUSG00000097247",
  "ENSMUSG00000097248",
  "ENSMUSG00000097251",
  "ENSMUSG00000097252",
  "ENSMUSG00000097254",
  "ENSMUSG00000097258",
  "ENSMUSG00000097271",
  "ENSMUSG00000097276",
  "ENSMUSG00000097277",
  "ENSMUSG00000097279",
  "ENSMUSG00000097280",
  "ENSMUSG00000097284",
  "ENSMUSG00000097287",
  "ENSMUSG00000097288",
  "ENSMUSG00000097289",
  "ENSMUSG00000097290",
  "ENSMUSG00000097292",
  "ENSMUSG00000097299",
  "ENSMUSG00000097308",
  "ENSMUSG00000097310",
  "ENSMUSG00000097313",
  "ENSMUSG00000097316",
  "ENSMUSG00000097320",
  "ENSMUSG00000097321",
  "ENSMUSG00000097322",
  "ENSMUSG00000097323",
  "ENSMUSG00000097324",
  "ENSMUSG00000097327",
  "ENSMUSG00000097328",
  "ENSMUSG00000097329",
  "ENSMUSG00000097331",
  "ENSMUSG00000097333",
  "ENSMUSG00000097334",
  "ENSMUSG00000097336",
  "ENSMUSG00000097337",
  "ENSMUSG00000097339",
  "ENSMUSG00000097343",
  "ENSMUSG00000097346",
  "ENSMUSG00000097350",
  "ENSMUSG00000097353",
  "ENSMUSG00000097354",
  "ENSMUSG00000097360",
  "ENSMUSG00000097361",
  "ENSMUSG00000097375",
  "ENSMUSG00000097377",
  "ENSMUSG00000097383",
  "ENSMUSG00000097386",
  "ENSMUSG00000097387",
  "ENSMUSG00000097391",
  "ENSMUSG00000097392",
  "ENSMUSG00000097398",
  "ENSMUSG00000097402",
  "ENSMUSG00000097403",
  "ENSMUSG00000097407",
  "ENSMUSG00000097413",
  "ENSMUSG00000097414",
  "ENSMUSG00000097421",
  "ENSMUSG00000097425",
  "ENSMUSG00000097430",
  "ENSMUSG00000097435",
  "ENSMUSG00000097440",
  "ENSMUSG00000097444",
  "ENSMUSG00000097448",
  "ENSMUSG00000097451",
  "ENSMUSG00000097452",
  "ENSMUSG00000097453",
  "ENSMUSG00000097456",
  "ENSMUSG00000097460",
  "ENSMUSG00000097462",
  "ENSMUSG00000097463",
  "ENSMUSG00000097466",
  "ENSMUSG00000097467",
  "ENSMUSG00000097468",
  "ENSMUSG00000097471",
  "ENSMUSG00000097483",
  "ENSMUSG00000097487",
  "ENSMUSG00000097491",
  "ENSMUSG00000097493",
  "ENSMUSG00000097494",
  "ENSMUSG00000097495",
  "ENSMUSG00000097497",
  "ENSMUSG00000097502",
  "ENSMUSG00000097515",
  "ENSMUSG00000097516",
  "ENSMUSG00000097523",
  "ENSMUSG00000097527",
  "ENSMUSG00000097534",
  "ENSMUSG00000097536",
  "ENSMUSG00000097537",
  "ENSMUSG00000097539",
  "ENSMUSG00000097543",
  "ENSMUSG00000097545",
  "ENSMUSG00000097547",
  "ENSMUSG00000097553",
  "ENSMUSG00000097559",
  "ENSMUSG00000097561",
  "ENSMUSG00000097571",
  "ENSMUSG00000097577",
  "ENSMUSG00000097580",
  "ENSMUSG00000097583",
  "ENSMUSG00000097585",
  "ENSMUSG00000097587",
  "ENSMUSG00000097589",
  "ENSMUSG00000097591",
  "ENSMUSG00000097595",
  "ENSMUSG00000097602",
  "ENSMUSG00000097615",
  "ENSMUSG00000097616",
  "ENSMUSG00000097617",
  "ENSMUSG00000097619",
  "ENSMUSG00000097621",
  "ENSMUSG00000097622",
  "ENSMUSG00000097624",
  "ENSMUSG00000097626",
  "ENSMUSG00000097627",
  "ENSMUSG00000097632",
  "ENSMUSG00000097636",
  "ENSMUSG00000097638",
  "ENSMUSG00000097639",
  "ENSMUSG00000097643",
  "ENSMUSG00000097648",
  "ENSMUSG00000097649",
  "ENSMUSG00000097650",
  "ENSMUSG00000097652",
  "ENSMUSG00000097662",
  "ENSMUSG00000097665",
  "ENSMUSG00000097666",
  "ENSMUSG00000097675",
  "ENSMUSG00000097682",
  "ENSMUSG00000097689",
  "ENSMUSG00000097690",
  "ENSMUSG00000097699",
  "ENSMUSG00000097709",
  "ENSMUSG00000097710",
  "ENSMUSG00000097712",
  "ENSMUSG00000097723",
  "ENSMUSG00000097724",
  "ENSMUSG00000097725",
  "ENSMUSG00000097726",
  "ENSMUSG00000097735",
  "ENSMUSG00000097736",
  "ENSMUSG00000097738",
  "ENSMUSG00000097740",
  "ENSMUSG00000097746",
  "ENSMUSG00000097750",
  "ENSMUSG00000097755",
  "ENSMUSG00000097756",
  "ENSMUSG00000097766",
  "ENSMUSG00000097768",
  "ENSMUSG00000097772",
  "ENSMUSG00000097773",
  "ENSMUSG00000097776",
  "ENSMUSG00000097779",
  "ENSMUSG00000097784",
  "ENSMUSG00000097787",
  "ENSMUSG00000097789",
  "ENSMUSG00000097792",
  "ENSMUSG00000097794",
  "ENSMUSG00000097800",
  "ENSMUSG00000097814",
  "ENSMUSG00000097820",
  "ENSMUSG00000097825",
  "ENSMUSG00000097827",
  "ENSMUSG00000097828",
  "ENSMUSG00000097831",
  "ENSMUSG00000097841",
  "ENSMUSG00000097847",
  "ENSMUSG00000097848",
  "ENSMUSG00000097850",
  "ENSMUSG00000097852",
  "ENSMUSG00000097855",
  "ENSMUSG00000097867",
  "ENSMUSG00000097868",
  "ENSMUSG00000097869",
  "ENSMUSG00000097871",
  "ENSMUSG00000097874",
  "ENSMUSG00000097881",
  "ENSMUSG00000097885",
  "ENSMUSG00000097886",
  "ENSMUSG00000097888",
  "ENSMUSG00000097890",
  "ENSMUSG00000097893",
  "ENSMUSG00000097899",
  "ENSMUSG00000097908",
  "ENSMUSG00000097910",
  "ENSMUSG00000097913",
  "ENSMUSG00000097915",
  "ENSMUSG00000097921",
  "ENSMUSG00000097924",
  "ENSMUSG00000097927",
  "ENSMUSG00000097929",
  "ENSMUSG00000097933",
  "ENSMUSG00000097934",
  "ENSMUSG00000097944",
  "ENSMUSG00000097960",
  "ENSMUSG00000097962",
  "ENSMUSG00000097971",
  "ENSMUSG00000097974",
  "ENSMUSG00000097988",
  "ENSMUSG00000097993",
  "ENSMUSG00000097997",
  "ENSMUSG00000098001",
  "ENSMUSG00000098008",
  "ENSMUSG00000098014",
  "ENSMUSG00000098024",
  "ENSMUSG00000098040",
  "ENSMUSG00000098045",
  "ENSMUSG00000098097",
  "ENSMUSG00000098098",
  "ENSMUSG00000098108",
  "ENSMUSG00000098112",
  "ENSMUSG00000098120",
  "ENSMUSG00000098127",
  "ENSMUSG00000098128",
  "ENSMUSG00000098132",
  "ENSMUSG00000098134",
  "ENSMUSG00000098145",
  "ENSMUSG00000098150",
  "ENSMUSG00000098155",
  "ENSMUSG00000098164",
  "ENSMUSG00000098176",
  "ENSMUSG00000098178",
  "ENSMUSG00000098184",
  "ENSMUSG00000098188",
  "ENSMUSG00000098197",
  "ENSMUSG00000098202",
  "ENSMUSG00000098206",
  "ENSMUSG00000098207",
  "ENSMUSG00000098234",
  "ENSMUSG00000098243",
  "ENSMUSG00000098274",
  "ENSMUSG00000098284",
  "ENSMUSG00000098292",
  "ENSMUSG00000098318",
  "ENSMUSG00000098322",
  "ENSMUSG00000098332",
  "ENSMUSG00000098340",
  "ENSMUSG00000098348",
  "ENSMUSG00000098383",
  "ENSMUSG00000098449",
  "ENSMUSG00000098505",
  "ENSMUSG00000098509",
  "ENSMUSG00000098557",
  "ENSMUSG00000098609",
  "ENSMUSG00000098627",
  "ENSMUSG00000098640",
  "ENSMUSG00000098659",
  "ENSMUSG00000098682",
  "ENSMUSG00000098702",
  "ENSMUSG00000098705",
  "ENSMUSG00000098727",
  "ENSMUSG00000098758",
  "ENSMUSG00000098760",
  "ENSMUSG00000098801",
  "ENSMUSG00000098854",
  "ENSMUSG00000098905",
  "ENSMUSG00000098912",
  "ENSMUSG00000098923",
  "ENSMUSG00000098959",
  "ENSMUSG00000099032",
  "ENSMUSG00000099061",
  "ENSMUSG00000099083",
  "ENSMUSG00000099098",
  "ENSMUSG00000099137",
  "ENSMUSG00000099139",
  "ENSMUSG00000099148",
  "ENSMUSG00000099170",
  "ENSMUSG00000099207",
  "ENSMUSG00000099216",
  "ENSMUSG00000099269",
  "ENSMUSG00000099294",
  "ENSMUSG00000099338",
  "ENSMUSG00000099354",
  "ENSMUSG00000099360",
  "ENSMUSG00000099364",
  "ENSMUSG00000099370",
  "ENSMUSG00000099378",
  "ENSMUSG00000099384",
  "ENSMUSG00000099391",
  "ENSMUSG00000099398",
  "ENSMUSG00000099411",
  "ENSMUSG00000099413",
  "ENSMUSG00000099446",
  "ENSMUSG00000099452",
  "ENSMUSG00000099465",
  "ENSMUSG00000099468",
  "ENSMUSG00000099470",
  "ENSMUSG00000099474",
  "ENSMUSG00000099486",
  "ENSMUSG00000099508",
  "ENSMUSG00000099517",
  "ENSMUSG00000099528",
  "ENSMUSG00000099553",
  "ENSMUSG00000099561",
  "ENSMUSG00000099579",
  "ENSMUSG00000099590",
  "ENSMUSG00000099616",
  "ENSMUSG00000099630",
  "ENSMUSG00000099639",
  "ENSMUSG00000099655",
  "ENSMUSG00000099665",
  "ENSMUSG00000099681",
  "ENSMUSG00000099687",
  "ENSMUSG00000099689",
  "ENSMUSG00000099696",
  "ENSMUSG00000099707",
  "ENSMUSG00000099724",
  "ENSMUSG00000099735",
  "ENSMUSG00000099747",
  "ENSMUSG00000099749",
  "ENSMUSG00000099751",
  "ENSMUSG00000099752",
  "ENSMUSG00000099759",
  "ENSMUSG00000099787",
  "ENSMUSG00000099794",
  "ENSMUSG00000099808",
  "ENSMUSG00000099820",
  "ENSMUSG00000099843",
  "ENSMUSG00000099847",
  "ENSMUSG00000099860",
  "ENSMUSG00000099877",
  "ENSMUSG00000099881",
  "ENSMUSG00000099900",
  "ENSMUSG00000099906",
  "ENSMUSG00000099907",
  "ENSMUSG00000099917",
  "ENSMUSG00000099922",
  "ENSMUSG00000099945",
  "ENSMUSG00000099950",
  "ENSMUSG00000099957",
  "ENSMUSG00000099958",
  "ENSMUSG00000099966",
  "ENSMUSG00000100000",
  "ENSMUSG00000100001",
  "ENSMUSG00000100005",
  "ENSMUSG00000100009",
  "ENSMUSG00000100016",
  "ENSMUSG00000100017",
  "ENSMUSG00000100079",
  "ENSMUSG00000100084",
  "ENSMUSG00000100094",
  "ENSMUSG00000100106",
  "ENSMUSG00000100119",
  "ENSMUSG00000100120",
  "ENSMUSG00000100121",
  "ENSMUSG00000100147",
  "ENSMUSG00000100155",
  "ENSMUSG00000100164",
  "ENSMUSG00000100166",
  "ENSMUSG00000100182",
  "ENSMUSG00000100186",
  "ENSMUSG00000100190",
  "ENSMUSG00000100210",
  "ENSMUSG00000100219",
  "ENSMUSG00000100231",
  "ENSMUSG00000100235",
  "ENSMUSG00000100241",
  "ENSMUSG00000100252",
  "ENSMUSG00000100253",
  "ENSMUSG00000100274",
  "ENSMUSG00000100277",
  "ENSMUSG00000100282",
  "ENSMUSG00000100294",
  "ENSMUSG00000100296",
  "ENSMUSG00000100301",
  "ENSMUSG00000100315",
  "ENSMUSG00000100335",
  "ENSMUSG00000100354",
  "ENSMUSG00000100364",
  "ENSMUSG00000100426",
  "ENSMUSG00000100431",
  "ENSMUSG00000100437",
  "ENSMUSG00000100444",
  "ENSMUSG00000100459",
  "ENSMUSG00000100483",
  "ENSMUSG00000100486",
  "ENSMUSG00000100502",
  "ENSMUSG00000100510",
  "ENSMUSG00000100511",
  "ENSMUSG00000100512",
  "ENSMUSG00000100518",
  "ENSMUSG00000100546",
  "ENSMUSG00000100553",
  "ENSMUSG00000100557",
  "ENSMUSG00000100573",
  "ENSMUSG00000100580",
  "ENSMUSG00000100585",
  "ENSMUSG00000100599",
  "ENSMUSG00000100603",
  "ENSMUSG00000100605",
  "ENSMUSG00000100632",
  "ENSMUSG00000100666",
  "ENSMUSG00000100668",
  "ENSMUSG00000100689",
  "ENSMUSG00000100714",
  "ENSMUSG00000100727",
  "ENSMUSG00000100733",
  "ENSMUSG00000100747",
  "ENSMUSG00000100760",
  "ENSMUSG00000100768",
  "ENSMUSG00000100783",
  "ENSMUSG00000100826",
  "ENSMUSG00000100837",
  "ENSMUSG00000100850",
  "ENSMUSG00000100857",
  "ENSMUSG00000100890",
  "ENSMUSG00000100923",
  "ENSMUSG00000100937",
  "ENSMUSG00000100950",
  "ENSMUSG00000100967",
  "ENSMUSG00000100969",
  "ENSMUSG00000100985",
  "ENSMUSG00000101009",
  "ENSMUSG00000101012",
  "ENSMUSG00000101013",
  "ENSMUSG00000101028",
  "ENSMUSG00000101031",
  "ENSMUSG00000101051",
  "ENSMUSG00000101073",
  "ENSMUSG00000101078",
  "ENSMUSG00000101126",
  "ENSMUSG00000101153",
  "ENSMUSG00000101174",
  "ENSMUSG00000101179",
  "ENSMUSG00000101189",
  "ENSMUSG00000101225",
  "ENSMUSG00000101243",
  "ENSMUSG00000101257",
  "ENSMUSG00000101268",
  "ENSMUSG00000101275",
  "ENSMUSG00000101303",
  "ENSMUSG00000101315",
  "ENSMUSG00000101324",
  "ENSMUSG00000101334",
  "ENSMUSG00000101335",
  "ENSMUSG00000101356",
  "ENSMUSG00000101360",
  "ENSMUSG00000101365",
  "ENSMUSG00000101386",
  "ENSMUSG00000101389",
  "ENSMUSG00000101391",
  "ENSMUSG00000101404",
  "ENSMUSG00000101463",
  "ENSMUSG00000101480",
  "ENSMUSG00000101483",
  "ENSMUSG00000101493",
  "ENSMUSG00000101505",
  "ENSMUSG00000101514",
  "ENSMUSG00000101517",
  "ENSMUSG00000101549",
  "ENSMUSG00000101553",
  "ENSMUSG00000101568",
  "ENSMUSG00000101578",
  "ENSMUSG00000101581",
  "ENSMUSG00000101584",
  "ENSMUSG00000101585",
  "ENSMUSG00000101599",
  "ENSMUSG00000101605",
  "ENSMUSG00000101609",
  "ENSMUSG00000101621",
  "ENSMUSG00000101636",
  "ENSMUSG00000101655",
  "ENSMUSG00000101674",
  "ENSMUSG00000101714",
  "ENSMUSG00000101734",
  "ENSMUSG00000101741",
  "ENSMUSG00000101768",
  "ENSMUSG00000101791",
  "ENSMUSG00000101799",
  "ENSMUSG00000101818",
  "ENSMUSG00000101848",
  "ENSMUSG00000101849",
  "ENSMUSG00000101856",
  "ENSMUSG00000101860",
  "ENSMUSG00000101874",
  "ENSMUSG00000101888",
  "ENSMUSG00000101892",
  "ENSMUSG00000101894",
  "ENSMUSG00000101895",
  "ENSMUSG00000101918",
  "ENSMUSG00000101930",
  "ENSMUSG00000101951",
  "ENSMUSG00000101952",
  "ENSMUSG00000101959",
  "ENSMUSG00000101968",
  "ENSMUSG00000101970",
  "ENSMUSG00000102004",
  "ENSMUSG00000102009",
  "ENSMUSG00000102018",
  "ENSMUSG00000102030",
  "ENSMUSG00000102046",
  "ENSMUSG00000102060",
  "ENSMUSG00000102069",
  "ENSMUSG00000102080",
  "ENSMUSG00000102098",
  "ENSMUSG00000102099",
  "ENSMUSG00000102101",
  "ENSMUSG00000102123",
  "ENSMUSG00000102158",
  "ENSMUSG00000102206",
  "ENSMUSG00000102215",
  "ENSMUSG00000102222",
  "ENSMUSG00000102224",
  "ENSMUSG00000102252",
  "ENSMUSG00000102279",
  "ENSMUSG00000102308",
  "ENSMUSG00000102329",
  "ENSMUSG00000102362",
  "ENSMUSG00000102397",
  "ENSMUSG00000102408",
  "ENSMUSG00000102416",
  "ENSMUSG00000102418",
  "ENSMUSG00000102439",
  "ENSMUSG00000102543",
  "ENSMUSG00000102544",
  "ENSMUSG00000102545",
  "ENSMUSG00000102574",
  "ENSMUSG00000102608",
  "ENSMUSG00000102629",
  "ENSMUSG00000102692",
  "ENSMUSG00000102697",
  "ENSMUSG00000102715",
  "ENSMUSG00000102716",
  "ENSMUSG00000102752",
  "ENSMUSG00000102758",
  "ENSMUSG00000102774",
  "ENSMUSG00000102785",
  "ENSMUSG00000102802",
  "ENSMUSG00000102862",
  "ENSMUSG00000102869",
  "ENSMUSG00000102887",
  "ENSMUSG00000102893",
  "ENSMUSG00000102909",
  "ENSMUSG00000102987",
  "ENSMUSG00000103103",
  "ENSMUSG00000103144",
  "ENSMUSG00000103156",
  "ENSMUSG00000103170",
  "ENSMUSG00000103181",
  "ENSMUSG00000103243",
  "ENSMUSG00000103255",
  "ENSMUSG00000103310",
  "ENSMUSG00000103346",
  "ENSMUSG00000103362",
  "ENSMUSG00000103409",
  "ENSMUSG00000103421",
  "ENSMUSG00000103428",
  "ENSMUSG00000103464",
  "ENSMUSG00000103503",
  "ENSMUSG00000103523",
  "ENSMUSG00000103567",
  "ENSMUSG00000103577",
  "ENSMUSG00000103594",
  "ENSMUSG00000103668",
  "ENSMUSG00000103695",
  "ENSMUSG00000103708",
  "ENSMUSG00000103711",
  "ENSMUSG00000103726",
  "ENSMUSG00000103770",
  "ENSMUSG00000103800",
  "ENSMUSG00000103844",
  "ENSMUSG00000103906",
  "ENSMUSG00000103912",
  "ENSMUSG00000103923",
  "ENSMUSG00000103927",
  "ENSMUSG00000104004",
  "ENSMUSG00000104011",
  "ENSMUSG00000104014",
  "ENSMUSG00000104015",
  "ENSMUSG00000104043",
  "ENSMUSG00000104055",
  "ENSMUSG00000104093",
  "ENSMUSG00000104106",
  "ENSMUSG00000104156",
  "ENSMUSG00000104158",
  "ENSMUSG00000104161",
  "ENSMUSG00000104178",
  "ENSMUSG00000104234",
  "ENSMUSG00000104284",
  "ENSMUSG00000104301",
  "ENSMUSG00000104340",
  "ENSMUSG00000104366",
  "ENSMUSG00000104423",
  "ENSMUSG00000104445",
  "ENSMUSG00000104465",
  "ENSMUSG00000104487",
  "ENSMUSG00000104507",
  "ENSMUSG00000104509",
  "ENSMUSG00000104529",
  "ENSMUSG00000104543",
  "ENSMUSG00000104553",
  "ENSMUSG00000104554",
  "ENSMUSG00000104586",
  "ENSMUSG00000104597",
  "ENSMUSG00000104610",
  "ENSMUSG00000104614",
  "ENSMUSG00000104703",
  "ENSMUSG00000104717",
  "ENSMUSG00000104752",
  "ENSMUSG00000104786",
  "ENSMUSG00000104818",
  "ENSMUSG00000104830",
  "ENSMUSG00000104835",
  "ENSMUSG00000104861",
  "ENSMUSG00000104904",
  "ENSMUSG00000104939",
  "ENSMUSG00000104955",
  "ENSMUSG00000104956",
  "ENSMUSG00000104968",
  "ENSMUSG00000104973",
  "ENSMUSG00000105039",
  "ENSMUSG00000105078",
  "ENSMUSG00000105105",
  "ENSMUSG00000105185",
  "ENSMUSG00000105222",
  "ENSMUSG00000105224",
  "ENSMUSG00000105239",
  "ENSMUSG00000105258",
  "ENSMUSG00000105260",
  "ENSMUSG00000105265",
  "ENSMUSG00000105274",
  "ENSMUSG00000105279",
  "ENSMUSG00000105297",
  "ENSMUSG00000105300",
  "ENSMUSG00000105376",
  "ENSMUSG00000105385",
  "ENSMUSG00000105402",
  "ENSMUSG00000105426",
  "ENSMUSG00000105437",
  "ENSMUSG00000105439",
  "ENSMUSG00000105490",
  "ENSMUSG00000105501",
  "ENSMUSG00000105504",
  "ENSMUSG00000105531",
  "ENSMUSG00000105535",
  "ENSMUSG00000105602",
  "ENSMUSG00000105615",
  "ENSMUSG00000105618",
  "ENSMUSG00000105619",
  "ENSMUSG00000105646",
  "ENSMUSG00000105652",
  "ENSMUSG00000105662",
  "ENSMUSG00000105703",
  "ENSMUSG00000105709",
  "ENSMUSG00000105734",
  "ENSMUSG00000105759",
  "ENSMUSG00000105765",
  "ENSMUSG00000105773",
  "ENSMUSG00000105795",
  "ENSMUSG00000105811",
  "ENSMUSG00000105816",
  "ENSMUSG00000105838",
  "ENSMUSG00000105851",
  "ENSMUSG00000105881",
  "ENSMUSG00000105888",
  "ENSMUSG00000105891",
  "ENSMUSG00000105912",
  "ENSMUSG00000105975",
  "ENSMUSG00000105987",
  "ENSMUSG00000105993",
  "ENSMUSG00000106000",
  "ENSMUSG00000106002",
  "ENSMUSG00000106019",
  "ENSMUSG00000106093",
  "ENSMUSG00000106122",
  "ENSMUSG00000106168",
  "ENSMUSG00000106210",
  "ENSMUSG00000106219",
  "ENSMUSG00000106223",
  "ENSMUSG00000106237",
  "ENSMUSG00000106269",
  "ENSMUSG00000106306",
  "ENSMUSG00000106350",
  "ENSMUSG00000106357",
  "ENSMUSG00000106371",
  "ENSMUSG00000106378",
  "ENSMUSG00000106379",
  "ENSMUSG00000106380",
  "ENSMUSG00000106422",
  "ENSMUSG00000106441",
  "ENSMUSG00000106461",
  "ENSMUSG00000106464",
  "ENSMUSG00000106492",
  "ENSMUSG00000106540",
  "ENSMUSG00000106547",
  "ENSMUSG00000106555",
  "ENSMUSG00000106583",
  "ENSMUSG00000106586",
  "ENSMUSG00000106589",
  "ENSMUSG00000106644",
  "ENSMUSG00000106683",
  "ENSMUSG00000106726",
  "ENSMUSG00000106734",
  "ENSMUSG00000106738",
  "ENSMUSG00000106762",
  "ENSMUSG00000106779",
  "ENSMUSG00000106791",
  "ENSMUSG00000106795",
  "ENSMUSG00000106807",
  "ENSMUSG00000106808",
  "ENSMUSG00000106820",
  "ENSMUSG00000106847",
  "ENSMUSG00000106864",
  "ENSMUSG00000106871",

  "ENSMUSG00000106902",
  "ENSMUSG00000106918",
  "ENSMUSG00000106927",
  "ENSMUSG00000106932",
  "ENSMUSG00000106943",
  "ENSMUSG00000106951",
  "ENSMUSG00000106968",
  "ENSMUSG00000106978",
  "ENSMUSG00000107002",
  "ENSMUSG00000107010",
  "ENSMUSG00000107013",
  "ENSMUSG00000107036",
  "ENSMUSG00000107044",
  "ENSMUSG00000107090",
  "ENSMUSG00000107108",
  "ENSMUSG00000107110",
  "ENSMUSG00000107111",
  "ENSMUSG00000107143",
  "ENSMUSG00000107144",
  "ENSMUSG00000107164",
  "ENSMUSG00000107167",
  "ENSMUSG00000107214",
  "ENSMUSG00000107217",
  "ENSMUSG00000107248",
  "ENSMUSG00000107252",
  "ENSMUSG00000107271",
  "ENSMUSG00000107283",
  "ENSMUSG00000107301",
  "ENSMUSG00000107305",
  "ENSMUSG00000107341",
  "ENSMUSG00000107352",
  "ENSMUSG00000107353",
  "ENSMUSG00000107385",
  "ENSMUSG00000107386",
  "ENSMUSG00000107417",
  "ENSMUSG00000107434",
  "ENSMUSG00000107477",
  "ENSMUSG00000107496",
  "ENSMUSG00000107516",
  "ENSMUSG00000107526",
  "ENSMUSG00000107573",
  "ENSMUSG00000107585",
  "ENSMUSG00000107614",
  "ENSMUSG00000107619",
  "ENSMUSG00000107622",
  "ENSMUSG00000107623",
  "ENSMUSG00000107637",
  "ENSMUSG00000107638",
  "ENSMUSG00000107648",
  "ENSMUSG00000107651",
  "ENSMUSG00000107660",
  "ENSMUSG00000107667",
  "ENSMUSG00000107677",
  "ENSMUSG00000107681",
  "ENSMUSG00000107709",
  "ENSMUSG00000107711",
  "ENSMUSG00000107732",
  "ENSMUSG00000107748",
  "ENSMUSG00000107785",
  "ENSMUSG00000107797",
  "ENSMUSG00000107815",
  "ENSMUSG00000107822",
  "ENSMUSG00000107874",
  "ENSMUSG00000107879",
  "ENSMUSG00000107880",
  "ENSMUSG00000107887",
  "ENSMUSG00000107895",
  "ENSMUSG00000107906",
  "ENSMUSG00000107926",
  "ENSMUSG00000107933",
  "ENSMUSG00000107935",
  "ENSMUSG00000107948",
  "ENSMUSG00000107956",
  "ENSMUSG00000107988",
  "ENSMUSG00000107993",
  "ENSMUSG00000107994",
  "ENSMUSG00000108015",
  "ENSMUSG00000108020",
  "ENSMUSG00000108022",
  "ENSMUSG00000108030",
  "ENSMUSG00000108043",
  "ENSMUSG00000108060",
  "ENSMUSG00000108062",
  "ENSMUSG00000108063",
  "ENSMUSG00000108071",
  "ENSMUSG00000108084",
  "ENSMUSG00000108103",
  "ENSMUSG00000108111",
  "ENSMUSG00000108113",
  "ENSMUSG00000108129",
  "ENSMUSG00000108167",
  "ENSMUSG00000108175",
  "ENSMUSG00000108187",
  "ENSMUSG00000108192",
  "ENSMUSG00000108207",
  "ENSMUSG00000108228",
  "ENSMUSG00000108236",
  "ENSMUSG00000108242",
  "ENSMUSG00000108255",
  "ENSMUSG00000108265",
  "ENSMUSG00000108313",
  "ENSMUSG00000108322",
  "ENSMUSG00000108345",
  "ENSMUSG00000108348",
  "ENSMUSG00000108354",
  "ENSMUSG00000108367",
  "ENSMUSG00000108379",
  "ENSMUSG00000108382",
  "ENSMUSG00000108383",
  "ENSMUSG00000108413",
  "ENSMUSG00000108414",
  "ENSMUSG00000108427",
  "ENSMUSG00000108431",
  "ENSMUSG00000108446",
  "ENSMUSG00000108461",
  "ENSMUSG00000108470",
  "ENSMUSG00000108523",
  "ENSMUSG00000108575",
  "ENSMUSG00000108603",
  "ENSMUSG00000108616",
  "ENSMUSG00000108621",
  "ENSMUSG00000108622",
  "ENSMUSG00000108627",
  "ENSMUSG00000108652",
  "ENSMUSG00000108695",
  "ENSMUSG00000108736",
  "ENSMUSG00000108738",
  "ENSMUSG00000108757",
  "ENSMUSG00000108763",
  "ENSMUSG00000108801",
  "ENSMUSG00000108811",
  "ENSMUSG00000108827",
  "ENSMUSG00000108841",
  "ENSMUSG00000108848",
  "ENSMUSG00000108854",
  "ENSMUSG00000108885",
  "ENSMUSG00000108888",
  "ENSMUSG00000108900",
  "ENSMUSG00000108908",
  "ENSMUSG00000108919",
  "ENSMUSG00000108924",
  "ENSMUSG00000108929",
  "ENSMUSG00000108930",
  "ENSMUSG00000108931",
  "ENSMUSG00000108935",
  "ENSMUSG00000108948",
  "ENSMUSG00000108950",
  "ENSMUSG00000108976",
  "ENSMUSG00000108980",
  "ENSMUSG00000108981",
  "ENSMUSG00000108995",
  "ENSMUSG00000109002",
  "ENSMUSG00000109008",
  "ENSMUSG00000109058",
  "ENSMUSG00000109080",
  "ENSMUSG00000109089",
  "ENSMUSG00000109097",
  "ENSMUSG00000109106",
  "ENSMUSG00000109110",
  "ENSMUSG00000109113",
  "ENSMUSG00000109118",
  "ENSMUSG00000109127",
  "ENSMUSG00000109179",
  "ENSMUSG00000109190",
  "ENSMUSG00000109212",
  "ENSMUSG00000109215",
  "ENSMUSG00000109219",
  "ENSMUSG00000109228",
  "ENSMUSG00000109251",
  "ENSMUSG00000109270",
  "ENSMUSG00000109282",
  "ENSMUSG00000109284",
  "ENSMUSG00000109291",
  "ENSMUSG00000109305",
  "ENSMUSG00000109321",
  "ENSMUSG00000109322",
  "ENSMUSG00000109324",
  "ENSMUSG00000109336",
  "ENSMUSG00000109344",
  "ENSMUSG00000109354",
  "ENSMUSG00000109372",
  "ENSMUSG00000109376",
  "ENSMUSG00000109382",
  "ENSMUSG00000109392",
  "ENSMUSG00000109394",
  "ENSMUSG00000109398",
  "ENSMUSG00000109403",
  "ENSMUSG00000109446",
  "ENSMUSG00000109449",
  "ENSMUSG00000109473",
  "ENSMUSG00000109487",
  "ENSMUSG00000109497",
  "ENSMUSG00000109499",
  "ENSMUSG00000109511",
  "ENSMUSG00000109528",
  "ENSMUSG00000109542",
  "ENSMUSG00000109547",
  "ENSMUSG00000109564",
  "ENSMUSG00000109582",
  "ENSMUSG00000109587",
  "ENSMUSG00000109631",
  "ENSMUSG00000109644",
  "ENSMUSG00000109655",
  "ENSMUSG00000109659",
  "ENSMUSG00000109701",
  "ENSMUSG00000109718",
  "ENSMUSG00000109722",
  "ENSMUSG00000109745",
  "ENSMUSG00000109764",
  "ENSMUSG00000109770",
  "ENSMUSG00000109771",
  "ENSMUSG00000109780",
  "ENSMUSG00000109783",
  "ENSMUSG00000109808",
  "ENSMUSG00000109809",
  "ENSMUSG00000109813",
  "ENSMUSG00000109819",
  "ENSMUSG00000109827",
  "ENSMUSG00000109835",
  "ENSMUSG00000109841",
  "ENSMUSG00000109846",
  "ENSMUSG00000109853",
  "ENSMUSG00000109858",
  "ENSMUSG00000109864",
  "ENSMUSG00000109865",
  "ENSMUSG00000109884",
  "ENSMUSG00000109890",
  "ENSMUSG00000109901",
  "ENSMUSG00000109915",
  "ENSMUSG00000109951",
  "ENSMUSG00000109962",
  "ENSMUSG00000109995",
  "ENSMUSG00000110001",
  "ENSMUSG00000110008",
  "ENSMUSG00000110009",
  "ENSMUSG00000110012",
  "ENSMUSG00000110013",
  "ENSMUSG00000110026",
  "ENSMUSG00000110037",
  "ENSMUSG00000110042",
  "ENSMUSG00000110058",
  "ENSMUSG00000110061",
  "ENSMUSG00000110067",
  "ENSMUSG00000110080",
  "ENSMUSG00000110101",
  "ENSMUSG00000110103",
  "ENSMUSG00000110105",
  "ENSMUSG00000110106",
  "ENSMUSG00000110116",
  "ENSMUSG00000110138",
  "ENSMUSG00000110148",
  "ENSMUSG00000110161",
  "ENSMUSG00000110171",
  "ENSMUSG00000110185",
  "ENSMUSG00000110206",
  "ENSMUSG00000110217",
  "ENSMUSG00000110218",
  "ENSMUSG00000110221",
  "ENSMUSG00000110231",
  "ENSMUSG00000110235",
  "ENSMUSG00000110253",
  "ENSMUSG00000110259",
  "ENSMUSG00000110264",
  "ENSMUSG00000110266",
  "ENSMUSG00000110278",
  "ENSMUSG00000110281",
  "ENSMUSG00000110286",
  "ENSMUSG00000110321",
  "ENSMUSG00000110332",
  "ENSMUSG00000110340",
  "ENSMUSG00000110344",
  "ENSMUSG00000110356",
  "ENSMUSG00000110357",
  "ENSMUSG00000110358",
  "ENSMUSG00000110390",
  "ENSMUSG00000110409",
  "ENSMUSG00000110410",
  "ENSMUSG00000110417",
  "ENSMUSG00000110419",
  "ENSMUSG00000110424",
  "ENSMUSG00000110427",
  "ENSMUSG00000110476",
  "ENSMUSG00000110477",
  "ENSMUSG00000110523",
  "ENSMUSG00000110537",
  "ENSMUSG00000110541",
  "ENSMUSG00000110554",
  "ENSMUSG00000110573",
  "ENSMUSG00000110580",
  "ENSMUSG00000110592",
  "ENSMUSG00000110594",
  "ENSMUSG00000110605",
  "ENSMUSG00000110621",
  "ENSMUSG00000110622",
  "ENSMUSG00000110629",
  "ENSMUSG00000110630",
  "ENSMUSG00000110647",
  "ENSMUSG00000110661",
  "ENSMUSG00000110682",
  "ENSMUSG00000110684",
  "ENSMUSG00000110697",
  "ENSMUSG00000110710",
  "ENSMUSG00000110737",
  "ENSMUSG00000110752",
  "ENSMUSG00000110781",
  "ENSMUSG00000110784",
  "ENSMUSG00000110804",
  "ENSMUSG00000110819",
  "ENSMUSG00000110862",
  "ENSMUSG00000110874",
  "ENSMUSG00000110876",
  "ENSMUSG00000110912",
  "ENSMUSG00000110945",
  "ENSMUSG00000110947",
  "ENSMUSG00000110970",
  "ENSMUSG00000110980",
  "ENSMUSG00000111008",
  "ENSMUSG00000111013",
  "ENSMUSG00000111020",
  "ENSMUSG00000111021",
  "ENSMUSG00000111028",
  "ENSMUSG00000111037",
  "ENSMUSG00000111054",
  "ENSMUSG00000111056",
  "ENSMUSG00000111058",
  "ENSMUSG00000111078",
  "ENSMUSG00000111079",
  "ENSMUSG00000111085",
  "ENSMUSG00000111086",
  "ENSMUSG00000111103",
  "ENSMUSG00000111159",
  "ENSMUSG00000111174",
  "ENSMUSG00000111179",
  "ENSMUSG00000111180",
  "ENSMUSG00000111243",
  "ENSMUSG00000111246",
  "ENSMUSG00000111250",
  "ENSMUSG00000111259",
  "ENSMUSG00000111278",
  "ENSMUSG00000111290",
  "ENSMUSG00000111293",
  "ENSMUSG00000111303",
  "ENSMUSG00000111329",
  "ENSMUSG00000111334",
  "ENSMUSG00000111342",
  "ENSMUSG00000111357",
  "ENSMUSG00000111375",
  "ENSMUSG00000111412",
  "ENSMUSG00000111422",
  "ENSMUSG00000111439",
  "ENSMUSG00000111454",
  "ENSMUSG00000111456",
  "ENSMUSG00000111489",
  "ENSMUSG00000111494",
  "ENSMUSG00000111511",
  "ENSMUSG00000111517",
  "ENSMUSG00000111520",
  "ENSMUSG00000111556",
  "ENSMUSG00000111567",
  "ENSMUSG00000111593",
  "ENSMUSG00000111611",
  "ENSMUSG00000111629",
  "ENSMUSG00000111637",
  "ENSMUSG00000111669",
  "ENSMUSG00000111673",
  "ENSMUSG00000111699",
  "ENSMUSG00000111711",
  "ENSMUSG00000111715",
  "ENSMUSG00000111729",
  "ENSMUSG00000111731",
  "ENSMUSG00000111732",
  "ENSMUSG00000111734",
  "ENSMUSG00000111746",
  "ENSMUSG00000111747",
  "ENSMUSG00000111761",
  "ENSMUSG00000111765",
  "ENSMUSG00000111772",
  "ENSMUSG00000111774",
  "ENSMUSG00000111785",
  "ENSMUSG00000111794",
  "ENSMUSG00000111797",
  "ENSMUSG00000111807",
  "ENSMUSG00000111818",
  "ENSMUSG00000111825",
  "ENSMUSG00000111830",
  "ENSMUSG00000111836",
  "ENSMUSG00000111857",
  "ENSMUSG00000111863",
  "ENSMUSG00000111866",
  "ENSMUSG00000111867",
  "ENSMUSG00000111869",
  "ENSMUSG00000111903",
  "ENSMUSG00000111915",
  "ENSMUSG00000111924",
  "ENSMUSG00000111952",
  "ENSMUSG00000111961",
  "ENSMUSG00000111970",
  "ENSMUSG00000111994",
  "ENSMUSG00000112029",
  "ENSMUSG00000112030",
  "ENSMUSG00000112039",
  "ENSMUSG00000112041",
  "ENSMUSG00000112071",
  "ENSMUSG00000112117",
  "ENSMUSG00000112121",
  "ENSMUSG00000112129",
  "ENSMUSG00000112141",
  "ENSMUSG00000112157",
  "ENSMUSG00000112187",
  "ENSMUSG00000112198",
  "ENSMUSG00000112201",
  "ENSMUSG00000112206",
  "ENSMUSG00000112210",
  "ENSMUSG00000112223",
  "ENSMUSG00000112230",
  "ENSMUSG00000112242",
  "ENSMUSG00000112258",
  "ENSMUSG00000112264",
  "ENSMUSG00000112265",
  "ENSMUSG00000112276",
  "ENSMUSG00000112280",
  "ENSMUSG00000112290",
  "ENSMUSG00000112314",
  "ENSMUSG00000112323",
  "ENSMUSG00000112343",
  "ENSMUSG00000112380",
  "ENSMUSG00000112397",
  "ENSMUSG00000112398",
  "ENSMUSG00000112399",
  "ENSMUSG00000112404",
  "ENSMUSG00000112406",
  "ENSMUSG00000112412",
  "ENSMUSG00000112421",
  "ENSMUSG00000112444",
  "ENSMUSG00000112452",
  "ENSMUSG00000112466",
  "ENSMUSG00000112481",
  "ENSMUSG00000112482",
  "ENSMUSG00000112484",
  "ENSMUSG00000112489",
  "ENSMUSG00000112505",
  "ENSMUSG00000112513",
  "ENSMUSG00000112519",
  "ENSMUSG00000112527",
  "ENSMUSG00000112532",
  "ENSMUSG00000112533",
  "ENSMUSG00000112539",
  "ENSMUSG00000112540",
  "ENSMUSG00000112544",
  "ENSMUSG00000112558",
  "ENSMUSG00000112609",
  "ENSMUSG00000112611",
  "ENSMUSG00000112627",
  "ENSMUSG00000112639",
  "ENSMUSG00000112647",
  "ENSMUSG00000112654",
  "ENSMUSG00000112701",
  "ENSMUSG00000112705",
  "ENSMUSG00000112716",
  "ENSMUSG00000112762",
  "ENSMUSG00000112765",
  "ENSMUSG00000112796",
  "ENSMUSG00000112803",
  "ENSMUSG00000112821",
  "ENSMUSG00000112832",
  "ENSMUSG00000112837",
  "ENSMUSG00000112846",
  "ENSMUSG00000112854",
  "ENSMUSG00000112858",
  "ENSMUSG00000112871",
  "ENSMUSG00000112896",
  "ENSMUSG00000112907",
  "ENSMUSG00000112911",
  "ENSMUSG00000112920",
  "ENSMUSG00000112924",
  "ENSMUSG00000112940",
  "ENSMUSG00000112941",
  "ENSMUSG00000112957",
  "ENSMUSG00000112963",
  "ENSMUSG00000112980",
  "ENSMUSG00000112988",
  "ENSMUSG00000113035",
  "ENSMUSG00000113047",
  "ENSMUSG00000113056",
  "ENSMUSG00000113057",
  "ENSMUSG00000113058",
  "ENSMUSG00000113074",
  "ENSMUSG00000113084",
  "ENSMUSG00000113087",
  "ENSMUSG00000113093",
  "ENSMUSG00000113101",
  "ENSMUSG00000113210",
  "ENSMUSG00000113211",
  "ENSMUSG00000113216",
  "ENSMUSG00000113218",
  "ENSMUSG00000113220",
  "ENSMUSG00000113262",
  "ENSMUSG00000113302",
  "ENSMUSG00000113303",
  "ENSMUSG00000113314",
  "ENSMUSG00000113321",
  "ENSMUSG00000113331",
  "ENSMUSG00000113334",
  "ENSMUSG00000113336",
  "ENSMUSG00000113346",
  "ENSMUSG00000113361",
  "ENSMUSG00000113404",
  "ENSMUSG00000113415",
  "ENSMUSG00000113447",
  "ENSMUSG00000113463",
  "ENSMUSG00000113465",
  "ENSMUSG00000113470",
  "ENSMUSG00000113484",
  "ENSMUSG00000113492",
  "ENSMUSG00000113535",
  "ENSMUSG00000113544",
  "ENSMUSG00000113573",
  "ENSMUSG00000113575",
  "ENSMUSG00000113581",
  "ENSMUSG00000113616",
  "ENSMUSG00000113628",
  "ENSMUSG00000113634",
  "ENSMUSG00000113645",
  "ENSMUSG00000113652",
  "ENSMUSG00000113669",
  "ENSMUSG00000113671",
  "ENSMUSG00000113679",
  "ENSMUSG00000113689",
  "ENSMUSG00000113696",
  "ENSMUSG00000113707",
  "ENSMUSG00000113722",
  "ENSMUSG00000113769",
  "ENSMUSG00000113774",
  "ENSMUSG00000113778",
  "ENSMUSG00000113796",
  "ENSMUSG00000113800",
  "ENSMUSG00000113802",
  "ENSMUSG00000113816",
  "ENSMUSG00000113837",
  "ENSMUSG00000113846",
  "ENSMUSG00000113856",
  "ENSMUSG00000113875",
  "ENSMUSG00000113880",
  "ENSMUSG00000113884",
  "ENSMUSG00000113902",
  "ENSMUSG00000113909",
  "ENSMUSG00000113918",
  "ENSMUSG00000113919",
  "ENSMUSG00000113930",
  "ENSMUSG00000113947",
  "ENSMUSG00000113960",
  "ENSMUSG00000113973",
  "ENSMUSG00000113985",
  "ENSMUSG00000114024",
  "ENSMUSG00000114033",
  "ENSMUSG00000114048",
  "ENSMUSG00000114069",
  "ENSMUSG00000114073",
  "ENSMUSG00000114136",
  "ENSMUSG00000114159",
  "ENSMUSG00000114163",
  "ENSMUSG00000114181",
  "ENSMUSG00000114189",
  "ENSMUSG00000114196",
  "ENSMUSG00000114205",
  "ENSMUSG00000114206",
  "ENSMUSG00000114208",
  "ENSMUSG00000114210",
  "ENSMUSG00000114213",
  "ENSMUSG00000114235",
  "ENSMUSG00000114255",
  "ENSMUSG00000114274",
  "ENSMUSG00000114290",
  "ENSMUSG00000114303",
  "ENSMUSG00000114306",
  "ENSMUSG00000114333",
  "ENSMUSG00000114345",
  "ENSMUSG00000114350",
  "ENSMUSG00000114352",
  "ENSMUSG00000114362",
  "ENSMUSG00000114372",
  "ENSMUSG00000114379",
  "ENSMUSG00000114382",
  "ENSMUSG00000114384",
  "ENSMUSG00000114390",
  "ENSMUSG00000114393",
  "ENSMUSG00000114412",
  "ENSMUSG00000114439",
  "ENSMUSG00000114442",
  "ENSMUSG00000114452",
  "ENSMUSG00000114468",
  "ENSMUSG00000114469",
  "ENSMUSG00000114474",
  "ENSMUSG00000114491",
  "ENSMUSG00000114492",
  "ENSMUSG00000114511",
  "ENSMUSG00000114531",
  "ENSMUSG00000114539",
  "ENSMUSG00000114553",
  "ENSMUSG00000114554",
  "ENSMUSG00000114564",
  "ENSMUSG00000114567",
  "ENSMUSG00000114590",
  "ENSMUSG00000114598",
  "ENSMUSG00000114613",
  "ENSMUSG00000114632",
  "ENSMUSG00000114639",
  "ENSMUSG00000114656",
  "ENSMUSG00000114665",
  "ENSMUSG00000114689",
  "ENSMUSG00000114702",
  "ENSMUSG00000114736",
  "ENSMUSG00000114753",
  "ENSMUSG00000114755",
  "ENSMUSG00000114772",
  "ENSMUSG00000114780",
  "ENSMUSG00000114790",
  "ENSMUSG00000114792",
  "ENSMUSG00000114796",
  "ENSMUSG00000114797",
  "ENSMUSG00000114821",
  "ENSMUSG00000114826",
  "ENSMUSG00000114828",
  "ENSMUSG00000114865",
  "ENSMUSG00000114875",
  "ENSMUSG00000114879",
  "ENSMUSG00000114892",
  "ENSMUSG00000114898",
  "ENSMUSG00000114922",
  "ENSMUSG00000114926",
  "ENSMUSG00000114978",
  "ENSMUSG00000114980",
  "ENSMUSG00000114981",
  "ENSMUSG00000114982",
  "ENSMUSG00000114994",
  "ENSMUSG00000115001",
  "ENSMUSG00000115002",
  "ENSMUSG00000115008",
  "ENSMUSG00000115009",
  "ENSMUSG00000115012",
  "ENSMUSG00000115019",
  "ENSMUSG00000115020",
  "ENSMUSG00000115027",
  "ENSMUSG00000115091",
  "ENSMUSG00000115092",
  "ENSMUSG00000115099",
  "ENSMUSG00000115111",
  "ENSMUSG00000115123",
  "ENSMUSG00000115139",
  "ENSMUSG00000115142",
  "ENSMUSG00000115146",
  "ENSMUSG00000115149",
  "ENSMUSG00000115153",
  "ENSMUSG00000115170",
  "ENSMUSG00000115172",
  "ENSMUSG00000115177",
  "ENSMUSG00000115180",
  "ENSMUSG00000115181",
  "ENSMUSG00000115199",
  "ENSMUSG00000115219",
  "ENSMUSG00000115230",
  "ENSMUSG00000115236",
  "ENSMUSG00000115240",
  "ENSMUSG00000115245",
  "ENSMUSG00000115253",
  "ENSMUSG00000115263",
  "ENSMUSG00000115267",
  "ENSMUSG00000115271",
  "ENSMUSG00000115304",
  "ENSMUSG00000115317",
  "ENSMUSG00000115320",
  "ENSMUSG00000115324",
  "ENSMUSG00000115333",
  "ENSMUSG00000115338",
  "ENSMUSG00000115343",
  "ENSMUSG00000115355",
  "ENSMUSG00000115388",
  "ENSMUSG00000115395",
  "ENSMUSG00000115398",
  "ENSMUSG00000115404",
  "ENSMUSG00000115422",
  "ENSMUSG00000115424",
  "ENSMUSG00000115426",
  "ENSMUSG00000115432",
  "ENSMUSG00000115439",
  "ENSMUSG00000115454",
  "ENSMUSG00000115467",
  "ENSMUSG00000115480",
  "ENSMUSG00000115482",
  "ENSMUSG00000115506",
  "ENSMUSG00000115507",
  "ENSMUSG00000115518",
  "ENSMUSG00000115522",
  "ENSMUSG00000115526",
  "ENSMUSG00000115529",
  "ENSMUSG00000115540",
  "ENSMUSG00000115576",
  "ENSMUSG00000115596",
  "ENSMUSG00000115609",
  "ENSMUSG00000115625",
  "ENSMUSG00000115665",
  "ENSMUSG00000115667",
  "ENSMUSG00000115668",
  "ENSMUSG00000115676",
  "ENSMUSG00000115681",
  "ENSMUSG00000115689",
  "ENSMUSG00000115699",
  "ENSMUSG00000115701",
  "ENSMUSG00000115725",
  "ENSMUSG00000115727",
  "ENSMUSG00000115729",
  "ENSMUSG00000115744",
  "ENSMUSG00000115767",
  "ENSMUSG00000115774",
  "ENSMUSG00000115778",
  "ENSMUSG00000115779",
  "ENSMUSG00000115791",
  "ENSMUSG00000115792",
  "ENSMUSG00000115796",
  "ENSMUSG00000115799",
  "ENSMUSG00000115801",
  "ENSMUSG00000115810",
  "ENSMUSG00000115813",
  "ENSMUSG00000115821",
  "ENSMUSG00000115829",
  "ENSMUSG00000115834",
  "ENSMUSG00000115845",
  "ENSMUSG00000115858",
  "ENSMUSG00000115867",
  "ENSMUSG00000115936",
  "ENSMUSG00000115946",
  "ENSMUSG00000115970",
  "ENSMUSG00000115971",
  "ENSMUSG00000115987",
  "ENSMUSG00000115992",
  "ENSMUSG00000116004",
  "ENSMUSG00000116056",
  "ENSMUSG00000116061",
  "ENSMUSG00000116096",
  "ENSMUSG00000116097",
  "ENSMUSG00000116116",
  "ENSMUSG00000116123",
  "ENSMUSG00000116138",
  "ENSMUSG00000116165",
  "ENSMUSG00000116173",
  "ENSMUSG00000116220",
  "ENSMUSG00000116245",
  "ENSMUSG00000116260",
  "ENSMUSG00000116287",
  "ENSMUSG00000116305",
  "ENSMUSG00000116311",
  "ENSMUSG00000116350",
  "ENSMUSG00000116360",
  "ENSMUSG00000116376",
  "ENSMUSG00000116396",
  "ENSMUSG00000116469",
  "ENSMUSG00000116491",
  "ENSMUSG00000116498",
  "ENSMUSG00000116500",
  "ENSMUSG00000116505",
  "ENSMUSG00000116506",
  "ENSMUSG00000116520",
  "ENSMUSG00000116537",
  "ENSMUSG00000116542",
  "ENSMUSG00000116556",
  "ENSMUSG00000116557",
  "ENSMUSG00000116564",
  "ENSMUSG00000116597",
  "ENSMUSG00000116607",
  "ENSMUSG00000116644",
  "ENSMUSG00000116652",
  "ENSMUSG00000116654",
  "ENSMUSG00000116656",
  "ENSMUSG00000116658",
  "ENSMUSG00000116660",
  "ENSMUSG00000116673",
  "ENSMUSG00000116689",
  "ENSMUSG00000116695",
  "ENSMUSG00000116725",
  "ENSMUSG00000116751",
  "ENSMUSG00000116755",
  "ENSMUSG00000116786",
  "ENSMUSG00000116792",
  "ENSMUSG00000116799",
  "ENSMUSG00000116805",
  "ENSMUSG00000116813",
  "ENSMUSG00000116848",
  "ENSMUSG00000116852",
  "ENSMUSG00000116858",
  "ENSMUSG00000116877",
  "ENSMUSG00000116884",
  "ENSMUSG00000116920",
  "ENSMUSG00000116922",
  "ENSMUSG00000116924",
  "ENSMUSG00000116930",
  "ENSMUSG00000116955",
  "ENSMUSG00000116973",
  "ENSMUSG00000116975",
  "ENSMUSG00000116976",
  "ENSMUSG00000116983",
  "ENSMUSG00000116993",
  "ENSMUSG00000117026",
  "ENSMUSG00000117035",
  "ENSMUSG00000117042",
  "ENSMUSG00000117076",
  "ENSMUSG00000117100",
  "ENSMUSG00000117106",
  "ENSMUSG00000117110",
  "ENSMUSG00000117145",
  "ENSMUSG00000117148",
  "ENSMUSG00000117171",
  "ENSMUSG00000117172",
  "ENSMUSG00000117199",
  "ENSMUSG00000117210",
  "ENSMUSG00000117220",
  "ENSMUSG00000117229",
  "ENSMUSG00000117232",
  "ENSMUSG00000117251",
  "ENSMUSG00000117264",
  "ENSMUSG00000117284",
  "ENSMUSG00000117286",
  "ENSMUSG00000117287",
  "ENSMUSG00000117289",
  "ENSMUSG00000117292",
  "ENSMUSG00000117314",
  "ENSMUSG00000117315",
  "ENSMUSG00000117322",
  "ENSMUSG00000117328",
  "ENSMUSG00000117341",
  "ENSMUSG00000117350",
  "ENSMUSG00000117361",
  "ENSMUSG00000117365",
  "ENSMUSG00000117371",
  "ENSMUSG00000117391",
  "ENSMUSG00000117406",
  "ENSMUSG00000117410",
  "ENSMUSG00000117421",
  "ENSMUSG00000117422",
  "ENSMUSG00000117426",
  "ENSMUSG00000117427",
  "ENSMUSG00000117431",
  "ENSMUSG00000117442",
  "ENSMUSG00000117450",
  "ENSMUSG00000117473",
  "ENSMUSG00000117478",
  "ENSMUSG00000117505",
  "ENSMUSG00000117509",
  "ENSMUSG00000117524",
  "ENSMUSG00000117539",
  "ENSMUSG00000117564",
  "ENSMUSG00000117571",
  "ENSMUSG00000117602",
  "ENSMUSG00000117611",
  "ENSMUSG00000117613",
  "ENSMUSG00000117618",
  "ENSMUSG00000117621",
  "ENSMUSG00000117633",
  "ENSMUSG00000117652",
  "ENSMUSG00000117661",
  "ENSMUSG00000117662",
  "ENSMUSG00000117664",
  "ENSMUSG00000117666",
  "ENSMUSG00000117674",
  "ENSMUSG00000117679",
  "ENSMUSG00000117692",
  "ENSMUSG00000117696",
  "ENSMUSG00000117698",
  "ENSMUSG00000117710",
  "ENSMUSG00000117718",
  "ENSMUSG00000117727",
  "ENSMUSG00000117731",
  "ENSMUSG00000117744",
  "ENSMUSG00000117752",
  "ENSMUSG00000117763",
  "ENSMUSG00000117768",
  "ENSMUSG00000117780",
  "ENSMUSG00000117786",
  "ENSMUSG00000117792",
  "ENSMUSG00000117797",
  "ENSMUSG00000117800",
  "ENSMUSG00000117811",
  "ENSMUSG00000117824",
  "ENSMUSG00000117827",
  "ENSMUSG00000117853",
  "ENSMUSG00000117861",
  "ENSMUSG00000117863",
  "ENSMUSG00000117872",
  "ENSMUSG00000117879",
  "ENSMUSG00000117889",
  "ENSMUSG00000117892",
  "ENSMUSG00000117896",
  "ENSMUSG00000117899",
  "ENSMUSG00000117916",
  "ENSMUSG00000117924",
  "ENSMUSG00000117959",
  "ENSMUSG00000117975",
  "ENSMUSG00000117985",
  "ENSMUSG00000117993",
  "ENSMUSG00000117994",
  "ENSMUSG00000118001",
  "ENSMUSG00000118002",
  "ENSMUSG00000118004",
  "ENSMUSG00000118008",
  "ENSMUSG00000118016",
  "ENSMUSG00000118026",
  "ENSMUSG00000118027",
  "ENSMUSG00000118038",
  "ENSMUSG00000118060",
  "ENSMUSG00000118086",
  "ENSMUSG00000118094",
  "ENSMUSG00000118101",
  "ENSMUSG00000118103",
  "ENSMUSG00000118119",
  "ENSMUSG00000118141",
  "ENSMUSG00000118157",
  "ENSMUSG00000118202",
  "ENSMUSG00000118209",
  "ENSMUSG00000118215",
  "ENSMUSG00000118225",
  "ENSMUSG00000118257",
  "ENSMUSG00000118259",
  "ENSMUSG00000118267",
  "ENSMUSG00000118292",
  "ENSMUSG00000118311",
  "ENSMUSG00000118314",
  "ENSMUSG00000118316",
  "ENSMUSG00000118317",
  "ENSMUSG00000118318",
  "ENSMUSG00000118322",
  "ENSMUSG00000118333",
  "ENSMUSG00000118337",
  "ENSMUSG00000118339",
  "ENSMUSG00000118346",
  "ENSMUSG00000118347",
  "ENSMUSG00000118358",
  "ENSMUSG00000118366",
  "ENSMUSG00000118376",
  "ENSMUSG00000118380",
  "ENSMUSG00000118384",
  "ENSMUSG00000118388",
  "ENSMUSG00000118394",
  "ENSMUSG00000118401",
  "ENSMUSG00000118444",
  "ENSMUSG00000118449",
  "Eaf1",
  "Eaf2",
  "Eapp",
  "Ear14",
  "Ear6",
  "Ears2",
  "Ebf1",
  "Ebf2",
  "Ebf3",
  "Ebf4",
  "Ebi3",
  "Ebna1bp2",
  "Ebp",
  "Ebpl",
  "Ecd",
  "Ece1",
  "Ece2",
  "Ecel1",
  "Ech1",
  "Echdc1",
  "Echdc2",
  "Echdc3",
  "Echs1",
  "Eci1",
  "Eci2",
  "Eci3",
  "Ecm1",
  "Ecm2",
  "Ecpas",
  "Ecrg4",
  "Ecscr",
  "Ecsit",
  "Ect2",
  "Ect2l",
  "Eda",
  "Eda2r",
  "Edar",
  "Edaradd",
  "Edc3",
  "Edc4",
  "Eddm3b",
  "Edem1",
  "Edem2",
  "Edem3",
  "Edf1",
  "Edil3",
  "Edn1",
  "Edn2",
  "Edn3",
  "Ednra",
  "Ednrb",
  "Edrf1",
  "Eea1",
  "Eed",
  "Eef1a1",
  "Eef1a2",
  "Eef1akmt1",
  "Eef1akmt2",
  "Eef1akmt3",
  "Eef1akmt4",
  "Eef1aknmt",
  "Eef1b2",
  "Eef1d",
  "Eef1e1",
  "Eef2",
  "Eef2k",
  "Eef2kmt",
  "Eefsec",
  "Eepd1",
  "Efcab1",
  "Efcab10",
  "Efcab11",
  "Efcab12",
  "Efcab14",
  "Efcab15",
  "Efcab2",
  "Efcab5",
  "Efcab6",
  "Efcab7",
  "Efcab8",
  "Efcab9",
  "Efcc1",
  "Efemp1",
  "Efemp2",
  "Efhb",
  "Efhc1",
  "Efhc2",
  "Efhd1",
  "Efhd2",
  "Efl1",
  "Efna1",
  "Efna2",
  "Efna3",
  "Efna4",
  "Efna5",
  "Efnb1",
  "Efnb2",
  "Efnb3",
  "Efr3a",
  "Efr3b",
  "Efs",
  "Eftud2",
  "Egf",
  "Egfem1",
  "Egfl6",
  "Egfl7",
  "Egfl8",
  "Egflam",
  "Egfr",
  "Egln1",
  "Egln2",
  "Egln3",
  "Egr1",
  "Egr2",
  "Egr3",
  "Egr4",
  "Ehbp1",
  "Ehbp1l1",
  "Ehd1",
  "Ehd2",
  "Ehd3",
  "Ehd4",
  "Ehf",
  "Ehhadh",
  "Ehmt1",
  "Ehmt2",
  "Ei24",
  "Eid1",
  "Eid2",
  "Eid2b",
  "Eid3",
  "Eif1",
  "Eif1a",
  "Eif1ad",
  "Eif1ax",
  "Eif1b",
  "Eif2a",
  "Eif2ak1",
  "Eif2ak2",
  "Eif2ak3",
  "Eif2ak4",
  "Eif2b1",
  "Eif2b2",
  "Eif2b3",
  "Eif2b4",
  "Eif2b5",
  "Eif2d",
  "Eif2s1",
  "Eif2s2",
  "Eif2s3x",
  "Eif2s3y",
  "Eif3a",
  "Eif3b",
  "Eif3c",
  "Eif3d",
  "Eif3e",
  "Eif3f",
  "Eif3g",
  "Eif3h",
  "Eif3i",
  "Eif3j1",
  "Eif3k",
  "Eif3l",
  "Eif4a1",
  "Eif4a2",
  "Eif4a3",
  "Eif4a3l2",
  "Eif4b",
  "Eif4e",
  "Eif4e1b",
  "Eif4e2",
  "Eif4e3",
  "Eif4ebp1",
  "Eif4ebp2",
  "Eif4enif1",
  "Eif4g1",
  "Eif4g2",
  "Eif4g3",
  "Eif4h",
  "Eif5",
  "Eif5a",
  "Eif5a2",
  "Eif5b",
  "Eif6",
  "Eipr1",
  "Elac1",
  "Elac2",
  "Elane",
  "Elavl1",
  "Elavl2",
  "Elavl3",
  "Elavl4",
  "Eldr",
  "Elf1",
  "Elf2",
  "Elf3",
  "Elf4",
  "Elf5",
  "Elfn2",
  "Elk1",
  "Elk3",
  "Elk4",
  "Ell",
  "Ell2",
  "Ell3",
  "Elmo1",
  "Elmo2",
  "Elmo3",
  "Elmod1",
  "Elmod2",
  "Elmod3",
  "Elmsan1",
  "Eln",
  "Eloa",
  "Elob",
  "Elobl",
  "Eloc",
  "Elof1",
  "Elovl1",
  "Elovl2",
  "Elovl3",
  "Elovl4",
  "Elovl5",
  "Elovl6",
  "Elovl7",
  "Elp1",
  "Elp2",
  "Elp3",
  "Elp4",
  "Elp5",
  "Elp6",
  "Emb",
  "Emc1",
  "Emc10",
  "Emc3",
  "Emc4",
  "Emc6",
  "Emc7",
  "Emc8",
  "Emc9",
  "Emcn",
  "Emd",
  "Eme1",
  "Eme2",
  "Emg1",
  "Emid1",
  "Emilin1",
  "Emilin2",
  "Emilin3",
  "Eml1",
  "Eml2",
  "Eml3",
  "Eml4",
  "Eml5",
  "Eml6",
  "Emp1",
  "Emp2",
  "Emp3",
  "Emsy",
  "Emx1",
  "Emx2",
  "Emx2os",
  "En1",
  "En2",
  "Enah",
  "Enam",
  "Enc1",
  "Endod1",
  "Endog",
  "Endou",
  "Eng",
  "Engase",
  "Enho",
  "Enkd1",
  "Enkur",
  "Eno1b",
  "Eno2",
  "Eno3",
  "Eno4",
  "Enoph1",
  "Enox1",
  "Enox2",
  "Enpep",
  "Enpp1",
  "Enpp2",
  "Enpp3",
  "Enpp4",
  "Enpp5",
  "Enpp6",
  "Enpp7",
  "Ensa",
  "Enthd1",
  "Entpd1",
  "Entpd2",
  "Entpd3",
  "Entpd5",
  "Entpd6",
  "Entpd7",
  "Entpd8",
  "Entr1",
  "Eny2",
  "Eogt",
  "Eomes",
  "Ep300",
  "Ep400",
  "Epas1",
  "Epb41",
  "Epb41l1",
  "Epb41l2",
  "Epb41l3",
  "Epb41l4a",
  "Epb41l4b",
  "Epb41l5",
  "Epb42",
  "Epc1",
  "Epc2",
  "Epcam",
  "Epdr1",
  "Epg5",
  "Epgn",
  "Epha1",
  "Epha10",
  "Epha2",
  "Epha3",
  "Epha4",
  "Epha5",
  "Epha6",
  "Epha7",
  "Epha8",
  "Ephb1",
  "Ephb2",
  "Ephb4",
  "Ephb6",
  "Ephx1",
  "Ephx2",
  "Ephx3",
  "Ephx4",
  "Epm2a",
  "Epm2aip1",
  "Epn1",
  "Epn2",
  "Epn3",
  "Epo",
  "Epop",
  "Epor",
  "Epp13",
  "Eppin",
  "Eppk1",
  "Eprn",
  "Eprs",
  "Eps15",
  "Eps15l1",
  "Eps8",
  "Eps8l1",
  "Eps8l2",
  "Eps8l3",
  "Epsti1",
  "Epx",
  "Epyc",
  "Eqtn",
  "Eral1",
  "Erap1",
  "Eras",
  "Erbb2",
  "Erbb3",
  "Erbb4",
  "Erbin",
  "Erc1",
  "Erc2",
  "Ercc1",
  "Ercc2",
  "Ercc3",
  "Ercc4",
  "Ercc5",
  "Ercc6",
  "Ercc6l",
  "Ercc6l2",
  "Ercc8",
  "Ereg",
  "Erf",
  "Erfe",
  "Erfl",
  "Erg",
  "Erg28",
  "Ergic1",
  "Ergic2",
  "Ergic3",
  "Erh",
  "Eri1",
  "Eri2",
  "Eri3",
  "Erich1",
  "Erich2",
  "Erich3",
  "Erich4",
  "Erich6",
  "Erich6b",
  "Erlin1",
  "Erlin2",
  "Ermap",
  "Ermard",
  "Ermn",
  "Ermp1",
  "Ern1",
  "Ern2",
  "Ero1l",
  "Ero1lb",
  "Erp27",
  "Erp29",
  "Erp44",
  "Errfi1",
  "Erv3",
  "Esam",
  "Esco1",
  "Esco2",
  "Esd",
  "Esf1",
  "Esm1",
  "Esp1",
  "Esp6",
  "Espl1",
  "Espn",
  "Espnl",
  "Esr1",
  "Esr2",
  "Esrp1",
  "Esrp2",
  "Esrra",
  "Esrrb",
  "Esrrg",
  "Ess2",
  "Esx1",
  "Esyt1",
  "Esyt2",
  "Esyt3",
  "Etaa1",
  "Etd",
  "Etf1",
  "Etfa",
  "Etfb",
  "Etfbkmt",
  "Etfdh",
  "Etfrf1",
  "Ethe1",
  "Etl4",
  "Etnk1",
  "Etnk2",
  "Etnppl",
  "Etohd2",
  "Etos1",
  "Ets1",
  "Ets2",
  "Etv1",
  "Etv2",
  "Etv3",
  "Etv4",
  "Etv5",
  "Etv6",
  "Eva1a",
  "Eva1b",
  "Eva1c",
  "Evc",
  "Evc2",
  "Evi2",
  "Evi2a",
  "Evi5",
  "Evi5l",
  "Evl",
  "Evpl",
  "Evx1",
  "Evx1os",
  "Evx2",
  "Ewsr1",
  "Exd1",
  "Exd2",
  "Exo1",
  "Exo5",
  "Exoc1",
  "Exoc2",
  "Exoc3",
  "Exoc3l",
  "Exoc3l2",
  "Exoc3l4",
  "Exoc4",
  "Exoc5",
  "Exoc6",
  "Exoc6b",
  "Exoc7",
  "Exoc8",
  "Exog",
  "Exosc1",
  "Exosc10",
  "Exosc2",
  "Exosc3",
  "Exosc4",
  "Exosc5",
  "Exosc7",
  "Exosc8",
  "Exph5",
  "Ext1",
  "Ext2",
  "Extl1",
  "Extl2",
  "Extl3",
  "Eya2",
  "Eya3",
  "Eya4",
  "Ezh1",
  "Ezh2",
  "Ezr",
  "F10",
  "F11",
  "F11r",
  "F12",
  "F13a1",
  "F13b",
  "F2",
  "F2r",
  "F2rl1",
  "F2rl3",
  "F3",
  "F420014N23Rik",
  "F5",
  "F530104D19Rik",
  "F630028O10Rik",
  "F630042J09Rik",
  "F7",
  "F730016J06Rik",
  "F730035P03Rik",
  "F730043M19Rik",
  "F730048M01Rik",
  "F8",
  "F830016B08Rik",
  "F830045P16Rik",
  "F830208F22Rik",
  "F8a",
  "F9",
  "F930017D23Rik",
  "Fa2h",
  "Faah",
  "Faap100",
  "Faap20",
  "Faap24",
  "Fabp1",
  "Fabp12",
  "Fabp2",
  "Fabp3",
  "Fabp4",
  "Fabp5",
  "Fabp6",
  "Fabp7",
  "Fabp9",
  "Fadd",
  "Faddos",
  "Fads1",
  "Fads2",
  "Fads2b",
  "Fads3",
  "Fads6",
  "Faf1",
  "Faf2",
  "Fah",
  "Fahd1",
  "Fahd2a",
  "Faim",
  "Faiml",
  "Fam102a",
  "Fam102b",
  "Fam104a",
  "Fam107a",
  "Fam107b",
  "Fam110a",
  "Fam110b",
  "Fam110c",
  "Fam111a",
  "Fam114a1",
  "Fam114a2",
  "Fam117a",
  "Fam117b",
  "Fam118a",
  "Fam118b",
  "Fam120a",
  "Fam120b",
  "Fam120c",
  "Fam122a",
  "Fam122b",
  "Fam122c",
  "Fam124a",
  "Fam124b",
  "Fam126a",
  "Fam126b",
  "Fam129a",
  "Fam129b",
  "Fam129c",
  "Fam131a",
  "Fam131b",
  "Fam131c",
  "Fam135a",
  "Fam135b",
  "Fam136a",
  "Fam13a",
  "Fam13b",
  "Fam13c",
  "Fam149a",
  "Fam149b",
  "Fam151a",
  "Fam151b",
  "Fam155a",
  "Fam160a1",
  "Fam160a2",
  "Fam160b1",
  "Fam160b2",
  "Fam161a",
  "Fam161b",
  "Fam162a",
  "Fam162b",
  "Fam163a",
  "Fam163b",
  "Fam166a",
  "Fam166b",
  "Fam166c",
  "Fam167a",
  "Fam167b",
  "Fam168a",
  "Fam168b",
  "Fam169a",
  "Fam169b",
  "Fam170a",
  "Fam170b",
  "Fam171a1",
  "Fam171a2",
  "Fam171b",
  "Fam172a",
  "Fam174a",
  "Fam174b",
  "Fam174c",
  "Fam178b",
  "Fam180a",
  "Fam181a",
  "Fam181b",
  "Fam183b",
  "Fam184a",
  "Fam184b",
  "Fam185a",
  "Fam186a",
  "Fam187a",
  "Fam187b",
  "Fam189a1",
  "Fam189a2",
  "Fam189b",
  "Fam192a",
  "Fam193a",
  "Fam193b",
  "Fam199x",
  "Fam204a",
  "Fam205c",
  "Fam207a",
  "Fam209",
  "Fam20a",
  "Fam20b",
  "Fam20c",
  "Fam210a",
  "Fam210b",
  "Fam214a",
  "Fam214b",
  "Fam216a",
  "Fam216b",
  "Fam217a",
  "Fam217b",
  "Fam219aos",
  "Fam219b",
  "Fam221a",
  "Fam221b",
  "Fam222a",
  "Fam222b",
  "Fam227a",
  "Fam227b",
  "Fam228a",
  "Fam228b",
  "Fam229a",
  "Fam229b",
  "Fam234a",
  "Fam234b",
  "Fam240a",
  "Fam240b",
  "Fam241a",
  "Fam241b",
  "Fam243",
  "Fam24a",
  "Fam24b",
  "Fam25c",
  "Fam32a",
  "Fam3a",
  "Fam3b",
  "Fam3c",
  "Fam43b",
  "Fam45a",
  "Fam47c",
  "Fam47e",
  "Fam49a",
  "Fam49b",
  "Fam50a",
  "Fam50b",
  "Fam53a",
  "Fam53b",
  "Fam53c",
  "Fam57a",
  "Fam57b",
  "Fam71a",
  "Fam71b",
  "Fam71d",
  "Fam71e1",
  "Fam71e2",
  "Fam71f1",
  "Fam71f2",
  "Fam72a",
  "Fam76a",
  "Fam76b",
  "Fam78a",
  "Fam78b",
  "Fam81a",
  "Fam81b",
  "Fam83a",
  "Fam83b",
  "Fam83c",
  "Fam83d",
  "Fam83e",
  "Fam83f",
  "Fam83g",
  "Fam83h",
  "Fam89a",
  "Fam8a1",
  "Fam90a1a",
  "Fam90a1b",
  "Fam91a1",
  "Fam92a",
  "Fam92b",
  "Fam98a",
  "Fam98b",
  "Fam98c",
  "Fan1",
  "Fanca",
  "Fancb",
  "Fancc",
  "Fancd2",
  "Fancd2os",
  "Fance",
  "Fancf",
  "Fancg",
  "Fanci",
  "Fancl",
  "Fancm",
  "Fank1",
  "Fap",
  "Far1",
  "Far2",
  "Far2os2",
  "Farp1",
  "Farp2",
  "Fars2",
  "Farsa",
  "Farsb",
  "Fas",
  "Fasl",
  "Fasn",
  "Fastk",
  "Fastkd1",
  "Fastkd2",
  "Fastkd3",
  "Fastkd5",
  "Fat1",
  "Fat2",
  "Fat3",
  "Fat4",
  "Fate1",
  "Fau",
  "Faxc",
  "Fbf1",
  "Fbh1",
  "Fbl",
  "Fblim1",
  "Fbll1",
  "Fbln1",
  "Fbln2",
  "Fbln5",
  "Fbln7",
  "Fbn1",
  "Fbn2",
  "Fbp1",
  "Fbp2",
  "Fbrs",
  "Fbrsl1",
  "Fbxl12",
  "Fbxl12os",
  "Fbxl13",
  "Fbxl14",
  "Fbxl15",
  "Fbxl16",
  "Fbxl17",
  "Fbxl18",
  "Fbxl19",
  "Fbxl2",
  "Fbxl20",
  "Fbxl21",
  "Fbxl22",
  "Fbxl3",
  "Fbxl4",
  "Fbxl5",
  "Fbxl6",
  "Fbxl7",
  "Fbxl8",
  "Fbxo10",
  "Fbxo11",
  "Fbxo15",
  "Fbxo16",
  "Fbxo17",
  "Fbxo2",
  "Fbxo21",
  "Fbxo22",
  "Fbxo24",
  "Fbxo25",
  "Fbxo27",
  "Fbxo28",
  "Fbxo3",
  "Fbxo30",
  "Fbxo31",
  "Fbxo32",
  "Fbxo33",
  "Fbxo34",
  "Fbxo36",
  "Fbxo38",
  "Fbxo39",
  "Fbxo4",
  "Fbxo40",
  "Fbxo41",
  "Fbxo42",
  "Fbxo43",
  "Fbxo44",
  "Fbxo45",
  "Fbxo46",
  "Fbxo47",
  "Fbxo48",
  "Fbxo5",
  "Fbxo6",
  "Fbxo7",
  "Fbxo8",
  "Fbxo9",
  "Fbxw10",
  "Fbxw11",
  "Fbxw13",
  "Fbxw15",
  "Fbxw16",
  "Fbxw17",
  "Fbxw18",
  "Fbxw19",
  "Fbxw2",
  "Fbxw20",
  "Fbxw21",
  "Fbxw22",
  "Fbxw24",
  "Fbxw25",
  "Fbxw26",
  "Fbxw27",
  "Fbxw4",
  "Fbxw5",
  "Fbxw7",
  "Fbxw8",
  "Fbxw9",
  "Fcamr",
  "Fcer1a",
  "Fcer1g",
  "Fcer2a",
  "Fcgbp",
  "Fcgr1",
  "Fcgr2b",
  "Fcgr4",
  "Fcgrt",
  "Fcho1",
  "Fcho2",
  "Fchsd1",
  "Fchsd2",
  "Fcmr",
  "Fcna",
  "Fcnaos",
  "Fcnb",
  "Fcrl1",
  "Fcrl5",
  "Fcrl6",
  "Fcrla",
  "Fcrlb",
  "Fcrls",
  "Fcsk",
  "Fdft1",
  "Fdps",
  "Fdx1",
  "Fdxacb1",
  "Fdxr",
  "Fech",
  "Fem1a",
  "Fem1al",
  "Fem1b",
  "Fem1c",
  "Fen1",
  "Fendrr",
  "Fer",
  "Fer1l4",
  "Fer1l5",
  "Fer1l6",
  "Ferd3l",
  "Fermt1",
  "Fermt2",
  "Fermt3",
  "Fes",
  "Fetub",
  "Fev",
  "Fez1",
  "Fez2",
  "Fezf1",
  "Fezf2",
  "Ffar1",
  "Ffar2",
  "Ffar3",
  "Ffar4",
  "Fga",
  "Fgb",
  "Fgd1",
  "Fgd2",
  "Fgd3",
  "Fgd4",
  "Fgd6",
  "Fgf1",
  "Fgf10",
  "Fgf11",
  "Fgf12",
  "Fgf13",
  "Fgf14",
  "Fgf15",
  "Fgf17",
  "Fgf18",
  "Fgf2",
  "Fgf20",
  "Fgf22",
  "Fgf23",
  "Fgf2os",
  "Fgf3",
  "Fgf4",
  "Fgf5",
  "Fgf6",
  "Fgf7",
  "Fgf8",
  "Fgf9",
  "Fgfbp3",
  "Fgfr1",
  "Fgfr1op",
  "Fgfr1op2",
  "Fgfr2",
  "Fgfr3",
  "Fgfr4",
  "Fgfrl1",
  "Fgg",
  "Fggy",
  "Fgl1",
  "Fgl2",
  "Fgr",
  "Fh1",
  "Fhad1",
  "Fhdc1",
  "Fhit",
  "Fhitos",
  "Fhl1",
  "Fhl2",
  "Fhl3",
  "Fhl4",
  "Fhl5",
  "Fhod1",
  "Fhod3",
  "Fibcd1",
  "Fibin",
  "Fibp",
  "Ficd",
  "Fig4",
  "Figla",
  "Fign",
  "Fignl1",
  "Fignl2",
  "Filip1",
  "Filip1l",
  "Fip1l1",
  "Firre",
  "Fis1",
  "Fitm1",
  "Fitm2",
  "Fiz1",
  "Fjx1",
  "Fkbp11",
  "Fkbp14",
  "Fkbp15",
  "Fkbp1a",
  "Fkbp1b",
  "Fkbp2",
  "Fkbp3",
  "Fkbp4",
  "Fkbp5",
  "Fkbp6",
  "Fkbp7",
  "Fkbp8",
  "Fkbp9",
  "Fkbpl",
  "Fkrp",
  "Fktn",
  "Flacc1",
  "Flad1",
  "Flcn",
  "Flg",
  "Flg2",
  "Fli1",
  "Flicr",
  "Flii",
  "Flna",
  "Flnb",
  "Flnc",
  "Flot1",
  "Flot2",
  "Flrt1",
  "Flrt2",
  "Flrt3",
  "Flt1",
  "Flt3",
  "Flt3l",
  "Flt4",
  "Flvcr1",
  "Flvcr2",
  "Flywch1",
  "Flywch2",
  "Fmc1",
  "Fmn1",
  "Fmn2",
  "Fmnl1",
  "Fmnl2",
  "Fmnl3",
  "Fmo1",
  "Fmo2",
  "Fmo3",
  "Fmo4",
  "Fmo5",
  "Fmo6",
  "Fmo9",
  "Fmod",
  "Fmr1",
  "Fmr1nb",
  "Fn1",
  "Fn3k",
  "Fn3krp",
  "Fnbp1",
  "Fnbp1l",
  "Fnbp4",
  "Fnd3c2",
  "Fndc1",
  "Fndc10",
  "Fndc11",
  "Fndc3a",
  "Fndc3b",
  "Fndc3c1",
  "Fndc4",
  "Fndc5",
  "Fndc7",
  "Fndc8",
  "Fndc9",
  "Fnip1",
  "Fnip2",
  "Fnta",
  "Fntb",
  "Focad",
  "Folh1",
  "Folr1",
  "Folr2",
  "Fopnl",
  "Fos",
  "Fosb",
  "Fosl1",
  "Fosl2",
  "Foxa1",
  "Foxa2",
  "Foxa3",
  "Foxb1",
  "Foxb2",
  "Foxc1",
  "Foxc2",
  "Foxd1",
  "Foxd2",
  "Foxd2os",
  "Foxd3",
  "Foxd4",
  "Foxe1",
  "Foxe3",
  "Foxf1",
  "Foxf2",
  "Foxg1",
  "Foxh1",
  "Foxi1",
  "Foxi2",
  "Foxi3",
  "Foxj1",
  "Foxj2",
  "Foxj3",
  "Foxk1",
  "Foxk2",
  "Foxl1",
  "Foxl2",
  "Foxl2os",
  "Foxm1",
  "Foxn1",
  "Foxn2",
  "Foxn3",
  "Foxn4",
  "Foxo1",
  "Foxo3",
  "Foxo4",
  "Foxo6",
  "Foxo6os",
  "Foxp1",
  "Foxp2",
  "Foxp3",
  "Foxp4",
  "Foxq1",
  "Foxr1",
  "Foxr2",
  "Foxred1",
  "Foxred2",
  "Foxs1",
  "Fpgs",
  "Fpgt",
  "Fpr-rs3",
  "Fpr-rs4",
  "Fpr-rs6",
  "Fpr-rs7",
  "Fpr1",
  "Fpr2",
  "Fpr3",
  "Fra10ac1",
  "Fras1",
  "Frat1",
  "Frat2",
  "Frem1",
  "Frem2",
  "Frem3",
  "Frg1",
  "Frg2f1",
  "Frk",
  "Frmd3",
  "Frmd4a",
  "Frmd4b",
  "Frmd5",
  "Frmd6",
  "Frmd8",
  "Frmd8os",
  "Frmpd1",
  "Frmpd2",
  "Frmpd3",
  "Frmpd4",
  "Frrs1",
  "Frrs1l",
  "Frs2",
  "Frs3os",
  "Fry",
  "Fryl",
  "Frzb",
  "Fsbp",
  "Fscb",
  "Fscn1",
  "Fscn2",
  "Fscn3",
  "Fsd1",
  "Fsd1l",
  "Fsd2",
  "Fshb",
  "Fshr",
  "Fsip1",
  "Fsip2",
  "Fsip2l",
  "Fst",
  "Fstl1",
  "Fstl3",
  "Fstl4",
  "Fstl5",
  "Ftcd",
  "Ftdc1",
  "Ftdc2",
  "Fth1",
  "Fthl17a",
  "Fthl17c",
  "Fthl17f",
  "Ftl1-ps1",
  "Ftmt",
  "Fto",
  "Ftsj1",
  "Ftsj3",
  "Ftx",
  "Fubp1",
  "Fuca1",
  "Fuca2",
  "Fundc1",
  "Fundc2",
  "Fuom",
  "Furin",
  "Fus",
  "Fut1",
  "Fut10",
  "Fut11",
  "Fut2",
  "Fut4",
  "Fut7",
  "Fut8",
  "Fut9",
  "Fuz",
  "Fv1",
  "Fxr1",
  "Fxr2",
  "Fxyd1",
  "Fxyd2",
  "Fxyd3",
  "Fxyd4",
  "Fxyd5",
  "Fxyd6",
  "Fxyd7",
  "Fyb",
  "Fyb2",
  "Fyco1",
  "Fyn",
  "Fyttd1",
  "Fzd1",
  "Fzd10",
  "Fzd10os",
  "Fzd2",
  "Fzd3",
  "Fzd4",
  "Fzd5",
  "Fzd6",
  "Fzd7",
  "Fzd8",
  "Fzd9",
  "Fzr1",
  "G0s2",
  "G2e3",
  "G3bp1",
  "G3bp2",
  "G430095P16Rik",
  "G530011O06Rik",
  "G630016G05Rik",
  "G630018N14Rik",
  "G630030J09Rik",
  "G630055G22Rik",
  "G6pc",
  "G6pc3",
  "G6pd2",
  "G6pdx",
  "G930009F23Rik",
  "G930045G22Rik",
  "Gaa",
  "Gab1",
  "Gab2",
  "Gab3",
  "Gabarap",
  "Gabarapl1",
  "Gabarapl2",
  "Gabbr1",
  "Gabbr2",
  "Gabpa",
  "Gabpb1",
  "Gabpb2",
  "Gabra1",
  "Gabra2",
  "Gabra3",
  "Gabra4",
  "Gabra5",
  "Gabra6",
  "Gabrb1",
  "Gabrb3",
  "Gabrd",
  "Gabre",
  "Gabrg1",
  "Gabrg2",
  "Gabrg3",
  "Gabrp",
  "Gabrq",
  "Gabrr2",
  "Gabrr3",
  "Gad1",
  "Gad2",
  "Gadd45a",
  "Gadd45b",
  "Gadd45g",
  "Gadd45gip1",
  "Gadl1",
  "Gal",
  "Gal3st1",
  "Gal3st2c",
  "Gal3st3",
  "Galc",
  "Gale",
  "Galk1",
  "Galk2",
  "Galm",
  "Galns",
  "Galnt1",
  "Galnt10",
  "Galnt11",
  "Galnt12",
  "Galnt13",
  "Galnt14",
  "Galnt15",
  "Galnt16",
  "Galnt17",
  "Galnt18",
  "Galnt3",
  "Galnt4",
  "Galnt5",
  "Galnt6",
  "Galnt9",
  "Galntl5",
  "Galntl6",
  "Galp",
  "Galr1",
  "Galr2",
  "Galr3",
  "Galt",
  "Gamt",
  "Gan",
  "Ganab",
  "Ganc",
  "Gap43",
  "Gapdhs",
  "Gapt",
  "Gapvd1",
  "Gar1",
  "Garem1",
  "Garem2",
  "Garnl3",
  "Gars",
  "Gart",
  "Gas1",
  "Gas2",
  "Gas2l1",
  "Gas2l2",
  "Gas2l3",
  "Gas5",
  "Gas6",
  "Gas7",
  "Gas8",
  "Gask1a",
  "Gask1b",
  "Gast",
  "Gata1",
  "Gata2",
  "Gata3",
  "Gata4",
  "Gata5",
  "Gata5os",
  "Gata6",
  "Gata6os",
  "Gatad1",
  "Gatad2a",
  "Gatad2b",
  "Gatb",
  "Gatc",
  "Gatd1",
  "Gatd3a",
  "Gatm",
  "Gba",
  "Gba2",
  "Gbe1",
  "Gbf1",
  "Gbgt1",
  "Gbp2",
  "Gbp2b",
  "Gbp3",
  "Gbp4",
  "Gbp5",
  "Gbp7",
  "Gbp8",
  "Gbp9",
  "Gbx1",
  "Gbx2",
  "Gc",
  "Gca",
  "Gcat",
  "Gcc1",
  "Gcc2",
  "Gcdh",
  "Gcfc2",
  "Gcg",
  "Gcgr",
  "Gch1",
  "Gchfr",
  "Gck",
  "Gckr",
  "Gclc",
  "Gclm",
  "Gcm1",
  "Gcn1",
  "Gcnt1",
  "Gcnt2",
  "Gcnt3",
  "Gcnt4",
  "Gcnt7",
  "Gcsam",
  "Gcsh",
  "Gda",
  "Gdap1",
  "Gdap1l1",
  "Gdap2",
  "Gde1",
  "Gdf10",
  "Gdf11",
  "Gdf15",
  "Gdf2",
  "Gdf3",
  "Gdf5",
  "Gdf6",
  "Gdf7",
  "Gdf9",
  "Gdi1",
  "Gdi2",
  "Gdnf",
  "Gdpd1",
  "Gdpd2",
  "Gdpd3",
  "Gdpd4",
  "Gdpd5",
  "Gdpgp1",
  "Gem",
  "Gemin2",
  "Gemin5",
  "Gemin6",
  "Gemin7",
  "Gemin8",
  "Gen1",
  "Get1",
  "Get4",
  "Gfap",
  "Gfer",
  "Gfi1",
  "Gfi1b",
  "Gfm1",
  "Gfm2",
  "Gfod1",
  "Gfod2",
  "Gfpt1",
  "Gfpt2",
  "Gfra1",
  "Gfra2",
  "Gfra3",
  "Gfra4",
  "Gfral",
  "Gfy",
  "Gga1",
  "Gga2",
  "Gga3",
  "Ggact",
  "Ggct",
  "Ggcx",
  "Ggh",
  "Ggn",
  "Ggnbp1",
  "Ggnbp2",
  "Ggps1",
  "Ggt1",
  "Ggt5",
  "Ggt6",
  "Ggt7",
  "Ggta1",
  "Gh",
  "Ghdc",
  "Ghitm",
  "Ghr",
  "Ghrh",
  "Ghrhr",
  "Ghrl",
  "Ghsr",
  "Gid4",
  "Gid8",
  "Gigyf1",
  "Gigyf2",
  "Gimap1",
  "Gimap1os",
  "Gimap3",
  "Gimap4",
  "Gimap5",
  "Gimap6",
  "Gimap7",
  "Gimap8",
  "Gimap9",
  "Gimd1",
  "Gin1",
  "Ginm1",
  "Gins1",
  "Gins2",
  "Gins3",
  "Gins4",
  "Gip",
  "Gipc1",
  "Gipc2",
  "Gipc3",
  "Gipr",
  "Git1",
  "Gja1",
  "Gja10",
  "Gja3",
  "Gja4",
  "Gja5",
  "Gja6",
  "Gja8",
  "Gjb1",
  "Gjb2",
  "Gjb3",
  "Gjb4",
  "Gjb5",
  "Gjb6",
  "Gjc1",
  "Gjc2",
  "Gjc3",
  "Gjd2",
  "Gjd3",
  "Gjd4",
  "Gje1",
  "Gk",
  "Gk2",
  "Gk5",
  "Gkap1",
  "Gkn1",
  "Gkn2",
  "Gkn3",
  "Gla",
  "Glb1",
  "Glb1l",
  "Glb1l2",
  "Glb1l3",
  "Glcci1",
  "Glce",
  "Gldc",
  "Gldn",
  "Gldnos",
  "Gle1",
  "Glg1",
  "Gli1",
  "Gli2",
  "Gli3",
  "Glipr1",
  "Glipr1l1",
  "Glipr1l2",
  "Glipr1l3",
  "Glipr2",
  "Glis1",
  "Glis2",
  "Glis3",
  "Glmn",
  "Glmp",
  "Glo1",
  "Glod4",
  "Glod5",
  "Glp1r",
  "Glp2r",
  "Glra1",
  "Glra2",
  "Glra3",
  "Glra4",
  "Glrb",
  "Glrp1",
  "Glrx",
  "Glrx2",
  "Glrx3",
  "Glrx5",
  "Gls",
  "Gls2",
  "Glt1d1",
  "Glt28d2",
  "Glt6d1",
  "Glt8d1",
  "Glt8d2",
  "Gltp",
  "Gltpd2",
  "Glud1",
  "Glul",
  "Glyat",
  "Glyatl3",
  "Glycam1",
  "Glyctk",
  "Glyr1",
  "Gm10000",
  "Gm10010",
  "Gm10024",
  "Gm10030",
  "Gm10032",
  "Gm10037",
  "Gm10061",
  "Gm10062",
  "Gm10067",
  "Gm10097",
  "Gm10101",
  "Gm10113",
  "Gm10115",
  "Gm10125",
  "Gm10130",
  "Gm10134",
  "Gm10135",
  "Gm10142",
  "Gm10143",
  "Gm10153",
  "Gm10160",
  "Gm10165",
  "Gm10188",
  "Gm10190",
  "Gm10197",
  "Gm10220",
  "Gm10228",
  "Gm10229",
  "Gm10244",
  "Gm10248",
  "Gm10267",
  "Gm10272",
  "Gm10277",
  "Gm10280",
  "Gm10283",
  "Gm10287",
  "Gm10300",
  "Gm10308",
  "Gm10309",
  "Gm10320",
  "Gm10322",
  "Gm10334",
  "Gm10337",
  "Gm10366",
  "Gm10369",
  "Gm10371",
  "Gm10373",
  "Gm10382",
  "Gm10384",
  "Gm10385",
  "Gm10389",
  "Gm10392",
  "Gm10398",
  "Gm10399",
  "Gm10415",
  "Gm10419",
  "Gm10421",
  "Gm10425",
  "Gm1043",
  "Gm10432",
  "Gm10433",
  "Gm10435",
  "Gm10440",
  "Gm10441",
  "Gm10445",
  "Gm10447",
  "Gm10451",
  "Gm10457",
  "Gm10465",
  "Gm10466",
  "Gm10475",
  "Gm10482",
  "Gm10501",
  "Gm10503",
  "Gm10505",
  "Gm10516",
  "Gm10518",
  "Gm10521",
  "Gm10522",
  "Gm10530",
  "Gm10532",
  "Gm10535",
  "Gm10538",
  "Gm10542",
  "Gm10544",
  "Gm10545",
  "Gm10549",
  "Gm10550",
  "Gm10556",
  "Gm10558",
  "Gm10561",
  "Gm10564",
  "Gm10570",
  "Gm10575",
  "Gm10578",
  "Gm10584",
  "Gm10603",
  "Gm10605",
  "Gm10610",
  "Gm10614",
  "Gm10629",
  "Gm10635",
  "Gm10636",
  "Gm10637",
  "Gm10638",
  "Gm10639",
  "Gm10640",
  "Gm10642",
  "Gm10643",
  "Gm10644",
  "Gm10646",
  "Gm10648",
  "Gm10649",
  "Gm10655",
  "Gm10658",
  "Gm10660",
  "Gm10680",
  "Gm10684",
  "Gm10687",
  "Gm10688",
  "Gm1070",
  "Gm10701",
  "Gm10706",
  "Gm10710",
  "Gm10714",
  "Gm10732",
  "Gm10734",
  "Gm10739",
  "Gm10745",
  "Gm10747",
  "Gm10754",
  "Gm10762",
  "Gm10770",
  "Gm10775",
  "Gm10782",
  "Gm10785",
  "Gm10787",
  "Gm10790",
  "Gm10791",
  "Gm10799",
  "Gm10802",
  "Gm10804",
  "Gm10817",
  "Gm10823",
  "Gm10824",
  "Gm10827",
  "Gm10832",
  "Gm10837",
  "Gm10845",
  "Gm10847",
  "Gm10848",
  "Gm10851",
  "Gm10855",
  "Gm10856",
  "Gm10857",
  "Gm10860",
  "Gm10862",
  "Gm10863",
  "Gm10865",
  "Gm10873",
  "Gm10876",
  "Gm10941",
  "Gm10999",
  "Gm11033",
  "Gm1110",
  "Gm11100",
  "Gm11110",
  "Gm11127",
  "Gm11131",
  "Gm11149",
  "Gm11175",
  "Gm11190",
  "Gm11210",
  "Gm11213",
  "Gm11214",
  "Gm11217",
  "Gm1123",
  "Gm11232",
  "Gm11240",
  "Gm11274",
  "Gm11292",
  "Gm11335",
  "Gm1141",
  "Gm11412",
  "Gm11423",
  "Gm11426",
  "Gm11437",
  "Gm11444",
  "Gm11468",
  "Gm11494",
  "Gm11496",
  "Gm11497",
  "Gm11515",
  "Gm11520",
  "Gm11523",
  "Gm11527",
  "Gm11528",
  "Gm11541",
  "Gm11555",
  "Gm11559",
  "Gm11563",
  "Gm11564",
  "Gm11565",
  "Gm11567",
  "Gm11568",
  "Gm11595",
  "Gm11608",
  "Gm11613",
  "Gm11627",
  "Gm11634",
  "Gm11639",
  "Gm11680",
  "Gm11681",
  "Gm11696",
  "Gm11716",
  "Gm11728",
  "Gm11733",
  "Gm11747",
  "Gm11766",
  "Gm11767",
  "Gm11789",
  "Gm11823",
  "Gm11840",
  "Gm11938",
  "Gm11939",
  "Gm11961",
  "Gm11981",
  "Gm11992",
  "Gm12018",
  "Gm12022",
  "Gm12027",
  "Gm12052",
  "Gm12059",
  "Gm12089",
  "Gm12108",
  "Gm12128",
  "Gm12169",
  "Gm12171",
  "Gm12185",
  "Gm12200",
  "Gm12216",
  "Gm12253",
  "Gm12258",
  "Gm12273",
  "Gm12292",
  "Gm12298",
  "Gm12347",
  "Gm12349",
  "Gm12359",
  "Gm12371",
  "Gm12408",
  "Gm12409",
  "Gm12414",
  "Gm12474",
  "Gm12480",
  "Gm12500",
  "Gm12503",
  "Gm12511",
  "Gm12522",
  "Gm12526",
  "Gm12542",
  "Gm12569",
  "Gm12576",
  "Gm12580",
  "Gm12592",
  "Gm12609",
  "Gm12610",
  "Gm12665",
  "Gm12668",
  "Gm12689",
  "Gm12690",
  "Gm12695",
  "Gm12703",
  "Gm12711",
  "Gm12758",
  "Gm12784",
  "Gm12789",
  "Gm12790",
  "Gm12794",
  "Gm128",
  "Gm12800",
  "Gm12827",
  "Gm12830",
  "Gm12863",
  "Gm12866",
  "Gm12886",
  "Gm12888",
  "Gm12945",
  "Gm12962",
  "Gm12973",
  "Gm12992",
  "Gm13017",
  "Gm13021",
  "Gm13023",
  "Gm13030",
  "Gm13032",
  "Gm13063",
  "Gm13078",
  "Gm13083",
  "Gm13084",
  "Gm13088",
  "Gm13090",
  "Gm13101",
  "Gm13103",
  "Gm13112",
  "Gm13180",
  "Gm13199",
  "Gm13200",
  "Gm13201",
  "Gm13209",
  "Gm13218",
  "Gm13219",
  "Gm13262",
  "Gm13274",
  "Gm1330",
  "Gm13344",
  "Gm13375",
  "Gm13391",
  "Gm13403",
  "Gm13420",
  "Gm13429",
  "Gm13446",
  "Gm13483",
  "Gm13490",
  "Gm13496",
  "Gm13522",
  "Gm13546",
  "Gm13547",
  "Gm13580",
  "Gm13582",
  "Gm136",
  "Gm13619",
  "Gm13661",
  "Gm13663",
  "Gm13686",
  "Gm13710",
  "Gm13713",
  "Gm13749",
  "Gm13773",
  "Gm13775",
  "Gm13793",
  "Gm13807",
  "Gm13816",
  "Gm13832",
  "Gm13833",
  "Gm13861",
  "Gm13889",
  "Gm13941",
  "Gm13943",
  "Gm13954",
  "Gm13977",
  "Gm13986",
  "Gm13997",
  "Gm13999",
  "Gm14003",
  "Gm14004",
  "Gm14010",
  "Gm14014",
  "Gm14015",
  "Gm14066",
  "Gm14089",
  "Gm14110",
  "Gm14124",
  "Gm14135",
  "Gm14137",
  "Gm14139",
  "Gm14149",
  "Gm14154",
  "Gm14164",
  "Gm14168",
  "Gm14204",
  "Gm14206",
  "Gm14207",
  "Gm14211",
  "Gm14214",
  "Gm14221",
  "Gm14222",
  "Gm14230",
  "Gm14243",
  "Gm14246",
  "Gm14296",
  "Gm14317",
  "Gm14321",
  "Gm14340",
  "Gm14393",
  "Gm14410",
  "Gm14412",
  "Gm14424",
  "Gm14443",
  "Gm14461",
  "Gm14488",
  "Gm14496",
  "Gm14634",
  "Gm14635",
  "Gm14636",
  "Gm14661",
  "Gm14703",
  "Gm14717",
  "Gm14762",
  "Gm14773",
  "Gm14812",
  "Gm14820",
  "Gm14862",
  "Gm14963",
  "Gm14964",
  "Gm14966",
  "Gm15046",
  "Gm15056",
  "Gm15104",
  "Gm15130",
  "Gm15155",
  "Gm15217",
  "Gm15222",
  "Gm15232",
  "Gm15234",
  "Gm15262",
  "Gm15270",
  "Gm15283",
  "Gm15294",
  "Gm15298",
  "Gm15318",
  "Gm15328",
  "Gm15340",
  "Gm15342",
  "Gm15348",
  "Gm15353",
  "Gm15401",
  "Gm15406",
  "Gm15407",
  "Gm15409",
  "Gm15412",
  "Gm15413",
  "Gm15417",
  "Gm15441",
  "Gm15448",
  "Gm15457",
  "Gm15458",
  "Gm15478",
  "Gm15479",
  "Gm15482",
  "Gm15498",
  "Gm15503",
  "Gm15520",
  "Gm1553",
  "Gm15535",
  "Gm15557",
  "Gm15612",
  "Gm15688",
  "Gm15691",
  "Gm15704",
  "Gm15706",
  "Gm15737",
  "Gm15743",
  "Gm15762",
  "Gm15821",
  "Gm15850",
  "Gm1587",
  "Gm15952",
  "Gm15998",
  "Gm16046",
  "Gm16062",
  "Gm16066",
  "Gm16140",
  "Gm16155",
  "Gm16181",
  "Gm16223",
  "Gm16267",
  "Gm16286",
  "Gm16287",
  "Gm16294",
  "Gm16347",
  "Gm16401",
  "Gm16497",
  "Gm16499",
  "Gm16506",
  "Gm16508",
  "Gm16551",
  "Gm16675",
  "Gm1673",
  "Gm16853",
  "Gm16863",
  "Gm16894",
  "Gm16958",
  "Gm16982",
  "Gm17018",
  "Gm17019",
  "Gm17092",
  "Gm1715",
  "Gm17175",
  "Gm17202",
  "Gm17216",
  "Gm17226",
  "Gm17359",
  "Gm17382",
  "Gm17399",
  "Gm17484",
  "Gm17494",
  "Gm17501",
  "Gm17509",
  "Gm17745",
  "Gm17749",
  "Gm17751",
  "Gm17753",
  "Gm17767",
  "Gm17783",
  "Gm19299",
  "Gm19325",
  "Gm19410",
  "Gm19510",
  "Gm1965",
  "Gm1968",
  "Gm19705",
  "Gm1976",
  "Gm1992",
  "Gm19935",
  "Gm20036",
  "Gm20089",
  "Gm2018",
  "Gm20219",
  "Gm20342",
  "Gm2044",
  "Gm20443",
  "Gm20468",
  "Gm20485",
  "Gm20492",
  "Gm20508",
  "Gm20556",
  "Gm20559",
  "Gm2061",
  "Gm20611",
  "Gm20619",
  "Gm20627",
  "Gm20634",
  "Gm20661",
  "Gm20703",
  "Gm20707",
  "Gm20732",
  "Gm20754",
  "Gm20755",
  "Gm20757",
  "Gm20758",
  "Gm20767",
  "Gm20914",
  "Gm20918",
  "Gm2093",
  "Gm20939",
  "Gm2109",
  "Gm2115",
  "Gm21292",
  "Gm21319",
  "Gm2155",
  "Gm2164",
  "Gm21719",
  "Gm21762",
  "Gm21781",
  "Gm21917",
  "Gm21958",
  "Gm21976",
  "Gm21987",
  "Gm2233",
  "Gm2245",
  "Gm2270",
  "Gm2310",
  "Gm2350",
  "Gm2366",
  "Gm2379",
  "Gm2381",
  "Gm2415",
  "Gm2447",
  "Gm2453",
  "Gm2464",
  "Gm2479",
  "Gm2511",
  "Gm2516",
  "Gm2582",
  "Gm2629",
  "Gm26513",
  "Gm26562",
  "Gm26569",
  "Gm26576",
  "Gm266",
  "Gm26604",
  "Gm26651",
  "Gm26652",
  "Gm26654",
  "Gm26671",
  "Gm26682",
  "Gm2670",
  "Gm26708",
  "Gm26737",
  "Gm2675",
  "Gm26760",
  "Gm26783",
  "Gm26795",
  "Gm26806",
  "Gm2682",
  "Gm26850",
  "Gm26879",
  "Gm26936",
  "Gm2694",
  "Gm26967",
  "Gm26979",
  "Gm27003",
  "Gm27008",
  "Gm27011",
  "Gm27017",
  "Gm27022",
  "Gm27042",
  "Gm27048",
  "Gm27194",
  "Gm27197",
  "Gm2721",
  "Gm27230",
  "Gm27253",
  "Gm27255",
  "Gm2788",
  "Gm28050",
  "Gm28055",
  "Gm281",
  "Gm2814",
  "Gm2822",
  "Gm28229",
  "Gm28237",
  "Gm2824",
  "Gm28269",
  "Gm28271",
  "Gm28286",
  "Gm28386",
  "Gm28410",
  "Gm28415",
  "Gm28502",
  "Gm28557",
  "Gm28653",
  "Gm28694",
  "Gm28721",
  "Gm28723",
  "Gm28750",
  "Gm28856",
  "Gm28876",
  "Gm28905",
  "Gm28919",
  "Gm28981",
  "Gm29016",
  "Gm29018",
  "Gm29055",
  "Gm29106",
  "Gm29113",
  "Gm29152",
  "Gm29187",
  "Gm29243",
  "Gm29254",
  "Gm29323",
  "Gm29340",
  "Gm29346",
  "Gm29455",
  "Gm29456",
  "Gm29458",
  "Gm29482",
  "Gm29483",
  "Gm29538",
  "Gm29554",
  "Gm29630",
  "Gm29666",
  "Gm29674",
  "Gm29676",
  "Gm29677",
  "Gm29683",
  "Gm29684",
  "Gm29686",
  "Gm29707",
  "Gm29724",
  "Gm29735",
  "Gm29776",
  "Gm29797",
  "Gm29825",
  "Gm2990",
  "Gm29992",
  "Gm29999",
  "Gm2a",
  "Gm30015",
  "Gm30074",
  "Gm30085",
  "Gm30211",
  "Gm30228",
  "Gm30302",
  "Gm30363",
  "Gm3045",
  "Gm30454",
  "Gm30498",
  "Gm30524",
  "Gm30551",
  "Gm30556",
  "Gm30613",
  "Gm30641",
  "Gm30784",
  "Gm30984",
  "Gm31013",
  "Gm31048",
  "Gm31087",
  "Gm31105",
  "Gm31135",
  "Gm31363",
  "Gm31373",
  "Gm31503",
  "Gm31562",
  "Gm31698",
  "Gm31718",
  "Gm31727",
  "Gm31805",
  "Gm31854",
  "Gm3187",
  "Gm31908",
  "Gm31946",
  "Gm31992",
  "Gm32031",
  "Gm3211",
  "Gm32126",
  "Gm32219",
  "Gm32235",
  "Gm3227",
  "Gm32296",
  "Gm3234",
  "Gm3235",
  "Gm32357",
  "Gm32364",
  "Gm32391",
  "Gm32442",
  "Gm32461",
  "Gm32468",
  "Gm32479",
  "Gm3252",
  "Gm32531",
  "Gm32548",
  "Gm32585",
  "Gm32591",
  "Gm32618",
  "Gm32742",
  "Gm32743",
  "Gm3279",
  "Gm32828",
  "Gm3285",
  "Gm32856",
  "Gm3287",
  "Gm3289",
  "Gm32916",
  "Gm33045",
  "Gm33055",
  "Gm33103",
  "Gm33125",
  "Gm33206",
  "Gm33222",
  "Gm33251",
  "Gm3327",
  "Gm33299",
  "Gm3331",
  "Gm33337",
  "Gm3336",
  "Gm33424",
  "Gm3364",
  "Gm33651",
  "Gm33782",
  "Gm33819",
  "Gm33948",
  "Gm33969",
  "Gm33994",
  "Gm34006",
  "Gm34059",
  "Gm34066",
  "Gm34093",
  "Gm3428",
  "Gm34307",
  "Gm34342",
  "Gm34388",
  "Gm34466",
  "Gm3455",
  "Gm34590",
  "Gm34653",
  "Gm34684",
  "Gm34808",
  "Gm34983",
  "Gm35028",
  "Gm35040",
  "Gm3509",
  "Gm3510",
  "Gm3513",
  "Gm35189",
  "Gm3519",
  "Gm35208",
  "Gm35229",
  "Gm35239",
  "Gm35256",
  "Gm35298",
  "Gm35315",
  "Gm35333",
  "Gm35339",
  "Gm35404",
  "Gm35438",
  "Gm35453",
  "Gm35550",
  "Gm35554",
  "Gm35625",
  "Gm35665",
  "Gm35696",
  "Gm3573",
  "Gm35772",
  "Gm35835",
  "Gm3587",
  "Gm36028",
  "Gm36033",
  "Gm36037",
  "Gm3604",
  "Gm36107",
  "Gm36199",
  "Gm36200",
  "Gm36210",
  "Gm36235",
  "Gm36236",
  "Gm36283",
  "Gm36315",
  "Gm36377",
  "Gm364",
  "Gm36426",
  "Gm3646",
  "Gm36535",
  "Gm36543",
  "Gm36560",
  "Gm36696",
  "Gm36723",
  "Gm36738",
  "Gm36756",
  "Gm36860",
  "Gm36862",
  "Gm36864",
  "Gm36913",
  "Gm3693",
  "Gm37146",
  "Gm3716",
  "Gm37170",
  "Gm37267",
  "Gm3734",
  "Gm37494",
  "Gm37573",
  "Gm3764",
  "Gm37640",
  "Gm3772",
  "Gm37855",
  "Gm37896",
  "Gm38100",
  "Gm38102",
  "Gm38132",
  "Gm38186",
  "Gm382",
  "Gm38246",
  "Gm38293",
  "Gm3830",
  "Gm38324",
  "Gm38398",
  "Gm38403",
  "Gm38405",
  "Gm3848",
  "Gm3854",
  "Gm38563",
  "Gm3880",
  "Gm38944",
  "Gm3896",
  "Gm39090",
  "Gm39164",
  "Gm3924",
  "Gm39317",
  "Gm39348",
  "Gm39436",
  "Gm39464",
  "Gm3970",
  "Gm3985",
  "Gm40038",
  "Gm40040",
  "Gm4013",
  "Gm4022",
  "Gm40271",
  "Gm40304",
  "Gm40331",
  "Gm4035",
  "Gm4065",
  "Gm40770",
  "Gm40787",
  "Gm40828",
  "Gm40841",
  "Gm40847",
  "Gm4107",
  "Gm4117",
  "Gm4128",
  "Gm4131",
  "Gm41600",
  "Gm41638",
  "Gm41639",
  "Gm4189",
  "Gm4208",
  "Gm4211",
  "Gm4219",
  "Gm42205",
  "Gm4221",
  "Gm4224",
  "Gm4230",
  "Gm42372",
  "Gm42375",
  "Gm4241",
  "Gm42425",
  "Gm42450",
  "Gm4251",
  "Gm4258",
  "Gm42609",
  "Gm42610",
  "Gm42688",
  "Gm42707",
  "Gm42722",
  "Gm42723",
  "Gm42743",
  "Gm42745",
  "Gm4278",
  "Gm4279",
  "Gm42800",
  "Gm42812",
  "Gm4285",
  "Gm42884",
  "Gm42916",
  "Gm42921",
  "Gm42931",
  "Gm42945",
  "Gm43050",
  "Gm43077",
  "Gm43086",
  "Gm43091",
  "Gm43100",
  "Gm43154",
  "Gm4316",
  "Gm4319",
  "Gm43262",
  "Gm43263",
  "Gm43293",
  "Gm43305",
  "Gm43517",
  "Gm43538",
  "Gm4356",
  "Gm43573",
  "Gm43598",
  "Gm43635",
  "Gm43660",
  "Gm43661",
  "Gm43672",
  "Gm43688",
  "Gm43803",
  "Gm43851",
  "Gm43950",
  "Gm44",
  "Gm44006",
  "Gm44028",
  "Gm44081",
  "Gm4409",
  "Gm44113",
  "Gm44166",
  "Gm4419",
  "Gm44196",
  "Gm44204",
  "Gm4425",
  "Gm44257",
  "Gm44366",
  "Gm44410",
  "Gm4450",
  "Gm44621",
  "Gm4473",
  "Gm44777",
  "Gm44864",
  "Gm4489",
  "Gm44899",
  "Gm4491",
  "Gm44997",
  "Gm45014",
  "Gm45052",
  "Gm45079",
  "Gm45083",
  "Gm45151",
  "Gm45155",
  "Gm45188",
  "Gm45202",
  "Gm45231",
  "Gm4524",
  "Gm45337",
  "Gm45338",
  "Gm45408",
  "Gm4544",
  "Gm45447",
  "Gm45494",
  "Gm4553",
  "Gm45627",
  "Gm4566",
  "Gm45666",
  "Gm45716",
  "Gm4577",
  "Gm45844",
  "Gm45866",
  "Gm45869",
  "Gm45901",
  "Gm45941",
  "Gm4598",
  "Gm4610",
  "Gm4631",
  "Gm4632",
  "Gm46336",
  "Gm46376",
  "Gm4640",
  "Gm46447",
  "Gm4651",
  "Gm46606",
  "Gm4665",
  "Gm4668",
  "Gm4673",
  "Gm4675",
  "Gm4681",
  "Gm47003",
  "Gm47015",
  "Gm47047",
  "Gm47076",
  "Gm4710",
  "Gm47112",
  "Gm47157",
  "Gm4719",
  "Gm47204",
  "Gm47235",
  "Gm47289",
  "Gm47336",
  "Gm47359",
  "Gm4744",
  "Gm47461",
  "Gm47469",
  "Gm47480",
  "Gm47547",
  "Gm4755",
  "Gm47552",
  "Gm47633",
  "Gm47671",
  "Gm47705",
  "Gm47715",
  "Gm47774",
  "Gm4779",
  "Gm47792",
  "Gm47818",
  "Gm4787",
  "Gm4788",
  "Gm47902",
  "Gm4791",
  "Gm4792",
  "Gm47921",
  "Gm4793",
  "Gm47966",
  "Gm48038",
  "Gm48053",
  "Gm48075",
  "Gm48081",
  "Gm48089",
  "Gm4814",
  "Gm48294",
  "Gm48311",
  "Gm48395",
  "Gm48399",
  "Gm4841",
  "Gm4847",
  "Gm48505",
  "Gm48538",
  "Gm48551",
  "Gm4861",
  "Gm48610",
  "Gm48622",
  "Gm48623",
  "Gm48671",
  "Gm4869",
  "Gm4871",
  "Gm4872",
  "Gm48727",
  "Gm48742",
  "Gm4876",
  "Gm48812",
  "Gm48866",
  "Gm48879",
  "Gm4890",
  "Gm48925",
  "Gm4894",
  "Gm48957",
  "Gm48960",
  "Gm48969",
  "Gm48970",
  "Gm49066",
  "Gm4907",
  "Gm49086",
  "Gm49097",
  "Gm49173",
  "Gm4922",
  "Gm49224",
  "Gm49237",
  "Gm4924",
  "Gm49240",
  "Gm49249",
  "Gm49336",
  "Gm49390",
  "Gm49448",
  "Gm49451",
  "Gm49452",
  "Gm49454",
  "Gm49472",
  "Gm4951",
  "Gm4952",
  "Gm49539",
  "Gm49575",
  "Gm49580",
  "Gm49632",
  "Gm4969",
  "Gm49698",
  "Gm49708",
  "Gm49711",
  "Gm49723",
  "Gm49793",
  "Gm49797",
  "Gm49808",
  "Gm4981",
  "Gm4985",
  "Gm49857",
  "Gm49872",
  "Gm49891",
  "Gm49932",
  "Gm49975",
  "Gm5",
  "Gm50019",
  "Gm50100",
  "Gm50114",
  "Gm50145",
  "Gm50218",
  "Gm50227",
  "Gm50304",
  "Gm50335",
  "Gm50350",
  "Gm50475",
  "Gm50477",
  "Gm5065",
  "Gm5082",
  "Gm5083",
  "Gm5084",
  "Gm5086",
  "Gm5087",
  "Gm5089",
  "Gm5091",
  "Gm5092",
  "Gm5094",
  "Gm5095",
  "Gm5099",
  "Gm5103",
  "Gm5105",
  "Gm5106",
  "Gm5108",
  "Gm5111",
  "Gm5114",
  "Gm5118",
  "Gm5122",
  "Gm5127",
  "Gm5128",
  "Gm5129",
  "Gm5134",
  "Gm5136",
  "Gm5141",
  "Gm5142",
  "Gm5144",
  "Gm5148",
  "Gm5150",
  "Gm5155",
  "Gm5244",
  "Gm525",
  "Gm527",
  "Gm5294",
  "Gm53",
  "Gm534",
  "Gm536",
  "Gm5414",
  "Gm5420",
  "Gm5427",
  "Gm5431",
  "Gm5432",
  "Gm5441",
  "Gm5444",
  "Gm5458",
  "Gm5460",
  "Gm5463",
  "Gm5464",
  "Gm5468",
  "Gm5475",
  "Gm5478",
  "Gm5485",
  "Gm5493",
  "Gm550",
  "Gm5524",
  "Gm553",
  "Gm5532",
  "Gm5533",
  "Gm5535",
  "Gm5544",
  "Gm5546",
  "Gm5547",
  "Gm5565",
  "Gm5570",
  "Gm5577",
  "Gm5591",
  "Gm5592",
  "Gm5600",
  "Gm5602",
  "Gm5608",
  "Gm5617",
  "Gm5627",
  "Gm567",
  "Gm5678",
  "Gm568",
  "Gm572",
  "Gm5724",
  "Gm5737",
  "Gm5741",
  "Gm5751",
  "Gm5767",
  "Gm5784",
  "Gm5800",
  "Gm5820",
  "Gm5833",
  "Gm5849",
  "Gm5860",
  "Gm5878",
  "Gm5893",
  "Gm5901",
  "Gm5907",
  "Gm5914",
  "Gm5916",
  "Gm5922",
  "Gm5938",
  "Gm5965",
  "Gm597",
  "Gm6034",
  "Gm6040",
  "Gm609",
  "Gm6093",
  "Gm6116",
  "Gm6117",
  "Gm6133",
  "Gm614",
  "Gm6145",
  "Gm6209",
  "Gm6213",
  "Gm6217",
  "Gm6225",
  "Gm6249",
  "Gm6260",
  "Gm6268",
  "Gm6270",
  "Gm6277",
  "Gm6288",
  "Gm6313",
  "Gm6329",
  "Gm6358",
  "Gm6377",
  "Gm6410",
  "Gm6416",
  "Gm6471",
  "Gm648",
  "Gm6525",
  "Gm6559",
  "Gm6566",
  "Gm6567",
  "Gm6569",
  "Gm6583",
  "Gm6588",
  "Gm6598",
  "Gm6602",
  "Gm6614",
  "Gm6639",
  "Gm671",
  "Gm6712",
  "Gm6760",
  "Gm6878",
  "Gm6938",
  "Gm6961",
  "Gm6980",
  "Gm6993",
  "Gm6999",
  "Gm7008",
  "Gm7072",
  "Gm7073",
  "Gm7097",
  "Gm7102",
  "Gm7160",
  "Gm7247",
  "Gm7271",
  "Gm7276",
  "Gm7298",
  "Gm732",
  "Gm7457",
  "Gm7467",
  "Gm7480",
  "Gm7489",
  "Gm7568",
  "Gm7579",
  "Gm7580",
  "Gm7596",
  "Gm7607",
  "Gm7616",
  "Gm7628",
  "Gm7644",
  "Gm765",
  "Gm7694",
  "Gm7706",
  "Gm7714",
  "Gm773",
  "Gm7854",
  "Gm7932",
  "Gm7967",
  "Gm8005",
  "Gm8013",
  "Gm805",
  "Gm8066",
  "Gm807",
  "Gm8075",
  "Gm8098",
  "Gm8104",
  "Gm8126",
  "Gm815",
  "Gm8212",
  "Gm8220",
  "Gm8247",
  "Gm8251",
  "Gm826",
  "Gm8300",
  "Gm831",
  "Gm833",
  "Gm8334",
  "Gm8369",
  "Gm8378",
  "Gm839",
  "Gm8439",
  "Gm8579",
  "Gm8633",
  "Gm867",
  "Gm8817",
  "Gm884",
  "Gm8883",
  "Gm8909",
  "Gm9",
  "Gm9008",
  "Gm9017",
  "Gm9112",
  "Gm9195",
  "Gm9376",
  "Gm94",
  "Gm9465",
  "Gm9530",
  "Gm9573",
  "Gm960",
  "Gm973",
  "Gm9733",
  "Gm9736",
  "Gm9747",
  "Gm9748",
  "Gm9750",
  "Gm9752",
  "Gm9754",
  "Gm9767",
  "Gm9774",
  "Gm9776",
  "Gm9796",
  "Gm9801",
  "Gm9803",
  "Gm9821",
  "Gm9828",
  "Gm9833",
  "Gm9856",
  "Gm9857",
  "Gm9866",
  "Gm9871",
  "Gm9873",
  "Gm9885",
  "Gm9887",
  "Gm9888",
  "Gm9889",

  "Gm9895",
  "Gm9898",
  "Gm9902",
  "Gm9903",
  "Gm9908",
  "Gm9913",
  "Gm9915",
  "Gm9916",
  "Gm9917",
  "Gm9918",
  "Gm9920",
  "Gm9921",
  "Gm9922",
  "Gm9925",
  "Gm9926",
  "Gm9932",
  "Gm9934",
  "Gm9936",
  "Gm9937",
  "Gm9944",
  "Gm9946",
  "Gm9947",
  "Gm9949",
  "Gm9951",
  "Gm9955",
  "Gm9958",
  "Gm9961",
  "Gm9962",
  "Gm9967",
  "Gm9968",
  "Gm9970",
  "Gm9979",
  "Gm9984",
  "Gm9989",
  "Gm9991",
  "Gm9993",
  "Gm9999",
  "Gmcl1",
  "Gmds",
  "Gmeb1",
  "Gmeb2",
  "Gmfb",
  "Gmfg",
  "Gmip",
  "Gml",
  "Gml2",
  "Gmnc",
  "Gmnn",
  "Gmppa",
  "Gmppb",
  "Gmpr",
  "Gmpr2",
  "Gmps",
  "Gna11",
  "Gna12",
  "Gna13",
  "Gna14",
  "Gna15",
  "Gnai1",
  "Gnai2",
  "Gnai3",
  "Gnal",
  "Gnao1",
  "Gnaq",
  "Gnas",
  "Gnasas1",
  "Gnat1",
  "Gnat2",
  "Gnat3",
  "Gnaz",
  "Gnb1",
  "Gnb1l",
  "Gnb3",
  "Gnb4",
  "Gnb5",
  "Gne",
  "Gng11",
  "Gng12",
  "Gng13",
  "Gng2",
  "Gng3",
  "Gng4",
  "Gng7",
  "Gng8",
  "Gngt1",
  "Gngt2",
  "Gnl1",
  "Gnl2",
  "Gnl3",
  "Gnl3l",
  "Gnmt",
  "Gnpat",
  "Gnpda1",
  "Gnpda2",
  "Gnpnat1",
  "Gnptab",
  "Gnptg",
  "Gnrh1",
  "Gnrhr",
  "Gns",
  "Golga1",
  "Golga2",
  "Golga3",
  "Golga4",
  "Golga5",
  "Golga7",
  "Golga7b",
  "Golgb1",
  "Golim4",
  "Golm1",
  "Golph3",
  "Golph3l",
  "Golt1a",
  "Golt1b",
  "Gon7",
  "Gopc",
  "Gorab",
  "Gorasp1",
  "Gorasp2",
  "Gosr1",
  "Gosr2",
  "Got1",
  "Got1l1",
  "Got2",
  "Gp1ba",
  "Gp2",
  "Gp5",
  "Gp6",
  "Gp9",
  "Gpa33",
  "Gpaa1",
  "Gpalpp1",
  "Gpam",
  "Gpank1",
  "Gpat2",
  "Gpat3",
  "Gpat4",
  "Gpatch1",
  "Gpatch11",
  "Gpatch2",
  "Gpatch2l",
  "Gpatch3",
  "Gpatch4",
  "Gpatch8",
  "Gpbar1",
  "Gpbp1",
  "Gpbp1l1",
  "Gpc1",
  "Gpc3",
  "Gpc4",
  "Gpc5",
  "Gpc6",
  "Gpcpd1",
  "Gpd1",
  "Gpd1l",
  "Gpd2",
  "Gper1",
  "Gpha2",
  "Gphb5",
  "Gphn",
  "Gpi1",
  "Gpihbp1",
  "Gpkow",
  "Gpld1",
  "Gpm6a",
  "Gpm6b",
  "Gpn1",
  "Gpn2",
  "Gpn3",
  "Gpnmb",
  "Gpr1",
  "Gpr101",
  "Gpr107",
  "Gpr108",
  "Gpr119",
  "Gpr12",
  "Gpr132",
  "Gpr135",
  "Gpr137",
  "Gpr137b",
  "Gpr137c",
  "Gpr139",
  "Gpr141",
  "Gpr141b",
  "Gpr142",
  "Gpr143",
  "Gpr146",
  "Gpr149",
  "Gpr15",
  "Gpr150",
  "Gpr151",
  "Gpr152",
  "Gpr153",
  "Gpr155",
  "Gpr156",
  "Gpr158",
  "Gpr160",
  "Gpr161",
  "Gpr162",
  "Gpr165",
  "Gpr17",
  "Gpr171",
  "Gpr173",
  "Gpr174",
  "Gpr176",
  "Gpr179",
  "Gpr18",
  "Gpr182",
  "Gpr183",
  "Gpr19",
  "Gpr20",
  "Gpr21",
  "Gpr22",
  "Gpr25",
  "Gpr26",
  "Gpr27",
  "Gpr3",
  "Gpr33",
  "Gpr34",
  "Gpr35",
  "Gpr37",
  "Gpr37l1",
  "Gpr39",
  "Gpr4",
  "Gpr45",
  "Gpr52",
  "Gpr55",
  "Gpr6",
  "Gpr61",
  "Gpr63",
  "Gpr65",
  "Gpr68",
  "Gpr75",
  "Gpr82",
  "Gpr83",
  "Gpr84",
  "Gpr85",
  "Gpr87",
  "Gpr88",
  "Gpr89",
  "Gprasp1",
  "Gprasp2",
  "Gprc5b",
  "Gprc5c",
  "Gprc5d",
  "Gprc6a",
  "Gprin1",
  "Gprin2",
  "Gprin3",
  "Gps1",
  "Gps2",
  "Gpsm1",
  "Gpsm2",
  "Gpsm3",
  "Gpt",
  "Gpt2",
  "Gpx1",
  "Gpx2",
  "Gpx3",
  "Gpx5",
  "Gpx6",
  "Gpx7",
  "Gpx8",
  "Gramd1a",
  "Gramd1b",
  "Gramd2",
  "Gramd3",
  "Gramd4",
  "Grap",
  "Grap2",
  "Grasp",
  "Grb10",
  "Grb14",
  "Grb2",
  "Grb7",
  "Grcc10",
  "Greb1",
  "Greb1l",
  "Grem1",
  "Grem2",
  "Grhl1",
  "Grhl2",
  "Grhl3",
  "Grhpr",
  "Gria1",
  "Gria2",
  "Gria3",
  "Gria4",
  "Grid1",
  "Grid2",
  "Grid2ip",
  "Grifin",
  "Grik1",
  "Grik2",
  "Grik3",
  "Grik4",
  "Grik5",
  "Grin1",
  "Grin2a",
  "Grin2b",
  "Grin2c",
  "Grin2d",
  "Grin3a",
  "Grin3b",
  "Grina",
  "Grip1",
  "Grip1os1",
  "Grip2",
  "Grk1",
  "Grk2",
  "Grk3",
  "Grk4",
  "Grk5",
  "Grk6",
  "Grm1",
  "Grm2",
  "Grm4",
  "Grm5",
  "Grm6",
  "Grm7",
  "Grm8",
  "Grn",
  "Grp",
  "Grpel1",
  "Grpel2",
  "Grpr",
  "Grrp1",
  "Grsf1",
  "Grtp1",
  "Grwd1",
  "Grxcr1",
  "Grxcr2",
  "Gsap",
  "Gsc",
  "Gsc2",
  "Gsdma",
  "Gsdma2",
  "Gsdma3",
  "Gsdmc3",
  "Gsdmc4",
  "Gsdmd",
  "Gsdme",
  "Gse1",
  "Gsg1",
  "Gsg1l",
  "Gsg1l2",
  "Gsk3a",
  "Gsk3b",
  "Gskip",
  "Gsn",
  "Gspt1",
  "Gspt2",
  "Gsr",
  "Gss",
  "Gsta3",
  "Gsta4",
  "Gstcd",
  "Gstk1",
  "Gstm1",
  "Gstm2",
  "Gstm3",
  "Gstm4",
  "Gstm5",
  "Gstm6",
  "Gstm7",
  "Gsto1",
  "Gsto2",
  "Gstp1",
  "Gstp3",
  "Gstt1",
  "Gstt2",
  "Gstt3",
  "Gstt4",
  "Gstz1",
  "Gsx1",
  "Gsx2",
  "Gt(ROSA)26Sor",
  "Gtdc1",
  "Gtf2a1",
  "Gtf2a1l",
  "Gtf2a2",
  "Gtf2b",
  "Gtf2e1",
  "Gtf2e2",
  "Gtf2f1",
  "Gtf2f2",
  "Gtf2h1",
  "Gtf2h2",
  "Gtf2h3",
  "Gtf2h4",
  "Gtf2h5",
  "Gtf2i",
  "Gtf2ird1",
  "Gtf2ird2",
  "Gtf3a",
  "Gtf3c1",
  "Gtf3c2",
  "Gtf3c3",
  "Gtf3c4",
  "Gtf3c5",
  "Gtf3c6",
  "Gtpbp1",
  "Gtpbp10",
  "Gtpbp2",
  "Gtpbp3",
  "Gtpbp4",
  "Gtpbp6",
  "Gtpbp8",
  "Gtse1",
  "Gtsf1",
  "Gtsf1l",
  "Gtsf2",
  "Guca1b",
  "Guca2a",
  "Guca2b",
  "Gucd1",
  "Gucy1a1",
  "Gucy1a2",
  "Gucy1b1",
  "Gucy1b2",
  "Gucy2c",
  "Gucy2d",
  "Gucy2e",
  "Gucy2f",
  "Gucy2g",
  "Guf1",
  "Guk1",
  "Gulo",
  "Gulp1",
  "Gusb",
  "Gxylt1",
  "Gxylt2",
  "Gyg",
  "Gykl1",
  "Gypa",
  "Gypc",
  "Gys1",
  "Gys2",
  "Gzf1",
  "Gzma",
  "Gzmb",
  "Gzmc",
  "Gzmd",
  "Gzme",
  "Gzmf",
  "Gzmg",
  "Gzmk",
  "Gzmm",
  "Gzmn",
  "H13",
  "H19",
  "H1f0",
  "H1f1",
  "H1f10",
  "H1f2",
  "H1f3",
  "H1f4",
  "H1f5",
  "H1f6",
  "H1f7",
  "H1f8",
  "H2-Aa",
  "H2-Ab1",
  "H2-D1",
  "H2-DMa",
  "H2-DMb1",
  "H2-DMb2",
  "H2-Eb1",
  "H2-Eb2",
  "H2-K1",
  "H2-M1",
  "H2-M10.1",
  "H2-M10.2",
  "H2-M10.3",
  "H2-M10.4",
  "H2-M10.6",
  "H2-M11",
  "H2-M2",
  "H2-M3",
  "H2-M5",
  "H2-M9",
  "H2-Oa",
  "H2-Ob",
  "H2-Q1",
  "H2-Q10",
  "H2-Q2",
  "H2-Q4",
  "H2-Q6",
  "H2-T22",
  "H2-T23",
  "H2-T24",
  "H2-T3",
  "H2ab2",
  "H2ac1",
  "H2ac10",
  "H2ac11",
  "H2ac12",
  "H2ac15",
  "H2ac20",
  "H2ac21",
  "H2ac4",
  "H2ac6",
  "H2ac8",
  "H2aj",
  "H2al1j",
  "H2al1n",
  "H2al2a",
  "H2al3",
  "H2ap",
  "H2aw",
  "H2ax",
  "H2az1",
  "H2az2",
  "H2bc1",
  "H2bc21",
  "H2bc3",
  "H2bc4",
  "H2bc6",
  "H2bc8",
  "H2bfm",
  "H2bl1",
  "H2bu2",
  "H3c1",
  "H3c3",
  "H3c7",
  "H3c8",
  "H3f3a",
  "H3f3aos",
  "H3f3b",
  "H4c11",
  "H4c14",
  "H4c4",
  "H4c8",
  "H4c9",
  "H4f16",
  "H60b",
  "H60c",
  "H6pd",
  "Haao",
  "Habp2",
  "Habp4",
  "Hacd1",
  "Hacd2",
  "Hacd3",
  "Hacd4",
  "Hace1",
  "Hacl1",
  "Hadh",
  "Hadha",
  "Hadhb",
  "Hagh",
  "Haghl",
  "Haglr",
  "Hal",
  "Hamp",
  "Hamp2",
  "Hand1",
  "Hand2",
  "Hand2os1",
  "Hao1",
  "Hao2",
  "Hap1",
  "Hapln2",
  "Hapln3",
  "Hapln4",
  "Harbi1",
  "Hars",
  "Hars2",
  "Has1",
  "Has2",
  "Has2os",
  "Has3",
  "Haspin",
  "Hat1",
  "Haus1",
  "Haus2",
  "Haus3",
  "Haus4",
  "Haus5",
  "Haus6",
  "Haus7",
  "Haus8",
  "Havcr1",
  "Havcr2",
  "Hax1",
  "Hba-x",
  "Hbb-bh1",
  "Hbb-bh2",
  "Hbb-bs",
  "Hbb-bt",
  "Hbb-y",
  "Hbegf",
  "Hbp1",
  "Hbq1a",
  "Hbq1b",
  "Hbs1l",
  "Hc",
  "Hcar1",
  "Hcar2",
  "Hccs",
  "Hcfc1",
  "Hcfc1r1",
  "Hcfc2",
  "Hck",
  "Hcls1",
  "Hcn1",
  "Hcn2",
  "Hcn3",
  "Hcn4",
  "Hcrt",
  "Hcrtr1",
  "Hcrtr2",
  "Hcst",
  "Hdac1",
  "Hdac10",
  "Hdac11",
  "Hdac2",
  "Hdac3",
  "Hdac4",
  "Hdac5",
  "Hdac6",
  "Hdac7",
  "Hdac8",
  "Hdac9",
  "Hdc",
  "Hddc2",
  "Hddc3",
  "Hdgf",
  "Hdgfl1",
  "Hdgfl2",
  "Hdgfl3",
  "Hdhd2",
  "Hdhd3",
  "Hdhd5",
  "Hdlbp",
  "Hdx",
  "Heatr1",
  "Heatr3",
  "Heatr5a",
  "Heatr5b",
  "Heatr6",
  "Heatr9",
  "Hebp1",
  "Hebp2",
  "Heca",
  "Hectd1",
  "Hectd2",
  "Hectd2os",
  "Hectd3",
  "Hectd4",
  "Hecw2",
  "Heg1",
  "Helb",
  "Hells",
  "Helq",
  "Helt",
  "Helz",
  "Helz2",
  "Hemk1",
  "Henmt1",
  "Hepacam",
  "Hepacam2",
  "Heph",
  "Hephl1",
  "Herc1",
  "Herc2",
  "Herc3",
  "Herc4",
  "Herc6",
  "Herpud1",
  "Herpud2",
  "Hes1",
  "Hes2",
  "Hes3",
  "Hes5",
  "Hes6",
  "Hes7",
  "Hesx1",
  "Hexa",
  "Hexb",
  "Hexdc",
  "Hexim1",
  "Hexim2",
  "Hey1",
  "Hey2",
  "Heyl",
  "Hfe",
  "Hfm1",
  "Hgd",
  "Hgf",
  "Hgfac",
  "Hgh1",
  "Hgs",
  "Hgsnat",
  "Hhat",
  "Hhatl",
  "Hhex",
  "Hhip",
  "Hhipl1",
  "Hhipl2",
  "Hhla1",
  "Hibadh",
  "Hibch",
  "Hic1",
  "Hic2",
  "Hid1",
  "Hif1a",
  "Hif1an",
  "Hif3a",
  "Higd1a",
  "Higd1b",
  "Higd2a",
  "Hikeshi",
  "Hilpda",
  "Hils1",
  "Hinfp",
  "Hint1",
  "Hint2",
  "Hint3",
  "Hip1",
  "Hip1r",
  "Hipk1",
  "Hipk2",
  "Hipk3",
  "Hipk4",
  "Hirip3",
  "Hist1h2bp",
  "Hist1h2br",
  "Hist1h4n",
  "Hivep1",
  "Hivep2",
  "Hivep3",
  "Hjurp",
  "Hjv",
  "Hk1",
  "Hk1os",
  "Hk2",
  "Hk3",
  "Hkdc1",
  "Hlcs",
  "Hlf",
  "Hltf",
  "Hlx",
  "Hmbox1",
  "Hmbs",
  "Hmces",
  "Hmcn1",
  "Hmcn2",
  "Hmg20a",
  "Hmg20b",
  "Hmga1",
  "Hmga2",
  "Hmgb1",
  "Hmgb2",
  "Hmgb3",
  "Hmgb4",
  "Hmgb4os",
  "Hmgcl",
  "Hmgcll1",
  "Hmgcr",
  "Hmgcs1",
  "Hmgcs2",
  "Hmgn1",
  "Hmgn3",
  "Hmgn5",
  "Hmgxb3",
  "Hmgxb4",
  "Hmox1",
  "Hmox2",
  "Hmx1",
  "Hmx2",
  "Hmx3",
  "Hnf1a",
  "Hnf1b",
  "Hnf4a",
  "Hnf4aos",
  "Hnf4g",
  "Hnmt",
  "Hnrnpa0",
  "Hnrnpa1",
  "Hnrnpa2b1",
  "Hnrnpa3",
  "Hnrnpab",
  "Hnrnpc",
  "Hnrnpd",
  "Hnrnpdl",
  "Hnrnpf",
  "Hnrnph1",
  "Hnrnph2",
  "Hnrnph3",
  "Hnrnpk",
  "Hnrnpl",
  "Hnrnpll",
  "Hnrnpm",
  "Hnrnpr",
  "Hnrnpu",
  "Hnrnpul1",
  "Hnrnpul2",
  "Hoga1",
  "Homer1",
  "Homer2",
  "Homer3",
  "Homez",
  "Hook1",
  "Hook2",
  "Hook3",
  "Hopx",
  "Hormad1",
  "Hormad2",
  "Hotair",
  "Hottip",
  "Hoxa1",
  "Hoxa10",
  "Hoxa11",
  "Hoxa11os",
  "Hoxa13",
  "Hoxa2",
  "Hoxa3",
  "Hoxa4",
  "Hoxa5",
  "Hoxa6",
  "Hoxa7",
  "Hoxa9",
  "Hoxaas2",
  "Hoxb1",
  "Hoxb13",
  "Hoxb2",
  "Hoxb3",
  "Hoxb4",
  "Hoxb5",
  "Hoxb5os",
  "Hoxb6",
  "Hoxb7",
  "Hoxb9",
  "Hoxc10",
  "Hoxc11",
  "Hoxc12",
  "Hoxc13",
  "Hoxc4",
  "Hoxc5",
  "Hoxc6",
  "Hoxc8",
  "Hoxc9",
  "Hoxd1",
  "Hoxd10",
  "Hoxd11",
  "Hoxd12",
  "Hoxd13",
  "Hoxd3os1",
  "Hoxd4",
  "Hoxd8",
  "Hoxd9",
  "Hp",
  "Hp1bp3",
  "Hpca",
  "Hpcal1",
  "Hpcal4",
  "Hpd",
  "Hpdl",
  "Hpf1",
  "Hpgd",
  "Hpgds",
  "Hpn",
  "Hprt",
  "Hps1",
  "Hps3",
  "Hps4",
  "Hps5",
  "Hps6",
  "Hpse",
  "Hpse2",
  "Hpx",
  "Hr",
  "Hras",
  "Hrc",
  "Hrct1",
  "Hrg",
  "Hrh1",
  "Hrh2",
  "Hrh3",
  "Hrh4",
  "Hrk",
  "Hrnr",
  "Hrob",
  "Hs1bp3",
  "Hs2st1",
  "Hs3st1",
  "Hs3st2",
  "Hs3st3a1",
  "Hs3st4",
  "Hs3st5",
  "Hs3st6",
  "Hs6st1",
  "Hs6st2",
  "Hs6st3",
  "Hsbp1",
  "Hsbp1l1",
  "Hscb",
  "Hsd11b1",
  "Hsd11b2",
  "Hsd17b1",
  "Hsd17b10",
  "Hsd17b11",
  "Hsd17b12",
  "Hsd17b13",
  "Hsd17b14",
  "Hsd17b2",
  "Hsd17b3",
  "Hsd17b4",
  "Hsd17b6",
  "Hsd17b7",
  "Hsd3b1",
  "Hsd3b2",
  "Hsd3b3",
  "Hsd3b5",
  "Hsd3b6",
  "Hsdl1",
  "Hsdl2",
  "Hsf1",
  "Hsf2",
  "Hsf2bp",
  "Hsf3",
  "Hsf4",
  "Hsf5",
  "Hsfy2",
  "Hsh2d",
  "Hsp90aa1",
  "Hsp90ab1",
  "Hsp90b1",
  "Hspa12a",
  "Hspa12b",
  "Hspa13",
  "Hspa14",
  "Hspa1l",
  "Hspa2",
  "Hspa4",
  "Hspa4l",
  "Hspa5",
  "Hspa8",
  "Hspa9",
  "Hspb1",
  "Hspb11",
  "Hspb2",
  "Hspb3",
  "Hspb6",
  "Hspb7",
  "Hspb8",
  "Hspb9",
  "Hspbap1",
  "Hspbp1",
  "Hspd1",
  "Hspe1-rs1",
  "Hspg2",
  "Hsph1",
  "Htatip2",
  "Htatsf1",
  "Htr1a",
  "Htr1b",
  "Htr1d",
  "Htr1f",
  "Htr2a",
  "Htr2b",
  "Htr2c",
  "Htr3a",
  "Htr3b",
  "Htr4",
  "Htr5a",
  "Htr5b",
  "Htr6",
  "Htr7",
  "Htra1",
  "Htra2",
  "Htra3",
  "Htra4",
  "Htt",
  "Hunk",
  "Hus1",
  "Hus1b",
  "Huwe1",
  "Hvcn1",
  "Hyal1",
  "Hyal2",
  "Hyal3",
  "Hyal4",
  "Hyal5",
  "Hyal6",
  "Hydin",
  "Hykk",
  "Hyls1",
  "Hyou1",
  "Hypk",
  "I0C0044D17Rik",
  "I830077J02Rik",
  "I830134H01Rik",
  "Iah1",
  "Iapp",
  "Iars",
  "Iars2",
  "Iba57",
  "Ibsp",
  "Ica1",
  "Ica1l",
  "Icam1",
  "Icam2",
  "Icam4",
  "Icam5",
  "Ice1",
  "Ice2",
  "Icmt",
  "Icos",
  "Icosl",
  "Id1",
  "Id2",
  "Id3",
  "Id4",
  "Ide",
  "Idh1",
  "Idh2",
  "Idh3a",
  "Idh3b",
  "Idh3g",
  "Idi1",
  "Idi2",
  "Idnk",
  "Ido1",
  "Ido2",
  "Ids",
  "Idua",
  "Ier2",
  "Ier3",
  "Ier3ip1",
  "Ier5",
  "Ier5l",
  "Iffo1",
  "Iffo2",
  "Ifi202b",
  "Ifi203",
  "Ifi204",
  "Ifi206",
  "Ifi207",
  "Ifi208",
  "Ifi209",
  "Ifi213",
  "Ifi214",
  "Ifi27",
  "Ifi27l2a",
  "Ifi27l2b",
  "Ifi30",
  "Ifi35",
  "Ifi44",
  "Ifi44l",
  "Ifi47",
  "Ifih1",
  "Ifit1",
  "Ifit1bl1",
  "Ifit1bl2",
  "Ifit2",
  "Ifit3",
  "Ifit3b",
  "Ifitm1",
  "Ifitm10",
  "Ifitm2",
  "Ifitm3",
  "Ifitm5",
  "Ifitm6",
  "Ifitm7",
  "Ifna12",
  "Ifna13",
  "Ifna14",
  "Ifna16",
  "Ifna2",
  "Ifna4",
  "Ifna5",
  "Ifnab",
  "Ifnar1",
  "Ifnb1",
  "Ifne",
  "Ifng",
  "Ifngas1",
  "Ifngr1",
  "Ifngr2",
  "Ifnk",
  "Ifnl3",
  "Ifnlr1",
  "Ifrd1",
  "Ifrd2",
  "Ift122",
  "Ift140",
  "Ift172",
  "Ift20",
  "Ift22",
  "Ift27",
  "Ift43",
  "Ift46",
  "Ift52",
  "Ift57",
  "Ift74",
  "Ift80",
  "Ift81",
  "Ift88",
  "Ift88os",
  "Igbp1",
  "Igbp1b",
  "Igdcc3",
  "Igdcc4",
  "Igf1",
  "Igf1os",
  "Igf1r",
  "Igf2bp1",
  "Igf2bp2",
  "Igf2bp3",
  "Igf2os",
  "Igf2r",
  "Igfals",
  "Igfbp1",
  "Igfbp2",
  "Igfbp3",
  "Igfbp4",
  "Igfbp5",
  "Igfbp6",
  "Igfbp7",
  "Igfbpl1",
  "Igfl3",
  "Igfn1",
  "Ighmbp2",
  "Igip",
  "Igll1",
  "Iglon5",
  "Igsf1",
  "Igsf10",
  "Igsf11",
  "Igsf21",
  "Igsf23",
  "Igsf3",
  "Igsf5",
  "Igsf6",
  "Igsf8",
  "Igsf9",
  "Igsf9b",
  "Igtp",
  "Ihh",
  "Iigp1",
  "Ik",
  "Ikbip",
  "Ikbkb",
  "Ikbke",
  "Ikbkg",
  "Ikzf1",
  "Ikzf2",
  "Ikzf4",
  "Ikzf5",
  "Il10",
  "Il10ra",
  "Il10rb",
  "Il11",
  "Il12b",
  "Il12rb1",
  "Il12rb2",
  "Il13",
  "Il13ra1",
  "Il13ra2",
  "Il15",
  "Il15ra",
  "Il16",
  "Il17a",
  "Il17b",
  "Il17c",
  "Il17d",
  "Il17f",
  "Il17ra",
  "Il17rb",
  "Il17rc",
  "Il17rd",
  "Il17re",
  "Il18",
  "Il18bp",
  "Il18r1",
  "Il18rap",
  "Il19",
  "Il1a",
  "Il1b",
  "Il1bos",
  "Il1f10",
  "Il1f5",
  "Il1f6",
  "Il1f8",
  "Il1f9",
  "Il1r1",
  "Il1r2",
  "Il1rap",
  "Il1rapl1",
  "Il1rapl2",
  "Il1rl1",
  "Il1rl2",
  "Il1rn",
  "Il2",
  "Il20",
  "Il20ra",
  "Il20rb",
  "Il21",
  "Il21r",
  "Il22ra1",
  "Il22ra2",
  "Il23a",
  "Il23r",
  "Il24",
  "Il25",
  "Il27",
  "Il27ra",
  "Il2ra",
  "Il2rb",
  "Il2rg",
  "Il31",
  "Il31ra",
  "Il33",
  "Il34",
  "Il3ra",
  "Il4",
  "Il4i1",
  "Il4ra",
  "Il5",
  "Il5ra",
  "Il6",
  "Il6ra",
  "Il6st",
  "Il7",
  "Il7r",
  "Il9",
  "Il9r",
  "Ildr1",
  "Ildr2",
  "Ilf2",
  "Ilf3",
  "Ilkap",
  "Ilrun",
  "Iltifb",
  "Ilvbl",
  "Immp1l",
  "Immp2l",
  "Imp3",
  "Imp4",
  "Impa1",
  "Impa2",
  "Impact",
  "Impad1",
  "Impdh1",
  "Impdh2",
  "Impg1",
  "Impg2",
  "Ina",
  "Inafm1",
  "Inafm2",
  "Inava",
  "Inca1",
  "Incenp",
  "Inf2",
  "Ing1",
  "Ing2",
  "Ing3",
  "Ing5",
  "Inha",
  "Inhba",
  "Inhbb",
  "Inhbc",
  "Inhbe",
  "Inip",
  "Inka1",
  "Inka2",
  "Inmt",
  "Ino80",
  "Ino80c",
  "Ino80d",
  "Ino80e",
  "Inpp1",
  "Inpp4a",
  "Inpp4b",
  "Inpp5a",
  "Inpp5b",
  "Inpp5d",
  "Inpp5e",
  "Inpp5f",
  "Inpp5j",
  "Inpp5k",
  "Inppl1",
  "Ins1",
  "Ins2",
  "Insc",
  "Insig1",
  "Insig2",
  "Insl5",
  "Insl6",
  "Insm1",
  "Insm2",
  "Insr",
  "Insrr",
  "Insyn1",
  "Insyn2a",
  "Insyn2b",
  "Ints1",
  "Ints10",
  "Ints11",
  "Ints12",
  "Ints13",
  "Ints14",
  "Ints2",
  "Ints3",
  "Ints4",
  "Ints5",
  "Ints6",
  "Ints6l",
  "Ints7",
  "Ints8",
  "Ints9",
  "Intu",
  "Invs",
  "Ip6k1",
  "Ip6k2",
  "Ip6k3",
  "Ipcef1",
  "Ipmk",
  "Ipo11",
  "Ipo13",
  "Ipo4",
  "Ipo5",
  "Ipo7",
  "Ipo8",
  "Ipp",
  "Ippk",
  "Iqank1",
  "Iqca",
  "Iqca1l",
  "Iqcb1",
  "Iqcc",
  "Iqcd",
  "Iqce",
  "Iqcf1",
  "Iqcf4",
  "Iqcf5",
  "Iqcf6",
  "Iqcg",
  "Iqch",
  "Iqcj",
  "Iqck",
  "Iqcm",
  "Iqcn",
  "Iqgap1",
  "Iqgap2",
  "Iqgap3",
  "Iqsec1",
  "Iqsec2",
  "Iqsec3",
  "Iqub",
  "Irak1",
  "Irak1bp1",
  "Irak2",
  "Irak3",
  "Irak4",
  "Ireb2",
  "Irf1",
  "Irf2",
  "Irf2bp1",
  "Irf2bp2",
  "Irf2bpl",
  "Irf3",
  "Irf4",
  "Irf5",
  "Irf6",
  "Irf7",
  "Irf8",
  "Irf9",
  "Irgc1",
  "Irgm1",
  "Irgm2",
  "Irgq",
  "Irs1",
  "Irs2",
  "Irs3",
  "Irs4",
  "Irx1",
  "Irx2",
  "Irx3",
  "Irx3os",
  "Irx4",
  "Irx5",
  "Irx6",
  "Isca1",
  "Isca2",
  "Iscu",
  "Isg15",
  "Isg20",
  "Isg20l2",
  "Isl1",
  "Isl2",
  "Islr",
  "Islr2",
  "Ism1",
  "Ism2",
  "Isoc1",
  "Isoc2a",
  "Isoc2b",
  "Ist1",
  "Isx",
  "Isy1",
  "Isyna1",
  "Itch",
  "Itfg1",
  "Itfg2",
  "Itga1",
  "Itga10",
  "Itga11",
  "Itga2",
  "Itga2b",
  "Itga3",
  "Itga4",
  "Itga5",
  "Itga7",
  "Itga8",
  "Itga9",
  "Itgad",
  "Itgae",
  "Itgal",
  "Itgam",
  "Itgav",
  "Itgax",
  "Itgb1",
  "Itgb1bp1",
  "Itgb1bp2",
  "Itgb2",
  "Itgb2l",
  "Itgb3",
  "Itgb3bp",
  "Itgb4",
  "Itgb5",
  "Itgb6",
  "Itgb7",
  "Itgb8",
  "Itgbl1",
  "Itih1",
  "Itih2",
  "Itih3",
  "Itih4",
  "Itih5",
  "Itk",
  "Itln1",
  "Itm2a",
  "Itm2b",
  "Itm2c",
  "Itpa",
  "Itpk1",
  "Itpka",
  "Itpkb",
  "Itpkc",
  "Itpr1",
  "Itpr2",
  "Itpr3",
  "Itpr3os",
  "Itprid1",
  "Itprid2",
  "Itprip",
  "Itpripl1",
  "Itpripl2",
  "Itsn1",
  "Itsn2",
  "Ivd",
  "Ivl",
  "Ivns1abp",
  "Iws1",
  "Iyd",
  "Izumo1",
  "Izumo1r",
  "Izumo2",
  "Izumo3",
  "Izumo4",
  "Jade1",
  "Jade2",
  "Jade3",
  "Jag1",
  "Jag2",
  "Jagn1",
  "Jak1",
  "Jak2",
  "Jak3",
  "Jakmip1",
  "Jakmip2",
  "Jakmip3",
  "Jam2",
  "Jam3",
  "Jaml",
  "Jarid2",
  "Jazf1",
  "Jcad",
  "Jchain",
  "Jdp2",
  "Jhy",
  "Jkamp",
  "Jmjd1c",
  "Jmjd4",
  "Jmjd6",
  "Jmjd8",
  "Jmy",
  "Josd1",
  "Josd2",
  "Jph1",
  "Jph2",
  "Jph3",
  "Jph4",
  "Jpt1",
  "Jpt2",
  "Jpx",
  "Jrk",
  "Jsrp1",
  "Jtb",
  "Jun",
  "Junb",
  "Jund",
  "Junos",
  "Jup",
  "K230015D01Rik",
  "Kalrn",
  "Kank1",
  "Kank2",
  "Kank3",
  "Kank4",
  "Kansl1",
  "Kansl1l",
  "Kansl2",
  "Kansl3",
  "Kantr",
  "Kap",
  "Kars",
  "Kat14",
  "Kat2a",
  "Kat2b",
  "Kat5",
  "Kat6a",
  "Kat6b",
  "Kat7",
  "Kat8",
  "Katna1",
  "Katnal1",
  "Katnal2",
  "Katnb1",
  "Katnbl1",
  "Kazald1",
  "Kazn",
  "Kbtbd11",
  "Kbtbd12",
  "Kbtbd13",
  "Kbtbd2",
  "Kbtbd3",
  "Kbtbd4",
  "Kbtbd6",
  "Kbtbd7",
  "Kbtbd8",
  "Kcmf1",
  "Kcna10",
  "Kcna2",
  "Kcna3",
  "Kcna5",
  "Kcna6",
  "Kcna7",
  "Kcnab1",
  "Kcnab2",
  "Kcnab3",
  "Kcnb1",
  "Kcnb2",
  "Kcnc1",
  "Kcnc2",
  "Kcnc3",
  "Kcnc4",
  "Kcnd1",
  "Kcnd2",
  "Kcnd3",
  "Kcnd3os",
  "Kcne1",
  "Kcne1l",
  "Kcne2",
  "Kcne3",
  "Kcne4",
  "Kcnf1",
  "Kcng1",
  "Kcng2",
  "Kcng3",
  "Kcng4",
  "Kcnh1",
  "Kcnh2",
  "Kcnh3",
  "Kcnh4",
  "Kcnh5",
  "Kcnh6",
  "Kcnh7",
  "Kcnh8",
  "Kcnip1",
  "Kcnip2",
  "Kcnip3",
  "Kcnip4",
  "Kcnj1",
  "Kcnj10",
  "Kcnj11",
  "Kcnj12",
  "Kcnj13",
  "Kcnj14",
  "Kcnj15",
  "Kcnj16",
  "Kcnj2",
  "Kcnj3",
  "Kcnj4",
  "Kcnj5",
  "Kcnj6",
  "Kcnj8",
  "Kcnj9",
  "Kcnk1",
  "Kcnk10",
  "Kcnk12",
  "Kcnk13",
  "Kcnk15",
  "Kcnk16",
  "Kcnk18",
  "Kcnk2",
  "Kcnk3",
  "Kcnk4",
  "Kcnk5",
  "Kcnk6",
  "Kcnk7",
  "Kcnk9",
  "Kcnma1",
  "Kcnmb1",
  "Kcnmb2",
  "Kcnmb3",
  "Kcnmb4",
  "Kcnmb4os1",
  "Kcnmb4os2",
  "Kcnn1",
  "Kcnn2",
  "Kcnn3",
  "Kcnn4",
  "Kcnq1",
  "Kcnq1ot1",
  "Kcnq2",
  "Kcnq3",
  "Kcnq4",
  "Kcnq5",
  "Kcnrg",
  "Kcns1",
  "Kcns2",
  "Kcns3",
  "Kcnt1",
  "Kcnt2",
  "Kcnu1",
  "Kcnv1",
  "Kcnv2",
  "Kcp",
  "Kctd1",
  "Kctd10",
  "Kctd11",
  "Kctd12",
  "Kctd12b",
  "Kctd13",
  "Kctd14",
  "Kctd15",
  "Kctd16",
  "Kctd17",
  "Kctd18",
  "Kctd19",
  "Kctd2",
  "Kctd20",
  "Kctd21",
  "Kctd3",
  "Kctd4",
  "Kctd5",
  "Kctd6",
  "Kctd7",
  "Kctd8",
  "Kctd9",
  "Kdelr1",
  "Kdelr2",
  "Kdelr3",
  "Kdf1",
  "Kdm1a",
  "Kdm1b",
  "Kdm2a",
  "Kdm2b",
  "Kdm3a",
  "Kdm3b",
  "Kdm4a",
  "Kdm4b",
  "Kdm4c",
  "Kdm4d",
  "Kdm5a",
  "Kdm5b",
  "Kdm5c",
  "Kdm5d",
  "Kdm6a",
  "Kdm6b",
  "Kdm6bos",
  "Kdm7a",
  "Kdm8",
  "Kdr",
  "Kdsr",
  "Keap1",
  "Keg1",
  "Kel",
  "Kera",
  "Khdc1b",
  "Khdc3",
  "Khdc4",
  "Khdrbs1",
  "Khdrbs2",
  "Khdrbs3",
  "Khk",
  "Khnyn",
  "Khsrp",
  "Kidins220",
  "Kif11",
  "Kif12",
  "Kif13a",
  "Kif13b",
  "Kif14",
  "Kif15",
  "Kif16b",
  "Kif17",
  "Kif18a",
  "Kif18b",
  "Kif19a",
  "Kif1a",
  "Kif1b",
  "Kif1c",
  "Kif20a",
  "Kif20b",
  "Kif21a",
  "Kif21b",
  "Kif22",
  "Kif23",
  "Kif24",
  "Kif26a",
  "Kif26b",
  "Kif27",
  "Kif28",
  "Kif2a",
  "Kif2b",
  "Kif2c",
  "Kif3a",
  "Kif3b",
  "Kif3c",
  "Kif4",
  "Kif5a",
  "Kif5b",
  "Kif5c",
  "Kif6",
  "Kif7",
  "Kif9",
  "Kifap3",
  "Kifbp",
  "Kifc2",
  "Kifc3",
  "Kifc5b",
  "Kin",
  "Kir3dl1",
  "Kir3dl2",
  "Kirrel",
  "Kirrel2",
  "Kirrel3",
  "Kirrel3os",
  "Kis2",
  "Kiss1r",
  "Kit",
  "Kitl",
  "Kiz",
  "Kl",
  "Klb",
  "Klc1",
  "Klc2",
  "Klc3",
  "Klc4",
  "Klf1",
  "Klf10",
  "Klf11",
  "Klf12",
  "Klf13",
  "Klf14",
  "Klf15",
  "Klf16",
  "Klf17",
  "Klf2",
  "Klf3",
  "Klf4",
  "Klf5",
  "Klf6",
  "Klf7",
  "Klf8",
  "Klf9",
  "Klhdc1",
  "Klhdc10",
  "Klhdc2",
  "Klhdc3",
  "Klhdc4",
  "Klhdc7a",
  "Klhdc7b",
  "Klhdc8a",
  "Klhdc8b",
  "Klhdc9",
  "Klhl1",
  "Klhl10",
  "Klhl11",
  "Klhl12",
  "Klhl13",
  "Klhl14",
  "Klhl15",
  "Klhl18",
  "Klhl2",
  "Klhl20",
  "Klhl21",
  "Klhl22",
  "Klhl23",
  "Klhl24",
  "Klhl25",
  "Klhl26",
  "Klhl28",
  "Klhl29",
  "Klhl3",
  "Klhl30",
  "Klhl31",
  "Klhl32",
  "Klhl33",
  "Klhl34",
  "Klhl35",
  "Klhl36",
  "Klhl38",
  "Klhl4",
  "Klhl40",
  "Klhl41",
  "Klhl42",
  "Klhl5",
  "Klhl6",
  "Klhl7",
  "Klhl8",
  "Klhl9",
  "Klk1",
  "Klk10",
  "Klk11",
  "Klk12",
  "Klk13",
  "Klk14",
  "Klk15",
  "Klk1b1",
  "Klk1b11",
  "Klk1b16",
  "Klk1b21",
  "Klk1b22",
  "Klk1b24",
  "Klk1b26",
  "Klk1b27",
  "Klk1b3",
  "Klk1b4",
  "Klk1b5",
  "Klk1b8",
  "Klk1b9",
  "Klk4",
  "Klk5",
  "Klk6",
  "Klk7",
  "Klk8",
  "Klk9",
  "Klkb1",
  "Klra1",
  "Klra10",
  "Klra17",
  "Klra2",
  "Klra4",
  "Klra5",
  "Klra6",
  "Klra7",
  "Klra8",
  "Klra9",
  "Klrb1",
  "Klrb1a",
  "Klrb1b",
  "Klrb1c",
  "Klrb1f",
  "Klrc1",
  "Klrc2",
  "Klrc3",
  "Klrd1",
  "Klre1",
  "Klrg1",
  "Klrg2",
  "Klrh1",
  "Klri1",
  "Klri2",
  "Klrk1",
  "Kmo",
  "Kmt2a",
  "Kmt2b",
  "Kmt2c",
  "Kmt2d",
  "Kmt2e",
  "Kmt5a",
  "Kmt5b",
  "Kmt5c",
  "Kncn",
  "Kndc1",
  "Knl1",
  "Knop1",
  "Knstrn",
  "Kntc1",
  "Kpna1",
  "Kpna2",
  "Kpna3",
  "Kpna4",
  "Kpna6",
  "Kpna7",
  "Kpnb1",
  "Kprp",
  "Kptn",
  "Kras",
  "Krba1",
  "Krcc1",
  "Kremen1",
  "Kremen2",
  "Kri1",
  "Krit1",
  "Krr1",
  "Krt1",
  "Krt10",
  "Krt12",
  "Krt13",
  "Krt14",
  "Krt15",
  "Krt16",
  "Krt17",
  "Krt18",
  "Krt19",
  "Krt2",
  "Krt20",
  "Krt222",
  "Krt23",
  "Krt24",
  "Krt25",
  "Krt26",
  "Krt27",
  "Krt28",
  "Krt31",
  "Krt32",
  "Krt33a",
  "Krt33b",
  "Krt34",
  "Krt35",
  "Krt36",
  "Krt39",
  "Krt4",
  "Krt40",
  "Krt42",
  "Krt5",
  "Krt6a",
  "Krt6b",
  "Krt7",
  "Krt71",
  "Krt72",
  "Krt73",
  "Krt75",
  "Krt76",
  "Krt77",
  "Krt78",
  "Krt79",
  "Krt8",
  "Krt80",
  "Krt82",
  "Krt84",
  "Krt86",
  "Krt87",
  "Krt88",
  "Krt9",
  "Krt90",
  "Krtap1-3",
  "Krtap1-4",
  "Krtap1-5",
  "Krtap10-10",
  "Krtap10-4",
  "Krtap11-1",
  "Krtap12-1",
  "Krtap13",
  "Krtap13-1",
  "Krtap14",
  "Krtap15",
  "Krtap16-1",
  "Krtap16-3",
  "Krtap17-1",
  "Krtap19-1",
  "Krtap19-2",
  "Krtap19-3",
  "Krtap19-4",
  "Krtap19-5",
  "Krtap19-9b",
  "Krtap2-4",
  "Krtap20-2",
  "Krtap21-1",
  "Krtap22-2",
  "Krtap24-1",
  "Krtap26-1",
  "Krtap28-10",
  "Krtap28-13",
  "Krtap29-1",
  "Krtap3-1",
  "Krtap3-2",
  "Krtap3-3",
  "Krtap31-1",
  "Krtap31-2",
  "Krtap4-1",
  "Krtap4-16",
  "Krtap4-2",
  "Krtap4-6",
  "Krtap4-7",
  "Krtap4-8",
  "Krtap4-9",
  "Krtap5-2",
  "Krtap5-4",
  "Krtap5-5",
  "Krtap6-1",
  "Krtap6-5",
  "Krtap7-1",
  "Krtap8-1",
  "Krtap9-3",
  "Krtap9-5",
  "Krtcap2",
  "Krtcap3",
  "Krtdap",
  "Ksr1",
  "Ksr2",
  "Kti12",
  "Ktn1",
  "Kxd1",
  "Ky",
  "Kyat1",
  "Kyat3",
  "Kynu",
  "L1cam",
  "L1td1",
  "L2hgdh",
  "L3hypdh",
  "L3mbtl1",
  "L3mbtl2",
  "L3mbtl3",
  "L3mbtl4",
  "LTO1",
  "Lacc1",
  "Lactb",
  "Lactb2",
  "Lactbl1",
  "Lad1",
  "Lag3",
  "Lage3",
  "Lair1",
  "Lalba",
  "Lama1",
  "Lama2",
  "Lama3",
  "Lama4",
  "Lama5",
  "Lamb1",
  "Lamb2",
  "Lamb3",
  "Lamc1",
  "Lamc2",
  "Lamc3",
  "Lamp1",
  "Lamp2",
  "Lamp5",
  "Lamtor2",
  "Lamtor3",
  "Lamtor4",
  "Lamtor5",
  "Lancl1",
  "Lancl2",
  "Lancl3",
  "Lao1",
  "Lap3",
  "Laptm4a",
  "Laptm4b",
  "Laptm5",
  "Large1",
  "Large2",
  "Larp1",
  "Larp1b",
  "Larp4",
  "Larp4b",
  "Larp6",
  "Larp7",
  "Lars",
  "Lars2",
  "Las1l",
  "Lasp1",
  "Lat",
  "Lat2",
  "Lats1",
  "Lats2",
  "Lax1",
  "Layn",
  "Lbh",
  "Lbhd2",
  "Lbp",
  "Lbr",
  "Lbx1",
  "Lbx2",
  "Lca5",
  "Lca5l",
  "Lcat",
  "Lce1a1",
  "Lce1a2",
  "Lce1b",
  "Lce1c",
  "Lce1d",
  "Lce1e",
  "Lce1f",
  "Lce1g",
  "Lce1h",
  "Lce1i",
  "Lce1j",
  "Lce1k",
  "Lce1l",
  "Lce1m",
  "Lce3a",
  "Lce3b",
  "Lce3c",
  "Lce3e",
  "Lce3f",
  "Lck",
  "Lclat1",
  "Lcmt1",
  "Lcmt2",
  "Lcn10",
  "Lcn11",
  "Lcn12",
  "Lcn2",
  "Lcn3",
  "Lcn4",
  "Lcn5",
  "Lcn6",
  "Lcn8",
  "Lcn9",
  "Lcor",
  "Lcorl",
  "Lcp1",
  "Lcp2",
  "Lct",
  "Lctl",
  "Ldah",
  "Ldb1",
  "Ldb2",
  "Ldb3",
  "Ldc1",
  "Ldha",
  "Ldhal6b",
  "Ldhb",
  "Ldhc",
  "Ldhd",
  "Ldlr",
  "Ldlrad1",
  "Ldlrad2",
  "Ldlrad3",
  "Ldlrad4",
  "Ldlrap1",
  "Ldoc1",
  "Leap2",
  "Lect2",
  "Lef1",
  "Lefty1",
  "Lefty2",
  "Lekr1",
  "Lelp1",
  "Lemd1",
  "Lemd2",
  "Lemd3",
  "Leng1",
  "Leng8",
  "Leng9",
  "Leo1",
  "Lep",
  "Lepr",
  "Leprot",
  "Leprotl1",
  "Letm1",
  "Letm2",
  "Letmd1",
  "Lexm",
  "Lfng",
  "Lgals1",
  "Lgals12",
  "Lgals2",
  "Lgals3",
  "Lgals3bp",
  "Lgals4",
  "Lgals7",
  "Lgals8",
  "Lgals9",
  "Lgalsl",
  "Lgi1",
  "Lgi2",
  "Lgi3",
  "Lgi4",
  "Lgmn",
  "Lgr4",
  "Lgr5",
  "Lgr6",
  "Lgsn",
  "Lhcgr",
  "Lhfp",
  "Lhfpl1",
  "Lhfpl2",
  "Lhfpl3",
  "Lhfpl4",
  "Lhfpl5",
  "Lhpp",
  "Lhx1",
  "Lhx2",
  "Lhx3",
  "Lhx4",
  "Lhx5",
  "Lhx6",
  "Lhx8",
  "Lhx9",
  "Lias",
  "Lif",
  "Lifr",
  "Lig1",
  "Lig3",
  "Lig4",
  "Lilra5",
  "Lilra6",
  "Lim2",
  "Lima1",
  "Limch1",
  "Limd1",
  "Limd2",
  "Limk1",
  "Lims1",
  "Lims2",
  "Lin28a",
  "Lin28b",
  "Lin37",
  "Lin52",
  "Lin54",
  "Lin7a",
  "Lin7b",
  "Lin7c",
  "Lin9",
  "Lingo1",
  "Lingo2",
  "Lingo3",
  "Lingo4",
  "Lins1",
  "Lipa",
  "Lipc",
  "Lipe",
  "Lipf",
  "Lipg",
  "Liph",
  "Lipi",
  "Lipk",
  "Lipm",
  "Lipn",
  "Lipo1",
  "Lipo3",
  "Lipo4",
  "Lipt1",
  "Lipt2",
  "Litaf",
  "Lix1",
  "Lix1l",
  "Lkaaear1",
  "Llcfc1",
  "Llgl1",
  "Llgl2",
  "Llph",
  "Lman1",
  "Lman1l",
  "Lman2",
  "Lman2l",
  "Lmbr1",
  "Lmbr1l",
  "Lmbrd1",
  "Lmbrd2",
  "Lmcd1",
  "Lmf1",
  "Lmf2",
  "Lmln",
  "Lmna",
  "Lmnb1",
  "Lmnb2",
  "Lmntd1",
  "Lmntd2",
  "Lmo1",
  "Lmo2",
  "Lmo3",
  "Lmo4",
  "Lmo7",
  "Lmod1",
  "Lmod2",
  "Lmod3",
  "Lmtk2",
  "Lmtk3",
  "Lmx1a",
  "Lmx1b",
  "Lncbate6",
  "Lncenc1",
  "Lncpint",
  "Lncppara",
  "Lnpep",
  "Lnpk",
  "Lnx1",
  "Lnx2",
  "Lockd",
  "Lonp1",
  "Lonp2",
  "Lonrf1",
  "Lonrf2",
  "Lonrf3",
  "Lor",
  "Lox",
  "Loxhd1",
  "Loxl1",
  "Loxl2",
  "Loxl3",
  "Loxl4",
  "Lpar1",
  "Lpar2",
  "Lpar3",
  "Lpar4",
  "Lpar5",
  "Lpar6",
  "Lpcat1",
  "Lpcat2",
  "Lpcat2b",
  "Lpcat3",
  "Lpgat1",
  "Lpin1",
  "Lpin2",
  "Lpin3",
  "Lpl",
  "Lpo",
  "Lpp",
  "Lpxn",
  "Lrat",
  "Lratd1",
  "Lratd2",
  "Lrba",
  "Lrch1",
  "Lrch2",
  "Lrcol1",
  "Lrfn1",
  "Lrfn2",
  "Lrfn3",
  "Lrfn4",
  "Lrfn5",
  "Lrg1",
  "Lrguk",
  "Lrif1",
  "Lrig1",
  "Lrig2",
  "Lrig3",
  "Lrit1",
  "Lrit2",
  "Lrit3",
  "Lrmda",
  "Lrmp",
  "Lrp1",
  "Lrp10",
  "Lrp11",
  "Lrp12",
  "Lrp1b",
  "Lrp2",
  "Lrp2bp",
  "Lrp3",
  "Lrp4",
  "Lrp5",
  "Lrp6",
  "Lrp8",
  "Lrp8os1",
  "Lrp8os2",
  "Lrpap1",
  "Lrpprc",
  "Lrr1",
  "Lrrc1",
  "Lrrc10",
  "Lrrc10b",
  "Lrrc14",
  "Lrrc14b",
  "Lrrc15",
  "Lrrc17",
  "Lrrc18",
  "Lrrc19",
  "Lrrc2",
  "Lrrc20",
  "Lrrc23",
  "Lrrc24",
  "Lrrc25",
  "Lrrc26",
  "Lrrc27",
  "Lrrc28",
  "Lrrc29",
  "Lrrc3",
  "Lrrc30",
  "Lrrc31",
  "Lrrc32",
  "Lrrc34",
  "Lrrc36",
  "Lrrc37a",
  "Lrrc38",
  "Lrrc39",
  "Lrrc3b",
  "Lrrc3c",
  "Lrrc4",
  "Lrrc40",
  "Lrrc41",
  "Lrrc42",
  "Lrrc43",
  "Lrrc45",
  "Lrrc46",
  "Lrrc47",
  "Lrrc49",
  "Lrrc4b",
  "Lrrc4c",
  "Lrrc51",
  "Lrrc52",
  "Lrrc55",
  "Lrrc56",
  "Lrrc57",
  "Lrrc58",
  "Lrrc59",
  "Lrrc6",
  "Lrrc61",
  "Lrrc63",
  "Lrrc66",
  "Lrrc69",
  "Lrrc7",
  "Lrrc71",
  "Lrrc72",
  "Lrrc73",
  "Lrrc74a",
  "Lrrc74b",
  "Lrrc75a",
  "Lrrc75b",
  "Lrrc8a",
  "Lrrc8b",
  "Lrrc8c",
  "Lrrc8d",
  "Lrrc8e",
  "Lrrc9",
  "Lrrcc1",
  "Lrrd1",
  "Lrrfip1",
  "Lrrfip2",
  "Lrriq1",
  "Lrriq3",
  "Lrrk1",
  "Lrrk2",
  "Lrrn1",
  "Lrrn2",
  "Lrrn3",
  "Lrrn4",
  "Lrrn4cl",
  "Lrrtm1",
  "Lrrtm2",
  "Lrrtm3",
  "Lrrtm4",
  "Lrsam1",
  "Lrtm1",
  "Lrtm2",
  "Lrwd1",
  "Lsamp",
  "Lsg1",
  "Lsm1",
  "Lsm10",
  "Lsm11",
  "Lsm14b",
  "Lsm2",
  "Lsm3",
  "Lsm4",
  "Lsm5",
  "Lsm6",
  "Lsm7",
  "Lsm8",
  "Lsmem1",
  "Lsmem2",
  "Lsp1",
  "Lsr",
  "Lss",
  "Lst1",
  "Lta",
  "Lta4h",
  "Ltb",
  "Ltb4r1",
  "Ltb4r2",
  "Ltbp1",
  "Ltbp2",
  "Ltbp3",
  "Ltbp4",
  "Ltbr",
  "Ltc4s",
  "Ltf",
  "Ltk",
  "Ltn1",
  "Ltv1",
  "Luc7l",
  "Luc7l2",
  "Luc7l3",
  "Lum",
  "Lurap1",
  "Lurap1l",
  "Luzp1",
  "Luzp2",
  "Lvrn",
  "Lxn",
  "Ly6a",
  "Ly6d",
  "Ly6e",
  "Ly6f",
  "Ly6g",
  "Ly6g2",
  "Ly6g5b",
  "Ly6g5c",
  "Ly6g6c",
  "Ly6g6e",
  "Ly6g6f",
  "Ly6h",
  "Ly6i",
  "Ly6k",
  "Ly6l",
  "Ly6m",
  "Ly75",
  "Ly86",
  "Ly9",
  "Ly96",
  "Lyar",
  "Lyg1",
  "Lyg2",
  "Lyl1",
  "Lyn",
  "Lynx1",
  "Lypd1",
  "Lypd10",
  "Lypd11",
  "Lypd2",
  "Lypd3",
  "Lypd4",
  "Lypd5",
  "Lypd6",
  "Lypd6b",
  "Lypd8",
  "Lypd9",
  "Lypla1",
  "Lypla2",
  "Lyplal1",
  "Lyrm1",
  "Lyrm2",
  "Lyrm4",
  "Lyrm7",
  "Lyrm9",
  "Lysmd1",
  "Lysmd2",
  "Lysmd3",
  "Lysmd4",
  "Lyst",
  "Lyve1",
  "Lyz1",
  "Lyz2",
  "Lyzl1",
  "Lyzl4",
  "Lyzl6",
  "Lzic",
  "Lztfl1",
  "Lztr1",
  "Lzts1",
  "Lzts2",
  "Lzts3",
  "M1ap",
  "M5C1000I18Rik",
  "M6pr",
  "Maats1",
  "Mab21l1",
  "Mab21l2",
  "Mab21l3",
  "Mab21l4",
  "Macc1",
  "Macf1",
  "Maco1",
  "Macrod1",
  "Macrod2",
  "Macrod2os2",
  "Macroh2a1",
  "Macroh2a2",
  "Mad1l1",
  "Mad2l1",
  "Mad2l1bp",
  "Mad2l2",
  "Madcam1",
  "Madd",
  "Maea",
  "Mael",
  "Maf",
  "Maf1",
  "Mafa",
  "Mafb",
  "Maff",
  "Mafg",
  "Mafk",
  "Mag",
  "Magea10",
  "Magea2",
  "Magea4",
  "Magea5",
  "Magea6",
  "Magea8",
  "Mageb16",
  "Mageb18",
  "Mageb4",
  "Maged1",
  "Maged2",
  "Magee1",
  "Magee2",
  "Mageh1",
  "Magel2",
  "Magi1",
  "Magi2",
  "Magi3",
  "Magix",
  "Magoh",
  "Magohb",
  "Magt1",
  "Maip1",
  "Majin",
  "Mak",
  "Mak16",
  "Mal",
  "Mal2",
  "Malat1",
  "Mall",
  "Malrd1",
  "Malsu1",
  "Malt1",
  "Mamdc2",
  "Mamdc4",
  "Maml1",
  "Maml2",
  "Maml3",
  "Mamld1",
  "Mamstr",
  "Man1a",
  "Man1a2",
  "Man1b1",
  "Man1c1",
  "Man2a1",
  "Man2a2",
  "Man2b1",
  "Man2b2",
  "Man2c1",
  "Man2c1os",
  "Manba",
  "Manbal",
  "Manea",
  "Maneal",
  "Manf",
  "Mansc1",
  "Mansc4",
  "Maoa",
  "Maob",
  "Map10",
  "Map11",
  "Map1a",
  "Map1b",
  "Map1lc3a",
  "Map1lc3b",
  "Map1s",
  "Map2",
  "Map2k1",
  "Map2k2",
  "Map2k3",
  "Map2k3os",
  "Map2k4",
  "Map2k5",
  "Map2k6",
  "Map3k1",
  "Map3k10",
  "Map3k11",
  "Map3k12",
  "Map3k13",
  "Map3k14",
  "Map3k15",
  "Map3k19",
  "Map3k2",
  "Map3k20",
  "Map3k21",
  "Map3k3",
  "Map3k4",
  "Map3k5",
  "Map3k6",
  "Map3k7",
  "Map3k7cl",
  "Map3k8",
  "Map3k9",
  "Map4",
  "Map4k1",
  "Map4k2",
  "Map4k3",
  "Map4k4",
  "Map4k5",
  "Map6",
  "Map6d1",
  "Map7",
  "Map7d1",
  "Map7d2",
  "Map7d3",
  "Map9",
  "Mapk1",
  "Mapk11",
  "Mapk12",
  "Mapk13",
  "Mapk14",
  "Mapk15",
  "Mapk1ip1",
  "Mapk1ip1l",
  "Mapk3",
  "Mapk4",
  "Mapk6",
  "Mapk7",
  "Mapk8",
  "Mapk8ip1",
  "Mapk8ip2",
  "Mapk8ip3",
  "Mapk9",
  "Mapkap1",
  "Mapkapk2",
  "Mapkapk3",
  "Mapkbp1",
  "Mapre1",
  "Mapre2",
  "Mapre3",
  "Mapt",
  "Marchf1",
  "Marchf10",
  "Marchf11",
  "Marchf2",
  "Marchf3",
  "Marchf4",
  "Marchf5",
  "Marchf6",
  "Marchf7",
  "Marchf8",
  "Marchf9",
  "Marcks",
  "Marco",
  "Marf1",
  "Mark1",
  "Mark2",
  "Mark3",
  "Mark4",
  "Mars1",
  "Mars2",
  "Marveld1",
  "Marveld2",
  "Marveld3",
  "Mas1",
  "Masp1",
  "Masp2",
  "Mast1",
  "Mast2",
  "Mast3",
  "Mast4",
  "Mastl",
  "Mat1a",
  "Mat2a",
  "Mat2b",
  "Matk",
  "Matn1",
  "Matn2",
  "Matn3",
  "Matn4",
  "Matr3",
  "Mau2",
  "Mavs",
  "Max",
  "Maz",
  "Mb",
  "Mb21d2",
  "Mbd1",
  "Mbd2",
  "Mbd3",
  "Mbd3l1",
  "Mbd3l2",
  "Mbd4",
  "Mbd5",
  "Mbd6",
  "Mbip",
  "Mbl1",
  "Mbl2",
  "Mblac1",
  "Mblac2",
  "Mbnl1",
  "Mbnl2",
  "Mbnl3",
  "Mboat1",
  "Mboat2",
  "Mboat4",
  "Mboat7",
  "Mbp",
  "Mbtd1",
  "Mbtps1",
  "Mbtps2",
  "Mc1r",
  "Mc2r",
  "Mc3r",
  "Mc4r",
  "Mc5r",
  "Mcam",
  "Mcat",
  "Mcc",
  "Mccc1",
  "Mccc1os",
  "Mccc2",
  "Mcee",
  "Mcemp1",
  "Mcf2",
  "Mcf2l",
  "Mcfd2",
  "Mchr1",
  "Mcl1",
  "Mcm10",
  "Mcm2",
  "Mcm3",
  "Mcm3ap",
  "Mcm4",
  "Mcm5",
  "Mcm6",
  "Mcm7",
  "Mcm8",
  "Mcm9",
  "Mcmbp",
  "Mcmdc2",
  "Mcoln1",
  "Mcoln2",
  "Mcoln3",
  "Mcph1",
  "Mcpt1",
  "Mcpt2",
  "Mcpt4",
  "Mcpt8",
  "Mcpt9",
  "Mcrip1",
  "Mcrip2",
  "Mcrs1",
  "Mctp1",
  "Mctp2",
  "Mcts2",
  "Mcu",
  "Mcub",
  "Mcur1",
  "Mdc1",
  "Mdfi",
  "Mdfic",
  "Mdga1",
  "Mdga2",
  "Mdh1",
  "Mdh1b",
  "Mdh2",
  "Mdk",
  "Mdm1",
  "Mdm2",
  "Mdm4",
  "Mdn1",
  "Mdp1",
  "Me1",
  "Me2",
  "Me3",
  "Mea1",
  "Meaf6",
  "Meak7",
  "Mecom",
  "Mecp2",
  "Mecr",
  "Med1",
  "Med10",
  "Med11",
  "Med12l",
  "Med13l",
  "Med14",
  "Med15",
  "Med16",
  "Med17",
  "Med18",
  "Med19",
  "Med20",
  "Med21",
  "Med22",
  "Med23",
  "Med24",
  "Med25",
  "Med26",
  "Med27",
  "Med28",
  "Med29",
  "Med30",
  "Med31",
  "Med4",
  "Med6",
  "Med7",
  "Med8",
  "Med9",
  "Med9os",
  "Medag",
  "Mef2a",
  "Mef2b",
  "Mef2c",
  "Mef2d",
  "Mefv",
  "Meg3",
  "Megf10",
  "Megf11",
  "Megf6",
  "Megf8",
  "Megf9",
  "Mei1",
  "Mei4",
  "Meig1",
  "Meikin",
  "Meiob",
  "Meioc",
  "Meis1",
  "Meis2",
  "Meis3",
  "Melk",
  "Meltf",
  "Memo1",
  "Men1",
  "Meox1",
  "Meox2",
  "Mep1a",
  "Mep1b",
  "Mepce",
  "Mepe",
  "Mertk",
  "Mesd",
  "Mesp1",
  "Mesp2",
  "Mest",
  "Met",
  "Metap1",
  "Metap1d",
  "Metap2",
  "Methig1",
  "Metrn",
  "Metrnl",
  "Mettl1",
  "Mettl11b",
  "Mettl14",
  "Mettl15",
  "Mettl16",
  "Mettl17",
  "Mettl18",
  "Mettl2",
  "Mettl21a",
  "Mettl21c",
  "Mettl21e",
  "Mettl22",
  "Mettl23",
  "Mettl24",
  "Mettl25",
  "Mettl26",
  "Mettl27",
  "Mettl3",
  "Mettl4",
  "Mettl5",
  "Mettl6",
  "Mettl7a1",
  "Mettl7a2",
  "Mettl7a3",
  "Mettl7b",
  "Mettl8",
  "Mettl9",
  "Mex3a",
  "Mex3b",
  "Mex3c",
  "Mex3d",
  "Mfap1a",
  "Mfap2",
  "Mfap3",
  "Mfap3l",
  "Mfap4",
  "Mfap5",
  "Mff",
  "Mfge8",
  "Mfhas1",
  "Mfn1",
  "Mfn2",
  "Mfng",
  "Mfsd1",
  "Mfsd10",
  "Mfsd11",
  "Mfsd12",
  "Mfsd13a",
  "Mfsd13b",
  "Mfsd14a",
  "Mfsd14b",
  "Mfsd2a",
  "Mfsd2b",
  "Mfsd3",
  "Mfsd4a",
  "Mfsd4b1",
  "Mfsd4b2",
  "Mfsd4b3-ps",
  "Mfsd4b4",
  "Mfsd4b5",
  "Mfsd5",
  "Mfsd6",
  "Mfsd6l",
  "Mfsd7a",
  "Mfsd8",
  "Mfsd9",
  "Mga",
  "Mgam",
  "Mgam2-ps",
  "Mgarp",
  "Mgat1",
  "Mgat2",
  "Mgat3",
  "Mgat4a",
  "Mgat4b",
  "Mgat4c",
  "Mgat4d",
  "Mgat4e",
  "Mgat4f",
  "Mgat5",
  "Mgat5b",
  "Mgll",
  "Mgme1",
  "Mgmt",
  "Mgp",
  "Mgrn1",
  "Mgst1",
  "Mgst2",
  "Mgst3",
  "Mhrt",
  "Mia",
  "Mia2",
  "Mia3",
  "Mib1",
  "Mib2",
  "Mical1",
  "Mical2",
  "Mical3",
  "Micall1",
  "Micall2",
  "Micos10",
  "Micos13",
  "Micu1",
  "Micu2",
  "Micu3",
  "Mid1",
  "Mid1ip1",
  "Mid2",
  "Midn",
  "Mief1",
  "Mief2",
  "Mien1",
  "Mier1",
  "Mier2",
  "Mier3",
  "Mif",
  "Mif4gd",
  "Miga1",
  "Miga2",
  "Miip",
  "Mill1",
  "Mill2",
  "Milr1",
  "Minar1",
  "Minar2",
  "Mindy1",
  "Mindy2",
  "Mindy3",
  "Mindy4",
  "Mindy4b-ps",
  "Mink1",
  "Minpp1",
  "Mios",
  "Miox",
  "Mip",
  "Mipep",
  "Mipol1",
  "Mir100hg",
  "Mir124-2hg",
  "Mir124a-1hg",
  "Mir17hg",
  "Mir181a-1hg",
  "Mir205hg",
  "Mir22hg",
  "Mir670hg",
  "Mir9-3hg",
  "Mir99ahg",
  "Mirg",
  "Mirt1",
  "Mirt2",
  "Mis12",
  "Mis18a",
  "Mis18bp1",
  "Misp",
  "Misp3",
  "Mitd1",
  "Mitf",
  "Mixl1",
  "Mki67",
  "Mkks",
  "Mkln1",
  "Mknk1",
  "Mknk2",
  "Mkrn1",
  "Mkrn2",
  "Mkrn2os",
  "Mkrn3",
  "Mks1",
  "Mkx",
  "Mlana",
  "Mlc1",
  "Mlec",
  "Mlf1",
  "Mlf2",
  "Mlh1",
  "Mlh3",
  "Mlip",
  "Mlkl",
  "Mllt1",
  "Mllt11",
  "Mllt3",
  "Mllt6",
  "Mlph",
  "Mlst8",
  "Mlx",
  "Mlxip",
  "Mlxipl",
  "Mlycd",
  "Mm2pr",
  "Mmaa",
  "Mmab",
  "Mmachc",
  "Mmadhc",
  "Mmd",
  "Mmd2",
  "Mme",
  "Mmel1",
  "Mmgt1",
  "Mmgt2",
  "Mmp10",
  "Mmp11",
  "Mmp12",
  "Mmp13",
  "Mmp14",
  "Mmp15",
  "Mmp16",
  "Mmp17",
  "Mmp19",
  "Mmp2",
  "Mmp20",
  "Mmp21",
  "Mmp23",
  "Mmp24",
  "Mmp25",
  "Mmp27",
  "Mmp28",
  "Mmp3",
  "Mmp7",
  "Mmp8",
  "Mmp9",
  "Mmrn1",
  "Mmrn2",
  "Mms19",
  "Mms22l",
  "Mmut",
  "Mn1",
  "Mnat1",
  "Mnd1",
  "Mndal",
  "Mns1",
  "Mnt",
  "Mnx1",
  "Mob1a",
  "Mob1b",
  "Mob2",
  "Mob3a",
  "Mob3b",
  "Mob3c",
  "Mob4",
  "Mobp",
  "Mocos",
  "Mocs1",
  "Mocs2",
  "Mocs3",
  "Mog",
  "Mogat1",
  "Mogat2",
  "Mogs",
  "Mok",
  "Mon1a",
  "Mon1b",
  "Mon2",
  "Morc1",
  "Morc2a",
  "Morc2b",
  "Morc3",
  "Morc4",
  "Morf4l2",
  "Morn1",
  "Morn2",
  "Morn3",
  "Morn4",
  "Morn5",
  "Morrbid",
  "Mos",
  "Mosmo",
  "Mospd1",
  "Mospd2",
  "Mospd3",
  "Mov10",
  "Mov10l1",
  "Moxd1",
  "Moxd2",
  "Mpc1",
  "Mpc2",
  "Mpdu1",
  "Mpdz",
  "Mpeg1",
  "Mpg",
  "Mphosph10",
  "Mphosph6",
  "Mphosph8",
  "Mphosph9",
  "Mpi",
  "Mpig6b",
  "Mpl",
  "Mplkip",
  "Mpnd",
  "Mpo",
  "Mpp1",
  "Mpp2",
  "Mpp3",
  "Mpp4",
  "Mpp5",
  "Mpp6",
  "Mpp7",
  "Mppe1",
  "Mpped1",
  "Mpped2",
  "Mprip",
  "Mpst",
  "Mptx1",
  "Mpv17",
  "Mpv17l",
  "Mpv17l2",
  "Mpz",
  "Mpzl1",
  "Mpzl2",
  "Mpzl3",
  "Mr1",
  "Mrap",
  "Mrap2",
  "Mras",
  "Mrc1",
  "Mrc2",
  "Mre11a",
  "Mreg",
  "Mrfap1",
  "Mrgbp",
  "Mrgpra1",
  "Mrgpra2a",
  "Mrgpra3",
  "Mrgpra4",
  "Mrgpra6",
  "Mrgpra9",
  "Mrgprb1",
  "Mrgprb2",
  "Mrgprb3",
  "Mrgprb4",
  "Mrgprb5",
  "Mrgprb8",
  "Mrgprd",
  "Mrgpre",
  "Mrgprf",
  "Mrgprg",
  "Mrgprh",
  "Mrgprx1",
  "Mrgprx2",
  "Mri1",
  "Mrln",
  "Mrm1",
  "Mrm2",
  "Mrm3",
  "Mrnip",
  "Mro",
  "Mroh1",
  "Mroh2a",
  "Mroh2b",
  "Mroh3",
  "Mroh4",
  "Mroh5",
  "Mroh7",
  "Mroh8",
  "Mroh9",
  "Mrpl1",
  "Mrpl10",
  "Mrpl11",
  "Mrpl12",
  "Mrpl13",
  "Mrpl14",
  "Mrpl15",
  "Mrpl16",
  "Mrpl17",
  "Mrpl18",
  "Mrpl19",
  "Mrpl2",
  "Mrpl20",
  "Mrpl21",
  "Mrpl22",
  "Mrpl24",
  "Mrpl27",
  "Mrpl28",
  "Mrpl3",
  "Mrpl30",
  "Mrpl32",
  "Mrpl33",
  "Mrpl34",
  "Mrpl35",
  "Mrpl36",
  "Mrpl37",
  "Mrpl38",
  "Mrpl39",
  "Mrpl4",
  "Mrpl40",
  "Mrpl41",
  "Mrpl42",
  "Mrpl43",
  "Mrpl44",
  "Mrpl45",
  "Mrpl46",
  "Mrpl47",
  "Mrpl48",
  "Mrpl49",
  "Mrpl50",
  "Mrpl52",
  "Mrpl54",
  "Mrpl55",
  "Mrpl57",
  "Mrpl58",
  "Mrpl9",
  "Mrps10",
  "Mrps11",
  "Mrps12",
  "Mrps14",
  "Mrps15",
  "Mrps16",
  "Mrps17",
  "Mrps18a",
  "Mrps18b",
  "Mrps18c",
  "Mrps2",
  "Mrps21",
  "Mrps23",
  "Mrps24",
  "Mrps25",
  "Mrps26",
  "Mrps27",
  "Mrps28",
  "Mrps30",
  "Mrps31",
  "Mrps33",
  "Mrps34",
  "Mrps35",
  "Mrps36",
  "Mrps6",
  "Mrps7",
  "Mrps9",
  "Mrrf",
  "Mrs2",
  "Mrtfa",
  "Mrtfb",
  "Mrto4",
  "Mrvi1",
  "Ms4a1",
  "Ms4a10",
  "Ms4a12",
  "Ms4a13",
  "Ms4a14",
  "Ms4a15",
  "Ms4a18",
  "Ms4a19",
  "Ms4a2",
  "Ms4a20",
  "Ms4a3",
  "Ms4a4a",
  "Ms4a4b",
  "Ms4a4c",
  "Ms4a4d",
  "Ms4a5",
  "Ms4a6b",
  "Ms4a6c",
  "Ms4a6d",
  "Ms4a7",
  "Ms4a8a",
  "Msantd1",
  "Msantd2",
  "Msantd3",
  "Msantd4",
  "Msc",
  "Msgn1",
  "Msh2",
  "Msh3",
  "Msh4",
  "Msh5",
  "Msh6",
  "Msi1",
  "Msi2",
  "Msl1",
  "Msl2",
  "Msl3",
  "Msl3l2",
  "Msln",
  "Mslnl",
  "Msmb",
  "Msmo1",
  "Msmp",
  "Msn",
  "Msr1",
  "Msra",
  "Msrb1",
  "Msrb2",
  "Msrb3",
  "Mss51",
  "Mst1",
  "Mst1r",
  "Mstn",
  "Msto1",
  "Msx1",
  "Msx2",
  "Msx3",
  "Mt1",
  "Mt2",
  "Mt3",
  "Mt4",
  "Mta1",
  "Mta2",
  "Mta3",
  "Mtag2",
  "Mtap",
  "Mtarc1",
  "Mtarc2",
  "Mtbp",
  "Mtch1",
  "Mtch2",
  "Mtcl1",
  "Mtcp1",
  "Mtdh",
  "Mterf1a",
  "Mterf2",
  "Mterf3",
  "Mterf4",
  "Mtf1",
  "Mtf2",
  "Mtfmt",
  "Mtfp1",
  "Mtfr1",
  "Mtfr1l",
  "Mtfr2",
  "Mtg1",
  "Mtg2",
  "Mthfd1",
  "Mthfd1l",
  "Mthfd2",
  "Mthfd2l",
  "Mthfr",
  "Mthfsd",
  "Mthfsl",
  "Mtif2",
  "Mtif3",
  "Mtln",
  "Mtm1",
  "Mtmr1",
  "Mtmr10",
  "Mtmr11",
  "Mtmr12",
  "Mtmr14",
  "Mtmr2",
  "Mtmr3",
  "Mtmr4",
  "Mtmr6",
  "Mtmr7",
  "Mtmr9",
  "Mtnr1a",
  "Mtnr1b",
  "Mto1",
  "Mtor",
  "Mtpap",
  "Mtpn",
  "Mtr",
  "Mtres1",
  "Mtrex",
  "Mtrf1",
  "Mtrf1l",
  "Mtrr",
  "Mtss1",
  "Mtss2",
  "Mttp",
  "Mturn",
  "Mtus1",
  "Mtus2",
  "Mtx1",
  "Mtx2",
  "Mtx3",
  "Muc1",
  "Muc13",
  "Muc15",
  "Muc16",
  "Muc19",
  "Muc2",
  "Muc20",
  "Muc3",
  "Muc4",
  "Muc5ac",
  "Muc5b",
  "Muc6",
  "Mucl1",
  "Mucl2",
  "Mucl3",
  "Mug1",
  "Mug2",
  "Mul1",
  "Mup1",
  "Mup20",
  "Mup4",
  "Mup5",
  "Mup6",
  "Mus81",
  "Musk",
  "Mustn1",
  "Mutyh",
  "Mvb12a",
  "Mvb12b",
  "Mvd",
  "Mvk",
  "Mvp",
  "Mxd1",
  "Mxd3",
  "Mxd4",
  "Mxi1",
  "Mxra7",
  "Mxra8",
  "Mxra8os",
  "Myadm",
  "Myadml2",
  "Mybbp1a",
  "Mybl1",
  "Mybl2",
  "Mybpc1",
  "Mybpc2",
  "Mybpc3",
  "Mybph",
  "Mybphl",
  "Myc",
  "Mycbp",
  "Mycbp2",
  "Mycbpap",
  "Mycl",
  "Mycn",
  "Mycs",
  "Myct1",
  "Myd88",
  "Mydgf",
  "Myef2",
  "Myf5",
  "Myf6",
  "Myg1",
  "Myh1",
  "Myh10",
  "Myh11",
  "Myh13",
  "Myh14",
  "Myh15",
  "Myh2",
  "Myh3",
  "Myh4",
  "Myh6",
  "Myh7",
  "Myh7b",
  "Myh8",
  "Myh9",
  "Myl1",
  "Myl10",
  "Myl12a",
  "Myl12b",
  "Myl2",
  "Myl3",
  "Myl4",
  "Myl6",
  "Myl6b",
  "Myl7",
  "Myl9",
  "Mylip",
  "Mylk",
  "Mylk2",
  "Mylk3",
  "Mylk4",
  "Mylpf",
  "Mymk",
  "Mymx",
  "Mynn",
  "Myo10",
  "Myo15",
  "Myo15b",
  "Myo16",
  "Myo18a",
  "Myo18b",
  "Myo19",
  "Myo1a",
  "Myo1b",
  "Myo1c",
  "Myo1d",
  "Myo1e",
  "Myo1f",
  "Myo1g",
  "Myo1h",
  "Myo3a",
  "Myo3b",
  "Myo5a",
  "Myo5b",
  "Myo6",
  "Myo7a",
  "Myo7b",
  "Myo9a",
  "Myo9b",
  "Myoc",
  "Myocd",
  "Myof",
  "Myom1",
  "Myom2",
  "Myom3",
  "Myorg",
  "Myot",
  "Myoz1",
  "Myoz2",
  "Myoz3",
  "Mypn",
  "Mypop",
  "Myrf",
  "Myrfl",
  "Myrip",
  "Mysm1",
  "Myt1l",
  "Myzap",
  "Mzf1",
  "Mzt1",
  "Mzt2",
  "N4bp1",
  "N4bp2",
  "N4bp2l1",
  "N4bp2l2",
  "N4bp2os",
  "N4bp3",
  "N6amt1",
  "Naa10",
  "Naa11",
  "Naa15",
  "Naa16",
  "Naa20",
  "Naa25",
  "Naa30",
  "Naa35",
  "Naa38",
  "Naa40",
  "Naa50",
  "Naa60",
  "Naa80",
  "Naaa",
  "Naalad2",
  "Naaladl1",
  "Naaladl2",
  "Nab1",
  "Nab2",
  "Nabp1",
  "Nabp2",
  "Nacad",
  "Nacc1",
  "Nacc2",
  "Nadk",
  "Nadk2",
  "Nadsyn1",
  "Nae1",
  "Naf1",
  "Naga",
  "Nagk",
  "Naglu",
  "Nagpa",
  "Nags",
  "Naif1",
  "Naip1",
  "Naip2",
  "Naip5",
  "Naip6",
  "Nalcn",
  "Nampt",
  "Nanog",
  "Nanos1",
  "Nanos2",
  "Nanos3",
  "Nanp",
  "Nans",
  "Nap1l1",
  "Nap1l2",
  "Nap1l3",
  "Nap1l4",
  "Nap1l5",
  "Napa",
  "Napb",
  "Napepld",
  "Napg",
  "Naprt",
  "Napsa",
  "Narf",
  "Nars",
  "Nars2",
  "Nasp",
  "Nat1",
  "Nat10",
  "Nat14",
  "Nat2",
  "Nat3",
  "Nat8",
  "Nat8f1",
  "Nat8f2",
  "Nat8f4",
  "Nat8f5",
  "Nat8l",
  "Nat9",
  "Natd1",
  "Nav1",
  "Nav2",
  "Nav3",
  "Naxd",
  "Naxe",
  "Nbas",
  "Nbdy",
  "Nbea",
  "Nbeal1",
  "Nbeal2",
  "Nbl1",
  "Nbn",
  "Nbr1",
  "Ncald",
  "Ncam1",
  "Ncam2",
  "Ncan",
  "Ncapd2",
  "Ncapd3",
  "Ncapg",
  "Ncapg2",
  "Ncaph",
  "Ncaph2",
  "Ncbp1",
  "Ncbp2",
  "Ncbp3",
  "Nccrp1",
  "Ncdn",
  "Nceh1",
  "Ncf1",
  "Ncf2",
  "Ncf4",
  "Nck1",
  "Nck2",
  "Nckap1",
  "Nckap1l",
  "Nckap5",
  "Nckap5l",
  "Nckap5los",
  "Nckipsd",
  "Ncl",
  "Ncln",
  "Ncmap",
  "Ncoa1",
  "Ncoa2",
  "Ncoa3",
  "Ncoa4",
  "Ncoa5",
  "Ncoa6",
  "Ncoa7",
  "Ncor1",
  "Ncor2",
  "Ncr1",
  "Ncs1",
  "Ncstn",
  "Nctc1",
  "Ndc1",
  "Ndc80",
  "Nde1",
  "Ndel1",
  "Ndfip1",
  "Ndfip2",
  "Ndn",
  "Ndnf",
  "Ndor1",
  "Ndp",
  "Ndrg1",
  "Ndrg2",
  "Ndrg3",
  "Ndrg4",
  "Ndst1",
  "Ndst2",
  "Ndst3",
  "Ndst4",
  "Ndufa1",
  "Ndufa10",
  "Ndufa11",
  "Ndufa12",
  "Ndufa13",
  "Ndufa2",
  "Ndufa3",
  "Ndufa4",
  "Ndufa4l2",
  "Ndufa5",
  "Ndufa6",
  "Ndufa7",
  "Ndufa8",
  "Ndufa9",
  "Ndufab1",
  "Ndufaf1",
  "Ndufaf2",
  "Ndufaf3",
  "Ndufaf4",
  "Ndufaf5",
  "Ndufaf6",
  "Ndufaf7",
  "Ndufaf8",
  "Ndufb1-ps",
  "Ndufb10",
  "Ndufb11",
  "Ndufb2",
  "Ndufb3",
  "Ndufb4",
  "Ndufb5",
  "Ndufb6",
  "Ndufb7",
  "Ndufb8",
  "Ndufb9",
  "Ndufc1",
  "Ndufc2",
  "Ndufs1",
  "Ndufs2",
  "Ndufs3",
  "Ndufs4",
  "Ndufs5",
  "Ndufs6",
  "Ndufs7",
  "Ndufs8",
  "Ndufv1",
  "Ndufv2",
  "Ndufv3",
  "Neat1",
  "Neb",
  "Nebl",
  "Necab1",
  "Necab2",
  "Necab3",
  "Necap1",
  "Necap2",
  "Nectin1",
  "Nectin2",
  "Nectin3",
  "Nectin4",
  "Nedd1",
  "Nedd4",
  "Nedd4l",
  "Nedd8",
  "Nedd9",
  "Nefh",
  "Nefl",
  "Nefm",
  "Negr1",
  "Neil1",
  "Neil2",
  "Neil3",
  "Nek1",
  "Nek10",
  "Nek11",
  "Nek2",
  "Nek3",
  "Nek4",
  "Nek5",
  "Nek6",
  "Nek7",
  "Nek8",
  "Nek9",
  "Nelfa",
  "Nelfb",
  "Nelfe",
  "Nell1",
  "Nell2",
  "Nemf",
  "Nemp1",
  "Nemp2",
  "Nenf",
  "Neo1",
  "Nepn",
  "Nepro",
  "Nes",
  "Net1",
  "Neto1",
  "Neto2",
  "Neu1",
  "Neu2",
  "Neu3",
  "Neu4",
  "Neurl1a",
  "Neurl1b",
  "Neurl2",
  "Neurl3",
  "Neurl4",
  "Neurod1",
  "Neurod4",
  "Neurod6",
  "Neurog1",
  "Neurog2",
  "Neurog3",
  "Nexmif",
  "Nexn",
  "Nf1",
  "Nf2",
  "Nfam1",
  "Nfasc",
  "Nfat5",
  "Nfatc1",
  "Nfatc2",
  "Nfatc2ip",
  "Nfatc3",
  "Nfatc4",
  "Nfe2",
  "Nfe2l1",
  "Nfe2l2",
  "Nfe2l3",
  "Nfia",
  "Nfib",
  "Nfic",
  "Nfil3",
  "Nfix",
  "Nfkb1",
  "Nfkb2",
  "Nfkbia",
  "Nfkbib",
  "Nfkbid",
  "Nfkbie",
  "Nfkbil1",
  "Nfkbiz",
  "Nfrkb",
  "Nfs1",
  "Nfu1",
  "Nfx1",
  "Nfxl1",
  "Nfya",
  "Nfyb",
  "Nfyc",
  "Ngb",
  "Ngdn",
  "Ngef",
  "Ngf",
  "Ngfr",
  "Ngly1",
  "Ngp",
  "Ngrn",
  "Nhej1",
  "Nhlh1",
  "Nhlh2",
  "Nhlrc1",
  "Nhlrc2",
  "Nhlrc3",
  "Nhlrc4",
  "Nhp2",
  "Nhs",
  "Nhsl1",
  "Nhsl2",
  "Nicn1",
  "Nid1",
  "Nid2",
  "Nif3l1",
  "Nifk",
  "Nim1k",
  "Nin",
  "Ninj1",
  "Ninj2",
  "Ninl",
  "Nip7",
  "Nipa1",
  "Nipa2",
  "Nipal1",
  "Nipal2",
  "Nipal3",
  "Nipal4",
  "Nipbl",
  "Nipsnap1",
  "Nipsnap2",
  "Nipsnap3a",
  "Nipsnap3b",
  "Nisch",
  "Nit1",
  "Nit2",
  "Nkain2",
  "Nkain3",
  "Nkain4",
  "Nkap",
  "Nkapd1",
  "Nkapl",
  "Nkd1",
  "Nkd2",
  "Nkg7",
  "Nkiras1",
  "Nkiras2",
  "Nkpd1",
  "Nkrf",
  "Nktr",
  "Nkx1-1",
  "Nkx1-2",
  "Nkx2-1",
  "Nkx2-2",
  "Nkx2-3",
  "Nkx2-4",
  "Nkx2-5",
  "Nkx2-6",
  "Nkx2-9",
  "Nkx3-1",
  "Nkx3-2",
  "Nkx6-1",
  "Nkx6-2",
  "Nkx6-3",
  "Nle1",
  "Nlgn1",
  "Nlgn2",
  "Nlgn3",
  "Nlk",
  "Nln",
  "Nlrc3",
  "Nlrc4",
  "Nlrc5",
  "Nlrp10",
  "Nlrp12",
  "Nlrp14",
  "Nlrp1a",
  "Nlrp1b",
  "Nlrp2",
  "Nlrp3",
  "Nlrp4a",
  "Nlrp4b",
  "Nlrp4c",
  "Nlrp4e",
  "Nlrp4f",
  "Nlrp5",
  "Nlrp6",
  "Nlrp9a",
  "Nlrp9b",
  "Nlrp9c",
  "Nlrx1",
  "Nmb",
  "Nmbr",
  "Nmd3",
  "Nme1",
  "Nme4",
  "Nme5",
  "Nme6",
  "Nme7",
  "Nme8",
  "Nme9",
  "Nmi",
  "Nmnat1",
  "Nmnat2",
  "Nmnat3",
  "Nmral1",
  "Nmrk1",
  "Nmrk2",
  "Nms",
  "Nmt1",
  "Nmt2",
  "Nmu",
  "Nmur1",
  "Nmur2",
  "Nnat",
  "Nnmt",
  "Nnt",
  "Noa1",
  "Nob1",
  "Nobox",
  "Noc2l",
  "Noc3l",
  "Noc4l",
  "Noct",
  "Nod1",
  "Nod2",
  "Nodal",
  "Nog",
  "Nol10",
  "Nol11",
  "Nol12",
  "Nol3",
  "Nol4",
  "Nol4l",
  "Nol6",
  "Nol7",
  "Nol8",
  "Nol9",
  "Nolc1",
  "Nom1",
  "Nomo1",
  "Nono",
  "Nop10",
  "Nop14",
  "Nop16",
  "Nop2",
  "Nop53",
  "Nop56",
  "Nop58",
  "Nop9",
  "Nos1",
  "Nos1ap",
  "Nos2",
  "Nos3",
  "Nosip",
  "Nostrin",
  "Notch1",
  "Notch2",
  "Notch3",
  "Notch4",
  "Noto",
  "Notum",
  "Notumos",
  "Nova1",
  "Nova2",
  "Nox1",
  "Nox3",
  "Nox4",
  "Noxa1",
  "Noxo1",
  "Noxred1",
  "Npas1",
  "Npas2",
  "Npas3",
  "Npas4",
  "Npb",
  "Npbwr1",
  "Npc1",
  "Npc1l1",
  "Npc2",
  "Npdc1",
  "Npepl1",
  "Npepps",
  "Npffr1",
  "Npffr2",
  "Nphp1",
  "Nphp3",
  "Nphp4",
  "Nphs1os",
  "Nphs2",
  "Npl",
  "Nploc4",
  "Npm1",
  "Npm2",
  "Npm3",
  "Npnt",
  "Nppa",
  "Nppb",
  "Nppc",
  "Npr1",
  "Npr2",
  "Npr3",
  "Nprl2",
  "Nprl3",
  "Nps",
  "Npsr1",
  "Nptn",
  "Nptx1",
  "Nptx2",
  "Nptxr",
  "Npvf",
  "Npw",
  "Npy",
  "Npy1r",
  "Npy2r",
  "Npy4r",
  "Npy5r",
  "Npy6r",
  "Nqo1",
  "Nqo2",
  "Nr0b1",
  "Nr0b2",
  "Nr1d1",
  "Nr1d2",
  "Nr1h2",
  "Nr1h3",
  "Nr1h4",
  "Nr1h5",
  "Nr1i2",
  "Nr1i3",
  "Nr2c1",
  "Nr2c2",
  "Nr2c2ap",
  "Nr2e1",
  "Nr2e3",
  "Nr2f1",
  "Nr2f2",
  "Nr2f6",
  "Nr3c1",
  "Nr3c2",
  "Nr4a1",
  "Nr4a2",
  "Nr4a3",
  "Nr5a1",
  "Nr5a2",
  "Nr6a1",
  "Nradd",
  "Nrap",
  "Nrarp",
  "Nras",
  "Nrbp1",
  "Nrbp2",
  "Nrcam",
  "Nrd1",
  "Nrde2",
  "Nrep",
  "Nrf1",
  "Nrg1",
  "Nrg2",
  "Nrg3",
  "Nrg3os",
  "Nrg4",
  "Nrgn",
  "Nrip1",
  "Nrip2",
  "Nrip3",
  "Nrk",
  "Nrl",
  "Nrm",
  "Nrn1",
  "Nrn1l",
  "Nron",
  "Nrp1",
  "Nrp2",
  "Nrros",
  "Nrsn1",
  "Nrsn2",
  "Nrtn",
  "Nrxn1",
  "Nrxn2",
  "Nrxn3",
  "Nsa2",
  "Nsd1",
  "Nsd2",
  "Nsd3",
  "Nsdhl",
  "Nsf",
  "Nsfl1c",
  "Nsg1",
  "Nsg2",
  "Nsl1",
  "Nsmaf",
  "Nsmce2",
  "Nsmce3",
  "Nsmce4a",
  "Nsmf",
  "Nsrp1",
  "Nsun2",
  "Nsun3",
  "Nsun4",
  "Nsun5",
  "Nsun6",
  "Nsun7",
  "Nt5c",
  "Nt5c1a",
  "Nt5c1b",
  "Nt5c2",
  "Nt5c3",
  "Nt5c3b",
  "Nt5dc1",
  "Nt5dc2",
  "Nt5dc3",
  "Nt5e",
  "Nt5el",
  "Nt5m",
  "Ntan1",
  "Ntf3",
  "Ntf5",
  "Nthl1",
  "Ntm",
  "Ntmt1",
  "Ntn1",
  "Ntn3",
  "Ntn5",
  "Ntng1",
  "Ntng2",
  "Ntpcr",
  "Ntrk1",
  "Ntrk2",
  "Ntrk3",
  "Nts",
  "Ntsr1",
  "Ntsr2",
  "Nuak1",
  "Nub1",
  "Nubp1",
  "Nubp2",
  "Nubpl",
  "Nucb1",
  "Nucb2",
  "Nucks1",
  "Nudc",
  "Nudcd1",
  "Nudcd2",
  "Nudcd3",
  "Nudt1",
  "Nudt10",
  "Nudt11",
  "Nudt12",
  "Nudt12os",
  "Nudt13",
  "Nudt14",
  "Nudt15",
  "Nudt16",
  "Nudt16l1",
  "Nudt16l2",
  "Nudt17",
  "Nudt18",
  "Nudt19",
  "Nudt2",
  "Nudt21",
  "Nudt22",
  "Nudt3",
  "Nudt4",
  "Nudt5",
  "Nudt6",
  "Nudt7",
  "Nudt9",
  "Nuf2",
  "Nufip1",
  "Nufip2",
  "Nuggc",
  "Numa1",
  "Numb",
  "Numbl",
  "Nup107",
  "Nup133",
  "Nup153",
  "Nup155",
  "Nup160",
  "Nup188",
  "Nup205",
  "Nup210",
  "Nup210l",
  "Nup214",
  "Nup35",
  "Nup37",
  "Nup43",
  "Nup50",
  "Nup54",
  "Nup62",
  "Nup62cl",
  "Nup85",
  "Nup88",
  "Nup93",
  "Nup98",
  "Nupl2",
  "Nupr1",
  "Nupr1l",
  "Nus1",
  "Nusap1",
  "Nutm1",
  "Nutm2",
  "Nvl",
  "Nwd1",
  "Nwd2",
  "Nxf1",
  "Nxf2",
  "Nxf3",
  "Nxf7",
  "Nxn",
  "Nxnl1",
  "Nxnl2",
  "Nxpe2",
  "Nxpe3",
  "Nxpe4",
  "Nxpe5",
  "Nxph1",
  "Nxph2",
  "Nxph3",
  "Nxph4",
  "Nxt1",
  "Nxt2",
  "Nyap1",
  "Nyap2",
  "Nynrin",
  "Nyx",
  "Oacyl",
  "Oaf",
  "Oard1",
  "Oas1a",
  "Oas1c",
  "Oas1d",
  "Oas1e",
  "Oas1f",
  "Oas1g",
  "Oas1h",
  "Oas2",
  "Oas3",
  "Oasl1",
  "Oasl2",
  "Oat",
  "Oaz1",
  "Oaz2",
  "Oaz3",
  "Obox3",
  "Obox5",
  "Obox6",
  "Obox7",
  "Obox8",
  "Obp1a",
  "Obp1b",
  "Obp2a",
  "Obp2b",
  "Obscn",
  "Obsl1",
  "Oc90",
  "Oca2",
  "Ocel1",
  "Ociad1",
  "Ociad2",
  "Ocln",
  "Ocm",
  "Ocrl",
  "Ocstamp",
  "Odam",
  "Odaph",
  "Odc1",
  "Odf1",
  "Odf2",
  "Odf2l",
  "Odf3",
  "Odf3b",
  "Odf3l1",
  "Odf3l2",
  "Odf4",
  "Odr4",
  "Ofcc1",
  "Ofd1",
  "Oga",
  "Ogdh",
  "Ogdhl",
  "Ogfod1",
  "Ogfod2",
  "Ogfod3",
  "Ogfr",
  "Ogfrl1",
  "Ogg1",
  "Ogn",
  "Ogt",
  "Oip5",
  "Oip5os1",
  "Oit1",
  "Oit3",
  "Ola1",
  "Olah",
  "Olfm1",
  "Olfm2",
  "Olfm3",
  "Olfm4",
  "Olfm5",
  "Olfml1",
  "Olfml2a",
  "Olfml2b",
  "Olfml3",
  "Olfr1",
  "Olfr10",
  "Olfr1000",
  "Olfr1002",
  "Olfr1006",
  "Olfr1008",
  "Olfr1009",
  "Olfr101",
  "Olfr1012",
  "Olfr1013",
  "Olfr1014",
  "Olfr1015",
  "Olfr1016",
  "Olfr1018",
  "Olfr1019",
  "Olfr1020",
  "Olfr1022",
  "Olfr1023",
  "Olfr1024",
  "Olfr1025-ps1",
  "Olfr1026",
  "Olfr1028",
  "Olfr1029",
  "Olfr103",
  "Olfr1030",
  "Olfr1031",
  "Olfr1032",
  "Olfr1033",
  "Olfr1036",
  "Olfr1037",
  "Olfr1039",
  "Olfr104-ps",
  "Olfr1040",
  "Olfr1042",
  "Olfr1043",
  "Olfr1044",
  "Olfr1045",
  "Olfr1047",
  "Olfr1048",
  "Olfr105-ps",
  "Olfr1052",
  "Olfr1053",
  "Olfr1054",
  "Olfr1055",
  "Olfr1056",
  "Olfr1058",
  "Olfr106-ps",
  "Olfr1061",
  "Olfr1062",
  "Olfr107",
  "Olfr1076",
  "Olfr1079",
  "Olfr108",
  "Olfr1080",
  "Olfr1084",
  "Olfr1086",
  "Olfr1087",
  "Olfr1089",
  "Olfr109",
  "Olfr1090",
  "Olfr1093",
  "Olfr1094",
  "Olfr1099",
  "Olfr11",
  "Olfr110",
  "Olfr1100",
  "Olfr1101",
  "Olfr1102",
  "Olfr1104",
  "Olfr1105",
  "Olfr1106",
  "Olfr1107",
  "Olfr1109",
  "Olfr111",
  "Olfr1110",
  "Olfr1111",
  "Olfr1112",
  "Olfr112",
  "Olfr1120",
  "Olfr1121",
  "Olfr1122",
  "Olfr1123",
  "Olfr1124",
  "Olfr1126",
  "Olfr113",
  "Olfr1131",
  "Olfr1132",
  "Olfr1133",
  "Olfr1134",
  "Olfr1135",
  "Olfr1136",
  "Olfr1137",
  "Olfr1138",
  "Olfr114",
  "Olfr1140",
  "Olfr1141",
  "Olfr1143",
  "Olfr1145",
  "Olfr1148",
  "Olfr115",
  "Olfr1151",
  "Olfr1152",
  "Olfr1153",
  "Olfr1154",
  "Olfr1155",
  "Olfr1156",
  "Olfr1157",
  "Olfr1158",
  "Olfr116",
  "Olfr1160",
  "Olfr1161",
  "Olfr1162",
  "Olfr1163",
  "Olfr1164",
  "Olfr1166",
  "Olfr1168",
  "Olfr117",
  "Olfr1170",
  "Olfr1173",
  "Olfr1175",
  "Olfr1176",
  "Olfr1177-ps",
  "Olfr1178",
  "Olfr1179",
  "Olfr1180",
  "Olfr1181",
  "Olfr1183",
  "Olfr1184",
  "Olfr1186",
  "Olfr1188",
  "Olfr1189",
  "Olfr119",
  "Olfr1195",
  "Olfr1197",
  "Olfr1198",
  "Olfr12",
  "Olfr120",
  "Olfr1200",
  "Olfr1201",
  "Olfr1202",
  "Olfr1204",
  "Olfr1205",
  "Olfr1206",
  "Olfr1208",
  "Olfr1209",
  "Olfr121",
  "Olfr1211",
  "Olfr1212",
  "Olfr1213",
  "Olfr1214",
  "Olfr1215",
  "Olfr1216",
  "Olfr1217",
  "Olfr1218",
  "Olfr1219",
  "Olfr122",
  "Olfr1220",
  "Olfr1221",
  "Olfr1222",
  "Olfr1223",
  "Olfr1224-ps1",
  "Olfr1225",
  "Olfr1226",
  "Olfr1228",
  "Olfr1229",
  "Olfr123",
  "Olfr1230",
  "Olfr1231",
  "Olfr1232",
  "Olfr1233",
  "Olfr1234",
  "Olfr1238",
  "Olfr1239",
  "Olfr124",
  "Olfr1240",
  "Olfr1241",
  "Olfr1242",
  "Olfr1243",
  "Olfr1245",
  "Olfr1246",
  "Olfr1247",
  "Olfr1249",
  "Olfr125",
  "Olfr1250",
  "Olfr1251",
  "Olfr1252",
  "Olfr1253",
  "Olfr1254",
  "Olfr1255",
  "Olfr1256",
  "Olfr1257",
  "Olfr1258",
  "Olfr1259",
  "Olfr126",
  "Olfr1260",
  "Olfr1262",
  "Olfr1264",
  "Olfr1265",
  "Olfr127",
  "Olfr1270",
  "Olfr1271",
  "Olfr1275",
  "Olfr1276",
  "Olfr1277",
  "Olfr1278",
  "Olfr1279",
  "Olfr128",
  "Olfr1280",
  "Olfr1283",
  "Olfr1284",
  "Olfr1288",
  "Olfr1289",
  "Olfr129",
  "Olfr1290",
  "Olfr1293-ps",
  "Olfr1294",
  "Olfr1295",
  "Olfr1297",
  "Olfr1298",
  "Olfr1299",
  "Olfr13",
  "Olfr130",
  "Olfr1301",
  "Olfr1302",
  "Olfr1303",
  "Olfr1305",
  "Olfr1306",
  "Olfr1307",
  "Olfr1308",
  "Olfr1309",
  "Olfr131",
  "Olfr1310",
  "Olfr1311",
  "Olfr1312",
  "Olfr1313",
  "Olfr1314",
  "Olfr1317",
  "Olfr1318",
  "Olfr132",
  "Olfr1320",
  "Olfr1321",
  "Olfr1322",
  "Olfr1323",
  "Olfr1324",
  "Olfr1325",
  "Olfr1326-ps1",
  "Olfr1328",
  "Olfr1329",
  "Olfr133",
  "Olfr1330",
  "Olfr1333",
  "Olfr1335",
  "Olfr1336",
  "Olfr1337",
  "Olfr1338",
  "Olfr1339",
  "Olfr134",
  "Olfr1340",
  "Olfr1341",
  "Olfr1342",
  "Olfr1346",
  "Olfr1347",
  "Olfr1348",
  "Olfr1349",
  "Olfr135",
  "Olfr1350",
  "Olfr1351",
  "Olfr1352",
  "Olfr1353",
  "Olfr1354",
  "Olfr1356",
  "Olfr1357",
  "Olfr1358",
  "Olfr136",
  "Olfr1361",
  "Olfr1362",
  "Olfr1364",
  "Olfr1366",
  "Olfr1367",
  "Olfr1368",
  "Olfr1369-ps1",
  "Olfr137",
  "Olfr1370",
  "Olfr1375",
  "Olfr1377",
  "Olfr1378",
  "Olfr138",
  "Olfr1382",
  "Olfr1383",
  "Olfr1384",
  "Olfr1385",
  "Olfr1386",
  "Olfr1387",
  "Olfr1388",
  "Olfr1389",
  "Olfr139",
  "Olfr1390",
  "Olfr1391",
  "Olfr1393",
  "Olfr1394",
  "Olfr1395",
  "Olfr1396",
  "Olfr140",
  "Olfr1402",
  "Olfr1404",
  "Olfr1406",
  "Olfr1408",
  "Olfr141",
  "Olfr1410",
  "Olfr1411",
  "Olfr1412",
  "Olfr1413",
  "Olfr1414",
  "Olfr1415",
  "Olfr1416",
  "Olfr1417",
  "Olfr1418",
  "Olfr1419",
  "Olfr142",
  "Olfr1420",
  "Olfr1423",
  "Olfr1424",
  "Olfr1425",
  "Olfr1426",
  "Olfr1427",
  "Olfr1428",
  "Olfr143",
  "Olfr1431",
  "Olfr1434",
  "Olfr1436",
  "Olfr1440",
  "Olfr1441",
  "Olfr1442",
  "Olfr1443",
  "Olfr1444",
  "Olfr1445",
  "Olfr1446",
  "Olfr1447",
  "Olfr1448",
  "Olfr1449",
  "Olfr145",
  "Olfr1450",
  "Olfr1451",
  "Olfr1453",
  "Olfr1454",
  "Olfr1457",
  "Olfr1459",
  "Olfr146",
  "Olfr1461",
  "Olfr1462",
  "Olfr1463",
  "Olfr1465",
  "Olfr1466",
  "Olfr1467",
  "Olfr1469",
  "Olfr147",
  "Olfr1471",
  "Olfr1472",
  "Olfr1477",
  "Olfr148",

  "Olfr1487",
  "Olfr1489",
  "Olfr149",
  "Olfr1490",
  "Olfr1491",
  "Olfr1494",
  "Olfr1495",
  "Olfr1496",
  "Olfr1497",
  "Olfr1499",
  "Olfr15",
  "Olfr1500",
  "Olfr1501",
  "Olfr1502",
  "Olfr1504",
  "Olfr1505",
  "Olfr1507",
  "Olfr1508",
  "Olfr1509",
  "Olfr1510",
  "Olfr1511",
  "Olfr1512",
  "Olfr1513",
  "Olfr152",
  "Olfr153",
  "Olfr1532-ps1",
  "Olfr1535",
  "Olfr1537",
  "Olfr154",
  "Olfr155",
  "Olfr156",
  "Olfr1564",
  "Olfr157",
  "Olfr159",
  "Olfr16",
  "Olfr160",
  "Olfr161",
  "Olfr164",
  "Olfr165",
  "Olfr166",
  "Olfr167",
  "Olfr168",
  "Olfr169",
  "Olfr17",
  "Olfr171",
  "Olfr173",
  "Olfr175",
  "Olfr178",
  "Olfr18",
  "Olfr180",
  "Olfr181",
  "Olfr183",
  "Olfr186",
  "Olfr187",
  "Olfr19",
  "Olfr190",
  "Olfr191",
  "Olfr193",
  "Olfr194",
  "Olfr195",
  "Olfr196",
  "Olfr198",
  "Olfr199",
  "Olfr2",
  "Olfr202",
  "Olfr203",
  "Olfr204",
  "Olfr206",
  "Olfr209",
  "Olfr211",
  "Olfr212",
  "Olfr213",
  "Olfr214",
  "Olfr215",
  "Olfr218",
  "Olfr220",
  "Olfr221",
  "Olfr222",
  "Olfr223",
  "Olfr224",
  "Olfr228",
  "Olfr229",
  "Olfr23",
  "Olfr231",
  "Olfr235",
  "Olfr237",
  "Olfr24",
  "Olfr243",
  "Olfr248",
  "Olfr25",
  "Olfr250",
  "Olfr251",
  "Olfr259",
  "Olfr26",
  "Olfr262",
  "Olfr263",
  "Olfr266",
  "Olfr267",
  "Olfr270",
  "Olfr272",
  "Olfr273",
  "Olfr275",
  "Olfr279",
  "Olfr281",
  "Olfr282",
  "Olfr283",
  "Olfr284",
  "Olfr285",
  "Olfr286",
  "Olfr292",
  "Olfr293",
  "Olfr294",
  "Olfr295",
  "Olfr297",
  "Olfr298",
  "Olfr299",
  "Olfr3",
  "Olfr30",
  "Olfr301",
  "Olfr303",
  "Olfr304",
  "Olfr305",
  "Olfr307",
  "Olfr308",
  "Olfr309",
  "Olfr31",
  "Olfr310",
  "Olfr311",
  "Olfr312",
  "Olfr314",
  "Olfr315",
  "Olfr316",
  "Olfr317",
  "Olfr318",
  "Olfr319",
  "Olfr32",
  "Olfr320",
  "Olfr322",
  "Olfr323",
  "Olfr324",
  "Olfr325",
  "Olfr328",
  "Olfr329",
  "Olfr33",
  "Olfr330",
  "Olfr331",
  "Olfr332",
  "Olfr338",
  "Olfr339",
  "Olfr340",
  "Olfr341",
  "Olfr342",
  "Olfr344",
  "Olfr345",
  "Olfr347",
  "Olfr348",
  "Olfr350",
  "Olfr351",
  "Olfr352",
  "Olfr353",
  "Olfr354",
  "Olfr355",
  "Olfr356",
  "Olfr357",
  "Olfr358",
  "Olfr360",
  "Olfr361",
  "Olfr362",
  "Olfr364-ps1",
  "Olfr365",
  "Olfr366",
  "Olfr368",
  "Olfr370",
  "Olfr371",
  "Olfr372",
  "Olfr373",
  "Olfr374",
  "Olfr376",
  "Olfr378",
  "Olfr38",
  "Olfr381",
  "Olfr382",
  "Olfr384",
  "Olfr385",
  "Olfr389",
  "Olfr39",
  "Olfr390",
  "Olfr391",
  "Olfr392",
  "Olfr393",
  "Olfr394",
  "Olfr395",
  "Olfr397",
  "Olfr398",
  "Olfr399",
  "Olfr401",
  "Olfr402",
  "Olfr406",
  "Olfr411",
  "Olfr412",
  "Olfr414",
  "Olfr417",
  "Olfr418",
  "Olfr419",
  "Olfr420",
  "Olfr424",
  "Olfr427",
  "Olfr429",
  "Olfr43",
  "Olfr430",
  "Olfr432",
  "Olfr433",
  "Olfr434",
  "Olfr435",
  "Olfr437",
  "Olfr44",
  "Olfr441",
  "Olfr444",
  "Olfr446",
  "Olfr447",
  "Olfr448",
  "Olfr449",
  "Olfr45",
  "Olfr450",
  "Olfr452",
  "Olfr453",
  "Olfr455",
  "Olfr458",
  "Olfr459",
  "Olfr46",
  "Olfr460",
  "Olfr461",
  "Olfr462",
  "Olfr463",
  "Olfr464",
  "Olfr466",
  "Olfr467",
  "Olfr47",
  "Olfr470",
  "Olfr472",
  "Olfr473",
  "Olfr474",
  "Olfr478",
  "Olfr479",
  "Olfr48",
  "Olfr480",
  "Olfr481",
  "Olfr482",
  "Olfr483",
  "Olfr484",
  "Olfr485",
  "Olfr486",
  "Olfr488",
  "Olfr49",
  "Olfr490",
  "Olfr491",
  "Olfr492",
  "Olfr493",
  "Olfr494",
  "Olfr495",
  "Olfr497",
  "Olfr498",
  "Olfr5",
  "Olfr50",
  "Olfr502",
  "Olfr503",
  "Olfr506",
  "Olfr507",
  "Olfr508",
  "Olfr509",
  "Olfr51",
  "Olfr510",
  "Olfr512",
  "Olfr513",
  "Olfr514",
  "Olfr516",
  "Olfr517",
  "Olfr518",
  "Olfr519",
  "Olfr52",
  "Olfr520",
  "Olfr521",
  "Olfr522",
  "Olfr523",
  "Olfr524",
  "Olfr525",
  "Olfr527",
  "Olfr53",
  "Olfr530",
  "Olfr531",
  "Olfr532",
  "Olfr533",
  "Olfr535",
  "Olfr536",
  "Olfr539",
  "Olfr54",
  "Olfr541",
  "Olfr543",
  "Olfr544",
  "Olfr545",
  "Olfr547",
  "Olfr549",
  "Olfr550",
  "Olfr551",
  "Olfr552",
  "Olfr553",
  "Olfr554",
  "Olfr555",
  "Olfr556",
  "Olfr557",
  "Olfr558",
  "Olfr559",
  "Olfr56",
  "Olfr560",
  "Olfr561",
  "Olfr564",
  "Olfr566",
  "Olfr568",
  "Olfr569",
  "Olfr57",
  "Olfr570",
  "Olfr571",
  "Olfr572",
  "Olfr573-ps1",
  "Olfr574",
  "Olfr575",
  "Olfr576",
  "Olfr577",
  "Olfr578",
  "Olfr58",
  "Olfr582",
  "Olfr583",
  "Olfr585",
  "Olfr586",
  "Olfr589",
  "Olfr59",
  "Olfr591",
  "Olfr592",
  "Olfr593",
  "Olfr594",
  "Olfr599",
  "Olfr6",
  "Olfr60",
  "Olfr600",
  "Olfr601",
  "Olfr605",
  "Olfr606",
  "Olfr607",
  "Olfr608",
  "Olfr609",
  "Olfr61",
  "Olfr611",
  "Olfr612",
  "Olfr613",
  "Olfr615",
  "Olfr616",
  "Olfr617",
  "Olfr618",
  "Olfr619",
  "Olfr62",
  "Olfr620",
  "Olfr622",
  "Olfr623",
  "Olfr624",
  "Olfr625-ps1",
  "Olfr629",
  "Olfr63",
  "Olfr630",
  "Olfr631",
  "Olfr632",
  "Olfr633",
  "Olfr635",
  "Olfr638",
  "Olfr64",
  "Olfr640",
  "Olfr641",
  "Olfr642",
  "Olfr644",
  "Olfr645",
  "Olfr646",
  "Olfr648",
  "Olfr649",
  "Olfr65",
  "Olfr651",
  "Olfr652",
  "Olfr653",
  "Olfr654",
  "Olfr655",
  "Olfr656",
  "Olfr658",
  "Olfr659",
  "Olfr66",
  "Olfr661",
  "Olfr665",
  "Olfr666",
  "Olfr667",
  "Olfr669",
  "Olfr67",
  "Olfr670",
  "Olfr671",
  "Olfr672",
  "Olfr675",
  "Olfr676",
  "Olfr677",
  "Olfr678",
  "Olfr683",
  "Olfr684",
  "Olfr685",
  "Olfr686",
  "Olfr689",
  "Olfr69",
  "Olfr690",
  "Olfr691",
  "Olfr692",
  "Olfr693",
  "Olfr694",
  "Olfr695",
  "Olfr697",
  "Olfr698",
  "Olfr699",
  "Olfr70",
  "Olfr700",
  "Olfr701",
  "Olfr702",
  "Olfr703",
  "Olfr704",
  "Olfr705",
  "Olfr706",
  "Olfr707",
  "Olfr71",
  "Olfr710",
  "Olfr711",
  "Olfr713",
  "Olfr714",
  "Olfr715",
  "Olfr716",
  "Olfr720",
  "Olfr722",
  "Olfr723",
  "Olfr725",
  "Olfr726",
  "Olfr727",
  "Olfr728",
  "Olfr729",
  "Olfr73",
  "Olfr730",
  "Olfr731",
  "Olfr732",
  "Olfr733",
  "Olfr734",
  "Olfr735",
  "Olfr736",
  "Olfr738",
  "Olfr739",
  "Olfr74",
  "Olfr740",
  "Olfr741",
  "Olfr742",
  "Olfr743",
  "Olfr744",
  "Olfr745",
  "Olfr746",
  "Olfr747",
  "Olfr748",
  "Olfr749",
  "Olfr750",
  "Olfr76",
  "Olfr761",
  "Olfr763",
  "Olfr764-ps1",
  "Olfr766-ps1",
  "Olfr767",
  "Olfr768",
  "Olfr769",
  "Olfr77",
  "Olfr770",
  "Olfr771",
  "Olfr773",
  "Olfr774",
  "Olfr775",
  "Olfr78",
  "Olfr780",
  "Olfr781",
  "Olfr782",
  "Olfr784",
  "Olfr785",
  "Olfr786",
  "Olfr787",
  "Olfr788",
  "Olfr790",
  "Olfr791",
  "Olfr792",
  "Olfr794",
  "Olfr796",
  "Olfr798",
  "Olfr799",
  "Olfr8",
  "Olfr801",
  "Olfr802",
  "Olfr803",
  "Olfr804",
  "Olfr805",
  "Olfr806",
  "Olfr807",
  "Olfr808",
  "Olfr809",
  "Olfr810",
  "Olfr811",
  "Olfr812",
  "Olfr813",
  "Olfr815",
  "Olfr816",
  "Olfr818",
  "Olfr820",
  "Olfr821",
  "Olfr822",
  "Olfr825",
  "Olfr826",
  "Olfr827",
  "Olfr828",
  "Olfr829",
  "Olfr830",
  "Olfr832",
  "Olfr834",
  "Olfr835",
  "Olfr836",
  "Olfr837",
  "Olfr843",
  "Olfr844",
  "Olfr845",
  "Olfr846",
  "Olfr847",
  "Olfr849",
  "Olfr850",
  "Olfr851",
  "Olfr853",
  "Olfr854",
  "Olfr855",
  "Olfr857",
  "Olfr859",
  "Olfr860",
  "Olfr862",
  "Olfr866",
  "Olfr867",
  "Olfr868",
  "Olfr869",
  "Olfr870",
  "Olfr872",
  "Olfr873",
  "Olfr874",
  "Olfr875",
  "Olfr876",
  "Olfr877",
  "Olfr878",
  "Olfr881",
  "Olfr883",
  "Olfr884",
  "Olfr885",
  "Olfr887",
  "Olfr888",
  "Olfr889",
  "Olfr891",
  "Olfr893",
  "Olfr894",
  "Olfr895",
  "Olfr898",
  "Olfr9",
  "Olfr90",
  "Olfr901",
  "Olfr902",
  "Olfr904",
  "Olfr905",
  "Olfr906",
  "Olfr907",
  "Olfr91",
  "Olfr910",
  "Olfr911-ps1",
  "Olfr913",
  "Olfr914",
  "Olfr916",
  "Olfr917",
  "Olfr918",
  "Olfr92",
  "Olfr920",
  "Olfr921",
  "Olfr922",
  "Olfr923",
  "Olfr924",
  "Olfr926",
  "Olfr93",
  "Olfr930",
  "Olfr933",
  "Olfr934",
  "Olfr935",
  "Olfr937",
  "Olfr938",
  "Olfr94",
  "Olfr943",
  "Olfr944",
  "Olfr945",
  "Olfr948",
  "Olfr95",
  "Olfr951",
  "Olfr952",
  "Olfr954",
  "Olfr955",
  "Olfr957",
  "Olfr958",
  "Olfr959",
  "Olfr96",
  "Olfr960",
  "Olfr961",
  "Olfr963",
  "Olfr965",
  "Olfr967",
  "Olfr968",
  "Olfr969",
  "Olfr97",
  "Olfr970",
  "Olfr971",
  "Olfr972",
  "Olfr974",
  "Olfr975",
  "Olfr976",
  "Olfr978",
  "Olfr979",
  "Olfr98",
  "Olfr980",
  "Olfr981",
  "Olfr982",
  "Olfr983",
  "Olfr984",
  "Olfr985",
  "Olfr986",
  "Olfr987",
  "Olfr988",
  "Olfr99",
  "Olfr992",
  "Olfr993",
  "Olfr994",
  "Olfr995",
  "Olfr996",
  "Olfr998",
  "Olig1",
  "Olig2",
  "Olig3",
  "Olr1",
  "Oma1",
  "Omd",
  "Omg",
  "Omp",
  "Omt2a",
  "Omt2b",
  "Onecut1",
  "Onecut2",
  "Onecut3",
  "Ooep",
  "Oog2",
  "Oog3",
  "Oog4",
  "Oosp1",
  "Oosp2",
  "Oosp3",
  "Opa1",
  "Opa3",
  "Opalin",
  "Opcml",
  "Ophn1",
  "Oplah",
  "Opn1mw",
  "Opn1sw",
  "Opn3",
  "Opn4",
  "Opn5",
  "Oprd1",
  "Oprk1",
  "Oprl1",
  "Oprm1",
  "Optc",
  "Optn",
  "Orai1",
  "Orai2",
  "Orai3",
  "Orc1",
  "Orc2",
  "Orc3",
  "Orc4",
  "Orc5",
  "Orc6",
  "Orm1",
  "Orm2",
  "Orm3",
  "Ormdl1",
  "Ormdl2",
  "Ormdl3",
  "Os9",
  "Osbp",
  "Osbp2",
  "Osbpl10",
  "Osbpl11",
  "Osbpl1a",
  "Osbpl2",
  "Osbpl3",
  "Osbpl5",
  "Osbpl6",
  "Osbpl7",
  "Osbpl8",
  "Oscar",
  "Oscp1",
  "Oser1",
  "Osgep",
  "Osgepl1",
  "Osgin1",
  "Osgin2",
  "Osm",
  "Osmr",
  "Osr1",
  "Osr2",
  "Ost4",
  "Ostc",
  "Ostf1",
  "Ostm1",
  "Ostn",
  "Otc",
  "Otoa",
  "Otof",
  "Otog",
  "Otogl",
  "Otol1",
  "Otop1",
  "Otop2",
  "Otop3",
  "Otor",
  "Otos",
  "Otp",
  "Otub1",
  "Otub2",
  "Otud1",
  "Otud3",
  "Otud4",
  "Otud5",
  "Otud6a",
  "Otud6b",
  "Otud7a",
  "Otud7b",
  "Otulin",
  "Otulinl",
  "Otx1",
  "Otx2",
  "Otx2os1",
  "Ovca2",
  "Ovch2",
  "Ovgp1",
  "Ovol1",
  "Ovol3",
  "Oxa1l",
  "Oxct1",
  "Oxct2b",
  "Oxgr1",
  "Oxld1",
  "Oxnad1",
  "Oxr1",
  "Oxsm",
  "Oxsr1",
  "Oxt",
  "Oxtr",
  "P2rx1",
  "P2rx2",
  "P2rx3",
  "P2rx4",
  "P2rx5",
  "P2rx6",
  "P2rx7",
  "P2ry1",
  "P2ry10",
  "P2ry10b",
  "P2ry12",
  "P2ry13",
  "P2ry14",
  "P2ry2",
  "P2ry4",
  "P2ry6",
  "P3h1",
  "P3h2",
  "P3h3",
  "P3h4",
  "P4ha1",
  "P4ha2",
  "P4ha3",
  "P4hb",
  "P4htm",
  "Pa2g4",
  "Pabpc1l",
  "Pabpc2",
  "Pabpc4l",
  "Pabpc5",
  "Pabpc6",
  "Pabpn1l",
  "Pacc1",
  "Pacrg",
  "Pacs1",
  "Pacs2",
  "Pacsin1",
  "Pacsin2",
  "Pacsin3",
  "Padi1",
  "Padi2",
  "Padi3",
  "Padi4",
  "Padi6",
  "Paf1",
  "Pafah1b1",
  "Pafah1b2",
  "Pafah1b3",
  "Pag1",
  "Pah",
  "Paics",
  "Paip1",
  "Paip2",
  "Paip2b",
  "Pak1",
  "Pak1ip1",
  "Pak2",
  "Pak3",
  "Pak6",
  "Pak7",
  "Pakap",
  "Palb2",
  "Pald1",
  "Palld",
  "Palm",
  "Palm3",
  "Palmd",
  "Pam",
  "Pam16",
  "Pamr1",
  "Pan2",
  "Pan3",
  "Panct2",
  "Pank1",
  "Pank2",
  "Pank3",
  "Pank4",
  "Pantr1",
  "Panx1",
  "Panx2",
  "Panx3",
  "Paox",
  "Papln",
  "Papola",
  "Papolb",
  "Papolg",
  "Pappa",
  "Pappa2",
  "Papss1",
  "Papss2",
  "Paqr3",
  "Paqr4",
  "Paqr5",
  "Paqr6",
  "Paqr7",
  "Paqr8",
  "Paqr9",
  "Pard3",
  "Pard3b",
  "Pard3bos1",
  "Pard6a",
  "Pard6b",
  "Pard6g",
  "Parg",
  "Park7",
  "Parl",
  "Parm1",
  "Parn",
  "Parp1",
  "Parp10",
  "Parp11",
  "Parp12",
  "Parp14",
  "Parp16",
  "Parp2",
  "Parp3",
  "Parp4",
  "Parp6",
  "Parp8",
  "Parp9",
  "Parpbp",
  "Pars2",
  "Parva",
  "Parvb",
  "Parvg",
  "Pask",
  "Pate11",
  "Pate12",
  "Pate13",
  "Pate2",
  "Pate4",
  "Pate5",
  "Pate6",
  "Pate9",
  "Patj",
  "Patl1",
  "Patl2",
  "Patz1",
  "Pax1",
  "Pax2",
  "Pax3",
  "Pax4",
  "Pax5",
  "Pax6",
  "Pax6os1",
  "Pax7",
  "Pax8",
  "Pax9",
  "Paxbp1",
  "Paxip1",
  "Paxx",
  "Pbdc1",
  "Pbk",
  "Pbld1",
  "Pbld2",
  "Pbp2",
  "Pbrm1",
  "Pbsn",
  "Pbx1",
  "Pbx2",
  "Pbx3",
  "Pbx4",
  "Pbxip1",
  "Pcare",
  "Pcbd1",
  "Pcbd2",
  "Pcbp1",
  "Pcbp2",
  "Pcbp3",
  "Pcbp4",
  "Pcca",
  "Pccb",
  "Pcdh1",
  "Pcdh10",
  "Pcdh11x",
  "Pcdh12",
  "Pcdh15",
  "Pcdh17",
  "Pcdh18",
  "Pcdh19",
  "Pcdh20",
  "Pcdh7",
  "Pcdh8",
  "Pcdh9",
  "Pcdha11",
  "Pcdha12",
  "Pcdha8",
  "Pcdha9",
  "Pcdhac1",
  "Pcdhac2",
  "Pcdhb1",
  "Pcdhb10",
  "Pcdhb11",
  "Pcdhb12",
  "Pcdhb13",
  "Pcdhb14",
  "Pcdhb15",
  "Pcdhb16",
  "Pcdhb17",
  "Pcdhb18",
  "Pcdhb19",
  "Pcdhb2",
  "Pcdhb20",
  "Pcdhb21",
  "Pcdhb22",
  "Pcdhb3",
  "Pcdhb4",
  "Pcdhb5",
  "Pcdhb6",
  "Pcdhb7",
  "Pcdhb8",
  "Pcdhb9",
  "Pcdhga1",
  "Pcdhga10",
  "Pcdhga5",
  "Pcdhgc5",
  "Pced1a",
  "Pced1b",
  "Pcf11",
  "Pcgf1",
  "Pcgf2",
  "Pcgf3",
  "Pcgf5",
  "Pcgf6",
  "Pcid2",
  "Pcif1",
  "Pck1",
  "Pck2",
  "Pclaf",
  "Pclo",
  "Pcm1",
  "Pcmt1",
  "Pcmtd1",
  "Pcmtd2",
  "Pcna",
  "Pcnp",
  "Pcnt",
  "Pcnx",
  "Pcnx2",
  "Pcnx3",
  "Pcnx4",
  "Pcolce",
  "Pcolce2",
  "Pcp2",
  "Pcp4",
  "Pcp4l1",
  "Pcsk1",
  "Pcsk1n",
  "Pcsk2",
  "Pcsk4",
  "Pcsk5",
  "Pcsk6",
  "Pcsk7",
  "Pcsk9",
  "Pctp",
  "Pcx",
  "Pcyox1",
  "Pcyox1l",
  "Pcyt1a",
  "Pcyt1b",
  "Pcyt2",
  "Pdap1",
  "Pdc",
  "Pdcd1",
  "Pdcd10",
  "Pdcd11",
  "Pdcd1lg2",
  "Pdcd2",
  "Pdcd2l",
  "Pdcd5",
  "Pdcd6",
  "Pdcd6ip",
  "Pdcd7",
  "Pdcl",
  "Pdcl2",
  "Pdcl3",
  "Pde10a",
  "Pde11a",
  "Pde12",
  "Pde1a",
  "Pde1b",
  "Pde1c",
  "Pde3a",
  "Pde3b",
  "Pde4a",
  "Pde4b",
  "Pde4c",
  "Pde4d",
  "Pde4dip",
  "Pde5a",
  "Pde6a",
  "Pde6b",
  "Pde6c",
  "Pde6d",
  "Pde6g",
  "Pde6h",
  "Pde7a",
  "Pde7b",
  "Pde8a",
  "Pde8b",
  "Pde9a",
  "Pdgfa",
  "Pdgfb",
  "Pdgfc",
  "Pdgfd",
  "Pdgfra",
  "Pdgfrb",
  "Pdgfrl",
  "Pdha1",
  "Pdha2",
  "Pdhb",
  "Pdhx",
  "Pdia2",
  "Pdia3",
  "Pdia4",
  "Pdia5",
  "Pdia6",
  "Pdik1l",
  "Pdilt",
  "Pdk1",
  "Pdk2",
  "Pdk3",
  "Pdk4",
  "Pdlim1",
  "Pdlim2",
  "Pdlim3",
  "Pdlim4",
  "Pdlim5",
  "Pdlim7",
  "Pdp1",
  "Pdp2",
  "Pdpk1",
  "Pdpn",
  "Pdpr",
  "Pdrg1",
  "Pds5a",
  "Pds5b",
  "Pdss1",
  "Pdss2",
  "Pdx1",
  "Pdxdc1",
  "Pdxk",
  "Pdxp",
  "Pdyn",
  "Pdzd11",
  "Pdzd2",
  "Pdzd3",
  "Pdzd4",
  "Pdzd7",
  "Pdzd8",
  "Pdzd9",
  "Pdzk1",
  "Pdzk1ip1",
  "Pdzph1",
  "Pdzrn4",
  "Pea15a",
  "Peak1",
  "Pear1",
  "Pebp1",
  "Pebp4",
  "Pecam1",
  "Pecr",
  "Pef1",
  "Peg10",
  "Peg12",
  "Peg13",
  "Peg3",
  "Peli1",
  "Peli2",
  "Peli3",
  "Pelp1",
  "Pemt",
  "Penk",
  "Pepd",
  "Per1",
  "Per2",
  "Per3",
  "Perm1",
  "Pes1",
  "Pex1",
  "Pex10",
  "Pex11a",
  "Pex11b",
  "Pex11g",
  "Pex12",
  "Pex13",
  "Pex14",
  "Pex16",
  "Pex19",
  "Pex2",
  "Pex26",
  "Pex3",
  "Pex5",
  "Pex5l",
  "Pex6",
  "Pex7",
  "Pf4",
  "Pfas",
  "Pfdn1",
  "Pfdn2",
  "Pfdn4",
  "Pfdn6",
  "Pfkfb1",
  "Pfkfb2",
  "Pfkfb3",
  "Pfkfb4",
  "Pfkl",
  "Pfkm",
  "Pfkp",
  "Pfn1",
  "Pfn2",
  "Pfn3",
  "Pfn4",
  "Pfn5",
  "Pfpl",
  "Pga5",
  "Pgam1",
  "Pgam2",
  "Pgam5",
  "Pgap1",
  "Pgap2",
  "Pgbd5",
  "Pgc",
  "Pgd",
  "Pgf",
  "Pgghg",
  "Pggt1b",
  "Pgk1",
  "Pgk2",
  "Pgls",
  "Pglyrp1",
  "Pglyrp2",
  "Pglyrp3",
  "Pglyrp4",
  "Pgm1",
  "Pgm2",
  "Pgm2l1",
  "Pgm3",
  "Pgm5",
  "Pgp",
  "Pgpep1",
  "Pgpep1l",
  "Pgr",
  "Pgr15l",
  "Pgrmc1",
  "Pgrmc2",
  "Pgs1",
  "Phactr1",
  "Phactr2",
  "Phactr3",
  "Phactr4",
  "Phax",
  "Phb",
  "Phb2",
  "Phc1",
  "Phc2",
  "Phc3",
  "Pheta1",
  "Pheta2",
  "Phex",
  "Phf1",
  "Phf10",
  "Phf11",
  "Phf11a",
  "Phf11b",
  "Phf11c",
  "Phf11d",
  "Phf12",
  "Phf13",
  "Phf14",
  "Phf19",
  "Phf2",
  "Phf20",
  "Phf20l1",
  "Phf21a",
  "Phf21b",
  "Phf23",
  "Phf24",
  "Phf2os1",
  "Phf3",
  "Phf5a",
  "Phf6",
  "Phf7",
  "Phf8",
  "Phgdh",
  "Phgr1",
  "Phip",
  "Phka1",
  "Phka2",
  "Phkb",
  "Phkg1",
  "Phkg2",
  "Phlda1",
  "Phlda2",
  "Phlda3",
  "Phldb1",
  "Phldb2",
  "Phldb3",
  "Phlpp1",
  "Phlpp2",
  "Phospho2",
  "Phox2b",
  "Phpt1",
  "Phrf1",
  "Phtf1",
  "Phtf1os",
  "Phtf2",
  "Phxr2",
  "Phyh",
  "Phyhd1",
  "Phyhip",
  "Phyhipl",
  "Phykpl",
  "Pi15",
  "Pi16",
  "Pi4k2a",
  "Pi4k2b",
  "Pi4ka",
  "Pi4kb",
  "Pianp",
  "Pias1",
  "Pias2",
  "Pias3",
  "Pias4",
  "Pibf1",
  "Picalm",
  "Pick1",
  "Pid1",
  "Pidd1",
  "Piezo1",
  "Piezo2",
  "Pif1",
  "Pifo",
  "Piga",
  "Pigb",
  "Pigbos1",
  "Pigc",
  "Pigf",
  "Pigg",
  "Pigh",
  "Pigk",
  "Pigl",
  "Pigm",
  "Pign",
  "Pigo",
  "Pigp",
  "Pigq",
  "Pigr",
  "Pigs",
  "Pigt",
  "Pigu",
  "Pigv",
  "Pigw",
  "Pigx",
  "Pigyl",
  "Pigz",
  "Pih1d1",
  "Pih1d2",
  "Pih1d3",
  "Pih1h3b",
  "Pik3ap1",
  "Pik3c2a",
  "Pik3c2b",
  "Pik3c2g",
  "Pik3c3",
  "Pik3ca",
  "Pik3cb",
  "Pik3cd",
  "Pik3cg",
  "Pik3ip1",
  "Pik3r1",
  "Pik3r2",
  "Pik3r3",
  "Pik3r4",
  "Pik3r6",
  "Pikfyve",
  "Pilra",
  "Pilrb1",
  "Pilrb2",
  "Pim1",
  "Pim2",
  "Pim3",
  "Pimreg",
  "Pin1",
  "Pin1rt1",
  "Pinc",
  "Pink1",
  "Pinlyp",
  "Pinx1",
  "Pip",
  "Pip4k2a",
  "Pip4k2b",
  "Pip4k2c",
  "Pip4p1",
  "Pip4p2",
  "Pip5k1a",
  "Pip5k1b",
  "Pip5k1c",
  "Pip5kl1",
  "Pipox",
  "Pir",
  "Pirb",
  "Pirt",
  "Pisd",
  "Pithd1",
  "Pitpna",
  "Pitpnb",
  "Pitpnc1",
  "Pitpnm1",
  "Pitpnm2",
  "Pitpnm2os1",
  "Pitpnm3",
  "Pitrm1",
  "Pitx1",
  "Pitx2",
  "Pitx3",
  "Piwil1",
  "Piwil2",
  "Piwil4",
  "Pja1",
  "Pja2",
  "Pjvk",
  "Pkd1",
  "Pkd1l1",
  "Pkd1l2",
  "Pkd1l3",
  "Pkd2",
  "Pkd2l1",
  "Pkd2l2",
  "Pkdcc",
  "Pkdrej",
  "Pkhd1",
  "Pkhd1l1",
  "Pkia",
  "Pkib",
  "Pkig",
  "Pklr",
  "Pkm",
  "Pkmyt1",
  "Pkn1",
  "Pkn2",
  "Pkn3",
  "Pknox1",
  "Pknox2",
  "Pkp1",
  "Pkp2",
  "Pkp4",
  "Pla1a",
  "Pla2g10",
  "Pla2g12a",
  "Pla2g12b",
  "Pla2g15",
  "Pla2g1b",
  "Pla2g2c",
  "Pla2g2d",
  "Pla2g2e",
  "Pla2g2f",
  "Pla2g3",
  "Pla2g4a",
  "Pla2g4c",
  "Pla2g4d",
  "Pla2g4e",
  "Pla2g4f",
  "Pla2g5",
  "Pla2g6",
  "Pla2g7",
  "Pla2r1",
  "Plaa",
  "Plaat1",
  "Plaat3",
  "Plaat5",
  "Plac1",
  "Plac8",
  "Plac8l1",
  "Plag1",
  "Plagl1",
  "Plagl2",
  "Plat",
  "Platr10",
  "Platr14",
  "Platr20",
  "Platr21",
  "Platr22",
  "Platr23",
  "Platr25",
  "Platr26",
  "Platr28",
  "Platr29",
  "Platr4",
  "Platr5",
  "Platr6",
  "Platr8",
  "Plau",
  "Plaur",
  "Plb1",
  "Plbd1",
  "Plbd2",
  "Plcb1",
  "Plcb2",
  "Plcb3",
  "Plcb4",
  "Plcd1",
  "Plcd3",
  "Plcd4",
  "Plce1",
  "Plcg1",
  "Plcg2",
  "Plch1",
  "Plch2",
  "Plcl1",
  "Plcl2",
  "Plcxd1",
  "Plcxd2",
  "Plcxd3",
  "Plcz1",
  "Pld1",
  "Pld2",
  "Pld3",
  "Pld4",
  "Pld5",
  "Pld6",
  "Pldi",
  "Plec",
  "Plek",
  "Plek2",
  "Plekha1",
  "Plekha2",
  "Plekha3",
  "Plekha4",
  "Plekha5",
  "Plekha6",
  "Plekha7",
  "Plekha8",
  "Plekhb1",
  "Plekhb2",
  "Plekhd1",
  "Plekhd1os",
  "Plekhf1",
  "Plekhf2",
  "Plekhg1",
  "Plekhg2",
  "Plekhg4",
  "Plekhg5",
  "Plekhg6",
  "Plekhh1",
  "Plekhh2",
  "Plekhh3",
  "Plekhj1",
  "Plekhm1",
  "Plekhm2",
  "Plekhm3",
  "Plekhn1",
  "Plekho1",
  "Plekho2",
  "Plekhs1",
  "Plet1",
  "Plg",
  "Plgrkt",
  "Plin1",
  "Plin2",
  "Plin3",
  "Plin4",
  "Plin5",
  "Plk1",
  "Plk2",
  "Plk3",
  "Plk4",
  "Plk5",
  "Pllp",
  "Pln",
  "Plod1",
  "Plod2",
  "Plod3",
  "Plp1",
  "Plpbp",
  "Plpp1",
  "Plpp2",
  "Plpp3",
  "Plpp4",
  "Plpp5",
  "Plpp6",
  "Plpp7",
  "Plppr1",
  "Plppr2",
  "Plppr3",
  "Plppr4",
  "Plppr5",
  "Plrg1",
  "Pls1",
  "Pls3",
  "Plscr1",
  "Plscr2",
  "Plscr3",
  "Plscr4",
  "Plscr5",
  "Pltp",
  "Plut",
  "Plvap",
  "Plxdc1",
  "Plxdc2",
  "Plxna1",
  "Plxna2",
  "Plxna3",
  "Plxna4",
  "Plxna4os1",
  "Plxnb1",
  "Plxnb2",
  "Plxnb3",
  "Plxnc1",
  "Plxnd1",
  "Pm20d1",
  "Pm20d2",
  "Pmaip1",
  "Pmch",
  "Pmel",
  "Pmepa1",
  "Pmf1",
  "Pmfbp1",
  "Pmis2",
  "Pml",
  "Pmm1",
  "Pmm2",
  "Pmp22",
  "Pmpca",
  "Pmpcb",
  "Pms1",
  "Pms2",
  "Pmvk",
  "Pnck",
  "Pnisr",
  "Pnkd",
  "Pnkp",
  "Pnldc1",
  "Pnlip",
  "Pnliprp1",
  "Pnliprp2",
  "Pnma1",
  "Pnma2",
  "Pnma3",
  "Pnma5",
  "Pnmal1",
  "Pnmal2",
  "Pnmt",
  "Pnn",
  "Pno1",
  "Pnoc",
  "Pnp",
  "Pnp2",
  "Pnpla1",
  "Pnpla2",
  "Pnpla5",
  "Pnpla6",
  "Pnpla7",
  "Pnpla8",
  "Pnpo",
  "Pnpt1",
  "Pnrc1",
  "Pnrc2",
  "Poc1a",
  "Poc1b",
  "Poc5",
  "Podn",
  "Podnl1",
  "Podxl",
  "Podxl2",
  "Pof1b",
  "Pofut1",
  "Pofut2",
  "Pogk",
  "Poglut1",
  "Poglut2",
  "Poglut3",
  "Pogz",
  "Pola1",
  "Pola2",
  "Polb",
  "Pold1",
  "Pold2",
  "Pold3",
  "Poldip2",
  "Poldip3",
  "Pole",
  "Pole2",
  "Pole3",
  "Polg",
  "Polg2",
  "Polh",
  "Poli",
  "Polk",
  "Poll",
  "Polm",
  "Poln",
  "Polq",
  "Polr1a",
  "Polr1b",
  "Polr1c",
  "Polr1d",
  "Polr1e",
  "Polr2a",
  "Polr2b",
  "Polr2c",
  "Polr2d",
  "Polr2e",
  "Polr2f",
  "Polr2g",
  "Polr2h",
  "Polr2i",
  "Polr2j",
  "Polr2k",
  "Polr2l",
  "Polr2m",
  "Polr3a",
  "Polr3b",
  "Polr3c",
  "Polr3d",
  "Polr3e",
  "Polr3f",
  "Polr3g",
  "Polr3gl",
  "Polr3h",
  "Polr3k",
  "Polrmt",
  "Pom121",
  "Pom121l12",
  "Pom121l2",
  "Pomc",
  "Pomgnt1",
  "Pomgnt2",
  "Pomk",
  "Pomp",
  "Pomt1",
  "Pomt2",
  "Pon1",
  "Pon2",
  "Pon3",
  "Pop1",
  "Pop4",
  "Pop5",
  "Pop7",
  "Popdc2",
  "Popdc3",
  "Por",
  "Porcn",
  "Postn",
  "Pot1a",
  "Pot1b",
  "Potegl",
  "Pou1f1",
  "Pou2af1",
  "Pou2f1",
  "Pou2f2",
  "Pou2f3",
  "Pou3f1",
  "Pou3f2",
  "Pou3f3",
  "Pou3f4",
  "Pou4f1",
  "Pou4f2",
  "Pou4f3",
  "Pou5f1",
  "Pou5f2",
  "Pou6f1",
  "Pou6f2",
  "Pp2d1",
  "Ppa1",
  "Ppa2",
  "Ppan",
  "Ppara",
  "Ppard",
  "Pparg",
  "Ppargc1a",
  "Ppargc1b",
  "Ppat",
  "Ppbp",
  "Ppcdc",
  "Ppcs",
  "Ppdpf",
  "Ppef1",
  "Ppef2",
  "Ppfia1",
  "Ppfia2",
  "Ppfia3",
  "Ppfia4",
  "Ppfibp1",
  "Ppfibp2",
  "Pphln1",
  "Ppia",
  "Ppib",
  "Ppic",
  "Ppid",
  "Ppie",
  "Ppif",
  "Ppifos",
  "Ppig",
  "Ppih",
  "Ppil1",
  "Ppil2",
  "Ppil3",
  "Ppil4",
  "Ppil6",
  "Ppip5k1",
  "Ppip5k2",
  "Ppl",
  "Ppm1a",
  "Ppm1b",
  "Ppm1d",
  "Ppm1e",
  "Ppm1f",
  "Ppm1g",
  "Ppm1h",
  "Ppm1j",
  "Ppm1k",
  "Ppm1l",
  "Ppm1m",
  "Ppm1n",
  "Ppme1",
  "Ppox",
  "Ppp1ca",
  "Ppp1cb",
  "Ppp1cc",
  "Ppp1r10",
  "Ppp1r11",
  "Ppp1r12a",
  "Ppp1r12b",
  "Ppp1r12c",
  "Ppp1r13b",
  "Ppp1r13l",
  "Ppp1r14a",
  "Ppp1r14b",
  "Ppp1r14bl",
  "Ppp1r14d",
  "Ppp1r15a",
  "Ppp1r15b",
  "Ppp1r16a",
  "Ppp1r16b",
  "Ppp1r17",
  "Ppp1r18",
  "Ppp1r1a",
  "Ppp1r1b",
  "Ppp1r1c",
  "Ppp1r2",
  "Ppp1r21",
  "Ppp1r26",
  "Ppp1r27",
  "Ppp1r32",
  "Ppp1r35",
  "Ppp1r36",
  "Ppp1r37",
  "Ppp1r3a",
  "Ppp1r3b",
  "Ppp1r3c",
  "Ppp1r3d",
  "Ppp1r3e",
  "Ppp1r3g",
  "Ppp1r42",
  "Ppp1r7",
  "Ppp1r8",
  "Ppp1r9a",
  "Ppp1r9b",
  "Ppp2ca",
  "Ppp2r1a",
  "Ppp2r1b",
  "Ppp2r2a",
  "Ppp2r2b",
  "Ppp2r2c",
  "Ppp2r2cos",
  "Ppp2r2d",
  "Ppp2r3a",
  "Ppp2r3c",
  "Ppp2r3d",
  "Ppp2r5a",
  "Ppp2r5b",
  "Ppp2r5c",
  "Ppp2r5d",
  "Ppp3ca",
  "Ppp3cb",
  "Ppp3cc",
  "Ppp3r1",
  "Ppp3r2",
  "Ppp4c",
  "Ppp4r1",
  "Ppp4r2",
  "Ppp4r3a",
  "Ppp4r3b",
  "Ppp4r4",
  "Ppp5c",
  "Ppp6c",
  "Ppp6r1",
  "Ppp6r2",
  "Ppp6r3",
  "Pprc1",
  "Ppt1",
  "Ppt2",
  "Pptc7",
  "Ppwd1",
  "Ppy",
  "Pqbp1",
  "Pqlc2",
  "Pqlc3",
  "Pradc1",
  "Praf2",
  "Prag1",
  "Prame",
  "Pramef12",
  "Pramef12os",
  "Pramef17",
  "Pramef20",
  "Pramef8",
  "Pramel1",
  "Pramel5",
  "Pramel6",
  "Pramel7",
  "Prap1",
  "Prb1",
  "Prc1",
  "Prcc",
  "Prcd",
  "Prcp",
  "Prdm1",
  "Prdm10",
  "Prdm11",
  "Prdm12",
  "Prdm13",
  "Prdm14",
  "Prdm15",
  "Prdm16",
  "Prdm16os",
  "Prdm2",
  "Prdm4",
  "Prdm5",
  "Prdm6",
  "Prdm8",
  "Prdm9",
  "Prdx1",
  "Prdx2",
  "Prdx3",
  "Prdx4",
  "Prdx5",
  "Prdx6",
  "Prdx6b",
  "Preb",
  "Prelid1",
  "Prelid2",
  "Prelid3a",
  "Prelid3b",
  "Prep",
  "Prepl",
  "Prex1",
  "Prex2",
  "Prf1",
  "Prg2",
  "Prg3",
  "Prg4",
  "Prh1",
  "Prickle1",
  "Prickle2",
  "Prickle3",
  "Prim1",
  "Prim2",
  "Prima1",
  "Primpol",
  "Prkaa1",
  "Prkaa2",
  "Prkab1",
  "Prkab2",
  "Prkaca",
  "Prkacb",
  "Prkag1",
  "Prkag2os1",
  "Prkag3",
  "Prkar1a",
  "Prkar1b",
  "Prkar2a",
  "Prkar2b",
  "Prkca",
  "Prkcb",
  "Prkcd",
  "Prkce",
  "Prkcg",
  "Prkch",
  "Prkci",
  "Prkcq",
  "Prkcsh",
  "Prkcz",
  "Prkd1",
  "Prkd2",
  "Prkd3",
  "Prkdc",
  "Prkg1",
  "Prkg2",
  "Prkn",
  "Prkra",
  "Prkrip1",
  "Prkx",
  "Prl",
  "Prl2a1",
  "Prl2b1",
  "Prl2c1",
  "Prl3a1",
  "Prl3b1",
  "Prl3c1",
  "Prl3d1",
  "Prl4a1",
  "Prl5a1",
  "Prl6a1",
  "Prl7a1",
  "Prl7a2",
  "Prl7b1",
  "Prl7c1",
  "Prl7d1",
  "Prl8a1",
  "Prl8a2",
  "Prl8a6",
  "Prl8a8",
  "Prl8a9",
  "Prlh",
  "Prlhr",
  "Prlr",
  "Prm1",
  "Prm2",
  "Prm3",
  "Prmt1",
  "Prmt2",
  "Prmt3",
  "Prmt5",
  "Prmt6",
  "Prmt7",
  "Prmt8",
  "Prmt9",
  "Prnp",
  "Prob1",
  "Proc",
  "Proca1",
  "Procr",
  "Prodh",
  "Prodh2",
  "Prok1",
  "Prok2",
  "Prokr1",
  "Prokr2",
  "Prol1",
  "Prom1",
  "Prom2",
  "Prop1",
  "Prorp",
  "Prorsd1",
  "Pros1",
  "Proser1",
  "Proser2",
  "Prox1",
  "Prox2",
  "Proz",
  "Prp2",
  "Prpf18",
  "Prpf19",
  "Prpf3",
  "Prpf31",
  "Prpf38a",
  "Prpf38b",
  "Prpf39",
  "Prpf4",
  "Prpf40a",
  "Prpf40b",
  "Prpf4b",
  "Prpf6",
  "Prpf8",
  "Prph",
  "Prph2",
  "Prpmp5",
  "Prps1",
  "Prps1l1",
  "Prps1l3",
  "Prps2",
  "Prpsap1",
  "Prpsap2",
  "Prr11",
  "Prr12",
  "Prr13",
  "Prr14",
  "Prr14l",
  "Prr15",
  "Prr15l",
  "Prr16",
  "Prr18",
  "Prr19",
  "Prr22",
  "Prr23a3",
  "Prr27",
  "Prr29",
  "Prr3",
  "Prr30",
  "Prr32",
  "Prr33",
  "Prr36",
  "Prr5",
  "Prr5l",
  "Prr7",
  "Prr9",
  "Prrc1",
  "Prrc2a",
  "Prrc2b",
  "Prrc2c",
  "Prrg1",
  "Prrg2",
  "Prrg3",
  "Prrg4",
  "Prrt1",
  "Prrt3",
  "Prrt4",
  "Prrx1",
  "Prrx2",
  "Prrxl1",
  "Prss1",
  "Prss12",
  "Prss16",
  "Prss2",
  "Prss21",
  "Prss22",
  "Prss23",
  "Prss23os",
  "Prss27",
  "Prss28",
  "Prss29",
  "Prss30",
  "Prss32",
  "Prss33",
  "Prss34",
  "Prss35",
  "Prss36",
  "Prss37",
  "Prss38",
  "Prss39",
  "Prss40",
  "Prss41",
  "Prss42",
  "Prss43",
  "Prss44",
  "Prss45",
  "Prss46",
  "Prss47",
  "Prss48",
  "Prss50",
  "Prss51",
  "Prss52",
  "Prss53",
  "Prss54",
  "Prss55",
  "Prss56",
  "Prss57",
  "Prss58",
  "Prss8",
  "Prtg",
  "Prtn3",
  "Prune1",
  "Prune2",
  "Prx",
  "Prxl2a",
  "Prxl2b",
  "Prxl2c",
  "Psap",
  "Psapl1",
  "Psat1",
  "Psca",
  "Psd",
  "Psd3",
  "Psd4",
  "Psen1",
  "Psen2",
  "Psenen",
  "Psg16",
  "Psg17",
  "Psg19",
  "Psg20",
  "Psg21",
  "Psg22",
  "Psg23",
  "Psg25",
  "Psg26",
  "Psg27",
  "Psg28",
  "Psg29",
  "Psip1",
  "Pskh1",
  "Psma1",
  "Psma2",
  "Psma3",
  "Psma4",
  "Psma6",
  "Psma7",
  "Psma8",
  "Psmb1",
  "Psmb10",
  "Psmb11",
  "Psmb2",
  "Psmb3",
  "Psmb4",
  "Psmb5",
  "Psmb6",
  "Psmb7",
  "Psmb8",
  "Psmb9",
  "Psmc1",
  "Psmc2",
  "Psmc3",
  "Psmc3ip",
  "Psmc4",
  "Psmc5",
  "Psmc6",
  "Psmd10",
  "Psmd12",
  "Psmd13",
  "Psmd14",
  "Psmd2",
  "Psmd3",
  "Psmd4",
  "Psmd5",
  "Psmd6",
  "Psmd7",
  "Psmd8",
  "Psmd9",
  "Psme1",
  "Psme2b",
  "Psme4",
  "Psmf1",
  "Psmg1",
  "Psmg2",
  "Psmg3",
  "Psmg4",
  "Psors1c2",
  "Pspc1",
  "Psph",
  "Pspn",
  "Psrc1",
  "Pstk",
  "Pstpip1",
  "Pstpip2",
  "Ptafr",
  "Ptar1",
  "Ptbp1",
  "Ptbp2",
  "Ptbp3",
  "Ptcd1",
  "Ptcd2",
  "Ptcd3",
  "Ptch1",
  "Ptch2",
  "Ptchd1",
  "Ptchd3",
  "Ptchd4",
  "Ptcra",
  "Ptdss1",
  "Ptdss2",
  "Pten",
  "Pter",
  "Ptf1a",
  "Ptgdr",
  "Ptgdr2",
  "Ptgds",
  "Ptger1",
  "Ptger2",
  "Ptger3",
  "Ptger4",
  "Ptges",
  "Ptges2",
  "Ptges3",
  "Ptges3l",
  "Ptgfr",
  "Ptgfrn",
  "Ptgir",
  "Ptgis",
  "Ptgr1",
  "Ptgr2",
  "Ptgs1",
  "Ptgs2",
  "Pth",
  "Pth1r",
  "Pth2",
  "Pth2r",
  "Pthlh",
  "Ptk2",
  "Ptk2b",
  "Ptk6",
  "Ptk7",
  "Ptma",
  "Ptms",
  "Ptn",
  "Ptov1",
  "Ptp4a2",
  "Ptp4a3",
  "Ptpa",
  "Ptpdc1",
  "Ptpmt1",
  "Ptpn1",
  "Ptpn11",
  "Ptpn12",
  "Ptpn13",
  "Ptpn14",
  "Ptpn18",
  "Ptpn2",
  "Ptpn20",
  "Ptpn21",
  "Ptpn22",
  "Ptpn23",
  "Ptpn3",
  "Ptpn4",
  "Ptpn5",
  "Ptpn6",
  "Ptpn7",
  "Ptpn9",
  "Ptpra",
  "Ptprb",
  "Ptprc",
  "Ptprcap",
  "Ptprd",
  "Ptpre",
  "Ptprg",
  "Ptprh",
  "Ptprj",
  "Ptprk",
  "Ptprm",
  "Ptprn",
  "Ptprn2",
  "Ptpro",
  "Ptprq",
  "Ptprr",
  "Ptprs",
  "Ptprt",
  "Ptpru",
  "Ptprv",
  "Ptprz1",
  "Ptrh1",
  "Ptrh2",
  "Ptrhd1",
  "Pts",
  "Pttg1",
  "Pttg1ip",
  "Ptx3",
  "Ptx4",
  "Pudp",
  "Puf60",
  "Pum1",
  "Pum2",
  "Pum3",
  "Pura",
  "Purb",
  "Purg",
  "Pus1",
  "Pus10",
  "Pus3",
  "Pus7",
  "Pus7l",
  "Pusl1",
  "Pvalb",
  "Pvr",
  "Pvt1",
  "Pwp1",
  "Pwp2",
  "Pwwp2a",
  "Pwwp2b",
  "Pwwp3a",
  "Pwwp3b",
  "Pxdc1",
  "Pxdn",
  "Pxk",
  "Pxmp2",
  "Pxmp4",
  "Pxn",
  "Pxt1",
  "Pxylp1",
  "Pycard",
  "Pycr1",
  "Pycr2",
  "Pycrl",
  "Pygb",
  "Pygl",
  "Pygm",
  "Pygo1",
  "Pygo2",
  "Pym1",
  "Pyroxd1",
  "Pyroxd2",
  "Pyurf",
  "Pyy",
  "Pzp",
  "Qars",
  "Qdpr",
  "Qk",
  "Qpct",
  "Qpctl",
  "Qprt",
  "Qrfp",
  "Qrfpr",
  "Qrfprl",
  "Qrich1",
  "Qrich2",
  "Qrsl1",
  "Qser1",
  "Qsox1",
  "Qsox2",
  "Qtrt1",
  "Qtrt2",
  "R3hcc1",
  "R3hcc1l",
  "R3hdm1",
  "R3hdm2",
  "R3hdm4",
  "R3hdml",
  "R74862",
  "Rab10",
  "Rab10os",
  "Rab11a",
  "Rab11b",
  "Rab11fip1",
  "Rab11fip2",
  "Rab11fip3",
  "Rab11fip4",
  "Rab11fip4os1",
  "Rab11fip5",
  "Rab12",
  "Rab13",
  "Rab14",
  "Rab15",
  "Rab17",
  "Rab18",
  "Rab19",
  "Rab1a",
  "Rab1b",
  "Rab20",
  "Rab21",
  "Rab22a",
  "Rab23",
  "Rab24",
  "Rab25",
  "Rab26",
  "Rab27a",
  "Rab27b",
  "Rab28",
  "Rab29",
  "Rab2a",
  "Rab2b",
  "Rab30",
  "Rab31",
  "Rab32",
  "Rab33a",
  "Rab33b",
  "Rab34",
  "Rab35",
  "Rab36",
  "Rab37",
  "Rab38",
  "Rab39",
  "Rab39b",
  "Rab3a",
  "Rab3b",
  "Rab3c",
  "Rab3d",
  "Rab3gap1",
  "Rab3gap2",
  "Rab3il1",
  "Rab3ip",
  "Rab40b",
  "Rab40c",
  "Rab42",
  "Rab44",
  "Rab4a",
  "Rab5a",
  "Rab5b",
  "Rab5c",
  "Rab5if",
  "Rab6a",
  "Rab6b",
  "Rab7",
  "Rab7b",
  "Rab8a",
  "Rab8b",
  "Rab9",
  "Rab9b",
  "Rabac1",
  "Rabep1",
  "Rabep2",
  "Rabepk",
  "Rabgap1",
  "Rabgap1l",
  "Rabgef1",
  "Rabggta",
  "Rabggtb",
  "Rabif",
  "Rabl2",
  "Rabl3",
  "Rabl6",
  "Rac1",
  "Rac2",
  "Rac3",
  "Racgap1",
  "Rack1",
  "Rad1",
  "Rad17",
  "Rad18",
  "Rad21",
  "Rad21l",
  "Rad23a",
  "Rad23b",
  "Rad50",
  "Rad51",
  "Rad51ap1",
  "Rad51ap2",
  "Rad51b",
  "Rad51c",
  "Rad51d",
  "Rad52",
  "Rad54b",
  "Rad54l",
  "Rad54l2",
  "Rad9a",
  "Rad9b",
  "Radil",
  "Radx",
  "Rae1",
  "Raet1e",
  "Raf1",
  "Rag1",
  "Rag2",
  "Rai1",
  "Rai14",
  "Rai2",
  "Rala",
  "Ralb",
  "Ralbp1",
  "Ralgapa1",
  "Ralgapa2",
  "Ralgapb",
  "Ralgds",
  "Ralgps1",
  "Ralgps2",
  "Raly",
  "Ralyl",
  "Ramp1",
  "Ramp2",
  "Ramp3",
  "Ran",
  "Ranbp1",
  "Ranbp10",
  "Ranbp17",
  "Ranbp2",
  "Ranbp3",
  "Ranbp3l",
  "Ranbp6",
  "Ranbp9",
  "Rangap1",
  "Rap1a",
  "Rap1b",
  "Rap1gap",
  "Rap1gap2",
  "Rap1gds1",
  "Rap2a",
  "Rap2b",
  "Rap2c",
  "Rapgef1",
  "Rapgef2",
  "Rapgef3",
  "Rapgef4",
  "Rapgef5",
  "Rapgef6",
  "Rapgefl1",
  "Raph1",
  "Rapsn",
  "Rara",
  "Rarb",
  "Rarg",
  "Rarres1",
  "Rarres2",
  "Rars",
  "Rars2",
  "Rasa1",
  "Rasa2",
  "Rasa3",
  "Rasa4",
  "Rasal1",
  "Rasal2",
  "Rasal3",
  "Rasd1",
  "Rasd2",
  "Rasef",
  "Rasgef1a",
  "Rasgef1b",
  "Rasgef1c",
  "Rasgrf1",
  "Rasgrf2",
  "Rasgrp1",
  "Rasgrp2",
  "Rasgrp3",
  "Rasgrp4",
  "Rasip1",
  "Rasl10a",
  "Rasl10b",
  "Rasl11a",
  "Rasl11b",
  "Rasl12",
  "Rasl2-9",
  "Rassf1",
  "Rassf10",
  "Rassf2",
  "Rassf3",
  "Rassf4",
  "Rassf5",
  "Rassf6",
  "Rassf7",
  "Rassf8",
  "Rassf9",
  "Raver2",
  "Rax",
  "Rb1",
  "Rb1cc1",
  "Rbak",
  "Rbakdn",
  "Rbbp4",
  "Rbbp5",
  "Rbbp6",
  "Rbbp7",
  "Rbbp8",
  "Rbbp8nl",
  "Rbbp9",
  "Rbck1",
  "Rbfa",
  "Rbfox1",
  "Rbfox2",
  "Rbfox3",
  "Rbks",
  "Rbl1",
  "Rbl2",
  "Rbm10",
  "Rbm11",
  "Rbm12",
  "Rbm12b1",
  "Rbm12b2",
  "Rbm14",
  "Rbm15",
  "Rbm15b",
  "Rbm17",
  "Rbm18",
  "Rbm19",
  "Rbm20",
  "Rbm22",
  "Rbm24",
  "Rbm25",
  "Rbm26",
  "Rbm28",
  "Rbm3",
  "Rbm33",
  "Rbm34",
  "Rbm38",
  "Rbm39",
  "Rbm3os",
  "Rbm41",
  "Rbm43",
  "Rbm44",
  "Rbm45",
  "Rbm46",
  "Rbm46os",
  "Rbm47",
  "Rbm48",
  "Rbm4b",
  "Rbm5",
  "Rbm6",
  "Rbm7",
  "Rbms1",
  "Rbms3",
  "Rbmx",
  "Rbmx2",
  "Rbmxl2",
  "Rbp1",
  "Rbp2",
  "Rbp3",
  "Rbp4",
  "Rbp7",
  "Rbpj",
  "Rbpjl",
  "Rbpms",
  "Rbpms2",
  "Rbsn",

  "Rbx1",
  "Rc3h1",
  "Rc3h2",
  "Rcan1",
  "Rcan2",
  "Rcan3",
  "Rcbtb1",
  "Rcbtb2",
  "Rcc1",
  "Rcc1l",
  "Rcc2",
  "Rccd1",
  "Rce1",
  "Rchy1",
  "Rcl1",
  "Rcn1",
  "Rcn2",
  "Rcn3",
  "Rcor1",
  "Rcor2",
  "Rcor3",
  "Rcsd1",
  "Rcvrn",
  "Rd3",
  "Rd3l",
  "Rdh1",
  "Rdh10",
  "Rdh11",
  "Rdh12",
  "Rdh13",
  "Rdh14",
  "Rdh16",
  "Rdh16f2",
  "Rdh19",
  "Rdh7",
  "Rdm1",
  "Rdx",
  "Rec114",
  "Rec8",
  "Reck",
  "Recql",
  "Recql4",
  "Recql5",
  "Redrum",
  "Reep1",
  "Reep2",
  "Reep3",
  "Reep4",
  "Reep5",
  "Reep6",
  "Reg1",
  "Reg2",
  "Reg3a",
  "Reg3b",
  "Reg3d",
  "Reg3g",
  "Reg4",
  "Rel",
  "Rela",
  "Relb",
  "Relch",
  "Rell1",
  "Rell2",
  "Reln",
  "Relt",
  "Rem1",
  "Rem2",
  "Ren1",
  "Renbp",
  "Rep15",
  "Repin1",
  "Reps1",
  "Reps2",
  "Rer1",
  "Rere",
  "Rerg",
  "Rergl",
  "Resf1",
  "Resp18",
  "Rest",
  "Ret",
  "Retn",
  "Retnla",
  "Retnlb",
  "Retnlg",
  "Retreg1",
  "Retreg2",
  "Retreg3",
  "Retsat",
  "Rev1",
  "Rev3l",
  "Rex1bd",
  "Rexo1",
  "Rexo2",
  "Rexo4",
  "Rexo5",
  "Rfc1",
  "Rfc2",
  "Rfc3",
  "Rfc4",
  "Rfc5",
  "Rfesd",
  "Rffl",
  "Rfk",
  "Rflna",
  "Rflnb",
  "Rfng",
  "Rfpl4",
  "Rfpl4b",
  "Rft1",
  "Rftn1",
  "Rftn2",
  "Rfwd3",
  "Rfx1",
  "Rfx2",
  "Rfx3",
  "Rfx4",
  "Rfx5",
  "Rfx6",
  "Rfx7",
  "Rfx8",
  "Rfxank",
  "Rfxap",
  "Rgl1",
  "Rgl2",
  "Rgl3",
  "Rgma",
  "Rgmb",
  "Rgn",
  "Rgp1",
  "Rgr",
  "Rgs1",
  "Rgs10",
  "Rgs11",
  "Rgs12",
  "Rgs13",
  "Rgs14",
  "Rgs16",
  "Rgs17",
  "Rgs18",
  "Rgs19",
  "Rgs2",
  "Rgs20",
  "Rgs21",
  "Rgs22",
  "Rgs3",
  "Rgs4",
  "Rgs5",
  "Rgs6",
  "Rgs7",
  "Rgs7bp",
  "Rgs8",
  "Rgs9",
  "Rgs9bp",
  "Rgsl1",
  "Rhag",
  "Rhbdd2",
  "Rhbdd3",
  "Rhbdf1",
  "Rhbdf2",
  "Rhbdl2",
  "Rhbdl3",
  "Rhbg",
  "Rhcg",
  "Rhd",
  "Rheb",
  "Rhebl1",
  "Rhno1",
  "Rho",
  "Rhoa",
  "Rhob",
  "Rhobtb2",
  "Rhobtb3",
  "Rhoc",
  "Rhod",
  "Rhof",
  "Rhog",
  "Rhoh",
  "Rhoj",
  "Rhoq",
  "Rhot1",
  "Rhot2",
  "Rhou",
  "Rhov",
  "Rhox1",
  "Rhox10",
  "Rhox11",
  "Rhox12",
  "Rhox13",
  "Rhox2h",
  "Rhox5",
  "Rhox6",
  "Rhox8",
  "Rhox9",
  "Rhpn1",
  "Rhpn2",
  "Rian",
  "Ribc1",
  "Ribc2",
  "Ric1",
  "Ric3",
  "Ric8a",
  "Ric8b",
  "Rictor",
  "Rida",
  "Rif1",
  "Riiad1",
  "Rilp",
  "Rilpl1",
  "Rilpl2",
  "Rimbp2",
  "Rimbp3",
  "Rimkla",
  "Rimklb",
  "Rims1",
  "Rims2",
  "Rims3",
  "Rims4",
  "Rin1",
  "Rin2",
  "Rin3",
  "Ring1",
  "Rinl",
  "Riok1",
  "Riok2",
  "Riok3",
  "Riox1",
  "Riox2",
  "Ripk1",
  "Ripk2",
  "Ripk3",
  "Ripk4",
  "Ripor1",
  "Ripor2",
  "Ripor3",
  "Ripply1",
  "Ripply2",
  "Ripply3",
  "Rit1",
  "Rit2",
  "Rita1",
  "Rlbp1",
  "Rlf",
  "Rlim",
  "Rln1",
  "Rln3",
  "Rmc1",
  "Rmdn1",
  "Rmdn2",
  "Rmdn3",
  "Rmi1",
  "Rmi2",
  "Rmnd1",
  "Rmnd5a",
  "Rmnd5b",
  "Rmst",
  "Rnase1",
  "Rnase10",
  "Rnase11",
  "Rnase12",
  "Rnase13",
  "Rnase2a",
  "Rnase2b",
  "Rnase4",
  "Rnase6",
  "Rnase9",
  "Rnaseh1",
  "Rnaseh2a",
  "Rnaseh2b",
  "Rnaseh2c",
  "Rnasel",
  "Rnaset2b",
  "Rnd1",
  "Rnd2",
  "Rnd3",
  "Rnf10",
  "Rnf103",
  "Rnf11",
  "Rnf111",
  "Rnf112",
  "Rnf113a1",
  "Rnf113a2",
  "Rnf114",
  "Rnf115",
  "Rnf121",
  "Rnf122",
  "Rnf123",
  "Rnf125",
  "Rnf126",
  "Rnf128",
  "Rnf13",
  "Rnf130",
  "Rnf133",
  "Rnf135",
  "Rnf138",
  "Rnf138rt1",
  "Rnf139",
  "Rnf14",
  "Rnf141",
  "Rnf144a",
  "Rnf144b",
  "Rnf145",
  "Rnf146",
  "Rnf148",
  "Rnf150",
  "Rnf151",
  "Rnf152",
  "Rnf157",
  "Rnf165",
  "Rnf166",
  "Rnf167",
  "Rnf168",
  "Rnf169",
  "Rnf17",
  "Rnf170",
  "Rnf180",
  "Rnf181",
  "Rnf182",
  "Rnf183",
  "Rnf185",
  "Rnf186",
  "Rnf187",
  "Rnf19a",
  "Rnf19b",
  "Rnf2",
  "Rnf20",
  "Rnf207",
  "Rnf208",
  "Rnf212",
  "Rnf212b",
  "Rnf213",
  "Rnf214",
  "Rnf215",
  "Rnf216",
  "Rnf217",
  "Rnf219",
  "Rnf220",
  "Rnf222",
  "Rnf224",
  "Rnf225",
  "Rnf227",
  "Rnf24",
  "Rnf25",
  "Rnf31",
  "Rnf32",
  "Rnf34",
  "Rnf38",
  "Rnf39",
  "Rnf4",
  "Rnf40",
  "Rnf41",
  "Rnf43",
  "Rnf44",
  "Rnf5",
  "Rnf6",
  "Rnf7",
  "Rnf8",
  "Rnft1",
  "Rnft2",
  "Rngtt",
  "Rnh1",
  "Rnmt",
  "Rnpc3",
  "Rnpep",
  "Rnpepl1",
  "Rnps1",
  "Ro60",
  "Robo1",
  "Robo2",
  "Robo3",
  "Robo4",
  "Rock1",
  "Rock2",
  "Rogdi",
  "Rom1",
  "Romo1",
  "Ropn1",
  "Ropn1l",
  "Ror1",
  "Ror2",
  "Rora",
  "Rorb",
  "Rorc",
  "Ros1",
  "Rp1",
  "Rp1l1",
  "Rp2",
  "Rp9",
  "Rpa1",
  "Rpa2",
  "Rpa3",
  "Rpain",
  "Rpap1",
  "Rpap2",
  "Rpap3",
  "Rpe",
  "Rpe65",
  "Rpf1",
  "Rpf2",
  "Rpgr",
  "Rpgrip1",
  "Rpgrip1l",
  "Rph3a",
  "Rph3al",
  "Rpia",
  "Rpl10a",
  "Rpl10l",
  "Rpl11",
  "Rpl13",
  "Rpl13a",
  "Rpl14",
  "Rpl15",
  "Rpl17",
  "Rpl18a",
  "Rpl21",
  "Rpl22",
  "Rpl22l1",
  "Rpl23",
  "Rpl24",
  "Rpl26",
  "Rpl27",
  "Rpl27a",
  "Rpl28",
  "Rpl29",
  "Rpl30",
  "Rpl31",
  "Rpl32",
  "Rpl35a",
  "Rpl36al",
  "Rpl37a",
  "Rpl38",
  "Rpl39",
  "Rpl39l",
  "Rpl3l",
  "Rpl4",
  "Rpl6",
  "Rpl7",
  "Rpl7l1",
  "Rpl8",
  "Rplp0",
  "Rpn1",
  "Rpn2",
  "Rpp14",
  "Rpp21",
  "Rpp25",
  "Rpp25l",
  "Rpp30",
  "Rpp38",
  "Rpp40",
  "Rprd1a",
  "Rprd1b",
  "Rprd2",
  "Rprm",
  "Rprml",
  "Rps10",
  "Rps12",
  "Rps14",
  "Rps15a",
  "Rps16",
  "Rps18-ps4",
  "Rps19bp1",
  "Rps20",
  "Rps21",
  "Rps23rg1",
  "Rps24",
  "Rps25",
  "Rps27a",
  "Rps27l",
  "Rps27rt",
  "Rps28",
  "Rps29",
  "Rps3",
  "Rps3a1",
  "Rps5",
  "Rps6ka1",
  "Rps6ka2",
  "Rps6ka3",
  "Rps6ka5",
  "Rps6ka6",
  "Rps6kb1",
  "Rps6kb2",
  "Rps6kc1",
  "Rps6kl1",
  "Rps8",
  "Rpsa",
  "Rptn",
  "Rptor",
  "Rpusd1",
  "Rpusd2",
  "Rpusd3",
  "Rpusd4",
  "Rrad",
  "Rraga",
  "Rragb",
  "Rragc",
  "Rragd",
  "Rras",
  "Rras2",
  "Rrbp1",
  "Rreb1",
  "Rrh",
  "Rrm1",
  "Rrm2",
  "Rrm2b",
  "Rrn3",
  "Rrnad1",
  "Rrp1",
  "Rrp12",
  "Rrp15",
  "Rrp1b",
  "Rrp36",
  "Rrp7a",
  "Rrp8",
  "Rrp9",
  "Rrs1",
  "Rs1",
  "Rsad1",
  "Rsad2",
  "Rsbn1",
  "Rsbn1l",
  "Rsf1",
  "Rsf1os2",
  "Rskr",
  "Rsl1",
  "Rsl1d1",
  "Rsl24d1",
  "Rslcan18",
  "Rsph1",
  "Rsph10b",
  "Rsph14",
  "Rsph4a",
  "Rsph6a",
  "Rsph9",
  "Rspo1",
  "Rspo2",
  "Rspo3",
  "Rspo4",
  "Rspry1",
  "Rsrc1",
  "Rsrc2",
  "Rsrp1",
  "Rsu1",
  "Rtbdn",
  "Rtcb",
  "Rtel1",
  "Rtf1",
  "Rtf2",
  "Rtkn",
  "Rtkn2",
  "Rtl1",
  "Rtl3",
  "Rtl4",
  "Rtl5",
  "Rtl6",
  "Rtl8a",
  "Rtl8c",
  "Rtn1",
  "Rtn2",
  "Rtn3",
  "Rtn4",
  "Rtn4ip1",
  "Rtn4r",
  "Rtn4rl1",
  "Rtn4rl2",
  "Rtp1",
  "Rtp2",
  "Rtp3",
  "Rtp4",
  "Rtraf",
  "Rttn",
  "Rubcn",
  "Rubcnl",
  "Rubie",
  "Rufy1",
  "Rufy2",
  "Rufy3",
  "Rufy4",
  "Rundc1",
  "Rundc3a",
  "Rundc3b",
  "Runx1",
  "Runx1t1",
  "Runx2",
  "Runx2os1",
  "Runx3",
  "Rusc1",
  "Rusc2",
  "Ruvbl1",
  "Ruvbl2",
  "Rwdd1",
  "Rwdd2a",
  "Rwdd2b",
  "Rwdd3",
  "Rwdd4a",
  "Rxfp1",
  "Rxfp2",
  "Rxfp3",
  "Rxfp4",
  "Rxra",
  "Rxrb",
  "Rxrg",
  "Rxylt1",
  "Rybp",
  "Ryk",
  "Ryr1",
  "Ryr2",
  "Ryr3",
  "S100a1",
  "S100a10",
  "S100a11",
  "S100a13",
  "S100a14",
  "S100a16",
  "S100a2",
  "S100a3",
  "S100a4",
  "S100a5",
  "S100a6",
  "S100a7a",
  "S100a7l2",
  "S100a8",
  "S100a9",
  "S100b",
  "S100g",
  "S100pbp",
  "S100z",
  "S1pr1",
  "S1pr2",
  "S1pr3",
  "S1pr4",
  "S1pr5",
  "Saa1",
  "Saa2",
  "Saa3",
  "Saa4",
  "Saal1",
  "Sac3d1",
  "Sacm1l",
  "Sacs",
  "Sae1",
  "Safb",
  "Safb2",
  "Sag",
  "Sall1",
  "Sall2",
  "Sall3",
  "Sall4",
  "Samd1",
  "Samd10",
  "Samd11",
  "Samd12",
  "Samd13",
  "Samd14",
  "Samd15",
  "Samd3",
  "Samd4",
  "Samd4b",
  "Samd5",
  "Samd7",
  "Samd8",
  "Samd9l",
  "Samhd1",
  "Samm50",
  "Samsn1",
  "Samt2b",
  "Samt3",
  "Samt4",
  "Sap130",
  "Sap18",
  "Sap30",
  "Sap30bp",
  "Sap30l",
  "Sapcd1",
  "Sapcd2",
  "Sar1a",
  "Sar1b",
  "Saraf",
  "Sardh",
  "Sarm1",
  "Sarnp",
  "Sars",
  "Sars2",
  "Sart1",
  "Sart3",
  "Sash1",
  "Sash3",
  "Sass6",
  "Sat1",
  "Sat2",
  "Satb1",
  "Satb2",
  "Satl1",
  "Sav1",
  "Saxo1",
  "Saxo2",
  "Saysd1",
  "Sbds",
  "Sbf1",
  "Sbf2",
  "Sbk1",
  "Sbk3",
  "Sbno1",
  "Sbno2",
  "Sbp",
  "Sbpl",
  "Sbspon",
  "Sc5d",
  "Scaf1",
  "Scaf11",
  "Scaf4",
  "Scaf8",
  "Scai",
  "Scamp1",
  "Scamp2",
  "Scamp3",
  "Scamp5",
  "Scand1",
  "Scap",
  "Scaper",
  "Scara3",
  "Scara5",
  "Scarb1",
  "Scarb2",
  "Scarf1",
  "Scarf2",
  "Scarletltr",
  "Sccpdh",
  "Scd1",
  "Scd2",
  "Scd3",
  "Scd4",
  "Scfd1",
  "Scfd2",
  "Scg2",
  "Scg3",
  "Scg5",
  "Scgb1a1",
  "Scgb1b2",
  "Scgb1b20",
  "Scgb1b27",
  "Scgb1b3",
  "Scgb1b30",
  "Scgb1c1",
  "Scgb2a2",
  "Scgb2b2",
  "Scgb2b21",
  "Scgb2b24",
  "Scgb2b26",
  "Scgb2b27",
  "Scgb2b3",
  "Scgb3a1",
  "Scgb3a2",
  "Scgn",
  "Scimp",
  "Scin",
  "Sclt1",
  "Scly",
  "Scmh1",
  "Scml2",
  "Scml4",
  "Scn10a",
  "Scn11a",
  "Scn1a",
  "Scn1b",
  "Scn2a",
  "Scn2b",
  "Scn3a",
  "Scn3b",
  "Scn4a",
  "Scn4b",
  "Scn5a",
  "Scn7a",
  "Scn8a",
  "Scn9a",
  "Scnm1",
  "Scnn1a",
  "Scnn1b",
  "Scnn1g",
  "Sco1",
  "Sco2",
  "Scoc",
  "Scp2d1",
  "Scpep1",
  "Scrg1",
  "Scrib",
  "Scrn1",
  "Scrn2",
  "Scrn3",
  "Scrt1",
  "Scrt2",
  "Sct",
  "Sctr",
  "Scube1",
  "Scube2",
  "Scube3",
  "Scx",
  "Scyl1",
  "Scyl2",
  "Scyl3",
  "Sdad1",
  "Sdc1",
  "Sdc2",
  "Sdc3",
  "Sdc4",
  "Sdcbp",
  "Sdcbp2",
  "Sdccag8",
  "Sde2",
  "Sdf2",
  "Sdf2l1",
  "Sdf4",
  "Sdha",
  "Sdhaf1",
  "Sdhaf2",
  "Sdhaf3",
  "Sdhaf4",
  "Sdhb",
  "Sdhc",
  "Sdhd",
  "Sdk1",
  "Sdk2",
  "Sdr16c5",
  "Sdr16c6",
  "Sdr39u1",
  "Sdr42e1",
  "Sdr9c7",
  "Sds",
  "Sdsl",
  "Sebox",
  "Sec1",
  "Sec11a",
  "Sec11c",
  "Sec13",
  "Sec14l1",
  "Sec14l2",
  "Sec14l3",
  "Sec14l4",
  "Sec14l5",
  "Sec16a",
  "Sec16b",
  "Sec22a",
  "Sec22b",
  "Sec22c",
  "Sec23a",
  "Sec23b",
  "Sec23ip",
  "Sec24a",
  "Sec24b",
  "Sec24c",
  "Sec24d",
  "Sec31a",
  "Sec31b",
  "Sec61a1",
  "Sec61a2",
  "Sec61b",
  "Sec62",
  "Sec63",
  "Secisbp2",
  "Secisbp2l",
  "Sectm1a",
  "Sectm1b",
  "Seh1l",
  "Sel1l",
  "Sel1l2",
  "Sel1l3",
  "Sele",
  "Selenbp1",
  "Selenof",
  "Selenoh",
  "Selenoi",
  "Selenok",
  "Selenok-ps1",
  "Selenom",
  "Selenon",
  "Selenoo",
  "Selenop",
  "Selenos",
  "Selenot",
  "Selenov",
  "Selenow",
  "Sell",
  "Selp",
  "Selplg",
  "Sem1",
  "Sema3a",
  "Sema3b",
  "Sema3c",
  "Sema3d",
  "Sema3f",
  "Sema3g",
  "Sema4a",
  "Sema4b",
  "Sema4c",
  "Sema4d",
  "Sema4f",
  "Sema4g",
  "Sema5a",
  "Sema5b",
  "Sema6a",
  "Sema6b",
  "Sema6c",
  "Sema6d",
  "Sema7a",
  "Senp1",
  "Senp2",
  "Senp3",
  "Senp5",
  "Senp6",
  "Senp7",
  "Senp8",
  "Sephs1",
  "Sephs2",
  "Sepsecs",
  "Septin1",
  "Septin10",
  "Septin11",
  "Septin12",
  "Septin14",
  "Septin3",
  "Septin4",
  "Septin6",
  "Septin7",
  "Septin8",
  "Septin9",
  "Serac1",
  "Serbp1",
  "Serf1",
  "Serf2",
  "Sergef",
  "Serhl",
  "Serinc1",
  "Serinc2",
  "Serinc3",
  "Serinc4",
  "Serinc5",
  "Serp1",
  "Serp2",
  "Serpina10",
  "Serpina11",
  "Serpina12",
  "Serpina16",
  "Serpina1d",
  "Serpina1e",
  "Serpina1f",
  "Serpina3a",
  "Serpina3b",
  "Serpina3c",
  "Serpina3f",
  "Serpina3g",
  "Serpina3i",
  "Serpina3j",
  "Serpina3k",
  "Serpina3m",
  "Serpina3n",
  "Serpina5",
  "Serpina6",
  "Serpina7",
  "Serpina9",
  "Serpinb11",
  "Serpinb12",
  "Serpinb13",
  "Serpinb1a",
  "Serpinb1b",
  "Serpinb1c",
  "Serpinb2",
  "Serpinb3a",
  "Serpinb3b",
  "Serpinb3c",
  "Serpinb3d",
  "Serpinb5",
  "Serpinb6a",
  "Serpinb6b",
  "Serpinb6c",
  "Serpinb6e",
  "Serpinb7",
  "Serpinb9",
  "Serpinb9b",
  "Serpinb9c",
  "Serpinb9e",
  "Serpinb9g",
  "Serpinc1",
  "Serpind1",
  "Serpine1",
  "Serpine2",
  "Serpinf1",
  "Serpinf2",
  "Serping1",
  "Serpinh1",
  "Serpini1",
  "Serpini2",
  "Sertad1",
  "Sertad2",
  "Sertad3",
  "Sertad4",
  "Sertm1",
  "Sesn1",
  "Sesn2",
  "Sesn3",
  "Sestd1",
  "Set",
  "Setbp1",
  "Setd1a",
  "Setd1b",
  "Setd2",
  "Setd3",
  "Setd4",
  "Setd5",
  "Setd6",
  "Setd7",
  "Setdb1",
  "Setdb2",
  "Setmar",
  "Setx",
  "Sez6",
  "Sez6l2",
  "Sf1",
  "Sf3a1",
  "Sf3a2",
  "Sf3a3",
  "Sf3b1",
  "Sf3b2",
  "Sf3b3",
  "Sf3b4",
  "Sf3b5",
  "Sf3b6",
  "Sfi1",
  "Sfmbt1",
  "Sfmbt2",
  "Sfpq",
  "Sfr1",
  "Sfrp1",
  "Sfrp2",
  "Sfrp4",
  "Sfrp5",
  "Sfswap",
  "Sft2d2",
  "Sft2d3",
  "Sfta2",
  "Sfta3-ps",
  "Sftpa1",
  "Sftpb",
  "Sftpc",
  "Sftpd",
  "Sfxn1",
  "Sfxn2",
  "Sfxn3",
  "Sfxn4",
  "Sfxn5",
  "Sgca",
  "Sgcb",
  "Sgcd",
  "Sgce",
  "Sgcg",
  "Sgcz",
  "Sgf29",
  "Sgip1",
  "Sgk1",
  "Sgk2",
  "Sgk3",
  "Sgms1",
  "Sgms2",
  "Sgo1",
  "Sgo2a",
  "Sgo2b",
  "Sgpl1",
  "Sgpp1",
  "Sgpp2",
  "Sgsh",
  "Sgsm1",
  "Sgsm2",
  "Sgsm3",
  "Sgta",
  "Sgtb",
  "Sh2b1",
  "Sh2b2",
  "Sh2b3",
  "Sh2d1a",
  "Sh2d1b1",
  "Sh2d1b2",
  "Sh2d2a",
  "Sh2d3c",
  "Sh2d4a",
  "Sh2d4b",
  "Sh2d5",
  "Sh2d6",
  "Sh2d7",
  "Sh3bgr",
  "Sh3bgrl",
  "Sh3bgrl2",
  "Sh3bgrl3",
  "Sh3bp1",
  "Sh3bp2",
  "Sh3bp4",
  "Sh3bp5",
  "Sh3bp5l",
  "Sh3d19",
  "Sh3d21",
  "Sh3gl1",
  "Sh3gl2",
  "Sh3gl3",
  "Sh3glb1",
  "Sh3glb2",
  "Sh3kbp1",
  "Sh3pxd2a",
  "Sh3pxd2b",
  "Sh3rf1",
  "Sh3rf2",
  "Sh3rf3",
  "Sh3tc1",
  "Sh3tc2",
  "Sh3yl1",
  "Shank1",
  "Shank2",
  "Shank3",
  "Sharpin",
  "Shb",
  "Shbg",
  "Shc1",
  "Shc2",
  "Shc3",
  "Shc4",
  "Shcbp1",
  "Shcbp1l",
  "Shd",
  "She",
  "Shf",
  "Shfl",
  "Shh",
  "Shisa2",
  "Shisa3",
  "Shisa4",
  "Shisa5",
  "Shisa6",
  "Shisa7",
  "Shisa8",
  "Shisa9",
  "Shisal1",
  "Shisal2a",
  "Shisal2b",
  "Shkbp1",
  "Shld1",
  "Shld2",
  "Shmt1",
  "Shmt2",
  "Shoc1",
  "Shoc2",
  "Shox2",
  "Shpk",
  "Shprh",
  "Shq1",
  "Shroom1",
  "Shroom2",
  "Shroom4",
  "Shtn1",
  "Siae",
  "Siah1a",
  "Siah3",
  "Sidt1",
  "Sidt2",
  "Sigirr",
  "Siglec1",
  "Siglec15",
  "Siglece",
  "Siglecf",
  "Siglecg",
  "Siglech",
  "Sigmar1",
  "Sik1",
  "Sik2",
  "Sik3",
  "Sike1",
  "Sil1",
  "Sim1",
  "Sim2",
  "Simc1",
  "Sin3a",
  "Sin3b",
  "Sipa1",
  "Sipa1l1",
  "Sipa1l2",
  "Sipa1l3",
  "Sirpa",
  "Sirpb1a",
  "Sirt1",
  "Sirt2",
  "Sirt3",
  "Sirt4",
  "Sirt5",
  "Sirt6",
  "Sirt7",
  "Sis",
  "Sit1",
  "Siva1",
  "Six1",
  "Six2",
  "Six3",
  "Six3os1",
  "Six4",
  "Six5",
  "Six6",
  "Ska1",
  "Ska2",
  "Ska3",
  "Skap1",
  "Skap2",
  "Ski",
  "Skida1",
  "Skil",
  "Skint1",
  "Skint10",
  "Skint11",
  "Skint2",
  "Skint3",
  "Skint4",
  "Skint5",
  "Skint6",
  "Skint7",
  "Skint8",
  "Skint9",
  "Skiv2l",
  "Skor1",
  "Skor2",
  "Skp1a",
  "Skp2",
  "Sla",
  "Sla2",
  "Slain1",
  "Slain1os",
  "Slain2",
  "Slamf1",
  "Slamf6",
  "Slamf7",
  "Slamf8",
  "Slamf9",
  "Slbp",
  "Slc10a1",
  "Slc10a2",
  "Slc10a3",
  "Slc10a4",
  "Slc10a4l",
  "Slc10a5",
  "Slc10a6",
  "Slc10a7",
  "Slc11a1",
  "Slc11a2",
  "Slc12a1",
  "Slc12a2",
  "Slc12a3",
  "Slc12a4",
  "Slc12a5",
  "Slc12a6",
  "Slc12a7",
  "Slc12a8",
  "Slc12a9",
  "Slc13a1",
  "Slc13a2",
  "Slc13a2os",
  "Slc13a3",
  "Slc13a4",
  "Slc13a5",
  "Slc14a1",
  "Slc14a2",
  "Slc15a1",
  "Slc15a2",
  "Slc15a3",
  "Slc15a4",
  "Slc15a5",
  "Slc16a1",
  "Slc16a10",
  "Slc16a11",
  "Slc16a12",
  "Slc16a13",
  "Slc16a14",
  "Slc16a3",
  "Slc16a4",
  "Slc16a5",
  "Slc16a6",
  "Slc16a7",
  "Slc16a8",
  "Slc16a9",
  "Slc17a1",
  "Slc17a2",
  "Slc17a3",
  "Slc17a4",
  "Slc17a5",
  "Slc17a6",
  "Slc17a7",
  "Slc17a8",
  "Slc17a9",
  "Slc18a1",
  "Slc18a2",
  "Slc18a3",
  "Slc18b1",
  "Slc19a1",
  "Slc19a2",
  "Slc19a3",
  "Slc1a1",
  "Slc1a2",
  "Slc1a3",
  "Slc1a4",
  "Slc1a5",
  "Slc1a6",
  "Slc1a7",
  "Slc20a1",
  "Slc20a2",
  "Slc22a1",
  "Slc22a12",
  "Slc22a13",
  "Slc22a14",
  "Slc22a15",
  "Slc22a16",
  "Slc22a17",
  "Slc22a18",
  "Slc22a19",
  "Slc22a2",
  "Slc22a20",
  "Slc22a21",
  "Slc22a22",
  "Slc22a23",
  "Slc22a26",
  "Slc22a27",
  "Slc22a28",
  "Slc22a29",
  "Slc22a3",
  "Slc22a30",
  "Slc22a4",
  "Slc22a6",
  "Slc22a7",
  "Slc22a8",
  "Slc23a1",
  "Slc23a2",
  "Slc23a3",
  "Slc23a4",
  "Slc24a1",
  "Slc24a2",
  "Slc24a3",
  "Slc24a4",
  "Slc24a5",
  "Slc25a1",
  "Slc25a10",
  "Slc25a11",
  "Slc25a12",
  "Slc25a13",
  "Slc25a14",
  "Slc25a15",
  "Slc25a16",
  "Slc25a17",
  "Slc25a18",
  "Slc25a19",
  "Slc25a2",
  "Slc25a20",
  "Slc25a21",
  "Slc25a22",
  "Slc25a23",
  "Slc25a24",
  "Slc25a25",
  "Slc25a26",
  "Slc25a27",
  "Slc25a28",
  "Slc25a29",
  "Slc25a3",
  "Slc25a30",
  "Slc25a31",
  "Slc25a32",
  "Slc25a33",
  "Slc25a34",
  "Slc25a35",
  "Slc25a36",
  "Slc25a37",
  "Slc25a38",
  "Slc25a39",
  "Slc25a4",
  "Slc25a40",
  "Slc25a41",
  "Slc25a42",
  "Slc25a43",
  "Slc25a44",
  "Slc25a45",
  "Slc25a46",
  "Slc25a47",
  "Slc25a48",
  "Slc25a51",
  "Slc25a53",
  "Slc25a54",
  "Slc26a1",
  "Slc26a10",
  "Slc26a11",
  "Slc26a2",
  "Slc26a3",
  "Slc26a4",
  "Slc26a5",
  "Slc26a6",
  "Slc26a7",
  "Slc26a8",
  "Slc26a9",
  "Slc27a1",
  "Slc27a2",
  "Slc27a3",
  "Slc27a4",
  "Slc27a5",
  "Slc28a1",
  "Slc28a2",
  "Slc28a2b",
  "Slc28a3",
  "Slc29a1",
  "Slc29a2",
  "Slc29a3",
  "Slc29a4",
  "Slc2a1",
  "Slc2a10",
  "Slc2a12",
  "Slc2a13",
  "Slc2a2",
  "Slc2a3",
  "Slc2a4",
  "Slc2a5",
  "Slc2a6",
  "Slc2a7",
  "Slc2a8",
  "Slc2a9",
  "Slc30a1",
  "Slc30a10",
  "Slc30a2",
  "Slc30a3",
  "Slc30a4",
  "Slc30a5",
  "Slc30a6",
  "Slc30a7",
  "Slc30a8",
  "Slc30a9",
  "Slc31a1",
  "Slc31a2",
  "Slc32a1",
  "Slc33a1",
  "Slc34a1",
  "Slc34a2",
  "Slc34a3",
  "Slc35a1",
  "Slc35a2",
  "Slc35a3",
  "Slc35a4",
  "Slc35a5",
  "Slc35b1",
  "Slc35b2",
  "Slc35b3",
  "Slc35b4",
  "Slc35c1",
  "Slc35c2",
  "Slc35d1",
  "Slc35d2",
  "Slc35d3",
  "Slc35e1",
  "Slc35e2",
  "Slc35e3",
  "Slc35e4",
  "Slc35f1",
  "Slc35f2",
  "Slc35f3",
  "Slc35f4",
  "Slc35f5",
  "Slc35f6",
  "Slc35g1",
  "Slc35g2",
  "Slc35g3",
  "Slc36a1",
  "Slc36a1os",
  "Slc36a2",
  "Slc36a3",
  "Slc36a4",
  "Slc37a1",
  "Slc37a2",
  "Slc37a3",
  "Slc37a4",
  "Slc38a1",
  "Slc38a10",
  "Slc38a11",
  "Slc38a2",
  "Slc38a3",
  "Slc38a4",
  "Slc38a5",
  "Slc38a6",
  "Slc38a7",
  "Slc38a8",
  "Slc38a9",
  "Slc39a1",
  "Slc39a10",
  "Slc39a11",
  "Slc39a12",
  "Slc39a13",
  "Slc39a14",
  "Slc39a2",
  "Slc39a3",
  "Slc39a4",
  "Slc39a5",
  "Slc39a6",
  "Slc39a8",
  "Slc39a9",
  "Slc3a1",
  "Slc3a2",
  "Slc40a1",
  "Slc41a1",
  "Slc41a2",
  "Slc41a3",
  "Slc43a1",
  "Slc43a2",
  "Slc43a3",
  "Slc44a1",
  "Slc44a2",
  "Slc44a3",
  "Slc44a4",
  "Slc44a5",
  "Slc45a1",
  "Slc45a2",
  "Slc45a3",
  "Slc45a4",
  "Slc46a1",
  "Slc46a2",
  "Slc46a3",
  "Slc47a1",
  "Slc47a2",
  "Slc48a1",
  "Slc49a4",
  "Slc4a1",
  "Slc4a10",
  "Slc4a11",
  "Slc4a1ap",
  "Slc4a2",
  "Slc4a3",
  "Slc4a4",
  "Slc4a5",
  "Slc4a7",
  "Slc4a8",
  "Slc4a9",
  "Slc50a1",
  "Slc51a",
  "Slc51b",
  "Slc52a2",
  "Slc52a3",
  "Slc5a1",
  "Slc5a10",
  "Slc5a11",
  "Slc5a12",
  "Slc5a2",
  "Slc5a3",
  "Slc5a4a",
  "Slc5a4b",
  "Slc5a5",
  "Slc5a6",
  "Slc5a7",
  "Slc5a8",
  "Slc5a9",
  "Slc66a2",
  "Slc6a1",
  "Slc6a11",
  "Slc6a12",
  "Slc6a13",
  "Slc6a14",
  "Slc6a15",
  "Slc6a16",
  "Slc6a17",
  "Slc6a18",
  "Slc6a19",
  "Slc6a19os",
  "Slc6a2",
  "Slc6a20a",
  "Slc6a20b",
  "Slc6a21",
  "Slc6a3",
  "Slc6a4",
  "Slc6a5",
  "Slc6a6",
  "Slc6a7",
  "Slc6a8",
  "Slc6a9",
  "Slc7a1",
  "Slc7a10",
  "Slc7a11",
  "Slc7a12",
  "Slc7a13",
  "Slc7a14",
  "Slc7a15",
  "Slc7a2",
  "Slc7a3",
  "Slc7a4",
  "Slc7a5",
  "Slc7a6",
  "Slc7a6os",
  "Slc7a7",
  "Slc7a8",
  "Slc7a9",
  "Slc8a1",
  "Slc8a2",
  "Slc8a3",
  "Slc8b1",
  "Slc9a1",
  "Slc9a2",
  "Slc9a3",
  "Slc9a3r1",
  "Slc9a3r2",
  "Slc9a5",
  "Slc9a6",
  "Slc9a7",
  "Slc9a8",
  "Slc9a9",
  "Slc9b1",
  "Slc9b2",
  "Slc9c1",
  "Slco1a1",
  "Slco1a4",
  "Slco1a5",
  "Slco1a6",
  "Slco1b2",
  "Slco1c1",
  "Slco2a1",
  "Slco2b1",
  "Slco3a1",
  "Slco4a1",
  "Slco4c1",
  "Slco5a1",
  "Slco6c1",
  "Slco6d1",
  "Slf1",
  "Slf2",
  "Slfn1",
  "Slfn14",
  "Slfn2",
  "Slfn3",
  "Slfn4",
  "Slfn5",
  "Slfn5os",
  "Slfn8",
  "Slfn9",
  "Slfnl1",
  "Slirp",
  "Slit1",
  "Slit2",
  "Slit3",
  "Slitrk1",
  "Slitrk2",
  "Slitrk3",
  "Slitrk4",
  "Slitrk5",
  "Slitrk6",
  "Slk",
  "Slmap",
  "Sln",
  "Slpi",
  "Sltm",
  "Slu7",
  "Slurp1",
  "Slurp2",
  "Slx1b",
  "Slx4",
  "Slx4ip",
  "Smad1",
  "Smad2",
  "Smad3",
  "Smad4",
  "Smad5",
  "Smad6",
  "Smad7",
  "Smad9",
  "Smagp",
  "Smap1",
  "Smap2",
  "Smarca1",
  "Smarca2",
  "Smarca4",
  "Smarca5",
  "Smarcad1",
  "Smarcal1",
  "Smarcb1",
  "Smarcc1",
  "Smarcc2",
  "Smarcd1",
  "Smarcd2",
  "Smarcd3",
  "Smarce1",
  "Smc1a",
  "Smc1b",
  "Smc2",
  "Smc2os",
  "Smc3",
  "Smc4",
  "Smc5",
  "Smc6",
  "Smchd1",
  "Smco1",
  "Smco2",
  "Smco3",
  "Smco4",
  "Smcp",
  "Smcr8",
  "Smdt1",
  "Smg1",
  "Smg5",
  "Smg6",
  "Smg7",
  "Smg8",
  "Smg9",
  "Smgc",
  "Smim1",
  "Smim10l1",
  "Smim10l2a",
  "Smim11",
  "Smim12",
  "Smim13",
  "Smim14",
  "Smim15",
  "Smim17",
  "Smim18",
  "Smim19",
  "Smim20",
  "Smim22",
  "Smim23",
  "Smim24",
  "Smim26",
  "Smim27",
  "Smim3",
  "Smim31",
  "Smim38",
  "Smim4",
  "Smim40",
  "Smim41",
  "Smim5",
  "Smim6",
  "Smim7",
  "Smim8",
  "Smim9",
  "Smlr1",
  "Smn1",
  "Smndc1",
  "Smo",
  "Smoc1",
  "Smoc2",
  "Smok2a",
  "Smok3a",
  "Smox",
  "Smpd1",
  "Smpd2",
  "Smpd3",
  "Smpd4",
  "Smpd5",
  "Smpdl3a",
  "Smpdl3b",
  "Smpx",
  "Smr2",
  "Smr3a",
  "Sms",
  "Smtn",
  "Smtnl1",
  "Smtnl2",
  "Smu1",
  "Smug1",
  "Smurf1",
  "Smurf2",
  "Smyd1",
  "Smyd2",
  "Smyd3",
  "Smyd4",
  "Smyd5",
  "Snai1",
  "Snai2",
  "Snai3",
  "Snap23",
  "Snap25",
  "Snap29",
  "Snap47",
  "Snap91",
  "Snapc1",
  "Snapc2",
  "Snapc3",
  "Snapc4",
  "Snapc5",
  "Snapin",
  "Snca",
  "Sncaip",
  "Sncb",
  "Sncg",
  "Snd1",
  "Sned1",
  "Snf8",
  "Snhg1",
  "Snhg10",
  "Snhg11",
  "Snhg12",
  "Snhg14",
  "Snhg15",
  "Snhg16",
  "Snhg17",
  "Snhg18",
  "Snhg20",
  "Snhg3",
  "Snhg5",
  "Snhg6",
  "Snhg7os",
  "Snip1",
  "Snn",
  "Snorc",
  "Snph",
  "Snrk",
  "Snrnp200",
  "Snrnp25",
  "Snrnp27",
  "Snrnp35",
  "Snrnp40",
  "Snrnp48",
  "Snrnp70",
  "Snrpa",
  "Snrpa1",
  "Snrpb",
  "Snrpb2",
  "Snrpc",
  "Snrpd1",
  "Snrpd2",
  "Snrpd3",
  "Snrpe",
  "Snrpf",
  "Snrpg",
  "Snrpn",
  "Snta1",
  "Sntb1",
  "Sntb2",
  "Sntg1",
  "Sntg2",
  "Sntn",
  "Snu13",
  "Snupn",
  "Snw1",
  "Snx1",
  "Snx10",
  "Snx11",
  "Snx12",
  "Snx13",
  "Snx14",
  "Snx15",
  "Snx16",
  "Snx17",
  "Snx19",
  "Snx2",
  "Snx20",
  "Snx21",
  "Snx22",
  "Snx24",
  "Snx25",
  "Snx27",
  "Snx29",
  "Snx3",
  "Snx30",
  "Snx31",
  "Snx32",
  "Snx33",
  "Snx4",
  "Snx5",
  "Snx6",
  "Snx7",
  "Snx8",
  "Snx9",
  "Soat1",
  "Soat2",
  "Sobp",
  "Socs1",
  "Socs2",
  "Socs3",
  "Socs4",
  "Socs5",
  "Socs6",
  "Socs7",
  "Sod1",
  "Sod2",
  "Sod3",
  "Soga1",
  "Soga3",
  "Sohlh1",
  "Sohlh2",
  "Son",
  "Sorbs2",
  "Sorbs2os",
  "Sorbs3",
  "Sorcs1",
  "Sorcs2",
  "Sorcs3",
  "Sord",
  "Sorl1",
  "Sort1",
  "Sos1",
  "Sos2",
  "Sost",
  "Sostdc1",
  "Sowaha",
  "Sowahb",
  "Sowahc",
  "Sowahd",
  "Sox1",
  "Sox10",
  "Sox11",
  "Sox12",
  "Sox13",
  "Sox14",
  "Sox15",
  "Sox17",
  "Sox18",
  "Sox1ot",
  "Sox2",
  "Sox21",
  "Sox2ot",
  "Sox3",
  "Sox30",
  "Sox4",
  "Sox5",
  "Sox5it",
  "Sox5os1",
  "Sox5os3",
  "Sox5os5",
  "Sox6",
  "Sox6os",
  "Sox7",
  "Sox8",
  "Sox9",
  "Sp1",
  "Sp100",
  "Sp110",
  "Sp140",
  "Sp2",
  "Sp3os",
  "Sp4",
  "Sp5",
  "Sp7",
  "Sp8",
  "Sp9",
  "Spa17",
  "Spaar",
  "Spaca1",
  "Spaca3",
  "Spaca4",
  "Spaca5",
  "Spaca6",
  "Spaca7",
  "Spaca9",
  "Spag1",
  "Spag11b",
  "Spag16",
  "Spag17",
  "Spag17os",
  "Spag4",
  "Spag5",
  "Spag6",
  "Spag6l",
  "Spag7",
  "Spag8",
  "Spag9",
  "Spam1",
  "Sparc",
  "Sparcl1",
  "Spast",
  "Spata1",
  "Spata13",
  "Spata16",
  "Spata17",
  "Spata18",
  "Spata19",
  "Spata2",
  "Spata20",
  "Spata21",
  "Spata22",
  "Spata25",
  "Spata2l",
  "Spata3",
  "Spata31",
  "Spata31d1a",
  "Spata31d1b",
  "Spata31d1c",
  "Spata31d1d",
  "Spata32",
  "Spata33",
  "Spata4",
  "Spata45",
  "Spata46",
  "Spata48",
  "Spata5",
  "Spata5l1",
  "Spata6",
  "Spata7",
  "Spata9",
  "Spatc1",
  "Spatc1l",
  "Spats1",
  "Spats2",
  "Spats2l",
  "Spc24",
  "Spc25",
  "Spcs1",
  "Spcs2",
  "Spcs3",
  "Spdef",
  "Spdl1",
  "Spdya",
  "Spdye4a",
  "Spdye4b",
  "Spdye4c",
  "Specc1",
  "Specc1l",
  "Speer2",
  "Speer4a",
  "Speer4e",
  "Speer4f1",
  "Speer9-ps1",
  "Spef1",
  "Spef1l",
  "Spef2",
  "Speg",
  "Spem1",
  "Spem2",
  "Spen",
  "Spertl",
  "Spesp1",
  "Spg11",
  "Spg20",
  "Spg21",
  "Spg7",
  "Sphk1",
  "Sphk2",
  "Sphkap",
  "Spi1",
  "Spib",
  "Spic",
  "Spice1",
  "Spidr",
  "Spin1",
  "Spin2c",
  "Spin2g",
  "Spin4",
  "Spindoc",
  "Spink1",
  "Spink10",
  "Spink11",
  "Spink12",
  "Spink13",
  "Spink14",
  "Spink2",
  "Spink4",
  "Spink5",
  "Spink6",
  "Spink7",
  "Spink8",
  "Spinkl",
  "Spint1",
  "Spint2",
  "Spint3",
  "Spint4",
  "Spint5",
  "Spire1",
  "Spn",
  "Spns1",
  "Spns2",
  "Spns3",
  "Spo11",
  "Spocd1",
  "Spock1",
  "Spock2",
  "Spock3",
  "Spon1",
  "Spon2",
  "Spop",
  "Spopfm1",
  "Spopfm2",
  "Spopl",
  "Spout1",
  "Spp1",
  "Spp2",
  "Sppl2a",
  "Sppl2b",
  "Sppl2c",
  "Sppl3",
  "Spr",
  "Spred1",
  "Spred2",
  "Spred3",
  "Sprn",
  "Sprr1a",
  "Sprr1b",
  "Sprr2b",
  "Sprr2d",
  "Sprr2e",
  "Sprr2f",
  "Sprr2h",
  "Sprr2i",
  "Sprr2j-ps",
  "Sprr2k",
  "Sprr3",
  "Sprr4",
  "Sprtn",
  "Spry1",
  "Spry2",
  "Spry4",
  "Spryd3",
  "Spryd4",
  "Spryd7",
  "Spsb1",
  "Spsb2",
  "Spsb3",
  "Spsb4",
  "Spta1",
  "Sptan1",
  "Sptb",
  "Sptbn1",
  "Sptbn2",
  "Sptbn4",
  "Sptbn5",
  "Sptlc1",
  "Sptlc2",
  "Sptlc3",
  "Sptssa",
  "Sptssb",
  "Spty2d1",
  "Spx",
  "Spz1",
  "Sqle",
  "Sqor",
  "Sqstm1",
  "Sra1",
  "Srarp",
  "Srbd1",
  "Src",
  "Srcap",
  "Srcin1",
  "Srd5a1",
  "Srd5a2",
  "Srd5a3",
  "Srebf1",
  "Srebf2",
  "Srek1",
  "Srek1ip1",
  "Srf",
  "Srfbp1",
  "Srgap1",
  "Srgap2",
  "Srgap3",
  "Srgn",
  "Sri",
  "Srl",
  "Srm",
  "Srms",
  "Srp14",
  "Srp19",
  "Srp68",
  "Srp72",
  "Srp9",
  "Srpk1",
  "Srpk3",
  "Srpr",
  "Srprb",
  "Srpx",
  "Srpx2",
  "Srr",
  "Srrd",
  "Srrm1",
  "Srrm2",
  "Srrm3",
  "Srrm3os",
  "Srrm4",
  "Srrt",
  "Srsf1",
  "Srsf10",
  "Srsf11",
  "Srsf12",
  "Srsf2",
  "Srsf3",
  "Srsf4",
  "Srsf6",
  "Srsf7",
  "Srsf9",
  "Srxn1",
  "Sry",
  "Ss18",
  "Ss18l1",
  "Ss18l2",
  "Ssb",
  "Ssbp1",
  "Ssbp2",
  "Ssbp3",
  "Ssbp4",
  "Ssc4d",
  "Ssc5d",
  "Ssh1",
  "Ssh2",
  "Ssh3",
  "Ssmem1",
  "Ssna1",
  "Sspn",
  "Sspo",
  "Ssr1",
  "Ssr2",
  "Ssr3",
  "Ssr4",
  "Ssrp1",
  "Sst",
  "Sstr1",
  "Sstr2",
  "Sstr3",
  "Sstr4",
  "Sstr5",
  "Ssu2",
  "Ssu72",
  "Ssx2ip",
  "Ssx9",
  "Ssxa1",
  "Ssxb10",
  "Ssxb6",
  "St13",
  "St14",
  "St18",
  "St3gal1",
  "St3gal2",
  "St3gal3",
  "St3gal4",
  "St3gal5",
  "St3gal6",
  "St6gal1",
  "St6gal2",
  "St6galnac1",
  "St6galnac2",
  "St6galnac3",
  "St6galnac4",
  "St6galnac5",
  "St6galnac6",
  "St7",
  "St7l",
  "St8sia1",
  "St8sia2",
  "St8sia3",
  "St8sia4",
  "St8sia5",
  "St8sia6",
  "Stab1",
  "Stab2",
  "Stac",
  "Stac2",
  "Stac3",
  "Stag1",
  "Stag2",
  "Stag3",
  "Stam",
  "Stam2",
  "Stambp",
  "Stambpl1",
  "Stap1",
  "Stap2",
  "Star",
  "Stard10",
  "Stard13",
  "Stard3",
  "Stard3nl",
  "Stard4",
  "Stard5",
  "Stard6",
  "Stard7",
  "Stard8",
  "Stard9",
  "Stat1",
  "Stat2",
  "Stat3",
  "Stat4",
  "Stat5a",
  "Stat5b",
  "Stat6",
  "Stau1",
  "Stau2",
  "Stbd1",
  "Stc1",
  "Stc2",
  "Steap1",
  "Steap2",
  "Steap3",
  "Steap4",
  "Stfa1",
  "Stfa2l1",
  "Stfa3",
  "Stil",
  "Stim1",
  "Stim2",
  "Stimate",
  "Sting1",
  "Stip1",
  "Stk10",
  "Stk11",
  "Stk11ip",
  "Stk16",
  "Stk17b",
  "Stk19",
  "Stk24",
  "Stk25",
  "Stk26",
  "Stk3",
  "Stk31",
  "Stk32a",
  "Stk32b",
  "Stk32c",
  "Stk33",
  "Stk35",
  "Stk36",
  "Stk38",
  "Stk38l",
  "Stk39",
  "Stk4",
  "Stk40",
  "Stkld1",
  "Stmn1",
  "Stmn2",
  "Stmn4",
  "Stmnd1",
  "Stn1",
  "Stom",
  "Stoml1",
  "Stoml2",
  "Stoml3",
  "Ston1",
  "Ston2",
  "Stox1",
  "Stox2",
  "Stpg1",
  "Stpg2",
  "Stpg3",
  "Stpg4",
  "Stra6",
  "Stra6l",
  "Stra8",
  "Strada",
  "Stradb",
  "Strap",
  "Strbp",
  "Strc",
  "Strip1",
  "Strip2",
  "Strn",
  "Strn3",
  "Strn4",
  "Stt3a",
  "Stt3b",
  "Stub1",
  "Stum",
  "Stx11",
  "Stx12",
  "Stx16",
  "Stx17",
  "Stx18",
  "Stx19",
  "Stx1a",
  "Stx1b",
  "Stx2",
  "Stx3",
  "Stx4a",
  "Stx5a",
  "Stx6",
  "Stx7",
  "Stx8",
  "Stxbp1",
  "Stxbp2",
  "Stxbp3",
  "Stxbp4",
  "Stxbp5",
  "Stxbp5l",
  "Stxbp6",
  "Styk1",
  "Styx",
  "Styxl1",
  "Sub1",
  "Sucla2",
  "Suclg1",
  "Suclg2",
  "Sucnr1",
  "Suco",
  "Suds3",
  "Sufu",
  "Sugct",
  "Sugp1",
  "Sugp2",
  "Sugt1",
  "Sulf1",
  "Sulf2",
  "Sult1a1",
  "Sult1b1",
  "Sult1c1",
  "Sult1c2",
  "Sult1d1",
  "Sult1e1",
  "Sult2a1",
  "Sult2a3",
  "Sult2a4",
  "Sult2a5",
  "Sult2a6",
  "Sult2a7",
  "Sult2a8",
  "Sult2b1",
  "Sult3a1",
  "Sult3a2",
  "Sult4a1",
  "Sult5a1",
  "Sult6b1",
  "Sult6b2",
  "Sumf1",
  "Sumf2",
  "Sumo1",
  "Sumo2",
  "Sumo3",
  "Sun1",
  "Sun2",
  "Sun3",
  "Sun5",
  "Suox",
  "Supt16",
  "Supt20",
  "Supt3",
  "Supt4a",
  "Supt5",
  "Supt6",
  "Supt7l",
  "Supv3l1",
  "Surf1",
  "Surf2",
  "Surf4",
  "Surf6",
  "Susd1",
  "Susd3",
  "Susd4",
  "Susd5",
  "Susd6",
  "Suv39h1",
  "Suv39h2",
  "Suz12",
  "Sv2a",
  "Sv2b",
  "Sv2c",
  "Sva",
  "Sval1",
  "Sval2",
  "Sval3",
  "Svbp",
  "Svep1",
  "Svil",
  "Svip",
  "Svop",
  "Svopl",
  "Svs1",
  "Svs2",
  "Svs3a",
  "Svs3b",
  "Svs4",
  "Svs5",
  "Svs6",
  "Swap70",
  "Swi5",
  "Swsap1",
  "Swt1",
  "Syap1",
  "Sybu",
  "Syce1",
  "Syce1l",
  "Syce2",
  "Syce3",
  "Sycn",
  "Sycp1",
  "Sycp2",
  "Sycp2l",
  "Sycp3",
  "Syde1",
  "Syde2",
  "Syf2",
  "Syk",
  "Sympk",
  "Syn1",
  "Syn2",
  "Syn3",
  "Syna",
  "Sync",
  "Syncrip",
  "Syndig1",
  "Syndig1l",
  "Syne1",
  "Syne2",
  "Syne3",
  "Syne4",
  "Syngap1",
  "Syngr1",
  "Syngr2",
  "Syngr3",
  "Syngr4",
  "Synj1",
  "Synj2",
  "Synj2bp",
  "Synm",
  "Synpo",
  "Synpo2",
  "Synpo2l",
  "Synpr",
  "Synrg",
  "Syp",
  "Sypl",
  "Sypl2",
  "Sys1",
  "Syt1",
  "Syt10",
  "Syt11",
  "Syt12",
  "Syt13",
  "Syt14",
  "Syt15",
  "Syt16",
  "Syt17",
  "Syt2",
  "Syt3",
  "Syt4",
  "Syt5",
  "Syt6",
  "Syt7",
  "Syt8",
  "Syt9",
  "Sytl1",
  "Sytl2",
  "Sytl3",
  "Sytl4",
  "Sytl5",
  "Syvn1",
  "Szt2",
  "T",
  "T2",
  "Taar1",
  "Taar2",
  "Taar3",
  "Taar4",
  "Taar5",
  "Taar6",
  "Taar7b",
  "Taar7d",
  "Taar7e",
  "Taar7f",
  "Taar8a",
  "Taar8b",
  "Taar9",
  "Tab1",
  "Tab2",
  "Tab3",
  "Tac1",
  "Tac2",
  "Tac4",
  "Tacc1",
  "Tacc2",
  "Tacc3",
  "Taco1",
  "Tacr1",
  "Tacr2",
  "Tacr3",
  "Tacstd2",
  "Tada1",
  "Tada2a",
  "Tada2b",
  "Tada3",
  "Taf1",
  "Taf10",
  "Taf11",
  "Taf12",
  "Taf13",
  "Taf15",
  "Taf1a",
  "Taf1b",
  "Taf1c",
  "Taf1d",
  "Taf2",
  "Taf3",
  "Taf4",
  "Taf4b",
  "Taf5",
  "Taf5l",
  "Taf6",
  "Taf6l",
  "Taf7",
  "Taf7l",
  "Taf7l2",
  "Taf8",
  "Taf9b",
  "Tafa1",
  "Tafa2",
  "Tafa3",
  "Tafa4",
  "Tafa5",
  "Tagln",
  "Tagln2",
  "Tagln3",
  "Tal1",
  "Tal2",
  "Taldo1",
  "Tamm41",
  "Tanc1",
  "Tanc2",
  "Tango2",
  "Tango6",
  "Tank",
  "Taok2",
  "Taok3",
  "Tap1",
  "Tap2",
  "Tapbp",
  "Tapbpl",
  "Tapt1",
  "Tarbp2",
  "Tardbp",
  "Tarm1",
  "Tars",
  "Tars2",
  "Tarsl2",
  "Tas1r1",
  "Tas1r2",
  "Tas1r3",
  "Tas2r102",
  "Tas2r103",
  "Tas2r104",
  "Tas2r105",
  "Tas2r106",
  "Tas2r107",
  "Tas2r108",
  "Tas2r109",
  "Tas2r110",
  "Tas2r113",
  "Tas2r114",
  "Tas2r115",
  "Tas2r116",
  "Tas2r118",
  "Tas2r119",
  "Tas2r120",
  "Tas2r121",
  "Tas2r122",
  "Tas2r123",
  "Tas2r124",
  "Tas2r125",
  "Tas2r126",
  "Tas2r129",
  "Tas2r130",
  "Tas2r131",
  "Tas2r134",
  "Tas2r135",
  "Tas2r136",
  "Tas2r137",
  "Tas2r138",
  "Tas2r139",
  "Tas2r140",
  "Tas2r143",
  "Tas2r144",
  "Tasor",
  "Tasor2",
  "Tasp1",
  "Tat",
  "Tatdn1",
  "Tatdn2",
  "Tatdn3",
  "Tax1bp1",
  "Tax1bp3",
  "Taz",
  "Tbata",
  "Tbc1d1",
  "Tbc1d10a",
  "Tbc1d10b",
  "Tbc1d10c",
  "Tbc1d12",
  "Tbc1d13",
  "Tbc1d14",
  "Tbc1d15",
  "Tbc1d16",
  "Tbc1d17",
  "Tbc1d19",
  "Tbc1d2",
  "Tbc1d20",
  "Tbc1d21",
  "Tbc1d22a",
  "Tbc1d22b",
  "Tbc1d22bos",
  "Tbc1d23",
  "Tbc1d24",
  "Tbc1d25",
  "Tbc1d2b",
  "Tbc1d30",
  "Tbc1d31",
  "Tbc1d32",
  "Tbc1d4",
  "Tbc1d5",
  "Tbc1d7",
  "Tbc1d8",
  "Tbc1d8b",
  "Tbc1d9",
  "Tbc1d9b",
  "Tbca",
  "Tbcb",
  "Tbcc",
  "Tbccd1",
  "Tbcd",
  "Tbce",
  "Tbcel",
  "Tbck",
  "Tbk1",
  "Tbkbp1",
  "Tbl1x",
  "Tbl1xr1",
  "Tbl2",
  "Tbl3",
  "Tbp",
  "Tbpl1",
  "Tbpl2",
  "Tbr1",
  "Tbrg1",
  "Tbrg4",
  "Tbx1",
  "Tbx10",
  "Tbx15",
  "Tbx18",
  "Tbx19",
  "Tbx2",
  "Tbx20",
  "Tbx21",
  "Tbx22",
  "Tbx3",
  "Tbx3os1",
  "Tbx4",
  "Tbx5",
  "Tbx6",
  "Tbxa2r",
  "Tbxas1",
  "Tc2n",
  "Tcaf1",
  "Tcaf2",
  "Tcaf3",
  "Tcaim",
  "Tcam1",
  "Tcap",
  "Tcea1",
  "Tcea2",
  "Tcea3",
  "Tceal1",
  "Tceal3",
  "Tceal5",
  "Tceal6",
  "Tceal7",
  "Tceal8",
  "Tceal9",
  "Tceanc",
  "Tceanc2",
  "Tcerg1l",
  "Tcf12",
  "Tcf15",
  "Tcf19",
  "Tcf20",
  "Tcf21",
  "Tcf23",
  "Tcf24",
  "Tcf25",
  "Tcf3",
  "Tcf4",
  "Tcf7",
  "Tcf7l1",
  "Tcf7l2",
  "Tcfl5",
  "Tchh",
  "Tchhl1",
  "Tchp",
  "Tcim",
  "Tcirg1",
  "Tcl1",
  "Tcl1b2",
  "Tcl1b3",
  "Tcl1b4",
  "Tcl1b5",
  "Tcn2",
  "Tcof1",
  "Tcp1",
  "Tcp10c",
  "Tcp11",
  "Tcp11l1",
  "Tcp11l2",
  "Tcp11x2",
  "Tcstv1",
  "Tcstv3",
  "Tcta",
  "Tcte1",
  "Tcte2",
  "Tcte3",
  "Tctex1d1",
  "Tctex1d2",
  "Tctex1d4",
  "Tctn1",
  "Tctn2",
  "Tctn3",
  "Tdg",
  "Tdgf1",
  "Tdh",
  "Tdo2",
  "Tdp1",
  "Tdp2",
  "Tdpoz1",
  "Tdpoz2",
  "Tdpoz3",
  "Tdpoz8",
  "Tdrd1",
  "Tdrd12",
  "Tdrd3",
  "Tdrd5",
  "Tdrd6",
  "Tdrd7",
  "Tdrd9",
  "Tdrkh",
  "Tdrp",
  "Tead1",
  "Tead2",
  "Tead3",
  "Tead4",
  "Tec",
  "Tecpr2",
  "Tecr",
  "Tecrl",
  "Tecta",
  "Tectb",
  "Tedc1",
  "Tedc2",
  "Teddm1a",
  "Teddm1b",
  "Teddm2",
  "Teddm3",
  "Tef",
  "Tefm",
  "Tek",
  "Tekt1",
  "Tekt2",
  "Tekt3",
  "Tekt4",
  "Tekt5",
  "Telo2",
  "Ten1",
  "Tenm1",
  "Tenm2",
  "Tenm3",
  "Tenm4",
  "Tent2",
  "Tent4a",
  "Tent4b",
  "Tent5a",
  "Tent5b",
  "Tent5c",
  "Tent5d",
  "Tep1",
  "Tepp",
  "Tepsin",
  "Terb1",
  "Terb2",
  "Terf1",
  "Terf2",
  "Terf2ip",
  "Tert",
  "Tes",
  "Tesc",
  "Tescl",
  "Tesk1",
  "Tesk2",
  "Tesmin",
  "Tespa1",
  "Tet1",
  "Tet2",
  "Tet3",
  "Tex10",
  "Tex101",
  "Tex11",
  "Tex12",
  "Tex13a",
  "Tex13b",
  "Tex13c1",
  "Tex13c2",
  "Tex14",
  "Tex15",
  "Tex16",
  "Tex19.1",
  "Tex19.2",
  "Tex2",
  "Tex21",
  "Tex22",
  "Tex24",
  "Tex26",
  "Tex261",
  "Tex264",
  "Tex28",
  "Tex29",
  "Tex30",
  "Tex33",
  "Tex35",
  "Tex36",
  "Tex37",
  "Tex38",
  "Tex43",
  "Tex44",
  "Tex45",
  "Tex46",
  "Tex47",
  "Tex48",
  "Tex50",
  "Tex52",
  "Tex55",
  "Tex9",
  "Tfam",
  "Tfap2a",
  "Tfap2b",
  "Tfap2c",
  "Tfap2d",
  "Tfap2e",
  "Tfap4",
  "Tfb1m",
  "Tfb2m",
  "Tfcp2",
  "Tfcp2l1",
  "Tfdp1",
  "Tfdp2",
  "Tfe3",
  "Tfeb",
  "Tfec",
  "Tff1",
  "Tff2",
  "Tff3",
  "Tfg",
  "Tfip11",
  "Tfpi",
  "Tfpi2",
  "Tfpt",
  "Tfr2",
  "Tfrc",
  "Tg",
  "Tgds",
  "Tgfa",
  "Tgfb1",
  "Tgfb1i1",
  "Tgfb2",
  "Tgfb3",
  "Tgfbi",
  "Tgfbr1",
  "Tgfbr2",
  "Tgfbr3",
  "Tgfbrap1",
  "Tgif1",
  "Tgif2",
  "Tgm1",
  "Tgm2",
  "Tgm3",
  "Tgm4",
  "Tgm5",
  "Tgm6",
  "Tgm7",
  "Tgoln1",
  "Tgs1",
  "Th",
  "Tha1",
  "Thada",
  "Thap1",
  "Thap11",
  "Thap12",
  "Thap2",
  "Thap3",
  "Thap4",
  "Thap7",
  "Thap8",
  "Thbd",
  "Thbs1",
  "Thbs2",
  "Thbs3",
  "Thbs4",
  "Theg",
  "Thegl",
  "Them4",
  "Them5",
  "Them6",
  "Them7",
  "Themis",
  "Themis2",
  "Themis3",
  "Thg1l",
  "Thnsl1",
  "Thnsl2",
  "Thoc1",
  "Thoc2",
  "Thoc2l",
  "Thoc3",
  "Thoc5",
  "Thoc6",
  "Thoc7",
  "Thop1",
  "Thpo",
  "Thra",
  "Thrap3",
  "Thrb",
  "Thrsp",
  "Thsd1",
  "Thsd4",
  "Thsd7a",
  "Thsd7b",
  "Thumpd1",
  "Thumpd2",
  "Thumpd3",
  "Thy1",
  "Thyn1",
  "Tia1",
  "Tial1",
  "Tiam1",
  "Tiam2",
  "Ticam1",
  "Ticam2",
  "Ticrr",
  "Tie1",
  "Tifab",
  "Tigar",
  "Tigd2",
  "Tigd3",
  "Tigd4",
  "Tigd5",
  "Tigit",
  "Timd2",
  "Timd4",
  "Timeless",
  "Timm10",
  "Timm10b",
  "Timm13",
  "Timm17a",
  "Timm17b",
  "Timm21",
  "Timm22",
  "Timm23",
  "Timm29",
  "Timm44",
  "Timm50",
  "Timm8a1",
  "Timm8a2",
  "Timm8b",
  "Timm9",
  "Timmdc1",
  "Timp1",
  "Timp2",
  "Timp3",
  "Timp4",
  "Tinag",
  "Tinagl1",
  "Tinf2",
  "Tiparp",
  "Tipin",
  "Tiprl",
  "Tirap",
  "Tjap1",
  "Tjp1",
  "Tjp2",
  "Tjp3",
  "Tk1",
  "Tk2",
  "Tkfc",
  "Tkt",
  "Tktl1",
  "Tktl2",
  "Tlcd1",
  "Tlcd2",
  "Tlcd4",
  "Tlcd5",
  "Tldc2",
  "Tle1",
  "Tle2",
  "Tle3",
  "Tle4",
  "Tle5",
  "Tle6",
  "Tle7",
  "Tlk1",
  "Tlk2",
  "Tll1",
  "Tll2",
  "Tln1",
  "Tln2",
  "Tlnrd1",
  "Tlr1",
  "Tlr11",
  "Tlr12",
  "Tlr13",
  "Tlr2",
  "Tlr3",
  "Tlr4",
  "Tlr5",
  "Tlr6",
  "Tlr7",
  "Tlr8",
  "Tlr9",
  "Tlx1",
  "Tlx2",
  "Tlx3",
  "Tm2d1",
  "Tm2d2",
  "Tm2d3",
  "Tm4sf1",
  "Tm4sf19",
  "Tm4sf4",
  "Tm4sf5",
  "Tm6sf1",
  "Tm6sf2",
  "Tm7sf2",
  "Tm7sf3",
  "Tm9sf2",
  "Tm9sf3",
  "Tm9sf4",
  "Tma16",
  "Tma7",
  "Tmbim1",
  "Tmbim4",
  "Tmbim6",
  "Tmbim7",
  "Tmc1",
  "Tmc2",
  "Tmc3",
  "Tmc4",
  "Tmc5",
  "Tmc6",
  "Tmc7",
  "Tmc8",
  "Tmcc1",
  "Tmcc2",
  "Tmcc3",
  "Tmco1",
  "Tmco2",
  "Tmco3",
  "Tmco4",
  "Tmco5",
  "Tmco5b",
  "Tmco6",
  "Tmed1",
  "Tmed10",
  "Tmed2",
  "Tmed3",
  "Tmed4",
  "Tmed5",
  "Tmed6",
  "Tmed7",
  "Tmed8",
  "Tmed9",
  "Tmeff1",
  "Tmeff2",
  "Tmem100",
  "Tmem101",
  "Tmem102",
  "Tmem104",
  "Tmem106a",
  "Tmem106b",
  "Tmem106c",
  "Tmem107",
  "Tmem108",
  "Tmem109",
  "Tmem11",
  "Tmem114",
  "Tmem115",
  "Tmem116",
  "Tmem117",
  "Tmem119",
  "Tmem120a",
  "Tmem120b",
  "Tmem121",
  "Tmem121b",
  "Tmem123",
  "Tmem125",
  "Tmem126b",
  "Tmem127",
  "Tmem128",
  "Tmem129",
  "Tmem130",
  "Tmem131",
  "Tmem131l",
  "Tmem132a",
  "Tmem132b",
  "Tmem132c",
  "Tmem132d",
  "Tmem132e",
  "Tmem134",
  "Tmem135",
  "Tmem138",
  "Tmem139",
  "Tmem140",
  "Tmem141",
  "Tmem143",
  "Tmem144",
  "Tmem145",
  "Tmem147",
  "Tmem147os",
  "Tmem14a",
  "Tmem14c",
  "Tmem150a",
  "Tmem150b",
  "Tmem150c",
  "Tmem150cos",
  "Tmem151a",
  "Tmem151b",
  "Tmem154",
  "Tmem156",
  "Tmem158",
  "Tmem159",
  "Tmem160",
  "Tmem161a",
  "Tmem161b",
  "Tmem163",
  "Tmem164",
  "Tmem165",
  "Tmem167",
  "Tmem167b",
  "Tmem168",
  "Tmem169",
  "Tmem17",
  "Tmem170",
  "Tmem170b",
  "Tmem171",
  "Tmem174",
  "Tmem175",
  "Tmem176a",
  "Tmem176b",
  "Tmem177",
  "Tmem178",
  "Tmem178b",
  "Tmem179",
  "Tmem179b",
  "Tmem18",
  "Tmem182",
  "Tmem183a",
  "Tmem184a",
  "Tmem184b",
  "Tmem184c",
  "Tmem185a",
  "Tmem185b",
  "Tmem186",
  "Tmem189",
  "Tmem19",
  "Tmem190",
  "Tmem191c",
  "Tmem192",
  "Tmem196",
  "Tmem198",
  "Tmem198b",
  "Tmem199",
  "Tmem200a",
  "Tmem200b",
  "Tmem200c",
  "Tmem201",
  "Tmem202",
  "Tmem203",
  "Tmem204",
  "Tmem205",
  "Tmem207",
  "Tmem208",
  "Tmem209",
  "Tmem210",
  "Tmem211",
  "Tmem212",
  "Tmem213",
  "Tmem214",
  "Tmem215",
  "Tmem216",
  "Tmem217",
  "Tmem218",
  "Tmem219",
  "Tmem220",
  "Tmem221",
  "Tmem222",
  "Tmem223",
  "Tmem225",
  "Tmem229a",
  "Tmem229b",
  "Tmem230",
  "Tmem231",
  "Tmem232",
  "Tmem233",
  "Tmem234",
  "Tmem235",
  "Tmem236",
  "Tmem237",
  "Tmem238",
  "Tmem238l",
  "Tmem240",
  "Tmem241",
  "Tmem242",
  "Tmem243",
  "Tmem245",
  "Tmem246",
  "Tmem247",
  "Tmem248",
  "Tmem249",
  "Tmem25",
  "Tmem250-ps",
  "Tmem251",
  "Tmem252",
  "Tmem253",
  "Tmem254a",
  "Tmem255a",
  "Tmem255b",
  "Tmem256",
  "Tmem258",
  "Tmem259",
  "Tmem26",
  "Tmem260",
  "Tmem262",
  "Tmem263",
  "Tmem267",
  "Tmem268",
  "Tmem269",
  "Tmem270",
  "Tmem273",
  "Tmem28",
  "Tmem29",
  "Tmem30a",
  "Tmem30b",
  "Tmem30c",
  "Tmem33",
  "Tmem35a",
  "Tmem35b",
  "Tmem37",
  "Tmem38a",
  "Tmem38b",
  "Tmem39a",
  "Tmem39b",
  "Tmem40",
  "Tmem41a",
  "Tmem41b",
  "Tmem42",
  "Tmem43",
  "Tmem44",
  "Tmem45a",
  "Tmem45a2",
  "Tmem45b",
  "Tmem47",
  "Tmem50a",
  "Tmem50b",
  "Tmem51",
  "Tmem51os1",
  "Tmem52",
  "Tmem52b",
  "Tmem53",
  "Tmem54",
  "Tmem59",
  "Tmem59l",
  "Tmem60",
  "Tmem61",
  "Tmem62",
  "Tmem63a",
  "Tmem63b",
  "Tmem63c",
  "Tmem64",
  "Tmem65",
  "Tmem67",
  "Tmem68",
  "Tmem69",
  "Tmem70",
  "Tmem71",
  "Tmem72",
  "Tmem74",
  "Tmem74b",
  "Tmem74bos",
  "Tmem79",
  "Tmem8",
  "Tmem80",
  "Tmem81",
  "Tmem82",
  "Tmem86a",
  "Tmem86b",
  "Tmem87a",
  "Tmem87b",
  "Tmem88",
  "Tmem88b",
  "Tmem89",
  "Tmem8b",
  "Tmem9",
  "Tmem91",
  "Tmem92",
  "Tmem94",
  "Tmem95",
  "Tmem97",
  "Tmem98",
  "Tmem9b",
  "Tmf1",
  "Tmie",
  "Tmigd1",
  "Tmigd3",
  "Tmod1",
  "Tmod2",
  "Tmod3",
  "Tmod4",
  "Tmpo",
  "Tmprss11a",
  "Tmprss11b",
  "Tmprss11c",
  "Tmprss11d",
  "Tmprss11e",
  "Tmprss11f",
  "Tmprss11g",
  "Tmprss12",
  "Tmprss13",
  "Tmprss15",
  "Tmprss2",
  "Tmprss3",
  "Tmprss4",
  "Tmprss5",
  "Tmprss6",
  "Tmprss7",
  "Tmprss9",
  "Tmsb15a",
  "Tmsb15b1",
  "Tmsb15l",
  "Tmsb4x",
  "Tmtc1",
  "Tmtc2",
  "Tmtc3",
  "Tmtc4",
  "Tmub1",
  "Tmub2",
  "Tmx1",
  "Tmx2",
  "Tmx3",
  "Tmx4",
  "Tnc",
  "Tnf",
  "Tnfaip1",
  "Tnfaip2",
  "Tnfaip3",
  "Tnfaip6",
  "Tnfaip8",
  "Tnfaip8l1",
  "Tnfaip8l2",
  "Tnfaip8l3",
  "Tnfrsf10b",
  "Tnfrsf11a",
  "Tnfrsf11b",
  "Tnfrsf12a",
  "Tnfrsf13b",
  "Tnfrsf13c",
  "Tnfrsf14",
  "Tnfrsf17",
  "Tnfrsf18",
  "Tnfrsf19",
  "Tnfrsf1a",
  "Tnfrsf21",
  "Tnfrsf22",
  "Tnfrsf23",
  "Tnfrsf25",
  "Tnfrsf26",
  "Tnfrsf4",
  "Tnfrsf8",
  "Tnfrsf9",
  "Tnfsf10",
  "Tnfsf11",
  "Tnfsf12",
  "Tnfsf13b",
  "Tnfsf13os",
  "Tnfsf14",
  "Tnfsf15",
  "Tnfsf18",
  "Tnfsf8",
  "Tnfsf9",
  "Tnfsfm13",
  "Tnik",
  "Tnip1",
  "Tnip2",
  "Tnip3",
  "Tnk1",
  "Tnk2",
  "Tnk2os",
  "Tnks",
  "Tnks1bp1",
  "Tnks2",
  "Tnmd",
  "Tnn",
  "Tnnc1",
  "Tnnc2",
  "Tnni1",
  "Tnni2",
  "Tnni3",
  "Tnni3k",
  "Tnnt1",
  "Tnnt2",
  "Tnnt3",
  "Tnp1",
  "Tnp2",
  "Tnpo1",
  "Tnpo2",
  "Tnpo3",
  "Tnr",
  "Tnrc18",
  "Tnrc6a",
  "Tnrc6b",
  "Tnrc6c",
  "Tns1",
  "Tns2",
  "Tns3",
  "Tns4",
  "Tnxb",
  "Tob1",
  "Tob2",
  "Toe1",
  "Togaram1",
  "Togaram2",
  "Tollip",
  "Tom1",
  "Tom1l1",
  "Tom1l2",
  "Tomm20",
  "Tomm20l",
  "Tomm22",
  "Tomm40",
  "Tomm40l",
  "Tomm5",
  "Tomm6",
  "Tomm7",
  "Tomm70a",
  "Tomt",
  "Tonsl",
  "Top1",
  "Top1mt",
  "Top2a",
  "Top2b",
  "Top3a",
  "Top3b",
  "Topaz1",
  "Topbp1",
  "Topors",
  "Toporsl",
  "Tor1a",
  "Tor1aip1",
  "Tor1aip2",
  "Tor1b",
  "Tor2a",
  "Tor3a",
  "Tor4a",
  "Tox",
  "Tox2",
  "Tox3",
  "Tox4",
  "Tpbg",
  "Tpbgl",
  "Tpbpa",
  "Tpbpb",
  "Tpcn1",
  "Tpcn2",
  "Tpd52",
  "Tpd52l1",
  "Tpd52l2",
  "Tpgs1",
  "Tpgs2",
  "Tph1",
  "Tph2",
  "Tpi1",
  "Tpk1",
  "Tpm1",
  "Tpm2",
  "Tpm3",
  "Tpm4",
  "Tpmt",
  "Tpo",
  "Tpp1",
  "Tpp2",
  "Tppp",
  "Tppp2",
  "Tppp3",
  "Tpr",
  "Tpra1",
  "Tprg",
  "Tprgl",
  "Tprkb",
  "Tprn",
  "Tpsab1",
  "Tpsb2",
  "Tpsg1",
  "Tpst1",
  "Tpst2",
  "Tpt1",
  "Tpte",
  "Tpx2",
  "Tra2a",
  "Trabd2b",
  "Tradd",
  "Traf1",
  "Traf2",
  "Traf3",
  "Traf3ip1",
  "Traf3ip2",
  "Traf3ip3",
  "Traf4",
  "Traf5",
  "Traf6",
  "Traf7",
  "Trafd1",
  "Traip",
  "Trak1",
  "Trak2",
  "Tram1",
  "Tram1l1",
  "Tram2",
  "Trank1",
  "Trap1",
  "Trap1a",
  "Trappc1",
  "Trappc10",
  "Trappc11",
  "Trappc12",
  "Trappc13",
  "Trappc2",
  "Trappc2l",
  "Trappc3",
  "Trappc3l",
  "Trappc4",
  "Trappc5",
  "Trappc6a",
  "Trappc6b",
  "Trappc8",
  "Trappc9",
  "Trarg1",
  "Trat1",
  "Trcg1",
  "Trdmt1",
  "Trdn",
  "Treh",
  "Trem2",
  "Trem3",
  "Treml1",
  "Treml2",
  "Treml4",
  "Trerf1",
  "Trex2",
  "Trf",
  "Trh",
  "Trhde",
  "Trhr",
  "Trhr2",
  "Triap1",
  "Trib1",
  "Trib2",
  "Trib3",
  "Tril",
  "Trim10",
  "Trim11",
  "Trim12a",
  "Trim12c",
  "Trim13",
  "Trim14",
  "Trim15",
  "Trim16",
  "Trim17",
  "Trim2",
  "Trim21",
  "Trim23",
  "Trim24",
  "Trim25",
  "Trim26",
  "Trim27",
  "Trim28",
  "Trim29",
  "Trim3",
  "Trim30a",
  "Trim30b",
  "Trim30c",
  "Trim30d",
  "Trim31",
  "Trim32",
  "Trim33",
  "Trim34a",
  "Trim35",
  "Trim36",
  "Trim37",
  "Trim38",
  "Trim39",
  "Trim40",
  "Trim41",
  "Trim42",
  "Trim43b",
  "Trim43c",
  "Trim44",
  "Trim45",
  "Trim46",
  "Trim47",
  "Trim5",
  "Trim50",
  "Trim52",
  "Trim54",
  "Trim55",
  "Trim56",
  "Trim58",
  "Trim59",
  "Trim6",
  "Trim60",
  "Trim61",
  "Trim62",
  "Trim63",
  "Trim65",
  "Trim66",
  "Trim67",
  "Trim68",
  "Trim69",
  "Trim7",
  "Trim71",
  "Trim75",
  "Trim8",
  "Trim80",
  "Trim9",
  "Triml1",
  "Triml2",
  "Trio",
  "Triobp",
  "Trip10",
  "Trip11",
  "Trip12",
  "Trip13",
  "Trip4",
  "Trip6",
  "Triqk",
  "Trir",
  "Trit1",
  "Trmo",
  "Trmt1",
  "Trmt10a",
  "Trmt10b",
  "Trmt10c",
  "Trmt11",
  "Trmt112",
  "Trmt12",
  "Trmt13",
  "Trmt1l",
  "Trmt2a",
  "Trmt2b",
  "Trmt44",
  "Trmt5",
  "Trmt6",
  "Trmt61a",
  "Trmt61b",
  "Trmt9b",
  "Trmu",
  "Trnau1ap",
  "Trnp1",
  "Trnt1",
  "Tro",
  "Troap",
  "Trp53bp1",
  "Trp53bp2",
  "Trp53cor1",

  "Trp53i11",
  "Trp53i13",
  "Trp53inp1",
  "Trp53inp2",
  "Trp53rka",
  "Trp53rkb",
  "Trp53tg5",
  "Trp63",
  "Trp73",
  "Trpa1",
  "Trpc1",
  "Trpc3",
  "Trpc4",
  "Trpc4ap",
  "Trpc5",
  "Trpc5os",
  "Trpc6",
  "Trpc7",
  "Trpd52l3",
  "Trpm1",
  "Trpm2",
  "Trpm3",
  "Trpm4",
  "Trpm5",
  "Trpm6",
  "Trpm7",
  "Trpm8",
  "Trps1",
  "Trpt1",
  "Trpv1",
  "Trpv2",
  "Trpv3",
  "Trpv4",
  "Trpv5",
  "Trpv6",
  "Trrap",
  "Trub1",
  "Trub2",
  "Try4",
  "Try5",
  "Tsacc",
  "Tsc1",
  "Tsc2",
  "Tsc22d1",
  "Tsc22d2",
  "Tsc22d3",
  "Tsc22d4",
  "Tsen15",
  "Tsen34",
  "Tsen54",
  "Tsfm",
  "Tsga10",
  "Tsga10ip",
  "Tsga13",
  "Tsga8",
  "Tshb",
  "Tshr",
  "Tshz1",
  "Tshz2",
  "Tshz3",
  "Tsix",
  "Tsks",
  "Tsku",
  "Tslp",
  "Tslrn1",
  "Tsn",
  "Tsnax",
  "Tsnaxip1",
  "Tspan1",
  "Tspan10",
  "Tspan11",
  "Tspan12",
  "Tspan13",
  "Tspan14",
  "Tspan15",
  "Tspan17",
  "Tspan18",
  "Tspan2",
  "Tspan3",
  "Tspan32",
  "Tspan32os",
  "Tspan33",
  "Tspan4",
  "Tspan5",
  "Tspan6",
  "Tspan7",
  "Tspan8",
  "Tspan9",
  "Tspear",
  "Tspo",
  "Tspo2",
  "Tspoap1",
  "Tspyl1",
  "Tspyl2",
  "Tspyl3",
  "Tspyl4",
  "Tspyl5",
  "Tsr1",
  "Tsr2",
  "Tsr3",
  "Tssc4",
  "Tssk1",
  "Tssk2",
  "Tssk3",
  "Tssk4",
  "Tssk5",
  "Tssk6",
  "Tst",
  "Tsta3",
  "Tstd1",
  "Tstd2",
  "Tstd3",
  "Tsx",
  "Ttbk1",
  "Ttbk2",
  "Ttc1",
  "Ttc12",
  "Ttc13",
  "Ttc14",
  "Ttc16",
  "Ttc17",
  "Ttc19",
  "Ttc21a",
  "Ttc21b",
  "Ttc22",
  "Ttc23",
  "Ttc23l",
  "Ttc24",
  "Ttc25",
  "Ttc26",
  "Ttc27",
  "Ttc28",
  "Ttc29",
  "Ttc3",
  "Ttc30a1",
  "Ttc30a2",
  "Ttc30b",
  "Ttc32",
  "Ttc33",
  "Ttc34",
  "Ttc36",
  "Ttc37",
  "Ttc38",
  "Ttc39a",
  "Ttc39b",
  "Ttc39c",
  "Ttc39d",
  "Ttc4",
  "Ttc41",
  "Ttc5",
  "Ttc6",
  "Ttc7",
  "Ttc7b",
  "Ttc8",
  "Ttc9",
  "Ttc9b",
  "Ttc9c",
  "Ttf1",
  "Ttf2",
  "Tti1",
  "Tti2",
  "Ttk",
  "Ttl",
  "Ttll1",
  "Ttll10",
  "Ttll11",
  "Ttll12",
  "Ttll13",
  "Ttll3",
  "Ttll4",
  "Ttll5",
  "Ttll6",
  "Ttll7",
  "Ttll8",
  "Ttll9",
  "Ttn",
  "Ttpa",
  "Ttpal",
  "Ttr",
  "Ttyh1",
  "Ttyh2",
  "Ttyh3",
  "Tub",
  "Tuba1a",
  "Tuba1b",
  "Tuba1c",
  "Tuba3a",
  "Tuba3b",
  "Tuba4a",
  "Tuba8",
  "Tubal3",
  "Tubb1",
  "Tubb2a",
  "Tubb2b",
  "Tubb3",
  "Tubb4a",
  "Tubb5",
  "Tubb6",
  "Tubd1",
  "Tube1",
  "Tubg1",
  "Tubg2",
  "Tubgcp2",
  "Tubgcp3",
  "Tubgcp4",
  "Tubgcp5",
  "Tubgcp6",
  "Tufm",
  "Tuft1",
  "Tug1",
  "Tulp1",
  "Tulp2",
  "Tulp3",
  "Tulp4",
  "Tunar",
  "Tusc1",
  "Tusc2",
  "Tusc3",
  "Tut1",
  "Tut4",
  "Tut7",
  "Tvp23a",
  "Tvp23b",
  "Twf1",
  "Twf2",
  "Twist1",
  "Twist2",
  "Twistnb",
  "Twnk",
  "Twsg1",
  "Txk",
  "Txlna",
  "Txlnb",
  "Txlng",
  "Txn1",
  "Txn2",
  "Txndc11",
  "Txndc12",
  "Txndc15",
  "Txndc16",
  "Txndc17",
  "Txndc2",
  "Txndc5",
  "Txndc8",
  "Txndc9",
  "Txnip",
  "Txnl1",
  "Txnl4a",
  "Txnl4b",
  "Txnrd1",
  "Txnrd2",
  "Txnrd3",
  "Tyk2",
  "Tyms",
  "Tyr",
  "Tyro3",
  "Tyrobp",
  "Tyrp1",
  "Tysnd1",
  "Tyw1",
  "Tyw3",
  "Tyw5",
  "U2af1",
  "U2af2",
  "U2surp",
  "U90926",
  "Uaca",
  "Uap1",
  "Uap1l1",
  "Uba1",
  "Uba1y",
  "Uba2",
  "Uba3",
  "Uba5",
  "Uba6",
  "Uba7",
  "Ubac1",
  "Ubac2",
  "Ubald1",
  "Ubald2",
  "Ubap1",
  "Ubap1l",
  "Ubap2",
  "Ubash3a",
  "Ubash3b",
  "Ubc",
  "Ubd",
  "Ube2a",
  "Ube2b",
  "Ube2c",
  "Ube2d1",
  "Ube2d2a",
  "Ube2d2b",
  "Ube2d3",
  "Ube2dnl1",
  "Ube2dnl2",
  "Ube2e1",
  "Ube2e2",
  "Ube2e3",
  "Ube2f",
  "Ube2g1",
  "Ube2g2",
  "Ube2i",
  "Ube2j1",
  "Ube2j2",
  "Ube2k",
  "Ube2l3",
  "Ube2l6",
  "Ube2m",
  "Ube2n",
  "Ube2o",
  "Ube2q1",
  "Ube2q2",
  "Ube2ql1",
  "Ube2r2",
  "Ube2s",
  "Ube2t",
  "Ube2u",
  "Ube2v2",
  "Ube2w",
  "Ube2z",
  "Ube3a",
  "Ube3b",
  "Ube3c",
  "Ube4a",
  "Ube4b",
  "Ube4bos1",
  "Ubfd1",
  "Ubiad1",
  "Ubl3",
  "Ubl4a",
  "Ubl4b",
  "Ubl5",
  "Ubl7",
  "Ublcp1",
  "Ubn1",
  "Ubn2",
  "Ubox5",
  "Ubp1",
  "Ubqln1",
  "Ubqln2",
  "Ubqln3",
  "Ubqln4",
  "Ubqln5",
  "Ubqlnl",
  "Ubr1",
  "Ubr2",
  "Ubr3",
  "Ubr4",
  "Ubr5",
  "Ubr7",
  "Ubtd1",
  "Ubtf",
  "Ubtfl1",
  "Ubxn1",
  "Ubxn10",
  "Ubxn11",
  "Ubxn2a",
  "Ubxn2b",
  "Ubxn4",
  "Ubxn6",
  "Ubxn7",
  "Ubxn8",
  "Uchl1",
  "Uchl3",
  "Uchl5",
  "Uck1",
  "Uck2",
  "Uckl1",
  "Ucma",
  "Ucn2",
  "Ucn3",
  "Ucp1",
  "Ucp2",
  "Ucp3",
  "Uevld",
  "Ufc1",
  "Ufd1",
  "Ufl1",
  "Ufm1",
  "Ufsp1",
  "Ufsp2",
  "Ugcg",
  "Ugdh",
  "Uggt1",
  "Uggt2",
  "Ugp2",
  "Ugt1a7c",
  "Ugt1a8",
  "Ugt2a3",
  "Ugt2b1",
  "Ugt2b34",
  "Ugt2b35",
  "Ugt2b37",
  "Ugt2b38",
  "Ugt2b5",
  "Ugt3a1",
  "Ugt3a2",
  "Ugt8a",
  "Uhmk1",
  "Uhrf1",
  "Uhrf1bp1",
  "Uhrf1bp1l",
  "Uhrf2",
  "Uimc1",
  "Ulbp1",
  "Ulk1",
  "Ulk2",
  "Ulk3",
  "Ulk4",
  "Umad1",
  "Umod",
  "Umodl1",
  "Umps",
  "Unc119",
  "Unc119b",
  "Unc13a",
  "Unc13b",
  "Unc13c",
  "Unc13d",
  "Unc45a",
  "Unc45b",
  "Unc45bos",
  "Unc50",
  "Unc5a",
  "Unc5b",
  "Unc5c",
  "Unc5cl",
  "Unc5d",
  "Unc79",
  "Unc80",
  "Unc93a2",
  "Unc93b1",
  "Uncx",
  "Unk",
  "Unkl",
  "Uox",
  "Upb1",
  "Upf1",
  "Upf2",
  "Upf3a",
  "Upf3b",
  "Upk1a",
  "Upk1b",
  "Upk2",
  "Upk3a",
  "Upk3b",
  "Upk3bl",
  "Upp1",
  "Upp2",
  "Uprt",
  "Uqcc1",
  "Uqcc2",
  "Uqcc3",
  "Uqcr10",
  "Uqcr11",
  "Uqcrb",
  "Uqcrc1",
  "Uqcrc2",
  "Uqcrfs1",
  "Uqcrh",
  "Uqcrq",
  "Urad",
  "Urah",
  "Urb1",
  "Urb2",
  "Urgcp",
  "Uri1",
  "Urm1",
  "Uroc1",
  "Urod",
  "Uros",
  "Usb1",
  "Use1",
  "Usf2",
  "Usf3",
  "Ush1c",
  "Ush1g",
  "Ush2a",
  "Ushbp1",
  "Uso1",
  "Usp1",
  "Usp10",
  "Usp11",
  "Usp12",
  "Usp13",
  "Usp14",
  "Usp15",
  "Usp16",
  "Usp17la",
  "Usp17lb",
  "Usp17lc",
  "Usp17ld",
  "Usp17le",
  "Usp18",
  "Usp19",
  "Usp2",
  "Usp20",
  "Usp21",
  "Usp22",
  "Usp24",
  "Usp25",
  "Usp26",
  "Usp27x",
  "Usp28",
  "Usp29",
  "Usp3",
  "Usp30",
  "Usp31",
  "Usp32",
  "Usp33",
  "Usp34",
  "Usp35",
  "Usp36",
  "Usp37",
  "Usp38",
  "Usp39",
  "Usp4",
  "Usp40",
  "Usp42",
  "Usp43",
  "Usp44",
  "Usp45",
  "Usp46",
  "Usp47",
  "Usp48",
  "Usp49",
  "Usp5",
  "Usp50",
  "Usp51",
  "Usp53",
  "Usp54",
  "Usp6nl",
  "Usp7",
  "Usp9x",
  "Usp9y",
  "Uspl1",
  "Ust",
  "Utf1",
  "Utp11",
  "Utp14a",
  "Utp15",
  "Utp18",
  "Utp20",
  "Utp23",
  "Utp25",
  "Utp3",
  "Utp4",
  "Utp6",
  "Utrn",
  "Uts2",
  "Uts2b",
  "Uts2r",
  "Uty",
  "Uvrag",
  "Uvssa",
  "Uxs1",
  "Uxt",
  "V1ra8",
  "V1rd19",
  "Vac14",
  "Vamp1",
  "Vamp2",
  "Vamp3",
  "Vamp4",
  "Vamp8",
  "Vamp9",
  "Vangl1",
  "Vangl2",
  "Vapa",
  "Vapb",
  "Vars",
  "Vars2",
  "Vash1",
  "Vash2",
  "Vasn",
  "Vasp",
  "Vat1",
  "Vat1l",
  "Vav1",
  "Vav2",
  "Vav3",
  "Vax1",
  "Vax2",
  "Vax2os",
  "Vbp1",
  "Vcam1",
  "Vcan",
  "Vcl",
  "Vcp",
  "Vcpip1",
  "Vcpkmt",
  "Vdac1",
  "Vdac2",
  "Vdac3",
  "Vdr",
  "Vegfa",
  "Vegfb",
  "Vegfc",
  "Vegfd",
  "Veph1",
  "Vezf1",
  "Vezt",
  "Vgf",
  "Vgll1",
  "Vgll2",
  "Vgll3",
  "Vgll4",
  "Vhl",
  "Vil1",
  "Vill",
  "Vim",
  "Vinac1",
  "Vip",
  "Vipas39",
  "Vipr1",
  "Vipr2",
  "Virma",
  "Vit",
  "Vkorc1",
  "Vldlr",
  "Vma21",
  "Vmac",
  "Vmn1r1",
  "Vmn1r10",
  "Vmn1r11",
  "Vmn1r12",
  "Vmn1r13",
  "Vmn1r139",
  "Vmn1r14",
  "Vmn1r15",
  "Vmn1r16",
  "Vmn1r167",
  "Vmn1r168",
  "Vmn1r170",
  "Vmn1r171",
  "Vmn1r174",
  "Vmn1r175",
  "Vmn1r178",
  "Vmn1r179",
  "Vmn1r18",
  "Vmn1r180",
  "Vmn1r181",
  "Vmn1r183",
  "Vmn1r185",
  "Vmn1r188",
  "Vmn1r19",
  "Vmn1r191",
  "Vmn1r192",
  "Vmn1r193",
  "Vmn1r194",
  "Vmn1r195",
  "Vmn1r196",
  "Vmn1r197",
  "Vmn1r198",
  "Vmn1r199",
  "Vmn1r20",
  "Vmn1r200",
  "Vmn1r201",
  "Vmn1r202",
  "Vmn1r203",
  "Vmn1r204",
  "Vmn1r205",
  "Vmn1r206",
  "Vmn1r207",
  "Vmn1r209",
  "Vmn1r21",
  "Vmn1r210",
  "Vmn1r211",
  "Vmn1r212",
  "Vmn1r213",
  "Vmn1r214",
  "Vmn1r215",
  "Vmn1r217",
  "Vmn1r218",
  "Vmn1r219",
  "Vmn1r22",
  "Vmn1r220",
  "Vmn1r222",
  "Vmn1r223",
  "Vmn1r224",
  "Vmn1r225",
  "Vmn1r226",
  "Vmn1r227",
  "Vmn1r228",
  "Vmn1r229",
  "Vmn1r23",
  "Vmn1r230",
  "Vmn1r231",
  "Vmn1r232",
  "Vmn1r233",
  "Vmn1r234",
  "Vmn1r235",
  "Vmn1r236",
  "Vmn1r237",
  "Vmn1r24",
  "Vmn1r25",
  "Vmn1r26",
  "Vmn1r27",
  "Vmn1r30",
  "Vmn1r31",
  "Vmn1r32",
  "Vmn1r33",
  "Vmn1r34",
  "Vmn1r35",
  "Vmn1r36",
  "Vmn1r37",
  "Vmn1r38",
  "Vmn1r39",
  "Vmn1r4",
  "Vmn1r40",
  "Vmn1r42",
  "Vmn1r43",
  "Vmn1r44",
  "Vmn1r45",
  "Vmn1r46",
  "Vmn1r47",
  "Vmn1r49",
  "Vmn1r5",
  "Vmn1r50",
  "Vmn1r51",
  "Vmn1r52",
  "Vmn1r53",
  "Vmn1r54",
  "Vmn1r55",
  "Vmn1r57",
  "Vmn1r58",
  "Vmn1r59",
  "Vmn1r6",
  "Vmn1r61",
  "Vmn1r62",
  "Vmn1r63",
  "Vmn1r64",
  "Vmn1r65",
  "Vmn1r66",
  "Vmn1r68",
  "Vmn1r69",
  "Vmn1r7",
  "Vmn1r70",
  "Vmn1r71",
  "Vmn1r72",
  "Vmn1r73",
  "Vmn1r74",
  "Vmn1r75",
  "Vmn1r76",
  "Vmn1r78",
  "Vmn1r8",
  "Vmn1r80",
  "Vmn1r81",
  "Vmn1r82",
  "Vmn1r83",
  "Vmn1r84",
  "Vmn1r85",
  "Vmn1r86",
  "Vmn1r87",
  "Vmn1r88",
  "Vmn1r89",
  "Vmn1r9",
  "Vmn1r91",
  "Vmn2r1",
  "Vmn2r10",
  "Vmn2r100",
  "Vmn2r101",
  "Vmn2r102",
  "Vmn2r103",
  "Vmn2r104",
  "Vmn2r105",
  "Vmn2r106",
  "Vmn2r107",
  "Vmn2r108",
  "Vmn2r109",
  "Vmn2r110",
  "Vmn2r111",
  "Vmn2r112",
  "Vmn2r113",
  "Vmn2r114",
  "Vmn2r116",
  "Vmn2r118",
  "Vmn2r120",
  "Vmn2r121",
  "Vmn2r13",
  "Vmn2r15",
  "Vmn2r17",
  "Vmn2r19",
  "Vmn2r23",
  "Vmn2r24",
  "Vmn2r25",
  "Vmn2r26",
  "Vmn2r27",
  "Vmn2r28",
  "Vmn2r3",
  "Vmn2r4",
  "Vmn2r52",
  "Vmn2r53",
  "Vmn2r54",
  "Vmn2r55",
  "Vmn2r59",
  "Vmn2r60",
  "Vmn2r61",
  "Vmn2r65",
  "Vmn2r66",
  "Vmn2r67",
  "Vmn2r68",
  "Vmn2r69",
  "Vmn2r70",
  "Vmn2r71",
  "Vmn2r72",
  "Vmn2r73",
  "Vmn2r75",
  "Vmn2r76",
  "Vmn2r80",
  "Vmn2r81",
  "Vmn2r83",
  "Vmn2r86",
  "Vmn2r88",
  "Vmn2r89",
  "Vmn2r90",
  "Vmn2r94",
  "Vmn2r97",
  "Vmn2r98",
  "Vmn2r99",
  "Vmo1",
  "Vmp1",
  "Vnn1",
  "Vnn3",
  "Vopp1",
  "Vpreb1",
  "Vpreb3",
  "Vps11",
  "Vps13a",
  "Vps13b",
  "Vps13c",
  "Vps13d",
  "Vps16",
  "Vps18",
  "Vps26a",
  "Vps26b",
  "Vps26c",
  "Vps28",
  "Vps29",
  "Vps33a",
  "Vps33b",
  "Vps35",
  "Vps35l",
  "Vps36",
  "Vps37a",
  "Vps37b",
  "Vps37c",
  "Vps37d",
  "Vps39",
  "Vps41",
  "Vps45",
  "Vps4a",
  "Vps4b",
  "Vps50",
  "Vps52",
  "Vps53",
  "Vps54",
  "Vps8",
  "Vps9d1",
  "Vrk1",
  "Vrk2",
  "Vrk3",
  "Vrtn",
  "Vsig1",
  "Vsig10",
  "Vsig10l",
  "Vsig2",
  "Vsig4",
  "Vsig8",
  "Vsir",
  "Vsnl1",
  "Vstm2a",
  "Vstm2b",
  "Vstm2l",
  "Vstm4",
  "Vstm5",
  "Vsx1",
  "Vsx2",
  "Vta1",
  "Vtcn1",
  "Vti1a",
  "Vti1b",
  "Vtn",
  "Vwa1",
  "Vwa2",
  "Vwa3a",
  "Vwa3b",
  "Vwa5a",
  "Vwa5b1",
  "Vwa5b2",
  "Vwa7",
  "Vwa8",
  "Vwc2",
  "Vwc2l",
  "Vwce",
  "Vwde",
  "Vwf",
  "Vxn",
  "Wac",
  "Wap",
  "Wapl",
  "Wars",
  "Wars2",
  "Was",
  "Wasf1",
  "Wasf2",
  "Wasf3",
  "Washc1",
  "Washc2",
  "Washc3",
  "Washc4",
  "Washc5",
  "Wasl",
  "Wbp1",
  "Wbp11",
  "Wbp1l",
  "Wbp2",
  "Wbp2nl",
  "Wbp4",
  "Wdcp",
  "Wdfy1",
  "Wdfy2",
  "Wdfy3",
  "Wdfy4",
  "Wdhd1",
  "Wdpcp",
  "Wdr1",
  "Wdr11",
  "Wdr12",
  "Wdr13",
  "Wdr17",
  "Wdr18",
  "Wdr19",
  "Wdr20",
  "Wdr20rt",
  "Wdr24",
  "Wdr25",
  "Wdr26",
  "Wdr27",
  "Wdr3",
  "Wdr31",
  "Wdr33",
  "Wdr34",
  "Wdr35",
  "Wdr36",
  "Wdr37",
  "Wdr38",
  "Wdr4",
  "Wdr41",
  "Wdr43",
  "Wdr44",
  "Wdr45",
  "Wdr45b",
  "Wdr46",
  "Wdr47",
  "Wdr48",
  "Wdr49",
  "Wdr5",
  "Wdr53",
  "Wdr54",
  "Wdr55",
  "Wdr59",
  "Wdr5b",
  "Wdr6",
  "Wdr60",
  "Wdr61",
  "Wdr62",
  "Wdr63",
  "Wdr64",
  "Wdr66",
  "Wdr7",
  "Wdr70",
  "Wdr72",
  "Wdr73",
  "Wdr74",
  "Wdr75",
  "Wdr76",
  "Wdr77",
  "Wdr78",
  "Wdr81",
  "Wdr82",
  "Wdr83",
  "Wdr83os",
  "Wdr86",
  "Wdr89",
  "Wdr90",
  "Wdr91",
  "Wdr93",
  "Wdr95",
  "Wdsub1",
  "Wdtc1",
  "Wdyhv1",
  "Wee1",
  "Wee2",
  "Wfdc1",
  "Wfdc10",
  "Wfdc11",
  "Wfdc12",
  "Wfdc13",
  "Wfdc15a",
  "Wfdc15b",
  "Wfdc16",
  "Wfdc17",
  "Wfdc18",
  "Wfdc2",
  "Wfdc21",
  "Wfdc3",
  "Wfdc5",
  "Wfdc6a",
  "Wfdc6b",
  "Wfdc8",
  "Wfdc9",
  "Wfikkn1",
  "Wfikkn2",
  "Whamm",
  "Whrn",
  "Wif1",
  "Wincr1",
  "Wipf1",
  "Wipf2",
  "Wipf3",
  "Wipi1",
  "Wipi2",
  "Wiz",
  "Wls",
  "Wnk1",
  "Wnk2",
  "Wnk3",
  "Wnk4",
  "Wnt1",
  "Wnt10a",
  "Wnt10b",
  "Wnt11",
  "Wnt16",
  "Wnt2",
  "Wnt2b",
  "Wnt3",
  "Wnt3a",
  "Wnt4",
  "Wnt5b",
  "Wnt6",
  "Wnt7a",
  "Wnt7b",
  "Wnt8a",
  "Wnt8b",
  "Wnt9a",
  "Wnt9b",
  "Wrap53",
  "Wrap73",
  "Wrn",
  "Wrnip1",
  "Wsb1",
  "Wscd1",
  "Wscd2",
  "Wt1",
  "Wt1os",
  "Wtap",
  "Wtip",
  "Wwc1",
  "Wwc2",
  "Wwox",
  "Wwp1",
  "Wwp2",
  "Wwtr1",
  "Xab2",
  "Xaf1",
  "Xbp1",
  "Xcl1",
  "Xcr1",
  "Xdh",
  "Xiap",
  "Xirp1",
  "Xirp2",
  "Xist",
  "Xk",
  "Xkr4",
  "Xkr5",
  "Xkr6",
  "Xkr7",
  "Xkr8",
  "Xkr9",
  "Xkrx",
  "Xlr",
  "Xlr5c",
  "Xpa",
  "Xpc",
  "Xpnpep1",
  "Xpnpep2",
  "Xpnpep3",
  "Xpo1",
  "Xpo4",
  "Xpo5",
  "Xpo6",
  "Xpo7",
  "Xpot",
  "Xpr1",
  "Xrcc1",
  "Xrcc2",
  "Xrcc3",
  "Xrcc4",
  "Xrcc5",
  "Xrcc6",
  "Xrn1",
  "Xrn2",
  "Xrra1",
  "Xxylt1",
  "Xylt1",
  "Xylt2",
  "Yae1d1",
  "Yaf2",
  "Yap1",
  "Yars",
  "Yars2",
  "Ybey",
  "Ybx1",
  "Ybx2",
  "Ybx3",
  "Ydjc",
  "Yeats2",
  "Yeats4",
  "Yes1",
  "Yif1a",
  "Yif1b",
  "Yipf1",
  "Yipf2",
  "Yipf3",
  "Yipf4",
  "Yipf5",
  "Yipf6",
  "Yipf7",
  "Yjefn3",
  "Yju2",
  "Ykt6",
  "Ylpm1",
  "Yme1l1",
  "Yod1",
  "Ypel1",
  "Ypel2",
  "Ypel3",
  "Ypel4",
  "Ypel5",
  "Yrdc",
  "Ythdc1",
  "Ythdc2",
  "Ythdf1",
  "Ythdf2",
  "Ythdf3",
  "Ywhab",
  "Ywhae",
  "Ywhag",
  "Ywhah",
  "Ywhaq",
  "Ywhaz",
  "Yy1",
  "Zadh2",
  "Zan",
  "Zap70",
  "Zar1",
  "Zar1l",
  "Zbbx",
  "Zbed3",
  "Zbed4",
  "Zbed5",
  "Zbp1",
  "Zbtb1",
  "Zbtb10",
  "Zbtb11",
  "Zbtb11os1",
  "Zbtb12",
  "Zbtb14",
  "Zbtb16",
  "Zbtb18",
  "Zbtb2",
  "Zbtb20",
  "Zbtb21",
  "Zbtb22",
  "Zbtb24",
  "Zbtb25",
  "Zbtb26",
  "Zbtb3",
  "Zbtb32",
  "Zbtb33",
  "Zbtb34",
  "Zbtb37",
  "Zbtb38",
  "Zbtb39",
  "Zbtb4",
  "Zbtb40",
  "Zbtb41",
  "Zbtb42",
  "Zbtb43",
  "Zbtb44",
  "Zbtb45",
  "Zbtb46",
  "Zbtb48",
  "Zbtb49",
  "Zbtb5",
  "Zbtb6",
  "Zbtb7a",
  "Zbtb7b",
  "Zbtb7c",
  "Zbtb8a",
  "Zbtb8b",
  "Zbtb8os",
  "Zc2hc1a",
  "Zc2hc1b",
  "Zc2hc1c",
  "Zc3h10",
  "Zc3h12a",
  "Zc3h12b",
  "Zc3h12c",
  "Zc3h12d",
  "Zc3h13",
  "Zc3h14",
  "Zc3h15",
  "Zc3h18",
  "Zc3h3",
  "Zc3h4",
  "Zc3h6",
  "Zc3h7a",
  "Zc3h7b",
  "Zc3h8",
  "Zc3hav1",
  "Zc3hav1l",
  "Zc3hc1",
  "Zc4h2",
  "Zcchc10",
  "Zcchc12",
  "Zcchc13",
  "Zcchc14",
  "Zcchc17",
  "Zcchc18",
  "Zcchc2",
  "Zcchc24",
  "Zcchc3",
  "Zcchc4",
  "Zcchc7",
  "Zcchc8",
  "Zcchc9",
  "Zcrb1",
  "Zcwpw1",
  "Zdbf2",
  "Zdhhc1",
  "Zdhhc11",
  "Zdhhc12",
  "Zdhhc13",
  "Zdhhc14",
  "Zdhhc15",
  "Zdhhc16",
  "Zdhhc17",
  "Zdhhc18",
  "Zdhhc19",
  "Zdhhc2",
  "Zdhhc20",
  "Zdhhc21",
  "Zdhhc22",
  "Zdhhc23",
  "Zdhhc24",
  "Zdhhc25",
  "Zdhhc3",
  "Zdhhc4",
  "Zdhhc5",
  "Zdhhc6",
  "Zdhhc7",
  "Zdhhc8",
  "Zdhhc9",
  "Zeb1",
  "Zeb2",
  "Zeb2os",
  "Zer1",
  "Zfand1",
  "Zfand2a",
  "Zfand2b",
  "Zfand3",
  "Zfand4",
  "Zfand5",
  "Zfand6",
  "Zfas1",
  "Zfat",
  "Zfc3h1",
  "Zfhx2",
  "Zfhx2os",
  "Zfhx3",
  "Zfhx4",
  "Zfp1",
  "Zfp101",
  "Zfp105",
  "Zfp106",
  "Zfp108",
  "Zfp109",
  "Zfp11",
  "Zfp110",
  "Zfp111",
  "Zfp112",
  "Zfp113",
  "Zfp114",
  "Zfp119a",
  "Zfp119b",
  "Zfp12",
  "Zfp120",
  "Zfp128",
  "Zfp13",
  "Zfp131",
  "Zfp14",
  "Zfp141",
  "Zfp142",
  "Zfp143",
  "Zfp146",
  "Zfp148",
  "Zfp157",
  "Zfp160",
  "Zfp169",
  "Zfp174",
  "Zfp180",
  "Zfp182",
  "Zfp184",
  "Zfp185",
  "Zfp189",
  "Zfp2",
  "Zfp202",
  "Zfp207",
  "Zfp212",
  "Zfp213",
  "Zfp217",
  "Zfp219",
  "Zfp229",
  "Zfp235",
  "Zfp236",
  "Zfp239",
  "Zfp24",
  "Zfp248",
  "Zfp251",
  "Zfp26",
  "Zfp260",
  "Zfp263",
  "Zfp266",
  "Zfp267",
  "Zfp27",
  "Zfp273",
  "Zfp275",
  "Zfp276",
  "Zfp277",
  "Zfp28",
  "Zfp280b",
  "Zfp280c",
  "Zfp280d",
  "Zfp281",
  "Zfp282",
  "Zfp286",
  "Zfp287",
  "Zfp292",
  "Zfp296",
  "Zfp3",
  "Zfp30",
  "Zfp300",
  "Zfp316",
  "Zfp317",
  "Zfp318",
  "Zfp319",
  "Zfp322a",
  "Zfp324",
  "Zfp326",
  "Zfp329",
  "Zfp330",
  "Zfp334",
  "Zfp335",
  "Zfp335os",
  "Zfp341",
  "Zfp345",
  "Zfp346",
  "Zfp35",
  "Zfp354a",
  "Zfp354b",
  "Zfp354c",
  "Zfp358",
  "Zfp362",
  "Zfp365",
  "Zfp366",
  "Zfp367",
  "Zfp369",
  "Zfp36l1",
  "Zfp36l2",
  "Zfp36l3",
  "Zfp37",
  "Zfp383",
  "Zfp385a",
  "Zfp385b",
  "Zfp385c",
  "Zfp386",
  "Zfp39",
  "Zfp395",
  "Zfp397",
  "Zfp398",
  "Zfp40",
  "Zfp407",
  "Zfp408",
  "Zfp41",
  "Zfp410",
  "Zfp414",
  "Zfp418",
  "Zfp42",
  "Zfp420",
  "Zfp422",
  "Zfp423",
  "Zfp426",
  "Zfp428",
  "Zfp429",
  "Zfp438",
  "Zfp442",
  "Zfp444",
  "Zfp445",
  "Zfp446",
  "Zfp449",
  "Zfp451",
  "Zfp454",
  "Zfp455",
  "Zfp456",
  "Zfp457",
  "Zfp458",
  "Zfp459",
  "Zfp46",
  "Zfp462",
  "Zfp467",
  "Zfp469",
  "Zfp472",
  "Zfp473",
  "Zfp474",
  "Zfp488",
  "Zfp503",
  "Zfp507",
  "Zfp51",
  "Zfp511",
  "Zfp512",
  "Zfp512b",
  "Zfp513",
  "Zfp516",
  "Zfp518a",
  "Zfp518b",
  "Zfp52",
  "Zfp521",
  "Zfp523",
  "Zfp524",
  "Zfp526",
  "Zfp53",
  "Zfp532",
  "Zfp536",
  "Zfp54",
  "Zfp541",
  "Zfp551",
  "Zfp553",
  "Zfp558",
  "Zfp560",
  "Zfp563",
  "Zfp566",
  "Zfp568",
  "Zfp57",
  "Zfp574",
  "Zfp575",
  "Zfp579",
  "Zfp58",
  "Zfp580",
  "Zfp583",
  "Zfp592",
  "Zfp593",
  "Zfp595",
  "Zfp597",
  "Zfp598",
  "Zfp599",
  "Zfp60",
  "Zfp605",
  "Zfp606",
  "Zfp607a",
  "Zfp607b",
  "Zfp608",
  "Zfp609",
  "Zfp61",
  "Zfp612",
  "Zfp616",
  "Zfp617",
  "Zfp618",
  "Zfp619",
  "Zfp62",
  "Zfp622",
  "Zfp623",
  "Zfp626",
  "Zfp628",
  "Zfp629",
  "Zfp637",
  "Zfp638",
  "Zfp639",
  "Zfp64",
  "Zfp641",
  "Zfp644",
  "Zfp646",
  "Zfp647",
  "Zfp648",
  "Zfp65",
  "Zfp651",
  "Zfp652",
  "Zfp652os",
  "Zfp653",
  "Zfp654",
  "Zfp655",
  "Zfp658",
  "Zfp661",
  "Zfp663",
  "Zfp664",
  "Zfp667",
  "Zfp668",
  "Zfp672",
  "Zfp677",
  "Zfp68",
  "Zfp683",
  "Zfp687",
  "Zfp688",
  "Zfp689",
  "Zfp69",
  "Zfp691",
  "Zfp692",
  "Zfp697",
  "Zfp7",
  "Zfp703",
  "Zfp704",
  "Zfp706",
  "Zfp707",
  "Zfp709",
  "Zfp710",
  "Zfp711",
  "Zfp712",
  "Zfp715",
  "Zfp719",
  "Zfp72",
  "Zfp729a",
  "Zfp729b",
  "Zfp735",
  "Zfp738",
  "Zfp74",
  "Zfp740",
  "Zfp746",
  "Zfp747",
  "Zfp748",
  "Zfp750",
  "Zfp758",
  "Zfp759",
  "Zfp760",
  "Zfp763",
  "Zfp768",
  "Zfp770",
  "Zfp771",
  "Zfp773",
  "Zfp775",
  "Zfp777",
  "Zfp78",
  "Zfp780b",
  "Zfp784",
  "Zfp786",
  "Zfp787",
  "Zfp788",
  "Zfp790",
  "Zfp791",
  "Zfp799",
  "Zfp800",
  "Zfp804a",
  "Zfp809",
  "Zfp81",
  "Zfp810",
  "Zfp811",
  "Zfp819",
  "Zfp820",
  "Zfp821",
  "Zfp825",
  "Zfp827",
  "Zfp830",
  "Zfp831",
  "Zfp839",
  "Zfp84",
  "Zfp846",
  "Zfp85",
  "Zfp850",
  "Zfp853",
  "Zfp85os",
  "Zfp865",
  "Zfp866",
  "Zfp867",
  "Zfp868",
  "Zfp869",
  "Zfp87",
  "Zfp870",
  "Zfp871",
  "Zfp872",
  "Zfp873",
  "Zfp874a",
  "Zfp874b",
  "Zfp879",
  "Zfp882",
  "Zfp9",
  "Zfp90",
  "Zfp92",
  "Zfp93",
  "Zfp930",
  "Zfp931",
  "Zfp932",
  "Zfp933",
  "Zfp934",
  "Zfp936",
  "Zfp937",
  "Zfp938",
  "Zfp94",
  "Zfp940",
  "Zfp941",
  "Zfp942",
  "Zfp943",
  "Zfp944",
  "Zfp945",
  "Zfp946",
  "Zfp948",
  "Zfp949",
  "Zfp950",
  "Zfp951",
  "Zfp952",
  "Zfp953",
  "Zfp954",
  "Zfp955b",
  "Zfp956",
  "Zfp957",
  "Zfp958",
  "Zfp959",
  "Zfp961",
  "Zfp963",
  "Zfp964",
  "Zfp972",
  "Zfp974",
  "Zfp975",
  "Zfp976",
  "Zfp977",
  "Zfp979",
  "Zfp981",
  "Zfp983",
  "Zfp985",
  "Zfp990",
  "Zfp992",
  "Zfp994",
  "Zfp995",
  "Zfpl1",
  "Zfpm1",
  "Zfpm2",
  "Zfr",
  "Zfr2",
  "Zfx",
  "Zfy1",
  "Zfy2",
  "Zfyve1",
  "Zfyve16",
  "Zfyve19",
  "Zfyve21",
  "Zfyve26",
  "Zfyve27",
  "Zfyve28",
  "Zfyve9",
  "Zg16",
  "Zgpat",
  "Zgrf1",
  "Zhx1",
  "Zhx2",
  "Zhx3",
  "Zic1",
  "Zic3",
  "Zic4",
  "Zic5",
  "Zik1",
  "Zim1",
  "Zim2",
  "Zim3",
  "Zkscan1",
  "Zkscan14",
  "Zkscan16",
  "Zkscan17",
  "Zkscan2",
  "Zkscan3",
  "Zkscan4",
  "Zkscan5",
  "Zkscan6",
  "Zkscan7",
  "Zkscan8",
  "Zmat1",
  "Zmat2",
  "Zmat3",
  "Zmat4",
  "Zmat5",
  "Zmiz1",
  "Zmiz1os1",
  "Zmiz2",
  "Zmpste24",
  "Zmym1",
  "Zmym2",
  "Zmym3",
  "Zmym4",
  "Zmym5",
  "Zmynd10",
  "Zmynd11",
  "Zmynd12",
  "Zmynd15",
  "Zmynd19",
  "Zmynd8",
  "Znfx1",
  "Znhit1",
  "Znhit2",
  "Znhit3",
  "Znhit6",
  "Znrd1",
  "Znrd1as",
  "Znrf2",
  "Znrf3",
  "Znrf4",
  "Zp1",
  "Zp2",
  "Zp3",
  "Zp3r",
  "Zpbp",
  "Zpbp2",
  "Zpld1",
  "Zpld2",
  "Zpr1",
  "Zranb1",
  "Zranb2",
  "Zranb3",
  "Zrsr1",
  "Zrsr2",
  "Zscan10",
  "Zscan12",
  "Zscan18",
  "Zscan2",
  "Zscan20",
  "Zscan21",
  "Zscan22",
  "Zscan26",
  "Zscan29",
  "Zscan4-ps1",
  "Zscan5b",
  "Zswim1",
  "Zswim2",
  "Zswim3",
  "Zswim4",
  "Zswim5",
  "Zswim6",
  "Zswim7",
  "Zswim8",
  "Zswim9",
  "Zup1",
  "Zw10",
  "Zwilch",
  "Zwint",
  "Zxdb",
  "Zxdc",
  "Zyg11a",
  "Zyg11b",
  "Zyx",
  "Zzef1",
  "Zzz3",
  "a",
  "ccdc198",
  "mmu-let-7a-1-3p",
  "mmu-let-7a-5p",
  "mmu-let-7b-5p",
  "mmu-let-7c-2-3p",
  "mmu-let-7c-5p",
  "mmu-let-7d-3p",
  "mmu-let-7d-5p",
  "mmu-let-7e-5p",
  "mmu-let-7f-1-3p",
  "mmu-let-7f-5p",
  "mmu-let-7g-3p",
  "mmu-let-7g-5p",
  "mmu-let-7i-3p",
  "mmu-let-7i-5p",
  "mmu-miR-100-5p",
  "mmu-miR-101a-3p",
  "mmu-miR-101b-3p",
  "mmu-miR-101c",
  "mmu-miR-122-5p",
  "mmu-miR-125b-5p",
  "mmu-miR-126-3p",
  "mmu-miR-126-5p",
  "mmu-miR-127-3p",
  "mmu-miR-130a-3p",
  "mmu-miR-133a-3p",
  "mmu-miR-133a-5p",
  "mmu-miR-133b-3p",
  "mmu-miR-136-3p",
  "mmu-miR-136-5p",
  "mmu-miR-140-3p",
  "mmu-miR-140-5p",
  "mmu-miR-142-3p",
  "mmu-miR-143-3p",
  "mmu-miR-149-5p",
  "mmu-miR-150-5p",
  "mmu-miR-151-3p",
  "mmu-miR-154-5p",
  "mmu-miR-185-5p",
  "mmu-miR-186-5p",
  "mmu-miR-1895",
  "mmu-miR-18a-5p",
  "mmu-miR-190a-5p",
  "mmu-miR-191-5p",
  "mmu-miR-194-5p",
  "mmu-miR-195a-5p",
  "mmu-miR-196a-5p",
  "mmu-miR-196b-5p",
  "mmu-miR-199a-3p",
  "mmu-miR-199b-3p",
  "mmu-miR-19b-3p",
  "mmu-miR-1a-1-5p",
  "mmu-miR-1a-2-5p",
  "mmu-miR-1a-3p",
  "mmu-miR-200b-3p",
  "mmu-miR-200c-3p",
  "mmu-miR-206-3p",
  "mmu-miR-208b-3p",
  "mmu-miR-2137",
  "mmu-miR-214-3p",
  "mmu-miR-22-3p",
  "mmu-miR-22-5p",
  "mmu-miR-23a-3p",
  "mmu-miR-23b-3p",
  "mmu-miR-24-1-5p",
  "mmu-miR-24-2-5p",
  "mmu-miR-24-3p",
  "mmu-miR-25-3p",
  "mmu-miR-26a-5p",
  "mmu-miR-26b-3p",
  "mmu-miR-27a-3p",
  "mmu-miR-27b-3p",
  "mmu-miR-28a-3p",
  "mmu-miR-299a-3p",
  "mmu-miR-29a-3p",
  "mmu-miR-29b-2-5p",
  "mmu-miR-29b-3p",
  "mmu-miR-29c-3p",
  "mmu-miR-300-5p",
  "mmu-miR-301a-3p",
  "mmu-miR-3096a-3p",
  "mmu-miR-30a-3p",
  "mmu-miR-30b-5p",
  "mmu-miR-30c-5p",
  "mmu-miR-30e-3p",
  "mmu-miR-30e-5p",
  "mmu-miR-3102-3p",
  "mmu-miR-3107-3p",
  "mmu-miR-320-3p",
  "mmu-miR-322-3p",
  "mmu-miR-322-5p",
  "mmu-miR-328-3p",
  "mmu-miR-329-3p",
  "mmu-miR-337-3p",
  "mmu-miR-338-3p",
  "mmu-miR-369-5p",
  "mmu-miR-376c-3p",
  "mmu-miR-378a-3p",
  "mmu-miR-378a-5p",
  "mmu-miR-378c",
  "mmu-miR-378d",
  "mmu-miR-379-5p",
  "mmu-miR-380-5p",
  "mmu-miR-382-5p",
  "mmu-miR-3960",
  "mmu-miR-410-3p",
  "mmu-miR-411-5p",
  "mmu-miR-423-3p",
  "mmu-miR-425-5p",
  "mmu-miR-466f-3p",
  "mmu-miR-484",
  "mmu-miR-486-3p",
  "mmu-miR-486-5p",
  "mmu-miR-489-3p",
  "mmu-miR-500-5p",
  "mmu-miR-503-5p",
  "mmu-miR-505-5p",
  "mmu-miR-5106",
  "mmu-miR-5108",
  "mmu-miR-5109",
  "mmu-miR-5112",
  "mmu-miR-5119",
  "mmu-miR-532-3p",
  "mmu-miR-532-5p",
  "mmu-miR-539-5p",
  "mmu-miR-544-3p",
  "mmu-miR-6240",
  "mmu-miR-6243",
  "mmu-miR-6416-3p",
  "mmu-miR-669c-3p",
  "mmu-miR-674-3p",
  "mmu-miR-708-5p",
  "mmu-miR-744-5p",
  "mmu-miR-7a-1-3p",
  "mmu-miR-872-5p",
  "mmu-miR-877-5p",
  "mmu-miR-93-5p",
  "mmu-miR-98-5p",
  "mmu-miR-99a-5p",
  "mmu-miR-99b-5p",
  "mt-Atp8",
  "mt-Cytb",
  "mt-Nd1",
  "mt-Nd2",
  "mt-Nd4",
  "mt-Nd4l",
  "mt-Nd5",
  "mt-Nd6"
];
