import React from "react";
import css from "./Homepage.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ToggleChoices = ({
  icon,
  onToggleChange,
  descriptionStyle,
  analysisStyle
}) => {
  return (
    <div className={css.toggleChoicesContainer}>
      <div
        className={css.descriptionChoice}
        style={descriptionStyle}
        onClick={onToggleChange}
      >
        Description
      </div>
      <FontAwesomeIcon
        className={css.toggleArrow}
        icon={icon}
        size="2x"
        onClick={onToggleChange}
      />
      <div
        className={css.analysisChoice}
        style={analysisStyle}
        onClick={onToggleChange}
      >
        Analysis
      </div>
    </div>
  );
};

export default ToggleChoices;
