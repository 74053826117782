import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faAngleRight,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faCircle,
  faExternalLinkAlt,
  faGreaterThan,
  faListUl,
  faSpinner,
  faToggleOn
} from "@fortawesome/free-solid-svg-icons";
import Viewer from "./components/Viewer";
import Homepage from "./components/Homepage/Homepage";
import UserNetwork from "./components/UserNetwork/UserNetwork";
import Pathway from "./components/Pathways/Pathway";
import AmbiguousIDs from "./components/AmbiguousIDs/AmbiguousIDs";
import Help from "./components/Help/Help";

library.add(faSpinner); // Spinner expressions' histogram
library.add(faCircle); // Circle for legend color
library.add(faListUl);
library.add(faExternalLinkAlt);
library.add(faGreaterThan);
library.add(faToggleOn);
library.add(faArrowAltCircleLeft);
library.add(faArrowAltCircleRight);
library.add(faAngleDown);
library.add(faAngleRight);

const App = () => (
  <Router basename={process.env.PUBLIC_URL}>
    <Route path={"/"} exact={true} component={Homepage} />
    <Route path={"/entry"} exact={true} component={Homepage} />

    <Route
      path={"/entry/:geneId"}
      render={props => {
        switch (props.match.params.geneId.toLowerCase()) {
          case "gm5089":
            return (
              <AmbiguousIDs
                symbol={props.match.params.geneId}
                values={
                  multiple_mappings[props.match.params.geneId.toLowerCase()]
                }
              />
            );
          case "gm16499":
            return (
              <AmbiguousIDs
                symbol={props.match.params.geneId}
                values={
                  multiple_mappings[props.match.params.geneId.toLowerCase()]
                }
              />
            );
          default:
            return <Viewer match={props.match} />;
        }
      }}
    />

    <Route
      path={"/network"}
      exact={true}
      render={() => (
        <UserNetwork
          key={Math.random()
            .toString(36)
            .substring(7)}
        />
      )}
    />
    <Route
      path={"/pathways"}
      exact={true}
      render={() => (
        <Pathway
          key={Math.random()
            .toString(36)
            .substring(7)}
        />
      )}
    />
    <Route path={"/help"} exact={true} component={Help} />
  </Router>
);

export default App;

const multiple_mappings = {
  gm16499: ["ENSMUSG00000108255", "ENSMUSG00000108175"],
  gm5089: ["ENSMUSG00000064052", "ENSMUSG00000115263"]
};
