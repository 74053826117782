import React from "react";
import PropTypes from "prop-types";

const Dropdown = ({ choices, selection, onChange }) => (
  <select value={selection} onChange={e => onChange(e.target.value)}>
    {choices.map(choice => (
      <option key={choice.value} value={choice.value}>
        {choice.label}
      </option>
    ))}
  </select>
);

export default Dropdown;

Dropdown.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object).isRequired,
  selection: PropTypes.any,
  onChange: PropTypes.func.isRequired
};
