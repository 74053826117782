import React, { useState } from "react";
import css from "./Homepage.module.css";
import Footer from "../Footer/Footer";
import StartingOptions from "./StartingOptions";
import Header from "./Header";
import ToggleChoices from "./ToggleChoices";
import Description from "./Description";

const Homepage = () => {
  const [description, setDescription] = useState(false);
  const [icon, setIcon] = useState("arrow-alt-circle-right");
  const [descriptionStyle, setDescriptionStyle] = useState({
    border: "1px solid lightgray",
    pointerEvents: "auto"
  });
  const [analysisStyle, setAnalysisStyle] = useState({
    border: "2px solid #2385e0",
    pointerEvents: "none"
  });

  const onToggleClick = () => {
    description === true
      ? setIcon("arrow-alt-circle-right")
      : setIcon("arrow-alt-circle-left");

    description === true
      ? setDescriptionStyle({
          border: "1px solid lightgray",
          pointerEvents: "auto"
        })
      : setDescriptionStyle({
          border: "2px solid #2385e0",
          pointerEvents: "none"
        });

    description === true
      ? setAnalysisStyle({ border: "2px solid #2385e0", pointerEvents: "none" })
      : setAnalysisStyle({
          border: "1px solid lightgray",
          pointerEvents: "auto"
        });

    setDescription(!description);
  };

  const ToggleComponent = () =>
    description ? <Description /> : <StartingOptions />;

  return (
    <div className={css.container}>
      <Header />
      <ToggleChoices
        icon={icon}
        onToggleChange={onToggleClick}
        descriptionStyle={descriptionStyle}
        analysisStyle={analysisStyle}
      />
      <ToggleComponent />
      <Footer />
    </div>
  );
};

export default Homepage;
