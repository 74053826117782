// Copyright 2019 Davide Corso

import { useEffect, useState } from "react";
import { relativeURL } from "../helperURL";

export const useGene = entry => {
  const [data, setData] = useState({
    flag: false,
    entry_type: "",
    main_expression: {
      series: []
    },
    source_gene_name: "",
    expression: {
      first_corr: {
        series: []
      },
      second_corr: {
        series: []
      }
    },
    correlations: { first: [], second: [], label: { first: "", second: "" } },
    exp_to_fetch: { first: undefined, second: undefined },
    source_entry: "",
    networkInfo: {
      flag: false,
      networkData: { nodes: [], edges: [] },
      networkChoices: []
    }
  });

  useEffect(() => {
    async function fetchData() {
      const resp = await fetch(relativeURL(`/api/entry/${entry}`));
      const body = await resp.json();
      setData(body);
    }

    fetchData();
  }, [entry]);

  return data;
};
