import React, { useEffect, useState } from "react";
import css from "./Network.module.css";
import NetworkViewer from "network-viewer";
import "network-viewer/dist/index.css";
import Dropdown from "./Dropdown/Dropdown";
import cssDropdown from "./Dropdown/Dropdown.module.css";
import DropdownGroup from "./Dropdown/DropdownGroup";
import { relativeURL } from "../../helperURL";
import * as PropTypes from "prop-types";
import { downloadNetwork } from "./downloadNetwork";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const NetworkGraph = ({
  entry,
  initialNetwork,
  networkChoices,
  selectElements
}) => {
  const [layoutTimeout, setLayoutTimeout] = useState(0);
  const [networkData, setNetworkData] = useState(initialNetwork);
  const [selectedValue, setSelectedValue] = useState(0);
  const { trackEvent } = useMatomo();

  const onLayoutChange = timeout => {
    setLayoutTimeout(findLayoutChoice(timeout, layoutChoices));
  };

  useEffect(() => {
    setSelectedValue(0);
    setNetworkData(initialNetwork);
  }, [entry, initialNetwork]);

  const onNetworkChange = value => {
    trackEvent({ category: "entry-page", action: "dropdown-changed-network" });
    selectElements({
      flag: false,
      element: null,
      infos: {
        interaction: {
          flag: false,
          data: null
        }
      }
    });
    setSelectedValue(value);
    async function fetchNetworkData() {
      const resp = await fetch(
        relativeURL(`/api/singlenetwork/${entry}/${value}`)
      );
      const body = await resp.json();
      setNetworkData(body);
    }
    fetchNetworkData();
  };

  return (
    <div className={css.netViewer}>
      <NetworkViewer
        nodes={networkData.nodes}
        edges={networkData.edges}
        style={style}
        layoutTimeout={layoutChoices[layoutTimeout].value}
        selectElements={selectElements}
      >
        <div className={cssDropdown.container}>
          <div className={`${cssDropdown.layout} ${cssDropdown.header}`}>
            Layout Duration:
          </div>
          <div className={`${cssDropdown.layout} ${cssDropdown.dropdown}`}>
            <Dropdown
              choices={layoutChoices}
              selection={layoutChoices[layoutTimeout].value}
              onChange={onLayoutChange}
            />
          </div>
          <div className={`${cssDropdown.mirna} ${cssDropdown.header}`}>
            Network type:
          </div>
          <div className={`${cssDropdown.mirna} ${cssDropdown.dropdown}`}>
            <DropdownGroup
              choices={networkChoices}
              selection={selectedValue}
              onChange={onNetworkChange}
            />
          </div>
          <div
            className={cssDropdown.download}
            onClick={() =>
              downloadNetwork(networkData.nodes, networkData.edges)
            }
          >
            <button>Download Network</button>
          </div>
        </div>
      </NetworkViewer>
    </div>
  );
};

export default NetworkGraph;

NetworkGraph.propTypes = {
  entry: PropTypes.string.isRequired,
  initialNetwork: PropTypes.objectOf(PropTypes.array).isRequired,
  networkChoices: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectElements: PropTypes.func
};

const layoutChoices = [
  { label: "5 seconds", value: 5 },
  { label: "10 seconds", value: 10 }
];

function findLayoutChoice(expected, choices) {
  let index = 0;
  for (const choice of choices) {
    // eslint-disable-next-line no-eval
    if (choice.value === eval(expected)) {
      return index;
    }
    index += 1;
  }
}

const style = [
  {
    selector: "node",
    css: {
      content: "data(label)",
      shape: "data(shape)",
      color: "data(col)",
      backgroundColor: "data(col)"
    }
  },
  {
    selector: "edge",
    css: {
      "line-color": "data(col)"
    }
  },
  {
    selector: ":selected",
    css: {
      "background-color": "#e933ff",
      "line-color": "#e933ff",
      "target-arrow-color": "#e933ff",
      "source-arrow-color": "#e933ff"
    }
  }
];
