import React from "react";
import { Link } from "react-router-dom";
import "./AmbiguousIDs.css";
import css from "../Homepage/Homepage.module.css";
import Header from "../Homepage/Header";
import Footer from "../Footer/Footer";

const AmbiguousIDs = ({ symbol, values }) => {
  return (
    <div className={css.container}>
      <Header />
      <div className={"containerAmbiguous"}>
        <p style={{ width: "90%", placeSelf: "center" }}>
          The symbol {symbol} has ambiguous mapping in our database, please
          choose one of these:
        </p>
        <ul style={{ placeSelf: "center" }}>
          {values.map(item => (
            <li key={item} style={{ marginBottom: "10px" }}>
              <Link to={`/entry/${item}`}>{item}</Link>
            </li>
          ))}
        </ul>
      </div>
      <Footer />
    </div>
  );
};

export default AmbiguousIDs;
