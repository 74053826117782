import React, { useEffect, useState } from "react";
import UserGraphSection from "./UserGraphSection";
import "./UserNetwork.css";
import Footer from "../Footer/Footer";
import { relativeURL } from "../../helperURL";
import RejectedNodes from "../RejectedNodes";
import Menu from "../Menu/Menu";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const UserNetwork = () => {
  const [value, setValue] = useState("");
  const [selectedElement, selectElement] = useState({
    flag: false,
    element: null,
    infos: {
      interaction: {
        flag: false,
        data: null
      }
    }
  });
  const [dataNetwork, setDataNetwork] = useState({
    flag: false,
    userInfo: {
      data: [],
      unique_inserted: "--",
      accepted: "--",
      not_accepted: "--",
      not_accepted_entries: []
    },
    message: "Insert nodes"
  });
  const { trackPageView, trackEvent } = useMatomo();

  // Track page view
  React.useEffect(trackPageView, []);

  const onChange = event => {
    setValue(event.target.value);
  };

  const onSubmit = event => {
    trackEvent({ category: "user-network", action: "computing-network" });
    fetchNetwork(value);
    event.preventDefault();
  };

  const fetchNetwork = value => {
    setDataNetwork({
      flag: false,
      userInfo: {
        data: [],
        unique_inserted: "--",
        accepted: "--",
        not_accepted: "--",
        not_accepted_entries: []
      },
      message: "Computing network..."
    });
    async function fetchNetworkData() {
      const resp = await fetch(relativeURL("/api/network"), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ raw_usernodes: value })
      });
      const body = await resp.json();
      setDataNetwork(body);
    }

    fetchNetworkData();
  };

  return (
    <div className={"containerUserNetwork"}>
      <Menu />
      <div className="userNetwork">
        <h1 className="userTitle">Computing network from user selection</h1>
        <div className="userFormContainer">
          <label>Insert ensembl gene or symbols or miRNAs name:</label>
          <form onSubmit={onSubmit} className="userForm">
            <textarea
              value={value}
              onChange={onChange}
              placeholder={"one entry per row..."}
            />
            <br />
            <input
              type="submit"
              value="Compute Network"
              className="userInput"
            />
          </form>
        </div>
        <div className="userInfo">
          <div className="userInfoElement">
            <b>Unique inserted nodes:</b> {dataNetwork.userInfo.unique_inserted}
          </div>
          <div className="userInfoElement">
            <b>Accepted:</b> {dataNetwork.userInfo.accepted}
          </div>
          <div className="userInfoElement">
            <b>Rejected:</b> {dataNetwork.userInfo.not_accepted}
          </div>
          <div className="userInfoElement">
            <b>Message:</b> {dataNetwork.message}
          </div>
        </div>
        <RejectedNodes
          rejectedNodes={dataNetwork.userInfo.not_accepted_entries}
        />
        <UserGraphSection
          flag={dataNetwork.flag}
          nodes={dataNetwork.nodes}
          edges={dataNetwork.edges}
          message={dataNetwork.message}
          selectedElement={selectedElement}
          selectElement={selectElement}
        />
      </div>
      <Footer />
    </div>
  );
};

export default UserNetwork;
