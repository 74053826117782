import React, { useState } from "react";
import css from "./Help.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faqs } from "./faqs";

const FAQBox = () => {
  return (
    <div className={css.faqBox}>
      <div className={css.faqTitle}>Frequently asked questions</div>
      {faqs.map(q => {
        return (
          <QuestionBox
            key={q.question}
            question={q.question}
            answers={q.answers}
          />
        );
      })}
    </div>
  );
};

export default FAQBox;

const QuestionBox = ({ question, answers }) => {
  const [open, setOpen] = useState(false);
  const [icon, setIcon] = useState("angle-right");
  const [iconColor, setIconColor] = useState("green");

  const onClick = () => {
    setOpen(!open);
    icon === "angle-right" ? setIcon("angle-down") : setIcon("angle-right");
    open ? setIconColor("green") : setIconColor("darkred");
  };

  return (
    <div className={css.singleQuestion}>
      <div className={css.question} onClick={onClick}>
        <FontAwesomeIcon
          icon={icon}
          size={"2x"}
          style={{
            paddingLeft: "20px",
            marginRight: "20px",
            color: `${iconColor}`
          }}
        />
        <div>{question}</div>
      </div>
      <AnswersBox
        style={{ display: `${open ? "grid" : "none"}` }}
        answers={answers}
      />
    </div>
  );
};

const AnswersBox = ({ answers, style }) => {
  return (
    <div className={css.answersBox} style={style}>
      {answers.map((x, index) => {
        return (
          <div key={index} className={css.singleAnswerBox}>
            <Answer key={x.answer} answer={x.answer} />
            <Img key={index} image={x.image} imageAlt={x.imageAlt} />
          </div>
        );
      })}
    </div>
  );
};

const Answer = ({ answer }) => {
  return answer !== null ? (
    <div className={css.answerText}>{answer}</div>
  ) : null;
};

const Img = ({ image, imageAlt }) => {
  return image !== null ? (
    <img
      className={css.answerImage}
      alt={imageAlt}
      src={image}
      height={"100%"}
      width={"90%"}
    />
  ) : null;
};
