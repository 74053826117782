import React, { useState } from "react";
import css from "./Network.module.css";
import NetworkTable from "./NetworkTables";
import * as PropTypes from "prop-types";
import NetworkGraph from "./NetworkGraph";
import NetworkLegend from "./NetworkLegend";

const Network = ({ flag, entry, networkData, networkChoices }) => {
  const [selectedElement, selectElement] = useState({
    flag: false,
    element: null,
    infos: {
      interaction: {
        flag: false,
        data: null
      }
    }
  });

  return flag ? (
    <div className={css.network}>
      <h2 className={css.netTitle}>Network</h2>
      <NetworkLegend />
      <NetworkGraph
        entry={entry}
        initialNetwork={networkData}
        networkChoices={networkChoices}
        selectElements={selectElement}
      />
      <NetworkTable
        flag={selectedElement.flag}
        element={selectedElement.element}
        information={selectedElement.infos}
      />
    </div>
  ) : (
    <div className={css.network}>
      <div />
      <div
        style={{
          placeSelf: "center",
          display: "grid",
          width: "20%",
          height: "100px",
          border: "1px solid lightgray",
          borderRadius: "5px"
        }}
      >
        <div style={{ placeSelf: "center" }}>No network found</div>
      </div>
      <div />
    </div>
  );
};

export default Network;

Network.propTypes = {
  flag: PropTypes.bool.isRequired,
  entry: PropTypes.string.isRequired,
  networkData: PropTypes.objectOf(PropTypes.array).isRequired,
  networkChoices: PropTypes.arrayOf(PropTypes.object).isRequired
};
