import React from "react";
import PropTypes from "prop-types";

const DropdownGroup = ({ choices, selection, onChange }) => (
  <select value={selection} onChange={e => onChange(e.target.value)}>
    {choices.map(group => {
      return (
        <optgroup key={group.groupLabel} label={group.groupLabel}>
          {group.data.map(opt => {
            return (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            );
          })}
        </optgroup>
      );
    })}
  </select>
);

export default DropdownGroup;

DropdownGroup.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object).isRequired,
  selection: PropTypes.any,
  onChange: PropTypes.func.isRequired
};
