// Copyright 2019 Davide Corso

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./style.css";
import PropTypes from "prop-types";

const Spinner = ({ size }) => {
  return (
    <div className="containerSpinner">
      <div className="spinner">
        <FontAwesomeIcon icon="spinner" size={size} spin />
      </div>
    </div>
  );
};

Spinner.propTypes = {
  size: PropTypes.string.isRequired
};

export default Spinner;
