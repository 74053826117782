import { saveAs } from "file-saver";

export const composeNetwork = (nodes, edges) => {
  let nodesMap = new Map();

  nodes.forEach(item => {
    nodesMap.set(item.data.id, item.data.label);
  });

  let s = "";
  edges.forEach(item => {
    s += `${nodesMap.get(item.data.source)}\t${nodesMap.get(
      item.data.target
    )}\n`;
  });

  return s;
};

export function downloadNetwork(nodes, edges) {
  const testo = composeNetwork(nodes, edges);

  const blob = new Blob([testo], {
    type: "text/plain;charset=utf-8"
  });
  saveAs(blob, "network.txt");
}

// const composeFilename = (group, label, entry) => {
//   if (group === "Single Myofiber") {
//     return `SingleMyo_${entry}_${label}.txt`;
//   } else if (group === "Single cell: Chemello at al., 2020 - Pathology (DMD)") {
//     return "";
//   } else if (group === "Single cell: Dos Santos et al., 2020 - Single Fiber") {
//     return "";
//   } else {
//     return "";
//   }
// };
