import React from "react";
import css from "./Homepage.module.css";

const Header = () => {
  return (
    <header className={css.homepageHeader} style={{ display: "grid" }}>
      <h1 className={css.homepageTitle}>MyoData</h1>
    </header>
  );
};

export default Header;
