import React from "react";
import "./UserNetwork.css";

const UserNetworkLegend = () => {
  return (
    <div className={"UNLegend"}>
      <div className={"UNLegendNodeContainer"}>
        <div className={"UNLegendCircle"} /> <div>mRNA</div>
      </div>
      <div className={"UNLegendNodeContainer"}>
        <div className={"UNLegendTriangle"} /> <div>lncRNA</div>
      </div>
      <div className={"UNLegendNodeContainer"}>
        <div className={"UNLegendDiamond"} /> <div>miRNA</div>
      </div>
      <div className={"UNLegendNodeContainer"}>
        <div className={"UNLegendSquare"} /> <div>User nodes</div>
      </div>
      <div className={"UNLegendEdgeContainer"}>
        <div className={"UNLegendValidated"} />
        <div style={{ marginLeft: "5px" }}>Validated Interaction</div>
      </div>
      <div className={"UNLegendEdgeContainer"}>
        <div className={"UNLegendPredicted"} />
        <div style={{ marginLeft: "5px" }}>Predicted Interaction</div>
      </div>
      <div className={"UNLegendEdgeContainer"}>
        <div className={"UNLegendCorrelation"} />
        <div style={{ marginLeft: "5px" }}>Correlation</div>
      </div>
    </div>
  );
};

export default UserNetworkLegend;
