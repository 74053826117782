// Copyright 2019 Davide Corso

import { useEffect, useState } from "react";
import { relativeURL } from "../helperURL";

function urlExpToFetch(target, expToFetch) {
  assert(["normal", "compressed"].includes(expToFetch), "ExpToFetch Error.");
  if (expToFetch === "normal") {
    return `/api/expression/normal/${target}`;
  } else if (expToFetch === "compressed") {
    return `/api/expression/compressed/${target}`;
  } else {
    throw new Error("Error URL Exp to Fetch.");
  }
}

function assert(condition, message) {
  if (!condition) {
    message = message || "Assertion failed";
    if (typeof Error !== "undefined") {
      throw new Error(message);
    }
    throw message; // Fallback
  }
}

export const useTarget = (target, expToFetch) => {
  const [data, setData] = useState({
    flag: false,
    entry_type: "",
    series: {},
    categories: []
  });

  useEffect(() => {
    async function fetchData() {
      let resp = await fetch(relativeURL(urlExpToFetch(target, expToFetch)));
      const body = await resp.json();
      setData(body);
    }

    fetchData();
  }, [target, expToFetch]);

  return data;
};
