import React, { useState } from "react";
import Footer from "../Footer/Footer";
import PathwayTable from "./PathwayTable";
import "./Pathway.css";
import { relativeURL } from "../../helperURL";
import PathwayNetwork from "./PathwayNetwork";
import Menu from "../Menu/Menu";
import RejectedNodes from "../RejectedNodes";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const Pathway = () => {
  const { trackPageView, trackEvent } = useMatomo();
  const [value, setValue] = useState("");
  const [pathwayNetwork, setPathwayNetwork] = useState({
    flag: false,
    title: "",
    nodes: [],
    edges: [],
    computing: false
  });
  const [tableResults, setTableResults] = useState({
    flag: false,
    pathwayInfo: {
      unique_inserted: "--",
      rejected: "--",
      accepted: "--",
      rejected_entries: [],
      accepted_usernodes: [],
      extra_entries: []
    },
    results: [],
    message: "Insert nodes"
  });
  const [selectedElement, selectElement] = useState({
    flag: false,
    element: null,
    infos: {
      interaction: {
        flag: false,
        data: null
      }
    }
  });

  // Track page view
  React.useEffect(trackPageView, []);

  const onChange = event => {
    setValue(event.target.value);
  };

  const onSubmit = event => {
    trackEvent({
      category: "pathway-page",
      action: "enrichment-computing-network"
    });
    setPathwayNetwork({
      flag: false,
      title: "",
      nodes: [],
      edges: [],
      computing: false
    });
    fetchPathwayTable(value);
    event.preventDefault();
  };

  const fetchPathwayTable = value => {
    async function fetchPathwayTableData() {
      const resp = await fetch(relativeURL("/api/pathways"), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ raw_usernodes: value })
      });
      const body = await resp.json();
      setTableResults(body);
    }

    fetchPathwayTableData();
  };

  const onClickTableRow = title => {
    trackEvent({ category: "pathway-page", action: "fetch-pathway-network" });
    fetchSinglePathway(title);
  };

  const fetchSinglePathway = title => {
    setPathwayNetwork({
      flag: false,
      title: "",
      nodes: [],
      edges: [],
      computing: true
    });
    async function fetchSinglePathwayData() {
      const resp = await fetch(relativeURL("/api/singlepathway"), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          pathway_title: title,
          extra_entries: tableResults.pathwayInfo.extra_entries,
          accepted_usernodes: tableResults.pathwayInfo.accepted_usernodes
        })
      });
      const body = await resp.json();
      setPathwayNetwork(body);
    }

    fetchSinglePathwayData();
  };

  return (
    <div className={"containerPathway"}>
      <Menu />
      <div className="pathway">
        <h1 className="pathwayHeader">Pathway Enrichment Analysis</h1>
        <div className="pathwayContainerForm">
          <label>Insert ensembl gene or symbols or miRNAs name:</label>
          <form onSubmit={onSubmit} className="pathwayForm">
            <textarea
              value={value}
              onChange={onChange}
              placeholder={"one entry per row..."}
            />
            <br />
            <input
              type="submit"
              value="Compute Network"
              className="pathwayInput"
            />
          </form>
        </div>
        <div className="pathwayInfo">
          <div className="pathwayInfoElement">
            <b>Unique inserted nodes:</b>{" "}
            {tableResults.pathwayInfo.unique_inserted}
          </div>
          <div className="pathwayInfoElement">
            <b>Accepted:</b> {tableResults.pathwayInfo.accepted}
          </div>
          <div className="pathwayInfoElement">
            <b>Rejected:</b> {tableResults.pathwayInfo.rejected}
          </div>
          <div className="pathwayInfoElement">
            <b>Message:</b> {tableResults.message}
          </div>
        </div>
        <RejectedNodes
          rejectedNodes={tableResults.pathwayInfo.rejected_entries}
        />
        <PathwayTable
          flag={tableResults.flag}
          results={tableResults.results}
          onClick={onClickTableRow}
        />
        <PathwayNetwork
          flag={pathwayNetwork.flag}
          nodes={pathwayNetwork.nodes}
          edges={pathwayNetwork.edges}
          title={pathwayNetwork.title}
          computing={pathwayNetwork.computing}
          selectElement={selectElement}
          selectedElement={selectedElement}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Pathway;
