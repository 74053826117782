import React from "react";
import css from "./Network.module.css";
import ReactTable from "react-table";

const NetworkTable = ({ flag, element, information }) => {
  return flag ? (
    element === "node" ? (
      <NetworkTableNode nodeInformation={information} />
    ) : (
      <NetworkTableEdge
        interactionsFlag={information.interaction.flag}
        interactionsData={information.interaction.data}
        correlations={information.correlation}
      />
    )
  ) : (
    <div className={css.netTable} />
  );
};

export default NetworkTable;

const NetworkTableNode = ({ nodeInformation }) => {
  return (
    <div className={css.netTable}>
      <div className={css.tableRelative}>
        <div className={css.tableAbsolute}>
          <TableInformation
            flag={true}
            label={"Node"}
            values={nodeInformation.data}
          />
        </div>
      </div>
    </div>
  );
};

const NetworkTableEdge = ({
  interactionsFlag,
  interactionsData,
  correlations
}) => {
  const corr_columns = [
    { Header: "Source Name", accessor: "source_name", minWidth: 200 },
    { Header: "Source", accessor: "source", minWidth: 200 },
    { Header: "Target Name / ID", accessor: "target_name", minWidth: 200 },
    { Header: "Target", accessor: "target", minWidth: 200 },
    { Header: "Correlations", accessor: "correlation", minWidth: 200 }
  ];

  return (
    <div className={css.netTable}>
      <div className={css.tableRelative}>
        <div className={css.tableAbsolute}>
          <TableInformation
            flag={true}
            values={correlations}
            label={"Correlations"}
            columns={corr_columns}
          />
          {interactionsFlag ? (
            Object.keys(interactionsData["database"]).map((db, index) => {
              return (
                <TableInformation
                  flag={interactionsFlag}
                  key={index}
                  label={`Edge - ${db}`}
                  values={interactionsData["database"][db]}
                />
              );
            })
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};

const TableInformation = ({ label, values, columns = null }) => {
  const defaultPageSize = values.length > 5 ? 5 : values.length;
  return values.length > 0 ? (
    <div className={css.table}>
      <ReactTable
        style={{ width: "auto", placeSelf: "center" }}
        data={values}
        columns={
          columns === null
            ? get_columns(label, values)
            : [{ Header: label, columns: columns }]
        }
        pageSizeOptions={[5, 10, 20, 25, 50]}
        pageSize={defaultPageSize}
        showPaginationBottom={values.length > 5}
      />
    </div>
  ) : (
    <div />
  );
};

function get_columns(label, interactions) {
  const first_int_value = interactions[0];
  let columns = [];
  for (const key in first_int_value) {
    columns.push({
      Header: key,
      accessor: key,
      minWidth: 200,
      style: {
        fontFamily: "Lucida Console, Ubuntu Mono, Courier, monospace"
      },
      Cell: row => (
        <div>
          <span title={row.value}>{row.value}</span>
        </div>
      )
    });
  }

  return [{ Header: label, columns: columns }];
}
